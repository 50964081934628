import { LoadingButton } from "@mui/lab";
import {
  Button,
  Grid,
  Stack,
  Typography
} from "@mui/material";
import { useSnackbar } from "notistack";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  HighlightSelectBox,
  SectionPaper,
} from "../../../components";
import { SelectBoxOption } from "../../../interfaces";
import { useAppDispatch } from "../../../redux";
import { constructRow, useCreateDataTableMutation, useDeleteDataTableMutation, useLazyGetAllDataTableDDOptionsQuery, useLazyGetDataTableByIdQuery, useUpdateDataTableMutation } from "../../../redux/services";
import { closeBackdrop, openBackdrop } from "../../../redux/slices/backdrop";
import { closeConfirmationDialog, openConfirmationDialog } from "../../../redux/slices/confirmationDialog";
import { openDialog } from "../../../redux/slices/dialog";
import { ImportDSFromDoc } from "./importDSFromDoc";
import CustomTable from "./table";

type LinkDataTableTemplate = (links: any[], unlinks: any[]) => Promise<void>;

export const DataTableForm: React.FC<{
  type: "datasheet" | "inputParameter", refetch?: () => void, linkDataTableTemplate: LinkDataTableTemplate,
  getPath: (path: string) => string, sheet_id?: string, preview?: boolean, dataTableData?: any, associations?: any[]
}> = (props) => {

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  let { ds_id = "New" } = useParams();

  const [getAllDataTable, { data: publishedTemplateOptions = [], isFetching, isLoading, status }] = useLazyGetAllDataTableDDOptionsQuery({ refetchOnFocus: true, refetchOnReconnect: true });
  const [getDataTableById, dataTableResponse] = useLazyGetDataTableByIdQuery({ refetchOnFocus: true, refetchOnReconnect: true })
  const [createForm, formResponse] = useCreateDataTableMutation();
  const [updateForm, updatedFormResponse] = useUpdateDataTableMutation();
  const [deleteForm] = useDeleteDataTableMutation();

  const ref = React.createRef();
  const [selectedTemplate, setSelectedTemplate] = React.useState<any>(null);
  const [formId, setFormId] = React.useState(null);
  const [state, updateState] = React.useState<any[]>([]);

  if (props?.sheet_id) {
    ds_id = props?.sheet_id
  }

  const isSystemGenId = ds_id !== "New";
  const loading = isFetching || isLoading || dataTableResponse.isFetching || dataTableResponse.isLoading || formResponse.isLoading || updatedFormResponse.isLoading;

  const fetchTemplates = async () => {
    dispatch(openBackdrop(`Fetching ${props.type === 'datasheet' ? 'Data Sheet' : 'Technical Parameter'} Templates...`));
    await getAllDataTable({ dtTemplateType: props.type }).unwrap().finally(() => {
      dispatch(closeBackdrop());
    })
  };

  const updateTablesState = async (res: any) => {
    const newState = res?.tables?.map((table: any, idx: number) => ({
      name: table.name,
      position: idx,
      description: table.description,
      header_color: table?.header_color,
      definition: {
        columns: table?.columns?.filter((col: any) => col.field)?.map((col: any) => {
          return {
            ...col,
            headerName: col.header_name,
            headerClassName: `${col.header_class_name}`,
            resizable: false,
            sortable: false,
            disableColumnMenu: true,
          }
        }),
        rows: table?.rows?.map((row: any) => {
          const obj: any = {};
          row?.column_content?.forEach((col: any) => {
            obj[col.field] = col.content
          })
          return {
            id: row?.id,
            ...obj
          }
        }) || [],
      },
    }));
    updateState(newState)
  };

  const fetchDataTable = async () => {
    if (isSystemGenId) {
      dispatch(openBackdrop(`Fetching ${props.type === 'datasheet' ? 'Data Sheet' : 'Technical Parameter'} Detail...`));
      await getDataTableById({
        dtTemplateType: props.type,
        dtInstanceType: "form",
        dtTemplateId: ds_id
      }).unwrap().then(async (res) => {
        if (res) {
          await updateTablesState(res);
          setFormId(res.sequence_id);
          setSelectedTemplate(publishedTemplateOptions.find(template => res.template_id === template.value) || null)
        }
      }).catch((error: any) => {
        console.error(`${props.type} form fetch error: `, error)
      }).finally(() => {
        dispatch(closeBackdrop());
      })
    }
  };

  const onSuccessfullyFormCreation = async (res: any) => {
    enqueueSnackbar('Form Created Successfully', { variant: "success" });
    await props.linkDataTableTemplate([res?._id], [])
    navigate(props.getPath(res?._id));
    setFormId(res?._id);
  }

  const onCreateForm = async () => {
    if (selectedTemplate?._id) {
      dispatch(openBackdrop("Creating Form..."));
      await createForm({
        dtTemplateType: props.type,
        dtInstanceType: "form",
        dtTemplateId: selectedTemplate?._id,
        payload: {
          name: selectedTemplate?.name,
          tables: selectedTemplate?.tables?.map((table: any, index: number) => {
            return {
              name: table.name,
              description: table.description,
              position: index,
              rows: table?.rows ?? [],
              header_color: table?.header_color,
              columns: table?.columns?.filter((col: any) => col.field)?.map((col: any) => {
                return {
                  ...col,
                  header_class_name: col.header_class_name,
                  header_name: col.header_name,
                  editable: col?.editable ? true : false
                };
              }) ?? [],
            }
          }) ?? [],
        } as any
      }).unwrap().then(async (res) => {
        if (!res.title) {
          onSuccessfullyFormCreation(res);
        }
      }).catch((error: any) => {
        enqueueSnackbar(
          error?.data?.title || "Something went wrong unable to create form",
          { variant: "error" }
        );
      }).finally(() => {
        dispatch(closeBackdrop());
      })
    }
  };

  const onUpdate = async (reset: () => void) => {
    if (selectedTemplate?._id && formId) {
      dispatch(openBackdrop("Updating Form..."));
      await updateForm({
        dtTemplateType: props.type,
        dtInstanceType: "form",
        dtTemplateId: ds_id,
        queryTemplateId: selectedTemplate?._id,
        payload: {
          name: selectedTemplate?.name,
          tables: state?.map((table: any, index: number) => {
            return {
              name: table.name,
              description: table.description,
              position: index,
              header_color: table?.header_color,
              rows: table?.definition?.rows?.map((row: any) => ({
                column_content: constructRow(table, row),
                id: row?.id,
              })) ?? [],
              columns: table?.definition?.columns?.map((col: any) => {
                return {
                  ...col,
                  header_class_name: col.headerClassName,
                  header_name: col.headerName,
                  editable: col.editable ? true : false
                };
              }) ?? [],
            }
          }) ?? [],
        } as any
      }).unwrap().then(res => {
        if (!res.title) {
          enqueueSnackbar('Form Updated Successfully', { variant: "success" });
        }
      }).catch((error: any) => {
        enqueueSnackbar(
          error?.data?.title || "Something went wrong unable to update form",
          { variant: "error" }
        );
      }).finally(() => {
        reset();
        dispatch(closeBackdrop());
      })
    }
  };

  const initiateTemplateChange = () => {
    dispatch(
      openConfirmationDialog({
        title: "Template Changing",
        body: `Are you sure you want to change the ${props.type === "datasheet" ? "data sheet" : "technical parameter"} ${formId}. It will delete the changes you may have done if any.`,
        positiveBtn: "Change Template",
        negativeBtn: "Cancel",
        onOk: async () => onTemplateChange(),
        onNegativeBtn: () => dispatch(closeConfirmationDialog()),
      })
    );
  };

  const onTemplateChange = async () => {
    await props.linkDataTableTemplate([], [ds_id]);
    dispatch(openBackdrop("Deleting Form..."))
    await deleteForm({
      dtTemplateType: props.type,
      dtInstanceType: "form",
      dtTemplateId: ds_id
    }).unwrap().then((res) => {
      navigate(props.getPath("New"));
      setFormId(null);
      setSelectedTemplate(null);
      enqueueSnackbar('Form Deleted Successfully', { variant: "success" });
    }).catch((error: any) => {
      enqueueSnackbar(
        error?.data?.title || "unable to delete form",
        { variant: "error" }
      );
    }).finally(() => {
      dispatch(closeBackdrop())
    })
  };

  const openImportFromDocument = async () => {
    dispatch(openDialog({
      title: "Import Datasheet from Documents",
      body: <ImportDSFromDoc associations={props.associations} onSuccessfullyFormCreation={onSuccessfullyFormCreation} />,
      hidePositiveBtn: true,
      hideNegativeBtn: true
    }));
  };

  React.useEffect(() => {
    fetchTemplates();
    // eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    fetchDataTable();
    // eslint-disable-next-line
  }, [ds_id, status]);

  React.useEffect(() => {
    if (!props?.sheet_id && props.dataTableData) {
      const d = props.dataTableData;
      setFormId(d?.sequence_id);
      updateTablesState(d);
      setSelectedTemplate(publishedTemplateOptions.find(template => d?.template_id === template.value) || null);
    }
    // eslint-disable-next-line
  }, [props.dataTableData, publishedTemplateOptions]);

  return (
    <Stack spacing={2} ref={ref}>
      {/* Basic Details */}
      <SectionPaper
        title={props.type === "datasheet" ? "Data Sheet Template" : "Technical Parameter Template"}
        renderButtons={[
          <Stack justifyContent={"center"}>
            <Typography variant="body1" fontFamily={"htrts_medium"}>
              {`Form ID: ${formId ?? "---"}`}
            </Typography>
          </Stack>
        ]}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} md={8}>
            <HighlightSelectBox
              disableClearable
              label={"Template"}
              margin={"none"}
              size="small"
              value={publishedTemplateOptions.find(template => selectedTemplate?.value === template.value) || null}
              options={publishedTemplateOptions}
              loading={loading}
              onChange={(data: SelectBoxOption) => {
                setSelectedTemplate(data)
              }}
              disabled={formId || false}
              viewOnly={props.preview}
            />
          </Grid>
          {!props.preview && <Grid item xs={12} md={2}>
            {(!formId && selectedTemplate?._id) || (!selectedTemplate?._id && !formId)
              ?
              <LoadingButton
                fullWidth
                size="medium"
                variant="contained"
                onClick={onCreateForm}
                loading={loading}
                disabled={props?.preview || false}
              >
                Create Form
              </LoadingButton>
              :
              <LoadingButton
                fullWidth
                size="medium"
                variant="contained"
                onClick={initiateTemplateChange}
                loading={loading}
                disabled={props?.preview || false}
              >
                Change Template
              </LoadingButton>
            }
          </Grid>}
          {props.type === 'datasheet' && <Grid item xs={12} md={2}>
            <Button variant="contained" disabled={props?.preview || false} fullWidth onClick={(e) => openImportFromDocument()} >Import</Button>
          </Grid>}
        </Grid>
      </SectionPaper>

      {/* Main Content */}
      {state?.map((data, index) => <CustomTable onUpdate={onUpdate} data={data} key={index} state={state} updateState={updateState} index={index} preview={props?.preview || false} />)}
    </Stack>
  );
};