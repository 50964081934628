import { Alert, Box, useTheme } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import { TitleHeader, CustomDataGrid, DeletePanel } from "../../../components";
import { useAppDispatch, useAppSelector } from "../../../redux";
import { useLazyGetVendorsQuery, useDeleteVendorMutation } from "../../../redux/services";
import { resetVendorSlice } from "../../../redux/slices/vendor";
import { AppRoutes } from "../../../router/routes";
import { DashboardTableColumn } from "../utils";
import { closeBackdrop, openBackdrop } from "../../../redux/slices/backdrop";
import { closeConfirmationDialog, openConfirmationDialog, updateConfirmationDialogPositiveBtn, updateCongirmationDialogBody } from "../../../redux/slices/confirmationDialog";
import { HasAccess } from "../../../router/authorization";
import { PERMISSIONS } from "../../../router/permission";
import { GridSortDirection, GridSortModel } from "@mui/x-data-grid-premium";
import { useStyles } from "../../documentTemplates/styles";

export const Dashboard: React.FC<{ children?: JSX.Element }> = (props) => {

    const classes = useStyles(useTheme());
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const [deleteVendorMutation] = useDeleteVendorMutation();
    const [getAllVendors, { data: allData = { data: [], totalCount: 0 }, isFetching, isLoading, isError }] = useLazyGetVendorsQuery({ refetchOnFocus: true });
    const { data, totalCount } = allData;

    const [search, setSearch] = React.useState("");

    const [paginationModel, setPaginationModel] = React.useState({
        page: 0,
        pageSize: 10,
    });
    const [queryOptions, setQueryOptions] = React.useState({
        sortModel: [{ field: 'sequence_id', sort: 'desc' as GridSortDirection }],
    });

    const { perm } = useAppSelector(store => store.auth.userDetails);

    const creatable = React.useMemo(() => HasAccess(perm, PERMISSIONS.SUPPLIER_CREATE), [perm]);
    const deletable = React.useMemo(() => HasAccess(perm, PERMISSIONS.SUPPLIER_DELETE), [perm]);
   
    const addEditVendor = (data: any, isEdit: boolean = true) => {
        dispatch(resetVendorSlice())
        navigate(AppRoutes.viewVendorBasicDetails(isEdit ? data._id : "New"))
    }
    const deleteMaterial = (vendor: any) => {
        let message = `Check for dependencies before deleting the Supplier ${vendor?.vendor_name} (${vendor?.sequence_id})`;
        dispatch(openConfirmationDialog({
            title: "Delete Operation",
            body: <DeletePanel
                message={message}
                warningMessage={""}
            />,
            positiveBtn: "Check for dependencies",
            negativeBtn: "Cancel",
            onOk: () => openDependency(vendor, message, false),
            onNegativeBtn: () => dispatch(closeConfirmationDialog())
        }))
    }
    const openDependency = (vendor: any, message: string, force: boolean) => {

        dispatch(updateCongirmationDialogBody(<DeletePanel
            message={vendor.associations ? vendor.associations.length === 0 ? 'No dependency found' : `Dependency Table of ${vendor?.sequence_id}` : "No dependency found"}
            errorMessage={""}
            errorMessageStacks={vendor?.associations ?? []}
            warningMessage={""}
        />))
        dispatch(updateConfirmationDialogPositiveBtn({
            positiveBtn: "Delete",
            onOk: () => {
                dispatch(updateCongirmationDialogBody(<DeletePanel
                    message={`Are you sure want to delete the Supplier ${vendor?.vendor_name} ( ${vendor?.sequence_id}) ?`}
                    warningMessage={"Warning: After delete its not possible to retrieve"}
                />))
                dispatch(updateConfirmationDialogPositiveBtn({
                    positiveBtn: "Ok",
                    onOk: () => performRowDel(vendor, "", true)
                }))
            },
        }))
    }

    const performRowDel = async (vendor: any, message: string, force: boolean = false) => {
        dispatch(openBackdrop("Deleting Supplier..."));
        let res: any = {};
        try {
            res = await deleteVendorMutation({ id: vendor._id, force })

            if (Object.keys(res).includes("data")) {
                enqueueSnackbar(`Deleted Supplier Successfully!`, { variant: "success" });
                dispatch(closeConfirmationDialog());
                getAllVendors({...paginationModel});
            } else if (Object.keys(res).includes("error")) {
                // eslint-disable-next-line 
                throw res.error.data;
            } else {
                // eslint-disable-next-line 
                throw "Data not found"
            }
        } catch (error: any) {
            let details = [];
           let object_type : 'material' = 'material';
           details.push({object_type: object_type,object_details: error?.details?.materials ?? []})
           let errorMessage: string = error?.title ?? "Opps! Something went wrong, Unable to delete"
            enqueueSnackbar(errorMessage, { variant: "error" });
            dispatch(updateCongirmationDialogBody(<DeletePanel
                message={errorMessage}
                errorMessage={""}
                errorMessageStacks={details}
                warningMessage={""}
            />))
            dispatch(updateConfirmationDialogPositiveBtn({
                positiveBtn: "Ok",
                onOk: () =>  dispatch(closeConfirmationDialog())
            }))
                    } finally {
                        dispatch(closeBackdrop());
                    }
    }

    const giveMeRows = () => {
        if (!isLoading && !isFetching && data) {
            if (search.trim().length) {
                return data?.filter((_: any) => _.vendor_name?.toLowerCase().includes(search.toLowerCase()) ||
                _?.sequence_id?.toLowerCase()?.includes(search.toLowerCase()) ||
                _?.primary_contact?.person_name?.toLowerCase()?.includes(search.toLowerCase()) ||
                _?.primary_contact?.email?.toLowerCase()?.includes(search.toLowerCase()) ||
                _?.primary_contact?.phone?.toString()?.includes(search.toLowerCase()) ||
                _?.primary_contact?.country?.toLowerCase()?.includes(search.toLowerCase()) ||
                _?.status?.toLowerCase()?.includes(search.toLowerCase())) ?? []
            }
            return data ?? [];
        } else {
            return [];
        }
    }

    let ROWS = giveMeRows();

    const handleSortModelChange = React.useCallback((sortModel: GridSortModel) => {
        // Here you save the data you need from the sort model
        setQueryOptions({ sortModel: [...sortModel] });
    }, [])

    React.useEffect(() => {
        getAllVendors({ ...paginationModel, sort_by: queryOptions?.sortModel?.[0]?.field, sort_order: queryOptions?.sortModel?.[0]?.sort });
        // eslint-disable-next-line
    }, [paginationModel, JSON.stringify(queryOptions?.sortModel)])

    return <Box sx={classes.root}>
        {/* Header */}
        <TitleHeader
            title="Suppliers"
            count={ROWS?.length ?? 0}
            showSearch={true}
            search={search}
            onSearchChange={setSearch}
            searchPlaceholder={"Search for Suppliers (only within this page)"}
            showCreateBtn={creatable}
            createBtnLabel={"Create Supplier"}
            onCreateBtn={() => addEditVendor(null, false)}
        />

        {/* Error */}
        {isError && <Alert sx={{ mt: 2 }} severity="error">Oops! Something went wrong, Unable to fetch Suppliers. Try Again Later!</Alert>}


        {/* Data Grid */}
        <Box mt={2}>
            <CustomDataGrid
                id="suppliers-list"
                rows={ROWS}
                columns={DashboardTableColumn(deleteMaterial, addEditVendor, deletable)}
                loading={isLoading || isFetching}
                getRowId={(row) => row._id}
                disableVirtualization
                showToolbar={true}
                rowCount={totalCount}
                paginationModel={paginationModel}
                paginationMode="server"
                onPaginationModelChange={setPaginationModel}
                sortingMode="server"
                onSortModelChange={handleSortModelChange}
            />
        </Box>
    </Box>
}