import React from "react";
import { TextField, Typography, Button, Grid, Alert, DialogTitle, DialogContent, DialogActions } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useDispatch } from "react-redux";
import { closeDialog } from "../../../../../redux/slices/dialog";
import {
  openBackdrop,
  closeBackdrop,
} from "../../../../../redux/slices/backdrop";
import {
  useCreateSNNounMutation,
  useGetSNGroupsQuery,
  useGetCategoryQuery,
  useUpdateSNNounMutation,
} from "../../../../../redux/services";
import { useSnackbar } from "notistack";
import { HighlightSelectBox } from "../../../../../components";
import { SelectBoxOption } from "../../../../../interfaces";

export const AddUpdateSNNoun: React.FC<{
  children?: JSX.Element;
  isEdit: boolean;
  data: any;
  refetch: any;
}> = (props) => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [createSNNoun, createSNNounData] = useCreateSNNounMutation();
  const [updateSNNoun, updateSNNounData] = useUpdateSNNounMutation();
  const {
    currentData: allCategories = { data: [] },
    isFetching: categoriesLoading,
    isError: categoryError,
    isSuccess: categorySuccess,
  } = useGetCategoryQuery({ page: null, pageSize: null }, { refetchOnMountOrArgChange: true });

  const { data: categoriesList } = allCategories;

  const {
    currentData: allGroup = { data: [] },
    isFetching: groupLoading,
    isError: groupError,
    isSuccess: groupSuccess,
  } = useGetSNGroupsQuery({ page: null, pageSize: null }, { refetchOnMountOrArgChange: true });

  const { data: group } = allGroup;

  const [state, setState] = React.useState<{
    category: SelectBoxOption;
    name: string;
    abbreviation: string;
    description: string;
    group: SelectBoxOption;
  }>({
    category: { value: "", label: "" },
    name: "",
    abbreviation: "",
    description: "",
    group: { value: "", label: "" },
  });

  const [error, setError] = React.useState({
    category: false,
    name: false,
    abbreviation: false,
    description: false,
    group: false,
  });

  const updateState = (key: string, value: string) => {
    setState({ ...state, [key]: value });
    setError({ ...error, [key]: !value?.trim()?.length });
  };

  const updateDropDown = (key: string, data: SelectBoxOption) => {
    setState({ ...state, [key]: data });
    setError({ ...error, [key]: !data?.value });
  };

  const onCancelBtnClicked = () => {
    dispatch(closeDialog());
  };

  const onCreateUpdateBtnClicked = () => {
    if (
      state.category.value &&
      state.name.trim().length &&
      state.description.trim().length &&
      state.abbreviation.trim().length &&
      state.group.value
    ) {
      if (props.isEdit) {
        updateSNNoun({
          id: props.data.id,
          payload: {
            noun_abbr: state.abbreviation,
            noun_desc: state.description,
            group_id: state.group.value,
            noun_name: state.name,
            category_id: state.category.value,
          },
        });
      } else {
        createSNNoun({
          noun_abbr: state.abbreviation,
          noun_desc: state.description,
          group_id: state.group.value,
          noun_name: state.name,
          category_id: state.category.value,
        });
      }
    } else {
      setError({
        ...error,
        name: !state.name.trim().length,
        description: !state.description.trim().length,
        category: !state.category.value,
        group: !state.group.value,
        abbreviation: !state.abbreviation.trim().length,
      });
    }
  };

  const giveMeButtonName = () => {
    if (createSNNounData.isError) {
      return `${
        props.isEdit ? "Retry Update SN Noun" : "Retry Create SN Noun"
      }`;
    }

    return `${props.isEdit ? "Update SN Noun" : "Create SN Noun"}`;
  };

  const giveMeInfoText = () => {
    if (props.isEdit && updateSNNounData.isError) {
      return (
        <Alert severity="error">
          Oops! Something went wrong, Unable to update SN Noun. Try Again Later!
        </Alert>
      );
    }
    if (createSNNounData.isError) {
      return (
        <Alert severity="error">
          Oops! Something went wrong, Unable to create SN Noun. Try Again Later!
        </Alert>
      );
    }
  };

  React.useEffect(() => {
    if (createSNNounData.isSuccess) {
      enqueueSnackbar("SN Noun Created Successfully", { variant: "success" });
      props.refetch();
      dispatch(closeDialog());
    }
    if (createSNNounData.isError) {
      enqueueSnackbar("Oops! Something went wrong, Unable to create SN Noun", {
        variant: "error",
      });
    }
    // eslint-disable-next-line
  }, [createSNNounData]);

  React.useEffect(() => {
    if (props.isEdit) {
      if (updateSNNounData.isSuccess) {
        enqueueSnackbar("SN Noun Updated Successfully", { variant: "success" });
        props.refetch();
        dispatch(closeDialog());
      }
      if (updateSNNounData.isError) {
        enqueueSnackbar(
          "Oops! Something went wrong, Unable to update SN Noun",
          { variant: "error" }
        );
      }
    }
    // eslint-disable-next-line
  }, [updateSNNounData]);

  React.useEffect(() => {
    if (props.isEdit && props.data?.id && categorySuccess && groupSuccess) {
      let selectedCategory = categoriesList?.filter(
        (_: any) => props.data.category_id === _._id
      )?.[0];
      let selectedGroup = group?.filter(
        (_: any) => props.data.group_id === _._id
      )?.[0];
      setState({
        ...state,
        name: props.data.noun_name,
        description: props.data.noun_desc,
        abbreviation: props.data.noun_abbr,
        category: {
          value: selectedCategory?._id,
          label: selectedCategory?.category_name,
          ...selectedCategory,
        },
        group: {
          value: selectedGroup?._id,
          label: selectedGroup?.group_name,
          ...selectedGroup,
        },
      });

      dispatch(closeBackdrop());
    } else if (categoriesLoading || groupLoading) {
      dispatch(openBackdrop("Fetching Category & Groups..."));
    } else if (
      (categorySuccess && groupSuccess) ||
      categoryError ||
      groupError
    ) {
      dispatch(closeBackdrop());
    }
    // eslint-disable-next-line
  }, [
    props.isEdit,
    categoriesList,
    categoriesLoading,
    categoryError,
    categorySuccess,
    group,
    groupLoading,
    groupError,
    groupSuccess,
  ]);

  return (
    <>
      {/* Title */}
      <DialogTitle>
        <Typography
          variant="h6"
          color="textPrimary"
          sx={{
            borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
            width: "100%",
            fontFamily: "htrts_medium",
          }}
        >
          {`${props.isEdit ? "Update" : "Create"} SN Noun`}
        </Typography>
      </DialogTitle>

      {/* Fields */}
      <DialogContent>
        <Grid container spacing={2}>
          {/* Category */}
          <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
            <HighlightSelectBox
              size="medium"
              label="Categories"
              disabled={createSNNounData.isSuccess || createSNNounData.isError}
              value={state.category}
              options={categoriesList?.map((_: any) => ({
                value: _._id,
                label: _.category_name,
              }))}
              onChange={(data: SelectBoxOption) =>
                updateDropDown("category", data)
              }
              required={true}
              loading={categoriesLoading}
              error={error.category || categoryError}
              helperText={
                error.category
                  ? "Please select Category"
                  : categoryError
                  ? "Oops! Unable to load Category"
                  : ""
              }
            />
          </Grid>

          {/* Group */}
          <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
            <HighlightSelectBox
              size="medium"
              label="Group"
              value={state.group}
              disabled={createSNNounData.isSuccess || createSNNounData.isError}
              options={group?.map((_: any) => ({
                value: _._id,
                label: _.group_name,
              }))}
              onChange={(data: SelectBoxOption) => updateDropDown("group", data)}
              required={true}
              loading={groupLoading}
              error={error.group || groupError}
              helperText={
                error.group
                  ? "Please select group"
                  : groupError
                  ? "Oops! Unable to load group"
                  : ""
              }
            />
          </Grid>

          {/* Name */}
          <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
            <TextField
              fullWidth
              label={"Name"}
              value={state.name}
              required={true}
              disabled={createSNNounData.isSuccess || createSNNounData.isError}
              error={error.name}
              helperText={error.name ? "Please enter SN Noun name" : ""}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                updateState("name", e.target.value)
              }
            />
          </Grid>

          {/* Abbreviation */}
          <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
            <TextField
              fullWidth
              label={"Abbreviation"}
              value={state.abbreviation}
              required={true}
              disabled={createSNNounData.isSuccess || createSNNounData.isError}
              error={error.abbreviation}
              helperText={
                error.abbreviation ? "Please enter SN Noun abbreviation." : ""
              }
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                updateState("abbreviation", e.target.value)
              }
            />
          </Grid>

          {/* Description */}
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <TextField
              fullWidth
              label={"Description"}
              value={state.description}
              required={true}
              disabled={createSNNounData.isSuccess || createSNNounData.isError}
              error={error.description}
              helperText={
                error.description ? "Please enter SN Noun description." : ""
              }
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                updateState("description", e.target.value)
              }
            />
          </Grid>

          {/* Info Tex */}
          <Grid item xs={12}>
            {giveMeInfoText()}
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        <Grid container spacing={2} px={2} pb={1}>
          {/* Add SN Noun Btn */}
          <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
            <LoadingButton
              fullWidth
              variant="contained"
              onClick={onCreateUpdateBtnClicked}
              loading={createSNNounData.isLoading || updateSNNounData.isLoading}
            >
              {giveMeButtonName()}
            </LoadingButton>
          </Grid>

          {/* Cancel Btn */}
          <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
            <Button
              fullWidth
              variant="outlined"
              onClick={onCancelBtnClicked}
              disabled={createSNNounData.isLoading || updateSNNounData.isLoading}
            >
              Cancel
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </>
  );
};
