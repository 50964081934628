import { Search } from "@mui/icons-material";
import { Box, IconButton, List, ListItem, ListItemText, Skeleton, TextField, Typography } from "@mui/material";
import copy from "fast-copy";
import React from "react";
import { InternatlServerError, SelectBox } from "../../components";
import { ReactSelectOption, SelectBoxOption } from "../../interfaces";
import { useGetUoMDropDownQuery } from "../../redux/services";

export interface NounDataInterface { data: any[], isLoading: boolean, isError: boolean, isSuccess: boolean, isFetching: boolean }

export const NounListing: React.FC<{ value: any, onChange: any, showAll: boolean, nounData: NounDataInterface, region: SelectBoxOption, setRegion: any }> = ({ value, onChange, showAll = false, nounData, region, setRegion }) => {

    const { data, isLoading, isError, isSuccess, isFetching } = nounData;
    const uomData = useGetUoMDropDownQuery({});

    const [search, setSearch] = React.useState("");
    const [showSearch, setShowSearch] = React.useState(false);

    const giveMeNouns = () => {
        let newData = copy(data);
        let rows: any[] = [];

        if (region && region.value && region?.value?.toString() !== 'all') {
            newData = newData?.filter((_: any) => _?.uom_id === region?.value);
        }

        if (search.length) {
            rows = [...rows, ...newData?.filter((_: any) => _?.noun_name?.toLowerCase()?.includes(search.toLowerCase())) ?? []];
        } else {
            rows = [...rows, ...newData];
        }
        return rows;
    }

    const toogleShowSearch = () => { setShowSearch(!showSearch) };

    React.useEffect(() => {
        if (isSuccess && !isLoading && !isError && !value) {
            if (region?.value) {
                onChange(data?.filter((_: any) => _.uom_id === region.value)?.[0] ?? {});
            } else {
                onChange(data?.[0] ?? {});
            }
        }
        // eslint-disable-next-line
    }, [isSuccess, isFetching, value]);

    if (isLoading) {
        return <>
            {Array(10).map((_: number, key: number) => {
                return <Skeleton variant="rectangular" />
            })}
        </>
    }

    if (isError && !isLoading) {
        return <InternatlServerError message="Opps!, Unable to fetch nouns" />
    }

    const nouns = giveMeNouns();

    return <Box sx={{ border: (theme) => `1px solid ${theme.palette.divider}`, marginRight: { xs: 0, sm: 1 }, borderRadius: 1 }}>
        {/* Heading */}
        <Box padding={1} display={"flex"} alignItems={"center"} justifyContent={"space-between"}>
            <Typography variant="subtitle2" sx={{ fontFamily: "htrts_bold" }}>{`Nouns (${nouns?.length ?? 0})`}</Typography>
            <IconButton size="small" onClick={toogleShowSearch}>
                <Search fontSize="small" htmlColor="black" />
            </IconButton>
        </Box>

        <Box px={1} paddingBottom={1}>
            <SelectBox
                loading={uomData.isLoading || uomData.isFetching}
                options={uomData.data as unknown as ReactSelectOption[]}
                label={"Region"}
                value={region}
                onChange={(data: any) => setRegion(data)}
            />
        </Box>

        {/* Search Bar */}
        {showSearch && <TextField
            sx={{ paddingX: 1, paddingBottom: 1 }}
            variant="standard"
            placeholder="Search noun"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            size={"small"}
            fullWidth
        />}

        <List component="nav" dense sx={{ height: "calc(100vh - 230px)", overflow: "scroll" }}>
            {nouns.map((noun: any, key: number) => {
                return <ListItem
                    id={`noun-${key}`}
                    key={key}
                    dense
                    divider
                    button
                    selected={value?._id === noun?._id}
                    onClick={(event) => onChange(noun)}
                >
                    <ListItemText
                        primary={noun?.uom_name ? `${noun.noun_name} (${noun?.uom_name})` : noun?.noun_name}
                        secondary={noun.group_name}
                    />
                </ListItem>
            })}
        </List>
    </Box>;
}