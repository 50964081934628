// AppDialog is used to show the dialog component all over the app whenever it needed with the use of dialog actions created in /redux/slices/dialog.js
import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Theme,
  useTheme,
  Box,
} from "@mui/material";
import { closeDialog } from "./redux/slices/dialog";
import { useAppDispatch, useAppSelector } from "./redux";
import Paper, { PaperProps } from "@mui/material/Paper";
import Draggable from "react-draggable";
import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const PaperComponent = (props: PaperProps) => {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
};

const useStyles = (theme: Theme) => ({
  dialog: {
    "& .MuiDialogActions-root": {
      [theme.breakpoints.down("sm")]: {
        flexDirection: "column",
        "& .MuiButtonBase-root": {
          width: "80%",
        },
      },
    },
  },
  title: {
    textAlign: "center",
  },
  dialogActions: {
    padding: "0px 24px 16px 24px",
  },
});

export const AppDialog: React.FC<{ children: JSX.Element }> = ({
  children,
}) => {
  const classes = useStyles(useTheme());
  const dispatch = useAppDispatch();

  const {
    open,
    title,
    body,
    hideNegativeBtn,
    hidePositiveBtn,
    negativeBtn,
    positiveBtn,
    onOk,
    maxWidth,
    onClose = () => { },
    hideCloseIcon,
    enablePadding,
    contentStyle = {},
    closeIconMarginRight,
    closeIconMarginTop
  } = useAppSelector((state) => state.dialog);

  const cancelDialog = (e: any) => {
    if (e.code === "Escape") {
      onClose();
      dispatch(closeDialog());
    }
  };

  const handleClose = () => {
    onClose();
    dispatch(closeDialog());
  };

  return (
    <>
      {children}
      <Dialog
        open={open}
        maxWidth={maxWidth}
        fullWidth={true}
        sx={{ paper: classes.dialog }}
        PaperComponent={PaperComponent}
        onClose={cancelDialog}
      >
        <Box>
          {/* Dialog Title */}
          {title && <DialogTitle sx={classes.title}>{title}</DialogTitle>}
          {!hideCloseIcon && (
            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={{
                backgroundColor: "#fff",
                border: "none",
                borderRadius: "5px",
                cursor: "pointer",
                outline: "none",
                transition: "box-shadow 0.3s ease-in-out",
                ":hover, :focus": {
                  boxShadow: "0px 0px 5px 2px rgba(103, 58, 183, 0.5)",
                },
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
                width: "25px",
                height: "25px",
                marginTop: closeIconMarginTop,
                marginRight: closeIconMarginRight,
              }}
            >
              <CloseIcon />
            </IconButton>
          )}
        </Box>
        {/* Dialog Content */}
        {body && enablePadding ? <DialogContent sx={contentStyle}>
            {body}
        </DialogContent> : body}

        {/* Ḍialog Action Button */}
        {(!Boolean(hideNegativeBtn) || !Boolean(hidePositiveBtn)) && (
          <DialogActions sx={classes.dialogActions}>
            {!Boolean(hideNegativeBtn) && (
              <Button onClick={cancelDialog} color="primary" variant="outlined">
                {negativeBtn}
              </Button>
            )}
            {!Boolean(hidePositiveBtn) && (
              <Button onClick={onOk} color="primary" variant="contained">
                {positiveBtn}
              </Button>
            )}
          </DialogActions>
        )}
      </Dialog>
    </>
  );
};
