import { Box, Chip, Grid, Stack, Theme, Typography, useTheme } from '@mui/material';
import copy from 'fast-copy';
import { useSnackbar } from 'notistack';
import React from 'react';
import { withNavBars } from '../../HOCs';
import { AttributeInterface, AttributeOptionInterface, DescriptionInterface, SelectBoxOption } from '../../interfaces';
import { useAppDispatch } from '../../redux';
import { useGetUoMQuery, useLazyGetAttributesByIdsQuery, useLazyGetNounVariantQuery } from '../../redux/services';
import { updateBOMSliceByKeyValue } from '../../redux/slices/bom';
import { AttributeDetail, updateMatSliceByKeyValue } from '../../redux/slices/material';
import { LocalStorageKeys } from '../../utils';
import { Attributes } from './attributes';
import { Footer } from './footer';
import { NounListing } from './nounListing';
import { NounVariantListing } from './nounVariantListing';
import { SearchBarSection } from './searchBar';

const useSx = (theme: Theme) => ({
    paper: {
        padding: 1, height: 'calc(100% - 2px)', boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
        background: theme.palette.background.paper, borderRadius: 2, overflow: 'scroll', position: 'relative'
    }
});

interface AttribteStateInterface { [key: string]: { option: AttributeOptionInterface, attr: AttributeInterface } }

export type For = "new_material" | "new_bom" | null;

export const MDCreate: React.FC<{ children?: JSX.Element, for?: For, onClose?: (generated: string) => void; onApply?: () => void; }> = (props) => {

    const sx = useSx(useTheme());
    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useAppDispatch();

    const [getVariantsByNounId, variantD] = useLazyGetNounVariantQuery();
    const variantData = { ...variantD, data: variantD?.data?.data ?? [], currentData: variantD?.data?.data ?? [], totalCount: variantD?.data?.totalCount ?? 0 };

    const [getAttributesData, attributeData] = useLazyGetAttributesByIdsQuery();
    const [getSecAttributesData, secAttributeData] = useLazyGetAttributesByIdsQuery();
    const uomD = useGetUoMQuery({ page: null, pageSize: null });
    const uomData = { ...uomD, data: uomD?.data?.data ?? [], currentData: uomD?.data?.data ?? [] };

    const [helpers, setHelpers] = React.useState<{ nounVariants: any, priAttribute: any }>({ nounVariants: {}, priAttribute: [] })
    const [UOM, setUOM] = React.useState<SelectBoxOption | null>(null);
    const [noun, setNoun] = React.useState<SelectBoxOption | null>(null);
    const [showAttr, setShowAttr] = React.useState(false);
    const [showSecAttr, setShowSecAttr] = React.useState(false);
    const [primaryAttrOption, setPrimaryAttrOption] = React.useState<any>([]);
    const [secondaryAttrOption, setSecondaryAttrOption] = React.useState<any>([]);
    const [primaryAttr, setPrimaryAttr] = React.useState<AttribteStateInterface>({});
    const [secondaryAttr, setSecondaryAttr] = React.useState<AttribteStateInterface>({});
    const [description, setDescription] = React.useState<DescriptionInterface[]>([]);
    const [shortDescription, setShortDescription] = React.useState<DescriptionInterface[]>([]);
    const [nounImage, setNounImage] = React.useState<any>(null);

    const isLoading = variantData.isFetching || variantData.isLoading || uomData.isLoading || uomData.isFetching || attributeData.isLoading || attributeData.isFetching || secAttributeData.isLoading || secAttributeData.isFetching;

    const onUOMChange = (uom: any) => { setUOM(uom) };

    const onNounSelected = (noun: any) => setNoun(noun);

    const preparePriAttrAndNounVariantImages = () => {
        let getAttributePayload: any = {};
        let nounVariantsKeyValuePair: any = {};
        variantData?.currentData?.forEach((variant: any) => {
            variant?.primary_attr?.forEach((attr: any) => {
                if (getAttributePayload?.[attr.attr_id]) {
                    if (!getAttributePayload[attr.attr_id]?.options?.includes(attr.option_id)) {
                        getAttributePayload[attr.attr_id]?.options.push(attr.option_id);
                        getAttributePayload[attr.attr_id]?.option_id.push(attr.option_id);
                    }
                    getAttributePayload[attr.attr_id]?.variants.push(variant._id);
                } else {
                    getAttributePayload[attr.attr_id] = { attribute_id: attr.attr_id, options: [attr.option_id], option_id: [attr.option_id], variants: [variant._id] }
                }
            });
            nounVariantsKeyValuePair[variant._id] = variant;
        });
        setHelpers({ ...helpers, nounVariants: nounVariantsKeyValuePair, priAttribute: getAttributePayload });
        getAttributesData(Object.values(getAttributePayload).map((_: any) => ({ attribute_id: _.attribute_id, option_id: _.option_id })));
        // dispatch(openBackdrop("Preparing Primary Attributes..."));
    };

    const onPrimaryAttrChanged = (attr: any, option: any) => {
        let newPrimaryAttr: AttribteStateInterface = primaryAttr;
        if (option) {
            newPrimaryAttr[attr._id] = { option, attr };
            setPrimaryAttr({ ...newPrimaryAttr });
        } else {
            delete newPrimaryAttr[attr._id];
            setPrimaryAttr({ ...newPrimaryAttr })
        }
        updatePrimaryAttriOptions(newPrimaryAttr);
    };

    const onSecondaryAttrChanged = (attr: any, option: any) => {
        setSecondaryAttr((prev) => ({ ...prev, [attr._id]: { option, attr } }));
    };

    const onNounImageClick = (data: any) => {
        if (attributeData.isFetching || attributeData.isLoading) {
            enqueueSnackbar("Please wait for Primary Attributes to load.", { variant: "warning" });
            return false;
        }
        setNounImage(data);
    };

    const onGenerateBtnClicked = () => {
        let canIGenerate = true;

        for (let i = 0; i < nounImage?.secondary_attr?.mandatory?.length; i++) {
            const attr: string = nounImage.secondary_attr.mandatory[i];
            if (!secondaryAttr?.[attr]?.option) {
                canIGenerate = false;
                break;
            }
        }

        if (!canIGenerate) {
            enqueueSnackbar('Please Select all the Mandatory (*) Secondary Attributes', { variant: "warning" });
            return false;
        }

        let description: DescriptionInterface[] = [{ label: noun?.noun_abbr ?? "", type: "Text", title: noun?.label ?? "" }];

        let shortDescription: DescriptionInterface[] = noun?.noun_abbr?.length <= 50 ? [...description] : [];

        let isStopShortDescriptionGen: boolean = shortDescription.length === 0;

        [...Object.values(primaryAttr), ...secondaryAttrOption?.map((_: any) => secondaryAttr[_._id])].forEach((pr: any) => {
            description.push({
                label: pr?.option?.option_abbr ?? "",
                type: pr?.option?.option_abbr === "Custom" ? "Input" : "Text",
                title: pr?.attr?.attr_name ?? ""
            });

            if (!isStopShortDescriptionGen) {

                shortDescription.push({
                    label: pr?.option?.option_abbr ?? "",
                    type: pr?.option?.option_abbr === "Custom" ? "Input" : "Text",
                    title: pr?.attr?.attr_name ?? ""
                });

                if (shortDescription.map((_: any) => _.label).join(", ").length > 50) {
                    shortDescription.pop();
                    isStopShortDescriptionGen = true;
                }
            }
        })

        setDescription(description);
        setShortDescription(shortDescription);

        if (!props.for) {
            enqueueSnackbar('Description Generated Successfully', { variant: "success" });
        }

        return canIGenerate;
    };

    const updatePrimaryAttriOptions = (newPrimaryAttr: AttribteStateInterface) => {
        let selectedPriAttr = Object.keys(newPrimaryAttr);
        let options: any = {};
        let newPriAttrOption = attributeData.data;
        newPriAttrOption?.forEach((_: any) => {
            if (selectedPriAttr.includes(_._id)) {
                options[_._id] = _;
            }
        });
        if (selectedPriAttr.length && attributeData.data.length !== selectedPriAttr.length) {
            selectedPriAttr.forEach((selPriAttr: any) => {
                let selectedVar = helpers.priAttribute?.[selPriAttr]?.variants ?? [];
                selectedVar.forEach((_var: any) => {
                    let canIUpdateOptions = false;
                    for (let index = 0; index < helpers?.nounVariants?.[_var]?.primary_attr?.length; index++) {
                        const priAttr = helpers?.nounVariants?.[_var]?.primary_attr?.[index];
                        if (newPrimaryAttr[priAttr.attr_id]?.option?.value === priAttr.option_id) {
                            canIUpdateOptions = true;
                            break;
                        }
                    };

                    if (canIUpdateOptions) {
                        helpers?.nounVariants?.[_var]?.primary_attr?.forEach((priAttr: any) => {
                            attributeData.data.forEach((attr: any) => {
                                if (attr._id === priAttr.attr_id && attr._id !== selPriAttr) {
                                    if (options[priAttr.attr_id]) {
                                        const optIDS = options[priAttr.attr_id].options.map((_: any) => _.option_id);
                                        attr.options.forEach((opt: any) => {
                                            if (!optIDS.includes(opt.option_id) && opt.option_id === priAttr.option_id) {
                                                options[priAttr.attr_id].options.push(opt);
                                            }
                                        })
                                    } else {
                                        options[priAttr.attr_id] = {
                                            ...attr,
                                            options: attr.options.filter((_: any) => _.option_id === priAttr.option_id)
                                        }
                                    }
                                }
                            });
                        });
                    }
                });
            });
            newPriAttrOption = Object.values(options);
        }
        if (newPriAttrOption?.length) {
            setPrimaryAttrOption(newPriAttrOption);
        }
        return newPriAttrOption;
    };

    const updateNounVariantImage = () => {
        let selectedPriAttr = Object.keys(primaryAttr);
        for (let k = 0; k < selectedPriAttr.length; k++) {
            let imageVariant = null;
            const selPriAttr: any = selectedPriAttr[k];

            let selectedVar = helpers.priAttribute?.[selPriAttr]?.variants ?? [];

            for (let a = 0; a < selectedVar.length; a++) {
                const _var: any = selectedVar[a];
                let isIamSelected = [];
                for (let i = 0; i < helpers?.nounVariants?.[_var]?.primary_attr?.length; i++) {
                    const priAttr = helpers?.nounVariants?.[_var]?.primary_attr?.[i];
                    if (primaryAttr[priAttr.attr_id]?.option?.value === priAttr.option_id) {
                        isIamSelected.push(priAttr);
                    }
                };

                if (selectedPriAttr.length === 1 && Object.values(helpers?.nounVariants?.[_var].primary_attr).length > 1) {
                    imageVariant = null;
                    break;
                }

                if (isIamSelected.length === selectedPriAttr.length) {
                    imageVariant = helpers?.nounVariants?.[_var];
                    break;
                }
            }

            if (imageVariant) {
                setNounImage(imageVariant);
                break;
            }
        }
    };

    const prepareSecondaryAttrData = () => {
        let payload: any = [];
        nounImage?.secondary_attr?.mandatory?.forEach((attr: any) => {
            payload.push({ attribute_id: attr });
        });
        nounImage?.secondary_attr?.optional?.forEach((attr: any) => {
            payload.push({ attribute_id: attr });
        });
        getSecAttributesData(payload);
    };

    const resetAll = (hideAttr: boolean = false) => {
        setPrimaryAttr({});
        setSecondaryAttr({});
        setDescription([]);
        setShortDescription([]);
        setNounImage(null);
        if (hideAttr) {
            setShowAttr(false);
        }
        updatePrimaryAttriOptions({});
    };

    const editDescription = () => { setDescription(description.map((_) => ({ ..._, type: "Input" }))) };

    const editShortDescription = () => { setShortDescription(shortDescription.map((_) => ({ ..._, type: "Input" }))) };

    const updateDesciption = (desc: DescriptionInterface[]) => { setDescription(desc) };

    const updateShortDesciption = (desc: DescriptionInterface[]) => { setShortDescription(desc) };

    const updateRecentNounSearches = () => {
        let existingRecentSearch: any = localStorage.getItem(LocalStorageKeys.nounRecentSearch);
        if (existingRecentSearch) {
            let recentSearches: any = JSON.parse(existingRecentSearch);
            delete recentSearches[noun?.value ?? ""]
            localStorage.setItem(LocalStorageKeys.nounRecentSearch, JSON.stringify(recentSearches));
        }
    };

    const updateNounVariantBasedOptions = () => {
        if (nounImage) {
            let newPriAttr: AttribteStateInterface = {};
            nounImage?.primary_attr?.forEach((_priAttr: any) => {
                attributeData.data.forEach((attr: any) => {
                    if (attr._id === _priAttr.attr_id) {
                        let option: AttributeOptionInterface = { value: "", label: "" };
                        for (let i = 0; i < attr?.options?.length; i++) {
                            const opt = attr.options[i];
                            if (opt.option_id === _priAttr.option_id) {
                                option = {
                                    value: opt.option_id,
                                    label: opt.option_name,
                                    ...opt
                                }
                                break;
                            }
                        }
                        newPriAttr[attr._id] = {
                            attr, option
                        }
                    }
                })
            })
            setPrimaryAttr(newPriAttr);

            // Updating the options
            let selectedPriAttr = Object.keys(newPriAttr);
            let options: any = {};
            let newPriAttrOption = attributeData.data;
            newPriAttrOption?.forEach((_: any) => {
                if (selectedPriAttr.includes(_._id)) {
                    _ = copy(_);
                    _.options = [newPriAttr[_._id].option];
                    options[_._id] = _;
                }
            });

            newPriAttrOption = Object.values(options);
            if (newPriAttrOption?.length) {
                setPrimaryAttrOption(newPriAttrOption);
            }

            prepareSecondaryAttrData();
        }
    };

    const goBack = () => {
        setPrimaryAttrOption([]);
        setNoun(null);
    }

    const onApply = () => {
        if (props.for && description.length > 0) {
            let newDesc: string[] = description.map((desc, key) => {
                return desc.label;
            });

            if (newDesc.length) {

                let newShortDesc: string[] = shortDescription.map((desc, key) => {
                    return desc.label;
                });

                let attribute_details: AttributeDetail[] = [];

                Object.values(primaryAttr)?.forEach((pr: any, index: number) => {
                    attribute_details.push({
                        attr_name: pr?.attr?.attr_name ?? "",
                        attr_type: 'Primary',
                        is_mandatory: pr?.attr?.isMandatory ?? false,
                        option_abbr: pr?.option?.option_abbr ?? "",
                        option_name: pr?.option?.option_name ?? "",
                        order: index,
                        reference_metadata: pr?.option?.reference_metadata ?? []
                    })
                })

                let primaryAttrLength = Object.values(primaryAttr).length;

                Object.values(secondaryAttr)?.forEach((sec: any, index: number) => {
                    const elem = description.find(d => d.title === sec?.attr?.attr_name);
                    attribute_details.push({
                        attr_name: sec?.attr?.attr_name ?? "",
                        attr_type: 'Secondary',
                        is_mandatory: sec?.attr?.isMandatory ?? false,
                        option_abbr: elem?.label ?? sec?.option?.option_abbr ?? "",
                        option_name: sec?.option?.option_name ?? "",
                        order: primaryAttrLength + index,
                        reference_metadata: sec?.option?.reference_metadata ?? []
                    })
                })

                if (props.for === 'new_material') {
                    dispatch(updateMatSliceByKeyValue({ key: "matDesc", value: newDesc.join(', ') }));
                    dispatch(updateMatSliceByKeyValue({ key: "matShortDesc", value: newShortDesc.join(', ') }));
                    dispatch(updateMatSliceByKeyValue({ key: "region", value: UOM }));
                    dispatch(updateMatSliceByKeyValue({ key: "materialGroup", value: { value: noun?.group_id, label: noun?.group_name } }));
                    dispatch(updateMatSliceByKeyValue({ key: "materialTag", value: noun }));
                    dispatch(updateMatSliceByKeyValue({ key: "varientId", value: nounImage?._id ?? null }));
                    dispatch(updateMatSliceByKeyValue({ key: "attribute_details", value: attribute_details }));
                }

                if (props.for === 'new_bom') {
                    dispatch(updateBOMSliceByKeyValue({ key: "bomDesc", value: newDesc.join(', ') }));
                    dispatch(updateBOMSliceByKeyValue({ key: "bomShortDesc", value: newShortDesc.join(', ') }));
                    dispatch(updateBOMSliceByKeyValue({ key: "region", value: UOM }));
                    dispatch(updateBOMSliceByKeyValue({ key: "bomGroup", value: { value: noun?.group_id, label: noun?.group_name } }));
                    dispatch(updateBOMSliceByKeyValue({ key: "bomTag", value: noun }));
                    dispatch(updateBOMSliceByKeyValue({ key: "varientId", value: nounImage?._id ?? null }));
                    dispatch(updateBOMSliceByKeyValue({ key: "attribute_details", value: attribute_details }));
                }
            }

            props.onApply && props.onApply();

            enqueueSnackbar('Description Generated Successfully', { variant: "success" });
        } else {
            enqueueSnackbar('Description is not yet Generated', { variant: "warning" });
        }
    };

    React.useEffect(() => {
        let value = noun?.value;
        if (!value) {
            setShowAttr(false);
            setShowSecAttr(false);
            setPrimaryAttrOption([]);
            setNoun(null);
        } else {
            resetAll(true);
            setShowAttr(true);
            getVariantsByNounId({ id: value, page: null, pageSize: null });

        }
        // eslint-disable-next-line
    }, [noun]);

    React.useEffect(() => {
        updateNounVariantBasedOptions();
        // eslint-disable-next-line
    }, [nounImage])

    React.useEffect(() => {
        setSecondaryAttr({});
        if ([1, 2].includes(Object.values(primaryAttr).length)) {
            updateNounVariantImage();
        } else {
            setNounImage(null);
            setShowSecAttr(false);
        }
        // eslint-disable-next-line
    }, [primaryAttr]);

    React.useEffect(() => {
        if (showSecAttr && secondaryAttrOption.length === 0) {
            setSecondaryAttrOption([])
        }
        // eslint-disable-next-line 
    }, [showSecAttr]);

    React.useEffect(() => {
        setNoun(null);
        // eslint-disable-next-line
    }, [UOM]);

    React.useEffect(() => {
        if (!variantData.isFetching && variantData?.currentData?.length > 0) {
            preparePriAttrAndNounVariantImages();
        } else if (!variantData.isFetching && variantData.isSuccess && variantData.currentData.length === 0) {
            updateRecentNounSearches();
            setPrimaryAttrOption([]);
        }
        // eslint-disable-next-line
    }, [variantD]);

    React.useEffect(() => {
        if (!attributeData.isFetching && attributeData.isSuccess && attributeData.currentData.length > 0) {
            setPrimaryAttrOption(attributeData.currentData);
        } else if (attributeData.isError) {
            enqueueSnackbar("Opps!, Something went wrong, Unable to fetch Primary Attributes", { variant: "error" })
        }
        // eslint-disable-next-line
    }, [attributeData.isFetching]);

    React.useEffect(() => {
        if (!secAttributeData.isFetching && secAttributeData.isSuccess) {
            if (secAttributeData.currentData.length > 0) {
                setSecondaryAttrOption(secAttributeData.currentData?.map((_: any) => ({ ..._, isMandatory: nounImage?.secondary_attr?.mandatory?.includes(_._id) })));
                setShowSecAttr(true);
            } else {
                setSecondaryAttrOption([]);
            }
        } else if (secAttributeData.isError) {
            enqueueSnackbar("Opps!, Something went wrong, Unable to fetch Secondary Attributes", { variant: "error" })
        }
        // eslint-disable-next-line
    }, [secAttributeData.isFetching]);

    React.useEffect(() => {
        setDescription([]);
        setShortDescription([]);
        // eslint-disable-next-line
    }, [secondaryAttr]);

    return <Box sx={{ height: '100%' }}>
        <Stack spacing={1} sx={{ height: '100%' }}>
            {/* Search Bar Section */}
            <SearchBarSection
                UOM={UOM}
                noun={noun}
                onUOMSelected={onUOMChange}
                onNounSelected={onNounSelected}
                goBack={goBack}
            />

            {/* Attribute Selection Section */}
            {showAttr ? <Grid container sx={{ height: 'calc(100% - 74px)' }}>
                {/* Primary & Secondary Attributes */}
                <Grid item xs={12} sm={4} md={3} lg={3} xl={3}>
                    <Box sx={sx.paper} >
                        <Stack spacing={1}>
                            <Attributes
                                id="primary-attr"
                                title={"Primary Attributes"}
                                options={primaryAttrOption}
                                value={primaryAttr}
                                onChange={onPrimaryAttrChanged}
                                loading={variantData.isFetching || attributeData.isFetching || attributeData.isLoading}
                            />

                            <Attributes
                                id="secondary-attr"
                                title={"Secondary Attributes"}
                                options={showSecAttr ? secondaryAttrOption : []}
                                value={secondaryAttr}
                                onChange={onSecondaryAttrChanged}
                                loading={variantData.isFetching || secAttributeData.isFetching || secAttributeData.isLoading}
                            />
                        </Stack>
                    </Box>
                </Grid>

                {/* Noun Images */}
                <Grid item sx={{ height: "100%", paddingLeft: { xs: 0, sm: 1, md: 1, lg: 1, xl: 1 }, paddingTop: { xs: 1, sm: 0 } }} xs={12} sm={8} md={9} lg={9} xl={9}>
                    <Box sx={sx.paper}>
                        {/* Noun Images */}
                        <Typography gutterBottom variant="body1" sx={{ paddingBottom: 1, fontFamily: "htrts_medium", borderBottom: (theme) => `1px solid ${theme.palette.divider}` }} >
                            {noun?.label} <Typography component={"span"} variant="body2">{"- Noun Variants"}</Typography>
                            <Chip sx={{ marginLeft: 1 }} color="primary" size={"small"} label={variantData?.currentData?.length ?? 0} />
                        </Typography>

                        {/* Selected Noun Image */}
                        {nounImage && <>
                            <Typography gutterBottom variant="body2" sx={{ padding: 1, background: (theme) => theme.palette.grey[200], borderRadius: 1, fontWeight: "bold", fontFamily: "htrts_regular" }} >
                                Selected Noun Variants
                            </Typography>
                            <NounVariantListing
                                selectedImage={nounImage}
                                variants={[nounImage]}
                                onImageClick={onNounImageClick}
                                loading={variantData.isFetching}
                                error={variantData.isError}
                                onRetry={preparePriAttrAndNounVariantImages}
                                height="auto"
                            />
                        </>}


                        {/*All Noun Images  */}
                        <Typography gutterBottom variant="body2" sx={{ padding: 1, background: (theme) => theme.palette.grey[200], borderRadius: 1, fontWeight: "bold", fontFamily: "htrts_regular" }} >
                            All Noun Variants
                        </Typography>
                        <NounVariantListing
                            selectedImage={nounImage}
                            variants={variantData.currentData}
                            onImageClick={onNounImageClick}
                            loading={variantData.isFetching}
                            error={variantData.isError}
                            onRetry={preparePriAttrAndNounVariantImages}
                        />

                        {/* Description Generate Footer */}
                        <Footer
                            resetAll={() => resetAll(false)}
                            onGenerateBtnClicked={onGenerateBtnClicked}
                            description={description}
                            editDescription={editDescription}
                            updateDescription={updateDesciption}
                            shortDescription={shortDescription}
                            editShortDescription={editShortDescription}
                            updateShortDescription={updateShortDesciption}
                            onApply={onApply}
                            for={props.for}
                            onClose={props.onClose}
                            isLoading={isLoading}
                        />
                    </Box>
                </Grid>
            </Grid> : <Grid container sx={{ height: 'calc(100% - 74px)' }}>
                {/* Noun Listing */}
                <NounListing onNounSelect={(noun: any) => {
                    onUOMChange(uomData?.data?.filter((_: any) => _._id === noun.uom_id)?.map((_: any) => ({ ..._, label: _.uom_name, value: _._id }))?.[0])
                    setTimeout(() => {
                        onNounSelected(noun);
                    }, 500);
                }} uom={UOM} />
            </Grid>}
        </Stack>
    </Box>
}

export default withNavBars(MDCreate);