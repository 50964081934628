import React from "react";
import { GridEditInputCell, GridEditInputCellProps, GridEditSingleSelectCell, GridEditSingleSelectCellProps } from "@mui/x-data-grid-premium";
import { SelectBoxOption } from "../../../interfaces";

export const typeOptions = [
    { label: "Material", value: "material" },
    { label: "BOM", value: "bom" },
    // createOption("Document")
]

// For Main Fields

export const commonSelect: React.FC<
    GridEditSingleSelectCellProps | GridEditInputCellProps
> = (params) => {
    return (
        <GridEditSingleSelectCell
            {...(params as GridEditSingleSelectCellProps)}
        />
    );
};

export const enableFieldBasedOnSelectedType: React.FC<{
    params: GridEditSingleSelectCellProps | GridEditInputCellProps,
    options: SelectBoxOption[],
    field: string | number,
    isDisabled: boolean
}> = ({ params, options, field, isDisabled }) => {
    if (isDisabled) {
        return (
            <GridEditInputCell
                {...(params as GridEditInputCellProps)}
                disabled={isDisabled}
                value={
                    options?.find(
                        (s: SelectBoxOption) => s?._id === field
                    )?.label ?? ""
                }
            />
        );
    }
    return (
        <GridEditSingleSelectCell
            {...(params as GridEditSingleSelectCellProps)}
            disabled={isDisabled}
        />
    );
};

export const enableInputBasedOnCondition: React.FC<GridEditInputCellProps> = (
    params, isDisabled
) => {
    return (
        <GridEditInputCell
            {...params}
            disabled={isDisabled}
        />
    );
};
