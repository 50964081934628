import {
  createApi,
  fetchBaseQuery,
  retry,
} from "@reduxjs/toolkit/dist/query/react";
import { config } from "../../../config";
import {
  CheckList,
  CheckListCategories,
} from "../../../screens/viewCheckList/viewCheckListDetail";
import {
  LocalStorageKeys,
  getTransformedDataForPagination,
  ConvertToReactSelect,
} from "../../../utils";

export type DtTemplateType = "checklist" | "datasheet" | "inputParameter";

export type DtInstanceType = "template" | "form";

export const constructRow = (category: CheckListCategories, row: any) => {
  let contentArray: any[] = [];
  category?.definition?.columns?.forEach((col) => {
    contentArray.push({ field: col.field, content: row[col.field] || "" });
  });
  return contentArray;
};

export const prepareDataTableBody = (payload: CheckList, type: DtInstanceType, isDuplicating?: boolean) => {
  if (type === "form" || isDuplicating) {
    return payload;
  }
  const body: any = {
    tables:
      payload?.categories?.map((category, index) => {
        return {
          rows: category?.definition?.rows?.map((row) => ({
            column_content: constructRow(category, row),
            id: row?.id,
          })) ?? [],
          columns: category?.definition?.columns?.map((col) => {
            return {
              ...col,
              header_class_name: col.headerClassName,
              header_name: col.headerName,
              editable: col.editable ? true : false
            };
          }),
          position: index,
          description: category.description,
          header_color: category.header_color,
          name: category.name,
        };
      }) ?? [],
    tags_info: payload?.tags_info?.map((tag) => {
      return {
        tag_group_id: tag.tag_group_id,
        tag_id: tag.value,
      };
    }),
    status: payload.status?.label ?? "",
    name: payload.name,
    links: [],
  };
  return body;
};

export const dataTableAPI = createApi({
  reducerPath: "dataTableAPI",
  baseQuery: retry(
    fetchBaseQuery({
      baseUrl: config.api_url + "tm/dtTemplate",
      prepareHeaders: (headers) => {
        const token = localStorage.getItem(LocalStorageKeys.authToken);

        // If we have a token set in state, let's assume that we should be passing it.
        if (token) {
          headers.set("authorization", `Bearer ${token}`);
        }

        return headers;
      },
    }),
    { maxRetries: 1 }
  ),
  tagTypes: ["DataTables", "DataTablesDD", "DataTable", "SearchDataTables"],
  endpoints: (builder) => ({
    // Get All Data Table API
    getAllDataTable: builder.query({
      query: ({
        dtTemplateType = "checklist",
        dtInstanceType = "template",
        dtTemplateId = null,
        page = 0,
        pageSize = 10,
        sort_by = null,
        sort_order = null
      }: {
        dtTemplateType: DtTemplateType;
        dtInstanceType: DtInstanceType;
        dtTemplateId?: string | null;
        page: number;
        pageSize: number;
        sort_by?: string | null;
        sort_order?: string | null;
      }) => ({
        url: `/${dtTemplateType}/${dtInstanceType}${page !== null ? `?page_no=${page + 1}` : ""
          }${pageSize ? `&page_size=${pageSize}` : ""}${dtTemplateId ? `dtTemplateId=${dtTemplateId}` : ""
          }${sort_by ? `&sort_by=${sort_by}` : ''}${sort_order ? `&sort_order=${sort_order}` : ''}`,
        method: "GET",
      }),
      transformResponse: (
        data: any,
        meta: { request: Request; response?: Response }
      ) => {
        return getTransformedDataForPagination(data, meta?.response);
      },
      providesTags: ["DataTables"]
    }),

    // Get All Data Table API
    getAllDataTableDDOptions: builder.query({
      query: ({
        dtTemplateType = "datasheet",
        dtInstanceType = "template",
        status = "Published"
      }: {
        dtTemplateType?: DtTemplateType;
        dtInstanceType?: DtInstanceType;
        status?: string;
      }) => ({
        url: `/${dtTemplateType}/${dtInstanceType}?status=${status}`,
        method: "GET",
      }),
      transformResponse: (response: any[], meta, arg) => ConvertToReactSelect(response, "_id", "sequence_id", ['name']),
      providesTags: ["DataTablesDD"]
    }),

    // Get Data Table by id API
    getDataTableById: builder.query({
      query: ({
        dtTemplateType = "checklist",
        dtInstanceType = "template",
        dtTemplateId = null,
      }: {
        dtTemplateType: DtTemplateType;
        dtInstanceType: DtInstanceType;
        dtTemplateId: string | null;
      }) => ({
        url: `/${dtTemplateType}/${dtInstanceType}/${dtTemplateId}`,
        method: "GET",
      }),
    }),

    // Create Data Table API
    createDataTable: builder.mutation({
      query: ({
        dtTemplateType = "checklist",
        dtInstanceType = "template",
        dtTemplateId = null,
        payload,
        isDuplicating = false
      }: {
        dtTemplateType: DtTemplateType;
        dtInstanceType: DtInstanceType;
        dtTemplateId: string | null;
        payload: CheckList;
        isDuplicating?: boolean;
      }) => ({
        url: `/${dtTemplateType}/${dtInstanceType}${dtTemplateId ? `?dtTemplateId=${dtTemplateId}` : ""
          }`,
        method: "POST",
        body: { ...prepareDataTableBody(payload, dtInstanceType, isDuplicating) },
      }),
      invalidatesTags: ["DataTables", "DataTablesDD", "SearchDataTables"]
    }),

    // Update Data Table API
    updateDataTable: builder.mutation({
      query: ({
        dtTemplateType = "checklist",
        dtInstanceType = "template",
        dtTemplateId = null,
        payload,
        publish = false,
        queryTemplateId = null,
      }: {
        dtTemplateType: DtTemplateType;
        dtInstanceType: DtInstanceType;
        dtTemplateId: string | null;
        payload: CheckList;
        publish?: boolean;
        queryTemplateId?: string | null
      }) => ({
        url: `/${dtTemplateType}/${dtInstanceType}/${dtTemplateId}${publish ? `?publish=${publish}` : ""}${queryTemplateId ? `?dtTemplateId=${queryTemplateId}` : ""}`,
        method: "PUT",
        body: { ...prepareDataTableBody(payload, dtInstanceType) },
      }),
      invalidatesTags: ["DataTables", "DataTablesDD", "SearchDataTables"]
    }),

    // Delete Data Table API
    deleteDataTable: builder.mutation({
      query: ({
        dtTemplateType = "checklist",
        dtInstanceType = "template",
        dtTemplateId = null,
      }: {
        dtTemplateType: DtTemplateType;
        dtInstanceType: DtInstanceType;
        dtTemplateId: string | null;
      }) => ({
        url: `/${dtTemplateType}/${dtInstanceType}/${dtTemplateId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["DataTables", "DataTablesDD", "SearchDataTables"]
    }),

    // Parse Document and create datasheet 
    parseFromDocument: builder.mutation({
      query: (payload: { document_id: string, datasheet_name: string, tags_info: { tag_id: string, tag_group_id: string }[], instruction?: string }) => ({
        url: "https://zzyjv4skw5c3rzzrl5daviblqm0axjck.lambda-url.us-east-1.on.aws/",
        method: "POST",
        body: payload,
      }),
    }),

    // Search Data Table API
    searchDataTable: builder.query({
      query: ({
        dtTemplateType = "checklist",
        dtInstanceType = "template",
        page = 0,
        pageSize = 10,
        sort_by = null,
        sort_order = null,
        search = "",
      }: {
        dtTemplateType: DtTemplateType;
        dtInstanceType: DtInstanceType;
        page: number;
        pageSize: number;
        sort_by?: string | null;
        sort_order?: string | null;
        search: string;
      }) => ({
        url: `/${dtTemplateType}/${dtInstanceType}/search?search=${search}${page !== null ? `&page_no=${page + 1}` : ""
          }${pageSize ? `&page_size=${pageSize}` : ""}${sort_by ? `&sort_by=${sort_by}` : ''}${sort_order ? `&sort_order=${sort_order}` : ''}`,
        method: "GET",
      }),
      transformResponse: (
        data: any,
        meta: { request: Request; response?: Response }
      ) => {
        return getTransformedDataForPagination(data, meta?.response);
      },
      providesTags: ["SearchDataTables"]
    }),
  }),
});

export const {
  useGetAllDataTableQuery,
  useLazyGetAllDataTableQuery,
  useLazyGetAllDataTableDDOptionsQuery,
  useGetDataTableByIdQuery,
  useLazyGetDataTableByIdQuery,
  useCreateDataTableMutation,
  useUpdateDataTableMutation,
  useDeleteDataTableMutation,
  useParseFromDocumentMutation,
  useLazySearchDataTableQuery
} = dataTableAPI;
