import moment from "moment";
import { config } from "../config";
import { AppRoutes } from "../router/routes";
import { LocalStorageKeys } from "./constants";

var timeoutID;

const DiffMins = (a, b) => {
  return moment(b).diff(moment(a)) / 1000 / 60;
};

export function setup(navigate) {
  window.addEventListener("mousemove", resetTimer, false);
  window.addEventListener("mousedown", resetTimer, false);
  window.addEventListener("keypress", resetTimer, false);
  window.addEventListener("DOMMouseScroll", resetTimer, false);
  window.addEventListener("mousewheel", resetTimer, false);
  window.addEventListener("touchmove", resetTimer, false);
  window.addEventListener("MSPointerMove", resetTimer, false);
  window.clearTimeout(timeoutID);
  window.addEventListener("offline", updateOnlineStatus);
  startTimer();
  timerId(navigate);
}

export function remove(navigate) {
  window.removeEventListener("mousemove", resetTimer, false);
  window.removeEventListener("mousedown", resetTimer, false);
  window.removeEventListener("keypress", resetTimer, false);
  window.removeEventListener("DOMMouseScroll", resetTimer, false);
  window.removeEventListener("mousewheel", resetTimer, false);
  window.removeEventListener("touchmove", resetTimer, false);
  window.removeEventListener("MSPointerMove", resetTimer, false);
  window.clearTimeout(timeoutID);
  window.removeEventListener("offline", updateOnlineStatus);
  navigate(AppRoutes.logout, {
    state: {
      isTimeout: true,
    },
  });
}

function updateOnlineStatus() {
  window.location.replace("/");
}
function startTimer() {
  localStorage.setItem(LocalStorageKeys.logoutTimer, new Date());
}

function resetTimer(e) {
  localStorage.setItem(LocalStorageKeys.logoutTimer, new Date());
  window.clearTimeout(timeoutID);
  goActive();
}

const timerId = (navigate) => {
  setInterval(() => goInactive(navigate), 500);
};

async function goInactive(navigate) {
  let getSessionTime = localStorage.getItem(LocalStorageKeys.logoutTimer);
  let currentTime = new Date();
  let diffMin = DiffMins(getSessionTime, currentTime);
  //   auto logout after 1 hour
  if (diffMin >= Number(config.auto_logout_time)) {
    var arr = ["/login", "/logout"];
    if (
      !arr.includes(window.location.pathname) &&
      localStorage.getItem("auth_token") !== undefined
    ) {
      remove(navigate);
    }
  }
}

function goActive() {
  // start timer
  startTimer();
}
