import {
  IconButton,
  Skeleton,
  Stack,
  Tooltip,
  Typography,
  Chip,
} from "@mui/material";
import { ContentCopy, Delete, Edit } from "@mui/icons-material";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid-premium";
import React from "react";
import { type FormatConfig, useGetSNNounsQuery } from "../../../redux/services";
import moment from "moment";

export const MasterValue = {
  category: "CATEGORY",
  group: "GROUP",
  noun: "NOUN",
  attribute: "ATTRIBUTE",
  template: "TEMPLATE",
};

export const MasterPath = {
  category: "sn_category",
  group: "sn_group",
  noun: "sn_noun",
  attribute: "sn_attribute",
  template: "sn_template",
};

interface Column {
  edit: any;
  deleteRow: any;
  duplicate?: any;
  hasAccess: boolean;
}

export interface SNMastersInterface {
  path?: string;
  is_active: boolean;
  value: string;
  label: string;
  previlages: string[];
  columnVisibilityModel?: any;
  column: (props: Column) => GridColDef[];
}

export const allMasters: SNMastersInterface[] = [
  {
    value: MasterValue.category,
    label: "SN Category",
    path: MasterPath.category,
    previlages: ["C", "R", "U", "D"],
    is_active: true,
    column: ({ edit, deleteRow, hasAccess }) => {
      let columns: GridColDef[] = [
        // { field: 'id', headerName: 'Id', width: 90 },
        {
          field: "category_name",
          headerName: "Categories",
          width: 250,
          renderCell: (params: GridRenderCellParams) => {
            if (params.rowNode.type === "group") {
              return params.value;
            }
            return (
              <Typography
                variant="body2"
                sx={{
                  width: "100%",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {params?.row?.category_name}
              </Typography>
            );
          },
        },
        {
          field: "category_desc",
          headerName: "Description",
          width: 400,
          renderCell: (params: GridRenderCellParams) => {
            if (params.rowNode.type === "group") {
              return params.value;
            }
            return (
              <Tooltip title={params?.row?.category_desc}>
                <Typography
                  variant="body2"
                  sx={{
                    width: "100%",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  {params?.row?.category_desc}
                </Typography>
              </Tooltip>
            );
          },
        },
      ];

      if (hasAccess) {
        columns = [{
          field: "actions",
          headerName: "Edit",
          width: 80,
          sortable: false,
          disableColumnMenu: true,
          renderCell: (params: GridRenderCellParams) => {
            return (
              <IconButton onClick={() => edit(params.row)}>
                {" "}
                <Edit fontSize="small" color={"primary"} />
              </IconButton>
            );
          },
        }, ...columns, {
          field: "delete",
          headerName: "Delete",
          width: 80,
          sortable: false,
          disableColumnMenu: true,
          renderCell: (params: GridRenderCellParams) => {
            return (
              <IconButton
                onClick={() =>
                  deleteRow({ ...params?.row, label: params?.row?.category_name })
                }
              >
                {" "}
                <Delete fontSize="small" color={"error"} />
              </IconButton>
            );
          },
        },]
      }

      return columns;
    },
  },
  {
    value: MasterValue.group,
    label: "SN Group",
    path: MasterPath.group,
    previlages: ["C", "R", "U", "D"],
    is_active: true,
    column: ({ edit, deleteRow, hasAccess }) => {
      let columns: GridColDef[] = [
        // { field: 'id', headerName: 'Id', width: 90 },
        { field: "group_name", headerName: "Group", width: 250 },
        {
          field: "group_desc",
          headerName: "Description",
          width: 400,
          renderCell: (params: GridRenderCellParams) => {
            return (
              <Tooltip title={params?.row?.group_desc}>
                <Typography
                  variant="body2"
                  sx={{
                    width: "100%",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  {params?.row?.group_desc}
                </Typography>
              </Tooltip>
            );
          },
        }
      ];

      if (hasAccess) {
        columns = [{
          field: "actions",
          headerName: "Edit",
          width: 80,
          sortable: false,
          disableColumnMenu: true,
          renderCell: (params: GridRenderCellParams) => {
            return (
              <IconButton onClick={() => edit(params.row)}>
                {" "}
                <Edit fontSize="small" color={"primary"} />
              </IconButton>
            );
          },
        }, ...columns, {
          field: "delete",
          headerName: "Delete",
          width: 80,
          sortable: false,
          disableColumnMenu: true,
          renderCell: (params: GridRenderCellParams) => {
            return (
              <IconButton
                onClick={() =>
                  deleteRow({ ...params?.row, label: params?.row?.group_name })
                }
              >
                {" "}
                <Delete fontSize="small" color={"error"} />
              </IconButton>
            );
          },
        },]
      }

      return columns;
    },
  },
  {
    value: MasterValue.noun,
    label: "SN Noun",
    path: MasterPath.noun,
    previlages: ["C", "R", "U", "D"],
    is_active: true,
    columnVisibilityModel: {
      // Hide columns by default here
      noun_desc: false,
    },
    column: ({ edit, deleteRow, hasAccess }) => {
      let columns: GridColDef[] = [
        // { field: 'id', headerName: 'Id', width: 90 },
        { field: "noun_name", headerName: "Noun Name", width: 250 },
        {
          field: "category_name",
          headerName: "Category",
          headerClassName: "no-sorting",
          width: 350,
        },
        { field: "group_name", headerName: "Group Name", width: 250 },
        {
          field: "noun_desc",
          headerName: "Description",
          width: 500,
          renderCell: (params: GridRenderCellParams) => {
            return (
              <Tooltip title={params.row.noun_desc}>
                <Typography
                  variant="body2"
                  sx={{
                    width: "100%",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  {params?.row?.noun_desc}
                </Typography>
              </Tooltip>
            );
          },
        },
        // { field: 'attribute', headerName: 'Attribute', width: 250 },
      ];

      if (hasAccess) {
        columns = [{
          field: "actions",
          headerName: "Edit",
          width: 80,
          sortable: false,
          disableColumnMenu: true,
          renderCell: (params: GridRenderCellParams) => {
            return (
              <IconButton onClick={() => edit(params.row)}>
                {" "}
                <Edit fontSize="small" color={"primary"} />
              </IconButton>
            );
          },
        }, ...columns, {
          field: "delete",
          headerName: "Delete",
          width: 80,
          sortable: false,
          disableColumnMenu: true,
          renderCell: (params: GridRenderCellParams) => {
            return (
              <IconButton
                onClick={() =>
                  deleteRow({ ...params?.row, label: params?.row?.noun_name })
                }
              >
                {" "}
                <Delete fontSize="small" color={"error"} />
              </IconButton>
            );
          },
        },]
      }

      return columns;
    },
  },
  {
    value: MasterValue.attribute,
    label: "SN Attribute",
    path: MasterPath.attribute,
    previlages: ["C", "R", "U", "D"],
    is_active: true,
    columnVisibilityModel: {
      // Hide columns by default here
      options: false,
    },
    column: ({ edit, deleteRow, duplicate, hasAccess }) => {
      let columns: GridColDef[] = [
        // { field: 'id', headerName: 'Id', width: 90 },
        { field: "attr_name", headerName: "Attribute Name", width: 250 },
        {
          field: "options",
          headerName: "Option (Abbreviation)",
          width: 350,
          renderCell: (params: GridRenderCellParams) => (
            <Stack
              spacing={1}
              direction={"row"}
              width="100%"
              overflow={"scroll"}
              id={"nounsChip"}
            >
              {params?.row?.options?.map((opt: any, key: number) => (
                <Chip
                  variant="outlined"
                  size={"small"}
                  label={`${opt.option_name} (${opt.option_abbr})`}
                />
              ))}
            </Stack>
          ),
        },
        {
          field: "nouns",
          headerName: "Associated Nouns",
          width: 400,
          renderCell: (params: GridRenderCellParams) => (
            <NounChipByNounIds nouns={params?.row?.nouns} />
          ),
        }
      ];

      if (hasAccess) {
        columns = [{
          field: "actions",
          headerName: "Edit",
          resizable: true,
          width: 40,
          disableColumnMenu: true,
          sortable: false,
          renderCell: (params: GridRenderCellParams) => {
            return (
              <IconButton onClick={() => edit(params?.row)}>
                {" "}
                <Edit fontSize="small" color={"primary"} />
              </IconButton>
            );
          },
        },
        {
          field: "duplicate",
          headerName: "Duplicate",
          resizable: true,
          width: 90,
          disableColumnMenu: true,
          sortable: false,
          renderCell: (params: GridRenderCellParams) => {
            return (
              <Tooltip title="Make a Duplicate">
                <IconButton
                  sx={{ margin: "auto" }}
                  onClick={() =>
                    duplicate({ ...params?.row, label: params?.row?.attr_name })
                  }
                >
                  <ContentCopy fontSize="small" />
                </IconButton>
              </Tooltip>
            );
          },
        }, ...columns, {
          field: "delete",
          headerName: "Delete",
          width: 80,
          sortable: false,
          disableColumnMenu: true,
          renderCell: (params: GridRenderCellParams) => {
            return (
              <IconButton
                onClick={() =>
                  deleteRow({ ...params?.row, label: params?.row?.attr_name })
                }
              >
                {" "}
                <Delete fontSize="small" color={"error"} />
              </IconButton>
            );
          },
        }]
      }

      return columns;
    },
  },
  {
    value: MasterValue.template,
    label: "SN Template",
    path: MasterPath.template,
    previlages: ["C", "R", "U", "D"],
    is_active: true,
    column: ({ edit, deleteRow, hasAccess }) => {
      let columns: GridColDef[] = [
        {
          field: "sequence_id",
          headerName: "SN ID",
          width: 150,
          renderCell: (params: GridRenderCellParams) => {
            return (
              <Typography
                sx={hasAccess ? {
                  color: "blue",
                  textDecoration: "underline",
                  cursor: "pointer",
                } : { textDecoration: "none" }}
                color="primary"
                variant="body2"
                onClick={() => edit(params?.row, true)}
              >
                {params?.row?.sequence_id}
              </Typography>
            );
          },
        },

        { field: "version", headerName: "Revision", width: 100 },
        {
          field: "name",
          headerName: "Name",
          width: 300,
        },
        {
          field: "format_config",
          headerName: "Sequence Format",
          width: 150,
          renderCell: (params: GridRenderCellParams) => {
            return (
              <Typography variant="body2">
                {params?.row?.format_config
                  ?.map((f: FormatConfig) => f?.value)
                  .join("")}
              </Typography>
            );
          },
        },
        { field: "category_name", headerName: "SN Category", width: 180 },
        { field: "group_name", headerName: "SN Group", width: 150 },
        { field: "noun_name", headerName: "SN Noun", width: 150 },
        { field: "created_user", headerName: "Uploaded By", width: 150 },
        {
          field: "created_time",
          headerName: "Uploaded Date",
          width: 250,
          renderCell: (params: GridRenderCellParams) => {
            return (
              <Typography variant="body2">
                {moment(params.row.created_time).format("lll")}
              </Typography>
            );
          },
        },
        { field: "modified_user", headerName: "Last modified by", width: 150 },
        {
          field: "modified_time",
          headerName: "Last modified date",
          width: 250,
          renderCell: (params: GridRenderCellParams) => {
            if (params.rowNode.type === "group") {
              return params.value;
            }
            return (
              <Typography variant="body2">
                {params.row.modified_time
                  ? moment(params.row.modified_time).format("lll")
                  : ""}
              </Typography>
            );
          },
        }
      ];

      if (hasAccess) {
        columns = [...columns, {
          field: "delete",
          headerName: "Delete",
          width: 80,
          sortable: false,
          disableColumnMenu: true,
          renderCell: (params: GridRenderCellParams) => {
            return (
              <IconButton
                onClick={() =>
                  deleteRow({
                    ...params?.row,
                    label: params?.row?.name,
                  })
                }
              >
                {" "}
                <Delete fontSize="small" color={"error"} />
              </IconButton>
            );
          },
        }]
      }

      return columns;
    },
  },
];

const NounChipByNounIds: React.FC<{ nouns: string[] }> = ({ nouns }) => {
  const { data: allData = { data: [] }, isLoading, isError } = useGetSNNounsQuery(
    { page: null, pageSize: null },
    {
      refetchOnMountOrArgChange: true,
    }
  );

  if (isLoading) {
    return (
      <Stack direction={"row"}>
        {[1, 2, 3].map((_: number) => (
          <Skeleton key={_} sx={{ width: "100px" }} />
        ))}
      </Stack>
    );
  }

  if (isError) {
    return (
      <Typography variant={"caption"} color={"error"}>
        Oops! Unable to get associated nouns.
      </Typography>
    );
  }

  let selectedNoun: any[] = allData?.data?.filter((_: any) => nouns?.includes(_._id));

  return (
    <Stack
      spacing={1}
      direction={"row"}
      width="100%"
      overflow={"scroll"}
      id={"nounsChip"}
    >
      {selectedNoun?.map((noun: any, key: number) => {
        return <Chip key={key} size="small" label={noun.noun_name} />;
      })}
    </Stack>
  );
};
