import { Alert, Box, Grid, Stack, Theme, Typography, useTheme } from "@mui/material";
import moment from "moment";
import { useSnackbar } from "notistack";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { CustomDataGrid, LoadingSection } from "../../components";
import { useLazyGetBOMCostByIdQuery } from "../../redux/services";
import { BOMCostSplitUpTree } from "./utils";

const useStyles = (theme: Theme) => ({
    root: {
        flexGrow: 1,
        background: theme.palette.background.paper,
        boxShadow: `rgba(99, 99, 99, 0.2) 0px 2px 8px 0px`,
        height: "100%",
        overflow: "auto",
        borderRadius: theme.spacing(1),
        padding: theme.spacing(2),
    },
});

export const ViewCostBreakup = ({ setSequenceId = (sequenceId) => false }: { sequenceId?: string, setSequenceId?: (sequenceId: string) => any }) => {

    const classes = useStyles(useTheme());
    const { bom_id } = useParams();
    const [getBOMCostbreakup, { data, isError, isFetching, isLoading, isSuccess }] = useLazyGetBOMCostByIdQuery();
    const { enqueueSnackbar } = useSnackbar();

    const [calculatedRows, setCalculatedRows] = React.useState<any[]>([]);
    const [bomCostingDetails, updateBOMCostingDetails] = React.useState([
        { title: "Cost Breakup for:", label: "-" },
        { title: "BOM Cost:", label: "-" },
        { title: "Calculated By:", label: "-" },
        { title: "Calculated At:", label: "-" },
    ]);


    const appendChild = (index: number, data: any, row: any[], level: number | string): any[] => {

        if (data?.bom_children_cost?.length === 0 && data?.material_children_cost?.length === 0) {
            return row;
        }

        data?.bom_children_cost?.forEach((bom_children: any, bom_children_index: number) => {

            let pathId = bom_children.sequence_id + "-r" + bom_children?.version;

            // Apending Level
            pathId = `${typeof level === "string" ? "" : "L"}${level}.${bom_children_index + 1} [${pathId}]`;

            let childLevel = `${typeof level === "string" ? "" : "L"}${level}.${bom_children_index + 1}`

            row.push({
                id: bom_children?.bom_id + bom_children_index + Math.random(),
                bom: [...row[index].bom, pathId],
                bomId: bom_children.sequence_id,
                short_description: bom_children?.short_description,
                total_assm_procs_cost: `${bom_children?.total_assm_procs_cost?.cost ?? "-"} ${bom_children?.total_assm_procs_cost?.currency ?? ""}`,
                total_child_bom_cost: `${bom_children?.total_child_bom_cost?.cost ?? "-"} ${bom_children?.total_child_bom_cost?.currency ?? ""}`,
                total_child_material_cost: `${bom_children?.total_child_material_cost?.cost ?? "-"} ${bom_children?.total_child_material_cost?.currency ?? ""}`,
                override_cost: `${bom_children?.override_cost ?? "-"}  ${bom_children?.bom_cost?.currency ?? ""}`,
                total_bom_cost: `${bom_children?.bom_cost?.cost ?? "-"} ${bom_children?.bom_cost?.currency ?? ""}`,
                spare_part_quantity: bom_children?.spare_part_quantity,
                normal_quantity: bom_children?.normal_quantity,
                total_cost: `${bom_children?.child_bom_cost?.cost ?? "-"} ${bom_children?.child_bom_cost?.currency ?? ""}`,
            });

            row.concat(appendChild(row.length - 1, bom_children, row, childLevel));
        });

        data?.material_children_cost?.forEach((bom_material: any, bom_material_index: number) => {

            let pathId = bom_material.sequence_id + "-r" + bom_material?.version;

            pathId = `${typeof level === "string" ? "" : "L"}${level}.${data?.bom_children_cost?.length + bom_material_index + 1} [${pathId}]`;

            row.push({
                id: bom_material?._id + bom_material_index + Math.random(),
                bom: [...row[index].bom, pathId],
                bomId: bom_material.sequence_id,
                short_description: bom_material?.short_description,
                material_cost: `${bom_material?.material_cost?.cost ?? "-"} ${bom_material?.material_cost?.currency ?? ""}`,
                override_cost: `${bom_material?.override_cost ?? "-"}  ${bom_material?.material_cost?.currency ?? ""}`,
                spare_part_quantity: bom_material?.spare_part_quantity ?? "-",
                normal_quantity: `${bom_material.normal_quantity ?? "-"}`,
                total_cost: `${bom_material.child_material_cost?.cost ?? "-"} ${bom_material.child_material_cost?.currency ?? ""}`
            });
        });

        return row;
    };

    const massageRows = React.useCallback((res: any) => {
        if (!res.bom_id) {
            setCalculatedRows([]);
        }

        let pathId = `L1 [${res.sequence_id}-r${res.version}]`;

        let row = [
            {
                id: res?.bom_id,
                bom: [pathId],
                bomId: res.sequence_id,
                short_description: res?.short_description,
                total_assm_procs_cost: `${res?.total_assm_procs_cost?.cost ?? "-"} ${res?.total_assm_procs_cost?.currency ?? ""}`,
                total_child_bom_cost: `${res?.total_child_bom_cost?.cost ?? "-"} ${res?.total_child_bom_cost?.currency ?? ""}`,
                total_child_material_cost: `${res?.total_child_material_cost?.cost ?? "-"} ${res?.total_child_material_cost?.currency ?? ""}`,
                total_bom_cost: `${res?.bom_cost?.cost ?? "-"} ${res?.bom_cost?.currency ?? ""}`,
                total_cost: `${res?.child_bom_cost?.cost ?? "-"} ${res?.child_bom_cost?.currency ?? ""}`,
                child_id: res?.child_id
            }
        ];

        setCalculatedRows(appendChild(0, res, row, 1));
        // eslint-disable-next-line
    }, [data]);

    const fetchCostBreakUp = async () => {
        await getBOMCostbreakup({ id: `${bom_id}` }).unwrap().then((res: any) => {
            if (res) {
                bomCostingDetails[0].label = `${res?.sequence_id ?? "-"}-r${res?.version ?? "0"}`;
                bomCostingDetails[1].label = `${res?.bom_cost?.cost ?? "-"} ${res?.bom_cost?.currency ?? ""}`;
                bomCostingDetails[2].label = res?.created_user ?? "";
                bomCostingDetails[3].label = moment(res?.created_time ?? "").format('lll').toString();
                setSequenceId(bomCostingDetails[0].label);
                updateBOMCostingDetails([...bomCostingDetails]);
                massageRows(res);
            } else {
                enqueueSnackbar('Something went wrong, Unable to Fetch Cost Breakup', { variant: "error" })
            }
        }).catch(err => {
            console.error('err:', err)
            enqueueSnackbar('Something went wrong, Unable to Fetch Cost Breakup', { variant: "error" })
        });
    }

    useEffect(() => {
        fetchCostBreakUp();
        // eslint-disable-next-line
    }, []);


    return <Box sx={classes.root}>
        {isLoading && <LoadingSection message="Fetching BOM Cost Breakup" />}

        {isError && <Alert severity="error">Something went wrong, Unable to fetch Cost Breakup</Alert>}

        {isSuccess && Object.values(data ? data : {}).length === 0 && <Alert severity="info">You are yet to calculate the cost for this BOM.</Alert>}

        {isSuccess && Object.values(data ? data : {}).length > 0 && <Grid container spacing={1} sx={{ width: "100%" }}>
            {bomCostingDetails.map(detail => (<Grid item xs={12} md={6}>
                <Stack direction={"row"}>
                    <Typography variant="body2">{detail.title}</Typography>&nbsp;
                    <Typography variant="body2"><b>{detail.label}</b></Typography>
                </Stack>
            </Grid>))}


            <Box pl={1} pt={1} sx={{ width: "100%" }}>
                <CustomDataGrid
                    id="cost-breakup-list"
                    treeData
                    showToolbar={true}
                    loading={isFetching || isLoading}
                    rows={calculatedRows}
                    columns={BOMCostSplitUpTree()}
                    getTreeDataPath={(row) => row.bom}
                    disableChildrenFiltering
                    getRowId={(row) => row?.id}
                    groupingColDef={{ headerName: "BOM Tree" }}
                    hideFooter={true}
                    pagination={false}
                    defaultGroupingExpansionDepth={1}
                />
            </Box>
        </Grid>}
    </Box>
}