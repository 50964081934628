import { AlignHorizontalLeft, Analytics, Architecture, Compress, DocumentScanner, FilePresent, Folder, GroupAdd, List, MenuBook, PrecisionManufacturingOutlined, Troubleshoot, ViewQuilt } from "@mui/icons-material";
import { AppRoutes } from "../../router/routes";

export const NavBars = (
    id: string,
    isEdit: boolean = false,
    loading: boolean = false,
    tags: { tag_id: string, tag_name: string }[] = [],
    tag_group_id: string,
    technicalParameterPath: string
) => ([
    {
        id: "1",
        title: "Basic Details",
        icon: <AlignHorizontalLeft fontSize="small" />,
        path: AppRoutes.viewProposalBasicDetail(id)
    },
    {
        id: "2",
        title: "Technical Parameters",
        icon: <Analytics fontSize="small" />,
        disabled: !isEdit,
        path: AppRoutes.viewProposalTechParamForm(id, technicalParameterPath)
    },
    {
        id: "3",
        title: "Documents",
        disabled: !isEdit,
        icon: <DocumentScanner fontSize="small" />,
        path: AppRoutes.viewProposalDocuments(id),
        loading,
        subNav: [
            {
                id: "3.1",
                title: "All",
                icon: <Folder htmlColor="#fde100" fontSize="small" />,
                path: `${AppRoutes.viewProposalDocuments(id)}?tags=all`
            },
            ...tags.map(_ => ({
                id: _.tag_id,
                title: _.tag_name,
                icon: <Folder htmlColor="#fde100" fontSize="small" />,
                path: `${AppRoutes.viewProposalDocuments(id)}?tag_id=${_.tag_id}&group_id=${tag_group_id}`
            }))
        ]
    },
    {
        id: "4",
        title: "IEDRAW",
        disabled: !isEdit,
        isBeta: true,
        icon: <Architecture fontSize="small" />,
        path: AppRoutes.viewProposalIEDraw(id),
    },
    {
        id: "5",
        title: "Material",
        disabled: !isEdit,
        icon: <FilePresent fontSize="small" />,
        path: AppRoutes.viewProposalMaterial(id) + "?tab=material"
    },
    {
        id: "5",
        title: "BOMs",
        disabled: !isEdit,
        icon: <ViewQuilt fontSize='small' />,
        path: AppRoutes.viewProposalBOM(id) + "?tab=bom",
    },
    {
        id: "7",
        title: "BOM Report",
        disabled: !isEdit,
        icon: <List fontSize='small' />,
        path: AppRoutes.viewBOMReportOnProposalModule(id) + "?tab=bomReport",
    },
    {
        id: "8",
        title: "List View",
        disabled: !isEdit,
        icon: <Compress fontSize='small' />,
        path: AppRoutes.viewListViewOnProposalModule(id) + "?tab=listView",
    },
    {
        id: "9",
        title: "Workbook",
        disabled: !isEdit,
        icon: <MenuBook fontSize='small' />,
        path: AppRoutes.workbookOnProposalModule(id) + "?tab=workbook",
    },
    {
        id:"10",
        title:"Issue Tracker",
        disabled: !isEdit,
        icon: <Troubleshoot fontSize="small" />,
        path: AppRoutes.viewIssueTrackerOnProposal(id) + "?tab=issueTracker"
    },
    {
        id:"11",
        title:"AI Search",
        disabled: !isEdit,
        icon: <PrecisionManufacturingOutlined fontSize="small" />,
        path: AppRoutes.viewProposalAISearch(id)
    },
    {
        id:"12",
        title:"Manage Users",
        disabled: !isEdit,
        icon: <GroupAdd fontSize="small" />,
        path: AppRoutes.manageUsersOnProposal(id)
    },
])
