import { TemplateErrorType } from "../../../redux/slices/documentTemplate";

export const initialErrorState = ():TemplateErrorType => ({
    logo: {
        widthError: false,
        heightError: false,
        xPositionError: false,
        yPositionError: false,
    },
    header: [
        { xPositionError: false, yPositionError: false, contentError: false },
        { xPositionError: false, yPositionError: false, contentError: false },
    ],
    footer: [
        { xPositionError: false, yPositionError: false, contentError: false },
        { xPositionError: false, yPositionError: false, contentError: false },
    ],
    cutsheet: {
        xPositionError: false,
        yPositionError: false,
        selectionError: false,
    },
    margin: {
        top_offset: false,
        bottom_offset: false,
        width_offset: false,
    },
    pagination: { xPositionError: false, yPositionError: false },
    left: { xPositionError: false, yPositionError: false, contentError: false },
    right: {
        xPositionError: false,
        yPositionError: false,
        contentError: false,
    }
});