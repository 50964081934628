import React from "react";
import { Box, Stack, Skeleton, Typography } from "@mui/material";
import { CardWithImage, InternatlServerError, NotFoundSection } from "../../components";
import { useLazyGetNounsQuery } from "../../redux/services";
import { groupBy } from "../../utils";
import copy from "fast-copy";

export const NounListing: React.FC<{
    onNounSelect: any,
    uom: any,
    selectedNoun?: string,
}> = ({ onNounSelect, uom, selectedNoun = "" }) => {

    const [refetch, { isFetching: isLoading, isError, isSuccess, data: allData = { data: [] } }] = useLazyGetNounsQuery({});

    const giveMeNouns = () => {
        if (isSuccess && allData?.data?.length) {
            let newData = copy(allData?.data);
            if (uom?.value) {
                newData = newData?.filter((_: any) => _.uom_id === uom.value);
            }
            return groupBy(newData?.sort((a: any, b: any) => a?.group_name?.localeCompare(b?.group_name)), 'group_id');
        }
        return {}
    }

    const nounsObj = giveMeNouns();

    // eslint-disable-next-line
    React.useEffect(() => { refetch({ page: null, pageSize: null }) }, []);

    return <Box p={1} height={"100%"} sx={{
        width: '100%',
        overflowY: "scroll",
        overflowX: "hidden",
        boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px',
        background: (theme) => theme.palette.background.paper,
        borderRadius: 2,
    }}>

        {/* Nouns Listing Title */}
        {/* <Typography gutterBottom variant="body2" sx={{ padding: 1, background: (theme) => theme.palette.grey[200], borderRadius: 1, fontWeight: "bold", fontFamily: "htrts_regular" }} >
            All Nouns
        </Typography> */}

        {/* Nouns Listing */}
        <Stack
            direction={"row"}
            flexWrap={"wrap"}
        >
            {isLoading && Array.from(Array(24).keys()).map((arr, key) => {
                return <Box key={key} height={"100px"} mb={1} mr={1} sx={{ width: { xs: "100%", sm: "100px" } }}>
                    <Skeleton variant="rectangular" width={"100%"} height={100} />
                </Box>
            })}

            {!isLoading && isError && <InternatlServerError message={'Opps! Something Went Wrong, Unable to fetch Nouns'} onRetry={() => refetch({ page: null, pageSize: null })} />}
            {!isLoading && !isError && allData?.data?.length === 0 && <NotFoundSection message='You are yet to create nouns.' />}
            {!isLoading && isSuccess && Object.keys(nounsObj).map((groupId: any, key: number) => {
                let nouns = nounsObj[groupId];
                return <Box key={key} width={"100%"}>
                    <Typography gutterBottom variant="body2" sx={{ padding: 1, background: (theme) => theme.palette.grey[200], borderRadius: 1, fontWeight: "bold", fontFamily: "htrts_regular" }} >
                        {nouns[0].group_name}
                    </Typography>

                    <Stack
                        direction={"row"}
                        flexWrap={"wrap"}
                        mt={1}
                    >
                        {nouns.sort((a: any, b: any) => a.noun_name.localeCompare(b.noun_name)).map((noun: any, key: string) => {
                            return <CardWithImage
                                id={`noun-${key}`}
                                key={key}
                                height={150}
                                data={{ ...noun, value: noun._id, label: noun.noun_name }}
                                onImageClick={onNounSelect}
                                image={noun.attachments?.[noun.attachments.length - 1]?.attachment_url}
                                title={noun?.noun_name ?? "noun " + key}
                                isSelected={selectedNoun === noun._id}
                            />
                        })}
                    </Stack>
                </Box>
            })}
        </Stack >
    </Box>
}