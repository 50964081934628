import React from 'react';
import { Grid, Box, useTheme, Theme, Collapse, Drawer } from '@mui/material';
import { LeftPanel } from './leftPanel';
import { RightPanel } from './rightPanel';
import { allMasters as getMasters, MasterValue } from './util';
import { withNavBars } from '../../HOCs';
import { MastersInterface, SelectBoxOption } from '../../interfaces';
import { useLazyGetUoMQuery, useLazyGetGroupsQuery, useLazyGetAttributesQuery, useLazyGetNounsQuery, useLazyGetNounVariantQuery } from '../../redux/services';
import { BreadScrumHeader } from '../../components';
import { HasAccess } from '../../router/authorization';
import { PERMISSIONS } from '../../router/permission';
import { useAppSelector } from '../../redux';
import { GridSortDirection, GridSortModel } from '@mui/x-data-grid-premium';

const useStyles = (theme: Theme) => ({
    root: {
        height: '100%',
        flexGrow: 1,
        padding: theme.spacing(1),
    },
    container: {
        height: 'calc(100% - 60px)',
        overflow: "scroll",
        marginTop: { xs: 0, sm: theme.spacing(2) }
    },
    rightPanel: (openWebNav: boolean = true) => ({
        paddingTop: { xs: theme.spacing(1), sm: 0 },
        height: { xs: "auto", sm: "100%" },
        paddingLeft: { xs: 0, sm: openWebNav ? theme.spacing(2) : 0 }
    })
})

export const initialPaginationState = {
    page: 0,
    pageSize: 10,
}

export const initialSortingState = {
    sortModel: [{ field: '', sort: '' as GridSortDirection }],
}

const MDUpdate: React.FC<{ children?: JSX.Element }> = (props) => {

    const classes = useStyles(useTheme());
    const { perm } = useAppSelector(store => store.auth.userDetails);

    const [openWebNav, setOpenWebNavBar] = React.useState(true);
    const [openMobileNav, setOpenMobileNavBar] = React.useState(false);

    // All Queries for masters
    const [getUoM, uomD] = useLazyGetUoMQuery();
    const uomData = { ...uomD, data: uomD?.data?.data ?? [], currentData: uomD?.data?.data ?? [], totalCount: uomD?.data?.totalCount ?? 0 };

    const [getGroup, groupD] = useLazyGetGroupsQuery();
    const groupData = { ...groupD, data: groupD?.data?.data ?? [], currentData: groupD?.data?.data ?? [], totalCount: groupD?.data?.totalCount ?? 0 };

    const [getNoun, nounD] = useLazyGetNounsQuery();
    const nounData = { ...nounD, data: nounD?.data?.data ?? [], currentData: nounD?.data?.data ?? [], totalCount: nounD?.data?.totalCount ?? 0 };

    const [getAttribute, attributeD] = useLazyGetAttributesQuery();
    const attributeData = { ...attributeD, data: attributeD?.data?.data ?? [], currentData: attributeD?.data?.data ?? [], totalCount: attributeD?.data?.totalCount ?? 0 };

    const [getNounVariant, nounVariantD] = useLazyGetNounVariantQuery();
    const nounVariantData = { ...nounVariantD, data: nounVariantD?.data?.data ?? [], currentData: nounVariantD?.data?.data ?? [], totalCount: nounVariantD?.data?.totalCount ?? 0 };

    const [paginationModel, setPaginationModel] = React.useState(initialPaginationState);
    const [queryOptions, setQueryOptions] = React.useState(initialSortingState);

    const creatable = React.useMemo(() => HasAccess(perm, PERMISSIONS.MATERIAL_REPO_EDIT), [perm]);
    const readable = React.useMemo(() => HasAccess(perm, PERMISSIONS.MATERIAL_REPO_READ), [perm]);
    const updatable = React.useMemo(() => HasAccess(perm, PERMISSIONS.MATERIAL_REPO_EDIT), [perm]);
    const deletable = React.useMemo(() => HasAccess(perm, PERMISSIONS.MATERIAL_REPO_EDIT), [perm]);

    const previlages = React.useMemo(() => {
        let rbac = [];
        if (creatable) rbac.push("C");
        if (readable) rbac.push("R");
        if (updatable) rbac.push("U");
        if (deletable) rbac.push("D");
        return rbac;
    }, [creatable, readable, updatable, deletable])

    const allMasters = React.useMemo(() => getMasters(previlages), [previlages]);

    const [state, setState] = React.useState<{ selectedMaster: MastersInterface, search: string, noun: any, region?: SelectBoxOption }>({ selectedMaster: allMasters?.[0], search: "", noun: null, region: { value: '', label: 'All' } });

    const changeState = (key: string, value: string) => { setState({ ...state, [key]: value }) };

    const handleSortModelChange = React.useCallback((sortModel: GridSortModel) => {
        // Here you save the data you need from the sort model
        setQueryOptions({ sortModel: [...sortModel] });
    }, [])

    const giveMeMasterReleatedFunctions = () => {
        switch (state.selectedMaster?.value) {
            case MasterValue.uom:
                return { getData: () => getUoM({ ...paginationModel, sort_by: queryOptions?.sortModel?.[0]?.field, sort_order: queryOptions?.sortModel?.[0]?.sort }), getDataProps: { ...uomData, paginationModel } }

            case MasterValue.group:
                return { getData: () => getGroup({ ...paginationModel, sort_by: queryOptions?.sortModel?.[0]?.field, sort_order: queryOptions?.sortModel?.[0]?.sort }), getDataProps: { ...groupData, paginationModel } }

            case MasterValue.noun:
                return { getData: () => getNoun({ ...paginationModel, sort_by: queryOptions?.sortModel?.[0]?.field, sort_order: queryOptions?.sortModel?.[0]?.sort, uom_id: (state.region?.value === 'all' || !state.region) ? '' : `${state.region?.value}` }), getDataProps: { ...nounData, uomData, paginationModel } }

            case MasterValue.attribute:
                return { getData: (payload: any) => getAttribute({ ...payload, ...paginationModel, sort_by: queryOptions?.sortModel?.[0]?.field, sort_order: queryOptions?.sortModel?.[0]?.sort }), getDataProps: { ...attributeData, uomData, paginationModel } }

            case MasterValue.nounVariant:
                return { getData: (payload: any) => getNounVariant({ ...payload, ...paginationModel, sort_by: queryOptions?.sortModel?.[0]?.field, sort_order: queryOptions?.sortModel?.[0]?.sort }), getDataProps: { ...nounVariantData, uomData, paginationModel } }

            default:
                return { getData: (data: any) => false, getDataProps: { paginationModel, totalCount: 0 } };
        }
    }

    const toogleWebNav = () => { setOpenWebNavBar(!openWebNav) };
    const toogleMobileNav = () => { setOpenMobileNavBar(!openMobileNav) };

    React.useEffect(() => {
        let isSearchWithNoun = state.selectedMaster.value === MasterValue.nounVariant || state.selectedMaster.value === MasterValue.attribute;

        if (isSearchWithNoun && state.noun) {
            giveMeMasterReleatedFunctions().getData({ id: state.noun?._id, ...paginationModel, sort_by: queryOptions?.sortModel?.[0]?.field, sort_order: queryOptions?.sortModel?.[0]?.sort });
        } else if (isSearchWithNoun) {
            giveMeMasterReleatedFunctions().getData({ id: null, ...paginationModel, sort_by: queryOptions?.sortModel?.[0]?.field, sort_order: queryOptions?.sortModel?.[0]?.sort });
        } else {
            giveMeMasterReleatedFunctions().getData({ ...paginationModel, sort_by: queryOptions?.sortModel?.[0]?.field, sort_order: queryOptions?.sortModel?.[0]?.sort });
        }
        // eslint-disable-next-line
    }, [state.selectedMaster, paginationModel, state.noun, state.region, JSON.stringify(queryOptions?.sortModel)]);

    React.useEffect(() => {
        setPaginationModel(initialPaginationState);
        setQueryOptions(initialSortingState);
        // eslint-disable-next-line
    }, [state.noun]);

    return <Box sx={classes.root}>

        {/* Header */}
        <BreadScrumHeader
            openWebNav={openWebNav}
            toogleWebNav={toogleWebNav}
            toogleMobileNav={toogleMobileNav}
            history={[
                { label: "Materials Module", onClick: () => false },
                { label: "Repository", onClick: () => { toogleWebNav() } }
            ]}
            currentPath={state.selectedMaster.label}
        />

        {/* Body */}
        <Grid
            container
            direction={"row"}
            sx={classes.container}
        >
            <Grid item xs={openWebNav ? 12 : 0} sm={openWebNav ? 3 : 0} md={openWebNav ? 2 : 0} sx={{ height: { xs: "auto", sm: "100%" }, display: { xs: "none", sm: "block" } }}>
                <Collapse in={openWebNav} timeout={1} orientation={"horizontal"} sx={{ height: { xs: "auto", sm: "100%" }, '.MuiCollapse-wrapperInner': { width: "100%" } }}>
                    <LeftPanel
                        selectedMaster={state.selectedMaster}
                        allMasters={allMasters}
                        onMasterClicked={(master: any) => {
                            changeState('selectedMaster', master);
                            setPaginationModel(initialPaginationState);
                            setQueryOptions(initialSortingState);
                        }}
                    />
                </Collapse>
            </Grid>

            <Grid item xs={12} sx={classes.rightPanel(openWebNav)} sm={!openWebNav ? 0 : 9} md={!openWebNav ? 0 : 10}>
                <RightPanel
                    {...giveMeMasterReleatedFunctions().getDataProps}
                    selectedMaster={state.selectedMaster}
                    getMaster={giveMeMasterReleatedFunctions().getData}
                    state={state}
                    setState={setState}
                    setPaginationModel={setPaginationModel}
                    handleSortModelChange={handleSortModelChange}
                />
            </Grid>
        </Grid>

        {/* Ṃobile Drawer */}
        <Drawer anchor="left" open={openMobileNav} onClose={toogleMobileNav}>
            <LeftPanel
                allMasters={allMasters}
                selectedMaster={state.selectedMaster}
                onMasterClicked={(master: any) => {
                    toogleMobileNav();
                    changeState('selectedMaster', master);
                    setPaginationModel(initialPaginationState);
                    setQueryOptions(initialSortingState);
                }}
            />
        </Drawer>
    </Box>
}

export default withNavBars(MDUpdate);