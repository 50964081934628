import { createApi, fetchBaseQuery, retry } from '@reduxjs/toolkit/query/react';
import { config } from '../../../config';
import { ConvertToReactSelect, getTransformedDataForPagination, LocalStorageKeys } from '../../../utils';

export const usersAPI = createApi({
    reducerPath: "usersAPI",
    baseQuery: retry(fetchBaseQuery({
        baseUrl: config.api_url  + "um/",
        prepareHeaders: (headers, { getState }) => {
            const token = localStorage.getItem(LocalStorageKeys.authToken);

            // If we have a token set in state, let's assume that we should be passing it.
            if (token) {
                headers.set('authorization', `Bearer ${token}`)
            }

            return headers
        },
    }), { maxRetries: 2 }),
    tagTypes: ['Users'],
    endpoints: (builder) => ({
        // Get All Users API
        getUsers: builder.query({
            query: ({ page = 0, pageSize = 10 }) => ({ url: `user${page !== null ? `?page_no=${page}` : ''}${pageSize ? `&page_size=${pageSize}` : ''}`, method: "GET" }),
            transformResponse: (data: any, meta: { request: Request; response?: Response }) => {
                return getTransformedDataForPagination(data, meta?.response);
            },
        }),

         // Get Users as DD Options API
         getUsersAsOptions: builder.query({
            query: () => ({ url: `user`, method: "GET" }),
            transformResponse: (response: any[], meta, arg) => ConvertToReactSelect(response, "user_id", "name"),
        }),

         // Get User by id API
         getUserById: builder.query({
            query: (id) => ({ url: `user/${id}`, method: "GET" }),
        }),

        // Update role for user
        updateRoleForUser: builder.mutation({
            query: ({ user_id , role_id  }) => ({ url: `user/${encodeURI(user_id)}/role/${encodeURI(role_id)}`, method: "PUT" })
        }),

        // Roles
        
        // get all roles
        getRoles: builder.query({
            query: ({ page = 0, pageSize = 10 }) => ({ url: `role${page !== null ? `?page_no=${page}` : ''}${pageSize ? `&page_size=${pageSize}` : ''}`, method: "GET" }),
        }),
    }) 
})

// Export hooks for usage in functional components
export const { useGetUserByIdQuery, useLazyGetUsersQuery, useGetUsersQuery, useLazyGetUserByIdQuery, useLazyGetUsersAsOptionsQuery, useLazyGetRolesQuery, useUpdateRoleForUserMutation } = usersAPI
