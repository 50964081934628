export const AppRoutes = {
  // Root
  root: "/",

  // Auth Routes
  login: "/login",
  auth0: "/auth0",
  logout: "/logout",

  // Home
  home: "/home",

  // Material Module Routes
  materialDashboard: "/material/dashboard",
  allMaterials: "/materials/all",
  viewMatBasicDetail: (id: string = ":mat_id") => `/material/${id}/basicDetail`,
  viewMatSourcing: (id: string = ":mat_id") => `/material/${id}/sourcing`,
  viewMatDocuments: (id: string = ":mat_id") => `/material/${id}/documents`,
  viewMatTechSpec: (id: string = ":mat_id") => `/material/${id}/techSpecification`,
  viewMatDataSheet: (id: string = ":mat_id", ds_id: string = ":ds_id") => `/material/${id}/dataSheet/${ds_id}`,
  descriptionGenerator: "/descriptionGenerator",
  materialRepository: "/materialRepository",
  viewMatDetailsPreview: (id: string = ":mat_id") => `/material/${id}/materialDetailsPreview`,
  viewMatProcure: (id: string = ":mat_id") => `/material/${id}/materialProcure`,
 
  bom: "/bom",
  viewBomBasicDetail: (id: string = ":bom_id") => `/bom/${id}/basicDetail`,
  viewBomCostingTree: (id: string = ":bom_id") => `/bom/${id}/costingTree`,
  viewBomTree: (id: string = ":bom_id") => `/bom/${id}/bomTree`,
  viewDocTree: (id: string = ":bom_id") => `/bom/${id}/docTree`,
  viewBomDocuments: (id: string = ":bom_id") => `/bom/${id}/documents`,
  viewBomCostBreakup: (id:string = ":bom_id") => `/bom/${id}/costBreakup`,
  viewBomDataSheet: (id: string = ":bom_id", ds_id: string = ":ds_id") => `/bom/${id}/dataSheet/${ds_id}`,
  viewBOMDetailsPreview: (id: string = ":bom_id") => `/bom/${id}/bomDetailsPreview`,
  
  // Product Module Routes
  productDashboard: "/products/dashboard",
  products: "/products",
  viewProductBasicDetail: (id: string = ":id") => `/product/${id}/basicDetail`,
  viewProductDocuments: (id: string = ":id") => `/product/${id}/documents`,
  viewProductMaterial: (id: string = ":id") => `/product/${id}/material`,  
  viewProductBOM: (id: string = ":id") => `/product/${id}/bom`,
  viewProductIEDraw: (id: string = ":id") => `/product/${id}/iedraw`,
  viewProductAISearch: (id: string = ":id") => `/product/${id}/aiSearch`,
  createOrUpdateProductBOMBasicDetail: (id: string = ":id", bom_id: string = ":bom_id") => `/product/${id}/bom/${bom_id}/basicDetail`,
  createOrUpdateProductBOMDocuments: (id: string = ":id", bom_id: string = ":bom_id") => `/product/${id}/bom/${bom_id}/documents`,
  createOrUpdateProductBOMTree: (id: string = ":id", bom_id: string = ":bom_id") => `/product/${id}/bom/${bom_id}/bomTree`,
  createOrUpdateProductDocTree: (id: string = ":id", bom_id: string = ":bom_id") => `/product/${id}/bom/${bom_id}/docTree`,
  createOrUpdateProductMaterialBasicDetail: (id: string = ":id", mat_id: string = ":mat_id") => `/product/${id}/material/${mat_id}/basicDetail`,
  createOrUpdateProductMaterialDocuments: (id: string = ":id", mat_id: string = ":mat_id") => `/product/${id}/material/${mat_id}/documents`,
  createOrUpdateProductMaterialDetailsPreview: (id: string = ":id", mat_id: string = ":mat_id") => `/product/${id}/material/${mat_id}/materialDetailsPreview`,
  createOrUpdateProductMaterialProcure: (id: string = ":id", mat_id: string = ":mat_id") => `/product/${id}/material/${mat_id}/materialProcure`,
  viewProductBOMDetailsPreview: (id: string = ":id", bom_id: string = ":bom_id") => `/product/${id}/bom/${bom_id}/bomDetailsPreview`,
  createOrUpdateProductMaterialDataSheet: (id: string = ":id", mat_id: string = ":mat_id", ds_id: string = ":ds_id") => `/product/${id}/material/${mat_id}/dataSheet/${ds_id}`,
  viewBOMProductDataSheet: (id: string = ":id", bom_id: string = ":bom_id", ds_id: string = ":ds_id") => `/product/${id}/bom/${bom_id}/dataSheet/${ds_id}`,
  product_vc: "/product/variantConfigurator",
  product_repository: "/product/repository",

  // Tags Module Routes
  tags: "/tags",
  tagGroups: "/tagGroups",
  documentTemplate: "/documentTemplates",
  viewTemplate: (template: string = ":template_type", id: string = ":id") => `/template/${template}/${id}`,
  viewTemplateList: "/documentTemplates/headerFooterTemplate",
  sequenceNumbering: (sequence_type: string = ":sequence_type") => `/sequenceNumbering/${sequence_type}`,
  viewSequenceNumber: (sequence_type: string = ":sequence_type", id: string = ":id") => `/sequenceNumbering/${sequence_type}/${id}`,
  checkList: "/documentTemplates/checkListTemplates",
  viewChecklistWithId: (checklist_id: string = ":checklist_id") => `/documentTemplates/checkListTemplates/${checklist_id}`,
  dataSheetList: "/documentTemplates/dataSheetTemplates",
  viewDataSheetWithId: (id: string = ":id") => `/documentTemplates/dataSheetTemplates/${id}`,
  technicalParametersList: "/documentTemplates/technicalParametersTemplates",
  viewTechnicalParametersWithId: (id: string = ":id") => `/documentTemplates/technicalParametersTemplates/${id}`,

  // Template Module Routes
  templates: "/templates",

  // Enquiry Module Routes
  enquiry: "/enquiry",

  // Project Module Routes
  projects: "/projects",
  projectDashboard: "/projects/dashboard",
  viewProjectBasicDetail: (id: string = ":id") => `/project/${id}/basicDetail`,
  viewProjectDocuments: (id: string = ":id") => `/project/${id}/documents`,
  viewProjectBOM: (id: string = ":id") => `/project/${id}/bom`,
  viewProjectMaterial: (id: string = ":id") => `/project/${id}/material`,
  viewProjectIEDraw: (id: string = ":id") => `/project/${id}/iedraw`,
  viewProjectAISearch: (id: string = ":id") => `/project/${id}/aiSearch`,
  createOrUpdateProjectBOMBasicDetail: (id: string = ":id", bom_id: string = ":bom_id") => `/project/${id}/bom/${bom_id}/basicDetail`,
  createOrUpdateProjectBOMDocuments: (id: string = ":id", bom_id: string = ":bom_id") => `/project/${id}/bom/${bom_id}/documents`,
  createOrUpdateProjectBOMTree: (id: string = ":id", bom_id: string = ":bom_id") => `/project/${id}/bom/${bom_id}/bomTree`,
  createOrUpdateProjectDocTree: (id: string = ":id", bom_id: string = ":bom_id") => `/project/${id}/bom/${bom_id}/docTree`,
  createOrUpdateProjectMaterialBasicDetail: (id: string = ":id", mat_id: string = ":mat_id") => `/project/${id}/material/${mat_id}/basicDetail`,
  createOrUpdateProjectMaterialDocuments: (id: string = ":id", mat_id: string = ":mat_id") => `/project/${id}/material/${mat_id}/documents`,
  createOrUpdateProjectMaterialDetailsPreview: (id: string = ":id", mat_id: string = ":mat_id") => `/project/${id}/material/${mat_id}/materialDetailsPreview`,
  createOrUpdateProjectMaterialProcure: (id: string = ":id", mat_id: string = ":mat_id") => `/project/${id}/material/${mat_id}/materialProcure`,
  viewProjectBOMDetailsPreview: (id: string = ":id", bom_id: string = ":bom_id") => `/project/${id}/bom/${bom_id}/bomDetailsPreview`,
  createOrUpdateProjectMaterialDataSheet: (id: string = ":id", mat_id: string = ":mat_id", ds_id: string = ":ds_id") => `/project/${id}/material/${mat_id}/dataSheet/${ds_id}`,
  viewBOMProjectDataSheet: (id: string = ":id", bom_id: string = ":bom_id", ds_id: string = ":ds_id") => `/project/${id}/bom/${bom_id}/dataSheet/${ds_id}`,

  // Proposal Module Routes
  proposals: "/proposals",
  proposalsDashboard: "/proposals/dashboard",
  viewProposalBasicDetail: (id: string = ":id") => `/proposal/${id}/basicDetail`,
  viewProposalDocuments: (id: string = ":id") => `/proposal/${id}/documents`,
  viewProposalMaterial: (id: string = ":id") => `/proposal/${id}/material`,
  viewProposalBOM: (id: string = ":id") => `/proposal/${id}/bom`,
  viewProposalIEDraw: (id: string = ":id") => `/proposal/${id}/iedraw`,
  viewProposalAISearch: (id: string = ":id") => `/proposal/${id}/aiSearch`,
  createOrUpdateProposalBOMBasicDetail: (id: string = ":id", bom_id: string = ":bom_id") => `/proposal/${id}/bom/${bom_id}/basicDetail`,
  createOrUpdateProposalBOMDocuments: (id: string = ":id", bom_id: string = ":bom_id") => `/proposal/${id}/bom/${bom_id}/documents`,
  createOrUpdateProposalBOMTree: (id: string = ":id", bom_id: string = ":bom_id") => `/proposal/${id}/bom/${bom_id}/bomTree`,
  createOrUpdateProposalDocTree: (id: string = ":id", bom_id: string = ":bom_id") => `/proposal/${id}/bom/${bom_id}/docTree`,
  createOrUpdateProposalMaterialBasicDetail: (id: string = ":id", mat_id: string = ":mat_id") => `/proposal/${id}/material/${mat_id}/basicDetail`,
  createOrUpdateProposalMaterialDocuments: (id: string = ":id", mat_id: string = ":mat_id") => `/proposal/${id}/material/${mat_id}/documents`,
  createOrUpdateProposalMaterialDetailsPreview: (id: string = ":id", mat_id: string = ":mat_id") => `/proposal/${id}/material/${mat_id}/materialDetailsPreview`,
  createOrUpdateProposalMaterialProcure: (id: string = ":id", mat_id: string = ":mat_id") => `/proposal/${id}/material/${mat_id}/materialProcure`,
  createOrUpdateProposalMaterialDataSheet: (id: string = ":id", mat_id: string = ":mat_id", ds_id: string = ":ds_id") => `/proposal/${id}/material/${mat_id}/dataSheet/${ds_id}`,
  viewProposalBOMDetailsPreview: (id: string = ":id", bom_id: string = ":bom_id") => `/proposal/${id}/bom/${bom_id}/bomDetailsPreview`,
  viewBOMProposalDataSheet: (id: string = ":id", bom_id: string = ":bom_id", ds_id: string = ":ds_id") => `/proposal/${id}/bom/${bom_id}/dataSheet/${ds_id}`,

  // Suppliers/Vendor Module Routes

  vendors: "/suppliers",
  vendorsDashboard: "/vendors/dashboard",
  viewVendorBasicDetails: (id: string | number = ":id") => `/supplier/${id}`,
  viewVendorBasicDetailsReadOnly: (id: string | number = ":id") => `/supplier/view/${id}`,
  viewVendorDocuments: (id: string = ":id") => `/supplier/${id}/documents`,
  vendorRepository: "/supplier/repository",
  suppliersList: "/suppliersList",

  // Knowledge Module Routes
  knowledge: "/knowledge",

  // User Module Routes
  user: "/user",
  userManagement: "/user/userManagement",
  licenseManagement: "/user/licenseManagement",

  // Document Module Routes
  docDashboard: "/documents/dashboard",
  documents: "/documents",
  cutSheetBuilder: "/cutSheetBuilder",
  viewCutSheetBasicDetails: (id: string = ":id") => `/cutSheetBuilder/basicDetails/${id}`,
  viewCutSheets: (id: string = ":id") => `/cutSheetBuilder/cutSheets/${id}`,

  // AI Module Routes
  ai: "/ai",
  aiAllSearches: "/ai/allSearches",
  viewAIAllSearcheBasicDetails: (id: string = ":id") => `/ai/allSearches/basicDetails/${id}`,
  viewAIAllSearcheDocuments: (id: string = ":id") => `/ai/allSearches/documents/${id}`,
  viewAIAllSearcheChatBox: (id: string = ":id") => `/ai/allSearches/chatbox/${id}`,
  aiInstantSearch: "/ai/instantSearch",

  // Quality Module Routes
  qualityDashboard: "/quality/dashboard",

  // Annotation Routes
  annotateViewer: (id: string = ":id") => `/documents/${id}`,
  proposalAnnotateViewer: (p_id: string = ":p_id", id: string = ":id") => `/proposal/${p_id}/documents/${id}`,
  projectAnnotateViewer: (p_id: string = ":p_id", id: string = ":id") => `/project/${p_id}/documents/${id}`,
  productAnnotateViewer: (p_id: string = ":p_id", id: string = ":id") => `/product/${p_id}/documents/${id}`,
  nestedBOMDocumentAnnotateViewer: (module: string = ":module", p_id: string = ":p_id", bom_id: string = ":bom_id", id: string = ":id") => `/${module}/${p_id}/bom/${bom_id}/documents/${id}`,
  nestedMaterialDocumentAnnotateViewer: (module: string = ":module", p_id: string = ":p_id", mat_id: string = ":mat_id", id: string = ":id") => `/${module}/${p_id}/material/${mat_id}/documents/${id}`,
  bomDocumentAnnotateViewer: (bom_id: string = ":bom_id", id: string = ":id") => `/bom/${bom_id}/documents/${id}`,
  materialDocumentAnnotateViewer: (mat_id: string = ":mat_id", id: string = ":id") => `/material/${mat_id}/documents/${id}`,

  // BOM Report
  bomReport: "/bomReport",
  viewBOMReport: (id: string = ":id") => `/bomReport/${id}`,
  viewBOMReportOnProductModule: (id: string = ":id") => `/product/${id}/bomReport`,
  viewBOMReportOnProjectModule: (id: string = ":id") => `/project/${id}/bomReport`,
  viewBOMReportOnProposalModule: (id: string = ":id") => `/proposal/${id}/bomReport`,
  viewIndividualBOMReportOnModule: (module: string = ":module", p_id: string = ":p_id", id: string = ":id") => `/${module}/${p_id}/bomReport/${id}`,
  previewBOMReportOnModule: (module: string = ":module", p_id: string = ":p_id", id: string = ":id") => `/${module}/${p_id}/bomPreview/${id}`,
  previewBOMReport: (id: string = ":id") => `/bomPreview/${id}`,

  // List View
  viewListViewOnProductModule: (id: string = ":id") => `/product/${id}/listView`,
  viewListViewOnProjectModule: (id: string = ":id") => `/project/${id}/listView`,
  viewListViewOnProposalModule: (id: string = ":id") => `/proposal/${id}/listView`,
  viewIndividualListViewOnModule: (module: string = ":module", p_id: string = ":p_id", id: string = ":id") => `/${module}/${p_id}/listView/${id}`,
  previewListViewOnModule: (module: string = ":module", p_id: string = ":p_id", id: string = ":id") => `/${module}/${p_id}/listViewPreview/${id}`,

  // Draw.io Diagram Module
  viewAllDrawioDiagrams: '/diagrams/iedraw',
  viewDrawioDiagram: (id: string = ":id") => `/diagram/iedraw/${id}`,
  drawioDiagramEditor: (document_id: string = ":document_id") => `/iedraw/${document_id}`,
  drawioDiagramPreview: (document_id: string = ":document_id") => `/iedraw/preview/${document_id}`,

  // Embed Component in a screen for iframes
  iframEmbedComponent: (component_name: string = ":component_name") => `/embed/${component_name}`,

  // Workbook Routes
  materialWorkbook: '/material/workbook',
  workbookOnProductModule: (id: string = ":id") => `/product/${id}/workbook`,
  workbookOnProjectModule: (id: string = ":id") => `/project/${id}/workbook`,
  workbookOnProposalModule: (id: string = ":id") => `/proposal/${id}/workbook`,
  viewWorkbook: (id: string = ":id") => `/material/workbook/${id}`,
  viewWorkbookOnModule: (module: string = ":module", p_id: string = ":p_id", id: string = ":id") => `/${module}/${p_id}/workbook/${id}`,
  
  // Draw.io Diagram Templates
  viewAllDiagramTemplates: '/diagramTemplates',

  // Issue Tracker Routes
  issuesList: "/issuesList",
  viewIssueWithId: (issue_id: string = ":issue_id") => `/issuesList/${issue_id}`,
  viewIssueTrackerOnProduct: (id: string = ":id") => `/product/${id}/issueTracker`,
  viewIssueTrackerOnProject: (id: string = ":id") => `/project/${id}/issueTracker`,
  viewIssueTrackerOnProposal: (id: string = ":id") => `/proposal/${id}/issueTracker`,
  viewIssueTrackerWithId: (module: string = ":module", id: string = ":id", issue_id: string = ":issue_id") => `/${module}/${id}/issueTracker/${issue_id}`,

  // Technical Parameters Form Routes
  viewProductTechParamForm: (id: string = ":id", ds_id: string = ":ds_id") => `/product/${id}/technicalParameters/${ds_id}`,
  viewProjectTechParamForm: (id: string = ":id", ds_id: string = ":ds_id") => `/project/${id}/technicalParameters/${ds_id}`,
  viewProposalTechParamForm: (id: string = ":id", ds_id: string = ":ds_id") => `/proposal/${id}/technicalParameters/${ds_id}`,

  // Manage User Routes
  manageUsersOnProduct: (id: string = ":id") => `/product/${id}/manageUsers`,
  manageUsersOnProject: (id: string = ":id") => `/project/${id}/manageUsers`,
  manageUsersOnProposal: (id: string = ":id") => `/proposal/${id}/manageUsers`,

  // Help and Support Routes
  tickets: '/help/tickets',
  howTo: '/howTo'
};
