import { Avatar, Chip, IconButton, Stack, Tooltip, Typography } from "@mui/material";
import { GridRenderCellParams } from "@mui/x-data-grid-pro";
import { Themes } from "../../utils";
import { tileStyles } from "../../components";
import { Edit } from "@mui/icons-material";
import moment from "moment";

export const L1 = "L1";
export const L2 = "L2";
export const L3 = "L3";
export const L4 = "L4";

const roleDescription: Record<string, string> = {
    L1: "Viewer",
    L2: "Editor",
    L3: "Admin",
    L4: "Super Admin",
};

export const DashboardTableColumn = (onDelete: any, themeContext: any, currentUserID: string, editRole?: (user: any) => void) => ([
    {
        field: 'picture', headerName: 'Profile Picture', width: 150, renderCell: (params: GridRenderCellParams) => {
            if (params.rowNode.type === 'group') {
                return params.value;
            }
            return <Tooltip title={"User Profile Picture"}>
                <Avatar sx={tileStyles(themeContext.name === Themes.default)} src={params.row?.picture} variant="square" />
            </Tooltip>
        }
    },
    { field: 'name', headerName: 'Name', width: 250 },
    // { field: 'username', headerName: 'User Name', width: 150 },
    { field: 'email', headerName: 'Email', width: 300 },
    {
        field: 'roles', headerName: 'Roles', width: 200, renderCell: (params: GridRenderCellParams) => {
            if (params.rowNode.type === 'group') {
                return params.value;
            }
            return (params?.row?.roles ?? []).length > 0 ? (params?.row?.roles ?? [])?.map((role: { name: string }, idx: number) => (
                <Stack direction="row" key={idx} alignItems={"center"}>
                    <Tooltip title={roleDescription[role.name] || role.name}>
                        <Chip variant="outlined" size={"small"} label={role.name} sx={{ mr: 0.8 }} />
                    </Tooltip>

                    {editRole && currentUserID !== params?.row?.user_id && (
                        <Tooltip title={"Edit Role"}>
                            <IconButton onClick={() => editRole(params.row)}>
                                <Edit fontSize="small" color={"primary"} />
                            </IconButton>
                        </Tooltip>
                    )}
                </Stack>
            )) : null
        }
    },
    {
        field: 'start_date', headerName: 'Start Date', width: 250, renderCell: (params: GridRenderCellParams) => {
            if (params.rowNode.type === 'group') {
                return params.value;
            }
            return <Typography variant="body2">{moment(params.row.license.start_date).format('lll')}</Typography>
        }
    },
    {
        field: 'end_date', headerName: 'End Date', width: 250, renderCell: (params: GridRenderCellParams) => {
            if (params.rowNode.type === 'group') {
                return params.value;
            }
            return <Typography variant="body2">{moment(params.row.license.end_date).format('lll')}</Typography>
        }
    }
]);

