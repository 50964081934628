import { createApi, fetchBaseQuery, retry } from "@reduxjs/toolkit/query/react";
import Axios from "axios";
import { config } from "../../../config";
import {
  getTransformedDataForPagination,
  LocalStorageKeys,
} from "../../../utils";
import { BOMSliceInterface } from "../../slices/bom";

const prepareBOMBody = (
  payload: Partial<BOMSliceInterface>,
  update_version?: boolean
) => {
  let body: any = {
    manual_desc_gen: payload?.manual_desc_gen ?? false,
    currency_code: payload?.currency?.value ?? payload?.currency ?? "USD",
    group_id: payload.bomGroup?.value,
    notes: payload?.notes ?? "",
    noun_id: payload.bomTag?.value,
    reference_id: payload.clientRefNo,
    short_description: payload.bomShortDesc,
    long_description: payload.bomDesc,
    status: payload.status?.value,
    uom_id: payload.region?.value,

    noun_variant_id: payload.varientId,
    attribute_details: payload?.attribute_details,

    sourcing_type: payload.sourceType,
    vendor: {
      manufacturer_name: payload.manufactureName,
      vendor_name: payload.supplierName?.label,
      vendor_id: payload.supplierName?.value,
      part_number: payload.manufactureNo,
    },
    organization_unit: {
      other_details: payload.otherDetails,
      unit_code: payload.unitName,
      unit_location: payload.unitLocation,
    },

    assm_procs_link: payload.assemblyProcess
      ?.filter((_) => !_._id)
      .map((_) => ({
        _id: _._id,
        proc_name: _.process_name,
        quantity: _.qty ? parseFloat(_.qty.toString()) : 0,
        unit_cost: {
          cost: _.cost ? parseFloat(_.cost.toString()) : 0,
          currency: _.currency?.value,
        },
      })),

    assm_procs_unlink: payload.assemblyProcessUnlink,
    links: payload.link?.filter((l) => (l.object_type !== "material" && l.object_type !== "bom") && l.object_ids?.length > 0)
  };

  if (payload.uom?.value === "length") {
    body.length = parseFloat(payload?.length?.toString() ?? "0");
    body.units_length = payload?.length_unit?.value;
  }

  if (payload.uom?.value === "weight") {
    body.weight = parseFloat(payload?.weight?.toString() ?? "0");
    body.units_weight = payload?.weight_unit?.value;
  }

  if (!payload.varientId) {
    delete body.noun_variant_id;
  }

  if (payload.sourceType === "external_purchase") {
    delete body.organization_unit;
  } else {
    delete body.vendor;
  }

  if (!body?.reference_id?.length) {
    body.reference_id = '';
  }

  if (update_version) {
    if ((payload?.link ?? [])?.length > 0) {
      body.links = payload.link?.filter((l) => (l.object_type !== "material" && l.object_type !== "bom") && l.object_ids?.length > 0);
      body.materials_link = [];
      payload.link?.filter((l) => l.object_type === "material" && l.object_ids?.length > 0).forEach(_ => {
        body.materials_link.push(..._?.object_ids.map((id: string) => ({ _id: id })))
      })
      body.bom_children_link = [];
      payload.link?.filter((l) => l.object_type === "bom" && l.object_ids?.length > 0).forEach(_ => {
        body.bom_children_link.push(..._?.object_ids.map((id: string) => ({ _id: id })))
      });
    }

    if ((payload?.unlink ?? [])?.length > 0) {
      body.unlinks = payload.unlink?.filter((l) => (l.object_type !== "material" && l.object_type !== "bom") && l.object_ids?.length > 0);
      body.materials_unlink = [];
      payload.unlink?.filter((l) => l.object_type === "material" && l.object_ids?.length > 0).forEach(_ => {
        body.materials_unlink.push(..._?.object_ids)
      });
      body.bom_children_unlink = [];
      payload.unlink?.filter((l) => l.object_type === "bom" && l.object_ids?.length > 0).forEach(_ => {
        body.bom_children_unlink.push(..._?.object_ids)
      });
    }
  }


  return { ...body };
};

export const bomAPI = createApi({
  reducerPath: "bomAPI",
  baseQuery: retry(
    fetchBaseQuery({
      baseUrl: config.api_url + "mm/bom",
      prepareHeaders: (headers, { getState }) => {
        const token = localStorage.getItem(LocalStorageKeys.authToken);

        // If we have a token set in state, let's assume that we should be passing it.
        if (token) {
          headers.set("authorization", `Bearer ${token}`);
        }

        return headers;
      },
    }),
    { maxRetries: 1 }
  ),
  tagTypes: ["BOMs", "BOM", "BOMChildren", "SearchedBOMs"],
  endpoints: (builder) => ({
    // Get BOMS API
    getBOMs: builder.query({
      query: ({
        include_histories = false,
        object_id = null,
        object_type = null,
        page = 0,
        pageSize = 10,
        sort_by = null, 
        sort_order = null
      }) => ({
        url: `?include_histories=${include_histories}${object_type ? `&object_type=${object_type}` : ``
          }${object_id ? `&object_id=${object_id}` : ``}${page !== null ? `&page_no=${page + 1}` : ""
          }${pageSize ? `&page_size=${pageSize}` : ""}${sort_by ? `&sort_by=${sort_by}` : ''}${sort_order ? `&sort_order=${sort_order}` : ''}`,
        method: "GET",
      }),
      transformResponse: (
        data: any,
        meta: { request: Request; response?: Response }
      ) => {
        return getTransformedDataForPagination(data, meta?.response);
      },
      providesTags: ["BOMs"],
    }),

    // Get All BOMs
    getAllBOMs: builder.query({
      query: ({ include_histories = false }) => ({
        url: `?include_histories=${include_histories}`,
        method: "GET"
      }),
    }),
    // Filter BOM using search API
    getBOMsBySearch: builder.query({
      query: ({
        search = "", include_histories = false, page: page_no = 0, pageSize: page_size = 10, sort_by = null, sort_order = null
      }) => ({
        url: `/search?search=${search}&include_histories=${include_histories}${page_no !== null ? `&page_no=${page_no + 1}` : ``}${page_size ? `&page_size=${page_size}` : ``}${sort_by ? `&sort_by=${sort_by}` : ''}${sort_order ? `&sort_order=${sort_order}` : ''}`,
        method: "GET"
      }),
      transformResponse: (
        data: any,
        meta: { request: Request; response?: Response }
      ) => {
        return getTransformedDataForPagination(data, meta?.response);
      },
      providesTags: ['SearchedBOMs'],
    }),

    // Get BOM by BOM id API
    getBOMById: builder.query({
      query: ({ id }) => ({ url: `${id}`, method: "GET" }),
      providesTags: ["BOM"],
    }),

    // Get BOM cost by BOM id API
    getBOMCostById: builder.query({
      query: ({ id }: { id: string | number }) => ({
        url: `${id}/costing`,
        method: "GET",
      }),
    }),

    // Calculate BOM cost by BOM id API
    calculateBOMCost: builder.mutation({
      async queryFn(_arg, _queryApi, _extraOptions, fetchWithBQ) {
        const { id, exchangerates } = _arg;
        const body = JSON.stringify({
          exchangerates: {
            ...exchangerates,
            current_date:
              exchangerates.current_date?.toISOString()?.split("T")?.[0] ?? "",
            exchange_date:
              exchangerates.exchange_date?.toISOString()?.split("T")?.[0] ?? "",
          },
        });
        let result: any = {};
        try {
          const response: any = await fetchWithBQ({
            url: `${id}/costing`,
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: body,
          });
          result = response?.error
            ? { error: response?.error }
            : { ...response };
        } catch (err) {
          result = { error: true };
        } finally {
          return result;
        }
      },
    }),

    // Get BOM by BOM id API
    getBOMChildrensById: builder.query({
      query: ({ include_histories, id, page = 0, pageSize = 10, sort_by = null, sort_order = null }) => ({
        url: `${id}/children_suggestions?include_histories=${include_histories}${page !== null ? `&page_no=${page + 1}` : ""
          }${pageSize ? `&page_size=${pageSize}` : ""}${sort_by ? `&sort_by=${sort_by}` : ''}${sort_order ? `&sort_order=${sort_order}` : ''}`,
        method: "GET",
      }),
      transformResponse: (
        data: any,
        meta: { request: Request; response?: Response }
      ) => {
        return getTransformedDataForPagination(data, meta?.response);
      },
    }),

    getBOMCostingById: builder.query({
      query: ({ id }) => ({ url: `${id}/costing`, method: "GET" }),
    }),

    // Create BOM API
    createBOM: builder.mutation({
      query: (payload: BOMSliceInterface) => ({ method: "POST", body: { ...prepareBOMBody(payload) } }),
      invalidatesTags: ["BOMs", "BOM", "BOMChildren"],
    }),

    // Update BOM API
    updateBOM: builder.mutation({
      query: ({
        id,
        payload,
        update_version = false,
      }: {
        id: string;
        payload: BOMSliceInterface;
        update_version: boolean;
      }) => {
        return {
          url: `/${id}?update_version=${update_version}`,
          method: "PUT",
          body: { ...prepareBOMBody(payload, update_version) },
        };
      },
      invalidatesTags: ["BOMs", "BOM", "BOMChildren"],
    }),

    // Delete BOM API
    deleteBOM: builder.mutation({
      query: ({ id, force }) => ({
        url: `/${id}?force=${force}`,
        method: "DELETE",
      }),
      invalidatesTags: ["BOMs", "BOM", "BOMChildren"],
    }),

    // Link Childs API
    linkChilds: builder.mutation({
      query: ({
        id,
        payload,
        update_version = false,
      }: {
        id: string;
        payload: {
          bom_children_link?: any[];
          materials_link?: any[];
          bom_children_unlink?: string[];
          materials_unlink?: string[];
          currency_code: string;
        };
        update_version: boolean;
      }) => {
        return {
          url: `/${id}?update_version=${update_version}`,
          method: "PUT",
          body: { ...payload },
        };
      },
      invalidatesTags: ["BOMs", "BOM", "BOMChildren"],
    }),

    // Get Dashboard API
    getBOMDashboard: builder.query({
      query: ({ year = "" }) => ({
        url: `/dashboard${year.length ? `?year=${year}` : ""}`,
        method: "GET",
      }),
    }),

    // Update BOM API
    updateBOMItems: builder.mutation({
      query: ({
        id,
        payload,
      }: {
        id: string;
        payload: {
          assm_procs?: any[];
          bom_children?: any[];
          bom_materials?: any[];
        };
        update_version: boolean;
      }) => {
        return {
          url: `/${id}/items`,
          method: "PUT",
          body: { ...payload },
        };
      },
      invalidatesTags: ["BOMs", "BOM", "BOMChildren"],
    }),

    // BOM Image Upload
    uploadBOMImage: builder.mutation({
      async queryFn(_arg, _queryApi, _extraOptions) {
        const { data, url, file } = _arg;
        let result: any = {};

        try {
          let resData = null;
          if (file) {
            resData = await Axios({
              url,
              method: "put",
              headers: { "Content-Type": file.type },
              data: file,
            });
          }
          result = { data: { resData, document: data } };
        } catch (err) {
          result = { error: err };
        } finally {
          return result;
        }
      },
      invalidatesTags: ["BOMs", "BOM", "BOMChildren"],
    }),

    // Duplicate a BOM
    duplicateBOM: builder.mutation({
      query: ({ id, level = "children" }) => {
        return {
          url: `/${id}?level=${level}`,
          method: "POST",
        };
      },
      invalidatesTags: ["BOMs"],
    }),

    // Get BOM Tree by BOM id API
    getBOMTreeById: builder.query({
      query: ({ id }) => ({ url: `${id}/tree`, method: "GET" }),
    }),

    // Get BOM Children by BOM id API
    getChildrenById: builder.query({
      query: ({ id, item_type, page, pageSize }) => ({ url: `/item/${id}/${item_type}${page !== null ? `?page_no=${page + 1}` : ""}${pageSize ? `&page_size=${pageSize}` : ""}`, method: "GET" }),
      transformResponse: (
        data: any,
        meta: { request: Request; response?: Response }
      ) => {
        return getTransformedDataForPagination(data, meta?.response);
      },
    }),
  }),
});

// Export hooks for usage in functional components
export const {
  useGetBOMsQuery,
  useLazyGetBOMsQuery,
  useGetBOMByIdQuery,
  useLazyGetBOMByIdQuery,
  useCreateBOMMutation,
  useUpdateBOMMutation,
  useDeleteBOMMutation,
  useLazyGetBOMDashboardQuery,
  useLinkChildsMutation,
  useLazyGetBOMChildrensByIdQuery,
  useGetBOMChildrensByIdQuery,
  useGetBOMCostByIdQuery,
  useLazyGetBOMCostByIdQuery,
  useCalculateBOMCostMutation,
  useGetBOMCostingByIdQuery,
  useLazyGetBOMCostingByIdQuery,
  useUpdateBOMItemsMutation,
  useUploadBOMImageMutation,
  useLazyGetAllBOMsQuery,
  useLazyGetBOMsBySearchQuery,
  useDuplicateBOMMutation,
  useLazyGetBOMTreeByIdQuery,
  useGetBOMTreeByIdQuery,
  useLazyGetChildrenByIdQuery
} = bomAPI;
