import {
    Grid,
    TextField,
    Button,
    Tooltip,
    Box,
    Stack,
    Typography
} from "@mui/material";
import React from "react";
import { HighlightSelectBox, SectionPaper } from "../../components";
import { PublishedIcon } from "../../images/svg/publishedIcon";
import { WIPIcon } from "../../images/svg/WIPIcon";
import { SelectBoxOption } from "../../interfaces/selectBox";
import {
    useGetCheckListTagsQuery
} from "../../redux/services";
import { CheckListContext } from "../viewCheckList/viewCheckListDetail";
import { documentTemplateStatus } from "../viewDatasheet/basicDetails";

export const BasicDetails: React.FC<{ children?: JSX.Element }> = () => {

    const tagsData = useGetCheckListTagsQuery(null);

    const { state, updateState, error } = React.useContext(CheckListContext);

    return (
        <SectionPaper
            py={0.2}
            title="Basic Details"
            renderButtons={[
                state?.status?.label === "Published"
                    ? <Stack direction={"row"} alignItems={"center"}>
                        <Typography variant="body2" color="textSecondary">Status: </Typography>
                        <Button disableRipple disableFocusRipple sx={{ cursor: "default", '&:hover': { backgroundColor: 'transparent' } }}>
                            <Tooltip
                                title="Published"
                                children={<Box width={30} height={30} alignItems={"center"} justifyContent={"center"}><PublishedIcon sx={{ width: "100%", height: "100%" }} /></Box>}
                            />
                        </Button>
                    </Stack>
                    : <Stack direction={"row"} alignItems={"center"}>
                        <Typography variant="body2" color="textSecondary">Status: </Typography>
                        <Button disableRipple disableFocusRipple sx={{ cursor: "default", '&:hover': { backgroundColor: 'transparent' } }}><Tooltip title="Work in progress" children={<Box width={30} height={30} alignItems={"center"} justifyContent={"center"}><WIPIcon sx={{ width: "100%", height: "100%" }} /></Box>} /></Button>
                    </Stack>
            ]}
        >
            <Grid container spacing={2}>
                <Grid item xs={12} sm={6} lg={4}>
                    <TextField
                        id="parameter-name"
                        variant="outlined"
                        size="small"
                        fullWidth
                        required={true}
                        value={state.name}
                        error={error.name}
                        helperText={error.name ? "Please fill Parameter Name" : ""}
                        label={"Parameter Name"}
                        onChange={(e) => updateState("name", e.target.value)}
                    />
                </Grid>

                <Grid item xs={12} sm={6} lg={4}>
                    <HighlightSelectBox
                        id="parameter-status"
                        label={"Parameter Status"}
                        margin={"none"}
                        required={true}
                        value={state.status}
                        error={error.status}
                        helperText={error.status ? "Please fill Parameter Status" : ""}
                        options={documentTemplateStatus}
                        onChange={(data: SelectBoxOption) => updateState("status", data)}
                    />
                </Grid>

                <Grid item xs={12} sm={6} lg={4}>
                    <HighlightSelectBox
                        id="parameter-tags"
                        label={"Tags"}
                        margin={"none"}
                        required={true}
                        value={state.tags_info}
                        multiple={true}
                        loading={tagsData?.isLoading || tagsData?.isFetching}
                        error={error.tags_info}
                        helperText={error.tags_info ? "Please fill the Tags" : ""}
                        options={tagsData?.data?.checkListTags ?? []}
                        onChange={(data: SelectBoxOption) => updateState("tags_info", data)}
                    />
                </Grid>
            </Grid>
        </SectionPaper>
    );
};
