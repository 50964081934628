import React from "react";
import { Alert, Box, Theme, useTheme } from "@mui/material";
import { CustomDataGrid, TitleHeader } from "../../components";
import { useSnackbar } from "notistack";
import { TableColumn } from "./checkListUtils";
import { useAppDispatch } from "../../redux";
import { closeBackdrop, openBackdrop } from "../../redux/slices/backdrop";
import {
  closeConfirmationDialog,
  openConfirmationDialog
} from "../../redux/slices/confirmationDialog";
import { useNavigate } from "react-router-dom";
import { useCreateDataTableMutation, useDeleteDataTableMutation, useLazyGetAllDataTableQuery, useLazySearchDataTableQuery } from "../../redux/services";
import { TagInfo } from "../../interfaces";
import { generateDataTableFormat } from "../../utils";
import { GridSortDirection, GridSortModel } from "@mui/x-data-grid-premium";

const useStyles = (theme: Theme) => ({
  root: {
    flexGrow: 1,
    background: theme.palette.background.paper,
    boxShadow: `rgba(99, 99, 99, 0.2) 0px 2px 8px 0px`,
    height: "100%",
    overflow: "scroll",
    borderRadius: theme.spacing(1),
    padding: theme.spacing(2)
  },
  dataGridParent: {
    height: "calc(100% - 40px)",
    overflow: "scroll",
    marginTop: theme.spacing(2)
  }
});

interface CheckListProps {
  checklist_id?: string;
  hasAccess: boolean;
}

const CheckList: React.FC<CheckListProps> = ({ hasAccess }) => {
  const classes = useStyles(useTheme());

  const dispatch = useAppDispatch();

  const { enqueueSnackbar } = useSnackbar();

  const [getAllCheckList, { data: allData = { data: [], totalCount: 0 }, isFetching, isLoading, isError }] = useLazyGetAllDataTableQuery({
    refetchOnFocus: true
  });
  const { data, totalCount } = allData;

  const [getChecklistBySearch, { data: checklistFilteredData, isFetching: checklistIsFetching, isLoading: checklistIsLoading, isError: filterHasError }] = useLazySearchDataTableQuery();

  const loading = isLoading || isFetching || checklistIsFetching || checklistIsLoading;

  const [search, setSearch] = React.useState("");
  const [paginationModel, setPaginationModel] = React.useState({
    page: 0,
    pageSize: 10,
  });
  const [queryOptions, setQueryOptions] = React.useState({
    sortModel: [{ field: 'sequence_id', sort: 'desc' as GridSortDirection }],
  });

  const [deleteCheckListMutation] = useDeleteDataTableMutation();

  const [createChecklist] = useCreateDataTableMutation();

  const navigate = useNavigate();

  const giveMeRows = () => {
    if (search.trim().length) {
      return checklistFilteredData?.data ?? [];
    } else if ((!isLoading && !isFetching) && data && !search.trim()) {
      return data ?? [];
    } else if ((!checklistIsFetching && !checklistIsLoading) && checklistFilteredData) {
      return checklistFilteredData?.data ?? [];
    } else {
      return [];
    }
  }

  let ROWS = giveMeRows();

  const deleteCheckList = (checklist: any) => {
    dispatch(
      openConfirmationDialog({
        title: "Delete Operation",
        body: `Are you sure you want to delete the checklist ${checklist.sequence_id}-r${checklist.version}`,
        positiveBtn: "Delete",
        negativeBtn: "Cancel",
        onOk: () => performRowDel(checklist._id),
        onNegativeBtn: () => dispatch(closeConfirmationDialog()),
      })
    );
  };

  const performRowDel = async (_id: any) => {
    dispatch(openBackdrop("Deleting checklist..."));
    let res: any = {};
    try {
      res = await deleteCheckListMutation({
        dtTemplateId: _id,
        dtTemplateType: "checklist",
        dtInstanceType: "template"
      });

      if (Object.keys(res).includes("data")) {
        enqueueSnackbar(`Deleted checklist Successfully!`, { variant: "success" });
        dispatch(closeConfirmationDialog());
        fetchCheckList();
      } else if (Object.keys(res).includes("error")) {
        // eslint-disable-next-line
        throw res.error.data;
      } else {
        // eslint-disable-next-line
        throw "Data not found";
      }
    } catch (error: any) {
      let errorMessage: string = error?.title ?? "Oops! Something went wrong, Unable to delete checklist";
      enqueueSnackbar(errorMessage, { variant: "error" });
    } finally {
      dispatch(closeBackdrop());
    }
  };

  const viewCheckList = (data: any) => {
    if (hasAccess) {
      navigate(`${window.location.pathname}/${data?._id || "New"}`);
    }
  };

  const duplicate = (data: any) => {
    let message = `Do you want to create a copy of this "${data.sequence_id}" check list?`;
    dispatch(
      openConfirmationDialog({
        title: "Are you sure?",
        body: message,
        positiveBtn: "Create",
        negativeBtn: "Cancel",
        onOk: () => performDuplicate(data),
        onNegativeBtn: () => dispatch(closeConfirmationDialog()),
      })
    );
  };

  const performDuplicate = async (data: any) => {
    dispatch(openBackdrop(`Creating copy of "${data.sequence_id}" check list....`));
    let res: any = {};
    try {
      const extractedDigit = Math.abs(data?.name?.match(/-\d+/)?.at(-1)) || 0;

      const checkListName = `${extractedDigit ? data?.name?.replace(/-\d/, '') : data.name}-${(Number(extractedDigit) ?? 1) + 1}`;

      res = await createChecklist({
        payload: {
          ...data, name: checkListName, tags_info: data?.tags_info?.map((tag: TagInfo) => ({
            tag_group_id: tag?.tag_group_id,
            tag_id: tag?.tag_id
          })),
          tables: generateDataTableFormat(data?.tables)
        },
        dtTemplateType: "checklist",
        dtInstanceType: "template",
        dtTemplateId: null,
        isDuplicating: true
      });
      if (Object.keys(res).includes("data")) {
        enqueueSnackbar(
          `Created copy of ${res?.data?.sequence_id} Successfully!`,
          { variant: "success" }
        );
        dispatch(closeConfirmationDialog());
        fetchCheckList();
      } else if (Object.keys(res).includes("error")) {
        // eslint-disable-next-line
        throw "Unable to create copy";
      } else {
        // eslint-disable-next-line
        throw "Data not found";
      }
    } catch (error) {
      let errorMessage: string = `Unable to create copy of ${data.sequence_id}!`;
      enqueueSnackbar(errorMessage, { variant: "error" });
      dispatch(closeBackdrop());
    } finally {
      dispatch(closeBackdrop());
      dispatch(closeConfirmationDialog());
    }
  };

  const handleSortModelChange = React.useCallback((sortModel: GridSortModel) => {
    // Here you save the data you need from the sort model
    setQueryOptions({ sortModel: [...sortModel] });
  }, [])

  const fetchCheckList = () => {
    getAllCheckList({
      ...paginationModel, dtTemplateType: "checklist", dtInstanceType: "template",
      sort_by: queryOptions?.sortModel?.[0]?.field, sort_order: queryOptions?.sortModel?.[0]?.sort
    });
  };

  React.useEffect(() => {
    if (!search.trim().length) {
      fetchCheckList();
    }
    // eslint-disable-next-line
  }, [paginationModel, JSON.stringify(queryOptions?.sortModel), search]);

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      if (search.trim().length) {
        getChecklistBySearch({
          ...paginationModel, dtTemplateType: "checklist", dtInstanceType: "template",
          sort_by: queryOptions?.sortModel?.[0]?.field, sort_order: queryOptions?.sortModel?.[0]?.sort, search
        });
      }
    }, 500)

    return () => clearTimeout(timeout)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, paginationModel, JSON.stringify(queryOptions?.sortModel)])

  return (
    <Box sx={classes.root}>
      <TitleHeader
        title="All Searches"
        search={search}
        onSearchChange={setSearch}
        searchPlaceholder={"Search"}
        showCreateBtn={hasAccess}
        createBtnLabel={"Create Check List"}
        onCreateBtn={() => viewCheckList(null)}
      />

      {/* Error */}
      {(isError || filterHasError) && <Alert sx={{ mt: 2 }} severity="error">Oops! Something went wrong, Unable to fetch Check List. Try Again Later!</Alert>}

      <Box mt={2}>
        <CustomDataGrid
          id="check-list"
          // initialState={{
          //   sorting: {
          //     sortModel: [{ field: "sequence_id", sort: "desc" }]
          //   }
          // }}
          rows={ROWS}
          columns={TableColumn(deleteCheckList, viewCheckList, duplicate, hasAccess)}
          loading={loading}
          getRowId={(row) => row._id}
          showToolbar={true}
          rowCount={search.trim() ? checklistFilteredData?.totalCount : totalCount}
          paginationModel={paginationModel}
          paginationMode="server"
          onPaginationModelChange={setPaginationModel}
          sortingMode="server"
          onSortModelChange={handleSortModelChange}
        />
      </Box>
    </Box>
  );
};

export default CheckList;