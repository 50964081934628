import { Box, Typography } from "@mui/material";
import React from "react";
import { useAppDispatch } from "../../redux";
import { useLazyGetBOMByIdQuery } from "../../redux/services";
import { closeBackdrop, openBackdrop } from "../../redux/slices/backdrop";
import { CapitalizeString } from "../../utils";
import { AppRoutes } from "../../router/routes";
import { Link } from "react-router-dom";
import { OpenInNewOutlined } from "@mui/icons-material";

export const ViewMetaData: React.FC<{ children?: JSX.Element, bomId: string, childMaterialId?: string, childBOMId?: string }> = ({
    bomId,
    childBOMId,
    childMaterialId
}) => {

    const dispatch = useAppDispatch();
    const [getBOM] = useLazyGetBOMByIdQuery();

    const [state, setState] = React.useState<any>({});

    const fetchDetails = async () => {
        dispatch(openBackdrop('Fetching Detail...'));

        if (bomId) {
            try {
                let data = await getBOM({ id: bomId }).unwrap();;

                if (childMaterialId && childMaterialId !== "null") {
                    data = { ...data.bom_materials?.filter((_: any) => _.child_id === childMaterialId)?.[0]?.material, isMaterial: true };
                } else if (childBOMId && childBOMId !== "null") {
                    data = { ...data.bom_children?.filter((_: any) => _.child_id === childBOMId)?.[0]?.bom, isMaterial: false };
                };

                setState(data);
            } catch (err) {
                console.log('err:', err)
            }
        }

        dispatch(closeBackdrop());
    };

    React.useEffect(() => {
        fetchDetails();
        // eslint-disable-next-line
    }, [bomId]);

    return <>
        {state && <Box>
            <table>
                <tbody>
                    <tr>
                        <td>
                            <Typography variant="body2" sx={{ fontWeight: "bold" }} gutterBottom >{state.isMaterial ? 'Material ID' : 'BOM ID'}</Typography>
                        </td>
                        <td>
                            <Typography variant="body2" gutterBottom >{`: ${state?.sequence_id ?? "-"} r${state.version ?? "0"}`}</Typography>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <Typography variant="body2" sx={{ fontWeight: "bold" }} gutterBottom >Short Description</Typography>
                        </td>
                        <td>
                            <Typography variant="body2" gutterBottom >{`: ${state?.short_description ?? "-"}`}</Typography>
                        </td>
                    </tr>
                    {state.isMaterial && <tr>
                        <td>
                            <Typography variant="body2" sx={{ fontWeight: "bold" }} gutterBottom >UOM Type</Typography>
                        </td>
                        <td>
                            <Typography variant="body2" gutterBottom >{`: ${CapitalizeString(state?.uom_type ?? "")}`}</Typography>
                        </td>
                    </tr>}
                    <tr>
                        <td>
                            <Typography variant="body2" sx={{ fontWeight: "bold" }} gutterBottom >{`Region`}</Typography>
                        </td>
                        <td>
                            {state.unit_region_name && <Typography variant="body2" gutterBottom >{`: ${state?.unit_region_name ?? "-"}`}</Typography>}
                            {state.uom_name && <Typography variant="body2" gutterBottom >{`: ${state?.uom_name ?? "-"}`}</Typography>}
                            {!state.unit_region_name && !state.uom_name && <Typography variant="body2" gutterBottom >{`: -`}</Typography>}
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <Typography variant="body2" sx={{ fontWeight: "bold" }} gutterBottom >{`Group`}</Typography>
                        </td>
                        <td>
                            <Typography variant="body2" gutterBottom >{`: ${state?.group_name ?? "-"}`}</Typography>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <Typography variant="body2" sx={{ fontWeight: "bold" }} gutterBottom >{`Noun Name`}</Typography>
                        </td>
                        <td>
                            <Typography variant="body2" gutterBottom >{`: ${state?.noun_name ?? "-"}`}</Typography>
                        </td>
                    </tr>
                    {state?.attribute_details?.map((_: any) => {
                        return <tr>
                            <td>
                                <Typography variant="body2" sx={{ fontWeight: "bold" }} gutterBottom >{`${_?.attr_name ?? "-"}`}</Typography>
                            </td>
                            <td>
                                <Typography variant="body2" gutterBottom >{`: ${_?.option_name ?? "-"}`}</Typography>
                            </td>
                        </tr>
                    })}
                    <tr>
                        <td>
                            <Typography variant="body2" sx={{ fontWeight: "bold" }} gutterBottom >{`Notes`}</Typography>
                        </td>
                        <td>
                            <Typography variant="body2" gutterBottom >{`: ${state?.notes ?? "-"}`}</Typography>
                        </td>
                    </tr>
                </tbody>
            </table>

            <Link title="Click to open in new tab" target="_blank" to={!state.isMaterial ? AppRoutes.viewBomBasicDetail(state?._id) : AppRoutes.viewMatBasicDetail(state?._id)}>{"View More Details"} <OpenInNewOutlined sx={{ fontSize: "12px", marginBottom: "-2px" }} fontSize="small" /></Link>
        </Box>}
    </>;
}