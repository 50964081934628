import React, { useState } from 'react';
import { Box, Button, Grid, Alert, Typography, useMediaQuery, Divider, Tabs, Tab, DialogTitle, DialogContent, DialogActions } from "@mui/material";
import { GridRowParams, GridSortDirection, GridSortModel } from "@mui/x-data-grid-premium";
import { LinkDocHeader, LinkDocHeaderProps, CustomDataGrid, SearchFilter } from '../../components';
import { DashboardTableColumn } from "./utils";
import { useDispatch } from "react-redux";
import { closeDialog } from "../../redux/slices/dialog";
import { closeConfirmationDialog, openConfirmationDialog } from "../../redux/slices/confirmationDialog";
import { LinkFilter, SelectBoxOption } from "../../interfaces";
import { useGetLinkDocumentTagsQuery, useLazyGetDocumentsBySearchQuery, useLazyGetDocumentsQuery } from "../../redux/services";

interface ILinkDoc {
    linkDocHeaderProps?: LinkDocHeaderProps;
    callLinkDocument?: any;
    associations: any;
    refetch?: any;
    isPdfOnly?: boolean;
    parentData?: LinkFilter;
}

const LinkedWithdropdown = [{ value: 'project', label: 'Project' },
{ value: 'material', label: 'Material' },
{ value: 'vendor', label: 'Supplier' },
{ value: 'proposal', label: 'Proposal' },
{ value: 'product', label: 'Product' },
];

const SearchDocuments: React.FC<ILinkDoc> = ({ associations = [], isPdfOnly = false, ...props }) => {

    const dispatch = useDispatch();
    const [getDocumentsBySearch, { data: searchData = { data: [], totalCount: 0 }, ...searchedData }] = useLazyGetDocumentsBySearchQuery();
    const { data, totalCount } = searchData;

    const [links, setLinks] = React.useState<string[]>([]);
    const [paginationModel, setPaginationModel] = React.useState({
        page: 0,
        pageSize: 10,
    });
    const [queryOptions, setQueryOptions] = React.useState({
        sortModel: [{ field: 'sequence_id', sort: 'desc' as GridSortDirection }],
    });

    const isMatch = useMediaQuery("(max-width: 600px)");

    const [search, setSearch] = useState("")

    const onCancelBtnClicked = () => {
        dispatch(closeDialog());
    }

    const linkDocumentCall = () => {
        dispatch(closeConfirmationDialog());
        dispatch(closeDialog());
        props.callLinkDocument(links, props.refetch, [], "document")
    }

    const onLinkBtnClicked = () => {
        dispatch(openConfirmationDialog({
            title: "Link Operation",
            body: `Are you sure you want to link the selected items`,
            positiveBtn: "Yes",
            negativeBtn: "Cancel",
            onOk: () => linkDocumentCall(),
            onNegativeBtn: () => dispatch(closeConfirmationDialog())
        }));
    }

    const handleSortModelChange = React.useCallback((sortModel: GridSortModel) => {
        // Here you save the data you need from the sort model
        setQueryOptions({ sortModel: [...sortModel] });
    }, [])

    const onSearch = () => {
        if (search.trim()) {
            getDocumentsBySearch({ 
                search, 
                ...paginationModel, 
                object_id: props?.parentData?.object_id, 
                object_type: props?.parentData?.object_type,
                sort_by: queryOptions?.sortModel?.[0]?.field, 
                sort_order: queryOptions?.sortModel?.[0]?.sort
            })
        }
    }

    const isRowSelectable = (row: any) => {
        const assocArray: any = associations.filter((obj: any) => obj.object_type === "document");
        let isSelectable = true;

        try {
            for (let index = 0; index < assocArray?.length; index++) {
                const asso = assocArray?.[index];
                isSelectable = !(asso.object_details.map((_: any) => _._id).includes(row._id));
                if (!isSelectable) {
                    break;
                }
            }
        } catch (error) {
            console.error("error", error);
        }

        if (row?.file_name?.split(".")?.at(-1) !== 'pdf' && isPdfOnly) {
            isSelectable = false;
        }

        return isSelectable;
    }

    const onRowSelection = (selected: any) => {
        setLinks(selected);
    }

    React.useEffect(() => {
        onSearch();
        // eslint-disable-next-line
    }, [])

    React.useEffect(() => {
        onSearch();
        // eslint-disable-next-line
    }, [paginationModel, JSON.stringify(queryOptions?.sortModel)])

    const isLoading = searchedData.isFetching || searchedData.isLoading;

    const giveMeRows = () => {
        if (!isLoading && data?.length > 0 && searchedData.isSuccess) {
            return data ?? [];
        } else {
            return [];
        }
    }

    let ROWS = giveMeRows();

    return <>
        <DialogContent>    
            <Grid container item xs={12} spacing={1} sm={12} md={12} sx={{ display: "flex", alignItems: "center", justifyContent: "flex-end", marginTop: "10px" }}>
                <Grid item xs={12} sm={6}>
                    <SearchFilter
                        searchPlaceholder={"Search for Documents"}
                        handleChange={setSearch}
                        value={search}
                    />
                </Grid>

                <Grid item xs={12} md={2}>
                    <Button
                        variant={"contained"}
                        color={"primary"}
                        fullWidth
                        onClick={onSearch}
                        sx={{ fontSize: (theme) => theme.spacing(1.75), paddingX: "4px" }}
                    >
                        Search
                    </Button>
                </Grid>

            </Grid>

            {searchedData.isError && <Alert sx={{ mt: 2 }} severity="error">Oops! Something went wrong, Unable to fetch. Try Again Later!</Alert>}

            <Box mt={2}>
                <CustomDataGrid
                    saveLocal={false}
                    rows={ROWS}
                    loading={isLoading}
                    columns={DashboardTableColumn()}
                    getRowId={(row) => row._id}
                    showToolbar={false}
                    checkboxSelection={true}
                    onRowSelectionModelChange={(selected) => { onRowSelection(selected) }}
                    isRowSelectable={(params: GridRowParams) => isRowSelectable(params.row)}
                    rowCount={totalCount}
                    paginationModel={paginationModel}
                    paginationMode="server"
                    onPaginationModelChange={setPaginationModel}
                    sortingMode="server"
                    onSortModelChange={handleSortModelChange}
                />
            </Box>
        </DialogContent>

        <DialogActions>
            <Grid container xs={12} spacing={1} sm={12} md={12} sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
            }} pb={1}>
                <Grid item xs={12} sm={6}></Grid>
                <Grid item xs={12} sm={3}>
                    <Button
                        variant={"contained"}
                        color={"primary"}
                        fullWidth
                        onClick={onLinkBtnClicked}
                        disabled={links.length === 0}
                        sx={{ fontSize: (theme) => theme.spacing(1.75), paddingX: "4px", right: isMatch ? 0 : 20, width: isMatch ? "80%" : "100%", transform: isMatch ? "translateX(10%)" : "translateX(0)" }}
                    >
                        Link
                    </Button>
                </Grid>
                <Grid item xs={12} sm={3}>
                    <Button
                        variant={"outlined"}
                        color={"primary"}
                        fullWidth
                        onClick={onCancelBtnClicked}
                        disabled={false}
                        sx={{ fontSize: (theme) => theme.spacing(1.75), paddingX: "4px", right: isMatch ? 0 : 20, width: isMatch ? "80%" : "100%", transform: isMatch ? "translateX(10%)" : "translateX(0)" }}
                    >
                        Cancel
                    </Button>
                </Grid>
            </Grid>
        </DialogActions>
    </>
}

const FilterDocuments: React.FC<ILinkDoc> = (props) => {

    const dispatch = useDispatch();
    const [getDocuments, { data: allData = { data: [], totalCount: 0 }, isLoading, isFetching, isError }] = useLazyGetDocumentsQuery({});
    const { data, totalCount } = allData;

    const [paginationModel, setPaginationModel] = React.useState({
        page: 0,
        pageSize: 10,
    });
    const [queryOptions, setQueryOptions] = React.useState({
        sortModel: [{ field: 'sequence_id', sort: 'desc' as GridSortDirection }],
    });

    const [revision, setRevision] = React.useState(false);
    const [documentType, setDocType] = React.useState<SelectBoxOption | null>(null);
    const [tags, setTags] = React.useState<SelectBoxOption | null>(null);
    const [linkedWith, setLinkedWith] = React.useState<SelectBoxOption | null>(null);
    const tagsData = useGetLinkDocumentTagsQuery(null, { refetchOnMountOrArgChange: true, refetchOnFocus: true });
    const folderDropDown = tagsData?.data?.folderTags;
    const docTypeDropdown = tagsData?.data?.documentTypeTags;
    const [links, setLinks] = React.useState([]);

    const isMatch = useMediaQuery("(max-width: 600px)");

    const documentTypeDropdown = [{ value: tagsData?.data?.FolderId, label: 'Folders' }, { value: tagsData?.data?.DocumentTypeId, label: 'Document Type' }];

    const handleSortModelChange = React.useCallback((sortModel: GridSortModel) => {
        // Here you save the data you need from the sort model
        setQueryOptions({ sortModel: [...sortModel] });
    }, [])

    React.useEffect(() => {
        getDocuments({ ...paginationModel, object_id: props?.parentData?.object_id, object_type: props?.parentData?.object_type, sort_by: queryOptions?.sortModel?.[0]?.field, sort_order: queryOptions?.sortModel?.[0]?.sort });
        // eslint-disable-next-line
    }, [paginationModel, JSON.stringify(queryOptions?.sortModel)])

    const onCancelBtnClicked = () => {
        dispatch(closeDialog());
    }

    const tagGroupChange = (val: SelectBoxOption | null) => {
        setTags(null);
        setDocType(val)
    }

    const linkDocumentCall = () => {
        dispatch(closeConfirmationDialog());
        dispatch(closeDialog());
        props.callLinkDocument(links, props.refetch, [], "document")
    }
    const onLinkBtnClicked = () => {
        dispatch(openConfirmationDialog({
            title: "Link Operation",
            body: `Are you sure you want to link the selected items`,
            positiveBtn: "Yes",
            negativeBtn: "Cancel",
            onOk: () => linkDocumentCall(),
            onNegativeBtn: () => dispatch(closeConfirmationDialog())
        }));
    }

    const onSearch = () => {
        const object = props?.parentData ? { object_id: props?.parentData?.object_id, object_type: props?.parentData?.object_type } : {};
        getDocuments({ tag_group_id: documentType?.value, tag_id: tags?.value, object_type: linkedWith?.value, include_history: revision, ...object, ...paginationModel, sort_by: queryOptions?.sortModel?.[0]?.field, sort_order: queryOptions?.sortModel?.[0]?.sort })
    }
    const giveMeRows = (): any[] => {
        if (!isLoading && data) {
            return data ?? [];
        } else {
            return [];
        }

    }

    const isRowSelectable = (row: any) => {
        const assocArray: any = props?.associations?.filter((obj: any) => obj.object_type === "document");
        let isSelectable = true;

        try {
            for (let index = 0; index < assocArray?.length; index++) {
                const asso = assocArray?.[index];
                isSelectable = !(asso.object_details.map((_: any) => _._id).includes(row._id));
                if (!isSelectable) {
                    break;
                }
            }
        } catch (error) {
            console.error("error", error);
        }

        if (row?.file_name?.split(".")?.at(-1) !== 'pdf' && props.isPdfOnly) {
            isSelectable = false;
        }

        return isSelectable;
    }

    let ROWS = giveMeRows();

    const onRowSelection = (selected: any) => {
        setLinks(selected);
    }

    return <>
        <DialogContent>
            {/* Header */}
            <LinkDocHeader
                title="Search and Add documents"
                revision={revision}
                setRevision={setRevision}
                documentTypeDropdown={documentTypeDropdown}
                documentType={documentType}
                setDocType={(data: SelectBoxOption) => tagGroupChange(data)}
                tags={tags}
                tagsDropdown={documentType?.label === "Folders" ? folderDropDown : docTypeDropdown}
                setTags={(data: SelectBoxOption) => setTags(data)}
                linkedWith={linkedWith}
                setLinkedWith={(data: SelectBoxOption) => setLinkedWith(data)}
                linkedWithdropdown={LinkedWithdropdown?.filter(l => {
                    if (!props?.parentData) {
                        return l;
                    } else {
                        return l?.value === props?.parentData?.object_type
                    }
                })}
                onSearch={onSearch}
                tagsLoading={tagsData.isLoading || tagsData.isFetching}
                {...props.linkDocHeaderProps}
            />

            {isError && <Alert sx={{ mt: 2 }} severity="error">Oops! Something went wrong, Unable to fetch Documents. Try Again Later!</Alert>}

            <Box mt={2}>
                <CustomDataGrid
                    saveLocal={false}
                    rows={ROWS}
                    loading={isLoading || isFetching}
                    columns={DashboardTableColumn()}
                    getRowId={(row) => row._id}
                    showToolbar={false}
                    checkboxSelection={true}
                    onRowSelectionModelChange={(selected) => { onRowSelection(selected) }}
                    isRowSelectable={(params: GridRowParams) => isRowSelectable(params.row)}
                    rowCount={totalCount}
                    paginationModel={paginationModel}
                    paginationMode="server"
                    onPaginationModelChange={setPaginationModel}
                    sortingMode="server"
                    onSortModelChange={handleSortModelChange}
                />
            </Box>
        </DialogContent>
        <DialogActions>
            <Grid container item xs={12} spacing={1} sm={12} md={12} sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
            }} pb={1}>
                <Grid item xs={12} sm={6}></Grid>
                <Grid item xs={12} sm={3}>
                    <Button
                        variant={"contained"}
                        color={"primary"}
                        fullWidth
                        onClick={onLinkBtnClicked}
                        disabled={links.length === 0}
                        sx={{ fontSize: (theme) => theme.spacing(1.75), paddingX: "4px", right: isMatch ? 0 : 20, width: isMatch ? "80%" : "100%", transform: isMatch ? "translateX(10%)" : "translateX(0)" }}
                    >
                        Link
                    </Button>
                </Grid>
                <Grid item xs={12} sm={3}>
                    <Button
                        variant={"outlined"}
                        color={"primary"}
                        fullWidth
                        onClick={onCancelBtnClicked}
                        disabled={false}
                        sx={{ fontSize: (theme) => theme.spacing(1.75), paddingX: "4px", right: isMatch ? 0 : 20, width: isMatch ? "80%" : "100%", transform: isMatch ? "translateX(10%)" : "translateX(0)" }}
                    >
                        Cancel
                    </Button>
                </Grid>
            </Grid>
        </DialogActions>
    </>
}

const allTabs: string[] = ['allDocuments', 'searchDocuments'];

const ViewLinkDoc: React.FC<ILinkDoc> = (props) => {

    const [selectedTab, updateTab] = useState(allTabs[0]);

    return (
        <>
            <DialogTitle>
                <Typography gutterBottom variant="body1" color={"textPrimary"} sx={{ display: "flex", fontWeight: 600 }}>
                    Search and Add documents
                </Typography>
                <Divider variant="fullWidth" sx={{ mb: 2 }} />
                <Tabs
                    value={selectedTab}
                    onChange={(e, tab) => updateTab(tab)}
                    indicatorColor="primary"
                    textColor="primary"
                >
                    <Tab value={allTabs[0]} label="All Documents" />
                    <Tab value={allTabs[1]} label="Search By Document" />
                </Tabs>
            </DialogTitle>

            {selectedTab === allTabs[0] && <FilterDocuments {...props} />}
            {selectedTab === allTabs[1] && <SearchDocuments {...props} />}
        </>
    );
};

export default ViewLinkDoc;