import { Box, LinearProgress, Stack, Typography } from "@mui/material";
import { PDFDocument, StandardFonts } from "pdf-lib";
import React from "react";
import { useAppDispatch } from "../../redux";
import { useLazyGetDocumentTemplateByIdQuery } from "../../redux/services";
import { closeBackdrop, openBackdrop } from "../../redux/slices/backdrop";
import { drawBOMPage, drawMaterialPage, drawDocumentImagePage, drawDocumentPdfPage } from "pdf-generator";
import { useSnackbar } from "notistack";
import PdfView from "../dataSheetPDFPreview/pdfView";

type Preview = "bom" | "material" | "image" | "pdf" | "datasheet" | "none";


// let htmlTagsMatch = new RegExp(/<(\"[^\"]*\"|'[^']*'|[^'\">])*>/);

let previewCount = 30;

export const PDFPreview: React.FC<{ data: any, type: Preview, id?: string }> = ({ data, type, id = "" }) => {
  const [pdfInfo, setPdfInfo] = React.useState<any>('');

  const [totalPages, setTotalPages] = React.useState<number>(0);

  const [getTemplateById, { data: templateData, status, ...rest }] =
    useLazyGetDocumentTemplateByIdQuery({});

  const dispatch = useAppDispatch();

  const { enqueueSnackbar } = useSnackbar();

  const [showToolbar, setShowToolbar] = React.useState(true);

  const [generatePdf, setGeneratePDF] = React.useState(false);

  React.useEffect(() => {
    setGeneratePDF(false);
    if (!id) {
      setGeneratePDF(true);
    }
    // eslint-disable-next-line
  }, [data]);

  React.useEffect(() => {
    if (id !== "isNew" && id) {
      fetchTemplate(id);
    }
    // eslint-disable-next-line
  }, [id]);

  React.useEffect(() => {
    if (Object.keys(data || {}).length > 0) {
      setTotalPages(0);
      if (generatePdf) {
        createPdf(data);
      }
    }
    // eslint-disable-next-line
  }, [data, status, generatePdf]);

  const fetchTemplate = async (id: string) => {
    try {
      await getTemplateById({ document_template_id: id })
      setGeneratePDF(true);
    } catch (error: any) {
      enqueueSnackbar("unable to fetch the template data to generate PDF.", { variant: "error" });
      console.error(`Error fetching template: ${error}`)
      setGeneratePDF(true);
    };
  }

  const setPageCount = (count: number) => {
    setTotalPages(count);
  }

  const createPdf = async (data: any) => {
    setPdfInfo('');
    const pdfDoc = await PDFDocument.create();
    const helveticaFont = await pdfDoc.embedFont(StandardFonts.Helvetica);

    const size = id ? templateData?.paper_size || "A4" : "A4";

    let imageDimension = { width: 150, height: 150 };

    if (type === "material") {
      let url = data?.attachments?.[0]?.url || '';
      await drawMaterialPage(pdfDoc, helveticaFont, data, size, imageDimension, id ? templateData : {}, url)
    }

    if (type === "bom") {
      let url = data?.attachments?.[0]?.url || '';
      await drawBOMPage(pdfDoc, helveticaFont, data, size, imageDimension, id ? templateData : {}, url)
    }

    if (type === "pdf") {
      // Documents
      let url = data?.mainContent?.url || '';
      if (url) {
        try {
          const pdfBytes = await fetch(decodeURIComponent(url)).then((res) =>
            res.arrayBuffer()
          )
          const pdf = await PDFDocument.load(pdfBytes);
          await drawDocumentPdfPage(pdfDoc, helveticaFont, data, pdf, size, id ? templateData : {}, previewCount, setPageCount)
        } catch (error: any) {
          setShowToolbar(false);
          console.error(`error while fetching document: ${error}`)
        };
      }
    }

    if (type === "image") {
      // Documents Image
      let url = data?.mainContent?.url || '';
      await drawDocumentImagePage(pdfDoc, helveticaFont, data, url, size, id ? templateData : {})
    }

    // Change the title
    pdfDoc.setTitle(`${data?.mainContent?.file_name ? data?.mainContent?.file_name : data?.sequence_id ? `${data?.sequence_id}-R${data?.version}` : data?._id}`);

    const pdfBytes = await pdfDoc.save();
    const bytes = new Uint8Array(pdfBytes);
    const blob = new Blob([bytes], { type: "application/pdf" });
    const docUrl = URL.createObjectURL(blob);
    await fetch(docUrl).then(() => {
      setPdfInfo(docUrl);
    }).catch((error: any) => {
      console.error(`Error loading document on preview: ${error}`)
    })
  };
  
  const isLoading = !((rest.isError || rest.isSuccess || (!id && rest.isUninitialized)) && pdfInfo.toString().length > 0)

  React.useEffect(() => {
    if (isLoading) {
      dispatch(openBackdrop("Generating pdf..."))
    } else {
      dispatch(closeBackdrop());
    }
    // eslint-disable-next-line
  }, [isLoading]);

  return (
    <Stack height={"90%"}>
      <Box height={"80vh"}>
        {!isLoading
          ?
            type === "datasheet" ? <PdfView details={{ sequenceId: data?.sequence_id, version: data?.version, manufactureNo: data?.vendor?.part_number ?? "", manufactureName: data?.vendor?.manufacturer_name ?? "", short_description: data?.short_description ?? "" }} dataSheet={{ data: data?.mainContent }} title={data?.mainContent?.name} templateData={templateData} orientation={templateData?.orientation} paperSize={templateData?.paper_size} />:  <iframe src={`${pdfInfo}#toolbar=${showToolbar ? 1 : 0}`} width={"100%"} height={"100%"} title={data?.mainContent?.name} />
          :
            <LinearProgress />
        }
      </Box>
      {totalPages > 0 && <Typography variant="body2" color={"primary"} sx={{ position: 'relative', bottom: '-25px', fontFamily: "htrts_bold", textAlign: 'center', verticalAlign: "middle" }}>{`*The preview is limited to ${previewCount} pages, to reduce the latency, the actual document has ${totalPages} pages.`}</Typography>}
    </Stack>
  );
};
