import { ChevronLeft, Save } from "@mui/icons-material"
import { Box, Button, Stack } from "@mui/material"
import React from "react"
import { MyPaper } from "../../../components"
import { useAppDispatch, useAppSelector } from "../../../redux"
import { navigateTab } from "../../../redux/slices/pmp"
import { DependencyMap } from "./dependencyMap"

export const Summary: React.FC<{ children?: JSX.Element }> = (props) => {

    const dispatch = useAppDispatch();
    const { selections } = useAppSelector(store => store.pmp);

    const lastSelIndex = selections.length - 1;
    const lastSelLastOptIndex = selections[lastSelIndex].options.length - 1;

    return <Box sx={{ height: "100%" }}>
        <Box sx={{ height: "calc(100% - 96px)", overflow: "scroll" }}>
            <MyPaper>
                <DependencyMap
                    option={selections[lastSelIndex].options[lastSelLastOptIndex]}
                    selIndex={lastSelIndex + 1}
                    selection={selections[lastSelIndex]}
                    optIndex={lastSelLastOptIndex}
                    hideSaveBtn={true}
                    height={"auto"}
                    pt={0}
                    leftTitle={"Option Selection"}
                    rightTitle={"Total Configuration"}
                />
            </MyPaper>
        </Box>


        <Box mt={2}>
            <MyPaper>
                <Stack spacing={1} p={1} direction={"row"} alignItems={"center"} justifyContent={"center"}>
                    <Button onClick={(e) => dispatch(navigateTab(2))} size={"small"} variant="outlined" color="primary" startIcon={<ChevronLeft />}>Back</Button>
                    <Button onClick={(e) => dispatch(navigateTab(3))} size={"small"} variant="contained" color="primary" startIcon={<Save />}>Save</Button>
                </Stack>
            </MyPaper>
        </Box>
    </Box>
} 