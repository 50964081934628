import { Box, Button, Stack, Typography } from "@mui/material";
import React from "react";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { AntSwitch, DataSheetPDFPreview, HighlightSelectBox, paperSizes, SectionPaper } from "../../../components";
import { reorder } from "../../../utils";
import { CategoryListItem } from "./categoryListItem";
import { CheckListCategories, CheckListContext, CheckListData } from "./../viewCheckListDetail";
import { useSnackbar } from "notistack";
import { closeDialog, openDialog } from "../../../redux/slices/dialog";
import { useAppDispatch } from "../../../redux";
import { SelectBoxOption } from "../../../interfaces";
import { orientationOptions } from "../../documentTemplates/dashboard/addEditTemplate";

export const Categories: React.FC<{ children?: JSX.Element, dataSheet?: CheckListData }> = ({ dataSheet }) => {

    const { state, updateState } = React.useContext(CheckListContext);

    const { enqueueSnackbar } = useSnackbar();

    const dispatch = useAppDispatch();

    const [paperSize, setPaperSize] = React.useState<SelectBoxOption | null>(paperSizes[6]);
    const [orientation, setOrientation] = React.useState<SelectBoxOption | null>(orientationOptions[0]);
    const [showRuler, setShowRuler] = React.useState(false);

    const limit = 15;

    const addCategory = () => {
        let categoryLength = state.categories?.length ?? 0;
        if (categoryLength >= limit) {
            enqueueSnackbar(
                `You can only have up to ${limit} tables. Adding more isn't allowed.`,
                { variant: "error" }
            );
        } else {
            let newCategories: CheckListCategories[] = [
                ...(state.categories ?? []),
                {
                    name: `Table ${categoryLength + 1}`,
                    description: '',
                    header_color: 'rgb(230, 229, 229)',
                    definition: {
                        columns: [
                            {
                                field: 'column',
                                headerName: 'Column 1',
                                headerClassName: `header-${0}`,
                                width: 230,
                                editable: true
                            }
                        ],
                        rows: []
                    },
                    position: categoryLength + 1
                }
            ];
            updateState('categories', [...newCategories]);
        }
    };

    const onDragEnd = (result: any) => {
        if (!result.destination) {
            return;
        }
        if (result.source.index === result.destination.index) {
            return;
        }

        const newCategories: CheckListCategories[] = reorder(
            state.categories,
            result.source.index,
            result.destination.index,
        );
        updateState('categories', [...newCategories]);
    }


    const generatePreviewHandler = () => {
        const title = dataSheet?.data?.name ?? "Data Sheet Preview";
        dispatch(
            openDialog({
                title: "",
                hideNegativeBtn: true,
                hideCloseIcon: true,
                maxWidth: "lg",
                enablePadding: false,
                body: <Stack p={1} height={"90vh"}><DataSheetPDFPreview title={title} dataSheet={dataSheet} selectedSize={paperSize || undefined} selectedOrientation={orientation || undefined} /></Stack>,
                onOk: () => dispatch(closeDialog())
            })
        );
    }

    const onChangeSwitch = (e: React.ChangeEvent<HTMLInputElement>, isChecked: boolean) => {
        setShowRuler(isChecked)
    }

    return (<SectionPaper
        title="Tables"
        primaryBtn={{
            title: "Add Table",
            onClick: addCategory,
            variant: "contained",
            color: "primary"
        }}
        renderButtons={[
            <Stack direction={"row"} spacing={1} alignItems={"center"} justifyContent={"flex-start"}>
                <Typography
                    variant="body1"
                    fontFamily={"htrts_medium"}>
                    Show Ruler
                </Typography>
                <AntSwitch sx={{ ml: 2 }} size="small" color="primary" checked={showRuler} onChange={onChangeSwitch} />
            </Stack>,
            <Box sx={{ width: 200 }}>
                <HighlightSelectBox
                    label={"Paper Size"}
                    margin={"none"}
                    fullWdith
                    value={paperSize}
                    options={paperSizes}
                    onChange={(option: SelectBoxOption) => { setPaperSize(option) }}
                />
            </Box>,
            <Box sx={{ width: 200 }}>
                <HighlightSelectBox
                    label={"Orientation"}
                    margin={"none"}
                    fullWdith
                    value={orientation}
                    options={orientationOptions}
                    onChange={(option: SelectBoxOption) => { setOrientation(option) }}
                />
            </Box>,
            dataSheet ? <Button
                disabled={dataSheet?.isLoading || dataSheet?.isFetching}
                size="small"
                sx={{ width: 200 }}
                variant={"outlined"}
                onClick={generatePreviewHandler}
            >
                Generate Preview
            </Button> : <></>
        ]}
    >
        {/* Details */}
        <DragDropContext onDragEnd={onDragEnd}>
            <Droppable
                droppableId="categoryList"
                mode="virtual"
            >
                {(droppableProvided: any) => (
                    <Stack spacing={1} ref={droppableProvided.innerRef} sx={{ overflow: "scroll" }}>
                        {state.categories?.map((category, key: number) => {
                            return <CategoryListItem category={category} index={key} key={key} size={paperSize} orientation={orientation} showRuler={showRuler} />
                        })}
                        {droppableProvided.placeholder}
                    </Stack>
                )}
            </Droppable>
        </DragDropContext>
    </SectionPaper>
    );
};