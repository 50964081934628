import { FiberManualRecord, LockOutlined } from '@mui/icons-material';
import { Box, Collapse, List, ListItemButton, ListItemIcon, ListItemText, Stack, SxProps, Theme, Tooltip, Typography, useTheme } from '@mui/material';
import React, { useContext } from 'react';
import { matchPath, useLocation, useNavigate } from 'react-router-dom';
import { ThemeContext } from '../../contexts';
import { RightVector } from '../../images';
import { useAppDispatch, useAppSelector } from '../../redux';
import { setOpenMobileSideNavBar, setOpenWebSideNavBar } from '../../redux/slices/utils';
import { NavBars, Themes } from '../../utils';

export const navbarWidths = {
    web: 296,
    webCollapse: 72,
    mobile: 256,
    topNavHeight: 68
};

interface Props { isMobile: boolean }

const useStyles = (props: any, theme: Theme, isLight: boolean): { [key: string]: SxProps<Theme> | any } => ({
    root: {
        width: props.isMobile ? navbarWidths.mobile : props.openWebSideNavBar ? navbarWidths.web : navbarWidths.webCollapse,
        height: props.isMobile ? `100%` : `calc(100% - ${navbarWidths.topNavHeight}px)`,
        transition: 'all 0.5s',
        position: 'absolute',
        overflowY: "auto",
        overflowX: "hidden",
        backgroundColor: isLight ? theme.palette.background.paper : "#292E32"
    },
    navListItem: (isSelected: boolean | undefined) => ({
        '&:hover': {
            backgroundColor: isLight ? '#D9C4FE80' : '#F2F3F81A',
            '.MuiListItemIcon-root, .MuiTypography-root, .MuiSvgIcon-root': { color: isLight ? theme.palette.primary.main : theme.palette.common.white }
        },
        borderRadius: '8px',
        px: '8px',
        paddingLeft: !props.isMobile && !props.openWebSideNavBar ? '11px' : '',
        margin: '8px 16px',
        backgroundColor: isSelected ? isLight ? '#D9C4FE80' : '#F2F3F81A' : ''
    })
});

export const SideNavBar: React.FC<Props> = (props) => {


    const navigate = useNavigate();
    const location = useLocation();

    const { userDetails } = useAppSelector(store => store.auth);
    const { openMobileSideNavBar, openWebSideNavBar } = useAppSelector(store => store.utils.sideNavBar);
    const dispatch = useAppDispatch();
    const themeContext = useContext(ThemeContext);

    const isLight = themeContext.name === Themes.default;

    const classes = useStyles({ isMobile: props.isMobile, openWebSideNavBar }, useTheme(), isLight);

    const toogleWebSideNavBar = () => dispatch(setOpenWebSideNavBar(!openWebSideNavBar));

    const toogleMobileSideNavBar = () => dispatch(setOpenMobileSideNavBar(!openMobileSideNavBar));

    const [state, setState] = React.useState<{ collapeIds: any[] }>({ collapeIds: [] });

    const isSelected = (data: any) => {
        if (data?.path && !data?.subPaths) {
            return matchPath(location?.pathname, data?.path?.split('?')?.[0]) ? true : false;
        } else {
            let isSelected = false;
            let routes = data.subPaths ? data.subPaths : data.routes;
            for (let i = 0; i < routes.length; i++) {
                const route = routes[i];
                if (matchPath(location?.pathname, route) ? true : false) {
                    isSelected = true;
                    break;
                }
            }
            return isSelected;
        }
    }

    const onListClick = (data: any) => {
        if (data.path) {
            navigate(data.path)
            if (props.isMobile) {
                toogleMobileSideNavBar();
            }
        } else {
            let index = state.collapeIds.indexOf(data.id);
            let collapeIds = state.collapeIds;
            if (index > -1) {
                collapeIds.splice(index, 1);
            } else {
                collapeIds.push(data.id);
            }
            setState({ ...state, collapeIds });
            if (!props.isMobile && !openWebSideNavBar) {
                toogleWebSideNavBar();
            }
        }
    };

    const canIShowAsSelected = (isSelectedNav: boolean, subNavCount: number = 0) => {
        if (subNavCount > 0) {
            if (!openWebSideNavBar) {
                return isSelectedNav;
            } else {
                return false;
            }
        }
        return isSelectedNav;
    };

    React.useEffect(() => {
        if (state.collapeIds.length === 0) {
            let path = location.pathname;
            let availableNavBars = NavBars(userDetails?.perm ?? {}).filter(_ => _.is_active === true);
            for (let x = 0; x < availableNavBars.length; x++) {
                const nav = availableNavBars[x];
                let collapeIds = [];
                if (nav.routes?.includes(path)) {
                    collapeIds.push(nav.id);

                    if (nav.subNavBar.length) {
                        for (let y = 0; y < nav.subNavBar.length; y++) {
                            const subNav: any = nav.subNavBar[y];
                            if (subNav?.routes?.includes(path)) {
                                collapeIds.push(subNav.id);
                                break;
                            }
                        }
                    }
                    setState({ ...state, collapeIds })
                    break;
                }
            }
        }
        //eslint-disable-next-line
    }, []);

    return (
        <Box sx={classes.root}>

            <Typography
                variant='overline'
                sx={{
                    fontFamily: "htrts_semibold",
                    paddingLeft: openWebSideNavBar ? 4 : 2,
                    color: (theme) => isLight ? '#637381' : '#7C7F90',
                }}>Menu</Typography>

            <List component="nav">
                {NavBars(userDetails?.perm ?? {})
                    .filter(_ => _.is_active === true)
                    .map((navBar: any, key: number) => {

                        let isSelectedNav = isSelected(navBar);

                        return <Box key={key}>
                            <Tooltip placement='right' title={`${navBar.title} ${navBar.isBeta ? '(Beta)' : ''}`}>
                                <ListItemButton
                                    sx={classes.navListItem(canIShowAsSelected(isSelectedNav, navBar?.subNavBar?.length ?? 0))}
                                    onClick={(e) => navBar.disabled ? false : onListClick(navBar)}
                                >

                                    {/* Nav Icon */}
                                    <ListItemIcon sx={{
                                        minWidth: "36px", ".MuiSvgIcon-root": {
                                            color: (theme) => isLight ?
                                                isSelectedNav ? theme.palette.primary.main : '#637381'
                                                : isSelectedNav ? theme.palette.common.white : '#7C7F90'
                                        }
                                    }}>
                                        {navBar.icon}
                                    </ListItemIcon>

                                    {/* Nav Title */}
                                    {(props.isMobile || openWebSideNavBar)
                                        && <ListItemText
                                            primaryTypographyProps={{
                                                variant: "body2",
                                                fontFamily: "htrts_medium",
                                                color: (theme) => isLight ?
                                                    isSelectedNav ? theme.palette.primary.main : '#637381'
                                                    : isSelectedNav ? theme.palette.common.white : '#7C7F90'
                                            }}
                                            primary={<Stack direction={"row"} alignItems={"center"}>
                                                {navBar.title}
                                                {navBar.isBeta && <img style={{ marginLeft: "8px" }} src={'/beta.gif'} alt='In Beta' />}
                                            </Stack>}
                                        />
                                    }

                                    {/* Expand & Collapse Icon */}
                                    {(props.isMobile || openWebSideNavBar) && navBar?.subNavBar?.length > 0 && <>
                                        <RightVector sx={{
                                            rotate: state.collapeIds.indexOf(navBar.id) > -1 ? "90deg" : "",
                                            transition: 'all 0.5s',
                                            fontSize: 12,
                                            color: (theme) => isLight ?
                                                isSelectedNav ? theme.palette.primary.main : '#637381'
                                                : isSelectedNav ? theme.palette.common.white : '#7C7F90'
                                        }} />
                                    </>}

                                    {/* Lock Icon */}
                                    {navBar.disabled && <LockOutlined fontSize='small' color="disabled" />}

                                </ListItemButton>
                            </Tooltip>

                            {/* Level 1 Sub Navbars */}
                            {openWebSideNavBar && <Collapse sx={{ '& .MuiCollapse-wrapper': { zIndex: 2 } }} in={state.collapeIds.indexOf(navBar.id) > -1} timeout="auto" unmountOnExit>
                                <List component="div" disablePadding>
                                    {navBar.subNavBar?.filter((_: any) => _.is_active === true)?.map((subNav: any, key: number, arr: any[]) => {
                                        let isSelectedSubNav = isSelected(subNav);
                                        return <Box key={key} sx={{ paddingBottom: (props.isMobile || openWebSideNavBar) ? '0px' : '4px' }}>
                                            <Tooltip enterDelay={0} placement={"right"} title={`${subNav.title} ${subNav.isBeta ? '(Beta)' : ''}`}>

                                                <ListItemButton
                                                    key={key}
                                                    onClick={(e) => subNav.disabled ? false : onListClick(subNav)}
                                                    sx={{...classes.navListItem(isSelectedSubNav),   
                                                        position: "relative",
                                                        zIndex: 1,
                                                        "::before": arr?.length > 1 ? {
                                                          content: '""',
                                                          position: "absolute",
                                                          left: 0,
                                                          top: key === 0 ? "40%": key === arr?.length - 1 ? undefined :0,
                                                          bottom: key === arr?.length - 1 ? "39% !important" : undefined,
                                                          transform: "translate(733%, 0%)",
                                                          width: "3px",
                                                          height: "115%",
                                                          zIndex: -1,
                                                          backgroundColor: isLight
                                                              ?"#D9D9D9"
                                                              : "#7C7F90",
                                                        } : {},
                                                    }}
                                                    disabled={subNav.disabled}
                                                >
                                                    {/* Nav Icon */}
                                                    <ListItemIcon sx={{
                                                        minWidth: "36px", ".MuiSvgIcon-root": {
                                                            color: (theme) => isLight ?
                                                                isSelectedSubNav ? theme.palette.primary.main : '#D9D9D9'
                                                                : isSelectedSubNav ? theme.palette.common.white : '#7C7F90'
                                                        }
                                                    }}>
                                                        <FiberManualRecord sx={{ fontSize: "16px" }} />
                                                    </ListItemIcon>

                                                    {/* Nav Title */}
                                                    <ListItemText
                                                        primaryTypographyProps={{
                                                            variant: "body2",
                                                            fontFamily: "htrts_medium",
                                                            color: (theme) => isLight ?
                                                                isSelectedSubNav ? theme.palette.primary.main : '#637381'
                                                                : isSelectedSubNav ? theme.palette.common.white : '#7C7F90'
                                                        }}
                                                        primary={<Stack direction={"row"} alignItems={"center"}>
                                                            {subNav.title}
                                                            {subNav.isBeta && <img style={{marginLeft: "8px"}} src={'/beta.gif'} alt='In Beta' />}
                                                        </Stack>}
                                                    />

                                                    {/* Expand & Collapse Icon */}
                                                    {(props.isMobile || openWebSideNavBar)
                                                        && subNav?.subNavBar?.length > 0
                                                        && <>
                                                            {state.collapeIds.indexOf(subNav.id) > -1
                                                                ? <RightVector htmlColor={isSelectedSubNav ? 'primary' : 'textPrimary'} />
                                                                : <RightVector htmlColor={isSelectedSubNav ? 'primary' : 'textPrimary'} />}
                                                        </>
                                                    }

                                                    {/* Lock Icon */}
                                                    {subNav.disabled && <LockOutlined fontSize='small' color="disabled" />}
                                                </ListItemButton>
                                            </Tooltip>
                                        </Box>
                                    })}
                                </List>
                            </Collapse>}
                        </Box>
                    })}
            </List>
        </Box>
    );
}