import { IconButton, Tooltip, Typography } from "@mui/material";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid-premium";
import { ContentCopy, DeleteOutline } from "@mui/icons-material";
import moment from "moment";
import { TemplateResponse } from "../../../redux/slices/documentTemplate";
import { orientationOptions } from "../dashboard/addEditTemplate";

export const TableColumn = (
  edit: (data: TemplateResponse) => void,
  onDelete: (data: TemplateResponse) => void,
  copy: (row: TemplateResponse) => void,
  hasAccess: boolean
) => {
  let columns: GridColDef[] = [
    {
      field: "sequence_id",
      headerName: "Template ID",
      width: 150,
      renderCell: (params: GridRenderCellParams) => {
        if (params.rowNode.type === "group") {
          return params.value;
        }
        return (
          <Typography
            onClick={() => edit(params.row)}
            variant="body2"
            color="primary"
            sx={hasAccess ? { color: "blue", cursor: "pointer", textDecoration: "underline" } : { textDecoration: "none" }}
          >
            {params.row.sequence_id}
          </Typography>
        );
      },
    },
    {
      field: "name",
      headerName: "Template Name",
      width: 250,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <Typography variant="body2">{params?.row?.name ?? ""}</Typography>
        );
      },
    },
    {
      field: "paper_size",
      headerName: "Paper Size",
      width: 100,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <Typography variant="body2">
            {params?.row?.paper_size ?? "A4"}
          </Typography>
        );
      },
    },
    {
      field: "orientation",
      headerName: "Orientation",
      width: 250,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <Typography variant="body2">
            {orientationOptions.find((o) => o.value === params?.row?.orientation)
              ?.label ?? "Portrait"}
          </Typography>
        );
      },
    },
    { field: "created_user", headerName: "Created By", width: 150 },
    {
      field: "created_time",
      headerName: "Created Date",
      width: 250,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <Typography variant="body2">
            {moment(params.row?.created_time).format("lll")}
          </Typography>
        );
      },
    },
    { field: "modified_user", headerName: "Last modified by", width: 150 },
    {
      field: "modified_time",
      headerName: "Last modified date",
      width: 250,
      renderCell: (params: GridRenderCellParams) => {
        if (params.rowNode.type === "group") {
          return params.value;
        }
        return (
          <Typography variant="body2">
            {params.row.modified_time
              ? moment(params.row?.modified_time).format("lll")
              : ""}
          </Typography>
        );
      },
    }
  ];

  if (hasAccess) {
    columns = [columns[0], {
      field: "duplicate",
      headerName: "Duplicate",
      resizable: true,
      width: 90,
      disableColumnMenu: true,
      sortable: false,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <Tooltip title="Make a Duplicate">
            <IconButton
              sx={{ margin: "auto" }}
              onClick={() =>
                copy({ ...params?.row, label: params?.row?.attr_name })
              }
            >
              <ContentCopy fontSize="small" />
            </IconButton>
          </Tooltip>
        );
      },
    }, ...columns.slice(1), {
      field: "delete",
      headerName: "Delete",
      width: 100,
      disableColumnMenu: true,
      sortable: false,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <IconButton onClick={() => onDelete(params.row)}>
            <DeleteOutline color="error" />
          </IconButton>
        );
      },
    },]
  }

  return columns;
};
