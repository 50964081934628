import { createApi, fetchBaseQuery, retry } from '@reduxjs/toolkit/query/react';
import { config } from '../../../config';
import { ConvertToReactSelect, getTransformedDataForPagination, LocalStorageKeys } from '../../../utils';

export const nounVariantAPI = createApi({
    reducerPath: "nounVariantAPI",
    baseQuery: retry(fetchBaseQuery({
        baseUrl: config.api_url  + "mm/mdg/",
        prepareHeaders: (headers, { getState }) => {
            const token = localStorage.getItem(LocalStorageKeys.authToken);

            // If we have a token set in state, let's assume that we should be passing it.
            if (token) {
                headers.set('authorization', `Bearer ${token}`)
            }

            return headers
        },
    }), { maxRetries: 0 }),
    tagTypes: ['NounVariants', 'NounVariantsAsDDByNoun'],
    endpoints: (builder) => ({
        // Get Noun Variant API
        getNounVariant: builder.query({
            query: ({ id, page = 0, pageSize = 10, sort_by = null, sort_order = null }) => ({ url: `nounVariant${page !== null ? `?page_no=${page + 1}` : ''}${pageSize ? `&page_size=${pageSize}` : ''}${typeof id === "string" ? `${page !== null ? '&' : '?'}noun_id=${id}`: ''}${sort_by ? `&sort_by=${sort_by}` : ''}${sort_order ? `&sort_order=${sort_order}` : ''}`, method: "GET" }),
            transformResponse: (data: any, meta: { request: Request; response?: Response }) => {
                return getTransformedDataForPagination(data, meta?.response);
            },
            providesTags: ['NounVariants']
        }),

        // Get Noun Variant by Noun
        getNounVariantByNounId: builder.query({
            query: ({ id }) => ({ url: `nounVariant/${id}`, method: "GET" })
        }),

        // Get Noun Variant by Noun for drop down
        getNounVariantByNounIdDD: builder.query({
            query: (payload) => ({ url: 'nounVariant', method: "GET" }),
            transformResponse: (response: any[], meta, arg) => ConvertToReactSelect(response, "_id", "nounvariant_name"),
            providesTags: ['NounVariantsAsDDByNoun']
        }),

        // Create Noun Variant API
        createNounVariant: builder.mutation({
            query: (payload) => ({ url: 'nounVariant', method: "POST", body: payload }),
            invalidatesTags: ['NounVariants', 'NounVariantsAsDDByNoun']
        }),

        // Update Noun Variant API
        updateNounVariant: builder.mutation({
            query: (payload) => ({ url: `nounVariant/${payload._id}`, method: "PUT", body: payload }),
            invalidatesTags: ['NounVariants', 'NounVariantsAsDDByNoun']
        }),

        // Delete Noun Variant API
        deleteNounVariant: builder.mutation({
            query: ({ id}) => ({ url: `nounVariant/${id}`, method: "DELETE" }),
            invalidatesTags: ['NounVariants', 'NounVariantsAsDDByNoun']
        })
    })
})

// Export hooks for usage in functional components
export const { useLazyGetNounVariantQuery, useGetNounVariantByNounIdDDQuery, useLazyGetNounVariantByNounIdDDQuery,useLazyGetNounVariantByNounIdQuery, useCreateNounVariantMutation, useUpdateNounVariantMutation, useGetNounVariantByNounIdQuery, useDeleteNounVariantMutation } = nounVariantAPI
