import { Folder } from "@mui/icons-material";
import React from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { InternatlServerError, Layout, LoadingSection } from "../../../components";
import { useGetLocationAndDocumentTagsQuery } from "../../../redux/services";
import { AppRoutes } from "../../../router/routes";
import { Documents } from "./../documents";

export const FolderView: React.FC<{ children?: JSX.Element, openDocumentHistory: (document_id: string) => void }> = (props) => {

    const navigate = useNavigate();
    const [params] = useSearchParams();
    const { currentData: data, isLoading, isError, isSuccess, refetch } = useGetLocationAndDocumentTagsQuery(null, { refetchOnMountOrArgChange: true, refetchOnFocus: true });

    React.useEffect(() => {
        if (!params.get("folder") && !params.get("id") && isSuccess) {
            navigate(`${AppRoutes.documents}?id=${data.locationTags?.[0]?.value}&folder=${data.locationTags?.[0]?.label}&group_id=${data.locationId}&tab=folder`)
        }
        // eslint-disable-next-line
    }, [isSuccess]);

    return <>
        {/* Loading */}
        {isLoading && <LoadingSection message="Fetching Folders..." />}

        {/* Error */}
        {isError && <InternatlServerError onRetry={refetch} />}

        {/* Breadscrum */}
        {isSuccess && <Layout
            currentPath="Documents"
            navBars={data.locationTags?.map((loc: any, index: number) => {
                return {
                    id: loc._id,
                    title: loc.label,
                    icon: <Folder htmlColor="#fde100" fontSize="small" />,
                    path: `${AppRoutes.documents}?id=${loc.value}&folder=${loc.label}&group_id=${data.locationId}&tab=folder`
                }
            })}
            showDropInsteadOfNavInMobile={true}
            navBarTitle="Folders"
            showNavbarSearch={true}
            mainPanel={<Documents refetch={refetch} />}
            sideNavVariant={"whiteboard"}
        />}
    </>
}

