import { LoadingButton } from "@mui/lab";
import { Alert, Box, Button, Grid, Stack, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { HighlightSelectBox, MyPaper } from "../../../components";
import { AppRoutes } from "../../../router/routes";

export const BasicDetails: React.FC<{ children?: JSX.Element, cutsheetId: string }> = ({ cutsheetId = "New", ...props }) => {

    const isEdit = cutsheetId !== "New";
    const navigate = useNavigate();

    return <Stack spacing={2} mb={2}>
        {/* Basic Details Section */}
        <MyPaper padding={0}>
            <Stack justifyContent={"space-between"} height="inherit">
                <Box>
                    {/* Heading */}
                    <Typography sx={{ px: 2, py: 1, borderBottom: (theme) => `1px solid ${theme.palette.divider}` }}
                        variant="body1"
                        fontFamily={"htrts_medium"}>
                        Basic Details
                    </Typography>

                    <Grid container p={2} spacing={2}>

                        <Grid item xs={12}>
                            <Stack direction={"row"} spacing={1}>
                                <Typography variant="body2" color="textSecondary">Cutsheet ID: </Typography>
                                <Typography sx={{ fontWeight: "bold" }} variant="body2" color="textPrimary">{`Yet to create`}</Typography>
                            </Stack>
                        </Grid>

                        {/* Header Footer Template */}
                        <Grid item xs={12} sm={4}>
                            <HighlightSelectBox
                                label={"Header Footer Template"}
                                margin={"none"}
                                required={true}
                                value={null}
                                onChange={() => false}
                                options={[]}
                            />
                        </Grid>

                        {/* Cutsheet Page Template */}
                        <Grid item xs={12} sm={4}>
                            <HighlightSelectBox
                                label={"Cutsheet Page Template"}
                                margin={"none"}
                                required={true}
                                value={null}
                                onChange={() => false}
                                options={[]}
                            />
                        </Grid>

                        {/* Cover Page Template */}
                        <Grid item xs={12} sm={4}>
                            <HighlightSelectBox
                                label={"Cover Page Template"}
                                margin={"none"}
                                required={true}
                                value={null}
                                onChange={() => false}
                                options={[]}
                            />
                        </Grid>
                    </Grid>
                </Box>
            </Stack>
        </MyPaper>

        {/* Footer */}
        <MyPaper>
            <Box>
                {/* Alert Box */}
                {false && <Alert sx={{ mt: 2 }} severity="error">{`Opps! Something went wrong, Unable to ${isEdit ? "Update" : "Create"} Materials. Try Again Later!`}</Alert>}

                {/* Footer Buttons */}
                <Stack direction={"row"} alignItems={"center"} justifyContent={"center"} spacing={1} p={1}>
                    {/* Clear All Button */}
                    <Button disabled={false} variant="outlined" sx={{ width: 200 }} color="primary" onClick={() => false} >
                        Clear All
                    </Button>

                    {/* Create Button */}
                    <LoadingButton loading={false} variant="contained" sx={{ width: 200 }} color="primary" onClick={() => navigate(AppRoutes.viewCutSheets(cutsheetId))}>
                        {isEdit ? "Update" : "Create"}
                    </LoadingButton>

                    {/* Cancel Button */}
                    <Button disabled={false} variant="outlined" sx={{ width: 200 }} color="primary" onClick={() => navigate(AppRoutes.cutSheetBuilder)} >
                        Cancel
                    </Button>
                </Stack>
            </Box>
        </MyPaper>
    </Stack>
}