import { createApi, fetchBaseQuery, retry } from "@reduxjs/toolkit/query/react";
import Axios from "axios";
import { config } from "../../../config";
import {
  getTransformedDataForPagination,
  LocalStorageKeys,
} from "../../../utils";

export interface AccessPayload {
  access?: {
    grant?: {
      owners?: Array<string>;
      members?: Array<string>;
      notes?: string;
    };
    revoke?: {
      user_ids: Array<string>;
    };
  };
  visibility: Visibility;
}

export type Visibility = "public" | "private";

export const commonAPIs = createApi({
  reducerPath: "commonAPIs",
  baseQuery: retry(
    fetchBaseQuery({
      baseUrl: config.api_url,
      prepareHeaders: (headers) => {
        const token = localStorage.getItem(LocalStorageKeys.authToken);

        // If we have a token set in state, let's assume that we should be passing it.
        if (token) {
          headers.set("authorization", `Bearer ${token}`);
          headers.set("Content-Type", "application/json");
        }

        return headers;
      },
    }),
    { maxRetries: 2 }
  ),
  tagTypes: ["Common Attachment", "Workbooks", "Workbook Items"],
  endpoints: (builder) => ({
    // Update Attachment API
    linkModule: builder.mutation({
      query: ({ object_type, object_id, payload, is_oneway = false }) => ({
        url: `link/${object_type}/${object_id}?oneway=${is_oneway}`,
        method: "PUT",
        body: payload,
      }),
    }),

    // delete attachment
    unlinkModule: builder.mutation({
      query: ({ object_type, object_id, payload }) => ({
        url: `unlink/${object_type}/${object_id}`,
        method: "PUT",
        body: payload,
      }),
    }),

    // Workbook Api's start

    // Get All Workbooks
    getAllWorkbooks: builder.query({
      query: ({
        page = 0,
        pageSize = 10,
        status = null,
        type = null,
        object_type = null,
        object_id = null,
      }) => ({
        url: `/workbook${page !== null ? `?page_no=${page + 1}` : ""}${
          pageSize ? `&page_size=${pageSize}` : ""
        }${status ? `&status=${status}` : ""}${type ? `&type=${type}` : ""}${
          object_type ? `&object_type=${object_type}` : ""
        }${object_id ? `&object_id=${object_id}` : ""}`,
        method: "GET",
      }),
      providesTags: ["Workbooks"],
      transformResponse: (
        data: any,
        meta: { request: Request; response?: Response }
      ) => {
        return getTransformedDataForPagination(data, meta?.response);
      },
    }),

    // Get Workbook by id
    getWorkbookById: builder.query({
      query: ({ id }) => ({
        url: `/workbook/${id}`,
        method: "GET",
      }),
    }),

    // Create Workbook
    createWorkbook: builder.mutation({
      query: (payload): Record<string, any> => ({
        url: "/workbook",
        method: "POST",
        body: payload,
      }),
    }),

    // Update Workbook
    updateWorkbook: builder.mutation({
      query: ({ id, payload }) => ({
        url: `/workbook/${id}`,
        method: "PUT",
        body: payload,
      }),
    }),

    // Import materials and boms based on csv file
    importWorkbook: builder.mutation({
      async queryFn(_arg, _queryApi, _extraOptions) {
        const { payload, id } = _arg;
        let result: any = {};
        try {
          const resData = await Axios({
            url: `${config.api_url}workbook/${id}/import`,
            method: "post",
            headers: {
              authorization: `Bearer ${localStorage.getItem(
                LocalStorageKeys.authToken
              )}`,
              "Content-Type": payload.type,
            },
            data: payload,
          });
          result = { data: resData?.data };
        } catch (err) {
          result = { error: true };
        } finally {
          return result;
        }
      },
    }),

    // Create Material and bom from workbook items
    batchCreateWorkbook: builder.mutation({
      query: (id): Record<string, any> => ({
        url: `/workbook/${id}`,
        method: "POST",
      }),
    }),

    // Delete Workbook
    deleteWorkbook: builder.mutation({
      query: ({ id }) => ({
        url: `/workbook/${id}`,
        method: "DELETE",
      }),
    }),

    // Workbook items API's

    // Get All items
    getAllWorkbookItems: builder.query({
      query: ({ page = 0, pageSize = 10, id }) => ({
        url: `/workbook/${id}/items${
          page !== null ? `?page_no=${page + 1}` : ""
        }${pageSize ? `&page_size=${pageSize}` : ""}`,
        method: "GET",
      }),
      providesTags: ["Workbook Items"],
      transformResponse: (
        data: any,
        meta: { request: Request; response?: Response }
      ) => {
        return getTransformedDataForPagination(data, meta?.response);
      },
    }),

    // Create Workbook item
    createWorkbookItem: builder.mutation({
      query: ({ id, payload }): Record<string, any> => ({
        url: `/workbook/${id}/items`,
        method: "POST",
        body: payload,
      }),
    }),

    // Update Workbook item
    updateWorkbookItem: builder.mutation({
      query: ({ id, item_id, payload }) => ({
        url: `/workbook/${id}/${item_id}`,
        method: "PUT",
        body: payload,
      }),
    }),

    // Delete Workbook item
    deleteWorkbookItem: builder.mutation({
      query: ({ id, item_id }) => ({
        url: `/workbook/${id}/${item_id}`,
        method: "DELETE",
      }),
    }),

    // Bulk Delete Workbook items
    bulkDeleteWorkbookItems: builder.mutation({
      query: ({ id, item_ids }) => ({
        url: `/workbook/${id}/items`,
        method: "DELETE",
        body: item_ids,
      }),
    }),

    // Workbook Api's end

    // Get hierarchy for a specific module
    getHierarchy: builder.query({
      query: ({ object_type, object_id }) => ({
        url: `children/${object_type}/${object_id}`,
        method: "GET",
      }),
    }),

    // User Access Management for Product, Project and Proposal
    setVisibility: builder.mutation({
      query: ({
        object_type,
        object_id,
        payload,
      }: {
        object_type: string;
        object_id: string;
        payload: AccessPayload;
      }) => ({
        url: `visibility/${object_type}/${object_id}`,
        method: "POST",
        body: payload,
      }),
    }),

    // Processing Status API's

    // Get Workbook processing status
    getWorkbookProcessingStatus: builder.query({
      query: (id) => ({
        url: `/workbook/${id}/processing_status`,
        method: "GET",
      }),
    }),

    // Get processing status for list view and bom report
    getProcessingStatus: builder.query({
      query: ({ id, type }) => ({
        url: `/dm/processing_status/${type}/${id}`,
        method: "GET",
      }),
    }),

    // End of Processing Status API's

    // Supplier Search
    searchSupplier: builder.mutation({
      query: (payload) => ({
        url: "https://xqji6arbdty5kab3aobef6bhzq0kecpa.lambda-url.us-east-1.on.aws/",
        method: "POST",
        body: payload,
      }),
    }),
  }),
});

export const {
  useLinkModuleMutation,
  useUnlinkModuleMutation,
  useLazyGetAllWorkbooksQuery,
  useLazyGetWorkbookByIdQuery,
  useCreateWorkbookMutation,
  useUpdateWorkbookMutation,
  useDeleteWorkbookMutation,
  useImportWorkbookMutation,
  useBatchCreateWorkbookMutation,
  useLazyGetHierarchyQuery,
  useLazyGetAllWorkbookItemsQuery,
  useCreateWorkbookItemMutation,
  useUpdateWorkbookItemMutation,
  useDeleteWorkbookItemMutation,
  useSetVisibilityMutation,
  useBulkDeleteWorkbookItemsMutation,
  useGetWorkbookProcessingStatusQuery,
  useGetProcessingStatusQuery,
  useSearchSupplierMutation
} = commonAPIs;
