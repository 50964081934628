/*
AppAuth is used to check wheather 
    1. The User is Logged In or not. 
    2. Refresh the auth token. 
    3. To update a build cache if necessary.
*/
import { useAuth0 } from "@auth0/auth0-react";
import { LinearProgress } from "@mui/material";
import React from "react";
import { useAppDispatch } from "./redux";
import { setAuth, setError } from "./redux/slices/auth";
import { closeConfirmationDialog, openConfirmationDialog } from "./redux/slices/confirmationDialog";
import { AppRoutes } from "./router/routes";
import { LocalStorageKeys, parseJwt } from "./utils";

export const AppAuth: React.FC<{ children: JSX.Element }> = ({ children }) => {
    const dispatch = useAppDispatch();
    const { user, getAccessTokenSilently, logout, ...rest } = useAuth0();
    const [auth0Loading, setAuth0Loading] = React.useState(false);

    React.useEffect(() => {
        const getUserMetadata = async () => {
            try {
                setAuth0Loading(true);
                const accessToken = await getAccessTokenSilently();
                localStorage.setItem(LocalStorageKeys.authToken, accessToken);
                dispatch(setAuth({ ...parseJwt(accessToken) }))
            } catch (e: any) {
                console.log('e:', e);
                localStorage.clear();
                if (e.toString() !== 'Error: Login required') {
                    dispatch(setError(e.toString()))
                }
            } finally {
                setAuth0Loading(false);
            }
        };

        getUserMetadata();


        // eslint-disable-next-line
    }, [getAccessTokenSilently, user?.sub]);

    React.useEffect(() => {
        // Handler function to handle visibility change
        const handleVisibilityChange = () => {
          if (document.hidden) {
            // Perform actions when the tab is hidden
          } else {
            if (!localStorage.getItem(LocalStorageKeys.authToken) && window.location.pathname !== AppRoutes.login) {
                dispatch(openConfirmationDialog({
                    title: "Force Reload",
                    body: "You have been logged out from other Tab/Window. Please click 'Login' to login",
                    hideNegativeBtn: true,
                    hideCloseIcon: true,
                    positiveBtn: "Login",
                    onOk: onOk
                }))
            }
            // Perform actions when the tab is visible
          }
        };
    
        // Add event listener
        document.addEventListener('visibilitychange', handleVisibilityChange);
    
        // Clean up event listener on component unmount
        return () => {
          document.removeEventListener('visibilitychange', handleVisibilityChange);
        };
        // eslint-disable-next-line
      }, [rest.isAuthenticated]); // Empty dependency array ensures this effect runs once on mount and cleanup on unmount
    
      

    // const checkForLatestBuild = () => {
    //     fetch(VersionFetchURL).then(res => res.json()).then((_) => {
    //         const isVersion = semverGreaterThan(_.version, localStorage?.getItem(LocalStorageKeys.version) ?? "");
    //         localStorage.setItem(LocalStorageKeys.version, _.version)
    //         if (isVersion) { refreshCacheAndReload() }
    //     }, (error) => { console.log('Error at Fetching Latest Version: ', error) })
    // };

    const onOk = () => {
        dispatch(closeConfirmationDialog());
        window.location.reload();
    };

    const logOutCheckInCrossBrowser = () => {
        window.addEventListener('storage', e => {
            if (e.key === LocalStorageKeys.authToken && e.oldValue && !e.newValue) {
                if (localStorage.getItem(LocalStorageKeys.authToken)) {
                    window.location.reload();
                } else {
                    dispatch(openConfirmationDialog({
                        title: "Force Reload",
                        body: "You have been logged out from other Tab/Window. Please click 'Reload' to reload",
                        hideNegativeBtn: true,
                        positiveBtn: "Reload",
                        onOk: onOk,
                        onNegativeBtn: () => dispatch(closeConfirmationDialog())
                    }))
                }
            }
        });
    }

    React.useEffect(() => {
        // checkForLatestBuild();
        logOutCheckInCrossBrowser();
        // eslint-disable-next-line
    }, []);

    return <>
        {auth0Loading ? <LinearProgress /> : children}
    </>
}