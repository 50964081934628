import React from "react";
import {
  Alert,
  Box,
  useTheme,
} from "@mui/material";
import { CustomDataGrid, TitleHeader } from "../../components";
import { TableColumn } from "./utils";
import { useAppDispatch } from "../../redux";
import {
  closeConfirmationDialog,
  openConfirmationDialog,
} from "../../redux/slices/confirmationDialog";
import { useNavigate } from "react-router-dom";
import { useStyles } from "../documentTemplates/styles";
import { useCreateDataTableMutation, useDeleteDataTableMutation, useLazyGetAllDataTableQuery, useLazySearchDataTableQuery } from "../../redux/services";
import { closeBackdrop, openBackdrop } from "../../redux/slices/backdrop";
import { useSnackbar } from "notistack";
import { generateDataTableFormat } from "../../utils";
import { TagInfo } from "../../interfaces";
import { GridSortDirection, GridSortModel } from "@mui/x-data-grid-premium";

const DataSheetList = ({ hasAccess }: { hasAccess: boolean }) => {
  const classes = useStyles(useTheme());

  const dispatch = useAppDispatch();

  const [
    getAllDataSheet,
    {
      data: allData = { data: [], totalCount: 0 },
      isFetching,
      isLoading,
      isError,
    },
  ] = useLazyGetAllDataTableQuery({
    refetchOnFocus: true,
  });
  const { data, totalCount } = allData;

  const [getDataSheetBySearch, { data: dataSheetFilteredData, isFetching: dataSheetIsFetching, isLoading: dataSheetIsLoading, isError: filterHasError }] = useLazySearchDataTableQuery();

  const loading = isLoading || isFetching || dataSheetIsFetching || dataSheetIsLoading;

  const [search, setSearch] = React.useState("");
  const [paginationModel, setPaginationModel] = React.useState({
    page: 0,
    pageSize: 10,
  });
  const [queryOptions, setQueryOptions] = React.useState({
    sortModel: [{ field: 'sequence_id', sort: 'desc' as GridSortDirection }],
  });

  const { enqueueSnackbar } = useSnackbar();

  const [deleteDataSheetMutation] = useDeleteDataTableMutation();

  const [createDataSheet] = useCreateDataTableMutation();

  const navigate = useNavigate();

  const giveMeRows = () => {
    if (search.trim().length) {
      return dataSheetFilteredData?.data ?? [];
    } else if ((!isLoading && !isFetching) && data && !search.trim()) {
      return data ?? [];
    } else if ((!dataSheetIsFetching && !dataSheetIsLoading) && dataSheetFilteredData) {
      return dataSheetFilteredData?.data ?? [];
    } else {
      return [];
    }
  }

  let ROWS = giveMeRows();

  const deleteDataSheet = (dataSheet: any) => {
    dispatch(
      openConfirmationDialog({
        title: "Delete Operation",
        body: `Are you sure you want to delete the data sheet ${dataSheet.sequence_id}-r${dataSheet.version}`,
        positiveBtn: "Delete",
        negativeBtn: "Cancel",
        onOk: () => performRowDel(dataSheet._id),
        onNegativeBtn: () => dispatch(closeConfirmationDialog()),
      })
    );
  };

  const performRowDel = async (_id: any) => {
    dispatch(openBackdrop("Deleting datasheet..."));
    let res: any = {};
    try {
      res = await deleteDataSheetMutation({
        dtTemplateId: _id,
        dtTemplateType: "datasheet",
        dtInstanceType: "template"
      });

      if (Object.keys(res).includes("data")) {
        enqueueSnackbar(`Deleted datasheet Successfully!`, { variant: "success" });
        dispatch(closeConfirmationDialog());
        fetchDataSheets();
      } else if (Object.keys(res).includes("error")) {
        // eslint-disable-next-line
        throw res.error.data;
      } else {
        // eslint-disable-next-line
        throw "Data not found";
      }
    } catch (error: any) {
      let errorMessage: string = error?.title ?? "Oops! Something went wrong, Unable to delete datasheet";
      enqueueSnackbar(errorMessage, { variant: "error" });
    } finally {
      dispatch(closeBackdrop());
    }
  };

  const viewDataSheet = (data: any) => {
    if (hasAccess) {
      navigate(`${window.location.pathname}/${data?._id || "New"}`);
    }
  };

  const duplicate = (data: any) => {
    let message = `Do you want to create a copy of this "${data.sequence_id}" datasheet?`;
    dispatch(
      openConfirmationDialog({
        title: "Are you sure?",
        body: message,
        positiveBtn: "Create",
        negativeBtn: "Cancel",
        onOk: () => performDuplicate(data),
        onNegativeBtn: () => dispatch(closeConfirmationDialog()),
      })
    );
  };

  const performDuplicate = async (data: any) => {
    dispatch(openBackdrop(`Creating copy of "${data.sequence_id}" datasheet....`));
    let res: any = {};
    try {
      const extractedDigit = Math.abs(data?.name?.match(/-\d+/)?.at(-1)) || 0;

      const checkListName = `${extractedDigit ? data?.name?.replace(/-\d/, '') : data.name}-${(Number(extractedDigit) ?? 1) + 1}`;

      res = await createDataSheet({
        payload: {
          ...data, name: checkListName, tags_info: data?.tags_info?.map((tag: TagInfo) => ({
            tag_group_id: tag?.tag_group_id,
            tag_id: tag?.tag_id
          })),
          tables: generateDataTableFormat(data?.tables)
        },
        dtTemplateType: "datasheet",
        dtInstanceType: "template",
        dtTemplateId: null,
        isDuplicating: true
      });
      if (Object.keys(res).includes("data")) {
        enqueueSnackbar(
          `Created copy of ${res?.data?.sequence_id} Successfully!`,
          { variant: "success" }
        );
        dispatch(closeConfirmationDialog());
        fetchDataSheets();
      } else if (Object.keys(res).includes("error")) {
        // eslint-disable-next-line
        throw "Unable to create copy";
      } else {
        // eslint-disable-next-line
        throw "Data not found";
      }
    } catch (error) {
      let errorMessage: string = `Unable to create copy of ${data.sequence_id}!`;
      enqueueSnackbar(errorMessage, { variant: "error" });
      dispatch(closeBackdrop());
    } finally {
      dispatch(closeBackdrop());
      dispatch(closeConfirmationDialog());
    }
  };

  const handleSortModelChange = React.useCallback((sortModel: GridSortModel) => {
    // Here you save the data you need from the sort model
    setQueryOptions({ sortModel: [...sortModel] });
  }, [])

  const fetchDataSheets = () => {
    getAllDataSheet({
      ...paginationModel,
      dtTemplateType: "datasheet",
      dtInstanceType: "template",
      sort_by: queryOptions?.sortModel?.[0]?.field,
      sort_order: queryOptions?.sortModel?.[0]?.sort
    });
  };

  React.useEffect(() => {
    if (!search.trim().length) {
      fetchDataSheets();
    }
    // eslint-disable-next-line
  }, [paginationModel, JSON.stringify(queryOptions?.sortModel), search]);

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      if (search.trim().length) {
        getDataSheetBySearch({
          ...paginationModel,
          dtTemplateType: "datasheet",
          dtInstanceType: "template",
          sort_by: queryOptions?.sortModel?.[0]?.field,
          sort_order: queryOptions?.sortModel?.[0]?.sort,
          search
        });
      }
    }, 500)

    return () => clearTimeout(timeout)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, paginationModel, JSON.stringify(queryOptions?.sortModel)])

  return (
    <Box sx={classes.root}>
      <TitleHeader
        title="All Searches"
        search={search}
        onSearchChange={setSearch}
        searchPlaceholder={"Search"}
        showCreateBtn={hasAccess}
        createBtnLabel={"Create Data Sheet"}
        onCreateBtn={() => viewDataSheet(null)}
      />

      {/* Error */}
      {(isError || filterHasError) && (
        <Alert sx={{ mt: 2 }} severity="error">
          Oops! Something went wrong, Unable to fetch Data Sheet List. Try Again
          Later!
        </Alert>
      )}

      <Box mt={2}>
        <CustomDataGrid
          id="datasheet-list"
          // initialState={{
          //   sorting: {
          //     sortModel: [{ field: "sequence_id", sort: "desc" }],
          //   },
          // }}
          rows={ROWS}
          columns={TableColumn(deleteDataSheet, viewDataSheet, duplicate, hasAccess)}
          loading={loading}
          getRowId={(row) => row._id}
          showToolbar={true}
          rowCount={search.trim() ? dataSheetFilteredData?.totalCount : totalCount}
          paginationModel={paginationModel}
          paginationMode="server"
          onPaginationModelChange={setPaginationModel}
          sortingMode="server"
          onSortModelChange={handleSortModelChange}
        />
      </Box>
    </Box>
  );
};

export default DataSheetList;
