import { Stack } from "@mui/material";
import React from "react";
import { Header } from "./header";
import { SelectionDetail } from "./selectionDetail";

export const AddSelectionPage: React.FC<{ children?: JSX.Element }> = (props) => {

    return <Stack spacing={1} height={"100%"}>
        
        {/* Header */}
        <Header />

        {/* Details of Section */}
        <SelectionDetail />
        
    </Stack>
}