import { OptionProps } from "react-select";
import { Stack, ListItemText, ListItemButton } from "@mui/material";
import { History } from "@mui/icons-material";
import { SelectBoxOption } from "../../../../interfaces";

export interface NounSelectBoxInterface {
  value: any;
  onChange: any;
  label?: string;
  isRequired?: boolean;
  placeholder?: string;
  isMulti?: boolean;
  disabled?: boolean;
  height?: number | null;
  category_id?: any;
  withSearchAPI?: boolean;
  viewOnly?: boolean;
}

export const Option = (
  props: OptionProps<{
    label: string;
    options: SelectBoxOption[];
    value: string;
    group_name: string;
    attachment?: any;
    isRecent?: boolean;
  }>
) => {
  return (
    <Stack>
      <ListItemButton
        alignItems="center"
        sx={{ padding: 0, paddingX: 2 }}
        selected={props.isSelected}
        onClick={(e) => props.selectOption(props.data)}
      >
        {props.data.isRecent && (
          <History sx={{ marginRight: 2 }} color="action" />
        )}
        <ListItemText
          primary={props.data.label}
          secondary={props.data.group_name}
        />
      </ListItemButton>
    </Stack>
  );
};
