import { Alert, Box, useTheme } from "@mui/material";
import { GridSortDirection, GridSortModel } from "@mui/x-data-grid-premium";
import React from "react";
import { useSearchParams } from "react-router-dom";
import { TitleHeader, CustomDataGrid } from "../../../components";
import { useAppDispatch, useAppSelector } from "../../../redux";
import { useLazyGetTagsQuery } from "../../../redux/services";
import { openDialog } from "../../../redux/slices/dialog";
import { HasAccess } from "../../../router/authorization";
import { PERMISSIONS } from "../../../router/permission";
import { useStyles } from "../../documentTemplates/styles";
import { TagsTableColumn } from "../utils";
import { AddEditTags } from "./addEditTags";

export const TagListing: React.FC<{ children?: JSX.Element }> = (props) => {

    const classes = useStyles(useTheme());
    const dispatch = useAppDispatch();
    let [getTags, { data: allData = { data: [], totalCount: 0 }, isFetching:isLoading, isError }] = useLazyGetTagsQuery();
    const { data, totalCount } = allData;

    const [params, setSearchParam] = useSearchParams();

    const [search, setSearch] = React.useState("");

    const [paginationModel, setPaginationModel] = React.useState({
        page: 0,
        pageSize: 10,
    });
    const [queryOptions, setQueryOptions] = React.useState({
        sortModel: [{ field: '', sort: '' as GridSortDirection }],
    });

    const { perm } = useAppSelector(store => store.auth.userDetails);

    const master = React.useMemo(() => HasAccess(perm, PERMISSIONS.TAG_MASTER), [perm]);

    const handleSortModelChange = React.useCallback((sortModel: GridSortModel) => {
        // Here you save the data you need from the sort model
        setQueryOptions({ sortModel: [...sortModel] });
    }, [])
    
    const refetch = () => {
        getTags({ groupId: params.get("group") === "all" ? '' : params.get("group"), sort_by: queryOptions?.sortModel?.[0]?.field, sort_order: queryOptions?.sortModel?.[0]?.sort, ...paginationModel });
    }

    const openAddEditTagDialog = (isEdit: boolean, data: any) => {
        dispatch(openDialog({
            title: "",
            hideNegativeBtn: true,
            hidePositiveBtn: true,
            body: <AddEditTags isEdit={isEdit} data={data} refetch={refetch} />
        }));
    }

    const giveMeRows = () => {
        if (!isLoading && data) {
            return search.trim().length ? data?.filter((_: any) => _?.tag_name?.toLowerCase()?.includes(search?.toLowerCase())) ?? [] : data;
        } else {
            return [];
        }
    };


    React.useEffect(() => {
        refetch();
        // eslint-disable-next-line
    }, [params, paginationModel, JSON.stringify(queryOptions?.sortModel)]);

    React.useEffect(() => {
        if (params.get('group')) {
            refetch()
        } else {
            setSearchParam({ group: "all" })
        }
        // eslint-disable-next-line
    }, [])

    const ROWS = giveMeRows();

    return <Box sx={classes.root}>
        {/* Header */}
        <TitleHeader
            title="Tags"
            count={ROWS.length}
            showSearch={true}
            search={search}
            onSearchChange={setSearch}
            searchPlaceholder={"Search for Tags (only within this page)"}
            showCreateBtn={master}
            createBtnLabel={"Create"}
            onCreateBtn={() => openAddEditTagDialog(false, null)}
        />

        {/* Error */}
        {isError && <Alert sx={{ mt: 2 }} severity="error">Oops! Something went wrong, Unable to fetch Tags. Try Again Later!</Alert>}

        {/* Data Drid */}
        <Box mt={2}>
            <CustomDataGrid
                id="tags-list"
                rows={ROWS}
                columns={TagsTableColumn((data: any) => openAddEditTagDialog(true, data), master)}
                loading={isLoading}
                getRowId={(row) => row._id}
                showToolbar={true}
                rowCount={totalCount}
                paginationModel={paginationModel}
                paginationMode="server"
                onPaginationModelChange={setPaginationModel}
                sortingMode="server"
                onSortModelChange={handleSortModelChange}
            />
        </Box>
    </Box>
}