import React from "react";
import Select, { components, GroupHeadingProps } from "react-select";
import { Typography, useTheme, Box, Link, Stack, Tooltip, Chip } from "@mui/material";
import { useLazyGetSNNounsQuery } from "../../../../redux/services";
import { NounSelectBoxInterface, Option } from "./util";
import { LocalStorageKeys } from "../../../../utils";
import { SelectBoxOption } from "../../../../interfaces";
import { customStyles } from "../../../../components/nounSelectBox/util";

export const NounSelectBoxWithSearch: React.FC<NounSelectBoxInterface> = ({
  value = null,
  onChange = () => null,
  label = "",
  isRequired = false,
  placeholder = "",
  isMulti = false,
  disabled = false,
  height = null,
  category_id = null,
  viewOnly = false
}) => {
  const theme = useTheme();
  const [searchNoun, snNounD] = useLazyGetSNNounsQuery({});
  const snNounData = {
    ...snNounD,
    data: snNounD?.data?.data ?? [],
    currentData: snNounD?.data?.data ?? [],
    totalCount: snNounD?.data?.totalCount ?? 0,
  };

  const [search, setSearch] = React.useState("");

  const onSelectInputChange = (search: string) => {
    setSearch(search);
    if (search.length) {
      searchNoun({ search, category_id, page: null, pageSize: null });
    }
    return search;
  };

  const clearRecentSearches = () => {
    localStorage.removeItem(LocalStorageKeys.snNounRecentSearch);
    setSearch("");
    searchNoun({ search: "", category_id, page: null, pageSize: null });
  };

  const GroupHeading = (
    props: GroupHeadingProps<{
      label: string;
      options: SelectBoxOption[];
      value: string;
      group_name: string;
      attachments?: any;
      isRecent?: boolean;
    }>
  ) => (
    <Stack direction="row" justifyContent={"space-between"}>
      <components.GroupHeading {...props} />
      {localStorage.getItem(LocalStorageKeys.snNounRecentSearch) ? (
        <Link
          style={{ marginRight: "8px", marginTop: "-2px", cursor: "pointer" }}
          variant="caption"
          color={"error"}
          onClick={clearRecentSearches}
        >
          Clear All
        </Link>
      ) : (
        <></>
      )}
    </Stack>
  );

  const onOptionSelect = (data: any) => {
    let existingRecentSearch: any = localStorage.getItem(
      LocalStorageKeys.snNounRecentSearch
    );
    if (!existingRecentSearch) {
      existingRecentSearch = {
        [data?.value]: { ...data, isRecent: true, category_id },
      };
    } else {
      existingRecentSearch = JSON.parse(existingRecentSearch);
      existingRecentSearch[data?.value] = {
        ...data,
        isRecent: true,
        category_id,
      };
    }
    localStorage.setItem(
      LocalStorageKeys.snNounRecentSearch,
      JSON.stringify(existingRecentSearch)
    );
    onChange(data);
  };

  const giveMeOptions = () => {
    if (search.trim().length === 0) {
      let existingRecentSearch: any = localStorage.getItem(
        LocalStorageKeys.snNounRecentSearch
      );
      if (!existingRecentSearch) {
        return [{ label: "Recent Searches", options: [] }];
      } else {
        let recentSearches = Object.values(JSON.parse(existingRecentSearch));
        if (category_id) {
          return [
            {
              label: "Recent Searches",
              options: recentSearches?.filter(
                (_: any) => _.category_id === category_id
              ),
            },
          ];
        }
        return [{ label: "Recent Searches", options: recentSearches }];
      }
    }
    return snNounData.data?.map((_: any) => ({
      value: _._id,
      label: _.noun_name ? `${_.noun_name} (${_?.category_name})` : "",
      ..._,
    }));
  };

  React.useEffect(() => {
    if (category_id) {
      searchNoun({ search: "", category_id, page: null, pageSize: null });
    }
    // eslint-disable-next-line
  }, [category_id]);

  let selectedValue = value && !Array.isArray(value) ? value?.label || "N/A" : "N/A";

  return viewOnly ? (
    <Stack sx={{ overflowWrap: "break-word" }}>
      <Typography variant="body1" sx={{ fontFamily: "htrts_semibold" }}>{label}</Typography>
      {Array.isArray(value) && value.length > 0
        ?
        <Stack
          gap={1}
          direction={"row"}
          flexWrap={"wrap"}
        >
          {
            value.map(v =>
              <Tooltip title={v?.label || "N/A"}>
                <Chip size={"small"} label={v?.label || "N/A"} />
              </Tooltip>
            )
          }
        </Stack>
        :
        <Typography variant="body2" sx={{ fontFamily: "htrts_regular" }}>{selectedValue}</Typography>}
    </Stack>
  ) : (
    <Box>
      {label.length > 0 && (
        <Box sx={{ display: "flex" }}>
          {
            <Typography
              variant="body2"
              color="primary"
              align="left"
              gutterBottom
            >
              {label}
            </Typography>
          }
          {isRequired && (
            <Typography color="error" variant="caption">
              *
            </Typography>
          )}
        </Box>
      )}

      <Select
        isMulti={isMulti}
        isClearable={true}
        isSearchable
        isDisabled={disabled}
        components={{
          IndicatorSeparator: () => null,
          Option,
          GroupHeading,
        }}
        value={value}
        placeholder={placeholder}
        options={giveMeOptions()}
        isLoading={snNounData.isFetching}
        onChange={onOptionSelect}
        styles={customStyles(theme, height)}
        onInputChange={onSelectInputChange}
        noOptionsMessage={(obj: any) =>
          search.length > 0 ? "No Nouns found" : "No Recent Searches"
        }
      />

      {snNounData.isError && !snNounData.isFetching && (
        <Typography variant="caption" color="error">
          {"Oops! Unable to fetch "}
        </Typography>
      )}
    </Box>
  );
};
