import { createApi, fetchBaseQuery, retry } from '@reduxjs/toolkit/query/react';
import { config } from '../../../config';
import { getTransformedDataForPagination, LocalStorageKeys } from '../../../utils';

export const attributeAPI = createApi({
    reducerPath: "attributeAPI",
    baseQuery: retry(fetchBaseQuery({
        baseUrl: config.api_url  + "mm/mdg/",
        prepareHeaders: (headers, { getState }) => {
            const token = localStorage.getItem(LocalStorageKeys.authToken);

            // If we have a token set in state, let's assume that we should be passing it.
            if (token) {
                headers.set('authorization', `Bearer ${token}`)
            }

            return headers
        },
    }), { maxRetries: 2 }),
    tagTypes: ['Attributes', 'AttributesByIds'],
    endpoints: (builder) => ({
        // Get Attribute API
        getAttributes: builder.query({
            query: ({ id, page = 0, pageSize = 10, sort_by = null, sort_order = null }) => ({ url: `attribute${page !== null ? `?page_no=${page + 1}` : ''}${pageSize ? `&page_size=${pageSize}` : ''}${typeof id === "string" ? `${page !== null ? '&' : '?'}noun_id=${id}`: ''}${sort_by ? `&sort_by=${sort_by}` : ''}${sort_order ? `&sort_order=${sort_order}` : ''}`, method: "GET" }),
            transformResponse: (data: any, meta: { request: Request; response?: Response }) => {
                return getTransformedDataForPagination(data, meta?.response);
            },
            providesTags: ['Attributes']
        }),

        // Create Attribute API
        createAttribute: builder.mutation({
            query: (payload) => ({ url: `attribute`, method: "POST", body: payload }),
            invalidatesTags: ['Attributes', 'AttributesByIds']
        }),

        // Update Attribute API
        updateAttribute: builder.mutation({
            query: (payload) => ({ url: `attribute`, method: "PUT", body: payload }),
            invalidatesTags: ['Attributes', 'AttributesByIds']
        }),

        // Get Attributes by attrbute ids and option ids
        getAttributesByIds: builder.query({
            query: (payload) => ({ url: `attributes`, method: "PUT", body: payload }),
            providesTags: ['AttributesByIds']
        }),

        // Delete Attribute API
        deleteAttribute: builder.mutation({
            query: ({ id }) => ({ url: `attribute/${id}`, method: "DELETE" }),
            invalidatesTags: ['Attributes', 'AttributesByIds']
        })
    })
})

// Export hooks for usage in functional components
export const {
    useGetAttributesQuery, useLazyGetAttributesQuery, useCreateAttributeMutation, useUpdateAttributeMutation,
    useLazyGetAttributesByIdsQuery, useGetAttributesByIdsQuery, useDeleteAttributeMutation
} = attributeAPI
