import { List } from "@mui/icons-material";
import { Box } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { Layout } from "../../components";
import { AppRoutes } from "../../router/routes";
import { Dashboard } from "./dashboard";

export const DrawioDiagramTemplates: React.FC<{ children?: JSX.Element }> = (props) => {
  const navigate = useNavigate();

  return (
    <Box sx={{ padding: 0.5, height: "100%" }}>
      <Layout
        history={[
          { label: "Drawing Templates", onClick: () => navigate(AppRoutes.viewAllDiagramTemplates) }
        ]}
        currentPath={"All Drawing Templates"}
        hideNavBarByDefault={true}
        navBars={[
          {
            id: "1",
            title: "All Drawing Templates",
            icon: <List fontSize="small" />,
            path: AppRoutes.viewAllDiagramTemplates,
          },
        ]}
        mainPanel={<Dashboard />}
        sideNavVariant={"whiteboard"}
      />
    </Box>
  );
};