import React from "react"
import { SelectBoxOption } from "../../../interfaces"
import { Stack } from "@mui/system"
import { Box, Button } from "@mui/material"
import { NounListing } from "../../../screens/mdCreate/nounListing"
import { NounSelectBoxWithoutSeach } from "../../nounSelectBox/withoutSearch"

export interface FilterStateInterface {
    noun?: SelectBoxOption
}

const FiltersWithSelect: React.FC<{
    showNoun?: boolean,
    showNounCard?: boolean,
    appliedFilters: FilterStateInterface,
    onApply: (state: FilterStateInterface) => void,
    onCancel: () => void
}> = ({
    showNoun, appliedFilters, onApply, onCancel, showNounCard,
}) => {

        const [state, setState] = React.useState<FilterStateInterface>({ ...appliedFilters });

        React.useEffect(() => {
            setState({ ...appliedFilters })
        }, [appliedFilters]);

        return <Box>
            <Stack spacing={2} sx={{ p: 2, height: "calc(100vh - 170px)", overflow: "auto", pb: 6, position: "relative" }}>
                {/* Noun Select Box */}
                {showNoun && <Box sx={{ width: "100%" }}>
                    <NounSelectBoxWithoutSeach label="Noun" value={state.noun} onChange={(noun: SelectBoxOption) => setState({ ...state, noun })} uom_id={""} />
                </Box>}

                {/* Noun with images */}
                {showNounCard && <Box sx={{ width: "100%" }}>
                    <NounListing selectedNoun={state.noun?.value?.toString() ?? ""} onNounSelect={(noun: SelectBoxOption) => setState({ ...state, noun })} uom={null} />
                </Box>}

                {/* Apply Cancel Button */}
            </Stack>
            <Stack direction={"row"} justifyContent={"end"} spacing={2} sx={{ background: (theme) => theme.palette.background.paper, padding: 2 }}>
                <Button variant="contained" onClick={() => onApply(state)}>Apply</Button>
                <Button variant="outlined" onClick={() => {
                    onCancel();
                    setState({});
                }} >Reset</Button>
            </Stack>
        </Box>
    }

export default FiltersWithSelect;