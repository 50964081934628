// AppDrawer is used to show the drawer component all over the app whenever it needed with the use of drawer actions created in /redux/slices/drawer.js
import React from "react";
import { Drawer, Theme, Typography, useTheme } from "@mui/material";
import { closeDrawer } from "./redux/slices/drawer";
import { useAppDispatch, useAppSelector } from "./redux";
import { Box } from "@mui/system";

const useStyles = (theme: Theme) => ({
    root: {
        maxWidth: 400,
        minWidth: 400,
        background: "#fff",
        [theme.breakpoints.down('sm')]: {
            maxWidth: 300,
            minWidth: 300,
        },
        overflowX: 'hidden'
    },
    large: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: "16px",
        width: "36px",
        height: "36px",
        borderRadius: "50%",
        position: "fixed",
        right: "410px",
        cursor: "pointer",
        top: "18px",
        color: "#6A7888",
        background: "#fff",
        [theme.breakpoints.down('sm')]: {
            right: "313px",
        },
    },
    root_extra_large: {
        maxWidth: 800,
        minWidth: 800,
        background: "#fff",
        [theme.breakpoints.down('md')]: {
            maxWidth: 500,
            minWidth: 500,
        },
        [theme.breakpoints.down('sm')]: {
            maxWidth: 300,
            minWidth: 300,
        },
        overflowX: 'hidden'
    },
    large_extra_large: {
        width: "50px",
        height: "50px",
        borderRadius: "50%",
        position: "fixed",
        right: "810px",
        cursor: "pointer",
        top: 10,
        [theme.breakpoints.down('md')]: {
            right: "510px",
        },
        [theme.breakpoints.down('sm')]: {
            right: "313px",
        },
    }
});

export const AppDrawer: React.FC<{ children: JSX.Element }> = ({ children }) => {
    const dispatch = useAppDispatch();

    const classes = useStyles(useTheme())
    const { direction, variant, open, component, isLarge = false, title = '', onClose } = useAppSelector(state => state.drawer);

    React.useEffect(() => { }, [open])

    const giveMeSx = () => {
        if (isLarge) {
            return {
                close: { ...classes.large, ...classes.large_extra_large },
                root: { ...classes.root, ...classes.root_extra_large }
            }
        } else {
            return {
                close: { ...classes.large },
                root: { ...classes.root }
            }
        }
    }

    const close = () => {
        onClose();
        dispatch(closeDrawer());
    };

    return <>
        {children}
        <Drawer anchor={direction} variant={variant} open={open} onClose={close} ModalProps={{ keepMounted: true }}>
            <Box onClick={close} sx={giveMeSx().close} >
                <img src="/images/close.svg" alt="x" />
            </Box>

            {title && <Typography variant="h6" sx={{fontFamily: "htrts_semibold", p: 2}}>{title}</Typography>}

            <Box sx={giveMeSx().root}>{component}</Box>
        </Drawer>
    </>
}