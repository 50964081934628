import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { Box, Button, Collapse, IconButton, Stack, Typography } from "@mui/material";
import React from "react";
import { CustomDataGrid, MyPaper } from "../../../components";
import { useAppDispatch, useAppSelector } from "../../../redux";
import { useGetCurrencyQuery } from "../../../redux/services";
import { AssemblyProcess as AssPro, addNewAssemblyProcess, deleteBOMAssemblyProcess, updateBOMAssemblyProcess } from "../../../redux/slices/bom";
import { AssemblyProcessColumn } from "./utils";
import { closeConfirmationDialog, openConfirmationDialog } from "../../../redux/slices/confirmationDialog";

export const AssemblyProcess: React.FC<{ children?: JSX.Element, isViewOnly?: boolean }> = ({isViewOnly}) => {

    const { bom } = useAppSelector(store => store);
    const dispatch = useAppDispatch();
    const currencyData = useGetCurrencyQuery(null);

    const [open, updateOpen] = React.useState(true);

    const updateState = (sl_no: number, key: keyof AssPro, value: any) => {
        dispatch(updateBOMAssemblyProcess({ sl_no, key, value }))
    };

    const handleDelete = (sl_no: number, _id: string, process_name: string) => {
        dispatch(openConfirmationDialog({
            title: 'Are you sure?',
            body: `Do you want to delete this assembly process '${process_name}'?`,
            positiveBtn: 'Yes',
            negativeBtn: 'No',
            onOk: () => {
                dispatch(deleteBOMAssemblyProcess({ sl_no, _id }));
                dispatch(closeConfirmationDialog())
            },
            onNegativeBtn: () => dispatch(closeConfirmationDialog())
        }));

    }

    return <MyPaper padding={0}>
        <Box>
            {/* Heading */}
            <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"} sx={{ px: 2, py: 1, borderBottom: (theme) => `1px solid ${theme.palette.divider}` }}>

                <Typography variant="body1" fontFamily={"htrts_medium"}>
                    Assembly Process
                </Typography>

                <Stack direction="row" spacing={1}>
                    {!isViewOnly && <Button
                        id="add-row"
                        size="small"
                        sx={{ height: "24px", marginTop: "4px", paddingTop: "4px" }}
                        variant={"outlined"}
                        onClick={() => dispatch(addNewAssemblyProcess(null))}
                    >
                        Add Row
                    </Button>}
                    <IconButton size={"small"} onClick={(e) => updateOpen(!open)}>
                        {!open && <ExpandMore />}
                        {open && <ExpandLess />}
                    </IconButton>
                </Stack>
            </Stack>

            {/* Main Section */}
            <Collapse in={open} timeout="auto" unmountOnExit>
                <CustomDataGrid
                    saveLocal={false}
                    rows={bom.assemblyProcess}
                    columns={AssemblyProcessColumn(updateState, currencyData, handleDelete, isViewOnly)}
                    getRowId={(row) => row.sl_no}
                    columnBuffer={3}
                />
            </Collapse>
        </Box>
    </MyPaper>
}