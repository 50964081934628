import { Button, DialogActions, DialogContent, DialogTitle, Grid, TextField, Typography } from "@mui/material";
import { useEffect } from "react";
import { SelectBox } from "../../../components";
import { useAppDispatch, useAppSelector } from "../../../redux";
import { closeDialog } from "../../../redux/slices/dialog";
import {
  Orientation,
  TemplateResponse,
  updateTemplateConfig,
} from "../../../redux/slices/documentTemplate";

export type TemplateType = "headerFooter" | "generalPage" | "coverPage";

export const paperSizeOptions = [
  { label: "A4", value: "A4" },
  { label: "A3", value: "A3" },
  { label: "A1", value: "A1" },
];

export const paperSizeInCM = {
  A4: { width: 21, height: 29.7 },
  A3: { width: 29.7, height: 42 },
  A1: { width: 59.5, height: 84.1 },
};

export const orientationOptions = [
  { label: "Portrait", value: "portrait" },
  { label: "Landscape", value: "landscape" },
];

const initialConfig = {
  name: "",
  paper_size: paperSizeOptions[0].value,
  orientation: orientationOptions[0].value,
};

export type ConfigureTemplateType = typeof initialConfig;

export const EditTemplate: React.FC<{
  onNextBtnClicked: (
    id: string,
    templateConfig: Partial<TemplateResponse>
  ) => void;
  templateType: TemplateType;
  isNew?: boolean;
  data?: TemplateResponse;
  hideBtns?: boolean
}> = (props) => {
  const { documentTemplate } = useAppSelector((store) => store);

  const { templateInitialConfig } = documentTemplate;

  const dispatch = useAppDispatch();

  const {
    data = {
      _id: "",
      name: "",
      orientation: "portrait",
      paper_size: "",
      sequence_id: "",
    },
  } = props;

  useEffect(() => {
    dispatch(
      updateTemplateConfig({
        name: data?.name || initialConfig.name,
        orientation: (data?.orientation ||
          initialConfig.orientation) as Orientation,
        paper_size: data?.paper_size || initialConfig.paper_size,
      })
    );
    // eslint-disable-next-line
  }, []);

  const updateState = (key: keyof TemplateResponse, value: any) => {
    dispatch(
      updateTemplateConfig({
        ...templateInitialConfig,
        [key]: value,
      })
    );
  };

  const onNextBtnClicked = () => {
    dispatch(closeDialog());
    props.onNextBtnClicked(data?._id || "isNew", {
      ...data,
      ...templateInitialConfig,
    });
  };

  return (
    <>
      {/* Heading */}
      <DialogTitle>
        <Typography
          variant="body1"
          fontFamily={"htrts_medium"}
          sx={{
            borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
            pb: 1,
          }}
        >
          {props?.isNew ? "Create Template" : data?.sequence_id}
        </Typography>
      </DialogTitle>

      <DialogContent>   
        <Grid container spacing={2} mt={0.2}>
          {/* Template Name */}
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <TextField
              fullWidth
              label={"Template Name"}
              value={templateInitialConfig?.name}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                updateState("name", e.target.value)
              }
            />
          </Grid>

          {/* Paper Size */}
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <SelectBox
              label={"Paper Size"}
              value={paperSizeOptions.find(
                (p) => (templateInitialConfig?.paper_size || "") === p?.value
              )}
              options={paperSizeOptions}
              loading={false}
              onChange={(data: any) => updateState("paper_size", data.value)}
              error={false}
            />
          </Grid>

          {/* Tag */}
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <SelectBox
              label={"Orientation"}
              value={orientationOptions.find(
                (or) => (templateInitialConfig?.orientation || "") === or?.value
              )}
              options={orientationOptions}
              loading={false}
              isMulti={false}
              onChange={(data: any) => updateState("orientation", data.value)}
              error={false}
            />
          </Grid>
        </Grid>
      </DialogContent>

      {!props.hideBtns && 
        <DialogActions>
          <Grid container spacing={2} px={2} pb={1}>
            {/* Close Btn */}
            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
              <Button
                fullWidth
                variant="outlined"
                onClick={() => dispatch(closeDialog())}
              >
                {"Close"}
              </Button>
            </Grid>

            {/* Next Btn */}
            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
              <Button
                fullWidth
                variant="contained"
                onClick={() => onNextBtnClicked()}
                disabled={templateInitialConfig?.name?.trim() === ""}
              >
                {props?.isNew ? "Create" : "Next"}
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      }
    </>
  );
};
