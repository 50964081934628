import { Box } from "@mui/material";
import { useSnackbar } from "notistack";
import React from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import { DrawIoEmbed } from "../../components";
import { useLazyGetDocumentByIdQuery, useUpdateDocumentEditStatusMutation, useUpdateMaterialDocumentMutation, useUploadDocumentMutation } from "../../redux/services";
import { closeBackdrop, openBackdrop } from "../../redux/slices/backdrop";
import { AppRoutes } from "../../router/routes";
import { LocalStorageKeys, parseJwt } from "../../utils";
import { closeConfirmationDialog, openConfirmationDialog } from "../../redux/slices/confirmationDialog";
import { HasAccess } from "../../router/authorization";
import { PERMISSIONS } from "../../router/permission";


export const DrawIOEditor: React.FC<{ children?: JSX.Element }> = (props) => {

    const dispatch = useDispatch();
    const params = useParams();
    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const [getDocumentAPI] = useLazyGetDocumentByIdQuery();
    const [uploadFile] = useUploadDocumentMutation();
    const [updateDocument] = useUpdateMaterialDocumentMutation();
    const [updateDocumentEditStatus] = useUpdateDocumentEditStatusMutation();
    const { perm } = parseJwt(localStorage.getItem(LocalStorageKeys.authToken) ?? "");

    const updatable = React.useMemo(() => HasAccess(perm, PERMISSIONS.IEDRAW_UPDATE), [perm]);

    const [xmlFile, setXMLFile] = React.useState<any>(null);
    const drawioRef = React.useRef<any>(null);

    const getDocument = async () => {
        try {
            dispatch(openBackdrop('Preparing Editor'))

            const { data } = await getDocumentAPI({ document_id: params.document_id });

            const bomId = data.associations?.filter((_: any) => _.object_type === 'bom')?.[0]?.object_details?.[0]?._id;
            let newSearchParams:any = { fileName: data?.file_name ?? "Unknown" };

            if (bomId) {
                newSearchParams.bomId = bomId;
            }

            setSearchParams(newSearchParams);

            if (data && !data.released) {
                const tokenData = parseJwt(localStorage.getItem(LocalStorageKeys.authToken) ?? "");
                if (data?.activity?.opened && tokenData.sub !== data?.activity?.user_id) {
                    dispatch(openConfirmationDialog({
                        title: "Drawing Locked",
                        negativeBtn: "Go Home",
                        hideCloseIcon: true,
                        body: `This drawing has been currently opened by the user "${data?.activity?.user_name ?? ""}". Until the user close or finish edit you only preview the diagram.`,
                        onNegativeBtn: () => {
                            dispatch(closeConfirmationDialog());
                            navigate(AppRoutes.home);
                        },
                        onOk: () => {
                            dispatch(closeConfirmationDialog());
                            navigate(AppRoutes.drawioDiagramPreview(params.document_id));
                        }
                    }))
                } else {
                    let fetchData = await fetch(data?.url)
                    if (fetchData.ok) {
                        const xml = await fetchData.text();
                        setXMLFile(xml);
                        updateDocumentEditStatus({ documentId: params.document_id ?? "", activityType: 'open' })
                    }
                }
            } else if (data && data.released) {
                dispatch(openConfirmationDialog({
                    title: 'Published Diagram',
                    hideCloseIcon: true,
                    hideNegativeBtn: true,
                    positiveBtn: 'Open Preview',
                    body: 'This diagram, is already published to you can only preview this diagram.',
                    onOk: () => {
                        dispatch(closeConfirmationDialog());
                        navigate(AppRoutes.drawioDiagramPreview(params.document_id))
                    }
                }))
            } else {
                dispatch(openConfirmationDialog({
                    title: 'Diagram Not Found',
                    hideCloseIcon: true,
                    hideNegativeBtn: true,
                    body: 'No Diagram is found on this URL',
                    onOk: () => {
                        dispatch(closeConfirmationDialog());
                        navigate(AppRoutes.home)
                    }
                }))
            }
        } catch (error) {
            console.log('error:', error)
            setXMLFile(null);
        }
        dispatch(closeBackdrop())
    };

    const uploadDiagram = async (data: string, hideMessage?: boolean) => {

        if (!hideMessage) {
            dispatch(openBackdrop('Saving the diagram...'));
        }

        try {
            if (data) {

                const fileName = searchParams.get('fileName') ?? new URLSearchParams(window.location.search).get('fileName');
                const resData = await updateDocument({ document_id: params.document_id, update_version: false, update_document: true, file_name: fileName }).unwrap();

                if (resData) {
                    const file = new Blob([data], { type: 'text/xml' });
                    await uploadFile({ data: resData, url: resData.url, file });
                }

                if (!hideMessage) {
                    enqueueSnackbar('Diagram Saved', { variant: "success" });
                }
            }
        } catch (error: any) {
            console.log('error:', error);
            if (error?.moreInfo?.status === 409) {
                dispatch(openConfirmationDialog({
                    title: "Caution: Drawing Force Released",
                    hideNegativeBtn: true,
                    hideCloseIcon: true,
                    body: `
                    This drawing has been currently opened by the user "${error?.moreInfo?.data?.details?.user_name ?? ""}" after the last checkpoint. 
                    All your changes have already been saved.
                    You can only preview the diagram until it is closed by other user. 
                    `,
                    onOk: () => {
                        dispatch(closeConfirmationDialog());
                        navigate(AppRoutes.drawioDiagramPreview(params.document_id));
                    }
                }))
            }
            enqueueSnackbar(error.title, { variant: "error" });
        } finally {
            dispatch(closeBackdrop());
        }
    };

    const releaseDocument = async () => {
        await updateDocumentEditStatus({ documentId: params.document_id ?? "", activityType: 'close' });
    };

    React.useEffect(() => {
        const handleBeforeUnload = (event: any) => {
            releaseDocument();
        };

        const handleUnload = () => {
            releaseDocument();
        };

        window.addEventListener('beforeunload', handleBeforeUnload);
        window.addEventListener('unload', handleUnload);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
            window.removeEventListener('unload', handleUnload);
        };
        // eslint-disable-next-line
    }, []);


    React.useEffect(() => {
        if (updatable) {
            getDocument();
        } else {
            dispatch(openConfirmationDialog({
                title: "404 - Not Found",
                body: "The Diagram you are looking to edit is ethier not available or you don't have permission to edit it",
                hideNegativeBtn: true,
                positiveBtn: "Go Home",
                onOk: () => {
                    dispatch(closeConfirmationDialog());
                    navigate(AppRoutes.home);
                }
            }))
        }

        // eslint-disable-next-line
    }, []);

    const Editor = React.useMemo(() => {
        if (xmlFile) {
            return <DrawIoEmbed
                xml={xmlFile}
                ref={drawioRef}
                urlParameters={{
                    ui: "kennedy",
                    libraries: true,
                    libs: "general;uml;er;bpmn;flowchart;basic;arrows2;electrical;pid",
                    modified: false,
                    parent_bom: searchParams.get('bomId') ?? "",
                    file_name: searchParams.get('fileName') ?? "",
                }}
                onExport={(data) => {
                    uploadDiagram(data.xml);
                }}
                onClose={(data) => {
                    if(data.event === 'exit' && data.modified === false){
                        releaseDocument();
                        navigate(AppRoutes.home);
                    }else{
                        alert('Your changes are not saved, Please and exit.')
                    }
                }}
                onAutoSave={(data) => {
                    uploadDiagram(data.xml, true);
                }}
            />
        } else {
            return <></>
        }
        // eslint-disable-next-line
    }, [xmlFile]);

    return <Box>
        {Editor}
    </Box>
}