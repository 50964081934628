import { Download } from "@mui/icons-material";
import { Alert, Box, Button, Tooltip, useTheme } from "@mui/material";
import { GridRowSelectionModel, GridSortDirection, GridSortModel } from "@mui/x-data-grid-premium";
import moment from "moment";
import { useSnackbar } from "notistack";
import React from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { CustomDataGrid, DeletePanel, TitleHeader } from "../../../components";
import { AddEditDocument, FileSelectionOptionType, MultipleDoc } from "../../../components/documents/addEditDocument";
import { fileSelectOptions, giveMeLocationAndDocumentTags } from "../../../components/documents/utils";
import { SelectBoxOption } from "../../../interfaces";
import { useAppDispatch, useAppSelector } from "../../../redux";
import { useDeleteMaterialDocumentMutation, useDuplicateDocumentMutation, useLazyBulkDownloadDocumentsQuery, useLazyGetDocumentsQuery } from "../../../redux/services";
import { closeBackdrop, openBackdrop } from "../../../redux/slices/backdrop";
import { closeConfirmationDialog, openConfirmationDialog, updateConfirmationDialogPositiveBtn, updateCongirmationDialogBody } from "../../../redux/slices/confirmationDialog";
import { closeDialog, openDialog } from "../../../redux/slices/dialog";
import { HasAccess } from "../../../router/authorization";
import { PERMISSIONS } from "../../../router/permission";
import { AppRoutes } from "../../../router/routes";
import { useStyles } from "../../documentTemplates/styles";
import { DependencyChildren, PublishComponent } from "../../materials/dashboard";
import { DashboardTableColumn } from "./utils";

export const versionDropdownOption = [{ value: "false", label: "Current Revision" }, { value: "true", label: "All Revision" }]

export const Documents: React.FC<{ children?: JSX.Element, object_type?: 'material' | 'project' | 'vendor' | 'proposal' | 'product', refetch?: any }> = (props) => {

    const classes = useStyles(useTheme());
    const [getFolderDocuments, { data: allData = { data: [], totalCount: 0 }, isLoading, isError, isFetching }] = useLazyGetDocumentsQuery();
    const { data, totalCount } = allData;

    const [paginationModel, setPaginationModel] = React.useState({
        page: 0,
        pageSize: 10,
    });

    const { perm } = useAppSelector(store => store.auth.userDetails);

    const [params] = useSearchParams();
    const dispatch = useAppDispatch();
    const [deleteDocumentMutation] = useDeleteMaterialDocumentMutation();
    const [duplicateDocument] = useDuplicateDocumentMutation();
    const { enqueueSnackbar } = useSnackbar();
    const [downloadDocuments] = useLazyBulkDownloadDocumentsQuery();

    const [dropDown, updateDropDown] = React.useState<SelectBoxOption>(versionDropdownOption[0]);
    const [search, setSearch] = React.useState<string>("");
    const [queryOptions, setQueryOptions] = React.useState({ sortModel: [{ field: 'sequence_id', sort: 'desc' as GridSortDirection }] });
    const [rowSelectionModel, setRowSelectionModel] = React.useState<GridRowSelectionModel>([]);

    const folderName = `${params.get('sequence_id') ? `${params.get('sequence_id')}:` : ``} ${params.get("folder")}`;

    const creatable = React.useMemo(() => HasAccess(perm, PERMISSIONS.DOCUMENT_CREATE), [perm]);
    const readable = React.useMemo(() => HasAccess(perm, PERMISSIONS.DOCUMENT_READ), [perm]);
    const updatable = React.useMemo(() => HasAccess(perm, PERMISSIONS.DOCUMENT_UPDATE), [perm]);
    const deletable = React.useMemo(() => HasAccess(perm, PERMISSIONS.DOCUMENT_DELETE), [perm]);
    const publishable = React.useMemo(() => HasAccess(perm, PERMISSIONS.DOCUMENT_PUBLISH), [perm]);

    const navigate = useNavigate();

    const handleSortModelChange = React.useCallback((sortModel: GridSortModel) => {
        // Here you save the data you need from the sort model
        setQueryOptions({ sortModel: [...sortModel] });
    }, [])

    const getAllDocuments = () => {
        getFolderDocuments({
            include_history: dropDown?.value ?? false,
            include_assoc: false,
            include_all: true,
            object_type: props.object_type,
            object_id: params.get('object_id'),
            tag_group_id: params.get('group_id'),
            tag_id: params.get('id'),
            sort_by: queryOptions?.sortModel?.[0]?.field,
            sort_order: queryOptions?.sortModel?.[0]?.sort,
            ...paginationModel
        })
        props.refetch();
    }

    const giveMeRows = (): any[] => {
        if (!isLoading && data && !isFetching) {
            if (search.trim().length) {
                return data?.filter((_: any) => (_?.sequence_id?.toLowerCase()?.includes(search.toLowerCase())
                    || _?.file_name?.toLowerCase()?.includes(search.toLowerCase())
                    || (giveMeLocationAndDocumentTags(_.tags_info).document_tags)?.label?.toLowerCase()?.includes(search.toLowerCase())
                    || _?.size?.toString()?.toLowerCase()?.includes(search.toLowerCase())
                    || _?.created_user?.toString()?.toLowerCase()?.includes(search.toLowerCase())
                    || moment(_.created_time).format('lll').toString().includes(search.toLowerCase())
                )) ?? []
            }
            return data ? data : [];
        } else {
            return [];
        }
    }

    const openAddEdit = (data: any, isEdit: boolean = true, selectedOption?: FileSelectionOptionType) => {
        if (updatable) {
            const id = params.get('object_id') ? params.get('object_id') : "";
            const objType = props.object_type ? props.object_type : "";
            const selectedFolder = {
                label: params.get('folder'),
                value: params.get('id'),
                tag_group_id: params.get('group_id')
            };
            if (isEdit) {
                dispatch(openDialog({
                    title: "",
                    hideNegativeBtn: true,
                    hidePositiveBtn: true,
                    enablePadding: false,
                    body: <AddEditDocument isEdit={isEdit} data={data} objectType={objType} Id={""} refetch={getAllDocuments} _id={id || ""} folderSelected={selectedFolder} />
                }));
            } else {
                if (selectedOption && selectedOption === "single") {
                    dispatch(openDialog({
                        title: "",
                        hideNegativeBtn: true,
                        hidePositiveBtn: true,
                        enablePadding: false,
                        body: <AddEditDocument isEdit={isEdit} data={data} objectType={objType} Id={""} refetch={getAllDocuments} _id={id || ""} folderSelected={selectedFolder} />
                    }));
                } else if (selectedOption && selectedOption === "multiple") {
                    dispatch(openDialog({
                        title: "",
                        hideNegativeBtn: true,
                        hidePositiveBtn: true,
                        maxWidth: "lg",
                        enablePadding: false,
                        body: <MultipleDoc isEdit={isEdit} data={data} objectType={objType} Id={""} refetch={getAllDocuments} _id={id || ""} folderSelected={selectedFolder} files={[]} />,
                    }))
                }
            }
        }
    }

    const deleteDocument = (document: any) => {
        let message = document.associations.length === 0 ?
            `Are you sure want to delete the Document ${document?.sequence_id}-r${document?.version} ?`
            : `Check for dependency before deleting the Document ${document.sequence_id}-r${document.version}?`;
        dispatch(openConfirmationDialog({
            title: "Delete Operation",
            body: <DeletePanel
                message={message}
                warningMessage={document.associations.length === 0 ? "Warning: After delete its not possible to retrieve" : ''}
            />,
            positiveBtn: document.associations.length === 0 ? "Delete" : "Check for dependencies",
            negativeBtn: "Cancel",
            onOk: () => performRowDel(document, message, false),
            onNegativeBtn: () => dispatch(closeConfirmationDialog())
        }))
    }

    const performRowDel = async (document: any, message: string, force: boolean = false) => {
        dispatch(openBackdrop("Checking Dependencies and deleting document..."));
        let res: any = {};
        try {
            res = await deleteDocumentMutation({ id: document._id, force })

            if (Object.keys(res).includes("data")) {
                enqueueSnackbar(`Deleted document Successfully!`, { variant: "success" })
                dispatch(closeConfirmationDialog());
                getAllDocuments();
            } else if (Object.keys(res).includes("error")) {
                // eslint-disable-next-line 
                throw res.error.data;
            } else {
                // eslint-disable-next-line 
                throw "Data not found"
            }
        } catch (error: any) {
            let errorMessage: string = error?.title ?? "Opps! Something went wrong, Unable to delete"
            enqueueSnackbar(errorMessage, { variant: "error" });
            dispatch(updateCongirmationDialogBody(<DeletePanel
                message={"Dependency Table of " + document?.sequence_id + "-r" + document?.version}
                errorMessage={errorMessage}
                errorMessageStacks={document?.associations ?? []}
                warningMessage={""}
            />))

            dispatch(updateConfirmationDialogPositiveBtn({
                positiveBtn: "Force Delete",
                hidePositiveBtn: !error.title.toLowerCase().includes('linked'),
                onOk: () => dispatch(updateConfirmationDialogPositiveBtn({
                    positiveBtn: "Force Delete",
                    onOk: () => {
                        dispatch(updateCongirmationDialogBody(<DeletePanel
                            message={"Are you sure want to force delete the Document " + document?.sequence_id + "-r" + document?.version + "?"}
                            warningMessage={"Warning: After delete its not possible to retrieve"}
                        />))
                        dispatch(updateConfirmationDialogPositiveBtn({
                            positiveBtn: "Ok",
                            onOk: () => performRowDel(document, "", true)
                        }))
                    },
                }))
            }))
        } finally {
            dispatch(closeBackdrop());
        }
    }

    const openAssociation = (document: any) => {
        dispatch(openConfirmationDialog({
            title: "Associations",
            body: <DeletePanel
                message={document?.associations ? document.associations.length === 0 ? 'No dependency found' : `Dependency Table of ${document?.sequence_id}-r${document.version}` : "No dependency found"}
                errorMessageStacks={document?.associations ?? []}
            />,
            hideNegativeBtn: true,
            hidePositiveBtn: true
        }))
    }

    const openReferences = (document: any) => {
        dispatch(openConfirmationDialog({
            title: "References",
            body: <DeletePanel
                message={document?.references ? document.references.length === 0 ? 'No references found' : `References Table of ${document?.sequence_id}-r${document.version}` : "No references found"}
                errorMessageStacks={document?.references ?? []}
            />,
            hideNegativeBtn: true,
            hidePositiveBtn: true
        }))
    }

    let ROWS = giveMeRows();

    const updatePublishStatus = (row: any) => {
        dispatch(
            openConfirmationDialog({
                title: "Publish Operation",
                body: <DeletePanel
                    message={`Are you sure you want to publish the document ${row?.sequence_id}?`}
                    warningMessage={`You will not be able to edit this revision after publishing. You can however create new revisions.`}
                />,
                positiveBtn: "Check for dependencies",
                negativeBtn: "Cancel",
                onOk: () => openDependencyTable(row),
                onNegativeBtn: () => dispatch(closeConfirmationDialog())
            })
        );
    };

    const openDependencyTable = (row: any) => {
        dispatch(closeConfirmationDialog())
        try {
            dispatch(openDialog({
                title: "",
                body: <DependencyChildren object_id={row?._id} object_type="document" onPublish={() => proceedToPublish(row)} />,
                hideNegativeBtn: true, hidePositiveBtn: true, enablePadding: false
            }));
        } catch (error) {
            console.error(`Unable to get the hierarchy: ${error}`);
            enqueueSnackbar("Error getting dependencies", { variant: "error" })
            dispatch(closeDialog())
        }
    };

    const proceedToPublish = (row: any) => {
        dispatch(openDialog({
            title: "",
            body: <PublishComponent {...row} refetch={getAllDocuments} object_type="document" object_id={row?._id} />,
            hidePositiveBtn: true,
            hideNegativeBtn: true,
            enablePadding: false
        }));
    }

    const handleDuplicate = (data: any) => {
        let message = `Do you want to create a copy of this "${data.sequence_id}" document?`;
        dispatch(
            openConfirmationDialog({
                title: "Are you sure?",
                body: message,
                positiveBtn: "Duplicate",
                negativeBtn: "Cancel",
                onOk: () => performDuplicate(data),
                onNegativeBtn: () => dispatch(closeConfirmationDialog()),
            })
        );
    };

    const performDuplicate = async (data: any) => {
        dispatch(openBackdrop(`Creating copy of "${data.sequence_id}" document....`));
        let res: any = {};
        try {
            res = await duplicateDocument(data?._id);
            if (Object.keys(res).includes("data")) {
                enqueueSnackbar(
                    res?.data?.message,
                    { variant: "success" }
                );
                getAllDocuments();
            } else if (Object.keys(res).includes("error")) {
                // eslint-disable-next-line
                throw "Unable to create copy";
            } else {
                // eslint-disable-next-line
                throw "Data not found";
            }
        } catch (error) {
            let errorMessage: string = `Unable to create copy of ${data.sequence_id} document!`;
            enqueueSnackbar(errorMessage, { variant: "error" });
        } finally {
            dispatch(closeBackdrop());
            dispatch(closeConfirmationDialog())
        }
    };

    const bulkDownload = async () => {
        try {
            dispatch(openBackdrop('Starting Download....'))
            await downloadDocuments({ document_ids: rowSelectionModel as string[], file_name: `${folderName} - Documents` })
            enqueueSnackbar('Download Started', { variant: "success" })
        } catch (error) {
            console.log('error:', error)
            enqueueSnackbar('Something went wrong, unable to start download', { variant: "error" })
        } finally {
            dispatch(closeBackdrop())
        }
    }

    React.useEffect(() => {
        getAllDocuments();
        // eslint-disable-next-line
    }, [params, dropDown, paginationModel, JSON.stringify(queryOptions?.sortModel)]);

    const handleAnnotate = (id: string) => {
        navigate(AppRoutes.annotateViewer(id), {
            state: {
                paths: [{ path: AppRoutes.documents, title: 'Documents' }]
            }
        })
    };

    return <Box sx={classes.root}>

        {/* Download Button */}
        {rowSelectionModel.length > 0 && <Tooltip
            title={`Download Selected documents`}
        >
            <Button
                disableRipple
                variant="outlined"
                color="info"
                sx={{ minWidth: "8px", position: "absolute", top: "9px" }}
                onClick={() => bulkDownload()}
            >
                <Download /> <span style={{ marginLeft: 1 }}>Download {`(${rowSelectionModel.length})`}</span>
            </Button>
        </Tooltip>}

        {/* Header */}
        <TitleHeader
            title={folderName ? folderName : "Folder"}
            count={ROWS?.length ?? 0}
            showSearch={true}
            search={search}
            onSearchChange={setSearch}
            searchPlaceholder={"Search for Documents (only within this page)"}
            showCreateBtn={creatable}
            createBtnLabel={"Create Document"}
            disableCreateBtn={isLoading || isFetching}
            menuOptions={fileSelectOptions}
            onCreateBtn={(value: FileSelectionOptionType | undefined) => {
                if (!isLoading && !isFetching) {
                    openAddEdit(null, false, value)
                }
            }}
            showDropDown={true}
            onDropDownChange={(data: SelectBoxOption) => updateDropDown(data)}
            dropDownLabel="Revision"
            dropDownOptions={versionDropdownOption}
            dropDownValue={dropDown}
        />

        {/* Error */}
        {isError && <Alert sx={{ mt: 2 }} severity="error">Oops! Something went wrong, Unable to fetch documents. Try Again Later!</Alert>}

        {/* Data Drid */}
        <Box mt={2}>
            <CustomDataGrid
                id="document-module-list"
                // initialState={{
                //     sorting: {
                //         sortModel: [{ field: 'sequence_id', sort: 'desc' }],
                //     },
                // }}
                rows={ROWS}
                columns={DashboardTableColumn(openAssociation, (data: any) => openAddEdit(data, true), deleteDocument, updatePublishStatus, handleAnnotate, openReferences, !readable, !deletable, publishable, creatable, handleDuplicate)}
                loading={isLoading || isFetching}
                getRowId={(row) => row?._id}
                showToolbar={true}
                rowCount={totalCount}
                paginationModel={paginationModel}
                paginationMode="server"
                onPaginationModelChange={setPaginationModel}
                sortingMode="server"
                onSortModelChange={handleSortModelChange}
                checkboxSelection={true}
                onRowSelectionModelChange={(newRowSelectionModel) =>
                    setRowSelectionModel(newRowSelectionModel)
                }
                rowSelectionModel={rowSelectionModel}
            />
        </Box>
    </Box>
}