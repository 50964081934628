import { PictureAsPdf } from "@mui/icons-material";
import { Box, Grid } from "@mui/material";
import React from "react";
import { MyPaper, SimpleSideNavBar } from "../../../components";
import { SecondaryNavBars } from "../../../interfaces";

export const CutSheets: React.FC<{ children?: JSX.Element, cutsheetId: string }> = ({ cutsheetId = "New", ...props }) => {

    const [pdf, updatePDF] = React.useState<SecondaryNavBars[]>([])

    const addPDF = () => {
        pdf.push({
            id: pdf.length + 1 + "",
            title: "PDF Name" + pdf.length,
            icon: <PictureAsPdf />,
            path: ""
        })
        updatePDF([...pdf]);
    }

    return <Box>
        <Grid container spacing={1}>
            <Grid item xs={12} sm={3}>
                <SimpleSideNavBar
                    navBars={pdf}
                    showAddBtn={true}
                    onAddBtnClick={addPDF}
                    variant={"whitecard"}
                    title={"PDF Upload"}
                />
            </Grid>
            <Grid item xs={12} sm={9}>
                <MyPaper height={"100%"} >
                    <></>
                </MyPaper>
            </Grid>
        </Grid>
    </Box>
}