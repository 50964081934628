import { Box, Button, Chip, Grid, Stack, Typography } from "@mui/material";
import React from "react";
import { Association, AssociationObjectDetails, ReactSelectOption, SelectBoxOption } from "../../interfaces";
import { useAppSelector } from "../../redux";
import { useLazyGetProductsAsDDQuery, useLazyGetProjectsAsDDQuery, useLazyGetProposalsAsDDQuery } from "../../redux/services";
import { HasAccess } from "../../router/authorization";
import { PERMISSIONS } from "../../router/permission";
import { SelectBox } from "../reactSelect";

const modules: ReactSelectOption[] = [
    { value: "product", label: "Product" },
    { value: "proposal", label: "Proposal" },
    { value: "project", label: "Project" }
]

export const ModuleMapper: React.FC<{
    children?: JSX.Element,
    disableProduct?: boolean,
    disableProposal?: boolean,
    disableProject?: boolean,
    associations: Association[],
    onChange?: (associations: Association[]) => void,
    onOptionChange?: (object_type: string | number | null, object_id: string[] | string | number | null) => void,
    showAdd?: boolean,
    errors?: { moduleError: boolean, moduleOptionError: boolean },
    optionsValue?: { moduleValue: string | number, moduleOptionValue: string[] | string | number }
    shouldSkip?: boolean
    isMulti?: boolean
}> = ({ disableProduct, disableProject, disableProposal, associations, onChange = () => { }, showAdd = false, onOptionChange = () => { }, optionsValue, errors = { moduleError: false, moduleOptionError: false }, shouldSkip = false, isMulti = false, ...props }) => {

    const [state, setState] = React.useState<{ module: SelectBoxOption | null, moduleOption: SelectBoxOption[] | SelectBoxOption | null, isLoading?: boolean, options: any[], error?: boolean }>({ options: [], module: null, moduleOption: isMulti ? [] : null });
    const [page, setPageSize] = React.useState<number>(1);

    const { perm } = useAppSelector(store => store.auth.userDetails);

    const projectUpdatable = React.useMemo(() => HasAccess(perm, PERMISSIONS.PROJECT_UPDATE), [perm]);
    const proposalUpdatable = React.useMemo(() => HasAccess(perm, PERMISSIONS.PROPOSAL_UPDATE), [perm]);
    const productUpdatable = React.useMemo(() => HasAccess(perm, PERMISSIONS.PRODUCT_UPDATE), [perm]);

    const moduleAccess = React.useMemo(() => {
        let access = [];
        if (projectUpdatable) {
            access.push("project")
        }

        if (proposalUpdatable) {
            access.push("proposal")
        }

        if (productUpdatable) {
            access.push("product")
        }

        return access;
    }, [projectUpdatable, proposalUpdatable, productUpdatable]);
    
    let moduleOptions = React.useMemo(() => modules.filter(m => moduleAccess.includes(m.value)), [moduleAccess])

    const skipEffectRef = React.useRef(true);

    const [getProducts] = useLazyGetProductsAsDDQuery();
    const [getProposals] = useLazyGetProposalsAsDDQuery();
    const [getProjects] = useLazyGetProjectsAsDDQuery();

    const getModuleOptions = (value: string): Promise<any[]> => {
        return new Promise<any[]>(async (resolve, reject) => {
            let data: any[] = [];
            try {
                setState((prevState) => ({ ...prevState, isLoading: true, error: false }));
                switch (value) {
                    case 'product':
                        data = await getProducts({ page_no: page, page_size: 50 }).unwrap();
                        break;

                    case 'proposal':
                        data = await getProposals({ page_no: page, page_size: 50 }).unwrap();
                        break;

                    case 'project':
                        data = await getProjects({ page_no: page, page_size: 50 }).unwrap();
                        break;

                    default:
                        break;
                };

            } catch (error) {
                console.error('error from module options fetching:', error);
                setState((prevState) => ({ ...prevState, error: true }));
            } finally {
                setState((prevState) => ({ ...prevState, isLoading: false, options: data ?? [] }));
                resolve(data);
            }
        })
    };

    const getAllModuleOptions = (value: string): Promise<any[]> => {
        return new Promise<any[]>(async (resolve, reject) => {
            let data: any[] = [];
            try {
                setState((prevState) => ({ ...prevState, isLoading: true, error: false }));
                switch (value) {
                    case 'product':
                        data = await getProducts({}).unwrap();
                        break;

                    case 'proposal':
                        data = await getProposals({}).unwrap();
                        break;

                    case 'project':
                        data = await getProjects({}).unwrap();
                        break;

                    default:
                        break;
                };

            } catch (error) {
                console.error('error from module options fetching:', error);
                setState((prevState) => ({ ...prevState, error: true }));
            } finally {
                setState((prevState) => ({ ...prevState, isLoading: false, options: data ?? [] }));
                resolve(data);
            }
        })
    };

    const handleDelete = (association: Association, obj_det: AssociationObjectDetails, parentIndex: number, childIndex: number) => {

    };

    const loadOption = async (module: string) => {
        let options = await getModuleOptions(module);
        return { options: options, hasMore: options.length === 50 }
    };

    const setInitialModuleOption = async () => {
        if (optionsValue?.moduleValue) {
            await getAllModuleOptions(optionsValue?.moduleValue as string).then((res) => {
                let selectedModule = moduleOptions.find(mod => mod.value === optionsValue?.moduleValue) ?? null;
                let selectedModuleOption = res?.find(r => r?.value === optionsValue?.moduleOptionValue) ?? null;
                setState((prevState) => ({ ...prevState, module: selectedModule, moduleOption: selectedModuleOption }));
            }).catch((error) => {
                console.error(`Unable to get module option: ${error}`)
                setState((prevState) => ({ ...prevState, error: true }));
            }).finally(() => {
                if (Object.keys(optionsValue || {}).length === 0 || optionsValue?.moduleValue !== "") {
                    skipEffectRef.current = false;
                }
            });
        }
    }

    React.useEffect(() => {
        if (!skipEffectRef?.current || shouldSkip) {
            setPageSize(1);
            setState((prevState) => ({ ...prevState, options: [] }));
            getModuleOptions((state.module?.value ?? "") as string);
            if (Array.isArray(state.moduleOption)) {
                onOptionChange(state.module?.value ?? null, state.moduleOption.map(m => m?._id) as string[])
            } else {
                onOptionChange(state.module?.value ?? null, state.moduleOption?.value ?? null)
            }
        }
        // eslint-disable-next-line
    }, [state.module]);

    React.useEffect(() => {
        if (!skipEffectRef?.current || shouldSkip) {
            if (Array.isArray(state.moduleOption)) {
                onOptionChange(state.module?.value ?? null, state.moduleOption.map(m => m?._id) as string[])
            } else {
                onOptionChange(state.module?.value ?? null, state.moduleOption?.value ?? null)
            }
        }
        // eslint-disable-next-line
    }, [state.moduleOption]);

    React.useEffect(() => {
        if (skipEffectRef?.current || shouldSkip) {
            setInitialModuleOption();
        }
        // eslint-disable-next-line
    }, [optionsValue]);

    return <Box>
        <Grid container spacing={2} alignItems={"flex-start"}>
            <Grid item xs={12} md={showAdd ? 5 : 6}>
                <SelectBox
                    id="select-module"
                    label={"Module"}
                    value={state.module}
                    options={moduleOptions}
                    onChange={(data: ReactSelectOption) => setState({ ...state, module: data, moduleOption: isMulti ? [] : null })}
                    isRequired={true}
                    error={errors?.moduleError}
                    helperText={
                        errors.moduleError ? "Please select the module" : ""
                    }
                    isMenuFixed
                />
            </Grid>
            <Grid item xs={12} md={showAdd ? 5 : 6}>
                <SelectBox
                    id={(state?.module?.value as string) ?? "module-option"}
                    isPaginate
                    loadOptions={() => loadOption((state.module?.value ?? "") as string)}
                    label={state.module?.label ?? "Module Option"}
                    value={state.moduleOption}
                    loading={state.isLoading}
                    isDisabled={!state.module}
                    options={[]}
                    onChange={(data: ReactSelectOption | ReactSelectOption[]) => {
                        setState({ ...state, moduleOption: data })
                    }}
                    isRequired={true}
                    error={errors?.moduleOptionError}
                    helperText={
                        errors.moduleOptionError ? `Please select the ${state.module?.label ?? 'module option'}` : ""
                    }
                    isMenuFixed
                    isMulti={isMulti}
                />
            </Grid>

            {showAdd &&
                <Grid item xs={12} sm={2}>
                    <Button color="primary" variant="contained">Add</Button>
                </Grid>
            }
        </Grid>
        {state.error && <Typography color={"error"} variant="caption">{'Something went wrong unable to fetch, ' + state.module?.label}</Typography>}

        <Stack direction={"row"} spacing={2}>
            {associations.map((association, parentIndex) => {
                if (['product', 'project', 'proposal'].includes(association.object_type)) {
                    return association.object_details.map((obj_det, childIndex) => {
                        return <Chip label={`${obj_det.sequence_id}-r${obj_det.version}`} onDelete={() => handleDelete(association, obj_det, parentIndex, childIndex)} />
                    })
                }
                return <></>;
            })}
        </Stack>
    </Box>
};
