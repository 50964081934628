import { Tooltip, Typography } from "@mui/material";
import { GridRenderCellParams } from "@mui/x-data-grid-premium";
import { Link } from "react-router-dom";
import { AppRoutes } from "../../router/routes";

export const DashboardTableColumn = (onDelete: any) => ([
    {
        field: 'sequence_id', headerName: 'Cutsheet ID', width: 150, renderCell: (params: GridRenderCellParams) => {
            return <Link to={AppRoutes.viewCutSheetBasicDetails(params.row?.sequence_id ?? "New")}>{params.row?.sequence_id}</Link >
        }
    },
    {
        field: 'name', headerName: 'Cutsheet Name', width: 300, renderCell: (params: GridRenderCellParams) => {
            return <Tooltip title={params.row.name}><Typography variant="body2" sx={{ width: "100%", overflow: "hidden", textOverflow: "ellipsis" }}>{params.row.name}</Typography></Tooltip>
        }
    },
    { field: 'header_footer_template', headerName: 'Header Footer Template', width: 200 },
    { field: 'cutsheet_page_template', headerName: 'Cutsheet Page Template', width: 200 },
    {
        field: 'document_id', headerName: 'View Document', width: 200, renderCell: (params: GridRenderCellParams) => {
            return <Link to={AppRoutes.viewMatDocuments(params.row?.document_id ?? "New")}>{params.row?.document_id}</Link >
        }
    }
]);