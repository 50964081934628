import { Box, Chip, Grid, Tab, Tabs, Typography, Checkbox, Stack, Button } from "@mui/material";
import React from "react";
import { useAppDispatch, useAppSelector } from "../../redux";
import { updateSelectedChip, clearSelectedChip, selectAllChip, SelectedDependency, DepKeys } from "../../redux/slices/utils";

export const DeletePanel: React.FC<{
    children?: JSX.Element,
    message?: string,
    warningMessage?: string,
    errorMessage?: string,
    errorMessageStacks?: {
        object_type: DepKeys,
        object_details: any
    }[],
    chipSelectable?: boolean
    isMaterial?: boolean
    errorLinkingMessage?: string
}> = ({
    message, errorMessage, errorMessageStacks = [],
    warningMessage, chipSelectable = false, isMaterial = false, errorLinkingMessage = ""
}) => {

        const [tab, updateTab] = React.useState(0);
        const { selectedChip } = useAppSelector(store => store.utils)
        const dispatch = useAppDispatch();

        React.useEffect(() => {
            updateTab(0);
            // eslint-disable-next-line
        }, [errorMessageStacks])

        return <Box>
            {message && <Typography gutterBottom >{message}</Typography>}

            {errorMessageStacks.length > 0 && <>
                <Tabs
                    value={tab}
                    onChange={(e, tab) => updateTab(tab)}
                    indicatorColor="primary"
                    textColor="primary"
                    sx={{
                        background: (theme) => theme.palette.background.default,
                        border: (theme) => `1px solid ${theme.palette.divider}`,
                        borderTopLeftRadius: 8, borderTopRightRadius: 8
                    }}
                >
                    {errorMessageStacks?.map((stack, index) => <Tab sx={{ background: tab === index ? "white" : "" }} key={index} value={index} label={stack.object_type} />)}
                </Tabs>
                <Box p={2} sx={{
                    border: (theme) => `1px solid ${theme.palette.divider}`,
                }}>
                    <Grid container spacing={1}>
                        {isMaterial && errorMessageStacks[tab].object_type === "bom" && <Typography variant="caption" color="error">{errorLinkingMessage}</Typography>}
                        {errorMessageStacks[tab].object_details.map((det : any, index : any) => {
                            let objectType = errorMessageStacks[tab].object_type;
                            return <Grid key={index} item>
                                <Chip
                                    icon={chipSelectable ?
                                        <Checkbox
                                            checked={selectedChip?.[objectType]?.includes(det._id)}
                                            onChange={(e) => dispatch(updateSelectedChip({ _id: det._id, key: objectType, index: selectedChip[objectType]?.indexOf(det._id) as number, checked: e.target.checked }))}
                                        /> : <></>}
                                    variant="outlined"
                                    label={`${det.sequence_id}-r${det.version}`}
                                    disabled={objectType === "bom" && isMaterial}
                                />
                            </Grid>
                        })}
                    </Grid>
                </Box>
                {chipSelectable && <Stack mt={1} direction={"row"} spacing={1}>
                    <Button id="select-all" size="small" variant="contained" onClick={(e) => {
                        if (isMaterial && errorMessageStacks[tab]?.object_type === "bom") {
                            return false;
                        }
                        const mapped: Partial<SelectedDependency> = {};
                        errorMessageStacks.forEach(e => mapped[e.object_type as DepKeys] = (e.object_type === "bom" && isMaterial) ? [] : e.object_details.map((_ : any) => _._id));
                        dispatch(selectAllChip(mapped as Partial<SelectedDependency>));
                    }} >Select All</Button>
                    <Button size="small" variant="outlined" onClick={(e) => dispatch(clearSelectedChip())} >Unselect All</Button>
                </Stack>}
            </>}
            {errorMessage && <Typography mt={1}>{errorMessage}</Typography>}
            {warningMessage && <Typography variant="caption" color="orange">{warningMessage}</Typography>}
        </Box>
    }