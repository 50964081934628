import React from "react";
import { useDispatch } from "react-redux";
import { useParams, useNavigate } from "react-router";
import { useLazyGetDocumentByIdQuery } from "../../redux/services";
import { closeBackdrop, openBackdrop } from "../../redux/slices/backdrop";
import { AppRoutes } from "../../router/routes";
import { HasAccess } from "../../router/authorization";
import { PERMISSIONS } from "../../router/permission";
import { closeConfirmationDialog, openConfirmationDialog } from "../../redux/slices/confirmationDialog";
import { LocalStorageKeys, parseJwt } from "../../utils";


export const DrawIOPreview: React.FC<{ children?: JSX.Element }> = (props) => {

    const params = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [getDocumentAPI] = useLazyGetDocumentByIdQuery();
    const { perm } = parseJwt(localStorage.getItem(LocalStorageKeys.authToken) ?? "");

    const readable = React.useMemo(() => HasAccess(perm, PERMISSIONS.IEDRAW_READ), [perm]);

    const [xmlFile, setXMLFile] = React.useState<any>(null);

    const getDocument = async () => {
        try {
            dispatch(openBackdrop('Preparing Preview...'))
            const data = await getDocumentAPI({ document_id: params.document_id }).unwrap();
            if (data) {
                // let fetchData = await fetch(data?.url)
                // if (fetchData.ok) {
                //     const res = await fetchData.text();
                //     const xml = atob(res);
                //     setXMLFile(xml);
                // }
                setXMLFile(data?.url);
            }
        } catch (error) {
            console.log('error:', error)
            setXMLFile(null);
        }
        dispatch(closeBackdrop())
    };

    React.useEffect(() => {
        if (readable) {
            getDocument();
        } else {
            dispatch(openConfirmationDialog({
                title: "404 - Not Found",
                body: "The Diagram you are looking to preview ethier not available or you don't have permission to preview it",
                hideNegativeBtn: true,
                positiveBtn: "Go Home",
                onOk: () => {
                    dispatch(closeConfirmationDialog());
                    navigate(AppRoutes.home);
                }
            }))
        }
        // eslint-disable-next-line
    }, []);

    if (xmlFile) {
        return <iframe
            id='drawio-editor-iframe'
            className="diagrams-iframe"
            src={`https://app.diagrams.net/?lightbox=1&edit=${window.location.origin}${AppRoutes.drawioDiagramEditor(params.document_id)}#U${xmlFile}`}
            title="Diagrams.net"
            style={{
                width: '100%',
                height: '100%',
                minWidth: '400px',
                minHeight: '400px',
                border: 'none'
            }}
        />
    } else {
        return <></>
    }
}