import { LoadingButton } from "@mui/lab";
import { Alert, Box, Button, Grid, TextField, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import React from "react";
import { useDispatch } from "react-redux";
import { HighlightSelectBox } from "../../../components";
import { SelectBoxOption } from "../../../interfaces";
import { useCreateTagMutation, useGetTagGroupsDDQuery, useUpdateTagMutation } from "../../../redux/services";
import { closeBackdrop, openBackdrop } from "../../../redux/slices/backdrop";
import { closeDialog } from "../../../redux/slices/dialog";

export const AddEditTags: React.FC<{ children?: JSX.Element, isEdit: boolean, data: any, refetch: any }> = (props) => {

    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const searchparams = new URLSearchParams(window.location.search);
    const [createTag, createTagData] = useCreateTagMutation();
    const [updateTag, updateTagData] = useUpdateTagMutation();
    const { data: groups, isFetching, isError, isSuccess } = useGetTagGroupsDDQuery(null);

    const [state, setState] = React.useState<{ group: SelectBoxOption, name: string, description: string }>({
        group: { value: props.isEdit ? "" : searchparams.get('group') ?? "", label: props.isEdit ? "" : searchparams.get('name') ?? "" },
        name: "",
        description: "",
    });

    const [error, setError] = React.useState({
        group: false,
        name: false,
        description: false,
    })

    const updateState = (key: string, value: string) => {
        setState({ ...state, [key]: value });
        setError({ ...error, [key]: !value?.length })
    }

    const updateDropDown = (key: string, data: SelectBoxOption) => {
        setState({ ...state, [key]: data });
        setError({ ...error, [key]: !data?.value })
    }

    const onCancelBtnClicked = () => {
        dispatch(closeDialog());
    }

    const onCreateUpdateBtnClicked = () => {
        if (state.group.value && state.name.length && state.description.length) {
            if (props.isEdit) {
                updateTag({
                    id: props.data._id,
                    payload: { tag_name: state.name, tag_desc: state.description, tag_group_id: state.group.value }
                })
            } else {
                createTag({ tag_name: state.name, tag_desc: state.description, tag_group_id: state.group.value })
            }
        } else {
            setError({
                ...error,
                name: !state.name.length, description: !state.description.length, group: !state.group.value
            })
        }
    }

    const giveMeButtonName = () => {
        return `${props.isEdit ? "Update Tag" : "Create Tag"}`;
    }

    const giveMeInfoText = () => {
        if (props.isEdit && updateTagData.isError) {
            return <Alert severity="error">Oops! Something went wrong, Unable to update Tag. Try Again Later!</Alert>
        }
        if (createTagData.isError) {
            return <Alert severity="error">Oops! Something went wrong, Unable to create Tag. Try Again Later!</Alert>
        }
    };


    React.useEffect(() => {
        if (createTagData.isSuccess) {
            enqueueSnackbar("Tag Created Successfully", { variant: "success" });
            props.refetch();
            dispatch(closeDialog());
        }
        if (createTagData.isError) {
            enqueueSnackbar("Oops! Something went wrong, Unable to create Tag", { variant: "error" })
        }
        // eslint-disable-next-line
    }, [createTagData]);

    React.useEffect(() => {
        if (props.isEdit) {
            if (updateTagData.isSuccess) {
                enqueueSnackbar("Tag Updated Successfully", { variant: "success" });
                props.refetch();
                dispatch(closeDialog());
            }
            if (updateTagData.isError) {
                enqueueSnackbar("Oops! Something went wrong, Unable to update Tag", { variant: "error" })
            }
        }
        // eslint-disable-next-line
    }, [updateTagData]);

    React.useEffect(() => {
        if (props.isEdit && props.data?._id && !isFetching && isSuccess) {
            setState({
                ...state,
                name: props.data.tag_name, description: props.data.tag_desc, group: groups?.filter((_: any) => _.value === props.data.tag_group_id)?.[0]
            });

            dispatch(closeBackdrop());
        }
        else if (isFetching) {
            dispatch(openBackdrop("Fetching Tag Categories..."));
        } else if (isSuccess || isError) {
            dispatch(closeBackdrop());
        }
        // eslint-disable-next-line
    }, [props.isEdit, isFetching]);

    return <Box>
        {/* Title */}
        <Typography
            variant="h6"
            color="textPrimary"
            sx={{
                borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
                width: "100%", fontFamily: 'htrts_medium'
            }}
        >
            {`${props.isEdit ? "Update" : "Create"} Tag`}
        </Typography>

        {/* Fields */}
        <Grid container spacing={2} mt={1}>
            {/* UoM */}
            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                <HighlightSelectBox
                    margin="none"
                    size="medium"
                    label='Tag Category'
                    disabled={false}
                    value={state.group}
                    options={groups as SelectBoxOption[]}
                    onChange={(data: SelectBoxOption) => updateDropDown("group", data)}
                    required={true}
                    loading={isFetching}
                    error={error.group}
                    helperText={error.group ? "Please select Tag Category" : false ? "Oops! Unable to load Tag Categories" : ""}
                />
            </Grid>

            {/* Name */}
            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                <TextField
                    fullWidth
                    label={"Tag Name"}
                    value={state.name}
                    required={true}
                    disabled={false}
                    error={error.name}
                    helperText={error.name ? "Please enter Tag name" : ""}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => updateState("name", e.target.value)}
                />
            </Grid>

            {/* Description */}
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <TextField
                    fullWidth
                    multiline
                    rows={3}
                    label={"Tag Description"}
                    value={state.description}
                    required={true}
                    disabled={false}
                    error={error.description}
                    helperText={error.description ? "Please enter Tag description." : ""}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => updateState("description", e.target.value)}
                />
            </Grid>

            {/* Info Tex */}
            <Grid item xs={12}>
                {giveMeInfoText()}
            </Grid>

            {/* Add Noun Btn */}
            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                <LoadingButton
                    fullWidth
                    variant="contained"
                    onClick={onCreateUpdateBtnClicked}
                    loading={createTagData.isLoading || updateTagData.isLoading}
                >
                    {giveMeButtonName()}
                </LoadingButton>
            </Grid>

            {/* Cancel Btn */}
            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                <Button
                    fullWidth
                    variant="outlined"
                    onClick={onCancelBtnClicked}
                    disabled={false}
                >
                    {"Cancel"}
                </Button>
            </Grid>
        </Grid>
    </Box>
}