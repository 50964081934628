import { useAuth0 } from "@auth0/auth0-react";
import { Button, Box, Typography } from "@mui/material";
import { useAppDispatch } from "../../redux";
import { closeDialog } from "../../redux/slices/dialog";
import Logo from "../../images/logo.jpg";
import { useEffect } from "react";
import { AppRoutes } from "../../router/routes";

export const Timeout = () => {
  const dispatch = useAppDispatch();

  const { logout } = useAuth0();

  useEffect(() => {
    if (window.location?.pathname?.includes(AppRoutes.logout)) {
      logout({ logoutParams: { returnTo: `${window.location.origin}?page=timeout` } });
    }
    // eslint-disable-next-line
  }, [])

  return (
    <Box
      display={"flex"}
      height={"100%"}
      flexDirection={"column"}
      alignItems={"center"}
      justifyContent={"center"}
    >
      <img src={Logo} style={{ height: "54px" }} alt="logo" />
      <Typography variant="h6" color="error" sx={{ my: 2 }}>
        Due to inactivity you have been logged out.
      </Typography>
      <Button
        variant="outlined"
        onClick={() => {
          dispatch(closeDialog());
          window.history?.pushState({}, "", `${window.location.origin}${AppRoutes.login}`)
          window.location.reload();
        }}
      >
        Login
      </Button>
    </Box>
  );
};
