import { Box, Grid } from "@mui/material";
import React from "react";
import { withNavBars } from "../../../../../HOCs";
import { BackToList } from "../../../../../components";
import { AddUpdateSNTemplate } from "./AddUpdateSNTemplate";

const SNTemplate: React.FC<{ children?: JSX.Element; currentPath?: string }> = (
  props
) => {
  return (
    <Box sx={{ padding: 0.5, height: "100%" }}>
      <Grid container spacing={1} height={"100%"}>
        <Grid item xs={12} sm={3} md={2}>
          <BackToList title="Back" />
        </Grid>
        <Grid item xs={12} sm={9} md={10}>
          <AddUpdateSNTemplate />
        </Grid>
      </Grid>
    </Box>
  );
};

export default withNavBars(SNTemplate);
