import { List, ListItemButton, ListItemText, Paper, ListItemIcon } from "@mui/material";
import React from "react";
import { SideNavbarItems } from "./util";

export const SideNavBar: React.FC<{ children?: JSX.Element, variant?: "whitecard" | "grey" }> = ({variant="whitecard"}) => {
    return <Paper variant="outlined" sx={{
        height: "100%",
        background: (theme) => theme.palette.background[variant === "whitecard" ? "paper" : "default"],
        boxShadow: variant === "whitecard" ? `rgba(99, 99, 99, 0.2) 0px 2px 8px 0px` : `unset`,
        borderRadius: 2
    }}>
        <List component="nav" dense>
            {SideNavbarItems?.map((nav, key) => {
                return <ListItemButton key={key} sx={{
                    background: (theme) => theme.palette.primary.light,
                    marginX: 1,
                    padding: "0px",
                    paddingX: "4px",
                    borderRadius: 1,
                    '&:hover': {
                        background: (theme) => theme.palette.primary.light,
                        border: (theme) => `1px solid ${theme.palette.primary.dark}`
                    }
                }}>
                    {/* Nav Icon */}
                    <ListItemIcon sx={{ minWidth: "36px", color: "black" }}>
                        {nav.icon}
                    </ListItemIcon>

                    {/* Nav Title */}
                    <ListItemText
                        primaryTypographyProps={{ variant: "body2" }}
                        primary={nav?.title}
                    />
                </ListItemButton>
            })}
        </List>
    </Paper>
}