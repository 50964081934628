import { Box, Chip, Stack, Tooltip, Typography } from "@mui/material";
import { GridRenderCellParams, GridGroupingValueGetterParams } from "@mui/x-data-grid-premium";
import moment from "moment";
import { hexToGrayscale } from "../../utils";
import { IconButton } from "@mui/material";
import { DeleteOutline, Lock } from "@mui/icons-material";
import { Attachment } from "../../redux/slices/material";

export const DashboardTableColumn = (onDelete: any, view: any, hideDelete?: boolean) => {
    const columns = [
        {
            field: 'sequence_id', headerName: 'Project ID', width: 200, renderCell: (params: GridRenderCellParams) => {
                if (params.rowNode.type === 'group') {
                    return params.value;
                }
                return <Stack direction="row" alignItems="center" spacing={1}>
                    <Typography
                        id="view-project-icon"
                        onClick={() => view(params.row)}
                        variant="body2"
                        component={"a"}
                        color="primary"
                        sx={{ cursor: "pointer", textDecoration: "underline" }}>
                        {params.row.sequence_id}
                    </Typography>
                    {params.row?.user_access ? <Tooltip title={`This ${params.row.sequence_id} is private`}><Lock color='disabled' fontSize={"small"} /></Tooltip> : <></>}
                </Stack>
            }
        },
        {
            field: 'version', headerName: 'Revision', width: 100, renderCell: (params: GridRenderCellParams) => {
                if (params.rowNode.type === 'group') {
                    return params.value;
                }
                return <Typography
                    variant="body2"
                >
                    {params.row?.version}
                </Typography>
            }
        },
        {
            field: "attachments",
            headerName: "Image",
            width: 150,
            renderCell: (params: GridRenderCellParams) => (
                <Box>
                    <img
                        height={"50px"}
                        width={"50px"}
                        src={
                            params?.row?.attachments
                                ? params?.row?.attachments?.find((attachment: Attachment) => attachment?.is_primary)?.url ?? params?.row?.attachments?.at(-1)?.url
                                : `/imageNotFound.png`
                        }
                        alt={params?.row?.attachments?.at(-1)?.file_name ?? ""}
                    />
                </Box>
            ),
            disableColumnMenu: true,
            sortable: false,
        },
        {
            field: 'type', headerName: 'Type', width: 150,
            valueGetter: (params: GridRenderCellParams) => { return params.row.tags_info?.filter((_: any) => _.tag_group_name === 'Project Category')?.[0]?.tag_name ?? "" },
            renderCell: (params: GridRenderCellParams) => {
                if (params.rowNode.type === 'group') {
                    if (params.rowNode.groupingField === 'type') {
                        return params.rowNode.groupingKey;
                    }
                    return '';
                }
                return <Stack spacing={1} direction={"row"} width="100%" overflow={"scroll"} id={"tagsChip"}>
                    {params.row.tags_info?.filter((_: any) => _.tag_group_name === 'Project Category').map((tag: any) => <Chip variant="outlined" sx={{ borderColor: tag.tag_group_color, backgroundColor: tag.tag_group_color, color: `${hexToGrayscale(tag.tag_group_color) > 150 ? "rgba(0, 0, 0, 0.87)" : "#FFFFFF"}` }} size={"small"} label={tag?.tag_name ?? ""} />)}
                </Stack>
            },
            groupingValueGetter: (params: GridGroupingValueGetterParams) => {
                return params.row.tags_info?.filter((_: any) => _.tag_group_name === 'Project Category')?.[0]?.tag_name ?? "";
            },
            disableColumnMenu: true,
            sortable: false,
        },
        {
            field: 'project_name', headerName: 'Project Name', width: 200, renderCell: (params: GridRenderCellParams) => {
                if (params.rowNode.type === 'group') {
                    return params.value;
                }
                return <Tooltip title={params.row.project_name}>
                    <Typography variant="body2" sx={{ width: "100%", overflow: "hidden", textOverflow: "ellipsis" }}>
                        {params.row.project_name}
                    </Typography>
                </Tooltip>
            }
        },
        { field: 'reference_number', headerName: 'Ref Number', width: 150 },
        { field: 'cost_center', headerName: 'Cost Center', width: 150, valueGetter: (params: GridRenderCellParams) => `${params.row?.cost_center ?? ""} ` },
        {
            field: 'Business Unit', headerName: 'Business Unit', width: 150,
            valueGetter: (params: GridRenderCellParams) => { return params.row.tags_info?.filter((_: any) => _.tag_group_name === 'Business Units')?.[0]?.tag_name ?? "" },
            renderCell: (params: GridRenderCellParams) => {
                if (params.rowNode.type === 'group') {
                    if (params.rowNode.groupingField === 'Business Unit') {
                        return params.rowNode.groupingKey;
                    }
                    return '';
                }
                return <Stack spacing={1} direction={"row"} width="100%" overflow={"scroll"} id={"tagsChip"}>
                    {params.row.tags_info?.filter((_: any) => _.tag_group_name === 'Business Units').map((tag: any) => <Chip variant="outlined" sx={{ borderColor: tag.tag_group_color, backgroundColor: tag.tag_group_color, color: `${hexToGrayscale(tag.tag_group_color) > 150 ? "rgba(0, 0, 0, 0.87)" : "#FFFFFF"}` }} size={"small"} label={tag?.tag_name ?? ""} />)}
                </Stack>
            },
            groupingValueGetter: (params: GridGroupingValueGetterParams) => {
                return params.row.tags_info?.filter((_: any) => _.tag_group_name === 'Business Units')?.[0]?.tag_name ?? "";
            },
            disableColumnMenu: true,
            sortable: false,
        },
        { field: 'project_manager', headerName: 'Project Mgr / Lead', width: 150 },
        { field: 'customer_name', headerName: 'Customer', width: 150 },
        {
            field: 'tags_info', headerName: 'Tags', width: 250,
            valueGetter: (params: GridRenderCellParams) => { return params.row.tags_info?.filter((_: any) => _.tag_group_name === 'Project General Tag')?.[0]?.tag_name ?? "" },
            renderCell: (params: GridRenderCellParams) => {
                if (params.rowNode.type === 'group') {
                    if (params.rowNode.groupingField === 'tags_info') {
                        return params.rowNode.groupingKey;
                    }
                    return '';
                }
                <Stack spacing={1} direction={"row"} width="100%" overflow={"scroll"} id={"tagsChip"}>
                    {params.row.tags_info?.filter((_: any) => _.tag_group_name === 'Project General Tag').map((tag: any) => <Chip variant="outlined" sx={{ borderColor: tag.tag_group_color, color: tag.tag_group_color }} size={"small"} label={tag?.tag_name ?? ""} />)}
                </Stack>
            },
            groupingValueGetter: (params: GridGroupingValueGetterParams) => {
                return params.row.tags_info?.filter((_: any) => _.tag_group_name === 'Project General Tag')?.[0]?.tag_name ?? "";
            },
        },
        {
            field: 'start_date', headerName: 'Start Date', width: 250, renderCell: (params: GridRenderCellParams) => {
                return <Typography variant="body2">{params.row.start_date}</Typography>
            }
        },
        {
            field: 'finish_date', headerName: 'End Date', width: 250, renderCell: (params: GridRenderCellParams) => {
                return <Typography variant="body2">{params.row.finish_date}</Typography>
            }
        },
        {
            field: 'status', headerName: 'Status', width: 150,
            valueGetter: (params: GridRenderCellParams) => { return params.row.tags_info?.filter((_: any) => _.tag_group_name === 'Project Status')?.[0]?.tag_name ?? "" },
            renderCell: (params: GridRenderCellParams) => {
                if (params.rowNode.type === 'group') {
                    if (params.rowNode.groupingField === 'status') {
                        return params.rowNode.groupingKey;
                    }
                    return '';
                }
                return <Stack spacing={1} direction={"row"} width="100%" overflow={"scroll"} id={"tagsChip"}>
                    {params.row.tags_info?.filter((_: any) => _.tag_group_name === 'Project Status').map((tag: any) => <Chip variant="outlined" sx={{ borderColor: tag.tag_group_color, backgroundColor: tag.tag_group_color, color: `${hexToGrayscale(tag.tag_group_color) > 150 ? "rgba(0, 0, 0, 0.87)" : "#FFFFFF"}` }} size={"small"} label={tag?.tag_name ?? ""} />)}
                </Stack>
            },
            groupingValueGetter: (params: GridGroupingValueGetterParams) => {
                return params.row.tags_info?.filter((_: any) => _.tag_group_name === 'Project Status')?.[0]?.tag_name ?? "";
            },
            disableColumnMenu: true,
            sortable: false,
        },
        { field: 'created_user', headerName: 'Created By', width: 150 },
        {
            field: 'created_time', headerName: 'Created Date', width: 250, renderCell: (params: GridRenderCellParams) => {
                if (params.rowNode.type === 'group') {
                    return params.value;
                }
                return <Typography variant="body2">{moment(params.row.created_time).format('lll')}</Typography>
            }
        },
        { field: 'modified_user', headerName: 'Last modified by', width: 150 },
        {
            field: 'modified_time', headerName: 'Last modified date', width: 250, renderCell: (params: GridRenderCellParams) => {
                if (params.rowNode.type === 'group') {
                    return params.value;
                }
                return <Typography variant="body2">{params.row.modified_time ? moment(params.row.modified_time).format('lll') : ""}</Typography>
            }
        },
        {
            field: 'delete', headerName: 'Delete', width: 100, renderCell: (params: GridRenderCellParams) => {
                return <IconButton onClick={() => onDelete(params.row)}>
                    <DeleteOutline color="error" id="delete-project-icon" />
                </IconButton>
            },
            disableColumnMenu: true,
            sortable: false,
        },
    ];

    if (hideDelete) {
        columns.pop();
    }

    return columns;
};