import { LoadingButton } from "@mui/lab";
import { Alert, Box, Button, Grid, Stack, Typography } from "@mui/material";
import copy from "fast-copy";
import { useSnackbar } from "notistack";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { MyPaper, TextField } from "../../../components";
import { useAppDispatch, useAppSelector } from "../../../redux";
import { useCreateInstantSearchMutation, useUpdateInstantSearchMutation } from "../../../redux/services";
import { resetAISearchSlice, updateAISearchSliceByKeyValue, updateErrors } from "../../../redux/slices/ai";
import { HasAccess } from "../../../router/authorization";
import { PERMISSIONS } from "../../../router/permission";
import { AppRoutes } from "../../../router/routes";
import { getObjectType } from "../../listview/addEditListView";
import { mandateKeys } from "../utils";

export const BasicDetails: React.FC<{ children?: JSX.Element, searchId: string, isEdit: boolean, readOnly?: boolean }> = (props) => {

    const navigate = useNavigate();
    const state = useAppSelector(store => store.aiSearch);
    const dispatch = useAppDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const [createSearch, createSearchData] = useCreateInstantSearchMutation();
    const [updateSearch, updateSearchData] = useUpdateInstantSearchMutation();

    const location: any = useLocation();
    const object_id = location.state?.object_id;
    const object_type = location.state?.object_type;

    const { perm } = useAppSelector(store => store.auth.userDetails);

    const creatable = React.useMemo(() => {
        const primaryAccess = HasAccess(perm, PERMISSIONS.AI_SEARCH_CREATE);
        const module = getObjectType(location);
        if (module === 'product') {
            return HasAccess(perm, PERMISSIONS.PRODUCT_UPDATE) && primaryAccess
        }
        if (module === 'proposal') {
            return HasAccess(perm, PERMISSIONS.PROPOSAL_UPDATE) && primaryAccess
        }
        if (module === 'project') {
            return HasAccess(perm, PERMISSIONS.PROJECT_UPDATE) && primaryAccess
        }
        return primaryAccess
    }, [perm, location]);

    const editable = React.useMemo(() => {
        const primaryAccess = HasAccess(perm, PERMISSIONS.AI_SEARCH_UPDATE);
        const module = getObjectType(location);
        if (module === 'product') {
            return HasAccess(perm, PERMISSIONS.PRODUCT_UPDATE) && primaryAccess
        }
        if (module === 'proposal') {
            return HasAccess(perm, PERMISSIONS.PROPOSAL_UPDATE) && primaryAccess
        }
        if (module === 'project') {
            return HasAccess(perm, PERMISSIONS.PROJECT_UPDATE) && primaryAccess
        }
        return primaryAccess
    }, [perm, location]);

    const isReadOnly = props.isEdit ? !editable : !creatable;

    const updateBasicDetails = (childKey: string, value: any) => {
        dispatch(updateAISearchSliceByKeyValue({ parentKey: "basic_details", childKey, value }))
    };

    const isValid = () => {
        let isValid = true;

        for (let index = 0; index < mandateKeys.length; index++) {
            const key: any = mandateKeys[index];
            const error: any = copy(state.error);
            if (error[key.parentKey][key.childKey]) {
                isValid = false;
                enqueueSnackbar('Please fill mandatory fields (*) correctly.', { variant: "warning" });
                break;
            }
        }

        return isValid;
    };

    const createUpdateInstantSearchDetails = () => {
        dispatch(updateErrors(mandateKeys));

        if (!isValid()) {
            return false;
        }

        let payload = {
            documents: state.documents,
            tags_info: [],
            ...state.basic_details,
            links: object_id ? [
                { object_ids: [object_id], object_type: object_type },
            ] : undefined,
        }

        if (state.basic_details.name) {
            if (props.isEdit) {
                updateSearch({ id: props.searchId, payload });
            } else {
                createSearch(payload);
            }
        }
    };

    const giveMeErrorMessage = () => {
        let message = `Oops! Something went wrong, Unable to ${props.isEdit ? "Update" : "Create"} Instant Search. Try Again Later!`;
        let errorData: any = props.isEdit ? updateSearchData.error : createSearchData.error;
        message = errorData?.data?.title ?? message;
        return message;
    };

    React.useEffect(() => {
        if (!createSearchData.isLoading && createSearchData.isSuccess) {
            enqueueSnackbar('Instant Search Created Successfully!', { variant: "success" });
            let newSearchId = createSearchData.data?._id ?? "New";
            navigate(AppRoutes.viewAIAllSearcheBasicDetails(newSearchId), { state: location.state });
        }
        // eslint-disable-next-line
    }, [createSearchData.isSuccess]);

    React.useEffect(() => {
        if (!updateSearchData.isLoading && updateSearchData.isSuccess) {
            enqueueSnackbar('Instant Search Updated Successfully!', { variant: "success" });
            navigate(AppRoutes.viewAIAllSearcheBasicDetails(props.searchId), { state: location.state });
        }
        // eslint-disable-next-line
    }, [updateSearchData.isSuccess]);

    React.useEffect(() => {
        if (!createSearchData.isLoading && createSearchData.isError) {
            enqueueSnackbar(giveMeErrorMessage(), { variant: "error" })
        }
        // eslint-disable-next-line
    }, [createSearchData.isError]);

    React.useEffect(() => {
        if (!updateSearchData.isLoading && updateSearchData.isError) {
            enqueueSnackbar(giveMeErrorMessage(), { variant: "error" })
        }
        // eslint-disable-next-line
    }, [updateSearchData.isError]);

    const isLoading = createSearchData.isLoading || updateSearchData.isLoading;

    return <Stack spacing={1} mb={2}>
        {/* Basic Data Section */}
        <MyPaper padding={0}>
            <Box>
                {/* Heading */}
                <Typography sx={{ px: 2, py: 1, borderBottom: (theme) => `1px solid ${theme.palette.divider}` }}
                    variant="body1"
                    fontFamily={"htrts_medium"}>
                    Basic Details
                </Typography>

                <Grid container p={2} spacing={2}>
                    {/* Search Name */}
                    <Grid item xs={12} sm={6}>
                        <TextField
                            variant="outlined"
                            size="small"
                            fullWidth
                            viewOnly={isReadOnly}
                            value={state.basic_details.name}
                            label={"Search Name"}
                            onChange={(e) => updateBasicDetails("name", e.target.value)}
                            required={true}
                            error={state.error.basic_details.name}
                            helperText={state.error.basic_details.name ? 'Please enter Search name' : ""}
                        />
                    </Grid>

                    {/* Reason for search */}
                    <Grid item xs={12} sm={6}>
                        <TextField
                            variant="outlined"
                            size="small"
                            fullWidth
                            viewOnly={isReadOnly}
                            value={state.basic_details.reason}
                            label={"Reason for search"}
                            onChange={(e) => updateBasicDetails("reason", e.target.value)}
                            required={true}
                            error={state.error.basic_details.reason}
                            helperText={state.error.basic_details.reason ? 'Please enter Reason for search' : ""}
                        />
                    </Grid>

                </Grid>
            </Box>
        </MyPaper>

        {/* Footer Button */}
        {!isReadOnly && <MyPaper padding={0}>
            <Box sx={{ paddingX: 2 }}>
                {/* Alert Box */}
                {(createSearchData.isError || updateSearchData.isError) && <Alert sx={{ mt: 2 }} severity="error">{giveMeErrorMessage()}</Alert>}

                <Stack direction={"row"} alignItems={"center"} justifyContent={"center"} spacing={1} p={2}>
                    {/* Clear All Button */}
                    <Button disabled={isLoading} variant="outlined" sx={{ width: 200 }} color="primary" onClick={() => dispatch(resetAISearchSlice())} >
                        Clear All
                    </Button>

                    {/* Create Button */}
                    <LoadingButton loading={isLoading} variant="contained" sx={{ width: 200 }} color="primary" onClick={createUpdateInstantSearchDetails}>
                        {props.isEdit ? "Update" : "Create"}
                    </LoadingButton>

                    {/* Cancel Button */}
                    <Button disabled={isLoading} variant="outlined" sx={{ width: 200 }} color="primary" onClick={() => navigate(AppRoutes.aiInstantSearch, { state: location.state })} >
                        Cancel
                    </Button>
                </Stack>
            </Box>
        </MyPaper>}
    </Stack>
}
