import { Sync } from "@mui/icons-material";
import {
    Alert,
    Button,
    IconButton,
    Skeleton,
    Stack,
    Tooltip,
    Typography
} from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useState } from "react";
import { Association } from "../../../../interfaces";
import { useAppDispatch } from "../../../../redux";
import { useGenerateBOMReportMutation, useLazyGetBOMReportByIdQuery } from "../../../../redux/services";
import { closeBackdrop, openBackdrop } from "../../../../redux/slices/backdrop";
import { ReportView } from "./reportView";

export interface IDocumentProps {
    associations: Association[];
    comment: string;
    created_time: string;
    created_user: string;
    created_user_id: string;
    file_name: string;
    s3key: string;
    sequence_id: string;
    size: number;
    status: string;
    url: string;
    version: number;
    _id: string;
    bomReportId: string;
    refetch?: () => void;
}

interface IReports {
    documents: IDocumentProps[];
    id: string
}

const ReportsView: React.FC<IReports> = ({ documents, id }) => {

    const dispatch = useAppDispatch();
    const { enqueueSnackbar } = useSnackbar();

    const [getBOMReport, bomReportData] = useLazyGetBOMReportByIdQuery();
    const [generateReport] = useGenerateBOMReportMutation();

    const [isShowAllGenReport, updateIsShowAllGenReport] = useState(false);
    const [isShowAllReqReport, updateIsShowAllReqReport] = useState(false);

    const generateFullBOMReport = async () => {
        dispatch(openBackdrop('Requesting Full Report Generation...'));
        await generateReport(id).unwrap().then((res: any) => {
            if (res.error) {
                enqueueSnackbar(`Failed to request full report generation, More Info: -${res.error?.data?.title ?? ""} ${res.error?.title ?? ""}`, { variant: "error" })
            } else {
                getBOMReport({ id })
                enqueueSnackbar('Full Report Generation Started', { variant: 'success' })
            }
        }).catch(err => {
            enqueueSnackbar(`Failed to request full report generation, More Info: -${err.error?.data?.title ?? ""} ${err.error?.title ?? ""}`, { variant: "error" })
        });
        dispatch(closeBackdrop())
    };

    React.useEffect(() => {
        getBOMReport({ id })
        // eslint-disable-next-line
    }, [id]);


    if (bomReportData.isError) {
        return <Stack p={1} px={2}>
            <Alert severity="error">Something went wrong, unable to fetch full reports</Alert>
        </Stack>
    }


    if (!(bomReportData.isLoading || bomReportData.isFetching) && (!bomReportData?.data?.attachments || bomReportData.data?.attachments?.length === 0)) {
        return <Stack p={1} px={2}>
            <Alert severity="info" action={
                <Button onClick={generateFullBOMReport} color="inherit" size="small" id="generate-report">
                    Generate
                </Button>
            }>You are yet to generate full reports.</Alert>
        </Stack>
    }

    const isLoading = (bomReportData.isLoading || bomReportData.isFetching) ? [1, 2, 3, 4, 5].map(key => <Skeleton variant="rounded" width={"100%"} height={"46px"} />) : <></>;

    const isSuccess = !(bomReportData.isLoading || bomReportData.isFetching) && bomReportData.isSuccess && bomReportData.data;

    const generatedReport = isSuccess ? bomReportData.data?.attachments?.filter((_: any) => _.status === 'saved')?.reverse() : [];

    const failedOrPendingReport = isSuccess ? bomReportData.data?.attachments?.filter((_: any) => _.status !== 'saved')?.reverse() : [];

    return (
        <Stack pb={1} px={2} spacing={2} overflow={"hidden"}>
            <Stack spacing={1} sx={{ height: "100%", overflow: "auto" }}>

                {isLoading}

                {generatedReport?.length > 0 && <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
                    <Typography variant="body1" sx={{ fontFamily: "htrts_medium" }}>
                        Generated Reports
                    </Typography>
                    <Tooltip title={"Generate new Full Report"}>
                        <Button variant="outlined" size="small" color="primary" onClick={() => generateFullBOMReport()}>
                            Generate New
                        </Button>
                    </Tooltip>
                </Stack>}


                {generatedReport?.map((doc: IDocumentProps, idx: number) => {
                    if (!isShowAllGenReport && idx >= 5) {
                        return <></>
                    }
                    return <ReportView {...doc} key={idx} bomReportId={id} />;
                })}

                {generatedReport?.length > 5 && <Stack direction={"row"} alignItems={"center"} justifyContent={isShowAllGenReport ? "end": "space-between"}>
                    {generatedReport?.length > 5 && !isShowAllGenReport && <Typography variant="caption">Showing 5 of {generatedReport.length} reports</Typography>}
                    <Typography variant="caption" color={"primary"} sx={{ cursor: "pointer" }} onClick={() => updateIsShowAllGenReport(!isShowAllGenReport)}>{isShowAllGenReport ? "Show Less" : "Show All"}</Typography>
                </Stack>}

                {failedOrPendingReport?.length > 0 && <Stack pt={2} direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
                    <Typography variant="body1" sx={{ fontFamily: "htrts_medium" }}>
                        Requested Reports

                        <Tooltip title={"Sync Reports"}>
                            <IconButton size="small" onClick={() => getBOMReport({ id })} id="sync">
                                <Sync fontSize="small" color="info" />
                            </IconButton>
                        </Tooltip>
                    </Typography>
                    {generatedReport?.length === 0 && <Tooltip title={"Generate new Full Report"}>
                        <Button variant="outlined" size="small" color="primary" onClick={() => generateFullBOMReport()}>
                            Generate New
                        </Button>
                    </Tooltip>}
                </Stack>
                }

                {failedOrPendingReport?.map((doc: IDocumentProps, idx: number) => {
                    if (!isShowAllReqReport && idx >= 5) {
                        return <></>
                    }
                    return <ReportView {...doc} key={idx} bomReportId={id} refetch={() => getBOMReport({ id })} />;
                })}

                {failedOrPendingReport?.length > 5 && <Stack direction={"row"} alignItems={"center"} justifyContent={isShowAllReqReport ? "end" : "space-between"}>
                    {failedOrPendingReport?.length > 5 && !isShowAllReqReport && <Typography variant="caption">Showing 5 of {failedOrPendingReport.length} reports</Typography>}
                    <Typography variant="caption" color={"primary"} sx={{ cursor: "pointer" }} onClick={() => updateIsShowAllReqReport(!isShowAllReqReport)}>{isShowAllReqReport ? "Show Less" : "Show All"}</Typography>
                </Stack>}
            </Stack>
        </Stack >
    );
}

export { ReportsView };

