import React from "react";
import { Box, TextField, useTheme, Theme } from "@mui/material";
import { Close, Search } from "@mui/icons-material";

const useStyles = (theme: Theme) => ({
  root: {
    flexGrow: 1,
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    marginLeft: 0,
    width: "100%",
    display: "inline-flex",
    "& .MuiOutlinedInput-adornedStart": {
      paddingLeft: 0,
    },
  },
});

export const SearchFilter: React.FC<{
  searchPlaceholder: string;
  handleChange: any;
  value: string;
  onFocus?:
    | React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>
    | undefined;
  onBlur?:
    | React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>
    | undefined;
  autoComplete?: boolean;
  onKeyDown?: React.KeyboardEventHandler<HTMLInputElement> | undefined;
}> = ({
  searchPlaceholder = "",
  handleChange = () => false,
  value = "",
  onFocus = () => {},
  onBlur = () => {},
  autoComplete = true,
  onKeyDown = () => {},
}) => {
  const classes = useStyles(useTheme());

  return (
    <Box sx={classes.root}>
      <Box>
        <Box sx={classes.search}>
          <TextField
            InputProps={{
              startAdornment: <Search style={{ margin: 8, marginLeft: 0 }} />,
              endAdornment: value ? (
                <Close
                  style={{
                    margin: 8,
                    marginRight: 0,
                    marginLeft: 0,
                    cursor: "pointer",
                  }}
                  onClick={() => handleChange("")}
                />
              ) : (
                ""
              ),
            }}
            placeholder={searchPlaceholder}
            onChange={(e) => handleChange(e.target.value)}
            value={value}
            variant={"outlined"}
            size={"small"}
            fullWidth
            autoComplete={autoComplete ? "on" : "off"}
            onFocus={onFocus}
            onBlur={onBlur}
            onKeyDown={onKeyDown}
          />
        </Box>
      </Box>
    </Box>
  );
};
