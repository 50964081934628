import { LoadingButton } from "@mui/lab";
import {
  Alert,
  Box,
  Button,
  Divider,
  FormControlLabel,
  Grid,
  Input,
  Radio,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import copy from "fast-copy";
import moment from "moment";
import "moment-timezone";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../redux";
import {
  ExchangeData,
  type ExchangeRate,
  useCalculateBOMCostMutation,
  useGetCurrencyQuery,
  useLazyGetBOMCostingByIdQuery,
  useLazyGetExchangeRatesQuery,
} from "../../../redux/services";
import { closeBackdrop, openBackdrop } from "../../../redux/slices/backdrop";
import { HighlightSelectBox, MyPaper } from "../../../components";
import { updateBOMSliceByKeyValue } from "../../../redux/slices/bom";
import { SelectBoxOption } from "../../../interfaces";
import { closeDialog } from "../../../redux/slices/dialog";
import { sortByKey } from "../../../utils";

export const CalculateCost = () => {
  const { bom } = useAppSelector((store) => store);
  const dispatch = useAppDispatch();
  const [updateExchangeRates, updateExchangeRatesData] =
    useCalculateBOMCostMutation();
  const [getExchangeRates] = useLazyGetExchangeRatesQuery();
  const { enqueueSnackbar } = useSnackbar();
  const currencyData = useGetCurrencyQuery(null);

  const [disableCalculate, setDisableCalculate] = useState(false);

  const theme = useTheme();

  const isSmallMatch = useMediaQuery(theme.breakpoints.down("sm"));

  const isGreaterThanSmallMatch = useMediaQuery(theme.breakpoints.up("md"));

  const [getBOMCosting] = useLazyGetBOMCostingByIdQuery();

  useEffect(() => {
    if (bom?.bomId) {
      getCosting();
    }
    // eslint-disable-next-line
  }, []);

  const [exchangerates, setExchangerates] = React.useState<ExchangeData>({
    base_currency: `${bom.currency?.value ?? "USD"}`,
    current_date: moment(),
    exchange_date: moment(),
    manual_rate: false,
    exchange_rates_auto: [],
    exchange_rates_manual: [],
    exchange_rates: [],
  });

  const prePopulateWhenError = (skip: boolean = false) => {
    let newExchangeRates: ExchangeRate[] = [];
    if (currencyData?.data) {
      for (let i = 0; i < currencyData.data.length; i++) {
        newExchangeRates[i] = {
          manual_rate: 0,
          market_rate: 0,
          currency_code: currencyData?.data[i]?.currency_code || "USD",
        };
      }
    }
    setExchangerates({
      ...exchangerates,
      exchange_rates_auto: [],
      exchange_rates_manual: skip
        ? []
        : sortByKey(newExchangeRates, "currency_code"),
    });
  };

  const getCosting = async () => {
    dispatch(openBackdrop("Fetching Rates...."));
    await getBOMCosting({ id: bom.bomId })
      .unwrap()
      .then((res) => {
        if (Object.keys(res || {}).length > 0 && res?.status !== "failed") {
          const clientTimezone = moment.tz.guess(); // get the client's timezone
          setExchangerates({
            ...exchangerates,
            ...res?.exchangerates,
            current_date: res?.exchangerates?.exchange_date
              ? moment.tz(res?.exchangerates?.exchange_date, clientTimezone)
              : exchangerates?.current_date,
            exchange_date: res?.exchangerates?.exchange_date
              ? moment.tz(res?.exchangerates?.exchange_date, clientTimezone)
              : exchangerates?.exchange_date,
            exchange_rates_auto: sortByKey(
              res?.exchangerates?.exchange_rates,
              "currency_code"
            ),
            exchange_rates_manual: sortByKey(
              res?.exchangerates?.exchange_rates,
              "currency_code"
            ),
          });
          dispatch(
            updateBOMSliceByKeyValue({
              key: "currency",
              value:
                currencyData?.data?.find(
                  (c) => res?.exchangerates?.base_currency === c?.currency_code
                ) || null,
            })
          );
          enqueueSnackbar("Fetched Exchange Rates", { variant: "success" });
          setDisableCalculate(false);
        } else {
          (async () => {
            await findRates(false);
          })();

          //   prePopulateWhenError(true);
          //   enqueueSnackbar(
          //     "Something went wrong, Unable to fetch exchange rates",
          //     { variant: "error" }
          //   );
          //   setDisableCalculate(true);
        }
      })
      .catch((err) => {
        prePopulateWhenError(true);
        enqueueSnackbar(
          "Something went wrong, Unable to fetch exchange rates",
          { variant: "error" }
        );
      });
    dispatch(closeBackdrop());
  };

  const findRates = async (isManual: boolean) => {
    dispatch(openBackdrop("Fetching Rates...."));
    await getExchangeRates({
      date: `${
        exchangerates.current_date?.utc(true).toISOString()?.split("T")?.[0] ??
        ""
      }`,
      // date: `${exchangerates.current_date?.toISOString()?.split("T")?.[0] ?? ""}`,
      base_currency:
        (bom.currency as any)?.currency_code || exchangerates.base_currency,
    })
      .unwrap()
      .then((res) => {
        if (res) {
          // const clientTimezone = moment.tz.guess(); // get the client's timezone
          const exchange_rates_manual = isManual
            ? {
                exchange_rates_manual: sortByKey(
                  res?.exchange_rates.map((e: ExchangeRate) => ({
                    ...e,
                    manual_rate: e?.market_rate,
                  })),
                  "currency_code"
                ),
              }
            : {};
          const exchange_rates_auto = isManual
            ? {}
            : {
                exchange_rates_auto: sortByKey(
                  res?.exchange_rates,
                  "currency_code"
                ),
              };
          setExchangerates({
            ...exchangerates,
            // current_date: moment.tz(res.exchange_date, clientTimezone),
            // exchange_date: moment.tz(res.exchange_date, clientTimezone),
            ...exchange_rates_manual,
            ...exchange_rates_auto,
            manual_rate: exchangerates?.manual_rate || false,
          });
          dispatch(
            updateBOMSliceByKeyValue({
              key: "currency",
              value:
                currencyData?.data?.find(
                  (c) => res?.base_currency === c?.currency_code
                ) || null,
            })
          );
          enqueueSnackbar("Fetched Exchange Rates", { variant: "success" });
          setDisableCalculate(false);
        } else {
          if (exchangerates?.manual_rate) {
            setDisableCalculate(false);
          }
          prePopulateWhenError();
          enqueueSnackbar(
            "Something went wrong, Unable to fetch exchange rates",
            { variant: "error" }
          );
        }
      })
      .catch((err) => {
        if (exchangerates?.manual_rate) {
          setDisableCalculate(false);
        }
        prePopulateWhenError();
        enqueueSnackbar(
          "Something went wrong, Unable to fetch exchange rates",
          { variant: "error" }
        );
      });
    dispatch(closeBackdrop());
  };

  const updateManualExchangeRates = (index: number, value: number) => {
    let newExchangeRates = copy(exchangerates.exchange_rates_manual);
    newExchangeRates[index].manual_rate = value;
    setExchangerates({
      ...exchangerates,
      exchange_rates_manual: [...newExchangeRates],
    });
  };

  const calculateCost = async () => {
    const { exchange_rates_manual, exchange_rates_auto } = exchangerates;
    if (exchangerates.manual_rate) {
      exchangerates.exchange_rates = exchange_rates_manual;
    } else {
      exchangerates.exchange_rates = exchange_rates_auto;
    }
    exchangerates.base_currency =
      (bom.currency?.value as string) || exchangerates?.base_currency;
    updateExchangeRates({ id: bom.bomId, exchangerates })
      .unwrap()
      .then((res) => {
        if (res) {
          enqueueSnackbar(res?.message ? res?.message : "Calculated BOM Cost", {
            variant: "success",
          });
          dispatch(closeDialog());
        } else {
          enqueueSnackbar(res?.title ?? "Something went wrong, Unable to calculate cost.", {
            variant: "error",
          });
        }
      })
      .catch((err) => {
        enqueueSnackbar(err?.data?.title ?? "Something went wrong, Unable to calculate cost.", {
          variant: "error",
        });
      });
  };

  return (
    <Box>
      <Stack sx={{ position: "sticky", top: 0, zIndex: 10 }}>
        <MyPaper>
          <>
            {/* <Divider  /> */}
            <Grid container pt={2} pl={2} columnGap={2}>
              <Grid item xs={12} sm={5} md={3} mb={isSmallMatch ? 2 : 0}>
                {/* Date */}
                <DatePicker
                  value={exchangerates.current_date}
                  onChange={(current_date) => {
                    setExchangerates({
                      ...exchangerates,
                      current_date,
                      exchange_date: current_date,
                    });
                    setDisableCalculate(true);
                  }}
                  label="Date"
                  format="DD-MM-YYYY"
                  formatDensity="spacious"
                  sx={{ width: "100%" }}
                />
              </Grid>

              <Grid item xs={12} sm={5} md={3}>
                <HighlightSelectBox
                  size="medium"
                  label={"Base Currency"}
                  margin={"none"}
                  fullWdith
                  loading={currencyData.isLoading}
                  value={bom.currency ? bom.currency : null}
                  options={
                    sortByKey(currencyData?.data || [], "currency_code") ?? []
                  }
                  onChange={(data: SelectBoxOption) => {
                    dispatch(
                      updateBOMSliceByKeyValue({ key: "currency", value: data })
                    );
                    setDisableCalculate(true);
                  }}
                />
              </Grid>
              <Grid container item xs={12} mt={3}>
                <Grid item xs={12} sm={12} md={6}>
                  <Stack
                    direction={"row"}
                    spacing={1}
                    alignItems={"center"}
                    justifyContent={"center"}
                  >
                    <FormControlLabel
                      label={"Automatic - Market Price"}
                      control={
                        <Radio
                          checked={!exchangerates.manual_rate}
                          onChange={(e) => {
                            setExchangerates({
                              ...exchangerates,
                              manual_rate: false,
                            });
                            setDisableCalculate(true);
                          }}
                        />
                      }
                    />
                    <Box>
                      <Button
                        size="small"
                        variant="contained"
                        disabled={exchangerates.manual_rate}
                        onClick={() => findRates(false)}
                      >
                        Find Rates
                      </Button>
                    </Box>
                  </Stack>
                </Grid>

                <Grid item xs={12} sm={12} md={6}>
                  <Stack
                    direction={"row"}
                    spacing={1}
                    alignItems={"center"}
                    justifyContent={"center"}
                  >
                    <FormControlLabel
                      label={"Manual"}
                      control={
                        <Radio
                          checked={exchangerates.manual_rate}
                          onChange={(e) => {
                            setExchangerates({
                              ...exchangerates,
                              manual_rate: true,
                            });
                            setDisableCalculate(true);
                          }}
                        />
                      }
                    />

                    <Button
                      size="small"
                      variant="contained"
                      disabled={!exchangerates.manual_rate}
                      onClick={() => findRates(true)}
                    >
                      Import Current Rate
                    </Button>
                  </Stack>
                </Grid>
              </Grid>
            </Grid>
          </>
        </MyPaper>
      </Stack>

      <Grid container mt={2} columns={13} id="grid">
        <Grid item sm={13} md={6} width={"100%"}>
          {/* Exchange Rates */}
          <Stack
            mt={2}
            spacing={1}
            width={"100%"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            {exchangerates.exchange_rates_auto?.map((rate, index) => (
              <Stack key={index} direction={"row"} spacing={1}>
                <Typography>
                  <b>1</b>&nbsp;
                  <Typography variant="caption">
                    {bom?.currency?.value}
                  </Typography>
                </Typography>
                <Typography>=</Typography>

                <Input
                  type={"number"}
                  value={rate.market_rate}
                  disabled={true}
                  endAdornment={<>{rate.currency_code}</>}
                />
              </Stack>
            ))}
          </Stack>
        </Grid>

        <Grid
          item
          sm={13}
          md={1}
          py={isGreaterThanSmallMatch ? 0 : 2}
          width={"100%"}
        >
          <Divider
            orientation={isGreaterThanSmallMatch ? "vertical" : "horizontal"}
          />
        </Grid>

        <Grid item sm={13} md={6} width={"100%"}>
          {/* Exchange Rates */}
          <Stack
            mt={2}
            spacing={1}
            width={"inherit"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            {exchangerates.exchange_rates_manual?.map((rate, index) => (
              <Stack key={index} direction={"row"} spacing={1}>
                <Typography>
                  <b>1</b>&nbsp;
                  <Typography variant="caption">
                    {bom?.currency?.value}
                  </Typography>
                </Typography>
                <Typography>=</Typography>

                <Input
                  type={"number"}
                  value={rate?.manual_rate}
                  disabled={!exchangerates.manual_rate}
                  onChange={(e) =>
                    updateManualExchangeRates(index, parseFloat(e.target.value))
                  }
                  endAdornment={<>{rate.currency_code}</>}
                />
              </Stack>
            ))}
          </Stack>
        </Grid>
      </Grid>

      {/* Calculate Cost Button */}
      <Box sx={{ position: "sticky", bottom: 0, p: 0 }}>
        <MyPaper>
          <Box
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            flexDirection={"column"}
          >
            {updateExchangeRatesData.isError && (
              <Box mb={2}>
                <Alert severity="error">
                  {(updateExchangeRatesData?.error as any)?.data?.title ?? "Something went wrong, Unable to calculate cost."}
                </Alert>
              </Box>
            )}

            <LoadingButton
              onClick={() => calculateCost()}
              loading={updateExchangeRatesData.isLoading}
              variant="contained"
              disabled={
                !exchangerates?.current_date ||
                !exchangerates?.base_currency ||
                !bom?.currency ||
                disableCalculate
              }
            >
              Calculate Cost
            </LoadingButton>
          </Box>
        </MyPaper>
      </Box>
    </Box>
  );
};
