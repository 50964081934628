import { Box, Grid, Collapse, Drawer } from "@mui/material";
import React from "react";
import { withNavBars } from "../../HOCs";
import { BreadScrumHeader } from "../../components";
import { RightPanel } from "./rightPanel";
import { SideNavBar } from "./sideNavBar";

const PMP: React.FC<{ children?: JSX.Element }> = (props) => {

    const [openWebNav, setOpenWebNavBar] = React.useState(true);
    const [openMobileNav, setOpenMobileNavBar] = React.useState(false);

    const toogleWebNav = () => { setOpenWebNavBar(!openWebNav) };
    const toogleMobileNav = () => { setOpenMobileNavBar(!openMobileNav) };

    return <Box sx={{ height: "100%" }}>

        {/* Header */}
        <BreadScrumHeader
            openWebNav={openWebNav}
            toogleWebNav={toogleWebNav}
            toogleMobileNav={toogleMobileNav}
            history={[
                { label: "Product Module", onClick: () => false },
                { label: "Variant Configurator", onClick: () => false }
            ]}
            currentPath={"Selection"}
        />

        {/* Panel */}
        <Grid container sx={{ height: { xs: "auto", sm: "calc(100% - 50px)" }, transition: 'all 1s', paddingTop: 1 }}>

            {/* Side Nav */}
            <Grid item xs={openWebNav ? 12 : 0} sm={openWebNav ? 2 : 0} sx={{ height: { xs: "auto", sm: "100%" }, display: { xs: "none", sm: "block" } }}>
                <Collapse in={openWebNav} timeout={1} orientation={"horizontal"} sx={{ height: { xs: "auto", sm: "100%" }, '.MuiCollapse-wrapperInner': { width: "100%" } }}>
                    <SideNavBar />
                </Collapse>
            </Grid>

            {/* Main Panel */}
            <Grid item xs={12} sm={!openWebNav ? 0 : 10} sx={{ height: { xs: "auto", sm: "100%" }, paddingLeft: { xs: 0, sm: openWebNav ? 1 : 0 } }}>
                <RightPanel />
            </Grid>
        </Grid>

        {/* Ṃobile Drawer */}
        <Drawer anchor="left" open={openMobileNav} onClose={toogleMobileNav}>
            <SideNavBar />
        </Drawer>
    </Box>
}

export default withNavBars(PMP);