import React from "react";
import { Box, Stack, Tooltip, IconButton, Typography } from "@mui/material";
import { KeyboardBackspaceRounded, MenuRounded } from "@mui/icons-material";
import { BreadScrumHeaderProps } from "../../interfaces";
import { useNavigate } from "react-router-dom";

export const BreadScrumHeader: React.FC<BreadScrumHeaderProps> = ({
    openWebNav, toogleWebNav, toogleMobileNav, history, currentPath, background = "",
    boxShadow = "", borderColor = "", borderWidth = "1px", borderRadius = "8px", showBackBtn = false,
    hideMenuBtn = false, onBackBtnClick
}) => {

    const navigate = useNavigate();

    const onBackBtnClicked = () => {
        if (onBackBtnClick) {
            onBackBtnClick();
        } else {
            navigate(-1);
        }
    }

    return <Box id="breadcrumbs" sx={{
        borderRadius,
        background: (theme) => theme.palette.background.paper,
        boxShadow: boxShadow ? boxShadow : ""
    }} >
        <Stack spacing={1} py={0.5} px={1} direction={"row"} alignItems={"center"} justifyContent={"flex-start"} sx={{ flexWrap: "wrap" }}>
            {!hideMenuBtn && <>
                <Tooltip title={openWebNav ? "Hide Navbar" : "Show Navbar"}>
                    <IconButton size="small" onClick={toogleWebNav} sx={{ display: { xs: "none", sm: "inline-flex" } }}>
                        <MenuRounded fontSize="small" />
                    </IconButton>
                </Tooltip>
                <IconButton onClick={toogleMobileNav} sx={{ display: { xs: "block", sm: "none" } }}>
                    <MenuRounded />
                </IconButton>
            </>}

            {showBackBtn && <>
                <Tooltip title={"Go Back"}>
                    <IconButton size="small" onClick={onBackBtnClicked} sx={{ display: { xs: "none", sm: "inline-flex" } }}>
                        <KeyboardBackspaceRounded fontSize="small" />
                    </IconButton>
                </Tooltip>
            </>}

            {history.map((his, key) => {
                return <React.Fragment key={key}>
                    <IconButton id={`${his.label}-${key}`} size="small" disableRipple>
                        <Typography key={key} variant="body2"
                            onClick={() => his.onClick ? his.onClick() : navigate(his.path ?? "")}
                            sx={{
                                fontFamily: "htrts_medium",
                                '&:hover': {
                                    color: (theme) => theme.palette.primary.main,
                                    cursor: "pointer",
                                    textDecoration: "underline"
                                }
                            }}
                        >{his.label}</Typography>
                    </IconButton>
                    <span>{" >"}</span>
                </React.Fragment>
            })}
            <Typography variant="body2" color={"primary"} sx={{ fontFamily: "htrts_regular" }}>{currentPath}</Typography>
        </Stack>
    </Box>
}