import React from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { MyPaper } from "../../../components";
import { Dashboard } from "../../materials/dashboard";
import { useAppDispatch, useAppSelector } from "../../../redux";
import {
  reset,
  updateMatSliceByKeyValue,
} from "../../../redux/slices/material";
import {
  useGetGroupsDDQuery,
  useGetNounsDDQuery,
  useLinkModuleMutation,
  useUnlinkModuleMutation,
} from "../../../redux/services";
import { closeBackdrop, openBackdrop } from "../../../redux/slices/backdrop";
import { useSnackbar } from "notistack";
import { AppRoutes } from "../../../router/routes";
import { LinkChildMaterial } from "../../viewBOMTree/linkMaterial";
import { openDialog } from "../../../redux/slices/dialog";
import { HasAccess } from "../../../router/authorization";
import { PERMISSIONS } from "../../../router/permission";

export const ProductMaterials: React.FC<{
  children?: JSX.Element;
  productId: string;
  sequence_id: string;
  refetch: any;
}> = (props) => {

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [params] = useSearchParams();
  const { enqueueSnackbar } = useSnackbar();
  const { product } = useAppSelector((store) => store);
  const { perm } = useAppSelector(store => store.auth.userDetails);

  const [linkDocument, linkResponse] = useLinkModuleMutation();
  const [unlinkDocument, unlinkResponse] = useUnlinkModuleMutation();
  const nounData = useGetNounsDDQuery(null, { refetchOnMountOrArgChange: true, refetchOnFocus: true });
  const groupData = useGetGroupsDDQuery(null, { refetchOnMountOrArgChange: true, refetchOnFocus: true });

  const [selected, updateSelected] = React.useState<string[]>([]);
  const [reload, toggleReload] = React.useState<boolean>(false);

  const creatable = React.useMemo(() => HasAccess(perm, PERMISSIONS.PRODUCT_CREATE), [perm]);
  const readable = React.useMemo(() => HasAccess(perm, PERMISSIONS.PRODUCT_READ), [perm]);
  const updatable = React.useMemo(() => HasAccess(perm, PERMISSIONS.PRODUCT_UPDATE), [perm]);
  const deletable = React.useMemo(() => HasAccess(perm, PERMISSIONS.PRODUCT_DELETE), [perm]);
  const publishable = React.useMemo(() => HasAccess(perm, PERMISSIONS.PRODUCT_PUBLISH), [perm]);

  const locationPaths = {
    paths: [
      { title: "Products", path: AppRoutes.products },
      {
        title: `${props?.sequence_id}`,
        path: AppRoutes.viewProductBasicDetail(props.productId),
      },
      {
        title: "Materials",
        path: `${AppRoutes.viewProductMaterial(props.productId)}?tab=material`,
      },
    ],
  };

  const unlinkMaterial = async () => {
    dispatch(openBackdrop(`Removing Materials...`));
    try {
      await unlinkDocument({
        object_id: props.productId,
        object_type: "product",
        payload: {
          unlinks: [{ object_type: "material", object_ids: selected }],
        },
      }).then((res) => {
        enqueueSnackbar("Removed Materials Linking", { variant: "success" });
        props.refetch();
        toggleReload(!reload);
      });
    } catch (err) {
      enqueueSnackbar(
        `Oops! Something went wrong, Unable to remove materials.`,
        { variant: "error" }
      );
      dispatch(closeBackdrop());
    }
  };

  const linkMaterial = async (linkIds: string[]) => {
    dispatch(openBackdrop(`Linking Materials...`));
    try {
      await linkDocument({
        object_id: props.productId,
        object_type: "product",
        payload: {
          links: [{ object_type: "material", object_ids: linkIds }],
        },
      }).then((res) => {
        enqueueSnackbar("Linked Materials to product", { variant: "success" });
        props.refetch();
        toggleReload(!reload);
      });
    } catch (err) {
      enqueueSnackbar(`Oops! Something went wrong, Unable to link materials.`, {
        variant: "error",
      });
      dispatch(closeBackdrop());
    }
  };

  const openLinkingWindow = () => {
    dispatch(
      openDialog({
        title: "",
        hideNegativeBtn: true,
        hidePositiveBtn: true,
        maxWidth: "md",
        hideCloseIcon: true,
        enablePadding: false,
        body: (
          <LinkChildMaterial
            id={props.productId}
            groupData={groupData}
            nounData={nounData}
            associations={product.associations}
            skipQty
            callLinkDocument={(linkIds) => linkMaterial(linkIds as string[])}
          />
        ),
      })
    );
  };

  React.useEffect(() => {
    updateSelected([]);
    // eslint-disable-next-line
  }, [params]);

  React.useEffect(() => {
    if (!reload) {
      toggleReload(!reload);
    }
  }, [reload]);

  return (
    <MyPaper height={"100%"} padding={0}>
      <>
        {reload && <Dashboard
          checkboxSelection={updatable}
          onCheckBoxSelected={(selected: string[]) => updateSelected(selected)}
          titleHeaderProps={{
            showHelperBtn: updatable,
            helperBtnLabel: "Remove Material",
            onHelperBtn: () => unlinkMaterial(),
            disableHelperBtn:
              selected.length === 0 || unlinkResponse.isLoading || linkResponse.isLoading,
            showDropDown: false,
            linkingOption: updatable ? {
              linkingBtnLabel: "Link Material",
              openLinkingWindow: openLinkingWindow,
            } : undefined,
            onCreateBtn: () => {
              dispatch(reset());
              navigate(AppRoutes.createOrUpdateProductMaterialBasicDetail(props.productId, "New"),{ state: locationPaths });
            },
          }}
          objectType={"product"}
          objectId={props.productId}
          onEdit={(id: string, sequence_id: string) => {
            dispatch(updateMatSliceByKeyValue({ key: "matId", value: id }));
            navigate(
              AppRoutes.createOrUpdateProductMaterialBasicDetail(
                props.productId,
                id
              ),
              {
                state: locationPaths,
              }
            );
          }}
          isNested={true}
          readable={readable}
          creatable={creatable}
          updatable={updatable}
          deletetable={deletable}
          publishable={publishable}
        />}
      </>
    </MyPaper>
  );
};
