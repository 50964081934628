import { Button, FormControl, FormControlLabel, Grid, Radio, RadioGroup, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import React from "react";
import { useSearchParams } from "react-router-dom";
import { MyPaper, SelectBox } from "../../components";
import { ReactSelectOption } from "../../interfaces";
import { useAppDispatch } from "../../redux";
import { useLazyGetBOMByIdQuery, useLinkChildsMutation } from "../../redux/services";
import { closeBackdrop, openBackdrop } from "../../redux/slices/backdrop";
import { openDialog } from "../../redux/slices/dialog";
import { LinkChildBOM } from "../viewBOMTree/linkBOM";
import { LinkChildMaterial } from "../viewBOMTree/linkMaterial";
import { CapitalizeString } from "../../utils";
import { LinkDetail } from "..";

export const SearchAssignChildBOMorMaterialToDrawioShape: React.FC<{ children?: JSX.Element }> = (props) => {

    const dispatch = useAppDispatch();
    const [searchParams, setSearchParams] = useSearchParams();
    const { enqueueSnackbar } = useSnackbar();

    const [getBOMById] = useLazyGetBOMByIdQuery();
    const [updateBOM] = useLinkChildsMutation();

    const [state, setState] = React.useState<{
        bom?: ReactSelectOption,
        childType?: "material" | "bom",
        childBOM?: ReactSelectOption,
        childMaterial?: ReactSelectOption
    }>();

    const updateChildType = (childType: string) => {
        if (childType === 'material') {
            setState({ ...state, childType: 'material', childBOM: undefined });
        } else if (childType === 'bom') {
            setState({ ...state, childType: 'bom', childMaterial: undefined });
        }
    };

    const onAssignTagNoBtnClicked = () => {
        const drawioContent: any = window.parent.window.parent.document.getElementById('drawio-editor-iframe');
        if (drawioContent) {
            let subEvent = '';
            let child = {};
            if (state?.childType === 'material') {
                subEvent = 'assignChildMaterial';
                child = state?.childMaterial ?? {};
            } else {
                subEvent = 'assignChildBOM';
                child = state?.childBOM ?? {};
            }

            drawioContent.contentWindow?.postMessage({ event: "iehub-plugin", subEvent, ...child }, "*");
        } else {
            console.log('drawio editor is not mapped');
        }
    };

    const updateExistingDetails = async () => {
        if (searchParams.get('edit') === "1") {
            dispatch(openBackdrop('Fetching BOM Detail...'));

            const bomId = searchParams.get("bomId");
            const bom = searchParams.get("bom");

            const childMaterial = searchParams.get("childMaterial");
            const childMaterialId = searchParams.get("childMaterialId");

            const childBOM = searchParams.get("childBOM");
            const childBOMId = searchParams.get("childBOMId");

            const childType = searchParams.get("itemType");

            if (bomId !== "null") {
                try {
                    const bomDetails = await getBOMById({ id: bomId }).unwrap();

                    if (bomDetails) {
                        let child: { childType?: 'bom' | 'material' } = {};

                        if (childType === "Material") {
                            child.childType = 'material'
                        } else if (childType === "BOM") {
                            child.childType = 'bom'
                        };

                        setState({
                            ...state,
                            bom: { value: bomId, label: bom, ...bomDetails } as unknown as ReactSelectOption,
                            childType: child.childType,
                            childMaterial: child.childType === "material" && childMaterialId !== 'null' ? { value: childMaterialId, label: childMaterial, ...bomDetails.bom_materials?.filter((_: any) => _.child_id === childMaterialId)?.[0] } as unknown as ReactSelectOption : undefined,
                            childBOM: child.childType === "bom" && childBOMId !== 'null' ? { value: childBOMId, label: childBOM, ...bomDetails.bom_children?.filter((_: any) => _.child_id === childBOMId) } as unknown as ReactSelectOption : undefined,
                        })
                    }
                } catch (err) {
                    console.log('err:', err)
                }
            }

            dispatch(closeBackdrop());
        }
    };

    const linkChilds = async (
        linkIds: LinkDetail[],
        type: "bom" | "material",
        id: string
    ) => {
        const bom: any = state?.bom ?? {};

        let payload: {
            bom_children_link?: any[];
            materials_link?: any[];
            currency_code: string;
            reference_id?: string;
            short_description?: string;
        } = {
            bom_children_link: [],
            materials_link: [],
            currency_code: bom?.currency ?? "USD",
            reference_id: bom?.reference_id ?? "",
            short_description: bom?.short_description,
        };

        if (type === "bom") {
            let count = bom?.bom_children?.length ?? 0;
            payload.bom_children_link = linkIds.map((_) => {
                count = count + 1;
                return {
                    _id: _?._id,
                    position: count,
                    normal_quantity: _?.qty ?? 1,
                    cost: 0,
                    currency: "USD",
                    spare_part: false,
                    spare_part_quantity: 0,
                };
            });
        } else if (type === "material") {
            let count = bom?.bom_materials?.length ?? 0;
            payload.materials_link = linkIds.map((_) => {
                count = count + 1;
                return {
                    _id: _?._id,
                    position: count,
                    normal_quantity: _?.qty ?? 1,
                    cost: 0,
                    currency: "USD",
                    spare_part: false,
                    spare_part_quantity: 0,
                };
            });
        }
        dispatch(openBackdrop(`Linking ${type === "bom" ? "BOM" : "Material"}...`))
        await updateBOM({ id, payload, update_version: false }).unwrap();
        const bomDetails = await getBOMById({ id: state?.bom?.value }).unwrap();

        if (bomDetails) {
            setState({ ...state, bom: { value: bom.value, label: bom.label, ...bomDetails } as unknown as ReactSelectOption })
        }

        enqueueSnackbar(`Linked ${type === "bom" ? "BOM" : "Material"}`, { variant: "success" });
        dispatch(closeBackdrop());
    };

    const searchAndSelect = async () => {

        const bom: any = state?.bom ?? {};

        if (state?.childType === 'material') {
            dispatch(
                openDialog({
                    title: "",
                    hideNegativeBtn: true,
                    hidePositiveBtn: true,
                    maxWidth: "md",
                    hideCloseIcon: true,
                    enablePadding: false,
                    body: (
                        <LinkChildMaterial
                            id={bom?.value}
                            associations={[]}
                            callLinkDocument={(linkIds, id: string) => linkChilds(linkIds as LinkDetail[], "material", id)}
                            showAllTabs={false}
                            showOnlyTabs={['list', 'search']}
                        />
                    ),
                })
            );
        }

        if (state?.childType === 'bom') {
            dispatch(
                openDialog({
                    title: "",
                    hideNegativeBtn: true,
                    hidePositiveBtn: true,
                    maxWidth: "md",
                    hideCloseIcon: true,
                    enablePadding: false,
                    body: (
                        <LinkChildBOM
                            id={bom?.value}
                            associations={[]}
                            callLinkDocument={(linkIds, id: string) => linkChilds(linkIds as LinkDetail[], "bom", id)}
                            showAllTabs={false}
                            showOnlyTabs={['list', 'search']}
                        />
                    ),
                })
            );
        }
    };

    const childBOMOptions = React.useMemo(() => {
        if (state?.bom) {
            let bom: any = state.bom;
            return bom?.bom_children?.map((_: any) => ({ ..._, value: _.child_id, label: `${_.sequence_id}-r${_.version} (${_?.bom?.short_description ?? "-"})` })) ?? [];
        } else {
            return [];
        }
    }, [state?.bom]);

    const childMaterialOptions = React.useMemo(() => {
        if (state?.bom) {
            let bom: any = state.bom;
            return bom?.bom_materials?.map((_: any) => ({ ..._, value: _.child_id, label: `${_.sequence_id}-r${_.version} (${_?.material?.short_description ?? "-"})` })) ?? [];
        } else {
            return [];
        }
    }, [state?.bom]);

    const messageHandler = (evt: MessageEvent) => {
        if (evt.data?.type === 'linkExisting') {
            setSearchParams({ ...evt.data, edit: 1 });
        }
    }

    // Initial load
    React.useEffect(() => {
        window.addEventListener('message', messageHandler);

        return () => {
            window.removeEventListener('message', messageHandler);
        };
        // eslint-disable-next-line
    }, []);

    React.useEffect(() => {
        updateExistingDetails();
        // eslint-disable-next-line
    }, [searchParams]);

    return <MyPaper height={"100vh"}>
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Typography variant="body2">Assigned BOM:&nbsp;<b>{state?.bom?.label ?? "Yet to assign BOM"}</b></Typography>
            </Grid>
            {false && <Grid item xs={12}>
                <FormControl>
                    <Typography variant="body2" color="primary" align="left">
                        {"Child Type"}
                    </Typography>
                    <RadioGroup
                        value={state?.childType}
                        row
                        name="childType"
                        onChange={(e) => updateChildType(e.target.value)}
                    >
                        <FormControlLabel value="material" control={<Radio checked={state?.childType === "material"} />} label="Material" />
                        <FormControlLabel value="bom" control={<Radio checked={state?.childType === "bom"} />} label="BOM" />
                    </RadioGroup>
                </FormControl>
            </Grid>}

            {state?.childType === "bom" && <Grid item xs={12} sm={6}>
                <SelectBox
                    label={"Child BOM"}
                    value={state?.childBOM}
                    options={childBOMOptions}
                    onChange={(data: ReactSelectOption) => setState({ ...state, childBOM: data, childMaterial: undefined })}
                />
            </Grid>}

            {state?.childType === "material" && <Grid item xs={12} sm={6}>
                <SelectBox
                    label={"Child Material"}
                    value={state?.childMaterial}
                    options={childMaterialOptions}
                    onChange={(data: ReactSelectOption) => setState({ ...state, childBOM: undefined, childMaterial: data })}
                />
            </Grid>}

            <Grid item xs={12} sm={3}>
                <Button sx={{marginTop: '26px'}} fullWidth disabled={!state?.bom} variant="outlined" onClick={searchAndSelect}>Link New {CapitalizeString(state?.childType ?? "")}</Button>
            </Grid>

            <Grid item xs={12} sm={3}>
                <Button sx={{ marginTop: '26px' }} disabled={!state?.childBOM && !state?.childMaterial} fullWidth variant="contained" onClick={onAssignTagNoBtnClicked}>Assign {state?.childType === 'material' ? 'Material' : 'BOM'}</Button>
            </Grid>
        </Grid>
    </MyPaper>;
}