import { ContentCopy, DeleteOutline, Folder, Link, LinkOff } from "@mui/icons-material";
import { Box, Button, Chip, IconButton, Stack, Tooltip, Typography } from "@mui/material";
import { GridGroupingValueGetterParams, GridRenderCellParams } from "@mui/x-data-grid-premium";
import moment from "moment";
import { DownloadDoc } from "..";
import { PublishedIcon } from "../../images/svg/publishedIcon";
import { WIPIcon } from "../../images/svg/WIPIcon";
import { convertSize, hexToGrayscale } from "../../utils";
import FileSize from "../fileSize";

export const giveMeLocationAndDocumentTags = (tags_info: any) => {
    let location_tags: any = null;
    let document_tags: any = null;

    tags_info?.forEach((_: any) => {
        if (_.tag_group_name === "Document Type") {
            document_tags = { value: _.tag_id, label: _.tag_name, ..._ }
        }
        if (_.tag_group_name === "Folders") {
            location_tags = { value: _.tag_id, label: _.tag_name, ..._ }
        }
    })
    return { location_tags, document_tags };
}

export const DocumentTableColumn = (arg: {
    view?: (row: any) => void,
    onDelete?: (row: any) => void,
    openAssociation?: (row: any) => void,
    updatePublishStatus?: (row: any) => void,
    hideDelete?: boolean,
    handleAnnotate?: (id: string) => void,
    openReferences?: (row: any) => void,
    unlinkDocument?: (row: any) => void,
    publishable?: boolean,
    isLinkable?: boolean,
    disablePreview?: boolean,
    handleDuplicate?: (row: any) => void,
    showDuplicate?: boolean,
}) => {

    const {
        view = () => false,
        onDelete = () => false,
        openAssociation = () => false,
        updatePublishStatus = () => false,
        hideDelete = false,
        handleAnnotate = () => false,
        openReferences = () => false,
        unlinkDocument = () => false,
        publishable = false,
        isLinkable = false,
        disablePreview = false,
        handleDuplicate = () => false,
        showDuplicate = false,
    } = arg;

    let columns = [
        {
            field: 'sequence_id', headerName: 'Doc ID', width: 150, renderCell: (params: GridRenderCellParams) => {
                return <Typography id="view-documents" sx={{ color: 'blue', textDecoration: "underline", cursor: "pointer" }} variant="body2" onClick={() => view(params.row)}>{params.row?.sequence_id}</Typography >
            }
        },
        { field: 'version', headerName: 'Revision', width: 100 },
        {
            field: "duplicate",
            headerName: "Duplicate",
            resizable: true,
            width: 90,
            disableColumnMenu: true,
            sortable: false,
            renderCell: (params: GridRenderCellParams) => {
                if (params.rowNode.type === "group") {
                    return params.value;
                }
                return (
                    <Tooltip title="Make a Duplicate">
                        <IconButton
                            sx={{ margin: "auto" }}
                            onClick={() =>
                                handleDuplicate && handleDuplicate(params?.row)
                            }
                        >
                            <ContentCopy fontSize="small" />
                        </IconButton>
                    </Tooltip>
                );
            },
        },
        {
            field: 'file_name', headerName: 'File Name', width: 200, renderCell: (params: GridRenderCellParams) => {
                return <Tooltip title={params.row.file_name}><Typography variant="body2" sx={{ width: "100%", overflow: "hidden", textOverflow: "ellipsis" }}>{params.row.file_name}</Typography></Tooltip>
            }
        },
        {
            field: 'location_tags', headerName: 'Folder', width: 250,
            valueGetter: (params: GridRenderCellParams) => giveMeLocationAndDocumentTags(params.row.tags_info).location_tags?.label,
            renderCell: (params: GridRenderCellParams) => {
                if (params.rowNode.type === 'group') {
                    if (params.rowNode.groupingField === 'location_tags') {
                        return params.rowNode.groupingKey;
                    }
                    return '';
                }

                const location = giveMeLocationAndDocumentTags(params.row.tags_info).location_tags;

                return <Stack spacing={1} direction={"row"} width="100%" overflow={"scroll"} id={"tagsChip"}>
                    <Chip sx={{ borderColor: location?.tag_group_color || '#3b82f6', backgroundColor: location?.tag_group_color || '#e5f6fd', color: `${hexToGrayscale(location?.tag_group_color || '#e5f6fd') > 150 ? "rgba(0, 0, 0, 0.87)" : "#FFFFFF"}` }} variant="outlined" size={"small"} label={`${location?.label}`} />
                </Stack>
            },
            renderHeader: () => (
                <Box display={"flex"} alignItems={"center"} gap={1}>
                    <Typography variant="body2">{"Folder"}</Typography>
                    <Folder htmlColor="#fde100" fontSize="medium" sx={{ verticalAlign: "bottom" }} />
                </Box>
            ),
            groupingValueGetter: (params: GridGroupingValueGetterParams) => {
                return giveMeLocationAndDocumentTags(params.row.tags_info).location_tags?.label;
            },
            disableColumnMenu: true,
            sortable: false,
        },
        {
            field: 'document_type_tags', headerName: 'Document Type', width: 250,
            valueGetter: (params: GridRenderCellParams) => giveMeLocationAndDocumentTags(params.row.tags_info).document_tags?.label,
            renderCell: (params: GridRenderCellParams) => {
                if (params.rowNode.type === 'group') {
                    if (params.rowNode.groupingField === 'document_type_tags') {
                        return params.rowNode.groupingKey;
                    }
                    return '';
                }

                const document = giveMeLocationAndDocumentTags(params.row.tags_info).document_tags;

                return <Stack spacing={1} direction={"row"} width="100%" overflow={"scroll"} id={"tagsChip"}>
                    <Chip sx={{ borderColor: document?.tag_group_color || '#3b82f6', backgroundColor: document?.tag_group_color || '#e5f6fd', color: `${hexToGrayscale(document?.tag_group_color || '#e5f6fd') > 150 ? "rgba(0, 0, 0, 0.87)" : "#FFFFFF"}` }} variant="outlined" size={"small"} label={`${document?.label}`} />
                </Stack>
            },
            groupingValueGetter: (params: GridGroupingValueGetterParams) => {
                return giveMeLocationAndDocumentTags(params.row.tags_info).document_tags?.label ?? "Grouping";
            },
            disableColumnMenu: true,
            sortable: false,
        },
        {
            field: 'released',
            headerName: 'Status',
            width: 150,
            renderCell: (params: GridRenderCellParams) => {
                return (
                    params?.row?.released
                        ? <Button disableRipple disableFocusRipple sx={{ cursor: "default", '&:hover': { backgroundColor: 'transparent' } }} id="doc-publish-success">
                            <Tooltip
                                title="Published"
                                children={<div><PublishedIcon /></div>}
                            />
                        </Button>
                        : <Button disableRipple disableFocusRipple id="doc-unpublish"><Tooltip title={publishable ? "Work in progress, click to publish the item" : "Not Published"} children={<div><WIPIcon onClick={() => publishable ? updatePublishStatus(params.row) : true} /></div>} /></Button>
                )
            }
        },
        {
            field: 'serial_num',
            headerName: 'Sequence Number',
            width: 150,
            renderCell: (params: GridRenderCellParams) => {
                return <Typography variant="body2" >{params?.row?.serial_num ?? ""}</Typography>
            }
        },
        {
            field: 'download', headerName: 'Download', width: 100, renderCell: (params: GridRenderCellParams) => {
                return <DownloadDoc id={params.row._id} url={params.row.url} status={params.row.status} />
            },
            disableColumnMenu: true,
            sortable: false,
        },
        {
            field: 'size', headerName: 'Size', width: 100, renderCell: (params: GridRenderCellParams) => {
                return <FileSize id={params.row._id} status={params.row.status} size={params.row?.size ?? 0} />
            },
            groupingValueGetter: (params: GridGroupingValueGetterParams) => {
                return convertSize(params.row?.size ?? 0)
            },
        },
        {
            field: 'preview', headerName: 'Preview', width: 100, renderCell: (params: GridRenderCellParams) => {
                return <DownloadDoc preview={true} id={params.row._id} url={params.row.url} status={params.row.status} fileType={disablePreview ? "" : params.row?.file_name?.split('.')?.pop() ?? "Unknown"} />
            },
            disableColumnMenu: true,
            sortable: false,
        },
        // {
        //     field: 'annotate', headerName: 'Annotate', width: 100, renderCell: (params: GridRenderCellParams) => {
        //         if (params.row?.file_name?.includes("pdf")) {
        //             return <Button color="primary" onClick={() => handleAnnotate && handleAnnotate(params?.row?._id)}>Annotate</Button>
        //         }
        //         return null;
        //     }
        // },
        {
            field: 'link', headerName: 'Association', width: 100, renderCell: (params: GridRenderCellParams) => {
                return <>
                    <IconButton disabled={!params.row.associations?.length} onClick={() => openAssociation(params.row)}><Link color={params.row.associations?.length ? "info" : "disabled"} /></IconButton>
                </>
            },
            disableColumnMenu: true,
            sortable: false,
        },
        {
            field: 'references', headerName: 'References', width: 100, renderCell: (params: GridRenderCellParams) => {
                return <>
                    <IconButton disabled={!params.row.references?.length} onClick={() => openReferences(params.row)}><Link color={params.row.references?.length ? "info" : "disabled"} /></IconButton>
                </>
            },
            disableColumnMenu: true,
            sortable: false,
        },
        { field: 'created_user', headerName: 'Uploaded By', width: 150 },
        {
            field: 'created_time', headerName: 'Uploaded Date', width: 250, renderCell: (params: GridRenderCellParams) => {
                return <Typography variant="body2">{moment(params.row.created_time).format('lll')}</Typography>
            }
        },
        { field: 'modified_user', headerName: 'Last modified by', width: 150 },
        {
            field: 'modified_time', headerName: 'Last modified date', width: 250, renderCell: (params: GridRenderCellParams) => {
                if (params.rowNode.type === 'group') {
                    return params.value;
                }
                return <Typography variant="body2">{params.row.modified_time ? moment(params.row.modified_time).format('lll') : ""}</Typography>
            }
        },
        { field: 'released_user', headerName: 'Published by', width: 150 },
        {
            field: 'released_time', headerName: 'Published date', width: 250, renderCell: (params: GridRenderCellParams) => {
                if (params.rowNode.type === 'group') {
                    return params.value;
                }
                return <Typography variant="body2">{params.row.released_time ? moment(params.row.released_time).format('lll') : ""}</Typography>
            }
        },
        {
            field: 'actions', headerName: 'Actions', width: 100, renderCell: (params: GridRenderCellParams) => {
                return <Stack direction={"row"}>
                    {unlinkDocument && isLinkable && <Tooltip title={`Unlink the Document "${params?.row?.sequence_id}"`}>
                        <IconButton onClick={() => unlinkDocument(params.row)}>
                            <LinkOff color={"action"} />
                        </IconButton>
                    </Tooltip>}
                    {!hideDelete && <Tooltip title={params?.row?.released ? `${params?.row?.sequence_id} Revision is published` : `Delete the Document ${params?.row?.sequence_id}`}>
                        <IconButton disableRipple={params?.row?.released} onClick={() => params?.row?.released ? () => { } : onDelete(params.row)}>
                            <DeleteOutline color={params?.row?.released ? "disabled" : "error"} />
                        </IconButton>
                    </Tooltip>}
                </Stack>
            },
            disableColumnMenu: true,
            sortable: false,
        }
    ];

    if (!handleAnnotate) {
        columns = columns.filter(c => c.field !== "annotate")
    }

    if (!handleDuplicate) {
        columns.splice(2, 1);
    }

    if (!showDuplicate) {
        columns.splice(2, 1);
    }

    return columns;
};

export const fileSelectOptions = [{ value: "single", label: "Single Document" }, { value: "multiple", label: "Multiple Documents" }];
