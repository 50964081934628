import { createApi, fetchBaseQuery, retry } from '@reduxjs/toolkit/query/react';
import { config } from '../../../config';
import { ConvertToReactSelect, getTransformedDataForPagination, LocalStorageKeys } from '../../../utils';

export const productNounAPI = createApi({
    reducerPath: "productNounAPI",
    baseQuery: retry(fetchBaseQuery({
        baseUrl: config.api_url  + "prodm/repo/productNoun",
        prepareHeaders: (headers, { getState }) => {
            const token = localStorage.getItem(LocalStorageKeys.authToken);

            // If we have a token set in state, let's assume that we should be passing it.
            if (token) {
                headers.set('authorization', `Bearer ${token}`)
            }

            return headers
        },
    }), { maxRetries: 2 }),
    tagTypes: ['Product Noun'],
    endpoints: (builder) => ({
        // Get All Product Noun API
        getProductNouns: builder.query({
            query: ({ page = 0, pageSize = 10, sort_by = null, sort_order = null }) => {
                return ({ url: `${page !== null ? `?page_no=${page + 1}` : ''}${pageSize ? `&page_size=${pageSize}` : ''}${sort_by ? `&sort_by=${sort_by}` : ''}${sort_order ? `&sort_order=${sort_order}` : ''}`, method: "GET" })
            },
            transformResponse: (data: any, meta: { request: Request; response?: Response }) => {
                return getTransformedDataForPagination(data, meta?.response);
            },
        }),

        // Get All Product Noun API for dropdown 
        getProductNounsDD: builder.query({
            query: (payload) => ({ method: "GET" }),
            transformResponse: (response: any[], meta, arg) => ConvertToReactSelect(response, "_id", "product_noun_name"),
        }),

        // Get Product Noun based on FAMILY ID and GROUP ID API for dropdown 
        getProductNounsMappingDD: builder.query({
            query: ({ family_id, group_id}) => {
                return { url: `?family_id=${family_id}&group_id=${group_id}`, method: "GET" }
            },
            transformResponse: (response: any[], meta, arg) => ConvertToReactSelect(response, "_id", "product_noun_name"),
        }),

        // Search Product Noun API
        searchProductNouns: builder.query({
            query: (payload) => {
                let url = payload.search ? `/search?search=${payload.search}` : '';
                if (payload.family_id) url += `${payload.search ? '&' : '?'}family_id=${payload.family_id}`;
                return { url, method: "GET" }
            }
        }),

        // Create Product Noun API
        createProductNoun: builder.mutation({
            query: (payload) => ({ method: "POST", body: payload })
        }),

        // Update Product Noun API
        updateProductNoun: builder.mutation({
            query: (payload) => ({ url: `/${payload._id}`, method: "PUT", body: payload })
        }),

        // Delete Product Noun API
        deleteProductNoun: builder.mutation({
            query: ({id}) => ({ url: `/${id}`, method: "DELETE" })
        })
    })
})

// Export hooks for usage in functional components
export const { useGetProductNounsQuery, useGetProductNounsDDQuery,useGetProductNounsMappingDDQuery,useLazyGetProductNounsQuery, useLazySearchProductNounsQuery, useCreateProductNounMutation, useUpdateProductNounMutation, useDeleteProductNounMutation, useLazyGetProductNounsMappingDDQuery } = productNounAPI
