
import { Box, Chip } from "@mui/material";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid-premium";
import { useSnackbar } from "notistack";
import React from "react";
import { CustomDataGrid, SectionPaper } from "../../components";
import { useAppDispatch } from "../../redux";
import { closeConfirmationDialog, openConfirmationDialog } from "../../redux/slices/confirmationDialog";
import { useLazyGetBOMTreeByIdQuery } from "../../redux/services";
import { closeBackdrop, openBackdrop } from "../../redux/slices/backdrop";

interface Shapes {
    id: string | number,
    child?: string,
    bom?: string,
    bomId?: string,
    childBOM?: string,
    childBOMId?: string,
    childMaterial?: string,
    childMaterialId?: string,
    tagNo?: string,
    changes?: string,
    status?: "progress" | "updated" | "deleted" | "none",
    updatedTagNo?: string
}

const columns: GridColDef[] = [
    { field: 'id', width: 200, headerName: 'Shape ID' },
    { field: 'bom', width: 200, headerName: 'Parent BOM' },
    { field: 'child', width: 200, headerName: 'Assigned BOM/Material' },
    { field: 'tagNo', width: 200, headerName: 'Tag Number' },
    {
        field: 'changes', width: 300, headerName: 'Changes', renderCell: (params: GridRenderCellParams) => {
            const chipColor: any = {
                progress: "info",
                updated: "warning",
                deleted: "error",
                none: "success"
            }
            return <Chip sx={{
                height: 'auto',
                '& .MuiChip-label': {
                    display: 'block',
                    whiteSpace: 'normal',
                },
            }} color={chipColor[params.row.status]} label={params.row.changes} variant="outlined" />
        }
    },
]

export const SyncBOMToDrawio: React.FC<{ children?: JSX.Element }> = (props) => {

    const dispatch = useAppDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const [getBOMTree] = useLazyGetBOMTreeByIdQuery();

    const [rows, setRows] = React.useState<Shapes[]>([]);

    const sync = async (data: any) => {
        const cells: any[] = data?.cells ?? [];

        const { BOM, BOM_ID, CHILD_MATERIAL, CHILD_MATERIAL_ID, CHILD_BOM, CHILD_BOM_ID, TAG_NO } = data?.keys ?? {};

        const newRows: Shapes[] = [];

        cells.forEach(cell => {
            if (cell.id && cell.style) {
                let bom: string = "";
                let bomId: string = "";
                let childMaterial: string = "";
                let childMaterialId: string = "";
                let childBOM: string = "";
                let childBOMId: string = "";
                let tagNo: string = "";

                cell.style.split(";").forEach((_: any) => {
                    if (!bom && _.includes(BOM)) {
                        bom = _.split(BOM)[1];
                    }
                    if (!bomId && _.includes(BOM_ID)) {
                        bomId = _.split(BOM_ID)[1];
                    }
                    if (!childMaterial && _.includes(CHILD_MATERIAL)) {
                        childMaterial = _.split(CHILD_MATERIAL)[1]
                    }
                    if (!childMaterialId && _.includes(CHILD_MATERIAL_ID)) {
                        childMaterialId = _.split(CHILD_MATERIAL_ID)[1]
                    }
                    if (!childBOM && _.includes(CHILD_BOM)) {
                        childBOM = _.split(CHILD_BOM)[1]
                    }
                    if (!childBOMId && _.includes(CHILD_BOM_ID)) {
                        childBOMId = _.split(CHILD_BOM_ID)[1]
                    }
                    if (!tagNo && _.includes(TAG_NO)) {
                        tagNo = _.split(TAG_NO)?.[1] ?? ""
                    }
                });

                if(bomId){
                    newRows.push({ id: cell.id, child: childBOM ? childBOM : childMaterial, bom, bomId, childBOM, childBOMId, childMaterial, childMaterialId, tagNo, changes: "Checking...", status: "progress" });
                }
            }
        });

        setRows(newRows);
        checkBOMWithShapes(newRows, data?.parentBOM);
    };

    const checkBOMWithShapes = async (shapes: Shapes[], parentBOM: string) => {
        try {
            dispatch(openBackdrop("Fetching BOM Tree..."));
            const bomTree = await getBOMTree({ id: parentBOM }).unwrap();
            const updatedShapes = shapes.map(shape => {

                const type: "bom" | "material" = shape.childBOM ? "bom" : "material";

                let childId: string | undefined = "";

                if (type === "bom") {
                    childId = shape.childBOMId;
                } else {
                    childId = shape.childMaterialId;
                }

                const response = checkChildByChildId(childId, type, bomTree, shape);

                return {
                    ...shape,
                    changes: response?.changes ?? "No Changes",
                    status: response?.status ?? "none",
                    updatedTagNo: response?.updatedTagNo
                }
            });

            setRows(updatedShapes);
        } catch (error) {
            console.log('error:', error)
            enqueueSnackbar('Something went wrong, Unable to fetch BOM Tree', { variant: "error" });
        } finally {
            dispatch(closeBackdrop());
        }
    };

    const checkChildByChildId = (childId: string | undefined, type: "bom" | "material", bom: any, shape: Shapes) => {
        let changes = "No Changes";
        let status: "progress" | "updated" | "deleted" | "none" = "none";
        let updatedTagNo = shape.tagNo;

        const childrenKey = type === "bom" ? "bom_children" : "bom_materials";

        const foundedChild = bom[childrenKey]?.filter((_: any) => _.child_id === childId);

        if (foundedChild.length === 1) {
            if (shape.tagNo !== foundedChild[0].serial_num) {
                if (foundedChild[0].serial_num) {
                    changes = `Tag number is changed from '${shape.tagNo}' to '${foundedChild[0].serial_num}'`;
                } else {
                    changes = `Tag number is un assigned from the child ${type}.`;
                }
                updatedTagNo = foundedChild[0].serial_num;
                status = "updated";

                return { changes, status, updatedTagNo }
            }
        } else if (type === "bom" && bom?.bom?.bom_children?.length && foundedChild.length === 0) {
            for (let i = 0; i < bom.bom.bom_children.length; i++) {
                const _bom = bom.bom.bom_children[i];
                const res: any = checkChildByChildId(childId, type, _bom, shape);

                if (res?.status !== "none") {
                    changes = res?.changes ?? "No changes";
                    status = res?.status ?? "none";
                    break
                }
            }
            return { changes, status, updatedTagNo }
        } else {
            changes = `The child ${type} is deleted from the BOM.`;
            status = "deleted";
            return { changes, status, updatedTagNo }
        }

        return { changes, status, updatedTagNo }
    };

    const messageHandler = (evt: MessageEvent) => {
        if (evt.data?.type === 'syncBOM') {
            sync({ ...evt.data });
        }
    };

    const sendMessage = (shape: any, subEvent: string) => {
        const drawioContent: any = window.parent.window.parent.document.getElementById('drawio-editor-iframe');
        if (drawioContent) {
            drawioContent.contentWindow?.postMessage({ event: "iehub-plugin-sync", subEvent, shape }, "*");
        } else {
            console.log('drawio editor is not mapped');
        }
    };

    const syncShapeWithBOM = (isConfirmed: boolean = false) => {
        if (isConfirmed) {
            rows.forEach(row => {
                if (row.status && ["deleted", "updated"].includes(row.status)) {
                    sendMessage(row, row.status)
                }
            })
            enqueueSnackbar("Synced Successfully!", { variant: "success" });
            alert("Synced Successfully!!!");
            sendMessage(null, "close");
        } else {
            dispatch(openConfirmationDialog({
                title: "Are you sure?",
                body: "Do you want to sync the shapes with latest BOM?",
                onOk: () => {
                    dispatch(closeConfirmationDialog());
                    syncShapeWithBOM(true);
                }
            }))
        }
    };

    // Initial load
    React.useEffect(() => {
        window.addEventListener('message', messageHandler);

        return () => {
            window.removeEventListener('message', messageHandler);
        };
        // eslint-disable-next-line
    }, []);

    return <Box p={1} height={"100%"}>
        <SectionPaper title="Sync BOM" hideExpandCollapse primaryBtn={{
            title: "Sync with BOM",
            onClick: () => syncShapeWithBOM(),
            variant: "contained"
        }}>
            <CustomDataGrid id="sync-bom-draw-io-list" columns={columns} rows={rows} />
        </SectionPaper>
    </Box>
}