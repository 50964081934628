import { List } from "@mui/icons-material";
import { Box } from "@mui/material";
import React from "react";
import { Layout } from "../../components";
import { AppRoutes } from "../../router/routes";
import { IssueTracker } from "../viewProject/issueTracker";

const IssuesList: React.FC<{currentPath: string}> = ({currentPath}) => {

  return (
    <Box sx={{ padding: 0.5, height: "100%" }}>
      <Layout
        currentPath={currentPath}
        hideNavBarByDefault={true}
        navBars={[
          {
            id: "1",
            title: currentPath,
            icon: <List fontSize="small" />,
            path: AppRoutes.issuesList,
          },
        ]}
        mainPanel={<IssueTracker />}
        sideNavVariant={"whiteboard"}
      />
    </Box>
  );
}

export default IssuesList;