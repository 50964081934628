
import { Box } from "@mui/material";
import React from "react";
import { Layout } from "../../components";
import { Dashboard } from './dashboard';

const Tickets: React.FC<{ children?: JSX.Element }> = (props) => {

    return <Box sx={{ padding: 0.5, height: "100%" }}>
        <Layout
            history={[
                { label: "Help & Support", onClick: () => { } },
            ]}
            navBars={[]}
            currentPath={"Tickets"}
            mainPanel={<Dashboard />}
            sideNavVariant={"whiteboard"}
            otherBreadscrumProps={{               
                hideMenuBtn: true
            }}
        />
    </Box>
}

export default Tickets;