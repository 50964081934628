import { Box } from "@mui/material";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { withNavBars } from "../../HOCs";
import { Layout } from "../../components";
import { useAppDispatch } from "../../redux";
import { useLazyGetDataTableByIdQuery } from "../../redux/services";
import { openBackdrop } from "../../redux/slices/backdrop";
import { AppRoutes } from "../../router/routes";
import { CheckListData, ViewCheckListDetail } from "./viewCheckListDetail";

function ViewCheckListWithId() {

  const dispatch = useAppDispatch();
  const { checklist_id = "" } = useParams();
  const navigate = useNavigate();
  const [getCheckListById, checkListData] = useLazyGetDataTableByIdQuery({ refetchOnReconnect: true, refetchOnFocus: true });

  const isEdit = checklist_id !== "New";

  const breadScrumName = React.useMemo((): string => {
    if (isEdit) {
      return checkListData.isLoading ? '...' : `${checkListData.data?.sequence_id ?? ""}-r${checkListData.data?.version ?? 0}`
    } else {
      return "New"
    }
    // eslint-disable-next-line
  }, [checkListData.data]);

  const fetchCheckList = async () => {
    if (isEdit) {
      dispatch(openBackdrop("Fetching Check List Details..."));
      await getCheckListById({
        dtTemplateType: "checklist",
        dtInstanceType: "template",
        dtTemplateId: checklist_id
      });
    }
  };

  React.useEffect(() => {
    fetchCheckList();
    // eslint-disable-next-line
  }, [checklist_id]);

  return (
    <Box p={0.5} height={"100%"}>
      <Layout
        currentPath={'Check List Details'}
        history={[
          { label: "Document Templates", onClick: () => navigate(AppRoutes.viewTemplateList) },
          { label: 'Check List Templates', onClick: () => navigate(AppRoutes.checkList) },
          { label: breadScrumName, onClick: () => navigate(AppRoutes.viewChecklistWithId(checklist_id)) },
        ]}
        navBars={[]}
        mainPanel={<ViewCheckListDetail checkListData={checkListData as CheckListData} isEdit={isEdit} />}
        sideNavVariant={"whiteboard"}
        hideSideNavBar={false}
        otherBreadscrumProps={{
          hideMenuBtn: true,
          showBackBtn: true
        }}
      />
    </Box>
  );
}

export default withNavBars(ViewCheckListWithId);