import { createApi, fetchBaseQuery, retry } from "@reduxjs/toolkit/query/react";
import { config } from "../../../config";
import { LocalStorageKeys } from "../../../utils";

export const commonAttachmentAPI = createApi({
  reducerPath: "commonAttachmentAPI",
  baseQuery: retry(
    fetchBaseQuery({
      baseUrl: config.api_url,
      prepareHeaders: (headers, { getState }) => {
        const token = localStorage.getItem(LocalStorageKeys.authToken);

        // If we have a token set in state, let's assume that we should be passing it.
        if (token) {
          headers.set("authorization", `Bearer ${token}`);
          headers.set("Content-Type", "application/json");
        }

        return headers;
      },
    }),
    { maxRetries: 2 }
  ),
  tagTypes: ["Common Attachment"],
  endpoints: (builder) => ({
    // Get Attachment Url
    getAttachmentUrl: builder.query({
      query: ({ id, object_type, object_id }) => ({ url: `attachments/${object_type}/${object_id}/${id}`, method: "GET" }),
    }),

    // Upload Attachment API
    uploadAttachment: builder.mutation({
      query: ({ payload, object_type, object_id }) => ({
        url: `attachments/${object_type}/${object_id}`,
        method: "POST",
        body: payload,
      }),
    }),

     // Update Attachment API
     updateS3Attachment: builder.mutation({
      query: ({ object_type, object_id, attachment_id = "", is_primary = false }) => ({
        url: `attachments/${object_type}/${object_id}/${attachment_id}?is_primary=${is_primary}`,
        method: "PUT"
      }),
    }),

    // delete attachment
    deleteAttachment: builder.mutation({
      query: ({ object_type, object_id, attachment_id }) => ({
        url: `attachments/${object_type}/${object_id}/${attachment_id}`,
        method: "DELETE",
      }),
    }),
  }),
});

// Export hooks for usage in functional components
export const { useUploadAttachmentMutation, useUpdateS3AttachmentMutation, useDeleteAttachmentMutation, useLazyGetAttachmentUrlQuery } =
  commonAttachmentAPI;
