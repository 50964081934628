import React from "react";
import { SelectBoxOption } from "../../interfaces";
import { Box, Tab } from "@mui/material";
import FiltersWithSelect from "./withSelect";
import FilterBySearch from "./withSearch";
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { createOption, KeywordOption } from "../keywordSearch";
import { LocalStorageKeys } from "../../utils";

export interface FilterStateInterface {
  noun?: SelectBoxOption;
}

export interface FilterProps {
  showNoun?: boolean;
  showNounCard?: boolean;
  appliedFilters: FilterStateInterface;
  searchByKeyword: string;
  onApply: (state: FilterStateInterface) => void;
  onCancel: () => void;
  onFind: (search: string) => void;
  onReset: () => void;
}

const allTabs: SelectBoxOption[] = [
  { label: "Search by Keyword", value: "search-by-keyword" },
  { label: "Search by Noun", value: "search-by-noun" },
];

export const Filters: React.FC<FilterProps> = ({
  showNoun,
  appliedFilters,
  onApply,
  onCancel,
  showNounCard,
  onFind,
  searchByKeyword,
  onReset
}) => {
  const [selectedTab, setSelectedTab] = React.useState(allTabs[0]?.value);

  let searches: KeywordOption[] = JSON.parse(
    localStorage.getItem(LocalStorageKeys.keywordRecentSearch) || "[]"
  );
  let updatedOptions = searches.map((s) => {
    if (typeof s === "string") {
      return createOption(s);
    } else {
      return s;
    }
  });

  return (
    <Box>
        <TabContext value={selectedTab as string}>
        <TabList
            onChange={(e, tab) => setSelectedTab(tab)}
            indicatorColor="primary"
            textColor="primary"
            sx={{ boxShadow: `0px 1px 4px rgba(0, 0, 0, 0.3)` }}
        >
            <Tab value={allTabs[0]?.value} label={allTabs[0]?.label} />
            {showNounCard && <Tab value={allTabs[1]?.value} label={allTabs[1]?.label} />}
        </TabList>
        <TabPanel value={allTabs[0]?.value as string}>
            <FilterBySearch onFind={onFind} onReset={onReset} searchByKeyword={updatedOptions.find(op => op.value === searchByKeyword) ?? null} />
        </TabPanel>
        {showNounCard &&    
          <TabPanel value={allTabs[1]?.value as string}>
              <FiltersWithSelect
              {...{ showNoun, appliedFilters, onApply, onCancel, showNounCard }}
              />
          </TabPanel>
        }
        </TabContext>
    </Box>
  );
};
