import React from "react";
import { Route, RouterProvider, createBrowserRouter, createRoutesFromElements } from "react-router-dom";
import PrivateRoute from "./privateRouter";
import { AppRoutes } from "./routes";
import { PDFAnnotator } from "../components";
import SequenceNumbering from "../screens/documentTemplates/sequenceNumbering";
import SnTemplate from "../screens/documentTemplates/sequenceNumbering/addUpdate/snTemplate";
import IssuesList from "../screens/issuesList";
import ListViewPreview from "../screens/listview/ListViewPreview";
import { AddEditListView } from "../screens/listview/addEditListView";
import ViewCheckListWithId from "../screens/viewCheckList";
import ViewDataSheetWithId from "../screens/viewDatasheet";
import ViewInputParameterWithId from "../screens/viewInputParameter";
import { ViewMaterials } from "../screens/viewMaterial";
import ViewIssueTrackerWithId from "../screens/viewProject/issueTracker/viewIssueWithId";
import {
  AIInstantSearch,
  AddEditBOMReport,
  Auth0,
  BOM,
  BOMPreview,
  BOMReport,
  CutSheetBuilder,
  DocumentDashboard,
  DocumentTemplate,
  Documents,
  DrawIOEditor,
  DrawioDiagrams,
  EmbedComponent,
  Home,
  Logout,
  MDCreate,
  MDUpdate,
  MaterialDashboard,
  Materials,
  NotFound,
  PMP,
  ProductRepository,
  Products,
  ProductsDashboard,
  Projects,
  ProjectsDashboard,
  ProposalDashboard,
  Proposals,
  Root,
  SupplierList,
  TagGroups,
  Tags,
  User,
  VendorDashboard,
  Vendors,
  ViewAISearch,
  ViewBOM,
  ViewBOMCostBreakup,
  ViewBOMCostingTree,
  ViewCutSheet,
  ViewMaterial,
  ViewProduct,
  ViewProjects,
  ViewProposal,
  ViewTemplate,
  ViewVendor,
  DrawioDiagramTemplates,
  AddEditWorkBook,
  Workbook,
  DrawIOPreview
} from "./../screens";
import { AppLayout } from "../App";
import { AppAuth } from "../App.auth";
import { PERMISSIONS } from "./permission";
import LoginV2 from "../screens/loginV2";
import Tickets from "../screens/tickets";

const App: React.FC = (props) => {
  return <RouterProvider router={createBrowserRouter(createRoutesFromElements(<Route element={<AppLayout />}>
    {/* Login Route */}
    <Route path={AppRoutes.login} element={<AppAuth>
      <LoginV2 />
    </AppAuth>}
    />

    {/* Auth0 Route */}
    <Route path={AppRoutes.auth0} element={<Auth0 />} />

    {/* Logout Route */}
    <Route path={AppRoutes.logout} element={<Logout />} />

    {/* Root route */}
    <Route path={AppRoutes.root} element={<AppAuth>
      <Root />
    </AppAuth>} />

    {/* Home Route */}
    <Route
      path={AppRoutes.home}
      element={
        <PrivateRoute permission={PERMISSIONS.PUBLIC} path={AppRoutes.home}>
          <Home />
        </PrivateRoute>
      }
    />


    {/* Material Route */}
    <Route
      path={AppRoutes.allMaterials}
      element={
        <PrivateRoute permission={PERMISSIONS.MATERIAL_READ} path={AppRoutes.allMaterials}>
          <Materials />
        </PrivateRoute>
      }
    />

    {/* View Material - Basic Details */}
    <Route
      path={AppRoutes.viewMatBasicDetail()}
      element={
        <PrivateRoute permission={PERMISSIONS.MATERIAL_READ} path={AppRoutes.viewMatBasicDetail()}>
          <ViewMaterial currentPath={"Basic Details"} />
        </PrivateRoute>
      }
    />

    {/* View Material - Documents */}
    <Route
      path={AppRoutes.viewMatDocuments()}
      element={
        <PrivateRoute permission={PERMISSIONS.MATERIAL_READ} path={AppRoutes.viewMatDocuments()}>
          <ViewMaterial currentPath={"Documents"} />
        </PrivateRoute>
      }
    />

    {/* View Material - Data Sheet */}
    <Route
      path={AppRoutes.viewMatDataSheet()}
      element={
        <PrivateRoute permission={PERMISSIONS.MATERIAL_READ} path={AppRoutes.viewMatDataSheet()}>
          <ViewMaterial currentPath={"Data Sheet"} />
        </PrivateRoute>
      }
    />

    {/* View Material - Material Details Preview */}
    <Route
      path={AppRoutes.viewMatDetailsPreview()}
      element={
        <PrivateRoute permission={PERMISSIONS.MATERIAL_READ} path={AppRoutes.viewMatDetailsPreview()}>
          <ViewMaterial currentPath={"Material Details Preview"} />
        </PrivateRoute>
      }
    />

    {/* View Material - Material Procure */}
    <Route
      path={AppRoutes.viewMatProcure()}
      element={
        <PrivateRoute permission={PERMISSIONS.MATERIAL_READ} path={AppRoutes.viewMatProcure()}>
          <ViewMaterial currentPath={"Procure Material"} />
        </PrivateRoute>
      }
    />

    {/* BOMs */}
    <Route
      path={AppRoutes.bom}
      element={
        <PrivateRoute permission={PERMISSIONS.BOM_READ} path={AppRoutes.bom}>
          <BOM />
        </PrivateRoute>
      }
    />

    {/* View BOM - Basic Details */}
    <Route
      path={AppRoutes.viewBomBasicDetail()}
      element={
        <PrivateRoute permission={PERMISSIONS.BOM_READ} path={AppRoutes.viewBomBasicDetail()}>
          <ViewBOM currentPath={"Basic Details"} />
        </PrivateRoute>
      }
    />

    {/* View BOM - Documents */}
    <Route
      path={AppRoutes.viewBomDocuments()}
      element={
        <PrivateRoute permission={PERMISSIONS.BOM_READ} path={AppRoutes.viewBomDocuments()}>
          <ViewBOM currentPath={"Documents"} />
        </PrivateRoute>
      }
    />

    {/* View BOM - Data Sheet */}
    <Route
      path={AppRoutes.viewBomDataSheet()}
      element={
        <PrivateRoute permission={PERMISSIONS.BOM_READ} path={AppRoutes.viewBomDataSheet()}>
          <ViewBOM currentPath={"Data Sheet"} />
        </PrivateRoute>
      }
    />


    {/* View BOM - Proposal Details Preview */}
    <Route
      path={AppRoutes.viewProposalBOMDetailsPreview()}
      element={
        <PrivateRoute permission={PERMISSIONS.BOM_READ} path={AppRoutes.viewProposalBOMDetailsPreview()}>
          <ViewBOM customAssociation={{ object_type: "proposal", object_id: "" }} currentPath={"BOM Details Preview"} />
        </PrivateRoute>
      }
    />

    {/* View BOM - BOM Tree */}
    <Route
      path={AppRoutes.viewBomTree()}
      element={
        <PrivateRoute permission={PERMISSIONS.BOM_READ} path={AppRoutes.viewBomTree()}>
          <ViewBOM currentPath={"BOM Tree"} />
        </PrivateRoute>
      }
    />

    {/* View BOM - Document Tree */}
    <Route
      path={AppRoutes.viewDocTree()}
      element={
        <PrivateRoute permission={PERMISSIONS.BOM_READ} path={AppRoutes.viewDocTree()}>
          <ViewBOM currentPath={"Document Tree"} />
        </PrivateRoute>
      }
    />

    {/* View BOM - BOM Costing Tree */}
    <Route
      path={AppRoutes.viewBomCostingTree()}
      element={
        <PrivateRoute permission={PERMISSIONS.BOM_READ} path={AppRoutes.viewBomCostingTree()}>
          <ViewBOMCostingTree currentPath={"Costing Tree"} />
        </PrivateRoute>
      }
    />

    {/* View BOM - Cost Breakup */}
    <Route
      path={AppRoutes.viewBomCostBreakup()}
      element={
        <PrivateRoute permission={PERMISSIONS.BOM_READ} path={AppRoutes.viewBomCostBreakup()}>
          <ViewBOMCostBreakup currentPath={"Cost Breakup"} />
        </PrivateRoute>
      }
    />

    {/* Material Description Generator Route */}
    <Route
      path={AppRoutes.descriptionGenerator}
      element={
        <PrivateRoute permission={PERMISSIONS.DG_READ} path={AppRoutes.descriptionGenerator}>
          <MDCreate />
        </PrivateRoute>
      }
    />

    {/* Material Repository Route */}
    <Route
      path={AppRoutes.materialRepository}
      element={
        <PrivateRoute permission={PERMISSIONS.MATERIAL_READ} path={AppRoutes.materialRepository}>
          <MDUpdate />
        </PrivateRoute>
      }
    />

    {/* Product Module */}
    {/* Variant Configurator */}
    <Route
      path={AppRoutes.product_vc}
      element={
        <PrivateRoute permission={PERMISSIONS.PRODUCT_VC} path={AppRoutes.product_vc}>
          <PMP />
        </PrivateRoute>
      }
    />

    {/* Repository */}
    <Route
      path={AppRoutes.product_repository}
      element={
        <PrivateRoute permission={PERMISSIONS.PRODUCT_REPO_READ} path={AppRoutes.product_repository}>
          <ProductRepository />
        </PrivateRoute>
      }
    />

    {/* Products */}
    <Route
      path={AppRoutes.products}
      element={
        <PrivateRoute permission={PERMISSIONS.PRODUCT_READ} path={AppRoutes.products}>
          <Products />
        </PrivateRoute>
      }
    />

    {/* View Product Basic Details */}
    <Route
      path={AppRoutes.viewProductBasicDetail()}
      element={
        <PrivateRoute permission={PERMISSIONS.PRODUCT_READ} path={AppRoutes.viewProductBasicDetail()}>
          <ViewProduct currentPath={"Basic Details"} />
        </PrivateRoute>
      }
    />

    {/* View Product Documents */}
    <Route
      path={AppRoutes.viewProductDocuments()}
      element={
        <PrivateRoute permission={PERMISSIONS.PRODUCT_READ} path={AppRoutes.viewProductDocuments()}>
          <ViewProduct currentPath={"Documents"} />
        </PrivateRoute>
      }
    />

    {/* View Product Materials */}
    <Route
      path={AppRoutes.viewProductMaterial()}
      element={
        <PrivateRoute permission={PERMISSIONS.PRODUCT_READ} path={AppRoutes.viewProductMaterial()}>
          <ViewProduct currentPath={"Materials"} />
        </PrivateRoute>
      }
    />

    {/* View Product Bill of Materials */}
    <Route
      path={AppRoutes.viewProductBOM()}
      element={
        <PrivateRoute permission={PERMISSIONS.PRODUCT_READ} path={AppRoutes.viewProductBOM()}>
          <ViewProduct currentPath={"BOMs"} />
        </PrivateRoute>
      }
    />

    {/* View Product IEDraws */}
    <Route
      path={AppRoutes.viewProductIEDraw()}
      element={
        <PrivateRoute permission={PERMISSIONS.PRODUCT_READ} path={AppRoutes.viewProductIEDraw()}>
          <ViewProduct currentPath={"IEDraw (Beta)"} />
        </PrivateRoute>
      }
    />

    {/* View Product AI Search */}
    <Route
      path={AppRoutes.viewProductAISearch()}
      element={
        <PrivateRoute permission={PERMISSIONS.PRODUCT_READ} path={AppRoutes.viewProductAISearch()}>
          <ViewProduct currentPath={"AI Search"} />
        </PrivateRoute>
      }
    />

    {/* Create OR Update BOM in Product */}
    <Route
      path={AppRoutes.createOrUpdateProductBOMBasicDetail()}
      element={
        <PrivateRoute permission={PERMISSIONS.PRODUCT_READ} path={AppRoutes.createOrUpdateProductBOMBasicDetail()}>
          <ViewBOM customAssociation={{ object_type: "product", object_id: "" }} currentPath={"Basic Details"} />
        </PrivateRoute>
      }
    />
    <Route
      path={AppRoutes.createOrUpdateProductBOMDocuments()}
      element={
        <PrivateRoute permission={PERMISSIONS.PRODUCT_READ} path={AppRoutes.createOrUpdateProductBOMDocuments()}>
          <ViewBOM customAssociation={{ object_type: "product", object_id: "" }} currentPath={"Documents"} />
        </PrivateRoute>
      }
    />
    {/* View BOM - BOM Tree */}
    <Route
      path={AppRoutes.createOrUpdateProductBOMTree()}
      element={
        <PrivateRoute permission={PERMISSIONS.PRODUCT_READ} path={AppRoutes.createOrUpdateProductBOMTree()}>
          <ViewBOM customAssociation={{ object_type: "product", object_id: "" }} currentPath={"BOM Tree"} />
        </PrivateRoute>
      }
    />
    {/* View BOM - Document Tree */}
    <Route
      path={AppRoutes.createOrUpdateProductDocTree()}
      element={
        <PrivateRoute permission={PERMISSIONS.PRODUCT_READ} path={AppRoutes.createOrUpdateProductDocTree()}>
          <ViewBOM customAssociation={{ object_type: "product", object_id: "" }} currentPath={"Document Tree"} />
        </PrivateRoute>
      }
    />
    {/* View BOM - Product BOM Data Sheet */}
    <Route
      path={AppRoutes.viewBOMProductDataSheet()}
      element={
        <PrivateRoute permission={PERMISSIONS.PRODUCT_READ} path={AppRoutes.viewBOMProductDataSheet()}>
          <ViewBOM customAssociation={{ object_type: "product", object_id: "" }} currentPath={"Data Sheet"} />
        </PrivateRoute>
      }
    />

    {/* Create OR Update Materials in Product */}
    <Route
      path={AppRoutes.createOrUpdateProductMaterialBasicDetail()}
      element={
        <PrivateRoute permission={PERMISSIONS.PRODUCT_READ} path={AppRoutes.createOrUpdateProductMaterialBasicDetail()}>
          <ViewMaterials customAssociation={{ object_type: "product", object_id: "" }} currentPath={"Basic Details"} />
        </PrivateRoute>
      }
    />
    <Route
      path={AppRoutes.createOrUpdateProductMaterialDocuments()}
      element={
        <PrivateRoute permission={PERMISSIONS.PRODUCT_READ} path={AppRoutes.createOrUpdateProductMaterialDocuments()}>
          <ViewMaterials customAssociation={{ object_type: "product", object_id: "" }} currentPath={"Documents"} />
        </PrivateRoute>
      }
    />
    {/* View BOM - Product Material Data Sheet */}
    <Route
      path={AppRoutes.createOrUpdateProductMaterialDataSheet()}
      element={
        <PrivateRoute permission={PERMISSIONS.PRODUCT_READ} path={AppRoutes.createOrUpdateProductMaterialDataSheet()}>
          <ViewMaterials customAssociation={{ object_type: "product", object_id: "" }} currentPath={"Data Sheet"} />
        </PrivateRoute>
      }
    />
    <Route
      path={AppRoutes.createOrUpdateProductMaterialDetailsPreview()}
      element={
        <PrivateRoute permission={PERMISSIONS.PRODUCT_READ} path={AppRoutes.createOrUpdateProductMaterialDetailsPreview()}>
          <ViewMaterials customAssociation={{ object_type: "product", object_id: "" }} currentPath={"Material Details Preview"} />
        </PrivateRoute>
      }
    />
    <Route
      path={AppRoutes.createOrUpdateProductMaterialProcure()}
      element={
        <PrivateRoute permission={PERMISSIONS.PRODUCT_READ} path={AppRoutes.createOrUpdateProductMaterialProcure()}>
          <ViewMaterials customAssociation={{ object_type: "product", object_id: "" }} currentPath={"Procure Material"} />
        </PrivateRoute>
      }
    />

    {/* Tags & Template Module */}
    {/* Tags */}
    <Route
      path={AppRoutes.tags}
      element={
        <PrivateRoute permission={PERMISSIONS.TAG_READ} path={AppRoutes.tags}>
          <Tags />
        </PrivateRoute>
      }
    />

    {/* Tags */}
    <Route
      path={AppRoutes.tagGroups}
      element={
        <PrivateRoute permission={PERMISSIONS.TAG_CATEGORY_READ} path={AppRoutes.tagGroups}>
          <TagGroups />
        </PrivateRoute>
      }
    />

    {/* Document Templates */}
    <Route
      path={AppRoutes.viewTemplateList}
      element={
        <PrivateRoute permission={PERMISSIONS.DOCUMENT_TEMPLATE_READ} path={AppRoutes.viewTemplateList}>
          <DocumentTemplate />
        </PrivateRoute>
      }
    />

    {/* View Template */}
    <Route
      path={AppRoutes.viewTemplate()}
      element={
        <PrivateRoute permission={PERMISSIONS.DOCUMENT_TEMPLATE_READ} path={AppRoutes.viewTemplate()}>
          <ViewTemplate currentPath={"Configure Template"} />
        </PrivateRoute>
      }
    />

    {/* Sequence Numbering */}
    <Route
      path={AppRoutes.sequenceNumbering()}
      element={
        <PrivateRoute permission={PERMISSIONS.SEQUENCE_NO_TEMPLATE_READ} path={AppRoutes.sequenceNumbering()}>
          <SequenceNumbering />
        </PrivateRoute>
      }
    />

    {/* View Sequence Number */}
    <Route
      path={AppRoutes.viewSequenceNumber()}
      element={
        <PrivateRoute permission={PERMISSIONS.SEQUENCE_NO_TEMPLATE_READ} path={AppRoutes.viewSequenceNumber()}>
          <SnTemplate currentPath={"Configure Sequence Number"} />
        </PrivateRoute>
      }
    />

    {/* Suppliers/Vendors Module */}

    {/* Suppliers/Vendors - Dashboard */}
    <Route
      path={AppRoutes.vendorsDashboard}
      element={
        <PrivateRoute permission={PERMISSIONS.SUPPLIER_READ} path={AppRoutes.vendorsDashboard}>
          <VendorDashboard />
        </PrivateRoute>
      }
    />

    {/* Suppliers/Vendors - Dashboard */}
    <Route
      path={AppRoutes.vendors}
      element={
        <PrivateRoute permission={PERMISSIONS.SUPPLIER_READ} path={AppRoutes.vendors}>
          <Vendors />
        </PrivateRoute>
      }
    />

    {/* View Suppliers/Vendor Basic Details */}
    <Route
      path={AppRoutes.viewVendorBasicDetails()}
      element={
        <PrivateRoute permission={PERMISSIONS.SUPPLIER_READ} path={AppRoutes.viewVendorBasicDetails()}>
          <ViewVendor currentPath="Supplier Details" />
        </PrivateRoute>
      }
    />

    {/* View Suppliers/Vendor Basic Details in read only mode*/}
    <Route
      path={AppRoutes.viewVendorBasicDetailsReadOnly()}
      element={
        <PrivateRoute permission={PERMISSIONS.SUPPLIER_READ} path={AppRoutes.viewVendorBasicDetails()}>
          <ViewVendor currentPath="Basic Details" />
        </PrivateRoute>
      }
    />

    {/* View Vendor Documents */}
    <Route
      path={AppRoutes.viewVendorDocuments()}
      element={
        <PrivateRoute permission={PERMISSIONS.SUPPLIER_READ} path={AppRoutes.viewVendorDocuments()}>
          <ViewVendor currentPath={"Documents"} />
        </PrivateRoute>
      }
    />

    {/* Suppliers/Vendor List */}
    <Route
      path={AppRoutes.suppliersList}
      element={
        <PrivateRoute permission={PERMISSIONS.SUPPLIER_READ} path={AppRoutes.suppliersList} enablePadding={false}>
          <SupplierList />
        </PrivateRoute>
      }
    />

    {/* Proposal Routes */}
    {/* Proposal List */}
    <Route
      path={AppRoutes.proposals}
      element={
        <PrivateRoute permission={PERMISSIONS.PROPOSAL_READ} path={AppRoutes.proposals}>
          <Proposals />
        </PrivateRoute>
      }
    />
    {/* View Proposal Basic Details */}
    <Route
      path={AppRoutes.viewProposalBasicDetail()}
      element={
        <PrivateRoute permission={PERMISSIONS.PROPOSAL_READ} path={AppRoutes.viewProposalBasicDetail()}>
          <ViewProposal currentPath={"Basic Details"} />
        </PrivateRoute>
      }
    />

    {/* View Proposal Documents */}
    <Route
      path={AppRoutes.viewProposalDocuments()}
      element={
        <PrivateRoute permission={PERMISSIONS.PROPOSAL_READ} path={AppRoutes.viewProposalDocuments()}>
          <ViewProposal currentPath={"Documents"} />
        </PrivateRoute>
      }
    />

    {/* View Proposal Materials */}
    <Route
      path={AppRoutes.viewProposalMaterial()}
      element={
        <PrivateRoute permission={PERMISSIONS.PROPOSAL_READ} path={AppRoutes.viewProposalMaterial()}>
          <ViewProposal currentPath={"Materials"} />
        </PrivateRoute>
      }
    />

    {/* View Proposal Bill of Materials */}
    <Route
      path={AppRoutes.viewProposalBOM()}
      element={
        <PrivateRoute permission={PERMISSIONS.PROPOSAL_READ} path={AppRoutes.viewProposalBOM()}>
          <ViewProposal currentPath={"BOMs"} />
        </PrivateRoute>
      }
    />

    {/* View Proposal IEDRAW */}
    <Route
      path={AppRoutes.viewProposalIEDraw()}
      element={
        <PrivateRoute permission={PERMISSIONS.PROPOSAL_READ} path={AppRoutes.viewProposalIEDraw()}>
          <ViewProposal currentPath={"IEDRAW (Beta)"} />
        </PrivateRoute>
      }
    />

    {/* View Proposal AI Search */}
    <Route
      path={AppRoutes.viewProposalAISearch()}
      element={
        <PrivateRoute permission={PERMISSIONS.PROPOSAL_READ} path={AppRoutes.viewProposalAISearch()}>
          <ViewProposal currentPath={"AI Search"} />
        </PrivateRoute>
      }
    />

    {/* Create OR Update BOM in Proposal */}
    <Route
      path={AppRoutes.createOrUpdateProposalBOMBasicDetail()}
      element={
        <PrivateRoute permission={PERMISSIONS.PROPOSAL_READ} path={AppRoutes.createOrUpdateProposalBOMBasicDetail()}>
          <ViewBOM customAssociation={{ object_type: "proposal", object_id: "" }} currentPath={"Basic Details"} />
        </PrivateRoute>
      }
    />

    <Route
      path={AppRoutes.createOrUpdateProposalBOMDocuments()}
      element={
        <PrivateRoute permission={PERMISSIONS.PROPOSAL_READ} path={AppRoutes.createOrUpdateProposalBOMDocuments()}>
          <ViewBOM customAssociation={{ object_type: "proposal", object_id: "" }} currentPath={"Documents"} />
        </PrivateRoute>
      }
    />
    <Route
      path={AppRoutes.createOrUpdateProposalBOMTree()}
      element={
        <PrivateRoute permission={PERMISSIONS.PROPOSAL_READ} path={AppRoutes.createOrUpdateProposalBOMTree()}>
          <ViewBOM customAssociation={{ object_type: "proposal", object_id: "" }} currentPath={"BOM Tree"} />
        </PrivateRoute>
      }
    />
    <Route
      path={AppRoutes.createOrUpdateProposalDocTree()}
      element={
        <PrivateRoute permission={PERMISSIONS.PROPOSAL_READ} path={AppRoutes.createOrUpdateProposalDocTree()}>
          <ViewBOM customAssociation={{ object_type: "proposal", object_id: "" }} currentPath={"Document Tree"} />
        </PrivateRoute>
      }
    />
    {/* View BOM - Proposal BOM Data Sheet */}
    <Route
      path={AppRoutes.viewBOMProposalDataSheet()}
      element={
        <PrivateRoute permission={PERMISSIONS.PROPOSAL_READ} path={AppRoutes.viewBOMProposalDataSheet()}>
          <ViewBOM customAssociation={{ object_type: "proposal", object_id: "" }} currentPath={"Data Sheet"} />
        </PrivateRoute>
      }
    />

    {/* Create OR Update Materials in Proposal */}
    <Route
      path={AppRoutes.createOrUpdateProposalMaterialBasicDetail()}
      element={
        <PrivateRoute permission={PERMISSIONS.PROPOSAL_READ} path={AppRoutes.createOrUpdateProposalMaterialBasicDetail()}>
          <ViewMaterials customAssociation={{ object_type: "proposal", object_id: "" }} currentPath={"Basic Details"} />
        </PrivateRoute>
      }
    />
    <Route
      path={AppRoutes.createOrUpdateProposalMaterialDocuments()}
      element={
        <PrivateRoute permission={PERMISSIONS.PROPOSAL_READ} path={AppRoutes.createOrUpdateProposalMaterialDocuments()}>
          <ViewMaterials customAssociation={{ object_type: "proposal", object_id: "" }} currentPath={"Documents"} />
        </PrivateRoute>
      }
    />
    {/* View BOM - Proposal Material Data Sheet */}
    <Route
      path={AppRoutes.createOrUpdateProposalMaterialDataSheet()}
      element={
        <PrivateRoute permission={PERMISSIONS.PROPOSAL_READ} path={AppRoutes.createOrUpdateProposalMaterialDataSheet()}>
          <ViewMaterials customAssociation={{ object_type: "proposal", object_id: "" }} currentPath={"Data Sheet"} />
        </PrivateRoute>
      }
    />
    <Route
      path={AppRoutes.createOrUpdateProposalMaterialDetailsPreview()}
      element={
        <PrivateRoute permission={PERMISSIONS.PROPOSAL_READ} path={AppRoutes.createOrUpdateProposalMaterialDetailsPreview()}>
          <ViewMaterials customAssociation={{ object_type: "proposal", object_id: "" }} currentPath={"Material Details Preview"} />
        </PrivateRoute>
      }
    />
    <Route
      path={AppRoutes.createOrUpdateProposalMaterialProcure()}
      element={
        <PrivateRoute permission={PERMISSIONS.PROPOSAL_READ} path={AppRoutes.createOrUpdateProposalMaterialProcure()}>
          <ViewMaterials customAssociation={{ object_type: "proposal", object_id: "" }} currentPath={"Procure Material"} />
        </PrivateRoute>
      }
    />

    {/* Project Routes */}
    {/* Project List */}
    <Route
      path={AppRoutes.projects}
      element={
        <PrivateRoute permission={PERMISSIONS.PROJECT_READ} path={AppRoutes.projects}>
          <Projects />
        </PrivateRoute>
      }
    />

    {/* View Project Basic Details */}
    <Route
      path={AppRoutes.viewProjectBasicDetail()}
      element={
        <PrivateRoute permission={PERMISSIONS.PROJECT_READ} path={AppRoutes.viewProjectBasicDetail()}>
          <ViewProjects currentPath={"Basic Details"} />
        </PrivateRoute>
      }
    />

    {/* View Project Documents */}
    <Route
      path={AppRoutes.viewProjectDocuments()}
      element={
        <PrivateRoute permission={PERMISSIONS.PROJECT_READ} path={AppRoutes.viewProjectDocuments()}>
          <ViewProjects currentPath={"Documents"} />
        </PrivateRoute>
      }
    />

    {/* View Project Bill of Materials */}
    <Route
      path={AppRoutes.viewProjectBOM()}
      element={
        <PrivateRoute permission={PERMISSIONS.PROJECT_READ} path={AppRoutes.viewProjectBOM()}>
          <ViewProjects currentPath={"BOMs"} />
        </PrivateRoute>
      }
    />

    {/* View Project Materials */}
    <Route
      path={AppRoutes.viewProjectMaterial()}
      element={
        <PrivateRoute permission={PERMISSIONS.PROJECT_READ} path={AppRoutes.viewProjectMaterial()}>
          <ViewProjects currentPath={"Materials"} />
        </PrivateRoute>
      }
    />

    {/* View Project IEDRAW */}
    <Route
      path={AppRoutes.viewProjectIEDraw()}
      element={
        <PrivateRoute permission={PERMISSIONS.PROJECT_READ} path={AppRoutes.viewProjectIEDraw()}>
          <ViewProjects currentPath={"IEDRAW (Beta)"} />
        </PrivateRoute>
      }
    />

    {/* View Project AI Search */}
    <Route
      path={AppRoutes.viewProjectAISearch()}
      element={
        <PrivateRoute permission={PERMISSIONS.PROJECT_READ} path={AppRoutes.viewProjectAISearch()}>
          <ViewProjects currentPath={"AI Search"} />
        </PrivateRoute>
      }
    />

    {/* Create OR Update BOM in Project */}
    <Route
      path={AppRoutes.createOrUpdateProjectBOMBasicDetail()}
      element={
        <PrivateRoute permission={PERMISSIONS.PROJECT_READ} path={AppRoutes.createOrUpdateProjectBOMBasicDetail()}>
          <ViewBOM customAssociation={{ object_type: "project", object_id: "" }} currentPath={"Basic Details"} />
        </PrivateRoute>
      }
    />
    <Route
      path={AppRoutes.createOrUpdateProjectBOMDocuments()}
      element={
        <PrivateRoute permission={PERMISSIONS.PROJECT_READ} path={AppRoutes.createOrUpdateProjectBOMDocuments()}>
          <ViewBOM customAssociation={{ object_type: "project", object_id: "" }} currentPath={"Documents"} />
        </PrivateRoute>
      }
    />
    <Route
      path={AppRoutes.createOrUpdateProjectBOMTree()}
      element={
        <PrivateRoute permission={PERMISSIONS.PROJECT_READ} path={AppRoutes.createOrUpdateProjectBOMTree()}>
          <ViewBOM customAssociation={{ object_type: "project", object_id: "" }} currentPath={"BOM Tree"} />
        </PrivateRoute>
      }
    />
    <Route
      path={AppRoutes.createOrUpdateProjectDocTree()}
      element={
        <PrivateRoute permission={PERMISSIONS.PROJECT_READ} path={AppRoutes.createOrUpdateProjectDocTree()}>
          <ViewBOM customAssociation={{ object_type: "project", object_id: "" }} currentPath={"Document Tree"} />
        </PrivateRoute>
      }
    />
    {/* View BOM - Project BOM Data Sheet */}
    <Route
      path={AppRoutes.viewBOMProjectDataSheet()}
      element={
        <PrivateRoute permission={PERMISSIONS.PROJECT_READ} path={AppRoutes.viewBOMProjectDataSheet()}>
          <ViewBOM customAssociation={{ object_type: "project", object_id: "" }} currentPath={"Data Sheet"} />
        </PrivateRoute>
      }
    />

    {/* Create OR Update Materials in Project */}
    <Route
      path={AppRoutes.createOrUpdateProjectMaterialBasicDetail()}
      element={
        <PrivateRoute permission={PERMISSIONS.PROJECT_READ} path={AppRoutes.createOrUpdateProjectMaterialBasicDetail()}>
          <ViewMaterials customAssociation={{ object_type: "project", object_id: "" }} currentPath={"Basic Details"} />
        </PrivateRoute>
      }
    />
    <Route
      path={AppRoutes.createOrUpdateProjectMaterialDocuments()}
      element={
        <PrivateRoute permission={PERMISSIONS.PROJECT_READ} path={AppRoutes.createOrUpdateProjectMaterialDocuments()}>
          <ViewMaterials customAssociation={{ object_type: "project", object_id: "" }} currentPath={"Documents"} />
        </PrivateRoute>
      }
    />
    {/* View BOM - Project Material Data Sheet */}
    <Route
      path={AppRoutes.createOrUpdateProjectMaterialDataSheet()}
      element={
        <PrivateRoute permission={PERMISSIONS.PROJECT_READ} path={AppRoutes.createOrUpdateProjectMaterialDataSheet()}>
          <ViewMaterials customAssociation={{ object_type: "project", object_id: "" }} currentPath={"Data Sheet"} />
        </PrivateRoute>
      }
    />
    <Route
      path={AppRoutes.createOrUpdateProjectMaterialDetailsPreview()}
      element={
        <PrivateRoute permission={PERMISSIONS.PROJECT_READ} path={AppRoutes.createOrUpdateProjectMaterialDetailsPreview()}>
          <ViewMaterials customAssociation={{ object_type: "project", object_id: "" }} currentPath={"Material Details Preview"} />
        </PrivateRoute>
      }
    />
    <Route
      path={AppRoutes.createOrUpdateProjectMaterialProcure()}
      element={
        <PrivateRoute permission={PERMISSIONS.PROJECT_READ} path={AppRoutes.createOrUpdateProjectMaterialProcure()}>
          <ViewMaterials customAssociation={{ object_type: "project", object_id: "" }} currentPath={"Procure Material"} />
        </PrivateRoute>
      }
    />

    {/* AI Module */}
    {/* AI Module - Instant Search*/}
    <Route
      path={AppRoutes.aiInstantSearch}
      element={
        <PrivateRoute permission={PERMISSIONS.AI_SEARCH_READ} path={AppRoutes.aiInstantSearch}>
          <AIInstantSearch />
        </PrivateRoute>
      }
    />
    {/* AI Module - View Basic Details*/}
    <Route
      path={AppRoutes.viewAIAllSearcheBasicDetails()}
      element={
        <PrivateRoute permission={PERMISSIONS.AI_SEARCH_READ} path={AppRoutes.viewAIAllSearcheBasicDetails()}>
          <ViewAISearch currentPath={"Basic Details"} />
        </PrivateRoute>
      }
    />
    {/* AI Module - View Documents*/}
    <Route
      path={AppRoutes.viewAIAllSearcheDocuments()}
      element={
        <PrivateRoute permission={PERMISSIONS.AI_SEARCH_READ} path={AppRoutes.viewAIAllSearcheDocuments()}>
          <ViewAISearch currentPath={"Documents"} />
        </PrivateRoute>
      }
    />
    {/* AI Module - View Chat bot*/}
    <Route
      path={AppRoutes.viewAIAllSearcheChatBox()}
      element={
        <PrivateRoute permission={PERMISSIONS.AI_SEARCH_READ} path={AppRoutes.viewAIAllSearcheChatBox()}>
          <ViewAISearch currentPath={"Chat Bot"} />
        </PrivateRoute>
      }
    />

    {/* Document Module */}

    {/* Documents */}
    <Route
      path={AppRoutes.documents}
      element={
        <PrivateRoute permission={PERMISSIONS.DOCUMENT_READ} path={AppRoutes.documents}>
          <Documents />
        </PrivateRoute>
      }
    />
    {/* Cutsheet Builder */}
    <Route
      path={AppRoutes.cutSheetBuilder}
      element={
        <PrivateRoute permission={[]} path={AppRoutes.cutSheetBuilder}>
          <CutSheetBuilder />
        </PrivateRoute>
      }
    />

    {/* View Cutsheet - Basic Details */}
    <Route
      path={AppRoutes.viewCutSheetBasicDetails()}
      element={
        <PrivateRoute permission={[]} path={AppRoutes.viewCutSheetBasicDetails()}>
          <ViewCutSheet currentPath={"Basic Details"} />
        </PrivateRoute>
      }
    />

    {/* View Cutsheet - Cutsheets */}
    <Route
      path={AppRoutes.viewCutSheets()}
      element={
        <PrivateRoute permission={[]} path={AppRoutes.viewCutSheets()}>
          <ViewCutSheet currentPath={"Cutsheets"} />
        </PrivateRoute>
      }
    />

    {/* User Module */}
    {/* UserManagement - User Module */}
    <Route
      path={AppRoutes.userManagement}
      element={
        <PrivateRoute permission={PERMISSIONS.USER_MODULE_MEM_READ} path={AppRoutes.userManagement}>
          <User />
        </PrivateRoute>
      }
    />

    {/* Dashboard Routes */}
    <Route
      path={AppRoutes.materialDashboard}
      element={
        <PrivateRoute permission={PERMISSIONS.MATERIAL_DASHBOARD} path={AppRoutes.materialDashboard}>
          <MaterialDashboard />
        </PrivateRoute>
      }
    />
    {/* Mat Dashboard Route */}
    <Route
      path={AppRoutes.docDashboard}
      element={
        <PrivateRoute permission={PERMISSIONS.DOCUMENT_DASHBOARD} path={AppRoutes.docDashboard}>
          <DocumentDashboard />
        </PrivateRoute>
      }
    />
    {/* Project Dashboard Route*/}
    <Route
      path={AppRoutes.projectDashboard}
      element={
        <PrivateRoute permission={PERMISSIONS.PROJECT_DASHBOARD} path={AppRoutes.projectDashboard}>
          <ProjectsDashboard />
        </PrivateRoute>
      }
    />
    {/* Proposal Dashboard Route*/}
    <Route
      path={AppRoutes.proposalsDashboard}
      element={
        <PrivateRoute permission={PERMISSIONS.PROPOSALS_DASHBOARD} path={AppRoutes.proposalsDashboard}>
          <ProposalDashboard />
        </PrivateRoute>
      }
    />
    {/* Product Dashboard Route*/}
    <Route
      path={AppRoutes.productDashboard}
      element={
        <PrivateRoute permission={PERMISSIONS.PRODUCT_DASHBOARD} path={AppRoutes.productDashboard}>
          <ProductsDashboard />
        </PrivateRoute>
      }
    />

    {/* View Track Project Issues with Issue Id */}
    <Route
      path={AppRoutes.viewIssueWithId()}
      element={
        <PrivateRoute permission={PERMISSIONS.QUALITY_READ} path={AppRoutes.viewIssueWithId()}>
          <ViewIssueTrackerWithId />
        </PrivateRoute>
      }
    />

    {/* All Check List are Listed here */}
    <Route
      path={AppRoutes.checkList}
      element={
        <PrivateRoute permission={PERMISSIONS.DOCUMENT_TEMPLATE_READ} path={AppRoutes.checkList}>
          <DocumentTemplate />
        </PrivateRoute>
      }
    />

    {/* View Check List with Id */}
    <Route
      path={AppRoutes.viewChecklistWithId()}
      element={
        <PrivateRoute permission={PERMISSIONS.DOCUMENT_TEMPLATE_READ} path={AppRoutes.viewChecklistWithId()}>
          <ViewCheckListWithId />
        </PrivateRoute>
      }
    />

    {/* All Data Sheets are Listed here */}
    <Route
      path={AppRoutes.dataSheetList}
      element={
        <PrivateRoute permission={PERMISSIONS.DOCUMENT_TEMPLATE_READ} path={AppRoutes.dataSheetList}>
          <DocumentTemplate />
        </PrivateRoute>
      }
    />

    {/* View Data Sheet */}
    <Route
      path={AppRoutes.viewDataSheetWithId()}
      element={
        <PrivateRoute permission={PERMISSIONS.DOCUMENT_TEMPLATE_READ} path={AppRoutes.viewDataSheetWithId()}>
          <ViewDataSheetWithId />
        </PrivateRoute>
      }
    />

    {/* PDF Annotator view */}
    <Route
      path={AppRoutes.annotateViewer()}
      element={
        <PrivateRoute permission={PERMISSIONS.DOCUMENT_READ} path={AppRoutes.annotateViewer()} hideNavbar>
          <PDFAnnotator />
        </PrivateRoute>
      }
    />
    <Route
      path={AppRoutes.proposalAnnotateViewer()}
      element={
        <PrivateRoute permission={PERMISSIONS.DOCUMENT_READ} path={AppRoutes.proposalAnnotateViewer()} hideNavbar>
          <PDFAnnotator />
        </PrivateRoute>
      }
    />
    <Route
      path={AppRoutes.projectAnnotateViewer()}
      element={
        <PrivateRoute permission={PERMISSIONS.DOCUMENT_READ} path={AppRoutes.projectAnnotateViewer()} hideNavbar>
          <PDFAnnotator />
        </PrivateRoute>
      }
    />
    <Route
      path={AppRoutes.productAnnotateViewer()}
      element={
        <PrivateRoute permission={PERMISSIONS.DOCUMENT_READ} path={AppRoutes.productAnnotateViewer()} hideNavbar>
          <PDFAnnotator />
        </PrivateRoute>
      }
    />
    <Route
      path={AppRoutes.bomDocumentAnnotateViewer()}
      element={
        <PrivateRoute permission={PERMISSIONS.DOCUMENT_READ} path={AppRoutes.bomDocumentAnnotateViewer()} hideNavbar>
          <PDFAnnotator />
        </PrivateRoute>
      }
    />
    <Route
      path={AppRoutes.materialDocumentAnnotateViewer()}
      element={
        <PrivateRoute permission={PERMISSIONS.DOCUMENT_READ} path={AppRoutes.materialDocumentAnnotateViewer()} hideNavbar>
          <PDFAnnotator />
        </PrivateRoute>
      }
    />
    <Route
      path={AppRoutes.nestedBOMDocumentAnnotateViewer()}
      element={
        <PrivateRoute permission={PERMISSIONS.DOCUMENT_READ} path={AppRoutes.nestedBOMDocumentAnnotateViewer()} hideNavbar>
          <PDFAnnotator />
        </PrivateRoute>
      }
    />
    <Route
      path={AppRoutes.nestedMaterialDocumentAnnotateViewer()}
      element={
        <PrivateRoute permission={PERMISSIONS.DOCUMENT_READ} path={AppRoutes.nestedMaterialDocumentAnnotateViewer()} hideNavbar>
          <PDFAnnotator />
        </PrivateRoute>
      }
    />

    {/* BOM Report */}
    <Route
      path={AppRoutes.bomReport}
      element={
        <PrivateRoute permission={PERMISSIONS.BOM_REPORT_READ} path={AppRoutes.bomReport}>
          <BOMReport />
        </PrivateRoute>
      }
    />
    <Route
      path={AppRoutes.viewBOMReportOnProductModule()}
      element={
        <PrivateRoute permission={PERMISSIONS.PRODUCT_READ} path={AppRoutes.viewBOMReportOnProductModule()}>
          <ViewProduct currentPath={"BOM Report"} />
        </PrivateRoute>
      }
    />
    <Route
      path={AppRoutes.viewBOMReportOnProjectModule()}
      element={
        <PrivateRoute permission={PERMISSIONS.PROJECT_READ} path={AppRoutes.viewBOMReportOnProjectModule()}>
          <ViewProjects currentPath={"BOM Report"} />
        </PrivateRoute>
      }
    />
    <Route
      path={AppRoutes.viewBOMReportOnProposalModule()}
      element={
        <PrivateRoute permission={PERMISSIONS.PROPOSAL_READ} path={AppRoutes.viewBOMReportOnProposalModule()}>
          <ViewProposal currentPath={"BOM Report"} />
        </PrivateRoute>
      }
    />
    <Route
      path={AppRoutes.viewBOMReport()}
      element={
        <PrivateRoute permission={PERMISSIONS.BOM_REPORT_READ} path={AppRoutes.viewBOMReport()}>
          <AddEditBOMReport />
        </PrivateRoute>
      }
    />
    <Route
      path={AppRoutes.viewIndividualBOMReportOnModule()}
      element={
        <PrivateRoute permission={PERMISSIONS.BOM_REPORT_READ} path={AppRoutes.viewIndividualBOMReportOnModule()}>
          <AddEditBOMReport />
        </PrivateRoute>
      }
    />

    {/* BOM Preview */}
    <Route
      path={AppRoutes.previewBOMReportOnModule()}
      element={
        <PrivateRoute permission={PERMISSIONS.BOM_REPORT_READ} path={AppRoutes.previewBOMReportOnModule()}>
          <BOMPreview />
        </PrivateRoute>
      }
    />
    <Route
      path={AppRoutes.previewBOMReport()}
      element={
        <PrivateRoute permission={PERMISSIONS.BOM_REPORT_READ} path={AppRoutes.previewBOMReport()}>
          <BOMPreview />
        </PrivateRoute>
      }
    />

    {/* List View */}
    <Route
      path={AppRoutes.viewListViewOnProductModule()}
      element={
        <PrivateRoute permission={PERMISSIONS.PROJECT_READ} path={AppRoutes.viewListViewOnProductModule()}>
          <ViewProduct currentPath={"List View"} />
        </PrivateRoute>
      }
    />
    <Route
      path={AppRoutes.viewListViewOnProjectModule()}
      element={
        <PrivateRoute permission={PERMISSIONS.PROJECT_READ} path={AppRoutes.viewListViewOnProjectModule()}>
          <ViewProjects currentPath={"List View"} />
        </PrivateRoute>
      }
    />
    <Route
      path={AppRoutes.viewListViewOnProposalModule()}
      element={
        <PrivateRoute permission={PERMISSIONS.PROPOSAL_READ} path={AppRoutes.viewListViewOnProposalModule()}>
          <ViewProposal currentPath={"List View"} />
        </PrivateRoute>
      }
    />
    <Route
      path={AppRoutes.viewIndividualListViewOnModule()}
      element={
        <PrivateRoute permission={PERMISSIONS.BOM_LIST_VIEW_READ} path={AppRoutes.viewIndividualListViewOnModule()}>
          <AddEditListView />
        </PrivateRoute>
      }
    />

    {/* List View Preview */}
    <Route
      path={AppRoutes.previewListViewOnModule()}
      element={
        <PrivateRoute permission={PERMISSIONS.BOM_LIST_VIEW_READ} path={AppRoutes.previewListViewOnModule()}>
          <ListViewPreview currentPath="Preview" />
        </PrivateRoute>
      }
    />

    {/* Draw.io Diagrams */}
    <Route
      path={AppRoutes.viewAllDrawioDiagrams}
      element={
        <PrivateRoute permission={PERMISSIONS.IEDRAW_READ} path={AppRoutes.viewAllDrawioDiagrams}>
          <DrawioDiagrams />
        </PrivateRoute>
      }
    />

    {/* Draw.io Diagram Editor */}
    <Route
      path={AppRoutes.drawioDiagramEditor()}
      element={<PrivateRoute hideNavbar={true} permission={PERMISSIONS.IEDRAW_READ} path={AppRoutes.drawioDiagramEditor()}>
        <DrawIOEditor />
      </PrivateRoute>}
    />

    {/* Draw.io Diagram Preview */}
    <Route
      path={AppRoutes.drawioDiagramPreview()}
      element={<PrivateRoute hideNavbar={true} permission={PERMISSIONS.IEDRAW_READ} path={AppRoutes.drawioDiagramPreview()}>
        <DrawIOPreview />
      </PrivateRoute>}
    />

    {/* Embed Ifram Component */}
    <Route
      path={AppRoutes.iframEmbedComponent()}
      element={<PrivateRoute hideNavbar={true} permission={PERMISSIONS.IEDRAW_READ} path={AppRoutes.drawioDiagramPreview()}>
        <EmbedComponent />
      </PrivateRoute>}
    />

    {/* Draw.io Diagram Templates */}
    <Route
      path={AppRoutes.viewAllDiagramTemplates}
      element={
        <PrivateRoute permission={PERMISSIONS.IEDRAW_TEMPLATE_READ} path={AppRoutes.viewAllDiagramTemplates}>
          <DrawioDiagramTemplates />
        </PrivateRoute>
      }
    />

    {/* WorkBook Routes */}
    <Route
      path={AppRoutes.materialWorkbook}
      element={
        <PrivateRoute permission={PERMISSIONS.WORKBOOK_READ} path={AppRoutes.materialWorkbook}>
          <Workbook />
        </PrivateRoute>
      }
    />

    <Route
      path={AppRoutes.workbookOnProductModule()}
      element={
        <PrivateRoute permission={PERMISSIONS.PRODUCT_READ} path={AppRoutes.workbookOnProductModule()}>
          <ViewProduct currentPath={"Workbook"} />
        </PrivateRoute>
      }
    />
    <Route
      path={AppRoutes.workbookOnProjectModule()}
      element={
        <PrivateRoute permission={PERMISSIONS.PROJECT_READ} path={AppRoutes.workbookOnProjectModule()}>
          <ViewProjects currentPath={"Workbook"} />
        </PrivateRoute>
      }
    />
    <Route
      path={AppRoutes.workbookOnProposalModule()}
      element={
        <PrivateRoute permission={PERMISSIONS.PROPOSAL_READ} path={AppRoutes.workbookOnProposalModule()}>
          <ViewProposal currentPath={"Workbook"} />
        </PrivateRoute>
      }
    />
    <Route
      path={AppRoutes.viewWorkbook()}
      element={
        <PrivateRoute permission={PERMISSIONS.WORKBOOK_READ} path={AppRoutes.viewWorkbook()}>
          <AddEditWorkBook />
        </PrivateRoute>
      }
    />
    <Route
      path={AppRoutes.viewWorkbookOnModule()}
      element={
        <PrivateRoute permission={PERMISSIONS.WORKBOOK_READ} path={AppRoutes.viewWorkbookOnModule()}>
          <AddEditWorkBook />
        </PrivateRoute>
      }
    />

    {/* Issue Tracker */}

    {/* All Issues are Listed here */}
    <Route
      path={AppRoutes.issuesList}
      element={
        <PrivateRoute permission={PERMISSIONS.QUALITY_READ} path={AppRoutes.issuesList}>
          <IssuesList currentPath={"Issue Tracker"} />
        </PrivateRoute>
      }
    />

    {/* Track Product Issues */}
    <Route
      path={AppRoutes.viewIssueTrackerOnProduct()}
      element={
        <PrivateRoute permission={PERMISSIONS.PRODUCT_READ} path={AppRoutes.viewIssueTrackerOnProduct()}>
          <ViewProduct currentPath={"Issue Tracker"} />
        </PrivateRoute>
      }
    />

    {/* Track Project Issues */}
    <Route
      path={AppRoutes.viewIssueTrackerOnProject()}
      element={
        <PrivateRoute permission={PERMISSIONS.PROJECT_READ} path={AppRoutes.viewIssueTrackerOnProject()}>
          <ViewProjects currentPath={"Issue Tracker"} />
        </PrivateRoute>
      }
    />

    {/* Track Proposal Issues */}
    <Route
      path={AppRoutes.viewIssueTrackerOnProposal()}
      element={
        <PrivateRoute permission={PERMISSIONS.PROPOSAL_READ} path={AppRoutes.viewIssueTrackerOnProposal()}>
          <ViewProposal currentPath={"Issue Tracker"} />
        </PrivateRoute>
      }
    />

    {/* View Issue */}
    <Route
      path={AppRoutes.viewIssueTrackerWithId()}
      element={
        <PrivateRoute permission={PERMISSIONS.QUALITY_READ} path={AppRoutes.viewIssueTrackerWithId()}>
          <ViewIssueTrackerWithId />
        </PrivateRoute>
      }
    />

    {/* BOM Details Preview */}

    {/* View BOM - BOM Details Preview */}
    <Route
      path={AppRoutes.viewBOMDetailsPreview()}
      element={
        <PrivateRoute permission={PERMISSIONS.BOM_READ} path={AppRoutes.viewBOMDetailsPreview()}>
          <ViewBOM currentPath={"BOM Details Preview"} />
        </PrivateRoute>
      }
    />

    {/* View BOM - Product Details Preview */}
    <Route
      path={AppRoutes.viewProductBOMDetailsPreview()}
      element={
        <PrivateRoute permission={PERMISSIONS.PRODUCT_READ} path={AppRoutes.viewProductBOMDetailsPreview()}>
          <ViewBOM customAssociation={{ object_type: "product", object_id: "" }} currentPath={"BOM Details Preview"} />
        </PrivateRoute>
      }
    />

    {/* View BOM - Project Details Preview */}
    <Route
      path={AppRoutes.viewProjectBOMDetailsPreview()}
      element={
        <PrivateRoute permission={PERMISSIONS.PROJECT_READ} path={AppRoutes.viewProjectBOMDetailsPreview()}>
          <ViewBOM customAssociation={{ object_type: "project", object_id: "" }} currentPath={"BOM Details Preview"} />
        </PrivateRoute>
      }
    />

    {/* Technical Parameters */}
    {/* All Technical Parameters are Listed here */}
    <Route
      path={AppRoutes.technicalParametersList}
      element={
        <PrivateRoute permission={PERMISSIONS.DOCUMENT_TEMPLATE_READ} path={AppRoutes.technicalParametersList}>
          <DocumentTemplate />
        </PrivateRoute>
      }
    />

    {/* View Technical Parameter */}
    <Route
      path={AppRoutes.viewTechnicalParametersWithId()}
      element={
        <PrivateRoute permission={PERMISSIONS.DOCUMENT_TEMPLATE_READ} path={AppRoutes.viewTechnicalParametersWithId()}>
          <ViewInputParameterWithId />
        </PrivateRoute>
      }
    />

    <Route
      path={AppRoutes.viewProductTechParamForm()}
      element={
        <PrivateRoute permission={PERMISSIONS.PRODUCT_READ} path={AppRoutes.viewProductTechParamForm()}>
          <ViewProduct currentPath={"Technical Parameters"} />
        </PrivateRoute>
      }
    />
    <Route
      path={AppRoutes.viewProjectTechParamForm()}
      element={
        <PrivateRoute permission={PERMISSIONS.PROJECT_READ} path={AppRoutes.viewProjectTechParamForm()}>
          <ViewProjects currentPath={"Technical Parameters"} />
        </PrivateRoute>
      }
    />
    <Route
      path={AppRoutes.viewProposalTechParamForm()}
      element={
        <PrivateRoute permission={PERMISSIONS.PROPOSAL_READ} path={AppRoutes.viewProposalTechParamForm()}>
          <ViewProposal currentPath={"Technical Parameters"} />
        </PrivateRoute>
      }
    />

    {/* User Management */}
    <Route
      path={AppRoutes.manageUsersOnProduct()}
      element={
        <PrivateRoute permission={PERMISSIONS.PRODUCT_READ} path={AppRoutes.manageUsersOnProduct()}>
          <ViewProduct currentPath={"Manage Users"} />
        </PrivateRoute>
      }
    />
    <Route
      path={AppRoutes.manageUsersOnProject()}
      element={
        <PrivateRoute permission={PERMISSIONS.PROJECT_READ} path={AppRoutes.manageUsersOnProject()}>
          <ViewProjects currentPath={"Manage Users"} />
        </PrivateRoute>
      }
    />
    <Route
      path={AppRoutes.manageUsersOnProposal()}
      element={
        <PrivateRoute permission={PERMISSIONS.PROPOSAL_READ} path={AppRoutes.manageUsersOnProposal()}>
          <ViewProposal currentPath={"Manage Users"} />
        </PrivateRoute>
      }
    />

    {/* Help & Support Routes */}
    <Route
      path={AppRoutes.tickets}
      element={
        <PrivateRoute permission={PERMISSIONS.PUBLIC} path={AppRoutes.tickets}>
          <Tickets />
        </PrivateRoute>
      }
    />

    {/* For unknown/non-defined path */}
    <Route path="*" element={<NotFound />} />
  </Route>))
  } />
};

export default App;