import React from "react";
import { CollapsableHeader } from "../header";
import {
  Box,
  Stack,
  Typography,
  TextField,
  Grid,
  useMediaQuery,
} from "@mui/material";
import { AntSwitch } from "../../antSwitch";
import { HorizontalPosition, VerticalPosition } from "../../../utils";
import { SelectBoxOption } from "../../../interfaces";
import { HighlightSelectBox } from "../../highLightSelectBox";
import {
  Alignment,
  NotesError,
  notesTemplate,
} from "../../../redux/slices/documentTemplate";

export interface NotesComponent {
  placeholder: string;
  note: string;
  onNoteChange: (note: string) => void;
  status: boolean;
  onStatusChange: (isChecked: boolean) => void;
  xPosition: SelectBoxOption | null;
  yPosition: SelectBoxOption | null;
  onPositionChange: (
    position: SelectBoxOption,
    key: keyof notesTemplate
  ) => void;
}

export const Notes: React.FC<{
  title: string;
  showSwitch?: boolean;
  switchStatus?: boolean;
  onSwitchChanged?: (isChecked: boolean) => void;
  notes: NotesComponent[];
  hidePosition?: boolean;
  error: NotesError[];
}> = (props) => {
  const { error } = props;

  const isMatch = useMediaQuery("(max-width:1530px)");

  return (
    <CollapsableHeader
      title={props.title}
      showIcon={true}
      isExpanded={true}
      showSwitch={props.showSwitch}
      switchStatus={props.switchStatus}
      onSwitchChanged={props.onSwitchChanged}
    >
      <Box p={1}>
        <Stack spacing={1}>
          {props.notes?.map((note, index) => {
            return (
              <Stack key={index} spacing={1}>
                <Stack
                  direction={"row"}
                  spacing={1}
                  alignItems={"center"}
                  justifyContent={"flex-start"}
                >
                  <Typography variant="body1" fontFamily={"htrts_medium"}>
                    {note.placeholder}
                  </Typography>
                  <AntSwitch
                    sx={{ ml: 2 }}
                    size="small"
                    color="primary"
                    checked={note.status}
                    onChange={(e, isChecked) => note.onStatusChange(isChecked)}
                  />
                </Stack>

                <TextField
                  variant="outlined"
                  placeholder="Enter Note"
                  size="small"
                  multiline
                  rows={3}
                  onChange={(e) => note.onNoteChange(e.target.value)}
                  value={note.note}
                  error={error[index]?.contentError || false}
                  helperText={
                    error[index]?.contentError || false ? "Please enter the note" : ""
                  }
                />

                {!props.hidePosition && (
                  <Grid
                    container
                    columnGap={isMatch ? 0 : 1}
                    justifyContent={"space-between"}
                    columns={isMatch ? 12 : 13}
                  >
                    <Grid item xs={12} sm={12} md={12} xl={6}>
                      <HighlightSelectBox
                        options={HorizontalPosition}
                        label={"Horizontal Position"}
                        onChange={(position: SelectBoxOption) =>
                          note.onPositionChange(position, "xPosition")
                        }
                        value={note.xPosition}
                        error={error[index]?.xPositionError}
                        helperText={
                          error[index]?.xPositionError
                            ? "Please select the note horizontal position"
                            : ""
                        }
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} xl={6}>
                      <HighlightSelectBox
                        options={VerticalPosition}
                        label={"Vertical Position"}
                        onChange={(position: SelectBoxOption) =>
                          note.onPositionChange(position, "yPosition")
                        }
                        value={note.yPosition}
                        error={error[index]?.yPositionError}
                        helperText={
                          error[index]?.yPositionError
                            ? "Please select the note vertical position"
                            : ""
                        }
                      />
                    </Grid>
                  </Grid>
                )}
              </Stack>
            );
          })}
        </Stack>
      </Box>
    </CollapsableHeader>
  );
};

export interface SideNoteComponent {
  placeholder: string;
  note: string;
  alignment: Alignment;
  onNoteChange: (note: string) => void;
  onPositionChange: (position: SelectBoxOption, key: keyof Alignment) => void;
}

export const SideNote: React.FC<{
  title: string;
  showSwitch?: boolean;
  switchStatus?: boolean;
  onSwitchChanged?: (isChecked: boolean) => void;
  sideNote: SideNoteComponent;
  hidePosition?: boolean;
  error: NotesError;
}> = (props) => {
  const isMatch = useMediaQuery("(max-width:1530px)");

  const { alignment } = props.sideNote;

  return (
    <CollapsableHeader
      title={props.title}
      showIcon={true}
      isExpanded={true}
      showSwitch={props.showSwitch}
      switchStatus={props.switchStatus}
      onSwitchChanged={props.onSwitchChanged}
    >
      <Box p={1}>
        <Stack spacing={1}>
          <TextField
            variant="outlined"
            size="small"
            multiline
            rows={3}
            placeholder={props.sideNote.placeholder || "Enter Note"}
            onChange={(e) => props.sideNote.onNoteChange(e.target.value)}
            value={props.sideNote.note}
            error={props.error.contentError}
            helperText={props.error.contentError ? "Please enter the note" : ""}
          />

          <Grid
            container
            columnGap={isMatch ? 0 : 1}
            justifyContent={"space-between"}
            columns={isMatch ? 12 : 13}
          >
            <Grid item xs={12} sm={12} md={12} xl={6}>
              <HighlightSelectBox
                options={HorizontalPosition}
                label={"Horizontal Position"}
                onChange={(position: SelectBoxOption) =>
                  props.sideNote.onPositionChange(position, "horizontal")
                }
                value={alignment.horizontal}
                error={props.error.xPositionError}
                helperText={
                  props.error.xPositionError
                    ? "Please select the horizontal position"
                    : ""
                }
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} xl={6}>
              <HighlightSelectBox
                options={VerticalPosition}
                label={"Vertical Position"}
                onChange={(position: SelectBoxOption) =>
                  props.sideNote.onPositionChange(position, "vertical")
                }
                value={alignment.vertical}
                error={props.error.yPositionError}
                helperText={
                  props.error.yPositionError
                    ? "Please select the vertical position"
                    : ""
                }
              />
            </Grid>
          </Grid>
        </Stack>
      </Box>
    </CollapsableHeader>
  );
};
