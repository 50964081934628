import { createApi, fetchBaseQuery, retry } from '@reduxjs/toolkit/query/react';
import { config } from '../../../config';
import { LocalStorageKeys } from '../../../utils';
import { Moment } from 'moment';

export interface ExchangeRate {
    currency_code: string;
    manual_rate: number;
    market_rate: number;
}

export interface ExchangeData {
    base_currency: string;
    current_date: Moment | null;
    exchange_date: Moment | null;
    exchange_rates_auto: ExchangeRate[];
    exchange_rates_manual: ExchangeRate[];
    manual_rate: boolean;
    exchange_rates: ExchangeRate[];
}

export const exchangeRatesAPI = createApi({
    reducerPath: "exchangeRatesAPI",
    baseQuery: retry(fetchBaseQuery({
        baseUrl: config.api_url + "mm/currencies/exchangerates",
        prepareHeaders: (headers, { getState }) => {
            const token = localStorage.getItem(LocalStorageKeys.authToken);

            // If we have a token set in state, let's assume that we should be passing it.
            if (token) {
                headers.set('authorization', `Bearer ${token}`)
            }

            return headers
        },
    }), { maxRetries: 1 }),
    tagTypes: ['ExchangeRates'],
    endpoints: (builder) => ({
        // Get Exchange Rates API
        getExchangeRates: builder.query({
            query: ({ date, base_currency }: { date: string, base_currency: string }) => ({ url: `?date=${date}&base_currency=${base_currency}`, method: "GET" })
        }),

        // Update Exchange Rates API
        updateExchangeRates: builder.mutation({
            query: ({ id, exchangerates }: { id: string | number, exchangerates: ExchangeData }) => ({ url: `${id}/costing`, method: "POST", body: { exchangerates } }),
        })
    })
})

// Export hooks for usage in functional components
export const {
    useGetExchangeRatesQuery, useLazyGetExchangeRatesQuery, useUpdateExchangeRatesMutation
} = exchangeRatesAPI
