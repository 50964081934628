import { Alert, Box, Theme, useTheme } from "@mui/material";
import moment from "moment";
import { useSnackbar } from "notistack";
import React from "react";
import { CustomDataGrid, DeletePanel, TitleHeader } from "../../../components";
import { AddEditDocument } from "../../../components/documents/addEditDocument";
import { giveMeLocationAndDocumentTags } from "../../../components/documents/utils";
import { useAppDispatch, useAppSelector } from "../../../redux";
import { useDeleteMaterialDocumentMutation, useDuplicateDocumentMutation, useLazyGetDocumentsQuery, useUpdateDocumentEditStatusMutation } from "../../../redux/services";
import { closeBackdrop, openBackdrop } from "../../../redux/slices/backdrop";
import { closeConfirmationDialog, openConfirmationDialog } from "../../../redux/slices/confirmationDialog";
import { closeDialog, openDialog } from "../../../redux/slices/dialog";
import { convertSize } from "../../../utils";
import { DependencyChildren, PublishComponent } from "../../materials/dashboard";
import { DashboardTableColumn, getEmptyDrawioDiagram } from "../utils";
import { useNavigate } from "react-router-dom";
import { AppRoutes } from "../../../router/routes";
import { HasAccess } from "../../../router/authorization";
import { PERMISSIONS } from "../../../router/permission";
import { versionDropdownOption } from "../../documents/documents";
import { SelectBoxOption } from "../../../interfaces";
import { GridSortDirection, GridSortModel } from "@mui/x-data-grid-premium";

const useStyles = (theme: Theme) => ({
    root: {
        flexGrow: 1,
        background: theme.palette.background.paper,
        boxShadow: `rgba(99, 99, 99, 0.2) 0px 2px 8px 0px`,
        height: "100%",
        overflow: "scroll",
        borderRadius: theme.spacing(1),
        padding: theme.spacing(2),
    },
    dataGridParent: {
        height: "calc(100% - 40px)",
        overflow: "scroll",
        marginTop: theme.spacing(2),
    }
});

export const Dashboard: React.FC<{ children?: JSX.Element }> = (props) => {

    const classes = useStyles(useTheme());
    const dispatch = useAppDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();

    const [getDiagramTemplates, { data: allData = { data: [], totalCount: 0 }, isError, ...rest }] = useLazyGetDocumentsQuery();
    const { data, totalCount } = allData;
    const [deleteDiagramTemplate] = useDeleteMaterialDocumentMutation();
    const [updateDocumentEditStatus] = useUpdateDocumentEditStatusMutation();
    const [duplicateDocument] = useDuplicateDocumentMutation();

    const [paginationModel, setPaginationModel] = React.useState({ page: 0, pageSize: 10 });
    const [search, setSearch] = React.useState("");
    const [dropDown, updateDropDown] = React.useState<SelectBoxOption>(versionDropdownOption[0]);
    const [queryOptions, setQueryOptions] = React.useState({
        sortModel: [{ field: 'sequence_id', sort: 'desc' as GridSortDirection }],
    });

    const { perm } = useAppSelector(store => store.auth.userDetails);

    const hasAccess = React.useMemo(() => HasAccess(perm, PERMISSIONS.IEDRAW_TEMPLATE_MASTER), [perm]);

    const isFetching = rest.isFetching || rest.isLoading;

    const deleteDiagramTemplateHandler = (diagramRes: any) => {
        dispatch(
            openConfirmationDialog({
                title: "Are you sure?",
                body: `Do you want to delete the drawing ${diagramRes.sequence_id}?`,
                positiveBtn: "Delete",
                negativeBtn: "Cancel",
                onOk: () => performRowDel(diagramRes),
                onNegativeBtn: () => dispatch(closeConfirmationDialog()),
            })
        );
    }

    const performRowDel = async (diagramRes: any) => {
        dispatch(openBackdrop("Deleting Drawing Template..."));
        let res: any = {};
        try {
            res = await deleteDiagramTemplate({ id: diagramRes._id, force: true })

            if (Object.keys(res).includes("data")) {
                enqueueSnackbar(`Deleted Drawing Template Successfully!`, { variant: "success" })
                dispatch(closeConfirmationDialog());
                refetch();
            } else if (Object.keys(res).includes("error")) {
                // eslint-disable-next-line 
                throw res.error.data;
            } else {
                // eslint-disable-next-line 
                throw "Data not found"
            }
        } catch (error: any) {
            let errorMessage: string = error?.title ?? "Oops! Something went wrong, Unable to delete"
            enqueueSnackbar(errorMessage, { variant: "error" });
        } finally {
            dispatch(closeBackdrop());
        }
    }

    const giveMeRows = (): any[] => {
        if (!rest.isFetching && data) {
            if (search.trim().length) {
                return data?.filter((_: any) => (_?.sequence_id?.toLowerCase()?.includes(search.toLowerCase())
                    || _?.file_name?.toLowerCase()?.includes(search.toLowerCase())
                    || (giveMeLocationAndDocumentTags(_?.tags_info).document_tags)?.label?.toLowerCase()?.includes(search.toLowerCase())
                    || convertSize(_?.size).toString()?.toLowerCase()?.includes(search.toLowerCase())
                    || _?.created_user?.toString()?.toLowerCase()?.includes(search.toLowerCase())
                    || moment(_.created_time).format('lll').toString().includes(search.toLowerCase())
                )) ?? []
            }
            return data;
        } else {
            return [];
        }
    }

    const addEditDiagram = async (data?: any) => {
        const isEdit = data ? true : false;
        const defaultFile = await getEmptyDrawioDiagram();
        if (hasAccess) {
            dispatch(openDialog({
                title: "",
                hideNegativeBtn: true,
                hidePositiveBtn: true,
                enablePadding: false,
                body: <AddEditDocument
                    isEdit={isEdit}
                    data={data}
                    refetch={refetch}
                    Id={""} _id={""} objectType={""}
                    hideUpload={true}
                    showFileName={true}
                    showModuleMapper={true}
                    documentMode="Drawing Template"
                    defaultFile={defaultFile}
                    defaultFileType={".xml"}
                />,
            }))
        }
    }

    const openAssociations = (document: any) => {
        dispatch(openConfirmationDialog({
            title: "Associations",
            body: <DeletePanel
                message={document?.associations ? document.associations.length === 0 ? 'No dependency found' : `Dependency Table of ${document?.sequence_id}-r${document.version}` : "No dependency found"}
                errorMessageStacks={document?.associations ?? []}
            />,
            hideNegativeBtn: true,
            hidePositiveBtn: true
        }))
    }

    const updatePublishStatus = (row: any) => {
        dispatch(
            openConfirmationDialog({
                title: "Publish Operation",
                body: <DeletePanel
                    message={`Are you sure you want to publish the document ${row?.sequence_id}?`}
                    warningMessage={`You will not be able to edit this revision after publishing. You can however create new revisions.`}
                />,
                positiveBtn: "Check for dependencies",
                negativeBtn: "Cancel",
                onOk: () => openDependencyTable(row),
                onNegativeBtn: () => dispatch(closeConfirmationDialog())
            })
        );
    };

    const openDependencyTable = (row: any) => {
        dispatch(closeConfirmationDialog())
        try {
            dispatch(openDialog({
                title: "",
                body: <DependencyChildren object_id={row?._id} object_type="document" onPublish={() => proceedToPublish(row)} />,
                hideNegativeBtn: true, hidePositiveBtn: true, enablePadding: false
            }));
        } catch (error) {
            console.error(`Unable to get the hierarchy: ${error}`);
            enqueueSnackbar("Error getting dependencies", { variant: "error" })
            dispatch(closeDialog())
        }
    };

    const proceedToPublish = (row: any) => {
        dispatch(openDialog({
            title: "",
            body: <PublishComponent {...row} refetch={refetch} object_type="document" object_id={row?._id} />,
            hidePositiveBtn: true,
            hideNegativeBtn: true,
            enablePadding: false
        }));
    };

    const handleSortModelChange = React.useCallback((sortModel: GridSortModel) => {
        // Here you save the data you need from the sort model
        setQueryOptions({ sortModel: [...sortModel] });
    }, [])

    const refetch = () => {
        getDiagramTemplates({ 
            ...paginationModel, 
            include_history: dropDown?.value ?? false, 
            document_type: 'drawing_template',
            sort_by: queryOptions?.sortModel?.[0]?.field, 
            sort_order: queryOptions?.sortModel?.[0]?.sort
        });
    };

    const forceRelease = async (document: any) => {
        dispatch(openConfirmationDialog({
            title: 'Are you sure?',
            body: "Do you want to force release this diagram, that is locked by '" + document.activity?.user_name + "'?",
            hideNegativeBtn: true,
            positiveBtn: "Release",
            onOk: async () => {
                dispatch(closeConfirmationDialog());
                dispatch(openBackdrop('Releasing the diagram...'));
                await updateDocumentEditStatus({ documentId: document._id ?? "", activityType: 'close' }).then(() => {
                    navigate(AppRoutes.drawioDiagramEditor(document._id));
                });
                dispatch(closeBackdrop());
            }
        }))
    };

    const handleDuplicate = (data: any) => {
        let message = `Do you want to create a copy of this "${data.sequence_id}" drawing template?`;
        dispatch(
            openConfirmationDialog({
                title: "Are you sure?",
                body: message,
                positiveBtn: "Duplicate",
                negativeBtn: "Cancel",
                onOk: () => performDuplicate(data),
                onNegativeBtn: () => dispatch(closeConfirmationDialog()),
            })
        );
    };

    const performDuplicate = async (data: any) => {
        dispatch(openBackdrop(`Creating copy of "${data.sequence_id}" drawing template....`));
        let res: any = {};
        try {
            res = await duplicateDocument(data?._id);
            if (Object.keys(res).includes("data")) {
                enqueueSnackbar(
                    res?.data?.message,
                    { variant: "success" }
                );
                dispatch(dispatch(
                    openConfirmationDialog({
                        title: "Duplicated Document",
                        body: `The duplicated document can be found under "Documents Module/Documents." Click "OK" to navigate there.`,
                        positiveBtn: "Ok",
                        negativeBtn: "Cancel",
                        onOk: () => {
                            const formattedTags = giveMeLocationAndDocumentTags(data?.tags_info);
                            dispatch(closeConfirmationDialog())
                            navigate(`${AppRoutes.documents}?id=${formattedTags?.location_tags?.value}&folder=${formattedTags?.location_tags?.label}&group_id=${formattedTags?.location_tags?.tag_group_id}&tab=folder`)
                        },
                        onNegativeBtn: () => {
                            dispatch(closeConfirmationDialog())
                            refetch();
                        },
                    })
                ))
            } else if (Object.keys(res).includes("error")) {
                // eslint-disable-next-line
                throw "Unable to create copy";
            } else {
                // eslint-disable-next-line
                throw "Data not found";
            }
        } catch (error) {
            let errorMessage: string = `Unable to create copy of ${data.sequence_id} drawing template!`;
            enqueueSnackbar(errorMessage, { variant: "error" });
        } finally {
            dispatch(closeBackdrop());
        }
    };


    const ROWS = giveMeRows();

    React.useEffect(() => {
        refetch();
        // eslint-disable-next-line
    }, [paginationModel, dropDown, JSON.stringify(queryOptions?.sortModel)])

    return <Box sx={classes.root}>
        {/* Header */}
        <TitleHeader
            title="Drawing Templates"
            count={allData.totalCount}
            showSearch={true}
            search={search}
            onSearchChange={setSearch}
            searchPlaceholder={"Search for Drawing Templates (only within this page)"}
            showCreateBtn={hasAccess}
            createBtnLabel={"Create Drawing Template"}
            onCreateBtn={() => addEditDiagram()}
            showDropDown={true}
            onDropDownChange={(data: SelectBoxOption) => updateDropDown(data)}
            dropDownLabel="Revision"
            dropDownOptions={versionDropdownOption}
            dropDownValue={dropDown}
        />

        {/* Error */}
        {isError && <Alert sx={{ mt: 2 }} severity="error">Oops! Something went wrong, Unable to fetch Drawing Templates. Try Again Later!</Alert>}

        {/* Data Grid */}
        <Box mt={2}>
            <CustomDataGrid
                id="drawio-template-list"
                // initialState={{
                //     sorting: {
                //         sortModel: [{ field: 'sequence_id', sort: 'desc' }],
                //     },
                // }}
                rows={ROWS}
                columns={DashboardTableColumn(deleteDiagramTemplateHandler, addEditDiagram, openAssociations, updatePublishStatus, hasAccess, forceRelease, hasAccess ? handleDuplicate : undefined)}
                loading={isFetching}
                getRowId={(row) => row._id}
                showToolbar={true}
                rowCount={totalCount}
                paginationModel={paginationModel}
                paginationMode="server"
                onPaginationModelChange={setPaginationModel}
                sortingMode="server"
                onSortModelChange={handleSortModelChange}
            />
        </Box>
    </Box>
}