import { Alert, Box, Button, DialogActions, DialogContent, DialogTitle, Grid, Theme, Typography, useTheme } from "@mui/material";
import moment from "moment";
import { useSnackbar } from "notistack";
import React from "react";
import { useNavigate } from "react-router-dom";
import { CustomDataGrid, DeletePanel, type FilterStateInterface, History, TitleHeader, TitleHeaderProps, Filters, RowRadioButtonsGroup } from "../../../components";
import { SelectBoxOption } from "../../../interfaces";
import { useAppDispatch, useAppSelector } from "../../../redux";
import { useDeleteBOMMutation, useDuplicateBOMMutation, useLazyGetBOMsBySearchQuery, useLazyGetBOMsQuery } from "../../../redux/services";
import { closeBackdrop, openBackdrop } from "../../../redux/slices/backdrop";
import { closeConfirmationDialog, openConfirmationDialog, updateConfirmationDialogPositiveBtn, updateCongirmationDialogBody } from "../../../redux/slices/confirmationDialog";
import { closeDrawer, openDrawer } from "../../../redux/slices/drawer";
import { AppRoutes } from "../../../router/routes";
import { DashboardTableColumn, DuplicateOptions } from "../utils";
import { GridRowParams, GridSortDirection, GridSortModel } from "@mui/x-data-grid-premium";
import { HasAccess } from "../../../router/authorization";
import { PERMISSIONS } from "../../../router/permission";
import { closeDialog, openDialog } from "../../../redux/slices/dialog";
import { DependencyChildren, PublishComponent } from "../../materials/dashboard";

const useStyles = (theme: Theme) => ({
    root: {
        flexGrow: 1,
        background: theme.palette.background.paper,
        boxShadow: `rgba(99, 99, 99, 0.2) 0px 2px 8px 0px`,
        height: "100%",
        overflow: "scroll",
        borderRadius: theme.spacing(1),
        padding: theme.spacing(2),
    },
    dataGridParent: {
        height: "calc(100% - 40px)",
        overflow: "scroll",
        marginTop: theme.spacing(2),
    }
});

const dropDownOptions = [{ value: "false", label: "Latest Only" }, { value: "true", label: "All" }]

const DuplicateTypeSelection = ({ onSuccess }: { onSuccess: (value: string) => void }) => {
    const dispatch = useAppDispatch();

    const [duplicateType, setDuplicateType] = React.useState(DuplicateOptions[0].value);

    return (
        <>
            <DialogTitle>
                <Typography
                    variant="body1"
                    fontFamily={"htrts_medium"}
                    sx={{
                        borderBottom: (theme: Theme) => `1px solid ${theme.palette.divider}`,
                        pb: 1,
                    }}
                >
                    Select BOM Duplication Option
                </Typography>
            </DialogTitle>

            <DialogContent>
                <RowRadioButtonsGroup
                    label=""
                    value={duplicateType}
                    onChange={(e: any) => {
                        setDuplicateType(e?.target?.value)
                    }}
                    options={DuplicateOptions}
                />
            </DialogContent>

            <DialogActions>
                <Grid container justifyContent={"center"} alignItems={"center"} spacing={2} px={2} pb={1} pt={4}>
                    {/* Cancel Btn */}
                    <Grid item xs={12} sm={6} md={6} lg={4}>
                        <Button
                            fullWidth
                            variant="outlined"
                            onClick={() => dispatch(closeDialog())}
                        >
                            Cancel
                        </Button>
                    </Grid>

                    {/* Next Btn */}
                    <Grid item xs={12} sm={6} md={6} lg={4}>
                        <Button
                            fullWidth
                            variant="contained"
                            onClick={() => onSuccess(duplicateType)}
                        >
                            Duplicate
                        </Button>
                    </Grid>
                </Grid>
            </DialogActions>
        </>
    );
}

export const Dashboard: React.FC<{
    children?: JSX.Element, titleHeaderProps?: TitleHeaderProps, checkboxSelection?: boolean, onCheckBoxSelected?: any,
    objectType?: string, objectId?: string, onEdit?: any, useRowSelection?: boolean, rowSelectionObjectId?: string, rowSelectionObjectType?: string,
    creatable?: boolean, updatable?: boolean, deletetable?: boolean, publishable?: boolean, readable?: boolean, isNested?: boolean
}> = (props) => {

    const classes = useStyles(useTheme());
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { perm } = useAppSelector(store => store.auth.userDetails);
    const [getBOMs, { data: allData = { data: [], totalCount: 0 }, isFetching, isError, isLoading }] = useLazyGetBOMsQuery();
    const { data, totalCount } = allData;

    const [getBOMsBySearch, { data: bomsFilteredData, isFetching: bomsIsFetching, isError: filterHasError }] = useLazyGetBOMsBySearchQuery();

    const loading = isLoading || isFetching || bomsIsFetching;

    const [paginationModel, setPaginationModel] = React.useState({
        page: 0,
        pageSize: 10,
    });

    const [duplicateBOM] = useDuplicateBOMMutation();
    const [deleteBOMMutation] = useDeleteBOMMutation();
    const { enqueueSnackbar } = useSnackbar();

    const [search, setSearch] = React.useState("");
    const [dropDown, updateDropDown] = React.useState<SelectBoxOption>(dropDownOptions[0]);
    const [moreFilters, updateMoreFilers] = React.useState<FilterStateInterface>({});
    const [searchByKeyword, setSearchByKeyword] = React.useState("");
    const [queryOptions, setQueryOptions] = React.useState({
        sortModel: [{ field: 'sequence_id', sort: 'desc' as GridSortDirection }],
    });

    const creatable = React.useMemo(() => {
        let primaryAccess = HasAccess(perm, PERMISSIONS.BOM_CREATE);
        if (props.isNested) {
            return props.creatable && primaryAccess;
        }
        return primaryAccess;
    }, [perm, props.isNested, props.creatable]);

    const deletable = React.useMemo(() => {
        let primaryAccess = HasAccess(perm, PERMISSIONS.BOM_DELETE);
        if (props.isNested) {
            return props.deletetable && primaryAccess;
        }
        return primaryAccess;
    }, [perm, props.isNested, props.deletetable]);

    const publishable = React.useMemo(() => {
        let primaryAccess = HasAccess(perm, PERMISSIONS.BOM_PUBLISH);
        if (props.isNested) {
            return props.publishable && primaryAccess;
        }
        return primaryAccess;
    }, [perm, props.isNested, props.publishable]);

    const viewBOM = (data: any, isEdit: boolean = true) => {
        navigate(AppRoutes.viewBomBasicDetail(isEdit ? data._id : "New"))
    }

    const deleteBOM = (bom: any) => {
        let message = `Check for dependencies before deleting the BOM ${bom.sequence_id}-r${bom.version}`;
        dispatch(openConfirmationDialog({
            title: "Delete Operation",
            body: <DeletePanel
                message={message}
                warningMessage={""}
            />,
            positiveBtn: "Check for dependencies",
            negativeBtn: "Cancel",
            onOk: () => openDependency(bom),
            onNegativeBtn: () => dispatch(closeConfirmationDialog())
        }))
    }

    const openDependency = (bom: any) => {
        dispatch(updateCongirmationDialogBody(<DeletePanel
            message={bom.associations ? bom.associations.length === 0 ? 'No dependency found' : `Dependency Table of ${bom?.sequence_id}-r${bom.version}` : "No dependency found"}
            errorMessage={""}
            errorMessageStacks={bom?.associations ?? []}
            warningMessage={""}
        />))
        dispatch(updateConfirmationDialogPositiveBtn({
            positiveBtn: "Force Delete",
            onOk: () => {
                dispatch(updateCongirmationDialogBody(<DeletePanel
                    message={"Are you sure want to force delete the BOM " + bom?.sequence_id + "-r" + bom?.version + "?"}
                    warningMessage={"Warning: After delete its not possible to retrieve"}
                />))
                dispatch(updateConfirmationDialogPositiveBtn({
                    positiveBtn: "Ok",
                    onOk: () => performRowDel(bom, "", true)
                }))
            },
        }))
    }

    const performRowDel = async (bom: any, message: string, force: boolean = false) => {
        dispatch(openBackdrop("Deleting BOM..."));
        let res: any = {};
        try {
            res = await deleteBOMMutation({ id: bom._id, force })

            if (Object.keys(res).includes("data")) {
                enqueueSnackbar(`Deleted BOM Successfully!`, { variant: "success" })
                dispatch(closeConfirmationDialog());
                refetch();
            } else if (Object.keys(res).includes("error")) {
                // eslint-disable-next-line 
                throw res.error.data;
            } else {
                // eslint-disable-next-line 
                throw "Data not found"
            }
        } catch (error: any) {
            let errorMessage: string = error?.title ?? "Opps! Something went wrong, Unable to delete"
            enqueueSnackbar(errorMessage, { variant: "error" });
        } finally {
            dispatch(closeBackdrop());
        }
    }

    const giveMeRows = () => {
        if (search.trim().length) {
            let searchable = searchByKeyword ? bomsFilteredData?.data ?? [] : data ?? [];
            return searchable?.filter((_: any) => (_?.sequence_id?.toLowerCase()?.includes(search.toLowerCase())
                || _?.short_description?.toLowerCase()?.includes(search.toLowerCase())
                || _?.long_description?.toLowerCase()?.includes(search.toLowerCase())
                || _?.group_name?.toLowerCase()?.includes(search.toLowerCase())
                || _?.noun_name?.toLowerCase()?.includes(search.toLowerCase())
                || _?.external_ref_id?.toLowerCase().includes(search.toLowerCase())
                || _?.created_user?.toLowerCase().includes(search.toLowerCase())
                || moment(_.created_time).format('lll').toString().includes(search.toLowerCase())
            )) ?? []
        } else if (!isFetching && data && Object.keys(moreFilters).length <= 0 && !searchByKeyword) {
            return data ?? [];
        } else if (!bomsIsFetching && bomsFilteredData) {
            return bomsFilteredData?.data ?? [];
        } else {
            return [];
        }
    }


    const openRevision = (data: any) => {
        if (false) {
            dispatch(openDrawer({
                title: "BOM History",
                btnName: "Close",
                component: <History variant="material" document_id={data.document_id} />,
                onSave: () => dispatch(closeDrawer())
            }));
        }
    }

    const view = (data: any) => {
        if (props.onEdit) {
            props.onEdit(data._id);
        } else {
            navigate(AppRoutes.viewBomBasicDetail(data._id));
        }
    }

    const openAssociation = (bom: any) => {
        dispatch(openConfirmationDialog({
            title: "Associations",
            body: <DeletePanel
                message={bom?.associations ? bom.associations.length === 0 ? 'No dependency found' : `Dependency Table of ${bom?.sequence_id}-r${bom.version}` : "No dependency found"}
                errorMessageStacks={bom?.associations ?? []}
            />,
            hideNegativeBtn: true,
            hidePositiveBtn: true
        }))
    }

    const openReferences = (bom: any) => {
        dispatch(openConfirmationDialog({
            title: "References",
            body: <DeletePanel
                message={bom?.references ? bom.references.length === 0 ? 'No references found' : `References Table of ${bom?.sequence_id}-r${bom.version}` : "No references found"}
                errorMessageStacks={bom?.references ?? []}
            />,
            hideNegativeBtn: true,
            hidePositiveBtn: true
        }))
    }

    const showTechnicalCard = (bom: any) => {
        dispatch(openConfirmationDialog({
            title: `Technical Card: ${bom.sequence_id}-r${bom.version}`,
            body: <Box sx={{ width: "500px" }}>
                <table>
                    <tbody>
                        <tr>
                            <td>
                                <Typography variant="body2" sx={{ fontWeight: "bold" }} gutterBottom >{`Noun Name`}</Typography>
                            </td>
                            <td>
                                <Typography variant="body2" gutterBottom >{`: ${bom.noun_name}`}</Typography>
                            </td>
                        </tr>
                        {bom?.attribute_details?.map((_: any) => {
                            return <tr>
                                <td>
                                    <Typography variant="body2" sx={{ fontWeight: "bold" }} gutterBottom >{`${_.attr_name}`}</Typography>
                                </td>
                                <td>
                                    <Typography variant="body2" gutterBottom >{`: ${_.option_name}`}</Typography>
                                </td>
                            </tr>
                        })}
                    </tbody>
                </table>

            </Box>,
            hideNegativeBtn: true,
            hidePositiveBtn: true
        }))
    }

    let ROWS = giveMeRows();

    const refetch = () => {
        getBOMs({ 
            include_histories: dropDown?.value, 
            object_type: props.objectType, 
            object_id: props.objectId, 
            sort_by: queryOptions?.sortModel?.[0]?.field, 
            sort_order: queryOptions?.sortModel?.[0]?.sort, 
            ...paginationModel 
        })
    }

    const isRowSelectable = (row: any) => {

        if (!row.associations || !props.useRowSelection) {
            return true;
        }

        let isSelectable = true;

        try {
            for (let index = 0; index < row?.associations?.length; index++) {
                const asso = row?.associations?.[index];
                isSelectable = !(asso.object_type === props.rowSelectionObjectType && asso.object_details.map((_: any) => _._id).includes(props.rowSelectionObjectId));
                if (!isSelectable) {
                    break;
                }
            }
        } catch (error) {
            console.error("error", error);
        }

        return isSelectable;
    }

    const applyFilter = (data: FilterStateInterface) => {
        updateMoreFilers({ ...moreFilters, ...data });
        dispatch(closeDrawer());
    }

    const cancelFilter = () => {
        updateMoreFilers({});
        dispatch(closeDrawer());
    }

    const onReset = () => {
        updateMoreFilers({});
        setSearchByKeyword("");
    }

    const openMoreFilter = () => {
        dispatch(openDrawer({
            isLarge: true,
            component: <Filters showNoun showNounCard searchByKeyword={searchByKeyword} appliedFilters={moreFilters} onApply={applyFilter} onCancel={cancelFilter} onFind={(val) => {
                setSearchByKeyword(val)
                dispatch(closeDrawer());
            }} onReset={onReset} />
        }))
    }

    const updatePublishStatus = (row: any) => {
        dispatch(
            openConfirmationDialog({
                title: "Publish Operation",
                body: <DeletePanel
                    isMaterial={true}
                    message={`Are you sure you want to publish the bom ${row?.sequence_id}?`}
                    warningMessage={`You will not be able to edit this revision after publishing. You can however create new revisions.`}
                />,
                positiveBtn: "Check for dependencies",
                negativeBtn: "Cancel",
                onOk: () => openDependencyTable(row),
                onNegativeBtn: () => dispatch(closeConfirmationDialog())
            })
        );
    };

    const openDependencyTable = (row: any) => {
        dispatch(closeConfirmationDialog())
        try {
            dispatch(openDialog({
                title: "",
                body: <DependencyChildren object_id={row?._id} object_type="bom" onPublish={() => proceedToPublish(row)} />,
                hideNegativeBtn: true, hidePositiveBtn: true, enablePadding: false
            }));
        } catch (error) {
            console.error(`Unable to get the hierarchy: ${error}`);
            enqueueSnackbar("Error getting dependencies", { variant: "error" })
            dispatch(closeDialog())
        }
    };

    const proceedToPublish = (row: any) => {
        dispatch(openDialog({
            title: "",
            body: <PublishComponent {...row} refetch={refetch} object_type="bom" object_id={row?._id} />,
            hidePositiveBtn: true,
            hideNegativeBtn: true,
            enablePadding: false
        }));
    }

    const handleBOMDuplicate = (data: any) => {
        dispatch(openDialog({
            title: "",
            positiveBtn: "Duplicate",
            hideNegativeBtn: true,
            hidePositiveBtn: true,
            enablePadding: false,
            body: <DuplicateTypeSelection onSuccess={(value) => performDuplicate(data, value)} />
        }))
    };

    const performDuplicate = async (data: any, duplicateType: string) => {
        dispatch(openBackdrop(`Creating duplicate of "${data.sequence_id}" bom....`));
        let res: any = {};
        try {
            res = await duplicateBOM({ id: data?._id, level: duplicateType });
            if (Object.keys(res).includes("data")) {
                enqueueSnackbar(
                    `${res?.data?.message}`,
                    { variant: "success" }
                );
                dispatch(closeDialog());
                refetch();
            } else if (Object.keys(res).includes("error")) {
                // eslint-disable-next-line
                throw "Unable to create duplicate";
            } else {
                // eslint-disable-next-line
                throw "Data not found";
            }
        } catch (error) {
            let errorMessage: string = `Unable to create duplicate of ${data.sequence_id} bom!`;
            enqueueSnackbar(errorMessage, { variant: "error" });
            dispatch(closeBackdrop());
        } finally {
            dispatch(closeBackdrop());
            dispatch(closeDialog());
        }
    };

    const handleSortModelChange = React.useCallback((sortModel: GridSortModel) => {
        // Here you save the data you need from the sort model
        setQueryOptions({ sortModel: [...sortModel] });
    }, [])

    React.useEffect(() => {
        if (moreFilters.noun?.value) {
            getBOMsBySearch({ 
                search: moreFilters.noun?.label, 
                include_histories: dropDown?.value,
                sort_by: queryOptions?.sortModel?.[0]?.field,
                sort_order: queryOptions?.sortModel?.[0]?.sort,
                ...paginationModel
            })
        } else {
            refetch();
        }
        // eslint-disable-next-line
    }, [dropDown, moreFilters, paginationModel, JSON.stringify(queryOptions?.sortModel)])

    React.useEffect(() => {
        if (searchByKeyword) {
            getBOMsBySearch({ 
                search: searchByKeyword, 
                include_histories: dropDown?.value,
                sort_by: queryOptions?.sortModel?.[0]?.field,
                sort_order: queryOptions?.sortModel?.[0]?.sort,
                ...paginationModel 
            })
        } else {
            refetch();
        }
        // eslint-disable-next-line
    }, [dropDown, searchByKeyword, paginationModel, JSON.stringify(queryOptions?.sortModel)])

    React.useEffect(() => {
        setSearch("");
    }, [paginationModel])

    React.useEffect(() => {
        if (searchByKeyword) {
            updateMoreFilers({});
        }
    }, [searchByKeyword])

    React.useEffect(() => {
        if (Object.keys(moreFilters).length > 0) {
            setSearchByKeyword("");
        }
    }, [moreFilters])


    React.useEffect(() => {
        refetch();
        // eslint-disable-next-line
    }, [dropDown, paginationModel])

    return <Box sx={classes.root}>
        {/* Header */}
        <TitleHeader
            title="BOMs"
            count={ROWS?.length ?? 0}
            showSearch={true}
            search={search}
            onSearchChange={setSearch}
            showCreateBtn={creatable}
            searchPlaceholder={"Search for BOM (only within this page)"}
            createBtnLabel={"Create BOM"}
            onCreateBtn={() => viewBOM(null, false)}
            showDropDown={true}
            onDropDownChange={(data: SelectBoxOption) => updateDropDown(data)}
            dropDownLabel="Version"
            dropDownOptions={dropDownOptions}
            dropDownValue={dropDown}
            showFilter={true}
            showMoreFilter={true}
            showFilterSelect={false}
            onMoreFilterClick={openMoreFilter}
            moreFilterApplied={Object.values(moreFilters).length > 0 || searchByKeyword.length > 0}
            {...props.titleHeaderProps}
        />

        {/* Error */}
        {(isError || filterHasError) && <Alert sx={{ mt: 2 }} severity="error">Oops! Something went wrong, Unable to fetch BOMs. Try Again Later!</Alert>}

        {/* Data Drid */}
        <Box mt={2}>
            <CustomDataGrid
                id={`${props.objectType ? `${props.objectType}-` : ""}bom-list`}
                // initialState={{
                //     sorting: {
                //         sortModel: [{ field: 'sequence_id', sort: 'desc' }],
                //     },
                // }}
                rows={ROWS}
                columns={DashboardTableColumn({
                    onDelete: deleteBOM, openRevision, view, openAssociation, showTechnicalCard, updatePublishStatus,
                    disableNavigation: false, removeDelete: !deletable, openReferences, handleBOMDuplicate,
                    showDuplicate: creatable, publishable
                })}
                checkboxSelection={props.checkboxSelection}
                onRowSelectionModelChange={(selected) => props.onCheckBoxSelected(selected)}
                isRowSelectable={(params: GridRowParams) => isRowSelectable(params.row)}
                loading={loading}
                getRowId={(row) => row._id}
                showToolbar={true}
                rowCount={(searchByKeyword || Object.keys(moreFilters).length > 0) ? bomsFilteredData?.totalCount : totalCount}
                paginationModel={paginationModel}
                paginationMode="server"
                onPaginationModelChange={setPaginationModel}
                sortingMode="server"
                onSortModelChange={handleSortModelChange}
            />
        </Box>
    </Box>
}