import { Box } from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useMemo } from "react";
import { matchPath, useLocation, useNavigate, useParams } from "react-router-dom";
import { Documents, Layout } from "../../components";
import { useAppDispatch, useAppSelector } from "../../redux";
import { useLazyConnectChatBotQuery, useLazyGetInstantSearcheByIdQuery, useUpdateInstantSearchMutation } from "../../redux/services";
import { updateAISearchSlice } from "../../redux/slices/ai";
import { closeBackdrop, openBackdrop } from "../../redux/slices/backdrop";
import { closeConfirmationDialog, openConfirmationDialog } from "../../redux/slices/confirmationDialog";
import { HasAccess } from "../../router/authorization";
import { PERMISSIONS } from "../../router/permission";
import { AppRoutes } from "../../router/routes";
import { BasicDetails } from "./basicDetails";
import { ChatBox } from "./chatBot";
import { NavBars } from "./utils";

const ViewAIInstantSearch: React.FC<{ children?: JSX.Element, currentPath: string, readOnly?: boolean, showLinkDocumentBtn?: boolean }> = ({ currentPath, readOnly, showLinkDocumentBtn = true }) => {

    const params = useParams();
    const navigate = useNavigate();
    
    const location: any = useLocation();
    const paths = location.state?.paths ?? [];
    const parentData = location.state?.parentData;
    const object_type = location.state?.object_type;

    const dispatch = useAppDispatch();
    const { enqueueSnackbar } = useSnackbar();

    const [getSearchesById, searchData] = useLazyGetInstantSearcheByIdQuery();
    const [updateSearch] = useUpdateInstantSearchMutation();

    const [connectChatBot] = useLazyConnectChatBotQuery();

    const searchId = params?.id ?? "New";

    const isEdit = !['New', ':id'].includes(searchId);

    const { perm } = useAppSelector(store => store.auth.userDetails);

    const creatable = React.useMemo(() => HasAccess(perm, PERMISSIONS.AI_SEARCH_CREATE), [perm]);
    const readable = React.useMemo(() => HasAccess(perm, PERMISSIONS.AI_SEARCH_READ), [perm]);
    const updatable = React.useMemo(() => HasAccess(perm, PERMISSIONS.AI_SEARCH_UPDATE), [perm]);
    const deletable = React.useMemo(() => HasAccess(perm, PERMISSIONS.AI_SEARCH_DELETE), [perm]);
    const publishable = React.useMemo(() => HasAccess(perm, PERMISSIONS.AI_SEARCH_UPDATE), [perm]);

    const callLinkOrUnlinkDocument = async ({ links, refetch, unlinks }: { links?: string[], refetch?: any, unlinks?: string[] }) => {
        const isLinking = (links ?? [])?.length > 0;

        dispatch(openBackdrop(`${isLinking ? 'Linking' : 'UnLinking'} Document...`));

        let payload = links ? { links: [{ object_ids: links, object_type: "document" }] } : unlinks ? { unlinks: [{ object_ids: unlinks, object_type: "document" }] } : {};

        await updateSearch({ id: searchId, payload }).then((res: any) => {
            if (res.error) {
                enqueueSnackbar(res?.error?.data?.title ?? `Something went wrong, Unable to ${isLinking ? 'link' : 'unlink'} document.`, { variant: "error" });
            } else {
                if (refetch) {
                    refetch();
                }
                enqueueSnackbar(`Document ${isLinking ? 'Linked' : 'Unlinked'} Successfully!`, { variant: "success" });
                connectChatBot({ id: searchId })
            }
        }).catch(err => {
            enqueueSnackbar(`Something went wrong, Unable to ${isLinking ? 'link' : 'unlink'} document.`, { variant: "error" });
        });

        dispatch(closeBackdrop());
    };

    const giveMeAssociations = useMemo(() => {
        return searchData?.data?.associations
    }, [searchData.data?.associations]);

    const onAnnotate = (id: string, docPath: string) => {

    };

    const giveMeComponent = () => {
        // Basic Details
        if (matchPath(location.pathname, AppRoutes.viewAIAllSearcheBasicDetails(searchId))) {
            return <BasicDetails isEdit={isEdit} searchId={searchId} readOnly={readOnly} />
        }

        // Documents
        if (matchPath(location.pathname, AppRoutes.viewAIAllSearcheDocuments(searchId))) {
            return <Documents
                refetch={() => getSearchesById(searchId)}
                Id={searchData?.data?.sequence_id ?? ""}
                _id={searchId}
                objectType="instantSearch"
                linkDocument={(links: any, refetch: any) =>
                    callLinkOrUnlinkDocument({ links, refetch })
                }
                unlinkDoc={(id: string, refetch: any) => callLinkOrUnlinkDocument({ unlinks: [id], refetch })}
                associations={giveMeAssociations}
                onAnnotate={(id: string) => onAnnotate(id, AppRoutes.viewAIAllSearcheDocuments(searchId))}
                isPdfOnly={true}
                showHelperBtn={object_type ? showLinkDocumentBtn : false}
                showCreateBtn={object_type ? false : true}
                parentData={parentData}
                creatable={creatable}
                updatable={updatable}
                publishable={publishable}
                deletetable={deletable}
                readable={readable}
            />
        }

        // Chatbox
        if (matchPath(location.pathname, AppRoutes.viewAIAllSearcheChatBox(searchId))) {
            return <ChatBox searchId={searchId} />
        }

        return <></>
    };

    React.useEffect(() => {
        if (searchId === ':id') {
            dispatch(closeBackdrop());
            navigate(AppRoutes.aiInstantSearch)
        }
    });

    React.useEffect(() => {
        if (isEdit) {
            getSearchesById(searchId);
        }
        // eslint-disable-next-line    
    }, []);

    React.useEffect(() => {
        if (searchData.isLoading) {
            dispatch(openBackdrop("Fetching Search Details..."));
        } else {
            dispatch(closeBackdrop());
        }
        // eslint-disable-next-line
    }, [searchData.isLoading])

    React.useEffect(() => {
        const updateValue = async () => {
            if (isEdit && searchData.isSuccess) {
                dispatch(updateAISearchSlice({
                    basic_details: { name: searchData.data.name, reason: searchData.data.reason },
                    error: {
                        basic_details: { name: false, reason: false },
                    }
                }))
            }
        }
        updateValue();
        // eslint-disable-next-line
    }, [searchData.isSuccess]);

    React.useEffect(() => {
        if (!searchData.isFetching && searchData.isError) {
            enqueueSnackbar('404 - Unable to find the requested Search details', { variant: "error" });
            dispatch(openConfirmationDialog({
                title: "404 - Not Found",
                body: "Unable to find the requested Search details",
                positiveBtn: "Ok",
                onOk: () => {
                    dispatch(closeConfirmationDialog())
                    navigate(AppRoutes.aiInstantSearch)
                },
                hideNegativeBtn: true
            }))
        }
        // eslint-disable-next-line
    }, [searchData.isError])

    let history = [
        { label: "AI", onClick: () => navigate(AppRoutes.aiInstantSearch) },
        { label: `${searchData.isLoading ? "Loading..." : searchData?.data?.sequence_id ?? "New"}`, onClick: () => navigate(AppRoutes.viewAIAllSearcheBasicDetails(searchId)) }
    ];

    if (paths?.length > 0) {
        history = [
            ...paths ?? [],
            history[1]
        ]
    }

    return <Box p={0.5} height={"100%"}>
        <Layout
            history={history}
            currentPath={currentPath}
            navBars={NavBars(searchId, isEdit)}
            mainPanel={giveMeComponent()}
            sideNavVariant={"whiteboard"}
            hideNavBarByDefault={false}
            locationState={location?.state}
            resizable
        />
    </Box>
}

export default ViewAIInstantSearch;