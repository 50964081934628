import { createApi, fetchBaseQuery, retry } from '@reduxjs/toolkit/query/react';
import { config } from '../../../config';
import { LocalStorageKeys, getTransformedDataForPagination, objectToQueryParams } from '../../../utils';

export type PDFDocumentProps = {
    document_id: string;
    filename: string;
};

export type CitationResponse = {
    document: PDFDocumentProps;
    bbox: {
        page: number;
        x0: number;
        y0: number;
        x1: number;
        y1: number;
        page_height: number;
        page_width: number;
    }[];
}

export interface ChatHistory {
    actor: "human" | "ai",
    content: string
    isError?: boolean;
    citations?: CitationResponse[]
}

interface ChatPayload {
    chat_history: ChatHistory[],
    question: string
}

interface QueryPayload {
    k?: number,
    mode: "keyword" | "semantic",
    query: string
}

export const aiAPI = createApi({
    reducerPath: "aiAPI",
    baseQuery: retry(fetchBaseQuery({
        baseUrl: config.api_url + "ai/instant_search",
        prepareHeaders: (headers, { getState }) => {
            const token = localStorage.getItem(LocalStorageKeys.authToken);

            // If we have a token set in state, let's assume that we should be passing it.
            if (token) {
                headers.set('authorization', `Bearer ${token}`)
            }

            return headers
        },
    }), { maxRetries: 1 }),
    tagTypes: ['AI Instant Search', 'AI Instant Search by Id'],
    endpoints: (builder) => ({
        // Get AI Instant Search API
        getInstantSearches: builder.query({
            query: ({ page = 0, pageSize = 10, object_id, object_type, sort_by = null, sort_order = null }) => ({ url: objectToQueryParams({ page_no: page + 1, page_size: pageSize, object_id, object_type, sort_by: sort_by, sort_order: sort_order }), method: "GET" }),
            transformResponse: (data: any, meta: { request: Request; response?: Response }) => {
                return getTransformedDataForPagination(data, meta?.response);
            },
            providesTags: ["AI Instant Search"],
        }),

        // Get AI Instant Search by ID API
        getInstantSearcheById: builder.query({
            query: (id) => ({ url: `/${id}`, method: "GET" }),
            providesTags: ["AI Instant Search by Id"],
        }),

        // Create InstantSearch API
        createInstantSearch: builder.mutation({
            query: (payload: any) => ({ method: "POST", body: payload }),
            invalidatesTags: ["AI Instant Search"],
        }),

        // Update InstantSearch API
        updateInstantSearch: builder.mutation({
            query: (payload: { id: string, payload: any }) => ({ url: `/${payload.id}`, method: "PUT", body: payload.payload }),
            invalidatesTags: ["AI Instant Search"]
        }),

        // Delete InstantSearch API
        deleteInstantSearch: builder.mutation({
            query: (id) => ({ url: `/${id}`, method: "DELETE" }),
            invalidatesTags: ["AI Instant Search"]
        }),

        // Connect ChatBot API
        connectChatBot: builder.query({
            query: ({ id }) => ({ url: `/${id}/load`, method: "POST" }),
        }),

        // Chat with bot API
        chat: builder.mutation({
            query: ({ id, payload }: { id: string, payload: ChatPayload }) => ({ url: `/${id}/chat`, method: "POST", body: payload }),
        }),

        // Query with bot API
        query: builder.mutation({
            query: ({ id, payload }: { id: string, payload: QueryPayload }) => ({ url: `/${id}/query`, method: "POST", body: payload }),
        }),
    })
})

// Export hooks for usage in functional components
export const { useCreateInstantSearchMutation, useLazyGetInstantSearchesQuery, useGetInstantSearcheByIdQuery, useLazyGetInstantSearcheByIdQuery, useGetInstantSearchesQuery, useUpdateInstantSearchMutation, useDeleteInstantSearchMutation, useChatMutation, useConnectChatBotQuery, useLazyConnectChatBotQuery, useQueryMutation } = aiAPI
