import { useAuth0 } from "@auth0/auth0-react";
import { Box, LinearProgress } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { InternatlServerError } from "../../components";
import { AppRoutes } from "../../router/routes";

export const Auth0: React.FC<{}> = () => {

    const navigate = useNavigate();
    const { isAuthenticated, isLoading, error } = useAuth0();

    React.useEffect(() => {
        if (isAuthenticated) {
            navigate(AppRoutes.root);
        }
        // eslint-disable-next-line
    }, [isAuthenticated]);

    if (isLoading) {
        return <LinearProgress />
    }

    const errorMessage = "Please ensure you have a valid account and added as a part of your organization in IEHub. If issue persists, please contact your system administrator.";

    if (error) {
        return <Box width={"100%"} display={"flex"} alignItems={"center"} justifyContent={"center"}>
            <Box width={{ xs: "90%", sm: 425 }}>
                <InternatlServerError title="Access Denied" message={errorMessage} retryBtnName="Go to Login" onRetry={() => navigate(AppRoutes.login)} />
            </Box>
        </Box>
    }

    return <></>
}