import React, { useEffect } from 'react';
import { PDFDocument, StandardFonts } from 'pdf-lib';
import { drawDocumentPdfPage, generateDataSheet, IBasicDetails, Orientation } from 'pdf-generator';
import { CircularProgress, Stack } from '@mui/material';
import { TemplateResponse } from '../../../redux/slices/documentTemplate';
import { SelectBoxOption } from '../../../interfaces';
import { CheckListData } from '../../../screens/viewCheckList/viewCheckListDetail';
import { useAppDispatch } from '../../../redux';
import { useLazyGetDataTableByIdQuery } from '../../../redux/services';
import { closeBackdrop, openBackdrop } from '../../../redux/slices/backdrop';
import { blobToBase64 } from '../../../utils';

export interface DocProps {
    title?: string;
    headerTitle?: string;
    orientation: Orientation;
    paperSize: PaperSize;
    templateData: Partial<TemplateResponse & SelectBoxOption> | null;
    dataSheet?: Partial<CheckListData>;
    basicDetails?: IBasicDetails;
    details?: Partial<IBasicDetails> & { short_description?: string }
}

export const paperSizes = [
    { label: '4A0', value: '4A0' },
    { label: '2A0', value: '2A0' },
    { label: 'A0', value: 'A0' },
    { label: 'A1', value: 'A1' },
    { label: 'A2', value: 'A2' },
    { label: 'A3', value: 'A3' },
    { label: 'A4', value: 'A4' },
    { label: 'A5', value: 'A5' },
    { label: 'A6', value: 'A6' },
    { label: 'A7', value: 'A7' },
    { label: 'A8', value: 'A8' },
    { label: 'A9', value: 'A9' },
    { label: 'A10', value: 'A10' },
    { label: 'B0', value: 'B0' },
    { label: 'B1', value: 'B1' },
    { label: 'B2', value: 'B2' },
    { label: 'B3', value: 'B3' },
    { label: 'B4', value: 'B4' },
    { label: 'B5', value: 'B5' },
    { label: 'B6', value: 'B6' },
    { label: 'B7', value: 'B7' },
    { label: 'B8', value: 'B8' },
    { label: 'B9', value: 'B9' },
    { label: 'B10', value: 'B10' },
    { label: 'C0', value: 'C0' },
    { label: 'C1', value: 'C1' },
    { label: 'C2', value: 'C2' },
    { label: 'C3', value: 'C3' },
    { label: 'C4', value: 'C4' },
    { label: 'C5', value: 'C5' },
    { label: 'C6', value: 'C6' },
    { label: 'C7', value: 'C7' },
    { label: 'C8', value: 'C8' },
    { label: 'C9', value: 'C9' },
    { label: 'C10', value: 'C10' },
    { label: 'RA0', value: 'RA0' },
    { label: 'RA1', value: 'RA1' },
    { label: 'RA2', value: 'RA2' },
    { label: 'RA3', value: 'RA3' },
    { label: 'RA4', value: 'RA4' },
    { label: 'SRA0', value: 'SRA0' },
    { label: 'SRA1', value: 'SRA1' },
    { label: 'SRA2', value: 'SRA2' },
    { label: 'SRA3', value: 'SRA3' },
    { label: 'SRA4', value: 'SRA4' },
    { label: 'Executive', value: 'EXECUTIVE' },
    { label: 'Folio', value: 'FOLIO' },
    { label: 'Legal', value: 'LEGAL' },
    { label: 'Letter', value: 'LETTER' },
    { label: 'Tabloid', value: 'TABLOID' },
    { label: 'ID1', value: 'ID1' },
];

export type PaperSize = typeof paperSizes[number]['value'];

export const pxToPoint = (px: number) => Math.ceil(px * 0.75);

const PdfView: React.FC<DocProps> = ({ title, templateData, dataSheet, basicDetails, details, ...rest }) => {

    const updatedTitle = title || `Material Details Preview for ${basicDetails?.sequenceId || '...'}-r${basicDetails?.version ?? 0}`;

    const dispatch = useAppDispatch();

    const [getDataSheetById, dataSheetResponse] = useLazyGetDataTableByIdQuery({
        refetchOnFocus: true,
        refetchOnReconnect: true
    })

    const [show, setShow] = React.useState(false);

    const [pdfInfo, setPdfInfo] = React.useState('');

    const [generatedURL, setURL] = React.useState<string>('');

    const [key, setKey] = React.useState("generated-pdf");

    const viewerRef = React.useRef<HTMLIFrameElement>(null);

    const fetchDataSheet = async () => {
        const ds_id = basicDetails?.associations?.find((_: any) => _.object_type === "dataTableForm")?.object_details?.at(-1)?._id || "";
        if (ds_id) {
            setShow(false);
            setURL('');
            dispatch(openBackdrop("Fetching Data Sheet Detail..."));
            await getDataSheetById({
                dtTemplateType: "datasheet",
                dtInstanceType: "form",
                dtTemplateId: ds_id
            }).unwrap().catch((error: any) => {
                console.error("data sheet form fetch error: ", error)
            }).finally(() => {
                setShow(true);
                dispatch(closeBackdrop());
            })
        } else {
            setShow(true)
        }
    }

    useEffect(() => {
        if (basicDetails) {
            fetchDataSheet();
        } else {
            setShow(true)
        }
        // eslint-disable-next-line
    }, [basicDetails?.sequenceId])

    const generateHFPdf = async () => {
        setPdfInfo('');
        dispatch(openBackdrop("Generating pdf..."));
        const pdfDoc = await PDFDocument.create();
        const helveticaFont = await pdfDoc.embedFont(StandardFonts.Helvetica);

        const size = (templateData ? templateData?.paper_size || "A4" : "A4") as any;

        if (generatedURL && templateData) {
            // Documents
            try {
                const pdfBytes = await fetch(decodeURIComponent(generatedURL)).then((res) =>
                    res.arrayBuffer()
                )
                const pdf = await PDFDocument.load(pdfBytes);
                await drawDocumentPdfPage(pdfDoc, helveticaFont, { sequence_id: (basicDetails?.sequenceId || details?.sequenceId) ?? "", version: (basicDetails?.version || details?.version) ?? 0, spare_part: false, vendor: { part_number: ((basicDetails?.manufactureNo || details?.version) ?? "-") as string, vendor_name: (basicDetails?.supplierName?.value ?? "-") as string, manufacturer_name: (basicDetails?.manufactureName || details?.manufactureName) ?? "-" }, short_description: details?.short_description ?? "-" }, pdf, size, templateData ? templateData as any : {}, undefined, undefined, true)
            } catch (error: any) {
                console.error(`error while fetching document: ${error}`)
            };
        }

        // Change the title
        pdfDoc.setTitle(updatedTitle);

        const pdfBytes = await pdfDoc.save();
        const bytes = new Uint8Array(pdfBytes);
        const blob = new Blob([bytes], { type: "application/pdf" });
        const docUrl = URL.createObjectURL(blob);
        const url = templateData ? docUrl : generatedURL;
        await fetch(url).then((res) => {
            return res.blob()
        }).then(async (res) => {
            try {
                const base64 = await blobToBase64(res) as string;
                setPdfInfo(base64);
            } catch (error) {
                console.error(`Error loading datasheet on preview: ${error}`)
            }
        }).catch((error: any) => {
            console.error(`Error loading datasheet on preview: ${error}`)
        }).finally(() => {
            dispatch(closeBackdrop());
        })
    };

    React.useEffect(() => {
        setURL('');
    }, [templateData, basicDetails?.sequenceId, rest.orientation, rest.paperSize, dataSheet])

    React.useEffect(() => {
        setKey(templateData?._id ?? "");
    }, [templateData])

    React.useEffect(() => {
        setKey(basicDetails?.sequenceId ?? "");
    }, [basicDetails?.sequenceId])

    React.useEffect(() => {
        setKey(rest.orientation);
    }, [rest.orientation])

    React.useEffect(() => {
        setKey(rest.paperSize);
    }, [rest.paperSize])

    React.useEffect(() => {
        if (dataSheet) {
            setKey(dataSheet?.data?._id ?? "");
        }
    }, [dataSheet])

    React.useEffect(() => {
        if (generatedURL) {
            generateHFPdf();
        }
        // eslint-disable-next-line
    }, [generatedURL])

    React.useEffect(() => {
        if (show) {
            setURL(generateDataSheet({ title: updatedTitle, dataSheet: dataSheetResponse?.data ?? dataSheet?.data, basicDetails,...rest }));
        }
        // eslint-disable-next-line
    }, [show, key])

    return (
        <Stack flexGrow={1} overflow={"auto"} id={basicDetails?.sequenceId ?? "viewer"} height={"100%"} width={"100%"}>
            {pdfInfo
                ? <iframe ref={viewerRef} height={"100%"} width={"100%"} title={updatedTitle} src={pdfInfo}></iframe>
                : <Stack height={"100%"} alignItems={'center'} justifyContent="center"><CircularProgress /></Stack>}
        </Stack>
    )
}

export default PdfView