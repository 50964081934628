import { Box } from "@mui/material";
import React, { useEffect } from "react";
import { matchPath, useLocation, useNavigate } from "react-router-dom";
import { Layout } from "../../components";
import { withNavBars } from "../../HOCs";
import { useAppSelector } from "../../redux";
import { HasAccess } from "../../router/authorization";
import { PERMISSIONS } from "../../router/permission";
import { AppRoutes } from "../../router/routes";
import CheckList from "../checkList";
import DataSheetList from "../dataSheetList";
import InputParameterList from "../inputParametersList";
import { NavBars } from "./utils";
import ViewTemplateList from "./viewTemplateList";

const DocumentTemplates: React.FC<{ children?: JSX.Element }> = (props) => {

    const { perm } = useAppSelector(store => store.auth.userDetails);

    const master = React.useMemo(() => HasAccess(perm, PERMISSIONS.DOCUMENT_TEMPLATE_MASTER), [perm]);

    const [currentPath, setCurrentPath] = React.useState(NavBars[0]?.title);

    const location = useLocation();

    const navigate = useNavigate();

    const giveMeComponent = () => {
        // Header Footer Template List
        if (
          matchPath(location.pathname, AppRoutes.viewTemplateList)
        ) {
          return (
            <ViewTemplateList hasAccess={master} />
          );
        }
        
        // Check List
        if (
          matchPath(location.pathname, AppRoutes.checkList)
        ) {
          return (
            <CheckList hasAccess={master} />
          );
        }
    
        // Data Sheet List
        if (
          matchPath(location.pathname, AppRoutes.dataSheetList)
        ) {
          return (
            <DataSheetList hasAccess={master} />
          );
        }
        
        // Technical Parameters List
        if (
          matchPath(location.pathname, AppRoutes.technicalParametersList)
        ) {
          return (
            <InputParameterList hasAccess={master} />
          );
        }
        return <></>;
    };
    
    useEffect(() => {
        setCurrentPath(NavBars.find(n => n.path === location.pathname)?.title || "")
    }, [location.pathname]);

    return <Box sx={{ padding: 0.5, height: "100%" }}>
        {/* Breadscrum */}
        <Layout
            history={[
                { label: "Document Templates", onClick: () => navigate(AppRoutes.viewTemplateList) },
            ]}
            shouldAddStyle={false}
            currentPath={currentPath}
            navBars={NavBars}
            navBarTitle={"Template Types"}
            mainPanel={giveMeComponent()}
            sideNavVariant={"whiteboard"}
        />
    </Box>
}

export default withNavBars(DocumentTemplates);