import React from "react";
import { Box, Typography, Stack, IconButton } from '@mui/material';
import { Close, Done } from "@mui/icons-material";
import { SketchPicker } from 'react-color'

const shades: string[] = ["#D3D3D3", "#FFFFF0", "#E6E6FA", "#ADD8E6", "#F5FFFA", "#98FB98", "#FFFAF0", "#F0F8FF", "#F5F5DC", "#F0FFF0"]


export const ColorPalette: React.FC<{
    label?: string,
    value?: string,
    required?: boolean,
    error?: boolean,
    helperText?: string,
    onChange?: (value: string) => void,
}> = ({ label = "", required = false, value = "", error = false, helperText = "", onChange = () => false }) => {

    const [open, toggleOpen] = React.useState(false);

    return <Box>
        {label.length > 0 && <Box sx={{ display: "flex" }}>
            {
                <Typography variant="body2" color="primary" align="left" gutterBottom>
                    {label}
                </Typography>
            }
            {required && (
                <Typography color="error" variant="caption">
                    *
                </Typography>
            )}
        </Box>}

        <Stack direction={"row"} spacing={1} >
            {false && shades.map(shade => {
                const isSelected = shade === value;
                return <Box height={30} width={30} sx={{
                    backgroundColor: shade,
                    border: (theme) => `${isSelected ? 2 : 1}px solid ${isSelected ? theme.palette.primary.dark : theme.palette.divider}`,
                    cursor: "pointer",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center"
                }}
                    onClick={(e) => onChange(shade)}
                >
                    {isSelected && <Done fontSize="small" color="primary" />}
                </Box>
            })}
            <Box position={"relative"}>
                <Box p={"5px"} sx={{
                    background: "white",
                    borderRadius: "1px",
                    boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
                    display: 'inline-block',
                    cursor: 'pointer',
                }} onClick={() => toggleOpen(true)} >
                    <Box sx={{ borderRadius: "2px", background: value }} width={"36px"} height={"14px"}></Box>
                </Box>
                {open && <Box position={"absolute"} display={"flex"} zIndex={5}>
                    <SketchPicker color={value} onChange={(color: any) => {
                        onChange(color.hex)
                    }} />
                    <IconButton sx={{ position: "absolute", top: "-12px", right: "-38px" }} onClick={() => toggleOpen(false)}><Close /></IconButton>
                </Box>}
            </Box>
        </Stack>

        {helperText?.length > 0 && (
            <Typography variant="caption" color={error ? "error" : ""}>
                {helperText}
            </Typography>
        )}
    </Box>
}