import { KeyboardBackspace } from "@mui/icons-material";
import { TreeView } from "@mui/lab";
import {
  Box,
  Divider,
  IconButton,
  LinearProgress,
  Stack,
  Typography,
} from "@mui/material";
import { useSnackbar } from "notistack";
import React from "react";
import { useNavigate } from "react-router-dom";
import {
  CustomTreeItemV2,
  Documents,
  LoadingSection,
  MyPaper,
} from "../../../components";
import { BOMContext } from "../../../contexts";
import { useAppDispatch, useAppSelector } from "../../../redux";
import {
  useLazyGetBOMByIdQuery,
  useLazyGetMaterialByIdQuery,
  useLinkModuleMutation,
} from "../../../redux/services";
import { closeBackdrop, openBackdrop } from "../../../redux/slices/backdrop";
import { linksData } from "../../../redux/slices/bom";
import { closeConfirmationDialog } from "../../../redux/slices/confirmationDialog";
import { Attachment } from "../../../redux/slices/material";
import { setOpenWebSideNavBar } from "../../../redux/slices/utils";
import { HasAccess } from "../../../router/authorization";
import { PERMISSIONS } from "../../../router/permission";
import { LocalStorageKeys } from "../../../utils";

export interface BOMStateInterface {
  type: "bom" | "material";
  _id: string;
  bomId?: string;
  materialId?: string;
  short_description?: string;
  group_name?: string;
  noun_name?: string;
  reference_id?: string;
  uom?: string;
  spare_part?: boolean;
  uom_type?: string;
  noun_id?: string;
  normal_quantity?: number;
  total_quantity?: number;
  cost?: number | null;
  currency?: string;
  childs: BOMStateInterface[];
  level?: number;
  index?: number[];
  serial_num?: string;
  spare_part_category?: string;
  isSpareEditable?: string;
  spare_part_quantity?: number;
  cost_override?: boolean;
  override_cost?: number;
  override_currency?: string;
  cost_per_qty?: number;
  sourcing_type?: string;
  child_id?: string;
  version: number;
  attachments: Attachment[];
  associations?: linksData;
}

export const DocumentTree: React.FC<{
  bomId: string;
  bom: any;
  groupData: any;
  nounData: any;
  onAnnotate: (id: string) => void;
  path: string;
  showCreateBtn?: boolean;
}> = (props) => {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { perm } = useAppSelector(store => store.auth.userDetails);

  const [getBOM, getBOMData] = useLazyGetBOMByIdQuery();
  const [getMaterialById, materialData] = useLazyGetMaterialByIdQuery({ refetchOnReconnect: true, refetchOnFocus: true });
  const [linkDocument] = useLinkModuleMutation();

  const [state, setState] = React.useState<BOMStateInterface[]>([]);
  const [expanded, setExpanded] = React.useState<string[]>([]);
  const [selected, setSelected] = React.useState<string>("");
  const [renderTree, reRenderTree] = React.useState<boolean>(true);
  const [currentItem, setCurrentItem] = React.useState<BOMStateInterface | null>(null);
  const [currentBOM, setCurrentBOM] = React.useState<string>("");
  const [associations, setAssociations] = React.useState([]);

  const resizeRef = React.useRef<HTMLDivElement>(null);

  const creatable = React.useMemo(() => HasAccess(perm, PERMISSIONS.BOM_CREATE), [perm]);
  const readable = React.useMemo(() => HasAccess(perm, PERMISSIONS.BOM_READ), [perm]);
  const updatable = React.useMemo(() => HasAccess(perm, PERMISSIONS.BOM_UPDATE), [perm]);
  const deletable = React.useMemo(() => HasAccess(perm, PERMISSIONS.BOM_DELETE), [perm]);
  const publishable = React.useMemo(() => HasAccess(perm, PERMISSIONS.BOM_PUBLISH), [perm]);

  const handleToggle = (event: React.SyntheticEvent, nodeIds: string[]) => setExpanded(nodeIds);

  const handleSelect = (event: React.SyntheticEvent, nodeIds: string) => setSelected(nodeIds);

  const addItem = (level: number, index: number[], items: BOMStateInterface[], isPositionChange?: boolean) => {
    reRenderTree(!isPositionChange);
    switch (level) {
      case 1:
        state[0].childs = items;
        break;

      case 2:
        state[index[0]].childs[index[1]].childs = items;
        break;

      case 3:
        state[index[0]].childs[index[1]].childs[index[2]].childs = items;
        break;

      case 4:
        state[index[0]].childs[index[1]].childs[index[2]].childs[
          index[3]
        ].childs = items;
        break;

      case 5:
        state[index[0]].childs[index[1]].childs[index[2]].childs[
          index[3]
        ].childs[index[4]].childs = items;
        break;

      case 6:
        state[index[0]].childs[index[1]].childs[index[2]].childs[
          index[3]
        ].childs[index[4]].childs[index[5]].childs = items;
        break;

      case 7:
        state[index[0]].childs[index[1]].childs[index[2]].childs[
          index[3]
        ].childs[index[4]].childs[index[5]].childs[index[6]].childs = items;
        break;

      case 8:
        state[index[0]].childs[index[1]].childs[index[2]].childs[
          index[3]
        ].childs[index[4]].childs[index[5]].childs[index[6]].childs[
          index[7]
        ].childs = items;
        break;

      case 9:
        state[index[0]].childs[index[1]].childs[index[2]].childs[
          index[3]
        ].childs[index[4]].childs[index[5]].childs[index[6]].childs[
          index[7]
        ].childs[index[8]].childs = items;
        break;

      default:
        break;
    }
    setState([...state]);

    setTimeout(() => {
      reRenderTree(true);
    }, 0);
  };

  const onItemClick = (bom: BOMStateInterface, level: number, index: number[]) => {
    let curItem = { ...bom, level, index };
    setCurrentBOM(bom?._id ?? "");
    if (bom.type === "material") {
      setCurrentItem(curItem);
      setCurrentBOM("");
      document
        .getElementsByClassName(`${bom?.child_id || bom?._id}`)?.[0]
        ?.scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "start",
        });
    } else {
      setCurrentItem(curItem);
      if (curItem.childs.length === 0) {
        updateBOMTree(curItem, level, index);
      } else {
        setTimeout(() => {
          setCurrentBOM("");
        }, 1000);
      }
      document
        .getElementsByClassName(`${bom?.child_id || bom?._id}`)?.[0]
        ?.scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "start",
        });
    }

    const element = resizeRef.current;
    const width = JSON.parse(localStorage.getItem(LocalStorageKeys.resizeDocumentTreeWidth) || "350");
    if (element) {
      element.style.width = `${width}px !important`;
    }
  };

  const giveMeLinksAndUnlinks = (links: any): linksData => {
    let obj: linksData = {
      links: [{ object_ids: links, object_type: "document" }],
    };
    return obj;
  };

  const callLinkDocument = async (cItem: BOMStateInterface, links?: any, refetch?: any) => {
    dispatch(openBackdrop("Linking the document..."));
    const linkedData = giveMeLinksAndUnlinks(links);
    let res: any = {};
    try {
      if (!cItem) {
        return false;
      }

      let object_type = "";

      if (cItem?.type === "bom") {
        object_type = "bom";
      } else {
        object_type = "material";
      }

      res = await linkDocument({
        object_id: cItem?._id,
        object_type: object_type,
        payload: linkedData,
      });

      if (Object.keys(res).includes("data")) {
        enqueueSnackbar(`Linked document Successfully!`, {
          variant: "success",
        });
        dispatch(closeConfirmationDialog());
        refetch();
        fetchAssociations(cItem);
      } else if (Object.keys(res).includes("error")) {
        // eslint-disable-next-line
        throw res?.error?.data;
      }
    } catch (error: any) {
      let errorMessage: string =
        error?.title ?? error ?? "Oops! Something went wrong, Unable to link document";
      enqueueSnackbar(errorMessage, { variant: "error" });
    } finally {
      dispatch(closeBackdrop());
    }
  };

  const giveMeTreeItems = (bom: BOMStateInterface, level: number, index: number[]) => {
    return (
      <CustomTreeItemV2
        key={bom._id}
        nodeId={index.join("-")}
        isExpand={expanded.includes(index.join("-"))}
        customTitle={`${bom.type === "bom" ? `${bom.bomId}` : `${bom.materialId}`
          }-r${bom.version}`}
        customSubTitle={`(${bom?.short_description})`}
        tooltip={`${bom.short_description}`}
        selected={selected}
        onClick={() => {
          onItemClick(bom, level, index);
        }}
        isLoading={currentBOM === bom._id}
        rightClickOptions={[]}
        hideExpandCollapseIcon={
          bom.type === "material" && bom.childs.length === 0
        }
        disableRightClick={bom.type === "material"}
      >
        {bom.childs.map((child, _index: number) => {
          return giveMeTreeItems(child, level + 1, [...index, _index]);
        })}
      </CustomTreeItemV2>
    );
  };

  const createFirstBOM = async (bomId: string) => {
    setCurrentBOM(bomId);
    let { data } = await getBOM({ id: bomId });
    state.push({
      type: "bom",
      _id: data._id,
      bomId: data.sequence_id,
      short_description: data.short_description,
      group_name: data.group_name,
      noun_name: data.noun_name,
      reference_id: data.reference_id,
      spare_part: true,
      normal_quantity: 0,
      spare_part_category: data?.tags_info?.[0]?.tag_id,
      cost: data?.total_cost?.cost ?? 0,
      currency: data?.currency_code ?? "USD",
      childs: [],
      version: data?.version,
      attachments: data?.attachments ?? [],
      associations: data?.associations ?? [],
    });
    setCurrentBOM("");
  };

  const updateBOMTree = async (bom: BOMStateInterface | null, level: number, index: number[], isPositionChange?: boolean) => {
    setCurrentBOM(bom?._id ?? "");
    const { data } = await getBOM({ id: bom?._id ?? "" });
    let items: BOMStateInterface[] = [];

    data?.bom_children?.forEach((_: any, _index: number) => {
      items.push({
        type: "bom",
        _id: _?._id,
        child_id: _?.child_id,
        bomId: _?.bom?.sequence_id,
        short_description: _?.bom?.short_description,
        spare_part: _.spare_part,
        normal_quantity: _?.normal_quantity ?? 1,
        total_quantity: _?.total_quantity ?? 1,
        group_name: _?.bom?.group_name ?? "",
        noun_name: _?.bom?.noun_name ?? "",
        reference_id: _?.bom?.reference_id ?? "",
        cost: _?.bom?.money?.cost ?? 0,
        currency: _?.bom?.money?.currency ?? "USD",
        childs: [],
        level: level + 1,
        index: [...index, _index],
        spare_part_category: _?.tags_info?.[0]?.tag_id,
        spare_part_quantity: _?.spare_part_quantity,
        cost_override: false,
        cost_per_qty: _?.override_cost,
        version: _?.bom?.version,
        serial_num: _?.serial_num,
        attachments: _?.bom?.attachments ?? [],
        associations: _?.bom?.associations ?? [],
      });
    });

    data?.bom_materials?.forEach((_: any, _index: number) => {
      items.push({
        type: "material",
        _id: _?._id,
        child_id: _?.child_id,
        materialId: _?.material?.sequence_id,
        short_description: _?.material?.short_description,
        spare_part: _.spare_part,
        normal_quantity: _?.normal_quantity ?? 1,
        total_quantity: _?.total_quantity ?? 1,
        group_name: _?.material?.group_name ?? "",
        noun_name: _?.material?.noun_name ?? "",
        reference_id: _?.material?.external_ref_id ?? "",
        cost: _?.material?.money?.cost ?? 0,
        currency: _?.material?.money?.currency ?? "USD",
        childs: [],
        level: level + 1,
        index: [...index, _index],
        spare_part_category: _?.tags_info?.[0]?.tag_id,
        spare_part_quantity: _?.spare_part_quantity,
        cost_override: false,
        cost_per_qty: _?.override_cost,
        version: _?.material?.version,
        serial_num: _?.serial_num,
        attachments: _?.material?.attachments ?? [],
        noun_id: _?.material?.noun_id ?? "",
        uom_type: _?.material?.uom_type,
        associations: _?.material?.associations ?? [],
      });
    });

    addItem(level, index, items, isPositionChange);
    setCurrentBOM("");
    setExpanded((oldExpanded) => [...oldExpanded, index.join("-")]);

    if (bom) {
      setCurrentItem({ ...bom, childs: items });
    }

    dispatch(closeBackdrop());

    const element = resizeRef.current;
    const width = JSON.parse(localStorage.getItem(LocalStorageKeys.resizeDocumentTreeWidth) || "350");
    if (element) {
      element.style.width = `${width}px !important`;
    }
  };

  const fetchAssociations = async (currentItem: BOMStateInterface | null) => {
    if (currentItem) {
      if (currentItem?.type === "bom") {
        await getBOM({ id: currentItem?._id }).then((res: any) => {
          if (res?.data?.associations) {
            setAssociations(res?.data?.associations ?? [])
          }
        }).catch(() => {
          setAssociations((currentItem as any)?.associations ?? [])
        });
      } else if (currentItem?.type === "material") {
        await getMaterialById({ id: currentItem?._id }).then((res: any) => {
          if (res?.data?.associations) {
            setAssociations(res?.data?.associations ?? [])
          }
        }).catch(() => {
          setAssociations((currentItem as any)?.associations ?? [])
        });
      }
    }
  };

  React.useEffect(() => {
    if (getBOMData.isLoading) {
      dispatch(openBackdrop("Fetching BOM data..."));
    }

    if (getBOMData.isSuccess || getBOMData.isError) {
      dispatch(closeBackdrop());
    }

    if (getBOMData.isError) {
      enqueueSnackbar(
        (getBOMData?.error as any)?.data ?? "Fetching BOM Failed",
        { variant: "error" }
      );
    }
    // eslint-disable-next-line
  }, [getBOMData]);

  React.useEffect(() => {
    if (materialData.isLoading) {
      dispatch(openBackdrop("Fetching material associations..."));
    }

    if (materialData.isSuccess || materialData.isError) {
      dispatch(closeBackdrop());
    }

    if (materialData.isError) {
      enqueueSnackbar(
        (materialData?.error as any)?.data ?? "Fetching material associations failed",
        { variant: "error" }
      );
    }
    // eslint-disable-next-line
  }, [materialData.status]);

  React.useEffect(() => {
    if (state.length === 0) {
      createFirstBOM(props.bomId);
    }
    dispatch(setOpenWebSideNavBar(false));
    // eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    fetchAssociations(currentItem);
    // eslint-disable-next-line
  }, [currentItem]);

  React.useEffect(() => {
    const element = resizeRef.current;
    const width = JSON.parse(localStorage.getItem(LocalStorageKeys.resizeDocumentTreeWidth) || "350");
    if (element) {
      element.style.width = `${width}px !important`;
      const handleResize = (event: any) => {
        console.log(event.detail);
      };

      element.addEventListener('resize', handleResize);

      const checkResize = (mutations: any[]) => {
        const el = mutations[0].target;
        const w = el.clientWidth;
        const h = el.clientHeight;

        const isChange = mutations
          .map((m) => `${m.oldValue}`)
          .some((prev) => prev.indexOf(`width: ${w}px`) === -1 || prev.indexOf(`height: ${h}px`) === -1);

        if (!isChange) { return; }

        localStorage.setItem(LocalStorageKeys.resizeDocumentTreeWidth, JSON.stringify(w));

        const event = new CustomEvent('resize', { detail: { width: w, height: h } });
        el.dispatchEvent(event);
      };

      const observer = new MutationObserver(checkResize);
      observer.observe(element, { attributes: true, attributeOldValue: true, attributeFilter: ['style'] });

      return () => {
        observer.disconnect();
        element.removeEventListener('resize', handleResize);
      };
    }
  }, []);

  return (
    <BOMContext.Provider value={{ updateBOMTree, bom: props.bom }}>
      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          background: (theme) => theme.palette.grey[100],
          padding: 1,
        }}
      >
        <Stack height={"100%"} spacing={1} direction="row">
          {/* Side Navbar */}
          <Stack
            height={"100%"}
            ref={resizeRef}
            sx={{ resize: "horizontal", overflow: "hidden", transform: "rotateX(180deg)", width: JSON.parse(localStorage.getItem(LocalStorageKeys.resizeDocumentTreeWidth) || "350") }}
          >
            <Stack height={"100%"} sx={{ transform: "rotateX(180deg)" }} width="100%" position={"relative"}>
              {/* Tree */}
              <MyPaper height={"100%"} sx={{ overflow: "hidden !important", display: 'flex', flexDirection: "column" }}>
                <>
                  {/* Heading */}
                  <Stack position={"relative"}>
                    <Box
                      width={"100%"}
                      display={"flex"}
                      justifyContent={"center"}
                      alignItems={"center"}
                      position={"relative"}
                      pb={2}
                    >
                      <IconButton
                        onClick={() => navigate(props.path)}
                        sx={{ position: "absolute", left: 0 }}
                      >
                        <KeyboardBackspace />
                      </IconButton>
                      <Typography
                        sx={{ fontWeight: "bold" }}
                        variant="body2"
                        color="textPrimary"
                      >
                        Document Tree
                      </Typography>
                    </Box>

                    <Box
                      sx={{
                        position: "absolute",
                        left: 0,
                        right: 0,
                        bottom: 0,
                      }}
                    >
                      <Divider variant="fullWidth" />
                      {currentBOM && <LinearProgress />}
                    </Box>
                  </Stack>

                  {renderTree && (
                    <Stack flexGrow={1} overflow={"auto"}>
                      <TreeView
                        sx={{ mt: 2.5 }}
                        defaultCollapseIcon={null}
                        defaultExpandIcon={null}
                        defaultEndIcon={null}
                        expanded={expanded}
                        selected={selected}
                        onNodeToggle={handleToggle}
                        onNodeSelect={handleSelect}
                      >
                        {state.map(
                          (bom: BOMStateInterface, index: number) => {
                            return (
                              <CustomTreeItemV2
                                key={bom._id}
                                nodeId={`${index}`}
                                isExpand={expanded.includes(`${index}`)}
                                customTitle={`${bom?.bomId ?? "-"}-r${bom.version
                                  }`}
                                customSubTitle={`(${bom?.short_description})`}
                                tooltip={`${bom.short_description}`}
                                selected={selected}
                                onClick={() => {
                                  onItemClick(bom, 1, [index]);
                                }}
                                isLoading={currentBOM === bom._id}
                                rightClickOptions={[]}
                              >
                                {bom.childs.map((child, _index: number) => {
                                  return giveMeTreeItems(child, 2, [
                                    index,
                                    _index,
                                  ]);
                                })}
                              </CustomTreeItemV2>
                            );
                          }
                        )}
                      </TreeView>
                    </Stack>
                  )}
                </>
              </MyPaper>
            </Stack>
          </Stack>

          {/* Right Panel */}
          <Stack pl={1} sx={{ width: `calc(100vw - ${JSON.parse(localStorage.getItem(LocalStorageKeys.resizeDocumentTreeWidth) || "350")}px)` }} height={"100%"} overflow={"auto"}>
            {currentBOM && (
              <LoadingSection message="Fetching Document Details...." />
            )}
            {!currentBOM && (
              <>
                {currentItem !== null && (
                  <MyPaper height={"100%"}>
                    <Documents
                      refetch={async () => await getBOM({ id: props.bomId })}
                      Id={(currentItem as any)?.sequence_id}
                      _id={currentItem?._id}
                      objectType={currentItem?.type}
                      linkDocument={(links: any, refetch: any) =>
                        callLinkDocument(currentItem, links, refetch)
                      }
                      associations={associations.length > 0 ? associations : (currentItem as any)?.associations ?? []}
                      onAnnotate={props.onAnnotate}
                      showCreateBtn={props.showCreateBtn}
                      creatable={creatable}
                      updatable={updatable}
                      publishable={publishable}
                      deletetable={deletable}
                      readable={readable}
                      showHelperBtn={false}
                    />
                  </MyPaper>
                )}
              </>
            )}
          </Stack>
        </Stack>
      </Box>
    </BOMContext.Provider>
  );
};
