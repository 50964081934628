import { LoadingButton } from "@mui/lab";
import {
  Alert,
  Box,
  Button,
  Stack
} from "@mui/material";
import { useSnackbar } from "notistack";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { MyPaper } from "../../components";
import { SelectBoxOption } from "../../interfaces/selectBox";
import {
  useCreateDataTableMutation,
  useGetCheckListTagsQuery,
  useUpdateDataTableMutation
} from "../../redux/services";
import { AppRoutes } from "../../router/routes";
import { BasicDetails } from "./basicDetails";
import { Categories } from "./categories/index";
import { GridColDef } from "@mui/x-data-grid-premium";
import { closeBackdrop } from "../../redux/slices/backdrop";
import { useAppDispatch } from "../../redux";

export interface CheckListCategories {
  name: string;
  position: number;
  description: string;
  header_color: string;
  definition: {
    columns: GridColDef[],
    rows: any[]
  }
}

export interface CheckList {
  name: string;
  status: SelectBoxOption | null;
  tags_info: SelectBoxOption[];
  categories: CheckListCategories[];
  locked: boolean;
  released: boolean;
}

export interface CheckListError {
  name: boolean;
  status: boolean;
  tags_info: boolean;
}

export const giveMeInitialState = () => ({ status: null, name: "", tags_info: [], categories: [], locked: false, released: false });

export const giveMeInitialErrorState = () => ({ status: false, name: false, tags_info: false });

export interface CheckListData { data: any, isLoading: boolean, isFetching: boolean, isError: boolean, isSuccess: boolean }

export const CheckListContext = React.createContext<{ state: CheckList, error: CheckListError, updateState: (key: string, value: any) => any }>({
  state: giveMeInitialState(),
  error: giveMeInitialErrorState(),
  updateState: (key, value) => false
});

export const ViewCheckListDetail: React.FC<{
  children?: JSX.Element;
  isEdit: boolean;
  checkListData: CheckListData
}> = ({ isEdit, checkListData }) => {

  const navigate = useNavigate();
  const { checklist_id } = useParams();
  const { enqueueSnackbar } = useSnackbar();

  const dispatch = useAppDispatch();

  const tagsData = useGetCheckListTagsQuery(null, { refetchOnMountOrArgChange: true, refetchOnFocus: true });
  const [createChecklist, createCheckListData] = useCreateDataTableMutation();
  const [updateChecklist, updateCheckListData] = useUpdateDataTableMutation();

  const [state, setState] = React.useState<CheckList>(giveMeInitialState());
  const [error, setError] = React.useState<CheckListError>(giveMeInitialErrorState());

  const updateState = (key: string, value: any) => {
    setState({ ...state, [key]: value });
    setError({ ...error, [key]: false });
  };

  const giveMeErrorMessage = () => {
    let message = `Oops! Something went wrong, Unable to ${isEdit ? "Update" : "Create"} Checklist. Try Again Later!`;
    let errorData: any = isEdit ? updateCheckListData.error : createCheckListData.error;
    message = errorData?.data?.title ?? message;
    return message;
  }

  const onAddUpdateBtnClicked = async () => {
    if (isValid()) {
      if (isEdit) {
        await updateChecklist({
          payload: state,
          dtTemplateId: checklist_id ?? "",
          dtTemplateType: "checklist",
          dtInstanceType: "template",
          publish: state.status?.label === "Published"
        }).unwrap().then(res => {
          if (!res.title) {
            enqueueSnackbar('Check List Updated Successfully', { variant: "success" });
            navigate(AppRoutes.checkList)
          }
        }).catch((error: any) => {
          enqueueSnackbar(
            error?.data?.title || "Something went wrong unable to update checklist",
            { variant: "error" }
          );
        });
      } else {
        await createChecklist({
          payload: state,
          dtTemplateType: "checklist",
          dtInstanceType: "template",
          dtTemplateId: null
        }).unwrap().then((res: any) => {
          if (!res.title) {
            enqueueSnackbar('Check List Created Successfully', { variant: "success" });
            navigate(AppRoutes.checkList)
          }
        }).catch((error: any) => {
          enqueueSnackbar(
            error?.data?.title || "Something went wrong unable to update checklist",
            { variant: "error" }
          );
        });
      }
    }
  }

  const isValid = () => {
    if (state.name?.trim()?.length === 0) {
      error.name = true;
    }

    if (!state.status) {
      error.status = true;
    }

    if (!state.tags_info || state.tags_info?.length === 0) {
      error.tags_info = true;
    }

    setError({ ...error });
    return !Object.values(error).includes(true);
  }

  React.useEffect(() => {
    if (tagsData.isSuccess && checkListData.data) {
      const data = checkListData?.data ?? {};
      const statusOptions = tagsData?.data?.checkListStatusTags ?? [];
      let status = null;

      if (data.status) {
        status = statusOptions.filter((_: any) => _.tag_name === data.status)?.[0] ?? null;
      }

      setState({
        ...state, ...data, status, tags_info: data?.tags_info?.map((_: any) => ({ ..._, value: _.tag_id, label: _.tag_name })), categories: data?.tables?.map((table: any, index: number) => {
          return {
            ...table,
            name: table.name,
            position: index,
            description: table.description,
            definition: {
              columns: table?.columns?.filter((col: any) => col.field)?.map((col: any) => {
                return {
                  ...col,
                  headerName: col?.header_name || "",
                  headerClassName: col?.header_class_name || "",
                }
              }),
              rows: table?.rows?.map((row: any) => {
                const obj: any = {};
                row?.column_content?.forEach((col: any) => {
                  obj[col.field] = col.content
                })
                return {
                  id: row?.id,
                  ...obj
                }
              })
            }
          }
        })
      });
      dispatch(closeBackdrop());
    }
    // eslint-disable-next-line
  }, [checkListData.data, tagsData.isSuccess]);

  const isLoading = createCheckListData.isLoading || updateCheckListData.isLoading;
  const isError = createCheckListData.isError || updateCheckListData.isError;

  return (<CheckListContext.Provider value={{ state, updateState, error }}>
    <Stack spacing={1}>

      {/* Basic Details */}
      <BasicDetails />

      {/* Categories */}
      <Categories />

      {/* Footer */}
      <MyPaper>
        <Box>
          {/* Alert Box */}
          {isError && <Alert sx={{ mt: 2 }} severity="error">{
            giveMeErrorMessage()
          }</Alert>}

          {/* Footer Buttons */}
          <Stack direction={"row"} alignItems={"center"} justifyContent={"center"} spacing={1} p={1}>
            {/* Create Button */}
            <LoadingButton id="u-c-btn" loading={isLoading} variant="contained" sx={{ width: 200 }} color="primary" onClick={onAddUpdateBtnClicked}>
              {isEdit ? "Update" : "Create"}
            </LoadingButton>

            {/* Cancel Button */}
            <Button disabled={isLoading} variant="outlined" sx={{ width: 200 }} color="primary" onClick={() => navigate(AppRoutes.checkList)} >
              Cancel
            </Button>
          </Stack>
        </Box>
      </MyPaper>
    </Stack>
  </CheckListContext.Provider>);
};
