import { useAuth0 } from '@auth0/auth0-react';
import { LoadingButton } from '@mui/lab';
import { Alert, Box, Button, Divider, Grid, Stack, TextField, Typography } from '@mui/material';
import React from 'react'
import { NavLink, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { LogoSVG } from '../../images';
import { useAppDispatch, useAppSelector } from '../../redux';
import { openDialog } from '../../redux/slices/dialog';
import { AppRoutes } from '../../router/routes';
import { LocalStorageKeys } from '../../utils';
import { Timeout } from '../timeout';
import './index.css';

const Motto = [
    "Efficient Engineering",
    "Quality Engineering",
    "Intelligent Engineering",
    "Standardized Engineering"
];

const LoginV2 = () => {
    const { loginWithRedirect } = useAuth0();

    const navigate = useNavigate();
    const location = useLocation();
    const [params] = useSearchParams();

    const dispatch = useAppDispatch();

    const { isError, message } = useAppSelector((state) => state.auth);

    const [isInitial, setIsInitial] = React.useState<boolean>(true);
    const [email, setEmail] = React.useState('');

    const [currentIndex, setCurrentIndex] = React.useState(0);
    const [isAnimating, setIsAnimating] = React.useState(false);
    const [dimension, setDimension] = React.useState<{ width: number | null; height: number | null; }>({ width: null, height: null });

    const textContainerRef = React.useRef<HTMLDivElement>(null);

    const onOpenSignUp = () => {
        window.location.replace(`/signup.html?email=${email}`);
    };

    const onSubmit = (e: any) => {
        if (e) {
            e?.preventDefault();
            const form: any = e?.target;
            if (form.checkValidity()) {
                onOpenSignUp();
            }
        }
    }

    React.useEffect(() => {
        if (localStorage.getItem(LocalStorageKeys.authToken)) {
            navigate(AppRoutes.root);
        }
    });

    React.useEffect(() => {
        if (params.get('page') === 'timeout') {
            dispatch(
                openDialog({
                    title: "",
                    hideNegativeBtn: true,
                    hidePositiveBtn: true,
                    hideCloseIcon: true,
                    body: <Timeout />,
                })
            );
        }
        // eslint-disable-next-line
    }, [])

    React.useEffect(() => {
        const interval = setInterval(() => {
            setIsAnimating(true);
            setTimeout(() => {
                setCurrentIndex((currentIndex + 1) % Motto.length);
                setIsAnimating(false);
            }, 1000);
        }, 4000);

        return () => clearInterval(interval);
    }, [currentIndex]);

    React.useEffect(() => {
        if (textContainerRef.current) {
            setDimension({ width: textContainerRef?.current?.clientWidth ?? 0, height: textContainerRef?.current?.clientHeight ?? 0 });
        }
    }, [currentIndex]);

    React.useEffect(() => {
        if (dimension.width) {
            setIsInitial(false)
        }
    }, [dimension]);

    if (params.get('page') === 'timeout') {
        return <></>;
    }

    return (
        <Stack sx={{
            background: (theme) => `linear-gradient(185deg, #000 45%, ${theme.palette.primary.main})`,
            height: "100vh",
            overflow: 'auto',
        }} p={{ xs: 5, sm: 6, md: 8, lg: 15 }}>
            <Grid container height={"100%"} className="grid" spacing={2} rowSpacing={8}>
                <Grid item sm={12} md={6} order={{ xs: 2, md: 1 }}>
                    <div className="login-container">
                        <span>
                            <span style={{ position: "relative" }}>
                                <div style={{ borderRadius: 20, position: 'absolute', border: "1px solid rgba(255, 255, 255, 0.5)", width: dimension?.width ?? 0, height: dimension?.height ?? 0, transition: !isInitial ? "all 1s ease" : "none" }}></div>
                                <div className="text-container" ref={textContainerRef}>
                                    <span
                                        className={`motto-text ${isAnimating ? 'slide-out' : 'slide-in'}`}
                                        style={{
                                            //    borderWidth: `${Motto[currentIndex].length + 2}ch`,
                                        }}
                                    >
                                        {Motto[currentIndex]}
                                    </span>
                                </div>
                            </span>
                        </span>
                        <div style={{ fontWeight: 'bold', color: 'white !important', textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)', marginLeft: 12 }}>
                            with IEHUB
                        </div>
                    </div>
                </Grid>
                <Grid item sm={12} md={6} order={{ xs: 1, md: 2 }}>
                    <Stack className="transparent-card" spacing={2}>
                        <Box width={100} height={36}>
                            <LogoSVG />
                        </Box>
                        <Typography variant='body1' color="white">Sign up for IEHUB demo</Typography>
                        <form style={{ width: "100%" }} onSubmit={onSubmit}>
                            <Stack spacing={2} width={"100%"}>
                                <TextField
                                    sx={{
                                        '.MuiFormLabel-colorPrimary': {
                                            color: (theme) => theme.palette.primary.main,
                                            bgcolor: "white"
                                        },
                                        bgcolor: "white",
                                        borderRadius: 2,
                                    }}
                                    type="email"
                                    fullWidth
                                    placeholder={"Your Email ID *"}
                                    value={email}
                                    required={true}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => setEmail(e.target.value)}
                                />
                                <Button type='submit' sx={{ textTransform: "none", marginTop: "20px !important" }} fullWidth variant='contained' color='primary' size="large">Sign Up For Free</Button>
                            </Stack>
                        </form>
                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: "100%" }}>
                            <Divider
                                orientation="horizontal"
                                sx={{
                                    borderColor: 'rgba(255, 255, 255, 0.2)',
                                    mr: 2,
                                    width: '40%',
                                    boxSizing: "border-box"
                                }}
                            />
                            <Typography variant="body2" color="darkgrey">
                                OR
                            </Typography>
                            <Divider
                                orientation="horizontal"
                                sx={{
                                    borderColor: 'rgba(255, 255, 255, 0.2)',
                                    ml: 2,
                                    width: '40%',
                                }}
                            />
                        </Box>
                        {!isError && (
                            <LoadingButton
                                id="sign-in"
                                variant="contained"
                                fullWidth
                                onClick={() => {
                                    sessionStorage.setItem(LocalStorageKeys.redirectURL, location.state?.from ?? "");
                                    loginWithRedirect();
                                }}
                                sx={{
                                    textTransform: "none",
                                    fontSize: 18,
                                    pl: 0,
                                    ".MuiLoadingButton-loadingIndicator": {
                                        color: "white",
                                    },
                                }}
                            >
                                Sign In
                            </LoadingButton>
                        )}

                        {isError && message && <Alert severity="error">{message}</Alert>}

                        {isError && (
                            <LoadingButton
                                variant="outlined"
                                color="warning"
                                onClick={() => {
                                    navigate(AppRoutes.logout)
                                }}
                            >
                                Logout
                            </LoadingButton>
                        )}

                        <Typography variant="body2" color="mintcream" sx={{ textAlign: 'left' }}>
                            For information about how IEHUB handles your personal data, please see out <NavLink to={"https://hontrel.com/privacy-policy/"} target="_blank" style={{
                                color: "orangered"
                            }}>privacy policy</NavLink>
                        </Typography>

                        <Typography variant="caption" color="mintcream" sx={{ textAlign: 'left' }}>
                            2024 © All rights reserved. Made by <NavLink to={"https://hontrel.com/"} target="_blank" style={{
                                color: "#FC6070"
                            }}>Hontrel</NavLink>
                        </Typography>
                    </Stack>
                </Grid>
            </Grid>

        </Stack>
    )
}

export default LoginV2;