import React from "react";
import { Grid, Box, useTheme, Theme } from "@mui/material";
import { CustomDataGrid, TitleHeader } from "../../../components";
import { SelectBoxOption } from "../../../interfaces";
import { openDialog } from "../../../redux/slices/dialog";
import { useDispatch } from "react-redux";
import {
  AddUpdateSNAttribute,
  AddUpdateCategory,
  AddUpdateSNGroup,
  AddUpdateSNNoun,
} from "./addUpdate";
import { MasterValue, SNMastersInterface } from "./util";
import { NounDataInterface, NounListing } from "./nounListing";
import {
  openConfirmationDialog,
  closeConfirmationDialog,
  updateCongirmationDialogBody,
} from "../../../redux/slices/confirmationDialog";
import { closeBackdrop, openBackdrop } from "../../../redux/slices/backdrop";
import { useSnackbar } from "notistack";
import {
  useDeleteSNAttributeMutation,
  useDeleteCategoryMutation,
  useDeleteSNGroupMutation,
  useDeleteSNNounMutation,
  useDeleteSNTemplateMutation,
  useGetSNNounsQuery,
  useCreateSNAttributeMutation,
} from "../../../redux/services";
import { DeletePanel } from "./deletePanel";
import { ConvertToReactSelect } from "../../../utils";
import { useNavigate, useParams } from "react-router-dom";
import { AppRoutes } from "../../../router/routes";
import SnTemplate from "./addUpdate/snTemplate";
import { GridPaginationModel, GridSortModel } from "@mui/x-data-grid-premium";
import { useAppSelector } from "../../../redux";
import { HasAccess } from "../../../router/authorization";
import { PERMISSIONS } from "../../../router/permission";

const useStyles = (theme: Theme) => ({
  root: {
    flexGrow: 1,
    background: theme.palette.background.paper,
    boxShadow: `rgba(99, 99, 99, 0.2) 0px 2px 8px 0px`,
    height: "100%",
    borderRadius: theme.spacing(1),
    padding: theme.spacing(2),
  },
  dataGridParent: {
    height: "calc(100% - 40px)",
    overflow: "scroll",
    marginTop: theme.spacing(2),
  },
  dataGrid: {
    background: theme.palette.background.paper,
    "@media print": {
      ".MuiDataGrid-main": { color: "rgba(0, 0, 0, 0.87)" },
    },
  },
});

export const RightPanel: React.FC<{
  selectedMaster: SNMastersInterface;
  data?: any;
  currentData?: any;
  isLoading?: boolean;
  isFetching?: boolean;
  isError?: boolean;
  getMaster?: any;
  categoriesData?: any;
  state: any;
  setState: any;
  paginationModel: GridPaginationModel;
  totalCount: number;
  setPaginationModel: React.Dispatch<React.SetStateAction<GridPaginationModel>>;
  handleSortModelChange: (sortModel: GridSortModel) => void
}> = ({
  selectedMaster,
  data = [],
  categoriesData = {},
  currentData = [],
  isLoading = true,
  isFetching = true,
  isError = false,
  getMaster = () => false,
  state = {},
  setState = () => false,
  totalCount = 0,
  paginationModel,
  setPaginationModel,
  handleSortModelChange
}) => {
    const classes = useStyles(useTheme());
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const [createSNAttribute] = useCreateSNAttributeMutation();

    const [deleteCategory, dC] = useDeleteCategoryMutation();
    const [deleteSNGroup, dG] = useDeleteSNGroupMutation();
    const [deleteSNNoun, dN] = useDeleteSNNounMutation();
    const [deleteSNAttribute, dA] = useDeleteSNAttributeMutation();
    const [deleteSNTemplate, dT] = useDeleteSNTemplateMutation();

    const { perm } = useAppSelector(store => store.auth.userDetails);

    const hasAccess = React.useMemo(() => HasAccess(perm, PERMISSIONS.SEQUENCE_NO_TEMPLATE_MASTER), [perm]);

    const snNounD = useGetSNNounsQuery(
      { page: null, pageSize: null },
      { refetchOnMountOrArgChange: true }
    );
    const snNounData = {
      ...snNounD,
      data: snNounD?.data?.data ?? [],
      currentData: snNounD?.data?.data ?? [],
      totalCount: snNounD?.data?.totalCount,
    };

    const navigate = useNavigate();

    const { sequence_type = "" } = useParams();

    const onSearchChange = (newData: string) => {
      setState({ ...state, search: newData });
    };

    const giveMeAddEditComponent = (isEdit: boolean, data: any) => {
      switch (selectedMaster.value) {
        case MasterValue.category:
          return (
            <AddUpdateCategory isEdit={isEdit} data={data} refetch={getMaster} />
          );

        case MasterValue.group:
          return (
            <AddUpdateSNGroup isEdit={isEdit} data={data} refetch={getMaster} />
          );

        case MasterValue.noun:
          return (
            <AddUpdateSNNoun isEdit={isEdit} data={data} refetch={getMaster} />
          );

        case MasterValue.attribute:
          return (
            <AddUpdateSNAttribute
              isEdit={isEdit}
              data={data}
              noun={state.noun}
              refetch={getMaster}
              updateNoun={(noun) => setState((prev: any) => ({ ...prev, noun }))}
            />
          );

        case MasterValue.template:
          return <SnTemplate />;

        default:
          return <></>;
      }
    };

    const openAddEditDrawer = (
      isEdit: boolean = false,
      data: any = null,
      isTemplate: boolean = false
    ) => {
      if (hasAccess) {
        if (isTemplate) {
          navigate(
            AppRoutes.viewSequenceNumber(sequence_type, data?.id || "isNew")
          );
        } else {
          dispatch(
            openDialog({
              title: "",
              hideNegativeBtn: true,
              hidePositiveBtn: true,
              enablePadding: false,
              body: giveMeAddEditComponent(isEdit, data),
            })
          );
        }
      }
    };

    const giveMeFilerKey = () => {
      switch (selectedMaster.value) {
        case MasterValue.category:
          return "category_name";

        case MasterValue.group:
          return "group_name";

        case MasterValue.noun:
          return "noun_name";

        case MasterValue.attribute:
          return "attr_name";

        case MasterValue.template:
          return "name";

        default:
          return "name";
      }
    };

    const giveMeRows = () => {
      let rows = currentData ?? [];

      if (
        selectedMaster.value === MasterValue.noun &&
        state.category &&
        state.category._id
      ) {
        rows = currentData?.filter(
          (_: any) => _.category_id === state.category?._id
        );
      }

      if (state.search.length === 0) {
        return rows?.map((_: any) => ({ ..._, id: _._id }));
      } else {
        let filterKey = giveMeFilerKey();
        return rows
          ?.map((_: any) => ({ ..._, id: _._id }))
          ?.filter((_: any) =>
            _[filterKey].toLowerCase().includes(state.search.toLowerCase())
          );
      }
    };

    const giveMeGridBreakPoints = () => {
      if ([MasterValue.attribute].includes(selectedMaster.value)) {
        return { sm: 8, md: 8, lg: 8, xl: 8 };
      } else {
        return { sm: 12, md: 12, lg: 12, xl: 12 };
      }
    };

    const deleteRow = (data: any = null) => {
      let message = `Do you want to delete this "${data.label}" (${selectedMaster.label})?`;
      dispatch(
        openConfirmationDialog({
          title: "Are you sure?",
          body: <DeletePanel message={message} />,
          positiveBtn: "Delete",
          negativeBtn: "Cancel",
          onOk: () => performRowDel(data, message),
          onNegativeBtn: () => dispatch(closeConfirmationDialog()),
        })
      );
    };

    const performRowDel = async (data: any, message: string) => {
      dispatch(openBackdrop("Deleting " + selectedMaster.label + "...."));
      let res: any = {};
      try {
        switch (selectedMaster.value) {
          case MasterValue.category:
            res = await deleteCategory({ id: data._id });
            break;

          case MasterValue.group:
            res = await deleteSNGroup({ id: data._id });
            break;

          case MasterValue.noun:
            res = await deleteSNNoun({ id: data._id });
            break;

          case MasterValue.attribute:
            res = await deleteSNAttribute({ id: data._id });
            break;

          case MasterValue.template:
            res = await deleteSNTemplate({
              id: data._id,
              force: true,
            });
            break;

          default:
            break;
        }

        if (Object.keys(res).includes("data")) {
          enqueueSnackbar(
            `Deleted ${data.label} (${selectedMaster.label}) Successfully!`,
            { variant: "success" }
          );
          dispatch(closeConfirmationDialog());
          getMaster({ id: state.noun?._id });
        } else if (Object.keys(res).includes("error")) {
          dispatch(closeConfirmationDialog());
          // eslint-disable-next-line
          throw res;
        } else {
          // eslint-disable-next-line
          throw "Data not found";
        }
      } catch (error: any) {
        let errorMessage: string = `Unable to delete ${data.label} (${selectedMaster.label})!`;
        enqueueSnackbar(error?.error?.data?.title ?? errorMessage, { variant: "error" });
        if (selectedMaster.value === MasterValue.template) {
          return;
        }
        dispatch(
          updateCongirmationDialogBody(
            <DeletePanel
              message={message}
              errorMessage={errorMessage}
              errorData={res?.error?.data?.details}
            />
          )
        );
      } finally {
        dispatch(closeBackdrop());
      }
    };

    const duplicate = (data: any) => {
      let message = `Do you want to create a copy of this "${data.label}" (${selectedMaster.label})?`;
      dispatch(
        openConfirmationDialog({
          title: "Are you sure?",
          body: message,
          positiveBtn: "Create",
          negativeBtn: "Cancel",
          onOk: () => performDuplicate(data, message),
          onNegativeBtn: () => dispatch(closeConfirmationDialog()),
        })
      );
    };

    const performDuplicate = async (data: any, message: string) => {
      dispatch(openBackdrop("Creating copy of " + selectedMaster.label + "...."));
      let res: any = {};
      try {
        res = await createSNAttribute({
          attr_name: "Copy of - " + data.attr_name,
          nouns: data.nouns,
          options: data.options.map((_: any) => ({
            option_name: _.option_name,
            option_abbr: _.option_abbr,
          })),
        });

        if (Object.keys(res).includes("data")) {
          enqueueSnackbar(
            `Created copy of ${data.label} (${selectedMaster.label}) Successfully!`,
            { variant: "success" }
          );
          dispatch(closeConfirmationDialog());
          getMaster({ id: state.noun?._id });
        } else if (Object.keys(res).includes("error")) {
          // eslint-disable-next-line
          throw "Unable to create copy";
        } else {
          // eslint-disable-next-line
          throw "Data not found";
        }
      } catch (error) {
        let errorMessage: string = `Unable to create copy of ${data.label} (${selectedMaster.label})!`;
        enqueueSnackbar(errorMessage, { variant: "error" });
      } finally {
        dispatch(closeBackdrop());
      }
    };

    const giveMeDropDownValues = () => {
      if (selectedMaster.value === MasterValue.noun) {
        return {
          dropDownLabel: "Categories",
          showDropDown: true,
          dropDownOptions: [
            { value: "all", label: "All" },
            ...ConvertToReactSelect(
              categoriesData?.currentData ?? [],
              "_id",
              "category_name"
            ),
          ],
          onDropDownChange: (category: SelectBoxOption) =>
            setState({ ...state, category }),
          dropDownValue: state.category,
        };
      }
      return {};
    };

    React.useEffect(() => {
      setState({ ...state, search: "", noun: null });
      // eslint-disable-next-line
    }, [selectedMaster]);

    const ROWS = giveMeRows();

    const loading = isLoading || isFetching || dC.isLoading || dG.isLoading || dN.isLoading || dA.isLoading || dT.isLoading;

    return (
      <Box sx={classes.root}>
        {/* Header */}
        <TitleHeader
          title={`${state?.noun?.noun_name ?? ""} ${selectedMaster?.label ?? ""}`}
          count={ROWS?.length ?? 0}
          showSearch={true}
          searchPlaceholder={"Search " + selectedMaster.label}
          onSearchChange={onSearchChange}
          search={state.search}
          showCreateBtn={hasAccess}
          onCreateBtn={() =>
            openAddEditDrawer(
              false,
              null,
              selectedMaster?.value === MasterValue.template
            )
          }
          createBtnLabel={"Create"}
          {...giveMeDropDownValues()}
        />

        {/* Data Table*/}
        <Box sx={classes.dataGridParent}>
          <Grid container>
            {[MasterValue.attribute].includes(selectedMaster.value) && (
              <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                <NounListing
                  category={state.category}
                  setCategory={(category: SelectBoxOption) =>
                    setState({ ...state, category })
                  }
                  snNounData={snNounData as NounDataInterface}
                  categoryOptions={ConvertToReactSelect(
                    categoriesData?.currentData ?? [],
                    "_id",
                    "category_name"
                  )}
                  showAll={MasterValue.attribute === selectedMaster.value}
                  value={state.noun}
                  onChange={(noun: any) => setState({ ...state, noun })}
                />
              </Grid>
            )}
            <Grid
              item
              xs={12}
              {...giveMeGridBreakPoints()}
              sx={{ paddingTop: { xs: 1, sm: 0 } }}
            >
              <CustomDataGrid
                saveLocal={false}
                sx={classes.dataGrid}
                rows={ROWS}                
                columns={selectedMaster?.column({
                  edit: (data: any, isTemplate?: boolean) =>
                    openAddEditDrawer(true, data, isTemplate),
                  deleteRow,
                  duplicate,
                  hasAccess
                })}
                loading={loading}
                getRowId={(row) => row._id}
                showToolbar={true}
                rowCount={totalCount}
                paginationModel={paginationModel}
                paginationMode="server"
                onPaginationModelChange={setPaginationModel}
                sortingMode="server"
                onSortModelChange={handleSortModelChange}
              />
            </Grid>
          </Grid>
        </Box>
      </Box>
    );
  };
