import { createApi, fetchBaseQuery, retry } from "@reduxjs/toolkit/query/react";
import { config } from "../../../config";
import {
  LocalStorageKeys,
  getTransformedDataForPagination
} from "../../../utils";
import { IssueTrackerDetails, linksData } from "../../slices/issueTracker";

const prepareIssueBody = (
  payload: IssueTrackerDetails,
  linkedData?: linksData
) => {
  let body: any = {
    action: payload.basic_details.action,
    change_req:
      payload.basic_details.change_req?.label === "Yes" ? true : false,
    description: payload.basic_details.description,
    impact: payload.basic_details.impact,
    tags_info: payload.basic_details.issue_tag.map((tag) => {
      return {
        tag_group_id: tag.tag_group_id,
        tag_id: tag.value,
      };
    }),
    why_issue: payload.basic_details.why_issue ?? [],
    links: linkedData?.links ?? payload?.links_data?.links,
    unlinks: linkedData?.unlinks ?? payload?.links_data?.unlinks,
    name: payload?.basic_details?.name ?? ""
  };
  if (
    payload.basic_details.issue_category?.tag_group_id &&
    payload.basic_details.issue_category?.value
  )
    body.tags_info.push({
      tag_group_id: payload.basic_details.issue_category?.tag_group_id ?? "",
      tag_id: payload.basic_details.issue_category?.value ?? "",
    });
  if (
    payload.basic_details.issue_status?.tag_group_id &&
    payload.basic_details.issue_status?.value
  )
    body.tags_info.push({
      tag_group_id: payload.basic_details.issue_status?.tag_group_id ?? "",
      tag_id: payload.basic_details.issue_status?.value ?? "",
    });

  return body;
};

export const issueTrackerAPI = createApi({
  reducerPath: "issueTrackerAPI",
  baseQuery: retry(
    fetchBaseQuery({
      baseUrl: config.api_url + "qm/itr",
      prepareHeaders: (headers, { getState }) => {
        const token = localStorage.getItem(LocalStorageKeys.authToken);

        // If we have a token set in state, let's assume that we should be passing it.
        if (token) {
          headers.set("authorization", `Bearer ${token}`);
        }

        return headers;
      },
    }),
    { maxRetries: 1 }
  ),
  tagTypes: ["IssueTracker"],
  endpoints: (builder) => ({
    // Get Issues API
    getAllIssues: builder.query({
      query: ({
        include_histories = false,
        object_id = null,
        object_type = null,
        page = 0,
        pageSize = 10, 
        sort_by = null, 
        sort_order = null
      }) => ({
        url: `?include_histories=${include_histories}${
          object_type ? `&object_type=${object_type}` : ``
        }${object_id ? `&object_id=${object_id}` : ``}${
          page !== null ? `&page_no=${page + 1}` : ""
        }${pageSize ? `&page_size=${pageSize}` : ""}${sort_by ? `&sort_by=${sort_by}` : ''}${sort_order ? `&sort_order=${sort_order}` : ''}`,
        method: "GET",
      }),
      transformResponse: (
        data: any,
        meta: { request: Request; response?: Response }
      ) => {
        return getTransformedDataForPagination(data, meta?.response);
      },
    }),

    // Get Issue by Issue id API
    getIssueById: builder.query({
      query: ({ id }) => ({ url: `/${id}`, method: "GET" }),
    }),

    // Create Issue API
    createIssue: builder.mutation({
      query: (payload: IssueTrackerDetails) => ({
        method: "POST",
        body: { ...prepareIssueBody(payload) },
      }),
    }),

    // Update Issue API
    updateIssue: builder.mutation({
      query: ({ id, payload }) => ({
        url: `/${id}`,
        method: "PUT",
        body: { ...prepareIssueBody(payload) },
      }),
    }),

    // Delete Issue API
    deleteIssue: builder.mutation({
      query: ({ id, force }) => ({
        url: `/${id}?force=${force}`,
        method: "DELETE",
      }),
    }),
  }),
});

export const {
  useGetAllIssuesQuery,
  useLazyGetAllIssuesQuery,
  useGetIssueByIdQuery,
  useLazyGetIssueByIdQuery,
  useCreateIssueMutation,
  useUpdateIssueMutation,
  useDeleteIssueMutation,
} = issueTrackerAPI;
