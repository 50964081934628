import { createSlice } from '@reduxjs/toolkit';
import copy from 'fast-copy';
import { Association, SelectBoxOption } from '../../../interfaces';
import { DGTypeOptions, StatusOptions } from '../../../screens/viewBOM/utils';
import { UOMOptions } from '../../../screens/viewMaterial/utils';
import { Attachment } from '../material';

export interface AttributeDetail {
    attr_name: string;
    attr_type: 'Primary' | 'Secondary' | 'Tertiary'; // or whatever the possible values are
    is_mandatory: boolean;
    option_abbr: string;
    option_name: string;
    order: number;
}

export interface linksData {
    links?: { object_type: string, object_ids: string[] }[],

    unlinks?: { object_type: string, object_ids: string[] }[],
}

export interface AssemblyProcess {
    _id?: string,
    sl_no: number,
    process_name: string,
    cost: number | null,
    currency: SelectBoxOption | null
    qty: number | null,
    total_cost: number | null
}

export interface BOMSliceInterface {
    sequenceId?: string,
    version?: number,
    reference_id?: string,
    mdgType: string | number,
    bomId: string | number,
    bomDesc: string,
    bomShortDesc?: string,
    bomGroup: SelectBoxOption | null,
    bomTag: SelectBoxOption | null,
    clientRefNo: string | number,
    status: SelectBoxOption | null,
    varientId?: string | number | null,
    uom: SelectBoxOption | null,
    length: string | number,
    length_unit: SelectBoxOption | null,
    weight: string | number,
    weight_unit: SelectBoxOption | null,
    manual_desc_gen: boolean;
    region: SelectBoxOption | null,

    assemblyProcess: AssemblyProcess[],
    assemblyProcessUnlink: string[],

    bomAssemblyCost: number | null,
    childBomCost: number | null,
    materialCost: number | null,
    totalCost: number | null,

    currency?: SelectBoxOption,

    notes: string,
    attribute_details: AttributeDetail[],
    attachments: Attachment[],

    link?: any[],
    unlink?: any[],

    links_data?: linksData,

    sourceType: "external_purchase" | "internal_purchase" | "external_manufacture",
    supplierName: SelectBoxOption | null,
    manufactureName: string,
    manufactureNo: string | number,
    unitName: string,
    unitLocation: string,
    otherDetails: string,
    locked?: boolean;
    released?: boolean;
    associations?: Array<Association>;

    error: {
        mdgType: boolean,
        bomDesc: boolean,
        bomShortDesc: boolean,
        bomGroup: boolean,
        bomTag: boolean,
        clientRefNo: boolean,
        status: boolean,
        uom: boolean,
        length: boolean,
        length_unit: boolean,
        weight: boolean,
        weight_unit: boolean,
        supplierName: boolean,
        region: boolean
    }
}

export const initialState = (): BOMSliceInterface => ({
    mdgType: DGTypeOptions[0].value, bomId: "", bomDesc: "", bomShortDesc: "", bomGroup: null, bomTag: null, clientRefNo: "", status: StatusOptions[0],
    attribute_details: [], varientId: null, uom: UOMOptions[0], length: 0, length_unit: null, weight: 0, weight_unit: null,
    notes: "", links_data: { links: [], unlinks: [] }, assemblyProcess: [], assemblyProcessUnlink: [], bomAssemblyCost: 0, childBomCost: 0, materialCost: 0, totalCost: 0,
    sourceType: "external_purchase", supplierName: null, manufactureNo: "", manufactureName: "", unitName: "", unitLocation: "", otherDetails: "",
    attachments: [], manual_desc_gen: false, region: null, released: false,
    error: {
        mdgType: false, bomDesc: false, bomShortDesc: false, bomGroup: false, bomTag: false, clientRefNo: false, status: false,
        uom: false, length: false, length_unit: false, weight: false, weight_unit: false, supplierName: false, region: false
    }
})

export const bomSlice = createSlice({
    name: 'bom',
    initialState: {...initialState()},
    reducers: {
        // To Slice by key value pair
        updateBOMSliceByKeyValue(state, { payload }: { payload: { key: string, value: any } }) {
            let error: any = copy(state.error);
            error[payload.key] = payload.value ? false : true;
            return { ...state, [payload.key]: payload.value, error }
        },

        // To update errors
        updateErrors(state, { payload }: { payload: { mandateKeys: string[], callback: (updateMat: BOMSliceInterface) => void } }) {
            let error: any = copy(state.error);
            let material: any = copy(state);
            payload?.mandateKeys?.forEach((key: any) => {
                if (!material[key]) {
                    error[key] = true;
                }
            })
            payload.callback({ ...material, error })
            return { ...state, error }
        },

        // Add New Assembly Process
        addNewAssemblyProcess(state, { payload }) {
            let newAssemplyProcess = copy(state.assemblyProcess);
            newAssemplyProcess.push({
                sl_no: newAssemplyProcess.length + 1,
                process_name: "",
                cost: null,
                currency: null,
                qty: null,
                total_cost: null,
            })
            return { ...state, assemblyProcess: newAssemplyProcess }
        },

        // Update Assembly Process
        updateBOMAssemblyProcess(state, { payload }: { payload: { sl_no: number, key: keyof AssemblyProcess, value: any } }) {
            let newAssemplyProcess = copy(state.assemblyProcess);
            newAssemplyProcess[payload.sl_no - 1][payload.key] = payload.value as never;
            if (newAssemplyProcess[payload.sl_no - 1].cost && newAssemplyProcess[payload.sl_no - 1].qty) {
                let cost = newAssemplyProcess[payload.sl_no - 1]?.cost ?? 0;
                let qty = newAssemplyProcess[payload.sl_no - 1]?.qty ?? 0;
                newAssemplyProcess[payload.sl_no - 1].total_cost = cost * qty;
            }
            return { ...state, assemblyProcess: newAssemplyProcess }
        },

        deleteBOMAssemblyProcess(state, { payload }: { payload: { sl_no: number, _id: string } }) {
            let newAssemplyProcess = copy(state.assemblyProcess);
            newAssemplyProcess.splice(payload.sl_no - 1, 1);
            return { ...state, assemblyProcess: newAssemplyProcess, assemblyProcessUnlink: [...state.assemblyProcessUnlink, payload._id] }
        },

        // To reset all values to initialValue
        reset(state) {
            return { ...state, ...initialState() }
        },

        // To Bulk Update the slice
        updateBOMSlice(state, { payload }: { payload: BOMSliceInterface }) {
            return { ...state, ...payload }
        }
    }
});

export const { updateBOMSliceByKeyValue, updateBOMSlice, reset, updateErrors, updateBOMAssemblyProcess, addNewAssemblyProcess, deleteBOMAssemblyProcess } = bomSlice.actions;
export default bomSlice.reducer;