import { LoadingButton } from "@mui/lab";
import { Alert, Box, Button, Grid, Stack, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import React from "react";
import { useNavigate } from "react-router-dom";
import { HighlightSelectBox, ImageUploadList, MyPaper, TextField, DatePicker } from "../../../components";
import { SelectBoxOption } from "../../../interfaces";
import { useAppDispatch, useAppSelector } from "../../../redux";
import { useCreateProposalMutation, useGetProposalTagsQuery, useGetProjectTagsQuery, useUpdateProposalMutation, useUploadAttachmentMutation, useUploadProposalImageMutation, useDeleteAttachmentMutation, useUpdateS3AttachmentMutation } from "../../../redux/services";
import { closeConfirmationDialog, openConfirmationDialog } from "../../../redux/slices/confirmationDialog";
import { ProposalBasicDetails, ProposalSliceInterface, ProposalTags, resetProposalSlice, updateErrors, updateProposalSliceByKeyValue } from "../../../redux/slices/proposal";
import { AppRoutes } from "../../../router/routes";
import moment from "moment";
import { Notes } from "../notes";
import { closeBackdrop, openBackdrop } from "../../../redux/slices/backdrop";
import { closeDialog } from "../../../redux/slices/dialog";
import { Attachment } from "../../../redux/slices/material";
import { HasAccess } from "../../../router/authorization";
import { PERMISSIONS } from "../../../router/permission";

const mandateKeys: { parentKey: keyof ProposalSliceInterface, childKey: keyof ProposalBasicDetails | keyof ProposalTags }[] = [
    { parentKey: "basic_details", childKey: "proposal_name" },
    { parentKey: "basic_details", childKey: "customer" },
    { parentKey: "basic_details", childKey: "description" },
    { parentKey: "basic_details", childKey: "proposalManager" },
    { parentKey: "basic_details", childKey: "businessUnit" },
    { parentKey: "proposal_tags", childKey: "tags" },
];

export const BasicDetails: React.FC<{ children?: JSX.Element, proposalId: string, refetch: any }> = ({ proposalId = "New", ...props }) => {

    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const { proposal, notifications: { notifications } } = useAppSelector(store => store);
    const { perm } = useAppSelector(store => store.auth.userDetails);

    const [createProposal, createProposalData] = useCreateProposalMutation();
    const [updateProposal, updateProposalData] = useUpdateProposalMutation();
    const tagsDataProj = useGetProjectTagsQuery(null, { refetchOnMountOrArgChange: true, refetchOnFocus: true });
    const tagsData = useGetProposalTagsQuery(null, { refetchOnMountOrArgChange: true, refetchOnFocus: true });
    const [postImage, postImageData] = useUploadAttachmentMutation();
    const [uploadProposalImage, uploadProposalImageData] = useUploadProposalImageMutation();
    const [updateAttachmentProperties] = useUpdateS3AttachmentMutation();
    const [deleteAttachment, deleteResponse] = useDeleteAttachmentMutation();

    const [uploads, setUploads] = React.useState<(File | Attachment)[]>([]);
    const [primaryImageId, setPrimaryImage] = React.useState<string>("");

    const isEdit = proposalId !== "New";
    const creatable = React.useMemo(() => HasAccess(perm, PERMISSIONS.PROPOSAL_CREATE), [perm]);
    const updatable = React.useMemo(() => HasAccess(perm, PERMISSIONS.PROPOSAL_UPDATE), [perm]);
    const isViewOnly = isEdit ? !updatable : !creatable;

    const updateState = (childKey: string, value: any) => {
        dispatch(updateProposalSliceByKeyValue({ parentKey: "basic_details", childKey, value }));
    };

    const updateProposalImageUploadStatus = async (data: any) => {
        if (data?.length > 0) {
            let uploadedFiles = data?.filter((p: Attachment) => p?.url && p?.status === "notUploaded");
            let newUploads = uploads.filter((u: any) => !u?.file_name);
            if (uploadedFiles.length > 0) {
                let uploadReadyImages: any[] = [];
                uploadedFiles.forEach(async (f: Attachment, idx: number) => {
                    uploadReadyImages.push(
                        uploadProposalImage({ data: f, url: f?.url, file: newUploads.find((u: any) => u?.name === f?.file_name) }).unwrap().catch((err: any) => {
                            enqueueSnackbar(err?.data?.title ?? "Oops! Something went wrong, Unable to Proposal Image to S3", { variant: "error" })
                        }).finally(() => {
                            if (uploadedFiles.length - 1 === idx) {
                                dispatch(closeBackdrop());
                            }
                        })
                    );
                })
                await Promise.all(uploadReadyImages)
            }
        }

    };

    const uploadProposalImageHandler = async (id: string, sequence_id: string, version: number) => {
        const images = uploads.filter((u: File | Attachment) => (u as File)?.name || !(u as Attachment)?._id).map((u: File | Attachment) => ({
            _id: id,
            file_name: (u as File)?.name,
            is_primary: primaryImageId === (u as File)?.name
        }));
        if (images.length > 0) {
            enqueueSnackbar('Proposal Image uploading started...', { variant: "info" });
            await postImage({
                object_id: id,
                object_type: "proposal",
                payload: images
            }).unwrap().then(async (res: any) => {
                await updateProposalImageUploadStatus(res);
            }).catch((err: any) => {
                console.error('err:', err)
                enqueueSnackbar(err?.data?.title ?? 'Proposal Image uploading failed', { variant: "error" });
            });
        }
        if (primaryImageId) {
            const attachment = uploads.find((u: File | Attachment) => (u as Attachment)?._id === primaryImageId);
            if (!(attachment as Attachment)?.is_primary) {
                dispatch(openBackdrop("Setting a primary image..."))
                await updateAttachmentProperties({
                    object_id: id,
                    object_type: "proposal",
                    attachment_id: (attachment as Attachment)?._id,
                    is_primary: true
                }).then((res: any) => {
                    if (!res?.title) {
                        props.refetch();
                    }
                }).catch((err: any) => {
                    console.error('is_primary update err:', err)
                    enqueueSnackbar(err?.data?.title ?? "Oops! Something went wrong, Unable to set Primary Image", { variant: "error" })
                }).finally(() => dispatch(closeBackdrop()));
            }
        }
    };

    const isValid = () => {
        let isValid = true;

        for (let index = 0; index < mandateKeys.length; index++) {
            const key = mandateKeys[index];

            if (key.parentKey === 'basic_details') {
                if (!proposal.basic_details[key.childKey as keyof ProposalBasicDetails]) {
                    isValid = false;
                }
            }

            if (key.parentKey === 'proposal_tags') {
                if (key.childKey === 'tags') {
                    if (proposal.proposal_tags[key.childKey as keyof ProposalTags].length === 0) {
                        isValid = false;
                    }
                } else {
                    if (!proposal.proposal_tags[key.childKey as keyof ProposalTags]) {
                        isValid = false;
                    }
                }
            }


            if (!isValid) {
                isValid = false;
                enqueueSnackbar('Please fill mandatory fields (*)', { variant: "warning" });
                dispatch(updateErrors(mandateKeys));
                break;
            }
        }

        return isValid;
    };

    const onCreateUpdateBtnClicked = () => {
        if (isValid()) {
            if (isEdit) {
                dispatch(openConfirmationDialog({
                    title: "Update Operation:",
                    body: "Are you sure want to update?",
                    positiveBtn: "Yes",
                    negativeBtn: "No",
                    onOk: () => addEditProposal(true),
                    onNegativeBtn: () => dispatch(closeConfirmationDialog())
                }));
            } else {
                if (createProposalData.isSuccess && postImageData.isError && uploads.length > 0) {
                    uploadProposalImageHandler(createProposalData?.data?._id ?? "", createProposalData?.data?.sequence_id ?? "", createProposalData?.data?.version ?? 0);
                } else {
                    addEditProposal(false);
                }
            }
        }
    };

    const addEditProposal = async (update_version: boolean = false) => {
        dispatch(closeConfirmationDialog());
        dispatch(openBackdrop(isEdit ? "Updating proposal..." : "Creating proposal..."))
        if (isEdit) {
            await updateProposal({ id: proposalId, update_version, payload: proposal }).unwrap().then((res: any) => {
                if (!res?.title) {
                    if (uploads.length === 0 || uploadProposalImageData.isSuccess || uploadProposalImageData.isUninitialized) {
                        enqueueSnackbar('Proposal Updated Successfully!', { variant: "success" });
                        dispatch(closeBackdrop());
                        let newProposalId = res?._id ?? proposalId;
                        navigate(AppRoutes.viewProposalBasicDetail(newProposalId));
                        props.refetch();
                    }

                    if (uploads.length > 0) {
                        uploadProposalImageHandler(res?._id ?? "", res?.sequence_id ?? "", res?.version ?? "");
                    }
                }
            }).catch((error: any) => {
                enqueueSnackbar(error?.data?.title ?? 'Oops! Something went wrong unable to update proposal.', { variant: "error" })
            }).finally(() => {
                dispatch(closeBackdrop());
            });
        } else {
            await createProposal(proposal).unwrap().then((res: any) => {
                if (!res?.title) {
                    if (uploads.length === 0 || uploadProposalImageData.isSuccess || uploadProposalImageData.isUninitialized) {
                        enqueueSnackbar('Proposal Created Successfully!', { variant: "success" });
                        dispatch(closeBackdrop());
                        let newProposalId = res?._id ?? "New";
                        updateState("proposalId", newProposalId);
                        navigate(AppRoutes.viewProposalBasicDetail(newProposalId));
                        dispatch(openConfirmationDialog({
                            title: "Proposal Created",
                            body: `${res?.sequence_id ?? ""}-r0 -  is the Proposal ID, attach relevant documents.`,
                            positiveBtn: "Ok",
                            onOk: () => {
                                dispatch(closeConfirmationDialog())
                                navigate(AppRoutes.viewProposalDocuments(newProposalId));
                            },
                            hideNegativeBtn: true
                        }))
                    }

                    if (uploads.length > 0) {
                        uploadProposalImageHandler(res?._id ?? "", res?.sequence_id ?? "", res?.version ?? 0);
                    }
                }
            }).catch((error: any) => {
                console.error(`Error while creating the proposal: ${error}`)
                enqueueSnackbar(error?.data?.title ?? 'Oops! Something went wrong unable to create proposal.', { variant: "error" })
            }).finally(() => {
                dispatch(closeBackdrop());
            });
        }

    };

    const isLoading = createProposalData.isLoading || updateProposalData.isLoading;

    const giveMeButtonName = () => {
        if (createProposalData.isError) {
            return `${isEdit ? "Retry Update" : "Retry Create"}`
        }

        if (postImageData?.isError) {
            return `Re-upload Proposal Image`
        }
        return `${isEdit ? "Update" : "Create"}`;
    };

    const onDelete = (file: Attachment | File, index: number) => {
        if ((file as Attachment)?._id) {
            setUploads((prev) => [...(prev.filter((_, idx) => idx !== index))]);
            deleteAttachment({ object_id: proposalId ?? "", object_type: "proposal", attachment_id: (file as Attachment)?._id });
        } else {
            setUploads((prev) => [...(prev.filter((_, idx) => idx !== index))]);
        }
    };

    const onUpload = (file: any) => {
        if (file?.name) {
            if (!primaryImageId) {
                setPrimaryImage(file?.name)
            }
            setUploads([...uploads, file])
        }
    };

    const giveMeErrorMessage = () => {
        let message = `Oops! Something went wrong, Unable to ${isEdit ? "Update" : "Create"} Proposal. Try Again Later!`;
        let errorData: any = isEdit ? updateProposalData.error : createProposalData.error;
        message = errorData?.data?.title ?? message;
        return message;
    };

    const uploadLoading = [...(postImageData?.data || [])?.map((p: any) => {
        return notifications?.["attachment-status"]?.[p?._id]?.status !== "saved"
    })].includes(true);

    React.useEffect(() => {
        if (postImageData.isError || postImageData?.error) {
            enqueueSnackbar("Oops! Something went wrong, Unable to upload Proposal Image", { variant: "error" })
            dispatch(closeBackdrop());
            dispatch(closeDialog());
        }
        // eslint-disable-next-line
    }, [postImageData.status]);

    React.useEffect(() => {
        if (proposal.attachments?.length > 0) {
            setUploads((ups) => {
                const attachments = proposal.attachments ?? [];
                const names = proposal.attachments?.map(attachment => attachment?.file_name);
                const filteredUploads = ups.filter(upload => (upload as File)?.name && !names.includes((upload as File)?.name));
                return [...attachments, ...filteredUploads];
            });
            setPrimaryImage(proposal.attachments.find((p) => p?.is_primary)?._id || "")
        }
    }, [proposal?.attachments]);

    React.useEffect(() => {
        if (deleteResponse.isLoading) {
            dispatch(openBackdrop("Deleting proposal image..."))
            return;
        }

        if (deleteResponse.isError) {
            enqueueSnackbar((deleteResponse?.error as any)?.data ?? "Unable to delete proposal image.", { variant: "error" })
            dispatch(closeBackdrop());
        }

        if (deleteResponse.isSuccess) {
            enqueueSnackbar("Proposal image deleted successfully.", { variant: "success" })
            dispatch(closeBackdrop());
            props.refetch();
        }
        // eslint-disable-next-line
    }, [deleteResponse.status]);

    return <Stack spacing={2} mb={2}>
        {/* Basic Details Section */}
        <MyPaper padding={0}>
            <Stack justifyContent={"space-between"} height="inherit">
                <Box>
                    {/* Heading */}
                    <Typography sx={{ px: 2, py: 1, borderBottom: (theme) => `1px solid ${theme.palette.divider}` }}
                        variant="body1"
                        fontFamily={"htrts_medium"}>
                        Basic Details
                    </Typography>

                    <Grid container p={2} spacing={2}>
                        {/* Project Name */}
                        <Grid item xs={12} sm={6}>
                            <TextField
                                id="proposal-name"
                                variant="outlined"
                                size="small"
                                fullWidth
                                required={true}
                                error={proposal.error.basic_details.proposal_name}
                                helperText={proposal.error.basic_details.proposal_name ? "Please fill Proposal Name" : ""}
                                value={proposal.basic_details.proposal_name}
                                label={"Proposal Name"}
                                onChange={(e) => updateState("proposal_name", e.target.value)}
                                viewOnly={isViewOnly}
                            />
                        </Grid>

                        {/* Type */}
                        <Grid item xs={12} sm={6}>
                            <HighlightSelectBox
                                id="proposal-type"
                                label={"Type"}
                                margin={"none"}
                                required={false}
                                error={proposal.error.basic_details.type}
                                helperText={proposal.error.basic_details.type ? "Please fill Type" : ""}
                                value={proposal.basic_details.type}
                                loading={tagsData?.isLoading || tagsData?.isFetching}
                                options={tagsData.data?.propCatTags ?? []}
                                onChange={(data: SelectBoxOption) => updateState("type", data)}
                                viewOnly={isViewOnly}
                            />
                        </Grid>

                        {/* Description */}
                        <Grid item xs={12} sm={6}>
                            <TextField
                                id="proposal-description"
                                variant="outlined"
                                size="small"
                                fullWidth
                                required={true}
                                error={proposal.error.basic_details.description}
                                helperText={proposal.error.basic_details.description ? "Please fill Description" : ""}
                                value={proposal.basic_details.description}
                                label={"Description"}
                                onChange={(e) => updateState("description", e.target.value)}
                                viewOnly={isViewOnly}
                            />
                        </Grid>

                        {/* Cost */}
                        <Grid item xs={12} sm={6}>
                            <TextField
                                id="proposal-cost_center"
                                variant="outlined"
                                size="small"
                                fullWidth
                                InputLabelProps={{ shrink: proposal.basic_details.cost_center ? true : false }}
                                required={false}
                                error={proposal.error.basic_details.cost_center}
                                helperText={proposal.error.basic_details.cost_center ? "Please fill Cost or Profit Center" : ""}
                                value={proposal.basic_details.cost_center}
                                label={"Cost or Profit Center"}
                                onChange={(e) => updateState("cost_center", e.target.value)}
                                viewOnly={isViewOnly}
                            />
                        </Grid>

                        {/* Business Unit */}
                        <Grid item xs={12} sm={6}>
                            <HighlightSelectBox
                                id="proposal-businessUnit"
                                label={"Business Unit"}
                                margin={"none"}
                                required={true}
                                error={proposal.error.basic_details.businessUnit}
                                helperText={proposal.error.basic_details.businessUnit ? "Please fill business unit" : ""}
                                value={proposal.basic_details.businessUnit}
                                options={tagsDataProj?.data?.businessUnitTags ?? []}
                                loading={tagsDataProj?.isLoading || tagsDataProj?.isFetching}
                                multiple={false}
                                onChange={(data: SelectBoxOption) => updateState("businessUnit", data)}
                                viewOnly={isViewOnly}
                            />
                        </Grid>

                        {/* Customer */}
                        <Grid item xs={12} sm={6}>
                            <TextField
                                id="proposal-customer"
                                variant="outlined"
                                size="small"
                                fullWidth
                                required={true}
                                error={proposal.error.basic_details.customer}
                                helperText={proposal.error.basic_details.customer ? "Please fill customer" : ""}
                                value={proposal.basic_details.customer}
                                label={"Customer"}
                                onChange={(e) => updateState("customer", e.target.value)}
                                viewOnly={isViewOnly}
                            />
                        </Grid>

                        {/* Project Manager */}
                        <Grid item xs={12} sm={6}>
                            <TextField
                                id="proposalManager"
                                variant="outlined"
                                size="small"
                                fullWidth
                                required={true}
                                error={proposal.error.basic_details.proposalManager}
                                helperText={proposal.error.basic_details.proposalManager ? "Please fill proposal manager" : ""}
                                value={proposal.basic_details.proposalManager}
                                label={"Proposal Manager"}
                                onChange={(e) => updateState("proposalManager", e.target.value)}
                                viewOnly={isViewOnly}
                            />
                        </Grid>

                        {/* Status */}
                        <Grid item xs={12} sm={6}>
                            <HighlightSelectBox
                                id="proposal-status"
                                label={"Status"}
                                margin={"none"}
                                required={false}
                                error={proposal.error.basic_details.status}
                                helperText={proposal.error.basic_details.status ? "Please fill Status" : ""}
                                value={proposal.basic_details.status}
                                options={tagsData?.data?.propGenStatus ?? []}
                                loading={tagsData?.isLoading || tagsData?.isFetching}
                                onChange={(data: SelectBoxOption) => updateState("status", data)}
                                viewOnly={isViewOnly}
                            />
                        </Grid>

                        {/* Start Date*/}

                        <Grid item xs={12} sm={6}>
                            <DatePicker
                                value={proposal.basic_details.start_date ? moment(proposal.basic_details.start_date, 'YYYY-MM-DD') : null}
                                onChange={(date) => { updateState("start_date", date?.format('YYYY-MM-DD') ?? ""); }}
                                label="Start Date"
                                viewOnly={isViewOnly}
                            />
                        </Grid>


                        {/* End Date*/}
                        <Grid item xs={12} sm={6}>
                            <DatePicker
                                value={proposal.basic_details.finish_date ? moment(proposal.basic_details.finish_date, 'YYYY-MM-DD') : null}
                                onChange={(date) => { updateState("finish_date", date?.format('YYYY-MM-DD') ?? ""); }}
                                label="End Date"
                                viewOnly={isViewOnly}
                            />
                        </Grid>

                        {/* Reference Number */}
                        <Grid item xs={12} sm={6}>
                            <TextField
                                id="proposal-reference_number"
                                variant="outlined"
                                size="small"
                                fullWidth
                                required={false}
                                error={proposal.error.basic_details.reference_number}
                                helperText={proposal.error.basic_details.reference_number ? "Please fill Reference Number" : ""}
                                value={proposal.basic_details.reference_number}
                                label={"Reference Number"}
                                onChange={(e) => updateState("reference_number", e.target.value)}
                                viewOnly={isViewOnly}
                            />
                        </Grid>
                    </Grid>

                </Box>
            </Stack>
        </MyPaper>

        {/* Upload Image */}
        <ImageUploadList
            title={`${isViewOnly ? "Uploaded" : "Upload"} Proposal Image`}
            disabled={createProposalData.isLoading || updateProposalData.isLoading}
            loading={uploadLoading}
            files={uploads}
            maxFiles={5}
            onDelete={onDelete}
            onUpload={onUpload}
            primaryConfig={{
                primaryImageId,
                onChangePrimary: (id) => setPrimaryImage(id)
            }}
            readOnly={isViewOnly}
        />

        {/* Project Tags */}
        <MyPaper padding={0}>
            <Stack justifyContent={"space-between"} height="inherit">
                <Box>
                    {/* Heading */}
                    <Typography sx={{ px: 2, py: 1, borderBottom: (theme) => `1px solid ${theme.palette.divider}` }}
                        variant="body1"
                        fontFamily={"htrts_medium"}>
                        Proposal Tags
                    </Typography>

                    <Grid container p={2} spacing={2}>
                        {/* Tags */}
                        <Grid item xs={12} sm={6}>
                            <HighlightSelectBox
                                id="proposal-projGenTags"
                                label={"Tags"}
                                margin={"none"}
                                required={true}
                                error={proposal.error.proposal_tags.tags}
                                helperText={proposal.error.proposal_tags.tags ? "Please fill Tags" : ""}
                                value={proposal.proposal_tags.tags}
                                options={tagsDataProj?.data?.projGenTags ?? []}
                                loading={tagsDataProj?.isLoading || tagsDataProj?.isFetching}
                                multiple={true}
                                onChange={(data: SelectBoxOption[]) => dispatch(updateProposalSliceByKeyValue({ parentKey: "proposal_tags", childKey: "tags", value: data }))}
                                viewOnly={isViewOnly}
                            />
                        </Grid>
                    </Grid>

                </Box>
            </Stack>
        </MyPaper>

        {/* Notes Section */}
        <Notes viewOnly={isViewOnly} />

        {/* Footer */}
        {!isViewOnly && <MyPaper>
            <Box>
                {/* Alert Box */}
                {(createProposalData.isError || updateProposalData.isError) && <Alert sx={{ mt: 2 }} severity="error">{
                    giveMeErrorMessage()
                }</Alert>}

                {/* Footer Buttons */}
                <Stack direction={"row"} alignItems={"center"} justifyContent={"center"} spacing={1} p={1}>
                    {/* Clear All Button */}
                    <Button disabled={isLoading} variant="outlined" sx={{ width: 200 }} color="primary" onClick={() => dispatch(resetProposalSlice())} >
                        Clear All
                    </Button>

                    {/* Create Button */}
                    <LoadingButton id="u-c-btn" loading={isLoading} variant="contained" sx={{ width: 200 }} color="primary" onClick={() => onCreateUpdateBtnClicked()}>
                        {giveMeButtonName()}
                    </LoadingButton>

                    {/* Cancel Button */}
                    <Button disabled={isLoading} variant="outlined" sx={{ width: 200 }} color="primary" onClick={() => navigate(AppRoutes.projectDashboard)} >
                        Cancel
                    </Button>
                </Stack>
            </Box>
        </MyPaper>}
    </Stack>
}