import { Alert, Box, useTheme } from "@mui/material";
import { GridSortDirection, GridSortModel } from "@mui/x-data-grid-premium";
import { useSnackbar } from "notistack";
import React from "react";
import { useNavigate } from "react-router-dom";
import { CustomDataGrid, TitleHeader } from "../../../components";
import { useAppDispatch } from "../../../redux";
import { useUploadAttachmentMutation } from "../../../redux/services";
import {
  useCreateDocumentTemplateMutation,
  useDeleteDocumentTemplateMutation,
  useLazyGetDocumentTemplatesQuery,
  useLazySearchDocumentTemplatesQuery,
  useUpdateDocumentTemplateMutation,
  useUploadTemplateLogoMutation,
} from "../../../redux/services/documentTemplate";
import { closeBackdrop, openBackdrop } from "../../../redux/slices/backdrop";
import { closeConfirmationDialog, openConfirmationDialog } from "../../../redux/slices/confirmationDialog";
import { openDialog } from "../../../redux/slices/dialog";
import { giveMeHeaderFooterTemplateInitialState, Orientation, Template, TemplateResponse } from "../../../redux/slices/documentTemplate";
import { Attachment } from "../../../redux/slices/material";
import { AppRoutes } from "../../../router/routes";
import { EditTemplate, TemplateType } from "../dashboard/addEditTemplate";
import { useStyles } from "../styles";
import { TableColumn } from "./utils";

export const giveMeTemplateIndex = (
  templateType: string,
  documentTemplate: any
) => {
  switch (templateType) {
    case "headerFooter":
      return documentTemplate.headerFooterTemplate?.length;
    case "generalPage":
      return documentTemplate.generalPageTemplate?.length;
    case "coverPage":
      return documentTemplate.coverPageTemplate?.length;
    default:
      return documentTemplate.headerFooterTemplate?.length;
  }
};

const ViewTemplateList: React.FC<{ children?: JSX.Element; hasAccess: boolean }> = ({ hasAccess }) => {
  const classes = useStyles(useTheme());
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const [fetchTemplates, { data: allData = { data: [], totalCount: 0 }, isFetching, isLoading, isError }] = useLazyGetDocumentTemplatesQuery({});
  const { data, totalCount } = allData;

  const [getDocTemplatesBySearch, { data: docsFilteredData, isFetching: docsIsFetching, isLoading: docsIsLoading, isError: filterHasError }] = useLazySearchDocumentTemplatesQuery();

  const loading = isLoading || isFetching || docsIsFetching || docsIsLoading;

  const [createDocumentTemplate] = useCreateDocumentTemplateMutation();
  const [deleteDocumentTemplate] = useDeleteDocumentTemplateMutation();
  const [postImage] = useUploadAttachmentMutation();
  const [uploadLogo] = useUploadTemplateLogoMutation();
  const [updateDocumentTemplate] = useUpdateDocumentTemplateMutation();

  const [search, setSearch] = React.useState("");
  const [paginationModel, setPaginationModel] = React.useState({ page: 0, pageSize: 10 });
  const [queryOptions, setQueryOptions] = React.useState({
    sortModel: [{ field: 'sequence_id', sort: 'desc' as GridSortDirection }],
  });

  const currentTemplate = "headerFooterTemplate";
  const templateType = "headerFooter";

  const editTemplate = (template: TemplateResponse) => {
    if (hasAccess) {
      navigate(AppRoutes.viewTemplate(currentTemplate, template._id))
    }
  };

  const deleteTemplate = (data: TemplateResponse) => {
    dispatch(
      openConfirmationDialog({
        title: "Are you sure?",
        body: `Do you want to delete the template "${data?.sequence_id}"?`,
        positiveBtn: "Delete",
        negativeBtn: "Cancel",
        onOk: () => {
          performDeletion(data);
          dispatch(closeConfirmationDialog());
        },
        onNegativeBtn: () => dispatch(closeConfirmationDialog()),
      })
    );
  }

  const performDeletion = async (data: TemplateResponse) => {
    dispatch(openBackdrop("Deleting template..."));
    let res: any = {};
    try {
      res = await deleteDocumentTemplate({ document_template_id: data?._id });

      if (Object.keys(res).includes("data")) {
        enqueueSnackbar(`Deleted Template Successfully!`, {
          variant: "success",
        });
        dispatch(closeConfirmationDialog());
        fetchTemplates({ ...paginationModel });
      } else if (Object.keys(res).includes("error")) {
        // eslint-disable-next-line
        throw res.error.data;
      } else {
        // eslint-disable-next-line
        throw "Data not found";
      }
    } catch (error: any) {
      let errorMessage: string =
        error?.title ?? "Oops! Something went wrong, Unable to delete";
      enqueueSnackbar(errorMessage, { variant: "error" });
    } finally {
      dispatch(closeBackdrop());
    }
  };

  const templateCreationHandler = () => {
    dispatch(
      openDialog({
        title: "",
        hideNegativeBtn: true,
        hidePositiveBtn: true,
        enablePadding: false,
        body: (
          <EditTemplate
            onNextBtnClicked={async (template_id, templateConfig) => {
              if (template_id !== "isNew") {
                navigate(AppRoutes.viewTemplate(currentTemplate, template_id));
              } else {
                dispatch(openBackdrop("Creating Template..."));
                await createDocumentTemplate({
                  payload: {
                    orientation: templateConfig.orientation as Orientation,
                    paper_size: templateConfig.paper_size as string,
                    name: templateConfig?.name ?? "",
                  },
                  isPartial: true
                }).unwrap().then((res) => {
                  if (res?._id) {
                    navigate(AppRoutes.viewTemplate(currentTemplate, res?._id));
                  }
                  dispatch(closeBackdrop());
                  enqueueSnackbar("Template created successfully...", { variant: "success" })
                }).catch(() => {
                  dispatch(closeBackdrop());
                  enqueueSnackbar("Something went wrong, Unable to create Template", { variant: "error" })
                })
              }
            }}
            isNew
            templateType={templateType as TemplateType}
          />
        ),
      })
    );
  };

  const giveMeRows = () => {
    if (search.trim().length) {
      return docsFilteredData?.data ?? [];
    } else if ((!isLoading && !isFetching) && data && !search.trim()) {
      return data ?? [];
    } else if ((!docsIsFetching && !docsIsLoading) && docsFilteredData) {
      return docsFilteredData?.data ?? [];
    } else {
      return [];
    }
  }

  const duplicate = (data: any) => {
    let message = `Do you want to create a copy of this "${data.sequence_id} hf template"?`;
    dispatch(
      openConfirmationDialog({
        title: "Are you sure?",
        body: message,
        positiveBtn: "Create",
        negativeBtn: "Cancel",
        onOk: () => performDuplicate(data),
        onNegativeBtn: () => dispatch(closeConfirmationDialog()),
      })
    );
  };

  const updateTemplateLogoStatus = async (data: any, sequence_id: string, version: number, template: Template) => {
    if (data?.length > 0) {
      if (data?.[0]?.url) {
        let file_name = (template.logo.src as Attachment)?.file_name;
        const response = await fetch((template.logo.src as Attachment)?.url ?? "");

        // Get the binary data (blob) from the response
        const blob = await response.blob();

        // Convert the blob into an array of BlobParts
        const blobParts = [];

        // Split the blob into parts if necessary
        const chunkSize = 1024 * 1024; // Adjust this according to your requirements
        for (let i = 0; i < blob.size; i += chunkSize) {
          blobParts.push(blob.slice(i, i + chunkSize));
        }
        const type = file_name.includes("png") ? "image/png" : "image/jpeg"
        await uploadLogo({
          data: data?.[0],
          url: data?.[0]?.url,
          file: new File(blobParts, file_name, {
            type: type
          }),
        }).unwrap().catch((err: any) => {
          console.error("upload err on template: ", err);
        }).finally(async () => {
          await updateDocumentTemplate({ id: template.id, payload: { ...template, attachments: [{ _id: data?.[0]?._id }] } }).unwrap().then((res) => {
            if (Object.keys(res).includes("message")) {
              enqueueSnackbar(
                `Created copy of ${res.sequence_id} Successfully!`,
                { variant: "success" }
              );
              fetchTemplates({ ...paginationModel });
            } else if (Object.keys(res).includes("error")) {
              // eslint-disable-next-line
              throw "Unable to create copy";
            } else {
              // eslint-disable-next-line
              throw "Data not found";
            }
          }).catch((error) => {
            enqueueSnackbar(error?.data?.title ?? "Error copying template...", {
              variant: "error",
            });
          }).finally(() => {
            dispatch(closeBackdrop());
            navigate(AppRoutes.viewTemplateList);
          });
        });
      }
    }
  };

  const uploadLogoToS3 = (id: string, sequence_id: string, version: number, template: Template) => {
    dispatch(openBackdrop("Uploading Logo..."));
    postImage({
      object_id: id,
      object_type: "hfTemplate",
      payload: [
        {
          _id: id,
          file_name: (template?.logo?.src as Attachment)?.file_name,
        }
      ]
    }).unwrap().then((res: any) => {
      updateTemplateLogoStatus(res, sequence_id, version, template);
    }).catch((err: any) => {
      console.error('err:', err)
      dispatch(closeBackdrop());
    });
  };

  const performDuplicate = async (data: any) => {
    dispatch(openBackdrop(`Creating copy of "${data.sequence_id}" hf template....`));
    try {
      const extractedDigit = Math.abs(data?.name?.match(/-\d+/)?.at(-1)) || 0;

      const templateName = `${extractedDigit ? data?.name?.replace(/-\d/, '') : data.name}-${(Number(extractedDigit) ?? 1) + 1}`;

      await createDocumentTemplate({
        payload: { ...data, margin: { ...data?.margin, border: { top: false, left: false, bottom: false, right: false, ...data?.margin?.border } }, name: templateName, attachments: [] },
        isPartial: true
      }).unwrap().then((res) => {
        if (res?._id) {
          let image = data.headers.find((header: any) => header.content?.content_type === "ImageContent");
          if (image && data?.attachments?.length > 0) {
            let template = giveMeHeaderFooterTemplateInitialState({ ...data, id: res?._id, _id: res?._id, sequence_id: res?.sequence_id })
            uploadLogoToS3(res?._id, res?.sequence_id ?? "", res?.version ?? 0, template);
          } else {
            if (Object.keys(res).includes("message")) {
              enqueueSnackbar(
                `Created copy of ${res.sequence_id} Successfully!`,
                { variant: "success" }
              );
              dispatch(closeConfirmationDialog());
              dispatch(closeBackdrop());
              fetchTemplates({ ...paginationModel });
            } else if (Object.keys(res).includes("error")) {
              // eslint-disable-next-line
              throw "Unable to create copy";
            } else {
              // eslint-disable-next-line
              throw "Data not found";
            }
          }
        }
      })
    } catch (error) {
      let errorMessage: string = `Unable to create copy of ${data.sequence_id}!`;
      enqueueSnackbar(errorMessage, { variant: "error" });
      dispatch(closeBackdrop());
    } finally {
      dispatch(closeConfirmationDialog());
    }
  };

  const handleSortModelChange = React.useCallback((sortModel: GridSortModel) => {
    // Here you save the data you need from the sort model
    setQueryOptions({ sortModel: [...sortModel] });
  }, [])

  React.useEffect(() => {
    if (!search.trim().length) {
      fetchTemplates({ ...paginationModel, sort_by: queryOptions?.sortModel?.[0]?.field, sort_order: queryOptions?.sortModel?.[0]?.sort });
    }
    // eslint-disable-next-line
  }, [paginationModel, JSON.stringify(queryOptions?.sortModel), search]);

  React.useEffect(() => {
    const timeout = setTimeout(() => {
        if (search.trim().length) {
          getDocTemplatesBySearch({ ...paginationModel, sort_by: queryOptions?.sortModel?.[0]?.field, sort_order: queryOptions?.sortModel?.[0]?.sort, search });
        }
    }, 500)

    return () => clearTimeout(timeout)
    // eslint-disable-next-line react-hooks/exhaustive-deps
}, [search, paginationModel, JSON.stringify(queryOptions?.sortModel)])

  const ROWS = giveMeRows();

  return (
    <Box sx={classes.root}>
      {/* Header */}
      <Box px={2}>
        <TitleHeader
          title="All Searches"
          count={ROWS?.length ?? 0}
          search={search}
          onSearchChange={setSearch}
          searchPlaceholder={"Search"}
          showCreateBtn={hasAccess}
          createBtnLabel={"New Template"}
          onCreateBtn={() => templateCreationHandler()}
        />
      </Box>

      {/* Error */}
      {(isError || filterHasError) && (
        <Alert sx={{ mt: 2 }} severity="error">
          Oops! Something went wrong, Unable to fetch Document Templates. Try
          Again Later!
        </Alert>
      )}

      {/* Data Grid */}
      <Box mt={2}>
        <CustomDataGrid
          id="document-template-list"
          rows={ROWS}
          columns={TableColumn(editTemplate, deleteTemplate, duplicate, hasAccess)}
          loading={loading}
          getRowId={(row) => row._id}
          showToolbar={true}
          rowCount={search.trim() ? docsFilteredData?.totalCount : totalCount}
          paginationModel={paginationModel}
          paginationMode="server"
          onPaginationModelChange={setPaginationModel}
          sortingMode="server"
          onSortModelChange={handleSortModelChange}
        />
      </Box>
    </Box>
  );
};

export default ViewTemplateList;
