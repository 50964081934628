import { createApi, fetchBaseQuery, retry } from '@reduxjs/toolkit/query/react';
import { config } from '../../../config';
import { ConvertToReactSelect, LocalStorageKeys } from '../../../utils';

export const countryAPI = createApi({
    reducerPath: "countryAPI",
    baseQuery: retry(fetchBaseQuery({
        baseUrl: config.api_url + "mm/",
        prepareHeaders: (headers, { getState }) => {
            const token = localStorage.getItem(LocalStorageKeys.authToken);

            // If we have a token set in state, let's assume that we should be passing it.
            if (token) {
                headers.set('authorization', `Bearer ${token}`)
            }

            return headers
        },
    }), { maxRetries: 1 }),
    tagTypes: ['Country'],
    endpoints: (builder) => ({
        // Get Currency
        getCurrency: builder.query({
            query: () => ({ url:"currencies", method: "get" }),
            transformResponse: (response: any[], meta, arg) => ConvertToReactSelect(response, "currency_code", "currency_code").sort((a, b) => a.label.localeCompare(b.label)),
        }),
        // Get Country
        getCountry: builder.query({
            query: () => ({ url:"country", method: "get" }),
            transformResponse: (response: any[], meta, arg) => ConvertToReactSelect(response, "_id", "country_name").sort((a, b) => a.label.localeCompare(b.label)),
        })
    })
})

// Export hooks for usage in functional components
export const { useGetCurrencyQuery, useGetCountryQuery } = countryAPI
