import { createApi, fetchBaseQuery, retry } from "@reduxjs/toolkit/query/react";
import { config } from "../../../config";
import {
  ConvertToReactSelect,
  getTransformedDataForPagination,
  LocalStorageKeys,
} from "../../../utils";

export const snNounAPI = createApi({
  reducerPath: "snNounAPI",
  baseQuery: retry(
    fetchBaseQuery({
      baseUrl: config.api_url + "tm/sn/noun",
      prepareHeaders: (headers, { getState }) => {
        const token = localStorage.getItem(LocalStorageKeys.authToken);

        // If we have a token set in state, let's assume that we should be passing it.
        if (token) {
          headers.set("authorization", `Bearer ${token}`);
        }

        return headers;
      },
    }),
    { maxRetries: 2 }
  ),
  tagTypes: ["SNNoun"],
  endpoints: (builder) => ({
    // Get All SN Noun API
    getSNNouns: builder.query({
      query: ({ page = 0, pageSize = 10, sort_by = null, sort_order = null }) => ({
        url: `${page !== null ? `?page_no=${page + 1}` : ""}${
          pageSize ? `&page_size=${pageSize}` : ""
        }${sort_by ? `&sort_by=${sort_by}` : ''}${sort_order ? `&sort_order=${sort_order}` : ''}`,
        method: "GET",
      }),
      transformResponse: (
        data: any,
        meta: { request: Request; response?: Response }
      ) => {
        return getTransformedDataForPagination(data, meta?.response);
      },
      providesTags: ["SNNoun"],
    }),

    // Get All SN Noun API for dropdown
    getSNNounsDD: builder.query({
      query: () => ({ method: "GET" }),
      transformResponse: (response: any[], meta, arg) =>
        ConvertToReactSelect(response, "_id", "noun_name"),
      providesTags: ["SNNoun"],
    }),

    // // Get Noun based on Category ID and Group ID API for dropdown
    // getNounsMappingDD: builder.query({
    //   query: ({ category_id, group_id }) => {
    //     return {
    //       url: `?category_id=${category_id}?group_id=${group_id}`,
    //       method: "GET",
    //     };
    //   },
    //   transformResponse: (response: any[], meta, arg) =>
    //     ConvertToReactSelect(response, "_id", "noun_name"),
    // }),

    // // Search Noun API
    // searchNouns: builder.query({
    //   query: (payload) => {
    //     let url = payload.search ? `noun?search=${payload.search}` : "noun";
    //     if (payload.category_id)
    //       url += `${payload.search ? "&" : "?"}category_id=${
    //         payload.category_id
    //       }`;
    //     return { url, method: "GET" };
    //   },
    // }),

    // Create SN Noun API
    createSNNoun: builder.mutation({
      query: (payload) => ({ method: "POST", body: payload }),
      invalidatesTags: ["SNNoun"],
    }),

    // Update SN Noun API
    updateSNNoun: builder.mutation({
      query: ({ id, payload }) => ({
        url: `${id}`,
        method: "PUT",
        body: payload,
      }),
      invalidatesTags: ["SNNoun"],
    }),

    // Delete SN Noun API
    deleteSNNoun: builder.mutation({
      query: ({ id }) => ({ url: `${id}`, method: "DELETE" }),
      invalidatesTags: ["SNNoun"],
    }),
  }),
});

// Export hooks for usage in functional components
export const {
  useGetSNNounsQuery,
  useGetSNNounsDDQuery,
  //   useGetNounsMappingDDQuery,
  useLazyGetSNNounsQuery,
  //   useLazySearchNounsQuery,
  useCreateSNNounMutation,
  useUpdateSNNounMutation,
  useDeleteSNNounMutation,
} = snNounAPI;
