
import React from "react";
import { DeletePanel, Layout, MyPaper } from "../../../components";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { RenderPreview } from "./preview";
import { Alert, Box, CircularProgress, Tab, Tabs } from "@mui/material";
import { setOpenWebSideNavBar } from "../../../redux/slices/utils";
import { useAppDispatch } from "../../../redux";
import { createOption } from "../../../components/keywordSearch";
import { RenderFlatView } from "./flatview";
import { useSnackbar } from "notistack";
import { useGetBOMSparePartsTagsQuery, useGetChildStatusTagsQuery, useGetProcessingStatusQuery, useLazyGetListViewByIdQuery } from "../../../redux/services";
import { SelectBoxOption } from "../../../interfaces";
import { QueryStatus } from "@reduxjs/toolkit/dist/query";
import { openConfirmationDialog } from "../../../redux/slices/confirmationDialog";

export type ListViewChildProps = {
    listViewResponse: any;
    sparePartsOptions: SelectBoxOption[];
    sparePartStatus: QueryStatus;
    childStatusOptions: SelectBoxOption[];
    childStatus: QueryStatus;
    openAssociation: (document: any) => any;
    openReferences: (document: any) => any
}

const allTabs = [createOption("Flat View"), createOption("Tree View")];

const ListViewPreview: React.FC<{ children?: JSX.Element, currentPath: string, customAssociation?: { object_type: string, object_id: string }, avoidNavigate?: boolean, handleParentDialogClose?: any }> = ({ currentPath, avoidNavigate = false, handleParentDialogClose, ...props }) => {

    const { id = "New" } = useParams();
    const location: any = useLocation();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const [getListViewById, listViewResponse] = useLazyGetListViewByIdQuery();

    const { data: processingStatus } =
        useGetProcessingStatusQuery({ id, type: "bom_list_view" }, {
            pollingInterval: 3000,
            skip: id === "New" || listViewResponse?.data?.processing === false
        })

    const sparePartsData = useGetBOMSparePartsTagsQuery(null, {
        refetchOnMountOrArgChange: true,
    });

    const materialStatusData = useGetChildStatusTagsQuery("material", {
        refetchOnMountOrArgChange: true,
    });

    const sparePartsOptions = sparePartsData.data?.spareParts ?? [];
    const childStatusOptions = materialStatusData?.data?.childStatus ?? [];

    const { enqueueSnackbar } = useSnackbar();

    const [currentTab, setCurrentTab] = React.useState(allTabs[0].value)

    const fetchListView = async () => {
        await getListViewById({ id: `${id}` }).unwrap().then((res: any) => {
            if (!res?._id) {
                enqueueSnackbar('Something went wrong, Unable to Fetch List View', { variant: "error" })
            }
        }).catch(err => {
            console.error('err:', err)
            enqueueSnackbar(err?.data?.title ?? 'Something went wrong, Unable to Fetch List View', { variant: "error" })
        });
    };

    const openAssociation = (document: any) => {
        dispatch(openConfirmationDialog({
            title: "Associations",
            body: <DeletePanel
                message={document?.associations ? document.associations.length === 0 ? 'No dependency found' : `Dependency Table of ${document?.sequence_id}-r${document.version}` : "No dependency found"}
                errorMessageStacks={document?.associations ?? []}
            />,
            hideNegativeBtn: true,
            hidePositiveBtn: true
        }))
    };

    const openReferences = (document: any) => {
        dispatch(openConfirmationDialog({
            title: "References",
            body: <DeletePanel
                message={document?.references ? document.references.length === 0 ? 'No references found' : `References Table of ${document?.sequence_id}-r${document.version}` : "No references found"}
                errorMessageStacks={document?.references ?? []}
            />,
            hideNegativeBtn: true,
            hidePositiveBtn: true
        }))
    };

    React.useEffect(() => {
        fetchListView();
        // eslint-disable-next-line
    }, []);

    const giveMeComponent = () => {
        return (
            <MyPaper height={"100%"}>
                <>
                    {processingStatus?.processing === true ?
                        <Alert
                            severity="info"
                            iconMapping={{
                                info: <CircularProgress size={"1em"} color="info" />,
                            }}>
                            Processing list view...
                        </Alert>
                        :
                        (
                            <>
                                <Tabs
                                    value={currentTab}
                                    onChange={(e, tab) => setCurrentTab(tab)}
                                    variant="scrollable"
                                    scrollButtons="auto"
                                    indicatorColor="primary"
                                    textColor="primary"
                                >
                                    {allTabs.map(tab => <Tab key={tab.value} sx={{ fontSize: 14 }} value={tab.value} label={tab.label} wrapped />)}
                                </Tabs>
                                {currentTab === allTabs[0].value && <RenderFlatView openAssociation={openAssociation} openReferences={openReferences} listViewResponse={listViewResponse} sparePartsOptions={sparePartsOptions} childStatusOptions={childStatusOptions} sparePartStatus={sparePartsData?.status} childStatus={materialStatusData?.status} />}
                                {currentTab === allTabs[1].value && <RenderPreview openAssociation={openAssociation} openReferences={openReferences} listViewResponse={listViewResponse} sparePartsOptions={sparePartsOptions} childStatusOptions={childStatusOptions} sparePartStatus={sparePartsData?.status} childStatus={materialStatusData?.status} />}
                            </>
                        )
                    }
                </>
            </MyPaper>
        );

    };

    const paths = location.state?.paths ?? [];

    React.useEffect(() => {
        dispatch(setOpenWebSideNavBar(false));
        // eslint-disable-next-line
    }, [])

    return <Box p={0.5} px={0.8} height={"100%"}>
        <Layout
            history={[
                ...paths.map((_: any) => {
                    return { label: _.title, onClick: () => navigate(_.path) }
                }),
                { label: currentPath, onClick: () => false },
                { label: listViewResponse.isLoading ? '...' : listViewResponse.data?.sequence_id, onClick: () => false },
            ]}
            currentPath={currentTab}
            navBars={[]}
            mainPanel={giveMeComponent()}
            hideSideNavBar={false}
            sideNavVariant={"whiteboard"}
            otherBreadscrumProps={{
                hideMenuBtn: true,
                showBackBtn: false,
            }}
            avoidNavigate={avoidNavigate}
            onBackBtnClick={paths[0]?.path ? () => navigate(paths[0]?.path) : undefined}
        />
    </Box>
}

export default ListViewPreview;