import { createApi, fetchBaseQuery, retry } from "@reduxjs/toolkit/query/react";
import { config } from "../../../config";
import {
  getTransformedDataForPagination,
  LocalStorageKeys,
  ConvertToReactSelect
} from "../../../utils";

export type FormatType = "Attribute" | "Separator" | "Counter" | "Label";

export interface FormatConfig {
  attribute_id?: string;
  option_id?: string;
  position: number;
  type: FormatType;
  value: string;
}

export interface ISNTemplate {
  _id: string;
  category_id: string;
  category_name: string;
  created_time: string;
  created_user: string;
  created_user_id: string;
  format_config: FormatConfig[];
  group_id: string;
  group_name: string;
  modified_time: string;
  modified_user: string;
  modified_user_id: string;
  name: string;
  noun_id: string;
  noun_name: string;
  sequence_id: string;
  status: string;
  version: number;
}

export const snTemplateAPI = createApi({
  reducerPath: "snTemplateAPI",
  baseQuery: retry(
    fetchBaseQuery({
      baseUrl: config.api_url + "tm/sn/template",
      prepareHeaders: (headers) => {
        const token = localStorage.getItem(LocalStorageKeys.authToken);

        // If we have a token set in state, let's assume that we should be passing it.
        if (token) {
          headers.set("authorization", `Bearer ${token}`);
        }

        return headers;
      },
    }),
    { maxRetries: 2 }
  ),
  tagTypes: ["SNTemplates", "SNTemplate"],
  endpoints: (builder) => ({
    // Get SN Templates API
    getSNTemplates: builder.query({
      query: ({ page = 0, pageSize = 10, sort_by = null, sort_order = null }) => ({
        url: `${page !== null ? `?page_no=${page + 1}` : ""}${
          pageSize ? `&page_size=${pageSize}` : ""
        }${sort_by ? `&sort_by=${sort_by}` : ''}${sort_order ? `&sort_order=${sort_order}` : ''}`,
        method: "GET",
      }),
      transformResponse: (
        data: any,
        meta: { request: Request; response?: Response }
      ) => {
        return getTransformedDataForPagination(data, meta?.response);
      },
      providesTags: ["SNTemplates"],
    }),

    // Get SN Template By ID API
    getSNTemplateById: builder.query({
      query: ({ id }) => ({ url: `${id}`, method: "GET" }),
      providesTags: ["SNTemplate"],
    }),

    // Get All SN Template as Options
    getSNTemplateDropDownOptions: builder.query({
      query: () => ({
        method: "GET",
      }),
      transformResponse: (response: any[]) => ConvertToReactSelect(response, "_id", "sequence_id", ['name']),
      providesTags: ["SNTemplates"],
    }),

    // Create SN Template API
    createSNTemplate: builder.mutation({
      query: (payload) => ({ method: "POST", body: payload }),
      invalidatesTags: ["SNTemplates"],
    }),

    // Update SN Template API
    updateSNTemplate: builder.mutation({
      query: ({ id, payload }) => ({
        url: `${id}`,
        method: "PUT",
        body: payload,
      }),
      invalidatesTags: ["SNTemplates"],
    }),

    // Delete SN Template API
    deleteSNTemplate: builder.mutation({
      query: ({ id, force = false }) => ({
        url: `${id}${force ? `?force=${force}` : ""}`,
        method: "DELETE",
      }),
      invalidatesTags: ["SNTemplates", "SNTemplate"],
    }),
  }),
});

// Export hooks for usage in functional components
export const {
  useGetSNTemplatesQuery,
  useGetSNTemplateByIdQuery,
  useLazyGetSNTemplateByIdQuery,
  useLazyGetSNTemplatesQuery,
  useCreateSNTemplateMutation,
  useUpdateSNTemplateMutation,
  useDeleteSNTemplateMutation,
  useGetSNTemplateDropDownOptionsQuery,
  useLazyGetSNTemplateDropDownOptionsQuery
} = snTemplateAPI;
