import React from "react";
import { BaseTextFieldProps, FilledTextFieldProps, OutlinedTextFieldProps, Stack, StandardTextFieldProps, TextField as TF, Typography } from "@mui/material";

type Variant = StandardTextFieldProps | FilledTextFieldProps | OutlinedTextFieldProps;

type CustomTextFieldProps = BaseTextFieldProps & Variant & {
    viewOnly?: boolean
}

export const TextField: React.FC<CustomTextFieldProps> = ({ viewOnly = false, ...props }) => {

    const value: any = typeof props.value === "string" ? props.value || "N/A" : props.value || 0;

    return (
        viewOnly ? (
            <Stack sx={{ overflowWrap: "break-word" }}>
                <Typography variant="body1" sx={{ fontFamily: "htrts_medium" }}>{props.label}</Typography>
                <Typography variant="body2" sx={{ fontFamily: "htrts_regular" }}>{value ? value : "-"}</Typography>
            </Stack>
        ) : <TF
            {...props}
        />

    )
};
