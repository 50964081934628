import { AccountTree, ChevronRight, Delete, ExpandLess, StopRounded } from '@mui/icons-material';
import TreeItem, { TreeItemProps, treeItemClasses } from '@mui/lab/TreeItem';
import { Button, Stack, Tooltip, Typography, MenuItem, ListItemText, ListItemIcon, Menu } from "@mui/material";
import Collapse from '@mui/material/Collapse';
import { alpha, styled } from '@mui/material/styles';
import { TransitionProps } from '@mui/material/transitions';
import { animated, useSpring } from '@react-spring/web';
import React from 'react';


function TransitionComponent(props: TransitionProps) {
    const style = useSpring({
        from: {
            opacity: 0,
            transform: 'translate3d(20px,0,0)',
        },
        to: {
            opacity: props.in ? 1 : 0,
            transform: `translate3d(${props.in ? 0 : 20}px,0,0)`,
        },
    });

    return (
        <animated.div style={style}>
            <Collapse {...props} />
        </animated.div>
    );
}

interface CustomTreeItemInterface extends TreeItemProps {
    isExpand: boolean,
    customTitle: string,
    showAddIcon: boolean,
    onAddIconClicked?: (type: 'item' | 'assembly') => void,
    onDeletIconClicked?: () => void,
    showAssembleIcon?: boolean,
    showItemIcon?: boolean,
    selected: string,
    showEndIcon?: boolean,
    type: 'item' | 'assembly'
}

export const CustomTreeItem = styled((props: CustomTreeItemInterface) => {

    const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | HTMLLIElement | null>(null);

    const handleClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent> | React.MouseEvent<HTMLLIElement, MouseEvent>) => {
        event.preventDefault();
        if (props.selected === props.nodeId) {
            setAnchorEl(event.currentTarget);
        } else {
            setAnchorEl(null);
        }
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'treeItem-' + props.nodeId : undefined;

    return <>
        <TreeItem {...props} TransitionComponent={TransitionComponent} label={
            <Stack onContextMenu={handleClick} direction={"row"} justifyContent={"space-between"} alignItems={"center"} my={0.5}>
                <Stack direction={"row"} gap={0.5}>
                    {props.showAssembleIcon && <AccountTree fontSize='small' />}
                    {props.showItemIcon && <StopRounded fontSize='small' />}
                    <Typography variant='body2'>{props.customTitle}</Typography>
                </Stack>

                <Stack direction={"row"} gap={1}>
                    {false && <Tooltip title="Add Assembly">
                        <Button sx={{ minWidth: "unset", width: "20px", height: "20px", zIndex: 999 }} variant="outlined" size="small">+</Button>
                    </Tooltip>}
                    {props.showAddIcon && <>
                        {!props.isExpand && <ChevronRight fontSize='small' />}
                        {props.isExpand && <ExpandLess fontSize='small' />}
                    </>}
                </Stack>
            </Stack>
        } />
        <Menu
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
        >
            {props.type === 'assembly' && props.showAddIcon && <MenuItem onClick={() => {
                handleClose();
                if (props.onAddIconClicked) {
                    props.onAddIconClicked('assembly')
                }
            }}>
                <ListItemIcon>
                    <AccountTree fontSize='small' />
                </ListItemIcon>
                <ListItemText>Add Assembly</ListItemText>
            </MenuItem>}
            {props.type === 'assembly' &&<MenuItem onClick={() => {
                handleClose();
                if (props.onAddIconClicked) {
                    props.onAddIconClicked('item')
                }
            }}>
                <ListItemIcon>
                    <StopRounded fontSize='small' />
                </ListItemIcon>
                <ListItemText>Add Item</ListItemText>
            </MenuItem>}
            <MenuItem onClick={() => {
                handleClose();
                if (props.onDeletIconClicked) {
                    props.onDeletIconClicked()
                }
            }}>
                <ListItemIcon>
                    <Delete fontSize='small' />
                </ListItemIcon>
                <ListItemText>Delete</ListItemText>
            </MenuItem>
        </Menu>
    </>
})(({ theme }) => ({
    [`& .${treeItemClasses.iconContainer}`]: {
        display: "none !important",
        '& .close': {
            opacity: 0.3,
        },
    },
    [`& .${treeItemClasses.group}`]: {
        marginLeft: 12,
        paddingLeft: 0,
        borderLeft: `1px dashed ${alpha(theme.palette.text.primary, 0.4)}`,
    },
}));