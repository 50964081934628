import React from 'react';
import { KeyboardDoubleArrowDown as ExpandLess, KeyboardDoubleArrowRight as ExpandMore } from '@mui/icons-material';
import { Box, Collapse, Skeleton, Stack, Typography } from '@mui/material';
import { CollapsableOption } from '../../components/collapsableOptions';
import { AttributeInterface, AttributeOptionInterface } from '../../interfaces';

export const Attributes: React.FC<{
    id: string;
    title: string,
    value: any,
    onChange: (data: AttributeInterface, option: AttributeOptionInterface) => any,
    options: AttributeInterface[],
    loading?: boolean
}> = (props) => {

    const [isExpand, setIsExpand] = React.useState(true);

    const toogle = () => { setIsExpand(!isExpand) }

    return <Box>
        {/* Title */}
        <Box
            onClick={toogle}
            sx={{
                cursor: "pointer", width: "100%", padding: 0.5,
                borderBottom: (theme) => '1px solid ' + theme.palette.divider,
                '&:hover': { background: (theme) => theme.palette.action.hover, borderRadius: 1 }
            }}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"space-between"}
        >
            <Typography variant={"body1"} sx={{ fontFamily: "htrts_medium" }}>
                {props.title}
            </Typography>
            {isExpand ? <ExpandLess /> : <ExpandMore />}
        </Box>

        {/* Options */}
        <Collapse in={isExpand} timeout="auto" unmountOnExit>
            <Stack spacing={0.5}>

                {/* For Loading */}
                {props?.loading && Array.from(Array(5).keys()).map((_:number,key:number) => {
                    return <Skeleton key={key} />
                })}

                {/* For Displaying Data */}
                {!props?.loading && props?.options?.map((option: any, key: number) => {
                    return <React.Fragment key={key}>
                        <CollapsableOption
                            id={props.id}
                            title={option?.attr_name}
                            options={option?.options?.map((_: any) => ({ ..._, value: _.option_id, label: _.option_name })) ?? []}
                            value={props?.value?.[option?._id]?.option}
                            onChange={(data: any) => props.onChange(option, data)}
                            isMandatory={option?.isMandatory}
                        />
                    </React.Fragment>
                })}

                {/* For No Options Sent */}
                {(!props.loading && props.options.length === 0) && <Typography sx={{width:"100%", textAlign:"center", marginTop: 2}} variant={"caption"} color={"textSecondary"}>{`${props.title} Not Found!`}</Typography>}
            </Stack>
        </Collapse>
    </Box>
}