import { createSlice } from '@reduxjs/toolkit';
import copy from 'fast-copy';
import { ReferenceCardRow } from '../../../components';
import { SelectBoxOption } from '../../../interfaces';
import { MDGTypeOptions, StatusOptions, UOMOptions } from '../../../screens/viewMaterial/utils';

export interface DetailedSpecification {
    id: number | string,
    location_tag: SelectBoxOption | null,
    document_tag: SelectBoxOption | null,
    manualy: string
}

export interface AttributeDetail {
    attr_name: string;
    attr_type: 'Primary' | 'Secondary' | 'Tertiary'; // or whatever the possible values are
    is_mandatory: boolean;
    option_abbr: string;
    option_name: string;
    order: number;
    reference_metadata: ReferenceCardRow[];
}

export interface ILinking { object_type: string, object_ids: string[] }

export interface linksData {
    links?: ILinking[],

    unlinks?: ILinking[],
}

export interface Attachment {
    file_name: string;
    status: string;
    _id: string;
    url?: string;
    is_primary?: boolean;
}

export interface MatSliceInterface {
    sequenceId?: string,
    version?: number,
    mdgType: string | number,
    matId: string | number,
    matDesc: string,
    matShortDesc?: string,
    materialGroup: SelectBoxOption | null,
    materialTag: SelectBoxOption | null,
    region: SelectBoxOption | null,
    clientRefNo: string | number,
    costingType: SelectBoxOption | null,
    bomType: SelectBoxOption | null,
    drawingNumber: string | number,
    status: SelectBoxOption | null,
    physicalMaterial: SelectBoxOption | null,
    rawMaterial: SelectBoxOption | null,
    inventoryType: SelectBoxOption | null,
    varientId?: string | number | null,
    cost: string | number | null,
    currency: SelectBoxOption | null,
    uom: SelectBoxOption | null,
    length: string | number,
    length_unit: SelectBoxOption | null,
    weight: string | number,
    weight_unit: SelectBoxOption | null,
    lead_time: number | null,

    sourceType: "external_purchase" | "internal_purchase" | "external_manufacture",
    supplierName: SelectBoxOption | null,
    manufactureName: string,
    manufactureNo: string | number,
    unitName: string,
    unitLocation: string,
    otherDetails: string,

    detailedSpecification: DetailedSpecification[],
    notes: string,
    preferedModelManufName: string,
    preferedModelManufNo: string,
    attribute_details: AttributeDetail[],
    attachments: Attachment[]

    link?: any[],
    unlink?: any[],

    links_data?: linksData,
    locked?: boolean;
    released?: boolean;
    associations?: any[];

    error: {
        mdgType: boolean,
        matDesc: boolean,
        matShortDesc: boolean,
        materialGroup: boolean,
        materialTag: boolean,
        region: boolean
        clientRefNo: boolean,
        costingType: boolean,
        bomType: boolean,
        drawingNumber: boolean,
        status: boolean,
        physicalMaterial: boolean,
        rawMaterial: boolean,
        inventoryType: boolean,
        sourceType: boolean,
        supplierName: boolean,
        manufactureName: boolean,
        manufactureNo: boolean,
        unitName: boolean,
        unitLocation: boolean,
        otherDetails: boolean,
        cost: boolean,
        currency: boolean,
        uom: boolean,
        length: boolean,
        length_unit: boolean,
        weight: boolean,
        weight_unit: boolean,
        lead_time: boolean,
    }
}

const initialState: MatSliceInterface = {
    mdgType: MDGTypeOptions[0].value, matId: "", matDesc: "", matShortDesc: "", materialGroup: null, materialTag: null, clientRefNo: "", costingType: null, bomType: null,
    drawingNumber: "", status: StatusOptions[0], physicalMaterial: null, rawMaterial: null, inventoryType: null, sourceType: "external_purchase", attribute_details: [],
    supplierName: null, manufactureNo: "", manufactureName: "", unitName: "", unitLocation: "", otherDetails: "", varientId: null, cost: "", currency: null,
    uom: UOMOptions[0], length: 0, length_unit: null, weight: 0, weight_unit: null, detailedSpecification: [], notes: "", preferedModelManufName: "",
    preferedModelManufNo: "", links_data: { links: [], unlinks: [] }, lead_time: null, attachments: [], associations: [], region: null, released: false, sequenceId: "",
    error: {
        mdgType: false, matDesc: false, matShortDesc: false, materialGroup: false, materialTag: false, clientRefNo: false, costingType: false, bomType: false,
        drawingNumber: false, status: false, physicalMaterial: false, rawMaterial: false, inventoryType: false, sourceType: false, supplierName: false,
        manufactureName: false, manufactureNo: false, unitName: false, unitLocation: false, otherDetails: false, cost: false, currency: false,
        uom: false, length: false, length_unit: false, weight: false, weight_unit: false, lead_time: false, region: false
    }
}

export const materialSlice = createSlice({
    name: 'material',
    initialState,
    reducers: {
        // To Slice by key value pair
        updateMatSliceByKeyValue(state, { payload }: { payload: { key: string, value: any } }) {
            let error: any = copy(state.error);
            error[payload.key] = payload.value ? false : true;
            return { ...state, [payload.key]: payload.value, error }
        },

        // To update errors
        updateErrors(state, { payload }: { payload: { mandateKeys: string[], callback: (updateMat: MatSliceInterface) => void } }) {
            let error: any = copy(state.error);
            let material: any = copy(state);
            payload?.mandateKeys?.forEach((key: any) => {
                if (!material[key]) {
                    error[key] = true;
                }
            })
            payload.callback({ ...material, error })
            return { ...state, error }
        },

        // To reset all values to initialValue
        reset(state) {
            return { ...state, ...initialState, lead_time: null }
        },

        // To Bulk Update the slice
        updateMatSlice(state, { payload }) {
            return { ...state, ...payload }
        },
    }
});

export const { updateMatSliceByKeyValue, updateMatSlice, reset, updateErrors } = materialSlice.actions;
export default materialSlice.reducer;