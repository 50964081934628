import React from "react";
import { AccountTree, ClearAll, Close, Save } from "@mui/icons-material";
import { Box, Button, Grid, IconButton, Stack, Typography, FormControlLabel, Checkbox, Tooltip, Chip, useTheme } from "@mui/material";
import { MyPaper } from "../../../components";
import { useAppDispatch, useAppSelector } from "../../../redux";
import { closeDialog } from "../../../redux/slices/dialog";
import { PMPVariantInterface, PMPVariantValueInterface, SelectionInterface, SelectionInterfaceOptionMapping, SelectionInterfaceOptions, updateSelectionOptionDetail, updateVariants } from "../../../redux/slices/pmp";
import copy from "fast-copy";
import { randomNameGenerator } from "../../../utils";
import { cartesian } from "../../../utils/other";

export const DependencyMap: React.FC<{
    children?: JSX.Element, option: SelectionInterfaceOptions, selIndex: number, leftTitle?: string, rightTitle?: string,
    selection: SelectionInterface, optIndex: number, hideSaveBtn?: boolean, height?: string, pt?: number
}> = ({
    option, selIndex, selection, optIndex, hideSaveBtn = false,
    height = "300px", pt = 3, leftTitle = "Filter with option", rightTitle = "Filter with variant"
}) => {

        const dispatch = useAppDispatch();
        const { selections, variants } = useAppSelector((store) => store.pmp);
        const [state, setState] = React.useState<SelectionInterfaceOptionMapping>(copy(option.optMapping));
        const currentVariant = variants[selections[selIndex - 1].id];
        const [selVariants, setSelVariants] = React.useState<any[]>([]);

        const saveBtnClicked = () => {

            let newVariants: PMPVariantInterface = {};

            selections.forEach((sel, selIndex) => {
                let selVariants: PMPVariantValueInterface[] = [];

                if (selIndex === 0) {
                    sel.options.forEach((opt, optIndex) => {
                        selVariants.push({
                            id: randomNameGenerator(12),
                            name: opt.abbr,
                            options: { [sel.id + "-" + opt.id]: { selection: sel, option: opt } }
                        })
                    })
                } else {
                    newVariants[selections[selIndex - 1].id].forEach((preVar, preVarIndex) => {
                        sel.options.forEach((opt, optIndex) => {
                            let optMapping = sel.id === selection.id && opt.id === option.id ? state : opt.optMapping;
                            if (Object.keys(optMapping).length) {
                                if (isIamChecked(preVar, optMapping, selIndex)) {
                                    selVariants.push({
                                        id: randomNameGenerator(12),
                                        name: preVar.name + " - " + opt.abbr,
                                        options: {
                                            ...preVar.options,
                                            [sel.id + "-" + opt.id]: { selection: sel, option: opt }
                                        }
                                    })
                                }
                            } else {
                                selVariants.push({
                                    id: randomNameGenerator(12),
                                    name: preVar.name + " - " + opt.abbr,
                                    options: {
                                        ...preVar.options,
                                        [sel.id + "-" + opt.id]: { selection: sel, option: opt }
                                    }
                                })
                            }
                        })
                    })
                }

                newVariants[sel.id] = selVariants;
            });

            dispatch(updateSelectionOptionDetail({ selIndex, optIndex, ...option, optMapping: state }));
            dispatch(updateVariants(newVariants));
            dispatch(closeDialog());
        }

        const onChipClicked = (opt: SelectionInterfaceOptions, optIndex: number, selection: SelectionInterface, _selIndex: number) => {
            if (state[selection.id]) {
                if (state[selection.id].options[selection.id + "-" + opt.id]) {
                    delete state[selection.id].options[selection.id + "-" + opt.id];
                } else {
                    state[selection.id].options[selection.id + "-" + opt.id] = opt;
                }
            } else {
                state[selection.id] = { selection, options: { [selection.id + "-" + opt.id]: opt } }
            }
            setState({ ...state });
        };

        const clearAll = () => { setState({ ...{} }) };

        const isIamChecked = (_var: PMPVariantValueInterface, optMapping: SelectionInterfaceOptionMapping = state, ___selIndex: number) => {
            let keys: string[][] = [];
            let keyValues = Object.values(optMapping);

            keyValues.forEach(_ => {
                if (Object.keys(_.options).length) {
                    keys.push(Object.keys(_.options));
                }
            });

            let allVar = keys.length === 1 ? keys[0] : cartesian(...keys);

            if (!allVar || allVar?.length === 0) {
                return false;
            }

            let varKeys = Object.keys(_var.options).sort();

            let checked = false;

            for (let i = 0; i < allVar?.length; i++) {
                const element = allVar[i];
                if (typeof element === "string") {
                    checked = varKeys.includes(element);
                } else {
                    if (element.length > varKeys.length) {
                        let regex = new RegExp(varKeys.join('(.*)'), 'gm');
                        checked = regex.exec(element.toString()) === null ? false : true;
                    } else {
                        let regex = new RegExp(element.join('(.*)'), 'gm');
                        checked = regex.exec(varKeys.toString()) === null ? false : true;
                    }
                }
                if (checked) {
                    break;
                }
            }

            return checked;

        }

        React.useEffect(() => {
            let newSelVariants: any[] = [];
            currentVariant.forEach((_var, varIndex) => {
                if (isIamChecked(_var, state, selIndex)) {
                    newSelVariants.push(_var.id);
                }
            });
            setSelVariants(newSelVariants);
            // eslint-disable-next-line
        }, [state]);

        return <Box pt={pt}>
            {/* Header */}
            <Stack direction={"row"} sx={{
                borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
                position: "absolute", top: 0, left: 0, width: "100%", background: (theme) => theme.palette.background.paper
            }} spacing={1} pt={1} px={1} alignItems="center" justifyContent="flex-start">
                {/* Mapping Icon */}
                <IconButton size="small">
                    <AccountTree htmlColor="black" fontSize="small" />
                </IconButton>

                {/* Title */}
                <Typography gutterBottom variant="body1" color="textPrimary" sx={{ fontFamily: "htrts_semibold" }}>{`${option.desc} - Applicable For`}</Typography>
            </Stack>

            {/* Content */}
            <Grid container alignItems={"stretch"}>
                {/* Filter with Option */}
                <Grid item xs={12} sm={6} sx={{ pr: { xs: 0, sm: 1 }, position: "relative", borderRight: (theme) => ({ xs: '', sm: `1px solid ${theme.palette.divider}` }) }}>
                    {/* Title */}
                    <Heading title={leftTitle} variant="button" onBtnClick={clearAll} />

                    {/* Selections */}
                    <Box sx={{ height, overflow: "scroll" }}>
                        <SelectionChip selections={selections} onChipClicked={onChipClicked} state={state} limit={selIndex} />
                    </Box>

                    {/* Footer */}
                    {!hideSaveBtn && <Stack sx={{ position: "absolute", bottom: 0, width: "100%", left: "-12px", background: (theme) => theme.palette.background.paper }} spacing={1} mt={2} direction={"row"} alignItems={"center"} justifyContent={"center"}>
                        <Button size={"small"} fullWidth onClick={(e) => saveBtnClicked()} variant="contained" color="primary" startIcon={<Save />}>Save</Button>
                        <Button size={"small"} fullWidth onClick={(e) => dispatch(closeDialog())} variant="outlined" color="primary" startIcon={<Close />}>Close</Button>
                    </Stack>}
                </Grid>

                {/* Filter with Variants */}
                <Grid item xs={12} sm={6} sx={{ pl: { xs: 0, sm: 2 } }}>
                    {/* Title */}
                    <Heading title={rightTitle} variant="" onBtnClick={clearAll} />

                    {/* Heading */}
                    <Box mt={1}>
                        <VariantHeading
                            titleLable={"Variants"}
                            titleValue={currentVariant.length}
                            subTitleLable={"Combination"}
                            subTitleValue={selVariants.length}
                        />
                    </Box>

                    {/* Variants */}
                    <Stack mt={1} direction={"column"} sx={{ height, overflow: "scroll" }}>
                        {currentVariant.map((_var, varIndex) => {
                            return <Stack direction={"row"} spacing={1} alignItems={"center"}>
                                <Checkbox
                                    disabled
                                    sx={{ '&.Mui-disabled': { color: (theme) => `${theme.palette.primary.main} !important` } }}
                                    checked={selVariants.includes(_var.id)}
                                    size="small"
                                />
                                <Typography variant={"body2"}>{_var.name}</Typography>
                            </Stack>
                        })}
                    </Stack>

                </Grid>
            </Grid>
        </Box>
    }

export const Heading: React.FC<{ title: string, variant?: '' | 'button' | 'checkbox', onBtnClick?: any }> = ({ title = "", variant = "", onBtnClick }) => {

    return <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"} pt={variant === '' ? 1 : 0}>
        {/* Title */}
        <Typography variant="body2" color="textPrimary" sx={{ fontFamily: "htrts_medium" }}>{title}</Typography>

        {/* Button */}
        {variant === "button" && <Tooltip title="Clear All">
            <IconButton onClick={onBtnClick} size="small">
                <ClearAll color="error" />
            </IconButton>
        </Tooltip>}

        {/* Checkbox */}
        {variant === "checkbox" && <FormControlLabel
            value="start"
            control={<Checkbox size="small" />}
            label="Select All"
            labelPlacement="start"
            componentsProps={{
                typography: { variant: "body2" }
            }}
        />}
    </Stack>
}

export const SelectionChip: React.FC<{ selections: SelectionInterface[], limit: number, onChipClicked: any, state: any }> = ({ selections, onChipClicked, state, limit }) => {
    return <Stack mt={1}>
        {selections.map((sel, _selIndex) => {
            if (_selIndex >= limit) { return <></> }
            return <Grid
                key={_selIndex}
                container
                alignItems="flex-start"
                justifyContent="flex-start"
                sx={{ borderBottom: (theme) => `1px solid ${theme.palette.divider}`, pb: 1 }}
            >
                {/* Selection Name */}
                <Grid item xs={12} sm={4} sx={{ pr: { xs: 0, sm: 2 }, mt: 1 }}>
                    <Typography gutterBottom variant="body2">{sel.name}</Typography>
                </Grid>

                {/* Options */}
                <Grid item xs={12} sm={8}>
                    <Stack direction={"row"} flexWrap={"wrap"}>
                        {sel.options.map((opt, optIndex) => {
                            return <Chip
                                sx={{ mr: 1, mt: 1 }}
                                color={state?.[sel.id]?.options?.[sel.id + "-" + opt.id]?.id === opt.id ? "primary" : "default"}
                                size="small"
                                key={optIndex}
                                onClick={(e) => onChipClicked(opt, optIndex, sel, _selIndex)}
                                label={opt.desc}
                            />
                        })}
                    </Stack>
                </Grid>
            </Grid>
        })}
    </Stack>
}

export const VariantHeading: React.FC<{ titleValue: string | number, titleLable: string, subTitleValue: string | number, subTitleLable: string }> = ({
    titleLable, titleValue, subTitleLable, subTitleValue
}) => {

    const theme = useTheme();

    return <MyPaper background={theme.palette.background.default}>
        <Stack direction="row" justifyContent={"space-between"} alignItems="center">
            {/* Tilte */}
            {titleLable.length > 0 && <Stack spacing={1} direction="row" justifyContent={"space-between"} alignItems="center">
                <Typography variant="subtitle2">{titleLable}</Typography>
                <Typography variant="subtitle2" sx={{ fontFamily: "htrts_bold" }}>{titleValue}</Typography>
            </Stack>}

            {/* Sub Tilte */}
            {subTitleLable.length > 0 && <Stack spacing={1} direction="row" justifyContent={"space-between"} alignItems="center">
                <Typography variant="subtitle2">{subTitleLable}</Typography>
                <Typography variant="subtitle2" sx={{ fontFamily: "htrts_bold" }}>{subTitleValue}</Typography>
            </Stack>}
        </Stack>
    </MyPaper>
}