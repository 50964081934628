import React from "react";
import { withNavBars } from "../../HOCs";
import { FolderView } from "./folderView";
import { useAppDispatch } from "../../redux";
import { closeDrawer, openDrawer } from "../../redux/slices/drawer";
import { History } from "../../components";

// const allTabs: string[] = ['folder', 'material', 'product', 'proposal', 'project', 'vendor']

const DocumentsParent: React.FC<{ children?: JSX.Element }> = (props) => {

    // const [params, setSearchParams] = useSearchParams();
    const dispatch = useAppDispatch();

    // const updateTab = (currentTab: string) => {
    //     setSearchParams({ tab: currentTab });
    // }

    const openDocumentHistory = (document_id: string) => {
        dispatch(openDrawer({
            title: "Document History",
            btnName: "Close",
            component: <History variant="document" document_id={document_id} />,
            onSave: () => dispatch(closeDrawer())
        }))
    }

    // React.useEffect(() => {
    //     let tab = params.get('tab');
    //     if (!tab || allTabs.includes(tab)) {
    //         updateTab(allTabs[0]);
    //     }
    //     // eslint-disable-next-line
    // }, []);

    // let tab = params.get('tab');

    // return <Box sx={{ height: "100%" }}>
    //     <Paper sx={{ borderRadius: 0, position: "absolute", left: 0, right: 0, top: '1px' }}>
    //         <Tabs
    //             value={tab}
    //             onChange={(e, tab) => updateTab(tab)}
    //             indicatorColor="primary"
    //             textColor="primary"
    //         >
    //             <Tab value={allTabs[0]} label="View by Folder" />
    //             <Tab value={allTabs[1]} label="View by Material" />
    //             <Tab value={allTabs[2]} label="View by Product" />
    //             <Tab value={allTabs[3]} label="View by Proposal" />
    //             <Tab value={allTabs[4]} label="View by Project" />
    //             <Tab value={allTabs[5]} label="View by Supplier" />

    //         </Tabs>
    //     </Paper>

    //     {tab === allTabs[0] && <FolderView openDocumentHistory={openDocumentHistory} />}

    //     {tab === allTabs[1] && <MaterialView />}

    //     {tab === allTabs[2] && <ProductView />}

    //     {tab === allTabs[3] && <ProposalView />}

    //     {tab === allTabs[4] && <ProjectView />}

    //     {tab === allTabs[5] && <VendorView />}
    // </Box>

    return (
        <FolderView openDocumentHistory={openDocumentHistory} />
    );
}

export default withNavBars(DocumentsParent);