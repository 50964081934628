import { Search } from "@mui/icons-material";
import {
  Box,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Skeleton,
  TextField,
  Typography,
} from "@mui/material";
import copy from "fast-copy";
import React from "react";
import { HighlightSelectBox, InternatlServerError } from "../../../components";
import { SelectBoxOption } from "../../../interfaces";

export interface NounDataInterface {
  data: any[];
  isLoading: boolean;
  isError: boolean;
  isSuccess: boolean;
  isFetching: boolean;
}

export const NounListing: React.FC<{
  value: any;
  onChange: any;
  showAll: boolean;
  categoryOptions: SelectBoxOption[];
  snNounData: NounDataInterface;
  category: SelectBoxOption;
  setCategory: any;
}> = ({
  value,
  onChange,
  showAll = false,
  categoryOptions,
  snNounData,
  category,
  setCategory,
}) => {
  const { data, isLoading, isError, isSuccess, isFetching } = snNounData;

  const [search, setSearch] = React.useState("");
  const [showSearch, setShowSearch] = React.useState(false);

  const allNounOption = {
    id: "all",
    noun_name: "All Nouns",
    group_name: "To view all attributes",
  };

  const giveMeNouns = () => {
    let newData = copy(data);
    let rows = showAll ? [{ ...allNounOption }] : [];

    if (category && category.value && category?.value?.toString() !== "all") {
      newData = newData?.filter((_: any) => _?.category_id === category?.value);
    }

    if (search.length) {
      rows = [
        ...rows,
        ...(newData?.filter((_: any) =>
          _?.noun_name?.toLowerCase()?.includes(search.toLowerCase())
        ) ?? []),
      ];
    } else {
      rows = [...rows, ...newData];
    }
    return rows;
  };

  const toggleShowSearch = () => {
    setShowSearch(!showSearch);
  };

  React.useEffect(() => {
    if (isSuccess && !isLoading && !isError && !value) {
      if (category?.value) {
        onChange(
          data?.filter((_: any) => _.category_id === category.value)?.[0] ?? {}
        );
      } else {
        onChange(data?.[0] ?? {});
      }
    }
    // eslint-disable-next-line
  }, [isSuccess, isFetching, value]);

  if (isLoading) {
    return (
      <>
        {Array(10).map((_: number, key: number) => {
          return <Skeleton variant="rectangular" />;
        })}
      </>
    );
  }

  if (isError && !isLoading) {
    return <InternatlServerError message="Opps!, Unable to fetch nouns" />;
  }

  const nouns = giveMeNouns();

  return (
    <Box
      sx={{
        border: (theme) => `1px solid ${theme.palette.divider}`,
        marginRight: { xs: 0, sm: 1 },
        borderRadius: 1,
      }}
    >
      {/* Heading */}
      <Box
        padding={1}
        display={"flex"}
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <Typography
          variant="subtitle2"
          sx={{ fontFamily: "htrts_bold" }}
        >{`Nouns (${nouns?.length ?? 0})`}</Typography>
        <IconButton size="small" onClick={toggleShowSearch}>
          <Search fontSize="small" htmlColor="black" />
        </IconButton>
      </Box>

      <Box px={1} paddingBottom={1}>
        <HighlightSelectBox
          label={"Category"}
          margin={"none"}
          value={category}
          options={[{ value: "all", label: "All" }, ...categoryOptions]}
          onChange={(options: SelectBoxOption) => {
            setCategory(options);
          }}
        />
      </Box>

      {/* Search Bar */}
      {showSearch && (
        <TextField
          sx={{ paddingX: 1, paddingBottom: 1 }}
          variant="standard"
          placeholder="Search noun"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          size={"small"}
          fullWidth
        />
      )}

      <List
        component="nav"
        dense
        sx={{ height: "calc(100vh - 230px)", overflow: "scroll" }}
      >
        {nouns.map((noun: any, key: number) => {
          return (
            <ListItem
              key={key}
              dense
              divider
              button
              selected={value?._id === noun?._id}
              onClick={(event) => onChange(noun)}
            >
              <ListItemText
                primary={
                  noun?.category_name
                    ? `${noun.noun_name} (${noun?.category_name})`
                    : noun?.noun_name
                }
                secondary={noun.group_name}
              />
            </ListItem>
          );
        })}
      </List>
    </Box>
  );
};
