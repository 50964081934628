import React from "react";
import { NounSelectBoxInterface } from "./util";
import { NounSelectBoxWithoutSearch } from "./withoutSearch";
import { NounSelectBoxWithSearch } from "./withSearch";

export const NounSNSelectBox: React.FC<NounSelectBoxInterface> = (props) => {
    if (props.withSearchAPI) {
        return <NounSelectBoxWithSearch {...props} />
    } else {
        return <NounSelectBoxWithoutSearch {...props} />
    }
};
