import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import {
  Delete,
  ExpandLess,
  ExpandMore,
  MoveToInbox,
} from "@mui/icons-material";
import {
  Alert,
  Button,
  Checkbox,
  Collapse,
  IconButton,
  Stack,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { grey, purple } from "@mui/material/colors";
import { useSnackbar } from "notistack";
import React from "react";
import { useAppDispatch } from "../../redux";
import { openDialog } from "../../redux/slices/dialog";
import { Attachment } from "../../redux/slices/material";
import { MyPaper } from "../myPaper";
import { MyNoRenderer, pattern } from "../uploadImage";
import "./index.css";

type PrimaryConfig = {
  primaryImageId: string;
  onChangePrimary: (id: string) => void;
}

type ImageUploadListProps = {
  title: string;
  maxFiles?: number;
  sizeLimit?: number | string;
  files?: (Attachment | File)[];
  onDelete: (file: Attachment | File, index: number) => void;
  onUpload: (file: File) => void;
  disabled?: boolean;
  uploadError?: boolean;
  uploadAllFileTypes?: boolean;
  disableCollapse?: boolean;
  stack?: boolean;
  hidePreview?: boolean;
  loading?: boolean;
  primaryConfig?: PrimaryConfig;
  readOnly?: boolean;
  accept?: string;
};

const ImageUploadList: React.FC<ImageUploadListProps> = (props) => {
  const {
    maxFiles = 1,
    title,
    files = [],
    sizeLimit = 1,
    onDelete,
    onUpload,
    disabled = false,
    uploadError = false,
    uploadAllFileTypes = false,
    disableCollapse = false,
    stack = true,
    hidePreview = false,
    // loading = false,
    primaryConfig,
    readOnly = false,
    accept = ''
  } = props;

  const theme = useTheme();

  const dispatch = useAppDispatch();

  const inputRef = React.useRef<HTMLInputElement>(null);

  const containerRef = React.useRef<HTMLDivElement>(null);

  const { enqueueSnackbar } = useSnackbar();

  const [open, updateOpen] = React.useState(true);

  const [showAlert, setShowAlert] = React.useState(false);

  // Function to prevent default behavior for drag over
  const handleDragOver = (event: any) => {
    if (containerRef?.current) {
      containerRef.current.style.border = `2px dotted ${theme.palette.primary.main}`;
      containerRef.current.style.backgroundColor = `${purple[50]}`;
      containerRef.current.style.opacity = "0.8";
    }
    event?.preventDefault();
  };

  const makeBorderNone = () => {
    if (containerRef?.current) {
      containerRef.current.style.border = `1px dashed ${grey[500]}`;
      containerRef.current.style.backgroundColor = `${grey[100]}`;
      containerRef.current.style.opacity = "1";
    }
  };

  const fileLimitCheck = (file: File) => {
    const sizeInBytes = file?.size ?? 0;
    const fileName = file?.name ?? "";
    const sizeInKB = sizeInBytes / 1024;
    let sizeInMB:number = sizeInKB / 1024;
    let stringified = sizeLimit.toString();
    let isGB = stringified.includes("GB");
    let limit:number = isGB ? parseInt(stringified.split("GB")[0] ?? 0) : sizeLimit as number;
    if (isGB) {
      sizeInMB = sizeInMB / 1024;
    }
    if (sizeInMB > limit) {
      enqueueSnackbar(
        `Image Size should be less than or equal to ${sizeLimit}${isGB ? "" : "MB"
        }`,
        { variant: "warning" }
      );
      return false;
    }
    if (!pattern.test(fileName ?? "")) {
      enqueueSnackbar(
        `Special characters like "/", "", "?", "#", "&", "=","+", "%" are not allowed, pls rename and upload`,
        { variant: "warning" }
      );
      return false;
    }
    return true;
  };

  // Function to handle image drop
  const handleImageUpload = (event: any) => {
    makeBorderNone();
    event?.preventDefault();
    const file =
      event?.dataTransfer?.files?.[0] || inputRef?.current?.files?.[0];
    if (disabled) {
      return false;
    }
    if (maxFiles === files.length) {
      setShowAlert(true);
      return;
    } else {
      setShowAlert(false);
    }
    if (fileLimitCheck(file)) {
      onUpload(file);
    }
  };

  const openFileInput = () => {
    if (disabled) {
      return false;
    }
    if (maxFiles === files.length) {
      setShowAlert(true);
      return false;
    } else {
      setShowAlert(false);
    }
    inputRef?.current?.click();
  };

  const openPreview = (index: number) => {
    let file: any = files?.[index];
    if (file?.url || file?.name) {
      const uri = decodeURIComponent((file as Attachment)?.url ?? "") ?? URL.createObjectURL(file as File);
      dispatch(
        openDialog({
          title: (file as File)?.name || (file as Attachment)?.file_name,
          hideNegativeBtn: true,
          hidePositiveBtn: true,
          maxWidth: "md",
          body: (
            <DocViewer
              prefetchMethod="GET"
              documents={[
                {
                  uri: uri
                },
              ]}
              pluginRenderers={DocViewerRenderers}
              config={{
                header: {
                  disableHeader: true,
                  disableFileName: true,
                  retainURLParams: true,
                },
                noRenderer: {
                  overrideComponent: MyNoRenderer,
                },
              }}
            />
          ),
        })
      );
    }
  };

  const onDeleteHandler = (file: File | Attachment, idx: number) => {
    onDelete(file, idx);
    setShowAlert(false);
  };

  return (
    <MyPaper padding={0}>
      <>
        {!disableCollapse && (
          <Stack
            direction={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
            sx={{
              px: 2,
              borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
            }}
          >
            <Typography variant="body1" fontFamily={"htrts_medium"}>
              {title}
            </Typography>

            <Stack direction="row" spacing={2}>
              <IconButton onClick={() => updateOpen(!open)}>
                {!open && <ExpandMore />}
                {open && <ExpandLess />}
              </IconButton>
            </Stack>
          </Stack>
        )}
        <Collapse in={open} timeout="auto" unmountOnExit>
          <Stack
            flexDirection={stack ? "column" : "row"}
            alignItems={"center"}
            p={2}
            gap={files.length ? 2 : 0}
          >
            {!readOnly && <Stack
              ref={containerRef}
              onMouseLeave={() => makeBorderNone()}
              onDragExit={() => makeBorderNone()}
              onDragLeave={() => makeBorderNone()}
              sx={{
                py: 1,
                px: 2,
                width: "auto",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: 4,
                bgcolor: grey[100],
                height: "max-content",
                cursor:
                  maxFiles === files.length || disabled ? "no-drop" : "pointer",
                border: `1px dashed ${grey[500]}`,
              }}
              spacing={1}
              component={"div"}
              onDragOver={handleDragOver}
              onDrop={handleImageUpload}
              onClick={openFileInput}
            >
              <MoveToInbox color="primary" sx={{ fontSize: 80 }} />
              <Typography variant={"body1"}>
                Click or drag {uploadAllFileTypes ? "files" : "image"} to this
                area to upload
              </Typography>
              <Stack sx={{ alignItems: "center" }}>
                <Typography variant={"caption"}>
                  Upload {uploadAllFileTypes ? "files" : "image"} less than or
                  equal to{" "}
                  <strong style={{ fontSize: "14px" }}>
                    {sizeLimit}
                    {sizeLimit.toString().includes("GB") ? "" : "MB"}
                  </strong>
                </Typography>
                {!uploadAllFileTypes && (
                  <Typography variant={"caption"} gutterBottom>
                    Please upload only these formats:{" "}
                    <strong>JPEG, JPG, PNG</strong>.
                  </Typography>
                )}
                {showAlert && (
                  <Alert severity="warning" sx={{ mt: 1 }}>
                    The upload is limited to {maxFiles}{" "}
                    {uploadAllFileTypes ? "files" : "image"}
                  </Alert>
                )}
                {uploadError && (
                  <Typography variant="caption" color="error">
                    {`Please upload the ${uploadAllFileTypes ? "files" : "image"
                      }.`}
                  </Typography>
                )}
              </Stack>
              <input
                id="file-upload"
                hidden
                type="file"
                ref={inputRef}
                accept={
                  uploadAllFileTypes ? "" : accept || "image/jpeg, image/png, image/jpg"
                }
                onChange={handleImageUpload}
              />
            </Stack>}

            {hidePreview &&
              ((files?.[0] as File)?.name ||
                (files?.[0] as Attachment)?.file_name) && (
                <Button
                  disabled={disabled}
                  onClick={() => onDeleteHandler(files?.[0], 0)}
                  variant={"outlined"}
                  size={"small"}
                >
                  Clear
                </Button>
              )}

            <Stack
              flexDirection={"row"}
              gap={2}
              flexWrap={"wrap"}
              alignItems={"center"}
              display={hidePreview ? "none" : "flex"}
            >
              {files?.map((file, idx: number) => {
                let src = "/imageNotFound.png";
                let name = "";
                if ((file as Attachment)?.url) {
                  src = (file as Attachment)?.url || "/imageNotFound.png";
                  name = (file as Attachment)?.file_name;
                } else if ((file as File)?.name) {
                  src = URL.createObjectURL(file as File);
                  name = (file as File)?.name;
                }
                // if (loading) {
                //   return (
                //     <Stack
                //       className={"image-container"}
                //       sx={{ border: `5px solid ${grey[200]}`, boxShadow: 2 }}
                //     >
                //       <CircularProgress
                //         className="progress"
                //         sx={{
                //           color: `${theme.palette.primary.main} !important`,
                //         }}
                //       />
                //     </Stack>
                //   );
                // }

                const isPrimary = primaryConfig?.primaryImageId === (file as Attachment)?._id || (primaryConfig?.primaryImageId === name && !(file as Attachment)?.file_name);

                return (
                  <Stack justifyContent={"center"} alignItems={'center'}>
                    <Stack
                      className={`image-container ${isPrimary ? "selected" : ""}`}
                      sx={{ border: `5px solid ${grey[200]}`, boxShadow: 2 }}
                    >
                      {["jpeg", "jpg", "png"].some((s) =>
                        name?.includes(s)
                      ) ? (
                        <img
                          className="image-element"
                          src={src}
                          height={"100%"}
                          width={"100%"}
                          alt={`${name ?? "alt"}`}
                          loading="lazy"
                        />
                      ) : (
                        <div role="img" aria-label={name}>
                          {name}
                        </div>
                      )}
                      {!readOnly && <Delete
                        className={"delete"}
                        onClick={() => onDeleteHandler(file, idx)}
                      />}
                      <Typography
                        className={"preview"}
                        onClick={() => openPreview(idx)}
                      >
                        Preview
                      </Typography>
                    </Stack>
                    {primaryConfig && !readOnly &&
                      <Tooltip title="Make this Image as Primary image">
                        <Checkbox
                          title="Make Primary"
                          checked={isPrimary}
                          onChange={() => primaryConfig?.onChangePrimary((file as Attachment)?._id || name)}
                          color={"success"}
                        />
                      </Tooltip>
                    }
                  </Stack>
                );
              })}
            </Stack>
          </Stack>
        </Collapse>
      </>
    </MyPaper>
  );
};

export { ImageUploadList };
