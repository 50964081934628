import { Stack, Typography, IconButton, Collapse } from "@mui/material";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import React from "react";
import { AntSwitch } from "../../antSwitch";

export const CollapsableHeader: React.FC<{
    title: string,

    showIcon: boolean,
    isExpanded: boolean,
    onIconClick?: (isExpanded: boolean) => void,

    showSwitch?: boolean,
    switchStatus?: boolean,
    onSwitchChanged?: (isChecked: boolean) => void,

    children: JSX.Element
}> = (props) => {

    const [expand, updateExpand] = React.useState(props.isExpanded);

    const onIconClick = () => {
        if (props.onIconClick) {
            props.onIconClick(!expand)
        }
        updateExpand(!expand);
    };

    const onSwitched = (e: React.ChangeEvent<HTMLInputElement>, isChecked: boolean) => {
        if (props.onSwitchChanged) {
            props.onSwitchChanged(isChecked)
        }
    }

    return <>
        <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"} sx={{ px: 1, borderBottom: (theme) => `1px solid ${theme.palette.divider}` }}>
            <Stack direction={"row"} spacing={1} alignItems={"center"} justifyContent={"flex-start"}>
                <Typography
                    variant="body1"
                    fontFamily={"htrts_medium"}>
                    {props.title}
                </Typography>
                {props.showSwitch && <AntSwitch sx={{ ml: 2 }} size="small" color="primary" checked={props.switchStatus} onChange={onSwitched} />}
            </Stack>

            {props.showIcon && <IconButton onClick={onIconClick}>
                {!expand && <ExpandMore />}
                {expand && <ExpandLess />}
            </IconButton>}

        </Stack>
        {/* Templates */}
        <Collapse in={expand} timeout="auto" unmountOnExit>
            {props.children}
        </Collapse>
    </>
}