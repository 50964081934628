import { Box, Grid, Stack, TextField, Typography } from "@mui/material";
import React from "react";
import {
  MarginError,
  marginInterface,
} from "../../../redux/slices/documentTemplate";
import { AntSwitch } from "../../antSwitch";
import { CollapsableHeader } from "../header";

export const Margin: React.FC<{
  title: string;
  showSwitch?: boolean;
  switchStatus?: boolean;
  onSwitchChanged?: (isChecked: boolean) => void;
  margin: Partial<marginInterface>;
  updateMargin: (key: keyof marginInterface, value: any) => void;
  updateOffset: (key: keyof marginInterface, value: any) => void;
  error: MarginError;
}> = (props) => {
  const { error, margin, updateOffset } = props;

  return (
    <CollapsableHeader
      title={props.title}
      showIcon={true}
      isExpanded={true}
      showSwitch={props.showSwitch}
      switchStatus={props.switchStatus}
      onSwitchChanged={props.onSwitchChanged}
    >
      <Box p={1} py={2}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              value={margin.top_offset}
              type="number"
              size="small"
              label="Top Margin Offset (%)"
              fullWidth
              onChange={(e) => {
                updateOffset("top_offset", Number(e.target.value))
              }}
              error={error.top_offset}
              helperText={
                error.top_offset
                  ? "Please enter the top margin between 5 to 15"
                  : ""
              }
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              value={margin.bottom_offset}
              type="number"
              size="small"
              label="Bottom Margin Offset (%)"
              fullWidth
              onChange={(e) => {
                updateOffset("bottom_offset", Number(e.target.value))
              }}
              error={error.bottom_offset}
              helperText={
                error.bottom_offset ? "Please enter the bottom margin between 5 to 15" : ""
              }
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              value={margin.width_offset}
              type="number"
              size="small"
              label="Side Margin Offset (%)"
              fullWidth
              onChange={(e) => {
                updateOffset("width_offset", Number(e.target.value))
              }}
              error={error.width_offset}
              helperText={
                error.width_offset ? "Please enter the side margin between 5 to 15" : ""
              }
            />
          </Grid>
          <Grid item xs={0} sm={6}></Grid>
          <Grid item xs={12} sm={6}>
            <Stack direction="row" spacing={1} alignItems="center">
              <AntSwitch
                checked={props.margin.showTopLine}
                onChange={(e, isChecked) =>
                  props.updateMargin("showTopLine", isChecked)
                }
              />
              <Typography>Top Line</Typography>
            </Stack>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Stack direction="row" spacing={1} alignItems="center">
              <AntSwitch
                checked={props.margin.showRightLine}
                onChange={(e, isChecked) =>
                  props.updateMargin("showRightLine", isChecked)
                }
              />
              <Typography variant="body2">Right Line</Typography>
            </Stack>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Stack direction="row" spacing={1} alignItems="center">
              <AntSwitch
                checked={props.margin.showBottomLine}
                onChange={(e, isChecked) =>
                  props.updateMargin("showBottomLine", isChecked)
                }
              />
              <Typography variant="body2">Bottom Line</Typography>
            </Stack>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Stack direction="row" spacing={1} alignItems="center">
              <AntSwitch
                checked={props.margin.showLeftLine}
                onChange={(e, isChecked) =>
                  props.updateMargin("showLeftLine", isChecked)
                }
              />
              <Typography variant="body2">Left Line</Typography>
            </Stack>
          </Grid>
        </Grid>
      </Box>
    </CollapsableHeader>
  );
};
