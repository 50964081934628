import { createSlice } from '@reduxjs/toolkit';
import copy from 'fast-copy';
import { SelectBoxOption } from '../../../interfaces';
import { ValidateEmail } from '../../../utils';

export interface VendorSliceInterface {
    // Basic Details
    basic_details: { vendor_name: string, vendor_website: string, approved_vendor: SelectBoxOption | null, status: SelectBoxOption | null },

    // Primary Contact Details
    primary_contact: { name: string, email: string, mobile: string, address: string, country: SelectBoxOption | null },

    // Secondary Contact Details
    secondary_contact: { name: string, email: string, mobile: string, address: string, country: SelectBoxOption | null },
    
    //Material mapping details
    applicable_materials: { group_id : string, noun_id: string, uom_id: string, noun_variant_ids: []}[],

    link?: any[],
    unlink?: any[],

    error: {
        // Basic Details
        basic_details: { vendor_name: boolean, vendor_website: boolean, approved_vendor: boolean, status: boolean },

        // Primary Contact Details
        primary_contact: { name: boolean, email: boolean, mobile: boolean, address: boolean, country: boolean },

        // Secondary Contact Details
        secondary_contact: { name: boolean, email: boolean, mobile: boolean, address: boolean, country: boolean },
    }
}

const initialState: VendorSliceInterface = {
    basic_details: { vendor_name: "", vendor_website: "", approved_vendor: null, status: null },
    primary_contact: { name: "", email: "", mobile: "", address: "", country: null },
    secondary_contact: { name: "", email: "", mobile: "", address: "", country: null },
    applicable_materials: [{ group_id : "", noun_id: "", uom_id: "", noun_variant_ids: []}],
    error: {
        basic_details: { vendor_name: false, vendor_website: false, approved_vendor: false, status: false },
        primary_contact: { name: false, email: false, mobile: false, address: false, country: false },
        secondary_contact: { name: false, email: false, mobile: false, address: false, country: false },
    }
}

export const vendorSlice = createSlice({
    name: 'vendor',
    initialState,
    reducers: {
        // To Slice by key value pair
        updateVendorSliceByKeyValue(state, { payload }: { payload: { parentKey: string, childKey: string, value: any } }) {
            let newState: any = copy(state);
            newState.error[payload.parentKey][payload.childKey] = payload.value ? false : true;
            newState[payload.parentKey][payload.childKey] = payload.value;

            // Validating Email
            if (newState.primary_contact.email) {
                newState.error.primary_contact.email = !ValidateEmail(newState.primary_contact.email);
            }
            if (newState.secondary_contact.email) {
                newState.error.secondary_contact.email = !ValidateEmail(newState.secondary_contact.email);
            }

            // Validating Mobile
            if (newState.primary_contact.mobile) {
                newState.error.primary_contact.mobile = newState.primary_contact.mobile.length < 10;
            }
            if (newState.secondary_contact.mobile) {
                newState.error.secondary_contact.mobile = newState.secondary_contact.mobile.length < 10;
            }

            return { ...state, ...newState }
        },

        // To update errors
        updateErrors(state, { payload }: { payload: {mandateKeys: any[], callback: (updateVendor: VendorSliceInterface) => void } }) {
            let vendor: any = copy(state);
            let error: any = vendor.error;
            payload?.mandateKeys?.forEach((key: any) => {
                if (!vendor[key.parentKey][key.childKey]) {
                    error[key.parentKey][key.childKey] = true;
                }

            })

            // Validating Email
            if (state.primary_contact.email) {
                error.primary_contact.email = !ValidateEmail(state.primary_contact.email);
            }
            if (state.secondary_contact.email) {
                error.secondary_contact.email = !ValidateEmail(state.secondary_contact.email);
            }

            // Validating Mobile
            if (state.primary_contact.mobile) {
                error.primary_contact.mobile = state.primary_contact.mobile.length < 10;
            }
            if (state.secondary_contact.mobile) {
                error.secondary_contact.mobile = state.secondary_contact.mobile.length < 10;
            }
            payload.callback({ ...vendor, error })
            return { ...state, error }
        },

        // To reset all values to initialValue
        resetVendorSlice(state) {
            return { ...state, ...initialState }
        },

        // To Bulk Update the slice
        updateVendorSlice(state, { payload }) {
            return { ...state, ...payload }
        }
    }
});

export const { updateVendorSliceByKeyValue, updateVendorSlice, resetVendorSlice, updateErrors } = vendorSlice.actions;
export default vendorSlice.reducer;