import { KeyboardBackspace } from "@mui/icons-material";
import { Box, Divider, IconButton, Stack, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { MyPaper } from "../../components";
import { useAppDispatch } from "../../redux";
import { setOpenWebSideNavBar } from "../../redux/slices/utils";

export const BackToList: React.FC<{ title?: string }> = ({title = ''}) => {

    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    React.useEffect(() => {
        dispatch(setOpenWebSideNavBar(false))
        // eslint-disable-next-line
    }, []);

    return <Box sx={{ background: (theme) => theme.palette.grey[100], width: 'auto', height: '100%' }}>
        <Stack height={"100%"} spacing={1}>
                {/* Side Navbar */}
                <Stack spacing={1} height={"100%"}>
                        <Box height={"100%"} position={"relative"}>
                            <MyPaper height={"100%"}>
                                <>
                                    {/* Heading */}
                                    <Box
                                        width={"100%"}
                                        display={"flex"}
                                        justifyContent={"center"}
                                        alignItems={"center"}
                                        position={"relative"}
                                        padding={1}
                                    >
                                        <IconButton onClick={() => navigate(-1)} sx={{ position: "absolute", left: 0 }}>
                                            <KeyboardBackspace />
                                        </IconButton>
                                        <Typography sx={{ fontWeight: "bold", pl: 3 }} variant="body2" color="textPrimary">{title}</Typography>
                                    </Box>

                                    <Box sx={{ position: "absolute", left: 0, right: 0, marginTop: "10px" }} >
                                        <Divider variant="fullWidth" />
                                    </Box>
                                </>
                            </MyPaper>
                        </Box>
                    </Stack>
        </Stack>
    </Box>
}