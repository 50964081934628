import { DashboardCustomizeRounded } from "@mui/icons-material";
import { Box } from "@mui/material";
import React from "react";
import { Layout } from "../../../components";
import { withNavBars } from "../../../HOCs";
import { AppRoutes } from "../../../router/routes";
import { Dashboard } from "./dashboard";

const ProposalDashboard: React.FC<{ children?: JSX.Element }> = (props) => {

    return <Box sx={{ padding: 0.5, height: "100%" }}>
        {/* Breadscrum */}
        <Layout
            history={[
                { label: "Proposals", onClick: () => false },
            ]}
            currentPath={"Dashboard"}
            hideNavBarByDefault={true}
            navBars={[{
                id: "1",
                title: "Dashboard",
                icon: <DashboardCustomizeRounded fontSize="small" />,
                path: AppRoutes.docDashboard
            },]}
            mainPanel={<Dashboard />}
            sideNavVariant={"whiteboard"}
        />
    </Box>
}

export default withNavBars(ProposalDashboard);