export const MainModule = {
    public: "public", // Public routes
    comm: "comm", // Comman Module
    mm: "mm", // Material Module
    prodm: "prd", // Product Module
    propm: "prp", // Proposal Module
    projm: "prj", // Project Module
    dm: "dm", // Document Module
    qm: "qm", // Quality Module
    vm: "vm", // Supplier(Vendor) Module
    ai: "ai", // AI Module
    tm: "ttm", // Tags & Template Module
    um: "um", // User Module
};

const Features = {
    tb: "tb",
    mat: "mat",
    bom: "bom",
    wb: "wb",
    repo: "rep",
    dg: "dg",
    prod: "prd",
    prop: "prp",
    proj: "prj",
    doc: "doc",
    drw: "drw",
    dwg: "dwg",
    itr: "itr",
    lv: "lv",
    bmr: "bmr",
    ven: "ven",
    tag: "tag",
    ttm: "ttm",
    tgc: "tgc",
    dt: "dt",
    sn: "sn",
    drt: "drt",
    inst: "ins",
    usr: "usr",
    um:"um",
    mem: "mem",
    dwgt: "dwgt"
};

const Operations = {
    all: "*",
    create: "c",
    read: "r",
    update: "u",
    delete: "d",
    calculated: "calc",
    export: "ex",
    publish: "p",
    vc: "vc"
};

export const PERMISSIONS = {
    PUBLIC: [MainModule.public],

    // ---------- Comman Permission ------
    EXPORT_TO_EXCEL: [MainModule.comm, Features.tb, [Operations.export]],

    // ---------- Material Module --------
    MATERIAL_DASHBOARD: [MainModule.mm, Features.mat, [Operations.read]],
    MATERIAL_CREATE: [MainModule.mm, Features.mat, [Operations.create]],
    MATERIAL_UPDATE: [MainModule.mm, Features.mat, [Operations.update]],
    MATERIAL_DELETE: [MainModule.mm, Features.mat, [Operations.delete]],
    MATERIAL_READ: [MainModule.mm, Features.mat, [Operations.read]],
    MATERIAL_PUBLISH: [MainModule.mm, Features.mat, [Operations.publish]],

    BOM_CREATE: [MainModule.mm, Features.bom, [Operations.create]],
    BOM_UPDATE: [MainModule.mm, Features.bom, [Operations.update]],
    BOM_DELETE: [MainModule.mm, Features.bom, [Operations.delete]],
    BOM_READ: [MainModule.mm, Features.bom, [Operations.read]],
    BOM_PUBLISH: [MainModule.mm, Features.bom, [Operations.publish]],
    BOM_REPORT: [MainModule.mm, Features.bom, [Operations.read, Operations.update]],

    WORKBOOK_CREATE: [MainModule.mm, Features.wb, [Operations.create]],
    WORKBOOK_UPDATE: [MainModule.mm, Features.wb, [Operations.update]],
    WORKBOOK_DELETE: [MainModule.mm, Features.wb, [Operations.delete]],
    WORKBOOK_READ: [MainModule.mm, Features.wb, [Operations.read]],
    WORKBOOK_PUBLISH: [MainModule.mm, Features.wb, [Operations.publish]],

    DG_READ: [MainModule.mm, Features.dg, [Operations.read]],

    MATERIAL_REPO_EDIT: [MainModule.mm, Features.repo, [Operations.create, Operations.update, Operations.delete, Operations.publish]],
    MATERIAL_REPO_READ: [MainModule.mm, Features.repo, [Operations.read]],

    // ---------- Product Module --------
    PRODUCT_DASHBOARD: [MainModule.prodm, Features.prod, [Operations.read]],

    PRODUCT_CREATE: [MainModule.prodm, Features.prod, [Operations.create]],
    PRODUCT_UPDATE: [MainModule.prodm, Features.prod, [Operations.update]],
    PRODUCT_DELETE: [MainModule.prodm, Features.prod, [Operations.delete]],
    PRODUCT_READ: [MainModule.prodm, Features.prod, [Operations.read]],
    PRODUCT_PUBLISH: [MainModule.prodm, Features.prod, [Operations.publish]],

    PRODUCT_VC: [MainModule.prodm, Features.prod, [Operations.vc]],

    PRODUCT_REPO_CREATE: [MainModule.prodm, Features.repo, [Operations.create, Operations.update]],
    PRODUCT_REPO_UPDATE: [MainModule.prodm, Features.repo, [Operations.update]],
    PRODUCT_REPO_DELETE: [MainModule.prodm, Features.repo, [Operations.delete, Operations.update]],
    PRODUCT_REPO_READ: [MainModule.prodm, Features.repo, [Operations.read]],

    // ---------- Proposals Module --------
    PROPOSALS_DASHBOARD: [MainModule.propm, Features.prop, [Operations.read]],

    PROPOSAL_CREATE: [MainModule.propm, Features.prop, [Operations.create]],
    PROPOSAL_UPDATE: [MainModule.propm, Features.prop, [Operations.update]],
    PROPOSAL_DELETE: [MainModule.propm, Features.prop, [Operations.delete]],
    PROPOSAL_READ: [MainModule.propm, Features.prop, [Operations.read]],
    PROPOSAL_PUBLISH: [MainModule.propm, Features.prop, [Operations.publish]],

    // ---------- Project Module --------
    PROJECT_DASHBOARD: [MainModule.projm, Features.proj, [Operations.read]],

    PROJECT_CREATE: [MainModule.projm, Features.proj, [Operations.create]],
    PROJECT_UPDATE: [MainModule.projm, Features.proj, [Operations.update]],
    PROJECT_DELETE: [MainModule.projm, Features.proj, [Operations.delete]],
    PROJECT_READ: [MainModule.projm, Features.proj, [Operations.read]],
    PROJECT_PUBLISH: [MainModule.projm, Features.proj, [Operations.publish]],

    // ---------- Document Module --------
    DOCUMENT_DASHBOARD: [MainModule.dm, Features.doc, [Operations.read]],

    DOCUMENT_CREATE: [MainModule.dm, Features.doc, [Operations.create]],
    DOCUMENT_UPDATE: [MainModule.dm, Features.doc, [Operations.update]],
    DOCUMENT_DELETE: [MainModule.dm, Features.doc, [Operations.delete]],
    DOCUMENT_READ: [MainModule.dm, Features.doc, [Operations.read]],
    DOCUMENT_PUBLISH: [MainModule.dm, Features.doc, [Operations.publish]],

    // ---------- BOM Report ------------
    BOM_REPORT_CREATE: [MainModule.dm, Features.bmr, [Operations.create]],
    BOM_REPORT_UPDATE: [MainModule.dm, Features.bmr, [Operations.update]],
    BOM_REPORT_DELETE: [MainModule.dm, Features.bmr, [Operations.delete]],
    BOM_REPORT_READ: [MainModule.dm, Features.bmr, [Operations.read]],
    BOM_REPORT_PUBLISH: [MainModule.dm, Features.bmr, [Operations.publish]],

    // ---------- BOM List View ------------
    BOM_LIST_VIEW_CREATE: [MainModule.dm, Features.lv, [Operations.create]],
    BOM_LIST_VIEW_UPDATE: [MainModule.dm, Features.lv, [Operations.update]],
    BOM_LIST_VIEW_DELETE: [MainModule.dm, Features.lv, [Operations.delete]],
    BOM_LIST_VIEW_READ: [MainModule.dm, Features.lv, [Operations.read]],
    BOM_LIST_VIEW_PUBLISH: [MainModule.dm, Features.lv, [Operations.publish]],

    // ---------- Quality Module --------
    QUALITY_DASHBOARD: [MainModule.qm, Features.itr, [Operations.read]],

    QUALITY_CREATE: [MainModule.qm, Features.itr, [Operations.create]],
    QUALITY_UPDATE: [MainModule.qm, Features.itr, [Operations.update]],
    QUALITY_DELETE: [MainModule.qm, Features.itr, [Operations.delete]],
    QUALITY_READ: [MainModule.qm, Features.itr, [Operations.read]],

    // ---------- AI Search ------------
    AI_SEARCH_CREATE: [MainModule.ai, Features.inst, [Operations.create]],
    AI_SEARCH_UPDATE: [MainModule.ai, Features.inst, [Operations.update]],
    AI_SEARCH_DELETE: [MainModule.ai, Features.inst, [Operations.delete]],
    AI_SEARCH_READ: [MainModule.ai, Features.inst, [Operations.read]],

     // ---------- IEDRAW ------------
    IEDRAW_CREATE: [MainModule.dm, Features.dwg, [Operations.create]],
    IEDRAW_UPDATE: [MainModule.dm, Features.dwg, [Operations.update]],
    IEDRAW_DELETE: [MainModule.dm, Features.dwg, [Operations.delete]],
    IEDRAW_READ: [MainModule.dm, Features.dwg, [Operations.read]],
    IEDRAW_PUBLISH: [MainModule.dm, Features.dwg, [Operations.publish]],

    // ---------- Supplier Module --------
    SUPPLIER_DASHBOARD: [MainModule.vm, Features.ven, [Operations.read]],

    SUPPLIER_CREATE: [MainModule.vm, Features.ven, [Operations.create]],
    SUPPLIER_UPDATE: [MainModule.vm, Features.ven, [Operations.update]],
    SUPPLIER_DELETE: [MainModule.vm, Features.ven, [Operations.delete]],
    SUPPLIER_READ: [MainModule.vm, Features.ven, [Operations.read]],

    // ---------- Tag Category --------
    TAG_CATEGORY_CREATE: [MainModule.tm, Features.tgc, [Operations.create]],
    TAG_CATEGORY_UPDATE: [MainModule.tm, Features.tgc, [Operations.update]],
    TAG_CATEGORY_DELETE: [MainModule.tm, Features.tgc, [Operations.delete]],
    TAG_CATEGORY_READ: [MainModule.tm, Features.tgc, [Operations.read]],
    TAG_CATEGORY_MASTER: [MainModule.tm, Features.tgc, [Operations.read, Operations.create, Operations.update, Operations.delete]],

    // ---------- Tags --------
    TAG_CREATE: [MainModule.tm, Features.tag, [Operations.create]],
    TAG_UPDATE: [MainModule.tm, Features.tag, [Operations.update]],
    TAG_DELETE: [MainModule.tm, Features.tag, [Operations.delete]],
    TAG_READ: [MainModule.tm, Features.tag, [Operations.read]],
    TAG_MASTER: [MainModule.tm, Features.tag, [Operations.read, Operations.create, Operations.update, Operations.delete]],

    // ---------- Document Template --------
    DOCUMENT_TEMPLATE_CREATE: [MainModule.tm, Features.dt, [Operations.create]],
    DOCUMENT_TEMPLATE_UPDATE: [MainModule.tm, Features.dt, [Operations.update]],
    DOCUMENT_TEMPLATE_DELETE: [MainModule.tm, Features.dt, [Operations.delete]],
    DOCUMENT_TEMPLATE_READ: [MainModule.tm, Features.dt, [Operations.read]],
    DOCUMENT_TEMPLATE_MASTER: [MainModule.tm, Features.dt, [Operations.read, Operations.create, Operations.update, Operations.delete, Operations.publish]],

    // -------- Sequence Number Template -----------------
    SEQUENCE_NO_TEMPLATE_CREATE: [MainModule.tm, Features.sn, [Operations.create]],
    SEQUENCE_NO_TEMPLATE_UPDATE: [MainModule.tm, Features.sn, [Operations.update]],
    SEQUENCE_NO_TEMPLATE_DELETE: [MainModule.tm, Features.sn, [Operations.delete]],
    SEQUENCE_NO_TEMPLATE_READ: [MainModule.tm, Features.sn, [Operations.read]],
    SEQUENCE_NO_TEMPLATE_MASTER: [MainModule.tm, Features.sn, [Operations.read, Operations.create, Operations.update, Operations.delete]],

    // -------- IEDRAW Template -----------------
    IEDRAW_TEMPLATE_CREATE: [MainModule.dm, Features.dwgt, [Operations.create]],
    IEDRAW_TEMPLATE_UPDATE: [MainModule.dm, Features.dwgt, [Operations.update]],
    IEDRAW_TEMPLATE_DELETE: [MainModule.dm, Features.dwgt, [Operations.delete]],
    IEDRAW_TEMPLATE_READ: [MainModule.dm, Features.dwgt, [Operations.read]],
    IEDRAW_TEMPLATE_MASTER: [MainModule.dm, Features.dwgt, [Operations.read, Operations.create, Operations.update, Operations.delete]],

    // ---------- User Module --------------------
    USER_MODULE_CREATE: [MainModule.um, Features.usr, [Operations.create]],
    USER_MODULE_UPDATE: [MainModule.um, Features.usr, [Operations.update]],
    USER_MODULE_DELETE: [MainModule.um, Features.usr, [Operations.delete]],
    USER_MODULE_READ: [MainModule.um, Features.usr, [Operations.read]],

    // ---------- User Module - Member --------------------
    USER_MODULE_MEM_CREATE: [MainModule.um, Features.mem, [Operations.create]],
    USER_MODULE_MEM_UPDATE: [MainModule.um, Features.mem, [Operations.update]],
    USER_MODULE_MEM_DELETE: [MainModule.um, Features.mem, [Operations.delete]],
    USER_MODULE_MEM_READ: [MainModule.um, Features.mem, [Operations.read]],
}