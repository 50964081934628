import { createApi, fetchBaseQuery, retry } from "@reduxjs/toolkit/query/react";
import { config } from "../../../config";
import { ConvertToReactSelect, getTransformedDataForPagination, LocalStorageKeys } from "../../../utils";

export const snGroupAPI = createApi({
  reducerPath: "snGroupAPI",
  baseQuery: retry(
    fetchBaseQuery({
      baseUrl: config.api_url + "tm/sn/group",
      prepareHeaders: (headers, { getState }) => {
        const token = localStorage.getItem(LocalStorageKeys.authToken);

        // If we have a token set in state, let's assume that we should be passing it.
        if (token) {
          headers.set("authorization", `Bearer ${token}`);
        }

        return headers;
      },
    }),
    { maxRetries: 2 }
  ),
  tagTypes: ["SN Group"],
  endpoints: (builder) => ({
    // Get SNGroups API
    getSNGroups: builder.query({
      query: ({ page = 0, pageSize = 10, sort_by = null, sort_order = null }) => ({
        url: `${page !== null ? `?page_no=${page + 1}` : ""}${
          pageSize ? `&page_size=${pageSize}` : ""
        }${sort_by ? `&sort_by=${sort_by}` : ''}${sort_order ? `&sort_order=${sort_order}` : ''}`,
        method: "GET",
      }),
      transformResponse: (
        data: any,
        meta: { request: Request; response?: Response }
      ) => {
        return getTransformedDataForPagination(data, meta?.response);
      },
      providesTags: ["SN Group"],
    }),

    getSNGroupsDD: builder.query({
      query: () => ({ method: "GET" }),
      transformResponse: (response: any[]) =>
        ConvertToReactSelect(response, "_id", "group_name"),
      providesTags: ["SN Group"],
    }),

    // Create SN Group API
    createSNGroup: builder.mutation({
      query: (payload) => ({ method: "POST", body: payload }),
      invalidatesTags: ["SN Group"],
    }),

    // Update SN Group API
    updateSNGroup: builder.mutation({
      query: ({ id, payload }) => ({
        url: `${id}`,
        method: "PUT",
        body: payload,
      }),
      invalidatesTags: ["SN Group"],
    }),

    // Delete SN Group API
    deleteSNGroup: builder.mutation({
      query: ({ id }) => ({ url: `${id}`, method: "DELETE" }),
      invalidatesTags: ["SN Group"],
    }),
  }),
});

// Export hooks for usage in functional components
export const {
  useGetSNGroupsQuery,
  useGetSNGroupsDDQuery,
  useLazyGetSNGroupsQuery,
  useCreateSNGroupMutation,
  useUpdateSNGroupMutation,
  useDeleteSNGroupMutation,
} = snGroupAPI;
