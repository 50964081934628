import { Button, Stack, TextField, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { HighlightSelectBox } from "../../../../../components";
import { SelectBoxOption } from "../../../../../interfaces";
import { useGetSNAttributeDDOptionsQuery } from "../../../../../redux/services";
import { ConvertToReactSelect } from "../../../../../utils";
import {
  IFormatConfig,
  IFormatConfigError,
  separatorOptions,
} from "./AddUpdateSNTemplate";

type FieldProps = {
  index: number;
  formatConfig: IFormatConfig[];
  onSave: (index: number, data: IFormatConfig) => void;
  error: IFormatConfigError;
  noun: SelectBoxOption | null;
};

function AddEditField({
  index,
  formatConfig,
  onSave,
  error,
  noun,
}: FieldProps) {
  let formatOptions = [
    { label: "Attribute", value: "Attribute" },
    { label: "Separator", value: "Separator" },
    { label: "Label", value: "Label" },
    { label: "Counter", value: "Counter" },
  ];

  const attributeOptions = useGetSNAttributeDDOptionsQuery(null);

  const attrOptions = attributeOptions.data?.filter((d: any) =>
    d.nouns.includes(noun?._id)
  );

  const { enqueueSnackbar } = useSnackbar();

  const hasSequence =
    formatConfig.find((f) => f.type === "Counter") !== undefined;

  if (hasSequence && !formatConfig[index]) {
    formatOptions = formatOptions.filter((f) => f.value !== "Counter");
  }

  const [selectedData, setSelectedData] = React.useState<IFormatConfig | null>(
    formatConfig[index] || null
  );

  const [selectedField, setSelectedField] =
    React.useState<SelectBoxOption | null>(
      formatOptions.find((d) => d.label === formatConfig[index]?.type) || null
    );

  const [fieldError, setFieldError] = useState(error);

  const options = ConvertToReactSelect(
    attrOptions?.find((a) => a.value === selectedData?.attribute?.value)
      ?.options || [],
    "_id",
    "option_name"
  );

  useEffect(() => {
    if (selectedField?.value === "Attribute") {
      setSelectedData({
        attribute: selectedData?.attribute || null,
        option: selectedData?.option || null,
        type: "Attribute",
      });
    } else if (selectedField?.value === "Separator") {
      setSelectedData({
        separator: selectedData?.separator || null,
        type: "Separator",
      });
    } else if (selectedField?.value === "Label") {
      setSelectedData({
        label: selectedData?.label || "",
        type: "Label",
      });
    } else if (selectedField?.value === "Counter") {
      setSelectedData({
        sequenceNumber: selectedData?.sequenceNumber || "",
        type: "Counter",
      });
    }
    // eslint-disable-next-line
  }, [selectedField]);

  useEffect(() => {
    if (selectedData?.type === "Attribute") {
      setSelectedData((prev) => ({
        attribute:
          attrOptions?.find((a) => a?._id === prev?.attribute?.value) || null,
        option: options.find((o) => o?.value === prev?.option?.value) || null,
        type: "Attribute",
      }));
    }
    // eslint-disable-next-line
  }, [selectedData?.attribute]);

  const handleSave = () => {
    if (!selectedData) {
      enqueueSnackbar("Please map the format.", { variant: "warning" });
      return false;
    }
    let currentError: IFormatConfigError = {
      attribute: false,
      option: false,
      sequenceNumber: false,
      label: false,
      separator: false,
    };
    if (selectedData?.type === "Attribute") {
      if (!selectedData.attribute) {
        currentError.attribute = true;
      }
      if (!selectedData.option) {
        currentError.option = true;
      }
    } else if (
      selectedData.type === "Counter" &&
      !selectedData.sequenceNumber?.trim()
    ) {
      currentError.sequenceNumber = true;
    } else if (selectedData.type === "Label" && !selectedData.label?.trim()) {
      currentError.label = true;
    } else if (selectedData.type === "Separator" && !selectedData.separator) {
      currentError.separator = true;
    }
    setFieldError(currentError);
    if (Object.values(currentError).some((e) => e === true)) {
      return false;
    }
    onSave(index, selectedData as IFormatConfig);
  };

  const giveMeComponent = () => {
    return (
      <Stack>
        {/* Title */}
        <Typography
          variant="h6"
          color="textPrimary"
          sx={{
            borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
            width: "100%",
            fontFamily: "htrts_medium",
            mb: 2,
          }}
        >
          Select Field
        </Typography>

        <Stack gap={2}>
          <HighlightSelectBox
            label={"Select Field"}
            value={selectedField || null}
            options={formatOptions}
            onChange={(data: SelectBoxOption) => setSelectedField(data)}
          />
          {selectedField?.value === "Attribute" && (
            <>
              <HighlightSelectBox
                label={"Select Attribute"}
                value={selectedData?.attribute || null}
                options={attrOptions || []}
                onChange={(data: SelectBoxOption) =>
                  setSelectedData({
                    ...selectedData,
                    attribute: data,
                    type: "Attribute",
                  })
                }
                error={fieldError?.attribute}
                helperText={
                  fieldError?.attribute
                    ? `Please select the Attribute value`
                    : ""
                }
              />
              <HighlightSelectBox
                label={"Select Option"}
                value={selectedData?.option || null}
                options={options}
                onChange={(data: SelectBoxOption) =>
                  setSelectedData({
                    ...selectedData,
                    option: data,
                    type: "Attribute",
                  })
                }
                error={fieldError?.option}
                helperText={
                  fieldError?.option ? `Please select the Attribute Option` : ""
                }
              />
            </>
          )}
          {selectedField?.value === "Separator" && (
            <HighlightSelectBox
              label={"Select Separator"}
              value={selectedData?.separator || null}
              options={separatorOptions}
              onChange={(data: SelectBoxOption) => {
                setSelectedData({
                  ...selectedData,
                  separator: data,
                  type: "Separator",
                });
              }}
              error={fieldError?.separator}
              helperText={
                fieldError?.separator ? `Please select the Separator value` : ""
              }
            />
          )}
          {selectedField?.value === "Counter" && (
            <TextField
              size={"small"}
              fullWidth
              label={"Enter the start sequence"}
              value={selectedData?.sequenceNumber || ""}
              onChange={(e) =>
                setSelectedData({
                  ...selectedData,
                  sequenceNumber: e.target.value,
                  type: "Counter",
                })
              }
              error={fieldError?.sequenceNumber}
              helperText={
                fieldError?.sequenceNumber
                  ? "Please enter the Sequence Number"
                  : ""
              }
            />
          )}
          {selectedField?.value === "Label" && (
            <TextField
              size={"small"}
              fullWidth
              label={"Enter the label"}
              value={selectedData?.label || ""}
              onChange={(e) =>
                setSelectedData({
                  ...selectedData,
                  label: e.target.value,
                  type: "Label",
                })
              }
              error={fieldError?.label}
              helperText={fieldError?.label ? "Please enter the Label" : ""}
            />
          )}
          <Button variant={"contained"} onClick={handleSave}>
            Save
          </Button>
        </Stack>
      </Stack>
    );
  };

  return giveMeComponent();
}

export default AddEditField;
