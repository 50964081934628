import React from "react";
import { Grid, Box, useTheme, Theme, Collapse, Drawer } from "@mui/material";
import { RightPanel } from "./rightPanel";
import { allMasters, MasterPath, SNMastersInterface } from "./util";
import { withNavBars } from "../../../HOCs";
import { SelectBoxOption } from "../../../interfaces";
import {
  useLazyGetSNGroupsQuery,
  useLazyGetSNAttributesQuery,
  useLazyGetSNNounsQuery,
  useLazyGetSNTemplatesQuery,
} from "../../../redux/services";
import { BreadScrumHeader } from "../../../components";
import { LeftPanel } from "../../mdUpdate/leftPanel";
import { useLazyGetCategoryQuery } from "../../../redux/services/snCategory";
import { AppRoutes } from "../../../router/routes";
import { useNavigate, useParams } from "react-router-dom";
import { initialPaginationState, initialSortingState } from "../../mdUpdate";
import { GridSortModel } from "@mui/x-data-grid-premium";

export const useStyles = (theme: Theme) => ({
  root: {
    height: "100%",
    flexGrow: 1,
    padding: theme.spacing(1),
  },
  container: {
    height: "calc(100% - 60px)",
    overflow: "scroll",
    marginTop: { xs: 0, sm: theme.spacing(2) },
  },
  rightPanel: (openWebNav: boolean = true) => ({
    paddingTop: { xs: theme.spacing(1), sm: 0 },
    height: { xs: "auto", sm: "100%" },
    paddingLeft: { xs: 0, sm: openWebNav ? theme.spacing(2) : 0 },
  }),
});

const SequenceNumbering: React.FC<{ children?: JSX.Element }> = (props) => {
  const classes = useStyles(useTheme());

  const navigate = useNavigate();

  const { sequence_type = "" } = useParams();

  const [state, setState] = React.useState<{
    selectedMaster: SNMastersInterface;
    search: string;
    noun: any;
    category?: SelectBoxOption;
  }>({
    selectedMaster: allMasters.find(f => f.path === sequence_type) || allMasters?.[0],
    search: "",
    noun: null,
    category: { value: "", label: "All" },
  });
  const [openWebNav, setOpenWebNavBar] = React.useState(true);
  const [openMobileNav, setOpenMobileNavBar] = React.useState(false);

  // All Queries for masters
  const [getCategories, categoriesD] = useLazyGetCategoryQuery({ refetchOnFocus: true });
  const categoriesData = { ...categoriesD, data: categoriesD?.data?.data ?? [], currentData: categoriesD?.data?.data ?? [], totalCount: categoriesD?.data?.totalCount ?? 0 };

  const [getSNGroup, snGroupD] = useLazyGetSNGroupsQuery({ refetchOnFocus: true });
  const snGroupData = { ...snGroupD, data: snGroupD?.data?.data ?? [], currentData: snGroupD?.data?.data ?? [], totalCount: snGroupD?.data?.totalCount ?? 0 };

  const [getSNNouns, snNounsD] = useLazyGetSNNounsQuery({ refetchOnFocus: true });
  const snNounsData = { ...snNounsD, data: snNounsD?.data?.data ?? [], currentData: snNounsD?.data?.data ?? [], totalCount: snNounsD?.data?.totalCount ?? 0 };

  const [getSNAttribute, anAttributeD] = useLazyGetSNAttributesQuery({
    refetchOnFocus: true,
  });
  const anAttributeData = { ...anAttributeD, data: anAttributeD?.data?.data ?? [], currentData: anAttributeD?.data?.data ?? [], totalCount: anAttributeD?.data?.totalCount ?? 0 };

  const [getSNTemplates, snTemplatesD] = useLazyGetSNTemplatesQuery({
    refetchOnFocus: true,
  });
  const snTemplatesData = { ...snTemplatesD, data: snTemplatesD?.data?.data ?? [], currentData: snTemplatesD?.data?.data ?? [], totalCount: snTemplatesD?.data?.totalCount ?? 0 };

  const [paginationModel, setPaginationModel] = React.useState(initialPaginationState);
  const [queryOptions, setQueryOptions] = React.useState(initialSortingState);

  const changeState = (key: string, value: string) => {
    setState({ ...state, [key]: value });
  };

  const handleSortModelChange = React.useCallback((sortModel: GridSortModel) => {
    // Here you save the data you need from the sort model
    setQueryOptions({ sortModel: [...sortModel] });
  }, [])

  const giveMeMasterReleatedFunctions = () => {
    switch (state.selectedMaster?.path) {
      case MasterPath.category:
        return { getData: () => getCategories({ ...paginationModel, sort_by: queryOptions?.sortModel?.[0]?.field, sort_order: queryOptions?.sortModel?.[0]?.sort }), getDataProps: { ...categoriesData, paginationModel } };

      case MasterPath.group:
        return { getData: () => getSNGroup({ ...paginationModel, sort_by: queryOptions?.sortModel?.[0]?.field, sort_order: queryOptions?.sortModel?.[0]?.sort }), getDataProps: { ...snGroupData, paginationModel } };

      case MasterPath.noun:
        return { getData: () => getSNNouns({ ...paginationModel, sort_by: queryOptions?.sortModel?.[0]?.field, sort_order: queryOptions?.sortModel?.[0]?.sort }), getDataProps: { ...snNounsData, categoriesData, paginationModel } };

      case MasterPath.attribute:
        return {
          getData: (payload: any) => getSNAttribute({ ...payload, ...paginationModel, sort_by: queryOptions?.sortModel?.[0]?.field, sort_order: queryOptions?.sortModel?.[0]?.sort }),
          getDataProps: { ...anAttributeData, categoriesData, paginationModel },
        };

      case MasterPath.template:
        return {
          getData: () => getSNTemplates({ ...paginationModel, sort_by: queryOptions?.sortModel?.[0]?.field, sort_order: queryOptions?.sortModel?.[0]?.sort }),
          getDataProps: { ...snTemplatesData, categoriesData, paginationModel },
        };

      default:
        return { getData: (data: any) => false, getDataProps: { paginationModel, totalCount: 0 } };
    }
  };

  const toogleWebNav = () => {
    setOpenWebNavBar(!openWebNav);
  };
  const toogleMobileNav = () => {
    setOpenMobileNavBar(!openMobileNav);
  };

  React.useEffect(() => {
    let isSearchWithNoun = state.selectedMaster.path === MasterPath.attribute;
    if (isSearchWithNoun && state.noun) {
      giveMeMasterReleatedFunctions().getData({ id: state.noun?._id, ...paginationModel, sort_by: queryOptions?.sortModel?.[0]?.field, sort_order: queryOptions?.sortModel?.[0]?.sort });
    } else if (isSearchWithNoun) {
      giveMeMasterReleatedFunctions().getData({ id: null, ...paginationModel, sort_by: queryOptions?.sortModel?.[0]?.field, sort_order: queryOptions?.sortModel?.[0]?.sort });
    } else {
      giveMeMasterReleatedFunctions().getData({ ...paginationModel, sort_by: queryOptions?.sortModel?.[0]?.field, sort_order: queryOptions?.sortModel?.[0]?.sort });
    }
    // eslint-disable-next-line
  }, [state.selectedMaster, paginationModel, state.noun, JSON.stringify(queryOptions?.sortModel)]);

  React.useEffect(() => {
    setPaginationModel(initialPaginationState);
    setQueryOptions(initialSortingState);
    // eslint-disable-next-line
  }, [state.noun]);

  return (
    <Box sx={classes.root}>
      {/* Header */}
      <BreadScrumHeader
        openWebNav={openWebNav}
        toogleWebNav={toogleWebNav}
        toogleMobileNav={toogleMobileNav}
        history={[
          { label: "Tags & Template Module", onClick: () => false },
          {
            label: "Sequence Numbering (SN)",
            onClick: () => {
              toogleWebNav();
            },
          },
        ]}
        currentPath={state.selectedMaster.label}
      />

      {/* Body */}
      <Grid container direction={"row"} sx={classes.container}>
        <Grid
          item
          xs={openWebNav ? 12 : 0}
          sm={openWebNav ? 3 : 0}
          md={openWebNav ? 2 : 0}
          sx={{
            height: { xs: "auto", sm: "100%" },
            display: { xs: "none", sm: "block" },
          }}
        >
          <Collapse
            in={openWebNav}
            timeout={1}
            orientation={"horizontal"}
            sx={{
              height: { xs: "auto", sm: "100%" },
              ".MuiCollapse-wrapperInner": { width: "100%" },
            }}
          >
            <LeftPanel
              title="Sequence Numbering"
              allMasters={allMasters}
              selectedMaster={state.selectedMaster}
              onMasterClicked={(master: any) => {
                changeState("selectedMaster", master);
                setPaginationModel(initialPaginationState);
                setQueryOptions(initialSortingState);
                navigate(AppRoutes.sequenceNumbering(master?.path));
              }}
            />
          </Collapse>
        </Grid>

        <Grid
          item
          xs={12}
          sx={classes.rightPanel(openWebNav)}
          sm={!openWebNav ? 0 : 9}
          md={!openWebNav ? 0 : 10}
        >
          <RightPanel
            selectedMaster={state.selectedMaster}
            getMaster={giveMeMasterReleatedFunctions().getData}
            {...giveMeMasterReleatedFunctions().getDataProps}
            state={state}
            setState={setState}
            setPaginationModel={setPaginationModel}
            handleSortModelChange={handleSortModelChange}
          />
        </Grid>
      </Grid>

      {/* Ṃobile Drawer */}
      <Drawer anchor="left" open={openMobileNav} onClose={toogleMobileNav}>
        <LeftPanel
          selectedMaster={state.selectedMaster}
          allMasters={allMasters}
          onMasterClicked={(master: any) => {
            toogleMobileNav();
            changeState("selectedMaster", master);
            setPaginationModel(initialPaginationState);
            setQueryOptions(initialSortingState);
            navigate(AppRoutes.sequenceNumbering(master?.path));
          }}
        />
      </Drawer>
    </Box>
  );
};

export default withNavBars(SequenceNumbering);
