import React, { useEffect, useState, useRef } from "react";
import Tree, { Point } from "react-d3-tree";
import { useAppSelector } from "../../redux";
import {
  Alert,
  Box,
  type Theme,
  useTheme,
  Stack,
  Typography,
} from "@mui/material";
import { useLazyGetBOMCostingByIdQuery } from "../../redux/services";
import { LoadingSection } from "../../components";
import { useSnackbar } from "notistack";

const useStyles = (theme: Theme) => ({
  root: {
    flexGrow: 1,
    background: theme.palette.background.paper,
    boxShadow: `rgba(99, 99, 99, 0.2) 0px 2px 8px 0px`,
    height: "100%",
    overflow: "auto",
    borderRadius: theme.spacing(1),
    padding: theme.spacing(2),
  },
});

function createNode(m: any) {
  let children = []
    .concat((m?.bom_children_cost || [])?.map(createJsonNode) || [])
    .concat((m?.material_children_cost || [])?.map(createNode) || []);
  return {
    name: m.short_description,
    attributes: {
      ID: `${m.sequence_id}-r${m.version}`,
      Cost: `${m?.material_cost?.cost} ${m?.material_cost?.currency}`,
    },
    children: children,
  };
}

function createJsonNode(child: any) {
  let children = []
    .concat(child?.bom_children_cost?.map(createJsonNode) || [])
    .concat(child?.material_children_cost?.map(createNode) || []);

  const ID =
    child?.sequence_id && typeof child?.version === "number"
      ? {
        ID: `${child.sequence_id}-r${child.version}`,
      }
      : {};

  return {
    name: child.short_description,
    attributes: {
      ...ID,
      "Total Cost": `${child?.bom_cost?.cost || 0} ${child?.bom_cost?.currency
        }`,
    },
    children: children,
  };
}

const ViewCostingTree: React.FC<{
  setSequenceId: (sequenceId: string) => any;
}> = ({ setSequenceId }) => {
  const ref = useRef<HTMLDivElement | null>(null);

  const classes = useStyles(useTheme());

  const { bom } = useAppSelector((store) => store);

  const { enqueueSnackbar } = useSnackbar();

  const [getBOMCosting, { data, isLoading, isError, isSuccess }] =
    useLazyGetBOMCostingByIdQuery();

  const [dimensions, setDimensions] = useState<{
    width: number;
    height: number;
  } | null>(null);

  const [translate, setTranslate] = useState({
    x: 200,
    y: 300,
  });

  const fetchCostingDetails = async () => {
    await getBOMCosting({ id: bom.bomId })
      .unwrap()
      .then((res: any) => {
        if (res) {
          setSequenceId(`${res?.sequence_id ?? "-"}-r${res?.version ?? "0"}`);
        } else {
          enqueueSnackbar(
            "Something went wrong, Unable to Fetch Costing Details",
            { variant: "error" }
          );
        }
      })
      .catch((err) => {
        console.error("err:", err);
        enqueueSnackbar(
          "Something went wrong, Unable to Fetch Costing Details",
          {
            variant: "error",
          }
        );
      });
  };

  useEffect(() => {
    if (bom) {
      fetchCostingDetails();
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const dimensions = ref?.current?.getBoundingClientRect();
    if (dimensions) {
      setTranslate({
        x: dimensions.width / 2.5,
        y: (dimensions.height / 2) - 30,
      });
      setDimensions({
        width: dimensions.width as number,
        height: dimensions.height - 30 as number,
      });
    }
  }, []);

  const ID: object =
    data?.sequence_id && typeof data?.version === "number"
      ? {
        ID: `${data.sequence_id}-r${data.version}`,
      }
      : {};

  let costData = {
    name: data?.short_description,
    attributes: {
      ...ID,
      "Total Cost": `${data?.bom_cost?.cost || 0} ${data?.bom_cost?.currency
        }`,
    },
    children: []
      .concat(data?.bom_children_cost?.map(createJsonNode) || [])
      .concat(data?.material_children_cost?.map(createNode) || []),
  };

  const giveMeComponent = () => {
    if (isLoading) {
      return <LoadingSection top={0} message="Fetching Costing Details" />;
    }

    if (isError) {
      return (
        <Alert severity="error">
          Something went wrong, Unable to fetch Cost Breakup
        </Alert>
      );
    }

    if (Object.keys(data || {})?.length > 0) {
      return (
        <Stack width={"100%"}>
          <Stack alignItems={"flex-end"} mr={1}>
            <Stack direction={"row"} alignItems={"center"} spacing={2}>
              <Box width={30} height={30} bgcolor="#777" sx={{ borderRadius: 5, border: "1px solid #000" }}></Box>
              <Typography variant="body2">Click the below circle to open the costing tree</Typography>
            </Stack>
          </Stack>
          <Stack width={dimensions?.width} height={dimensions?.height}>
            <Tree
              data={costData}
              dimensions={dimensions as any}
              translate={translate as Point}
              orientation="vertical"
              initialDepth={0}
              pathFunc={"step"}
              separation={{ siblings: 2, nonSiblings: 2 }}
              nodeSize={{ x: 200, y: 200 }}
            />
          </Stack>
        </Stack>
      );
    } else if (isSuccess) {
      return (
        <Alert severity="info">
          You are yet to calculate costing for this BOM.
        </Alert>
      );
    }
  };

  return (
    <Box
      sx={[
        classes.root,
        (Object.keys(data || {})?.length <= 0 || isError) && !isLoading
          ? {
            p: 2,
          }
          : {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          },
      ]}
      ref={ref}
    >
      {giveMeComponent()}
    </Box>
  );
};

export default ViewCostingTree;
