import { Alert, Box, Button, DialogActions, DialogContent, DialogTitle, Grid, Tooltip, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import React from "react";
import { CustomDataGrid, DeletePanel, ModuleMapper, MyPaper, TitleHeader } from "..";
import { useAppDispatch, useAppSelector } from "../../redux";
import { useDeleteMaterialDocumentMutation, useDuplicateDocumentMutation, useLazyBulkDownloadDocumentsQuery, useLazyGetDocumentsBySearchQuery, useLazyGetDocumentsQuery, useLinkModuleMutation } from "../../redux/services";
import { closeBackdrop, openBackdrop } from "../../redux/slices/backdrop";
import { closeConfirmationDialog, openConfirmationDialog, updateConfirmationDialogPositiveBtn, updateCongirmationDialogBody } from "../../redux/slices/confirmationDialog";
import { closeDialog, openDialog } from "../../redux/slices/dialog";
import { updateDocUploadStatus } from "../../redux/slices/utils";
import { AddEditDocument, FileSelectionOptionType, MultipleDoc } from "./addEditDocument";
import { DocumentTableColumn, fileSelectOptions } from "./utils";
import { ViewLinkDocument } from "../../screens";
import { giveMeLocationAndDocumentTags } from "./utils";
import moment from 'moment';
import { CapitalizeString, convertSize } from "../../utils";
import { LinkFilter, SelectBoxOption } from "../../interfaces";
import { versionDropdownOption } from "../../screens/documents/documents";
import { DependencyChildren, PublishComponent } from "../../screens/materials/dashboard";
import { HasAccess } from "../../router/authorization";
import { PERMISSIONS } from "../../router/permission";
import { closeDrawer, openDrawer } from "../../redux/slices/drawer";
import { Filters } from "../filter";
import { GridRowSelectionModel, GridSortDirection, GridSortModel } from "@mui/x-data-grid-premium";
import { Download } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { AppRoutes } from "../../router/routes";
interface State { objectType: string, objectId: string }

const LinkTo = ({ links, clearSelectionHandler }: { links: GridRowSelectionModel, clearSelectionHandler: () => void }) => {
    const dispatch = useAppDispatch();

    const [linkDocuments] = useLinkModuleMutation();

    const { enqueueSnackbar } = useSnackbar();

    const [state, setState] = React.useState<State>({ objectType: '', objectId: '', });

    const onLinkBtnClicked = async () => {
        dispatch(openBackdrop(`Linking Document...`));
        const res: any = await linkDocuments({ object_type: state.objectType, object_id: state.objectId, payload: { links: [{ object_ids: links, object_type: "document" }] } });
        if (res.error) {
            enqueueSnackbar(`Unable to link the document. Info: ${res.error?.data?.title ?? "Unknown"}`, { variant: "warning" })
        } else {
            clearSelectionHandler();
        }
        dispatch(closeBackdrop());
        dispatch(closeDialog());
    }

    return <>
        {/* Heading */}
        <DialogTitle>
            <Typography
                variant="body1"
                fontFamily={"htrts_medium"}
                sx={{
                    borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
                    pb: 1,
                }}
            >
                Link Documents
            </Typography>
        </DialogTitle>

        <DialogContent>
            <ModuleMapper
                associations={[]}
                onOptionChange={(object_type, object_id) => {
                    setState((prevState) => ({ ...prevState, objectType: object_type as string, objectId: (object_id ?? '') as string }))
                }}
                shouldSkip
            />
        </DialogContent>

        <DialogActions>
            <Grid container justifyContent={"center"} alignItems={"center"} spacing={2} px={2} pb={1} pt={4}>
                {/* Cancel Btn */}
                <Grid item xs={12} sm={6} md={6} lg={4}>
                    <Button
                        fullWidth
                        variant="outlined"
                        onClick={() => dispatch(closeDialog())}
                    >
                        Cancel
                    </Button>
                </Grid>

                {/* Next Btn */}
                <Grid item xs={12} sm={6} md={6} lg={4} id="link-entity">
                    <Button
                        fullWidth
                        variant="contained"
                        onClick={onLinkBtnClicked}
                        disabled={!state.objectId}
                    >
                        Link
                    </Button>
                </Grid>
            </Grid>
        </DialogActions>
    </>
}

export const Documents: React.FC<{
    children?: JSX.Element, refetch: any, Id: string, _id: string, objectType: string, onAnnotate?: (id: string) => void,
    documentData?: any, linkDocument?: any, associations?: any, tag_id?: string | null, tag_group_id?: string | null,
    showCreateBtn?: boolean, showHelperBtn?: boolean, hideDeleteBtn?: boolean, isPdfOnly?: boolean, unlinkDoc?: (id: string, refetch: any) => void,
    parentData?: LinkFilter, creatable?: boolean, updatable?: boolean, deletetable?: boolean, publishable?: boolean, readable?: boolean,
    showSearch?: boolean, showFilter?: boolean, showRevision?: boolean, hasCheckbox?: boolean
}> = ({ showCreateBtn = true, showHelperBtn = true, hideDeleteBtn = false, isPdfOnly = false, showSearch = true, showFilter = true, showRevision = true, hasCheckbox = false, ...props }) => {

    const dispatch = useAppDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const docUploadStatus = useAppSelector(store => store.utils.docUploadStatus);
    const { perm } = useAppSelector(store => store.auth.userDetails);

    const [getDocuments, { data: allData = { data: [], totalCount: 0 }, isLoading, isError, isFetching }] = useLazyGetDocumentsQuery({});
    const [deleteDocumentMutation] = useDeleteMaterialDocumentMutation();
    const [duplicateDocument] = useDuplicateDocumentMutation();
    const [getDocumentsBySearch, { data: documentsFilteredData, isFetching: documentsIsFetching, isError: filterHasError }] = useLazyGetDocumentsBySearchQuery();
    const [downloadDocuments] = useLazyBulkDownloadDocumentsQuery();

    const loading = isLoading || isFetching || documentsIsFetching;

    const [paginationModel, setPaginationModel] = React.useState({ page: 0, pageSize: 10 });
    const [search, setSearch] = React.useState("");
    const [dropDown, updateDropDown] = React.useState<SelectBoxOption>(versionDropdownOption[0]);
    const [searchByKeyword, setSearchByKeyword] = React.useState("");
    const [rowSelectionModel, setRowSelectionModel] = React.useState<GridRowSelectionModel>([]);
    const [queryOptions, setQueryOptions] = React.useState({ sortModel: [{ field: 'sequence_id', sort: 'desc' as GridSortDirection }] });

    const { data, totalCount } = allData;
    const creatable = React.useMemo(() => props.updatable && HasAccess(perm, PERMISSIONS.DOCUMENT_CREATE), [perm, props.updatable]);
    const readable = React.useMemo(() => props.updatable && HasAccess(perm, PERMISSIONS.DOCUMENT_READ), [perm, props.updatable]);
    const updatable = React.useMemo(() => props.updatable && HasAccess(perm, PERMISSIONS.DOCUMENT_UPDATE), [perm, props.updatable]);
    const deletable = React.useMemo(() => props.deletetable && HasAccess(perm, PERMISSIONS.DOCUMENT_DELETE), [perm, props.deletetable]);
    const publishable = React.useMemo(() => props.publishable && HasAccess(perm, PERMISSIONS.DOCUMENT_PUBLISH), [perm, props.publishable]);

    const handleSortModelChange = React.useCallback((sortModel: GridSortModel) => {
        // Here you save the data you need from the sort model
        setQueryOptions({ sortModel: [...sortModel] });
    }, [])

    const refetch = () => {
        getDocuments({
            include_all: true,
            include_history: dropDown?.value ?? false,
            object_id: props._id,
            object_type: props.objectType,
            tag_id: props.tag_id,
            tag_group_id: props.tag_group_id,
            sort_by: queryOptions?.sortModel?.[0]?.field,
            sort_order: queryOptions?.sortModel?.[0]?.sort,
            ...paginationModel
        });
    };

    const refetchAll = () => {
        refetch();
        props.refetch();
    };

    const openAddEdit = (data: any, isEdit: boolean = true, selectedOption?: FileSelectionOptionType) => {
        if (updatable) {
            if (isEdit) {
                dispatch(openDialog({
                    title: "",
                    hideNegativeBtn: true,
                    hidePositiveBtn: true,
                    enablePadding: false,
                    body: <AddEditDocument isEdit={isEdit} data={data} objectType={props.objectType} Id={props.Id} refetch={refetchAll} _id={props._id} />
                }));
            } else {
                if (selectedOption && selectedOption === "single") {
                    dispatch(openDialog({
                        title: "",
                        hideNegativeBtn: true,
                        hidePositiveBtn: true,
                        enablePadding: false,
                        body: <AddEditDocument isEdit={isEdit} data={data} objectType={props.objectType} Id={props.Id} refetch={refetchAll} _id={props._id} />
                    }));
                } else if (selectedOption && selectedOption === "multiple") {
                    dispatch(openDialog({
                        title: "",
                        hideNegativeBtn: true,
                        hidePositiveBtn: true,
                        maxWidth: "lg",
                        enablePadding: false,
                        body: <MultipleDoc isEdit={isEdit} data={data} objectType={props.objectType} Id={props.Id} refetch={refetchAll} _id={props._id} files={[]} />,
                    }))
                }
            }
        }
    };

    const deleteDocument = (document: any) => {
        let message = `Check for dependency before deleting the Document ${document.sequence_id}-r${document.version}?`;
        dispatch(openConfirmationDialog({
            title: "Delete Operation",
            body: <DeletePanel
                message={message}
                warningMessage={""}
            />,
            positiveBtn: "Check for dependencies",
            negativeBtn: "Cancel",
            onOk: () => performRowDel(document, message, false),
            onNegativeBtn: () => dispatch(closeConfirmationDialog())
        }))
    };

    const performRowDel = async (document: any, message: string, force: boolean = false) => {
        dispatch(openBackdrop("Checking Dependencies and deleting document..."));
        let res: any = {};
        try {
            res = await deleteDocumentMutation({ id: document._id, force })

            if (Object.keys(res).includes("data")) {
                enqueueSnackbar(`Deleted document Successfully!`, { variant: "success" })
                dispatch(closeConfirmationDialog());
                refetch();
            } else if (Object.keys(res).includes("error")) {
                // eslint-disable-next-line 
                throw res.error.data;
            } else {
                // eslint-disable-next-line 
                throw "Data not found"
            }
        } catch (error: any) {
            let errorMessage: string = error?.title ?? "Opps! Something went wrong, Unable to delete"
            enqueueSnackbar(errorMessage, { variant: "error" });
            dispatch(updateCongirmationDialogBody(<DeletePanel
                message={"Dependency Table of " + document?.sequence_id + "-r" + document?.version}
                errorMessage={errorMessage}
                errorMessageStacks={document?.associations ?? []}
                warningMessage={""}
            />))

            dispatch(updateConfirmationDialogPositiveBtn({
                positiveBtn: "Force Delete",
                hidePositiveBtn: !error.title.toLowerCase().includes('linked'),
                onOk: () => dispatch(updateConfirmationDialogPositiveBtn({
                    positiveBtn: "Force Delete",
                    onOk: () => {
                        dispatch(updateCongirmationDialogBody(<DeletePanel
                            message={"Are you sure want to force delete the Document " + document?.sequence_id + "-r" + document?.version + "?"}
                            warningMessage={"Warning: After delete its not possible to retrieve"}
                        />))
                        dispatch(updateConfirmationDialogPositiveBtn({
                            positiveBtn: "Ok",
                            onOk: () => performRowDel(document, "", true)
                        }))
                    },
                }))
            }))
        } finally {
            dispatch(closeBackdrop());
        }
    };

    const openLinkDocument = () => {
        dispatch(openDialog({
            title: "",
            hideNegativeBtn: true,
            hidePositiveBtn: true,
            maxWidth: "md",
            hideCloseIcon: true,
            body: <ViewLinkDocument isPdfOnly={isPdfOnly} refetch={refetchAll} associations={props.associations} callLinkDocument={props.linkDocument} parentData={props?.parentData} />,
            enablePadding: false
        }));
    };

    const openAssociation = (document: any) => {
        dispatch(openConfirmationDialog({
            title: "Associations",
            body: <DeletePanel
                message={document?.associations ? document.associations.length === 0 ? 'No dependency found' : `Dependency Table of ${document?.sequence_id}-r${document.version}` : "No dependency found"}
                errorMessageStacks={document?.associations ?? []}
            />,
            hideNegativeBtn: true,
            hidePositiveBtn: true
        }))
    };

    const openReferences = (document: any) => {
        dispatch(openConfirmationDialog({
            title: "References",
            body: <DeletePanel
                message={document?.references ? document.references.length === 0 ? 'No references found' : `References Table of ${document?.sequence_id}-r${document.version}` : "No references found"}
                errorMessageStacks={document?.references ?? []}
            />,
            hideNegativeBtn: true,
            hidePositiveBtn: true
        }))
    };

    const giveMeRows = () => {
        if (search.trim().length) {
            let searchable = searchByKeyword ? documentsFilteredData?.data : data;
            return searchable?.filter((_: any) => (_?.sequence_id?.toLowerCase()?.includes(search.toLowerCase())
                || _?.file_name?.toLowerCase()?.includes(search.toLowerCase())
                || (giveMeLocationAndDocumentTags(_?.tags_info).document_tags)?.label?.toLowerCase()?.includes(search.toLowerCase())
                || convertSize(_?.size).toString()?.toLowerCase()?.includes(search.toLowerCase())
                || _?.created_user?.toString()?.toLowerCase()?.includes(search.toLowerCase())
                || moment(_.created_time).format('lll').toString().includes(search.toLowerCase())
            )) ?? [];
        } else if (!isFetching && data && !searchByKeyword) {
            return data ?? [];
        } else if (!documentsIsFetching && documentsFilteredData) {
            return documentsFilteredData?.data ?? [];
        } else {
            return [];
        }
    }

    const ROWS = giveMeRows();

    const updatePublishStatus = (row: any) => {
        dispatch(
            openConfirmationDialog({
                title: "Publish Operation",
                body: <DeletePanel
                    message={`Are you sure you want to publish the document ${row?.sequence_id}?`}
                    warningMessage={`You will not be able to edit this revision after publishing. You can however create new revisions.`}
                />,
                positiveBtn: "Check for dependencies",
                negativeBtn: "Cancel",
                onOk: () => openDependencyTable(row),
                onNegativeBtn: () => dispatch(closeConfirmationDialog())
            })
        );
    };

    const openDependencyTable = (row: any) => {
        dispatch(closeConfirmationDialog())
        try {
            dispatch(openDialog({
                title: "",
                body: <DependencyChildren object_id={row?._id} object_type="document" onPublish={() => proceedToPublish(row)} />,
                hideNegativeBtn: true, hidePositiveBtn: true, enablePadding: false
            }));
        } catch (error) {
            console.error(`Unable to get the hierarchy: ${error}`);
            enqueueSnackbar("Error getting dependencies", { variant: "error" })
            dispatch(closeDialog())
        }
    };

    const proceedToPublish = (row: any) => {
        dispatch(openDialog({
            title: "",
            body: <PublishComponent {...row} refetch={refetch} object_type="document" object_id={row?._id} />,
            hidePositiveBtn: true,
            hideNegativeBtn: true,
            enablePadding: false
        }));
    };

    const unlinkDocument = (row: any) => {
        dispatch(
            openConfirmationDialog({
                title: "Unlink Document",
                body: <DeletePanel
                    message={`Are you sure you want to unlink the document "${row?.sequence_id}"?`}
                />,
                positiveBtn: "Unlink",
                negativeBtn: "Cancel",
                onOk: () => {
                    if (props?.unlinkDoc) {
                        props.unlinkDoc(row?._id, refetch)
                        dispatch(closeConfirmationDialog())
                    }
                },
                onNegativeBtn: () => dispatch(closeConfirmationDialog())
            })
        );
    };

    const onReset = () => {
        setSearchByKeyword("");
    }

    const openMoreFilter = () => {
        dispatch(openDrawer({
            isLarge: true,
            component: <Filters searchByKeyword={searchByKeyword} appliedFilters={{}} onApply={() => { }} onCancel={() => { }} onFind={(val: string) => {
                setSearchByKeyword(val)
                dispatch(closeDrawer());
            }} onReset={onReset} />
        }))
    }

    const openLinkingWindow = () => {
        dispatch(openDialog({
            title: "",
            maxWidth: "md",
            body: <LinkTo links={rowSelectionModel} clearSelectionHandler={() => setRowSelectionModel([])} />,
            hidePositiveBtn: true,
            hideNegativeBtn: true,
            enablePadding: false
        }));
    }

    const handleDuplicate = (data: any) => {
        let message = `Do you want to create a copy of this "${data.sequence_id}" document?`;
        dispatch(
            openConfirmationDialog({
                title: "Are you sure?",
                body: message,
                positiveBtn: "Duplicate",
                negativeBtn: "Cancel",
                onOk: () => performDuplicate(data),
                onNegativeBtn: () => dispatch(closeConfirmationDialog()),
            })
        );
    };

    const performDuplicate = async (data: any) => {
        dispatch(openBackdrop(`Creating copy of "${data.sequence_id}" document....`));
        let res: any = {};
        try {
            res = await duplicateDocument(data?._id);
            if (Object.keys(res).includes("data")) {
                enqueueSnackbar(
                    res?.data?.message,
                    { variant: "success" }
                );
                dispatch(dispatch(
                    openConfirmationDialog({
                        title: "Duplicated Document",
                        body: `The duplicated document can be found under "Documents Module/Documents." Click "OK" to navigate there.`,
                        positiveBtn: "Ok",
                        negativeBtn: "Cancel",
                        onOk: () => {
                            const formattedTags = giveMeLocationAndDocumentTags(data?.tags_info);
                            dispatch(closeConfirmationDialog())
                            navigate(`${AppRoutes.documents}?id=${formattedTags?.location_tags?.value}&folder=${formattedTags?.location_tags?.label}&group_id=${formattedTags?.location_tags?.tag_group_id}&tab=folder`)
                        },
                        onNegativeBtn: () => {
                            dispatch(closeConfirmationDialog())
                            refetch();
                        },
                    })
                ))
            } else if (Object.keys(res).includes("error")) {
                // eslint-disable-next-line
                throw "Unable to create copy";
            } else {
                // eslint-disable-next-line
                throw "Data not found";
            }
        } catch (error) {
            let errorMessage: string = `Unable to create copy of ${data.sequence_id} document!`;
            enqueueSnackbar(errorMessage, { variant: "error" });
        } finally {
            dispatch(closeBackdrop());
        }
    };

    const bulkDownload = async () => {
        try {
            dispatch(openBackdrop('Starting Download....'))
            await downloadDocuments({ document_ids: rowSelectionModel as string[], file_name: `${CapitalizeString(props.objectType)} - ${props?.Id ?? "All"} - Documents` })
            enqueueSnackbar('Download Started', { variant: "success" })
        } catch (error) {
            console.log('error:', error)
            enqueueSnackbar('Something went wrong, unable to start download', { variant: "error" })
        } finally {
            dispatch(closeBackdrop())
        }
    }

    React.useEffect(() => {
        refetch();
        props.refetch();
        // eslint-disable-next-line
    }, [props._id, paginationModel, dropDown, props?.tag_group_id, props.tag_id, JSON.stringify(queryOptions?.sortModel)])

    React.useEffect(() => {
        refetch();
        props.refetch();
        updateDropDown(versionDropdownOption[0])
        // eslint-disable-next-line
    }, [props._id])

    React.useEffect(() => {
        dispatch(updateDocUploadStatus(null))
        // eslint-disable-next-line
    }, [])

    React.useEffect(() => {
        if (searchByKeyword) {
            getDocumentsBySearch({
                search: searchByKeyword,
                ...paginationModel,
                object_id: props._id,
                object_type: props.objectType,
                include_histories: dropDown?.value ?? false,
                sort_by: queryOptions?.sortModel?.[0]?.field,
                sort_order: queryOptions?.sortModel?.[0]?.sort
            })
        } else {
            refetch();
        }
        props.refetch();
        // eslint-disable-next-line
    }, [dropDown, searchByKeyword, paginationModel, JSON.stringify(queryOptions?.sortModel)]);

    React.useEffect(() => {
        setSearch("");
    }, [paginationModel]);

    React.useEffect(() => {
        if (docUploadStatus) {
            refetch();
        }
        // eslint-disable-next-line
    }, [docUploadStatus])

    React.useEffect(() => {
        if (isLoading || isFetching) {
            dispatch(openBackdrop("Fetching documents..."));
        } else {
            dispatch(closeBackdrop());
        }
        // eslint-disable-next-line
    }, [isLoading, isFetching])

    return <MyPaper height={"100%"}>
        <Box position={"relative"}>

            {/* Download Button */}
            {rowSelectionModel.length > 0 && <Tooltip
                title={`Download Selected documents`}
            >
                <Button
                    disableRipple
                    variant="outlined"
                    color="info"
                    sx={{ minWidth: "8px", position: "absolute", top: "9px" }}
                    onClick={() => bulkDownload()}
                >
                    <Download /> <span style={{ marginLeft: 1 }}>Download {`(${rowSelectionModel.length})`}</span>
                </Button>
            </Tooltip>}

            {/* Header */}
            <TitleHeader
                title="Documents"
                count={ROWS.length}
                showSearch={showSearch}
                search={search}
                onSearchChange={setSearch}
                searchPlaceholder={"Search for Documents (only within this page)"}
                showCreateBtn={showCreateBtn ? creatable : false}
                createBtnLabel={"Create Document"}
                disableCreateBtn={isLoading}
                menuOptions={fileSelectOptions}
                onCreateBtn={(value: FileSelectionOptionType | undefined) => {
                    if (!isLoading) {
                        openAddEdit(null, false, value)
                    }
                }}
                showHelperBtn={showHelperBtn ? updatable : false}
                helperBtnLabel="Link Document"
                onHelperBtn={openLinkDocument}
                showDropDown={showRevision}
                onDropDownChange={(data: SelectBoxOption) => updateDropDown(data)}
                dropDownLabel="Revision"
                dropDownOptions={versionDropdownOption}
                dropDownValue={dropDown}
                showFilter={showFilter}
                showMoreFilter={showFilter}
                showFilterSelect={false}
                onMoreFilterClick={openMoreFilter}
                moreFilterApplied={searchByKeyword.length > 0}
                linkingOption={hasCheckbox && rowSelectionModel.length > 0 ? {
                    linkingBtnLabel: "Link To",
                    openLinkingWindow: openLinkingWindow
                } : undefined}
            />

            {/* Error */}
            {(isError || filterHasError) && <Alert sx={{ mt: 2 }} severity="error">Oops! Something went wrong, Unable to fetch Documents. Try Again Later!</Alert>}

            {/* Data Drid */}
            <Box mt={2}>
                <CustomDataGrid
                    saveLocal={false}
                    // initialState={{
                    //     sorting: {
                    //         sortModel: [{ field: 'sequence_id', sort: 'desc' }],
                    //     },
                    // }}
                    rows={ROWS}
                    columns={DocumentTableColumn({
                        view: (data: any) => openAddEdit(data, true),
                        onDelete: deleteDocument,
                        openAssociation,
                        updatePublishStatus,
                        hideDelete: hideDeleteBtn ? true : !deletable,
                        handleAnnotate: props.onAnnotate,
                        openReferences,
                        unlinkDocument: props.unlinkDoc ? unlinkDocument : undefined,
                        publishable,
                        isLinkable: props.unlinkDoc && updatable,
                        disablePreview: !readable,
                        showDuplicate: creatable,
                        handleDuplicate
                    })}
                    loading={loading}
                    pagination
                    getRowId={(row) => row._id}
                    showToolbar={true}
                    rowCount={searchByKeyword ? documentsFilteredData?.totalCount : totalCount}
                    paginationModel={paginationModel}
                    paginationMode="server"
                    onPaginationModelChange={setPaginationModel}
                    checkboxSelection={true}
                    onRowSelectionModelChange={(newRowSelectionModel) =>
                        setRowSelectionModel(newRowSelectionModel)
                    }
                    rowSelectionModel={rowSelectionModel}
                    sortingMode="server"
                    onSortModelChange={handleSortModelChange}
                />
            </Box>
        </Box>
    </MyPaper>
}