import { Alert, Box, Button, Stack } from "@mui/material";
import React from "react";
import { CustomDataGrid, DeletePanel, MyPaper } from "../../../components";
import { useAppDispatch } from "../../../redux";
import { useLazyGetMaterialsQuery } from "../../../redux/services";
import { DashboardTableColumn } from "../../materials/utils";
import { openDialog } from "../../../redux/slices/dialog";
import ViewLinkMat from "./linkMaterial";
import { openConfirmationDialog } from "../../../redux/slices/confirmationDialog";
import { useParams } from "react-router-dom";
import { GridSortDirection, GridSortModel } from "@mui/x-data-grid-premium";

export const IssueMaterials: React.FC<{ children?: JSX.Element, refetch?: any, Id?: string, _id?: string, objectType?: string, linkMaterial?: any, associations?: any, delinkMaterial?: any, isViewOnly: boolean }> = (props) => {
    const dispatch = useAppDispatch();
    const [getMaterials, { data: allData = { data: [], totalCount: 0 }, isFetching, isError }] = useLazyGetMaterialsQuery();
    const { data, totalCount } = allData;

    const [links, setLinks] = React.useState([]);

    const [paginationModel, setPaginationModel] = React.useState({
        page: 0,
        pageSize: 10,
    });
    const [queryOptions, setQueryOptions] = React.useState({
        sortModel: [{ field: 'sequence_id', sort: 'desc' as GridSortDirection }],
    });

    const { issue_id = "" } = useParams();

    const handleSortModelChange = React.useCallback((sortModel: GridSortModel) => {
        // Here you save the data you need from the sort model
        setQueryOptions({ sortModel: [...sortModel] });
    }, [])

    React.useEffect(() => {
        refetch();
        // eslint-disable-next-line
    }, [props._id, paginationModel, JSON.stringify(queryOptions?.sortModel)]);

    const openLinkMaterial = () => {
        dispatch(openDialog({
            title: "",
            hideNegativeBtn: true,
            hidePositiveBtn: true,
            maxWidth: "md",
            hideCloseIcon: true,
            enablePadding: false,
            body: <ViewLinkMat id={props._id} refetch={refetch} associations={props.associations} callLinkMaterial={props.linkMaterial} />
        }));

    }

    const openDlinkMaterial = () => {
        props.linkMaterial([], refetch, links, "material")
    }

    const giveMeRows = () => {
        if (data && !isFetching) {
            return data;
        } else {
            return [];
        }
    }

    let ROWS = giveMeRows();

    const refetch = () => {
        getMaterials({ include_histories: false, object_type: "issue", object_id: issue_id, ...paginationModel, sort_by: queryOptions?.sortModel?.[0]?.field, sort_order: queryOptions?.sortModel?.[0]?.sort })
    }

    const openAssociation = (document: any) => {
        dispatch(openConfirmationDialog({
            title: "Associations",
            body: <DeletePanel
                message={document?.associations ? document.associations.length === 0 ? 'No dependency found' : `Dependency Table of ${document?.sequence_id}-r${document.version}` : "No dependency found"}
                errorMessageStacks={document?.associations ?? []}
            />,
            hideNegativeBtn: true,
            hidePositiveBtn: true
        }))
    };

    const openReferences = (document: any) => {
        dispatch(openConfirmationDialog({
            title: "References",
            body: <DeletePanel
                message={document?.references ? document.references.length === 0 ? 'No references found' : `References Table of ${document?.sequence_id}-r${document.version}` : "No references found"}
                errorMessageStacks={document?.references ?? []}
            />,
            hideNegativeBtn: true,
            hidePositiveBtn: true
        }))
    }

    const onRowSelection = (selected: any) => {
        setLinks(selected);
    }

    return <MyPaper height={"100%"}>
        <Box>
            {/* Header */}
            {!props.isViewOnly &&
                <Stack
                    sx={{ flexDirection: { xs: "column", sm: "row" } }}
                    alignItems={"center"}
                    justifyContent={"flex-end"}
                    gap={1}
                >
                    <Button
                        variant={"contained"}
                        color={"primary"}
                        onClick={() => { openLinkMaterial() }}
                        sx={{ fontSize: (theme) => theme.spacing(1.75), width: 250 }}
                        disabled={links.length > 0}
                    >
                        Link Material
                    </Button>
                    <Button
                        variant={"contained"}
                        color={"primary"}
                        onClick={() => { openDlinkMaterial() }}
                        disabled={links?.length > 0 ? false : true}
                        sx={{ fontSize: (theme) => theme.spacing(1.75), width: 250 }}
                    >
                        UnLink Material
                    </Button>
                </Stack>
            }

            {/* Error */}
            {isError && <Alert sx={{ mt: 2 }} severity="error">Oops! Something went wrong, Unable to fetch Materials. Try Again Later!</Alert>}

            {/* Data Grid */}
            <Box mt={2}>
                <CustomDataGrid
                    id="issue-tracker-materials"
                    initialState={{
                        sorting: {
                            sortModel: [{ field: 'sequence_id', sort: 'desc' }],
                        },
                    }}
                    rows={ROWS}
                    columns={DashboardTableColumn({ openAssociation, disableNavigation: false, removeDelete: true, openReferences })}
                    loading={isFetching}
                    pagination
                    getRowId={(row) => row._id}
                    showToolbar={true}
                    checkboxSelection={!props.isViewOnly}
                    onRowSelectionModelChange={(selected) => { onRowSelection(selected) }}
                    isRowSelectable={() => true}
                    rowCount={totalCount}
                    paginationModel={paginationModel}
                    paginationMode="server"
                    onPaginationModelChange={setPaginationModel}
                    sortingMode="server"
                    onSortModelChange={handleSortModelChange}
                />
            </Box>
        </Box>
    </MyPaper>

}