import { Alert, Box, Theme, useTheme } from "@mui/material";
import moment from "moment";
import { useSnackbar } from "notistack";
import React from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { CustomDataGrid, DeletePanel, TitleHeader } from "../../../components";
import { useAppDispatch, useAppSelector } from "../../../redux";
import { closeBackdrop, openBackdrop } from "../../../redux/slices/backdrop";
import { closeConfirmationDialog, openConfirmationDialog, updateConfirmationDialogPositiveBtn, updateCongirmationDialogBody } from "../../../redux/slices/confirmationDialog";
import { AppRoutes } from "../../../router/routes";
import { DashboardTableColumn } from "./utils";
import { useDeleteWorkbookMutation, useLazyGetAllWorkbooksQuery, useUpdateWorkbookMutation } from "../../../redux/services/commonApis";
import { Association } from "../../../interfaces";
import { HasAccess } from "../../../router/authorization";
import { PERMISSIONS } from "../../../router/permission";

const useStyles = (theme: Theme) => ({
    root: {
        flexGrow: 1,
        background: theme.palette.background.paper,
        boxShadow: `rgba(99, 99, 99, 0.2) 0px 2px 8px 0px`,
        height: "100%",
        overflow: "scroll",
        borderRadius: theme.spacing(1),
        padding: theme.spacing(2),
    },
    dataGridParent: {
        height: "calc(100% - 40px)",
        overflow: "scroll",
        marginTop: theme.spacing(2),
    }
});

export const Dashboard: React.FC<{ paths?: Array<any>, object_type?: string }> = ({ paths = [], object_type = "" }) => {

    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const classes = useStyles(useTheme());
    const { id = "" } = useParams();
    const { enqueueSnackbar } = useSnackbar();
    const [searchParams] = useSearchParams();
    const { perm } = useAppSelector(store => store.auth.userDetails);

    const [getWorkbooks, { data: allData = { data: [], totalCount: 0 }, isFetching, isError }] = useLazyGetAllWorkbooksQuery();
    const [updateWorkbook] = useUpdateWorkbookMutation();
    const [deleteWorkbook] = useDeleteWorkbookMutation();

    const [paginationModel, setPaginationModel] = React.useState({ page: 0, pageSize: 10 });
    const [search, setSearch] = React.useState("");

    const { data, totalCount } = allData;

    const creatable = React.useMemo(() => HasAccess(perm, PERMISSIONS.WORKBOOK_CREATE), [perm]);
    const deletable = React.useMemo(() => HasAccess(perm, PERMISSIONS.WORKBOOK_DELETE), [perm]);

    const deleteWorkbookHandler = (workbook: any) => {
        if (workbook.associations?.length > 0) {
            let message = `Check for dependencies before deleting the Workbook ${workbook.sequence_id}`;
            dispatch(openConfirmationDialog({
                title: "Delete Operation",
                body: <DeletePanel
                    message={message}
                    warningMessage={""}
                />,
                positiveBtn: "Check for dependencies",
                negativeBtn: "Cancel",
                onOk: () => openDependency(workbook),
                onNegativeBtn: () => dispatch(closeConfirmationDialog())
            }))
        } else {
            dispatch(openConfirmationDialog({
                title: "Delete Operation",
                body: <DeletePanel
                    message={"Are you sure want to force delete operation?"}
                    errorMessage={""}
                    errorMessageStacks={[]}
                    warningMessage={""}
                />,
                positiveBtn: "Force Delete",
                negativeBtn: "Cancel",
                onOk: () => {
                    dispatch(updateCongirmationDialogBody(<DeletePanel
                        message={`Workbook ${workbook?.sequence_id} will be deleted?`}
                        warningMessage={"Warning: After delete its not possible to retrieve"}
                    />))
                    dispatch(updateConfirmationDialogPositiveBtn({
                        positiveBtn: "Ok",
                        onOk: () => {
                            performRowDel(workbook)
                        },
                    }))
                },
                onNegativeBtn: () => dispatch(closeConfirmationDialog())
            }))
        }
    }

    const openDependency = (workbook: any) => {
        dispatch(updateCongirmationDialogBody(<DeletePanel
            message={`Dependency Table of ${workbook?.sequence_id}`}
            errorMessage={""}
            errorMessageStacks={workbook?.associations ?? []}
            warningMessage={""}
        />))
        dispatch(updateConfirmationDialogPositiveBtn({
            positiveBtn: "Force Delete",
            onOk: () => {
                dispatch(updateCongirmationDialogBody(<DeletePanel
                    message={`Workbook ${workbook?.sequence_id} will be deleted?`}
                    warningMessage={"Warning: After delete its not possible to retrieve"}
                />))
                dispatch(updateConfirmationDialogPositiveBtn({
                    positiveBtn: "Ok",
                    onOk: () => performRowDel(workbook)
                }))
            },
        }))
    };

    const performRowDel = async (workbook: any) => {
        dispatch(openBackdrop("Deleting Workbook..."));
        let res: any = {};
        try {
            let payload = {
                type: workbook?.type,
                bom_parent_id: workbook?.bom_parent_id,
                unlinks: workbook?.associations?.map((association: Association) => {
                    return {
                        object_type: association?.object_type,
                        object_ids: association?.object_details?.map((obj: any) => obj?._id)
                    }
                })
            }
            if (workbook?.associations?.length > 0) {
                res = await updateWorkbook({ id: workbook?._id, payload });
                if (res?.data?._id && res?.data?.message) {
                    res = await deleteWorkbook({ id: workbook?._id })
                }
            } else {
                res = await deleteWorkbook({ id: workbook?._id })
            }

            if (Object.keys(res).includes("data")) {
                enqueueSnackbar(`Deleted Workbook Successfully!`, { variant: "success" })
                dispatch(closeConfirmationDialog());
                refetch();
            } else if (Object.keys(res).includes("error")) {
                // eslint-disable-next-line 
                throw res.error.data;
            } else {
                // eslint-disable-next-line 
                throw "Data not found"
            }
        } catch (error: any) {
            let errorMessage: string = error?.title ?? "Oops! Something went wrong, Unable to delete the workbook"
            enqueueSnackbar(errorMessage, { variant: "error" });
        } finally {
            dispatch(closeConfirmationDialog());
            dispatch(closeBackdrop());
        }
    };

    const giveMeRows = (): any[] => {
        if (!isFetching && data) {
            if (search.trim().length) {
                return data?.filter((_: any) => (_?.sequence_id?.toLowerCase()?.includes(search.toLowerCase())
                    || _?.name?.toLowerCase()?.includes(search.toLowerCase())
                    || _?.description?.toLowerCase()?.includes(search.toLowerCase())
                    || _?.status?.toLowerCase()?.includes(search.toLowerCase())
                    || _?.type?.toLowerCase()?.includes(search.toLowerCase())
                    || _?.modified_user?.toLowerCase().includes(search.toLowerCase())
                    || _?.created_user?.toLowerCase().includes(search.toLowerCase())
                    || moment(_.modified_time).format('lll').toString().includes(search.toLowerCase())
                    || moment(_.created_time).format('lll').toString().includes(search.toLowerCase())
                )) ?? []
            }
            return data;
        } else {
            return [];
        }
    };

    const openAssociation = (workbook: any) => {
        dispatch(openConfirmationDialog({
            title: "Associations",
            body: <DeletePanel
                message={workbook?.associations ? workbook.associations.length === 0 ? 'No dependency found' : `Dependency Table of ${workbook?.sequence_id}` : "No dependency found"}
                errorMessageStacks={workbook?.associations ?? []}
            />,
            hideNegativeBtn: true,
            hidePositiveBtn: true
        }))
    };

    const viewWorkbook = (data: any) => {
        let stateObj = {
            state: {
                paths: [
                    ...paths,
                    {
                        title: "Workbook",
                        path: -1,
                    }
                ]
            }
        }
        if (id && object_type) {
            navigate(AppRoutes.viewWorkbookOnModule(object_type, id, data?._id ?? "New"), stateObj)
        } else {
            navigate(AppRoutes.viewWorkbook(data?._id ?? "New"), stateObj)
        }
    };

    let ROWS = giveMeRows();

    const refetch = () => getWorkbooks({ ...paginationModel, type: searchParams.get("type"), object_type: object_type, object_id: id });

    React.useEffect(() => {
        refetch();
        // eslint-disable-next-line
    }, [paginationModel]);

    return <Box sx={classes.root}>
        {/* Header */}
        <TitleHeader
            title="Workbooks"
            count={ROWS?.length ?? 0}
            showSearch={true}
            search={search}
            onSearchChange={setSearch}
            searchPlaceholder={"Search for Workbook (only within this page)"}
            showCreateBtn={creatable}
            createBtnLabel={"Create Workbook"}
            onCreateBtn={() => viewWorkbook(null)}
        />

        {/* Error */}
        {isError && <Alert sx={{ mt: 2 }} severity="error">Oops! Something went wrong, Unable to fetch Workbooks. Try Again Later!</Alert>}

        {/* Data Grid */}
        <Box mt={2}>
            <CustomDataGrid
                id={`${object_type ? `${object_type}-` : ''}workbook-list`}
                initialState={{
                    sorting: {
                        sortModel: [{ field: 'sequence_id', sort: 'desc' }],
                    },
                }}
                rows={ROWS}
                columns={DashboardTableColumn({ onDelete: deleteWorkbookHandler, view: viewWorkbook, openAssociation: openAssociation, hideDeleteBtn: !deletable })}
                loading={isFetching}
                pagination
                getRowId={(row) => row._id}
                showToolbar={true}
                rowCount={totalCount}
                paginationModel={paginationModel}
                paginationMode="server"
                onPaginationModelChange={setPaginationModel}
            />
        </Box>
    </Box>
}