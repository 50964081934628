import { useAuth0 } from "@auth0/auth0-react";
import React from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { AppRoutes } from "../../router/routes";
import { LocalStorageKeys } from "../../utils";

export const Root = ({ state }: { state?: { path: string, from: string, hasPrevilage: boolean } }) => {

    const navigate = useNavigate();
    const [params] = useSearchParams();
    const { isLoading } = useAuth0();

    React.useEffect(() => {
        if (!localStorage.getItem(LocalStorageKeys.authToken)) {
            if (params.get('page') === 'timeout') {
                navigate(`${AppRoutes.login}?page=timeout`)
            } else {
                navigate(AppRoutes.login, { state });
            }
        } else if (!isLoading) {
            if (state?.from) {
                navigate(state.from);
            } else if(sessionStorage.getItem(LocalStorageKeys.redirectURL)){
                navigate(sessionStorage.getItem(LocalStorageKeys.redirectURL) ?? AppRoutes.home);
                sessionStorage.removeItem(LocalStorageKeys.redirectURL);
            } else {
                navigate(AppRoutes.home);
            }
        }
        // eslint-disable-next-line
    }, [isLoading])

    return <></>;
}