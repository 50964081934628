import { createApi, fetchBaseQuery, retry } from "@reduxjs/toolkit/dist/query/react";
import Axios from "axios";
import { config } from "../../../config";
import { ConvertToReactSelect, LocalStorageKeys, getTransformedDataForPagination } from "../../../utils";
import { ContentWithPositionRequest, DynamicContentField, Template } from "../../slices/documentTemplate";

export const prepareDocumentTemplateBody = (payload: Template) => {

  const left_annotation = (payload.notes.left?.isActive || payload.notes.left?.notes) ? {
    content: {
      content_type: "StaticContent",
      text: payload.notes?.left?.notes,
    },
    alignment: {
      horizontal: payload.notes.left?.alignment?.horizontal?.value,
      vertical: payload.notes.left?.alignment?.vertical?.value,
    },
    is_active: payload.notes.left?.isActive
  } : undefined;

  const right_annotation = (payload.notes.right.isActive || payload.notes.right?.notes) ? {
    content: {
      content_type: "StaticContent",
      text: payload.notes?.right?.notes,
    },
    alignment: {
      horizontal: payload.notes.right?.alignment?.horizontal?.value,
      vertical: payload.notes.right?.alignment?.vertical?.value,
    },
    is_active: payload.notes.right.isActive
  } : undefined;

  const footers = payload.footers.notes?.filter(h => h.show || h.content)?.map((n) => ({
    content: {
      content_type: "StaticContent",
      text: n.content,
    },
    alignment: {
      horizontal: n.xPosition?.value,
      vertical: n.yPosition?.value,
    },
    is_active: n.show
  }));

  const headers: ContentWithPositionRequest[] = payload.notes.header.notes?.filter(h => h.show || h.content).map(
    (h) => ({
      content: {
        content_type: "StaticContent",
        text: h.content,
      },
      alignment: {
        horizontal: h.xPosition?.value as string,
        vertical: h.yPosition?.value as string,
      },
      is_active: h.show
    })
  );

  if (payload.logo.src && payload?.attachments?.length > 0) {
    headers.push({
      content: {
        content_type: "ImageContent",
        height: payload.logo.height,
        width: payload.logo.width,
        s3Key: "",
        attachment_id: payload?.attachments?.at(-1)?._id,
      },
      alignment: {
        horizontal: payload.logo.horizontal?.value as string,
        vertical: payload.logo.vertical?.value as string,
      },
      position: {
        x: payload.logo.width,
        y: payload.logo.height,
      },
      is_active: payload.logo.isActive
    });
  }

  let availableHeaders = [
    payload.cutSheetHeader.materialId,
    payload.cutSheetHeader.materialDescription,
    payload.cutSheetHeader.materialManufactureNo,
    payload.cutSheetHeader.manufacture,
    payload.cutSheetHeader.serialNum,
  ];

  if (
    availableHeaders.some((h) => h === true)
  ) {
    let fields: DynamicContentField[] = [];

    if (availableHeaders[0]) {
      fields.push({
        display_name: "Material ID",
        field_name: "sequence_id",
      });
    }

    if (availableHeaders[1]) {
      fields.push({
        display_name: "Material Description",
        field_name: "short_description",
      });
    }

    if (availableHeaders[2]) {
      fields.push({
        display_name: "Material Manufacturer Number",
        field_name: "vendor.part_number",
      });
    }

    if (availableHeaders[3]) {
      fields.push({
        display_name: "Material Manufacturer Name",
        field_name: "vendor.manufacturer_name",
      });
    }

    if (availableHeaders[4]) {
      fields.push({
        display_name: "Tag Number",
        field_name: "serial_num",
      });
    }

    headers.push({
      content: {
        content_type: "DynamicContent",
        fields: fields,
        object_type: "material",
      },
      alignment: {
        horizontal: payload.cutSheetHeader.alignment?.horizontal
          ?.value as string,
        vertical: payload.cutSheetHeader.alignment?.vertical?.value as string,
      },
      is_active: payload.cutSheetHeader.isActive
    });
  }

  let body: any = {
    description: "",
    footers: footers,
    headers: headers,
    left_annotation: left_annotation,
    margin: {
      border: {
        bottom: payload.margin.showBottomLine,
        left: payload.margin.showLeftLine,
        right: payload.margin.showRightLine,
        top: payload.margin.showTopLine,
      },
      bottom_offset: Number(payload.margin.bottom_offset),
      top_offset: Number(payload.margin.top_offset),
      width_offset: Number(payload.margin.width_offset),
      is_active: payload.margin?.isActive
    },
    orientation: payload.orientation,
    // page_number: payload.paginationFormat.isActive ? {
    //   alignment: {
    //     horizontal: payload.paginationFormat.xPosition?.value as string,
    //     vertical: payload.paginationFormat.yPosition?.value as string,
    //   },
    //   format: payload.paginationFormat.format,
    //   print: payload.paginationFormat.isActive,
    // } : undefined,
    is_footer_active: payload.footers.isActive,
    is_header_active: payload.notes.header.isActive,
    paper_size: payload.paper_size,
    right_annotation: right_annotation,
    name: payload?.name ?? "",
    attachments: payload?.attachments
  };

  return body;
};

export const documentTemplateAPI = createApi({
  reducerPath: "documentTemplateAPI",
  baseQuery: retry(
    fetchBaseQuery({
      baseUrl: config.api_url + "tm/docTemplate/hf",
      prepareHeaders(headers) {
        const token = localStorage.getItem(LocalStorageKeys.authToken);

        // If we have a token set in state, let's assume that we should be passing it.
        if (token) {
          headers.set("authorization", `Bearer ${token}`);
        }

        return headers;
      },
    }),
    { maxRetries: 0 }
  ),
  tagTypes: ["DocumentTemplates", "DocumentTemplate", "SearchDT"],
  endpoints: (builder) => ({
    // Get All Document
    getDocumentTemplates: builder.query({
      query: ({ page = 0, pageSize = 10, sort_by = null, sort_order = null }) => ({
        url: `${page !== null ? `?page_no=${page + 1}` : ''}${pageSize ? `&page_size=${pageSize}${sort_by ? `&sort_by=${sort_by}` : ''}${sort_order ? `&sort_order=${sort_order}` : ''}` : ''}`,
        method: "GET",
      }),
      transformResponse: (data: any, meta: { request: Request; response?: Response }) => {
        return getTransformedDataForPagination(data, meta?.response);
      },
      providesTags: ["DocumentTemplates"],
    }),
    // Get All Document as options
    getDocumentTemplatesDDOptions: builder.query({
      query: () => ({
        method: "GET"
      }),
      transformResponse: (response: any[], meta, arg) => ConvertToReactSelect(response, "_id", "sequence_id", ['name']),
      providesTags: ["DocumentTemplates"],
    }),
    // Get Document Template by id
    getDocumentTemplateById: builder.query({
      query: ({ document_template_id }) => ({
        url: `/${document_template_id}`,
        method: "GET",
      }),
      providesTags: ["DocumentTemplate"]
    }),
    // Create Document Template API
    createDocumentTemplate: builder.mutation({
      query: ({ payload, isPartial = false }: { payload: Partial<Template>, isPartial?: boolean }) => ({
        method: "POST",
        body: isPartial ? payload : { ...prepareDocumentTemplateBody(payload as Template) },
      }),
      invalidatesTags: ["DocumentTemplates"],
    }),
    // Update Document Template API
    updateDocumentTemplate: builder.mutation({
      query: ({ id, payload }) => ({
        url: `/${id}`,
        method: "PUT",
        body: { ...prepareDocumentTemplateBody(payload) },
      }),
      invalidatesTags: ["DocumentTemplates", "DocumentTemplate", "SearchDT"],
    }),
    // Delete Document Template
    deleteDocumentTemplate: builder.mutation({
      query: ({ document_template_id, force = true }) => ({
        url: `/${document_template_id}?force=${force}`,
        method: "DELETE",
      }),
      invalidatesTags: ["DocumentTemplates", "SearchDT"],
    }),

    // Logo Upload
    uploadTemplateLogo: builder.mutation({
      async queryFn(_arg, _queryApi, _extraOptions) {
        const { data, url, file } = _arg;
        let result: any = {};

        try {
          let resData = null;
          if (file) {
            resData = await Axios({
              url,
              method: "put",
              headers: { 'Content-Type': file.type },
              data: file
            });
          }
          result = { data: { resData, document: data } }
        } catch (err) {
          result = { error: err }
        } finally {
          return result;
        }
      },
      invalidatesTags: ["DocumentTemplates", "DocumentTemplate", "SearchDT"],
    }),

    // Search Document Templates By keyword
    searchDocumentTemplates: builder.query({
      query: ({ search = "", page = 0, pageSize = 10, sort_by = null, sort_order = null }) => ({
        url: `/search${page !== null ? `?page_no=${page + 1}` : ''}${pageSize ? `&page_size=${pageSize}${sort_by ? `&sort_by=${sort_by}` : ''}${sort_order ? `&sort_order=${sort_order}` : ''}${search ? `&search=${search}` : ""}` : ''}`,
        method: "GET",
      }),
      transformResponse: (data: any, meta: { request: Request; response?: Response }) => {
        return getTransformedDataForPagination(data, meta?.response);
      },
      providesTags: ["SearchDT"],
    }),
  }),
});

// Export hooks for usage in functional components
export const {
  useGetDocumentTemplatesQuery,
  useLazyGetDocumentTemplatesQuery,
  useGetDocumentTemplateByIdQuery,
  useLazyGetDocumentTemplateByIdQuery,
  useDeleteDocumentTemplateMutation,
  useCreateDocumentTemplateMutation,
  useUpdateDocumentTemplateMutation,
  useGetDocumentTemplatesDDOptionsQuery,
  useLazyGetDocumentTemplatesDDOptionsQuery,
  useUploadTemplateLogoMutation,
  useLazySearchDocumentTemplatesQuery
} = documentTemplateAPI;
