import {
  Box,
  Button,
  Grid,
  Stack,
} from "@mui/material";
import React from "react";
import { type KeywordOption, KeywordSearch } from "../../keywordSearch";

interface Search {
  placeholder?: string;
  onFind: (value: string) => void;
  searchByKeyword: KeywordOption | null;
  onReset: () => void;
}

const FilterBySearch: React.FC<Search> = ({ onFind, searchByKeyword, onReset }) => {
  const [search, setSearch] = React.useState<KeywordOption | null>(searchByKeyword);

  return (
    <Box>
      <Stack
        spacing={2}
        sx={{
          p: 2,
          height: "calc(100vh - 170px)",
          overflow: "hidden",
          position: "relative",
        }}
      >
        <Grid container justifyContent={"center"}>
          <Grid item xs={12} sm={8}>
            <KeywordSearch value={search} onChange={setSearch} onFind={onFind} />
          </Grid>
        </Grid>
      </Stack>
      <Stack
        direction={"row"}
        justifyContent={"end"}
        sx={{
          background: (theme) => theme.palette.background.paper,
          padding: 2,
        }}
      >
        <Button
          variant="outlined"
          onClick={() => {
            setSearch(null);
            onFind("");
            onReset();
          }}
        >
          Reset
        </Button>
      </Stack>
    </Box>
  );
};

export default FilterBySearch;
