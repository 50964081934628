import React from "react";
import { Box, Divider, Stack, Typography, Table, TableHead, TableCell, TableRow, TableBody, Select, MenuItem, Tooltip, Button, IconButton } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../redux";
import { MyPaper } from "../../../components";
import { navigateTab, SelectionInterface, SelectionInterfaceOptions, updateSelectionOptionDetail } from "../../../redux/slices/pmp";
import { AccountTree, ChevronLeft, Save } from "@mui/icons-material";
import { openDialog } from "../../../redux/slices/dialog";
import { DependencyMap } from "./dependencyMap";

const OptionTableHeader = [{ title: "Option (Abbrevation)", width: "40%" }, { title: "Applicable For", width: "50%" }, { title: "Combination", width: "10%" }]


export const SelOptMapping: React.FC<{ children?: JSX.Element }> = (props) => {

    const dispatch = useAppDispatch();
    const { selections, variants } = useAppSelector((store) => store.pmp);

    const openDependencyDialog = (option: SelectionInterfaceOptions, selection: SelectionInterface, selIndex: number, optIndex: number) => {
        dispatch(openDialog({
            body: <DependencyMap option={option} selIndex={selIndex} selection={selection} optIndex={optIndex} />,
            hideNegativeBtn: true,
            hidePositiveBtn: true,
            maxWidth: "md"
        }));
    };

    const giveMeOptionCombination = (option: SelectionInterfaceOptions, sel: SelectionInterface, selIndex: number, optIndex: number) => {
        return variants[sel.id].filter(opt => Object.keys(opt.options).includes(sel.id + "-" + option.id)).length;
    };

    const giveMeTotalCombination = (sel: SelectionInterface, selIndex: number) => {
        return variants[sel.id].length;
    };

    const updateApplicableFor = (selIndex: number, sel: SelectionInterface, optIndex: number, opt: SelectionInterfaceOptions, applicableFor: string) => {
        dispatch(updateSelectionOptionDetail({ selIndex, optIndex, ...opt, applicableFor }));
        if (applicableFor === "select") {
            openDependencyDialog(opt, sel, selIndex, optIndex)
        }
    }

    return <Stack height={"100%"} spacing={1} divider={<Divider />} justifyContent={"space-between"}>

        {/* All Selections */}
        <Stack spacing={1} height={"calc(100% - 50px)"} sx={{ overflow: "scroll" }}>
            {selections.map((sel, selIndex) => {
                return <MyPaper padding={0} key={selIndex}>
                    <Stack divider={<Divider />}>
                        {/* Heading */}
                        <Stack direction={"row"} px={2} py={1} alignItems={"center"} justifyContent={"space-between"}>
                            {/* Selection Details */}
                            <Stack direction={"row"} spacing={1} alignItems={"baseline"}>
                                <Typography variant="body1" fontFamily={"htrts_medium"}>{sel.name}</Typography>
                                <Typography variant="caption">{sel.notes}</Typography>
                            </Stack>

                            {/* Combination Details */}
                            <Stack direction={"row"} spacing={1} alignItems={"center"}>
                                <Typography variant="body2">{"Total Combination: "}</Typography>
                                <Typography variant="body1" fontFamily={"htrts_medium"}>{giveMeTotalCombination(sel, selIndex)}</Typography>
                            </Stack>
                        </Stack>

                        {/* Option Mapping */}
                        <Box px={2} pb={2} width={"100%"} sx={{ overflow: "scroll" }}>
                            <Table size="small" sx={{
                                border: (theme) => `1px solid ${theme.palette.divider}`,
                                marginTop: 2,
                                minWidth: { xs: "768px", sm: '' }
                            }}>

                                {/* Heading */}
                                <TableHead sx={{ background: (theme) => theme.palette.grey[50] }}>
                                    <TableRow>
                                        {
                                            OptionTableHeader.map((head, key) => <TableCell
                                                key={key}
                                                sx={{ fontWeight: "bold", borderRight: (theme) => `1px solid ${theme.palette.divider}` }}
                                                width={head.width}>
                                                {head.title}
                                            </TableCell>)
                                        }
                                    </TableRow>
                                </TableHead>

                                {/* Option Row */}
                                <TableBody>
                                    {sel.options.map((opt, optIndex) => {
                                        return <TableRow key={optIndex}>
                                            {/* Description & Abbrevation */}
                                            <Tooltip title={opt.info}>
                                                <TableCell sx={{ borderRight: (theme) => `1px solid ${theme.palette.divider}` }}>
                                                    {`${opt.desc} (${opt.abbr})`}
                                                </TableCell>
                                            </Tooltip>

                                            {/* Applicable For */}
                                            <TableCell sx={{ borderRight: (theme) => `1px solid ${theme.palette.divider}` }}>
                                                <Stack direction={"row"} spacing={1}>
                                                    {/* Applicable For */}
                                                    <Select
                                                        fullWidth
                                                        size="small"
                                                        disabled={selIndex === 0}
                                                        value={opt.applicableFor}
                                                        onChange={(e) => updateApplicableFor(selIndex, sel, optIndex, opt, e.target.value)}
                                                    >
                                                        <MenuItem value={"all"}>All</MenuItem>
                                                        <MenuItem value={"select"}>Select</MenuItem>
                                                    </Select>

                                                    {/* Option Mapping */}
                                                    {opt.applicableFor === "select" && <IconButton size="small" onClick={(e) => openDependencyDialog(opt, sel, selIndex, optIndex)}>
                                                        <AccountTree htmlColor="black" fontSize="small" />
                                                    </IconButton>}
                                                </Stack>
                                            </TableCell>

                                            {/* Combination */}
                                            <TableCell sx={{ fontFamily: "htrts_medium" }}>
                                                {giveMeOptionCombination(opt, sel, selIndex, optIndex)}
                                            </TableCell>
                                        </TableRow>
                                    })}
                                </TableBody>
                            </Table>
                        </Box>
                    </Stack>
                </MyPaper>
            })}
        </Stack>

        {/* Footer */}
        <MyPaper>
            <Stack spacing={1} p={1} direction={"row"} alignItems={"center"} justifyContent={"center"}>
                <Button onClick={(e) => dispatch(navigateTab(1))} size={"small"} variant="outlined" color="primary" startIcon={<ChevronLeft />}>Back</Button>
                <Button onClick={(e) => dispatch(navigateTab(3))} size={"small"} variant="contained" color="primary" startIcon={<Save />}>Save</Button>
            </Stack>
        </MyPaper>
    </Stack>
}