import React from 'react';
import { Box, Button, Grid, Typography, } from '@mui/material';

export const NotFoundSection: React.FC<{
    message?: string,
    top?: string,
    actionBtnName?: string,
    showActionBtn?: boolean,
    onActionButtonClicked?: any
}> = (props) => {

    const {
        message = "404 - Nothing to Show",
        top = "30vh",
        showActionBtn = false,
        actionBtnName = 'Button',
        onActionButtonClicked = () => false
    } = props;

    return <Box sx={{ width: "100%" }}>
        <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            sx={{ marginTop: top }}
        >
            <Grid item>
                <Typography variant="body1" align="center">
                    {message}
                </Typography>
            </Grid>

            {showActionBtn && <Grid item>
                <Button variant="contained" color="primary" onClick={onActionButtonClicked} id={`${"Add" + actionBtnName}`}>
                    {actionBtnName}
                </Button>
            </Grid>}
        </Grid>
    </Box>
}
