import { createSlice } from '@reduxjs/toolkit';
import copy from 'fast-copy';
import { Association, SelectBoxOption } from '../../../interfaces';
import { Attachment } from '../material';

export interface ProjectBasicDetails { project_name: string, description: string, type: SelectBoxOption | null, cost_center: string | null, businessUnit: SelectBoxOption | null, customer: string, projectManager: string, status: SelectBoxOption | null, reference_number: string, start_date: Date | null | undefined, finish_date: Date | null | undefined}

export interface ProjectTags { tags: SelectBoxOption[] }

export interface linksData {
    links?: { object_type: string, object_ids: string[] }[],

    unlinks?: { object_type: string, object_ids: string[] }[],
}

interface ProjectBasicDetailsError { project_name: boolean, description: boolean, type: boolean, cost_center: boolean, businessUnit: boolean, customer: boolean, projectManager: boolean, status: boolean, reference_number: boolean, start_date: boolean, finish_date: boolean }

interface ProjectTagsError { tags: boolean }
export interface ProjectSliceInterface {
    // Basic Details
    basic_details: ProjectBasicDetails,

    // Project Tags
    project_tags: ProjectTags,

    notes: string,

    // Links
    links_data?: linksData,

    attachments: Attachment[],

    associations: Association[],

    sequenceId?: string,
    version?: number,

    error: {
        // Basic Details
        basic_details: ProjectBasicDetailsError,

        // Project Tags
        project_tags: ProjectTagsError
    }
}

const initialState: ProjectSliceInterface = {
    basic_details: { project_name: "", description: "", type: null, cost_center: null, businessUnit: null, customer: "", projectManager: "", status: null, reference_number: "",  start_date: null, finish_date: null },
    notes: "",
    project_tags: { tags: [] },links_data:{links: [], unlinks:[]},
    attachments: [],
    associations: [],
    error: {
        basic_details: { project_name: false, description: false, type: false, cost_center: false, businessUnit: false, customer: false, projectManager: false, status: false, reference_number: false,  start_date: false, finish_date: false },
        project_tags: { tags: false }
    }
}

export const projecrSlice = createSlice({
    name: 'project',
    initialState,
    reducers: {
        // To Slice by key value pair
        updateProjectSliceByKeyValue(state, { payload }: { payload: { parentKey: string, childKey: string, value: any } }) {
            let newState: any = copy(state);
            newState.error[payload.parentKey][payload.childKey] = payload.value ? false : true;
            newState[payload.parentKey][payload.childKey] = payload.value;
            return { ...state, ...newState }
        },
        // To Update notes
        updateNotes(state, { payload }: { payload: { notes: string } }) {
            let newState: any = copy(state);
            newState.notes = payload;
            return { ...state, ...newState };
        },
        // To update errors
        updateErrors(state, { payload }: { payload: { parentKey: keyof ProjectSliceInterface, childKey: keyof ProjectBasicDetails | keyof ProjectTags }[] }) {
            let project: ProjectSliceInterface = copy(state);
            let error = project.error;
            payload.forEach((key) => {
                if (key.parentKey === 'basic_details') {
                    if (!project.basic_details[key.childKey as keyof ProjectBasicDetails]) {
                        error.basic_details[key.childKey as keyof ProjectBasicDetails] = true;
                    }
                }

                if (key.parentKey === 'project_tags') {
                    if (key.childKey === 'tags') {
                        if (project.project_tags[key.childKey as keyof ProjectTags].length === 0) {
                            error.project_tags[key.childKey as keyof ProjectTags] = true;
                        }
                    } else {
                        if (!project.project_tags[key.childKey as keyof ProjectTags]) {
                            error.project_tags[key.childKey as keyof ProjectTags] = true;
                        }
                    }
                }

            })

            return { ...state, error }
        },

        // To reset all values to initialValue
        resetProjectSlice(state) {
            return { ...state, ...initialState }
        },

        // To Bulk Update the slice
        updateProjectSlice(state, { payload }: {
            payload: {
                // Basic Details
                basic_details: ProjectBasicDetails,
                notes: string,
                // Project Tags
                project_tags: ProjectTags,

                links_data: linksData,
                attachments: Attachment[],
                associations: Association[],
                sequenceId: string,
                version: number
            }
        }) {
            return { ...state, ...payload }
        }
    }
});

export const { updateProjectSliceByKeyValue, updateProjectSlice, resetProjectSlice, updateErrors, updateNotes } = projecrSlice.actions;
export default projecrSlice.reducer;