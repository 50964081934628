import { createApi, fetchBaseQuery, retry } from '@reduxjs/toolkit/query/react';
import { config } from '../../../config';
import { ConvertToReactSelect, getTransformedDataForPagination, LocalStorageKeys, objectToQueryParams } from '../../../utils';

export const nounAPI = createApi({
    reducerPath: "nounAPI",
    baseQuery: retry(fetchBaseQuery({
        baseUrl: config.api_url + "mm/mdg/",
        prepareHeaders: (headers, { getState }) => {
            const token = localStorage.getItem(LocalStorageKeys.authToken);

            // If we have a token set in state, let's assume that we should be passing it.
            if (token) {
                headers.set('authorization', `Bearer ${token}`)
            }

            return headers
        },
    }), { maxRetries: 2 }),
    tagTypes: ['Nouns', 'NounsAsOptions', 'NounsMapping', 'NounsSearch'],
    endpoints: (builder) => ({
        // Get All Noun API
        getNouns: builder.query({
            query: (payload: { page: number | null, pageSize: number | null, uom_id?: string, sort_by?: string | null, sort_order?: string | null }) => ({ url: `nouns${objectToQueryParams({...payload, page_size: payload?.pageSize, page_no: (typeof payload?.page === "number" ? payload.page + 1 : undefined)})}`, method: "GET" }),
            transformResponse: (data: any, meta: { request: Request; response?: Response }) => {
                return getTransformedDataForPagination(data, meta?.response);
            },
            providesTags: ['Nouns']
        }),

        // Get All Noun API for dropdown 
        getNounsDD: builder.query({
            query: (payload) => ({ url: `nouns${objectToQueryParams({...payload, page_size: payload?.pageSize, page_no: (typeof payload?.page === "number" ? payload.page + 1 : undefined)})}`, method: "GET" }),
            transformResponse: (response: any[], meta, arg) => ConvertToReactSelect(response, "_id", "noun_name"),
            providesTags: ['NounsAsOptions']
        }),

        // Get Noun based on UOM ID and GROUP ID API for dropdown 
        getNounsMappingDD: builder.query({
            query: ({ uom_id, group_id }) => {
                return { url: `nouns?uom_id=${uom_id}&group_id=${group_id}`, method: "GET" }
            },
            transformResponse: (response: any[], meta, arg) => ConvertToReactSelect(response, "_id", "noun_name"),
            providesTags: ['NounsMapping']
        }),

        // Search Noun API
        searchNouns: builder.query({
            query: (payload) => {
                let url = payload.search ? `noun?search=${payload.search}` : 'noun';
                if (payload.uom_id) url += `${payload.search ? '&' : '?'}uom_id=${payload.uom_id}`;
                return { url, method: "GET" }
            },
            providesTags: ['NounsSearch']
        }),

        // Get Noun By Id API
        getNounById: builder.query({
            query: (payload) => {
                let url =`noun/${payload.id}`;
                return { url, method: "GET" }
            }
        }),

        // Create Noun API
        createNoun: builder.mutation({
            query: (payload) => ({ url: `noun`, method: "POST", body: payload }),
            invalidatesTags: ['Nouns', 'NounsAsOptions', 'NounsMapping', 'NounsSearch']
        }),

        // Update Noun API
        updateNoun: builder.mutation({
            query: (payload) => ({ url: `noun`, method: "PUT", body: payload }),
            invalidatesTags: ['Nouns', 'NounsAsOptions', 'NounsMapping', 'NounsSearch']
        }),

        // Delete Noun API
        deleteNoun: builder.mutation({
            query: ({ id }) => ({ url: `noun/${id}`, method: "DELETE" }),
            invalidatesTags: ['Nouns', 'NounsAsOptions', 'NounsMapping', 'NounsSearch']
        })
    })
})

// Export hooks for usage in functional components
export const { useGetNounsQuery, useGetNounsDDQuery, useGetNounByIdQuery, useLazyGetNounByIdQuery, useGetNounsMappingDDQuery, useLazyGetNounsMappingDDQuery, useLazyGetNounsDDQuery, useLazyGetNounsQuery, useLazySearchNounsQuery, useCreateNounMutation, useUpdateNounMutation, useDeleteNounMutation } = nounAPI
