import { Stack, Grid, Tooltip, FormControlLabel, Checkbox, Box } from "@mui/material";
import { HighlightSelectBox, SectionPaper, TextField } from "../../../components"
import React from "react";
import { SearchParameters } from "./paramters";
import { FoundResults } from "./foundResults";
import { GridRowSelectionModel } from "@mui/x-data-grid-premium";
import { useSearchSupplierMutation } from "../../../redux/services";
import { useAppDispatch, useAppSelector } from "../../../redux";
import { LoadingButton } from "@mui/lab";
import { SelectBoxOption } from "../../../interfaces";
import { useSearchParams } from "react-router-dom";
import { closeBackdrop, openBackdrop } from "../../../redux/slices/backdrop";
import { Edit } from "@mui/icons-material";
import { useSnackbar } from "notistack";
import { AttributeDetail, MatSliceInterface } from "../../../redux/slices/material";
import { SourcingOption } from "../../viewBOM/sourcing";

interface PayloadObj { name: string, value?: string, [index: string]: any }

const dsOptions = [{ label: "eqhub", value: "eqhub" }];

const ResultsOptions = [{ label: "10", value: 10 }, { label: "20", value: 20 }, { label: "30", value: 30 }, { label: "40", value: 40 }, { label: "50", value: 50 }];

export function buildRows(materials: MatSliceInterface) {
    const omitKeys = new Set([
        "rawMaterial", "released", "sequenceId", "matId",
        "detailedSpecification", "drawingNumber", "error",
        "inventoryType", "bomType", "associations", "attachments",
        "physicalMaterial", "mdgType", "version", "links_data",
        "preferedModelManufNo", "preferedModelManufName", "varientId"
    ]);

    const rows = [];

    const splitCamelCaseAndCapitalize = (str: string) => {
        return str
            .replace(/([a-z])([A-Z])/g, '$1 $2')
            .replace(/_/g, ' ')
            .replace(/\b\w/g, char => char.toUpperCase());
    };

    for (const key in materials) {
        if (omitKeys.has(key)) {
            continue;
        }

        let value = materials[key as keyof MatSliceInterface];
        if (!value || (typeof value === 'object' && !Array.isArray(value) && !(value as any)?.value)) {
            continue;
        }
        if (typeof value === 'object' && value !== null && 'label' in value) {
            value = value.label;
        }


        if (key === "sourceType") {
            value = SourcingOption.find(s => s.value === value)?.label
        }

        if (key === "attribute_details") {
            if (Array.isArray(value)) {
                let collections = (value as AttributeDetail[])?.map((v, idx) => ({
                    id: v.attr_name,
                    description: v.attr_name,
                    value: v.option_abbr
                }))
                rows.push(...collections);
            }
        } else {
            let row = {
                id: key,
                description: splitCamelCaseAndCapitalize(key),
                value: value
            };
            rows.push(row);
        }

    }


    return rows;
}

export const MaterialProcure = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const dispatch = useAppDispatch();
    const { enqueueSnackbar } = useSnackbar();

    const [searchSupplier, supplierResponse] = useSearchSupplierMutation();

    const { materials } = useAppSelector(store => store);

    const contentRef = React.useRef<HTMLDivElement>(null);

    const [calculatedHeight, setHeight] = React.useState(0);
    const [selectedDsName, setSelectedDsName] = React.useState<SelectBoxOption>(dsOptions[0]);
    const [includeReference, setReference] = React.useState<boolean>(false);
    const [maxResults, setMaxResults] = React.useState<SelectBoxOption>(ResultsOptions[0]);

    // Parameters State
    const [rowSelectionModel, setRowSelectionModel] = React.useState<GridRowSelectionModel>([]);
    const [dataSheet, setDataSheet] = React.useState<any[]>([]);
    const [basicDetails, setBasicDetails] = React.useState<any[]>([]);
    const [keywordSelections, setKeywordSelections] = React.useState<GridRowSelectionModel>([]);

    console.log("materials", materials, dataSheet)

    const onReset = () => {
        setRowSelectionModel([]);
        setKeywordSelections([]);
        setDataSheet((prevDs) => prevDs.map(ds => ({ ...ds, rowSelectionModel: [] })))
    };

    console.log("rows", basicDetails)

    const getKeywords = () => {
        let str: string[] = [];

        basicDetails.forEach((b) => {
            if (keywordSelections.includes(b.id)) {
                str.push(b.value)
            }
        })

        return str;
    }

    const onFind = async () => {
        let kW = getKeywords();

        if (kW.length <= 0) {
            enqueueSnackbar("Please select at least one keyword to perform the search.", { variant: "warning" });
            return;
        }

        dispatch(openBackdrop("Searching suppliers..."));
        const refIds: string[] = [];
        if (includeReference) {
            materials?.attribute_details?.forEach((attr: any) => {
                if (attr.attr_type === "Primary") {
                    refIds.push(...(attr?.reference_metadata || [])?.map((r: any) => r.id));
                }
            })
        }

        const params: PayloadObj[] = [];

        basicDetails.forEach((b) => {
            if (rowSelectionModel.includes(b.id)) {
                params.push({ name: b.id, value: b.value });
            }
        })

        dataSheet.forEach(ds => {
            ds?.definition?.rows?.forEach((r: any) => {
                if (ds.rowSelectionModel.includes(r.id)) {
                    let obj: Record<string, any> = {};
                    ds?.definition?.columns.slice(1).forEach((c: { header_name: string, field: string }) => {
                        obj[c["header_name"]] = r[c["field"]]
                    })
                    params.push({ name: r[ds?.definition?.columns[0].field], ...obj });
                }
            })
        })
        
        const payload = {
            keywords: kW.length > 1 ? kW : kW[0] ?? "",
            reference_ids: refIds,
            search_params: params,
            format_search_params: true,
            max_results: maxResults?.value || 10,
        };

        await searchSupplier(payload).unwrap().then((res) => {
            if (res.success) {
                localStorage.setItem("airesponse", JSON.stringify(res))
                setSearchParams({ tab: "tab2" });
            }
        }).catch((err) => {
            console.log(`Error while searching suppliers: ${JSON.stringify(err)}`)
            enqueueSnackbar(err?.data?.message ?? err?.data ?? "Unable to search the suppliers", { variant: "error" });
        }).finally(() => {
            dispatch(closeBackdrop())
        })
    }

    const keywords = React.useMemo(() => {
        return getKeywords().join(", ")
        // eslint-disable-next-line
    }, [keywordSelections])

    React.useEffect(() => {
        const handleResize = () => {
            setHeight(contentRef?.current?.clientWidth ?? 0);
        };

        window.addEventListener('resize', handleResize);

        if (!calculatedHeight) {
            setHeight(contentRef?.current?.clientWidth ?? 0);
        }

        return () => {
            window.removeEventListener('resize', handleResize);
        };
        // eslint-disable-next-line
    }, []);

    return <Stack>
        <React.Fragment>
            <SectionPaper
                title={searchParams.get("tab") === "tab1" ? "Configuration" : "Procured Material - Supplier Results"}
                renderButtons={searchParams.get("tab") === "tab2" ? [
                    <Tooltip title="Edit Parameters"><Edit color="primary" sx={{ cursor: "pointer" }} onClick={() => setSearchParams({ ...searchParams, tab: "tab1" })} /></Tooltip>
                ] : [
                    <FormControlLabel
                        control={
                            <Checkbox
                                title="Include Reference Ids"
                                checked={includeReference}
                                onChange={() => {
                                    setReference(!includeReference);
                                }}
                            />
                        }
                        label="Include Reference Ids"
                    />,
                    <Box sx={{ minWidth: 100 }}>
                        <HighlightSelectBox
                            disableClearable
                            label={"Max Results"}
                            margin={"none"}
                            size="small"
                            value={maxResults}
                            options={ResultsOptions}
                            onChange={(data: SelectBoxOption) => {
                                setMaxResults(data)
                            }}
                        />
                    </Box>
                ]}
            >
                <Grid container spacing={2}>
                    <Grid item xs={12} md={8}>
                        <HighlightSelectBox
                            disableClearable
                            label={"Database Name"}
                            margin={"none"}
                            size="small"
                            value={selectedDsName}
                            options={dsOptions}
                            onChange={(data: SelectBoxOption) => {
                                setSelectedDsName(data)
                            }}
                            disabled={true}
                            // viewOnly
                        />
                    </Grid>
                    {searchParams.get("tab") === "tab1" &&
                        <Grid item xs={12} md={4}>
                            <Stack direction={"row"} gap={2}>
                                <LoadingButton
                                    fullWidth
                                    size="medium"
                                    variant="contained"
                                    onClick={onFind}
                                    loading={supplierResponse.isLoading}
                                    disabled={!selectedDsName}
                                >
                                    Search
                                </LoadingButton>
                                <LoadingButton
                                    fullWidth
                                    size="medium"
                                    variant="outlined"
                                    onClick={onReset}
                                    loading={supplierResponse.isLoading}
                                >
                                    Reset
                                </LoadingButton>
                            </Stack>
                        </Grid>
                    }
                    {keywords.length > 0 &&
                        <Grid item xs={12}>
                            <TextField label={"Keywords"} value={keywords} viewOnly />
                        </Grid>
                    }
                </Grid>
            </SectionPaper>
            <Stack ref={contentRef}>
                {searchParams.get("tab") === "tab1" && (
                    <SearchParameters
                        rowSelectionModel={rowSelectionModel}
                        setRowSelectionModel={setRowSelectionModel}
                        dataSheet={dataSheet}
                        setDataSheet={setDataSheet}
                        keywordSelections={keywordSelections}
                        setKeywordSelections={setKeywordSelections}
                        rows={basicDetails}
                        setRows={setBasicDetails}
                    />
                )}
                {searchParams.get("tab") === "tab2" && <FoundResults response={supplierResponse} />}
            </Stack>

            {/* <MyPaper sx={{ position: 'fixed', bottom: 0, width: calculatedHeight, boxShadow: `rgba(0, 0, 0, 0.24) 0px 3px 8px` }}>
                <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                    <Button
                        variant="outlined"
                        color="inherit"
                        disabled={activeStep === 0}
                        onClick={handleBack}
                        sx={{ mr: 1 }}
                    >
                        Back
                    </Button>
                    <Box sx={{ flex: '1 1 auto' }} />
                    {activeStep < steps.length - 1 && <Button onClick={handleNext} variant="contained">
                        {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                    </Button>}
                </Box>
            </MyPaper> */}
        </React.Fragment>
    </Stack>
}