import { ContentCopy, DeleteOutline, Edit, Link as IconLink, LockReset, ZoomOutMapTwoTone } from "@mui/icons-material";
import { Button, IconButton, Tooltip, Typography } from "@mui/material";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid-premium";
import moment from "moment";
import { Link } from "react-router-dom";
import { DownloadDoc } from "../../components";
import { PublishedIcon } from "../../images/svg/publishedIcon";
import { WIPIcon } from "../../images/svg/WIPIcon";
import { AppRoutes } from "../../router/routes";
import { LocalStorageKeys, parseJwt } from "../../utils";

export const DashboardTableColumn = (onDelete: any, view: any, openAssociations: (row: any) => void, updatePublishStatus: (row: any) => void, hasAccess: boolean, forceRelease?: (row: any) => void, handleDuplicate?: (row: any) => void) => {
    let columns: GridColDef[] = [
        {
            field: 'sequence_id', headerName: 'Drawing Template ID', width: 200, renderCell: (params: GridRenderCellParams) => {
                if (params.rowNode.type === 'group') {
                    return params.value;
                }
                return <Typography color="primary" sx={hasAccess ? { color: 'blue', textDecoration: "underline", cursor: "pointer" } : { textDecoration: "none" }} variant="body2" onClick={() => view(params.row)}>{params.row?.sequence_id}</Typography >
            }
        },
        { field: 'version', headerName: 'Revision', width: 150 },
        {
            field: 'file_name', headerName: 'Drawing Template Name', width: 250, renderCell: (params: GridRenderCellParams) => {
                if (params.rowNode.type === 'group') {
                    return params.value;
                }
                return <Tooltip title={params.row.file_name}><Typography variant="body2" sx={{ width: "100%", overflow: "hidden", textOverflow: "ellipsis" }}>{params.row.file_name}</Typography></Tooltip>
            }
        },
        {
            field: 'serial_num',
            headerName: 'Sequence Number',
            width: 150,
            renderCell: (params: GridRenderCellParams) => {
                return <Typography variant="body2" >{params?.row?.serial_num ?? ""}</Typography>
            }
        },
        { field: 'created_user', headerName: 'Uploaded By', width: 150 },
        {
            field: 'created_time', headerName: 'Uploaded Date', width: 250, renderCell: (params: GridRenderCellParams) => {
                return <Typography variant="body2">{moment(params.row.created_time).format('lll')}</Typography>
            }
        },
        { field: 'modified_user', headerName: 'Last modified by', width: 150 },
        {
            field: 'modified_time', headerName: 'Last modified date', width: 250, renderCell: (params: GridRenderCellParams) => {
                if (params.rowNode.type === 'group') {
                    return params.value;
                }
                return <Typography variant="body2">{params.row.modified_time ? moment(params.row.modified_time).format('lll') : ""}</Typography>
            }
        }
    ];

    if (hasAccess) {
        columns = [...columns.slice(0, 2), {
            field: "duplicate",
            headerName: "Duplicate",
            resizable: true,
            width: 90,
            disableColumnMenu: true,
            sortable: false,
            renderCell: (params: GridRenderCellParams) => {
                if (params.rowNode.type === "group") {
                    return params.value;
                }
                return (
                    <Tooltip title="Make a Duplicate">
                        <IconButton
                            sx={{ margin: "auto" }}
                            onClick={() =>
                                handleDuplicate && handleDuplicate(params?.row)
                            }
                        >
                            <ContentCopy fontSize="small" />
                        </IconButton>
                    </Tooltip>
                );
            },
        }, ...columns.slice(2, 3), {
            field: 'released',
            headerName: 'Status',
            width: 150,
            renderCell: (params: GridRenderCellParams) => {
                return (
                    params?.row?.released
                        ? <Button disableRipple disableFocusRipple sx={{ cursor: "default", '&:hover': { backgroundColor: 'transparent' } }} id="listView-published">
                            <Tooltip
                                title="Published"
                                children={<div><PublishedIcon /></div>}
                            />
                        </Button>
                        : <Button disableRipple disableFocusRipple>
                            <Tooltip title="Work in progress, click to publish the item"
                                children={
                                    <div><WIPIcon onClick={() => updatePublishStatus(params.row)} /></div>
                                }
                            />
                        </Button>
                )
            }
        }, {
            field: 'download', headerName: 'Download', width: 100, renderCell: (params: GridRenderCellParams) => {
                return <DownloadDoc id={params.row._id} url={params.row.url} status={params.row.status} />
            },
            disableColumnMenu: true,
            sortable: false,
        },
        {
            field: 'edit', headerName: 'Edit', width: 100, renderCell: (params: GridRenderCellParams) => {
                if (params.rowNode.type === 'group') {
                    return params.value;
                }

                const tokenData = parseJwt(localStorage.getItem(LocalStorageKeys.authToken) ?? "");

                if (params.row?.activity?.opened && tokenData.sub !== params.row?.activity?.user_id) {
                    return <Tooltip title={`Document locked, Since user "${params.row?.activity?.user_name}" is still editing. Click to enforce Force Release`}>
                        <IconButton onClick={() => forceRelease ? forceRelease(params.row) : null}>
                            <LockReset color="error" />
                        </IconButton>
                    </Tooltip>
                } else if (params.row.released) {
                    return <Tooltip title={`Document is published, So you are not allowed to editing.`}>
                        <Edit fontSize="small" color="disabled" />
                    </Tooltip>
                } else {
                    return (
                        <Link target="_blank" to={AppRoutes.drawioDiagramEditor(params.row._id) + "?file_name=" + params.row.file_name}>
                            <Edit color={"info"} fontSize="small" />
                        </Link>
                    )
                }
            },
            disableColumnMenu: true,
            sortable: false,
        },
        {
            field: 'preview', headerName: 'Preview', width: 100, renderCell: (params: GridRenderCellParams) => {
                if (params.rowNode.type === 'group') {
                    return params.value;
                }
                return (
                    <Link target="_blank" to={AppRoutes.drawioDiagramPreview(params.row._id) + "?file_name=" + params.row.file_name}>
                        <ZoomOutMapTwoTone color={"info"} fontSize="small" />
                    </Link>
                )
            },
            disableColumnMenu: true,
            sortable: false,
        },
        {
            field: 'link', headerName: 'Associations', width: 150, renderCell: (params: GridRenderCellParams) => {
                return (
                    <IconButton disabled={params.row?.associations?.length === 0} onClick={() => openAssociations(params.row)}>
                        <IconLink color={params.row.associations?.length === 0 ? "disabled" : "info"} />
                    </IconButton>
                )
            },
            disableColumnMenu: true,
            sortable: false,
        }, ...columns.slice(3), {
            field: 'delete', headerName: 'Delete', width: 100, renderCell: (params: GridRenderCellParams) => {
                return <IconButton onClick={() => onDelete(params.row)}>
                    <DeleteOutline color="error" />
                </IconButton>
            },
            disableColumnMenu: true,
            sortable: false,
        }]
    }

    return columns;
};

export const emptyDrawioDiagramBase64Str = `data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB2ZXJzaW9uPSIxLjEiIHdpZHRoPSIxcHgiIGhlaWdodD0iMXB4IiB2aWV3Qm94PSItMC41IC0wLjUgMSAxIiBjb250ZW50PSImbHQ7bXhmaWxlIGV0YWc9JnF1b3Q7NldjWVcxUHA2T2lfQ1cxLXRQVjcmcXVvdDsgYWdlbnQ9JnF1b3Q7TW96aWxsYS81LjAgKFdpbmRvd3MgTlQgMTAuMDsgV2luNjQ7IHg2NCkgQXBwbGVXZWJLaXQvNTM3LjM2IChLSFRNTCwgbGlrZSBHZWNrbykgQ2hyb21lLzEyMy4wLjAuMCBTYWZhcmkvNTM3LjM2JnF1b3Q7IG1vZGlmaWVkPSZxdW90OzIwMjQtMDMtMjZUMTM6MDk6MjAuODM2WiZxdW90OyBob3N0PSZxdW90O2xvY2FsaG9zdCZxdW90OyB2ZXJzaW9uPSZxdW90OzI0LjAuNyZxdW90OyB0eXBlPSZxdW90O2VtYmVkJnF1b3Q7Jmd0OyZsdDtkaWFncmFtIGlkPSZxdW90O3JVdXh2bWFtZE5aMXpyTFhPbF82JnF1b3Q7IG5hbWU9JnF1b3Q7UGFnZS0xJnF1b3Q7Jmd0O2xkRlBENElnRkFEd1Q4T3hEY0ZXMTZaV2wxcXJRNXMzSm9SczZITkl5L3IwNmNDTWVha1RqeCtQOS9pRGFGSjFPOE9hOGdCY2FFUXc3eEJORVNGUlRPTitHT1RwWkxVaURxUlIzQ2ROY0ZFdjRSRjd2U3N1MmlEUkFtaXJtaEFMcUd0UjJNQ1lNZkFJMDI2Z3c2NE5rMklHbDRMcHVWNFZ0NlhUOVJKUHZoZEtsbVBuQ1B1VmlvM0pIdHFTY1hoOEVjMFFUUXlBZFZIVkpVSVBqemUreXpFNnBUR2g1MFdkNDBLU1RaWkx0WERGdHY5cytWekJpTnIrV3JvUHBxUDFrK0NEYWZZRyZsdDsvZGlhZ3JhbSZndDsmbHQ7L214ZmlsZSZndDsiPjxkZWZzLz48Zy8+PC9zdmc+`;

export const emptyDrawioDiagramXMLStr = `
<mxGraphModel dx="794" dy="412" grid="1" gridSize="10" guides="1" tooltips="1" connect="1" arrows="1" fold="1" page="1" pageScale="1" pageWidth="850" pageHeight="1100" math="0" shadow="0">
  <root>
    <mxCell id="0" />
    <mxCell id="1" parent="0" />
  </root>
</mxGraphModel>
`;


// Convert base64 string to blob
export const getEmptyDrawioDiagram = async () => {
    // const blob = await fetch(emptyDrawioDiagramBase64Str).then(response => response.blob());
    const blob = new Blob([emptyDrawioDiagramXMLStr], { type: 'text/xml' });
    return blob;
}
