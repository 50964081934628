import { Box } from "@mui/material";
import React from "react";
import { matchPath, useLocation, useNavigate, useParams } from "react-router-dom";
import { withNavBars } from "../../HOCs";
import { Layout } from "../../components";
import { AppRoutes } from "../../router/routes";
import { BasicDetails } from "./basicDetails";
import { CutSheets } from "./cutSheets";
import { NavBars } from "./utils";

const ViewCutsheets: React.FC<{ children?: JSX.Element, currentPath: string }> = ({ currentPath }) => {

    const params = useParams();
    const navigate = useNavigate();
    const location = useLocation();

    const cutsheetId = params?.cutsheet_id ?? "New";

    const isEdit = cutsheetId !== ':cutsheet_id' && cutsheetId !== "New";

    const giveMeComponent = () => {
        // Basic Details
        if (matchPath(location.pathname, AppRoutes.viewCutSheetBasicDetails(cutsheetId))) {
            return <BasicDetails cutsheetId={cutsheetId}  />
        }

        // CutSheets
        if (matchPath(location.pathname, AppRoutes.viewCutSheets(cutsheetId))) {
            return <CutSheets cutsheetId={cutsheetId}  />
        }

        return <></>
    };

    React.useEffect(() => {
        if (cutsheetId === ':cutsheet_id') {
            navigate(AppRoutes.cutSheetBuilder);
        }
    });

    React.useEffect(() => {
        if (isEdit) {
        } else {
        }
        // eslint-disable-next-line    
    }, [])

    return <Box p={0.5} height={"100%"}>
        <Layout
            history={[
                { label: "Document", onClick: () => false },
                { label: "Cutsheet Builder", onClick: () => navigate(AppRoutes.cutSheetBuilder) },
                { label: `${cutsheetId}`, onClick: () => navigate(AppRoutes.viewCutSheetBasicDetails(cutsheetId)) }
            ]}
            currentPath={currentPath}
            navBars={NavBars(cutsheetId, isEdit)}
            mainPanel={giveMeComponent()}
            sideNavVariant={"whiteboard"}
        />
    </Box>
}

export default withNavBars(ViewCutsheets);