import { SelectBoxOption } from "../../../interfaces";
import { HorizontalPosition, VerticalPosition } from "../../../utils";
import { HighlightSelectBox } from "../../highLightSelectBox";
import { CollapsableHeader } from "../header";
import { Box, Grid, Stack, TextField, Tooltip, Typography, useMediaQuery } from "@mui/material";
import { LogoError } from "../../../redux/slices/documentTemplate";
import { ImageUploadList } from "../../imageUploadList";
import { Attachment } from "../../../redux/slices/material";
import { Link } from "@mui/icons-material";

export const ImageUpload: React.FC<{
  title: string;

  showSwitch?: boolean;
  switchStatus?: boolean;
  onSwitchChanged?: (isChecked: boolean) => void;

  image: File | Attachment | null;
  onImageUpload: (file: File) => void;
  onDelete: (file: File | Attachment) => void;

  showBtn?: boolean;
  btnName: string;

  showHorizontalPosition: boolean;
  horizontalPosition: SelectBoxOption | null;
  onHorizontalPositionChange: (position: SelectBoxOption) => void;

  showVerticalPosition: boolean;
  verticalPosition: SelectBoxOption | null;
  onVerticalPositionChange: (position: SelectBoxOption) => void;

  height: number;
  width: number;
  onHeightChange: (height: number) => void;
  onWidthChange: (Width: number) => void;

  error: LogoError;
  children?: JSX.Element;
}> = (props) => {
  const { error } = props;

  const isMatch = useMediaQuery("(max-width:1530px)");

  const isMedium = useMediaQuery("(max-width: 900px)");

  return (
    <CollapsableHeader
      title={props.title}
      showIcon={true}
      isExpanded={true}
      showSwitch={props.showSwitch}
      switchStatus={props.switchStatus}
      onSwitchChanged={props.onSwitchChanged}
    >
      <>
        <Box p={1}>
          <ImageUploadList
            title={props.btnName}
            files={props?.image ? [props?.image] : []}
            maxFiles={1}
            onDelete={props.onDelete}
            onUpload={props.onImageUpload}
            disableCollapse
          />

          <Grid
            container
            mt={2}
            columnGap={isMatch ? 0 : 1}
            rowGap={1}
            justifyContent={"space-between"}
            columns={isMatch ? 12 : 13}
          >
            <Grid item xs={12} sm={12} md={5} xl={4}>
              <Stack direction="row" alignItems="center" gap={1}>
                <TextField
                  size="small"
                  label="Height"
                  type="number"
                  fullWidth
                  value={props.height}
                  onChange={(e) => props.onHeightChange(Number(e.target.value))}
                  error={error.heightError}
                  helperText={
                    error.heightError ? "Please enter the logo height" : ""
                  }
                />
                <Typography variant="body2">px</Typography>
              </Stack>
            </Grid>
            <Grid item xs={12} sm={12} md={1}>
              <Stack height={"100%"} alignItems={"center"} justifyContent={"center"}><Tooltip title="Aspect ratio locked"><Link sx={isMedium ? { transform: "rotate(90deg)" } : {}} /></Tooltip></Stack>
            </Grid>
            <Grid item xs={12} sm={12} md={5} xl={4}>
              <Stack direction="row" alignItems="center" gap={1}>
                <TextField
                  size="small"
                  label="Width"
                  type="number"
                  fullWidth
                  value={props.width}
                  onChange={(e) => props.onWidthChange(Number(e.target.value))}
                  error={error.widthError}
                  helperText={error.widthError ? "Please enter the logo width" : ""}
                />
                <Typography variant="body2">px</Typography>
              </Stack>
            </Grid>
          </Grid>
          <Grid
            container
            spacing={2}
            columnGap={isMatch ? 0 : 1}
            justifyContent={"space-between"}
            columns={isMatch ? 12 : 13}
            mt={1}
          >
            <Grid item xs={12} md={12} xl={6}>
              <HighlightSelectBox
                options={HorizontalPosition}
                label={"Horizontal Position"}
                onChange={(position: SelectBoxOption) =>
                  props.onHorizontalPositionChange(position)
                }
                value={props.horizontalPosition}
                error={error.xPositionError}
                margin="none"
                helperText={
                  error.xPositionError
                    ? "Please select the logo horizontal position"
                    : ""
                }
              />
            </Grid>
            <Grid item xs={12} md={12} xl={6}>
              <HighlightSelectBox
                options={VerticalPosition}
                label={"Vertical Position"}
                onChange={(position: SelectBoxOption) =>
                  props.onVerticalPositionChange(position)
                }
                margin="none"
                value={props.verticalPosition}
                error={error.yPositionError}
                helperText={
                  error.yPositionError
                    ? "Please select the logo vertical position"
                    : ""
                }
              />
            </Grid>
          </Grid>
        </Box>
        {props.children}
      </>
    </CollapsableHeader>
  );
};
