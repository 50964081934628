import { Box, Button, Typography, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { components, MenuListProps } from "react-select";

import CreatableSelect from "react-select/creatable";
import { LocalStorageKeys } from "../../utils";
import { customStyles } from "../nounSelectBox/util";

export interface KeywordOption {
  readonly label: string;
  readonly value: string;
}

interface IKeywordSearch {
  placeholder?: string;
  onChange: (search: KeywordOption | null) => void;
  value: KeywordOption | null;
  onFind?: (search: string) => void;
}

export const createOption = (label: string) => ({
  label,
  value: label,
});

const defaultOptions: KeywordOption[] = [];

export const KeywordSearch: React.FC<IKeywordSearch> = ({
  placeholder = "Search by keyword",
  onChange,
  value,
  onFind = () => { },
}) => {
  const theme = useTheme();

  const [options, setOptions] = useState(defaultOptions);

  const handleCreate = (inputValue: string) => {
    const newOption = createOption(inputValue);
    setOptions((prev) => [newOption, ...prev]);
    onChange(newOption);
    if (newOption?.value) {
      onFind && onFind(newOption?.value ?? "");
    }
  };

  useEffect(() => {
    let searches: KeywordOption[] = JSON.parse(
      localStorage.getItem(LocalStorageKeys.keywordRecentSearch) || "[]"
    );
    let updatedOptions = searches.map((s) => {
      if (typeof s === "string") {
        return createOption(s);
      } else {
        return s;
      }
    });
    setOptions(updatedOptions);
  }, []);

  useEffect(() => {
    localStorage.setItem(
      LocalStorageKeys.keywordRecentSearch,
      JSON.stringify(options)
    );
  }, [options]);

  const MenuList = (props: MenuListProps<KeywordOption, false>) => {
    return (
      <components.MenuList {...props}>
        {options.length > 0 && (
          <Box
            sx={{ width: "100%" }}
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
            px={2}
            py={1}
          >
            <Typography
              color="grey"
              sx={{ textTransform: "uppercase", fontSize: 14 }}
            >
              Recent Searches
            </Typography>
            <Button
              size="small"
              variant="text"
              color="error"
              onClick={() => {
                localStorage.removeItem(LocalStorageKeys.keywordRecentSearch);
                setOptions([]);
              }}
              sx={{ width: "auto", textDecoration: "underline !important" }}
            >
              Clear All
            </Button>
          </Box>
        )}
        {props.children}
      </components.MenuList>
    );
  };

  return (
    <CreatableSelect
      isClearable
      components={{ IndicatorSeparator: () => null, MenuList }}
      placeholder={placeholder}
      onChange={(newValue) => {
        if (newValue?.value?.trim()) {
          setOptions((prev) => [
            newValue,
            ...prev.filter((p) => p.value !== newValue.value),
          ]);
        }
        onChange(newValue);
        if (newValue?.value) {
          onFind && onFind(newValue?.value ?? "");
        }
      }}
      onKeyDown={(e: any) => {
        if (e.key === " " ||
          e.code === "Space" ||
          e.keyCode === 32) {
          if (!e.target?.value) {
            e.preventDefault();
          }
        }
      }}
      onCreateOption={handleCreate}
      options={options}
      value={value}
      formatCreateLabel={(inputValue) => {
        const trimmedInput = inputValue.trim();
        return `Search ${trimmedInput}`;
      }}
      styles={customStyles(theme, null)}
    />
  );
};
