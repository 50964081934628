import { Box, Grid, Button, TextField, Typography } from "@mui/material";
import React from "react";
import { useDispatch } from "react-redux";
import { LoadingButton } from "@mui/lab";
import { closeDialog } from "../../redux/slices/dialog";
import { HighlightSelectBox } from "../../components";
import { SelectBoxOption } from '../../interfaces';
import { useSnackbar } from "notistack";

const UserDetails: React.FC<{ children?: JSX.Element, isEdit: boolean, data: any }> = (props) => {
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();

    const userTypeDropDown: SelectBoxOption[] = [
        { label: "User", value: "User" }, { label: "Admin", value: "Admin" }
    ]
    const [state, setState] = React.useState<{ firstName: string, lastName: string, roleType: SelectBoxOption[], emailId: string, additionalDetails: string }>({
        firstName: "", lastName: "", emailId: "", roleType: [], additionalDetails: ""
    });

    const updateState = (key: string, value: any) => { 
        setState({...state, [key] : value})
    };

    const isValid = () => {
        let isValid = true;
        const entries = Object.entries(state);
            entries.forEach(([key, value]) => {
            if(!value) {
                isValid = false;
            }
});
        return isValid;
    }
    /* Need to integrate with create/update user api */
    const onCreateUpdateBtnClicked = () => { 
        if(isValid()) {dispatch(closeDialog()) } else {enqueueSnackbar('Please fill mandatory fields (*)', { variant: "warning" });}
    };

    const onCancelBtnClicked = () => { dispatch(closeDialog()) };


    React.useEffect(() => {
        if (props.isEdit && props.data.user_id) {
            setState({
                ...state, firstName: props.data.user_first_name, lastName: props.data.user_last_name,
                emailId: props.data.user_email_id, roleType: [{ label: props.data.role_type, value: props.data.role_type }]
            })
        }
        // eslint-disable-next-line
    }, [props.isEdit])

    return <Box>
        {/* Title */}
        <Typography
            variant="h6"
            color="textPrimary"
            sx={{
                borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
                width: "100%", fontFamily: 'htrts_medium'
            }}
        >
            {`${props.isEdit ? "Update User" : "Create User"}`}
        </Typography>
        {/* Fields */}
        <Grid container spacing={2} mt={0.5}>
            <Grid item xs={6} sm={12} md={6} lg={6} xl={6}>
                {/*User First Name*/}
                <TextField
                    variant="outlined"
                    size="small"
                    fullWidth
                    required={true}
                    //error={state.firstName ? false : true}
                    // helperText={state.first ? "":"Please fill first name"}
                    value={state.firstName}
                    label={"First Name"}
                    onChange={(e) => updateState("firstName", e.target.value)}
                />
            </Grid>
            {/* User Last Name*/}
            <Grid item xs={6} sm={12} md={6} lg={6} xl={6}>
                <TextField
                    variant="outlined"
                    size="small"
                    fullWidth
                    required={true}
                    //error={state.lastName ? false : true}
                    //helperText={state.lastName ? "":"Please fill last name"}
                    value={state.lastName}
                    label={"Last Name"}
                    onChange={(e) => updateState("lastName", e.target.value)}
                />
            </Grid>
            {/* Emial Id */}
            <Grid item xs={6} sm={12} md={6} lg={6} xl={6}>
                <TextField
                    variant="outlined"
                    size="small"
                    fullWidth
                    required={true}
                    //error={state.emailId ? false : true}
                    // helperText={state.emailId ? "":"Please fill email id"}
                    value={state.emailId}
                    label={"Email Id"}
                    onChange={(e) => updateState("emailId", e.target.value)}
                />
            </Grid>
            {/* Role*/}
            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                <HighlightSelectBox
                    margin={"none"}
                    label={"Role"}
                    value={state.roleType[0]}
                    options={userTypeDropDown}
                    onChange={(data: SelectBoxOption) => {
                        updateState("roleType", [data])
                    }}
                />
            </Grid>
            {/* Additional User Details */}
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <TextField
                    fullWidth
                    label={" Additional Details"}
                    value={state.additionalDetails}
                    required={false}
                    error={false}
                    onChange={(e) => updateState("additionalDetails", e.target.value)}
                />
            </Grid>

            {/*Create Btn */}

            <Grid item xs={6} sm={12} md={6} lg={6} xl={6}>
                <LoadingButton
                    fullWidth
                    variant="contained"
                    onClick={onCreateUpdateBtnClicked}
                >
                    {`${props.isEdit ? "Update" : "Create"}`}
                </LoadingButton>
            </Grid>
            <Grid item xs={6} sm={12} md={6} lg={6} xl={6}>

                {/* Cancel Btn */}

                <Button
                    fullWidth
                    variant="outlined"
                    onClick={onCancelBtnClicked}
                >
                    {"Cancel"}
                </Button>
            </Grid>
        </Grid>
    </Box>
}
export default UserDetails;