import { useAuth0 } from "@auth0/auth0-react";
import { Logout, SupportAgentRounded } from '@mui/icons-material';
import { Avatar, Badge, Box, Button, Divider, Drawer, ListItemIcon, Menu, MenuItem, Stack, SxProps, Theme, Tooltip, Typography, useMediaQuery, useTheme } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ThemeContext } from "../../contexts";
import { DarkThemeIcon, LeftVector, LightThemeIcon, LogoSVG, LogoSmall, NotificationIcon } from "../../images";
import { useAppDispatch, useAppSelector } from "../../redux";
import { openDrawer } from "../../redux/slices/drawer";
import { toggleBadge } from "../../redux/slices/notifications";
import { setOpenMobileSideNavBar, setOpenWebSideNavBar } from "../../redux/slices/utils";
import { AppRoutes } from "../../router/routes";
import { LocalStorageKeys, Themes, openZohoDeskFeedbackModal, parseJwt } from "../../utils";
import { NotificationsBlock } from "../notifications";
import { SideNavBar, navbarWidths } from "./SideNavBar";

export const tileStyles = (isLight: boolean): SxProps<Theme> => ({
    borderRadius: 2,
    cursor: "pointer",
    backgroundColor: (theme) => isLight ? theme.palette.primary.light : "#F2F3F814",
    border: (theme) => `0.5px solid ${theme.palette.primary.light}`,
    height: 36,
    minWidth: 36
})

export const TopNavBar: React.FC<{ children?: JSX.Element }> = (props) => {

    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const { user } = useAuth0();
    const { showBadge }: any = useAppSelector((state) => state.notifications);
    const { openMobileSideNavBar, openWebSideNavBar } = useAppSelector(store => store.utils.sideNavBar);

    const token = localStorage.getItem(LocalStorageKeys.authToken) || "";

    const data = token ? parseJwt(token) : {};

    const themeContext = React.useContext(ThemeContext);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const open = Boolean(anchorEl);

    const toogleWebSideNavBar = () => dispatch(setOpenWebSideNavBar(!openWebSideNavBar));

    const toogleMobileSideNavBar = () => dispatch(setOpenMobileSideNavBar(!openMobileSideNavBar));

    const handleProfileClick = (event: React.MouseEvent<HTMLDivElement>) => setAnchorEl(event.currentTarget);

    const handleProfileClose = () => setAnchorEl(null);

    const toggleTheme = () => themeContext.setTheme({ name: themeContext.name === Themes.default ? Themes.dark : Themes.default })

    const handleNotifications = () => {
        dispatch(openDrawer({
            title: "Notifications",
            component: <NotificationsBlock />,
            onClose: () => dispatch(toggleBadge(false))
        }))
    };

    const handleLogout = () => navigate(AppRoutes.logout);

    return (
        <Stack
            height={navbarWidths.topNavHeight}
            bgcolor={(theme) => themeContext.name === Themes.default ? theme.palette.background.paper : "#292E32"}
            direction={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
            pr={2}
            id="topNavBar"
        >

            <Box
                display={"flex"} position={"relative"} alignItems={"center"} justifyContent={"center"}
                width={!isMobile && openWebSideNavBar ? navbarWidths.web : navbarWidths.webCollapse}
                sx={{ transition: 'all 0.5s' }}
            >
                {!isMobile && openWebSideNavBar && <Box width={100} height={36}>
                    <LogoSVG />
                </Box>}

                {(!openWebSideNavBar || isMobile) && <Box width={36} height={36}>
                    <LogoSmall />
                </Box>}

                <Box
                    onClick={() => isMobile ? toogleMobileSideNavBar() : toogleWebSideNavBar()}
                    position={"absolute"} right={openWebSideNavBar ? -10 : -16}
                    display={"flex"} alignItems={"center"} justifyContent={"center"}
                    sx={{
                        height: 24, width: 24,
                        border: (t) => `1.5px dashed ${themeContext.name === Themes.default ? "#919EAB33" : "white"}`, borderRadius: "50%",
                        "&:hover": {
                            backgroundColor: (t) => themeContext.name === Themes.default ? t.palette.primary.light : '#F2F3F814'
                        },
                        cursor: "pointer"
                    }}
                >
                    <LeftVector color={themeContext.name === Themes.default ? '#637381' : 'white'} />
                </Box>
            </Box>

            <Stack
                direction={"row"}
                alignItems={"center"}
                justifyContent={"flex-end"}
                spacing={1}
            >
                {/* Report Feedback Icon */}
                <Tooltip title={"Feedback/Report an Issue"}>
                    <Button
                        onClick={openZohoDeskFeedbackModal}
                        sx={tileStyles(themeContext.name === Themes.default)}
                    >
                        <Badge color="error" variant="dot" invisible={false} overlap="circular" >
                            <SupportAgentRounded htmlColor="#637381" />
                        </Badge>
                    </Button>
                </Tooltip>

                {/* Toggle Theme Icon */}
                <Tooltip title={"Toggle Theme"}>
                    <Button
                        onClick={toggleTheme}
                        sx={tileStyles(themeContext.name === Themes.default)}
                    >
                        {themeContext.name === Themes.default && <LightThemeIcon />}
                        {themeContext.name === Themes.dark && <DarkThemeIcon />}
                    </Button>
                </Tooltip>

                {/* Notifications Icon */}
                <Tooltip title={"Notifications"}>
                    <Button
                        onClick={handleNotifications}
                        sx={tileStyles(themeContext.name === Themes.default)}
                    >
                        <Badge color="error" variant="dot" invisible={!showBadge} overlap="circular">
                            <NotificationIcon />
                        </Badge>
                    </Button>
                </Tooltip>

                {/* Profile Pic */}
                <Tooltip title={"View Profile"}>
                    <Avatar onClick={handleProfileClick} sx={tileStyles(themeContext.name === Themes.default)} src={user?.picture} variant="square" />
                </Tooltip>

                {/* Profile Drawer */}
                <Menu
                    id="profile-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleProfileClose}
                >
                    <Box sx={{
                        background: (theme) => theme.palette.grey[100],
                        width: "280px",
                        marginTop: "-8px"
                    }}>
                        <Stack px={2} py={1} direction={"row"} spacing={1}>
                            <Typography variant="body2">Organization: </Typography>
                            <Typography sx={{ fontWeight: "bold" }} variant="caption">{data?.org_display_name ?? ""}</Typography>
                        </Stack>
                        <Stack px={2} pb={1} direction={"row"} spacing={1}>
                            <Typography variant="body2">Plan Type: </Typography>
                            <Typography sx={{ fontWeight: "bold" }} variant="caption">{data?.plan ?? "Unknown"}</Typography>
                        </Stack>
                        <Stack px={2} pb={1} direction={"row"} spacing={1}>
                            <Typography variant="body2">Role Type: </Typography>
                            <Typography sx={{ fontWeight: "bold" }} variant="caption">{data?.role ?? "Unknown"}</Typography>
                        </Stack>
                        <Stack px={2} pb={1} direction={"row"} spacing={1}>
                            <Typography variant="body2">IEHUB Version: </Typography>
                            <Typography sx={{ fontWeight: "bold" }} variant="caption">{"v1.0"}</Typography>
                        </Stack>
                    </Box>

                    <Divider />
                    <MenuItem onClick={handleLogout}>
                        <ListItemIcon>
                            <Logout fontSize="small" />
                        </ListItemIcon>
                        Logout
                    </MenuItem>
                </Menu>

                {/* Mobile SideNavBar */}
                <Drawer
                    open={openMobileSideNavBar}
                    variant={"temporary"}
                    anchor="left"
                    onClose={toogleMobileSideNavBar}>
                    <Box style={{ width: navbarWidths.mobile }}>
                        <SideNavBar isMobile={true} />
                    </Box>
                </Drawer>
            </Stack>
        </Stack>
    );
}
