import { Stack, Typography } from '@mui/material';

const ChatBotMessage = ({ message }: { message: string }) => {

    // Split the message into parts based on newline
    const messageParts = message.split("\n");

    // Render each item separately
    const renderedItems = messageParts.map((item, index) => <Typography key={index} variant="body1">{item}</Typography>);

    return <Stack direction={"column"} alignItems={"flex-start"} spacing={0.5}>
        {renderedItems}
    </Stack>;
}

export default ChatBotMessage;
