import { Alert, Box, Grid, Stack, Typography, useMediaQuery } from "@mui/material";
import React from "react";
import { SelectBoxOption } from "../../../interfaces";
import {
  Alignment,
  CutSheetError,
  cutSheetHeaderInterface,
} from "../../../redux/slices/documentTemplate";
import { HorizontalPosition, VerticalPosition } from "../../../utils";
import { AntSwitch } from "../../antSwitch";
import { HighlightSelectBox } from "../../highLightSelectBox";
import { CollapsableHeader } from "../header";

export const CutSheet: React.FC<{
  title: string;
  showSwitch?: boolean;
  switchStatus?: boolean;
  onSwitchChanged?: (isChecked: boolean) => void;
  cutSheet: cutSheetHeaderInterface;
  updateCut: (key: keyof cutSheetHeaderInterface, value: any) => void;
  onPositionChange: (position: SelectBoxOption, key: keyof Alignment) => void;
  error: CutSheetError;
}> = (props) => {

  const isMatch = useMediaQuery("(max-width:1530px)");

  return (
    <CollapsableHeader
      title={props.title}
      showIcon={true}
      isExpanded={true}
      showSwitch={props.showSwitch}
      switchStatus={props.switchStatus}
      onSwitchChanged={props.onSwitchChanged}
    >
      <Box p={1}>
        <Grid container rowSpacing={1}>
          <Grid item xs={12} md={12} xl={6}>
            <Stack direction="row" spacing={1} alignItems="center">
              <AntSwitch
                checked={props.cutSheet.materialId}
                onChange={(e, isChecked) =>
                  props.updateCut("materialId", isChecked)
                }
              />
              <Typography variant="body2">Material ID</Typography>
            </Stack>
          </Grid>
          <Grid item xs={12} md={12} xl={6}>
            <Stack direction="row" spacing={1} alignItems="center">
              <AntSwitch
                checked={props.cutSheet.serialNum}
                onChange={(e, isChecked) =>
                  props.updateCut("serialNum", isChecked)
                }
              />
              <Typography variant="body2">Tag Number</Typography>
            </Stack>
          </Grid>
          <Grid item xs={12} md={12} xl={6}>
            <Stack direction="row" spacing={1} alignItems="center">
              <AntSwitch
                checked={props.cutSheet.materialDescription}
                onChange={(e, isChecked) =>
                  props.updateCut("materialDescription", isChecked)
                }
              />
              <Typography variant="body2">Material Description</Typography>
            </Stack>
          </Grid>
          <Grid item xs={12} md={12} xl={6}>
            <Stack direction="row" spacing={1} alignItems="center">
              <AntSwitch
                checked={props.cutSheet.materialManufactureNo}
                onChange={(e, isChecked) =>
                  props.updateCut("materialManufactureNo", isChecked)
                }
              />
              <Typography variant="body2">
                Material Manufacturer Number
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs={12} md={12} xl={6}>
            <Stack direction="row" spacing={1} alignItems="center">
              <AntSwitch
                checked={props.cutSheet.manufacture}
                onChange={(e, isChecked) =>
                  props.updateCut("manufacture", isChecked)
                }
              />
              <Typography variant="body2">Material Manufacturer Name</Typography>
            </Stack>
          </Grid>
          {props.error.selectionError && (
            <Alert sx={{ m: 1 }} severity="error">
              Please select at least one cut sheet or mark it as inactive.
            </Alert>
          )}
          <Grid
            container
            columnGap={isMatch ? 0 : 1}
            justifyContent={"space-between"}
            columns={isMatch ? 12 : 13}
          >
            <Grid item xs={12} sm={12} md={12} xl={6}>
              <HighlightSelectBox
                options={HorizontalPosition}
                label={"Horizontal Position"}
                onChange={(position: SelectBoxOption) =>
                  props.onPositionChange(position, "horizontal")
                }
                value={props.cutSheet.alignment.horizontal}
                error={props.error.xPositionError}
                helperText={
                  props.error.xPositionError
                    ? "Please select the horizontal position"
                    : ""
                }
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} xl={6}>
              <HighlightSelectBox
                options={VerticalPosition}
                label={"Vertical Position"}
                onChange={(position: SelectBoxOption) =>
                  props.onPositionChange(position, "vertical")
                }
                value={props.cutSheet.alignment.vertical}
                error={props.error.yPositionError}
                helperText={
                  props.error.yPositionError
                    ? "Please select the vertical position"
                    : ""
                }
              />
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </CollapsableHeader>
  );
};
