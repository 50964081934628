import React from "react";
import {
  Box,
  Grid,
  TextField,
  Typography,
  Button,
  IconButton,
  Stack,
  Tooltip,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useDispatch } from "react-redux";
import { closeDialog } from "../../../../../redux/slices/dialog";
import {
  openBackdrop,
  closeBackdrop,
} from "../../../../../redux/slices/backdrop";
import {
  useCreateSNAttributeMutation,
  useLazyGetSNNounsQuery,
  useUpdateSNAttributeMutation,
} from "../../../../../redux/services";
import { useSnackbar } from "notistack";
import { SelectBoxOption } from "../../../../../interfaces";
import { Delete, FlipToFront } from "@mui/icons-material";
import {
  closeConfirmationDialog,
  openConfirmationDialog,
} from "../../../../../redux/slices/confirmationDialog";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { NounSNSelectBox } from "../../nounSNSelectBox";
import copy from "fast-copy";

interface SelectionInterface {
  option_name: string;
  option_abbr: string;
}

export const AddUpdateSNAttribute: React.FC<{
  children?: JSX.Element;
  isEdit: boolean;
  data: any;
  refetch: any;
  noun: any;
  updateNoun: (noun: SelectBoxOption | null) => void;
}> = (props) => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [createSNAttribute, createSNAttributeData] =
    useCreateSNAttributeMutation();
  const [updateSNAttribute, updateSNAttributeData] =
    useUpdateSNAttributeMutation();
  const [getSNNouns, snNounsD] = useLazyGetSNNounsQuery({
    refetchOnFocus: true,
  });
  const snNounsData = {
    ...snNounsD,
    data: snNounsD?.data?.data ?? [],
    currentData: snNounsD?.data?.data ?? [],
    totalCount: snNounsD?.data?.totalCount ?? 0,
  };

  const [state, setState] = React.useState<{
    name: string;
    selectedNouns: SelectBoxOption[];
    nouns: SelectBoxOption[];
    selections: SelectionInterface[];
  }>({
    name: "",
    selectedNouns: [],
    nouns: [],
    selections: [{ option_name: "", option_abbr: "" }],
  });

  const [error, setError] = React.useState({
    name: false,
    nouns: false,
    selections: false,
  });

  const updateState = (key: string, value: string) => {
    setState({ ...state, [key]: value });
    setError({ ...error, [key]: !value.length });
  };

  const reorder = (
    list: SelectionInterface[],
    startIndex: number,
    endIndex: number
  ) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragEnd = (result: any) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const selections = reorder(
      state.selections,
      result.source.index,
      result.destination.index
    );

    setState({ ...state, selections });
  };

  const updateDropDown = (selectedNouns: SelectBoxOption[]) => {
    setState({
      ...state,
      selectedNouns: selectedNouns.filter((_: any) => _.value),
      nouns: selectedNouns.map((_: any) => _.value).filter(Boolean),
    });
  };

  const onCancelBtnClicked = () => {
    dispatch(closeDialog());
  };

  const onCreateUpdateBtnClicked = () => {
    if (state.name.length && state.nouns.length && state.selections.length) {
      if (props.isEdit) {
        updateSNAttribute({
          id: props.data._id,
          payload: {
            attr_name: state.name,
            nouns: state.nouns,
            options: state.selections,
          },
        });
      } else {
        createSNAttribute({
          attr_name: state.name,
          nouns: state.nouns,
          options: state.selections,
        });
      }
    } else {
      if (state.selections.length === 0) {
        enqueueSnackbar(
          `Minimum one option is needed for ${props.isEdit ? "updating" : "creating"
          }.`,
          { variant: "warning" }
        );
      }
      setError({
        ...error,
        name: !state.name.length,
        nouns: !state.nouns.length,
        selections: !state.selections.length,
      });
    }
  };

  const updateOption = (index: number, value: string) => {
    let selections: SelectionInterface[] = copy(state.selections);
    selections[index].option_name = value;
    setState((prevState) => ({ ...prevState, selections }));
  };

  const updateAbbreviation = (index: number, value: string) => {
    let selections: SelectionInterface[] = copy(state.selections);
    selections[index].option_abbr = value;
    setState((prevState) => ({ ...prevState, selections }));
  };

  const addOption = () => {
    let selections: SelectionInterface[] = copy(state.selections);
    selections.push({ option_name: "", option_abbr: "" });
    setState((prevState) => ({ ...prevState, selections }));
  };

  const deleteConfirmation = (index: number, attribute: string) => {
    if (attribute.length === 0) {
      deleteRow(index);
      return false;
    }
    dispatch(
      openConfirmationDialog({
        title: "Are you sure?",
        body: "Do you want to delete this attribute - " + attribute + "?",
        positiveBtn: "Delete",
        negativeBtn: "Cancel",
        onOk: () => deleteRow(index),
        onNegativeBtn: () => dispatch(closeConfirmationDialog()),
      })
    );
  };

  const deleteRow = (index: number) => {
    let selections: SelectionInterface[] = state.selections;
    selections.splice(index, 1);
    setState({ ...state, selections });
    dispatch(closeConfirmationDialog());
  };

  React.useEffect(() => {
    if (createSNAttributeData.isSuccess) {
      enqueueSnackbar("Attribute Created Successfully", { variant: "success" });
      props.updateNoun(props.noun)
      props.refetch({id: props.noun?._id});
      dispatch(closeDialog());
    }
    if (createSNAttributeData.isError) {
      enqueueSnackbar(
        "Oops! Something went wrong, Unable to create Attribute",
        { variant: "error" }
      );
    }
    // eslint-disable-next-line
  }, [createSNAttributeData.status]);

  React.useEffect(() => {
    if (updateSNAttributeData.isSuccess) {
      enqueueSnackbar("Attribute Updated Successfully", { variant: "success" });
      props.updateNoun(props.noun)
      props.refetch({id: props.noun?._id});
      dispatch(closeDialog());
    }
    if (updateSNAttributeData.isError) {
      enqueueSnackbar(
        "Oops! Something went wrong, Unable to update Attribute",
        { variant: "error" }
      );
    }
    // eslint-disable-next-line
  }, [updateSNAttributeData.status]);

  React.useEffect(() => {
    if (props.isEdit && props.data?._id && snNounsData.isSuccess) {
      let selectedNouns = snNounsData.data
        ?.filter((_: any) => props?.data?.nouns?.includes(_._id))
        ?.map((_: any) => ({ value: _._id, label: _.noun_name, ..._ }));
      setState({
        ...state,
        name: props.data.attr_name,
        nouns: props.data.nouns,
        selections: props.data.options?.map((_: any) => ({ ..._ })),
        selectedNouns,
      });
      dispatch(closeBackdrop());
    } else if (snNounsData.isFetching) {
      dispatch(openBackdrop("Fetching Nouns..."));
    }
    // eslint-disable-next-line
  }, [props.isEdit, snNounsData.status]);

  React.useEffect(() => {
    if (props.isEdit) {
      getSNNouns({ page: null, pageSize: null });
    } else {
      updateDropDown([
        {
          value: props.noun?._id ?? "",
          label: props.noun?.noun_name ?? "",
          ...props.noun,
        },
      ]);
    }
    // eslint-disable-next-line
  }, []);

  return (
    <>
      {/* Title */}
      <DialogTitle>
        <Typography
          variant="h6"
          color="textPrimary"
          sx={{
            borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
            width: "100%",
            fontFamily: "htrts_medium",
          }}
        >
          {`${props.isEdit ? "Update" : "Create"} SN Attribute`}
        </Typography>
      </DialogTitle>

      {/* Fields */}
      <DialogContent>
        <Stack spacing={2}>
          {/* Noun */}
          <NounSNSelectBox
            label="Select Noun"
            isMulti={true}
            value={state.selectedNouns.filter((f) => f.value !== "")}
            onChange={(data: SelectBoxOption[]) => updateDropDown(data)}
            isRequired={true}
          />

          {/* Name */}
          <TextField
            sx={{ marginTop: 2 }}
            fullWidth
            size="small"
            label={"Name"}
            value={state.name}
            required={true}
            error={error.name}
            helperText={error.name ? "Please enter Attribute name" : ""}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              updateState("name", e.target.value)
            }
          />

          {/* Options */}
          {/* Title */}
          <Box display={"flex"} justifyContent={"space-between"}>
            <Typography gutterBottom>Options</Typography>
            <Button
              variant={"outlined"}
              size={"small"}
              sx={{ marginLeft: 1 }}
              onClick={addOption}
            >
              Add Option
            </Button>
          </Box>

          {/* Selections */}
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable-option">
              {(provided: any, _: any) => (
                <div ref={provided.innerRef} {...provided.droppableProps}>
                  {state.selections?.map((opt, index) => {
                    return (
                      <Draggable
                        key={index}
                        draggableId={`draggable-${index + 1}-option`}
                        index={index}
                      >
                        {(provided: any, _: any) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            key={`key${index + 1}`}
                            id={`div-option-${index + 1}`}
                          >
                            <Grid container alignItems={"center"} key={index}>
                              <Grid
                                {...provided.dragHandleProps}
                                sx={{ textAlign: "center" }}
                                item
                                xs={12}
                                sm={1}
                                md={1}
                                lg={1}
                                xl={1}
                              >
                                <Tooltip title="Drag Up/Down">
                                  <FlipToFront
                                    fontSize="small"
                                    sx={{ marginTop: 1 }}
                                  />
                                </Tooltip>
                              </Grid>
                              <Grid item xs={12} sm={7} md={7} lg={7} xl={7}>
                                <TextField
                                  size="small"
                                  variant={"filled"}
                                  fullWidth
                                  label={`Option ${index + 1}`}
                                  value={opt.option_name}
                                  onChange={(e) =>
                                    updateOption(index, e.target.value)
                                  }
                                />
                              </Grid>
                              <Grid
                                item
                                xs={12}
                                sm={3}
                                md={3}
                                lg={3}
                                xl={3}
                                sx={{
                                  paddingLeft: {
                                    xs: 0,
                                    sm: 2,
                                    md: 2,
                                    lg: 2,
                                    xl: 2,
                                  },
                                  marginTop: {
                                    xs: 1,
                                    sm: 0,
                                    md: 0,
                                    lg: 0,
                                    xl: 0,
                                  },
                                }}
                              >
                                <TextField
                                  size="small"
                                  variant={"filled"}
                                  fullWidth
                                  label={`Abbreviation ${index + 1}`}
                                  value={opt.option_abbr}
                                  onChange={(e) =>
                                    updateAbbreviation(index, e.target.value)
                                  }
                                />
                              </Grid>
                              <Grid
                                item
                                xs={12}
                                sm={1}
                                md={1}
                                lg={1}
                                xl={1}
                                sx={{
                                  paddingLeft: {
                                    xs: 0,
                                    sm: 1,
                                    md: 1,
                                    lg: 1,
                                    xl: 1,
                                  },
                                  marginTop: {
                                    xs: 1,
                                    sm: 0,
                                    md: 0,
                                    lg: 0,
                                    xl: 0,
                                  },
                                }}
                              >
                                <IconButton
                                  color="error"
                                  size="small"
                                  onClick={(e) =>
                                    deleteConfirmation(index, opt.option_name)
                                  }
                                >
                                  <Delete />
                                </IconButton>
                              </Grid>
                            </Grid>
                          </div>
                        )}
                      </Draggable>
                    );
                  })}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </Stack>
      </DialogContent>

      <DialogActions>
        <Grid container px={2} pb={1}>
          {/* Add Attribute Btn */}
          <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
            <LoadingButton
              fullWidth
              variant="contained"
              onClick={onCreateUpdateBtnClicked}
              loading={
                createSNAttributeData.isLoading ||
                updateSNAttributeData.isLoading
              }
            >
              {`${props.isEdit ? "Update" : "Create"}`}
            </LoadingButton>
          </Grid>

          {/* Cancel Btn */}
          <Grid
            item
            xs={12}
            sm={6}
            md={6}
            lg={6}
            xl={6}
            sx={{
              paddingLeft: { xs: 0, sm: 2, md: 2, lg: 2, xl: 2 },
              marginTop: { xs: 1, sm: 0, md: 0, lg: 0, xl: 0 },
            }}
          >
            <Button
              fullWidth
              variant="outlined"
              onClick={onCancelBtnClicked}
              disabled={
                createSNAttributeData.isLoading ||
                updateSNAttributeData.isLoading
              }
            >
              Cancel
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </>
  );
};
