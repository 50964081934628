import { LoadingButton } from "@mui/lab";
import { Box, Button, DialogActions, DialogContent, Divider, Grid, Stack, Typography } from "@mui/material";
import React from "react";
import { HighlightSelectBox, MyPaper, TextField } from "../../../components";
import { SelectBoxOption } from "../../../interfaces";
import { useAppDispatch } from "../../../redux";
import { useGetNounsMappingDDQuery } from "../../../redux/services";
import { closeDialog } from "../../../redux/slices/dialog";
import { SourcingOption } from "../../viewMaterial/sourcing";
import { CostingTypes, LengthUnits, UOMOptions, WeightUnits } from "../../viewMaterial/utils";
import { IRow, IRowError, QueryType, sparePartOptions } from "./actionTable";
import { typeOptions } from "./utils";

export type RowProps = {
    _id: string;
    rows: Partial<IRow>[];
    isCompleted: boolean;
    workbookData: any;
    rowsError: IRowError[];
    uomData: QueryType;
    uomOptions: SelectBoxOption[];
    groupData: QueryType;
    groupOptions: SelectBoxOption[];
    supplierData: QueryType;
    supplierOptions: SelectBoxOption[];
    currencyData: QueryType;
    currencyOptions: SelectBoxOption[];
    sparePartCategoryData: QueryType;
    sparePartCategoryOptions: SelectBoxOption[];
    materialStatusData: QueryType;
    childMaterialStatusOptions: SelectBoxOption[];
    bomStatusData: QueryType;
    childBOMStatusOptions: SelectBoxOption[];
    onUpdate: (row: Partial<IRow>) => void;
};

const SelectBoxRenderer = ({ id, value, onChange, options, isDisabled = false, label, isLoading = false, defaultValue, error = false, helperText }: { value?: SelectBoxOption | null; onChange: (value: SelectBoxOption | null) => void; id: string; label: string; isDisabled?: boolean; options: SelectBoxOption[]; isLoading?: boolean; defaultValue?: SelectBoxOption | null; error?: boolean; helperText?: string; }) => {
    return (
        <Stack width={"100%"}>
            <HighlightSelectBox
                id={id}
                label={label}
                margin={"none"}
                disabled={isDisabled}
                loading={isLoading}
                value={options.find(op => (op.value === value?.value || op.label === value?.label) || (op.value === defaultValue?.value || op.label === defaultValue?.label)) ?? null}
                options={options}
                error={error}
                helperText={error ? helperText : ""}
                onChange={(option: SelectBoxOption) => {
                    onChange(option)
                }}
                viewOnly={isDisabled}
            />
        </Stack>
    );
}

const InputRenderer = ({ id, label, value, onChange, isDisabled = false, placeholder = "Enter value", type = 'text', error = false, helperText }: { id: string; label: string; value?: string | number; onChange: (value?: string | number) => void; isDisabled?: boolean; placeholder?: string; type?: React.InputHTMLAttributes<unknown>['type']; error?: boolean; helperText?: string; }) => {
    return (
        <Stack width={"100%"}>
            <TextField
                id={id}
                sx={{
                    '.MuiOutlinedInput-input': {
                        p: 1
                    },
                    '.MuiInputBase-input.Mui-disabled': { border: error ? "1px solid red !important" : 'inherit' },
                }}
                size="small"
                label={label}
                disabled={isDisabled}
                placeholder={placeholder}
                type={type}
                inputProps={type === "number" ? { inputMode: 'numeric', pattern: '[0-9]*' } : {}}
                value={value}
                error={error}
                helperText={error ? helperText : ""}
                onChange={(e) => {
                    onChange(e.target.value)
                }}
                onKeyDown={(event) => {
                    event.stopPropagation();
                }}
                viewOnly={isDisabled}
            />
        </Stack>
    );
}

const NounOptionsRenderer = ({ currentRow, onChange, isDisabled = false, error = false, helperText }: { currentRow: Partial<IRow>; onChange: (value: SelectBoxOption | null) => void; isDisabled?: boolean; error?: boolean; helperText?: string; }) => {
    const uom_id = currentRow?.uom_id?.value;
    const group_id = currentRow?.group_id?.value;
    const noun_id = currentRow?.noun_id;

    const nounResponse = useGetNounsMappingDDQuery({ uom_id, group_id }, { skip: !uom_id || !group_id });

    let nounOptions = uom_id && group_id ? nounResponse?.data ?? [] : [];

    return (
        <Stack width={"100%"}>
            <HighlightSelectBox
                id="select-noun"
                label={"Noun"}
                margin={"none"}
                value={nounOptions.find(op => (op.value === noun_id?.value || op.label === noun_id?.label)) ?? null}
                options={nounOptions}
                loading={nounResponse.isFetching || nounResponse.isLoading}
                disabled={isDisabled}
                error={error}
                helperText={error ? helperText : ""}
                onChange={(option: SelectBoxOption) => {
                    onChange(option)
                }}
                viewOnly={isDisabled}
            />
        </Stack>
    );
}

export const AddEditRow: React.FC<RowProps> = ({
    _id, rows, rowsError, isCompleted, workbookData, uomData, uomOptions, groupData, groupOptions, supplierData, supplierOptions, currencyData, currencyOptions, sparePartCategoryData, sparePartCategoryOptions, materialStatusData, childMaterialStatusOptions, bomStatusData, childBOMStatusOptions, onUpdate
}) => {

    const row = React.useMemo(() => (rows?.find(r => r?._id === _id) ?? {}) as Partial<IRow>, [rows, _id]);

    const errors = (rowsError?.find(r => r?._id === _id) ?? {}) as Partial<IRowError>;

    const isEdit = isNaN(Number(_id));

    const dispatch = useAppDispatch();

    const [currentRow, setRow] = React.useState({ ...row });

    const onChange = (key: string, value: any, data?: Partial<IRow>) => {
        setRow((prevRow) => ({ ...prevRow, [key]: value, ...(data || {}) }));
    }

    const onAddUpdateBtnClicked = async () => {
        onUpdate({ ...currentRow });
    };

    const spare_part = [true, "yes"].includes((currentRow?.spare_part?.value as string) ?? "");

    const selectedType = currentRow?.type?.value;

    const isMaterial = selectedType === typeOptions[0].value;

    const isChildStatusLoading = materialStatusData?.isFetching || materialStatusData?.isLoading || bomStatusData?.isFetching || bomStatusData?.isLoading;

    React.useEffect(() => {
        setRow({ ...row })
    }, [row])

    return <>
        <DialogContent>
            <Stack p={1} spacing={2}>
                {/* Basic Details Section */}
                <MyPaper padding={0}>
                    <Stack justifyContent={"space-between"} height="inherit">
                        <Box>
                            {/* Heading */}
                            <Typography sx={{ px: 2, py: 1 }}
                                variant="body1"
                                fontFamily={"htrts_medium"}>
                                Basic Details
                            </Typography>

                            <Divider />

                            <Grid container p={2} spacing={2}>
                                {/* Type */}
                                <Grid item xs={12} sm={4}>
                                    <SelectBoxRenderer
                                        id="select-type"
                                        label="Type"
                                        value={currentRow?.type}
                                        onChange={(data) => {
                                            onChange(
                                                'type',
                                                data,
                                                { long_description: "", uom_type: null, length: 0, units_length: null, weight: 0, units_weight: null, cost_category: null, cost: 0, lead_time_days: 0, child_status: null }
                                            )
                                        }}
                                        options={workbookData?.type === typeOptions[0].value ? [typeOptions[0]] : typeOptions}
                                        isDisabled={isCompleted}
                                        error={errors?.type}
                                        helperText={"Please select the Type"}
                                    />
                                </Grid>


                                {/* Region */}
                                <Grid item xs={12} sm={4}>
                                    <SelectBoxRenderer id="workbook-region" value={currentRow?.uom_id} label="Region" onChange={(data) => onChange('uom_id', data)} options={uomOptions} isLoading={uomData.isFetching || uomData.isLoading} isDisabled={isCompleted} error={errors?.uom_id} helperText={"Please select the Region"} />
                                </Grid>

                                {/* Group */}
                                <Grid item xs={12} sm={4}>
                                    <SelectBoxRenderer id="workbook-group"  value={currentRow?.group_id} label="Group" onChange={(data) => onChange('group_id', data)} options={groupOptions} isLoading={groupData.isFetching || groupData.isLoading} isDisabled={isCompleted} error={errors?.group_id} helperText={"Please select the Group"} />
                                </Grid>

                                {/* Tag */}
                                <Grid item xs={12} sm={4}>
                                    <NounOptionsRenderer currentRow={currentRow} onChange={(data) => onChange('noun_id', data)} isDisabled={isCompleted} error={errors?.noun_id} helperText={"Please select the Noun"} />
                                </Grid>

                                {/* Short Description*/}
                                <Grid item xs={12} sm={4}>
                                    <InputRenderer id="workbook-short_description" label="Description" value={currentRow?.short_description} onChange={(data) => onChange('short_description', data)} isDisabled={isCompleted} error={errors?.short_description} helperText={"Please fill the Description"} />
                                </Grid>


                                {/* Long Description*/}
                                {isMaterial &&
                                    <Grid item xs={12} sm={4}>
                                        <InputRenderer id="workbook-long_description" value={currentRow?.long_description} label="Long description" isDisabled={isCompleted} onChange={(data) => onChange('long_description', data)} />
                                    </Grid>
                                }

                                {/* Divider */}
                                <Grid item xs={12}>
                                    <Divider variant="fullWidth" />
                                </Grid>

                                {/* Client Reference Number */}
                                <Grid item xs={12} sm={6}>
                                    <InputRenderer id="workbook-reference_id" value={currentRow?.reference_id} label="Reference Number" onChange={(data) => onChange('reference_id', data)} isDisabled={isCompleted} error={errors?.reference_id} helperText={"Please fill the Reference Number"} />
                                </Grid>

                                {/* Tag Number */}
                                {workbookData?.type === typeOptions[1].value &&
                                    <Grid item xs={12} sm={6}>
                                        <InputRenderer id="workbook-serial_num" value={currentRow?.serial_num} label="Tag Number" onChange={(data) => onChange('serial_num', data)} isDisabled={isCompleted} />
                                    </Grid>
                                }

                                {isMaterial &&
                                    <>
                                        {/* UOM */}
                                        <Grid item xs={12} sm={6}>
                                            <SelectBoxRenderer
                                                id="workbook-uom_type"
                                                label="UOM"
                                                value={currentRow?.uom_type}
                                                isDisabled={isCompleted}
                                                onChange={(data) => onChange('uom_type', data, { length: 0, units_length: null, weight: 0, units_weight: null })}
                                                options={UOMOptions}
                                                error={errors?.uom_type}
                                                helperText={"Please Select the Description"}
                                            />
                                        </Grid>

                                        {/* Length */}
                                        {currentRow.uom_type?.value === UOMOptions[1].value && <Grid item xs={12} sm={3}>
                                            <InputRenderer id="workbook-length" value={currentRow?.length} type={'number'} label="Length" isDisabled={isCompleted} onChange={(data) => onChange('length', data)} error={errors?.length} helperText={"Please fill the Length"} />
                                        </Grid>}

                                        {currentRow.uom_type?.value === UOMOptions[1].value && <Grid item xs={12} sm={3}>
                                            <SelectBoxRenderer id="workbook-units_length" value={currentRow?.units_length} label="Unit" isDisabled={isCompleted} onChange={(data) => onChange('units_length', data)} options={LengthUnits} error={errors?.units_length} helperText={"Please Select the Unit"} />
                                        </Grid>}

                                        {/* Weight */}
                                        {currentRow.uom_type?.value === UOMOptions[2].value && <Grid item xs={12} sm={3}>
                                            <InputRenderer id="workbook-weight" value={currentRow?.weight} type={'number'} label="Weight" isDisabled={isCompleted} onChange={(data) => onChange('weight', data)} error={errors?.weight} helperText={"Please fill the Weight"} />
                                        </Grid>}

                                        {/* Weight Unit */}
                                        {currentRow.uom_type?.value === UOMOptions[2].value && <Grid item xs={12} sm={3}>
                                            <SelectBoxRenderer id="workbook-units_weight" value={currentRow?.units_weight} label="Unit" isDisabled={isCompleted} onChange={(data) => onChange('units_weight', data)} options={WeightUnits} error={errors?.units_weight} helperText={"Please Select the Unit"} />
                                        </Grid>}
                                    </>
                                }

                                {/* Currency */}
                                {selectedType === typeOptions[1].value &&
                                    <Grid item xs={12} sm={6}>
                                        <SelectBoxRenderer id="workbook-currency-bom" value={currentRow?.currency} label="Currency" isDisabled={isCompleted} onChange={(data) => onChange('currency', data)} options={currencyOptions} isLoading={currencyData.isLoading || currencyData.isFetching} error={errors?.currency} helperText={"Please Select the Currency"} />
                                    </Grid>
                                }
                            </Grid>

                        </Box>
                    </Stack>
                </MyPaper>

                {/* Costing Type */}
                {isMaterial &&
                    <MyPaper padding={0}>
                        <Box>
                            {/* Heading */}
                            <Typography sx={{ px: 2, py: 1, borderBottom: (theme) => `1px solid ${theme.palette.divider}` }}
                                variant="body1"
                                fontFamily={"htrts_medium"}>
                                Costing & Lead time Details
                            </Typography>

                            <Grid container p={2} spacing={2}>
                                {/* Costing Type */}
                                <Grid item xs={12} sm={3}>
                                    <SelectBoxRenderer
                                        id="workbook-cost_category"
                                        label="Costing Type"
                                        value={currentRow?.cost_category}
                                        isDisabled={isCompleted}
                                        onChange={(data) => onChange('cost_category', data, { cost: 0, currency: null, lead_time_days: 0 })}
                                        options={CostingTypes}
                                    />
                                </Grid>

                                {/* Cost */}
                                <Grid item xs={12} sm={3}>
                                    <InputRenderer id="workbook-cost" type="number" value={currentRow?.cost} label="Cost" isDisabled={isCompleted} onChange={(value) => onChange("cost", value)} error={errors?.cost} helperText={"Please fill the Cost"} />
                                </Grid>

                                {/* Currency */}
                                <Grid item xs={12} sm={3}>
                                    <SelectBoxRenderer id="workbook-currency-material" value={currentRow?.currency} label="Currency" isDisabled={isCompleted} onChange={(data) => onChange('currency', data)} options={currencyOptions} isLoading={currencyData.isLoading || currencyData.isFetching} error={errors?.currency} helperText={"Please Select the Currency"} />
                                </Grid>

                                {/* Lead time */}
                                <Grid item xs={12} sm={3}>
                                    <Stack width={"100%"}>
                                        <TextField
                                            id="workbook-lead"
                                            sx={{
                                                '.MuiOutlinedInput-input': {
                                                    p: 1
                                                },
                                                '.MuiInputBase-input.Mui-disabled': { border: errors?.lead_time_days ? "1px solid red !important" : 'inherit' },
                                            }}
                                            size="small"
                                            label="Lead Time (Days)"
                                            disabled={isCompleted}
                                            type={"number"}
                                            inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                                            InputLabelProps={{ shrink: true }}
                                            value={currentRow?.lead_time_days}
                                            error={errors?.lead_time_days}
                                            helperText={errors?.lead_time_days ? "Please fill the Lead Time (Days)" : ""}
                                            onChange={(e) => {
                                                onChange("lead_time_days", e.target.value)
                                            }}
                                            onKeyDown={(event) => {
                                                event.stopPropagation();
                                            }}
                                            viewOnly={isCompleted}
                                        />
                                    </Stack>
                                </Grid>
                            </Grid>

                        </Box>
                    </MyPaper>
                }

                {/* Sourcing Section */}
                <MyPaper padding={0}>
                    <Box>
                        {/* Heading */}
                        <Typography variant="body1" fontFamily={"htrts_medium"} sx={{ px: 2, py: 1, borderBottom: (theme) => `1px solid ${theme.palette.divider}` }}>
                            Sourcing Details
                        </Typography>

                        {/* Main Section */}
                        <Grid container spacing={2} p={2}>
                            {/* Sourcing Type */}
                            <Grid item xs={12}>
                                <SelectBoxRenderer
                                    id="workbook-sourcing_type" 
                                    label="Sourcing Type"
                                    value={currentRow?.sourcing_type}
                                    onChange={(data) => {
                                        onChange('sourcing_type', data, { vendor_id: null, manufacturer_name: "", part_number: "", unit_code: "", unit_location: "", other_details: "" })
                                    }}
                                    options={SourcingOption}
                                    isDisabled={isCompleted}
                                />
                            </Grid>

                            {/* Supplier Name */}
                            {currentRow?.sourcing_type?.value === "external_purchase" && <Grid item xs={12} sm={4}>
                                <SelectBoxRenderer id="workbook-vendor_id" value={currentRow?.vendor_id} label="Supplier Name" isDisabled={isCompleted} onChange={(data) => onChange('vendor_id', data)} options={supplierOptions} isLoading={supplierData.isLoading || supplierData.isFetching} error={errors?.vendor_id} helperText={"Please Select the Supplier Name"} />
                            </Grid>}

                            {/* Manufacture Name */}
                            {currentRow?.sourcing_type?.value === "external_purchase" && <Grid item xs={12} sm={4}>
                                <InputRenderer id="workbook-manufacturer_name" value={currentRow?.manufacturer_name} label="Manufacturer Name" isDisabled={isCompleted} onChange={(data) => onChange('manufacturer_name', data)} error={errors?.manufacturer_name} helperText={"Please fill the Manufacturer Name"} />
                            </Grid>}

                            {/* Manufacture Number */}
                            {currentRow?.sourcing_type?.value === "external_purchase" && <Grid item xs={12} sm={4}>
                                <InputRenderer id="workbook-part_number" value={currentRow?.part_number} label="Manufacturer Number" isDisabled={isCompleted} onChange={(data) => onChange('part_number', data)} error={errors?.part_number} helperText={"Please fill the Manufacturer Number"} />
                            </Grid>}

                            {/* Unit Name */}
                            {currentRow?.sourcing_type?.value !== "external_purchase" && currentRow?.sourcing_type && <Grid item xs={12} sm={4}>
                                <InputRenderer id="workbook-unit_code" value={currentRow?.unit_code} label="Unit Name" isDisabled={isCompleted} onChange={(data) => onChange('unit_code', data)} error={errors?.unit_code} helperText={"Please fill the Unit Name"} />
                            </Grid>}

                            {/* Unit Location */}
                            {currentRow?.sourcing_type?.value !== "external_purchase" && currentRow?.sourcing_type && <Grid item xs={12} sm={4}>
                                <InputRenderer id="workbook-unit_location" value={currentRow?.unit_location} label="Unit Location" isDisabled={isCompleted} onChange={(data) => onChange('unit_location', data)} error={errors?.unit_location} helperText={"Please fill the Unit Location"} />
                            </Grid>}

                            {/* Other Details */}
                            {currentRow?.sourcing_type?.value !== "external_purchase" && currentRow?.sourcing_type && <Grid item xs={12} sm={4}>
                                <InputRenderer id="workbook-other_details" value={currentRow?.other_details} label="Other Details" isDisabled={isCompleted} onChange={(data) => onChange('other_details', data)} error={errors?.other_details} helperText={"Please fill the Other Details"} />
                            </Grid>}
                        </Grid>
                    </Box>
                </MyPaper>

                {/* Child Options */}
                {workbookData?.type === typeOptions[1].value && <MyPaper padding={0}>
                    <Box>
                        {/* Heading */}
                        <Typography variant="body1" fontFamily={"htrts_medium"} sx={{ px: 2, py: 1, borderBottom: (theme) => `1px solid ${theme.palette.divider}` }}>
                            Child Options
                        </Typography>

                        {/* Main Section */}
                        <Grid container spacing={2} p={2}>
                            {/* Spare Part */}
                            <Grid item xs={12}>
                                <SelectBoxRenderer
                                    id="workbook-spare_part"
                                    label="Spare Part"
                                    value={currentRow?.spare_part}
                                    onChange={(data) => onChange('spare_part', data, { spare_part_category: null, spare_part_quantity: 0 })}
                                    options={sparePartOptions}
                                    isDisabled={isCompleted}
                                    error={errors?.spare_part}
                                    helperText={"Please Select the Spare Part"}
                                />
                            </Grid>

                            {/* Spare Part Category */}
                            {spare_part && <Grid item xs={12} sm={4}>
                                <SelectBoxRenderer id="workbook-spare_part_category" value={currentRow?.spare_part_category} label="Category" isLoading={sparePartCategoryData.isLoading || sparePartCategoryData.isFetching} isDisabled={isCompleted} onChange={(data) => onChange('spare_part_category', data)} options={sparePartCategoryOptions} error={errors?.spare_part_category} helperText={"Please Select the Spare Part Category"} />
                            </Grid>}

                            {/* Spare Part Qty */}
                            {spare_part && <Grid item xs={12} sm={4}>
                                <InputRenderer id="workbook-spare_part_quantity" value={currentRow?.spare_part_quantity} type={"number"} label="Spare Part Qty" isDisabled={isCompleted} onChange={(data) => onChange('spare_part_quantity', data)} error={errors?.spare_part_quantity} helperText={"Please fill the Spare Part Qty"} />
                            </Grid>}

                            {/* Qty */}
                            <Grid item xs={12} sm={4}>
                                <InputRenderer id="workbook-normal_quantity" value={currentRow?.normal_quantity} type={"number"} label="Qty" onChange={(data) => onChange('normal_quantity', data)} isDisabled={isCompleted} error={errors?.normal_quantity} helperText={"Please fill the Qty"} />
                            </Grid>

                            {/* Override Cost Qty */}
                            <Grid item xs={12} sm={4}>
                                <InputRenderer id="workbook-override_cost" value={currentRow?.override_cost} type={"number"} label="Override cost" onChange={(data) => onChange('override_cost', Number(data))} isDisabled={isCompleted} />
                            </Grid>

                            {/* Child Status */}
                            <Grid item xs={12} sm={4}>
                                <SelectBoxRenderer id="workbook-child_status" value={currentRow?.child_status} label="Child Status" isLoading={isChildStatusLoading} onChange={(data) => onChange('child_status', data)} options={selectedType === typeOptions[1].value ? childBOMStatusOptions : isMaterial ? childMaterialStatusOptions : []} isDisabled={isCompleted} />
                            </Grid>

                        </Grid>
                    </Box>
                </MyPaper>}
            </Stack>
        </DialogContent>

        {/* Footer */}
        {!isCompleted && 
            <DialogActions sx={{ justifyContent: 'center' }}>
                {/* Footer Buttons */}
                <Stack direction={"row"} alignItems={"center"} justifyContent={"center"} spacing={1} p={1}>
                    {/* Clear All Button */}
                    <Button variant="outlined" sx={{ width: 200 }} color="primary" onClick={() => setRow(row)} >
                        Clear All
                    </Button>

                    {/* Create Button */}
                    <LoadingButton id="c-u-item" variant="contained" sx={{ width: 200 }} color="primary" onClick={() => onAddUpdateBtnClicked()}>
                        {isEdit ? "Update" : "Create"}
                    </LoadingButton>

                    {/* Cancel Button */}
                    <Button variant="outlined" sx={{ width: 200 }} color="primary" onClick={() => dispatch(closeDialog())} >
                        Cancel
                    </Button>
                </Stack>
            </DialogActions>
        }
    </>
}