import { createSlice } from "@reduxjs/toolkit";
import copy from "fast-copy";
import { SelectBoxOption } from "../../../interfaces";

export interface IssueReason {
  why_description: string;
  why_seq: number;
}

export interface IssueTrackerBasicDetails {
  issue_id: string;
  revision: number;
  issue_created_On: string;
  issue_status: SelectBoxOption | null;
  issue_category: SelectBoxOption | null;
  issue_tag: SelectBoxOption[];
  description: string;
  why_issue: Array<IssueReason>;
  impact: string;
  action: string;
  name: string;
  change_req: SelectBoxOption | null;
}

export interface IssueTrackerBasicDetailsError {
  issue_id: boolean;
  revision: boolean;
  issue_created_On: boolean;
  issue_status: boolean;
  issue_category: boolean;
  issue_tag: boolean;
  description: boolean;
  why_issue: Array<boolean>;
  impact: boolean;
  action: boolean;
  name: boolean;
  change_req: boolean;
}

export interface linksData {
  links?: { object_type: string; object_ids: string[] }[];

  unlinks?: { object_type: string; object_ids: string[] }[];
}

export interface IssueTrackerDetails {
  basic_details: IssueTrackerBasicDetails;
  links_data?: linksData;
  error: {
    // Basic Details
    basic_details: IssueTrackerBasicDetailsError;
  };
}
const initialState: IssueTrackerDetails = {
  basic_details: {
    issue_id: "",
    revision: 0,
    issue_created_On: "",
    issue_status: null,
    issue_category: null,
    issue_tag: [],
    description: "",
    why_issue: [{ why_description: "", why_seq: 0 }],
    impact: "",
    action: "",
    name: "",
    change_req: null,
  },
  links_data: {
    links: [
      { object_type: "document", object_ids: [] },
      { object_type: "material", object_ids: [] },
    ],
  },
  error: {
    basic_details: {
      issue_id: false,
      revision: false,
      issue_created_On: false,
      issue_status: false,
      issue_category: false,
      issue_tag: false,
      description: false,
      why_issue: [false],
      impact: false,
      action: false,
      name: false,
      change_req: false,
    },
  },
};

export const issueTrackerSlice = createSlice({
  name: "issueTracker",
  initialState,
  reducers: {
    // To Slice by key value pair
    updateIssueSliceByKeyValue(
      state,
      {
        payload,
      }: { payload: { parentKey: string; childKey: string; value: any } }
    ) {
      let newState: any = copy(state);
      newState.error[payload.parentKey][payload.childKey] = payload.value
        ? false
        : true;
      newState[payload.parentKey][payload.childKey] = payload.value;
      return { ...state, ...newState };
    },
    // To reset all values to initialValue
    resetIssueSlice(state) {
      return { ...state, ...initialState };
    },
    // To update errors
    updateErrors(
      state,
      {
        payload,
      }: {
        payload: {
          parentKey: keyof IssueTrackerDetails;
          childKey: keyof IssueTrackerBasicDetails;
        }[];
      }
    ) {
      let project: IssueTrackerDetails = copy(state);
      let error = project.error;
      payload.forEach((key) => {
        if (key.parentKey === "basic_details") {
          if (
            Array.isArray(
              project.basic_details[
                key.childKey as keyof IssueTrackerBasicDetails
              ]
            ) &&
            key.childKey === "why_issue"
          ) {
            let errors: Array<boolean> = [];
            (
              project.basic_details[
                key.childKey as keyof IssueTrackerBasicDetails
              ] as Array<IssueReason>
            ).forEach((w) => {
              if (w.why_description === "") {
                errors.push(true);
              } else {
                errors.push(false);
              }
            });
            (error.basic_details[
              key.childKey as keyof IssueTrackerBasicDetails
            ] as Array<boolean>) = errors;
          }

          if (
            !project.basic_details[
              key.childKey as keyof IssueTrackerBasicDetails
            ]
          ) {
            (error.basic_details[
              key.childKey as keyof IssueTrackerBasicDetails
            ] as boolean) = true;
          }
        }
      });

      return { ...state, error };
    },
    // To Bulk Update the slice
    updateIssueSlice(
      state,
      {
        payload,
      }: {
        payload: {
          // Basic Details
          basic_details: IssueTrackerBasicDetails;

          links_data: linksData;
        };
      }
    ) {
      return { ...state, ...payload };
    },
  },
});
export const {
  updateIssueSliceByKeyValue,
  updateIssueSlice,
  resetIssueSlice,
  updateErrors,
} = issueTrackerSlice.actions;
export default issueTrackerSlice.reducer;
