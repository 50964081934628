import { Button, FormControl, FormControlLabel, Grid, Radio, RadioGroup, Typography } from "@mui/material";
import React from "react";
import { useSearchParams } from "react-router-dom";
import { MyPaper, SelectBox } from "../../components";
import { ReactSelectOption } from "../../interfaces";
import { useAppDispatch } from "../../redux";
import { useLazyGetBOMByIdQuery } from "../../redux/services";
import { closeBackdrop, openBackdrop } from "../../redux/slices/backdrop";

export const AssignChildBOMorMaterialToDrawioShape: React.FC<{ children?: JSX.Element }> = (props) => {

    const dispatch = useAppDispatch();
    const [getBOMById] = useLazyGetBOMByIdQuery();
    const [searchParams, setSearchParams] = useSearchParams();

    const [state, setState] = React.useState<{
        bom?: ReactSelectOption,
        childType?: "material" | "bom",
        childBOM?: ReactSelectOption,
        childMaterial?: ReactSelectOption
    }>();

    const updateChildType = (childType: string) => {
        if (childType === 'material') {
            setState({ ...state, childType: 'material', childBOM: undefined });
        } else if (childType === 'bom') {
            setState({ ...state, childType: 'bom', childMaterial: undefined });
        }
    };

    const onAssignTagNoBtnClicked = () => {
        const drawioContent: any = window.parent.window.parent.document.getElementById('drawio-editor-iframe');
        if (drawioContent) {
            let subEvent = '';
            let child = {};
            if(state?.childType === 'material'){
                subEvent = 'assignChildMaterial';
                child = state?.childMaterial ?? {};
            }else{
                subEvent = 'assignChildBOM';
                child = state?.childBOM ?? {};
            }

            drawioContent.contentWindow?.postMessage({ event: "iehub-plugin", subEvent, ...child }, "*");
        } else {
            console.log('drawio editor is not mapped');
        }
    };

    const updateExistingDetails = async () => {
        if (searchParams.get('edit') === "1") {
            dispatch(openBackdrop('Fetching BOM Detail...'));

            const bomId = searchParams.get("bomId");
            const bom = searchParams.get("bom");

            const childMaterial = searchParams.get("childMaterial");
            const childMaterialId = searchParams.get("childMaterialId");

            const childBOM = searchParams.get("childBOM");
            const childBOMId = searchParams.get("childBOMId");

            if (bomId) {
                try {
                    const bomDetails = await getBOMById({ id: bomId }).unwrap();

                    if (bomDetails) {
                        let child: { childType?: 'bom' | 'material' } = {};

                        if (childMaterialId) {
                            child.childType = 'material'
                        } else if (childBOMId) {
                            child.childType = 'bom'
                        };

                        setState({
                            ...state,
                            bom: { value: bomId, label: bom, ...bomDetails } as unknown as ReactSelectOption,
                            childType: child.childType,
                            childMaterial: child.childType === "material" ? { value: childMaterialId, label: childMaterial, ...bomDetails.bom_materials?.filter((_: any) => _.child_id === childMaterialId)?.[0] } as unknown as ReactSelectOption : undefined,
                            childBOM: child.childType === "bom" ? { value: childBOMId, label: childBOM, ...bomDetails.bom_children?.filter((_: any) => _.child_id === childBOMId) } as unknown as ReactSelectOption : undefined,
                        })
                    }
                } catch (err) {
                    console.log('err:', err)
                }
            }

            dispatch(closeBackdrop());
        }
    }

    const childBOMOptions = React.useMemo(() => {
        if (state?.bom) {
            let bom: any = state.bom;
            return bom?.bom_children?.map((_: any) => ({ ..._, value: _.child_id, label: `${_.sequence_id}-r${_.version} (${_?.bom?.short_description ?? "-"})` })) ?? [];
        } else {
            return [];
        }
    }, [state?.bom]);

    const childMaterialOptions = React.useMemo(() => {
        if (state?.bom) {
            let bom: any = state.bom;
            return bom?.bom_materials?.map((_: any) => ({ ..._, value: _.child_id, label: `${_.sequence_id}-r${_.version} (${_?.material?.short_description ?? "-"})` })) ?? [];
        } else {
            return [];
        }
    }, [state?.bom]);

    const messageHandler = (evt: MessageEvent) => {
        if (evt.data?.type === 'linkExisting') {
            setSearchParams({ ...evt.data, edit: 1 });
        }
    }

    // Initial load
    React.useEffect(() => {
        window.addEventListener('message', messageHandler);

        return () => {
            window.removeEventListener('message', messageHandler);
        };
        // eslint-disable-next-line
    }, []);

    React.useEffect(() => {
        updateExistingDetails();
        // eslint-disable-next-line
    }, [searchParams]);

    return <MyPaper height={"100vh"}>
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Typography variant="body2">Assigned BOM:&nbsp;<b>{state?.bom?.label ?? "Yet to assign BOM"}</b></Typography>
            </Grid>
            <Grid item xs={12}>
                <FormControl>
                    <Typography variant="body2" color="primary" align="left">
                        {"Child Type"}
                    </Typography>
                    <RadioGroup
                        value={state?.childType}
                        row
                        name="childType"
                        onChange={(e) => updateChildType(e.target.value)}
                    >
                        <FormControlLabel value="material" control={<Radio checked={state?.childType === "material"} />} label="Child Material" />
                        <FormControlLabel value="bom" control={<Radio checked={state?.childType === "bom"} />} label="Child BOM" />
                    </RadioGroup>
                </FormControl>
            </Grid>

            {state?.childType === "bom" && <Grid item xs={12}>
                <SelectBox
                    label={"Child BOM"}
                    value={state?.childBOM}
                    options={childBOMOptions}
                    onChange={(data: ReactSelectOption) => setState({ ...state, childBOM: data, childMaterial: undefined })}
                />
            </Grid>}

            {state?.childType === "material" && <Grid item xs={12}>
                <SelectBox
                    label={"Child Material"}
                    value={state?.childMaterial}
                    options={childMaterialOptions}
                    onChange={(data: ReactSelectOption) => setState({ ...state, childBOM: undefined, childMaterial: data })}
                />
            </Grid>}


            <Grid item xs={12}>
                <Button disabled={!state?.childBOM && !state?.childMaterial} fullWidth variant="contained" onClick={onAssignTagNoBtnClicked}>Assign {state?.childType === 'material' ? 'Material' : 'BOM'}</Button>
            </Grid>
        </Grid>
    </MyPaper>;
}