import {
    createApi,
    retry,
    fetchBaseQuery,
  } from "@reduxjs/toolkit/dist/query/react";
  import { config } from "../../../config";
  import {
    getTransformedDataForPagination,
    LocalStorageKeys,
  } from "../../../utils";
  
  export const bomReportAPI = createApi({
    reducerPath: "bomReportAPI",
    baseQuery: retry(
      fetchBaseQuery({
        baseUrl: config.api_url + "dm/bom_report",
        prepareHeaders: (headers) => {
          const token = localStorage.getItem(LocalStorageKeys.authToken);
  
          // If we have a token set in state, let's assume that we should be passing it.
          if (token) {
            headers.set("authorization", `Bearer ${token}`);
          }
  
          return headers;
        },
      }),
      { maxRetries: 1 }
    ),
    tagTypes: ["BOMReports"],
    endpoints: (builder) => ({
      // Get All BOM Reports API
      getAllBOMReports: builder.query({
        query: ({
          page = 0,
          pageSize = 10,
          object_type = null,
          object_id = null,
          sort_by = null, 
          sort_order = null
        }) => ({
          url: `${page !== null ? `?page_no=${page + 1}` : ""}${
            pageSize ? `&page_size=${pageSize}` : ""
          }${object_type ? `&object_type=${object_type}` : ""}${object_id ? `&object_id=${object_id}` : ""}${sort_by ? `&sort_by=${sort_by}` : ''}${sort_order ? `&sort_order=${sort_order}` : ''}`,
          method: "GET",
        }),
        transformResponse: (
          data: any,
          meta: { request: Request; response?: Response }
        ) => {
          return getTransformedDataForPagination(data, meta?.response);
        },
      }),
  
      // Get BOM Report by id API
      getBOMReportById: builder.query({
        query: ({ id, include_bom = true }) => ({
          url: `/${id}?include_bom=${include_bom}`,
          method: "GET",
        }),
      }),
  
      // Create BOM Report API
      createBOMReport: builder.mutation({
        query: (payload): Record<string, any> => ({
          method: "POST",
          body: payload,
        }),
      }),
  
      // Update BOM Report API
      updateBOMReport: builder.mutation({
        query: ({ id, payload }) => ({
          url: `/${id}`,
          method: "PUT",
          body: payload,
        }),
      }),
  
      // Delete BOM Report API
      deleteBOMReport: builder.mutation({
        query: ({
          id
        }) => ({
          url: `/${id}`,
          method: "DELETE",
        }),
      }),

      // Generate Full report
      generateBOMReport: builder.mutation({
        query: (id) => ({
          url: `/${id}/generate`,
          method: "POST",
        }),
      }),
    }),
  });
  
  export const {
   useGetAllBOMReportsQuery,
   useLazyGetAllBOMReportsQuery,
   useGetBOMReportByIdQuery,
   useLazyGetBOMReportByIdQuery,
   useCreateBOMReportMutation,
   useUpdateBOMReportMutation,
   useDeleteBOMReportMutation,
   useGenerateBOMReportMutation
  } = bomReportAPI;
  