import React from "react";
import Select from "react-select";
import { Typography, useTheme, Box, Tooltip, Chip, Stack } from "@mui/material";
import { useGetProductNounsQuery } from "../../redux/services";
import { ProductNounSelectBoxInterface, Option, customStyles } from "./util";

export const ProductNounSelectBoxWithoutSeach: React.FC<ProductNounSelectBoxInterface> = ({
    value = null, onChange = () => null,
    label = "", isRequired = false, placeholder = "", isMulti = false, disabled = false, height = null, family_id = null, viewOnly = false
}) => {

    const theme = useTheme();
    const productNounD = useGetProductNounsQuery({ page: null, pageSize: null }, { refetchOnMountOrArgChange: true });
    const productNounData = { ...productNounD, data: productNounD?.data?.data ?? [], currentData: productNounD?.data?.data ?? [], totalCount: productNounD?.data?.totalCount };

    const giveMeOptions = () => {
        if (family_id) {
            return productNounData.currentData?.filter((_: any) => _.family_id === family_id)?.map((_: any) => ({ value: _._id, label: _.family_name ? `${_.product_noun_name}(${_.family_name})` : _.product_noun_name, ..._ }));
        }
        return productNounData.currentData?.map((_: any) => ({ value: _._id, label: _.family_name ? `${_.product_noun_name}(${_.family_name})` : _.product_noun_name, ..._ }));
    }


    let selectedValue = value && !Array.isArray(value) ? value?.label || "N/A" : "N/A";

    return viewOnly ? (
        <Stack sx={{ overflowWrap: "break-word" }}>
            <Typography variant="body1" sx={{ fontFamily: "htrts_semibold" }}>{label}</Typography>
            {Array.isArray(value) && value.length > 0 ? <Stack
                gap={1}
                direction={"row"}
                flexWrap={"wrap"}
            >
                {
                    value.map(v =>
                        <Tooltip title={v?.label || "N/A"}>
                            <Chip size={"small"} label={v?.label || "N/A"} />
                        </Tooltip>
                    )
                }
            </Stack>
                : <Typography variant="body2" sx={{ fontFamily: "htrts_regular" }}>{selectedValue}</Typography>}
        </Stack>
    ) : (
        <Box>
            {label.length > 0 && <Box sx={{ display: "flex" }}>
                {
                    <Typography variant="body2" color="primary" align="left" gutterBottom>
                        {label}
                    </Typography>
                }
                {isRequired && (
                    <Typography color="error" variant="caption">
                        *
                    </Typography>
                )}
            </Box>}


            <Select
                id="product-without-search"
                isMulti={isMulti}
                isClearable={false}
                isSearchable
                isDisabled={disabled}
                components={{
                    IndicatorSeparator: () => null,
                    Option
                }}
                value={value}
                placeholder={placeholder}
                options={giveMeOptions()}
                isLoading={productNounData.isFetching}
                onChange={onChange}
                styles={customStyles(theme, height)}
            />

            {(productNounData.isError && !productNounData.isFetching) && (
                <Typography variant="caption" color="error">
                    {"Opps! Unable to fetch "}
                </Typography>
            )}
        </Box>
    );
};
