import { createApi, fetchBaseQuery, retry } from '@reduxjs/toolkit/query/react';
import { config } from '../../../config';
import { LocalStorageKeys } from '../../../utils';

export const chatBotAPI = createApi({
    reducerPath: "chatBotAPI",
    baseQuery: retry(fetchBaseQuery({
        baseUrl: config.api_url + "chatbot",
        prepareHeaders: (headers, { getState }) => {
            const token = localStorage.getItem(LocalStorageKeys.authToken);

            // If we have a token set in state, let's assume that we should be passing it.
            if (token) {
                headers.set('authorization', `Bearer ${token}`)
            }

            return headers
        },
    }), { maxRetries: 1 }),
    tagTypes: ['ChatBot'],
    endpoints: (builder) => ({
        // Get Answer
        getAnswer: builder.mutation({
            query: (payload) => ({ method: "POST", body: payload })
        }),

    })
})

// Export hooks for usage in functional components
export const { useGetAnswerMutation } = chatBotAPI
