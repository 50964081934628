import { useAuth0 } from "@auth0/auth0-react";
import { Check, Link, Send } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Alert, Avatar, Box, Button, Chip, IconButton, InputBase, Skeleton, Stack, Tooltip, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import React, { FormEvent, KeyboardEvent, useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../redux";
import { ChatHistory, CitationResponse, useChatMutation, useConnectChatBotQuery } from "../../../redux/services";
import { openDialog } from "../../../redux/slices/dialog";
import { HasAccess } from "../../../router/authorization";
import { PERMISSIONS } from "../../../router/permission";
import { getObjectType } from "../../listview/addEditListView";
import PDFHighlighter, { ICitation } from "./pdfHighlighter";
import ChatBotMessage from "./renderMessage";

const char_limit = 2000;
const chat_count = 4;
const chat_history_limit = chat_count * 2;

const welcome_message = { actor: "ai", content: "Hello! How can I assist you today?" } as ChatHistory;
const loading_message = { actor: "ai", content: "Building chatbot with the documents. Please wait..." } as ChatHistory;
const no_document_message = { actor: "ai", content: "You are yet to add the document, Please add and come back to chat bot.", isError: true } as ChatHistory;

export const ChatBox: React.FC<{ children?: JSX.Element, searchId: string }> = (props) => {
    const { user } = useAuth0();
    const location = useLocation();
    const connectChatBot = useConnectChatBotQuery({ id: props.searchId });
    const [sendMessage, sendMessageData] = useChatMutation();

    const [userInput, setUserInput] = useState("");
    const [loading, setLoading] = useState(false);
    const [messages, setMessages] = useState<ChatHistory[]>([
        loading_message
    ]);

    const [calculatedHeight, setHeight] = React.useState(0);

    const messageListRef = useRef<HTMLDivElement>(null);
    const textAreaRef = useRef<HTMLTextAreaElement>(null);

    const { enqueueSnackbar } = useSnackbar();

    const dispatch = useAppDispatch();

    const { perm } = useAppSelector(store => store.auth.userDetails);

    const editable = React.useMemo(() => {
        const primaryAccess = HasAccess(perm, PERMISSIONS.AI_SEARCH_UPDATE);
        const module = getObjectType(location);
        if (module === 'product') {
            return HasAccess(perm, PERMISSIONS.PRODUCT_UPDATE) && primaryAccess
        }
        if (module === 'proposal') {
            return HasAccess(perm, PERMISSIONS.PROPOSAL_UPDATE) && primaryAccess
        }
        if (module === 'project') {
            return HasAccess(perm, PERMISSIONS.PROJECT_UPDATE) && primaryAccess
        }
        return primaryAccess
    }, [perm, location]);

    const disableSend = loading || connectChatBot.isError || connectChatBot.isLoading || connectChatBot.isFetching || !editable;

    // Auto scroll chat to bottom
    useEffect(() => {
        if (messageListRef.current) {
            const messageList = messageListRef.current;
            messageList.scrollTop = messageList.scrollHeight;
        }
    }, [messages]);

    // Focus on input field
    useEffect(() => {
        if (textAreaRef.current) {
            textAreaRef.current.focus();
        }
    }, []);

    // Handle errors
    const handleError = (message: string) => {
        setMessages((prevMessages) => [
            ...prevMessages,
            {
                actor: "ai",
                content: message,
                isError: true
            },
        ]);
        setLoading(false);
        setUserInput("");
    };

    // Handle form submission
    const handleSubmit = async (e: FormEvent) => {
        e.preventDefault();

        if (disableSend) {
            return;
        }

        if (userInput.trim() === "") {
            return;
        }

        setLoading(true);
        setMessages((prevMessages) => [...prevMessages, { actor: "human", content: userInput }]);

        try {
            let sliceResponseCount = Math.max(messages.length - chat_history_limit, 0)
            // Reset user input
            sendMessage({ id: props.searchId, payload: { chat_history: messages.slice(sliceResponseCount), question: userInput } }).then((response: any) => {
                let message = response.error ?? response?.data?.error?.title ?? response?.data?.error ?? "";
                if (message) {
                    handleError(message);
                } else {
                    let pattern = /<<(?:\d+)>>,*\s*/g;
                    setMessages((prevMessages) => [...prevMessages, { actor: "ai", content: (response?.data?.answer ?? "").replace(pattern, ""), citations: response?.data?.citations ?? [] }]);
                }
                setLoading(false);
            });
        } catch (error: any) {
            let message = error?.data?.title ?? error?.data ?? error ?? "";
            handleError(message);
            setLoading(false);
        }

        setUserInput("");

    };

    // Prevent blank submissions and allow for multiline input
    const handleEnter = (e: KeyboardEvent<HTMLTextAreaElement>) => {
        if (e.key === "Enter" && userInput) {
            if (!e.shiftKey && userInput) {
                handleSubmit(e);
            }
        } else if (e.key === "Enter") {
            e.preventDefault();
        }
    };

    const handleClearChatHistory = async (e: FormEvent) => {
        e.preventDefault();

        setMessages([
            welcome_message
        ]);
    }

    const openPDfViewer = (citation: ICitation) => {
        dispatch(
            openDialog({
                title: "",
                hideNegativeBtn: true,
                hidePositiveBtn: true,
                maxWidth: "lg",
                enablePadding: false,
                body: <PDFHighlighter citation={citation} storagePrefix={props.searchId} />,
            })
        );
    }

    const getCitationsMapped = (citation: CitationResponse) => {
        return {
            ...citation, bbox: citation.bbox.map(box => ({
                ...box, pageIndex: box.page - 1, width: Math.abs((box.x1 - box.x0) / box.page_width) * 100,
                left: (box.x0 / box.page_width) * 100,
                height: Math.abs((box.y1 - box.y0) / box.page_height) * 100,
                top: (box.y0 / box.page_height) * 100,
            }))
        }
    }

    useEffect(() => {
        if (connectChatBot?.isError) {
            let errorContent = (connectChatBot?.error as any)?.data?.title ?? "Unable to connect to the chat";
            enqueueSnackbar(errorContent, { variant: "error" });
            setMessages([{ ...no_document_message, content: errorContent }]);
        }
        if (connectChatBot?.isSuccess) {
            setMessages([welcome_message]);
        }
        // eslint-disable-next-line
    }, [connectChatBot.status])

    useEffect(() => {
        if (sendMessageData?.data?.error || sendMessageData?.data?.data?.error) {
            enqueueSnackbar(sendMessageData?.data?.error ?? sendMessageData?.data?.data?.error ?? "No data available to query", { variant: "error" });
        }
        // eslint-disable-next-line
    }, [sendMessageData.status])

    useEffect(() => {
        const breadcrumbs = document.getElementById("breadcrumbs");
        const topNavBar = document.getElementById("topNavBar");
        const handleResize = () => {
            setHeight((breadcrumbs?.clientHeight ?? 0) + (topNavBar?.clientHeight ?? 0) + 30);
        };

        window.addEventListener('resize', handleResize);

        if (!calculatedHeight) {
            setHeight((breadcrumbs?.clientHeight ?? 0) + (topNavBar?.clientHeight ?? 0) + 30);
        }

        return () => {
            window.removeEventListener('resize', handleResize);
        };
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        // Function to clear specific localStorage items
        const clearLocalStorageItems = () => {
            for (let i = 0; i < localStorage.length; i++) {
                const key = localStorage.key(i);
                if (key && key.startsWith(`${props.searchId}-`)) {
                    localStorage.removeItem(key);

                    // After removing an item, the localStorage length changes
                    // Decrement i to ensure the loop does not skip the next item
                    i--;
                }
            }
        };

        return () => {
            clearLocalStorageItems();
        };
        // eslint-disable-next-line
    }, []);

    return <Stack sx={{
        boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
        background: (theme) => theme.palette.background.paper,
        borderRadius: 2,
        height: `calc(100vh - ${calculatedHeight}px)`,
        overflow: "auto",
    }}>
        <Stack height={"100%"} overflow={"hidden"} id='chatbot'>
            {/* Heading */}
            <Stack sx={{ borderBottom: (theme) => `1px solid ${theme.palette.divider}` }} direction={"row"} justifyContent={"space-between"} p={1}>
                <Stack direction={"row"} spacing={2} alignItems={"center"}>
                    <Typography
                        variant="body1"
                        fontFamily={"htrts_medium"}>
                        Chat Bot
                    </Typography>
                    <Button variant="outlined" color={(messages.length > 1 && !loading) ? "error" : "inherit"} disabled={messages.length <= 1 || loading} onClick={handleClearChatHistory}>
                        <Typography variant="body2" color={(messages.length > 1 && !loading) ? "error" : "disabled"} >Clear</Typography>
                    </Button>
                </Stack>

                <LoadingButton
                    size="small"
                    variant="outlined"
                    color={connectChatBot.isSuccess ? "success" : "primary"}
                    loading={connectChatBot.isLoading || connectChatBot.isFetching}
                    loadingPosition="end"
                    endIcon={connectChatBot.isSuccess ? <Check color="success" /> : <Link />}
                    onClick={() => {
                        connectChatBot.refetch()
                    }}
                >
                    {`${connectChatBot.isSuccess ? "Connected" : "Connect"}`}
                </LoadingButton>
            </Stack>

            {/* Messages */}
            <Box flexGrow={1} overflow={'auto'} ref={messageListRef}>
                {messages.map((message, index) => {
                    const isChatBot = message.actor === 'ai';
                    return <Stack direction={"row"} spacing={1} p={1} key={index + 1} sx={{
                        background: (theme) => isChatBot ? "#f5f5f5" : 'white',
                        borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
                        borderRight: (theme) => `1px solid ${theme.palette.divider}`
                    }} alignItems={"flex-start"}>
                        <Avatar src={isChatBot ? '/images/openai.jpeg' : user?.picture} variant="square" />
                        {message?.isError
                            ? <Alert severity="error">{message?.content}</Alert>
                            : <Stack>
                                <ChatBotMessage message={message.content} />
                                <Stack direction={"row"} mt={1} flexWrap={'wrap'}>
                                    {(message.citations ?? []).map(getCitationsMapped).map((citation, index) => {
                                        const title = citation.document?.filename ?? "Citation Preview";
                                        return (
                                            <Tooltip title={title}>
                                                <Button onClick={() => openPDfViewer(citation)}>
                                                    <Chip size="small" sx={{ borderRadius: 2, cursor: "pointer" }} variant="outlined" color="primary" avatar={<Avatar>{index + 1}</Avatar>} label={title.length >= 50 ? `${title.substring(0, 50)}...` : title} />
                                                </Button>
                                            </Tooltip>
                                        )
                                    })}
                                </Stack>
                            </Stack>
                        }
                    </Stack>
                })}

                {sendMessageData.isLoading && <Stack direction={"row"} spacing={1} p={1} sx={{
                    background: (theme) => "#f5f5f5",
                    borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
                    borderRight: (theme) => `1px solid ${theme.palette.divider}`
                }}>
                    <Avatar src={'/images/openai.jpeg'} variant="square" />
                    <Box width={"100%"}>
                        <Skeleton variant="text" animation="wave" width={"100%"} />
                        <Skeleton variant="text" animation="wave" width={"100%"} />
                        <Skeleton variant="text" animation="wave" width={"100%"} />
                    </Box>
                </Stack>}
            </Box>
            {/* Input Box */}
            <Box p={1} display={"flex"} borderRadius={"8px"} sx={{ boxShadow: `rgba(0, 0, 0, 0.24) 0px 3px 8px` }}>
                <InputBase
                    onKeyDown={handleEnter}
                    disabled={disableSend}
                    autoFocus={true}
                    fullWidth
                    maxRows={5}
                    type="text"
                    inputProps={{ maxLength: char_limit }}
                    multiline={true}
                    placeholder="Type something here..."
                    value={userInput}
                    onChange={(e) => setUserInput(e.target.value)}
                />
                <IconButton disabled={true}><Typography variant={'body2'} sx={{ whiteSpace: "nowrap" }}>{userInput.length} / {char_limit}</Typography></IconButton>
                <IconButton disabled={disableSend} onClick={handleSubmit}>
                    <Send color={disableSend ? "disabled" : "success"} />
                </IconButton>
            </Box>
        </Stack>
    </Stack>
}
