import { IconButton, Skeleton, Stack, Tooltip, Typography, Chip, Avatar } from "@mui/material";
import { ChromeReaderMode, ContentCopy, Delete, Edit } from "@mui/icons-material";
import { MastersInterface } from "../../interfaces";
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid-premium';
import React from "react";
import { useGetNounsQuery, useGetUoMQuery } from "../../redux/services";

export const MasterValue = {
    uom: "UOM",
    group: "GROUP",
    noun: "NOUN",
    attribute: "ATTRIBUTE",
    nounVariant: "NOUN_VARIANT"
}

export const allMasters = (previlages: string[]): MastersInterface[] => ([
    {
        value: MasterValue.uom,
        label: "Regions",
        previlages,
        is_active: true,
        column: (edit, deleteRow) => {
            const columns: GridColDef[] = [
                {
                    field: 'actions', headerName: 'Edit', width: 80, sortable: false, disableColumnMenu: true, renderCell: (params: GridRenderCellParams) => {
                        return <IconButton disabled={!previlages.includes('D')} onClick={() => edit(params.row)} id="edit-region"> <Edit fontSize="small" color={"primary"} /></IconButton >
                    }
                },
                // { field: 'id', headerName: 'Id', width: 90 },
                {
                    field: 'uom_name', headerName: 'Regions', width: 250, renderCell: (params: GridRenderCellParams) => {
                        if (params.rowNode.type === 'group') {
                            return params.value;
                        }
                        return <Typography variant="body2" sx={{ width: "100%", overflow: "hidden", textOverflow: "ellipsis" }}>{params?.row?.uom_name}</Typography>
                    }
                },
                {
                    field: 'uom_desc', headerName: 'Description', width: 400, renderCell: (params: GridRenderCellParams) => {
                        if (params.rowNode.type === 'group') {
                            return params.value;
                        }
                        return <Tooltip title={params?.row?.uom_desc}><Typography variant="body2" sx={{ width: "100%", overflow: "hidden", textOverflow: "ellipsis" }}>{params?.row?.uom_desc}</Typography></Tooltip>
                    }
                },
                {
                    field: 'delete', headerName: 'Delete', width: 80, sortable: false, disableColumnMenu: true, renderCell: (params: GridRenderCellParams) => {
                        return <IconButton disabled={!previlages.includes('D')} onClick={() => deleteRow({ ...params?.row, label: params?.row?.uom_name })} id="delete-region"> <Delete fontSize="small" color={"error"} /></IconButton >
                    }
                },
            ]

            if (!previlages.includes('U')) {
                columns.shift();
            }

            if (!previlages.includes('D')) {
                columns.pop();
            }

            return columns;
        }
    },
    {
        value: MasterValue.group,
        label: "Group",
        previlages,
        is_active: true,
        column: (edit, deleteRow) => {
            const columns = [
                {
                    field: 'actions', headerName: 'Edit', width: 80, sortable: false, disableColumnMenu: true, renderCell: (params: GridRenderCellParams) => {
                        return <IconButton onClick={() => edit(params?.row)} id="edit-group"> <Edit fontSize="small" color={"primary"} /></IconButton >
                    }
                },
                // { field: 'id', headerName: 'Id', width: 90 },
                { field: 'group_name', headerName: 'Group', width: 250 },
                {
                    field: 'group_desc', headerName: 'Description', width: 400, renderCell: (params: GridRenderCellParams) => {
                        return <Tooltip title={params?.row?.group_desc}><Typography variant="body2" sx={{ width: "100%", overflow: "hidden", textOverflow: "ellipsis" }}>{params?.row?.group_desc}</Typography></Tooltip>
                    }
                },
                {
                    field: 'delete', headerName: 'Delete', width: 80, sortable: false, disableColumnMenu: true, renderCell: (params: GridRenderCellParams) => {
                        return <IconButton onClick={() => deleteRow({ ...params?.row, label: params?.row?.group_name })} id="delete-group"> <Delete fontSize="small" color={"error"} /></IconButton >
                    }
                },
            ];

            if (!previlages.includes('U')) {
                columns.shift();
            }

            if (!previlages.includes('D')) {
                columns.pop();
            }

            return columns;
        }
    },
    {
        value: MasterValue.noun,
        label: "Noun",
        previlages,
        is_active: true,
        columnVisibilityModel: {
            // Hide columns by default here
            noun_desc: false,
        },
        column: (edit, deleteRow) => {
            const columns = [
                {
                    field: 'actions', headerName: 'Edit', width: 80, sortable: false, disableColumnMenu: true, renderCell: (params: GridRenderCellParams) => {
                        return <IconButton onClick={() => edit(params?.row)} id="edit-noun"> <Edit fontSize="small" color={"primary"} /></IconButton >
                    }
                },
                // { field: 'id', headerName: 'Id', width: 90 },
                {
                    field: 'attachments',
                    headerName: 'Image',
                    width: 100,
                    renderCell: (params: GridRenderCellParams) => (
                        <Avatar variant="square" sx={{ '.MuiAvatar-img': { objectFit: "contain !important" } }} alt={params?.row?.noun_name} src={params?.row?.attachments ? params?.row?.attachments?.[params?.row?.attachments.length - 1]?.attachment_url : `/imageNotFound.jpg`}>
                            <img height={"50px"} width={"50px"} src={`/imageNotFound.jpg`} alt={params?.row?.noun_name} />
                        </Avatar>
                    ),
                    sortable: false, 
                    disableColumnMenu: true
                },
                { field: 'noun_name', headerName: 'Noun', width: 250 },
                { field: 'uom_name', headerName: 'Region', headerClassName: 'no-sorting', width: 350, renderCell: (params: GridRenderCellParams) => (<GiveMeUoMName uom_id={params?.row?.uom_id} />) },
                { field: 'group_name', headerName: 'Group', width: 250 },
                {
                    field: 'noun_desc', headerName: 'Description', width: 500, renderCell: (params: GridRenderCellParams) => {
                        return <Tooltip title={params?.row?.noun_desc}><Typography variant="body2" sx={{ width: "100%", overflow: "hidden", textOverflow: "ellipsis" }}>{params?.row?.noun_desc}</Typography></Tooltip>
                    }
                },
                {
                    field: 'delete', headerName: 'Delete', width: 80, sortable: false, disableColumnMenu: true, renderCell: (params: GridRenderCellParams) => {
                        return <IconButton onClick={() => deleteRow({ ...params?.row, label: params?.row?.noun_name })} id="delete-noun"> <Delete fontSize="small" color={"error"} /></IconButton >
                    }
                },
                // { field: 'attribute', headerName: 'Attribute', width: 250 },
            ];

            if (!previlages.includes('U')) {
                columns.shift();
            }

            if (!previlages.includes('D')) {
                columns.pop();
            }

            return columns;
        }
    },
    {
        value: MasterValue.attribute,
        label: "Attribute",
        previlages,
        is_active: true,
        columnVisibilityModel: {
            // Hide columns by default here
            options: false,
        },
        column: (edit, deleteRow, copy) => {
            const columns = [
                {
                    field: 'actions', headerName: 'Edit', resizable: true, width: 40, disableColumnMenu: true, sortable: false, renderCell: (params: GridRenderCellParams) => {
                        return <IconButton onClick={() => edit(params.row)} id="edit-attribute"> <Edit fontSize="small" color={"primary"} /></IconButton >
                    }
                },
                {
                    field: 'duplicate', headerName: 'Duplicate', resizable: true, width: 90, disableColumnMenu: true, sortable: false, renderCell: (params: GridRenderCellParams) => {
                        return <Tooltip title="Make a Duplicate">
                            <IconButton sx={{ margin: "auto" }} onClick={() => copy({ ...params?.row, label: params?.row?.attr_name })}>
                                <ContentCopy fontSize="small" />
                            </IconButton >
                        </Tooltip>
                    }
                },
                // { field: 'id', headerName: 'Id', width: 90 },
                { field: 'attr_name', headerName: 'Attribute Name', width: 250 },
                {
                    field: 'options', headerName: 'Option (Abbrevation)', width: 350, renderCell: (params: GridRenderCellParams) => (
                        <Stack spacing={1} direction={"row"} width="100%" overflow={"scroll"} id={"nounsChip"}>
                            {params?.row?.options?.map((opt: any, key: number) => (<Chip variant="outlined" size={"small"} label={`${opt.option_name} (${opt.option_abbr})`} />))}
                        </Stack>)
                },
                { field: 'nouns', headerName: 'Associated Nouns', width: 400, renderCell: (params: GridRenderCellParams) => (<NounChipByNounIds nouns={params?.row?.nouns} />) },
                {
                    field: 'delete', headerName: 'Delete', width: 80, sortable: false, disableColumnMenu: true, renderCell: (params: GridRenderCellParams) => {
                        return <IconButton onClick={() => deleteRow({ ...params?.row, label: params?.row?.attr_name })} id="delete-attribute"> <Delete fontSize="small" color={"error"} /></IconButton >
                    }
                },
            ]

            if (!previlages.includes('U')) {
                columns.shift();
                columns.shift();
            }

            if (!previlages.includes('D')) {
                columns.pop();
            }

            return columns;
        }
    },
    {
        value: MasterValue.nounVariant,
        label: "Noun Variants",
        previlages,
        is_active: true,
        column: (edit, deleteRow) => {

            const viewOnly = !previlages.includes('U');

            const columns = [
                {
                    field: 'actions', headerName: viewOnly ? 'View' : 'Edit', width: 80, sortable: false, disableColumnMenu: true, renderCell: (params: GridRenderCellParams) => {
                        return <IconButton onClick={() => edit(params?.row)} id="edit-noun-variant">
                            {!viewOnly && <Edit fontSize="small" color={"primary"} />}
                            {viewOnly && <ChromeReaderMode fontSize="small" color={"primary"} />}
                        </IconButton >
                    }
                },
                // { field: 'id', headerName: 'Id', width: 90 },
                {
                    field: 'attachments',
                    headerName: 'Image',
                    width: 120,
                    renderCell: (params: GridRenderCellParams) => (
                        <Avatar variant="square" sx={{ '.MuiAvatar-img': { objectFit: "contain !important" } }} alt={params?.row?.noun_name} src={params?.row?.attachments ? params?.row?.attachments?.[params?.row?.attachments.length - 1]?.attachment_url : `/imageNotFound.jpg`}>
                            <img height={"50px"} width={"50px"} src={`/imageNotFound.jpg`} alt={params?.row?.noun_name} />
                        </Avatar>
                    ),
                    sortable: false, 
                    disableColumnMenu: true
                },
                { field: 'nounvariant_name', headerName: 'Noun Variant Name', width: 300 },
                {
                    field: 'delete', headerName: 'Delete', width: 80, sortable: false, disableColumnMenu: true, renderCell: (params: GridRenderCellParams) => {
                        return <IconButton onClick={() => deleteRow({ ...params?.row, label: params?.row?.nounvariant_name })} id="delete-noun-variant"> <Delete fontSize="small" color={"error"} /></IconButton >
                    }
                },
            ]

            if (!previlages.includes('D')) {
                columns.pop();
            }

            return columns;
        }
    }
]);

const GiveMeUoMName: React.FC<{ uom_id: any }> = (props) => {

    const { data: allData = { data: [], totalCount: 0 }, isLoading, isError } = useGetUoMQuery({ page: null, pageSize: null }, { refetchOnMountOrArgChange: true });
    const { data } = allData;

    if (isLoading) {
        return <Skeleton sx={{ width: "100px" }} />
    }

    if (isError) {
        return <Typography variant={"caption"} color={"error"} >Oops! Unable to get UoM name.</Typography>
    }

    let selectedUoM: any = data?.filter((_: any) => _._id === props.uom_id)?.[0] ?? {};

    return <Typography variant={"body2"}>{selectedUoM.uom_name}</Typography>
}

const NounChipByNounIds: React.FC<{ nouns: string[] }> = ({ nouns }) => {
    const { data: allData = { data: [], totalCount: 0 }, isLoading, isError } = useGetNounsQuery({ page: null, pageSize: null }, { refetchOnMountOrArgChange: true });
    const { data } = allData;

    if (isLoading) {
        return <Stack direction={"row"}>
            {[1, 2, 3].map((_: number) => (<Skeleton key={_} sx={{ width: "100px" }} />))}
        </Stack>
    }

    if (isError) {
        return <Typography variant={"caption"} color={"error"} >Oops! Unable to get associated nouns.</Typography>
    }

    let selectedNoun: any[] = data?.filter((_: any) => nouns?.includes(_._id));

    return <Stack spacing={1} direction={"row"} width="100%" overflow={"scroll"} id={"nounsChip"}>
        {selectedNoun?.map((noun: any, key: number) => {
            return <Chip key={key} size="small" label={noun.noun_name} />
        })}
    </Stack>
}