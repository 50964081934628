import React from "react";
import {
  Alert,
  AlertColor,
  AlertTitle,
  CircularProgress,
  Typography,
} from "@mui/material";
import { buildContent } from "../../utils";
import { notify } from "../../redux/slices/notifications";
import { useAppDispatch } from "../../redux";

interface IAlert {
  status: AlertColor;
  value: {
    content: string;
    subs: Array<string>;
    sequenceID: string;
    message?: string;
    id: string;
    name: string;
  };
}

const AlertCard: React.FC<IAlert> = ({ status, value }) => {
  const [alertStatus, setAlertStatus] = React.useState(status);

  const [content, setContent] = React.useState("");

  const [isWaitingDone, setIsWaitingDone] = React.useState(false);

  const timeout = React.useRef<NodeJS.Timeout | null>(null);

  const dispatch = useAppDispatch();

  React.useEffect(() => {
    setContent(buildContent(value?.content, value?.subs, value?.sequenceID));
    setAlertStatus(status);

    if (status === "info") {
      timeout.current = setTimeout(() => {
        setIsWaitingDone(true);
      }, 1000 * 60 * 2);
    } else {
      setIsWaitingDone(false);
    }

    if (isWaitingDone) {
      if (status === "info") {
        dispatch(notify({
          name: value?.name,
          content: `Unable to fetch the status for ${value?.sequenceID ?? ""}. Please refresh the page to know the status`,
          id: value?.id,
          status: "failed",
          subs: [
            "error"
          ],
        })) 
      }
    }

    return () => {
      if (timeout.current) {
        clearTimeout(timeout.current)
      }
    };
    // eslint-disable-next-line
  }, [status, isWaitingDone]);
  
  

  return (
    <Alert
      severity={alertStatus}
      iconMapping={{
        info: <CircularProgress size={"1em"} color="info" />,
      }}
    >
      <AlertTitle>{content}</AlertTitle>
      <Typography color="error">{value?.message}</Typography>
    </Alert>
  );
};

export default AlertCard;
