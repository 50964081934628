import { PERMISSIONS } from "./permission";
import { AppRoutes } from "./routes"

/**
 * The below function define redirection for a particular route based on the role
 */
export const Redirections = (userRole: string) => {
    switch (userRole) {
        default:
            return '';
    }
}

/**
 * The below function define redirection to a particular route based on the role
 */
export const LoginSuccess = (userRole: string = "") => {
    switch (userRole) {
        default:
            return AppRoutes.home;
    }
}



/**
 * Below function gives true or false based on the permission the user have for the given 
 * module, sub mdoule and  operation (c,r,u,d, ...)
 */
export const HasAccess = (perm: any, permission: any[]) => {

    if(!perm){
        return false
    }

    if (Object.values(perm).length === 0 && permission.length === 0) {
        return false;
    }

    if (permission[0] === PERMISSIONS.PUBLIC[0]) {
        return true;
    }

    let module: string = permission?.[0] ?? "";
    let subModule: string = permission?.[1] ?? "";
    let operations: string[] = permission?.[2] ?? [];

    let moduleAccess = perm?.[module] ?? false;

    if (!moduleAccess) {
        return false;
    }

    let subModuleAccess = perm[module]?.[subModule] ?? false;

    if (!subModuleAccess) {
        return false;
    }

    return operations.every(operation => subModuleAccess.includes(operation));
}