import React from "react";
import { Box, Stack, Skeleton } from "@mui/material";
import { CardWithImage, InternatlServerError, NotFoundSection } from "../../components";

export const NounVariantListing: React.FC<{
    variants: any,
    onImageClick: any,
    selectedImage: any,
    loading?: boolean,
    error?: boolean,
    onRetry?: any,
    height?: string
}> = ({ variants, onImageClick, selectedImage, loading = false, error = false, onRetry = () => false, height = "calc(100% - 90px)" }) => {

    return <Box mt={1} height={height} sx={{ width: '100%',  overflowY: "scroll", overflowX: "hidden" }}>
        <Stack
            direction={"row"}
            flexWrap={"wrap"}
        >
            {loading && Array.from(Array(6).keys()).map((arr, key) => {
                return <Box
                    key={key}
                    height={"200px"}
                    mb={1}
                    mr={1}
                    sx={{ width: { xs: "100%", sm: "200px" } }}
                >
                    <Skeleton variant="rectangular" width={"100%"} height={200} />
                </Box>
            })}

            {!loading && error && <InternatlServerError message={'Opps! Something Went Wrong, Unable to fetch Noun Variants'} onRetry={onRetry} />}
            {!loading && !error && variants?.length === 0 && <NotFoundSection message='You are yet to map variants to this noun.' />}
            {!loading && variants?.map((variant: any, key: number) => {
                return <CardWithImage
                    id={`noun-variant-${key}`}
                    key={key}
                    data={variant}
                    onImageClick={onImageClick}
                    image={variant.attachments?.[variant.attachments.length - 1]?.attachment_url}
                    title={variant?.nounvariant_name ?? "Variant " + key}
                    isSelected={variant._id === selectedImage?._id}
                />
            })}
        </Stack >
    </Box>
}