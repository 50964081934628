import { ChevronRight, ExpandMore } from "@mui/icons-material";
import { TreeItem, treeItemClasses, TreeView } from "@mui/lab";
import { Alert, alpha, Box, Button, CircularProgress, DialogActions, DialogContent, DialogTitle, Grid, Stack, styled, TextField, Theme, Typography, useTheme } from "@mui/material";
import { GridRowParams, GridSortDirection, GridSortModel } from "@mui/x-data-grid-premium";
import copy from 'fast-copy';
import { useSnackbar } from "notistack";
import React from "react";
import { useNavigate } from "react-router-dom";
import { CustomDataGrid, DeletePanel, FilterStateInterface, Filters, History, TitleHeader, TitleHeaderProps } from "../../../components";
import { SelectBoxOption } from "../../../interfaces";
import { useAppDispatch, useAppSelector } from "../../../redux";
import { useDeleteMaterialMutation, useGetUoMDropDownQuery, useLazyGetHierarchyQuery, useLazyGetMaterialsBySearchQuery, useLazyGetMaterialsQuery, useSetAccessStateMutation } from "../../../redux/services";
import { closeBackdrop, openBackdrop } from "../../../redux/slices/backdrop";
import { closeConfirmationDialog, openConfirmationDialog, updateConfirmationDialogPositiveBtn, updateCongirmationDialogBody } from "../../../redux/slices/confirmationDialog";
import { closeDialog, openDialog } from "../../../redux/slices/dialog";
import { closeDrawer, openDrawer } from "../../../redux/slices/drawer";
import { AppRoutes } from "../../../router/routes";
import { searchLocalList } from "../../../utils";
import { DashboardTableColumn } from "../utils";
import { HasAccess } from "../../../router/authorization";
import { PERMISSIONS } from "../../../router/permission";

const useStyles = (theme: Theme) => ({
    root: {
        flexGrow: 1,
        background: theme.palette.background.paper,
        boxShadow: `rgba(99, 99, 99, 0.2) 0px 2px 8px 0px`,
        height: "100%",
        overflow: "scroll",
        borderRadius: theme.spacing(1),
        padding: theme.spacing(2),
    },
    dataGridParent: {
        height: "calc(100% - 40px)",
        overflow: "scroll",
        marginTop: theme.spacing(2),
    }
});

const dropDownOptions = [{ value: "false", label: "Latest Only" }, { value: "true", label: "All" }]

let timeoutId: NodeJS.Timeout;

export type StateManagement = "material" | "bom" | "document";

const buildTreeView = (items: any) => {
    const tree: any[] = [];

    // Helper function to find or create a node based on its level
    const findOrCreateNode = (parentNode: any, level: string) => {
        let existingNode = parentNode.children.find((node: any) => node.id === level);
        if (!existingNode) {
            existingNode = {
                id: level,
                label: items.find((item: any) => item.level === level)?.sequence_id,
                children: [],
            };
            parentNode.children.push(existingNode);
        }
        return existingNode;
    };

    // Iterate through each item in the data
    items.forEach((item: any) => {
        const { level } = item;
        const levelParts = level.split('.');

        // Start building from the root of the tree
        let parentNode = { children: tree };
        for (let i = 0; i < levelParts.length - 1; i++) {
            const currentLevel = levelParts.slice(0, i + 1).join('.');
            parentNode = findOrCreateNode(parentNode, currentLevel);
        }

        // Add the current item as a child of the parent node
        findOrCreateNode(parentNode, level);
    });

    return tree;
};

const StyledTreeItem = styled(TreeItem)(({ theme }: any) => ({
    color:
        theme.palette.mode === 'light'
            ? theme.palette.grey[800]
            : theme.palette.grey[200],

    [`& .${treeItemClasses.content}`]: {
        borderRadius: theme.spacing(0.5),
        padding: theme.spacing(0.5, 1),
        margin: theme.spacing(0.2, 0),
        [`& .${treeItemClasses.label}`]: {
            fontSize: '0.8rem',
            fontWeight: 500,
        },
    },
    [`& .${treeItemClasses.iconContainer}`]: {
        borderRadius: '50%',
        backgroundColor:
            theme.palette.mode === 'light'
                ? alpha(theme.palette.primary.main, 0.25)
                : theme.palette.primary.dark,
        color: theme.palette.mode === 'dark' && theme.palette.primary.contrastText,
        padding: theme.spacing(0, 1.2),
    },
}));

const RecursiveTreeView = ({ data }: any) => {
    const treeData = buildTreeView(data);
    return (
        <TreeView
            defaultCollapseIcon={<ExpandMore />}
            defaultExpandIcon={<ChevronRight />}
        >
            <CustomTreeView nodes={treeData} />
        </TreeView>
    );
};

const CustomTreeView = ({ nodes }: any) => (
    <React.Fragment>
        {nodes.map((node: any) => (
            <StyledTreeItem key={node.id} nodeId={node.id} label={`[${node.id}] ${node.label}`}>
                {node.children.length > 0 && <CustomTreeView nodes={node.children} />}
            </StyledTreeItem>
        ))}
    </React.Fragment>
);

export const DependencyChildren = ({ onPublish, object_id, object_type }: { onPublish: () => void; object_id: string; object_type: StateManagement }) => {
    const [getAllChildren, childrenResponse] = useLazyGetHierarchyQuery();
    const dispatch = useAppDispatch();

    React.useEffect(() => {
        getAllChildren({ object_type: object_type, object_id: object_id });
        // eslint-disable-next-line
    }, [])

    return <>
        {/* Title */}
        <DialogTitle>
            <Typography
                variant="h6"
                color="textPrimary"
                sx={{
                    borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
                    width: "100%", fontFamily: 'htrts_medium'
                }}
            >
                Publish Operation
            </Typography>
        </DialogTitle>
        {childrenResponse.isSuccess && childrenResponse?.data?.length > 0 ?
            <>
                <DialogContent>
                    <Typography variant="caption">These are the dependent child modules that will be published.</Typography>
                    <Box p={2} sx={{
                        border: (theme: Theme) => `1px solid ${theme.palette.divider}`,
                    }}>
                        <Grid container spacing={1}>
                            <RecursiveTreeView data={childrenResponse?.data} />
                        </Grid>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Stack direction="row" justifyContent={"flex-end"} spacing={1} mt={1}>
                        <Button onClick={() => {
                            dispatch(closeDialog())
                            dispatch(closeConfirmationDialog())
                        }} color="primary" variant="outlined">
                            Cancel
                        </Button>
                        <Button onClick={() => onPublish()} color="primary" variant="contained" disabled={childrenResponse?.isFetching || childrenResponse?.isLoading || childrenResponse?.isError} id="confirm">
                            Confirm
                        </Button>
                    </Stack>
                </DialogActions>
            </>
            : childrenResponse.isSuccess && (childrenResponse.data ?? []).length <= 0 ? <>
                <DialogContent>
                    <Typography gutterBottom>{"No child dependencies were found. Only this item will be published."}</Typography>
                </DialogContent>
                <DialogActions>
                    <Stack direction="row" justifyContent={"flex-end"} spacing={1} mt={1}>
                        <Button onClick={() => {
                            dispatch(closeDialog())
                            dispatch(closeConfirmationDialog())
                        }} color="primary" variant="outlined">
                            Cancel
                        </Button>
                        <Button id="confirm" onClick={() => onPublish()} color="primary" variant="contained" disabled={childrenResponse?.isFetching || childrenResponse?.isLoading || childrenResponse?.isError}>
                            Confirm
                        </Button>
                    </Stack>
                </DialogActions>
            </> : (childrenResponse.isLoading || childrenResponse.isFetching) ? <DialogContent><Stack alignItems={"center"} justifyContent={"center"} height={"100%"}><CircularProgress /></Stack></DialogContent> : <DialogContent><Alert severity="error">Oops! Something went wrong, Unable to get the child dependencies. Try Again Later!</Alert></DialogContent>}</>
}

export const PublishComponent = (props: { _id: string; sequence_id: string; object_id: string; object_type: StateManagement; refetch?: () => void; }) => {
    const dispatch = useAppDispatch();
    const [publishMaterial] = useSetAccessStateMutation();

    const [reason, setReason] = React.useState("");
    const { enqueueSnackbar } = useSnackbar();

    const publish = async (row: any) => {
        const payload = {
            access_state: "release",
            notes: `publish the ${props.object_type}`,
            object_id: props?._id,
            object_type: props.object_type
        }
        dispatch(openBackdrop(`Publishing ${props.object_type} ${row?.sequence_id}....`))
        await publishMaterial({ payload }).unwrap().then((res) => {
            if (res?._id) {
                enqueueSnackbar(res?.message ?? `${props.object_type?.toUpperCase()} published successfully!`, { variant: "success" });
                if (row.refetch) {
                    row.refetch();
                }
            }
        }).catch((error: any) => {
            console.error(`Error publishing ${props.object_type} ${row?.sequence_id}: ${error}`)
            let errorMessage: string = error?.data?.title ?? error?.title ?? `Error publishing ${props.object_type} ${row?.sequence_id}`
            enqueueSnackbar(errorMessage, { variant: "error" });
        }).finally(() => {
            dispatch(closeDialog())
            dispatch(closeBackdrop())
        })
    }

    return (
        <>
            {/* Title */}
            <DialogTitle>
                <Typography
                    variant="h6"
                    color="textPrimary"
                    sx={{
                        borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
                        width: "100%", fontFamily: 'htrts_medium'
                    }}
                >
                    Publish Operation
                </Typography>
            </DialogTitle>
                <DialogContent>
                    <Typography sx={{ mb: 1 }} variant={"body1"}>Enter the reason to publish</Typography>
                    <TextField id="publish-reason" fullWidth autoFocus value={reason} onChange={(event) => {
                        event.preventDefault();
                        setReason(event.target.value)
                    }} placeholder={"Enter the reason"} />
                </DialogContent>
                <DialogActions>
                    <Stack direction={"row"} spacing={1} justifyContent={"flex-end"} mt={1}>
                        <Button onClick={() => dispatch(closeDialog())} color="primary" variant="outlined">
                            Cancel
                        </Button>
                        <Button onClick={() => publish(props)} color="primary" variant="contained" id="publish">
                            Publish
                        </Button>
                    </Stack>
                </DialogActions>
        </>
    );
}

export const Dashboard: React.FC<{
    children?: JSX.Element, titleHeaderProps?: TitleHeaderProps, checkboxSelection?: boolean, onCheckBoxSelected?: any,
    objectType?: string, objectId?: string, onEdit?: any, useRowSelection?: boolean, rowSelectionObjectId?: string, rowSelectionObjectType?: string,
    creatable?: boolean, updatable?: boolean, deletetable?: boolean, publishable?: boolean, readable?: boolean, isNested?: boolean
}> = (props) => {

    const classes = useStyles(useTheme());
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { enqueueSnackbar } = useSnackbar();

    const uomData = useGetUoMDropDownQuery(null);
    const { perm } = useAppSelector(store => store.auth.userDetails);

    const [getMaterials, { data: allData = { data: [], totalCount: 0 }, isFetching, isLoading, isError }] = useLazyGetMaterialsQuery();
    const { data, totalCount } = allData;

    const [deleteMaterialMutation] = useDeleteMaterialMutation();
    const [getMaterialsBySearch, { data: materialsFilteredData, isFetching: materialsIsFetching, isError: filterHasError }] = useLazyGetMaterialsBySearchQuery();

    const loading = isLoading || isFetching || materialsIsFetching;

    const [uomDropDown, updateUomDropDown] = React.useState(uomData?.data ?? []);
    const [search, setSearch] = React.useState("");
    const [dropDown, updateDropDown] = React.useState<SelectBoxOption>(dropDownOptions[0]);
    const [filterValue, updateFilterDown] = React.useState<SelectBoxOption>({ value: "all", label: "All" });
    const [moreFilters, updateMoreFilers] = React.useState<FilterStateInterface>({});
    const [paginationModel, setPaginationModel] = React.useState({ page: 0, pageSize: 10 });
    const [searchByKeyword, setSearchByKeyword] = React.useState("");
    const [queryOptions, setQueryOptions] = React.useState({
        sortModel: [{ field: 'sequence_id', sort: 'desc' as GridSortDirection }],
    });

    const creatable = React.useMemo(() => {
        let primaryAccess = HasAccess(perm, PERMISSIONS.MATERIAL_CREATE);
        if (props.isNested) {
            return props.creatable && primaryAccess;
        }
        return primaryAccess;
    }, [perm, props.isNested, props.creatable]);

    const deletable = React.useMemo(() => {
        let primaryAccess = HasAccess(perm, PERMISSIONS.MATERIAL_DELETE);
        if (props.isNested) {
            return props.deletetable && primaryAccess;
        }
        return primaryAccess;
    }, [perm, props.isNested, props.deletetable]);

    const publishable = React.useMemo(() => {
        let primaryAccess = HasAccess(perm, PERMISSIONS.MATERIAL_PUBLISH);
        if (props.isNested) {
            return props.publishable && primaryAccess;
        }
        return primaryAccess;
    }, [perm, props.isNested, props.publishable]);

    const viewMaterial = (data: any, isEdit: boolean = true) => navigate(AppRoutes.viewMatBasicDetail(isEdit ? data._id : "New"));

    const deleteMaterial = (material: any) => {
        let message = `Check for dependencies before deleting the Material ${material.sequence_id}-r${material.version}`;
        dispatch(openConfirmationDialog({
            title: "Delete Operation",
            body: <DeletePanel
                message={message}
                warningMessage={""}
            />,
            positiveBtn: "Check for dependencies",
            negativeBtn: "Cancel",
            onOk: () => openDependency(material),
            onNegativeBtn: () => dispatch(closeConfirmationDialog())
        }))
    };

    const openDependency = (material: any) => {
        dispatch(updateCongirmationDialogBody(<DeletePanel
            message={material.associations ? material.associations.length === 0 ? 'No dependency found' : `Dependency Table of ${material?.sequence_id}-r${material.version}` : "No dependency found"}
            errorMessage={""}
            errorMessageStacks={material?.associations ?? []}
            warningMessage={""}
        />))

        // Updating the positive button of the confirmation panel
        dispatch(updateConfirmationDialogPositiveBtn({
            positiveBtn: "Force Delete",
            onOk: () => {
                dispatch(updateCongirmationDialogBody(<DeletePanel
                    message={"Are you sure want to force delete the Material " + material?.sequence_id + "-r" + material?.version + "?"}
                    warningMessage={"Warning: After delete its not possible to retrieve"}
                />))
                dispatch(updateConfirmationDialogPositiveBtn({
                    positiveBtn: "Ok",
                    onOk: () => performRowDel(material, "", true)
                }))
            },
        }))
    }

    const performRowDel = async (material: any, message: string, force: boolean = false) => {
        dispatch(openBackdrop("Deleting material..."));
        let res: any = {};
        try {
            res = await deleteMaterialMutation({ id: material._id, force })

            if (Object.keys(res).includes("data")) {
                enqueueSnackbar(`Deleted material Successfully!`, { variant: "success" })
                dispatch(closeConfirmationDialog());
                refetch();
            } else if (Object.keys(res).includes("error")) {
                // eslint-disable-next-line 
                throw res.error.data;
            } else {
                // eslint-disable-next-line 
                throw "Data not found"
            }
        } catch (error: any) {
            let errorMessage: string = error?.title ?? "Oops! Something went wrong, Unable to delete"
            enqueueSnackbar(errorMessage, { variant: "error" });
        } finally {
            dispatch(closeBackdrop());
        }
    }

    const giveMeRows = () => {
        if (search.trim().length) {
            let searchable = searchByKeyword ? materialsFilteredData?.data : data;
            return searchable.filter((d: any) => searchLocalList(d, search));
        } else if (!isFetching && data && Object.keys(moreFilters).length <= 0 && !searchByKeyword) {
            return data ?? [];
        } else if (!materialsIsFetching && materialsFilteredData) {
            return materialsFilteredData?.data ?? [];
        } else {
            return [];
        }
    }

    const openRevision = (data: any) => {
        if (false) {
            dispatch(openDrawer({
                title: "Material History",
                btnName: "Close",
                component: <History variant="material" document_id={data.document_id} />,
                onSave: () => dispatch(closeDrawer())
            }));
        }
    }

    const view = (data: any) => {
        if (props.onEdit) {
            props.onEdit(data._id, data.sequence_id);
        } else {
            navigate(AppRoutes.viewMatBasicDetail(data._id));
        }
    }

    const openAssociation = (material: any) => {
        dispatch(openConfirmationDialog({
            title: "Associations",
            body: <DeletePanel
                message={material?.associations ? material.associations.length === 0 ? 'No dependency found' : `Dependency Table of ${material?.sequence_id}-r${material.version}` : "No dependency found"}
                errorMessageStacks={material?.associations ?? []}
            />,
            hideNegativeBtn: true,
            hidePositiveBtn: true
        }))
    };

    const openReferences = (material: any) => {
        dispatch(openConfirmationDialog({
            title: "References",
            body: <DeletePanel
                message={material?.references ? material.references.length === 0 ? 'No references found' : `References Table of ${material?.sequence_id}-r${material.version}` : "No references found"}
                errorMessageStacks={material?.references ?? []}
            />,
            hideNegativeBtn: true,
            hidePositiveBtn: true
        }))
    }

    const showTechnicalCard = (material: any) => {
        dispatch(openConfirmationDialog({
            title: `Technical Card: ${material.sequence_id}-r${material.version}`,
            body: <Box sx={{ width: "500px" }}>
                <table>
                    <tbody>
                        <tr>
                            <td>
                                <Typography variant="body2" sx={{ fontWeight: "bold" }} gutterBottom >{`Noun Name`}</Typography>
                            </td>
                            <td>
                                <Typography variant="body2" gutterBottom >{`: ${material.noun_name}`}</Typography>
                            </td>
                        </tr>
                        {material?.attribute_details?.map((_: any) => {
                            return <tr>
                                <td>
                                    <Typography variant="body2" sx={{ fontWeight: "bold" }} gutterBottom >{`${_.attr_name}`}</Typography>
                                </td>
                                <td>
                                    <Typography variant="body2" gutterBottom >{`: ${_.option_name}`}</Typography>
                                </td>
                            </tr>
                        })}
                    </tbody>
                </table>

            </Box>,
            hideNegativeBtn: true,
            hidePositiveBtn: true
        }))
    }

    let ROWS = giveMeRows();

    const refetch = () => {
        let uomid = filterValue?.value !== "all" ? filterValue?.value : null;
        if (dropDown?.value) {
            getMaterials({
                include_histories: dropDown?.value || "all",
                object_type: props.objectType,
                object_id: props.objectId,
                unit_region_id: uomid,
                noun_id: moreFilters.noun?.value,
                sort_by: queryOptions?.sortModel?.[0]?.field,
                sort_order: queryOptions?.sortModel?.[0]?.sort,
                ...paginationModel
            })
        }
    }

    const isRowSelectable = (row: any) => {

        if (!row.associations || !props.useRowSelection) {
            return true;
        }

        let isSelectable = true;

        try {
            for (let index = 0; index < row?.associations?.length; index++) {
                const asso = row?.associations?.[index];
                isSelectable = !(asso.object_type === props.rowSelectionObjectType && asso.object_details.map((_: any) => _._id).includes(props.rowSelectionObjectId));
                if (!isSelectable) {
                    break;
                }
            }
        } catch (error) {
            console.error("error", error);
        }

        return isSelectable;
    }

    const applyFilter = (data: FilterStateInterface) => {

        updateMoreFilers({ ...moreFilters, ...data });
        dispatch(closeDrawer());
    }

    const cancelFilter = () => {
        updateMoreFilers({});
        dispatch(closeDrawer());
    }

    const onReset = () => {
        updateMoreFilers({});
        setSearchByKeyword("");
    }

    const openMoreFilter = () => {
        dispatch(openDrawer({
            isLarge: true,
            component: <Filters showNoun showNounCard searchByKeyword={searchByKeyword} appliedFilters={moreFilters} onApply={applyFilter} onCancel={cancelFilter} onFind={(val) => {
                setSearchByKeyword(val)
                dispatch(closeDrawer());
            }} onReset={onReset} />
        }))
    }

    const updatePublishStatus = (row: any) => {
        dispatch(
            openConfirmationDialog({
                title: "Publish Operation",
                body: <DeletePanel
                    isMaterial={true}
                    message={`Are you sure you want to publish the material ${row?.sequence_id}?`}
                    warningMessage={`You will not be able to edit this revision after publishing. You can however create new revisions.`}
                />,
                positiveBtn: "Check for dependencies",
                negativeBtn: "Cancel",
                onOk: () => openDependencyTable(row),
                onNegativeBtn: () => dispatch(closeConfirmationDialog())
            })
        );
    };

    const openDependencyTable = (row: any) => {
        dispatch(closeConfirmationDialog())
        try {
            dispatch(openDialog({
                title: "",
                body: <DependencyChildren object_id={row?._id} object_type="material" onPublish={() => proceedToPublish(row)} />,
                hideNegativeBtn: true, hidePositiveBtn: true, enablePadding: false
            }));
        } catch (error) {
            console.error(`Unable to get the hierarchy: ${error}`);
            enqueueSnackbar("Error getting dependencies", { variant: "error" })
            dispatch(closeDialog())
        }
    };

    const proceedToPublish = (row: any) => {
        dispatch(openDialog({
            title: "",
            body: <PublishComponent {...row} refetch={refetch} object_type="material" object_id={row?._id} />,
            hidePositiveBtn: true,
            hideNegativeBtn: true,
            enablePadding: false
        }));
    };

    const handleSortModelChange = React.useCallback((sortModel: GridSortModel) => {
        // Here you save the data you need from the sort model
        setQueryOptions({ sortModel: [...sortModel] });
      }, [])

    React.useEffect(() => {
        if (moreFilters.noun?.value) {
            getMaterialsBySearch({ 
                search: moreFilters.noun?.label, 
                include_histories: dropDown?.value,
                sort_by: queryOptions?.sortModel?.[0]?.field,
                sort_order: queryOptions?.sortModel?.[0]?.sort,
                ...paginationModel 
            })
        } else {
            refetch();
        }
        // eslint-disable-next-line
    }, [dropDown, filterValue, moreFilters, paginationModel, JSON.stringify(queryOptions?.sortModel)]);

    React.useEffect(() => {
        if (searchByKeyword) {
            getMaterialsBySearch({ 
                search: searchByKeyword, 
                include_histories: dropDown?.value,
                sort_by: queryOptions?.sortModel?.[0]?.field,
                sort_order: queryOptions?.sortModel?.[0]?.sort,
                ...paginationModel 
            })
        } else {
            refetch();
        }
        // eslint-disable-next-line
    }, [dropDown, filterValue, searchByKeyword, paginationModel, JSON.stringify(queryOptions?.sortModel)]);

    React.useEffect(() => {
        setSearch("");
    }, [paginationModel]);

    React.useEffect(() => {
        if (searchByKeyword) {
            updateMoreFilers({});
        }
    }, [searchByKeyword]);

    React.useEffect(() => {
        if (Object.keys(moreFilters).length > 0) {
            setSearchByKeyword("");
        }
    }, [moreFilters]);

    React.useEffect(() => {
        if (uomData.isSuccess) {
            let updatedUomData = copy(uomData.data);
            let allSelec = { label: "All", uom_desc: "", uom_name: "", value: "all", _id: "" }
            updatedUomData.push(allSelec)
            updateUomDropDown(updatedUomData)
        }
        // eslint-disable-next-line
    }, [uomData.isSuccess]);

    React.useEffect(() => {
        // Clean up the timeout on component unmount
        return () => clearTimeout(timeoutId);
        // eslint-disable-next-line
    }, [timeoutId]);

    return <Box sx={classes.root}>
        {/* Header */}
        <TitleHeader
            title="Materials"
            count={ROWS?.length ?? 0}
            showSearch={true}
            search={search}
            onSearchChange={setSearch}
            searchPlaceholder={"Search for Materials (only within this page)"}
            showCreateBtn={creatable}
            createBtnLabel={"Create Material"}
            onCreateBtn={() => viewMaterial(null, false)}
            showDropDown={true}
            onDropDownChange={(data: SelectBoxOption) => updateDropDown(data)}
            dropDownLabel="Version"
            dropDownOptions={dropDownOptions}
            dropDownValue={dropDown}
            showFilter={true}
            onFilterChange={(data: SelectBoxOption) => updateFilterDown(data)}
            filterLabel="Region"
            filterOptions={uomDropDown}
            filterValue={filterValue}
            showMoreFilter={true}
            showFilterSelect={false}
            onMoreFilterClick={openMoreFilter}
            moreFilterApplied={Object.values(moreFilters).length > 0 || searchByKeyword.length > 0}
            {...props.titleHeaderProps}
        />

        {/* Error */}
        {(isError || filterHasError) && <Alert sx={{ mt: 2 }} severity="error">Oops! Something went wrong, Unable to fetch Materials. Try Again Later!</Alert>}

        {/* Data Drid */}
        <Box mt={2}>
            <CustomDataGrid
                id={`$${props.objectType ? `${props.objectType}-` : ""}material-list`}
                initialState={{
                    // sorting: {
                    //     sortModel: [{ field: 'sequence_id', sort: 'desc' }],
                    // },
                    columns: {
                        columnVisibilityModel: {
                            // Hide columns lead_time_days and sourcing_type, the other columns will remain visible
                            lead_time_days: false,
                            cost_category: false,
                            "money.currency": false,
                            "money.cost": false,
                        },
                    },
                }}
                rows={ROWS}
                columns={DashboardTableColumn({ onDelete: deleteMaterial, openRevision, view, openAssociation, showTechnicalCard, updatePublishStatus, removeDelete: !deletable, openReferences, publishable })}
                checkboxSelection={props.checkboxSelection}
                onRowSelectionModelChange={(selected) => {
                    props.onCheckBoxSelected(selected)
                }}
                isRowSelectable={(params: GridRowParams) => isRowSelectable(params.row)}
                loading={loading}
                getRowId={(row) => row._id}
                showToolbar={true}
                rowCount={(searchByKeyword || Object.keys(moreFilters).length > 0) ? materialsFilteredData?.totalCount : totalCount}
                paginationModel={paginationModel}
                paginationMode="server"
                onPaginationModelChange={setPaginationModel}
                sortingMode="server"
                onSortModelChange={handleSortModelChange}
            />
        </Box>
    </Box>
}