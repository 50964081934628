import { Add, CheckCircle, FormatListNumbered } from "@mui/icons-material";
import { TreeView } from "@mui/lab";
import { Box, LinearProgress } from "@mui/material";
import { useSnackbar } from "notistack";
import React from "react";
import { PublishedIcon } from "../../images/svg/publishedIcon";
import { WIPIcon } from "../../images/svg/WIPIcon";
import { useAppDispatch } from "../../redux";
import { AssignmentStatus, useLazyGetBOMTreeByIdQuery, useLinkChildsMutation, useUpdateAssignmentMutation } from "../../redux/services";
import { closeBackdrop, openBackdrop } from "../../redux/slices/backdrop";
import { closeDialog, openDialog } from "../../redux/slices/dialog";
import { LinkDetail } from "../../screens";
import { LinkChildBOM } from "../../screens/viewBOMTree/linkBOM";
import { LinkChildMaterial } from "../../screens/viewBOMTree/linkMaterial";
import { Assign } from "../assign";
import { CustomTreeChips, CustomTreeItemV2, CustomTreeRightClickOpt } from "../customTreeItemV2";
import { UnAssign } from "../unAssign";
import { BOMChild } from "../../interfaces/bom";

export const giveMeChips = (bom: any): CustomTreeChips[] => {
    let chips: CustomTreeChips[] = [
        {
            title: `${bom?.short_description ?? "-"}`,
            color: 'default'
        },
        {
            title: `${bom?.serial_num ?? "-"}`,
            color: 'info'
        }
    ]

    if (!bom.serial_num) {
        chips.splice(1, 1);
    }

    return chips;
}

export const BOMTree: React.FC<{
    bomId: string,
    reload?: any,
    itemSelected?: { curItem: BOMChild | null, parentBOM: BOMChild | null, },
    toggleReload?: () => any,
    onItemSelected?: (item: { curItem: any, parentBOM: any, }) => any,
    otherRightClickOptions?: (bom: any) => CustomTreeRightClickOpt[],
    onSerialNumberChanged?: (type: AssignmentStatus) => any,
    defaultSelected?: string,
    defaultSelectType?: 'bom' | 'material'
}> = ({
    bomId,
    reload,
    itemSelected,
    toggleReload = () => false,
    otherRightClickOptions = () => [],
    onItemSelected = () => false,
    onSerialNumberChanged,
    defaultSelected = "",
    defaultSelectType = 'bom'
}) => {

        const dispatch = useAppDispatch();
        const { enqueueSnackbar } = useSnackbar();
        const [updateBOM] = useLinkChildsMutation();
        const [getBOMTree, getBOMTreeData] = useLazyGetBOMTreeByIdQuery();
        const [assignSequenceNumberQuery] = useUpdateAssignmentMutation({});

        const [expanded, setExpanded] = React.useState<string[]>([]);
        const [selected, setSelected] = React.useState<string>(defaultSelected);
        const [currentItem, setCurrentItem] = React.useState<any | null>(null);

        const handleToggle = (event: React.SyntheticEvent, nodeIds: string[]) => setExpanded(nodeIds);

        const handleSelect = (event: React.SyntheticEvent, nodeIds: string) => setSelected(nodeIds);

        const handleClickOpen = (type: "none" | "bom" | "material" = "none", id?: string) => {
            if (type === "bom") {
                dispatch(
                    openDialog({
                        title: "",
                        hideNegativeBtn: true,
                        hidePositiveBtn: true,
                        maxWidth: "md",
                        hideCloseIcon: true,
                        enablePadding: false,
                        body: (
                            <LinkChildBOM
                                id={id}
                                associations={[]}
                                callLinkDocument={(linkIds, id: string) =>
                                    linkChilds(linkIds as LinkDetail[], "bom", id)
                                }
                            />
                        ),
                    })
                );
            } else if (type === "material") {
                dispatch(
                    openDialog({
                        title: "",
                        hideNegativeBtn: true,
                        hidePositiveBtn: true,
                        maxWidth: "md",
                        hideCloseIcon: true,
                        enablePadding: false,
                        body: (
                            <LinkChildMaterial
                                id={id}
                                showAllTabs={true}
                                associations={[]}
                                callLinkDocument={(linkIds, id: string) => {
                                    linkChilds(linkIds as LinkDetail[], "material", id)
                                }
                                }
                            />
                        ),
                    })
                );
            }
        };

        const linkChilds = async (linkIds: LinkDetail[], type: "bom" | "material", id: string) => {
            let payload: {
                bom_children_link?: any[];
                materials_link?: any[];
                currency_code: string;
                reference_id?: string;
                short_description?: string;
            } = {
                bom_children_link: [],
                materials_link: [],
                currency_code: currentItem?.bom?.currency ?? "USD",
                reference_id: currentItem?.bom?.reference_id ?? "",
                short_description: currentItem?.bom?.short_description,
            };
            if (type === "bom") {
                let count = currentItem?.childs?.filter((_: any) => _.type === "bom")?.length ?? 0;
                payload.bom_children_link = linkIds.map((_) => {
                    count = count + 1;
                    return {
                        _id: _?._id,
                        position: count,
                        normal_quantity: _?.qty ?? 1,
                        cost: 0,
                        currency: "USD",
                        spare_part: false,
                        spare_part_quantity: 0,
                    };
                });
            } else if (type === "material") {
                let count = currentItem?.childs?.filter((_: any) => _.type === "material")?.length ?? 0;
                payload.materials_link = linkIds.map((_) => {
                    count = count + 1;
                    return {
                        _id: _?._id,
                        position: count,
                        normal_quantity: _?.qty ?? 1,
                        cost: 0,
                        currency: "USD",
                        spare_part: false,
                        spare_part_quantity: 0,
                    };
                });
            }

            dispatch(openBackdrop('Linking....'));
            await updateBOM({ id, payload, update_version: false }).unwrap().catch((error) => {
                enqueueSnackbar(`Linking Failed: ${error?.data?.title ?? "Something went wrong, Unable to"}`, { variant: "error" })
                console.error(`Error while linking children ${JSON.stringify(error, null, 2)}`)
            });
            fetchBOMTree(bomId);
            dispatch(closeBackdrop());
        };

        const onItemClick = (bom: any, parentBOM?: any) => {
            let curItem = { ...bom };
            if (bom.type === "material") {
                setCurrentItem(parentBOM);
                document.getElementsByClassName(`${bom?.child_id || bom?._id}`)?.[0]?.scrollIntoView({
                    behavior: "smooth",
                    block: "center",
                    inline: "start"
                })
            } else {
                setCurrentItem(curItem);
                document.getElementsByClassName(`${bom?.child_id || bom?._id}`)?.[0]?.scrollIntoView({
                    behavior: "smooth",
                    block: "center",
                    inline: "start"
                })
            }
            setExpanded((oldExpanded) => [...oldExpanded, ([]).join("-")]);
            onItemSelected({ curItem, parentBOM });
        };

        const generateSerialNumbersForTemplate = async (id: string | null, serial_num: string, type: AssignmentStatus = "assign", bom: any, parentBOM: any, isManual?: boolean) => {
            dispatch(openBackdrop('Assigning Serial Number....'));
            let object_type = `${bom?.type}`;
            let object_id = bom?._id ?? "";
            let child_id = bom?.child_id ?? "";

            const selectedChild: {
                object_type: string,
                object_id: string,
                child_id: string,
                serial_num?: string
            } = { object_type, object_id, child_id };

            if (serial_num) {
                selectedChild.serial_num = serial_num;
            }

            await assignSequenceNumberQuery({
                id: id,
                status: type,
                manual_serial_num: isManual,
                payload: [
                    {
                        object_type: parentBOM?.type,
                        object_id: parentBOM?._id,
                        children: [selectedChild],
                    },
                ],
            }).then(async (res: any) => {
                if (res.error) {
                    enqueueSnackbar(`Something went wrong unable to ${type === 'assign' ? "assign" : "un-assign"} serial number. Info: ${res?.error?.data?.title ?? "-"}`, { variant: "error" })
                } else {
                    enqueueSnackbar(`${type === 'assign' ? "Assigned" : "Un Assigned"} Serial Number`, { variant: "success" });
                    dispatch(closeDialog());
                    if (onSerialNumberChanged) {
                        onSerialNumberChanged(type);
                    }
                    fetchBOMTree(bomId);
                }
            }).catch((err: any) => {
                console.log('err:', err)
                enqueueSnackbar('Something went wrong unable to assign serial number', { variant: "error" });
            });
            dispatch(closeBackdrop());
        };

        const handleAssignClick = (bom: any, parentBOM: any) => {
            dispatch(
                openDialog({
                    title: "",
                    hideNegativeBtn: true,
                    hidePositiveBtn: true,
                    enablePadding: false,
                    body: <Assign onAssign={(id?: string | null, serial_num?: string, isManual?: boolean, type: AssignmentStatus = "assign") => generateSerialNumbersForTemplate(id ?? "", serial_num ?? "", type, bom, parentBOM, isManual)} />,
                })
            );
        };

        const onUnAssignClick = (bom: any, parentBOM: any) => {
            dispatch(
                openDialog({
                    title: "",
                    hideNegativeBtn: true,
                    hidePositiveBtn: true,
                    body: (
                        <UnAssign
                            onCancel={() => dispatch(closeDialog())}
                            onUnassign={() => generateSerialNumbersForTemplate(null, bom?.serial_num ?? "", "unassign", bom, parentBOM)}
                        />
                    ),
                })
            );
        };

        const giveMeTreeItems = (bom: any, level: number, type: "bom" | "material", parentBOM: any) => {
            return (
                <CustomTreeItemV2
                    key={bom._id}
                    id={bom.child_id}
                    nodeId={bom.child_id}
                    isExpand={expanded.includes(bom.child_id)}
                    customTitle={`${bom.sequence_id}-r${bom.version}`}
                    tooltip={`${bom?.[type]?.short_description}${bom.serial_num ? " -" + bom.serial_num : ""} - ${bom?.[type]?.released ? "Published" : "Not Published"} - ${bom.symbol_assigned ? 'Assigned' : 'Not Assigned'}`}
                    selected={selected}
                    onClick={() => onItemClick(bom, parentBOM)}
                    rightClickOptions={[
                        ...giveMeRightClickOption(bom?._id?.toString(), bom?._id?.toString(), level === 9, type === 'material'),
                        {
                            name: `${bom.serial_num ? "Un Assign" : "Assign"} Tag No.`,
                            showIcon: true,
                            icon: <FormatListNumbered fontSize="small" />,
                            onClick: () => {
                                if (bom.serial_num) {
                                    onUnAssignClick(bom, parentBOM);
                                } else {
                                    handleAssignClick(bom, parentBOM);
                                }
                            },
                        },
                        ...otherRightClickOptions(bom)
                    ]}
                    hideExpandCollapseIcon={type === "material" ? true : false}
                    showIcons={true}
                    icons={<>
                        {bom?.[type]?.released && <div><PublishedIcon sx={{ width: 30, height: "100%" }} /></div>}
                        {!bom?.[type]?.released && <div><WIPIcon sx={{ width: 30, height: "100%" }} /></div>}
                        {bom.symbol_assigned && <div><CheckCircle color={'success'} sx={{ fontSize: "20px" }} /></div>}
                    </>}
                    showChips={true}
                    chips={giveMeChips({ ...bom, ...bom?.[type] })}
                >
                    {bom?.bom?.bom_children?.map((child: any, _index: number) => {
                        return giveMeTreeItems({ type: "bom", ...child }, level + 1, "bom", bom);
                    })}
                    {bom?.bom?.bom_materials?.map((child: any, _index: number) => {
                        return giveMeTreeItems({ type: "material", ...child }, level + 1, "material", bom);
                    })}
                </CustomTreeItemV2>
            );
        };

        const giveMeRightClickOption = (parentBOMId?: string, parentMaterialId?: string, hideLinkBom?: boolean, isMaterial?: boolean): CustomTreeRightClickOpt[] => {

            if (isMaterial) {
                return [];
            }

            let menu = [
                {
                    name: "Create/Link BOM",
                    showIcon: true,
                    icon: <Add fontSize="small" />,
                    onClick: () => handleClickOpen("bom", parentBOMId),
                },
                {
                    name: "Create/Link Material",
                    showIcon: true,
                    icon: <Add fontSize="small" />,
                    onClick: () => handleClickOpen("material", parentMaterialId),
                },
            ];

            if (hideLinkBom) {
                menu.shift();
            }

            return menu;
        };

        const findChildWithParents = (root: any, targetChildId: string, parents: string[] = []): string[] | null => {
            // Check if the current item is the target
            if (root.child_id === targetChildId) {
                return [...parents, root];
            }

            // Check in bom_materials
            if (root.bom_materials) {
                for (const material of root.bom_materials) {
                    if (material.child_id === targetChildId) {
                        return [...parents, root];
                    }
                }
            }

            // Recursively search in bom_children
            if (root.bom_children) {
                for (const child of root.bom_children) {
                    const result = findChildWithParents({ ...child.bom, ...child }, targetChildId, [...parents, root]);
                    if (result) {
                        return result;
                    }
                }
            }

            return null;
        }

        const fetchBOMTree = async (bomId: string, isSelectDefauult?: boolean) => {
            const { data } = await getBOMTree({ id: bomId });

            if (isSelectDefauult) {
                expandAndSelectTheGivenChild(data);
            }
        };

        const expandAndSelectTheGivenChild = (data: any) => {
            if (defaultSelected && data) {
                let newExpanded = findChildWithParents({ ...data, child_id: data._id }, defaultSelected)?.map((_: any) => _.child_id) ?? [];

                if (newExpanded) {
                    setExpanded(newExpanded);
                    setSelected(defaultSelected);
                }
            } else {
                setSelected('');
            }
        }

        React.useEffect(() => {
            if (reload && itemSelected && itemSelected.curItem) {
                fetchBOMTree(bomId);
                toggleReload();
            }
            // eslint-disable-next-line
        }, [reload])

        React.useEffect(() => {
            if (bomId) {
                fetchBOMTree(bomId, true);
            }
            // eslint-disable-next-line
        }, [bomId])

        React.useEffect(() => {
            expandAndSelectTheGivenChild(getBOMTreeData.data);
            // eslint-disable-next-line
        }, [defaultSelected])

        return <Box>
            {(getBOMTreeData.isLoading || getBOMTreeData.isFetching) && <LinearProgress />}
            {getBOMTreeData?.data && <TreeView
                defaultCollapseIcon={null}
                defaultExpandIcon={null}
                defaultEndIcon={null}
                expanded={expanded}
                selected={selected}
                onNodeToggle={handleToggle}
                onNodeSelect={handleSelect}
            >
                {[getBOMTreeData?.data ?? {}].map((bom: any, index: number) => {
                    return (
                        <CustomTreeItemV2
                            id={bom._id}
                            key={bom._id}
                            nodeId={bom._id}
                            isExpand={expanded.includes(`${bom._id}`)}
                            customTitle={`${bom?.sequence_id ?? "-"}-r${bom.version}`}
                            tooltip={`${bom.short_description}${bom.serial_num ? " -" + bom.serial_num : ""} - ${bom.released ? "Published" : "Not Published"}`}
                            selected={selected}
                            onClick={() => onItemClick({ level: 1, ...bom, type: "bom" }, {level: 0, ...bom, type: "bom"})}
                            rightClickOptions={giveMeRightClickOption(bom._id.toString(), bom._id.toString())}
                            showIcons={true}
                            icons={<>
                                {bom.released && <div><PublishedIcon sx={{ width: 30, height: "100%" }} /></div>}
                                {!bom.released && <div><WIPIcon sx={{ width: 30, height: "100%" }} /></div>}
                            </>}
                            showChips={true}
                            chips={giveMeChips(bom)}
                        >
                            {bom.bom_children.map((child: any, _index: number) => {
                                return giveMeTreeItems({ type: "bom", level: 2, ...child }, 2, "bom", { type: "bom", level: 2, ...bom });
                            })}
                            {bom.bom_materials.map((child: any, _index: number) => {
                                return giveMeTreeItems({ type: "material", level: 2, ...child }, 2, "material", { type: "bom", level: 2, ...bom });
                            })}
                        </CustomTreeItemV2>
                    );
                })}
            </TreeView>}
        </Box>
    }