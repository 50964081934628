import { createApi, fetchBaseQuery, retry } from '@reduxjs/toolkit/query/react';
import { config } from '../../../config';
import { ConvertToReactSelect, getTransformedDataForPagination, LocalStorageKeys } from '../../../utils';

export const productFamilyAPI = createApi({
    reducerPath: "productFamilyAPI",
    baseQuery: retry(fetchBaseQuery({
        baseUrl: config.api_url + "prodm/repo/family",
        prepareHeaders: (headers, { getState }) => {
            const token = localStorage.getItem(LocalStorageKeys.authToken);

            // If we have a token set in state, let's assume that we should be passing it.
            if (token) {
                headers.set('authorization', `Bearer ${token}`)
            }

            return headers
        },
    }), { maxRetries: 2 }),
    tagTypes: ['Product Family'],
    endpoints: (builder) => ({
        // Get Product Family API
        getProductFamily: builder.query({
            query: ({ page = 0, pageSize = 10, sort_by = null, sort_order = null }) => ({ url: `${page !== null ? `?page_no=${page + 1}` : ''}${pageSize ? `&page_size=${pageSize}` : ''}${sort_by ? `&sort_by=${sort_by}` : ''}${sort_order ? `&sort_order=${sort_order}` : ''}`, method: "GET" }),
            transformResponse: (data: any, meta: { request: Request; response?: Response }) => {
                return getTransformedDataForPagination(data, meta?.response);
            },
        }),

        // Create Product Family API
        createProductFamily: builder.mutation({
            query: (payload) => ({ method: "POST", body: payload })
        }),

        // Update Product Family API
        updateProductFamily: builder.mutation({
            query: (payload) => ({ url: `/${payload._id}`, method: "PUT", body: payload })
        }),

        // Delete Product Family API
        deleteProductFamily: builder.mutation({
            query: ({ id }) => ({ url: `${id}`, method: "DELETE" })
        }),

        getProductFamilyDropDown: builder.query({
            query: (payload) => ({ method: "GET" }),
            transformResponse: (response: any[], meta, arg) => ConvertToReactSelect(response, "_id", "family_name"),
        }),

    })
})

// Export hooks for usage in functional components
export const { useGetProductFamilyQuery, useLazyGetProductFamilyQuery, useGetProductFamilyDropDownQuery, useCreateProductFamilyMutation, useUpdateProductFamilyMutation, useDeleteProductFamilyMutation } = productFamilyAPI
