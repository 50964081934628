import { LoadingButton } from "@mui/lab";
import { Alert, Stack } from "@mui/material";
import { useSnackbar } from "notistack";
import React from "react";
import { HighlightSelectBox, TextField } from "../../../components";
import { SelectBoxOption } from "../../../interfaces";
import { useAppDispatch } from "../../../redux";
import { useCreateDataTableMutation, useGetCheckListTagsQuery, useLazyGetDataTableByIdQuery, useParseFromDocumentMutation } from "../../../redux/services";
import { closeBackdrop, openBackdrop } from "../../../redux/slices/backdrop";
import { ConvertToReactSelect } from "../../../utils";
import { closeDialog } from "../../../redux/slices/dialog";
import { AutoAwesome } from "@mui/icons-material";

interface IState {
    document: SelectBoxOption | null, datasheet_name: string, tags_info: SelectBoxOption[], instruction?: string
}

export const ImportDSFromDoc: React.FC<{ associations?: any[], onSuccessfullyFormCreation: any }> = ({ associations, onSuccessfullyFormCreation }) => {

    const dispatch = useAppDispatch();
    const { enqueueSnackbar } = useSnackbar();

    const tagsData = useGetCheckListTagsQuery(null);
    const [createForm] = useCreateDataTableMutation();
    const [parseDocument, parseDocumentData] = useParseFromDocumentMutation();
    const [getDataTableById] = useLazyGetDataTableByIdQuery({ refetchOnFocus: true, refetchOnReconnect: true })

    const [state, setState] = React.useState<IState>({ document: null, datasheet_name: "", tags_info: [], instruction: "" })
    const [error, setError] = React.useState<{ document: boolean, datasheet_name: boolean, tags_info: boolean }>({ document: false, datasheet_name: false, tags_info: false });

    const updateState = (key: keyof IState, value: SelectBoxOption | string | SelectBoxOption[] | null) => {
        setState({ ...state, [key]: value });
        setError({ ...error, [key]: value ? false : true });
    }

    const documentToParse: SelectBoxOption[] = React.useMemo(() => {
        if (associations) {
            let associatedDocuments = associations?.filter(_ => _.object_type === 'document')?.[0]?.object_details ?? [];

            if (associatedDocuments.length) {
                return ConvertToReactSelect(associatedDocuments, '_id', 'sequence_id', ['version'], 'r')
            }

            return []
        } else {
            return []
        }
    }, [associations])

    const createFormForDS = async (selectedTemplate: any) => {

        dispatch(openBackdrop("Creating Form..."));
        await createForm({
            dtTemplateType: "datasheet",
            dtInstanceType: "form",
            dtTemplateId: selectedTemplate?._id,
            payload: {
                name: selectedTemplate?.name,
                tables: selectedTemplate?.tables?.map((table: any, index: number) => {
                    return {
                        name: table.name,
                        description: table.description,
                        position: index,
                        rows: table?.rows ?? [],
                        header_color: table?.header_color,
                        columns: table?.columns?.filter((col: any) => col.field)?.map((col: any) => {
                            return {
                                ...col,
                                header_class_name: col.header_class_name,
                                header_name: col.header_name,
                                editable: col?.editable ? true : false
                            };
                        }) ?? [],
                    }
                }) ?? [],
            } as any
        }).unwrap().then(async (res) => {
            if (!res.title) {
                onSuccessfullyFormCreation(res)
                dispatch(closeDialog())
            }
        }).catch((error: any) => {
            enqueueSnackbar(
                error?.data?.title || "Something went wrong unable to create form",
                { variant: "error" }
            );
        }).finally(() => {
            dispatch(closeBackdrop());
        })
    }

    const onParseDocument = async () => {
        if (Object.values(error).includes(true)) {
            enqueueSnackbar('Please fill mandatory fields', { variant: 'warning' })
        } else {
            dispatch(openBackdrop('Processing....'));
            await parseDocument({
                document_id: `${state.document?.value}`,
                datasheet_name: state.datasheet_name,
                tags_info: state?.tags_info?.map(_ => ({ tag_id: `${_.value}`, tag_group_id: `${_.tag_group_id}` })) ?? [],
                instruction: state.instruction
            }).then(async (res: any) => {
                if (res.data) {
                    enqueueSnackbar(`Successfully Created a DataSheet Template and a form is created and linked`, { variant: "success" })
                    const selectedTemplate = await getDataTableById({ dtInstanceType: "template", dtTemplateType: "datasheet", dtTemplateId: res.data._id }).unwrap()
                    createFormForDS(selectedTemplate);
                    dispatch(closeDialog())
                } else {
                    enqueueSnackbar('Something went wrong, Unable to procced', { variant: "error" })
                }
            }).catch(err => {
                enqueueSnackbar('Something went wrong, Unable to procced', { variant: "error" })
            })
            dispatch(closeBackdrop());
        }
    }

    return <Stack gap={2}>
        <HighlightSelectBox
            required
            label={"Document"}
            margin={"none"}
            size="small"
            value={state?.document}
            options={documentToParse ? documentToParse : []}
            onChange={(data: SelectBoxOption) => updateState('document', data)}
            error={error.document}
            helperText={error.document ? "Please select a document" : ""}
        />

        <TextField
            required
            size="small"
            label={"Datasheet Name"}
            value={state?.datasheet_name}
            onChange={(e) => updateState('datasheet_name', e.target.value)}
            error={error.datasheet_name}
            helperText={error.datasheet_name ? "Please enter datasheet name" : ""}
        />

        <HighlightSelectBox
            id="datasheet-tags"
            label={"Tags"}
            margin={"none"}
            required={true}
            size="small"
            value={state.tags_info}
            multiple={true}
            loading={tagsData?.isLoading || tagsData?.isFetching}
            error={error.tags_info}
            helperText={error.tags_info ? "Please fill the Tags" : ""}
            options={tagsData?.data?.checkListTags ?? []}
            onChange={(data: SelectBoxOption[]) => updateState("tags_info", data)}
        />

        <TextField
            size="small"
            multiline={true}
            rows={5}
            label={<>
                Instruction
                <AutoAwesome fontSize="small" sx={{ padding: "2px", marginBottom: "-4px", marginLeft: "2px" }} />
            </>}
            value={state?.instruction}
            onChange={(e) => updateState('instruction', e.target.value)}
        />

        <Alert severity="info">
            Info: A New Datasheet is created from the above document and a form will be created and linked.
        </Alert>

        {parseDocumentData.isError && <Alert severity="warning">
            Something went wrong, Unable to proceed
        </Alert>}

        <LoadingButton variant="contained" loading={parseDocumentData.isLoading} onClick={onParseDocument}>
            Parse Document
        </LoadingButton>
    </Stack>
}