import { GridColDef } from "@mui/x-data-grid-premium";

export const BOMCostSplitUpTree = (): GridColDef[] => ([
    {
        field: 'short_description',
        width: 200,
        headerName: 'Description',
    },
    {
        field: 'total_assm_procs_cost',
        headerName: 'Total Asembly Process Cost',
        width: 200
    },
    {
        field: 'total_child_bom_cost',
        headerName: 'Total Child BOM Cost',
        width: 200
    },
    {
        field: 'total_child_material_cost',
        headerName: 'Total Child Material Cost',
        width: 200
    },
    {
        field: 'total_bom_cost',
        headerName: 'Total BOM Cost',
        width: 200
    },
    {
        field: 'material_cost',
        headerName: 'Material Cost',
        width: 150
    },
    {
        field: 'override_cost',
        headerName: 'Override Cost',
        width: 150
    },
    {
        field: 'spare_part_quantity',
        headerName: 'Spare Part Qty',
        width: 150
    },
    {
        field: 'normal_quantity',
        headerName: 'Normal Qty',
        width: 150
    },
    {
        field: 'total_cost',
        headerName: 'Total Cost',
        width: 150
    },
]);