import { createApi, fetchBaseQuery, retry } from '@reduxjs/toolkit/query/react';
import Axios from "axios";
import { config } from '../../../config';
import { LocalStorageKeys, customBtoa, getTransformedDataForPagination, ConvertToReactSelect, objectToQueryParams } from '../../../utils';
import { ProjectSliceInterface, linksData } from '../../slices/project';


const prepareProjectBody = (payload: ProjectSliceInterface, linkedData?: linksData) => {
    let body: any = {
        "customer_name": payload.basic_details.customer,
        "description": payload.basic_details.description,
        "project_manager": payload.basic_details.projectManager,
        "project_name": payload.basic_details.project_name,
        "tags_info": payload.project_tags.tags.map((tag) => {
            return {
                "tag_group_id": tag.tag_group_id,
                "tag_id": tag.value
            }
        }),
        "links": linkedData?.links ?? payload?.links_data?.links,
        "unlinks": linkedData?.unlinks ?? payload?.links_data?.unlinks,
        "notes": customBtoa(payload?.notes ?? ""),
        "cost_center": payload.basic_details.cost_center,
        "reference_number": payload.basic_details.reference_number
    }
    if (payload.basic_details.start_date) body.start_date = payload.basic_details.start_date;
    if (payload.basic_details.finish_date) body.finish_date = payload.basic_details.finish_date;
    if (payload.basic_details.type?.tag_group_id && payload.basic_details.type?.value) body.tags_info.push({
        "tag_group_id": payload.basic_details.type?.tag_group_id ?? "",
        "tag_id": payload.basic_details.type?.value ?? ""
    });
    if (payload.basic_details.businessUnit?.tag_group_id && payload.basic_details.businessUnit?.value) body.tags_info.push({
        "tag_group_id": payload.basic_details.businessUnit?.tag_group_id ?? "",
        "tag_id": payload.basic_details.businessUnit?.value ?? ""
    });
    if (payload.basic_details.status?.value && payload.basic_details.status?.tag_group_id) body.tags_info.push({
        "tag_group_id": payload.basic_details.status?.tag_group_id ?? "",
        "tag_id": payload.basic_details.status?.value ?? ""
    });
    return body;
}

export const projectAPI = createApi({
    reducerPath: "projectAPI",
    baseQuery: retry(fetchBaseQuery({
        baseUrl: config.api_url + "projm/project",
        prepareHeaders: (headers, { getState }) => {
            const token = localStorage.getItem(LocalStorageKeys.authToken);

            // If we have a token set in state, let's assume that we should be passing it.
            if (token) {
                headers.set('authorization', `Bearer ${token}`)
            }

            return headers
        },
    }), { maxRetries: 1 }),
    tagTypes: ['Projects'],
    endpoints: (builder) => ({
        // Get Projects API
        getProjects: builder.query({
            query: ({ page = 0, pageSize = 10, sort_by = null, sort_order = null }) => ({
                url: `${page !== null ? `?page_no=${page + 1}` : ''}${pageSize ? `&page_size=${pageSize}` : ''}${sort_by ? `&sort_by=${sort_by}` : ''}${sort_order ? `&sort_order=${sort_order}` : ''}`,
                method: "GET"
            }),
            transformResponse: (data: any, meta: { request: Request; response?: Response }) => {
                return getTransformedDataForPagination(data, meta?.response);
            },
            providesTags: ['Projects']
        }),

        // Get Projects as Options API
        getProjectsAsDD: builder.query({
            query: (payload: { page_no?: number, page_size?: number }) => ({
                url: `${objectToQueryParams(payload)}`,
                method: "GET"
            }),
            transformResponse: (response: any[], meta, arg) => ConvertToReactSelect(response, "_id", "sequence_id", ["project_name"]),
        }),

        // Get Project by project id API
        getProjectById: builder.query({
            query: ({ id }) => ({ url: `/${id}`, method: "GET" }),
        }),

        // Create Project API
        createProject: builder.mutation({
            query: (payload: ProjectSliceInterface) => ({ method: "POST", body: { ...prepareProjectBody(payload) } }),
            invalidatesTags: ['Projects']
        }),

        // Update Project API
        updateProject: builder.mutation({
            query: ({ id, payload, update_version }: { id: string, update_version: boolean, payload: ProjectSliceInterface }) => {
                return { url: `/${id}?update_version=${false}`, method: "PUT", body: { ...prepareProjectBody(payload) } }
            },
            invalidatesTags: ['Projects']
        }),

        // Delete Project API
        deleteProject: builder.mutation({
            query: ({ id, force }) => ({ url: `/${id}?force=${force}`, method: "DELETE" }),
            invalidatesTags: ['Projects']
        }),

        //Get Dashboard Data
        getProjectDashboardData: builder.query({
            query: ({ year = '' }) => ({ url: `/dashboard${year.length ? `?year=${year}` : ''}`, method: "GET" })
        }),

        // Project Image Upload
        uploadProjectImage: builder.mutation({
            async queryFn(_arg, _queryApi, _extraOptions) {
                const { data, url, file } = _arg;
                let result: any = {};

                try {
                    let resData = null;
                    if (file) {
                        resData = await Axios({
                            url,
                            method: "put",
                            headers: { "Content-Type": file.type },
                            data: file,
                        });
                    }
                    result = { data: { resData, document: data } };
                } catch (err) {
                    result = { error: err };
                } finally {
                    return result;
                }
            },
            invalidatesTags: ['Projects']
        }),
    })
})

// Export hooks for usage in functional components
export const { useGetProjectsQuery, useLazyGetProjectsQuery, useGetProjectByIdQuery, useLazyGetProjectByIdQuery, useCreateProjectMutation, useUpdateProjectMutation, useDeleteProjectMutation, useLazyGetProjectDashboardDataQuery, useUploadProjectImageMutation, useLazyGetProjectsAsDDQuery } = projectAPI
