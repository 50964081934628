import { List } from "@mui/icons-material";
import { Box } from "@mui/material";
import React from "react";
import { Layout } from "../../components";
import { withNavBars } from "../../HOCs";
import { AppRoutes } from "../../router/routes";
import { Dashboard } from "./dashboard";
import { useAppDispatch } from "../../redux";
import { closeBackdrop } from "../../redux/slices/backdrop";
import { reset } from "../../redux/slices/bom";
import { useNavigate } from "react-router-dom";

const BOM: React.FC<{ children?: JSX.Element }> = (props) => {

    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    React.useEffect(() => {
        dispatch(closeBackdrop());
        dispatch(reset());
        // eslint-disable-next-line
    }, [])

    return <Box sx={{ padding: 0.5, height: "100%" }}>
        {/* Breadscrum */}
        <Layout
            history={[
                { label: "Materials Module", onClick: () => navigate(AppRoutes.bom) },
            ]}
            currentPath={"BOMs"}
            hideNavBarByDefault={true}
            navBars={[{
                id: "1",
                title: "BOMs",
                icon: <List fontSize="small" />,
                path: AppRoutes.bom
            },]}
            mainPanel={<Dashboard />}
            sideNavVariant={"whiteboard"}
        />
    </Box>
}

export default withNavBars(BOM);