import {
  AlertColor,
  Stack,
  Typography,
} from "@mui/material";
import { useAppSelector } from "../../redux";
import AlertCard from "./AlertCard";

export const severity: any = {
  completed: "success",
  saved: "success",
  "In Progress": "info",
  "notUploaded": "info",
  "pending": "info",
  failed: "error",
};

const headings: any = {
  "document-status": "Document Upload",
  "bom-cost-status": "Cost Calculation",
  "attachment-status": "Attachment Upload",
  "workbook-status": "Workbook Status",
};

function NotificationsBlock() {
  const { notifications }: any = useAppSelector((state) => state.notifications);

  let availableCategories = Object.keys(notifications);

  return (
    <Stack p={1} px={2} spacing={2} overflow={"hidden"}>
      {availableCategories.map((notification: string) => {
        return (
          <Stack
            spacing={1}
            sx={{
              height: `calc((100vh - 64px) / ${availableCategories.length})`,
              overflow: "auto",
            }}
            key={notification}
          >
            <Typography variant="body1" sx={{ fontFamily: "htrts_medium" }}>
              {headings[notification]}
            </Typography>
            {Object.keys(notifications[notification])
              .reverse()
              .map((n: any) => {
                const value: any = notifications?.[notification]?.[n];
                const status = severity[value?.["status"]] as AlertColor;
                return <AlertCard status={status} value={value} />;
              })}
          </Stack>
        );
      })}
    </Stack>
  );
}

export { NotificationsBlock };
