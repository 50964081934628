import { Alert, Box, Button, DialogActions, DialogContent, DialogTitle, Grid, Stack, Theme, Typography, useTheme } from "@mui/material";
import React from "react";
import { CustomDataGrid, SelectBox, TextField, TitleHeader } from "../../../components";
import { useAppDispatch, useAppSelector } from "../../../redux";
import { DashboardTableColumn, L4 } from "../utils";
import { default as UserDetails } from "../../viewUser";
import { closeDialog, openDialog } from "../../../redux/slices/dialog";
import moment from "moment";
import { useLazyGetRolesQuery, useLazyGetUsersQuery, useUpdateRoleForUserMutation } from "../../../redux/services";
import { ThemeContext } from "../../../contexts";
import { closeBackdrop, openBackdrop } from "../../../redux/slices/backdrop";
import { LocalStorageKeys, parseJwt } from "../../../utils";
import { SelectBoxOption } from "../../../interfaces";
import { LoadingButton } from "@mui/lab";
import { HasAccess } from "../../../router/authorization";
import { PERMISSIONS } from "../../../router/permission";
import { useSnackbar } from "notistack";

const useStyles = (theme: Theme) => ({
    root: {
        flexGrow: 1,
        background: theme.palette.background.paper,
        boxShadow: `rgba(99, 99, 99, 0.2) 0px 2px 8px 0px`,
        height: "100%",
        overflow: "scroll",
        borderRadius: theme.spacing(1),
        padding: theme.spacing(2),
    },
    dataGridParent: {
        height: "calc(100% - 40px)",
        overflow: "scroll",
        marginTop: theme.spacing(2),
    }
});

const RoleEdit = ({ userDetails, refetch }: { userDetails: any; refetch: () => void }) => {
    const dispatch = useAppDispatch();
    const { enqueueSnackbar } = useSnackbar();

    const [getRoles, rolesRes] = useLazyGetRolesQuery();
    const [updateRole, updateRoleRes] = useUpdateRoleForUserMutation();

    const [selectedRole, setSelectedRole] = React.useState<SelectBoxOption | null>(null);
    const [hasError, setError] = React.useState(false);

    const loadRoles = async (search: string, loadedOptions: any) => {
        try {
            let page = Math.round(loadedOptions.length / 50);
            let options = await getRoles({ page, pageSize: 50 }).unwrap();
            return { options: options?.map((_: any) => ({ ..._, value: _.id, label: `${_.name} (${_.description})` })), hasMore: options?.length === 50 }
        } catch (error) {
            console.log('error:', error)
            return { options: [], hasMore: false }
        }
    };

    const onUpdateRole = async () => {
        dispatch(openBackdrop(`setting ${selectedRole?.label} for ${userDetails?.name}...`));
        await updateRole({ user_id: userDetails?.user_id, role_id: selectedRole?.value }).unwrap().then((res) => {
            enqueueSnackbar("Role updated successfully!", { variant: "success" });
        }).catch((error: any) => {
            console.error(`Error creating bom report ${error}`)
            enqueueSnackbar(
                error?.data?.title ?? `Something went wrong, Unable to update role for ${userDetails?.name}`,
                { variant: "error" }
            );
        }).finally(() => {
            refetch();
            dispatch(closeDialog());
            dispatch(closeBackdrop());
        });
    }

    React.useEffect(() => {
        let sR = userDetails?.roles?.at(-1);
        if (Object.keys(sR).length > 0) {
            setSelectedRole({ ...sR, value: sR.id, label: `${sR.name}${sR.description ? ` (${sR.description})` : ''}` })
        }
    }, [userDetails])

    return (
        <>
            <DialogTitle>
                <Typography
                    variant="h6"
                    color="textPrimary"
                    sx={{
                        borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
                        width: "100%", fontFamily: 'htrts_medium'
                    }}
                >
                    Update Role
                </Typography>
            </DialogTitle>
            <DialogContent>
                <TextField label={"User Name"} value={userDetails?.name} viewOnly={true} />
                <Stack width={"100%"} mt={1}>
                    <SelectBox
                        isPaginate
                        label={"Select Role"}
                        loadOptions={loadRoles}
                        loading={rolesRes.isLoading || rolesRes.isFetching}
                        options={[]}
                        value={selectedRole}
                        onChange={(option: SelectBoxOption) => {
                            setSelectedRole(option)
                            if (!option) {
                                setError(true);
                            } else {
                                setError(false);
                            }
                        }}
                        error={hasError}
                        helperText={hasError ? "Please select the role" : ""}
                        isMenuFixed
                    />
                </Stack>
            </DialogContent>
            <DialogActions>
                <Grid container spacing={2} px={2} pb={1}>
                    {/* Update Btn */}
                    <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                        <LoadingButton
                            fullWidth
                            variant="contained"
                            disabled={hasError}
                            onClick={onUpdateRole}
                            loading={rolesRes.isLoading || rolesRes.isFetching || updateRoleRes.isLoading}
                        >
                            Update
                        </LoadingButton>
                    </Grid>

                    {/* Cancel Btn */}
                    <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                        <Button
                            fullWidth
                            variant="outlined"
                            onClick={() => dispatch(closeDialog())}
                            disabled={rolesRes.isLoading || rolesRes.isFetching || updateRoleRes.isLoading}
                        >
                            {"Cancel"}
                        </Button>
                    </Grid>
                </Grid>
            </DialogActions>
        </>
    );
}

export const Dashboard: React.FC<{ children?: JSX.Element }> = (props) => {
    const dispatch = useAppDispatch();
    const classes = useStyles(useTheme());

    const themeContext = React.useContext(ThemeContext);

    // permissions start
    const { perm } = useAppSelector(store => store.auth.userDetails);
    const updatable = React.useMemo(() => HasAccess(perm, PERMISSIONS.USER_MODULE_UPDATE), [perm]);
    // end

    const [getUsers, { data: allData = { data: [], totalCount: 0 }, ...rest }] = useLazyGetUsersQuery();
    const { data, totalCount } = allData;

    const [search, setSearch] = React.useState('');

    const [paginationModel, setPaginationModel] = React.useState({
        page: 0,
        pageSize: 10,
    });

    const giveMeRows = () => {
        if (search.trim().length) {
            return data?.filter((_: any) => (_?.name?.toLowerCase()?.includes(search.toLowerCase())
                || _?.nickname?.toLowerCase()?.includes(search.toLowerCase())
                || _?.email?.toLowerCase()?.includes(search.toLowerCase())
                || _?.username?.toLowerCase()?.includes(search.toLowerCase())
                || _?.given_name?.toLowerCase()?.includes(search.toLowerCase())
                || _?.family_name?.toLowerCase()?.includes(search.toLowerCase())
                || _?.roles?.map((r: { Name: string }) => r.Name).join("")?.toLowerCase()?.includes(search.toLowerCase())
                || moment(_.created_at).format('lll').toString().includes(search.toLowerCase())
                || moment(_.updated_at).format('lll').toString().includes(search.toLowerCase())
            ))
        } else { return data }


    };
    const deleteUser = () => {

    };
    const CreateEditUser = (data: any, isEdit: boolean = false) => {
        dispatch(openDialog({
            title: "",
            hideNegativeBtn: true,
            hidePositiveBtn: true,
            body: <UserDetails isEdit={isEdit} data={data} />
        }));
    };
    let ROWS = giveMeRows();

    const token = localStorage.getItem(LocalStorageKeys.authToken) || "";

    const userData = token ? parseJwt(token) : {};

    const isUserAdmin = userData?.role === L4;

    const currentUserID = userData?.sub;

    const editRole = (userDetails: any) => {
        dispatch(openDialog({
            title: "",
            hideNegativeBtn: true,
            hidePositiveBtn: true,
            enablePadding: false,
            body: <RoleEdit userDetails={userDetails} refetch={() => getUsers({ ...paginationModel })} />
        }));
    }

    React.useEffect(() => {
        getUsers({ ...paginationModel });
        // eslint-disable-next-line
    }, [paginationModel]);

    React.useEffect(() => {
        if (rest.isLoading || rest.isFetching) {
            dispatch(openBackdrop("Fetching users..."));
        } else {
            dispatch(closeBackdrop());
        }
        // eslint-disable-next-line
    }, [rest.status]);

    return <Box sx={classes.root}>
        {/* Header */}
        <TitleHeader
            title="Users"
            count={ROWS?.length ?? 0}
            showSearch={true}
            search={search}
            onSearchChange={setSearch}
            searchPlaceholder={"Search for User (only within this page)"}
            showCreateBtn={false}
            createBtnLabel={"New User"}
            onCreateBtn={() => CreateEditUser(null, false)}
        />

        {/* Error */}
        {rest.isError && <Alert sx={{ mt: 2 }} severity="error">Oops! Something went wrong, Unable to fetch "Users". Try Again Later!</Alert>}

        {/* Data Grid */}
        <Box mt={2}>
            <CustomDataGrid
                id="ua-management-list"
                initialState={{
                    sorting: {
                        sortModel: [{ field: 'sequence_id', sort: 'desc' }],
                    },
                }}
                rows={ROWS}
                columns={DashboardTableColumn(deleteUser, themeContext, currentUserID, isUserAdmin && updatable ? editRole : undefined)}
                getRowId={(row) => row.user_id}
                rowCount={totalCount}
                pagination
                paginationModel={paginationModel}
                paginationMode="server"
                onPaginationModelChange={setPaginationModel}
            />
        </Box>
    </Box>
}