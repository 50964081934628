import React from 'react';
import { Grid, Typography, CircularProgress, Box } from '@mui/material';

export const LoadingSection: React.FC<{
    message?: string,
    top?: string | number
}> = (props) => {

    const {
        message = "Loading...",
        top = "30vh"
    } = props;

    return <Box>
        <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            sx={{ marginTop: top }}
        >
            <Grid item>
                <CircularProgress />
            </Grid>
            <Grid item>
                <Typography variant="h6" align="center">
                    {message}
                </Typography>
            </Grid>

        </Grid>
    </Box>
}

