import { createApi, fetchBaseQuery, retry } from "@reduxjs/toolkit/query/react";
import { config } from "../../../config";
import {
  ConvertToReactSelect,
  getTransformedDataForPagination,
  LocalStorageKeys,
} from "../../../utils";

export const snAttributeAPI = createApi({
  reducerPath: "snAttributeAPI",
  baseQuery: retry(
    fetchBaseQuery({
      baseUrl: config.api_url + "tm/sn/attribute",
      prepareHeaders: (headers) => {
        const token = localStorage.getItem(LocalStorageKeys.authToken);

        // If we have a token set in state, let's assume that we should be passing it.
        if (token) {
          headers.set("authorization", `Bearer ${token}`);
        }

        return headers;
      },
    }),
    { maxRetries: 2 }
  ),
  tagTypes: ["SNAttributes", "SNAttribute"],
  endpoints: (builder) => ({
    // Get SN Attributes API
    getSNAttributes: builder.query({
      query: ({ id, page = 0, pageSize = 10, sort_by = null, sort_order = null }) => ({
        url: `${page !== null ? `?page_no=${page + 1}` : ""}${
          pageSize ? `&page_size=${pageSize}` : ""
        }${
          typeof id === "string"
            ? `${page !== null ? "&" : "?"}sn_noun_id=${id}`
            : ""
        }${sort_by ? `&sort_by=${sort_by}` : ''}${sort_order ? `&sort_order=${sort_order}` : ''}`,
        method: "GET",
      }),
      transformResponse: (
        data: any,
        meta: { request: Request; response?: Response }
      ) => {
        return getTransformedDataForPagination(data, meta?.response);
      },
      providesTags: ["SNAttributes"],
    }),

    // Get SN Attributes for Dropdown API
    getSNAttributeDDOptions: builder.query({
      query: (payload) => ({ method: "GET" }),
      transformResponse: (response: any[], meta, arg) =>
        ConvertToReactSelect(response, "_id", "attr_name"),
      providesTags: ["SNAttributes"],
    }),

    // Create SN Attribute API
    createSNAttribute: builder.mutation({
      query: (payload) => ({ method: "POST", body: payload }),
      invalidatesTags: ["SNAttributes"],
    }),

    // Update SN Attribute API
    updateSNAttribute: builder.mutation({
      query: ({ id, payload }) => ({
        url: `${id}`,
        method: "PUT",
        body: payload,
      }),
      invalidatesTags: ["SNAttributes"],
    }),

    // Get SN Attributes by attribute ids and option ids
    getSNAttributesByIds: builder.query({
      query: (payload) => ({ method: "PUT", body: payload }),
      providesTags: ["SNAttribute"],
    }),

    // Delete SN Attribute API
    deleteSNAttribute: builder.mutation({
      query: ({ id }) => ({ url: `${id}`, method: "DELETE" }),
      invalidatesTags: ["SNAttributes", "SNAttribute"],
    }),
  }),
});

// Export hooks for usage in functional components
export const {
  useGetSNAttributesQuery,
  useLazyGetSNAttributesQuery,
  useGetSNAttributeDDOptionsQuery,
  useCreateSNAttributeMutation,
  useUpdateSNAttributeMutation,
  useLazyGetSNAttributesByIdsQuery,
  useGetSNAttributesByIdsQuery,
  useDeleteSNAttributeMutation,
} = snAttributeAPI;
