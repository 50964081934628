import { Search } from "@mui/icons-material";
import { Box } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { withNavBars } from "../../HOCs";
import { Layout } from "../../components";
import { AppRoutes } from "../../router/routes";
import { AllSearches } from "./dashboard";

const AIInstantSearch: React.FC<{ children?: JSX.Element }> = (props) => {

    const navigate = useNavigate();

    return <Box sx={{ padding: 0.5, height: "100%" }}>
        {/* Breadscrum */}
        <Layout
            history={[
                { label: "AI", onClick: () => navigate(AppRoutes.aiInstantSearch) },
            ]}
            currentPath={"All Instant Search"}
            hideNavBarByDefault={true}
            navBars={[{
                id: "1",
                title: "All Instant Search",
                icon: <Search fontSize="small" />,
                path: AppRoutes.aiInstantSearch
            },]}
            mainPanel={<AllSearches />}
            sideNavVariant={"whiteboard"}
        />
    </Box>
}

export default withNavBars(AIInstantSearch);