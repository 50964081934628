import {
    createApi,
    retry,
    fetchBaseQuery,
  } from "@reduxjs/toolkit/dist/query/react";
  import { config } from "../../../config";
  import {
    getTransformedDataForPagination,
    LocalStorageKeys,
  } from "../../../utils";
  
  export const listViewAPI = createApi({
    reducerPath: "listViewAPI",
    baseQuery: retry(
      fetchBaseQuery({
        baseUrl: config.api_url + "dm/bom_list_view",
        prepareHeaders: (headers) => {
          const token = localStorage.getItem(LocalStorageKeys.authToken);
  
          // If we have a token set in state, let's assume that we should be passing it.
          if (token) {
            headers.set("authorization", `Bearer ${token}`);
          }
  
          return headers;
        },
      }),
      { maxRetries: 1 }
    ),
    tagTypes: ["ListView"],
    endpoints: (builder) => ({
      // Get All List View API
      getAllListView: builder.query({
        query: ({
          page = 0,
          pageSize = 10,
          object_type = null,
          object_id = null,
          sort_by = null, 
          sort_order = null
        }) => ({
          url: `${page !== null ? `?page_no=${page + 1}` : ""}${
            pageSize ? `&page_size=${pageSize}` : ""
          }${object_type ? `&object_type=${object_type}` : ""}${object_id ? `&object_id=${object_id}` : ""}${sort_by ? `&sort_by=${sort_by}` : ''}${sort_order ? `&sort_order=${sort_order}` : ''}`,
          method: "GET",
        }),
        transformResponse: (
          data: any,
          meta: { request: Request; response?: Response }
        ) => {
          return getTransformedDataForPagination(data, meta?.response);
        },
      }),
  
      // Get List View by id API
      getListViewById: builder.query({
        query: ({ id }) => ({
          url: `/${id}`,
          method: "GET",
        }),
      }),
  
      // Create List View API
      createListView: builder.mutation({
        query: (payload): Record<string, any> => ({
          method: "POST",
          body: payload,
        }),
      }),
  
      // Update List View API
      updateListView: builder.mutation({
        query: ({ id, payload }) => ({
          url: `/${id}`,
          method: "PUT",
          body: payload,
        }),
      }),
  
      // Delete List View API
      deleteListView: builder.mutation({
        query: ({
          id
        }) => ({
          url: `/${id}`,
          method: "DELETE",
        }),
      }),
    }),
  });
  
  export const {
   useGetAllListViewQuery,
   useLazyGetAllListViewQuery,
   useGetListViewByIdQuery,
   useLazyGetListViewByIdQuery,
   useCreateListViewMutation,
   useUpdateListViewMutation,
   useDeleteListViewMutation
  } = listViewAPI;
  