import React from "react";
import Select from "react-select";
import { Typography, useTheme, Box, Tooltip, Chip, Stack } from "@mui/material";
import { useGetNounsQuery } from "../../redux/services";
import { NounSelectBoxInterface, Option, customStyles } from "./util";

export const NounSelectBoxWithoutSeach: React.FC<NounSelectBoxInterface> = ({
    value = null, onChange = () => null,
    label = "", isRequired = false, placeholder = "", isMulti = false, disabled = false, height = null, uom_id = null, viewOnly = false
}) => {

    const theme = useTheme();
    const nounD = useGetNounsQuery({ page: null, pageSize: null }, { refetchOnMountOrArgChange: true });
    const nounData = { ...nounD, data: nounD?.data?.data ?? [], currentData: nounD?.data?.data ?? [] };

    const giveMeOptions = () => {
        if (uom_id) {
            return nounData.currentData?.filter((_: any) => _.uom_id === uom_id)?.map((_: any) => ({ value: _._id, label: _.noun_name, ..._ }));
        }
        return nounData.currentData?.map((_: any) => ({ value: _._id, label: _?.uom_name ? `${_.noun_name} (${_?.uom_name})` : _.noun_name, ..._ }));
    }


    let selectedValue = value && !Array.isArray(value) ? value?.label || "N/A" : "N/A";

    return viewOnly ? (
        <Stack sx={{ overflowWrap: "break-word" }}>
            <Typography variant="body1" sx={{ fontFamily: "htrts_semibold" }}>{label}</Typography>
            {Array.isArray(value) && value.length > 0
                ? 
                    <Stack
                        gap={1}
                        direction={"row"}
                        flexWrap={"wrap"}
                    >
                        {
                            value.map(v =>
                                <Tooltip title={v?.label || "N/A"}>
                                    <Chip size={"small"} label={v?.label || "N/A"} />
                                </Tooltip>
                            )
                        }
                    </Stack>
                : 
                    <Typography variant="body2" sx={{ fontFamily: "htrts_regular" }}>{selectedValue}</Typography>}
        </Stack>
    ) : (
        <Box>
            {label.length > 0 && <Box sx={{ display: "flex" }}>
                {
                    <Typography variant="body2" color="primary" align="left" gutterBottom>
                        {label}
                    </Typography>
                }
                {isRequired && (
                    <Typography color="error" variant="caption">
                        *
                    </Typography>
                )}
            </Box>}


            <Select
                id="without-search"
                isMulti={isMulti}
                isClearable={false}
                isSearchable
                isDisabled={disabled}
                components={{
                    IndicatorSeparator: () => null,
                    Option
                }}
                value={value}
                placeholder={placeholder}
                options={giveMeOptions()}
                isLoading={nounData.isFetching}
                onChange={onChange}
                styles={customStyles(theme, height)}
            />

            {(nounData.isError && !nounData.isFetching) && (
                <Typography variant="caption" color="error">
                    {"Opps! Unable to fetch "}
                </Typography>
            )}
        </Box>
    );
};
