import { Box } from "@mui/material";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Layout } from "../../components";
import { useAppDispatch } from "../../redux";
import { useLazyGetDataTableByIdQuery } from "../../redux/services";
import { openBackdrop } from "../../redux/slices/backdrop";
import { AppRoutes } from "../../router/routes";
import { CheckListData } from "../viewCheckList/viewCheckListDetail";
import { ViewDataSheetDetail } from "./viewDatasheetDetail";

function ViewDataSheetWithId() {
  const dispatch = useAppDispatch();
  const { id = "" } = useParams();
  const navigate = useNavigate();
  const [getDataSheetById, dataSheetResponse] = useLazyGetDataTableByIdQuery({
    refetchOnReconnect: true,
    refetchOnFocus: true,
  });

  const isEdit = id !== "New";

  const breadScrumName = React.useMemo((): string => {
    if (isEdit) {
      return dataSheetResponse.isLoading
        ? "..."
        : `${dataSheetResponse.data?.sequence_id ?? ""}-r${
            dataSheetResponse.data?.version ?? 0
          }`;
    } else {
      return "New";
    }
    // eslint-disable-next-line
  }, [dataSheetResponse.data]);

  const fetchDataSheet = async () => {
    if (isEdit) {
      dispatch(openBackdrop("Fetching Data Sheet Details..."));
      await getDataSheetById({
        dtTemplateType: "datasheet",
        dtInstanceType: "template",
        dtTemplateId: id
      });
    }
  };

  React.useEffect(() => {
    fetchDataSheet();
    // eslint-disable-next-line
  }, [id]);

  return (
    <Box p={0.5} height={"100%"}>
      <Layout
        currentPath={"Data Sheet Details"}
        history={[
          {
            label: "Document Templates",
            onClick: () => navigate(AppRoutes.viewTemplateList),
          },
          {
            label: "Data Sheet Templates",
            onClick: () => navigate(AppRoutes.dataSheetList),
          },
          {
            label: breadScrumName,
            onClick: () => navigate(AppRoutes.viewDataSheetWithId(id)),
          },
        ]}
        navBars={[]}
        mainPanel={
          <ViewDataSheetDetail
            dataSheetData={dataSheetResponse as CheckListData}
            isEdit={isEdit}
          />
        }
        sideNavVariant={"whiteboard"}
        hideSideNavBar={false}
        otherBreadscrumProps={{         
          hideMenuBtn: true,
          showBackBtn: true,
        }}
      />
    </Box>
  );
}

export default ViewDataSheetWithId;
