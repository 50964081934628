import { Edit } from "@mui/icons-material";
import { IconButton, Tooltip, Typography } from "@mui/material";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid-premium";
import moment from "moment";
import { CapitalizeString, GiveMeStatusColor } from "../../utils";

export const TagsTableColumn = (edit: any, hasEditAccess: boolean) => {
    let columns: GridColDef[] = [
        { field: 'tag_name', headerName: 'Tag Name', width: 250 },
        { field: 'tag_group_name', headerName: 'Tag Category', width: 250 },
        {
            field: 'tag_desc', headerName: 'Tag Description', width: 400, renderCell: (params: GridRenderCellParams) => {
                if (params.rowNode.type === 'group') {
                    return params.value;
                  }
                return <Tooltip title={params.row.tag_desc}><Typography variant="body2" sx={{ width: "100%", overflow: "hidden", textOverflow: "ellipsis" }}>{params.row.tag_desc}</Typography></Tooltip>
            }
        },
        { field: 'created_user', headerName: 'Created By', width: 250 },
        {
            field: 'created_time', headerName: 'Created On', width: 250, renderCell: (params: GridRenderCellParams) => {
                if (params.rowNode.type === 'group') {
                    return params.value;
                  }
                return <Typography variant="body2">{moment(params.row.created_time).format('lll')}</Typography>
            }
        },
        {
            field: 'status', headerName: 'Status', width: 250, renderCell: (params: GridRenderCellParams) => {
                return <Typography variant="body2" color={GiveMeStatusColor(params?.row?.status)} >{params.row.status.length ? CapitalizeString(params.row.status) : "-"}</Typography>
            }
        },
    ];

    if (hasEditAccess) {
        columns = [{
            field: 'actions', headerName: 'Edit', width: 80, sortable: false, disableColumnMenu: true, renderCell: (params: GridRenderCellParams) => {
                return <Tooltip placement="right" title={params.row.system_defined ? "System defined records cannot be modified" : "Edit"}>
                    <IconButton
                        onClick={() => params.row.system_defined ? false : edit(params.row)}
                    >
                        <Edit fontSize="small" color={params.row.system_defined ? "disabled" : "primary"} />
                    </IconButton >
                </Tooltip>
            }
        }, ...columns];
    }

    return columns;
};