import { DeleteOutline, Folder, Upload } from '@mui/icons-material';
import { LoadingButton } from "@mui/lab";
import { Alert, Box, Button, DialogActions, DialogContent, DialogTitle, Grid, InputBase, Link, Stack, styled, TextField, Tooltip, Typography, useTheme } from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useContext } from "react";
import { useDispatch } from "react-redux";
import { Assign, DeletePanel, HighlightSelectBox, ImageUploadList, pattern, SelectBox, UnAssign } from "..";
import { SocketContext } from '../../contexts';
import { PublishedIcon } from '../../images/svg/publishedIcon';
import { WIPIcon } from '../../images/svg/WIPIcon';
import { LinkUnlikeOnUpdate, SelectBoxOption } from "../../interfaces";
import { useAppDispatch, useAppSelector } from "../../redux";
import { useCreateMaterialDocumentMutation, useGetLocationAndDocumentTagsQuery, useLazyGetBOMsQuery, useLazyGetDocumentByIdQuery, useLazyGetDocumentsQuery, useLinkModuleMutation, useUnlinkModuleMutation, useUpdateAssignmentMutation, useUpdateMaterialDocumentMutation, useUploadDocumentMutation, type AssignmentStatus } from "../../redux/services";
import { closeBackdrop, openBackdrop } from "../../redux/slices/backdrop";
import { closeConfirmationDialog, openConfirmationDialog } from "../../redux/slices/confirmationDialog";
import { closeDialog, openDialog } from "../../redux/slices/dialog";
import { notify, toggleBadge } from '../../redux/slices/notifications';
import { clearSelectedChip } from "../../redux/slices/utils";
import { DependencyChildren, PublishComponent } from '../../screens/materials/dashboard';
import { giveMeLocationAndDocumentTags } from "./utils";
import { getEmtpyTextFile } from '../../screens/drawioDiagrams/utils';
import GreyAccordian from '../greyAccordian';
import { GridActionsCellItem, GridRenderCellParams } from '@mui/x-data-grid-premium';
import copy from 'fast-copy';
import { StyledDataGrid } from '../../screens/workbook/actions/actionTable';

type State = {
    location_tags: SelectBoxOption | null;
    document_tags: SelectBoxOption | null;
    tag_group: SelectBoxOption | null;
    tags: SelectBoxOption | null;
    diagram_template: SelectBoxOption & { url: string; } | null;
    note: string;
    file_name: string
    bom: SelectBoxOption | null
}

type MultiState = { s_no: number; id: string; name: string; location_tags: SelectBoxOption | null; document_tags: SelectBoxOption | null; comment: string; file: File; }

type MultiStateError = { id: string; location_tags: boolean; document_tags: boolean; comment: boolean; }

export type FileSelectionOptionType = "single" | "multiple";

const StyledInputBase = styled(InputBase)(({ theme, error }) => ({
    border: '2px solid',
    borderColor: error ? "red !important" : theme.palette.grey[400],
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(1),
}));

export const MultipleDoc = ({ files, defaultFileType, extraTags, documentMode, _id, objectType, ...props }: any) => {
    const selectOptions = useGetLocationAndDocumentTagsQuery(null, { refetchOnFocus: true, refetchOnMountOrArgChange: true });
    const [createDocument, createDocumentData] = useCreateMaterialDocumentMutation();
    const [uploadDocument] = useUploadDocumentMutation();

    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useAppDispatch();
    const { open } = useContext(SocketContext);

    const [rows, setRows] = React.useState<MultiState[]>([]);
    const [errorRows, setErrorRows] = React.useState<MultiStateError[]>([])
    const [selectedTags, setSelectedTags] = React.useState<{ location_tags: SelectBoxOption | null; document_tags: SelectBoxOption | null }>({ document_tags: null, location_tags: null });
    const [connectingSocket, updateSocketConnection] = React.useState<boolean>(false);

    const inputRef = React.useRef<HTMLInputElement>(null);

    const onChange = (data: MultiState[keyof MultiState], row: MultiState, key: keyof MultiState) => {
        const copiedRow: any = { ...row, [key]: data };
        const idx = rows.findIndex((r: { id: string }) => r.id === copiedRow.id);
        if (idx > -1) {
            setRows((prev) => [...prev.slice(0, idx), copiedRow, ...prev.slice(idx + 1)]);
            const eIdx = errorRows.findIndex(e => e.id === copiedRow.id);
            if (eIdx > -1) {
                const errorRow: any = { ...errorRows[eIdx], [key]: !data };
                setErrorRows((prev) => [...prev.slice(0, eIdx), errorRow, ...prev.slice(eIdx + 1)]);
            }
        }
    }

    const handleDelete = (id: string) => {
        setRows(prevRows => {
            let fRows = prevRows.filter(p => p.id !== id);
            return fRows.map((f, idx: number) => ({ ...f, s_no: idx + 1 }))
        });
    };

    const onApply = () => {
        if (selectedTags?.location_tags || selectedTags?.document_tags) {
            setRows((prev) => prev.map(p => ({ ...p, location_tags: selectedTags?.location_tags ?? p?.location_tags, document_tags: selectedTags?.document_tags ?? p.document_tags })))
            setErrorRows((prev) => prev.map(p => ({ ...p, location_tags: !(selectedTags?.location_tags ?? p?.location_tags), document_tags: !(selectedTags?.document_tags ?? p.document_tags) })))
        } else {
            enqueueSnackbar("Select both the tags to apply", { variant: "warning" });
        }
    };

    const openFileInput = () => {
        inputRef?.current?.click();
    };

    const handleImageUpload = (event: any) => {
        event?.preventDefault();
        const files =
            event?.dataTransfer?.files || inputRef?.current?.files;

        if ((files.length + rows.length) > 10) {
            enqueueSnackbar(
                "More than 10 files can't be uploaded we'll take the first 10 files",
                { variant: "warning" }
            );
        }

        let valid = true;
        let slicedFiles = Array.prototype.slice.call(files, 0, Math.abs(rows.length - 10)).slice(0, Math.abs(rows.length - 10));
        slicedFiles.slice(0, Math.abs(rows.length - 10)).forEach((f: File) => {
            if (!pattern.test(f?.name ?? "")) {
                enqueueSnackbar(
                    `Special characters like "/", "", "?", "#", "&", "=","+", "%" are not allowed, pls rename and upload`,
                    { variant: "warning" }
                );
                valid = false;
            }
        });

        if (valid) {
            let errors = [...errorRows];
            let uRows = slicedFiles.map((f: File, idx: number) => {
                let obj = { file: f, name: f.name, id: `${f.name}-${idx + 1}`, s_no: rows.length + idx + 1, location_tags: null, document_tags: null, comment: "" }

                let errorObj = { id: `${f.name}-${idx + 1}`, location_tags: false, document_tags: false, comment: false }

                errors.push(errorObj);

                return obj;
            })

            setRows([...rows, ...uRows]);

            setErrorRows(errors);
        }

        return valid;
    };

    const onCreateDocuments = async () => {
        dispatch(closeConfirmationDialog());
        let payload: any[] = [];
        let errorOb: any[] = [];
        rows.forEach(r => {
            if (r.location_tags && r.document_tags && r.comment.trim().length) {
                const tags_info = [
                    { tag_id: r.document_tags.value, tag_group_id: r.document_tags.tag_group_id },
                    { tag_id: r.location_tags.value, tag_group_id: r.location_tags.tag_group_id }
                ];

                if (extraTags) {
                    tags_info.push({ tag_id: extraTags.value, tag_group_id: extraTags.tag_group_id })
                }

                let document_type: any;
                let file_name: string = `${r.name}${defaultFileType ?? ""}`;

                if (documentMode === 'Drawing') {
                    document_type = 'drawing';
                } else if (documentMode === 'Drawing Template') {
                    document_type = 'drawing_template';
                } else {
                    document_type = undefined
                }

                const pObj: any = {
                    comment: r.comment,
                    tags_info,
                    file_name: `${file_name.trim()}${props?.defaultFileType ?? ""}`,
                    document_type
                };

                if (objectType && _id) {
                    pObj.links = [{ object_ids: [_id], object_type: objectType }];
                } else {
                    pObj.links = []
                }

                payload.push(pObj);
            } else {
                payload = [];
                const idx = errorRows.findIndex(e => e.id === r.id);
                const copiedErr = copy(errorRows[idx]);
                errorOb.push({ ...copiedErr, id: r.id, location_tags: !r.location_tags, document_tags: !r.document_tags, comment: !r.comment.trim() });
            }
        })

        if (errorOb.length <= 0 && payload.length > 0) {
            dispatch(openBackdrop("Creating Documents..."));
            createDocument(payload);
        }

        if (errorOb.length > 0) {
            setErrorRows(errorOb);
        }

    };

    const openSocket = (id: string, sequence_id: string, version: number) => {
        updateSocketConnection(true);
        return new Promise(async (resolve, reject) => {
            try {
                const socketPayload = {
                    "name": "document-status",
                    "payload": {
                        "document_id": id
                    }
                };
                await open(socketPayload);
                dispatch(notify({
                    name: "document-status",
                    id: id,
                    status: "In Progress",
                    content: `${sequence_id ? "Updating" : "Creating"} document for seqID is (0)`,
                    sequenceID: `${sequence_id ?? "New"}-r${version ?? "0"}`,
                    subs: [
                        "in progress"
                    ],
                    message: ""
                }));
                dispatch(toggleBadge(true));
                resolve(true);
            } catch (error) {
                console.error('error:', error)
                resolve(false);
            } finally {
                updateSocketConnection(false);
            }

        })
    };

    const giveMeButtonName = (cdData: any) => {
        let documentMode = props.documentMode ? props.documentMode : "Documents"
        if (cdData.isError) {
            return `${`Retry Create ${documentMode}`}`
        }

        return `${`Create ${documentMode}`}`;
    };

    let columns = React.useMemo(() => {
        return [
            {
                field: "actions",
                type: "actions",
                headerName: "Actions",
                width: 100,
                cellClassName: "actions",
                getActions: ({ row }: any) => {
                    return [
                        <GridActionsCellItem
                            icon={<Tooltip title={`Delete ${row?.name}`}><DeleteOutline /></Tooltip>}
                            label="Delete"
                            className="textPrimary"
                            onClick={() => handleDelete(row?.id)}
                            color="error"
                        />
                    ];
                },
            },
            {
                field: "s_no",
                headerName: "S.No",
                width: 50,
                renderCell: (params: GridRenderCellParams) => {
                    if (params.rowNode.type === "group") {
                        return params.value;
                    }
                    return <Typography variant="body2">{params.row?.s_no}</Typography>;
                },
            },
            {
                field: "name",
                headerName: "File Name",
                width: 250,
                renderCell: (params: GridRenderCellParams) => {
                    if (params.rowNode.type === "group") {
                        return params.value;
                    }
                    return <Typography variant="body2">{params.row?.name}</Typography>;
                },
            },
            {
                field: 'location_tags',
                headerName: 'Folder',
                width: 250,
                disableColumnMenu: true,
                sortable: false,
                renderCell: (params: GridRenderCellParams) => {
                    const errObj = errorRows.find(e => e.id === params.row.id);
                    return <HighlightSelectBox
                        disableClearable
                        label={"Folder"}
                        margin={"none"}
                        fullWdith
                        loading={selectOptions.isLoading || selectOptions.isFetching}
                        value={params.row.location_tags}
                        options={selectOptions?.data?.locationTags}
                        onChange={(data: SelectBoxOption) => onChange(data, params.row, "location_tags")}
                        error={errObj?.location_tags || selectOptions.isError}
                        onKeyDown={(event) => {
                            event.stopPropagation();
                        }}
                    />
                }
            },
            {
                field: 'document_tags',
                headerName: 'Document Type',
                width: 250,
                disableColumnMenu: true,
                sortable: false,
                renderCell: (params: GridRenderCellParams) => {
                    const errObj = errorRows.find(e => e.id === params.row.id);
                    return <HighlightSelectBox
                        disableClearable
                        label={"Document Type"}
                        margin={"none"}
                        fullWdith
                        loading={selectOptions.isLoading || selectOptions.isFetching}
                        value={params.row.document_tags}
                        options={selectOptions?.data?.documentTags}
                        onChange={(data: SelectBoxOption) => onChange(data, params.row, "document_tags")}
                        error={errObj?.document_tags || selectOptions.isError}
                        onKeyDown={(event) => {
                            event.stopPropagation();
                        }}
                    />
                }
            },
            {
                field: 'comment',
                headerName: 'Notes',
                width: 250,
                disableColumnMenu: true,
                sortable: false,
                renderCell: (params: GridRenderCellParams) => {
                    const errObj = errorRows.find(e => e.id === params.row.id);

                    return <StyledInputBase
                        placeholder="Enter Notes"
                        type="text"
                        value={params.row.comment}
                        onChange={(e) => {
                            onChange(e.target.value, params.row, "comment")
                        }}
                        onKeyDown={(event) => {
                            event.stopPropagation();
                        }}
                        error={errObj?.comment}
                    />;
                }
            },
        ];
        // eslint-disable-next-line
    }, [rows, errorRows, selectOptions.status])

    React.useEffect(() => {
        if (files.length > 0) {
            let errors: MultiStateError[] = [];
    
            let updatedRows = files.map((f: File, idx: number) => {
                let obj = { file: f, name: f.name, id: `${f.name}-${idx + 1}`, s_no: idx + 1, location_tags: props.folderSelected ?? null, document_tags: null, comment: "" }
    
                let errorObj = { id: `${f.name}-${idx + 1}`, location_tags: false, document_tags: false, comment: false }
    
                errors.push(errorObj);
    
                return obj;
            })
    
            setRows(updatedRows);
    
            setErrorRows(errors);
        } else if (props.folderSelected) {
            setRows((prev) => prev.map(p => ({ ...p, location_tags: props.folderSelected })));
            setSelectedTags((prev) => ({ ...prev, location_tags: props.folderSelected }));
        }
    }, [files, props.folderSelected])

    React.useEffect(() => {
        const updateCreateDocumentStatus = async () => {
            if (createDocumentData.isSuccess) {
                enqueueSnackbar("Documents Created Successfully", { variant: "success" });

                props.refetch();
                createDocumentData.data?.forEach(async (d: any) => {
                    if (d?.url) {
                        enqueueSnackbar("Uploading your document...", { variant: "info" });
                        await openSocket(d?._id, d?.sequence_id, d?.version);
                        const file_name = decodeURIComponent(d?.url.split("?")[0].split("/").at(-1));
                        let file = rows.find((r) => r.name === file_name)?.file;

                        if (file) {
                            uploadDocument({ data: d, url: d?.url, file });
                        }
                    }
                })
                dispatch(closeDialog());
                dispatch(closeBackdrop());
            }
            if (createDocumentData.isError) {
                const error: any = createDocumentData.error;
                enqueueSnackbar(error?.data?.title ?? "Oops! Something went wrong, Unable to create Documents", { variant: "error" })
            }
        }

        updateCreateDocumentStatus();
        // eslint-disable-next-line
    }, [createDocumentData.status]);

    const isLoading = selectOptions.isLoading || selectOptions.isFetching;

    return (
        <>
            <DialogTitle>
                <Typography
                    variant="h6"
                    color="textPrimary"
                    sx={{
                        width: "100%",
                        fontFamily: 'htrts_medium',
                        borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
                    }}
                >
                    Create Documents
                </Typography>
            </DialogTitle>
            <DialogContent>
                <Grid container mt={0.5} mb={2} spacing={2} alignItems={"center"}>
                    <Grid item xs={12} md={4}>
                        <HighlightSelectBox
                            disableClearable
                            label={"Folder"}
                            margin={"none"}
                            fullWdith
                            loading={isLoading}
                            value={selectedTags.location_tags}
                            options={selectOptions?.data?.locationTags}
                            onChange={(data: SelectBoxOption) => setSelectedTags((prev) => ({ ...prev, location_tags: data }))}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <HighlightSelectBox
                            disableClearable
                            label={"Document Type"}
                            margin={"none"}
                            fullWdith
                            loading={isLoading}
                            value={selectedTags.document_tags}
                            options={selectOptions?.data?.documentTags}
                            onChange={(data: SelectBoxOption) => setSelectedTags((prev) => ({ ...prev, document_tags: data }))}
                        />
                    </Grid>
                    <Grid item xs={12} md={2}>
                        <Button variant="outlined" fullWidth onClick={onApply}>Apply To All</Button>
                    </Grid>
                    <Grid item xs={12} md={2}>
                        <Stack>
                            <Button disabled={rows.length >= 10} variant="contained" fullWidth onClick={openFileInput} startIcon={<Upload />}>Upload</Button>
                            <input
                                id="multiple-file-upload"
                                hidden
                                multiple
                                type="file"
                                ref={inputRef}
                                onChange={handleImageUpload}
                            />
                        </Stack>
                    </Grid>
                </Grid>

                <StyledDataGrid
                    hideFooter
                    saveLocal={false}
                    rows={rows}
                    columns={columns}
                    sx={{
                        "& .MuiDataGrid-row:hover": {
                            backgroundColor: "white",
                        },
                        "& .MuiInputBase-root": {
                            border: "1px solid rgba(224, 224, 224, 1)",
                            borderRadius: 1,
                            px: 1,
                            py: 0,
                        },
                        "& .MuiDataGrid-editInputCell": {
                            border: "1px solid rgba(224, 224, 224, 1)",
                            mx: 1,
                            borderRadius: 1,
                            py: "5px !important",
                            px: "0px !important",
                        },
                        '.MuiDataGrid-columnHeaderTitleContainer': {
                            width: "100% !important",
                        },
                        '.MuiDataGrid-columnHeaderTitleContainerContent': {
                            width: "100% !important",
                        },
                    }}
                />
            </DialogContent>

            <DialogActions sx={{ justifyContent: 'center !important', mb: 2 }}>
                <Stack sx={{ flexDirection: { xs: "column", md: "row" } }} alignItems="center" justifyContent={"center"} gap={2}>
                    <Button
                        variant="outlined"
                        onClick={() => dispatch(closeDialog())}
                    >
                        {"Cancel"}
                    </Button>

                    <LoadingButton
                        variant="contained"
                        onClick={onCreateDocuments}
                        loading={createDocumentData.isLoading || connectingSocket}
                    >
                        {giveMeButtonName(createDocumentData)}
                    </LoadingButton>
                </Stack>
            </DialogActions>
        </>
    )
}

export const AddEditDocument: React.FC<{
    children?: JSX.Element, isEdit: boolean, data: any, refetch: any, Id: string | number, _id: string, objectType: string, folderSelected?: any,
    hideFolderSelect?: boolean, hideUpload?: boolean, showFileName?: boolean, showModuleMapper?: boolean, defaultFile?: any, defaultFileType?: any,
    documentMode?: string, extraTags?: any, showBOM?: boolean
}> = (props) => {

    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const { selectedChip } = useAppSelector(store => store.utils);
    const { open } = useContext(SocketContext);
    const theme = useTheme();

    const selectOptions = useGetLocationAndDocumentTagsQuery(null, { refetchOnFocus: true, refetchOnMountOrArgChange: true });
    const [getDiagramTemplates, documentsResponse] = useLazyGetDocumentsQuery();

    const [createDocument, createDocData] = useCreateMaterialDocumentMutation();
    const createDocumentData = { ...createDocData, data: createDocData?.data?.[0] }
    const [updateDocument, updateDocumentData] = useUpdateMaterialDocumentMutation();
    const [uploadDocument] = useUploadDocumentMutation();
    const [getDocuments, documentData] = useLazyGetDocumentByIdQuery();
    const [assignSequenceNumberQuery] = useUpdateAssignmentMutation({});
    const [getBOMs, boms] = useLazyGetBOMsQuery();
    const [linkBOM] = useLinkModuleMutation();
    const [unLinkBOM] = useUnlinkModuleMutation();

    const [connectingSocket, updateSocketConnection] = React.useState<boolean>(false);

    const [state, setState] = React.useState<State>({
        location_tags: null,
        document_tags: null,
        tag_group: null,
        tags: null,
        diagram_template: null,
        note: "",
        file_name: "",
        bom: null
    });

    const [upload, setUpload] = React.useState<any>(null);

    const [error, setError] = React.useState({
        file_name: false,
        location_tags: false,
        document_tags: false,
        tag_group: false,
        tags: false,
        note: false,
        upload: false,
        bom: false
    });

    const updateUpload = (value: any) => {
        setUpload(value);
        setError({ ...error, upload: !value });
    };

    const updateState = (key: string, value: string) => {
        setState((prevState) => ({ ...prevState, [key]: value }));
        setError((prevError) => ({ ...prevError, [key]: !value?.length }));
    };

    const updateDropDown = (key: string, data: SelectBoxOption[]) => {
        setState((prevState) => ({ ...prevState, [key]: data }));
        setError((prevError) => ({ ...prevError, [key]: !data }));
    };

    const onCancelBtnClicked = () => {
        dispatch(closeDialog());
    };

    const onCreateUpdateBtnClicked = () => {
        let isValid: any = state?.location_tags?.value && state?.document_tags?.value && state?.note;

        // TODO: uncomment this line if we want BOM in the diagrams are mandatory
        // if (props.documentMode === 'Drawing') {
        //     isValid = isValid ? state.bom : isValid;
        // }

        if (props.isEdit && isValid && documentData?.data?.released) {
            dispatch(openConfirmationDialog({
                title: "Update Operation",
                body: `Current revision for ${documentData.data?.sequence_id} is already published. Do you want to create a new revision?`,
                positiveBtn: "Check for dependencies",
                negativeBtn: "Cancel",
                onOk: () => openDependencyTable(),
                onNegativeBtn: () => dispatch(closeConfirmationDialog())
            }));
        } else if (props.isEdit && isValid) {
            dispatch(clearSelectedChip())
            dispatch(openConfirmationDialog({
                title: "Update Operation",
                body: <DeletePanel
                    message={`Are you sure want to overwrite?`}
                    warningMessage={`The dependent items of ${documentData.data?.sequence_id}-r${documentData.data?.version} will be updated with new changes`}
                />,
                positiveBtn: "Overwrite",
                negativeBtn: "Cancel",
                onOk: () => createUpdateDocument(false),
                onNegativeBtn: () => dispatch(closeConfirmationDialog())
            }));
        } else if (isValid) {
            createUpdateDocument(false);
        } else {
            setError({
                ...error,
                location_tags: !state.location_tags, document_tags: !state.document_tags, tag_group: !state.tag_group, tags: !state.tags, note: !state.note, upload: !upload,
                bom: !state.bom
            })
        }
    };

    const openDependencyTable = () => {
        dispatch(clearSelectedChip());
        dispatch(openConfirmationDialog({
            title: "Update Operation",
            body: <DeletePanel
                message={`Dependency Table of ${documentData.data?.sequence_id ?? ""}-r${documentData.data?.version ?? ""}`}
                errorMessage={"Do you want to create new version?"}
                errorMessageStacks={documentData.data?.associations ?? []}
            />,
            positiveBtn: "New Version",
            negativeBtn: "Cancel",
            onOk: () => documentData.data?.associations?.length > 0 ? openNewVersionDialog() : createUpdateDocument(true),
            onNegativeBtn: () => dispatch(closeConfirmationDialog())
        }));
    };

    const openNewVersionDialog = () => {
        dispatch(openConfirmationDialog({
            title: "Update Operation",
            body: <DeletePanel
                message={`Select the dependencies of the newer version of ${props.data?.sequence_id ?? ""}-r${props.data?.version ?? ""}`}
                errorMessage={""}
                errorMessageStacks={props.data?.associations ?? []}
                warningMessage={"New version will be attached with selected dependencies"}
                chipSelectable={true}
            />,
            positiveBtn: "Create",
            negativeBtn: "Cancel",
            onOk: () => createUpdateDocument(true),
            onNegativeBtn: () => {
                dispatch(closeConfirmationDialog());
            }
        }));
    };

    const updatePublishStatus = (row: any) => {
        dispatch(
            openConfirmationDialog({
                title: "Publish Operation",
                body: <DeletePanel
                    message={`Are you sure you want to publish the document ${documentData.data?.sequence_id}?`}
                    warningMessage={`You will not be able to edit this revision after publishing. You can however create new revisions.`}
                />,
                positiveBtn: "Check for dependencies",
                negativeBtn: "Cancel",
                onOk: () => openDependencyTableForPublish(row),
                onNegativeBtn: () => dispatch(closeConfirmationDialog())
            })
        );
    };

    const openDependencyTableForPublish = async (row: any) => {
        dispatch(closeConfirmationDialog())
        try {
            dispatch(openDialog({
                title: "",
                body: <DependencyChildren object_id={documentData.data?._id} object_type="document" onPublish={() => proceedToPublish(row)} />,
                hideNegativeBtn: true, hidePositiveBtn: true, enablePadding: false
            }));
        } catch (error) {
            console.error(`Unable to get the hierarchy: ${error}`);
            enqueueSnackbar("Error getting dependencies", { variant: "error" })
            dispatch(closeDialog())
        }
    };

    const loadOption = async (search: string, loadedOptions: any) => {
        if (props.documentMode === "Drawing") {
            try {
                let page = Math.round(loadedOptions.length / 50);
                let options = await getDiagramTemplates({ page, pageSize: 50, document_type: 'drawing_template' }).unwrap();
                return { options: options.data.map((_: any) => ({ ..._, value: _._id, label: `${_.sequence_id}-r${_.version} (${_.file_name})` })), hasMore: options.data.length === 50 }
            } catch (error) {
                console.error('error:', error)
                return { options: [], hasMore: false }
            }
        } else {
            return { options: [], hasMore: false }
        }
    };

    const loadBOMOption = async (search: string, loadedOptions: any) => {

        try {
            let page = Math.round(loadedOptions.length / 50);
            let options = await getBOMs({ page, pageSize: 50, object_id: props._id, object_type: props.objectType, }).unwrap();
            return { options: options.data.map((_: any) => ({ ..._, value: _._id, label: `${_.sequence_id}-r${_.version} (${_.short_description})` })), hasMore: options.data.length === 50 }
        } catch (error) {
            console.error('error:', error)
            return { options: [], hasMore: false }
        }
    };

    const proceedToPublish = (row: any) => {
        dispatch(openDialog({
            title: "",
            body: <PublishComponent {...({ ...row, sequence_id: documentData.data?.sequence_id as string, _id: documentData.data?._id })} refetch={props.refetch} object_type="document" object_id={props?._id} />,
            hidePositiveBtn: true,
            hideNegativeBtn: true,
            enablePadding: false
        }));
    }

    const giveMeLinksAndUnlinks = (): LinkUnlikeOnUpdate => {
        let obj: LinkUnlikeOnUpdate = {
            link: [],
            unlink: [],
        }
        let lsChip: { [key: string]: string[] } = JSON.parse(localStorage.getItem('selectedChip') ?? "{}");
        props.data?.associations?.forEach((asso: { object_details: { _id: string }[], object_type: 'document' | 'material' | 'tag', }) => {
            let linkedIds: string[] = [];
            let unlinkedIds: string[] = [];
            asso.object_details.forEach(od => {
                if (lsChip[asso.object_type]?.includes(od._id)) {
                    linkedIds.push(od._id);
                } else {
                    unlinkedIds.push(od._id);
                }
            });
            obj.link.push({ object_ids: linkedIds, object_type: asso.object_type })
            obj.unlink.push({ object_ids: unlinkedIds, object_type: asso.object_type })
        })


        return obj;
    };

    const createUpdateDocument = async (update_version: boolean = false) => {
        dispatch(closeConfirmationDialog());
        if (state.location_tags && state.document_tags && state.note.length) {
            const file = upload;
            const tags_info = [
                { tag_id: state.document_tags.value, tag_group_id: state.document_tags.tag_group_id },
                { tag_id: state.location_tags.value, tag_group_id: state.location_tags.tag_group_id }
            ];

            if (props.extraTags) {
                tags_info.push({ tag_id: props.extraTags.value, tag_group_id: props.extraTags.tag_group_id })
            }

            // if (state.diagram_template) {
            //     tags_info.push({ tag_id: state.diagram_template.value, tag_group_id: state.diagram_template.tag_group_id })
            // }

            let document_type: any;
            let file_name: string = file ? (file.name ?? file?.file_name) : state.file_name;

            if (props.documentMode === 'Drawing') {
                document_type = 'drawing';
                file_name = state.file_name;
            } else if (props.documentMode === 'Drawing Template') {
                document_type = 'drawing_template';
                file_name = state.file_name;
            } else {
                document_type = undefined
                file_name = file ? (file.name ?? file?.file_name) : "Unnamed Document.txt";
            }

            if (props.isEdit) {
                let linkUnlink = giveMeLinksAndUnlinks();
                updateDocument({
                    comment: state.note,
                    object_id: props._id,
                    tags_info,
                    file,
                    document_id: props.data._id,
                    file_name,
                    update_version: update_version,
                    update_document: file?.name ? true : false,
                    links: update_version ? linkUnlink.link : linkUnlink.unlink,
                    unlinks: update_version ? linkUnlink.unlink : [],
                    object_type: props.objectType,
                    document_type
                });
            } else {
                let body: any = {
                    comment: state.note,
                    tags_info,
                    file_name: `${file_name.trim()}${props?.defaultFileType ?? ""}`,
                    document_type
                };

                if (props.objectType) {
                    body.links = [{ object_ids: [props._id], object_type: props.objectType }];
                } else {
                    body.links = []
                }
                createDocument([body]);
            }
        } else {
            setError({
                ...error,
                location_tags: !state.location_tags, document_tags: !state.document_tags, tag_group: !state.tag_group, tags: !state.tags, note: !state.note, upload: !upload
            })
        }
    };

    const giveMeButtonName = (cdData: any) => {
        let documentMode = props.documentMode ? props.documentMode : "Document"
        if (cdData.isError) {
            return `${props.isEdit ? `Retry Update ${documentMode}` : `Retry Create ${documentMode}`}`
        }

        return `${props.isEdit ? `Update ${documentMode}` : `Create ${documentMode}`}`;
    };

    const giveMeInfoText = () => {
        if (props.isEdit && updateDocumentData.isError) {
            let error: any = updateDocumentData.error;
            return <Alert severity="error">{`${error?.title}`}</Alert>
        }
        if (createDocumentData.isError) {
            const error: any = createDocumentData.error;
            return <Alert severity="error">{error?.data?.title ?? "Oops! Something went wrong, Unable to create Document"}</Alert>
        }
    };

    const openSocket = (id: string, sequence_id: string, version: number) => {
        updateSocketConnection(true);
        return new Promise(async (resolve, reject) => {
            try {
                const socketPayload = {
                    "name": "document-status",
                    "payload": {
                        "document_id": id
                    }
                };
                await open(socketPayload);
                dispatch(notify({
                    name: "document-status",
                    id: id,
                    status: "In Progress",
                    content: `${sequence_id ? "Updating" : "Creating"} document for seqID is (0)`,
                    sequenceID: `${sequence_id ?? "New"}-r${version ?? "0"}`,
                    subs: [
                        "in progress"
                    ],
                    message: ""
                }));
                dispatch(toggleBadge(true));
                resolve(true);
            } catch (error) {
                console.error('error:', error)
                resolve(false);
            } finally {
                updateSocketConnection(false);
            }

        })
    };

    // Sequence Number related
    const generateSerialNumbersForTemplate = async (
        id?: string | null,
        serial_num?: string,
        type: AssignmentStatus = "assign",
        isManual?: boolean
    ) => {
        if (props.data._id) {
            let message = type === "assign"
                ? "Assigning Serial Number..."
                : "Un assigning Serial Number...";
            dispatch(openBackdrop(message));
            await assignSequenceNumberQuery({
                id: id,
                status: type,
                manual_serial_num: isManual,
                payload: [
                    {
                        object_type: "document",
                        object_id: props.data._id,
                        serial_num: serial_num || undefined,
                    },
                ],
            })
                .unwrap()
                .then(() => {
                    let message =
                        type === "assign"
                            ? "Serial Number assigned successfully!"
                            : "Serial Number unassigned successfully!";
                    enqueueSnackbar(message, {
                        variant: "success",
                    });
                    props.refetch()
                })
                .catch(() => {
                    let message =
                        type === "assign"
                            ? "Assigning Serial Number Failed..."
                            : "Un assigning Serial Number Failed...";
                    enqueueSnackbar(message, {
                        variant: "error",
                    });
                })
                .finally(() => {
                    dispatch(closeBackdrop());
                    dispatch(closeDialog());
                });
        }
    };

    const handleAssignClick = () => {
        dispatch(
            openDialog({
                title: "",
                hideNegativeBtn: true,
                hidePositiveBtn: true,
                enablePadding: false,
                body: <Assign onAssign={(id?: string | null, serial_num?: string, isManual?: boolean, type: AssignmentStatus = "assign") => generateSerialNumbersForTemplate(id ?? "", serial_num ?? "", type, isManual)} />,
            })
        );
    };

    const handleUnAssignClick = () => {
        dispatch(
            openDialog({
                title: "",
                hideNegativeBtn: true,
                hidePositiveBtn: true,
                body: (
                    <UnAssign
                        onCancel={() => dispatch(closeDialog())}
                        onUnassign={() => {
                            generateSerialNumbersForTemplate(
                                null,
                                props?.data?.serial_num,
                                "unassign"
                            );
                        }}
                    />
                ),
            })
        );
    };

    const onDelete = () => {
        updateUpload(null);
    };

    const onUpload = (file: any) => {
        if (file) {
            updateUpload(file)
        }
    };

    const getDiagramTemplateFile = async (url: string) => {
        try {
            const res = await fetch(url).then((res) => res.blob());
            setUpload(res);
        } catch (error) {
            console.error(`Error getting template file: ${error}`);
            setUpload(props.defaultFile);
        }
    };

    const linkedTo = React.useMemo(() => {
        let associations = props.data?.associations ?? [];
        let references = props.data?.references ?? [];

        return [...associations, ...references]
    }, [props.data])

    React.useEffect(() => {
        const updateCreateDocumentStatus = async () => {
            if (createDocumentData.isSuccess) {
                enqueueSnackbar("Document Created Successfully", { variant: "success" });

                props.refetch();
                if (createDocumentData.data?.url) {
                    enqueueSnackbar("Uploading your document...", { variant: "info" });
                    await openSocket(createDocumentData.data?._id, createDocumentData.data?.sequence_id, createDocumentData.data?.version);
                    let file = upload;

                    if (!file) {
                        file = await getEmtpyTextFile();
                    }

                    uploadDocument({ data: createDocumentData.data, url: createDocumentData.data?.url, file });
                }
                dispatch(closeDialog());
                if (props.showBOM) {
                    if (state.bom?.value) {
                        dispatch(openBackdrop('Linking BOM to the created Document...'));
                        const res: any = await linkBOM({ object_type: 'document', object_id: createDocumentData.data?._id, payload: { links: [{ object_ids: [state.bom?.value], object_type: "bom" }] } });
                        if (res.error) {
                            enqueueSnackbar(`Unable to link the BOM to the document. Info: ${res.error?.data?.title ?? "Unknown"}`, { variant: "warning" })
                        }
                        dispatch(closeBackdrop());
                    }
                }
            }
            if (createDocumentData.isError) {
                const error: any = createDocumentData.error;
                enqueueSnackbar(error?.data?.title ?? "Oops! Something went wrong, Unable to create Document", { variant: "error" })
            }
        }

        updateCreateDocumentStatus();
        // eslint-disable-next-line
    }, [createDocumentData.status]);

    React.useEffect(() => {
        const updateUpdateDocumentStatus = async () => {
            if (props.isEdit) {
                if (updateDocumentData.isSuccess) {
                    enqueueSnackbar("Document Updated Successfully", { variant: "success" });
                    props.refetch();
                    if (updateDocumentData.data?.url) {
                        enqueueSnackbar("Uploading your document...", { variant: "info" });
                        await openSocket(updateDocumentData.data?._id, updateDocumentData.data?.sequence_id, updateDocumentData.data?.version);
                        let file = upload;

                        if (!file) {
                            file = await getEmtpyTextFile();
                        }
                        uploadDocument({ data: updateDocumentData.data, url: updateDocumentData.data?.url, file });
                    }
                    dispatch(closeDialog());
                    dispatch(clearSelectedChip());
                    if (props.showBOM) {
                        dispatch(openBackdrop(`Linking BOM to the document ${props.Id}...`));
                        await unLinkBOM({ object_type: 'document', object_id: documentData.data?._id, payload: { unlinks: [{ object_ids: documentData?.data?.associations?.filter((_: any) => _.object_type === 'bom')?.[0]?.object_details.map((_: any) => _._id), object_type: "bom" }] } })
                        let res: any = await linkBOM({ object_type: 'document', object_id: updateDocumentData.data?._id, payload: { links: [{ object_ids: [state.bom?.value], object_type: "bom" }] } });
                        if (res.error) {
                            enqueueSnackbar(`Unable to link the BOM to the document. Info: ${res.error?.data?.title ?? "Unknown"}`, { variant: "warning" })
                        }
                    }
                    dispatch(closeBackdrop());
                }
                if (updateDocumentData.isError) {
                    let error: any = updateDocumentData.error;
                    enqueueSnackbar(`${error.title}`, { variant: "error" })
                }
            }
        }

        updateUpdateDocumentStatus();

        // eslint-disable-next-line
    }, [updateDocumentData.status]);

    React.useEffect(() => {
        if (props.folderSelected) {
            setState({
                ...state,
                location_tags: props.folderSelected ?? null,
            })
        }
        // eslint-disable-next-line
    }, [props.folderSelected]);

    React.useEffect(() => {
        if (props.isEdit && props.data?._id && documentData.isSuccess) {

            const { location_tags, document_tags } = giveMeLocationAndDocumentTags(documentData?.data?.tags_info ?? []);

            let bom = null;

            if (props.showBOM) {
                bom = documentData.data?.associations?.filter((_: any) => _.object_type === 'bom')?.[0]?.object_details?.[0];
                bom = bom ? { ...bom, label: `${bom?.sequence_id}-r${bom?.version}` } : null;
            }

            setState({
                ...state,
                note: documentData.data.comment,
                location_tags: location_tags ?? null,
                document_tags: document_tags ?? null,
                file_name: documentData.data.file_name,
                bom: bom
            });

            dispatch(closeBackdrop());
        } else if (documentData.isLoading) {
            dispatch(openBackdrop("Fetching Documents..."));
        } else if (documentData.isSuccess || documentData.isError) {
            dispatch(closeBackdrop());
        }
        // eslint-disable-next-line
    }, [props.isEdit, documentData.status]);

    React.useEffect(() => {
        if (props.isEdit) {
            getDocuments({ document_id: props.data._id });
        }
        // eslint-disable-next-line
    }, []);

    React.useEffect(() => {
        localStorage.setItem('selectedChip', JSON.stringify(selectedChip));
        // eslint-disable-next-line
    }, [selectedChip]);

    React.useEffect(() => {
        if (documentData?.data?.url) {
            setUpload({
                url: documentData?.data?.url,
                size: documentData?.data?.size,
                file_name: documentData?.data?.file_name,
                status: documentData?.data?.status
            });
        }
    }, [documentData?.data]);

    React.useEffect(() => {
        if (state.diagram_template) {
            getDiagramTemplateFile(state.diagram_template?.url)
        } else {
            setUpload(props.defaultFile);
        }
        // eslint-disable-next-line
    }, [props.defaultFile, state.diagram_template]);

    return <>
        {/* Title */}
        <DialogTitle>
            <Typography
                variant="h6"
                color="textPrimary"
                sx={{
                    width: "100%",
                    fontFamily: 'htrts_medium',
                    borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
                }}
            >
                {`${props.isEdit ? "Update" : "Create"} ${props.documentMode ? props.documentMode : 'Document'}`}
            </Typography>
        </DialogTitle>

        {/* Fields */}
        <DialogContent>
            <Grid container spacing={2} alignItems={"center"}>
                {/* Material ID */}
                {false && <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                    <Stack direction={"row"} spacing={1}>
                        <Typography variant="body2" color="textSecondary">Material ID:</Typography>
                        <Typography sx={{ fontWeight: "bold" }} variant="body2" color="textPrimary">{`${props.Id}`}</Typography>
                    </Stack>
                </Grid>}

                {/* Document ID */}
                <Grid item xs={6} sm={5} md={5}>
                    <Stack direction={"row"} spacing={1}>
                        <Typography variant="body2" color="textSecondary">Document ID:</Typography>
                        <Typography sx={{ fontWeight: "bold" }} variant="body2" color="textPrimary">{`${props.isEdit ? props.data.sequence_id : "-"}`}</Typography>
                    </Stack>
                </Grid>

                {/* Version */}
                <Grid item xs={6} sm={5} md={4}>
                    <Stack direction={"row"} spacing={1}>
                        <Typography variant="body2" color="textSecondary">{`${props.isEdit ? "Current" : "Default"} Revision:`}</Typography>
                        <Typography sx={{ fontWeight: "bold" }} variant="body2" color="textPrimary">{`${props.isEdit ? documentData?.data?.version ?? "-" : "0"}`}</Typography>
                        {false && <Link color={"secondary"} variant="caption">View Rev History</Link>}
                    </Stack>
                </Grid>

                <Grid item xs={12} sm={2} md={3}>
                    <Stack direction={"row"} alignItems={"center"}>
                        <Typography variant="body2" color="textSecondary">Status: </Typography>
                        {documentData?.data?.released ? (
                            <Button disableRipple disableFocusRipple sx={{ cursor: "default", '&:hover': { backgroundColor: 'transparent' } }}>
                                <Tooltip
                                    title="Published"
                                    children={<Box width={30} height={30} alignItems={"center"} justifyContent={"center"}><PublishedIcon sx={{ width: "100%", height: "100%" }} /></Box>}
                                />
                            </Button>
                        ) : props.isEdit && (
                            <Button disableRipple disableFocusRipple>
                                <Tooltip
                                    title="Work in progress, click to publish the item"
                                    children={
                                        <Box width={30} height={30} alignItems={"center"} justifyContent={"center"}><WIPIcon sx={{ width: "100%", height: "100%" }} onClick={() => updatePublishStatus(documentData?.data)} /></Box>
                                    }
                                />
                            </Button>
                        )}
                    </Stack>
                </Grid>

                {props.isEdit && <Grid item xs={12}>
                    <GreyAccordian title='Linked To' content={<DeletePanel errorMessageStacks={linkedTo ?? []} />} />
                </Grid>}

                {/* File Name */}
                {props.showFileName && !props.isEdit && <Grid item xs={12}>
                    <TextField
                        sx={{
                            '.MuiFormLabel-colorPrimary': {
                                color: theme.palette.primary.main
                            }
                        }}
                        variant="outlined"
                        label={"Filename"}
                        size={"small"}
                        required
                        fullWidth
                        value={state?.file_name}
                        onChange={(e) => updateState('file_name', e.target.value)}
                        error={error?.file_name}
                        helperText={error?.file_name ? "Please enter filename" : ""}
                    />
                </Grid>}

                {/* BOMs */}
                {props.showBOM &&
                    <Grid item xs={12}>
                        <SelectBox
                            isPaginate
                            loadOptions={loadBOMOption}
                            loading={boms.isLoading || boms.isFetching}
                            options={[]}
                            label={"BOM"}
                            value={state.bom}
                            onChange={(data: any) => updateDropDown("bom", data)}
                            error={error.bom || boms.isError}
                            helperText={error.bom ? "Please select BOM" : boms.isError ? "Unable to Fetch BOMs" : ""}
                        />
                    </Grid>
                }

                {/* Folder */}
                {!props.hideFolderSelect && <Grid item xs={12}>
                    <SelectBox
                        id="folder"
                        label={"Folder"}
                        value={state.location_tags}
                        options={selectOptions?.data?.locationTags ?? []}
                        loading={selectOptions.isLoading}
                        // isMulti={true}
                        labelStartIcon={<Folder htmlColor="#fde100" fontSize="medium" sx={{ verticalAlign: "bottom" }} />}
                        onChange={(data: any) => updateDropDown("location_tags", data)}
                        error={error.location_tags || selectOptions.isError}
                        helperText={error.location_tags ? "Please select Location Tags" : selectOptions.isError ? "Unable to Fetch Location Tags" : ""}
                    />
                </Grid>}

                {/* Document Type */}
                <Grid item xs={12}>
                    <SelectBox
                        id="doc-type"
                        label={"Document Type"}
                        value={state.document_tags}
                        options={selectOptions.data?.documentTags ?? []}
                        loading={selectOptions.isLoading}
                        // isMulti={true}
                        onChange={(data: any) => updateDropDown("document_tags", data)}
                        error={error.document_tags || selectOptions.isError}
                        helperText={error.document_tags ? "Please select Document Tags" : selectOptions.isError ? "Unable to Fetch Document Tags" : ""}
                    />
                </Grid>

                {/* Diagram Template */}
                {props.documentMode === "Drawing" && !props.isEdit &&
                    <Grid item xs={12}>
                        <SelectBox
                            isPaginate
                            loadOptions={loadOption}
                            loading={documentsResponse.isLoading || documentsResponse.isFetching}
                            options={[]}
                            label={"Diagram Templates"}
                            value={state.diagram_template}
                            onChange={(data: any) => updateDropDown("diagram_template", data)}
                        />
                    </Grid>
                }

                {/* General Notes */}
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} mt={0.5}>
                    <TextField
                        id="doc-notes"
                        sx={{
                            '.MuiFormLabel-colorPrimary': {
                                color: theme.palette.primary.main
                            }
                        }}
                        fullWidth
                        label={"Notes"}
                        value={state.note}
                        required={true}
                        error={error.note}
                        helperText={error.note ? "Please enter Notes." : ""}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => updateState("note", e.target.value)}
                    />
                </Grid>

                {/* Serial Number Section */}
                {props.isEdit && documentData?.data?.file_name && <Grid container item xs={12} mt={1}>
                    <Grid item xs={12} md={8}>
                        <Stack flexDirection={"row"} columnGap={1}>
                            <Typography variant='body2' fontFamily={"htrts_semibold"}>Sequence Number:</Typography>
                            <Typography variant='body2'>{props?.data?.serial_num ?? '-'}</Typography>
                        </Stack>
                        {!documentData?.data?.released && <Stack my={2} flexDirection={'row'} gap={2}>
                            <Button variant='contained' disabled={props?.data?.serial_num} sx={{ minWidth: "unset", width: "fit-content" }} onClick={handleAssignClick}>Assign</Button>
                            <Button variant='contained' disabled={!props?.data?.serial_num} sx={{ minWidth: "unset", width: "fit-content" }} onClick={handleUnAssignClick}>Unassign</Button>
                        </Stack>}
                    </Grid>
                </Grid>}

                {/* File name */}
                {props.isEdit && documentData?.data?.file_name && <Grid item xs={12}>
                    <Stack>
                        <Typography gutterBottom variant="body2" color="textSecondary">{`Uploaded File Name`}</Typography>
                        <Typography sx={{ fontWeight: "bold", textDecoration: upload?.name ? "line-through" : "none" }} variant={upload?.name ? "caption" : "body2"} color="textPrimary">{documentData?.data?.file_name ?? "-"}</Typography>
                        {upload?.name && <Typography sx={{ fontWeight: "bold" }} variant="body2" color="textPrimary">{upload?.name ?? "-"}</Typography>}
                    </Stack>
                </Grid>}

                {!props.isEdit && upload?.name &&
                    <Grid item xs={12}>
                        <Stack>
                            <Typography gutterBottom variant="body2" color="textSecondary">{`File Name`}</Typography>
                            <Typography sx={{ fontWeight: "bold" }} variant="body2" color="textPrimary">{upload?.name ?? "-"}</Typography>
                        </Stack>
                    </Grid>
                }

                {/* Upload Image */}
                {!props.hideUpload && <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <ImageUploadList
                        title='Upload Document'
                        disabled={createDocumentData.isLoading || updateDocumentData.isLoading}
                        files={upload ? [upload] : []}
                        onDelete={onDelete}
                        onUpload={onUpload}
                        uploadAllFileTypes={true}
                        uploadError={error.upload}
                        sizeLimit={'10GB'}
                        disableCollapse
                        hidePreview
                    />
                </Grid>}

                {/* Info Tex */}
                <Grid item xs={12}>
                    {giveMeInfoText()}
                </Grid>

                {documentData?.data?.released && <Grid item xs={12}>
                    <Alert severity="info">{
                        "This item is published. Changes, overwrite or deleting is not allowed. Only revision is allowed."
                    }</Alert>
                </Grid>}
            </Grid>
        </DialogContent>

        <DialogActions>
            <Grid container spacing={2} px={2} pb={1}>
                {/* Add Noun Btn */}
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                    <LoadingButton
                        id="doc-btn"
                        fullWidth
                        variant="contained"
                        onClick={onCreateUpdateBtnClicked}
                        loading={createDocumentData.isLoading || updateDocumentData.isLoading || connectingSocket}
                    >
                        {giveMeButtonName(createDocumentData)}
                    </LoadingButton>
                </Grid>

                {/* Cancel Btn */}
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                    <Button
                        fullWidth
                        variant="outlined"
                        onClick={onCancelBtnClicked}
                        disabled={createDocumentData.isLoading || updateDocumentData.isLoading || connectingSocket}
                    >
                        {"Cancel"}
                    </Button>
                </Grid>
            </Grid>
        </DialogActions>
    </>
}