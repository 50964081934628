// AppBackDrop is used to show the backdrop component all over the app whenever it needed with the use of backdrop actions created in /redux/slices/backdrop.js
import React from "react";
import { Grid, Typography, CircularProgress, Backdrop, useTheme, IconButton } from "@mui/material";
import { closeBackdrop } from "./redux/slices/backdrop";
import { useAppDispatch, useAppSelector } from "./redux";
import { Close } from "@mui/icons-material";

export const AppBackDrop: React.FC<{ children: JSX.Element }> = ({ children }) => {
    const theme = useTheme();
    const dispatch = useAppDispatch();

    const { open, message, hideProgress } = useAppSelector((state) => state.backdrop);

    const close = () => { dispatch(closeBackdrop()) }

    return <>
        {children}
        <Backdrop sx={{ zIndex: theme.zIndex.drawer + 1000, color: theme.palette.background.paper }} open={open} >
            {hideProgress && <IconButton
                aria-label="close"
                title="Close"
                onClick={() => close()}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 4,
                }}
                size="large"
            >
                <Close fontSize="large" htmlColor="white" />
            </IconButton>}
            <Grid container direction="column" alignItems="center" justifyContent="center">
                {!hideProgress && <Grid item>
                    <CircularProgress color="inherit" />
                </Grid>}
                <Grid item>
                    <Typography variant="h6" color="inherit" >{message}</Typography>
                </Grid>
            </Grid>
        </Backdrop>
    </>;
}