import React from 'react';
import { Typography, List, ListItemText, useTheme, Box, Theme, ListItemButton } from '@mui/material';
import { MastersInterface } from '../../interfaces';

const useStyles = (theme: Theme) => ({
    root: {
        flexGrow: 1,
        background: theme.palette.background.paper,
        height: '100%',
        borderRadius: theme.spacing(1),
        boxShadow: `rgba(99, 99, 99, 0.2) 0px 2px 8px 0px`
    },
    headerText: {
        paddingLeft: theme.spacing(2),
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        borderBottom: `1px solid ${theme.palette.divider}`,
        fontFamily: 'htrts_bold'
    }
})

export const LeftPanel: React.FC<{ selectedMaster: any, onMasterClicked: any, allMasters: MastersInterface[], title?: string }> = ({
    selectedMaster = {},
    onMasterClicked = () => false,
    title = "Repository",
    allMasters
}) => {

    const classes = useStyles(useTheme());

    return <Box sx={classes.root}>
        {/* Header */}
        <Typography variant="body2" sx={classes.headerText}>{title}</Typography>

        {/* Masters */}
        <List component="nav">
            {allMasters.map((master, key) => {
                return <React.Fragment key={key}>
                    {master.is_active && <ListItemButton
                        key={key}
                        selected={selectedMaster.value === master.value}
                        onClick={(event) => onMasterClicked(master)}
                    >
                        <ListItemText primary={master.label} primaryTypographyProps={{ variant: "body2" }} />
                    </ListItemButton>}
                </React.Fragment>
            })}
        </List>
    </Box>
}