import { DeleteOutline, ExpandMore } from "@mui/icons-material";
import { Stack, Typography, Divider, Button, Accordion, AccordionSummary, IconButton, AccordionDetails, Skeleton, Box } from "@mui/material";
import React from "react";
import { SectionPaper } from "../../../components";
import { useAppDispatch, useAppSelector } from "../../../redux";
import { MappingInfo } from './mappingInfo';
import { addSelection, deleteSelection, setInitialValues } from "../../../redux/slices/supplierMapping";
import { useGetNounsDDQuery, useGetUoMDropDownQuery, useGetGroupsDDQuery, useLazyGetNounVariantByNounIdDDQuery } from '../../../redux/services';

export const MaterialMapping: React.FC<{ children?: JSX.Element, initialSelections?: any, isEdit: boolean, readOnly?: boolean | undefined }> = (props) => {

    const { selections } = useAppSelector((state) => state.supplierMap);
    const nouns = useGetNounsDDQuery(null);
    const uomData = useGetUoMDropDownQuery(null);
    const groups = useGetGroupsDDQuery(null);
    const [getVariantsByNounId, nounVariantData] = useLazyGetNounVariantByNounIdDDQuery();
    const dispatch = useAppDispatch();

    const onAddMappingClicked = () => {
        dispatch(addSelection({ nouns }))
    };

    const onDelSelectionClicked = (index: number) => {
        dispatch(deleteSelection({ index }))
    };

    React.useEffect(() => {
        const prepareMappingInitialState = async () => {
            if (props.isEdit && props.initialSelections?.length > 0 && uomData.isSuccess && groups.isSuccess && nouns.isSuccess) {
                let mappedArr = [];
                if (props.initialSelections.length > 0) {
                    for (let i = 0; i < props.initialSelections.length; i++) {
                        const data = props.initialSelections[i];
                        let filteredVariants: any[] = []
                        if (data?.noun_id) {
                            const res = await getVariantsByNounId(data?.noun_id);
                            filteredVariants = res?.data?.filter((obj: any) => data?.noun_variant_ids?.includes(obj._id)) ?? [];
                        }
                        mappedArr.push({
                            uom_id: uomData?.data?.filter((_: any) => (_._id === data?.uom_id))?.[0],
                            group_id: groups?.data?.filter((_: any) => (_._id === data?.group_id))?.[0],
                            noun_id: nouns?.data?.filter((_: any) => (_._id === data?.noun_id))?.[0],
                            noun_variant_ids: filteredVariants ? filteredVariants : []
                        })
                    }
                }
                dispatch(setInitialValues({ mappedArr }));
            }
        }
        prepareMappingInitialState();
        // eslint-disable-next-line
    }, [props.isEdit, props.initialSelections, uomData.status, groups.status, nouns.status])

    const isLoading = uomData.isLoading || groups.isLoading || nouns.isLoading || nounVariantData.isLoading || uomData.isFetching || groups.isFetching || nouns.isFetching;

    return <SectionPaper contentPadding={0} title={"Material Mapping"} renderButtons={[
        <Button disabled={isLoading || props.readOnly} size="small" variant="contained" color="primary" onClick={onAddMappingClicked}>Add Mapping</Button>
    ]}>
        <Stack divider={<Divider />} height={"100%"}>
            {isLoading && <Box px={1}>
                {[1].map(_ => (<Skeleton width={"100%"} height={"120px"} />))}
            </Box>}

            {/* Details */}
            <Stack spacing={1} p={1}>
                {!isLoading && selections && (selections || [])?.map((sel, key) => {
                    return <Accordion key={key} variant="outlined" id="accordion">
                        {/* Header  */}
                        <AccordionSummary expandIcon={<ExpandMore htmlColor="black" />} sx={{ background: (theme) => theme.palette.background.default }}>
                            <Stack direction={"row"} width={"100%"} alignItems={"center"} justifyContent={"space-between"}>
                                {/* Title */}
                                <Typography variant="body1">{`Mapping ${key + 1}`}</Typography>

                                {/* Delete Selection Button */}
                                {key > 0 && !props.readOnly && <IconButton onClick={(e) => onDelSelectionClicked(key)} size="small" ><DeleteOutline color="error" fontSize="small" /></IconButton>}
                            </Stack>
                        </AccordionSummary>

                        {/* Content */}
                        <AccordionDetails>
                            <MappingInfo readOnly={props.readOnly} index={key} data={sel} initialSelections={props.initialSelections} selections={selections} isEdit={props.isEdit} />
                        </AccordionDetails>
                    </Accordion>
                })}
            </Stack>
        </Stack>
    </SectionPaper>
}