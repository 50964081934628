import { List } from "@mui/icons-material";
import { Box } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { Layout } from "../../components";
import { AppRoutes } from "../../router/routes";
import { Dashboard } from "./dashboard";

export const DrawioDiagrams: React.FC<{ children?: JSX.Element }> = (props) => {

  const navigate = useNavigate();

  return (
    <Box sx={{ padding: 0.5, height: "100%" }}>
      <Layout
        history={[
          { label: "Drawings", onClick: () => navigate(AppRoutes.viewAllDrawioDiagrams) }
        ]}
        currentPath={"All Drawings"}
        hideNavBarByDefault={true}
        navBars={[
          {
            id: "1",
            title: "All Drawings",
            icon: <List fontSize="small" />,
            path: AppRoutes.viewAllDrawioDiagrams,
          },
        ]}
        mainPanel={<Dashboard />}
        sideNavVariant={"whiteboard"}
      />
    </Box>
  );
};