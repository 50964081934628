import { Box, Grid } from "@mui/material";
import React from "react";
import { useBlocker, useBeforeUnload, useParams } from "react-router-dom";
import { withNavBars } from "../../HOCs";
import { InternatlServerError, LoadingSection, NotFoundSection } from "../../components";
import { useAppDispatch, useAppSelector } from "../../redux";
import { useLazyGetDocumentTemplateByIdQuery } from "../../redux/services/documentTemplate";
import { addHeaderFooterTemplate, giveMeHeaderFooterTemplateInitialState } from "../../redux/slices/documentTemplate";
import { ConfigurePanel } from "./configurePanel";
import { HeaderFooterTemplatePreview } from "./previewPanel";
import { closeConfirmationDialog, openConfirmationDialog } from "../../redux/slices/confirmationDialog";
import copy from "fast-copy";

const ViewTemplate: React.FC<{ children?: JSX.Element; currentPath: string; }> = ({ currentPath }) => {

  const params = useParams();
  const dispatch = useAppDispatch();
  const { documentTemplate } = useAppSelector(store => store);
  const template = documentTemplate.headerFooterTemplate;

  const [getTemplateId, { data, isFetching, isLoading, isError, isSuccess }] = useLazyGetDocumentTemplateByIdQuery();

  const refetch = async () => {
    const data = await getTemplateId({ document_template_id: params?.id }).unwrap();
    dispatch(addHeaderFooterTemplate(data));
  }

  const originalTemplate = () => {
    if (data) {
      let copyTemplate = copy(giveMeHeaderFooterTemplateInitialState(data));
      copyTemplate.attachments = [];
      copyTemplate.logo.src = null;
      return copyTemplate;
    } else {
      return {};
    }
  };

  const updatedTemplate = () => {
    if (template) {
      let copyTemplate = copy(template);
      copyTemplate.attachments = [];
      copyTemplate.logo.src = null;
      return copyTemplate;
    }
    return template;
  };

  // eslint-disable-next-line
  const isChangesMade = () => JSON.stringify(originalTemplate()) !== JSON.stringify(updatedTemplate());

  // Block navigating elsewhere when data has been entered into the input for router navigation
  let blocker = useBlocker(({ currentLocation, nextLocation }) => isChangesMade() && currentLocation.pathname !== nextLocation.pathname);

  // Block navigating elsewhere when data has been entered into the input for browser
  useBeforeUnload(React.useCallback((event) => {
    if (isChangesMade()) {
      event.preventDefault()
      // eslint-disable-next-line no-param-reassign
      event.returnValue = "Changes that you made may not be saved."
    }
    // eslint-disable-next-line
  }, [template]), { capture: true })

  React.useEffect(() => {
    refetch();
    // eslint-disable-next-line
  }, [params?.id]);

  React.useEffect(() => {
    if (blocker && blocker.state === 'blocked') {
      dispatch(openConfirmationDialog({
        title: "Do you want to leave?",
        body: "Changes that you made may not be saved.",
        positiveBtn: "Leave",
        negativeBtn: "Cancel",
        onOk: () => {
          if (blocker.proceed) {
            blocker.proceed();
          }
          dispatch(closeConfirmationDialog());
        },
        onNegativeBtn: () => {
          if (blocker.reset) {
            blocker.reset();
          }
          dispatch(closeConfirmationDialog());
        }
      }));
    }
    // eslint-disable-next-line
  }, [blocker]);

  if (isFetching || isLoading) {
    return <LoadingSection message="Fetching Template Details..." />
  }

  if (isError) {
    return <InternatlServerError message="Something went wrong unable to fetch Template Details, Try again later!" onRetry={() => refetch()} />
  }

  if (isSuccess) {
    return (
      <Box height={"100%"} sx={{ overflowY: "hidden" }}>
        <Grid container height={"calc(100dvh - 60px)"} sx={{ overflowY: "hidden" }} alignItems={"center"}>
          <Grid item xs={12} sm={3} md={4} height={"100%"} sx={{ overflowY: "auto" }} paddingRight={{ xs: 0, sm: 1 }} pb={"4px"}>
            <ConfigurePanel />
          </Grid>
          <Grid item xs={12} sm={9} md={8} height={"100%"} sx={{ overflowY: "hidden" }} id="blue">
            <HeaderFooterTemplatePreview />
          </Grid>
        </Grid>
      </Box>
    );
  }

  return <NotFoundSection message="Unable to find the requested template." />
};

export default withNavBars(ViewTemplate);