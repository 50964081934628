import { Box } from '@mui/material';
import { useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Layout } from '../../../../components';
import { withNavBars } from '../../../../HOCs';
import { ViewIssueDetails } from './viewIssueDetails';

function ViewIssueTrackerWithId() {
    const { issue_id = '' } = useParams();

    const navigate = useNavigate();

    const location: any = useLocation();

    const paths = location?.state?.paths ?? [];

    const [sequenceId, setSequenceId] = useState("...");

    return (
        <Box p={0.5} height={"100%"}>
        <Layout
            history={[
                ...paths.map((_: any) => {
                  return { label: _.title, onClick: () => navigate(_.path) };
                }),
                { label: "Issue Tracker", onClick: () => navigate(-1) },
            ]}
            navBars={[]}
            currentPath={sequenceId}
            mainPanel={
                <ViewIssueDetails isEdit={issue_id !== 'New'} setSequenceId={setSequenceId} />
            }
            sideNavVariant={"whiteboard"}
            otherBreadscrumProps={{                
                hideMenuBtn: true,
                showBackBtn: true
            }}
            onBackBtnClick={paths[0]?.path ? () => navigate(paths[0]?.path) : undefined}
        />
    </Box>
       
    )
}

export default withNavBars(ViewIssueTrackerWithId);