import { createSlice } from '@reduxjs/toolkit';

interface initialStateType {
    open: boolean,
    message: string,
    hideProgress: boolean,
}

const initialState: initialStateType = {
    open: false,
    message: "",
    hideProgress: false,
}

const backdropSlice = createSlice({
    name: 'backdrop',
    initialState,
    reducers: {
        openBackdrop(state, { payload }: { payload: string }) {
            state.open = true;
            state.message = payload;
            state.hideProgress = false;
        },
        closeBackdrop(state) {
            state.open = false;
            state.message = "";
            state.hideProgress = false;
        },
        openBackdropWithContent(state, { payload }: { payload: any }) {
            state.open = true;
            state.message = payload;
            state.hideProgress = true
        }
    },
})

export const { openBackdrop, closeBackdrop, openBackdropWithContent } = backdropSlice.actions;
export default backdropSlice.reducer;