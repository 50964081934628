import React from "react";
import { CustomDataGrid, SectionPaper } from '../../../components';
import { GridActionsCellItem, GridEventListener, GridRenderCellParams, GridRowEditStopReasons, GridRowId, GridRowModel, GridRowModes, GridRowModesModel } from '@mui/x-data-grid-premium';
import { Box, Stack, styled, Typography } from '@mui/material';
import copy from 'fast-copy';
import { Cancel, Edit, Lock, Save } from '@mui/icons-material';
import { useSnackbar } from 'notistack';
import { LoadingButton } from "@mui/lab";

export const StyledDataGrid = styled(CustomDataGrid)(() => ({
    // borderLeftWidth: 0,
    // borderRightWidth: 0,
}));

const CustomTable = ({ data, state, updateState, index, preview = false, onUpdate, includeCheckbox = false, searchResponse }: any) => {

    const { enqueueSnackbar } = useSnackbar();

    const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>({});
    const [isUpdateRequired, setUpdateRequired] = React.useState(false);

    const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
        if (Object.keys(rowModesModel || {}).length === 0) {
            setRowModesModel(newRowModesModel);
        }
    };

    const handleEditClick = (id: GridRowId) => () => {
        const field = Object.keys(rowModesModel || {})?.[0];
        if (!field) {
            setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
        }
    };

    const handleSaveClick = (id: GridRowId) => () => {
        const field = Object.keys(rowModesModel?.[id] || {})?.[0];
        if (field) {
            setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
            setUpdateRequired(true);
        }
    };

    const handleCancelClick = (id: GridRowId) => () => {
        const field = Object.keys(rowModesModel?.[id] || {})?.[0];
        if (field) {
            setRowModesModel({
                ...rowModesModel,
                [id]: { mode: GridRowModes.View, ignoreModifications: true },
            });
        }
    };

    const handleProcessRowUpdateError = React.useCallback((error: Error) => {
        console.error("error:", error);
        enqueueSnackbar(error.message, { variant: "error" });
        // eslint-disable-next-line
    }, [])

    const processRowUpdate = (newRow: GridRowModel, index: number) => {
        const updatedRow: any = { ...newRow, isNew: false };
        let newCategories = copy(state);
        newCategories[index].definition.rows[updatedRow.id - 1] = updatedRow;
        updateState(newCategories);
        return updatedRow;
    };

    const handleRowEditStop: GridEventListener<"rowEditStop"> = (
        params,
        event
    ) => {
        if (params.reason === GridRowEditStopReasons.rowFocusOut) {
            event.defaultMuiPrevented = true;
        }
    };

    React.useEffect(() => {
        let key = Object.keys(rowModesModel || {})?.[0];
        if (key) {
            if (rowModesModel[key]?.mode === "view") {
                setRowModesModel({});
            }
        }
        // eslint-disable-next-line
    }, [rowModesModel]);

    const Columns = React.useCallback((table: any) => {
        let cols = [{
            field: 'actions',
            type: 'actions',
            headerName: 'Actions',
            width: 100,
            cellClassName: 'actions',
            sortable: false,
            hideSortIcons: true,
            disableColumnMenu: true,
            resizable: false,
            renderHeader: () => {
                return (
                    <Box sx={{ bgcolor: `${data?.header_color ? `${data?.header_color} !important` : 'rgb(230, 229, 229) !important'}`, width: "100% !important", px: 1 }}>
                        <strong>Actions</strong>
                    </Box>
                )
            },
            getActions: ({ id }: any) => {
                const isInEditMode =
                    rowModesModel?.[id]?.mode === GridRowModes.Edit;

                if (isInEditMode) {
                    return [
                        <GridActionsCellItem
                            disableRipple
                            icon={<Save sx={{ height: "36px" }} />}
                            label="Save"
                            sx={{
                                color: "primary.main",
                            }}
                            onClick={handleSaveClick(id)}
                        />,
                        <GridActionsCellItem
                            disableRipple
                            icon={<Cancel />}
                            label="Cancel"
                            className="textPrimary"
                            onClick={handleCancelClick(id)}
                            color="inherit"
                        />,
                    ];
                }

                return preview ? [<Box sx={{ height: "36px" }}></Box>] : [
                    <GridActionsCellItem
                        disableRipple
                        icon={<Edit sx={{ height: "36px" }} />}
                        label="Edit"
                        className="textPrimary"
                        onClick={handleEditClick(id)}
                        color="inherit"
                    />,
                ]
            }
        },
        ...table.definition?.columns?.map((_: any, idx: number) => {
            return {
                ..._,
                sortable: false,
                hideSortIcons: true,
                disableColumnMenu: true,
                renderHeader: () => {
                    return (
                        <Stack display={"flex"} direction={"row"} alignItems={"center"} spacing={1} key={idx} sx={{ bgcolor: `${data?.header_color ? `${data?.header_color} !important` : 'rgb(230, 229, 229) !important'}`, width: "100% !important", px: 1 }}>
                            <strong>{_.header_name}</strong>
                            {!_?.editable && <Lock color="error" fontSize="small" />}
                        </Stack>
                    )
                },
            }
        })
        ]

        if (includeCheckbox) {
            cols.shift();
        }

        if (searchResponse?.hasScore) {
            cols.push({
                field: "score",
                headerName: "Score",
                width: 350,
                renderHeader: () => {
                    return (
                        <Box sx={{ bgcolor: `${data?.header_color ? `${data?.header_color} !important` : 'rgb(230, 229, 229) !important'}`, width: "100% !important", px: 1 }}>
                            <strong>Score</strong>
                        </Box>
                    )
                },
                renderCell: (params: GridRenderCellParams) => {
                    let key = data?.definition?.columns[0]?.field;
                    const d = searchResponse?.response?.matching_fields?.[params.row?.[key]];
                    if (params.rowNode.type === "group") {
                        return params.value;
                    }
                    return (
                        <Typography
                            variant="body2"
                            sx={{
                                color: d?.score === 1 ? "green" : "primary.main",
                                textDecoration: d?.score ? "underline" : "none",
                                cursor: d?.score ? "pointer" : 'default'
                            }}
                            onClick={() => searchResponse?.onOpenMatches({ ...d, title: params.row?.[key] })}
                        >
                            {d?.score ? (d?.score * 100).toFixed(2) : "No Match Found"}
                        </Typography>
                    );
                },
            },)
        }

        return table.definition?.columns.length > 0 ? cols : [];
        // eslint-disable-next-line
    }, [data, rowModesModel, JSON.stringify(searchResponse)]);

    const getRows = () => {
        if (!searchResponse) {
            return data.definition.rows;
        } else {
            const keys = Object.keys(searchResponse?.response?.matching_fields || {});
            return data.definition.rows.filter((r: any) => {
                let values = Object.values(r)
                let res = keys.map(k => values.includes(k))
                return res.filter(Boolean).length > 0;
            });
        }
    }

    const rows = React.useMemo(() => {
        return getRows();
        // eslint-disable-next-line
    }, [data.definition.rows, searchResponse])

    React.useEffect(() => {
        if (isUpdateRequired) {
            onUpdate(() => setUpdateRequired(false));
        }
        // eslint-disable-next-line
    }, [data?.definition?.rows])

    return (
        <SectionPaper
            title={data?.name ?? ""}
            subTitle={`${data?.description ? `(${data?.description})` : ""}`}
            contentPadding={1}
            renderButtons={includeCheckbox ? [
                <LoadingButton
                    fullWidth
                    size="medium"
                    variant="outlined"
                    sx={{ width: 200 }}
                    onClick={() => {
                        let newCategories = copy(state);
                        newCategories[index].rowSelectionModel = [];
                        updateState(newCategories);
                    }}
                >
                    Reset
                </LoadingButton>
            ] : []}
        >
            <StyledDataGrid
                saveLocal={false}
                rows={rows}
                columns={Columns(data)}
                loading={false}
                showToolbar={false}
                getRowId={(row) => row.id}
                processRowUpdate={(newRow) =>
                    processRowUpdate(newRow, index)}
                onRowEditStop={handleRowEditStop}
                rowModesModel={rowModesModel}
                onRowModesModelChange={handleRowModesModelChange}
                onProcessRowUpdateError={handleProcessRowUpdateError}
                onCellDoubleClick={(e) => (e.isEditable = false)}
                getRowHeight={() => 'auto'}
                hideFooter
                pagination={false}
                checkboxSelection={includeCheckbox}
                onRowSelectionModelChange={(newRowSelectionModel) => {
                    if (includeCheckbox) {
                        let newCategories = copy(state);
                        newCategories[index].rowSelectionModel = newRowSelectionModel;
                        updateState(newCategories);
                    }
                }
                }
                rowSelectionModel={includeCheckbox ? state[index].rowSelectionModel : undefined}
                sx={{
                    width: data.definition.columns
                        .map((c: any) => c.width)
                        .reduce((acc: number, sum: number) => acc + sum) + 2 + (includeCheckbox ? 50 : 100) + (searchResponse?.hasScore ? 350 : 0), // 2 For border width on both sides and 100 for actions column
                    "& .MuiDataGrid-row:hover": {
                        backgroundColor: "white",
                    },
                    "& .MuiInputBase-root": {
                        border: "1px solid rgba(224, 224, 224, 1)",
                        borderRadius: 1,
                        px: 1,
                        py: 0,
                    },
                    "& .MuiDataGrid-editInputCell": {
                        border: "1px solid rgba(224, 224, 224, 1)",
                        mx: 1,
                        borderRadius: 1,
                        py: "5px !important",
                        px: "0px !important",
                    },
                    '.MuiDataGrid-columnHeaderTitleContainer': {
                        width: "100% !important",
                    },
                    '.MuiDataGrid-columnHeaderTitleContainerContent': {
                        width: "100% !important",
                    },
                    '.MuiDataGrid-columnHeader': {
                        padding: "0 !important",
                    }
                }}
            />
        </SectionPaper>
    )
}

export default CustomTable;