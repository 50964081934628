import { Alert, Box, Button, Stack } from "@mui/material";
import React from "react";
import {
  CustomDataGrid,
  DeletePanel,
  MyPaper,
} from "../../../components";
import { useAppDispatch } from "../../../redux";
import {
  useLazyGetBOMsQuery,
} from "../../../redux/services";
import { openDialog } from "../../../redux/slices/dialog";
import { openConfirmationDialog } from "../../../redux/slices/confirmationDialog";
import { useParams } from "react-router-dom";
import { DashboardTableColumn } from "../../boms/utils";
import { LinkChildBOM } from "../../viewBOMTree/linkBOM";
import { GridSortDirection, GridSortModel } from "@mui/x-data-grid-premium";

export const IssueBOMs: React.FC<{
  refetch?: any;
  _id?: string;
  objectType?: string;
  linkBOMs?: any;
  associations?: any;
  isViewOnly: boolean;
}> = (props) => {
  const dispatch = useAppDispatch();
  const [
    getBOMs,
    { data: allData = { data: [], totalCount: 0 }, isLoading, isError },
  ] = useLazyGetBOMsQuery();
  const { data, totalCount } = allData;

  const [links, setLinks] = React.useState([]);

  const [paginationModel, setPaginationModel] = React.useState({
    page: 0,
    pageSize: 10,
  });
  const [queryOptions, setQueryOptions] = React.useState({
    sortModel: [{ field: 'sequence_id', sort: 'desc' as GridSortDirection }],
  });

  const { issue_id = "" } = useParams();

  const handleSortModelChange = React.useCallback((sortModel: GridSortModel) => {
    // Here you save the data you need from the sort model
    setQueryOptions({ sortModel: [...sortModel] });
  }, [])

  React.useEffect(() => {
    refetch();
    // eslint-disable-next-line
  }, [props._id, paginationModel, JSON.stringify(queryOptions?.sortModel)]);

  const openLinkBOM = () => {
    dispatch(
      openDialog({
        title: "",
        hideNegativeBtn: true,
        hidePositiveBtn: true,
        maxWidth: "md",
        hideCloseIcon: true,
        enablePadding: false,
        body: (
          <LinkChildBOM
            id={props._id}
            associations={props.associations}
            callLinkDocument={(linkIds, id: string) =>
              props.linkBOMs(linkIds as string[], refetch, [], "bom")
            }
            isGeneral
            skipQty
          />
        ),
      })
    );
  };

  const openUnLinkBOMs = () => {
    props.linkBOMs([], refetch, links, "bom");
  };

  const giveMeRows = () => {
    if (data && !isLoading) {
      return data;
    } else {
      return [];
    }
  };

  let ROWS = giveMeRows();

  const refetch = () => {
    getBOMs({
      include_histories: false,
      object_type: "issue",
      object_id: issue_id,
      sort_by: queryOptions?.sortModel?.[0]?.field, 
      sort_order: queryOptions?.sortModel?.[0]?.sort,
      ...paginationModel,
    });
  };

  const openAssociation = (bom: any) => {
    dispatch(
      openConfirmationDialog({
        title: "Associations",
        body: (
          <DeletePanel
            message={
              bom?.associations
                ? bom.associations.length === 0
                  ? "No dependency found"
                  : `Dependency Table of ${bom?.sequence_id}-r${bom.version}`
                : "No dependency found"
            }
            errorMessageStacks={bom?.associations ?? []}
          />
        ),
        hideNegativeBtn: true,
        hidePositiveBtn: true,
      })
    );
  };

  const openReferences = (bom: any) => {
    dispatch(openConfirmationDialog({
      title: "References",
      body: <DeletePanel
        message={bom?.references ? bom.references.length === 0 ? 'No references found' : `References Table of ${bom?.sequence_id}-r${bom.version}` : "No references found"}
        errorMessageStacks={bom?.references ?? []}
      />,
      hideNegativeBtn: true,
      hidePositiveBtn: true
    }))
  }

  const onRowSelection = (selected: any) => {
    setLinks(selected);
  };

  return (
    <MyPaper height={"100%"}>
      <Box>
        {/* Header */}
        {!props.isViewOnly &&
          <Stack
            sx={{ flexDirection: { xs: "column", sm: "row" } }}
            alignItems={"center"}
            justifyContent={"flex-end"}
            gap={1}
          >
            <Button
              variant={"contained"}
              color={"primary"}
              onClick={() => {
                openLinkBOM();
              }}
              sx={{ fontSize: (theme) => theme.spacing(1.75), width: 250 }}
              disabled={links.length > 0}
            >
              Link BOM
            </Button>
            <Button
              variant={"contained"}
              color={"primary"}
              onClick={() => {
                openUnLinkBOMs();
              }}
              disabled={links?.length > 0 ? false : true}
              sx={{ fontSize: (theme) => theme.spacing(1.75), width: 250 }}
            >
              UnLink BOM
            </Button>
          </Stack>
        }

        {/* Error */}
        {isError && (
          <Alert sx={{ mt: 2 }} severity="error">
            Oops! Something went wrong, Unable to fetch BOMs. Try Again Later!
          </Alert>
        )}

        {/* Data Grid */}
        <Box mt={2}>
          <CustomDataGrid
            id="issue-tracker-boms"
            // initialState={{
            //   sorting: {
            //     sortModel: [{ field: "sequence_id", sort: "desc" }],
            //   },
            // }}
            rows={ROWS}
            columns={DashboardTableColumn({ openAssociation, disableNavigation: false, removeDelete: true, openReferences })}
            loading={isLoading}
            pagination
            getRowId={(row) => row._id}
            showToolbar={true}
            checkboxSelection={!props.isViewOnly}
            onRowSelectionModelChange={(selected) => {
              onRowSelection(selected);
            }}
            isRowSelectable={() => true}
            rowCount={totalCount}
            paginationModel={paginationModel}
            paginationMode="server"
            onPaginationModelChange={setPaginationModel}
            sortingMode="server"
            onSortModelChange={handleSortModelChange}
          />
        </Box>
      </Box>
    </MyPaper>
  );
};
