import { Box } from "@mui/material";
import { useSnackbar } from "notistack";
import React from "react";
import { matchPath, useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { withNavBars } from "../../HOCs";
import { Layout, Documents } from "../../components";
import { useAppDispatch, useAppSelector } from "../../redux";
import { useLazyGetProductByIdQuery, useLazyGetDocumentsByTagGroupIdQuery, useGetLocationAndDocumentTagsQuery, useLinkModuleMutation, useUnlinkModuleMutation } from "../../redux/services";
import { closeBackdrop, openBackdrop } from "../../redux/slices/backdrop";
import { closeConfirmationDialog, openConfirmationDialog } from "../../redux/slices/confirmationDialog";
import { resetProductSlice, updateProductSlice } from "../../redux/slices/product";
import { AppRoutes } from "../../router/routes";
import { BasicDetails } from "./basicDetails";
import { NavBars } from "./utils";
import { SelectBoxOption } from "../../interfaces";
import { ProductMaterials } from "./material";
import { ProductBOMs } from "./bom";
import BOMReport from "../bomReport";
import ListView from "../listview";
import { linksData } from "../../redux/slices/proposal";
import { Workbook } from "../workbook";
import { IssueTracker } from "../viewProject/issueTracker";
import { Dashboard } from "../drawioDiagrams/dashboard";
import { AllSearches } from "../aiInstantSearch/dashboard";
import { DataTableForm } from "../viewMaterial/dataSheet";
import { HasAccess } from "../../router/authorization";
import { PERMISSIONS } from "../../router/permission";
import { UserAccessManagement } from "../userAccessManagement";

const ViewProduct: React.FC<{ children?: JSX.Element, currentPath: string }> = ({ currentPath }) => {

    const params = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useAppDispatch();
    const [searchParams] = useSearchParams();
    const { enqueueSnackbar } = useSnackbar();
    const { perm } = useAppSelector(store => store.auth.userDetails);

    const [getProductById, productData] = useLazyGetProductByIdQuery({ refetchOnReconnect: true, refetchOnFocus: true });
    const [linkDocument] = useLinkModuleMutation();
    const [unlinkDocument] = useUnlinkModuleMutation();
    const [getDocumentFolders, { currentData: data, isLoading }] = useLazyGetDocumentsByTagGroupIdQuery();
    const folderData = useGetLocationAndDocumentTagsQuery(null);

    const productId = params?.id ?? "Product ID";

    const isEdit = productId !== ':id' && productId !== "New";

    const technicalParameterPath = productData?.data?.associations?.find((_: any) => _.object_type === "dataTableForm")?.object_details?.at(-1)?._id || "New";

    const creatable = React.useMemo(() => HasAccess(perm, PERMISSIONS.PRODUCT_CREATE), [perm]);
    const readable = React.useMemo(() => HasAccess(perm, PERMISSIONS.PRODUCT_READ), [perm]);
    const updatable = React.useMemo(() => HasAccess(perm, PERMISSIONS.PRODUCT_UPDATE), [perm]);
    const deletable = React.useMemo(() => HasAccess(perm, PERMISSIONS.PRODUCT_DELETE), [perm]);
    const publishable = React.useMemo(() => HasAccess(perm, PERMISSIONS.PRODUCT_PUBLISH), [perm]);

    const isViewOnly = isEdit ? !updatable : !creatable;

    const giveMeComponent = () => {
        // Basic Details
        if (matchPath(location.pathname, AppRoutes.viewProductBasicDetail(productId))) {
            return <BasicDetails refetch={() => {
                if (isEdit) {
                    dispatch(openBackdrop("Fetching product details"));
                    getProductById({ id: productId });
                }
            }} productId={productId} />
        }

        // Technical Parameter
        if (matchPath(location.pathname, AppRoutes.viewProductTechParamForm(productId, technicalParameterPath))) {
            return <DataTableForm type="inputParameter" preview={isViewOnly} refetch={refetch} getPath={(path: string) => AppRoutes.viewProductTechParamForm(productId, path)} linkDataTableTemplate={(links: any[], unlinks: any[]) => callLinkDocument('dataTableForm', links, unlinks)} />
        }

        // Documents
        if (matchPath(location.pathname, AppRoutes.viewProductDocuments(productId))) {
            return <Documents
                refetch={refetch}
                Id={productData?.data?.sequence_id ?? ""}
                _id={productId}
                objectType="product"
                tag_id={searchParams.get('tag_id')}
                tag_group_id={searchParams.get('group_id')}
                linkDocument={(links: any, refetch: any) => callLinkDocument('document', links, [], refetch)}
                associations={productData?.data?.associations ?? []}
                onAnnotate={(id: string) => {
                    navigate(AppRoutes.productAnnotateViewer(productId, id), {
                        state: {
                            paths: [
                                { title: "Products", path: AppRoutes.products },
                                {
                                    title: `${productData?.data?.sequence_id}`,
                                    path: AppRoutes.viewProductBasicDetail(productId),
                                },
                                { title: "Documents", path: AppRoutes.viewProductDocuments(productId) },
                            ]
                        }
                    })
                }}
                showHelperBtn={false}
                creatable={creatable}
                readable={readable}
                updatable={updatable}
                deletetable={deletable}
                publishable={publishable}
                hasCheckbox={updatable}
                unlinkDoc={(id: string, refetch: any) => callLinkDocument('document', [], [id], refetch)}
            />
        }

        // Material
        if (matchPath(location.pathname, AppRoutes.viewProductMaterial(productId))) {
            return <ProductMaterials refetch={refetch} productId={productId} sequence_id={productData?.data?.sequence_id ?? "Add Product"} />
        }

        // BOMs
        if (matchPath(location.pathname, AppRoutes.viewProductBOM(productId))) {
            return <ProductBOMs refetch={refetch} productId={productId} sequence_id={productData?.data?.sequence_id ?? "Add Product"} />
        }

        // BOM Report
        if (matchPath(location.pathname, AppRoutes.viewBOMReportOnProductModule(productId))) {
            return <BOMReport paths={[
                { title: "Products", path: AppRoutes.products },
                {
                    title: `${productData?.data?.sequence_id ?? "Add Product"}`,
                    path: AppRoutes.viewProductBasicDetail(productId),
                },
            ]} object_type="product" />
        }

        // List View
        if (matchPath(location.pathname, AppRoutes.viewListViewOnProductModule(productId))) {
            return <ListView paths={[
                { title: "Products", path: AppRoutes.products },
                {
                    title: `${productData?.data?.sequence_id ?? "Add Product"}`,
                    path: AppRoutes.viewProductBasicDetail(productId),
                },
            ]} object_type="product" />
        }

        // Workbook
        if (matchPath(location.pathname, AppRoutes.workbookOnProductModule(productId))) {
            return <Workbook paths={[
                { title: "Products", path: AppRoutes.products },
                {
                    title: `${productData?.data?.sequence_id ?? "Add Product"}`,
                    path: AppRoutes.viewProductBasicDetail(productId),
                },
            ]} object_type="product" />
        }

        // IssueTracker
        if (matchPath(location.pathname, AppRoutes.viewIssueTrackerOnProduct(productId))) {
            return <IssueTracker
                paths={[
                    { title: "Products", path: AppRoutes.products },
                    {
                        title: `${productData?.data?.sequence_id ?? "Add Product"}`,
                        path: AppRoutes.viewProductBasicDetail(productId),
                    },
                ]}
                object_type="product"
                isNested={true}
                readable={readable}
                creatable={creatable}
                updatable={updatable}
                deletetable={deletable}
            />
        }

        // IEDraw
        if (matchPath(location.pathname, AppRoutes.viewProductIEDraw(productId))) {
            return <Dashboard object_id={productId} object_type="product" sequence_id={productData?.data?.sequence_id ?? ""} />
        }

        // AI Search
        if (matchPath(location.pathname, AppRoutes.viewProductAISearch(productId))) {
            return <AllSearches
                object_id={productId}
                object_type="product"
                paths={[...history, { label: "AI Search", path: AppRoutes.viewProductAISearch(productId) }]}
                documentsFilter={productData?.data?.associations?.find((a: { object_type: string }) => a?.object_type === "document")?.object_details?.map((d: { _id: string }) => d?._id)}
                unlinkSearch={(unlinks: any[]) => callLinkDocument('instantSearch', [], unlinks)}
            />
        }

        // User Management
        if (matchPath(location.pathname, AppRoutes.manageUsersOnProduct(productId))) {
            return <UserAccessManagement updatable={updatable} isLoading={productData.isFetching || productData.isLoading} object_id={productId} object_type="product" refetch={refetch} user_access={productData?.data?.user_access} sequence_id={productData?.data?.sequence_id ?? "..."} created_user={productData?.data?.created_user} />
        }

        return <></>
    };

    const giveMeLinksAndUnlinks = (links: any[], unlinks: any[], object_type: string): linksData => {
        let obj: linksData = {
            links: [{ object_ids: links, object_type: object_type }],
            unlinks: [{ object_ids: unlinks, object_type: object_type }],
        }
        return obj;
    };

    const callLinkDocument = async (object_type: string, links?: any[], unlinks?: any[], refetch?: any) => {
        let action = (unlinks || [])?.length > 0 ? "Unlinking" : "Linking";
        let actionSuccess = (unlinks || [])?.length > 0 ? "Unlinked" : "Linked";
        let actionError = (unlinks || [])?.length > 0 ? "Unlink" : "Link";
        dispatch(openBackdrop(`${action} the ${object_type}...`));
        const linkedData = giveMeLinksAndUnlinks(links || [], unlinks || [], object_type);
        let res: any = {};
        try {
            if (action === "Linking") {
                res = await linkDocument({ object_id: productId, object_type: "product", payload: { links: linkedData.links } })
            } else {
                res = await unlinkDocument({ object_id: productId, object_type: "product", payload: { unlinks: linkedData.unlinks } });
            }
            if (Object.keys(res).includes("data")) {
                enqueueSnackbar(`${actionSuccess} ${object_type} Successfully!`, { variant: "success" })
                dispatch(closeConfirmationDialog());
                getProductById({ id: productId });
                refetch && refetch();
            } else if (Object.keys(res).includes("error")) {
                // eslint-disable-next-line 
                throw res.error.data;
            }
        } catch (error: any) {
            let errorMessage: string = error?.title ?? error ?? `Oops! Something went wrong, Unable to ${actionError} ${object_type}`
            enqueueSnackbar(errorMessage, { variant: "error" });
        } finally {
            dispatch(closeBackdrop());
        }
    };

    const refetch = () => {
        if (isEdit) {
            refetchDocumentFolders();
            getProductById({ id: productId })
        }
    };

    const refetchDocumentFolders = () => {
        getDocumentFolders({ tag_group_id: folderData.data?.locationId, object_type: "product", object_ids: [productId] })
    };

    React.useEffect(() => {
        if (productId === ':id') {
            dispatch(closeBackdrop());
            navigate(AppRoutes.products);
        }
    });

    React.useEffect(() => {
        if (isEdit) {
            dispatch(openBackdrop("Fetching Product Details..."));
            getProductById({ id: productId });
            refetchDocumentFolders();
        } else {
            dispatch(resetProductSlice());
        }
        // eslint-disable-next-line    
    }, []);

    React.useEffect(() => {
        const updateValue = async () => {
            if (isEdit && !productData.isFetching && productData.isSuccess) {
                let tags: { businessUnit: SelectBoxOption | null, tags: SelectBoxOption[], status: SelectBoxOption | null } = { businessUnit: null, tags: [], status: null };
                productData.data?.tags_info?.forEach((_: any) => {
                    if (_.tag_group_name === 'Business Units') {
                        tags.businessUnit = {
                            value: _?.tag_id ?? "",
                            label: _?.tag_name ?? "",
                            ..._
                        }
                    }

                    if (_.tag_group_name === 'Product General Tags') {
                        tags.tags.push({
                            value: _?.tag_id ?? "",
                            label: _?.tag_name ?? "",
                            ..._
                        })
                    }

                    if (_.tag_group_name === 'Product Status') {
                        tags.status = {
                            value: _?.tag_id ?? "",
                            label: _?.tag_name ?? "",
                            ..._
                        }
                    }
                })
                dispatch(updateProductSlice({
                    basic_details: {
                        family: { label: productData.data?.family_name ?? "", value: productData.data?.family_id ?? "" },
                        group: { label: productData.data?.group_name ?? "", value: productData.data?.group_id ?? "" },
                        product: { label: productData.data?.product_noun_name ?? "", value: productData.data?.product_noun_id ?? "" },
                        external_ref_id: productData.data?.external_ref_id ?? "",
                        description: productData.data?.product_description ?? "",
                        businessUnit: tags.businessUnit,
                        productManager: productData.data?.product_manager ?? "",
                        status: tags.status,
                    },
                    notes: atob(productData?.data?.notes ?? ""),
                    product_images: [],
                    product_tags: {
                        tags: tags.tags
                    },
                    attachments: productData?.data?.attachments ?? [],
                    associations: productData?.data?.associations ?? [],
                    sequenceId: productData?.data?.sequence_id ?? "",
                    version: productData?.data?.version ?? "",
                    user_access: productData?.data?.user_access
                }))
                dispatch(closeBackdrop());
            }
        }
        updateValue();
        // eslint-disable-next-line
    }, [productData]);

    React.useEffect(() => {
        if (!productData.isFetching && productData.isError) {
            enqueueSnackbar('404 - Unable to find the requested product details', { variant: "error" });
            dispatch(openConfirmationDialog({
                title: "404 - Not Found",
                body: "Unable to find the requested product",
                positiveBtn: "Ok",
                onOk: () => {
                    dispatch(closeConfirmationDialog())
                    navigate(AppRoutes.products)
                },
                hideNegativeBtn: true
            }))
            dispatch(closeBackdrop());
        }
        // eslint-disable-next-line
    }, [productData.isError]);

    React.useEffect(() => {
        if (folderData.isSuccess) {
            refetchDocumentFolders();
        }
        // eslint-disable-next-line
    }, [folderData.isSuccess]);

    const documentFolders = data?.filter((_: any) => _.object_id === productId)?.[0] ?? { tags: [], tag_group_id: "" };

    const history = [
        { label: "Products", path: AppRoutes.products },
        { label: `${productData?.data?.sequence_id ?? "Add Product"}`, path: AppRoutes.viewProductBasicDetail(productId) }
    ];

    return <Box p={0.5} height={"100%"}>
        <Layout
            history={history}
            currentPath={currentPath}
            navBars={NavBars(productId, isEdit, isLoading || folderData.isLoading, documentFolders.tags ?? [], documentFolders?.tag_group_id ?? "", technicalParameterPath)}
            mainPanel={giveMeComponent()}
            sideNavVariant={"whiteboard"}
            onBackBtnClick={() => navigate(AppRoutes.products)}
            resizable
        />
    </Box>
}

export default withNavBars(ViewProduct);