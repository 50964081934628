import { Tooltip, Typography, IconButton } from "@mui/material";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid-premium";
import { DeleteOutline } from "@mui/icons-material";
import { CapitalizeString, GiveMeStatusColor } from "../../utils";

export const DashboardTableColumn = (onDelete: any, view: any, enableDelete: boolean) => {
    let columns: GridColDef[] = [
        {
            field: 'sequence_id', headerName: 'Supplier Id', width: 200, renderCell: (params: GridRenderCellParams) => {
                if (params.rowNode.type === 'group') {
                    return params.value;
                }
                return <Tooltip title={"View Supplier Details"}>
                    <Typography
                        id="view-vendor"
                        onClick={() => view(params.row)}
                        variant="body2"
                        component={"a"}
                        color="primary"
                        sx={{ width: "100%", overflow: "hidden", textOverflow: "ellipsis", cursor: "pointer", textDecoration: "underline" }}>
                        {params.row.sequence_id}
                    </Typography>
                </Tooltip>
            }
        },
        {
            field: 'version', headerName: 'Revision', width: 100, renderCell: (params: GridRenderCellParams) => {
                if (params.rowNode.type === 'group') {
                    return params.value;
                }
                return <Typography
                    variant="body2"
                >
                    {params.row?.version}
                </Typography>
            }
        },
        {
            field: 'vendor_name', headerName: 'Supplier Name', width: 200, renderCell: (params: GridRenderCellParams) => {
                if (params.rowNode.type === 'group') {
                    return params.value;
                }
                return <Typography variant="body2" sx={{ width: "100%", overflow: "hidden", textOverflow: "ellipsis" }}>{params.row.vendor_name}</Typography>
            }
        },
        {
            field: 'primary_contact.person_name', headerName: 'Contact Person Name', width: 200, renderCell: (params: GridRenderCellParams) => {
                if (params.rowNode.type === 'group') {
                    return params.value;
                }
                return <Typography variant="body2" sx={{ width: "100%", overflow: "hidden", textOverflow: "ellipsis" }}>{params.row.primary_contact?.person_name}</Typography>
            }
        },
        {
            field: 'primary_contact.email', headerName: 'Contact Person Email', width: 250, renderCell: (params: GridRenderCellParams) => {
                if (params.rowNode.type === 'group') {
                    return params.value;
                }
                return <Typography color={"primary"} component={"a"} href={`mailto:${params.row.primary_contact?.email}`} variant="body2">{params.row.primary_contact?.email}</Typography>
            }
        },
        {
            field: 'primary_contact.phone', headerName: 'Contact Person Mobile', width: 200, renderCell: (params: GridRenderCellParams) => {
                if (params.rowNode.type === 'group') {
                    return params.value;
                }
                return <Typography color={"primary"} component={"a"} href={`tel:${params.row.primary_contact?.phone}`} variant="body2">{params.row.primary_contact?.phone}</Typography>
            }
        },
        {
            field: 'primary_contact.country', headerName: 'Country', width: 150, renderCell: (params: GridRenderCellParams) => {
                if (params.rowNode.type === 'group') {
                    return params.value;
                }
                return <Typography variant="body2" sx={{ width: "100%", overflow: "hidden", textOverflow: "ellipsis" }}>{params.row.primary_contact?.country}</Typography>
            }
        },
        {
            field: 'approved', headerName: 'Is Approved?', width: 150, renderCell: (params: GridRenderCellParams) => {
                if (params.rowNode.type === 'group') {
                    return params.value;
                }
                return <Typography variant="body2" color={GiveMeStatusColor(params.row.approved)} sx={{ width: "100%", overflow: "hidden", textOverflow: "ellipsis" }}>{params.row.approved ? "Yes" : "No"}</Typography>
            }
        },
        {
            field: 'status', headerName: 'Status', width: 150, renderCell: (params: GridRenderCellParams) => {
                if (params.rowNode.type === 'group') {
                    return params.value;
                }
                return <Typography variant="body2" color={GiveMeStatusColor(params.row.status)} sx={{ width: "100%", overflow: "hidden", textOverflow: "ellipsis" }}>{CapitalizeString(params.row.status)}</Typography>
            }
        }
    ];

    if (enableDelete) {
        columns = [...columns, {
            field: 'delete', headerName: 'Delete', width: 100, sortable: false, disableColumnMenu: true, renderCell: (params: GridRenderCellParams) => {
                return <IconButton onClick={() => onDelete(params.row)}>
                    <DeleteOutline color="error" id="delete-vendor-icon" />
                </IconButton>
            }
        }]
    }

    return columns;
};