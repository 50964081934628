import { IconButton, Skeleton, Stack, Tooltip, Typography, Chip, Avatar } from "@mui/material";
import { ContentCopy, Delete, Edit } from "@mui/icons-material";
import { MastersInterface } from "../../interfaces";
import { GridRenderCellParams } from '@mui/x-data-grid-premium';
import React from "react";
import { useGetProductNounsQuery, useGetProductFamilyQuery } from "../../redux/services";

export const MasterValue = {
    family: "FAMILY",
    group: "GROUP",
    productNoun: "PRODUCT NOUN",
    attribute: "ATTRIBUTE",
}

export const allMasters = (previlages: string[]): MastersInterface[] => [
    {
        value: MasterValue.family,
        label: "Family",
        previlages,
        is_active: true,
        column: (edit, deleteRow) => {
            const columns = [
                {
                    field: 'actions', headerName: 'Edit', width: 80, sortable: false, disableColumnMenu: true, renderCell: (params: GridRenderCellParams) => {
                        return <IconButton onClick={() => edit(params.row)} > <Edit fontSize="small" color={"primary"} /></IconButton >
                    }
                },
                // { field: 'id', headerName: 'Id', width: 90 },
                {
                    field: 'family_name', headerName: 'Family', width: 250, renderCell: (params: GridRenderCellParams) => {
                        if (params.rowNode.type === 'group') {
                            return params.value;
                        }
                        return <Typography variant="body2" sx={{ width: "100%", overflow: "hidden", textOverflow: "ellipsis" }}>{params.row.family_name}</Typography>
                    }
                },
                {
                    field: 'family_desc', headerName: 'Description', width: 400, renderCell: (params: GridRenderCellParams) => {
                        if (params.rowNode.type === 'group') {
                            return params.value;
                        }
                        return <Tooltip title={params.row.family_desc}><Typography variant="body2" sx={{ width: "100%", overflow: "hidden", textOverflow: "ellipsis" }}>{params.row.family_desc}</Typography></Tooltip>
                    }
                },
                {
                    field: 'delete', headerName: 'Delete', width: 80, sortable: false, disableColumnMenu: true, renderCell: (params: GridRenderCellParams) => {
                        return <IconButton onClick={() => deleteRow({ ...params.row, label: params.row.family_name })} > <Delete fontSize="small" color={"error"} id="delete-family" /></IconButton >
                    }
                },
            ];

            if (!previlages.includes('U')) {
                columns.shift();
            }

            if (!previlages.includes('D')) {
                columns.pop();
            }

            return columns;
        }
    },
    {
        value: MasterValue.group,
        label: "Group",
        previlages,
        is_active: true,
        column: (edit, deleteRow) => {
            const columns = [
                {
                    field: 'actions', headerName: 'Edit', width: 80, sortable: false, disableColumnMenu: true, renderCell: (params: GridRenderCellParams) => {
                        return <IconButton onClick={() => edit(params.row)} > <Edit fontSize="small" color={"primary"} /></IconButton >
                    }
                },
                // { field: 'id', headerName: 'Id', width: 90 },
                { field: 'group_name', headerName: 'Group', width: 250 },
                {
                    field: 'group_desc', headerName: 'Description', width: 400, renderCell: (params: GridRenderCellParams) => {
                        return <Tooltip title={params.row.group_desc}><Typography variant="body2" sx={{ width: "100%", overflow: "hidden", textOverflow: "ellipsis" }}>{params.row.group_desc}</Typography></Tooltip>
                    }
                },
                {
                    field: 'delete', headerName: 'Delete', width: 80, sortable: false, disableColumnMenu: true, renderCell: (params: GridRenderCellParams) => {
                        return <IconButton onClick={() => deleteRow({ ...params.row, label: params.row.group_name })} > <Delete fontSize="small" color={"error"} id="delete-group" /></IconButton >
                    }
                },
            ]

            if (!previlages.includes('U')) {
                columns.shift();
            }

            if (!previlages.includes('D')) {
                columns.pop();
            }

            return columns;
        }
    },
    {
        value: MasterValue.productNoun,
        label: "Product Noun",
        previlages,
        is_active: true,
        columnVisibilityModel: {
            // Hide columns by default here
            noun_desc: false,
        },
        column: (edit, deleteRow) => {
            const columns = [
                {
                    field: 'actions', headerName: 'Edit', width: 80, sortable: false, disableColumnMenu: true, renderCell: (params: GridRenderCellParams) => {
                        return <IconButton onClick={() => edit(params.row)} > <Edit fontSize="small" color={"primary"} /></IconButton >
                    }
                },
                // { field: 'id', headerName: 'Id', width: 90 },
                {
                    field: 'attachments',
                    headerName: 'Image',
                    width: 100,
                    renderCell: (params: GridRenderCellParams) => (
                        <Avatar variant="square" sx={{ '.MuiAvatar-img': { objectFit: "contain !important" } }} alt={params.row.noun_name} src={params.row.attachments ? params.row.attachments?.[params.row.attachments.length - 1]?.attachment_url : `/imageNotFound.jpg`}>
                            <img height={"50px"} width={"50px"} src={`/imageNotFound.jpg`} alt={params.row.noun_name} />
                        </Avatar>
                    ),
                    sortable: false, 
                    disableColumnMenu: true
                },
                { field: 'product_noun_name', headerName: 'Product Name', width: 250 },
                { field: 'family_name', headerName: 'Family', headerClassName: 'no-sorting', width: 350, renderCell: (params: GridRenderCellParams) => (<GiveMeFamilyName family_id={params.row.family_id} />) },
                { field: 'group_name', headerName: 'Group Name', width: 250 },
                {
                    field: 'product_noun_desc', headerName: 'Description', width: 500, renderCell: (params: GridRenderCellParams) => {
                        return <Tooltip title={params.row.noun_desc}><Typography variant="body2" sx={{ width: "100%", overflow: "hidden", textOverflow: "ellipsis" }}>{params.row.product_noun_desc}</Typography></Tooltip>
                    }
                },
                {
                    field: 'delete', headerName: 'Delete', width: 80, sortable: false, disableColumnMenu: true, renderCell: (params: GridRenderCellParams) => {
                        return <IconButton onClick={() => deleteRow({ ...params.row, label: params.row.product_noun_name })} > <Delete fontSize="small" color={"error"} id="delete-noun" /></IconButton >
                    }
                },
                // { field: 'attribute', headerName: 'Attribute', width: 250 },
            ];

            if (!previlages.includes('U')) {
                columns.shift();
            }

            if (!previlages.includes('D')) {
                columns.pop();
            }

            return columns;
        }
    },
    {
        value: MasterValue.attribute,
        label: "Attribute",
        previlages,
        is_active: true,
        columnVisibilityModel: {
            // Hide columns by default here
            options: false,
        },
        column: (edit, deleteRow, copy) => {
            const columns = [
                {
                    field: 'actions', headerName: 'Edit', resizable: true, width: 40, disableColumnMenu: true, sortable: false, renderCell: (params: GridRenderCellParams) => {
                        return <IconButton onClick={() => edit(params.row)} > <Edit fontSize="small" color={"primary"} /></IconButton >
                    }
                },
                {
                    field: 'duplicate', headerName: 'Duplicate', resizable: true, width: 90, disableColumnMenu: true, sortable: false, renderCell: (params: GridRenderCellParams) => {
                        return <Tooltip title="Make a Duplicate">
                            <IconButton sx={{ margin: "auto" }} onClick={() => copy({ ...params.row, label: params.row.attr_name })}>
                                <ContentCopy fontSize="small" />
                            </IconButton>
                        </Tooltip>
                    }
                },
                // { field: 'id', headerName: 'Id', width: 90 },
                { field: 'attr_name', headerName: 'Attribute Name', width: 250 },
                {
                    field: 'options', headerName: 'Option (Abbrevation)', width: 350, renderCell: (params: GridRenderCellParams) => (
                        <Stack spacing={1} direction={"row"} width="100%" overflow={"scroll"} id={"nounsChip"}>
                            {params.row?.options?.map((opt: any, key: number) => (<Chip variant="outlined" size={"small"} label={`${opt.option_name} (${opt.option_abbr})`} />)) ?? "-"}
                        </Stack>)
                },
                { field: 'product_nouns', headerName: 'Associated Products', width: 400, renderCell: (params: GridRenderCellParams) => (<ProductNounChipByProductNounIds product_nouns={params.row.product_nouns} />) },
                {
                    field: 'delete', headerName: 'Delete', width: 80, sortable: false, disableColumnMenu: true, renderCell: (params: GridRenderCellParams) => {
                        return <IconButton onClick={() => deleteRow({ ...params.row, label: params.row.attr_name })} > <Delete fontSize="small" color={"error"} id="delete-attribute" /></IconButton >
                    }
                },
            ]

            if (!previlages.includes('U')) {
                columns.shift();
                columns.shift();
            }

            if (!previlages.includes('D')) {
                columns.pop();
            }

            return columns;
        }
    }
];

const GiveMeFamilyName: React.FC<{ family_id: any }> = (props) => {

    const { data: allData = { data: [] }, isLoading, isError } = useGetProductFamilyQuery({ page: null, pageSize: null }, { refetchOnMountOrArgChange: true });

    if (isLoading) {
        return <Skeleton sx={{ width: "100px" }} />
    }

    if (isError) {
        return <Typography variant={"caption"} color={"error"} >Oops! Unable to get Family name.</Typography>
    }

    let selectedUoM: any = allData?.data?.filter((_: any) => _._id === props.family_id)?.[0] ?? {};

    return <Typography variant={"body2"}>{selectedUoM.family_name}</Typography>
}

const ProductNounChipByProductNounIds: React.FC<{ product_nouns: string[] }> = ({ product_nouns }) => {
    const { data: allData = { data: [] }, isLoading, isError } = useGetProductNounsQuery({ page: null, pageSize: null }, { refetchOnMountOrArgChange: true });

    if (isLoading) {
        return <Stack direction={"row"}>
            {[1, 2, 3].map((_: number) => (<Skeleton key={_} sx={{ width: "100px" }} />))}
        </Stack>
    }

    if (isError) {
        return <Typography variant={"caption"} color={"error"} >Oops! Unable to get associated product nouns.</Typography>
    }

    let selectedProductNoun: any[] = allData?.data?.filter((_: any) => product_nouns?.includes(_._id)) ?? [];

    return <Stack spacing={1} direction={"row"} width="100%" overflow={"scroll"} id={"nounsChip"}>
        {selectedProductNoun?.map((noun: any, key: number) => {
            return <Chip key={key} size="small" label={noun.product_noun_name} />
        })}
    </Stack>
}