import React from "react";
import { Layout } from "../../components";
import { useLocation, useNavigate } from "react-router-dom";
import { Box } from "@mui/material";
import { setOpenWebSideNavBar } from "../../redux/slices/utils";
import { useAppDispatch } from "../../redux";
import ViewCostingTree from "./viewCostingTree";

const ViewBOMCostingTree: React.FC<{
  currentPath: string;
  customAssociation?: { object_type: string; object_id: string };
  avoidNavigate?: boolean;
  handleParentDialogClose?: any;
}> = ({
  currentPath,
  avoidNavigate = false,
  handleParentDialogClose,
  ...props
}) => {
  const location: any = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [sequenceId, setSequenceId] = React.useState("...");

  const giveMeComponent = () => {
    return (
      <ViewCostingTree setSequenceId={setSequenceId} />
    );
  };

  const paths = location.state?.paths ?? [];

  React.useEffect(() => {
    dispatch(setOpenWebSideNavBar(false));
    // eslint-disable-next-line
  }, []);

  return (
    <Box py={0.5} px={0.8} height={"100%"}>
      <Layout
        history={[
            ...paths.map((_: any) => {
              if (_.title) {
                  return { label: _.title, onClick: () => navigate(_.path) }
              } else {
                  return { label: sequenceId, onClick: () => navigate(_.path) }
              }
            }),
        ]}
        currentPath={currentPath}
        navBars={[]}
        mainPanel={giveMeComponent()}
        hideSideNavBar={false}
        sideNavVariant={"whiteboard"}
        otherBreadscrumProps={{
          hideMenuBtn: paths.length > 0,
          showBackBtn: paths.length > 0,
        }}
        avoidNavigate={avoidNavigate}
        locationState={paths}
      />
    </Box>
  );
};

export default ViewBOMCostingTree;
