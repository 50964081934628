import { MenuBook } from "@mui/icons-material";
import { Box } from "@mui/material";
import React from "react";
import { useParams } from "react-router-dom";
import { Layout, MyPaper } from "../../components";
import { AppRoutes } from "../../router/routes";
import { Dashboard } from "./dashboard";

const Workbook: React.FC<{ paths?: Array<any>, object_type?: string }> = ({ paths = [], object_type = "" }) => {
    const { id = "" } = useParams();

    if (id) {
        return (
          <MyPaper height={"100%"} padding={0}>
            <Dashboard paths={paths} object_type={object_type} />
          </MyPaper>
        )
    }

    return <Box sx={{ padding: 0.5, height: "100%" }}>
        {/* Breadscrum */}
        <Layout
            currentPath={"Workbook"}
            hideNavBarByDefault={true}
            navBars={[
                {
                  id: "1",
                  title: "Workbook",
                  icon: <MenuBook fontSize="small" />,
                  path: AppRoutes.materialWorkbook,
                },
              ]}
            mainPanel={<Dashboard />}
            sideNavVariant={"whiteboard"}
        />
    </Box>
}

export { Workbook };