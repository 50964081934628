import { Button, Grid, Stack, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import React from "react";
import { useSearchParams } from "react-router-dom";
import { Assign, MyPaper, UnAssign } from "../../components";
import { ReactSelectOption } from "../../interfaces";
import { useAppDispatch } from "../../redux";
import { AssignmentStatus, useLazyGetBOMByIdQuery, useUpdateAssignmentMutation } from "../../redux/services";
import { closeBackdrop, openBackdrop } from "../../redux/slices/backdrop";
import { closeDialog, openDialog } from "../../redux/slices/dialog";
import { CapitalizeString } from "../../utils";


export const AssignTagNoToDrawioShape: React.FC<{ children?: JSX.Element }> = (props) => {

    const dispatch = useAppDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const [getBOMById] = useLazyGetBOMByIdQuery();
    const [searchParams, setSearchParams] = useSearchParams();

    const [state, setState] = React.useState<{
        bom?: ReactSelectOption,
        childType?: "material" | "bom",
        childBOM?: ReactSelectOption,
        childMaterial?: ReactSelectOption
    }>();

    const [assignSequenceNumberQuery] = useUpdateAssignmentMutation({});

    const generateSerialNumbersForTemplate = async (
        id?: string | null,
        serial_num?: string,
        type: AssignmentStatus = "assign",
        isManual?: boolean
    ) => {
        dispatch(openBackdrop('Assigning Serial Number....'));
        const currentItem: { type: string, _id: string } = {
            type: "bom",
            _id: state?.bom?.value ?? "",
        };

        let object_type: string = "";
        let object_id: string = "";
        let child_id: string = "";

        if (state?.childType === 'bom') {
            let childBOM: any = state?.childBOM;
            object_type = 'bom';
            object_id = childBOM?._id ?? "";
            child_id = childBOM?.child_id ?? "";
        } else if (state?.childType === 'material') {
            let childMaterial: any = state?.childMaterial;
            object_type = 'material';
            object_id = childMaterial?._id ?? "";
            child_id = childMaterial?.child_id ?? "";
        }

        const selectedChild: {
            object_type: string,
            object_id: string,
            child_id: string,
            serial_num?: string
        } = { object_type, object_id, child_id };

        if (serial_num) {
            selectedChild.serial_num = tagNumber;
        }

        await assignSequenceNumberQuery({
            id: id,
            status: type,
            manual_serial_num: isManual,
            payload: [
                {
                    object_type: currentItem?.type,
                    object_id: currentItem?._id,
                    children: [selectedChild],
                },
            ],
        }).then(async (res: any) => {
            if (res.error) {
                enqueueSnackbar(`Something went wrong unable to ${type === 'assign' ? "assign" : "un-assign"} serial number. Info: ${res?.error?.data?.title ?? "-"}`, { variant: "error" })
            } else {
                enqueueSnackbar(`${type === 'assign' ? "Assigned" : "Un Assigned"} Serial Number`, { variant: "success" });
                dispatch(closeDialog());

                if (type === 'assign') {
                    dispatch(openBackdrop('Fetching updated BOM...'));
                    let bom = await getBOMById({ id: state?.bom?.value }).unwrap();
                    if (bom && state?.bom) {
                        setState({
                            ...state,
                            bom: { ...state.bom, ...bom },
                            childMaterial: state.childType === 'material' ? { ...state.childMaterial, ...bom?.bom_materials?.filter((_: any) => _.child_id === state.childMaterial?.value)?.[0] } as ReactSelectOption : undefined,
                            childBOM: state.childType === 'bom' ? { ...state.childBOM, ...bom?.bom_children?.filter((_: any) => _.child_id === state.childBOM?.value)?.[0] } as ReactSelectOption : undefined,
                        })
                    }
                    dispatch(closeBackdrop());
                } else if (state?.childType) {
                    setState({
                        ...state,
                        childMaterial: state.childType === 'material' ? { ...state.childMaterial, serial_num: "-" } as ReactSelectOption : undefined,
                        childBOM: state.childType === 'bom' ? { ...state.childBOM, serial_num: "-" } as ReactSelectOption : undefined,
                    })
                }
            }
        }).catch(err => {
            console.log('err:', err)
            enqueueSnackbar('Something went wrong unable to assign serial number', { variant: "error" });
        });
        dispatch(closeBackdrop());
    };

    const handleAssignClick = () => {
        dispatch(
            openDialog({
                title: "",
                hideNegativeBtn: true,
                hidePositiveBtn: true,
                maxWidth: "md",
                enablePadding: false,
                body: <Assign onAssign={(id?: string | null, serial_num?: string, isManual?: boolean, type: AssignmentStatus = "assign") => generateSerialNumbersForTemplate(id ?? "", serial_num ?? "", type, isManual)} />,
            })
        );
    };

    const onUnAssignClick = () => {
        dispatch(
            openDialog({
                title: "",
                hideNegativeBtn: true,
                hidePositiveBtn: true,
                body: (
                    <UnAssign
                        onCancel={() => dispatch(closeDialog())}
                        onUnassign={() => {
                            generateSerialNumbersForTemplate(
                                null,
                                tagNumber,
                                "unassign"
                            );
                        }}
                    />
                ),
            })
        );
    };

    const onAssignTagNoBtnClicked = () => {
        const drawioContent: any = window.parent.window.parent.document.getElementById('drawio-editor-iframe');
        if (drawioContent) {
            drawioContent.contentWindow?.postMessage({ ...state, tagNumber, subEvent: "assignTagNo", event: "iehub-plugin" }, "*");
        } else {
            console.log('drawio editor is not mapped');
        }
    };

    const updateExistingDetails = async () => {
        if (searchParams.get('edit') === "1") {
            dispatch(openBackdrop('Fetching BOM Detail...'));

            const bomId = searchParams.get("bomId");
            const bom = searchParams.get("bom");

            const childMaterial = searchParams.get("childMaterial");
            const childMaterialId = searchParams.get("childMaterialId");

            const childBOM = searchParams.get("childBOM");
            const childBOMId = searchParams.get("childBOMId");

            if (bomId !== "null") {
                try {
                    const bomDetails = await getBOMById({ id: bomId }).unwrap();

                    if (bomDetails) {
                        let child: { childType?: 'bom' | 'material' } = {};

                        if (childMaterialId) {
                            child.childType = 'material'
                        } else if (childBOMId) {
                            child.childType = 'bom'
                        };

                        setState({
                            ...state,
                            bom: { value: bomId, label: bom, ...bomDetails } as unknown as ReactSelectOption,
                            childType: child.childType,
                            childMaterial: child.childType === "material" ? { value: childMaterialId, label: childMaterial, ...bomDetails.bom_materials?.filter((_: any) => _.child_id === childMaterialId)?.[0] } as unknown as ReactSelectOption : undefined,
                            childBOM: child.childType === "bom" ? { value: childBOMId, label: childBOM, ...bomDetails.bom_children?.filter((_: any) => _.child_id === childBOMId)?.[0] } as unknown as ReactSelectOption : undefined,
                        })
                    }
                } catch (err) {
                    console.log('err:', err)
                }
            }

            dispatch(closeBackdrop());
        }
    }

    const tagNumber = React.useMemo(() => {
        if (state?.childBOM) {
            let childBOM: any = state.childBOM;
            return childBOM?.serial_num ?? "-";
        } else if (state?.childMaterial) {
            let childMaterial: any = state.childMaterial;
            return childMaterial?.serial_num ?? "-";
        } else {
            return "-"
        }
    }, [state?.childBOM, state?.childMaterial]);

    const messageHandler = (evt: MessageEvent) => {
        if (evt.data?.type === 'assignTagNo') {
            setSearchParams({ ...evt.data, edit: 1 });
        }
    }

    // Initial load
    React.useEffect(() => {
        window.addEventListener('message', messageHandler);

        return () => {
            window.removeEventListener('message', messageHandler);
        };
        // eslint-disable-next-line
    }, []);

    React.useEffect(() => {
        updateExistingDetails();
        // eslint-disable-next-line
    }, [searchParams]);

    return <MyPaper height={"100vh"}>
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Typography variant="body2">Assigned BOM:&nbsp;<b>{state?.bom?.label}</b></Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="body2">Assigned Child {CapitalizeString(state?.childType ?? "")}:&nbsp;
                    {state?.childType === 'material' && <b>{state?.childMaterial?.label ?? "-"}</b>}
                    {state?.childType === 'bom' && <b>{state?.childBOM?.label ?? "-"}</b>}
                    {!state?.childType && <b>{"Yet to assign child"}</b>}
                </Typography>
            </Grid>

            {state?.childType && <Grid item xs={12}>
                <Stack direction={"row"} alignItems={"center"} spacing={1}>
                    <Typography variant="body2" color={"primary"}>{"Tag Number: "}</Typography>
                    <Typography variant="body1" color={"darkgreen"}><b>{tagNumber}</b></Typography>

                    {(state.childBOM || state.childMaterial) && tagNumber === "-" && <Button size="small" variant="outlined" color="info" onClick={handleAssignClick}>Assign Tag No.</Button>}
                    {(state.childBOM || state.childMaterial) && tagNumber !== "-" && <Button size="small" variant="outlined" color="error" onClick={onUnAssignClick} >Un Assign Tag No.</Button>}
                </Stack>
            </Grid>}

            <Grid item xs={12}>
                <Button fullWidth disabled={tagNumber === "-"} variant="contained" onClick={onAssignTagNoBtnClicked}>Assign Tag No. to Shape</Button>
            </Grid>
        </Grid>
    </MyPaper>;
}