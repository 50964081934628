import React from "react";
import {
  Box,
  Stack,
  Typography,
  Button,
  Grid,
  Checkbox,
  FormControlLabel,
  Tooltip,
  CircularProgress,
  Alert,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useDispatch } from "react-redux";
import { useSnackbar } from "notistack";
import {
  useCreateBOMReportMutation,
  useGetDocumentTemplatesDDOptionsQuery,
  useGetLocationAndDocumentTagsQuery,
  useGetProcessingStatusQuery,
  useLazyGetBOMReportByIdQuery,
  useLazyGetProductByIdQuery,
  useLazyGetProductsAsDDQuery,
  useLazyGetProjectByIdQuery,
  useLazyGetProjectsAsDDQuery,
  useLazyGetProposalByIdQuery,
  useLazyGetProposalsAsDDQuery,
  useUpdateBOMReportMutation,
} from "../../../redux/services";
import { SelectBoxOption } from "../../../interfaces";
import { DeletePanel, HighlightSelectBox, Layout, MyPaper, TextField } from "../../../components";
import { type Location, useLocation, useNavigate, useParams } from "react-router-dom";
import { SectionPaper } from "../../../components/sectionPaper";
import copy from "fast-copy";
import { Delete } from "@mui/icons-material";
import { closeBackdrop, openBackdrop } from "../../../redux/slices/backdrop";
import { ConvertToReactSelect } from "../../../utils";
import { useAppDispatch, useAppSelector } from "../../../redux";
import { PublishComponent } from "../../materials/dashboard";
import { closeConfirmationDialog, openConfirmationDialog } from "../../../redux/slices/confirmationDialog";
import { openDialog } from "../../../redux/slices/dialog";
import { HasAccess } from "../../../router/authorization";
import { PERMISSIONS } from "../../../router/permission";
import { WIPIcon } from "../../../images/svg/WIPIcon";
import { PublishedIcon } from "../../../images/svg/publishedIcon";

interface IState {
  name: string;
  id: SelectBoxOption | null;
  bom: SelectBoxOption | null;
}

interface ContentItem {
  id: string;
  title: string;
  template?: SelectBoxOption | null;
  checked: boolean;
}

interface IContent extends ContentItem {
  items: ContentItem[];
}

interface DocumentItem {
  tag: SelectBoxOption | null;
  template: SelectBoxOption | null;
}

interface IDocument extends ContentItem {
  items: DocumentItem[];
}

interface Error {
  name: boolean;
  id: boolean;
  bom: boolean;
  documents: {
    [key: string]: { tag: boolean; template: boolean; }[]
  }
}

const initialDocument = {
  tag: null,
  template: null
}

const preSelectedFields = ["sequence_id", "short_description", "tag", "normal_quantity", "document_name", "serial_num"];

export const getObjectType = (location: Location, prefix?: string) => {
  if (location.pathname.includes("product") || prefix === "PRD") {
    return "product";
  } else if (location.pathname.includes("project") || prefix === "PJ") {
    return "project";
  } else if (location.pathname.includes("proposal") || prefix === "PRP") {
    return "proposal"
  }
}

export const RenderBOMReport: React.FC<{
  data: any;
  refetch: (id: string) => any;
}> = ({ data, refetch }) => {

  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { p_id = "", id = "" } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const { perm } = useAppSelector(store => store.auth.userDetails);

  const [getAllProducts, productsRes] = useLazyGetProductsAsDDQuery({ refetchOnFocus: true });
  const [getAllProjects, projectsRes] = useLazyGetProjectsAsDDQuery({ refetchOnFocus: true });
  const [getAllProposals, proposalsRes] = useLazyGetProposalsAsDDQuery({ refetchOnFocus: true });
  const [getProductById] = useLazyGetProductByIdQuery({ refetchOnReconnect: true, refetchOnFocus: true });
  const [getProjectById] = useLazyGetProjectByIdQuery({ refetchOnReconnect: true, refetchOnFocus: true });
  const [getProposalById] = useLazyGetProposalByIdQuery({ refetchOnReconnect: true, refetchOnFocus: true });
  const [createBOMReport, createBOMReportData] = useCreateBOMReportMutation();
  const [updateBOMReport, updateBOMReportData] = useUpdateBOMReportMutation();
  const selectOptions = useGetLocationAndDocumentTagsQuery(null, { refetchOnFocus: true, refetchOnMountOrArgChange: true });
  const templateOptions = useGetDocumentTemplatesDDOptionsQuery({}, { refetchOnFocus: true, refetchOnMountOrArgChange: true });
  const { data: processingStatus } =
    useGetProcessingStatusQuery({ id, type: "bom_report" }, {
      pollingInterval: 3000,
      skip: id === "New" || data?.processing === false
    })

  const [parentData, setParentData] = React.useState<any>({});
  const [moduleOptions, setModuleOptions] = React.useState<SelectBoxOption[]>([]);
  const [state, setState] = React.useState<IState>({ name: "", id: null, bom: null });
  const [error, setError] = React.useState<Error>({
    name: false,
    id: false,
    bom: false,
    documents: {
      bom_associated_document: [],
      material_associated_document: []
    }
  });
  const [contents, setContents] = React.useState<IContent[]>([
    {
      id: "bom_details",
      title: "Include BOM Details",
      template: null,
      checked: false,
      items: [
        {
          id: "sequence_id",
          title: "BOM ID & Revision Number",
          checked: true
        },
        {
          id: "sourcing_details",
          title: "Sourcing Details",
          checked: false
        },
        {
          id: "short_description",
          title: "BOM Short Description",
          checked: true
        },
        {
          id: "reference_number",
          title: "Reference Number",
          checked: false
        },
        {
          id: "image",
          title: "BOM Image",
          checked: false
        },
        {
          id: "datasheet",
          title: "Datasheet",
          checked: false
        }
      ]
    },
    {
      id: "child_bom_details",
      title: "Include Child BOM Details",
      checked: false,
      items: [
        {
          id: "serial_num",
          title: "Tag Number",
          checked: true
        },
        {
          id: "normal_quantity",
          title: "Default Quantity",
          checked: true
        },
        {
          id: "tag",
          title: "Tag",
          checked: true
        },
        {
          id: "spare_part",
          title: "Spare Part (if applicable)",
          checked: false
        },
        {
          id: "sourcing_details",
          title: "Sourcing Details (if applicable)",
          checked: false
        },
        {
          id: "spare_part_category",
          title: "Spare Part Category (if applicable)",
          checked: false
        },
        {
          id: "spare_part_quantity",
          title: "Spare Part Quantity (if applicable)",
          checked: false
        },
        {
          id: "datasheet",
          title: "Datasheet",
          checked: false
        }
      ]
    },
    {
      id: "material_details",
      title: "Include Material Details",
      template: null,
      checked: false,
      items: [
        {
          id: "sequence_id",
          title: "Material ID & Revision Number",
          checked: true
        },
        {
          id: "sourcing_details",
          title: "Sourcing Details",
          checked: false
        },
        {
          id: "short_description",
          title: "Material Short Description",
          checked: true
        },
        {
          id: "reference_number",
          title: "Reference Number",
          checked: false
        },
        {
          id: "uom",
          title: "Unit of Measure Details",
          checked: false
        },
        {
          id: "image",
          title: "Material Image",
          checked: false
        },
        {
          id: "group\u0026noun",
          title: "Material Group & Noun",
          checked: false
        },
        {
          id: "datasheet",
          title: "Datasheet",
          checked: false
        }
      ]
    },
    {
      id: "child_material_details",
      title: "Include Child Material Details",
      checked: false,
      items: [
        {
          id: "serial_num",
          title: "Tag Number",
          checked: true
        },
        {
          id: "normal_quantity",
          title: "Default Quantity",
          checked: true
        },
        {
          id: "tag",
          title: "Tag",
          checked: true
        },
        {
          id: "spare_part",
          title: "Spare Part (if applicable)",
          checked: false
        },
        {
          id: "sourcing_details",
          title: "Sourcing Details (if applicable)",
          checked: false
        },
        {
          id: "spare_part_category",
          title: "Spare Part Category (if applicable)",
          checked: false
        },
        {
          id: "spare_part_quantity",
          title: "Spare Part Quantity (if applicable)",
          checked: false
        },
        {
          id: "datasheet",
          title: "Datasheet",
          checked: false
        }
      ]
    },
    {
      id: "document_details",
      title: "Include Child Document Details",
      checked: false,
      items: [
        {
          id: "sequence_id",
          title: "Document ID & Revision Number",
          checked: true
        },
        {
          id: "document_type",
          title: "Document Type",
          checked: false
        },
        {
          id: "document_name",
          title: "Document Name",
          checked: true
        },
        {
          id: "serial_num",
          title: "Tag Number",
          checked: true
        },
        {
          id: "status",
          title: "Document Status",
          checked: false
        },
        {
          id: "folder_type",
          title: "Folder Type",
          checked: false
        },
        {
          id: "metadata",
          title: "Document Created by, Time etc",
          checked: false
        }
      ]
    },
  ]);
  const [documents, setDocuments] = React.useState<IDocument[]>([
    {
      id: "bom_associated_document",
      title: "BOM Associated Documents",
      checked: false,
      items: [
        initialDocument
      ]
    },
    {
      id: "material_associated_document",
      title: "Material Associated Documents",
      checked: false,
      items: [
        initialDocument
      ]
    }
  ]);

  const isEdit = id !== "" && id !== "New";
  const bomOptions = ConvertToReactSelect(parentData?.associations?.find((a: any) => a.object_type === "bom")?.object_details ?? [], "_id", "sequence_id", ["version"], "r");
  const moduleLoading = productsRes.isFetching || productsRes.isLoading || projectsRes.isLoading || projectsRes.isLoading || proposalsRes.isLoading || proposalsRes.isLoading;
  const docTypeTags = selectOptions.data?.documentTags ?? [];

  const creatable = React.useMemo(() => HasAccess(perm, PERMISSIONS.BOM_REPORT_CREATE), [perm]);
  const updatable = React.useMemo(() => HasAccess(perm, PERMISSIONS.BOM_REPORT_UPDATE), [perm]);
  const publishable = React.useMemo(() => {
    const primaryAccess = HasAccess(perm, PERMISSIONS.BOM_REPORT_PUBLISH);
    const objectType = getObjectType(location);
    if (objectType === 'product') {
      return HasAccess(perm, PERMISSIONS.PRODUCT_PUBLISH) && primaryAccess
    }
    if (objectType === 'proposal') {
      return HasAccess(perm, PERMISSIONS.PROPOSAL_PUBLISH) && primaryAccess
    }
    if (objectType === 'project') {
      return HasAccess(perm, PERMISSIONS.PROJECT_PUBLISH) && primaryAccess
    }
    return primaryAccess
  }, [perm, location]);

  const isViewOnly = isEdit ? !updatable : !creatable;

  const updatedModuleOptions = React.useMemo(() => {
    return moduleOptions.map((option) => {
      const prefix = option.label.split('-')?.[0];
      return {
        prefix: prefix,
        ...option,
      };
    })
  }, [moduleOptions]);

  const updateState = (key: keyof IState, value: IState[keyof IState]) => {
    setState((prevState) => ({ ...prevState, [key]: value }));
    setError((prevError) => ({ ...prevError, [key]: !value }));
  };

  const onCancelBtnClicked = () => {
    navigate(-1);
  };

  const validate = () => {
    let isValid = true;
    if (!state.name.trim()) {
      setError((prevError) => ({
        ...prevError,
        name: true
      }))
      isValid = false;
    }

    if (!state.bom) {
      setError((prevError) => ({
        ...prevError,
        bom: true
      }))
      isValid = false;
    }

    if (!parentData?._id && !state.id) {
      setError((prevError) => ({
        ...prevError,
        id: true
      }))
      isValid = false;
    }

    documents.forEach((doc) => {
      if (doc.checked) {
        doc.items.forEach((item, index) => {
          let docError = { tag: false, template: false };
          if (!item.tag) {
            isValid = false;
            docError.tag = true
          }
          setError((prevError: any) => ({
            ...prevError,
            documents: {
              ...prevError.documents,
              [doc.id]: [...prevError.documents?.[doc.id]?.slice(0, index), docError, ...prevError.documents?.[doc.id]?.slice(index + 1)]
            }
          }))
        })
      }
    })
    return isValid;
  };

  const onCreateUpdateBtnClicked = async () => {
    let valid = validate();
    if (valid) {
      let payload: Record<string, any> = {
        object_type: getObjectType(location, parentData?.sequence_id?.split("-")?.[0] || state?.id?.sequence_id?.split("-")?.[0]),
        object_id: p_id || parentData?._id,
        bom_id: state?.bom?._id,
        name: state?.name
      };
      contents.forEach((content) => {
        if (content.checked) {
          payload[content.id] = content.items.filter(item => item.checked).map(item => item.id)
          if (content.id === "bom_details") {
            payload["bom_template_id"] = content.template?.value;
            payload["bom_template_type"] = 'hfTemplate';
          } else if (content.id === "material_details") {
            payload["material_template_id"] = content.template?.value;
            payload["material_template_type"] = 'hfTemplate';
          }
        } else {
          payload[content.id] = [];
          if (content.id === "bom_details") {
            payload["bom_template_id"] = content.template?.value;
            payload["bom_template_type"] = 'hfTemplate';
          } else if (content.id === "material_details") {
            payload["material_template_id"] = content.template?.value;
            payload["material_template_type"] = 'hfTemplate';
          }
        }
      })
      documents.forEach(document => {
        payload[document.id] = document.checked ? [
          ...document.items.filter(item => item.tag).map(item => {
            return {
              document_tag_id: item.tag?._id,
              template_id: item.template?._id,
              template_type: item.template?._id ? "hfTemplate" : undefined
            }
          })
        ] : []
      })

      if (isEdit) {
        dispatch(openBackdrop(`updating bom report...`));
        await updateBOMReport({ id, payload }).unwrap().then((res) => {
          enqueueSnackbar(res?.message ?? "BOM Report Updated Successfully", {
            variant: "success",
          });
          navigate(-1);
        }).catch((error: any) => {
          console.error(`Error creating bom report ${error}`)
        }).finally(() => dispatch(closeBackdrop()));
      } else {
        dispatch(openBackdrop(`creating bom report...`));
        await createBOMReport(payload).unwrap().then((res) => {
          if (res?._id) {
            // navigate(AppRoutes.previewBOMReportOnModule(getObjectType(location), p_id, res?._id), {
            //   state: res
            // })
            enqueueSnackbar(res?.message ?? "BOM Report Created Successfully", {
              variant: "success",
            });
            navigate(-1);
          }
        }).catch((error: any) => {
          console.error(`Error creating bom report ${error}`)
        }).finally(() => dispatch(closeBackdrop()));
      }
    }
  };

  const fetchParentData = async () => {
    if (p_id) {
      dispatch(openBackdrop("Fetch the module data..."))
      if (location.pathname.includes("product")) {
        await getProductById({ id: p_id }).unwrap().then((res) => {
          if (Object.keys(res ?? {}).length > 0) {
            setParentData(res);
          }
        }).catch((err: any) => {
          enqueueSnackbar(
            "Oops! Something went wrong, Unable to fetch the module data",
            {
              variant: "error",
            }
          );
          console.error("error: ", err)
        }).finally(() => {
          dispatch(closeBackdrop());
        })
      } else if (location.pathname.includes("project")) {
        await getProjectById({ id: p_id }).unwrap().then((res) => {
          if (Object.keys(res ?? {}).length > 0) {
            setParentData(res);
          }
        }).catch((err: any) => {
          enqueueSnackbar(
            "Oops! Something went wrong, Unable to fetch the module data",
            {
              variant: "error",
            }
          );
          console.error("error: ", err)
        }).finally(() => {
          dispatch(closeBackdrop());
        })
      } else {
        await getProposalById({ id: p_id }).unwrap().then((res) => {
          if (Object.keys(res ?? {}).length > 0) {
            setParentData(res);
          }
        }).catch((err: any) => {
          enqueueSnackbar(
            "Oops! Something went wrong, Unable to fetch the module data",
            {
              variant: "error",
            }
          );
          console.error("error: ", err)
        }).finally(() => {
          dispatch(closeBackdrop());
        })
      }
    }
  };

  const fetchModuleOptions = async () => {
    try {
      const productOptions = (await getAllProducts({})).data || [];
      const projectOptions = (await getAllProjects({})).data || [];
      const proposalOptions = (await getAllProposals({})).data || [];
      setModuleOptions([...productOptions, ...projectOptions, ...proposalOptions])
    } catch (error: any) {
      console.error(`Error from bom report page: ${error}`);
      setModuleOptions([]);
    }
  };

  const updateContents = (parentId: string, childId: string, isChecked: boolean) => {
    let updatedContents = copy(contents);
    let parent = updatedContents.find(content => content.id === parentId);
    if (parent) {
      let child = parent.items.find(item => item.id === childId);
      if (child) {
        child.checked = isChecked;
      }
    }
    setContents(updatedContents);
  };

  const addRow = (documentId: string) => {
    let updatedDocuments = copy(documents);
    let parent = updatedDocuments.find(document => document.id === documentId);
    if (parent) {
      parent.items.push(initialDocument)
    }
    setDocuments(updatedDocuments)
  };

  const updateDocuments = (parentId: string, index: number, key: keyof DocumentItem, value: SelectBoxOption | null) => {
    let updatedDocuments = copy(documents);
    let parent = updatedDocuments.find(content => content.id === parentId);
    if (parent) {
      parent.items.splice(index, 1, ({ ...parent.items[index], [key]: value }));
    }
    setDocuments(updatedDocuments);
  }

  const removeRow = (parentId: string, index: number) => {
    let updatedDocuments = copy(documents);
    let parent = updatedDocuments.find(content => content.id === parentId);
    if (parent) {
      parent.items.splice(index, 1)
    }
    setDocuments(updatedDocuments);
  };

  const updatePublishStatus = (row: any) => {
    dispatch(
      openConfirmationDialog({
        title: "Publish Operation",
        body: <DeletePanel
          message={`Are you sure you want to publish the bom report ${row?.sequence_id}?`}
          warningMessage={`You will not be able to edit this revision after publishing.`}
        />,
        positiveBtn: "Proceed",
        negativeBtn: "Cancel",
        onOk: () => proceedToPublish(row),
        onNegativeBtn: () => dispatch(closeConfirmationDialog())
      })
    );
  };

  const proceedToPublish = (row: any) => {
    dispatch(closeConfirmationDialog());
    dispatch(openDialog({
      title: "",
      body: <PublishComponent {...row} refetch={() => refetch(row?._id)} object_type="bomReport" object_id={row?._id} />,
      hidePositiveBtn: true,
      hideNegativeBtn: true,
      enablePadding: false
    }));
  };

  const giveMeErrorMessage = () => {
    let message = `Oops! Something went wrong, Unable to ${isEdit ? "Update" : "Create"} BOM Report. Try Again Later!`;
    let errorData: any = isEdit ? updateBOMReportData.error : createBOMReportData.error;
    message = errorData?.data?.title ?? message;
    return message;
  };

  React.useEffect(() => {
    if (p_id) {
      fetchParentData();
    } else {
      fetchModuleOptions();
    }
    // eslint-disable-next-line
  }, [])

  React.useEffect(() => {
    if (createBOMReportData.isError) {
      enqueueSnackbar(giveMeErrorMessage(), { variant: "error" });
    }
    // eslint-disable-next-line
  }, [createBOMReportData.status]);

  React.useEffect(() => {
    if (updateBOMReportData.isError) {
      enqueueSnackbar(giveMeErrorMessage(), { variant: "error" });
    }
    // eslint-disable-next-line
  }, [updateBOMReportData.status]);

  React.useEffect(() => {
    if (isEdit) {
      dispatch(openBackdrop("Updating state..."));
      let selectedId = moduleOptions.length > 0 ? { id: moduleOptions.find((d: SelectBoxOption) => d?._id === data?.object_id) || null } : {}

      let bomOp: SelectBoxOption[] = [];
      if (selectedId?.id) {
        bomOp = ConvertToReactSelect(((selectedId?.id || parentData) as any)?.associations?.find((a: any) => a.object_type === "bom")?.object_details ?? [], "_id", "sequence_id", ["version"], "r")
      }

      setState({
        ...state,
        name: data?.name,
        bom: [...bomOp, ...bomOptions].find((d: SelectBoxOption) => d?._id === data?.bom_id) || null,
        ...selectedId
      });

      if (selectedId?.id) {
        setParentData(selectedId?.id);
      }

      const updatedDocs = [
        {
          id: "bom_associated_document",
          title: "BOM Associated Documents",
          checked: data?.bom_associated_document?.length > 0 || false,
          items: data?.bom_associated_document?.length > 0 ? data?.bom_associated_document?.map((doc: any) => {
            return {
              tag: docTypeTags.find(
                (d: SelectBoxOption) =>
                  d?._id === doc?.document_tag_id
              ) || null,
              template: (templateOptions?.data ?? [])?.find(
                (d: SelectBoxOption) =>
                  d?._id === doc?.template_id
              ) || null
            }
          }) : [initialDocument]
        },
        {
          id: "material_associated_document",
          title: "Material Associated Documents",
          checked: data?.material_associated_document?.length > 0 || false,
          items: data?.material_associated_document?.length > 0 ? data?.material_associated_document?.map((doc: any) => {
            return {
              tag: docTypeTags.find(
                (d: SelectBoxOption) =>
                  d?._id === doc?.document_tag_id
              ) || null,
              template: (templateOptions?.data ?? [])?.find(
                (d: SelectBoxOption) =>
                  d?._id === doc?.template_id
              ) || null
            }
          }) : [initialDocument]
        }
      ];

      const updatedContents = [
        {
          id: "bom_details",
          title: "Include BOM Details",
          template: data?.bom_details?.length > 0 ? (templateOptions?.data ?? [])?.find(
            (d: SelectBoxOption) =>
              d?._id === data?.bom_template_id
          ) || null : null,
          checked: data?.bom_details?.length > 0 || false,
          items: [
            {
              id: "sequence_id",
              title: "BOM ID & Revision Number",
              checked: true
            },
            {
              id: "sourcing_details",
              title: "Sourcing Details",
              checked: data?.bom_details?.includes("sourcing_details")
            },
            {
              id: "short_description",
              title: "BOM Short Description",
              checked: true
            },
            {
              id: "reference_number",
              title: "Reference Number",
              checked: data?.bom_details?.includes("reference_number")
            },
            {
              id: "image",
              title: "BOM Image",
              checked: data?.bom_details?.includes("image")
            },
            {
              id: "datasheet",
              title: "Datasheet",
              checked: data?.bom_details?.includes("datasheet")
            }
          ]
        },
        {
          id: "child_bom_details",
          title: "Include Child BOM Details",
          checked: data?.child_bom_details?.length > 0 || false,
          items: [
            {
              id: "serial_num",
              title: "Tag Number",
              checked: true
            },
            {
              id: "normal_quantity",
              title: "Default Quantity",
              checked: true
            },
            {
              id: "tag",
              title: "Tag",
              checked: true
            },
            {
              id: "spare_part",
              title: "Spare Part (if applicable)",
              checked: false
            },
            {
              id: "sourcing_details",
              title: "Sourcing Details (if applicable)",
              checked: data?.child_bom_details?.includes("sourcing_details")
            },
            {
              id: "spare_part_category",
              title: "Spare Part Category (if applicable)",
              checked: data?.child_bom_details?.includes("spare_part_category")
            },
            {
              id: "spare_part_quantity",
              title: "Spare Part Quantity (if applicable)",
              checked: data?.child_bom_details?.includes("spare_part_quantity")
            },
            {
              id: "datasheet",
              title: "Datasheet",
              checked: data?.child_bom_details?.includes("datasheet")
            }
          ]
        },
        {
          id: "material_details",
          title: "Include Material Details",
          template: data?.material_details?.length > 0 ? (templateOptions?.data ?? [])?.find(
            (d: SelectBoxOption) =>
              d?._id === data?.material_template_id
          ) || null : null,
          checked: data?.material_details?.length > 0 || false,
          items: [
            {
              id: "sequence_id",
              title: "Material ID & Revision Number",
              checked: true
            },
            {
              id: "sourcing_details",
              title: "Sourcing Details",
              checked: data?.material_details?.includes("sourcing_details")
            },
            {
              id: "short_description",
              title: "Material Short Description",
              checked: true
            },
            {
              id: "reference_number",
              title: "Reference Number",
              checked: data?.material_details?.includes("reference_number")
            },
            {
              id: "uom",
              title: "Unit of Measure Details",
              checked: data?.material_details?.includes("uom")
            },
            {
              id: "image",
              title: "Material Image",
              checked: data?.material_details?.includes("image")
            },
            {
              id: "group\u0026noun",
              title: "Material Group & Noun",
              checked: data?.material_details?.includes("group&noun")
            },
            {
              id: "datasheet",
              title: "Datasheet",
              checked: data?.material_details?.includes("datasheet")
            }
          ]
        },
        {
          id: "child_material_details",
          title: "Include Child Material Details",
          checked: data?.child_material_details?.length > 0 || false,
          items: [
            {
              id: "serial_num",
              title: "Tag Number",
              checked: true
            },
            {
              id: "normal_quantity",
              title: "Default Quantity",
              checked: true
            },
            {
              id: "tag",
              title: "Tag",
              checked: true
            },
            {
              id: "spare_part",
              title: "Spare Part (if applicable)",
              checked: data?.child_material_details?.includes("spare_part")
            },
            {
              id: "sourcing_details",
              title: "Sourcing Details (if applicable)",
              checked: data?.child_material_details?.includes("sourcing_details")
            },
            {
              id: "spare_part_category",
              title: "Spare Part Category (if applicable)",
              checked: data?.child_material_details?.includes("spare_part_category")
            },
            {
              id: "spare_part_quantity",
              title: "Spare Part Quantity (if applicable)",
              checked: data?.child_material_details?.includes("spare_part_quantity")
            },
            {
              id: "datasheet",
              title: "Datasheet",
              checked: data?.child_material_details?.includes("datasheet")
            }
          ]
        },
        {
          id: "document_details",
          title: "Include Child Document Details",
          checked: data?.document_details?.length > 0 || false,
          items: [
            {
              id: "sequence_id",
              title: "Document ID & Revision Number",
              checked: true
            },
            {
              id: "document_type",
              title: "Document Type",
              checked: data?.document_details?.includes("document_type")
            },
            {
              id: "document_name",
              title: "Document Name",
              checked: true
            },
            {
              id: "serial_num",
              title: "Tag Number",
              checked: true
            },
            {
              id: "status",
              title: "Document Status",
              checked: data?.document_details?.includes("status")
            },
            {
              id: "folder_type",
              title: "Folder Type",
              checked: data?.document_details?.includes("folder_type")
            },
            {
              id: "metadata",
              title: "Document Created by, Time etc",
              checked: data?.document_details?.includes("metadata")
            }
          ]
        },
      ]

      setDocuments(updatedDocs);
      setContents(updatedContents);
    }
    // eslint-disable-next-line
  }, [data, templateOptions.status, selectOptions.status, updatedModuleOptions]);

  React.useEffect(() => {
    if (isEdit) {
      dispatch(closeBackdrop());
    }
    // eslint-disable-next-line
  }, [state.id, state.bom]);

  return (
    <Box>
      {processingStatus?.processing === true ? <MyPaper>
        <Alert
          severity="info"
          iconMapping={{
            info: <CircularProgress size={"1em"} color="info" />,
          }}>
          Processing bom report...
        </Alert>
      </MyPaper> : (
        <>
          <MyPaper padding={0}>
            <>
              <Stack px={1.5} direction={"row"} justifyContent={"space-between"} alignItems={"center"} sx={{ borderBottom: (theme) => `1px solid ${theme.palette.divider}`, }}>
                {/* Title */}
                <Typography
                  variant="h6"
                  color="textPrimary"
                  sx={{
                    width: "100%",
                    fontFamily: "htrts_medium",
                  }}
                >
                  {`${isEdit ? isViewOnly ? "View " : "Update" : "Create"} BOM Report`}
                </Typography>

                {/* Publish */}
                {isEdit &&
                  data?.released ? (
                  <Stack direction={"row"} alignItems={"center"}>
                    <Typography variant="body2" color="textSecondary">Status: </Typography>
                    <Button disableRipple disableFocusRipple sx={{ cursor: "default", '&:hover': { backgroundColor: 'transparent' } }}>
                      <Tooltip
                        title="Published"
                        children={<Box width={30} height={30} alignItems={"center"} justifyContent={"center"}><PublishedIcon sx={{ width: "100%", height: "100%" }} /></Box>}
                      />
                    </Button>
                  </Stack>
                ) : isEdit && (
                  <Stack direction={"row"} alignItems={"center"}>
                    <Typography variant="body2" color="textSecondary">Status: </Typography>
                    <Button disableRipple disableFocusRipple>
                      <Tooltip
                        title={publishable ? "Click to Publish" : "Not Published"}
                        children={
                          <Box width={30} height={30} alignItems={"center"} justifyContent={"center"}><WIPIcon sx={{ width: "100%", height: "100%" }} onClick={() => publishable ? updatePublishStatus(data) : false} /></Box>
                        }
                      />
                    </Button>
                  </Stack>
                )
                }
              </Stack>

              <Stack sx={{ marginTop: 3, px: 1.5, pb: 1.5 }} spacing={2}>
                {/* Name */}
                <TextField
                  id="bom-report-name"
                  label={"Name"}
                  value={state.name}
                  required={true}
                  fullWidth
                  size={"small"}
                  variant="outlined"
                  placeholder={"Enter BOM Report Name *"}
                  error={error.name}
                  helperText={error.name ? "Please enter BOM Report name" : ""}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    updateState("name", e.target.value)
                  }
                  viewOnly={isViewOnly}
                />

                {/* ID */}
                {parentData?._id && updatedModuleOptions.length <= 0 ? <Box sx={{ paddingTop: 2 }}>
                  <TextField
                    label={"ID"}
                    defaultValue={parentData.sequence_id}
                    disabled={true}
                    fullWidth
                    size={"small"}
                    viewOnly={isViewOnly}
                  />
                </Box> :
                  <HighlightSelectBox
                    label={"Select Project ID or Proposal ID or Product ID *"}
                    onChange={(data: SelectBoxOption) => {
                      updateState("id", data)
                      setParentData(data)
                      updateState("bom", null)
                    }}
                    loading={moduleLoading}
                    options={updatedModuleOptions}
                    value={updatedModuleOptions.find((d: SelectBoxOption) => d?.value === state.id?.value) ||
                      null}
                    error={error.id}
                    helperText={
                      error.id ? "Please select Project ID or Proposal or Product ID" : ""
                    }
                    viewOnly={isViewOnly}
                  />}

                {/* BOM */}
                <HighlightSelectBox
                  id="select-bom"
                  label={"Select BOM *"}
                  onChange={(data: SelectBoxOption | null) => updateState("bom", data)}
                  options={bomOptions}
                  value={
                    bomOptions.find((d: SelectBoxOption) => d?.value === state.bom?.value) ||
                    null
                  }
                  error={error.bom}
                  helperText={
                    error.bom ? "Please select BOM" : ""
                  }
                  viewOnly={isViewOnly}
                />
              </Stack>
            </>
          </MyPaper>

          {/* Contents */}
          <Stack sx={{ marginTop: 3 }} spacing={2}>
            <SectionPaper title="Select Content">
              <>
                {contents.map((content, idx: number) => (
                  <Box key={content.id} mt={idx > 0 ? 2 : 0}>
                    <Grid container alignItems={"center"}>
                      <Grid item xs={12} md={6}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={content.checked}
                              onChange={() => {
                                let updatedContents = copy(contents);
                                let parent = updatedContents.find(ct => ct.id === content.id);
                                if (parent) {
                                  parent.checked = !content.checked;
                                  if (!parent.checked) {
                                    parent.template = null;
                                  }
                                }
                                setContents(updatedContents);
                              }}
                              disabled={isViewOnly}
                            />
                          }
                          label={content.title}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        {(content.id === "bom_details" || content.id === "material_details") && (
                          <HighlightSelectBox
                            id={content.id}
                            margin="none"
                            label={"Select header and footer template"}
                            value={
                              (templateOptions?.data ?? [])?.find(
                                (d: SelectBoxOption) =>
                                  d?.value === content.template?.value
                              ) || null
                            }
                            options={templateOptions?.data ?? []}
                            onChange={(data: SelectBoxOption | null) => {
                              let updatedContents = copy(contents);
                              let parentIndex = updatedContents.findIndex(ct => ct.id === content.id);
                              if (parentIndex > -1) {
                                updatedContents.splice(parentIndex, 1, { ...updatedContents[parentIndex], template: data });
                              }
                              setContents(updatedContents);
                            }}
                            viewOnly={isViewOnly}
                          />
                        )}
                      </Grid>

                    </Grid>
                    <Box pl={4} pt={1}>
                      <Grid container>
                        {content.items.map((itm) => (
                          <Grid item xs={12} md={6} key={itm.id}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={itm.checked}
                                  onChange={() => updateContents(content.id, itm.id, !itm.checked)}
                                  disabled={!content.checked || preSelectedFields.includes(itm.id) || isViewOnly}
                                />
                              }
                              label={itm.title}
                            />
                          </Grid>
                        ))}
                      </Grid>
                    </Box>
                  </Box>
                ))}
              </>
            </SectionPaper>
          </Stack>

          {/* Contents */}
          <Stack sx={{ marginTop: 3 }} spacing={2}>
            <SectionPaper title="Select Document">
              <>
                {documents.map((document, idx: number) => (
                  <Box key={document.id} mt={idx > 0 ? 2 : 0}>
                    <Stack direction={"row"} justifyContent={"space-between"}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={document.checked}
                            onChange={() => {
                              let updatedDocuments = copy(documents);
                              let parent = updatedDocuments.find(doc => doc.id === document.id);
                              if (parent) {
                                parent.checked = !document.checked;
                              }
                              setDocuments(updatedDocuments)
                            }}
                            disabled={isViewOnly}
                          />
                        }
                        label={document.title}
                      />
                      {!isViewOnly && <Button color="primary" variant="contained" onClick={() => addRow(document.id)}>Add Row</Button>}
                    </Stack>
                    <Box pl={4} pt={2}>
                      <Grid container gap={2}>
                        {document.items.map((doc, index: number) => (
                          <Grid container item key={index} columnSpacing={4} columns={13} alignItems={"flex-start"}>
                            <Grid item xs={13} md={6}>
                              <HighlightSelectBox
                                id={`${document.id}-tag`}
                                margin="none"
                                label={"Document Type Tag"}
                                onChange={(data: SelectBoxOption | null) =>
                                  updateDocuments(document.id, index, "tag", data)
                                }
                                options={docTypeTags}
                                value={
                                  docTypeTags.find(
                                    (d: SelectBoxOption) =>
                                      d?.value === doc.tag?.value
                                  ) || null
                                }
                                error={error.documents?.[document.id]?.[index]?.tag}
                                helperText={
                                  error.documents?.[document.id]?.[index]?.tag ? "Please select Tag" : ""
                                }
                                viewOnly={isViewOnly}
                              />
                            </Grid>
                            <Grid item xs={13} md={6}>
                              <HighlightSelectBox
                                id={`${document.id}-template`}
                                margin="none"
                                label={"Select Template"}
                                value={
                                  (templateOptions?.data ?? [])?.find(
                                    (d: SelectBoxOption) =>
                                      d?.value === doc.template?.value
                                  ) || null
                                }
                                options={templateOptions?.data ?? []}
                                onChange={(data: SelectBoxOption | null) =>
                                  updateDocuments(document.id, index, "template", data)
                                }
                                error={error.documents?.[document.id]?.[index]?.template}
                                helperText={
                                  error.documents?.[document.id]?.[index]?.template ? "Please select template" : ""
                                }
                                viewOnly={isViewOnly}
                              />
                            </Grid>
                            <Grid item xs={13} md={1}>
                              <Button variant={"outlined"} color="error" disabled={index === 0 || isViewOnly} sx={{ cursor: "pointer" }} onClick={() => removeRow(document.id, index)}>
                                <Delete />
                              </Button>
                            </Grid>
                          </Grid>
                        ))}
                      </Grid>
                    </Box>
                  </Box>
                ))}
              </>
            </SectionPaper>
          </Stack></>
      )
      }

      {/* Alert Box */}
      {(createBOMReportData.isError || updateBOMReportData.isError) && <Alert sx={{ mt: 2 }} severity="error">{
        giveMeErrorMessage()
      }</Alert>}

      {!isViewOnly && <Box mt={3}>
        <MyPaper>
          <>
            {/* Actions */}
            <Stack
              gap={2}
              flexDirection={"row"}
              alignItems={"center"}
              justifyContent={"center"}
            >
              <Button
                variant="outlined"
                onClick={onCancelBtnClicked}
                disabled={
                  createBOMReportData.isLoading || updateBOMReportData.isLoading
                }
              >
                Cancel
              </Button>

              <LoadingButton
                id="c-u-btn"
                variant="contained"
                onClick={onCreateUpdateBtnClicked}
                disabled={data?.released}
                loading={
                  createBOMReportData.isLoading || updateBOMReportData.isLoading || processingStatus?.processing === true
                }
              >
                {`${isEdit ? "Update" : "Create"}`}
              </LoadingButton>
            </Stack>
          </>
        </MyPaper>
      </Box>}
    </Box>
  );
};

export const AddEditBOMReport = () => {

  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  const { enqueueSnackbar } = useSnackbar();

  const location: any = useLocation();

  const paths = location?.state?.paths ?? [];

  const { id = "New" } = useParams();

  const [getBOMReportById, bomReportResponse] = useLazyGetBOMReportByIdQuery();

  const fetchBOMreport = async (id: string) => {
    dispatch(openBackdrop("Fetching BOM Report data..."));
    await getBOMReportById({ id, include_bom: false }).unwrap().catch(() => {
      enqueueSnackbar(
        (bomReportResponse?.error as any)?.title ?? "Fetching BOM Report Failed",
        { variant: "error" }
      );
    }).finally(() => {
      dispatch(closeBackdrop());
    })
  }

  React.useEffect(() => {
    if (id !== "New") {
      fetchBOMreport(id);
    }
    // eslint-disable-next-line
  }, []);

  return (
    <Box padding={1}>
      <Layout
        history={[
          ...paths.map((_: any) => {
            return { label: _.title, onClick: () => navigate(_.path) };
          }),
        ]}
        currentPath={`${bomReportResponse?.data?.sequence_id ?? "..."}-r${bomReportResponse?.data?.version ?? 0}`}
        navBars={[]}
        mainPanel={<RenderBOMReport data={bomReportResponse?.data} refetch={fetchBOMreport} />}
        sideNavVariant={"whiteboard"}
        otherBreadscrumProps={{
          hideMenuBtn: true,
          showBackBtn: false,
        }}
        locationState={paths}
        onBackBtnClick={paths[0]?.path ? () => navigate(paths[0]?.path) : undefined}
      />
    </Box>
  );
};