import { CloudDownload, ReportGmailerrorred, ZoomOutMapTwoTone } from "@mui/icons-material";
import { Box, CircularProgress, IconButton, Tooltip } from "@mui/material";
import { Viewer, Worker } from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import { useSnackbar } from "notistack";
import React, { useContext } from "react";
import { SocketContext } from "../../contexts";
import { useAppDispatch } from "../../redux";
import { useLazyGetDocumentByIdQuery } from "../../redux/services";
import { closeBackdrop, openBackdrop, openBackdropWithContent } from "../../redux/slices/backdrop";
import { ThreeDViewer } from "../3dViewer";


const tooltipTitle = (key: string, isPreview: boolean | undefined, isPreviewAvailable: boolean): string => {
    let status: { [key: string]: string } = {
        'pending': "Uploading",
        'saved': isPreview ? isPreviewAvailable ? 'Preview' : 'Preview not available' : 'Download',
        'failed': 'Error on Upload'
    }
    return status[key];
}

const previewableTypes = ['pdf', 'jpeg', 'jpg', 'png', 'gif', 'bmp', 'tiff', 'svg', 'ico', 'docx', 'doc'];

const threeDModalTypes = [
    "FCStd",
    "glb",
    "bim",
    "3dm",
    "dae",
    "fbx",
    "brep",
    "igs",
    "stp",
    "bin",
    "gltf",
    "ply",
    "off",
    "3ds",
    "wrl",
    "ifc",
    "mtl",
    "obj",
    "3mf",
    "amf",
    "stl",
    "step"
];

export const DownloadDoc: React.FC<{ url: string, status: string, id: string, preview?: boolean, fileType?: string }> = (props) => {

    const [getDocumentAPI] = useLazyGetDocumentByIdQuery();
    const dispatch = useAppDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const { receivedEvents, clearReceivedEvent } = useContext(SocketContext);
    const defaultLayoutPluginInstance = defaultLayoutPlugin();

    const [status, updateStatus] = React.useState<string>(props.status)

    const getDocuments = async () => {
        dispatch(openBackdrop("Downloading Document..."))
        getDocumentAPI({ document_id: props.id, url_type: props.preview ? "inline" : "attachment" }).then(res => {
            if (props.preview) {
                if (threeDModalTypes.includes(props.fileType?.toLowerCase() ?? "")) {
                    dispatch(openBackdropWithContent(
                        <ThreeDViewer url={res.data.url} file_name={res.data?.file_name} />
                    ));
                } else {
                    dispatch(openBackdropWithContent(<Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js">
                        <div style={{  width: window.innerWidth * 0.80 , height: "95vh" }}>
                            <Viewer
                                fileUrl={res.data.url}
                                plugins={[
                                    defaultLayoutPluginInstance
                                ]}
                            />
                        </div>
                    </Worker>))
                }
            } else {
                let downloadLink = document.getElementById('downloadLink');
                downloadLink?.setAttribute('href', res.data.url);
                downloadLink?.click();
                dispatch(closeBackdrop())
            }
        }).catch(err => {
            dispatch(closeBackdrop());
            enqueueSnackbar('Something went wrong, unable to get document url', { variant: "error" })
        })
    }

    const isPreviewAvailable = previewableTypes.includes(props.fileType?.toLowerCase() ?? "") || threeDModalTypes.includes(props.fileType?.toLowerCase() ?? "");

    React.useEffect(() => {
        let socketData = receivedEvents?.["document-status"]?.[props.id] || {};
        if (status === 'pending' && Object.keys(socketData).length > 0) {
            let payload = socketData;
            if (payload.status === 'saved') {
                updateStatus("saved");
            } else {
                updateStatus("failed");
            }
            if (props.preview) {
                enqueueSnackbar(payload.alert, { variant: payload.status === 'saved' ? 'success' : 'error' });
                clearReceivedEvent("document-status", props.id);
            }
        }
        // eslint-disable-next-line
    }, [receivedEvents?.["document-status"], receivedEvents?.["document-status"]?.[props.id]]);

    return <Box sx={{ width: "100%", textAlign: "center" }}>
        {/* eslint-disable-next-line */}
        <a id="downloadLink" href="#" target="_blank" style={{ display: "none" }} download >Download</a>
        <Tooltip title={tooltipTitle(status, props.preview, isPreviewAvailable) ?? ""}>
            <Box textAlign={"center"}>
                {status === 'pending' && <CircularProgress size={20} />}
                {status === 'saved' && <>
                    {props.preview && <>
                        {<IconButton disabled={!isPreviewAvailable} size={"small"} onClick={() => getDocuments()}>
                            <ZoomOutMapTwoTone color={isPreviewAvailable ? "info" : "disabled"} fontSize="small" />
                        </IconButton >}
                    </>}
                    {!props.preview && <IconButton size={"small"} onClick={() => getDocuments()}>
                        <CloudDownload color="info" fontSize="small" />
                    </IconButton >}
                </>
                }
                {status === 'failed' && <ReportGmailerrorred color="error" fontSize="small" />}
            </Box>
        </Tooltip>
    </Box>
}
