import { AlignHorizontalLeft, DocumentScanner } from "@mui/icons-material";
import { AppRoutes } from "../../router/routes";

export const NavBars = (id: string, isEdit: boolean = false) => ([
    {
        id: "1",
        title: "Basic Details",
        icon: <AlignHorizontalLeft fontSize="small" />,
        path: AppRoutes.viewCutSheetBasicDetails(id)
    },
    {
        id: "2",
        title: "Cusheet",
        disabled: false,
        icon: <DocumentScanner fontSize="small" />,
        path: AppRoutes.viewCutSheets(id)
    }
]);