import Timeline from '@mui/lab/Timeline';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineOppositeContent, {
    timelineOppositeContentClasses,
} from '@mui/lab/TimelineOppositeContent';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import { Box, Typography } from "@mui/material";
import moment from "moment";
import React from "react";
import { useLazyGetDocumentByIdQuery, useLazyGetMaterialByIdQuery } from "../../redux/services";
import { InternatlServerError, LoadingSection } from "../integrationHelper";

export const History: React.FC<{ variant: 'material' | 'document', material_id?: string, document_id?: string }> = ({ variant, material_id, document_id }) => {

    const [getDocumentHistory, documentHistory] = useLazyGetDocumentByIdQuery();
    const [getMaterialHistory, materialHistory] = useLazyGetMaterialByIdQuery();

    const refetch = () => {
        if (variant === 'material') {
            getMaterialHistory({ sequence_id: material_id, include_history: true });
        } else if (variant === 'document') {
            getDocumentHistory({ sequence_id: document_id, include_history: true, tag_group_id: "63e1f657efc556213c30aabe", tag_id: "6411f31d68b79ad24506a318" });
        }
    }

    React.useEffect(() => {
        refetch();
        // eslint-disable-next-line
    }, []);

    let data = null;

    if (variant === 'material') {
        data = materialHistory;
    } else if (variant === 'document') {
        data = documentHistory;
    }

    return <Box>
        <Typography variant='h6' sx={{ textAlign: "center", marginTop: 2, fontWeight: "bold" }} >Document Version History</Typography>
        {data?.isLoading && <LoadingSection message={`Fetching ${variant === 'document' ? 'Document' : 'Material'} Histories....`} />}

        {data?.isError && <InternatlServerError message="Unable to fetch history" onRetry={refetch} />}

        {data?.isSuccess && <Timeline
            sx={{
                [`& .${timelineOppositeContentClasses.root}`]: {
                    flex: 0.2,
                },
            }}
        >
            {data?.data?.map((_: any, index: number) => <TimelineItem key={index}>
                <TimelineOppositeContent color="textSecondary">
                    {_.version}
                </TimelineOppositeContent>
                <TimelineSeparator>
                    <TimelineDot />
                    <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent>
                    {moment(_.created_time).format("lll")}
                </TimelineContent>
            </TimelineItem>
            )}
        </Timeline>}
    </Box>
}