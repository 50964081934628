import { Box } from "@mui/material";
import { useSnackbar } from "notistack";
import React from "react";
import { matchPath, useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { Layout, Documents } from "../../components";
import { withNavBars } from "../../HOCs";
import { useAppDispatch, useAppSelector } from "../../redux";
import { useLazyGetVendorByIdQuery, useLazyGetDocumentsByTagGroupIdQuery, useGetLocationAndDocumentTagsQuery, useLinkModuleMutation } from "../../redux/services";
import { closeBackdrop, openBackdrop } from "../../redux/slices/backdrop";
import { closeConfirmationDialog, openConfirmationDialog } from "../../redux/slices/confirmationDialog";
import { updateVendorSlice } from "../../redux/slices/vendor";
import { AppRoutes } from "../../router/routes";
import { BasicDetails } from "./basicDetails";
import { ApprovedOptions, NavBars, StatusOptions } from "./utils";
import { resetState } from "../../redux/slices/supplierMapping";
import { linksData } from "../../redux/slices/bom";
import { HasAccess } from "../../router/authorization";
import { PERMISSIONS } from "../../router/permission";

const ViewVendor: React.FC<{ children?: JSX.Element, currentPath: string, readOnly?: boolean }> = ({ currentPath, readOnly }) => {

    const params = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useAppDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const [getVendorDetailById, vendorData] = useLazyGetVendorByIdQuery({ refetchOnFocus: true });
    const [linkDocument] = useLinkModuleMutation();
    const [getDocumentsByTagGroupId, { currentData: data, isLoading }] = useLazyGetDocumentsByTagGroupIdQuery();
    const folderData = useGetLocationAndDocumentTagsQuery(null);
    const [searchParams] = useSearchParams();

    const vendorId = params?.id ?? "New";

    const isEdit = !['New', ':id'].includes(vendorId);

    const { perm } = useAppSelector(store => store.auth.userDetails);

    const creatable = React.useMemo(() => HasAccess(perm, PERMISSIONS.SUPPLIER_CREATE), [perm]);
    const readable = React.useMemo(() => HasAccess(perm, PERMISSIONS.SUPPLIER_READ), [perm]);
    const updatable = React.useMemo(() => HasAccess(perm, PERMISSIONS.SUPPLIER_UPDATE), [perm]);
    const deletable = React.useMemo(() => HasAccess(perm, PERMISSIONS.SUPPLIER_DELETE), [perm]);
    const publishable = React.useMemo(() => HasAccess(perm, PERMISSIONS.SUPPLIER_UPDATE), [perm]);

    const giveMePayload = (links: any): linksData => {
        let obj: linksData = {
            links: [{ object_ids: links, object_type: 'document' }],
        }
        return obj;
    }

    const callLinkDocument = async (links?: any, refetch?: any) => {
        const linkedData = giveMePayload(links);
        dispatch(openBackdrop("Linking the document..."));
        dispatch(updateVendorSlice({ links: linkedData.links }))
        let res: any = {};
        try {
            res = await linkDocument({ object_id: vendorId, object_type: "vendor", payload: linkedData })

            if (Object.keys(res).includes("data")) {
                enqueueSnackbar(`Linked document Successfully!`, { variant: "success" })
                dispatch(closeConfirmationDialog());
                getVendorDetailById({ id: vendorId });
                refetch();
            } else if (Object.keys(res).includes("error")) {
                // eslint-disable-next-line 
                throw res.error.data;
            }
        } catch (error: any) {
            let errorMessage: string = error?.title ?? error ?? "Oops! Something went wrong, Unable to link document"
            enqueueSnackbar(errorMessage, { variant: "error" });
        } finally {
            dispatch(closeBackdrop());
        }
    }

    const giveMeComponent = () => {
        // Basic Details
        if (matchPath(location.pathname, AppRoutes.viewVendorBasicDetails(vendorId))
            || matchPath(location.pathname, AppRoutes.viewVendorBasicDetailsReadOnly(vendorId))) {
            return <BasicDetails isEdit={isEdit} vendorId={vendorId} readOnly={readOnly} />
        }

        // Documents
        if (matchPath(location.pathname, AppRoutes.viewVendorDocuments(vendorId))) {
            return <Documents
                refetch={refetch}
                Id={vendorData?.data?.sequence_id ?? ""}
                _id={vendorId}
                objectType="vendor"
                tag_id={searchParams.get('tag_id')}
                tag_group_id={searchParams.get('group_id')}
                linkDocument={(links: any, refetch: any) => callLinkDocument(links, refetch)}
                associations={vendorData?.data?.associations ?? []}
                creatable={creatable}
                updatable={updatable}
                publishable={publishable}
                deletetable={deletable}
                readable={readable}
            />
        }

        return <></>
    };

    const refetch = () => {
        refetchDocumentFolders();
        getVendorDetailById({ id: vendorId });
    }

    const refetchDocumentFolders = () => {
        getDocumentsByTagGroupId({ tag_group_id: folderData.data?.locationId, object_type: "vendor", object_ids: [vendorId] })
    };

    React.useEffect(() => {
        if (vendorId === ':id') {
            dispatch(closeBackdrop());
            navigate(AppRoutes.vendors)
        }
    });

    React.useEffect(() => {
        if (isEdit) {
            getVendorDetailById({ id: vendorId });
            refetchDocumentFolders();
        } else {
            dispatch(resetState(null));
        }
        // eslint-disable-next-line    
    }, [params]);

    React.useEffect(() => {
        if (vendorData.isLoading || vendorData.isFetching) {
            dispatch(openBackdrop("Fetching Supplier Details..."));
        } else {
            dispatch(closeBackdrop());
        }
        // eslint-disable-next-line
    }, [vendorData.isLoading])

    React.useEffect(() => {
        const updateValue = async () => {
            if (isEdit && vendorData.isSuccess) {
                const data = vendorData.currentData;
                dispatch(updateVendorSlice({
                    basic_details: { vendor_name: data.vendor_name, vendor_website: data.website, approved_vendor: data.approved ? ApprovedOptions[0] : ApprovedOptions[1], status: data.status === "inactive" ? StatusOptions[1] : StatusOptions[0] },
                    primary_contact: {
                        name: data.primary_contact?.person_name, email: data.primary_contact?.email, mobile: data.primary_contact?.phone, address: data.primary_contact?.address, country: {
                            value: data.primary_contact?.country, label: data.primary_contact?.country
                        }
                    },
                    secondary_contact: {
                        name: data.secondary_contact?.person_name, email: data.secondary_contact?.email, mobile: data.secondary_contact?.phone, address: data.secondary_contact?.address, country: {
                            value: data.secondary_contact?.country, label: data.secondary_contact?.country
                        }
                    },
                    applicable_materials: data?.applicable_materials ?? [],
                    error: {
                        basic_details: { vendor_name: false, vendor_website: false, approved_vendor: false, status: false },
                        primary_contact: { name: false, email: false, mobile: false, address: false, country: false },
                        secondary_contact: { name: false, email: false, mobile: false, address: false, country: false },
                    }
                }))
            }
        }
        updateValue();
        // eslint-disable-next-line
    }, [vendorData.isSuccess]);

    React.useEffect(() => {
        if (!vendorData.isFetching && vendorData.isError) {
            enqueueSnackbar('404 - Unable to find the requested Supplier details', { variant: "error" });
            dispatch(openConfirmationDialog({
                title: "404 - Not Found",
                body: "Unable to find the requested Supplier details",
                positiveBtn: "Ok",
                onOk: () => {
                    dispatch(closeConfirmationDialog())
                    navigate(AppRoutes.vendors)
                },
                hideNegativeBtn: true
            }))
        }
        // eslint-disable-next-line
    }, [vendorData.isError]);

    React.useEffect(() => {
        if (folderData.isSuccess) {
            refetchDocumentFolders();
        }
        // eslint-disable-next-line
    }, [folderData.isSuccess]);

    const documentFolders = data?.filter((_: any) => _.object_id === vendorId)?.[0] ?? { tags: [], tag_group_id: "" };


    return <Box p={0.5} height={"100%"}>
        <Layout
            history={[
                { label: "Suppliers", onClick: () => navigate(AppRoutes.vendors) },
                { label: `${vendorData.isLoading ? "Loading..." : vendorData?.data?.sequence_id ?? "New"}`, onClick: () => navigate(AppRoutes.viewVendorBasicDetails(vendorId)) }
            ]}
            currentPath={currentPath}
            navBars={NavBars(vendorId, isEdit, isLoading || folderData.isLoading, documentFolders.tags ?? [], documentFolders?.tag_group_id ?? "")}
            mainPanel={giveMeComponent()}
            sideNavVariant={"whiteboard"}
            resizable
        />
    </Box>
}

export default withNavBars(ViewVendor);