import React from "react";
import { Stack, TextField, Typography, Button, DialogTitle, DialogContent, DialogActions, Grid } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useDispatch } from "react-redux";
import { closeDialog } from "../../../../redux/slices/dialog";
import { useCreateUoMMutation, useUpdateUoMMutation } from "../../../../redux/services";
import { useSnackbar } from "notistack";
import { ReferenceCard, ReferenceCardRow } from "../../../../components";
import GreyAccordian from "../../../../components/greyAccordian";
import copy from "fast-copy";

export const AddUpdateUoM: React.FC<{ children?: JSX.Element, isEdit: boolean, data: any, refetch: any }> = (props) => {

    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const [createUoM, createUoMData] = useCreateUoMMutation();
    const [updateUoM, updateUoMData] = useUpdateUoMMutation();

    const [state, setState] = React.useState({ name: "", description: ""});
    const [error, setError] = React.useState({ name: false, description: false});
    const [rows, setRows] = React.useState<ReferenceCardRow[]>([{ id: "", name: "" }]);


    const updateState = (key: string, value: string) => {
        setState({ ...state, [key]: value });
        setError({ ...error, [key]: !value.length })
    }

    const onCancelBtnClicked = () => {
        dispatch(closeDialog());
    }

    const onCreateUpdateBtnClicked = () => {
        if (state.name.length && state.description.length) {
            if (props.isEdit) {
                updateUoM({ uom_name: state.name, uom_desc: state.description, _id: props.data.id, reference_metadata: rows })
            } else {
                createUoM({ uom_name: state.name, uom_desc: state.description, reference_metadata: rows });
            }
        } else {
            setError({ ...error, name: !state.name.length, description: !state.description.length })
        }
    }

    React.useEffect(() => {
        if (createUoMData.isSuccess) {
            enqueueSnackbar("UoM Created Successfully", { variant: "success" });
            props.refetch();
            dispatch(closeDialog());
        }
        if (createUoMData.isError) {
            enqueueSnackbar("Oops! Something went wrong, Unable to create UoM", { variant: "error" })
        }
        // eslint-disable-next-line
    }, [createUoMData.status]);

    React.useEffect(() => {
        if (updateUoMData.isSuccess) {
            enqueueSnackbar("UoM Updated Successfully", { variant: "success" });
            props.refetch();
            dispatch(closeDialog());
        }
        if (updateUoMData.isError) {
            enqueueSnackbar("Oops! Something went wrong, Unable to update UoM", { variant: "error" })
        }
        // eslint-disable-next-line
    }, [updateUoMData.status]);

    React.useEffect(() => {
        if (props.isEdit && props.data?.id) {
            setState({ ...state, name: props.data.uom_name, description: props.data.uom_desc })
            setRows(props.data?.reference_metadata ?? [{ id: "", name: "" }]);
        }
        // eslint-disable-next-line
    }, [props.isEdit]);

    return <>
        {/* Title */}
        <DialogTitle>
            <Typography
                variant="h6"
                color="textPrimary"
                sx={{
                    borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
                    width: "100%", fontFamily: 'htrts_medium'
                }}
            >
                {`${props.isEdit ? "Update" : "Create"} Regions`}
            </Typography>
        </DialogTitle>

        {/* Fields */}
        <DialogContent>
            <Stack spacing={2} mt={1}>
                {/* Name */}
                <TextField
                    id="region-name"
                    label={"Name"}
                    value={state.name}
                    required={true}
                    error={error.name}
                    helperText={error.name ? "Please enter UoM name" : ""}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => updateState("name", e.target.value)}
                />

                {/* Description */}
                <TextField
                    id="region-description"
                    label={"Description"}
                    value={state.description}
                    required={true}
                    error={error.description}
                    helperText={error.description ? "Please enter UoM description." : ""}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => updateState("description", e.target.value)}
                />

                {/* Reference Card */}
                <GreyAccordian title="Reference Card" content={
                    <ReferenceCard rows={copy(rows)} setRows={(newRows) => setRows([...newRows])} hideTitle={true} />
                } />
            </Stack>
        </DialogContent>

        <DialogActions>
            <Grid container spacing={2} px={2} pb={1}>
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                    {/* Add UoM Btn */}
                    <LoadingButton
                        id="u-c-region"
                        fullWidth
                        variant="contained"
                        onClick={onCreateUpdateBtnClicked}
                        loading={createUoMData.isLoading || updateUoMData.isLoading}
                    >
                        {`${props.isEdit ? "Update" : "Create"}`}
                    </LoadingButton>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                    {/* Cancel Btn */}
                    <Button
                        fullWidth
                        variant="outlined"
                        onClick={onCancelBtnClicked}
                        disabled={createUoMData.isLoading || updateUoMData.isLoading}
                    >
                        Cancel
                    </Button>
                </Grid>
            </Grid>
        </DialogActions>
    </>
}