import { DeleteOutline } from "@mui/icons-material";
import { IconButton, Tooltip, Typography } from "@mui/material";
import { GridRenderCellParams } from "@mui/x-data-grid-premium";
import moment from "moment";
import { Association } from "../../../interfaces";

const modules = ["project", "product", "proposal"]

export const DashboardTableColumn = (view: any, onDelete: (row: any) => void, isInternal: boolean) => ([
    {
        field: 'sequence_id', headerName: 'Insearch Id', width: 200, renderCell: (params: GridRenderCellParams) => {
            const disableAccess = isInternal ? false : params.row?.associations?.find((a: Association) => modules.includes(a.object_type));
            const elem = <Typography
                onClick={() => disableAccess ? () => { } : view(params.row)}
                variant="body2"
                component={"a"}
                color={disableAccess ? "grey" : "primary"}
                sx={{ width: "100%", overflow: "hidden", textOverflow: "ellipsis", cursor: disableAccess ? "default" : "pointer", textDecoration: disableAccess ? "none" : "underline" }}>
                {params.row.sequence_id}
            </Typography>;
            
            return disableAccess 
                ? 
                    <Tooltip title={`This item belongs to ${disableAccess?.object_details?.[0]?.sequence_id}`}>
                        {elem}
                    </Tooltip> 
                : 
                    <>
                        {elem}
                    </>
        }
    },
    {
        field: 'name', headerName: 'Search Name', width: 200, renderCell: (params: GridRenderCellParams) => {
            return <Typography variant="body2" sx={{ width: "100%", overflow: "hidden", textOverflow: "ellipsis" }}>{params.row.name}</Typography>
        }
    },
    {
        field: 'reason', headerName: 'Reason For Search', width: 200, renderCell: (params: GridRenderCellParams) => {
            return <Typography variant="body2" sx={{ width: "100%", overflow: "hidden", textOverflow: "ellipsis" }}>{params.row.reason}</Typography>
        }
    },
    { field: 'created_user', headerName: 'Created By', width: 150 },
    {
        field: 'created_time', headerName: 'Created At', width: 250, renderCell: (params: GridRenderCellParams) => {
            return <Typography variant="body2">{moment(params.row.created_time).format('lll')}</Typography>
        }
    },
    { field: 'modified_user', headerName: 'Uploaded By', width: 150 },
    {
        field: 'modified_time', headerName: 'Uploaded At', width: 250, renderCell: (params: GridRenderCellParams) => {
            return <Typography variant="body2">{moment(params.row.modified_time).format('lll')}</Typography>
        }
    },
    {
        field: 'delete', headerName: 'Delete', width: 100, renderCell: (params: GridRenderCellParams) => {
            const disableAccess = params.row?.associations?.find((a: Association) => modules.includes(a.object_type));
            return <IconButton onClick={() => onDelete(params.row)} disabled={disableAccess}>
                <DeleteOutline color={disableAccess ? "disabled" : "error"} />
            </IconButton>
        },
        disableColumnMenu: true,
        sortable: false,
    }
]);