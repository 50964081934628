import React from "react";
import Select, { components, GroupHeadingProps } from "react-select";
import { Typography, useTheme, Box, Link, Stack, Tooltip, Chip } from "@mui/material";
import { useLazySearchNounsQuery } from "../../redux/services";
import { NounSelectBoxInterface, Option, customStyles } from "./util";
import { LocalStorageKeys } from "../../utils";
import { SelectBoxOption } from "../../interfaces";

export const NounSelectBoxWithSearch: React.FC<NounSelectBoxInterface> = ({
    value = null, onChange = () => null,
    label = "", isRequired = false, placeholder = "", isMulti = false, disabled = false, height = null, uom_id = null, viewOnly = false
}) => {

    const theme = useTheme();
    const [searchNoun, nounData] = useLazySearchNounsQuery();
    const [search, setSearch] = React.useState("");

    const onSelectInputChange = (search: string) => {
        setSearch(search);
        return search;
    }

    const clearRecentSearches = () => {
        localStorage.removeItem(LocalStorageKeys.nounRecentSearch);
        setSearch("");
        searchNoun({ search: "", uom_id });
    }

    const GroupHeading = (props: GroupHeadingProps<{ label: string, options: SelectBoxOption[], value: string, group_name: string, attachments: any, isRecent?: boolean, }>) => (
        <Stack direction="row" justifyContent={"space-between"}>
            <components.GroupHeading {...props} />
            {localStorage.getItem(LocalStorageKeys.nounRecentSearch) ?
                <Link style={{ marginRight: "8px", marginTop: "-2px", cursor: "pointer" }} variant="caption" color={"error"} onClick={clearRecentSearches}>
                    Clear All
                </Link> : <></>}
        </Stack>
    );

    const onOptionSelect = (data: any) => {
        let existingRecentSearch: any = localStorage.getItem(LocalStorageKeys.nounRecentSearch);
        if (!existingRecentSearch) {
            existingRecentSearch = {
                [data?.value]: { ...data, isRecent: true, uom_id }
            };
        } else {
            existingRecentSearch = JSON.parse(existingRecentSearch);
            existingRecentSearch[data?.value] = { ...data, isRecent: true, uom_id };
        }
        localStorage.setItem(LocalStorageKeys.nounRecentSearch, JSON.stringify(existingRecentSearch));
        onChange(data);
    }

    const giveMeOptions = () => {
        if (search.trim().length === 0) {
            let existingRecentSearch: any = localStorage.getItem(LocalStorageKeys.nounRecentSearch);
            if (!existingRecentSearch) {
                return [{ label: "Recent Searches", options: [] }];
            } else {
                let recentSearches = Object.values(JSON.parse(existingRecentSearch));
                if (uom_id) {
                    return [{ label: "Recent Searches", options: recentSearches?.filter((_: any) => _.uom_id === uom_id && _.value) }];
                }
                return [{ label: "Recent Searches", options: recentSearches.filter((_: any) => _.value) }]
            }
        }
        return nounData.data?.map((_: any) => ({ value: _._id, label: _.noun_name ? `${_.noun_name} (${_?.uom_name})` : "", ..._ }))
    }

    React.useEffect(() => {
        if (uom_id) {
            searchNoun({ search: "", uom_id });
        }
        // eslint-disable-next-line
    }, [uom_id])

    React.useEffect(() => {
        const timeout = setTimeout(() => {
            if (search.length) {
                searchNoun({ search, uom_id });
            }
        }, 500)

        return () => clearTimeout(timeout)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search])

    let selectedValue = value && !Array.isArray(value) ? value?.label || "N/A" : "N/A";

    return viewOnly ? (
        <Stack sx={{ overflowWrap: "break-word" }}>
            <Typography variant="body1" sx={{ fontFamily: "htrts_semibold" }}>{label}</Typography>
            {Array.isArray(value) && value.length > 0 ? <Stack
                gap={1}
                direction={"row"}
                flexWrap={"wrap"}
            >
                {
                    value.map(v =>
                        <Tooltip title={v?.label || "N/A"}>
                            <Chip size={"small"} label={v?.label || "N/A"} />
                        </Tooltip>
                    )
                }
            </Stack>
                : <Typography variant="body2" sx={{ fontFamily: "htrts_regular" }}>{selectedValue}</Typography>}
        </Stack>
    ) : (
        <Box>
            {label.length > 0 && <Box sx={{ display: "flex" }}>
                {
                    <Typography variant="body2" color="primary" align="left" gutterBottom>
                        {label}
                    </Typography>
                }
                {isRequired && (
                    <Typography color="error" variant="caption">
                        *
                    </Typography>
                )}
            </Box>}


            <Select
                id="with-search"
                isMulti={isMulti}
                isClearable={true}
                isSearchable
                isDisabled={disabled}
                components={{
                    IndicatorSeparator: () => null,
                    Option,
                    GroupHeading
                }}
                value={value}
                placeholder={placeholder}
                options={giveMeOptions()}
                isLoading={nounData.isFetching}
                onChange={onOptionSelect}
                styles={customStyles(theme, height)}
                onInputChange={onSelectInputChange}
                noOptionsMessage={(obj: any) => search.length > 0 ? "No Nouns found" : "No Recent Searches"}
            />

            {(nounData.isError && !nounData.isFetching) && (
                <Typography variant="caption" color="error">
                    {"Opps! Unable to fetch "}
                </Typography>
            )}
        </Box>
    );
};
