import { Button, Chip, IconButton, Stack, Tooltip, Typography } from "@mui/material";
import { GridRenderCellParams, GridGroupingValueGetterParams } from "@mui/x-data-grid-premium";
import moment from "moment";
import { DownloadDoc } from "../../../components";
import { convertSize, hexToGrayscale } from "../../../utils";
import { ContentCopy, DeleteOutline } from "@mui/icons-material";
import { giveMeLocationAndDocumentTags } from "../../../components/documents/utils";
import FileSize from "../../../components/fileSize";
import { WIPIcon } from "../../../images/svg/WIPIcon";
import { PublishedIcon } from "../../../images/svg/publishedIcon";

export const DashboardTableColumn = (openAssociation: any, view: any, onDelete: any, updatePublishStatus: (row: any) => void, handleAnnotate: (id: string) => void, openReferences: any, disablePreview: boolean, hideDelete: boolean, publishable: boolean, showDuplicate: boolean, handleDuplicate?: (row: any) => void) => {
    let columns = [
        {
            field: 'sequence_id', headerName: 'Doc ID', width: 150, renderCell: (params: GridRenderCellParams) => {
                if (params.rowNode.type === 'group') {
                    return params.value;
                }
                return <Typography sx={{ color: 'blue', textDecoration: "underline", cursor: "pointer" }} variant="body2" onClick={() => view(params.row)}>{params.row?.sequence_id}</Typography >
            }
        },
        { field: 'version', headerName: 'Revision', width: 150 },
        {
            field: "duplicate",
            headerName: "Duplicate",
            resizable: true,
            width: 90,
            disableColumnMenu: true,
            sortable: false,
            renderCell: (params: GridRenderCellParams) => {
                if (params.rowNode.type === "group") {
                    return params.value;
                }
                return (
                    <Tooltip title="Make a Duplicate">
                        <IconButton
                            sx={{ margin: "auto" }}
                            onClick={() =>
                                handleDuplicate && handleDuplicate(params?.row)
                            }
                        >
                            <ContentCopy fontSize="small" />
                        </IconButton>
                    </Tooltip>
                );
            },
        },
        {
            field: 'file_name', headerName: 'Document Name', width: 200, renderCell: (params: GridRenderCellParams) => {
                if (params.rowNode.type === 'group') {
                    return params.value;
                }
                return <Tooltip title={params.row.file_name}><Typography variant="body2" sx={{ width: "100%", overflow: "hidden", textOverflow: "ellipsis" }}>{params.row.file_name}</Typography></Tooltip>
            }
        },
        {
            field: 'document_type_tags', headerName: 'Document Type', width: 250,
            valueGetter: (params: GridRenderCellParams) => giveMeLocationAndDocumentTags(params.row.tags_info).document_tags?.label,
            renderCell: (params: GridRenderCellParams) => {
                if (params.rowNode.type === 'group') {
                    if (params.rowNode.groupingField === 'document_type_tags') {
                        return params.rowNode.groupingKey;
                    }
                    return '';
                }
    
                const document = giveMeLocationAndDocumentTags(params.row.tags_info).document_tags;
    
                return <Stack spacing={1} direction={"row"} width="100%" overflow={"scroll"} id={"tagsChip"}>
                    <Chip sx={{ borderColor: document?.tag_group_color || '#3b82f6', backgroundColor: document?.tag_group_color || '#e5f6fd', color: `${hexToGrayscale(document?.tag_group_color || '#e5f6fd') > 150 ? "rgba(0, 0, 0, 0.87)" : "#FFFFFF"}` }} variant="outlined" size={"small"} label={`${document?.label}`} />
                </Stack>
            },
            groupingValueGetter: (params: GridGroupingValueGetterParams) => {
                return giveMeLocationAndDocumentTags(params.row.tags_info).document_tags?.label ?? "Grouping";
            },
            disableColumnMenu: true,
            sortable: false,
        },
        {
            field: 'released',
            headerName: 'Status',
            width: 150,
            renderCell: (params: GridRenderCellParams) => {
                return (
                    params?.row?.released
                        ? <Button disableRipple disableFocusRipple sx={{ cursor: "default", '&:hover': { backgroundColor: 'transparent' } }}>
                            <Tooltip
                                title="Published"
                                children={<div><PublishedIcon /></div>}
                            />
                        </Button>
                        : <Button disableRipple disableFocusRipple>
                            <Tooltip title={publishable ? "Work in progress, click to publish the item" : "Not Published"} children={
                                <div><WIPIcon onClick={() => publishable ? updatePublishStatus(params.row) : true} /></div>
                            } />
                        </Button>
                )
            }
        },
        {
            field: 'serial_num',
            headerName: 'Sequence Number',
            width: 160,
            renderCell: (params: GridRenderCellParams) => {
                return <Typography variant="body2">{params?.row?.serial_num ?? ""}</Typography>
            }
        },
        {
            field: 'download', headerName: 'Download', width: 100, renderCell: (params: GridRenderCellParams) => {
                return <DownloadDoc id={params.row._id} url={params.row.url} status={params.row.status} />
            },
            disableColumnMenu: true,
            sortable: false,
        },
        {
            field: 'size', headerName: 'Size', width: 100, renderCell: (params: GridRenderCellParams) => {
                if (params.rowNode.type === 'group') {
                    if (params.rowNode.groupingField === 'size') {
                        return params.rowNode.groupingKey;
                    }
                    return '';
                }
                return <FileSize id={params.row._id} status={params.row.status} size={params.row?.size ?? 0} />
            },
            groupingValueGetter: (params: GridGroupingValueGetterParams) => {
                return convertSize(params.row?.size ?? 0)
            },
        },
        {
            field: 'preview', headerName: 'Preview', width: 100, renderCell: (params: GridRenderCellParams) => {
                return <DownloadDoc preview={true} id={params.row._id} url={params.row.url} status={params.row.status} fileType={disablePreview ? "" : params.row?.file_name?.split('.')?.pop() ?? "Unknown"} />
            },
            disableColumnMenu: true,
            sortable: false,
        },
        // {
        //     field: 'annotate', headerName: 'Annotate', width: 100, renderCell: (params: GridRenderCellParams) => {
        //         if (params.row?.file_name?.includes("pdf")) {
        //             return <Button color="primary" onClick={() => handleAnnotate(params?.row?._id)}>Annotate</Button>
        //         }
        //         return null;
        //     }
        // },
        // {
        //     field: 'link', headerName: 'Associations', width: 100, renderCell: (params: GridRenderCellParams) => {
        //         return <>
        //             <IconButton disabled={params.row.associations?.length === 0} onClick={() => openAssociation(params.row)}><Link color={params.row.associations?.length === 0 ? "disabled" : "info"} /></IconButton>
        //         </>
        //     }
        // },
        // {
        //     field: 'references', headerName: 'References', width: 100, renderCell: (params: GridRenderCellParams) => {
        //         return <>
        //             <IconButton disabled={!params.row.references?.length} onClick={() => openReferences(params.row)}><Link color={params.row.references?.length ? "info" : "disabled"} /></IconButton>
        //         </>
        //     }
        // },
        { field: 'created_user', headerName: 'Uploaded By', width: 150 },
        {
            field: 'created_time', headerName: 'Uploaded Date', width: 250, renderCell: (params: GridRenderCellParams) => {
                return <Typography variant="body2">{moment(params.row.created_time).format('lll')}</Typography>
            }
        },
        { field: 'modified_user', headerName: 'Last modified by', width: 150 },
        {
            field: 'modified_time', headerName: 'Last modified date', width: 250, renderCell: (params: GridRenderCellParams) => {
                if (params.rowNode.type === 'group') {
                    return params.value;
                }
                return <Typography variant="body2">{params.row.modified_time ? moment(params.row.modified_time).format('lll') : ""}</Typography>
            }
        },
        {
            field: 'delete', headerName: 'Delete', width: 100, renderCell: (params: GridRenderCellParams) => {
                return <Tooltip title={params?.row?.released ? `${params?.row?.sequence_id} Revision is published` : `Delete the Document ${params?.row?.sequence_id}`}>
                    <IconButton disableRipple={params?.row?.released} onClick={() => params?.row?.released ? () => { } : onDelete(params.row)}>
                        <DeleteOutline color={params?.row?.released ? "disabled" : "error"} />
                    </IconButton>
                </Tooltip>
            },
            disableColumnMenu: true,
            sortable: false,
        },
    ]

    if (!handleDuplicate) {
        columns.splice(2, 1);
    }

    if (!showDuplicate) {
        columns.splice(2, 1);
    }

    if (hideDelete) {
        columns.pop();
    }

    return columns;
};