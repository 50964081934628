import { LoadingButton } from "@mui/lab";
import { Alert, Box, Button, Grid, Stack, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import React from "react";
import { useNavigate } from "react-router-dom";
import { HighlightSelectBox, MyPaper, TextField } from "../../../components";
import { SelectBoxOption } from "../../../interfaces";
import { useAppDispatch, useAppSelector } from "../../../redux";
import { useCreateVendorMutation, useUpdateVendorMutation } from "../../../redux/services";
import { VendorSliceInterface, resetVendorSlice, updateErrors, updateVendorSliceByKeyValue } from "../../../redux/slices/vendor";
import { HasAccess } from "../../../router/authorization";
import { PERMISSIONS } from "../../../router/permission";
import { AppRoutes } from "../../../router/routes";
import { ApprovedOptions, StatusOptions, mandateKeys } from "../utils";
import { MaterialMapping } from "./materialMapping";
import { PersonDetails } from "./personalDetail";

export const BasicDetails: React.FC<{ children?: JSX.Element, vendorId: string, isEdit: boolean, readOnly?: boolean }> = (props) => {

    const navigate = useNavigate();
    const state = useAppSelector(store => store.vendor);
    const { selections } = useAppSelector((state) => state.supplierMap);
    const { perm } = useAppSelector(store => store.auth.userDetails);

    const creatable = React.useMemo(() => HasAccess(perm, PERMISSIONS.SUPPLIER_CREATE), [perm]);
    const editable = React.useMemo(() => HasAccess(perm, PERMISSIONS.SUPPLIER_UPDATE), [perm]);

    const isReadOnly = props.isEdit ? !editable : !creatable;

    const dispatch = useAppDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const [createVendor, createVendorData] = useCreateVendorMutation();
    const [updateVendor, updateVendorData] = useUpdateVendorMutation();

    const isEdit = props.isEdit;

    const updateBasicDetails = (childKey: string, value: any) => {
        dispatch(updateVendorSliceByKeyValue({ parentKey: "basic_details", childKey, value }))
    };

    const updatePrimaryContactPersonDetails = (childKey: string, value: any) => {
        dispatch(updateVendorSliceByKeyValue({ parentKey: "primary_contact", childKey, value }))
    };

    const updateSecondaryContactPersonDetails = (childKey: string, value: any) => {
        dispatch(updateVendorSliceByKeyValue({ parentKey: "secondary_contact", childKey, value }))
    };

    const isValid = () => {
        return new Promise((resolve, reject) => {

            const isSecondaryContact = giveMeDefaultCheckBoxValue();
            if (isSecondaryContact) {
                mandateKeys.push({ parentKey: "secondary_contact", childKey: "name" });
                mandateKeys.push({ parentKey: "secondary_contact", childKey: "email" });
                mandateKeys.push({ parentKey: "secondary_contact", childKey: "mobile" });
                mandateKeys.push({ parentKey: "secondary_contact", childKey: "address" });
                mandateKeys.push({ parentKey: "secondary_contact", childKey: "country" });
            }
            dispatch(updateErrors({
                mandateKeys, callback: (updatedVendor: VendorSliceInterface) => {
                    let isValid = true;
                    for (let index = 0; index < mandateKeys.length; index++) {
                        const key: any = mandateKeys[index];
                        const error: any = updatedVendor.error;
                        if (error[key.parentKey][key.childKey]) {
                            isValid = false;
                            enqueueSnackbar('Please fill mandatory fields (*) correctly.', { variant: "warning" });
                            break;
                        }
                    }

                    // checking valid email
                    if (state.error.primary_contact.email) {
                        enqueueSnackbar('Please enter a valid primary contact email.', { variant: "warning" });
                    }

                    resolve(isValid)
                }

            }));

        })
    };

    const createUpdateVendorDetails = async () => {
        const isValidData = await isValid();
        if (!isValidData) {
            return false;
        }
        let materialMappingData = selections?.map((val: any) => {
            let variantIds = val?.noun_variant_ids?.map((_: any) => { return _?._id })
            return {
                group_id: val?.group_id?._id,
                noun_id: val?.noun_id?._id,
                noun_variant_ids: variantIds,
                uom_id: val?.uom_id?._id
            }
        })
        let payload = {
            approved: state.basic_details.approved_vendor?.value === "Yes",
            primary_contact: { address: state.primary_contact.address, country: state.primary_contact.country?.label, email: state.primary_contact.email, person_name: state.primary_contact?.name, phone: state.primary_contact.mobile },
            secondary_contact: { address: state.secondary_contact.address, country: state.secondary_contact.country?.label, email: state.secondary_contact.email, person_name: state.secondary_contact.name, phone: state.secondary_contact.mobile },
            status: state.basic_details.status?.value,
            vendor_name: state.basic_details.vendor_name,
            website: state.basic_details.vendor_website,
            applicable_materials: materialMappingData

        }

        if (state.basic_details.vendor_name && state.basic_details.vendor_website && state.basic_details.approved_vendor?.value &&
            state.basic_details.status?.value && state.primary_contact.address && state.primary_contact.country?.label && state.primary_contact.email && state.primary_contact?.name && state.primary_contact.mobile) {
            if (props.isEdit) {
                updateVendor({ id: props.vendorId, payload });
            } else {
                createVendor(payload);
            }
        }
    };

    const giveMeDefaultCheckBoxValue = () => {
        if (state.secondary_contact?.address?.trim()?.length > 0
            || (state.secondary_contact?.country && state.secondary_contact?.country.value)
            || state.secondary_contact?.email?.trim()?.length > 0
            || state.secondary_contact?.mobile?.trim()?.length > 0
            || state.secondary_contact?.name?.trim()?.length > 0
        ) {
            return true;
        }

        return false;
    };

    const giveMeErrorMessage = () => {
        let message = `Oops! Something went wrong, Unable to ${isEdit ? "Update" : "Create"} Supplier. Try Again Later!`;
        let errorData: any = isEdit ? updateVendorData.error : createVendorData.error;
        message = errorData?.data?.title ?? message;
        return message;
    };

    React.useEffect(() => {
        if (!createVendorData.isLoading && createVendorData.isSuccess) {
            enqueueSnackbar('Supplier Created Successfully!', { variant: "success" });
            let newVendorId = createVendorData.data?._id ?? "New";
            navigate(AppRoutes.viewVendorBasicDetails(newVendorId));
        }
        // eslint-disable-next-line
    }, [createVendorData.isSuccess]);

    React.useEffect(() => {
        if (!updateVendorData.isLoading && updateVendorData.isSuccess) {
            enqueueSnackbar('Supplier Updated Successfully!', { variant: "success" });
            navigate(AppRoutes.viewVendorBasicDetails(props.vendorId));
        }
        // eslint-disable-next-line
    }, [updateVendorData.isSuccess]);

    React.useEffect(() => {
        if (!createVendorData.isLoading && createVendorData.isError) {
            enqueueSnackbar(giveMeErrorMessage(), { variant: "error" })
        }
        // eslint-disable-next-line
    }, [createVendorData.isError]);

    React.useEffect(() => {
        if (!updateVendorData.isLoading && updateVendorData.isError) {
            enqueueSnackbar(giveMeErrorMessage(), { variant: "error" })
        }
        // eslint-disable-next-line
    }, [updateVendorData.isError]);

    const isLoading = createVendorData.isLoading || updateVendorData.isLoading;

    return <Stack spacing={1} mb={2}>
        {/* Basic Data Section */}
        <MyPaper padding={0}>
            <Box>
                {/* Heading */}
                <Typography sx={{ px: 2, py: 1, borderBottom: (theme) => `1px solid ${theme.palette.divider}` }}
                    variant="body1"
                    fontFamily={"htrts_medium"}>
                    Basic Details
                </Typography>

                <Grid container p={2} spacing={2}>
                    {/* Supplier Name */}
                    <Grid item xs={12} sm={6}>
                        <TextField
                            id="vendor_name"
                            variant="outlined"
                            size="small"
                            fullWidth
                            value={state.basic_details.vendor_name}
                            label={"Supplier Name"}
                            onChange={(e) => updateBasicDetails("vendor_name", e.target.value)}
                            required={true}
                            error={state.error.basic_details.vendor_name}
                            helperText={state.error.basic_details.vendor_name ? 'Please enter Supplier name' : ""}
                            viewOnly={isReadOnly}
                        />
                    </Grid>

                    {/* Supplier Website */}
                    <Grid item xs={12} sm={6}>
                        <TextField
                            id="vendor_website"
                            variant="outlined"
                            size="small"
                            fullWidth
                            value={state.basic_details.vendor_website}
                            label={"Supplier Website"}
                            onChange={(e) => updateBasicDetails("vendor_website", e.target.value)}
                            required={true}
                            error={state.error.basic_details.vendor_website}
                            helperText={state.error.basic_details.vendor_website ? 'Please enter Supplier website' : ""}
                            viewOnly={isReadOnly}
                        />
                    </Grid>

                    {/* Approved Supplier */}
                    <Grid item xs={12} sm={6}>
                        <HighlightSelectBox
                            id="approved_vendor"
                            label={"Approved Supplier"}
                            margin={"none"}
                            value={state.basic_details.approved_vendor}
                            options={ApprovedOptions}
                            onChange={(data: SelectBoxOption) => updateBasicDetails("approved_vendor", data)}
                            required={true}
                            error={state.error.basic_details.approved_vendor}
                            helperText={state.error.basic_details.approved_vendor ? 'Please select Yes or No' : ""}
                            viewOnly={isReadOnly}
                        />
                    </Grid>

                    {/* Status */}
                    <Grid item xs={12} sm={6}>
                        <HighlightSelectBox
                            id="vendor_status"
                            label={"Status"}
                            margin={"none"}
                            value={state.basic_details.status}
                            options={StatusOptions}
                            onChange={(data: SelectBoxOption) => updateBasicDetails("status", data)}
                            required={true}
                            error={state.error.basic_details.status}
                            helperText={state.error.basic_details.status ? 'Please select Supplier status' : ""}
                            viewOnly={isReadOnly}
                        />
                    </Grid>
                </Grid>
            </Box>
        </MyPaper>

        {/* Primary Contact Person Details */}
        <PersonDetails title="Primary Contact Details" required={Object.keys(state.primary_contact)} person={state.primary_contact} error={state.error.primary_contact} updatePerson={updatePrimaryContactPersonDetails} readOnly={isReadOnly} />

        {/* Secondary Contact Person Details */}
        <PersonDetails
            title="Secondary Contact Details"
            required={[]}
            person={state.secondary_contact}
            error={state.error.secondary_contact}
            updatePerson={updateSecondaryContactPersonDetails}
            readOnly={isReadOnly}
            showCheckBox={true}
            defaultCheckBoxValue={giveMeDefaultCheckBoxValue()}
            id="secondary"
        />

        {/* Material mapping details */}
        <MyPaper padding={0}>
            <MaterialMapping initialSelections={state.applicable_materials} isEdit={props.isEdit} readOnly={isReadOnly} />
        </MyPaper>

        {/* Footer Button */}
        {!isReadOnly && <MyPaper padding={0}>
            <Box sx={{ paddingX: 2 }}>
                {/* Alert Box */}
                {(createVendorData.isError || updateVendorData.isError) && <Alert sx={{ mt: 2 }} severity="error">{giveMeErrorMessage()}</Alert>}

                <Stack direction={"row"} alignItems={"center"} justifyContent={"center"} spacing={1} p={2}>
                    {/* Clear All Button */}
                    <Button disabled={isLoading} variant="outlined" sx={{ width: 200 }} color="primary" onClick={() => dispatch(resetVendorSlice())} >
                        Clear All
                    </Button>

                    {/* Create Button */}
                    <LoadingButton id="u-c-btn" loading={isLoading} variant="contained" sx={{ width: 200 }} color="primary" onClick={createUpdateVendorDetails}>
                        {props.isEdit ? "Update" : "Create"}
                    </LoadingButton>

                    {/* Cancel Button */}
                    <Button disabled={isLoading} variant="outlined" sx={{ width: 200 }} color="primary" onClick={() => navigate(AppRoutes.vendors)} >
                        Cancel
                    </Button>
                </Stack>
            </Box>
        </MyPaper>}
    </Stack>
}
