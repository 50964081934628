// Global Context for notistack package
import copy from "fast-copy";
import { useSnackbar } from "notistack";
import React from "react";
import { config } from "./config";
import { SocketContext } from "./contexts";
import { useAppDispatch } from "./redux";
import { notify, toggleBadge } from "./redux/slices/notifications";

interface SocketState {
    socketObj: WebSocket | null,
    isConnected: boolean,
    lastUsed: Date | null,
    receivedEvents: { [key: string]: { [key: string]: { alert: string, status: 'saved', document_id: string, size: number } } },
}

export const AppSocket: React.FC<{ children: JSX.Element }> = ({ children }) => {

    const [state, setState] = React.useState<SocketState>({
        socketObj: null,
        isConnected: false,
        lastUsed: null,
        receivedEvents: {
            "document-status": {}
        }
    })

    const socketRef = React.useRef<WebSocket | null>(null);

    const lastUsed = React.useRef<Date | null>(null);

    const dispatch = useAppDispatch();

    const { enqueueSnackbar } = useSnackbar();

    const open = (payload: object, messages?: object[]) => {
        return new Promise<boolean>((resolve, reject) => {
            if (!socketRef.current) {

                socketRef.current = new WebSocket(config.web_socket_url);
                // let newState: SocketState = state;

                // Event listener for when the connection is opened
                socketRef.current.onopen = () => {
                    console.log('WebSocket connection opened', new Date());

                    if (payload) {
                        socketRef?.current?.send(JSON.stringify(payload));
                    }

                    if (messages?.length) {
                        messages.forEach((message: any) => {
                            socketRef?.current?.send(JSON.stringify(message));
                        })
                    }

                    if ((payload as any)?.name !== "ping") {
                        lastUsed.current = new Date();
                    }
                    resolve(true);
                };

                // Event listener when closing
                socketRef.current.onclose = () => {
                    console.log('WebSocket connection closed!', new Date());
                    setState({
                        ...state, lastUsed: null, receivedEvents: {
                            "document-status": {}
                        }
                    })
                    lastUsed.current = null
                    socketRef?.current?.close();
                    socketRef.current = null
                }

                // Event listener for errors
                socketRef.current.onerror = (error) => {
                    console.error('WebSocket error:', error);
                    lastUsed.current = null;
                    socketRef?.current?.close();
                    resolve(true);
                };

                socketRef.current.addEventListener("message", (event: any) => {
                    let receivedEvents = copy(state.receivedEvents);
                    let data = JSON.parse(event?.data ?? "{}");
                    let ID = data.payload?.document_id || data?.payload?.attachment?._id || data.payload?.bom_id || data.payload?._id || data.payload?.workbook_id;
                    receivedEvents[data.name] = {
                        [ID]: data.payload
                    };
                    if (data?.name !== "pong" && data?.name) {
                        dispatch(notify({
                            name: data.name,
                            id: ID,
                            status: data?.payload?.attachment?.status ?? data.payload?.status ?? data?.payload?.workbook?.status ?? "",
                            subs: [
                                data?.payload?.attachment?.status ?? data.payload?.status ?? data?.payload?.workbook?.status ?? ""
                            ],
                            message: data?.payload?.attachment?.failure_reason ?? data.payload?.failure_reason ?? ""
                        }))
                        dispatch(toggleBadge(true));
                        if ((data?.payload?.attachment?.status ?? data.payload?.status) === "failed") {
                            enqueueSnackbar(data?.payload?.alert, { variant: "error" });
                        } else if ((data?.payload?.attachment?.status ?? data.payload?.status) === "saved") {
                            enqueueSnackbar(data?.payload?.alert, { variant: "success" });
                        } else if ((data?.payload?.workbook?.status ?? data.payload?.status) === "completed") {
                            enqueueSnackbar(data?.payload?.alert, { variant: "success" });
                        }
                        lastUsed.current = new Date();
                        setState({ ...state, receivedEvents });
                    }
                    console.log("Websocket connection event", event)
                });
            } else {
                send(payload);
                resolve(true);
            }
        })
    };

    const close = () => {
        if (socketRef?.current) {
            console.log("Closing websocket connection")
            socketRef?.current?.close();
        }
    }

    const send = (payload: object) => {
        if (socketRef?.current && (payload as any)?.name !== "ping") {
            lastUsed.current = new Date();
            socketRef?.current?.send(JSON.stringify(payload));
        } else {
            socketRef?.current?.send(JSON.stringify(payload));
        }
    }

    const clearReceivedEvent = (parent: string, child: string) => {
        if (state.receivedEvents[parent] && state.receivedEvents[parent][child]) {
            delete state.receivedEvents[parent][child]
        }
        setState({ ...state });
    }

    setInterval(() => {
        if (socketRef?.current && lastUsed?.current) {
            const diff = Math.floor((new Date().getTime() - lastUsed.current?.getTime()) / (1000 * 60));
            if (diff < 5) {
                send({ name: "ping" });
            }
        }
    }, 1000 * 60)

    return <SocketContext.Provider value={{ ...state, open, close, send, clearReceivedEvent }}>
        {children}
    </SocketContext.Provider>
}