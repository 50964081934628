import { Box, LinearProgress } from "@mui/material";
import { Attachment, PageSize, drawTemplateOnPage } from "pdf-generator";
import { PDFDocument, PageSizes, StandardFonts } from "pdf-lib";
import React from "react";
import { useAppSelector } from "../../../redux";
import { prepareDocumentTemplateBody } from "../../../redux/services";
import { TemplateResponse } from "../../../redux/slices/documentTemplate";
import { fileToBase64 } from "../../../utils";

export const HeaderFooterTemplatePreview: React.FC<{ children?: JSX.Element }> = (props) => {

  const { documentTemplate } = useAppSelector((store) => store);
  const template = documentTemplate.headerFooterTemplate;

  const [pdfInfo, setPdfInfo] = React.useState<any>('');

  const generatePreview = async (templateData: TemplateResponse) => {

    setPdfInfo('');

    const pdfDoc = await PDFDocument.create();
    const helveticaFont = await pdfDoc.embedFont(StandardFonts.Helvetica);

    const size = (templateData?.paper_size || "A4") as PageSize;
    const pageDimensions: [number, number] = templateData?.orientation === "landscape" ? [PageSizes[size as PageSize][1], PageSizes[size as PageSize][0]] : PageSizes[size];
    const page = pdfDoc.addPage(pageDimensions);

    let url = '';

    if ((template?.logo?.src as Attachment)?.url) {
      url = (template?.logo?.src as Attachment)?.url as string
    } else if (template?.logo?.src as File) {
      try {
        const base64String = await fileToBase64(template.logo.src as File);
        url = base64String as string;
      } catch (error) {
        console.error('Error converting file to base64:', error);
      }
    }

    await drawTemplateOnPage(pdfDoc, page, templateData, helveticaFont, false, undefined, {
      url: url,
      dimension: {
        width: template?.logo?.width,
        height: template?.logo?.height,
      },
      alignment: {
        horizontal: template?.logo?.horizontal?.value as string || "",
        vertical: template?.logo?.vertical?.value as string || "",
      },
      isActive: template?.logo?.isActive
    })

    // Add title
    pdfDoc.setTitle(`Template Preview ${template?.sequence_id ? template?.sequence_id : ''}`);

    const pdfBytes = await pdfDoc.save();
    const bytes = new Uint8Array(pdfBytes);
    const blob = new Blob([bytes], { type: "application/pdf" });
    const docUrl = URL.createObjectURL(blob);
    await fetch(docUrl).then(() => {
      setPdfInfo(docUrl);
    }).catch((error: any) => {
      console.error(`Error loading document on preview: ${error}`)
    })
  };

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      if (template) {
        const templateData = prepareDocumentTemplateBody(template);
        generatePreview(templateData);
      }
    }, 2000);

    return () => clearTimeout(timeout);
    // eslint-disable-next-line
  }, [template]);

  return (
    <Box height={"100%"}>
      {pdfInfo
        ?
        <iframe src={`${pdfInfo}#toolbar=1`} width={"100%"} height={"100%"} title='Template Preview' />
        :
        <LinearProgress />
      }
    </Box>
  );
};
