import { AlignHorizontalLeft, Biotech, DataArray, DocumentScanner, Folder, Handshake } from "@mui/icons-material";
import { SelectBoxOption } from "../../interfaces";

export const BOMTypeOptions: SelectBoxOption[] = [{ value: "individual", label: "Individual Part ID" }, { value: "assembled", label: "Assembly ID" }, { value: "block", label: "Area ID" }];

export const NavBars = (
    id: string,
    isEdit: boolean = false,
    loading: boolean = false,
    tags: { tag_id: string, tag_name: string }[] = [],
    tag_group_id: string,
    paths: Array<string>
) => ([
    {
        id: "1",
        title: "Basic Details",
        icon: <AlignHorizontalLeft fontSize="small" />,
        path: paths[0]
    },
    {
        id: "2",
        title: "Documents",
        disabled: !isEdit,
        icon: <DocumentScanner fontSize="small" />,
        path: paths[1],
        loading,
        subNav: [
            {
                id: "2.1",
                title: "All",
                icon: <Folder htmlColor="#fde100" fontSize="small" />,
                path: `${paths[1]}?tags=all`
            },
            ...tags.map((_, index: number) => ({
                ...tags[index],
                id: `2.${index + 2}`,
                title: _.tag_name,
                icon: <Folder htmlColor="#fde100" fontSize="small" />,
                path: `${paths[1]}?tag_id=${_.tag_id}&group_id=${tag_group_id}`,
            }))
        ]
    },
    {
        id: "3",
        title: "Datasheet",
        disabled: !isEdit,
        icon: <DataArray fontSize="small" />,
        path: paths[2]
    },
    {
        id: "4",
        title: "Material Details Preview",
        disabled: !isEdit,
        icon: <Biotech fontSize="small" />,
        path: paths[3]
    },
    {
        id: "5",
        title: "Procure Material",
        disabled: !isEdit,
        icon: <Handshake fontSize="small" />,
        path: paths[4]
    },
])

export const MDGTypeOptions = [{ value: "Generate using MDG", label: "Generate using MDG" }, { value: "Manually Enter the Description", label: "Manually Enter the Description" }];

export const UOMOptions = [{ label: "Piece", value: "piece" }, { label: "Length", value: "length" }, { label: "Weight", value: 'weight' }];

export const LengthUnits = [{ value: "In", label: "In" }, { value: "Ft", label: "Ft" }, { value: "mm", label: "mm" }, { value: "cm", label: "cm" }, { value: "m", label: "m" }]

export const WeightUnits = [{ value: "gram", label: "gram" }, { value: "kg", label: "kg" }, { value: "mt", label: "mt" }, { value: "lb", label: "lb" }, { value: "m", label: "m" }]

export const StatusOptions = [{ value: "Active", label: "Active" }, { value: "Inactive", label: "Inactive" }, { value: "Obsolete", label: "Obsolete" }]

export const CostingTypes = [{ value: "Firm", label: "Firm" }, { value: "Budgetary", label: "Budgetary" }];