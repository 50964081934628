import { InputBase } from "@mui/material";
import { GridActionsCellItem, GridColDef, GridRenderCellParams } from "@mui/x-data-grid-premium";
import { HighlightSelectBox } from "../../../components";
import { SelectBoxOption } from "../../../interfaces";
import { DeleteOutline } from "@mui/icons-material";

export const AssemblyProcessColumn = (onChange: any, currencyData: any, handleDeleteClick: any, isViewOnly:boolean = true): GridColDef[] => ([
    { field: 'sl_no', headerName: 'Sl No', disableColumnMenu: true, sortable: false },
    {
        field: 'process_name', width: 250, disableColumnMenu: true, sortable: false, headerName: 'Assembly Process Name', renderCell: (params: GridRenderCellParams) => {
            return <InputBase
                id="assembly-name"
                placeholder="Enter Assembly Process Name"
                type="text"
                readOnly={isViewOnly}
                value={params.row.process_name}
                onChange={(e) => onChange(params.row.sl_no, 'process_name', e.target.value)}
                onKeyDown={(event) => {
                    event.stopPropagation();
                }}
            />
        }
    },
    {
        field: 'cost', headerName: 'Cost', disableColumnMenu: true, sortable: false, renderCell: (params: GridRenderCellParams) => {
            return <InputBase readOnly={isViewOnly} id="assembly-cost" placeholder="Enter Cost" type="number" value={params.row.cost} onChange={(e) => onChange(params.row.sl_no, 'cost', e.target.value)} />
        }
    },
    {
        field: 'currency', headerName: 'Currency', width: 200, disableColumnMenu: true, sortable: false, renderCell: (params: GridRenderCellParams) => {
            return <HighlightSelectBox
                id="assembly-currency"
                label={"Currency"}
                margin={"none"}
                fullWdith
                loading={currencyData.isLoading}
                value={params.row.currency}
                options={currencyData?.data ?? []}
                onChange={(data: SelectBoxOption) => onChange(params.row.sl_no, 'currency', data)}
                viewOnly={isViewOnly}
            />
        }
    },
    {
        field: 'qty', headerName: 'Qty', disableColumnMenu: true, sortable: false, renderCell: (params: GridRenderCellParams) => {
            return <InputBase readOnly={isViewOnly} id="assembly-qty" placeholder="Enter Qty" type="number" value={params.row.qty} onChange={(e) => onChange(params.row.sl_no, 'qty', e.target.value)} />
        }
    },
    {
        field: 'total_cost', headerName: 'Total Cost (Currency)', width: 200, disableColumnMenu: true, sortable: false, valueGetter: (params: GridRenderCellParams) => {
            return `${params.row?.total_cost ?? ""} ${params.row?.currency?.label ?? ""}`
        }
    },
    {
        field: 'actions',
        type: 'actions',
        headerName: 'Delete',
        width: 100,
        cellClassName: 'actions',
        getActions: ({ id, row }) => {
            return [
                <GridActionsCellItem
                    disabled={isViewOnly}
                    icon={<DeleteOutline />}
                    label="Delete"
                    onClick={() => handleDeleteClick(row.sl_no, row._id, row.process_name)}
                    color="inherit"
                />,
            ];
        },
    },
]);