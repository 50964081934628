import { configureStore } from "@reduxjs/toolkit";
import {
  Provider,
  useDispatch,
  useSelector,
  TypedUseSelectorHook,
} from "react-redux";
import {
  authAPI,
  uomAPI,
  nounAPI,
  groupAPI,
  attachementAPI,
  attachementProdAPI,
  attributeAPI,
  nounVariantAPI,
  tagGroupsAPI,
  tagsAPI,
  materialAPI,
  vendorsAPI,
  materialDocumentAPI,
  aiAPI,
  countryAPI,
  projectAPI,
  proposalAPI,
  productFamilyAPI,
  productGroupAPI,
  productNounAPI,
  productAttributeAPI,
  productAPI,
  bomAPI,
  issueTrackerAPI,
  categoryAPI,
  snGroupAPI,
  snNounAPI,
  snAttributeAPI,
  snTemplateAPI,
  documentTemplateAPI,
  assignmentAPI,
  exchangeRatesAPI,
  commonAttachmentAPI,
  dataTableAPI,
  chatBotAPI,
  bomReportAPI,
  listViewAPI,
  commonAPIs,
  stateManagementAPI,
  usersAPI
} from "./services";
import {
  authReducer,
  backdropReducer,
  confirmationReducer,
  dialogReducer,
  drawerReducer,
  PMPReducer,
  MaterialReducer,
  VendorReducer,
  ProjectReducer,
  DocumentTemplateReducer,
  UtilsReducer,
  AISearchReducer,
  SupplierMappingReducer,
  ProposalReducer,
  ProductReducer,
  BOMReducer,
  IssueTrackerReducer,
  CheckListReducer,
  NotificationsReducer
} from "./slices";

const ReduxStore = configureStore({
  reducer: {
    [authAPI.reducerPath]: authAPI.reducer,
    [uomAPI.reducerPath]: uomAPI.reducer,
    [nounAPI.reducerPath]: nounAPI.reducer,
    [groupAPI.reducerPath]: groupAPI.reducer,
    [attributeAPI.reducerPath]: attributeAPI.reducer,
    [nounVariantAPI.reducerPath]: nounVariantAPI.reducer,
    [attachementAPI.reducerPath]: attachementAPI.reducer,
    [attachementProdAPI.reducerPath]: attachementProdAPI.reducer,
    [tagGroupsAPI.reducerPath]: tagGroupsAPI.reducer,
    [tagsAPI.reducerPath]: tagsAPI.reducer,
    [materialAPI.reducerPath]: materialAPI.reducer,
    [vendorsAPI.reducerPath]: vendorsAPI.reducer,
    [materialDocumentAPI.reducerPath]: materialDocumentAPI.reducer,
    [aiAPI.reducerPath]: aiAPI.reducer,
    [countryAPI.reducerPath]: countryAPI.reducer,
    [projectAPI.reducerPath]: projectAPI.reducer,
    [proposalAPI.reducerPath]: proposalAPI.reducer,
    [productFamilyAPI.reducerPath]: productFamilyAPI.reducer,
    [productGroupAPI.reducerPath]: productGroupAPI.reducer,
    [productNounAPI.reducerPath]: productNounAPI.reducer,
    [productAttributeAPI.reducerPath]: productAttributeAPI.reducer,
    [productAPI.reducerPath]: productAPI.reducer,
    [bomAPI.reducerPath]: bomAPI.reducer,
    [issueTrackerAPI.reducerPath]: issueTrackerAPI.reducer,
    [categoryAPI.reducerPath]: categoryAPI.reducer,
    [snGroupAPI.reducerPath]: snGroupAPI.reducer,
    [snNounAPI.reducerPath]: snNounAPI.reducer,
    [snAttributeAPI.reducerPath]: snAttributeAPI.reducer,
    [snTemplateAPI.reducerPath]: snTemplateAPI.reducer,
    [documentTemplateAPI.reducerPath]: documentTemplateAPI.reducer,
    [assignmentAPI.reducerPath]: assignmentAPI.reducer,
    [exchangeRatesAPI.reducerPath]: exchangeRatesAPI.reducer,
    [commonAttachmentAPI.reducerPath]: commonAttachmentAPI.reducer,
    [bomReportAPI.reducerPath]: bomReportAPI.reducer,
    [dataTableAPI.reducerPath]: dataTableAPI.reducer,
    [chatBotAPI.reducerPath]: chatBotAPI.reducer,
    [listViewAPI.reducerPath]: listViewAPI.reducer,
    [commonAPIs.reducerPath]: commonAPIs.reducer,
    [stateManagementAPI.reducerPath]: stateManagementAPI.reducer,
    [usersAPI.reducerPath]: usersAPI.reducer,
    issueTracker: IssueTrackerReducer,
    auth: authReducer,
    backdrop: backdropReducer,
    dialog: dialogReducer,
    drawer: drawerReducer,
    confirmation: confirmationReducer,
    pmp: PMPReducer,
    supplierMap: SupplierMappingReducer,
    materials: MaterialReducer,
    vendor: VendorReducer,
    project: ProjectReducer,
    proposal: ProposalReducer,
    documentTemplate: DocumentTemplateReducer,
    utils: UtilsReducer,
    aiSearch: AISearchReducer,
    product: ProductReducer,
    bom: BOMReducer,
    checkList: CheckListReducer,
    notifications: NotificationsReducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false })
      .concat(authAPI.middleware)
      .concat(uomAPI.middleware)
      .concat(groupAPI.middleware)
      .concat(nounAPI.middleware)
      .concat(nounVariantAPI.middleware)
      .concat(attributeAPI.middleware)
      .concat(attachementAPI.middleware)
      .concat(attachementProdAPI.middleware)
      .concat(tagGroupsAPI.middleware)
      .concat(tagsAPI.middleware)
      .concat(materialAPI.middleware)
      .concat(vendorsAPI.middleware)
      .concat(materialDocumentAPI.middleware)
      .concat(aiAPI.middleware)
      .concat(countryAPI.middleware)
      .concat(projectAPI.middleware)
      .concat(proposalAPI.middleware)
      .concat(issueTrackerAPI.middleware)
      .concat(categoryAPI.middleware)
      .concat(snGroupAPI.middleware)
      .concat(snNounAPI.middleware)
      .concat(snAttributeAPI.middleware)
      .concat(snTemplateAPI.middleware)
      .concat(documentTemplateAPI.middleware)
      .concat(assignmentAPI.middleware)
      .concat(exchangeRatesAPI.middleware)
      .concat(commonAttachmentAPI.middleware)
      .concat(bomReportAPI.middleware)
      .concat(dataTableAPI.middleware)
      .concat(chatBotAPI.middleware)
      .concat(listViewAPI.middleware)
      .concat(commonAPIs.middleware)
      .concat(stateManagementAPI.middleware)
      .concat(usersAPI.middleware)
});

export const AppRedux: React.FC<{ children: JSX.Element }> = ({ children }) => {
  return <Provider store={ReduxStore}> {children} </Provider>;
};

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof ReduxStore.getState>;

// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof ReduxStore.dispatch;

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
