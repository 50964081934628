import React from 'react';
import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';
import { SunEditorReactProps } from 'suneditor-react/dist/types/SunEditorReactProps';

// Make SunEditor as Text only editor by limiting its functionality

const TextOnlyEditor: React.FC<SunEditorReactProps> = (props) => {
  const handleImageUploadBefore = () => {
    return undefined;
  };

  const handleVideoUploadBefore = () => {
    return undefined;
  };

  const handleAudioUploadBefore = () => {
    return undefined;
  };

  const decoratedProps = {
    onImageUploadBefore: handleImageUploadBefore,
    onVideoUploadBefore: handleVideoUploadBefore,
    onAudioUploadBefore: handleAudioUploadBefore,
  };

  return <SunEditor {...props} {...decoratedProps} setOptions={{ maxCharCount: 1000, ...props.setOptions }} />;
};

export default TextOnlyEditor;
