import { Check, Clear, ContentCopy, Edit, Engineering } from '@mui/icons-material';
import { Box, Button, Chip, IconButton, Input, Stack, Theme, Tooltip, Typography, useTheme } from '@mui/material';
import { useSnackbar } from 'notistack';
import React from 'react';
import { For } from '.';
import { DescriptionInterface } from '../../interfaces';
import { useAppDispatch } from '../../redux';
import { closeConfirmationDialog, openConfirmationDialog } from '../../redux/slices/confirmationDialog';

const useSx = (theme: Theme) => ({
    paper: {
        padding: 1, boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
        background: theme.palette.background.paper, position: 'sticky', left: 0, right: 0, bottom: -10
    }
})

export const Footer: React.FC<{
    description: DescriptionInterface[],
    onGenerateBtnClicked: () => any,
    editDescription: () => any,
    resetAll: () => any,
    updateDescription: any,
    shortDescription: DescriptionInterface[],
    editShortDescription: () => any,
    updateShortDescription: any,
    onApply: () => void,
    for?: For,
    onClose?: (generated: string) => void,
    isLoading: boolean,
}> = (props) => {

    const sx = useSx(useTheme());
    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useAppDispatch();

    const [state, setState] = React.useState<{ [key: string]: string }>({});
    const [isCopied, setIsCopied] = React.useState(false);
    const [isEditOn, setIsEditOn] = React.useState(false);
    const [regenerate, setRegenerate] = React.useState(false);
    const [shortDescState, setShortDescState] = React.useState<{ [key: string]: string }>({});
    const [isShortDescCopied, setIsShortDescCopied] = React.useState(false);
    const [isShortDescEditOn, setIsShortDescEditOn] = React.useState(false);

    const copyDescription = () => {
        let newDesc: string[] = props.description.map((desc, key) => {
            if (state[key]) { return state[key]; } else {
                return desc.label;
            }
        });

        window.navigator.clipboard.writeText(newDesc.join(', '));

        setIsCopied(true);

        setInterval(() => {
            setIsCopied(false);
        }, 2000);
    };

    const copyShortDescription = () => {
        let newDesc: string[] = props.shortDescription.map((desc, key) => {
            if (shortDescState[key]) { return shortDescState[key]; } else {
                return desc.label;
            }
        });

        window.navigator.clipboard.writeText(newDesc.join(', '));

        setIsShortDescCopied(true);

        setInterval(() => {
            setIsShortDescCopied(false);
        }, 2000);
    };

    const onEditDescriptionClicked = () => {
        setIsEditOn(true);
        props.editDescription();
    };

    const onEditShortDescriptionClicked = () => {
        setIsShortDescEditOn(true);
        props.editShortDescription();
    };

    const resetState = () => {
        let newState: any = {};
        props.description.forEach((_, key: number) => {
            newState[key] = _.label;
        });
        setState({ ...state, ...newState });
    };

    const resetShortDescState = () => {
        let newState: any = {};
        props.shortDescription.forEach((_, key: number) => {
            newState[key] = _.label;
        });
        setShortDescState({ ...shortDescState, ...newState });
    };

    const clearDescription = () => {
        resetState();
        props.updateDescription(props.description.map((_, key: number) => {
            return {
                ..._,
                type: _.label === "Custom" ? "Input" : "Text",
            }
        }));
        setIsEditOn(false);
    };

    const clearShortDescription = () => {
        resetShortDescState();
        props.updateShortDescription(props.shortDescription.map((_, key: number) => {
            return {
                ..._,
                type: _.label === "Custom" ? "Input" : "Text",
            }
        }));
        setIsShortDescEditOn(false);
    };

    const saveDescription = () => {
        props.updateDescription(props.description.map((_, key: number) => {
            return {
                ..._,
                type: _.label === "Custom" ? "Input" : "Text",
                label: state[key]
            }
        }));

        let shortDescription: DescriptionInterface[] = [];

        props.description.every((_, key: number) => {
            if (shortDescription.map((_: any) => _.label).join(", ").length > 50) {
                shortDescription.pop();
                return false;
            }
            shortDescription.push({
                ..._,
                type: _.label === "Custom" ? "Input" : "Text",
                label: state[key]
            })
            return true;
        })

        props.updateShortDescription(shortDescription)
        setIsEditOn(false);
    }

    const saveShortDescription = () => {
        // Getting the length of the description to be generated
        let length: number = 0;
        Object.values(shortDescState).forEach((desc: string) => {
            length += desc.length;
        });

        if (length > 50) {
            enqueueSnackbar('Short Description length must be less than or equal to 50. Current Length - ' + length, { variant: "warning", anchorOrigin: { horizontal: "center", vertical: "bottom" } });
            return false;
        }

        props.updateShortDescription(props.shortDescription.map((_, key: number) => {
            return {
                ..._,
                type: _.label === "Custom" ? "Input" : "Text",
                label: shortDescState[key]
            }
        }));
        setIsShortDescEditOn(false);
    }

    React.useEffect(() => {
        if (props.description.length > 0) {
            resetState();
        }
        // eslint-disable-next-line
    }, [props.description]);

    React.useEffect(() => {
        if (props.shortDescription.length > 0) {
            resetShortDescState();
        }
        // eslint-disable-next-line
    }, [props.shortDescription])

    return <Box sx={sx.paper}>
        <Stack height={"100%"} display={"flex"} justifyContent={"center"} alignItems={"center"} spacing={1}>

            {props.shortDescription.length > 0 && <Stack direction={{ xs: "column", sm: "row" }} spacing={1} justifyContent={"space-evenly"} alignItems={"flex-start"}>
                <Typography variant='body2'>Short Description: </Typography>
                <Stack direction={"row"} flexWrap={"wrap"} spacing={1} width={{ xs: "100%", sm: "fit-content" }} sx={{ overflow: "scroll" }}>
                    {props.shortDescription.map((desc, key) => {
                        if (desc?.type === "Text") {
                            return <DescriptionWrapper key={key} label={desc.title}>
                                <Chip sx={{ width: "fit-content" }} key={key} label={desc?.label} />
                            </DescriptionWrapper>
                        } else if (desc?.type === "Input") {
                            return <DescriptionWrapper key={key} label={desc.title}>
                                <Input type='text' value={shortDescState[key]} onChange={(e: any) => setShortDescState({ ...shortDescState, [key]: e.target.value.toUpperCase() })} />
                            </DescriptionWrapper>
                        } else {
                            return <React.Fragment key={key}></React.Fragment>
                        }
                    })}
                </Stack>

                {/* Edit Icon */}
                <Stack direction={"row"} spacing={1} justifyContent={{ xs: "center", sm: "flex-start" }} width={{ xs: "100%", sm: "fit-content" }}>
                    {false && <Tooltip title={"Edit Description"} placement={"top"} arrow>
                        <IconButton size={"small"} onClick={onEditShortDescriptionClicked}>
                            <Edit fontSize='small' />
                        </IconButton>
                    </Tooltip>}

                    {isShortDescEditOn && <>
                        <Tooltip title={"Save"} placement={"top"} arrow>
                            <IconButton size={"small"} color={"success"} onClick={saveShortDescription}>
                                <Check fontSize={"small"} />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title={"Clear"} placement={"top"} arrow>
                            <IconButton size={"small"} color={"error"} onClick={clearShortDescription}>
                                <Clear fontSize={"small"} />
                            </IconButton>
                        </Tooltip>
                    </>}

                    {/* Copy Icon */}
                    {!isShortDescEditOn && <Tooltip title={isShortDescCopied ? "Copied !!!" : "Copy Description"} placement={"top"} arrow>
                        <IconButton size={"small"} onClick={copyShortDescription}>
                            <ContentCopy fontSize='small' />
                        </IconButton>
                    </Tooltip>}
                </Stack>
            </Stack>}

            {/* Length of Short Generated Description */}
            {props.shortDescription.length > 0 && <Box>
                <Typography variant="body1" color={"black"}>
                    <Typography variant="caption" color={"GrayText"}>Total Characters: </Typography>
                    {props.shortDescription.map((_: any) => _.label).join(", ").length}
                </Typography>
            </Box>}

            {props.description.length > 0 && <Stack direction={{ xs: "column", sm: "row" }} spacing={1} justifyContent={"space-evenly"} alignItems={"flex-start"}>
                <Typography variant='body2'>Long Description: </Typography>
                <Stack direction={"row"} flexWrap={"wrap"} spacing={1} width={{ xs: "100%", sm: "fit-content" }} sx={{ overflow: "scroll" }}>
                    {props.description.map((desc, key) => {
                        if (desc?.type === "Text") {
                            return <DescriptionWrapper key={key} label={desc.title}>
                                <Chip sx={{ width: "fit-content" }} key={key} label={desc?.label} />
                            </DescriptionWrapper>
                        } else if (desc?.type === "Input") {
                            return <DescriptionWrapper key={key} label={desc.title}>
                                <Input type='text' value={state[key]} onChange={(e: any) => setState({ ...state, [key]: e.target.value.toUpperCase() })} />
                            </DescriptionWrapper>
                        } else {
                            return <React.Fragment key={key}></React.Fragment>
                        }
                    })}
                </Stack>

                {/* Edit Icon */}
                <Stack direction={"row"} spacing={1} justifyContent={{ xs: "center", sm: "flex-start" }} width={{ xs: "100%", sm: "fit-content" }}>
                    {!isEditOn && <Tooltip title={"Edit Description"} placement={"top"} arrow>
                        <IconButton size={"small"} onClick={onEditDescriptionClicked}>
                            <Edit fontSize='small' />
                        </IconButton>
                    </Tooltip>}

                    {isEditOn && <>
                        <Tooltip title={"Save"} placement={"top"} arrow>
                            <IconButton size={"small"} color={"success"} onClick={saveDescription}>
                                <Check fontSize={"small"} />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title={"Clear"} placement={"top"} arrow>
                            <IconButton size={"small"} color={"error"} onClick={clearDescription}>
                                <Clear fontSize={"small"} />
                            </IconButton>
                        </Tooltip>
                    </>}

                    {/* Copy Icon */}
                    {!isEditOn && <Tooltip title={isCopied ? "Copied !!!" : "Copy Description"} placement={"top"} arrow>
                        <IconButton size={"small"} onClick={copyDescription}>
                            <ContentCopy fontSize='small' />
                        </IconButton>
                    </Tooltip>}
                </Stack>
            </Stack>}

            {/* Length of Generated Description */}
            {props.description.length > 0 && <Box>
                <Typography variant="body1" color={"black"}>
                    <Typography variant="caption" color={"GrayText"}>Total Characters: </Typography>
                    {props.description.map((_: any) => _.label).join(", ").length}
                </Typography>
            </Box>}

            <Stack direction={"row"} spacing={1}>
                {props.for &&
                    <Button variant="outlined" onClick={() => {
                        if (props.onClose) {
                            let newDesc: string[] = props.description.map((desc, key) => {
                                if (state[key]) { return state[key]; } else {
                                    return desc.label;
                                }
                            });
                            props.onClose(newDesc.join(", "))
                        }
                    }
                    } sx={{ textTransform: "none" }}>Cancel</Button>
                }

                <Button id="md-generate" disabled={isEditOn || props.isLoading} onClick={(e: any) => {
                    if (regenerate) {
                        dispatch(openConfirmationDialog({
                            title: "Re-generate",
                            body: "Are you sure you want to regenerate the description? You may lose any data you've entered.",
                            positiveBtn: "Re-generate",
                            onOk: () => {
                                props.onGenerateBtnClicked();
                                dispatch(closeConfirmationDialog());
                            },
                            onNegativeBtn: () => dispatch(closeConfirmationDialog())
                        }))
                    } else {
                        let value = props.onGenerateBtnClicked();
                        if (value) {
                            setRegenerate(true);
                        }
                    }
                }} variant={"contained"} sx={{ fontFamily: "htrts_semibold", textTransform: "none" }}>
                    {regenerate ? 'Re-generate' : 'Generate'}
                    <Engineering sx={{ marginLeft: 1 }} />
                </Button>

                {props.for && <Button id="md-apply" variant="contained" disabled={isEditOn || props.isLoading} onClick={() => props.onApply()} sx={{ textTransform: "none" }}>Apply to {props.for === "new_bom" ? "BOM" : "Material"}</Button>}

                <Button disabled={isEditOn || props.isLoading} variant="outlined" onClick={() => {
                    setRegenerate(false);
                    props.resetAll();
                    setIsEditOn(false);
                }} sx={{ textTransform: "none" }}>Reset</Button>
            </Stack>
        </Stack>
    </Box >
}

export const DescriptionWrapper: React.FC<{ label: string, children: JSX.Element }> = ({ label = "", children = <></> }) => {
    return <Tooltip title={label} placement={"top"} arrow>
        {children}
    </Tooltip>
}