import React from "react";
import { Box } from "@mui/material";
import { withNavBars } from "../../HOCs";
import { Layout } from "../../components";
import { TagGroupsModuleNavBars } from "../../utils";
import { TagGroupsListing } from "./tagGroupsListing";

const TagGroups: React.FC<{ children?: JSX.Element }> = (props) => {

    return <Box sx={{ height: "100%" }}>
        {/* Layout */}
        <Layout
            history={[
                { label: "Tag Categories", onClick: () => false },
            ]}
            currentPath={"Dashboard"}
            navBars={TagGroupsModuleNavBars}
            mainPanel={<TagGroupsListing />}
            sideNavVariant={"whiteboard"}
            hideNavBarByDefault={true}
        />
    </Box>
}

export default withNavBars(TagGroups);