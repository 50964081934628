import React from "react";
import {
  Button,
  Grid,
  Alert,
  Typography,
  Divider,
  useMediaQuery,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { GridRowParams, GridSortDirection, GridSortModel } from "@mui/x-data-grid-premium";
import { CustomDataGrid } from "../../../components";
import { DashboardTableColumn } from "../../materials/utils";
import { useDispatch } from "react-redux";
import { closeDialog } from "../../../redux/slices/dialog";
import {
  closeConfirmationDialog,
  openConfirmationDialog,
} from "../../../redux/slices/confirmationDialog";
import { useLazyGetMaterialsQuery } from "../../../redux/services";

const ViewLinkMat: React.FC<{
  children?: JSX.Element;
  id?: any;
  callLinkMaterial?: any;
  associations: any;
  refetch?: any;
}> = (props) => {
  const dispatch = useDispatch();
  const [
    getMaterials,
    { data: allData = { data: [], totalCount: 0 }, isLoading, isError, isFetching },
  ] = useLazyGetMaterialsQuery();
  const { data, totalCount } = allData;

  const [links, setLinks] = React.useState([]);
  const [paginationModel, setPaginationModel] = React.useState({
    page: 0,
    pageSize: 10,
  });
  const [queryOptions, setQueryOptions] = React.useState({
    sortModel: [{ field: 'sequence_id', sort: 'desc' as GridSortDirection }],
  });

  const isMatch = useMediaQuery("(max-width: 600px)");

  const handleSortModelChange = React.useCallback((sortModel: GridSortModel) => {
    // Here you save the data you need from the sort model
    setQueryOptions({ sortModel: [...sortModel] });
  }, [])

  const refetch = () => {
    getMaterials({ include_histories: false, ...paginationModel, sort_by: queryOptions?.sortModel?.[0]?.field, sort_order: queryOptions?.sortModel?.[0]?.sort });
  };

  React.useEffect(() => {
    refetch();
    // eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    refetch();
    // eslint-disable-next-line
  }, [paginationModel, JSON.stringify(queryOptions?.sortModel)]);

  const onCancelBtnClicked = () => {
    dispatch(closeDialog());
  };

  const linkMaterialCall = () => {
    dispatch(closeConfirmationDialog());
    dispatch(closeDialog());
    props.callLinkMaterial(links, props.refetch, [], "material");
  };
  const onLinkBtnClicked = () => {
    dispatch(
      openConfirmationDialog({
        title: "Link Operation",
        body: `Are you sure you want to link the selected items`,
        positiveBtn: "Yes",
        negativeBtn: "Cancel",
        onOk: () => linkMaterialCall(),
        onNegativeBtn: () => dispatch(closeConfirmationDialog()),
      })
    );
  };

  const giveMeRows = () => {
    if (!isLoading && data) {
      return data;
    } else {
      return [];
    }
  };

  let ROWS = giveMeRows();

  const onRowSelection = (selected: any) => {
    setLinks(selected);
  };
  const isRowSelectable = (row: any) => {
    const assocArray: any = props.associations.filter(
      (obj: any) => obj.object_type === "material"
    );
    let isSelectable = true;

    try {
      for (let index = 0; index < assocArray?.length; index++) {
        const asso = assocArray?.[index];
        isSelectable = !asso.object_details
          .map((_: any) => _._id)
          .includes(row._id);
        if (!isSelectable) {
          break;
        }
      }
    } catch (error) {
      console.error("error", error);
    }

    return isSelectable;
  };

  const loading = isLoading || isFetching;

  return (
    <>
      <DialogTitle>   
        <Typography
          gutterBottom
          variant="body1"
          color={"textPrimary"}
          sx={{ display: "flex", fontWeight: 600 }}
        >
          Link Material
        </Typography>
        <Divider variant="fullWidth" sx={{ mb: 2 }} />
      </DialogTitle>

      <DialogContent>
        {isError && (
          <Alert sx={{ mb: 2 }} severity="error">
            Oops! Something went wrong, Unable to fetch materials. Try Again
            Later!
          </Alert>
        )}
        <CustomDataGrid
          saveLocal={false}
          initialState={{
            sorting: {
              sortModel: [{ field: "sequence_id", sort: "desc" }],
            },
          }}
          rows={ROWS}
          columns={DashboardTableColumn({ disableNavigation: true, removeDelete: true })}
          loading={loading}
          pagination
          getRowId={(row) => row._id}
          showToolbar={false}
          checkboxSelection={true}
          onRowSelectionModelChange={(selected) => {
            onRowSelection(selected);
          }}
          isRowSelectable={(params: GridRowParams) =>
            isRowSelectable(params.row)
          }
          rowCount={totalCount}
          paginationModel={paginationModel}
          paginationMode="server"
          onPaginationModelChange={setPaginationModel}
          sortingMode="server"
          onSortModelChange={handleSortModelChange}
        />
      </DialogContent>

      <DialogActions>
        <Grid
          container
          xs={12}
          spacing={1}
          sm={12}
          md={12}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
          }}
          pb={1}
        >
          <Grid item xs={12} sm={6}></Grid>
          <Grid item xs={12} sm={3}>
            <Button
              variant={"contained"}
              color={"primary"}
              fullWidth
              onClick={onLinkBtnClicked}
              disabled={links.length === 0}
              sx={{
                fontSize: (theme) => theme.spacing(1.75),
                paddingX: "4px",
                right: isMatch ? 0 : 20,
                width: isMatch ? "80%" : "100%",
                transform: isMatch ? "translateX(10%)" : "translateX(0)",
              }}
            >
              Link
            </Button>
          </Grid>
          <Grid item xs={12} sm={3}>
            <Button
              variant={"outlined"}
              color={"primary"}
              fullWidth
              onClick={onCancelBtnClicked}
              disabled={false}
              sx={{
                fontSize: (theme) => theme.spacing(1.75),
                paddingX: "4px",
                right: isMatch ? 0 : 20,
                width: isMatch ? "80%" : "100%",
                transform: isMatch ? "translateX(10%)" : "translateX(0)",
              }}
            >
              Cancel
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </>
  );
};

export default ViewLinkMat;
