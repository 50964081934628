import { LoadingButton } from "@mui/lab";
import { Checkbox, Stack, Tooltip, Typography } from "@mui/material"
import { GridActionsCellItem, GridRenderCellParams, GridRowSelectionModel } from "@mui/x-data-grid-premium";
import React, { FC, useEffect, useMemo } from "react";
import { buildRows } from ".";
import { CustomDataGrid, SectionPaper } from "../../../components"
import { useAppDispatch, useAppSelector } from "../../../redux";
import { useLazyGetDataTableByIdQuery } from "../../../redux/services";
import { closeBackdrop, openBackdrop } from "../../../redux/slices/backdrop";
import CustomTable from "../dataSheet/table";

interface SearchParams {
    rowSelectionModel: GridRowSelectionModel;
    setRowSelectionModel: React.Dispatch<React.SetStateAction<GridRowSelectionModel>>;
    keywordSelections: GridRowSelectionModel;
    setKeywordSelections: React.Dispatch<React.SetStateAction<GridRowSelectionModel>>;
    dataSheet: any[];
    setDataSheet: React.Dispatch<React.SetStateAction<any[]>>;
    rows: any[];
    setRows: React.Dispatch<React.SetStateAction<any[]>>;
}

export const SearchParameters: FC<SearchParams> = ({
    rowSelectionModel,
    setRowSelectionModel,
    keywordSelections,
    setKeywordSelections,
    dataSheet,
    setDataSheet,
    rows, 
    setRows
}) => {
    const dispatch = useAppDispatch();
    const { materials } = useAppSelector(store => store);

    const [getDataTableById] = useLazyGetDataTableByIdQuery({ refetchOnFocus: true, refetchOnReconnect: true })

    console.log("rowsrowsrowsrows", rows)

    const handleCheckboxSelection = (id: string) => {
        setKeywordSelections((prevSel) => {
            if (prevSel.includes(id)) {
                return prevSel.filter(p => p !== id)
            } else {
                return [...prevSel, id]
            }
        })
    };

    let columns = useMemo(() => [
        {
            field: "description",
            headerName: "Description",
            width: 280,
            renderCell: (params: GridRenderCellParams) => {
                if (params.rowNode.type === "group") {
                    return params.value;
                }
                return (
                    <Typography
                        color="primary"
                        variant="body2"
                    >
                        {params.row?.description}
                    </Typography>
                );
            },
        },
        {
            field: "value",
            headerName: "Value",
            width: 350,
            renderCell: (params: GridRenderCellParams) => {
                if (params.rowNode.type === "group") {
                    return params.value;
                }
                return (
                    <Tooltip title={params.row?.value}>
                        <Typography
                            variant="body2"
                            sx={{
                                width: "100%",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                            }}
                        >
                            {params.row?.value}
                        </Typography>
                    </Tooltip>
                );
            },
        },
        {
            field: "includeInSearchKeyword",
            headerName: "Include in Search Keyword",
            width: 220,
            cellClassName: 'actions',
            sortable: false,
            hideSortIcons: true,
            disableColumnMenu: true,
            resizable: false,
            renderCell: ({ id }: any) => {
                const shouldDisable = !rowSelectionModel.includes(id);
                return [
                    <GridActionsCellItem
                        disableRipple
                        icon={<Checkbox disabled={shouldDisable} checked={keywordSelections.includes(id)} />}
                        label="Keywords"

                        disabled={shouldDisable}
                        onClick={() => handleCheckboxSelection(id)}
                    />
                ];
            }
        },
        // eslint-disable-next-line
    ], [materials, keywordSelections, rowSelectionModel])

    const updateTablesState = async (res: any) => {
        const newState = res?.tables?.map((table: any, idx: number) => ({
            rowSelectionModel: [],
            name: table.name,
            position: idx,
            description: table.description,
            header_color: table?.header_color,
            definition: {
                columns: table?.columns?.filter((col: any) => col.field)?.map((col: any) => {
                    return {
                        ...col,
                        headerName: col.header_name,
                        headerClassName: `${col.header_class_name}`,
                        resizable: false,
                        sortable: false,
                        disableColumnMenu: true,
                    }
                }),
                rows: table?.rows?.map((row: any) => {
                    const obj: any = {};
                    row?.column_content?.forEach((col: any) => {
                        obj[col.field] = col.content
                    })
                    return {
                        id: row?.id,
                        ...obj
                    }
                }) || [],
            },
        }));
        setDataSheet(newState)
    };

    const fetchDataSheet = async (id: string) => {
        dispatch(openBackdrop(`Fetching Data Sheet Detail...`));
        await getDataTableById({
            dtTemplateType: "datasheet",
            dtInstanceType: "form",
            dtTemplateId: id
        }).unwrap().then(async (res) => {
            if (res) {
                await updateTablesState(res);
            }
        }).catch((error: any) => {
            console.error(`Error fetching datasheet : ${error}`)
        }).finally(() => {
            dispatch(closeBackdrop());
        })
    };

    useEffect(() => {
        setKeywordSelections((prevSel) => {
            return prevSel.filter(s => rowSelectionModel.includes(s))
        })
        // eslint-disable-next-line
    }, [rowSelectionModel])

    useEffect(() => {
        const sheet_id = materials?.associations?.find((_: any) => _.object_type === "dataTableForm")?.object_details?.at(-1)?._id;
        if (sheet_id) {
            fetchDataSheet(sheet_id);
        }
        // eslint-disable-next-line
    }, [materials])

    useEffect(() => {
        const r = buildRows(materials);
        setRows(r);
        // eslint-disable-next-line
    }, []);

    console.log("dataSheet", dataSheet)

    return <Stack mt={2} spacing={2}>
        <SectionPaper
            title="Basic Details"
            renderButtons={[
                <LoadingButton
                    fullWidth
                    size="medium"
                    variant="outlined"
                    sx={{ width: 200 }}
                    onClick={() => {
                        setRowSelectionModel([]);
                        setKeywordSelections([]);
                    }}
                >
                    Reset
                </LoadingButton>
            ]}
        >
            <>
                <CustomDataGrid
                    saveLocal={false}
                    initialState={{
                        sorting: {
                            sortModel: [{ field: 'description', sort: 'desc' }],
                        },
                    }}
                    rows={rows}
                    columns={columns}
                    getRowId={(row) => row.id}
                    checkboxSelection={true}
                    isRowSelectable={(params) => params?.row?.value}
                    onRowSelectionModelChange={(newRowSelectionModel) =>
                        setRowSelectionModel(newRowSelectionModel)
                    }
                    rowSelectionModel={rowSelectionModel}
                    hideFooter
                />
            </>
        </SectionPaper>

        {dataSheet?.map((data, index) => <CustomTable includeCheckbox onUpdate={() => { }} data={data} key={index} state={dataSheet} updateState={setDataSheet} index={index} preview />)}
    </Stack>
}