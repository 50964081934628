import { Chip, Stack, Tooltip, Typography } from "@mui/material";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid-premium";
import moment from "moment";
import { IconButton } from "@mui/material";
import { ContentCopy, DeleteOutline } from "@mui/icons-material";
import { hexToGrayscale } from "../../utils";
import { TagInfo } from "../../interfaces";

export const TableColumn = (
  onDelete: (row: any) => void,
  view: (row: any) => void,
  duplicateTemplate: (row: any) => void,
  hasAccess: boolean
) => {
  let columns: GridColDef[] = [
    {
      field: "sequence_id",
      headerName: "Parameter ID",
      width: 150,
      renderCell: (params: GridRenderCellParams) => {
        if (params.rowNode.type === "group") {
          return params.value;
        }
        return (
          <Typography
            id="view-parameter"
            onClick={() => view(params.row)}
            variant="body2"
            color="primary"
            sx={hasAccess ? { color: "blue", cursor: "pointer", textDecoration: "underline" } : { textDecoration: "none" }}
          >
            {params.row.sequence_id}
          </Typography>
        );
      },
    },
    {
      field: "name",
      headerName: "Parameter Name",
      width: 230,
      renderCell: (params: GridRenderCellParams) => {
        if (params.rowNode.type === "group") {
          return params.value;
        }
        return (
          <Typography variant="body2">{params.row?.name ?? ""}</Typography>
        );
      },
      valueGetter: (params: GridRenderCellParams) => {
        return params.row?.name ?? "";
      },
    },
    {
      field: "tags_info",
      headerName: "Tags",
      width: 230,
      renderCell: (params: GridRenderCellParams) => {
        if (params.rowNode.type === "group") {
          if (params.rowNode.groupingField === "type") {
            return params.rowNode.groupingKey;
          }
          return "";
        }
        return (
          <Stack
            spacing={1}
            direction={"row"}
            width="100%"
            overflow={"scroll"}
            id={"tagsChip"}
          >
            {params.row.tags_info
              ?.filter((_: any) => _.tag_group_name === "Checklist Tag")
              .map((tag: any) => (
                <Tooltip
                  title={tag?.tag_name}
                  key={tag?.tag_name}
                  placement={"top"}
                >
                  <Chip
                    variant="outlined"
                    sx={{
                      borderColor: tag.tag_group_color,
                      backgroundColor: tag.tag_group_color,
                      color: `${hexToGrayscale(tag.tag_group_color) > 150
                        ? "rgba(0, 0, 0, 0.87)"
                        : "#FFFFFF"
                        }`,
                    }}
                    size={"small"}
                    label={tag?.tag_name ?? ""}
                  />
                </Tooltip>
              ))}
          </Stack>
        );
      },
      valueGetter: (params: GridRenderCellParams) => {
        return (
          params.row?.tags_info
            ?.filter((t: TagInfo) => t.tag_group_name === "Checklist Tag")
            .map((t: TagInfo) => t?.tag_name)
            .join(",") ?? ""
        );
      },
    },
    {
      field: "status",
      headerName: "Status",
      width: 150,
      renderCell: (params: GridRenderCellParams) => {
        if (params.rowNode.type === "group") {
          return params.value;
        }
        return <Typography variant="body2" id="status">{params.row?.status}</Typography>;
      },
    },
    {
      field: "created_user",
      headerName: "Created By",
      width: 150,
      renderCell: (params: GridRenderCellParams) => {
        if (params.rowNode.type === "group") {
          return params.value;
        }
        return (
          <Typography variant="body2">{params.row?.created_user}</Typography>
        );
      },
    },
    {
      field: "created_time",
      headerName: "Date & Time",
      width: 180,
      renderCell: (params: GridRenderCellParams) => {
        if (params.rowNode.type === "group") {
          return params.value;
        }
        return (
          <Typography variant="body2">
            {moment(params.row?.created_time).format("lll")}
          </Typography>
        );
      },
    },
    {
      field: "modified_user",
      headerName: "Updated By",
      width: 150,
      renderCell: (params: GridRenderCellParams) => {
        if (params.rowNode.type === "group") {
          return params.value;
        }
        return (
          <Typography variant="body2">{params.row?.modified_user}</Typography>
        );
      },
    },
    {
      field: "modified_time",
      headerName: "Date & Time",
      width: 250,
      renderCell: (params: GridRenderCellParams) => {
        if (params.rowNode.type === "group") {
          return params.value;
        }
        return (
          <Typography variant="body2">
            {params.row.modified_time
              ? moment(params.row.modified_time).format("lll")
              : ""}
          </Typography>
        );
      },
    }
  ];

  if (hasAccess) {
    columns = [columns[0], {
      field: "duplicate",
      headerName: "Duplicate",
      resizable: true,
      width: 90,
      disableColumnMenu: true,
      sortable: false,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <Tooltip title="Make a Duplicate">
            <IconButton
              sx={{ margin: "auto" }}
              onClick={() =>
                duplicateTemplate(params?.row)
              }
            >
              <ContentCopy fontSize="small" />
            </IconButton>
          </Tooltip>
        );
      },
    }, ...columns.slice(1), {
      field: "delete",
      headerName: "Delete",
      width: 100,
      disableColumnMenu: true,
      sortable: false,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <IconButton onClick={() => onDelete(params.row)}>
            <DeleteOutline color="error" />
          </IconButton>
        );
      },
    },]
  }

  return columns;
};
