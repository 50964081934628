import { createApi, fetchBaseQuery, retry } from '@reduxjs/toolkit/query/react';
import { config } from '../../../config';
import { ConvertToReactSelect, getTransformedDataForPagination, LocalStorageKeys } from '../../../utils';

export const uomAPI = createApi({
    reducerPath: "uomAPI",
    baseQuery: retry(fetchBaseQuery({
        baseUrl: config.api_url  + "mm/mdg/",
        prepareHeaders: (headers, { getState }) => {
            const token = localStorage.getItem(LocalStorageKeys.authToken);

            // If we have a token set in state, let's assume that we should be passing it.
            if (token) {
                headers.set('authorization', `Bearer ${token}`)
            }

            return headers
        },
    }), { maxRetries: 2 }),
    tagTypes: ['UoMs', 'UoMsAsOptions'],
    endpoints: (builder) => ({
        // Get UoM API
        getUoM: builder.query({
            query: ({ page = 0, pageSize = 10, sort_by = null, sort_order = null }) => ({ url: `uom${page !== null ? `?page_no=${page + 1}` : ''}${pageSize ? `&page_size=${pageSize}` : ''}${sort_by ? `&sort_by=${sort_by}` : ''}${sort_order ? `&sort_order=${sort_order}` : ''}`, method: "GET" }),
            transformResponse: (data: any, meta: { request: Request; response?: Response }) => {
                return getTransformedDataForPagination(data, meta?.response);
            },
            providesTags: ["UoMs"]
        }),

        // Create UoM API
        createUoM: builder.mutation({
            query: (payload) => ({ url: `uom`, method: "POST", body: payload }),
            invalidatesTags: ["UoMs", "UoMsAsOptions"]
        }),

        // Update UoM API
        updateUoM: builder.mutation({
            query: (payload) => ({ url: `uom`, method: "PUT", body: payload }),
            invalidatesTags: ["UoMs", "UoMsAsOptions"]
        }),

        // Delete UoM API
        deleteUoM: builder.mutation({
            query: ({id}) => ({ url: `uom/${id}`, method: "DELETE" }),
            invalidatesTags: ["UoMs", "UoMsAsOptions"]
        }),

        // Get UoM as Dropdown Options API
        getUoMDropDown: builder.query({
            query: (payload) => ({ url: `uom`, method: "GET" }),
            transformResponse: (response: any[], meta, arg) => ConvertToReactSelect(response, "_id", "uom_name"),
            providesTags: ["UoMsAsOptions"]
        }),

    }) 
})

// Export hooks for usage in functional components
export const { useGetUoMQuery, useLazyGetUoMQuery, useGetUoMDropDownQuery, useCreateUoMMutation, useUpdateUoMMutation, useDeleteUoMMutation, useLazyGetUoMDropDownQuery } = uomAPI
