import { Button, Grid } from "@mui/material";
import React from "react";
import { useSearchParams } from "react-router-dom";
import { MyPaper, SelectBox } from "../../components";
import { ReactSelectOption } from "../../interfaces";
import { useAppDispatch } from "../../redux";
import { useLazyGetBOMByIdQuery, useLazyGetBOMsQuery } from "../../redux/services";
import { closeBackdrop, openBackdrop } from "../../redux/slices/backdrop";

export const AssignBOMToDrawioShape: React.FC<{ children?: JSX.Element }> = (props) => {

    const dispatch = useAppDispatch();
    const [getBOMs, getBOMsData] = useLazyGetBOMsQuery();
    const [getBOMById] = useLazyGetBOMByIdQuery();
    const [searchParams, setSearchParams] = useSearchParams();

    const [bom, setBom] = React.useState<ReactSelectOption>();

    const loadOption = async (search: string, loadedOptions: any) => {
        try {
            let page = Math.round(loadedOptions.length / 50);
            let options = await getBOMs({ page, pageSize: 50 }).unwrap();
            return { options: options.data.map((_: any) => ({ ..._, value: _._id, label: `${_.sequence_id}-r${_.version} (${_.short_description})` })), hasMore: options.data.length === 50 }
        } catch (error) {
            console.log('error:', error)
            return { options: [], hasMore: false }
        }
    };

    const onAssignTagNoBtnClicked = () => {
        const drawioContent: any = window.parent.window.parent.document.getElementById('drawio-editor-iframe');
        if (drawioContent) {
            drawioContent.contentWindow?.postMessage({ ...bom, event: "iehub-plugin", subEvent: "assignBOM" }, "*");
        } else {
            console.log('drawio editor is not mapped');
        }
    };

    const updateExistingDetails = async () => {
        if (searchParams.get('edit') === "1") {
            dispatch(openBackdrop('Fetching BOM Detail...'));

            const bomId = searchParams.get("bomId");

            if (bomId !== "null") {
                try {
                    const bomDetails = await getBOMById({ id: bomId }).unwrap();

                    if (bomDetails) {
                        setBom({ ...bomDetails, value: bomDetails._id, label: `${bomDetails.sequence_id}-r${bomDetails.version} (${bomDetails.short_description})` } as unknown as ReactSelectOption)
                    }
                } catch (err) {
                    console.log('err:', err)
                }
            }

            dispatch(closeBackdrop());
        }
    }

    const messageHandler = (evt: MessageEvent) => {
        if (evt.data?.type === 'assignBOM') {
            setSearchParams({ ...evt.data, edit: 1 });
        }
    }

    // Initial load
    React.useEffect(() => {
        window.addEventListener('message', messageHandler);

        return () => {
            window.removeEventListener('message', messageHandler);
        };
        // eslint-disable-next-line
    }, []);

    React.useEffect(() => {
        updateExistingDetails();
        // eslint-disable-next-line
    }, [searchParams]);

    return <MyPaper height={"100vh"}>
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <SelectBox
                    isPaginate
                    loadOptions={loadOption}
                    label={"BOM"}
                    value={bom}
                    loading={getBOMsData.isLoading || getBOMsData.isFetching}
                    options={[]}
                    onChange={(data: ReactSelectOption) => setBom({ ...bom, ...data })}
                />
            </Grid>

            <Grid item xs={12}>
                <Button disabled={!bom} fullWidth variant="contained" onClick={onAssignTagNoBtnClicked}>Assign BOM</Button>
            </Grid>
        </Grid>
    </MyPaper>;
}