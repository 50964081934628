import React from "react";
import { BOMStateInterface } from "../screens/viewBOMTree";
import { Themes } from "../utils";

/**
 * ThemeContext store the current theme of the app,which is provided
 * at the /src/App.js using the /src/App.theme.js.
 */
export let ThemeContext = React.createContext<{
  name: string;
  setTheme: any;
}>({
  name: Themes.default,
  setTheme: () => false,
});

/**
 * SocketContext store the current socket connection details and functions
 * to open, close, send, receive message from socket.
 */
export interface socketContextInterface {
  socketObj: WebSocket | null;
  isConnected: boolean;
  lastUsed: Date | null;
  receivedEvents: {
    [key: string]: {
      [key: string]: {
        alert: string;
        status: "saved" | "failed";
        document_id: string;
        size: number;
      };
    };
  };
  open: (payload: any) => Promise<boolean>;
  send: (payload: any) => void;
  close: () => void;
  clearReceivedEvent: (parent: string, child: string) => void;
}

export let SocketContext = React.createContext<socketContextInterface>({
  socketObj: null,
  isConnected: false,
  lastUsed: null,
  receivedEvents: {},
  open: () => Promise.resolve(false),
  send: (payload: any) => false,
  close: () => false,
  clearReceivedEvent: (parent: string, child: string) => false,
});

export let BOMContext = React.createContext<{
  updateBOMTree: (
    bom: BOMStateInterface | null,
    level: number,
    index: number[]
  ) => Promise<void>;
  bom: any;
}>({
  updateBOMTree: async () => {},
  bom: {}
});
