import { Stack, Tooltip, Typography } from "@mui/material";
import { GridRenderCellParams } from "@mui/x-data-grid-premium";
import { GiveMeStatusColor } from "../../utils";

export const SupplierTableColumn = (view: any) => ([
    {
        field: 'vendor_name', headerName: 'Supplier Name', width: 200, renderCell: (params: GridRenderCellParams) => {
            if (params.rowNode.type === 'group') {
                return params.value;
            }
            return <Tooltip title={"View Supplier Details"}>
                <Typography
                    variant="body2"
                    component={"a"}
                    color="primary"
                    sx={{ width: "100%", cursor: "pointer", textDecoration: "underline" }}
                    onClick={() => view(params?.row)}
                >
                    {params?.row.vendor_name}
                </Typography>
            </Tooltip>

        },
    },
    { field: 'email', headerName: 'Email', width: 200 },
    { field: 'phone', headerName: 'Phone', width: 200 },
    {
        field: 'is_approved', headerName: 'Approved (Yes/No)', flex: 1, renderCell: (params: GridRenderCellParams) => {
            if (params.rowNode.type === 'group') {
                return params.value;
            }
            return (
                <Stack width={"100%"}>
                    <Typography variant="body2" color={GiveMeStatusColor(params?.row?.approved.toString())} sx={{ width: "100%", overflow: "hidden", textOverflow: "ellipsis" }}>
                        {params?.row?.approved ? "Yes" : "No"}
                    </Typography>
                </Stack>
            )
        },
        valueGetter: (params: GridRenderCellParams) => params?.row?.approved ? "Yes" : "No",
    }
]);