// AppConfirmationDialog is used to show the confirmation dialog component all over the app whenever it needed with the use of confirmationDialog actions created in /redux/slices/confirmationDialog.js
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, DialogTitleProps, IconButton } from "@mui/material";
import Paper, { PaperProps } from '@mui/material/Paper';
import React from "react";
import Draggable from 'react-draggable';
import { useAppDispatch, useAppSelector } from "./redux";
import { closeConfirmationDialog } from "./redux/slices/confirmationDialog";
import { Close } from "@mui/icons-material";

const PaperComponent = (props: PaperProps) => {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

export const AppConfirmationDialog: React.FC<{ children: JSX.Element }> = ({ children }) => {

  const dispatch = useAppDispatch();

  const {
    open, title, body, hideNegativeBtn,
    hidePositiveBtn, negativeBtn, positiveBtn, onOk, onNegativeBtn,
    hideForceBtn, onForce, forceBtn, hideCloseIcon = false
  } = useAppSelector((state) => state.confirmation);

  const cancelDialog = () => {
    if(onNegativeBtn){
      onNegativeBtn();
    }else{
      dispatch(closeConfirmationDialog())
    }
  }

  function CustomDialogTitle(props: DialogTitleProps) {
    const { children, ...other } = props;

    return (
      <DialogTitle sx={{ m: 0, p: 1 }} {...other}>
        {children}
        {!hideCloseIcon &&
          <IconButton
            aria-label="close"
            title="Close"
            onClick={() => dispatch(closeConfirmationDialog())}
            sx={{
              backgroundColor: '#fff',
              border: 'none',
              borderRadius: '5px',
              cursor: 'pointer',
              outline: 'none',
              transition: 'box-shadow 0.3s ease-in-out',
              ':hover, :focus': {
                boxShadow: '0px 0px 5px 2px rgba(103, 58, 183, 0.5)',
              },
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
              width: '25px',
              height: '25px',
              marginTop: '10px',
              marginRight: '11px'
            }}
          >
            <Close />
          </IconButton>
        }
      </DialogTitle>
    );
  }

  return <>
    {children}
    <Dialog open={open} maxWidth="sm" PaperComponent={PaperComponent} onClose={cancelDialog}>

      {/* Dialog Title */}
      {title && <CustomDialogTitle>{title}</CustomDialogTitle>}
      {/* Dialog Content */}
      {body && <DialogContent dividers sx={{ borderBottom: "unset" }} >{body}</DialogContent>}

      {/* Ḍialog Action Button */}
      <DialogActions style={{ marginBottom: 8, marginRight: 8 }}>
        {!Boolean(hideNegativeBtn) && <Button onClick={cancelDialog} color="primary" variant="outlined">
          {negativeBtn}
        </Button>}
        {!Boolean(hidePositiveBtn) && <Button onClick={onOk} color="primary" variant="contained" id="dialog-positive-action">
          {positiveBtn}
        </Button>}
        {!Boolean(hideForceBtn) && <Button onClick={onForce} color="error" variant="contained" id="dialog-force-action">
          {forceBtn}
        </Button>}
      </DialogActions>
    </Dialog>
  </>
}

