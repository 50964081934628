import React from "react";
import { Grid, Box, useTheme, Theme, Typography } from "@mui/material";
import { CustomDataGrid, TitleHeader } from "../../components";
import { MastersInterface, SelectBoxOption } from "../../interfaces";
import { openDialog } from "../../redux/slices/dialog";
import { useDispatch } from "react-redux";
import { AddUpdateAttribute, AddUpdateGroup, AddUpdateNoun, AddUpdateNounVariant, AddUpdateUoM } from "./addUpdate";
import { MasterValue } from "./util";
import { NounDataInterface, NounListing } from "./nounListing";
import { openConfirmationDialog, closeConfirmationDialog, updateCongirmationDialogBody } from "../../redux/slices/confirmationDialog";
import { closeBackdrop, openBackdrop } from "../../redux/slices/backdrop";
import { useSnackbar } from "notistack";
import { useCreateAttributeMutation, useDeleteAttributeMutation, useDeleteGroupMutation, useDeleteNounMutation, useDeleteNounVariantMutation, useDeleteUoMMutation, useLazyGetNounsQuery, useLazyGetUoMDropDownQuery } from "../../redux/services";
import { DeletePanel } from "./deletePanel";
import { GridPaginationModel, GridSortModel } from "@mui/x-data-grid-premium";

const useStyles = (theme: Theme) => ({
  root: {
    flexGrow: 1,
    background: theme.palette.background.paper,
    boxShadow: `rgba(99, 99, 99, 0.2) 0px 2px 8px 0px`,
    height: "100%",
    borderRadius: theme.spacing(1),
    padding: theme.spacing(2),
  },
  dataGridParent: {
    height: "calc(100% - 40px)",
    overflow: "scroll",
    marginTop: theme.spacing(2),
  },
  dataGrid: {
    background: theme.palette.background.paper,
    '@media print': {
      '.MuiDataGrid-main': { color: 'rgba(0, 0, 0, 0.87)' },
    }
  },
});

export const RightPanel: React.FC<{
  selectedMaster: MastersInterface,
  data?: any,
  currentData?: any,
  isLoading?: boolean,
  isFetching?: boolean,
  isError?: boolean,
  getMaster?: any,
  uomData?: any,
  state: any,
  setState: any,
  paginationModel: GridPaginationModel,
  totalCount: number,
  setPaginationModel: React.Dispatch<React.SetStateAction<GridPaginationModel>>,
  status?: any,
  handleSortModelChange: (sortModel: GridSortModel) => void
}> = ({
  selectedMaster,
  data = [],
  uomData = {},
  currentData = [],
  isLoading = true,
  isFetching = true,
  totalCount = 0,
  isError = false,
  getMaster = () => false,
  state = {},
  setState = () => false,
  paginationModel,
  setPaginationModel,
  handleSortModelChange,
  status
}) => {

    const classes = useStyles(useTheme());
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const [createAttribute] = useCreateAttributeMutation();

    const [deleteUoM, dUomData] = useDeleteUoMMutation();
    const [deleteGroup, dGroupData] = useDeleteGroupMutation();
    const [deleteNoun, dNounData] = useDeleteNounMutation();
    const [deleteAttribute, dAttributeData] = useDeleteAttributeMutation();
    const [deleteNounVariant, dNVData] = useDeleteNounVariantMutation();

    const [getAllUoM, uomResponse] = useLazyGetUoMDropDownQuery();
    const [getNouns, nounD] = useLazyGetNounsQuery();

    const nounData = { ...nounD, data: nounD?.data?.data ?? [], currentData: nounD?.data?.data ?? [], totalCount: nounD?.data?.totalCount };

    const showNounListing = React.useMemo(() => [MasterValue.nounVariant, MasterValue.attribute].includes(selectedMaster.value), [selectedMaster.value]);

    const onSearchChange = (newData: string) => {
      setState({ ...state, search: newData });
    };

    const giveMeAddEditComponent = (isEdit: boolean, data: any) => {
      switch (selectedMaster.value) {
        case MasterValue.uom:
          return <AddUpdateUoM isEdit={isEdit} data={data} refetch={getMaster} />

        case MasterValue.group:
          return <AddUpdateGroup isEdit={isEdit} data={data} refetch={getMaster} />

        case MasterValue.noun:
          return <AddUpdateNoun isEdit={isEdit} data={data} refetch={getMaster} />

        case MasterValue.attribute:
          return <AddUpdateAttribute isEdit={isEdit} data={data} noun={state.noun} refetch={getMaster} updateNoun={(noun) => setState((prev: any) => ({...prev, noun}))} />

        case MasterValue.nounVariant:
          return <AddUpdateNounVariant previlages={selectedMaster.previlages} isEdit={isEdit} data={data} noun={state.noun} refetch={getMaster} updateNoun={(noun) => setState((prev: any) => ({ ...prev, noun }))} />

        default:
          return <></>
      }
    };

    const openAddEditDrawer = (isEdit: boolean = false, data: any = null) => {
      dispatch(openDialog({
        title: "",
        hideNegativeBtn: true,
        hidePositiveBtn: true,
        enablePadding: false,
        body: giveMeAddEditComponent(isEdit, data)
      }));
    };

    const giveMeFilerKey = () => {
      switch (selectedMaster.value) {
        case MasterValue.uom:
          return "uom_name";

        case MasterValue.group:
          return "group_name";

        case MasterValue.noun:
          return "noun_name";

        case MasterValue.attribute:
          return "attr_name";

        case MasterValue.nounVariant:
          return "nounvariant_name";

        default:
          return "name";
      }
    };

    const giveMeRows = () => {
      let rows = currentData ?? [];

      if (state.search.length === 0) {
        return rows?.map((_: any) => ({ ..._, id: _._id }))
      } else {
        let filterKey = giveMeFilerKey();
        return rows?.map((_: any) => ({ ..._, id: _._id }))?.filter((_: any) => _[filterKey].toLowerCase().includes(state.search.toLowerCase()))
      }
    };

    const giveMeGridBreakPoints = () => {
      if ([MasterValue.nounVariant, MasterValue.attribute].includes(selectedMaster.value)) {
        return { sm: 8, md: 8, lg: 8, xl: 8 }
      } else {
        return { sm: 12, md: 12, lg: 12, xl: 12 }
      }
    };

    const deleteRow = (data: any = null) => {
      let message = `Do you want to delete this "${data.label}" (${selectedMaster.label})?`;
      dispatch(openConfirmationDialog({
        title: "Are you sure?",
        body: <DeletePanel message={message} />,
        positiveBtn: "Delete",
        negativeBtn: "Cancel",
        onOk: () => performRowDel(data, message),
        onNegativeBtn: () => dispatch(closeConfirmationDialog())
      }))
    };

    const performRowDel = async (data: any, message: string) => {
      dispatch(openBackdrop("Deleting " + selectedMaster.label + "...."));
      let res: any = {};
      try {
        switch (selectedMaster.value) {
          case MasterValue.uom:
            res = await deleteUoM({ id: data._id });
            break;

          case MasterValue.group:
            res = await deleteGroup({ id: data._id });
            break;

          case MasterValue.noun:
            res = await deleteNoun({ id: data._id });
            break;

          case MasterValue.attribute:
            res = await deleteAttribute({ id: data._id });
            break;

          case MasterValue.nounVariant:
            res = await deleteNounVariant({ noun_id: state.noun._id, id: data._id });
            break;

          default:
            break;
        }

        if (Object.keys(res).includes("data")) {
          enqueueSnackbar(`Deleted ${data.label} (${selectedMaster.label}) Successfully!`, { variant: "success" })
          dispatch(closeConfirmationDialog());
          getMaster({id: state.noun?._id});
        } else if (Object.keys(res).includes("error")) {
          // eslint-disable-next-line 
          throw "Unable to delete UoM"
        } else {
          // eslint-disable-next-line 
          throw "Data not found"
        }
      } catch (error) {
        let errorMessage: string = `Unable to delete ${data.label} (${selectedMaster.label})!`;
        enqueueSnackbar(errorMessage, { variant: "error" });
        dispatch(updateCongirmationDialogBody(<DeletePanel message={message} errorMessage={errorMessage} errorData={res?.error?.data?.details} />))
      } finally {
        dispatch(closeBackdrop());
      }
    };

    const duplicate = (data: any) => {
      let message = `Do you want to create a copy of this "${data.label}" (${selectedMaster.label})?`;
      dispatch(openConfirmationDialog({
        title: "Are you sure?",
        body: message,
        positiveBtn: "Create",
        negativeBtn: "Cancel",
        onOk: () => performDuplicate(data, message),
        onNegativeBtn: () => dispatch(closeConfirmationDialog())
      }))
    };

    const performDuplicate = async (data: any, message: string) => {
      dispatch(openBackdrop("Creating copy of " + selectedMaster.label + "...."));
      let res: any = {};
      try {
        res = await createAttribute({
          attr_name: "Copy of - " + data.attr_name,
          nouns: data.nouns,
          options: data.options.map((_: any) => ({ option_name: _.option_name, option_abbr: _.option_abbr }))
        });

        if (Object.keys(res).includes("data")) {
          enqueueSnackbar(`Created copy of ${data.label} (${selectedMaster.label}) Successfully!`, { variant: "success" })
          dispatch(closeConfirmationDialog());
          getMaster({id: state.noun?._id});
        } else if (Object.keys(res).includes("error")) {
          // eslint-disable-next-line 
          throw "Unable to create copy"
        } else {
          // eslint-disable-next-line 
          throw "Data not found"
        }
      } catch (error) {
        let errorMessage: string = `Unable to create copy of ${data.label} (${selectedMaster.label})!`;
        enqueueSnackbar(errorMessage, { variant: "error" });
      } finally {
        dispatch(closeBackdrop());
      }
    };

    const giveMeDropDownValues = () => {
      if (selectedMaster.value === MasterValue.noun) {
        return {
          dropDownLabel: "Regions",
          showDropDown: true,
          dropDownOptions: [{ value: 'all', label: 'All' }, ...(uomResponse?.data ?? [])],
          onDropDownChange: (region: SelectBoxOption) => setState({ ...state, region }),
          dropDownValue: state.region
        }
      }
      return {};
    }

    React.useEffect(() => {
      setState({ ...state, search: "", noun: null });
      // eslint-disable-next-line
    }, [selectedMaster])
    
    React.useEffect(() => {
      if (selectedMaster.value === MasterValue.noun) {
        getAllUoM(null)
      }
      // eslint-disable-next-line
    }, [selectedMaster])

    React.useEffect(() => {
      if (showNounListing) {
        getNouns({ page: null, pageSize: null });
      }
      // eslint-disable-next-line
    }, [selectedMaster?.value])

    const ROWS = giveMeRows();

    const loading = isLoading || isFetching || dUomData.isLoading || dGroupData.isLoading || dNounData.isLoading || dAttributeData.isLoading || dNVData.isLoading;

    return (
      <Box sx={classes.root}>
        {/* Header */}
        <TitleHeader
          title={`${state?.noun?.noun_name ?? ""} ${selectedMaster?.label ?? ""}`}
          count={ROWS?.length ?? 0}
          showSearch={true}
          searchPlaceholder={"Search " + selectedMaster.label}
          onSearchChange={onSearchChange}
          search={state.search}
          showCreateBtn={selectedMaster?.previlages?.includes("C")}
          onCreateBtn={() => openAddEditDrawer(false, null)}
          createBtnLabel={"Create"}
          note={selectedMaster.label === "Noun" ? <Typography variant="caption" sx={{ fontStyle: "italic" }} color="grey">*Note: Some images are AI-generated</Typography> : undefined}
          {...giveMeDropDownValues()}
        />

        {/* Data Table*/}
        <Box sx={classes.dataGridParent}>
          <Grid container>
            {showNounListing && <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
              <NounListing
                region={state.region}
                setRegion={(region: SelectBoxOption) => setState({ ...state, region })}
                nounData={nounData as NounDataInterface}
                showAll={true}
                value={state.noun}
                onChange={(noun: any) => setState({ ...state, noun })}
              />
            </Grid>}
            <Grid item xs={12} {...giveMeGridBreakPoints()} sx={{ paddingTop: { xs: 1, sm: 0 } }}>
              <CustomDataGrid
                saveLocal={false}
                sx={classes.dataGrid}
                rows={ROWS}
                columns={selectedMaster?.column((data: any) => openAddEditDrawer(true, data), deleteRow, duplicate)}
                loading={loading}
                getRowId={row => row._id}
                showToolbar={true}
                rowCount={totalCount}
                paginationModel={paginationModel}
                paginationMode="server"
                onPaginationModelChange={setPaginationModel}
                sortingMode="server"
                onSortModelChange={handleSortModelChange}
              />
            </Grid>
          </Grid>
        </Box>
      </Box>
    );
  };
