import { createSlice } from '@reduxjs/toolkit';
import copy from 'fast-copy';
import { Association, SelectBoxOption } from '../../../interfaces';
import { Attachment } from '../material';

export interface ProposalBasicDetails { proposal_name: string, description: string, type: SelectBoxOption | null, cost_center: string, businessUnit: SelectBoxOption | null, customer: string, proposalManager: string, status: SelectBoxOption | null, reference_number: string, start_date: Date | null | undefined, finish_date: Date | null | undefined }

export interface ProposalTags { tags: SelectBoxOption[] }

interface ProposalBasicDetailsError { proposal_name: boolean, description: boolean, type: boolean, cost_center: boolean, businessUnit: boolean, customer: boolean, proposalManager: boolean, status: boolean, reference_number: boolean, start_date: boolean, finish_date: boolean }

interface ProposalTagsError { tags: boolean }

export interface linksData {
    links?: { object_type: string, object_ids: string[] }[],

    unlinks?: { object_type: string, object_ids: string[] }[],
}
export interface ProposalSliceInterface {
    // Basic Details
    basic_details: ProposalBasicDetails,

    // Proposal Tags
    proposal_tags: ProposalTags,

    // Note
    notes: string,

    // Links
    links_data?: linksData,

    attachments: Attachment[],

    associations: Association[],
    sequenceId?: string,
    version?: number

    error: {
        // Basic Details
        basic_details: ProposalBasicDetailsError,

        // Proposal Tags
        proposal_tags: ProposalTagsError
    }
}

const initialState: ProposalSliceInterface = {
    basic_details: { proposal_name: "", description: "", type: null, cost_center: "", businessUnit: null, customer: "", proposalManager: "", status: null, reference_number: "", start_date: null, finish_date: null },
    proposal_tags: { tags: [] },links_data:{links: [], unlinks:[]}, notes: "",
    attachments: [],
    associations: [],
    error: {
        basic_details: { proposal_name: false, description: false, type: false, cost_center: false, businessUnit: false, customer: false, proposalManager: false, status: false, reference_number: false, start_date: false, finish_date: false },
        proposal_tags: { tags: false }
    }
}

export const proposalSlice = createSlice({
    name: 'proposal',
    initialState,
    reducers: {
        // To Slice by key value pair
        updateProposalSliceByKeyValue(state, { payload }: { payload: { parentKey: string, childKey: string, value: any } }) {
            let newState: any = copy(state);
            newState.error[payload.parentKey][payload.childKey] = payload.value ? false : true;
            newState[payload.parentKey][payload.childKey] = payload.value;
            return { ...state, ...newState }
        },

        // To update errors
        updateErrors(state, { payload }: { payload: { parentKey: keyof ProposalSliceInterface, childKey: keyof ProposalBasicDetails | keyof ProposalTags }[] }) {
            let proposal: ProposalSliceInterface = copy(state);
            let error = proposal.error;
            payload.forEach((key) => {
                if (key.parentKey === 'basic_details') {
                    if (!proposal.basic_details[key.childKey as keyof ProposalBasicDetails]) {
                        error.basic_details[key.childKey as keyof ProposalBasicDetails] = true;
                    }
                }

                if (key.parentKey === 'proposal_tags') {
                    if (key.childKey === 'tags') {
                        if (proposal.proposal_tags[key.childKey as keyof ProposalTags].length === 0) {
                            error.proposal_tags[key.childKey as keyof ProposalTags] = true;
                        }
                    } else {
                        if (!proposal.proposal_tags[key.childKey as keyof ProposalTags]) {
                            error.proposal_tags[key.childKey as keyof ProposalTags] = true;
                        }
                    }
                }

            })

            return { ...state, error }
        },

        // To reset all values to initialValue
        resetProposalSlice(state) {
            return { ...state, ...initialState }
        },

        // To Update notes
        updateNotes(state, { payload }: { payload: { notes: string } }) {
            let newState: any = copy(state);
            newState.notes = payload;
            return { ...state, ...newState };
        },

        // To Bulk Update the slice
        updateProposalSlice(state, { payload }: {
            payload: {
                // Basic Details
                basic_details: ProposalBasicDetails,

                // notes
                notes: string,

                // Proposal Tags
                proposal_tags: ProposalTags,

                links_data: linksData,
                
                attachments: Attachment[],

                associations: Association[],
                sequenceId: string,
                version: number
            }
        }) {
            return { ...state, ...payload }
        }
    }
});

export const { updateProposalSliceByKeyValue, updateProposalSlice, resetProposalSlice, updateErrors, updateNotes } = proposalSlice.actions;
export default proposalSlice.reducer;