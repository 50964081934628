import { Button, Divider, Stack, Typography } from "@mui/material";
import React from "react";
interface IUnassign {
  onCancel: () => void;
  onUnassign: () => void;
}

export const UnAssign: React.FC<IUnassign> = ({ onCancel, onUnassign }) => {

  return (
    <Stack>
      <Typography variant="h6" color="inherit">
        Unassign Tag or Serial Number
      </Typography>
      <Divider variant="fullWidth" sx={{ mb: 2 }} />
      <Stack>
        <Typography variant="body1" color="inherit">
          Are you sure you want to unassign tag or serial number
        </Typography>
        <Stack
          flexDirection={"row"}
          justifyContent={"center"}
          gap={2}
          sx={{ width: "100%" }}
        >
          <Button
            onClick={onCancel}
            sx={{ width: "fit-content", marginTop: "50px !important" }}
            variant="outlined"
          >
            Cancel
          </Button>
          <Button
            onClick={onUnassign}
            sx={{ width: "fit-content", marginTop: "50px !important" }}
            variant="contained"
          >
            Unassign
          </Button>
        </Stack>
      </Stack>
    </Stack>
  );
};
