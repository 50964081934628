import React from "react";
import { Box, Grid, Button, Stack, Tooltip, Badge, Menu, MenuItem } from "@mui/material";
import { SearchFilter, HighlightSelectBox } from "../../components";
import { SelectBoxOption } from "../../interfaces";
import { FilterAltOutlined } from "@mui/icons-material";

interface Linking {
  openLinkingWindow: () => void;
  linkingBtnLabel: string;
}

export interface TitleHeaderProps {
  title?: string;
  count?: string | number;
  showSearch?: boolean;
  onSearchChange?: any;
  searchPlaceholder?: string;
  search?: string;
  showCreateBtn?: boolean;
  createBtnLabel?: string;
  onCreateBtn?: any;
  disableCreateBtn?: boolean;
  showDropDown?: boolean;
  dropDownOptions?: SelectBoxOption[];
  onDropDownChange?: any;
  dropDownValue?: SelectBoxOption | null;
  dropDownLabel?: string;
  showFilter?: boolean;
  filterOptions?: SelectBoxOption[] | undefined;
  onFilterChange?: any;
  filterValue?: SelectBoxOption | null;
  filterLabel?: string;
  showHelperBtn?: boolean;
  onHelperBtn?: any;
  disableHelperBtn?: boolean;
  helperBtnLabel?: string;
  showMoreFilter?: boolean;
  onMoreFilterClick?: any;
  moreFilterApplied?: boolean;
  showFilterSelect?: boolean;
  linkingOption?: Linking;
  note?: JSX.Element;
  menuOptions?: SelectBoxOption[];
}

export const TitleHeader: React.FC<TitleHeaderProps> = ({
  title = "Title",
  count = 0,
  showSearch = true,
  onSearchChange = () => false,
  search = "",
  searchPlaceholder = "",
  showCreateBtn = true,
  createBtnLabel = "Create",
  onCreateBtn,
  disableCreateBtn = false,
  showFilter = false,
  filterOptions = [],
  onFilterChange = () => false,
  filterValue = null,
  filterLabel = "",
  showDropDown = false,
  dropDownOptions = [],
  onDropDownChange = () => false,
  dropDownValue = null,
  dropDownLabel = "",
  showHelperBtn = false,
  onHelperBtn = () => false,
  disableHelperBtn = false,
  helperBtnLabel = "Helper",
  showMoreFilter = false,
  onMoreFilterClick = () => false,
  moreFilterApplied = false,
  showFilterSelect = true,
  linkingOption,
  note,
  menuOptions
}) => {
  const [menuEl, setMenuEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(menuEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setMenuEl(event.currentTarget);
  };

  const handleClose = () => {
    setMenuEl(null);
  };

  const giveMeMediaQuery = (
    component: "Dropdown" | "Search" | "Create" | "Helper" | "filter" | "linking" | "Note"
  ): { sm: number; md: number; lg: number; xl: number } => {
    let mediaQuery = { sm: 6, md: 6, lg: 6, xl: 6 };
    let totalItems: number = [
      showFilter,
      showDropDown,
      showSearch,
      showCreateBtn,
      showHelperBtn,
      linkingOption,
      note
    ].filter((_) => _).length;
    switch (component) {
      case "Search":
      case "Note":
        mediaQuery.sm = 12 / totalItems;
        mediaQuery.md = 12 / totalItems;
        mediaQuery.lg = 12 / totalItems;
        mediaQuery.xl = 4;
        break;

      default:
        mediaQuery.sm = 3;
        mediaQuery.md = 3;
        mediaQuery.lg = 3;
        mediaQuery.xl = 2;
        break;
    }
    return mediaQuery;
  };

  return (
    <Box>
      <Grid
        container
        direction={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
        spacing={1}
      >
        <Grid
          container
          item
          xs={12}
          spacing={1}
          sm={12}
          md={12}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
          }}
        >
          {showFilter && (
            <Grid item xs={12} {...giveMeMediaQuery("filter")}>
              <Stack direction={"row"} spacing={1} alignItems={"flex-end"}>
                {showMoreFilter && (
                  <Box width={"100%"} display={'flex'} alignItems={showFilterSelect ? "stretch" : "flex-end"} justifyContent={"end"}>
                    <Tooltip
                      title={`Advance Filter ${moreFilterApplied ? "Applied" : ""
                        }`}
                    >
                      <Badge
                        color="warning"
                        variant="dot"
                        invisible={!moreFilterApplied}
                      >
                        <Button
                          disableRipple
                          variant="outlined"
                          color="warning"
                          sx={{ minWidth: "8px", width: "100%" }}
                          onClick={onMoreFilterClick}
                        >
                          <FilterAltOutlined />
                        </Button>
                      </Badge>
                    </Tooltip>
                  </Box>
                )}
                {showFilterSelect && (
                  <HighlightSelectBox
                    fullWdith
                    label={filterLabel}
                    margin={"none"}
                    value={filterValue}
                    options={filterOptions}
                    onChange={(options: SelectBoxOption) =>
                      onFilterChange(options)
                    }
                  />
                )}
              </Stack>
            </Grid>
          )}

          {note && (
            <Grid item xs={12} {...giveMeMediaQuery("Note")}>
              {/* Note */}
              {note}
            </Grid>
          )}

          {showDropDown && (
            <Grid item xs={12} {...giveMeMediaQuery("Dropdown")}>
              <HighlightSelectBox
                label={dropDownLabel}
                margin={"none"}
                value={dropDownValue}
                options={dropDownOptions}
                onChange={(options: SelectBoxOption) =>
                  onDropDownChange(options)
                }
              />
            </Grid>
          )}
          {showSearch && (
            <Grid item xs={12} {...giveMeMediaQuery("Search")}>
              <SearchFilter
                searchPlaceholder={searchPlaceholder}
                handleChange={onSearchChange}
                value={search}
              />
            </Grid>
          )}
          {showCreateBtn && (
            <Grid item xs={12} {...giveMeMediaQuery("Create")}>
              <>
                <Button
                  id={"createBtn"}
                  variant={"contained"}
                  color={"primary"}
                  fullWidth
                  onClick={(e) => {
                    if (menuOptions) {
                      handleClick(e);
                    } else {
                      onCreateBtn();
                    }
                  }}
                  disabled={disableCreateBtn}
                  sx={{
                    fontSize: (theme) => theme.spacing(1.75),
                    paddingX: "4px",
                  }}
                >
                  {createBtnLabel}
                </Button>

                {menuOptions && (
                  <Menu
                    id="basic-menu"
                    anchorEl={menuEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                      'aria-labelledby': 'basic-button',
                    }}
                  >
                    {menuOptions.map(menu => (
                      <MenuItem onClick={() => {
                        onCreateBtn(menu.value);
                        handleClose();
                      }}>{menu?.label}</MenuItem>
                    ))}
                  </Menu>
                )}
              </>
            </Grid>
          )}
          {linkingOption && (
            <Grid item xs={12} {...giveMeMediaQuery("linking")}>
              <Button
                variant={"contained"}
                color={"primary"}
                fullWidth
                onClick={linkingOption?.openLinkingWindow}
                sx={{
                  fontSize: (theme) => theme.spacing(1.75),
                  paddingX: "4px",
                }}
              >
                {linkingOption?.linkingBtnLabel}
              </Button>
            </Grid>
          )}
          {showHelperBtn && (
            <Grid item xs={12} {...giveMeMediaQuery("Helper")}>
              <Button
                variant={"contained"}
                color={"primary"}
                fullWidth
                onClick={onHelperBtn}
                disabled={disableHelperBtn}
                sx={{
                  fontSize: (theme) => theme.spacing(1.75),
                  paddingX: "4px",
                }}
              >
                {helperBtnLabel}
              </Button>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};
