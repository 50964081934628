import { createApi, fetchBaseQuery, retry } from '@reduxjs/toolkit/query/react';
import { config } from '../../../config';
import { ConvertToReactSelect, getTransformedDataForPagination, LocalStorageKeys } from '../../../utils';

export const tagsAPI = createApi({
    reducerPath: "tagsAPI",
    baseQuery: retry(fetchBaseQuery({
        baseUrl: config.api_url + "tm/tag",
        prepareHeaders: (headers, { getState }) => {
            const token = localStorage.getItem(LocalStorageKeys.authToken);

            // If we have a token set in state, let's assume that we should be passing it.
            if (token) {
                headers.set('authorization', `Bearer ${token}`)
            }

            return headers
        },
    }), { maxRetries: 2 }),
    tagTypes: ['Tag'],
    endpoints: (builder) => ({
        // Get Tags API
        getTags: builder.query({
            query: ({ status = "", groupId, page = 0, pageSize = 10, sort_by = null, sort_order = null }) => ({
                url: `${status ? `?status=${status}` : ``}${groupId ? `${status ? "&" : "?"}group_id=${groupId}` : ""}${page !== null ? `${status || groupId ? '&' : '?'}page_no=${page + 1}` : ''}${pageSize ? `&page_size=${pageSize}` : ''}${sort_by ? `&sort_by=${sort_by}` : ''}${sort_order ? `&sort_order=${sort_order}` : ''}`,
                method: "GET"
            }),
            transformResponse: (data: any, meta: { request: Request; response?: Response }) => {
                return getTransformedDataForPagination(data, meta?.response);
            }
        }),

        // Get Tags API for dropdown,
        getTagsDD: builder.query({
            query: ({ status = "", groupId }) => ({ url: `?${status ? `status=${status}` : ``}${groupId ? `&group_id=${groupId}` : ""}`, method: "GET" }),
            transformResponse: (response: any[], meta, arg) => ConvertToReactSelect(response, "_id", "tag_name"),
        }),

        // Get Tag by tag id API
        getTagById: builder.query({
            query: ({ id }) => ({ url: `/${id}`, method: "GET" })
        }),

        // Create Tag API
        createTag: builder.mutation({
            query: (payload) => ({ method: "POST", body: payload })
        }),

        // Update Tag API
        updateTag: builder.mutation({
            query: ({ id, payload }) => ({ url: `/${id}`, method: "PUT", body: payload })
        }),

        // Delete Tag API
        deleteTag: builder.mutation({
            query: ({ id }) => ({ url: `/${id}`, method: "DELETE" })
        }),

        // Get Location and Document Tags
        getLocationAndDocumentTags: builder.query({
            async queryFn(_arg, _queryApi, _extraOptions, fetchWithBQ) {
                let result: any = {};
                try {
                    const [{ data: locationTags }, { data: documentTags }]: any = await Promise.all([
                        fetchWithBQ({url: `?canonical_name=folders&sort_by=tag_name&sort_order=asc`, method: "GET"}),
                        fetchWithBQ({ url: `?canonical_name=doc_type&sort_by=tag_name&sort_order=asc`, method: "GET" })
                    ])

                    result = { 
                        data: { 
                            locationId: locationTags?.[0]?.tag_group_id ?? "", 
                            documentId: documentTags?.[0]?.tag_group_id ?? "", 
                            locationTagsData: locationTags, 
                            locationTags: ConvertToReactSelect(locationTags as any[], '_id', 'tag_name'), 
                            documentTags: ConvertToReactSelect(documentTags as any[], '_id', 'tag_name')
                        } 
                     }
                } catch (err) {
                    result = { error: true }
                } finally {
                    return result;
                }
            }
        }),

        // Get Business Unit, Project Category and Project General Tags
        getProjectTags: builder.query({
            async queryFn(_arg, _queryApi, _extraOptions, fetchWithBQ) {
                let result: any = {};
                try {

                    const [
                        { data: businessUnitTags },
                        { data: projCatTags },
                        { data: projGenTags },
                        { data: projGenStatus }
                    ]: any = await Promise.all([
                        fetchWithBQ({ url: `?canonical_name=business_units&sort_by=tag_name&sort_order=asc`, method: "GET" }),
                        fetchWithBQ({ url: `?canonical_name=proj_category&sort_by=tag_name&sort_order=asc`, method: "GET" }),
                        fetchWithBQ({ url: `?canonical_name=proj_general&sort_by=tag_name&sort_order=asc`, method: "GET" }),
                        fetchWithBQ({ url: `?canonical_name=proj_status&sort_by=tag_name&sort_order=asc`, method: "GET" }),
                    ])

                    result = {
                        data: {
                            businessUnitId: businessUnitTags?.[0]?.tag_group_id,
                            projCatId: projCatTags?.[0]?.tag_group_id,
                            projGenTagId: projGenTags?.[0]?.tag_group_id,
                            projStatus: projGenStatus?.[0]?.tag_group_id,
                            businessUnitTags: ConvertToReactSelect(businessUnitTags as any[], '_id', 'tag_name'),
                            projCatTags: ConvertToReactSelect(projCatTags as any[], '_id', 'tag_name'),
                            projGenTags: ConvertToReactSelect(projGenTags as any[], '_id', 'tag_name'),
                            projStatusTags: ConvertToReactSelect(projGenStatus as any[], '_id', 'tag_name'),
                        }
                    }
                } catch (err) {
                    result = { error: true }
                } finally {
                    return result;
                }
            }
        }),

        // Get folder and document tags for link document page
        getLinkDocumentTags: builder.query({
            async queryFn(_arg, _queryApi, _extraOptions, fetchWithBQ) {
                let result: any = {};
                try {

                    const [{ data: folderTags }, { data: documentTypeTags }]: any = await Promise.all([
                        fetchWithBQ({ url: `?canonical_name=folders&sort_by=tag_name&sort_order=asc`, method: "GET" }),
                        fetchWithBQ({ url: `?canonical_name=doc_type&sort_by=tag_name&sort_order=asc`, method: "GET" })
                    ])

                    result = { 
                        data: { 
                            FolderId: folderTags?.[0]?.tag_group_id ?? "", 
                            DocumentTypeId: documentTypeTags?.[0]?.tag_group_id ?? "", 
                            locationTagsData: folderTags, 
                            folderTags: ConvertToReactSelect(folderTags as any[], '_id', 'tag_name'), 
                            documentTypeTags: ConvertToReactSelect(documentTypeTags as any[], '_id', 'tag_name') 
                        } 
                    }
                } catch (err) {
                    result = { error: true }
                } finally {
                    return result;
                }
            }
        }),

        getProposalTags: builder.query({
            async queryFn(_arg, _queryApi, _extraOptions, fetchWithBQ) {
                let result: any = {};
                try {
                    const [
                        { data: propCatTags },
                        { data: propGenStatus }
                    ]: any = await Promise.all([
                        fetchWithBQ({ url: `?canonical_name=prop_category&sort_by=tag_name&sort_order=asc`, method: "GET" }),
                        fetchWithBQ({ url: `?canonical_name=prop_status&sort_by=tag_name&sort_order=asc`, method: "GET" })
                    ])

                    result = {
                        data: {
                            propCatId: propCatTags?.[0]?.tag_group_id,
                            propStatusId: propGenStatus?.[0]?.tag_group_id,
                            propCatTags: ConvertToReactSelect(propCatTags as any[], '_id', 'tag_name'),
                            propGenStatus: ConvertToReactSelect(propGenStatus as any[], '_id', 'tag_name'),
                        }
                    }
                }
                catch (err) {
                    result = { error: true }
                } finally {
                    return result;
                }
            }
        }),

        //Get Issue category, issue status and issue tags
        getIssueTrackerTags: builder.query({
            async queryFn(_arg, _queryApi, _extraOptions, fetchWithBQ) {
                let result: any = {};
                try {
                    const [
                        { data: issueStatusTags },
                        { data: issueCategoryTags },
                        { data: issueTags },
                    ]: any = await Promise.all([
                        fetchWithBQ({ url: `?canonical_name=iss_status&sort_by=tag_name&sort_order=asc`, method: "GET" }),
                        fetchWithBQ({ url: `?canonical_name=iss_category&sort_by=tag_name&sort_order=asc`, method: "GET" }),
                        fetchWithBQ({ url: `?canonical_name=iss_tag&sort_by=tag_name&sort_order=asc`, method: "GET" }),
                    ])

                    result = {
                        data: {
                            IssuestatusId: issueStatusTags?.[0]?.tag_group_id,
                            IssueCategoryId: issueCategoryTags?.[0]?.tag_group_id,
                            IssueTagId: issueTags?.[0]?.tag_group_id,
                            issueStatusTags: ConvertToReactSelect(issueStatusTags as any[], '_id', 'tag_name'),
                            issueCategoryTags: ConvertToReactSelect(issueCategoryTags as any[], '_id', 'tag_name'),
                            issueTags: ConvertToReactSelect(issueTags as any[], '_id', 'tag_name'),
                        }
                    }
                } catch (err) {
                    result = { error: true }
                } finally {
                    return result;
                }
            }
        }),

        // Get Business Unit, Project Category and Project General Tags
        getProductTags: builder.query({
            async queryFn(_arg, _queryApi, _extraOptions, fetchWithBQ) {
                let result: any = {};
                try {
                    const [
                        { data: businessUnitTags },
                        { data: prodGenStatus },
                        { data: prodGenTags }
                    ]: any = await Promise.all([
                        fetchWithBQ({ url: `?canonical_name=business_units&sort_by=tag_name&sort_order=asc`, method: "GET" }),
                        fetchWithBQ({ url: `?canonical_name=prod_status&sort_by=tag_name&sort_order=asc`, method: "GET" }),
                        fetchWithBQ({ url: `?canonical_name=prod_general&sort_by=tag_name&sort_order=asc`, method: "GET" }),
                    ])

                    result = {
                        data: {
                            businessUnitId: businessUnitTags?.[0]?.tag_group_id,
                            prodStatus: prodGenStatus?.[0]?.tag_group_id,
                            prodGenTagId: prodGenTags?.[0]?.tag_group_id,
                            businessUnitTags: ConvertToReactSelect(businessUnitTags as any[], '_id', 'tag_name'),
                            prodStatusTags: ConvertToReactSelect(prodGenStatus as any[], '_id', 'tag_name'),
                            prodGenTags: ConvertToReactSelect(prodGenTags as any[], '_id', 'tag_name'),
                        }
                    }
                } catch (err) {
                    result = { error: true }
                } finally {
                    return result;
                }
            }
        }),

        // Get CheckList tags and CheckList status
        getCheckListTags: builder.query({
            async queryFn(_arg, _queryApi, _extraOptions, fetchWithBQ) {
                let result: any = {};
                try {                  

                    const [
                        { data: checkListStatusTags },
                        { data: checkListTags }
                    ]: any = await Promise.all([
                        fetchWithBQ({ url: `?canonical_name=cl_status&sort_by=tag_name&sort_order=asc`, method: "GET" }),
                        fetchWithBQ({ url: `?canonical_name=cl_general&sort_by=tag_name&sort_order=asc`, method: "GET" })
                    ])

                    result = {
                        data: {
                            CheckListStatusId: checkListStatusTags?.[0]?.tag_group_id,
                            CheckListTagId: checkListTags?.[0]?.tag_group_id,
                            checkListStatusTags: ConvertToReactSelect(checkListStatusTags as any[], "_id", "tag_name"),
                            checkListTags: ConvertToReactSelect(checkListTags as any[], "_id", "tag_name")
                        }
                    }
                } catch (err) {
                    result = { error: true };
                } finally {
                    return result;
                }
            }
        }),

        // Get BOM Spare part category tags
        getBOMSparePartsTags: builder.query({
            async queryFn(_arg, _queryApi, _extraOptions, fetchWithBQ) {
                let result: any = {};
                try {
                    const [
                        { data: spareParts }
                    ]: any = await Promise.all([
                        fetchWithBQ({ url: `?canonical_name=sp_category&sort_by=tag_name&sort_order=asc`, method: "GET" }),
                    ])

                    result = {
                        data: {
                            sparePartTagGroup: spareParts?.[0]?.tag_group_id,
                            spareParts: ConvertToReactSelect(spareParts as any[], "_id", "tag_name")
                        }
                    }
                } catch (err) {
                    result = { error: true };
                } finally {
                    return result;
                }
            }
        }),

        // Get BOM Spare part category tags
        getChildStatusTags: builder.query({
            async queryFn(_arg, _queryApi, _extraOptions, fetchWithBQ) {
                let result: any = {};
                try {                  

                    const [
                        { data: status }
                    ]: any = await Promise.all([
                        fetchWithBQ({ url: `?canonical_name=${_arg.toUpperCase() === 'BOM' ? 'child_bom_status' : 'child_mat_status'}&sort_by=tag_name&sort_order=asc`, method: "GET" }),
                    ])

                    result = {
                        data: {
                            statusTagGroup: status?.[0]?.tag_group_id,
                            childStatus: ConvertToReactSelect(status as any[],"_id","tag_name"),
                        }
                    }
                } catch (err) {
                    result = { error: true };
                } finally {
                    return result;
                }
            }
        }),

        // Get Tags depend on Parameter
        getTagsBasedOnParameter: builder.query({
            async queryFn(_arg, _queryApi, _extraOptions, fetchWithBQ) {
                let result: any = {};
                try {
                    const [
                        { data: tags }
                    ]: any = await Promise.all([
                        fetchWithBQ({ url: `?canonical_name=${_arg}&sort_by=tag_name&sort_order=asc`, method: "GET" }),
                    ])

                    result = {
                        data: {
                            tagGroupId: tags?.[0]?.tag_group_id,
                            tags: ConvertToReactSelect(tags as any[],"_id","tag_name").sort((a, b) => a.label.localeCompare(b.label)),
                        }
                    }
                } catch (err) {
                    result = { error: true };
                } finally {
                    return result;
                }
            }
        }),
    })
});


// Export hooks for usage in functional components
export const {
    useGetTagsQuery, useGetTagsDDQuery, useLazyGetTagsQuery, useGetTagByIdQuery,
    useCreateTagMutation, useUpdateTagMutation, useDeleteTagMutation,
    useGetLocationAndDocumentTagsQuery, useLazyGetLocationAndDocumentTagsQuery,
    useGetProjectTagsQuery, useGetLinkDocumentTagsQuery, useGetProposalTagsQuery,
    useGetProductTagsQuery, useGetIssueTrackerTagsQuery, useLazyGetIssueTrackerTagsQuery,
    useGetCheckListTagsQuery, useLazyGetCheckListTagsQuery, useLazyGetBOMSparePartsTagsQuery,
    useGetBOMSparePartsTagsQuery, useGetChildStatusTagsQuery, useLazyGetChildStatusTagsQuery, 
    useGetTagsBasedOnParameterQuery, useLazyGetTagsBasedOnParameterQuery 
} = tagsAPI
