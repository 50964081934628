import { AlignHorizontalLeft, FilePresent, SmartToy } from "@mui/icons-material";
import { AppRoutes } from "../../router/routes";

export const mandateKeys = [
    { parentKey: "basic_details", childKey: "search_name" },
    { parentKey: "basic_details", childKey: "reason_for_search" }
];

export const NavBars = (id: string, isEdit: boolean) => ([
    {
        id: "1",
        title: "Basic Details",
        icon: <AlignHorizontalLeft fontSize="small" />,
        path: AppRoutes.viewAIAllSearcheBasicDetails(id)
    },
    {
        id: "2",
        title: "Documents",
        disabled: !isEdit,
        icon: <FilePresent fontSize="small" />,
        path: AppRoutes.viewAIAllSearcheDocuments(id)
    },
    {
        id: "3",
        title: "Chat Bot",
        disabled: !isEdit,
        icon: <SmartToy fontSize="small" />,
        path: AppRoutes.viewAIAllSearcheChatBox(id)
    }
])