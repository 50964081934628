import {
    AutoFixHighOutlined,
    Dashboard,
    EngineeringOutlined,
    HomeOutlined,
    Info,
    LocalMallOutlined,
    MuseumOutlined,
    PrecisionManufacturingOutlined,
    SellOutlined,
    ShoppingBasketOutlined, SnippetFolderOutlined,
    Verified,
    VpnKeyRounded
} from '@mui/icons-material';
import { SecondaryNavBars, SelectBoxOption } from "../interfaces";
import { AppRoutes } from "../router/routes";
import { MasterPath } from '../screens/documentTemplates/sequenceNumbering/util';
import { MainModule, PERMISSIONS } from '../router/permission';
import { HasAccess } from '../router/authorization';

/**
 * Object with role as key and value, which is used for 
 * comparison of role in different place.
 */
export const UserRoles = {
    admin: "admin",
    user: "user"
};

/**
 * Object which has the proper name of all the role
 * used in the application.
 */
export let UserRolesName = {
    [UserRoles.admin]: "Admin",
    [UserRoles.user]: "User"
};

/**
 * Object which has the different themes used in 
 * the application.
 */
export let Themes = {
    default: "default",
    dark: "dark",
};

/**
 * Object which has the different props for the Alert Component (/src/component/alert) 
 * which is used via AlertContext (/src/contexts) and provided at /src/App.alert.js.
 */
export let AlertProps = {
    vertical: {
        top: "top",
        bottom: "bottom",
    },
    horizontal: {
        left: "left",
        right: "right",
        center: "center",
    },
    severity: {
        success: "success",
        error: "error",
        warning: "warning",
        info: "info",
    },
};

/**
 * Object which has the different props for the Drawer Component (/src/App.drawer.js) 
 * which is used via DrawerContext (/src/contexts) and provided at /src/App.drawer.js.
 */
export const DrawerProps = {
    direction: {
        top: "top",
        bottom: "bottom",
        left: "left",
        right: "right",
    },
    variant: {
        permanent: "permanent",
        persistent: "persistent",
        temporary: "temporary",
    },
};

/**
 * Object has the key and value pair of all the keys which 
 * are used to store some values in the local storage.
 */
export let LocalStorageKeys = {
    authToken: "auth_token",
    version: "version",
    name: "name",
    nounRecentSearch: "nounRecentSearch",
    template: "template",
    keywordRecentSearch: "keywordRecentSearch",
    snNounRecentSearch: "snNounRecentSearch",
    logoutTimer: "logoutTimer",
    timeout: "timeout",
    locationStatePaths: "paths",
    resizeWidthBOM: "resizeWidthBOM",
    resizeWidthBOMReport: "resizeWidthBOMReport",
    resizeDocumentTreeWidth: "resizeDocumentTreeWidth",
    resizeSideNav: "resizeSideNav",
    redirectURL: "redirectURL"
};

// To build version string
export const VersionFetchURL = `${window.location.protocol}//${window.location.hostname}${window.location.port ? ":" + window.location.port : ''}/meta.json?v=${+new Date()}`;

// Navbars
export const NavBars = (perm: any) => {

    let module_access = Object.keys(perm);

    return [
        {
            id: "1",
            is_active: true,
            title: 'Home',
            path: AppRoutes.home,
            icon: <HomeOutlined fontSize="small" />,
            subNavBar: []
        },
        {
            id: "2",
            is_active: module_access.includes(MainModule.mm),
            title: 'Materials Module',
            routes: [AppRoutes.materialDashboard, AppRoutes.allMaterials, AppRoutes.descriptionGenerator, AppRoutes.descriptionGenerator + "?tab=1", AppRoutes.descriptionGenerator + "?tab=2", AppRoutes.materialRepository],
            icon: <MuseumOutlined fontSize="small" />,
            subNavBar: [
                {
                    id: "2.0",
                    is_active: HasAccess(perm, PERMISSIONS.MATERIAL_DASHBOARD),
                    title: "Dashboard",
                    path: AppRoutes.materialDashboard,
                    subNavBar: []
                },
                {
                    id: "2.1",
                    is_active: HasAccess(perm, PERMISSIONS.MATERIAL_READ),
                    title: "Materials",
                    path: AppRoutes.allMaterials,
                    subNavBar: []
                },
                {
                    id: "2.2",
                    is_active: HasAccess(perm, PERMISSIONS.BOM_READ),
                    title: "BOMs",
                    path: AppRoutes.bom,
                    subNavBar: []
                },
                {
                    id: "2.3",
                    is_active: HasAccess(perm, PERMISSIONS.WORKBOOK_READ),
                    title: "Workbook",
                    path: AppRoutes.materialWorkbook,
                    subNavBar: []
                },
                {
                    id: "2.4",
                    is_active: HasAccess(perm, PERMISSIONS.DG_READ),
                    title: "Description Generator",
                    path: AppRoutes.descriptionGenerator + "?tab=1",
                    subNavBar: []
                },
                {
                    id: "2.5",
                    is_active: HasAccess(perm, PERMISSIONS.MATERIAL_REPO_READ),
                    title: "Repository",
                    path: AppRoutes.materialRepository,
                    subNavBar: []
                }
            ]
        },
        // {
        //     id: "3",
        //     is_active: module_access.includes("prodm"),
        //     title: 'CAD Library',
        //     path: AppRoutes.enquiry,
        //     disabled: true,
        //     icon: <HouseSiding fontSize="small" />,
        //     subNavBar: []
        // },
        {
            id: "4",
            is_active: module_access.includes(MainModule.prodm),
            title: 'Product Module',
            routes: [AppRoutes.productDashboard, AppRoutes.product_vc, AppRoutes.products, AppRoutes.product_repository],
            icon: <LocalMallOutlined fontSize="small" />,
            subNavBar: [
                {
                    id: "4.1",
                    is_active: HasAccess(perm, PERMISSIONS.PRODUCT_DASHBOARD),
                    title: "Dashboard",
                    path: AppRoutes.productDashboard,
                    subNavBar: []
                },
                {
                    id: "4.2",
                    is_active: HasAccess(perm, PERMISSIONS.PRODUCT_READ),
                    title: "Products",
                    path: AppRoutes.products,
                    subNavBar: []
                },
                {
                    id: "4.3",
                    is_active: HasAccess(perm, PERMISSIONS.PRODUCT_VC),
                    title: "Variant Configurator",
                    path: AppRoutes.product_vc,
                    subNavBar: []
                },
                {
                    id: "4.4",
                    is_active: HasAccess(perm, PERMISSIONS.PRODUCT_REPO_READ),
                    title: "Repository",
                    path: AppRoutes.product_repository,
                    subNavBar: []
                },
                // {
                //     id: "4.2",
                //     is_active: module_access.includes("prodm"),
                //     title: "Proposal Configurator",
                //     disabled: true,
                //     path: AppRoutes.pmo_pg,
                //     subNavBar: []
                // }
            ]
        },
        {
            id: "6",
            is_active: module_access.includes(MainModule.propm),
            title: "Proposal Module",
            routes: [AppRoutes.proposals, AppRoutes.proposalsDashboard],
            icon: <AutoFixHighOutlined fontSize="small" />,
            subNavBar: [
                {
                    id: "6.1",
                    is_active: HasAccess(perm, PERMISSIONS.PROPOSALS_DASHBOARD),
                    title: "Dashboard",
                    path: AppRoutes.proposalsDashboard,
                    subNavBar: []
                },
                {
                    id: "6.2",
                    is_active: HasAccess(perm, PERMISSIONS.PROPOSAL_READ),
                    title: "Proposals",
                    path: AppRoutes.proposals,
                    subNavBar: []
                },
            ]
        },
        {
            id: "7",
            is_active: module_access.includes(MainModule.projm),
            title: "Project Module",
            routes: [AppRoutes.projects, AppRoutes.projectDashboard],
            icon: <EngineeringOutlined fontSize="small" />,
            subNavBar: [
                {
                    id: "7.1",
                    is_active: HasAccess(perm, PERMISSIONS.PROJECT_DASHBOARD),
                    title: "Dashboard",
                    path: AppRoutes.projectDashboard,
                    subNavBar: []
                },
                {
                    id: "7.1",
                    is_active: HasAccess(perm, PERMISSIONS.PROJECT_READ),
                    title: "Projects",
                    path: AppRoutes.projects,
                    subNavBar: []
                },
            ]
        },
        {
            id: "8",
            is_active: module_access.includes(MainModule.dm),
            title: "Document Module",
            routes: [AppRoutes.cutSheetBuilder, AppRoutes.documents, AppRoutes.bomReport, AppRoutes.viewAllDrawioDiagrams],
            icon: <SnippetFolderOutlined fontSize="small" />,
            subNavBar: [
                {
                    id: "8.1",
                    is_active: true,
                    title: "Dashboard",
                    path: AppRoutes.docDashboard,
                    subNavBar: []
                },
                {
                    id: "8.2",
                    is_active: true,
                    title: "Documents",
                    path: AppRoutes.documents,
                    subNavBar: []
                },
                {
                    id: "8.3",
                    is_active: false,
                    title: "BOM Report",
                    path: AppRoutes.bomReport,
                    disabled: false,
                    subNavBar: []
                },
                {
                    id: "8.4",
                    is_active: false, 
                    title: "IEDRAW",
                    path: AppRoutes.viewAllDrawioDiagrams,
                    disabled: false,
                    isBeta: true,
                    subNavBar: []
                },
                {
                    id: "8.5",
                    is_active: false,
                    title: "IEDRAW Templates",
                    path: AppRoutes.viewAllDiagramTemplates,
                    disabled: false,
                    isBeta: true,
                    subNavBar: []
                },
                // {
                //     id: "8.3",
                //     is_active: true,
                //     title: "Document Builder",
                //     path: AppRoutes.suppliersList,
                //     disabled: true,
                //     subNavBar: []
                // },
                // {
                //     id: "8.4",
                //     is_active: true,
                //     title: "Cutsheet Builder",
                //     path: AppRoutes.cutSheetBuilder,
                //     disabled: false,
                //     subNavBar: []
                // },
                // {
                //     id: "8.5",
                //     is_active: true,
                //     title: "Worksheet",
                //     path: AppRoutes.suppliersList,
                //     disabled: true,
                //     subNavBar: []
                // },
            ]
        },
        {
            id: "9",
            is_active: false,
            title: "Quality Module",
            routes: [AppRoutes.qualityDashboard, AppRoutes.issuesList, AppRoutes.checkList],
            icon: <Verified fontSize="small" />,
            subNavBar: [
                {
                    id: "9.1",
                    is_active: true,
                    title: "Dashboard",
                    disabled: true,
                    path: AppRoutes.qualityDashboard,
                    subNavBar: []
                },
                {
                    id: "9.2",
                    is_active: true,
                    title: "Issue Tracker",
                    path: AppRoutes.issuesList,
                    disabled: false,
                    subNavBar: []
                }
            ]
        },
        {
            id: "10",
            is_active: module_access.includes(MainModule.vm),
            title: "Supplier Module",
            routes: [AppRoutes.vendors, AppRoutes.suppliersList, AppRoutes.vendorsDashboard],
            icon: <ShoppingBasketOutlined fontSize="small" />,
            subNavBar: [
                {
                    id: "10.1",
                    is_active: HasAccess(perm, PERMISSIONS.SUPPLIER_READ),
                    title: "Dashboard",
                    disabled: false,
                    path: AppRoutes.vendorsDashboard,
                    subNavBar: []
                },
                {
                    id: "10.2",
                    is_active: HasAccess(perm, PERMISSIONS.SUPPLIER_READ),
                    title: "Suppliers",
                    path: AppRoutes.vendors,
                    subNavBar: []
                },
                {
                    id: "10.3",
                    is_active: HasAccess(perm, PERMISSIONS.SUPPLIER_READ),
                    title: "Approved Supplier List",
                    path: AppRoutes.suppliersList,
                    subNavBar: []
                }
            ]
        },
        // {
        //     id: "10",
        //     is_active: true,
        //     title: "Knowledge Module",
        //     path: AppRoutes.knowledge,
        //     disabled: true,
        //     icon: <School fontSize="small" />,
        //     subNavBar: []
        // },
        {
            id: "11",
            is_active: module_access.includes(MainModule.ai),
            title: "AI Module",
            routes: [AppRoutes.ai, AppRoutes.aiInstantSearch],
            icon: <PrecisionManufacturingOutlined fontSize="small" />,
            subNavBar: [
                {
                    id: "12.1",
                    is_active: HasAccess(perm, PERMISSIONS.AI_SEARCH_READ),
                    title: "Instant Search",
                    path: AppRoutes.aiInstantSearch,
                    subNavBar: []
                },
                // {
                //     id: "12.2",
                //     is_active: true,
                //     title: "Proposal Builder",
                //     disabled: true,
                //     path: AppRoutes.userManagement,
                //     subNavBar: []
                // },
                // {
                //     id: "12.3",
                //     is_active: true,
                //     title: "BOM Builder",
                //     disabled: true,
                //     path: AppRoutes.userManagement,
                //     subNavBar: []
                // },
                // {
                //     id: "12.4",
                //     is_active: true,
                //     title: "Manual Builder",
                //     disabled: true,
                //     path: AppRoutes.userManagement,
                //     subNavBar: []
                // },
                // {
                //     id: "12.5",
                //     is_active: true,
                //     title: "PID Builder",
                //     disabled: true,
                //     path: AppRoutes.userManagement,
                //     subNavBar: []
                // },
                // {
                //     id: "12.6",
                //     is_active: true,
                //     title: "BOT ME",
                //     disabled: true,
                //     path: AppRoutes.userManagement,
                //     subNavBar: []
                // },
                // {
                //     id: "12.7",
                //     is_active: true,
                //     title: "BOT EE",
                //     disabled: true,
                //     path: AppRoutes.userManagement,
                //     subNavBar: []
                // },
            ]
        },
        {
            id: "13",
            is_active: module_access.includes(MainModule.tm),
            title: "Tags & Template Module",
            routes: [AppRoutes.tags, AppRoutes.tagGroups, AppRoutes.viewAllDiagramTemplates, AppRoutes.viewTemplateList, AppRoutes.sequenceNumbering()],
            icon: <SellOutlined fontSize="small" />,
            subNavBar: [
                {
                    id: "13.1",
                    is_active: HasAccess(perm, PERMISSIONS.TAG_CATEGORY_READ),
                    title: "Tag Categories",
                    path: AppRoutes.tagGroups,
                    subNavBar: []
                },
                {
                    id: "13.2",
                    is_active: HasAccess(perm, PERMISSIONS.TAG_READ),
                    title: "Tags",
                    path: AppRoutes.tags,
                    subNavBar: []
                },
                {
                    id: "13.3",
                    is_active: HasAccess(perm, PERMISSIONS.DOCUMENT_TEMPLATE_READ),
                    title: "Document Templates",
                    path: AppRoutes.viewTemplateList,
                    subNavBar: []
                },
                {
                    id: "13.4",
                    is_active: HasAccess(perm, PERMISSIONS.SEQUENCE_NO_TEMPLATE_READ),
                    title: "Sequence Numbering (SN)",
                    path: AppRoutes.sequenceNumbering(MasterPath.category),
                    subNavBar: []
                },
                {
                    id: "13.5",
                    is_active: HasAccess(perm, PERMISSIONS.IEDRAW_TEMPLATE_READ),
                    title: "IEDRAW Templates",
                    path: AppRoutes.viewAllDiagramTemplates,
                    disabled: false,
                    isBeta: true,
                    subNavBar: []
                },
            ]
        },
        {
            id: "12",
            is_active: module_access.includes(MainModule.um),
            title: "User Module",
            routes: [AppRoutes.user, AppRoutes.userManagement, AppRoutes.licenseManagement],
            icon: < VpnKeyRounded fontSize="small" />,
            subNavBar: [
                {
                    id: "12.1",
                    is_active: HasAccess(perm, PERMISSIONS.USER_MODULE_MEM_READ),
                    title: "User Management",
                    path: AppRoutes.userManagement,
                    subNavBar: []
                },
                {
                    id: "12.2",
                    is_active: true,
                    title: "License Management",
                    path: AppRoutes.licenseManagement,
                    subNavBar: [],
                    disabled: true
                }
            ]
        },
        {
            id: "14",
            is_active: true,
            title: "Help & Support",
            routes: [AppRoutes.tickets],
            icon: <Info fontSize="small" />,
            subNavBar: [
                {
                    id: "14.1",
                    is_active: true,
                    disabled: true,
                    title: "My Tickets",
                    path: AppRoutes.tickets,
                    subNavBar: []
                },
                {
                    id: "14.2",
                    is_active: true,
                    title: "How to?",
                    path: AppRoutes.howTo,
                    subNavBar: [],
                    disabled: true
                }
            ]
        },
    ]
}

export const TagsModuleNavBars = (tagGroups: any): SecondaryNavBars[] => {

    let navBars = [
        {
            id: "all",
            title: "All Tags",
            icon: null,
            path: AppRoutes.tags + "?group=all"
        }
    ]

    if (tagGroups) {
        navBars = [...navBars, ...tagGroups?.map((group: any, index: number) => ({
            id: group._id,
            title: group.tag_group_name,
            icon: null,
            path: `${AppRoutes.tags}?group=${group._id}&name=${group.tag_group_name}`
        }
        ))
        ]
    }

    return navBars;
}

export const TagGroupsModuleNavBars: SecondaryNavBars[] = [
    {
        id: "1",
        title: "Dashboard",
        icon: <Dashboard fontSize="small" />,
        path: AppRoutes.tagGroups
    }
]

export const HorizontalPosition: SelectBoxOption[] = [
    { label: "Left", value: "left" }, { label: "Right", value: "right" }, { label: "Center", value: "center" }
]

export const VerticalPosition: SelectBoxOption[] = [
    { label: "Top", value: "top" }, { label: "Bottom", value: "bottom" }, { label: "Middle", value: "middle" }
]

export const Dimensions: SelectBoxOption[] = [
    { label: "Full", value: "full" }, { label: "Half", value: "half" }
]

export const paginationFormatOptions = [
    "1 of N",
    "1/N",
    "1"
] as const