import { LoadingButton } from "@mui/lab";
import { Alert, Box, Button, Grid, Stack, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import React from "react";
import { useNavigate } from "react-router-dom";
import { HighlightSelectBox, ImageUploadList, MyPaper, TextField } from "../../../components";
import { SelectBoxOption } from "../../../interfaces";
import { useAppDispatch, useAppSelector } from "../../../redux";
import { useCreateProductMutation, useGetProductTagsQuery, useUpdateProductMutation, useLazyGetProductNounsMappingDDQuery, useGetProductFamilyDropDownQuery, useGetProductGroupsDDQuery, useUploadAttachmentMutation, useUploadProductImageMutation, useDeleteAttachmentMutation, useUpdateS3AttachmentMutation } from "../../../redux/services";
import { closeBackdrop, openBackdrop } from "../../../redux/slices/backdrop";
import { closeConfirmationDialog, openConfirmationDialog } from "../../../redux/slices/confirmationDialog";
import { closeDialog } from "../../../redux/slices/dialog";
import { Attachment } from "../../../redux/slices/material";
import { ProductBasicDetails, ProductSliceInterface, ProductTags, resetProductSlice, updateErrors, updateProductSliceByKeyValue } from "../../../redux/slices/product";
import { AppRoutes } from "../../../router/routes";
import { Notes } from "../notes";
import { HasAccess } from "../../../router/authorization";
import { PERMISSIONS } from "../../../router/permission";

const mandateKeys: { parentKey: keyof ProductSliceInterface, childKey: keyof ProductBasicDetails | keyof ProductTags }[] = [
    { parentKey: "basic_details", childKey: "product" },
    { parentKey: "basic_details", childKey: "family" },
    { parentKey: "basic_details", childKey: "group" },
    { parentKey: "basic_details", childKey: "productManager" },
    { parentKey: "basic_details", childKey: "description" },
    { parentKey: "basic_details", childKey: "businessUnit" },
    { parentKey: "product_tags", childKey: "tags" },
];

export const BasicDetails: React.FC<{ children?: JSX.Element, productId: string, refetch: any }> = ({ productId = "New", ...props }) => {

    const isEdit = productId !== "New";

    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const { product, notifications: { notifications } } = useAppSelector(store => store);
    const { perm } = useAppSelector(store => store.auth.userDetails);

    const [createProduct, createProductData] = useCreateProductMutation();
    const [updateProduct, updateProductData] = useUpdateProductMutation();
    const familyData = useGetProductFamilyDropDownQuery(null, { refetchOnMountOrArgChange: true, refetchOnFocus: true });
    const groupData = useGetProductGroupsDDQuery(null, { refetchOnMountOrArgChange: true, refetchOnFocus: true });
    const [getProductNoun, productNounData] = useLazyGetProductNounsMappingDDQuery();
    const tagsData = useGetProductTagsQuery(null, { refetchOnMountOrArgChange: true, refetchOnFocus: true });
    const [postImage, postImageData] = useUploadAttachmentMutation();
    const [uploadProductImage, uploadProductImageData] = useUploadProductImageMutation();
    const [updateAttachmentProperties] = useUpdateS3AttachmentMutation();
    const [deleteAttachment, deleteResponse] = useDeleteAttachmentMutation();

    const [uploads, setUploads] = React.useState<(File | Attachment)[]>([]);
    const [primaryImageId, setPrimaryImage] = React.useState<string>("");

    const creatable = React.useMemo(() => HasAccess(perm, PERMISSIONS.PRODUCT_CREATE), [perm]);
    const updatable = React.useMemo(() => HasAccess(perm, PERMISSIONS.PRODUCT_UPDATE), [perm]);

    const isViewOnly = isEdit ? !updatable : !creatable;

    const updateState = (childKey: string, value: any) => {
        dispatch(updateProductSliceByKeyValue({ parentKey: "basic_details", childKey, value }));
    };

    const updateProductImageUploadStatus = async (data: any) => {
        if (data?.length > 0) {
            let uploadedFiles = data?.filter((p: Attachment) => p?.url && p?.status === "notUploaded");
            let newUploads = uploads.filter((u: any) => !u?.file_name);
            if (uploadedFiles.length > 0) {
                let uploadReadyImages: any[] = [];
                uploadedFiles.forEach(async (f: Attachment, idx: number) => {
                    uploadReadyImages.push(
                        uploadProductImage({ data: f, url: f?.url, file: newUploads.find((u: any) => u?.name === f?.file_name) }).unwrap().catch((err: any) => {
                            enqueueSnackbar(err?.data?.title ?? "Oops! Something went wrong, Unable to Product Image to S3", { variant: "error" })
                        }).finally(() => {
                            if (uploadedFiles.length - 1 === idx) {
                                dispatch(closeBackdrop());
                            }
                        })
                    );
                })
                await Promise.all(uploadReadyImages)
            }
        }
    };

    const uploadProductImageHandler = async (id: string, sequence_id: string, version: number) => {
        const images = uploads.filter((u: File | Attachment) => (u as File)?.name || !(u as Attachment)?._id).map((u: File | Attachment) => ({
            _id: id,
            file_name: (u as File)?.name,
            is_primary: primaryImageId === (u as File)?.name
        }));
        if (images.length > 0) {
            enqueueSnackbar('Product Image uploading started...', { variant: "info" });
            await postImage({
                object_id: id,
                object_type: "product",
                payload: images
            }).unwrap().then(async (res: any) => {
                await updateProductImageUploadStatus(res);
            }).catch((err: any) => {
                console.error('err:', err)
                enqueueSnackbar(err?.data?.title ?? 'Product Image uploading failed', { variant: "error" });
            });
        }
        if (primaryImageId) {
            const attachment = uploads.find((u: File | Attachment) => (u as Attachment)?._id === primaryImageId);
            if (!(attachment as Attachment)?.is_primary) {
                dispatch(openBackdrop("Setting a primary image..."))
                await updateAttachmentProperties({
                    object_id: id,
                    object_type: "product",
                    attachment_id: (attachment as Attachment)?._id,
                    is_primary: true
                }).then((res: any) => {
                    if (!res?.title) {
                        props.refetch();
                    }
                }).catch((err: any) => {
                    console.error('is_primary update err:', err)
                    enqueueSnackbar(err?.data?.title ?? "Oops! Something went wrong, Unable to set Primary Image", { variant: "error" })
                }).finally(() => dispatch(closeBackdrop()));
            }
        }
    };

    const isValid = () => {
        let isValid = true;

        for (let index = 0; index < mandateKeys.length; index++) {
            const key = mandateKeys[index];

            if (key.parentKey === 'basic_details') {
                if (!product.basic_details[key.childKey as keyof ProductBasicDetails]) {
                    isValid = false;
                }
            }

            if (key.parentKey === 'product_tags') {
                if (key.childKey === 'tags') {
                    if (product.product_tags[key.childKey as keyof ProductTags].length === 0) {
                        isValid = false;
                    }
                } else {
                    if (!product.product_tags[key.childKey as keyof ProductTags]) {
                        isValid = false;
                    }
                }
            }


            if (!isValid) {
                isValid = false;
                enqueueSnackbar('Please fill mandatory fields (*)', { variant: "warning" });
                dispatch(updateErrors(mandateKeys));
                break;
            }
        }

        return isValid;
    };

    const onCreateUpdateBtnClicked = () => {
        if (isValid()) {
            if (isEdit) {
                dispatch(openConfirmationDialog({
                    title: "Update Operation:",
                    body: "Are you sure want to update?",
                    positiveBtn: "Yes",
                    negativeBtn: "No",
                    onOk: () => addEditProduct(true),
                    onNegativeBtn: () => dispatch(closeConfirmationDialog())
                }));
            } else {
                if (createProductData.isSuccess && postImageData.isError && uploads.length > 0) {
                    uploadProductImageHandler(createProductData?.data?._id ?? "", createProductData?.data?.sequence_id ?? "", createProductData?.data?.version ?? 0);
                } else {
                    addEditProduct(false);
                }
            }
        }
    };

    const addEditProduct = async (update_version: boolean = false) => {
        dispatch(closeConfirmationDialog());
        dispatch(openBackdrop(isEdit ? "Updating product..." : "Creating product..."))
        if (isEdit) {
            await updateProduct({ id: productId, update_version, payload: product }).unwrap().then((res: any) => {
                if (!res?.title) {
                    if ((uploads.length === 0 || uploadProductImageData.isSuccess || uploadProductImageData.isUninitialized)) {
                        enqueueSnackbar('Product Updated Successfully!', { variant: "success" });
                        let newProjectId = res?._id ?? productId;
                        navigate(AppRoutes.viewProductBasicDetail(newProjectId));
                        props.refetch();
                    }

                    if (uploads.length > 0) {
                        uploadProductImageHandler(res?._id ?? "", res?.sequence_id ?? "", res?.version ?? "");
                    }
                }
            }).catch((error: any) => {
                enqueueSnackbar(error?.data?.title ?? 'Oops! Something went wrong unable to update product.', { variant: "error" })
            }).finally(() => {
                dispatch(closeBackdrop());
            });
        } else {
            await createProduct(product).unwrap().then((res: any) => {
                if (!res?.title) {
                    if (uploads.length === 0 || uploadProductImageData.isSuccess || uploadProductImageData.isUninitialized) {
                        enqueueSnackbar('Product Created Successfully!', { variant: "success" });
                        let newProjectId = res?._id ?? "New";
                        updateState("productId", newProjectId);
                        navigate(AppRoutes.viewProductBasicDetail(newProjectId));
                        dispatch(openConfirmationDialog({
                            title: "Product Created",
                            body: `${res?.sequence_id ?? ""} -  is the Product ID, attach relevant documents.`,
                            positiveBtn: "Ok",
                            onOk: () => {
                                dispatch(closeConfirmationDialog())
                                navigate(AppRoutes.viewProductDocuments(newProjectId))
                            },
                            hideNegativeBtn: true
                        }))
                    }

                    if (uploads.length > 0) {
                        uploadProductImageHandler(res?._id ?? "", res?.sequence_id ?? "", res?.version ?? 0);
                    }
                }
            }).catch((error: any) => {
                console.error(`Error while creating the product: ${error}`)
                enqueueSnackbar(error?.data?.title ?? 'Oops! Something went wrong unable to create product.', { variant: "error" })
            }).finally(() => {
                dispatch(closeBackdrop());
            });
        }
    };

    const giveMeButtonName = () => {
        if (createProductData.isError) {
            return `${isEdit ? "Retry Update" : "Retry Create"}`
        }

        if (postImageData?.isError) {
            return `Re-upload Product Image`
        }
        return `${isEdit ? "Update" : "Create"}`;
    };

    const onDelete = (file: Attachment | File, index: number) => {
        if ((file as Attachment)?._id) {
            setUploads((prev) => [...(prev.filter((_, idx) => idx !== index))]);
            deleteAttachment({ object_id: productId ?? "", object_type: "product", attachment_id: (file as Attachment)?._id });
        } else {
            setUploads((prev) => [...(prev.filter((_, idx) => idx !== index))]);
        }
    };

    const onUpload = (file: any) => {
        if (file?.name) {
            if (!primaryImageId) {
                setPrimaryImage(file?.name)
            }
            setUploads([...uploads, file])
        }
    };

    const uploadLoading = [...(postImageData?.data || [])?.map((p: any) => {
        return notifications?.["attachment-status"]?.[p?._id]?.status !== "saved"
    })].includes(true);

    const isLoading = createProductData.isLoading || updateProductData.isLoading;

    const giveMeErrorMessage = () => {
        let message = `Oops! Something went wrong, Unable to ${isEdit ? "Update" : "Create"} Product. Try Again Later!`;
        let errorData: any = isEdit ? updateProductData.error : createProductData.error;
        message = errorData?.data?.title ?? message;
        return message;
    };

    React.useEffect(() => {
        if (product.basic_details.family && product.basic_details.group) {
            getProductNoun({ family_id: product.basic_details.family.value, group_id: product.basic_details.group.value })
        }
        // eslint-disable-next-line
    }, [product.basic_details.family?.value, product.basic_details.group?.value]);

    React.useEffect(() => {
        if (postImageData.isError || postImageData?.error) {
            enqueueSnackbar("Oops! Something went wrong, Unable to upload Product Image", { variant: "error" })
            dispatch(closeBackdrop());
            dispatch(closeDialog());
        }
        // eslint-disable-next-line
    }, [postImageData.status]);

    React.useEffect(() => {
        if (product.attachments?.length > 0) {
            // setUploads(product.attachments ?? []);
            setUploads((ups) => {
                const attachments = product.attachments ?? [];
                const names = product.attachments?.map(attachment => attachment?.file_name);
                const filteredUploads = ups.filter(upload => (upload as File)?.name && !names.includes((upload as File)?.name));
                return [...attachments, ...filteredUploads];
            });
            setPrimaryImage(product.attachments.find((p) => p?.is_primary)?._id || "")
        }
    }, [product?.attachments]);

    React.useEffect(() => {
        if (deleteResponse.isLoading) {
            dispatch(openBackdrop("Deleting product image..."))
            return;
        }

        if (deleteResponse.isError) {
            enqueueSnackbar((deleteResponse?.error as any)?.data ?? "Unable to delete product image.", { variant: "error" })
        }

        if (deleteResponse.isSuccess) {
            enqueueSnackbar("Product image deleted successfully.", { variant: "success" })
            props.refetch();
        }

        dispatch(closeBackdrop());
        // eslint-disable-next-line
    }, [deleteResponse.status]);

    return <Stack spacing={2} mb={2}>
        {/* Basic Details Section */}
        <MyPaper padding={0}>
            <Stack justifyContent={"space-between"} height="inherit">
                <Box>
                    {/* Heading */}
                    <Typography sx={{ px: 2, py: 1, borderBottom: (theme) => `1px solid ${theme.palette.divider}` }}
                        variant="body1"
                        fontFamily={"htrts_medium"}>
                        Basic Details
                    </Typography>

                    <Grid container p={2} spacing={2}>
                        {/* Product Family */}
                        <Grid item xs={12} sm={6}>
                            <HighlightSelectBox
                                id="product_family"
                                label={"Product Family"}
                                margin={"none"}
                                required={true}
                                error={product.error.basic_details.family}
                                helperText={product.error.basic_details.family ? "Please fill Product Family" : ""}
                                value={product.basic_details.family}
                                loading={familyData?.isLoading || familyData?.isFetching}
                                options={familyData?.data ?? []}
                                onChange={(data: SelectBoxOption) => updateState("family", data)}
                                viewOnly={isViewOnly}
                            />
                        </Grid>

                        {/* Product Group */}
                        <Grid item xs={12} sm={6}>
                            <HighlightSelectBox
                                id="product_group"
                                label={"Product Group"}
                                margin={"none"}
                                required={true}
                                error={product.error.basic_details.group}
                                helperText={product.error.basic_details.group ? "Please fill Product Group" : ""}
                                value={product.basic_details.group}
                                loading={groupData?.isLoading || groupData?.isFetching}
                                options={groupData.data ?? []}
                                onChange={(data: SelectBoxOption) => updateState("group", data)}
                                viewOnly={isViewOnly}
                            />
                        </Grid>

                        {/* Product Name */}
                        <Grid item xs={12} sm={6}>
                            <HighlightSelectBox
                                id="product_name"
                                label={"Product Name"}
                                margin={"none"}
                                required={true}
                                error={product.error.basic_details.product}
                                helperText={product.error.basic_details.product ? "Please fill Product Name" : ""}
                                value={product.basic_details.product}
                                loading={productNounData?.isLoading || productNounData?.isFetching}
                                options={productNounData.data ?? []}
                                onChange={(data: SelectBoxOption) => updateState("product", data)}
                                viewOnly={isViewOnly}
                            />
                        </Grid>

                        {/* Reference Number */}
                        <Grid item xs={12} sm={6}>
                            <TextField
                                id="external_ref_id"
                                variant="outlined"
                                size="small"
                                fullWidth
                                required={false}
                                error={product.error.basic_details.external_ref_id}
                                helperText={product.error.basic_details.external_ref_id ? "Please fill Reference Number" : ""}
                                value={product.basic_details.external_ref_id}
                                label={"Reference Number"}
                                onChange={(e) => updateState("external_ref_id", e.target.value)}
                                viewOnly={isViewOnly}
                            />
                        </Grid>

                        {/* Description */}
                        <Grid item xs={12} sm={12}>
                            <TextField
                                id="product_description"
                                variant="outlined"
                                size="small"
                                fullWidth
                                required={true}
                                multiline
                                minRows={3}
                                error={product.error.basic_details.description}
                                helperText={product.error.basic_details.description ? "Please fill Description" : ""}
                                value={product.basic_details.description}
                                label={"Description"}
                                onChange={(e) => updateState("description", e.target.value)}
                                viewOnly={isViewOnly}
                            />
                        </Grid>

                        {/* Business Unit */}
                        <Grid item xs={12} sm={6}>
                            <HighlightSelectBox
                                id="product_businessUnit"
                                label={"Business Unit"}
                                margin={"none"}
                                required={true}
                                error={product.error.basic_details.businessUnit}
                                helperText={product.error.basic_details.businessUnit ? "Please fill business unit" : ""}
                                value={product.basic_details.businessUnit}
                                options={tagsData?.data?.businessUnitTags ?? []}
                                loading={tagsData?.isLoading || tagsData?.isFetching}
                                multiple={false}
                                onChange={(data: SelectBoxOption) => updateState("businessUnit", data)}
                                viewOnly={isViewOnly}
                            />
                        </Grid>

                        {/* Product Manager */}
                        <Grid item xs={12} sm={6}>
                            <TextField
                                id="productManager"
                                variant="outlined"
                                size="small"
                                fullWidth
                                required={true}
                                error={product.error.basic_details.productManager}
                                helperText={product.error.basic_details.productManager ? "Please fill Product manager" : ""}
                                value={product.basic_details.productManager}
                                label={"Product Manager"}
                                onChange={(e) => updateState("productManager", e.target.value)}
                                viewOnly={isViewOnly}
                            />
                        </Grid>

                        {/* Status */}
                        <Grid item xs={12} sm={6}>
                            <HighlightSelectBox
                                id="product-status"
                                label={"Status"}
                                margin={"none"}
                                required={false}
                                error={product.error.basic_details.status}
                                helperText={product.error.basic_details.status ? "Please fill Status" : ""}
                                value={product.basic_details.status}
                                options={tagsData?.data?.prodStatusTags ?? []}
                                loading={tagsData?.isLoading || tagsData?.isFetching}
                                onChange={(data: SelectBoxOption) => updateState("status", data)}
                                viewOnly={isViewOnly}
                            />
                        </Grid>
                    </Grid>

                </Box>
            </Stack>
        </MyPaper>

        {/* Upload Image */}
        <ImageUploadList
            title='Upload Product Images'
            disabled={createProductData.isLoading || updateProductData.isLoading}
            loading={uploadLoading}
            files={uploads}
            maxFiles={5}
            onDelete={onDelete}
            onUpload={onUpload}
            primaryConfig={{
                primaryImageId,
                onChangePrimary: (id) => isViewOnly ? false : setPrimaryImage(id)
            }}
            readOnly={isViewOnly}
        />

        {/* Product Tags */}
        <MyPaper padding={0}>
            <Stack justifyContent={"space-between"} height="inherit">
                <Box>
                    {/* Heading */}
                    <Typography sx={{ px: 2, py: 1, borderBottom: (theme) => `1px solid ${theme.palette.divider}` }}
                        variant="body1"
                        fontFamily={"htrts_medium"}>
                        Product Tags
                    </Typography>

                    <Grid container p={2} spacing={2}>
                        {/* Tags */}
                        <Grid item xs={12} sm={6}>
                            <HighlightSelectBox
                                id="product_tags"
                                label={"Tags"}
                                margin={"none"}
                                required={true}
                                error={product.error.product_tags.tags}
                                helperText={product.error.product_tags.tags ? "Please fill Tags" : ""}
                                value={product.product_tags.tags}
                                options={tagsData?.data?.prodGenTags ?? []}
                                loading={tagsData?.isLoading || tagsData?.isFetching}
                                multiple={true}
                                onChange={(data: SelectBoxOption[]) => dispatch(updateProductSliceByKeyValue({ parentKey: "product_tags", childKey: "tags", value: data }))}
                                viewOnly={isViewOnly}
                            />
                        </Grid>
                    </Grid>

                </Box>
            </Stack>
        </MyPaper>

        {/* Notes Section */}
        <Notes viewOnly={isViewOnly} />

        {/* Product Images */}
        {/* <ProductImages /> */}

        {/* Footer */}
        {!isViewOnly && <MyPaper>
            <Box>
                {/* Alert Box */}
                {(createProductData.isError || updateProductData.isError) && <Alert sx={{ mt: 2 }} severity="error">{
                    giveMeErrorMessage()
                }</Alert>}

                {/* Footer Buttons */}
                <Stack direction={"row"} alignItems={"center"} justifyContent={"center"} spacing={1} p={1}>
                    {/* Clear All Button */}
                    <Button disabled={isLoading} variant="outlined" sx={{ width: 200 }} color="primary" onClick={() => dispatch(resetProductSlice())} >
                        Clear All
                    </Button>

                    {/* Create Button */}
                    <LoadingButton id="u-c-btn" loading={isLoading} variant="contained" sx={{ width: 200 }} color="primary" onClick={() => onCreateUpdateBtnClicked()}>
                        {giveMeButtonName()}
                    </LoadingButton>

                    {/* Cancel Button */}
                    <Button disabled={isLoading} variant="outlined" sx={{ width: 200 }} color="primary" onClick={() => navigate(AppRoutes.projectDashboard)} >
                        Cancel
                    </Button>
                </Stack>
            </Box>
        </MyPaper>}
    </Stack>
}