import { Delete } from "@mui/icons-material";
import { Box, Button, Grid, IconButton, InputBase, Table, TableBody, TableCell, TableHead, TableRow, TextField } from "@mui/material";
import React from "react";
import { useAppDispatch } from "../../../redux";
import { addSelectionOption, deleteSelectionOption, SelectionInterface, updateSelectionDetail, updateSelectionOptionDetail } from "../../../redux/slices/pmp";

const OptionTableHeader = [{ title: "Description", width: "40%" }, { title: "Abbrevation", width: "20%" }, { title: "Info", width: "30%" }, { title: "Action", width: "10%" }]

export const SelectionInfo: React.FC<{ children?: JSX.Element, index: number, data: SelectionInterface }> = ({ data, index }) => {

    const dispatch = useAppDispatch();

    return <Box pt={1}>
        <Grid container spacing={2} alignItems={"center"}>
            {/* Selection Basic Details */}
            {/* Selection Name */}
            <Grid item xs={12} sm={3}>
                <TextField
                    fullWidth
                    size={"small"}
                    label={"Name"}
                    value={data.name}
                    onChange={(e) => dispatch(updateSelectionDetail({ index, ...data, name: e.target.value }))}
                />
            </Grid>

            {/* Selection Notes */}
            <Grid item xs={12} sm={7}>
                <TextField
                    fullWidth
                    size={"small"}
                    label={"Notes"}
                    value={data.notes}
                    onChange={(e) => dispatch(updateSelectionDetail({ index, ...data, notes: e.target.value }))}
                />
            </Grid>

            {/* Add Option Button */}
            <Grid item xs={12} sm={2}>
                <Button
                    size={"medium"}
                    variant={"outlined"}
                    fullWidth
                    onClick={(e) => dispatch(addSelectionOption({ index }))}
                >
                    Add Option
                </Button>
            </Grid>
        </Grid>

        {/*Selection  Option Table */}
        <Box sx={{ width: "100%", overflow: "scroll" }}>
            <Table size="small" sx={{
                border: (theme) => `1px solid ${theme.palette.divider}`,
                marginTop: 2,
                minWidth: { xs: "768px", sm: '' }
            }}>

                {/* Heading */}
                <TableHead sx={{ background: (theme) => theme.palette.grey[50] }}>
                    <TableRow>
                        {
                            OptionTableHeader.map((head, key) => <TableCell
                                key={key}
                                sx={{ borderRight: (theme) => `1px solid ${theme.palette.divider}` }}
                                width={head.width}>
                                {head.title}
                            </TableCell>)
                        }
                    </TableRow>
                </TableHead>

                {/* Option Row */}
                <TableBody>
                    {data.options.map((opt, key) => {
                        return <TableRow key={key}>
                            {/* Description */}
                            <TableCell sx={{ borderRight: (theme) => `1px solid ${theme.palette.divider}` }}>
                                <InputBase
                                    placeholder={`Description ${key + 1}`}
                                    value={opt.desc}
                                    fullWidth
                                    onChange={(e) => dispatch(updateSelectionOptionDetail({ selIndex: index, optIndex: key, ...opt, desc: e.target.value }))}
                                />
                            </TableCell>

                            {/* Abbrevation */}
                            <TableCell sx={{ borderRight: (theme) => `1px solid ${theme.palette.divider}` }}>
                                <InputBase
                                    placeholder={`Abbrevation ${key + 1}`}
                                    value={opt.abbr}
                                    fullWidth
                                    onChange={(e) => dispatch(updateSelectionOptionDetail({ selIndex: index, optIndex: key, ...opt, abbr: e.target.value }))}
                                />
                            </TableCell>

                            {/* Info */}
                            <TableCell sx={{ borderRight: (theme) => `1px solid ${theme.palette.divider}` }}>
                                <InputBase
                                    placeholder={`Info ${key + 1}`}
                                    value={opt.info}
                                    fullWidth
                                    inputProps={{ maxLength: 20 }}
                                    onChange={(e) => dispatch(updateSelectionOptionDetail({ selIndex: index, optIndex: key, ...opt, info: e.target.value }))}
                                />
                            </TableCell>

                            {/* Delete */}
                            <TableCell>
                                <IconButton onClick={(e) => dispatch(deleteSelectionOption({ selIndex: index, optIndex: key }))}>
                                    <Delete />
                                </IconButton>
                            </TableCell>
                        </TableRow>
                    })}
                </TableBody>
            </Table>
        </Box>
    </Box>
}