import {
  createApi,
  fetchBaseQuery,
  retry,
} from "@reduxjs/toolkit/dist/query/react";
import { config } from "../../../config";
import { LocalStorageKeys } from "../../../utils";

export interface BOMChildren {
  object_id: string;
  object_type: string;
  serial_num?: string;
  child_id: string;
}

export type AssignmentStatus = "assign" | "unassign";

export const assignmentAPI = createApi({
  reducerPath: "assignmentAPI",
  baseQuery: retry(
    fetchBaseQuery({
      baseUrl: config.api_url + "tm/sn/assignment",
      prepareHeaders(headers) {
        const token = localStorage.getItem(LocalStorageKeys.authToken);

        // If we have a token set in state, let's assume that we should be passing it.
        if (token) {
          headers.set("authorization", `Bearer ${token}`);
        }

        return headers;
      },
    }),
    { maxRetries: 0 }
  ),
  tagTypes: ["Assignment"],
  endpoints: (builder) => ({
    // Get All Unassigned values for a template
    getUnAssignmentValuesByTemplate: builder.query({
      query: ({ id, status = "unassign" }) => ({
        url: `?status=${status}${id ? `&template_id=${id}` : ""}`,
        method: "GET",
      }),
      providesTags: ["Assignment"],
    }),
    // Update Assignment API
    updateAssignment: builder.mutation({
      query: ({ id, status = "unassign", count, payload, manual_serial_num = false }) => ({
        url: `?status=${status}&manual_serial_num=${manual_serial_num}${count ? `&count=${count}` : ""}${
          id ? `&template_id=${id}` : ""
        }`,
        method: "PUT",
        body: payload,
      }),
      invalidatesTags: ["Assignment"],
    }),
  }),
});

// Export hooks for usage in functional components
export const {
  useGetUnAssignmentValuesByTemplateQuery,
  useLazyGetUnAssignmentValuesByTemplateQuery,
  useUpdateAssignmentMutation,
} = assignmentAPI;
