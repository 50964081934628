import { createSlice } from '@reduxjs/toolkit';
import { randomNameGenerator } from '../../../utils';

export interface PMPVariantValueInterface { id: string | number, name: string, options: { [key: string | number]: { selection: SelectionInterface, option: SelectionInterfaceOptions } } }

export interface PMPVariantInterface { [key: string | number]: PMPVariantValueInterface[] }

export interface SelectionInterfaceOptionMapping { [key: string | number]: { selection: SelectionInterface, options: { [key: string | number]: SelectionInterfaceOptions } } }

export interface SelectionInterfaceOptions { id: number, desc: string, abbr: string, info: string, applicableFor: string, optMapping: SelectionInterfaceOptionMapping }

export interface SelectionInterface { id: number, name: string, notes: string, options: SelectionInterfaceOptions[] }

export interface StateInterface { tab: number, name: string, abbr: string, selections: SelectionInterface[], variants: PMPVariantInterface }

const initialSelectionOption: SelectionInterfaceOptions = { id: 1, desc: "", abbr: "", info: "", applicableFor: "all", optMapping: {} };

const initialSelection: SelectionInterface = { id: 1, name: "", notes: "", options: [{ ...initialSelectionOption }] }

const initialState: StateInterface = { tab: 1, name: randomNameGenerator(12), abbr: "", selections: [{ ...initialSelection }], variants: {} }

const pmpSlice = createSlice({
    name: 'pmp',
    initialState,
    reducers: {
        // To Update Abbrevation Name
        updateAbbrevation(state, { payload }) { state.abbr = payload.trim().length ? payload.toUpperCase() : "" },

        // To Update Variants
        updateVariants(state, { payload }) { state.variants = payload },

        // To Add Empty Selection
        addSelection(state, { payload }) { state.selections = [...state.selections, { ...initialSelection, id: state.selections.length + 1 }] },

        // To Delete a Selection
        deleteSelection(state, { payload }) {
            let { index } = payload;
            if (index) {
                let newSelection = state.selections;
                newSelection.splice(index, 1);
                state.selections = [...newSelection];
            }
        },

        // To Update Selection Name
        updateSelectionDetail(state, { payload }) {
            let { index, name, notes } = payload;
            if (index !== undefined) {
                let newSelection = state.selections;
                newSelection[index].name = name;
                newSelection[index].notes = notes;
                state.selections = [...newSelection];
            }
        },

        // To Add Selection Option
        addSelectionOption(state, { payload }) {
            let { index } = payload;
            if (index !== undefined) {
                let newSelection = state.selections;
                newSelection[index].options.push({ ...initialSelectionOption, id: newSelection[index].options.length + 1 });
                state.selections = [...newSelection];
            }
        },

        // Update Selection Option Detail
        updateSelectionOptionDetail(state, { payload }) {
            let { selIndex, optIndex, desc, abbr, info, applicableFor, optMapping } = payload;
            if (selIndex !== undefined && optIndex !== undefined) {
                let newSelection = state.selections;
                newSelection[selIndex].options[optIndex].desc = desc;
                newSelection[selIndex].options[optIndex].abbr = abbr;
                newSelection[selIndex].options[optIndex].info = info;
                newSelection[selIndex].options[optIndex].applicableFor = applicableFor;
                newSelection[selIndex].options[optIndex].optMapping = optMapping;
                state.selections = [...newSelection];
            }
        },

        // Delete Selection Option
        deleteSelectionOption(state, { payload }) {
            let { selIndex, optIndex } = payload;
            if (selIndex !== undefined && optIndex !== undefined) {
                let newSelection = state.selections;
                newSelection[selIndex].options.splice(optIndex, 1);
                state.selections = [...newSelection];
            }
        },

        // Navigate between tabs
        navigateTab(state, { payload }) { state.tab = payload }
    },
})

export const {
    updateAbbrevation, addSelection, deleteSelection, updateSelectionDetail,
    addSelectionOption, updateSelectionOptionDetail, deleteSelectionOption,
    navigateTab, updateVariants
} = pmpSlice.actions;
export default pmpSlice.reducer;