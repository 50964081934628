import React from "react";
import { TextField, Typography, Button, Grid, Alert, DialogTitle, DialogContent, DialogActions } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useDispatch } from "react-redux";
import { closeDialog } from "../../../../redux/slices/dialog";
import { openBackdrop, closeBackdrop } from "../../../../redux/slices/backdrop";
import { useCreateNounMutation, useLazyGetGroupsQuery, useLazyGetNounByIdQuery, useLazyGetUoMQuery, useUpdateNounMutation, useUploadS3AttachementMutation } from "../../../../redux/services";
import { useSnackbar } from "notistack";
import { HighlightSelectBox, ReferenceCard, ReferenceCardRow, UploadImage } from "../../../../components";
import { SelectBoxOption } from "../../../../interfaces";
import { Attachment } from "../../../../redux/slices/material";
import GreyAccordian from "../../../../components/greyAccordian";
import copy from "fast-copy";

export interface NounState {
    uom: SelectBoxOption,
    name: string,
    abbrevation: string,
    description: string,
    group: SelectBoxOption,
}

export const AddUpdateNoun: React.FC<{ children?: JSX.Element, isEdit: boolean, data: any, refetch: any }> = (props) => {

    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const [createNoun, createNounData] = useCreateNounMutation();
    const [updateNoun, updateNounData] = useUpdateNounMutation();
    const [uploadImage, uploadImageData] = useUploadS3AttachementMutation();
    const [getNoun, getNounData] = useLazyGetNounByIdQuery();
    const [getUoM, { data: allUoMData = { data: [] }, isFetching: uomLoading, isError: uomError }] = useLazyGetUoMQuery();
    const [getGroup, { data: allGroupsData = { data: [] }, isFetching: groupLoading, isError: groupError }] = useLazyGetGroupsQuery();
    const { data: uom } = allUoMData;
    const { data: group } = allGroupsData;

    const [state, setState] = React.useState<NounState>({ uom: { value: "", label: "" }, name: "", abbrevation: "", description: "", group: { value: "", label: "" } });
    const [rows, setRows] = React.useState<ReferenceCardRow[]>([{ id: "", name: "" }]);
    const [upload, setUpload] = React.useState<any>(null);
    const [attachments, setAttachments] = React.useState<Attachment[] | null>(null);
    const [error, setError] = React.useState({ uom: false, name: false, abbrevation: false, description: false, group: false });

    const updateState = (key: string, value: string) => {
        setState({ ...state, [key]: value });
        setError({ ...error, [key]: !value?.length })
    };

    const updateDropDown = (key: string, data: SelectBoxOption) => {
        setState({ ...state, [key]: data });
        setError({ ...error, [key]: !data?.value })
    };

    const onCancelBtnClicked = () => {
        dispatch(closeDialog());
    };

    const validate = () => {
        let isValid = true;

        if (!state.uom?.value) {
            setError((prevError) => ({
                ...prevError,
                uom: true
            }))
            isValid = false;
        }

        if (!state.name.trim()) {
            setError((prevError) => ({
                ...prevError,
                name: true
            }))
            isValid = false;
        }

        if (!state.description.trim()) {
            setError((prevError) => ({
                ...prevError,
                description: true
            }))
            isValid = false;
        }

        if (!state.abbrevation.trim()) {
            setError((prevError) => ({
                ...prevError,
                abbrevation: true
            }))
            isValid = false;
        }

        if (!state.group?.value) {
            setError((prevError) => ({
                ...prevError,
                group: true
            }))
            isValid = false;
        }

        return isValid;
    }

    const onCreateUpdateBtnClicked = () => {
        if (validate()) {
            if (props.isEdit) {
                if (updateNounData.isSuccess && uploadImageData.isError) {
                    uploadTheNounImage(updateNounData?.data?._id ?? "");
                } else {
                    let files = upload ? { attachments: [{ file_name: upload.name, document_type: "display_pic" }] } : attachments ? {} : { attachments: [] }
                    updateNoun({
                        noun_abbr: state.abbrevation,
                        noun_desc: state.description,
                        group_id: state.group.value,
                        noun_name: state.name,
                        uom_id: state.uom.value,
                        _id: props.data.id,
                        reference_metadata: rows,
                        ...files,
                    })
                }
            } else {
                if (createNounData.isSuccess && uploadImageData.isError) {
                    uploadTheNounImage(createNounData?.data?._id ?? "");
                } else {
                    let attachments = upload ? { attachments: [{ file_name: upload.name, document_type: "display_pic" }] } : {}
                    createNoun({
                        noun_abbr: state.abbrevation,
                        noun_desc: state.description,
                        group_id: state.group.value,
                        noun_name: state.name,
                        uom_id: state.uom.value,
                        reference_metadata: rows,
                        ...attachments,
                    });
                }
            }
        }
    };

    const uploadTheNounImage = (id: string) => {
        uploadImage({ file: upload, object_id: id, object_type: "noun" });
    };

    const giveMeButtonName = () => {
        if (createNounData.isError) {
            return `${props.isEdit ? "Retry Update Noun" : "Retry Create Noun"}`
        }

        if (uploadImageData.isError) {
            return `Re-upload Noun Image`
        }
        return `${props.isEdit ? "Update Noun" : "Create Noun"}`;
    };

    const giveMeInfoText = () => {
        if (props.isEdit && updateNounData.isError) {
            return <Alert severity="error">Opps! Something went wrong, Unable to update Noun. Try Again Later!</Alert>
        }
        if (createNounData.isError) {
            return <Alert severity="error">Opps! Something went wrong, Unable to create Noun. Try Again Later!</Alert>
        }
        if (uploadImageData.isError) {
            return <Alert severity="warning">
                {`The Noun is ${props.isEdit ? "Updated" : "Created"} Successfully, only image upload is failed.  You can re-upload the image or upload the image later by click on the below button`}
            </Alert>
        }
    };

    const giveMeURLToShow = () => {
        if (props.isEdit) {
            return getNounData?.data?.attachments?.[getNounData?.data?.attachments.length - 1]?.attachment_url;
        }
        return "";
    };

    React.useEffect(() => {
        if (createNounData.isSuccess && (upload === null || uploadImageData.isSuccess)) {
            enqueueSnackbar("Noun Created Successfully", { variant: "success" });
            props.refetch();
            dispatch(closeDialog());
        }
        if (createNounData.isError) {
            enqueueSnackbar("Opps! Something went wrong, Unable to create Noun", { variant: "error" })
        }

        if (uploadImageData.isError) {
            enqueueSnackbar("Opps! Something went wrong, Unable to upload Noun Image", { variant: "error" })
        }

        if (createNounData.isSuccess && !uploadImageData.isError && !uploadImageData.isLoading && !uploadImageData.isSuccess) {
            uploadTheNounImage(createNounData.data?._id ?? "");
        }
        // eslint-disable-next-line
    }, [createNounData, uploadImageData]);

    React.useEffect(() => {
        if (props.isEdit) {
            if (updateNounData.isSuccess && (upload === null || uploadImageData.isSuccess)) {
                enqueueSnackbar("Noun Updated Successfully", { variant: "success" });
                props.refetch();
                dispatch(closeDialog());
            }
            if (updateNounData.isError) {
                enqueueSnackbar("Opps! Something went wrong, Unable to update Noun", { variant: "error" })
            }
            if (uploadImageData.isError) {
                enqueueSnackbar("Opps! Something went wrong, Unable to upload Noun Image", { variant: "error" })
            }

            if (updateNounData.isSuccess && !uploadImageData.isError && !uploadImageData.isLoading && !uploadImageData.isSuccess) {
                uploadTheNounImage(props.data?._id ?? "");
            }
        }
        // eslint-disable-next-line
    }, [updateNounData, uploadImageData]);

    React.useEffect(() => {
        const prepareNoun = async () => {
            dispatch(openBackdrop("Fetching..."));
            const dataCalls = [
                getUoM({ page: null, pageSize: null }),
                getGroup({ page: null, pageSize: null })
            ];

            if (props.isEdit) {
                dataCalls.push(getNoun({ id: props.data.id }));
            }

            const res: any = await Promise.all(dataCalls);

            if (props.isEdit) {
                let selectedUoM = res?.[0]?.data?.data?.filter((_: any) => props.data.uom_id === _._id)?.[0];
                let selectedGroup = res?.[1]?.data?.data?.filter((_: any) => props.data.group_id === _._id)?.[0];

                setState({
                    ...state,
                    name: props.data.noun_name, description: props.data.noun_desc, abbrevation: props.data.noun_abbr,
                    uom: { value: selectedUoM?._id, label: selectedUoM?.uom_name, ...selectedUoM },
                    group: { value: selectedGroup?._id, label: selectedGroup?.group_name, ...selectedGroup },
                });
                setAttachments(props.data?.attachments?.length > 0 ? props.data?.attachments : null);
                setRows(props.data?.reference_metadata ?? [{ id: "", name: "" }]);
            }
            dispatch(closeBackdrop());
        }

        prepareNoun();

        // eslint-disable-next-line
    }, [props.isEdit]);

    return <>
        {/* Title */}
        <DialogTitle>
            <Typography
                variant="h6"
                color="textPrimary"
                sx={{
                    borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
                    width: "100%", fontFamily: 'htrts_medium'
                }}
            >
                {`${props.isEdit ? "Update" : "Create"} Noun`}
            </Typography>
        </DialogTitle>

        {/* Fields */}
        <DialogContent>
            <Grid container spacing={2}>
                {/* UoM */}
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                    <HighlightSelectBox
                        id="select-region"
                        size="medium"
                        label='Regions'
                        disabled={createNounData.isSuccess || createNounData.isError}
                        value={state.uom}
                        options={uom?.map((_: any) => ({ value: _._id, label: _.uom_name }))}
                        onChange={(data: SelectBoxOption) => updateDropDown("uom", data)}
                        required={true}
                        loading={uomLoading}
                        error={error.uom || uomError}
                        helperText={error.uom ? "Please select UoM" : uomError ? "Opps! Unable to load UoM" : ""}
                    />
                </Grid>

                {/* Group */}
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                    <HighlightSelectBox
                        id="select-group"
                        size="medium"
                        label='Group'
                        value={state.group}
                        disabled={createNounData.isSuccess || createNounData.isError}
                        options={group?.map((_: any) => ({ value: _._id, label: _.group_name }))}
                        onChange={(data: SelectBoxOption) => updateDropDown("group", data)}
                        required={true}
                        loading={groupLoading}
                        error={error.group || groupError}
                        helperText={error.group ? "Please select group" : groupError ? "Opps! Unable to load group" : ""}
                    />
                </Grid>

                {/* Name */}
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                    <TextField
                        id="noun-name"
                        fullWidth
                        label={"Name"}
                        value={state.name}
                        required={true}
                        disabled={createNounData.isSuccess || createNounData.isError}
                        error={error.name}
                        helperText={error.name ? "Please enter Noun name" : ""}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => updateState("name", e.target.value)}
                    />
                </Grid>

                {/* Abbreviation */}
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                    <TextField
                        id="noun-abbr"
                        fullWidth
                        label={"Abbrevation"}
                        value={state.abbrevation}
                        required={true}
                        disabled={createNounData.isSuccess || createNounData.isError}
                        error={error.abbrevation}
                        helperText={error.abbrevation ? "Please enter Noun abbrevation." : ""}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => updateState("abbrevation", e.target.value)}
                    />
                </Grid>

                {/* Description */}
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <TextField
                        id="noun-description"
                        fullWidth
                        label={"Description"}
                        value={state.description}
                        required={true}
                        disabled={createNounData.isSuccess || createNounData.isError}
                        error={error.description}
                        helperText={error.description ? "Please enter Noun description." : ""}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => updateState("description", e.target.value)}
                    />
                </Grid>

                <Grid item xs={12}>
                    <GreyAccordian title="Reference Card" content={
                        <ReferenceCard rows={copy(rows)} setRows={(newRows) => setRows([...newRows])} hideTitle={true} />
                    } />
                </Grid>

                {/* Upload Image */}
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <UploadImage
                        useImage={true}
                        url={giveMeURLToShow()}
                        onUploaded={(file: any) => setUpload(file)}
                        disabled={createNounData.isSuccess || createNounData.isError}
                        title={"Upload Noun Image"}
                        resetAttachments={() => setAttachments(null)}
                    />
                </Grid>
            </Grid>
        </DialogContent>

        <DialogActions>
            <Grid container spacing={2} px={2} pb={1}>
                {/* Info Tex */}
                <Grid item xs={12}>
                    {giveMeInfoText()}
                </Grid>

                {/* Add Noun Btn */}
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                    <LoadingButton
                        id="u-c-noun"
                        fullWidth
                        variant="contained"
                        onClick={onCreateUpdateBtnClicked}
                        loading={createNounData.isLoading || updateNounData.isLoading || uploadImageData.isLoading}
                    >
                        {giveMeButtonName()}
                    </LoadingButton>
                </Grid>

                {/* Cancel Btn */}
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                    <Button
                        fullWidth
                        variant="outlined"
                        onClick={onCancelBtnClicked}
                        disabled={createNounData.isLoading || updateNounData.isLoading || uploadImageData.isLoading}
                    >
                        {`${uploadImageData.isError ? "Upload Later" : "Cancel"}`}
                    </Button>
                </Grid>
            </Grid>
        </DialogActions>
    </>
}