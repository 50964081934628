import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useLocation } from "react-router-dom";
import { useAppDispatch } from "../../redux";
import { openDialog } from "../../redux/slices/dialog";
import { Timeout } from "../timeout";

export const Logout: React.FC<{}> = () => {
  const { logout } = useAuth0();

  const location = useLocation();

  const dispatch = useAppDispatch();

  React.useEffect(() => {
    localStorage.clear();
    if ((location.state as any)?.isTimeout) {
      dispatch(
        openDialog({
          title: "",
          hideNegativeBtn: true,
          hidePositiveBtn: true,
          hideCloseIcon: true,
          body: <Timeout />,
        })
      );
    } else {
      logout({ logoutParams: { returnTo: window.location.origin } });
    }
  });

  return <>{(location.state as any)?.isTimeout ? "" : "Logging Out..."}</>;
};
