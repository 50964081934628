import { Dimension } from "pdf-generator";
import { config } from "../config";
import { SelectBoxOption } from "../interfaces";
import { Category } from "../redux/slices/checkList";
import { LocalStorageKeys } from "./constants";

/**
 * The below function convert the normal array of object to
 * {label: "",value:1} pair which is suitable for React Select
 * component.
 */
export let ConvertToReactSelect = (
  data: any[],
  valueKey: string,
  labelKey: string,
  optionalLabelKeys?: string[],
  prefix?: string
): SelectBoxOption[] => {
  if (!data || !data?.length) {
    return [];
  }

  return data.map((val: any) => {
    let label = (optionalLabelKeys || [])?.map(key => val?.[key]).join(" - ")
    return {
      ...val,
      value: val?.[valueKey] ?? "",
      label: `${val?.[labelKey]}${label ? ` - ${prefix ?? ""}${label}` : ""}` ?? "",
    };
  });
};

/**
 * The below function convert the uploaded file to base64 file.
 */
export let ToBase64 = (file: File) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

/**
 * The below function capitalize the given string.
 */
export let CapitalizeString = (string: string) => {
  if (!string) {
    return "-";
  }
  return string.charAt(0).toUpperCase() + string.slice(1);
};

/**
 * The below function convert the HEX code to RGBA
 */
export let ConvertHexToRGBA = (hex: string, opacity: number) => {
  if (hex) {
    let tempHex = hex.replace("#", "");
    let r = parseInt(tempHex.substring(0, 2), 16);
    let g = parseInt(tempHex.substring(2, 4), 16);
    let b = parseInt(tempHex.substring(4, 6), 16);

    return `rgba(${r},${g},${b},${opacity / 100})`;
  }
  return null;
};

/**
 * The below function will scroll the page to the Top.
 */
export let ScrollToTop = () => {
  document.body.scrollTop = 0;
  document.documentElement.scrollTop = 0;
};

// Check the versions
export let semverGreaterThan = (versionA: string, versionB: string) => {
  const versionsA = versionA ? versionA.split(/\./g) : ["0", "0", "0"];
  const versionsB = versionB ? versionB.split(/\./g) : ["0", "0", "0"];

  while (versionsA.length || versionsB.length) {
    const a = Number(versionsA.shift());

    const b = Number(versionsB.shift());
    // eslint-disable-next-line no-continue
    if (a === b) continue;
    // eslint-disable-next-line no-restricted-globals
    return a > b || isNaN(b);
  }
  return false;
};

// Refresh the cache by clearing the cache and reload
export const refreshCacheAndReload = async () => {
  let names = await caches.keys();
  if (names.length > 0) {
    // Service worker cache should be cleared with caches.delete()
    for (const name of names) {
      caches.delete(name);
    }
    // delete browser cache and hard reload
    window.location.reload();
  }
};

// To get distance between two lattitude and longitude
export const distance = (
  lat1: number,
  lon1: number,
  lat2: number,
  lon2: number,
  unit: string
) => {
  if (lat1 === lat2 && lon1 === lon2) {
    return 0;
  } else {
    var radlat1 = (Math.PI * lat1) / 180;
    var radlat2 = (Math.PI * lat2) / 180;
    var theta = lon1 - lon2;
    var radtheta = (Math.PI * theta) / 180;
    var dist =
      Math.sin(radlat1) * Math.sin(radlat2) +
      Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
    if (dist > 1) {
      dist = 1;
    }
    dist = Math.acos(dist);
    dist = (dist * 180) / Math.PI;
    dist = dist * 60 * 1.1515;
    if (unit === "K") {
      dist = dist * 1.609344;
    }
    if (unit === "N") {
      dist = dist * 0.8684;
    }
    return dist;
  }
};

export const giveMeImageURL = (
  object_id: string = "",
  object_type: string = "",
  file_id: string = ""
) => {
  let url = `${config.api_url}mm/mdg/attachment/${object_type}/${object_id}?file_id=${file_id}`;
  return url;
};

export const randomNameGenerator = (num: number) => {
  let res = "";
  for (let i = 0; i < num; i++) {
    let ch = String.fromCharCode(97 + Math.floor(Math.random() * 27));
    if (["{", "}"].includes(ch)) {
      ch = String.fromCharCode(97 + Math.floor(Math.random() * 27));
    }
    res += ch;
  }
  return res;
};

// To Parse JWT Token
export const parseJwt = (token: String) => {
  var base64Url = token.split(".")[1];
  var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  var jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );

  return JSON.parse(jsonPayload);
};

// Accepts the array and key
export const groupBy = (array: any[], key: string) => {
  // Return the end result
  return array.reduce((result, currentValue) => {
    // If an array already present for key, push it to the array. Else create an array and push the object
    (result[currentValue[key]] = result[currentValue[key]] || []).push(
      currentValue
    );
    // Return the current iteration `result` value, this will be taken as next iteration `result` value and accumulate
    return result;
  }, {}); // empty object is the initial value for result object
};

// Give the font color of the status
export const GiveMeStatusColor = (status: string = "") => {
  status = String(status).toLowerCase();
  // active or yes status
  if (["active", "yes", "true"].includes(status)) {
    return "green";
  }

  // inactive or no status
  if (["inactive", "in-active", "no", "false"].includes(status)) {
    return "red";
  }

  return "GrayText";
};

// Conver the size in bytes to all the sizes
export const convertSize = (bytes: number): string => {
  if (!bytes) {
    return "--";
  }

  const kilobyte = 1024;
  const megabyte = kilobyte * 1024;
  const gigabyte = megabyte * 1024;
  const terabyte = gigabyte * 1024;
  const petabyte = terabyte * 1024;

  if (bytes >= petabyte) {
    return (bytes / petabyte).toFixed(2) + " PB";
  } else if (bytes >= terabyte) {
    return (bytes / terabyte).toFixed(2) + " TB";
  } else if (bytes >= gigabyte) {
    return (bytes / gigabyte).toFixed(2) + " GB";
  } else if (bytes >= megabyte) {
    return (bytes / megabyte).toFixed(2) + " MB";
  } else if (bytes >= kilobyte) {
    return (bytes / kilobyte).toFixed(2) + " KB";
  } else {
    return "1 KB";
  }
};

// To convert color codes to grey scale values.
export const hexToGrayscale = (hexColor: string): number => {
  // Remove the '#' symbol if present
  hexColor = hexColor.replace(/^#/, "");

  // Parse the RGB components
  const red = parseInt(hexColor.substr(0, 2), 16);
  const green = parseInt(hexColor.substr(2, 2), 16);
  const blue = parseInt(hexColor.substr(4, 2), 16);

  // Calculate grayscale using perceived brightness formula
  const grayscale = Math.floor(0.299 * red + 0.587 * green + 0.114 * blue);

  return grayscale;
};

export const customBtoa = (input: any) => {
  const encoder = new TextEncoder();
  const dataView = encoder.encode(input);

  // Convert the dataView to a binary array
  const binary = [];
  for (let i = 0; i < dataView.byteLength; i++) {
    binary.push(String.fromCharCode(dataView[i]));
  }

  // Join the binary array into a string and use btoa to encode it
  return btoa(binary.join(""));
};

export const searchLocalList = (data: object, search: string) => {
  return JSON.stringify(
    Object.values(data)
      .map((e: any) => {
        if (Array.isArray(e)) {
          if (e.length > 0) {
            e.map((e) => {
              if (typeof e === "object" && e) {
                return Object.values(e).join(" ").toLowerCase();
              }
              return e?.toString()?.toLoweCase();
            });
          }
          return "";
        }
        if (typeof e === "object" && e) {
          return Object.values(e).join(" ").toLowerCase();
        }
        return e?.toString()?.toLowerCase();
      })
      .filter(Boolean)
  )
    .toLowerCase()
    .includes(search.toLowerCase());
};

// Year Options generate function
const currentYear = new Date().getFullYear();

const LastFiveYear = currentYear - 4;

export const generateYearOptions = () => {
  let options = [];

  for (let i = currentYear; i >= LastFiveYear; i--) {
    options.push({
      value: i.toString(),
      label: i === currentYear ? "Current Year" : i.toString(),
    })
  }

  return options;
}

export const getTransformedDataForPagination = (data: any[], response?: Response) => {
  let count = Number(response?.headers?.get('x-hontrel-total-records-count'));
  let totalCount = isNaN(count) ? 0 : count;
  return { data, totalCount };
};

export const sortByKey = (arr: any[], key: string) => {
  let newArr = [...arr];
  return newArr?.sort((a, b) => {
    if (typeof a[key] === "number") {
      return a[key] - b[key]
    } else {
      if (a[key] < b[key]) {
        return -1
      } else {
        return 1;
      }
    }
  }) ?? []
};

export const isRowSelectable = (row: any, associations: any) => {
  const assocArray: any = associations;
  let isSelectable = true;

  try {
    for (let index = 0; index < assocArray?.object_details?.length; index++) {
      const association = assocArray?.object_details?.[index];
      isSelectable = association?._id !== row._id;
      if (!isSelectable) {
        break;
      }
    }
  } catch (error) {
    console.error("error", error);
  }

  return isSelectable;
};

export const reorder = (list: any[], startIndex: number, endIndex: number): any[] => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

export const buildContent = (content: string, subs: Array<string>, sequence_id: string) => {
  subs.forEach((s: string, idx: number) => {
    content = content?.replace(`(${idx})`, s)
  })
  content = content?.replace("seqID", sequence_id)
  return content;
}

export const objectToQueryParams = (obj: any) => {
  if (!obj || Object.keys(obj).length <= 0) {
    return '';
  };
  return `?${Object.keys(obj)
    .filter(key => {
      if (typeof obj[key] === 'string') {
        return obj[key].length > 0
      }
      if (typeof obj[key] === 'number') {
        return true
      }
      return false
    })
    .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(obj[key])}`)
    .join('&')}`;
}

export function UUIDv4() {
  return "10000000-1000-4000-8000-100000000000".replace(/[018]/g, (c: any) =>
    (c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(16)
  );
}

export const getImageDimension = (imageSrc: string, callback: (dimension: Dimension, aspectRatio: number) => void) => {
  const img = new Image();

  img.onload = function () {
    const aspectRatio = img.width / img.height;
    callback({ width: img.width, height: img.height }, aspectRatio);
  };

  img.src = imageSrc;
}

export function fileToBase64(file: File) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      const base64String = reader.result;
      resolve(base64String);
    };
    reader.onerror = function (error) {
      reject(error);
    };
  });
}

export const generateDataTableFormat = (data: Category[]) => {
  return data?.map((table: any, index: number) => {
    return {
      name: table.name,
      description: table.description,
      position: index,
      rows: table?.rows ?? [],
      header_color: table?.header_color,
      columns: table?.columns?.map((col: any) => {
        return {
          ...col,
          header_class_name: col.header_class_name,
          header_name: col.header_name,
          editable: col?.editable ? true : false
        };
      }) ?? [],
    }
  }) ?? []
}

export function blobToBase64(blob: Blob): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    // Handle error event
    reader.onerror = () => {
      reader.abort(); // Abort reading
      reject(new Error("Failed to read the Blob as Base64."));
    };

    // Resolve with the result when reading is done
    reader.onloadend = () => {
      if (reader.result instanceof ArrayBuffer) {
        reject(new Error("Unexpected result type: ArrayBuffer"));
      } else {
        resolve(reader.result as string);
      }
    };

    // Start reading the Blob as Data URL
    reader.readAsDataURL(blob);
  });
}

export const convertNumberToCurrencyString = (number: number | bigint, currency: string = "USD"): string => {
  const formatter = new Intl.NumberFormat('en-US', { style: 'currency', currency });
  return formatter.format(number);
};

export const openZohoDeskFeedbackModal = () => {
  const token = localStorage.getItem(LocalStorageKeys.authToken) || "";

  const data = token ? parseJwt(token) : {};

  const zohoFeedbackBtn = document.getElementById('feedbacklabel');

  if (zohoFeedbackBtn) {
    const nameField = document.getElementById('feedbNameTxtField');
    const emailField = document.getElementById('feedbEmailTxtField');
    nameField?.setAttribute('value', data.name);
    nameField?.setAttribute('disabled', "true");
    emailField?.setAttribute('value', data.email);
    emailField?.setAttribute('disabled', "true");
    zohoFeedbackBtn.click();
  }else{
    window.location.reload();
  }
};