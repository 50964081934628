import { createApi, fetchBaseQuery, retry } from '@reduxjs/toolkit/query/react';
import { config } from '../../../config';
import { ConvertToReactSelect, getTransformedDataForPagination, LocalStorageKeys } from '../../../utils';

export const groupAPI = createApi({
    reducerPath: "groupAPI",
    baseQuery: retry(fetchBaseQuery({
        baseUrl: config.api_url  + "mm/mdg/",
        prepareHeaders: (headers, { getState }) => {
            const token = localStorage.getItem(LocalStorageKeys.authToken);

            // If we have a token set in state, let's assume that we should be passing it.
            if (token) {
                headers.set('authorization', `Bearer ${token}`)
            }

            return headers
        },
    }), { maxRetries: 2 }),
    tagTypes: ['Groups', 'GroupsAsOptions'],
    endpoints: (builder) => ({
        // Get Groups API
        getGroups: builder.query({
            query: ({ page = 0, pageSize = 10, sort_by = null, sort_order = null }) => ({ url: `group${page !== null ? `?page_no=${page + 1}` : ''}${pageSize ? `&page_size=${pageSize}` : ''}${sort_by ? `&sort_by=${sort_by}` : ''}${sort_order ? `&sort_order=${sort_order}` : ''}`, method: "GET" }),
            transformResponse: (data: any, meta: { request: Request; response?: Response }) => {
                return getTransformedDataForPagination(data, meta?.response);
            },
            providesTags: ['Groups']
        }),
        
        getGroupsDD: builder.query({
            query: (payload) => ({ url: `group`, method: "GET" }),
            transformResponse: (response: any[], meta, arg) => ConvertToReactSelect(response, "_id", "group_name"),
            providesTags: ['GroupsAsOptions']
        }),

        // Create Group API
        createGroup: builder.mutation({
            query: (payload) => ({ url: `group`, method: "POST", body: payload }),
            invalidatesTags: ['Groups', 'GroupsAsOptions']
        }),

        // Update Group API
        updateGroup: builder.mutation({
            query: (payload) => ({ url: `group`, method: "PUT", body: payload }),
            invalidatesTags: ['Groups', 'GroupsAsOptions']
        }),

        // Delete Group API
        deleteGroup: builder.mutation({
            query: ({id}) => ({ url: `group/${id}`, method: "DELETE" }),
            invalidatesTags: ['Groups', 'GroupsAsOptions']
        })
    })
})

// Export hooks for usage in functional components
export const { useGetGroupsQuery, useGetGroupsDDQuery,useLazyGetGroupsQuery, useCreateGroupMutation, useUpdateGroupMutation, useDeleteGroupMutation } = groupAPI
