import { createSlice } from '@reduxjs/toolkit';
import { SelectBoxOption } from '../../../interfaces';

export interface supplierMappingInterface { id: number, noun_id: SelectBoxOption | null, uom_id: SelectBoxOption | null, group_id: SelectBoxOption | null, noun_variant_ids: [] }

export interface StateInterface { tab: number, selections: supplierMappingInterface[], selectedNouns: string[] }

const initialSelection: supplierMappingInterface = { id: 1, noun_id: null, uom_id: null, group_id: null, noun_variant_ids: [] }

const initialState: StateInterface = { tab: 1, selections: [{ ...initialSelection }], selectedNouns: [] }


const supplierMappingSlice = createSlice({
    name: 'supplierMapping',
    initialState,
    reducers: {
        // Update selection on auto population
        setInitialValues(state, { payload }) {
            let newSelections = payload?.mappedArr ?? [];
            state.selections = [...newSelections]
            state.selectedNouns = state.selections.map(_ => _.noun_id?.value?.toString() ?? "")
        },
        // To Add Empty Selection
        addSelection(state, { payload }) {
            state.selections = [...state.selections, { ...initialSelection, id: state.selections.length + 1 }]
        },

        // To Delete a Selection
        deleteSelection(state, { payload }) {
            let { index } = payload;
            if (index) {
                let newSelection = state.selections;
                newSelection.splice(index, 1);
                state.selections = [...newSelection];
            }
        },

        // To Update Selection Name
        updateSelectionDetail(state, { payload }) {
            let { index, noun_id, uom_id, group_id, noun_variant_ids } = payload;
            if (index !== undefined) {
                let newSelection = state.selections;
                newSelection[index].noun_id = noun_id;
                newSelection[index].uom_id = uom_id;
                newSelection[index].group_id = group_id;
                newSelection[index].noun_variant_ids = noun_variant_ids;
                state.selections = [...newSelection];
            }
            state.selectedNouns = state.selections.map(_ => _.noun_id?.value?.toString() ?? "")
        },

        resetState(state, { payload }) {
            state.tab = 1;
            state.selections = [{ ...initialSelection }]
            state.selectedNouns = [];
        }
    }
})

export const {
    addSelection, deleteSelection, updateSelectionDetail, setInitialValues, resetState
} = supplierMappingSlice.actions;
export default supplierMappingSlice.reducer; 