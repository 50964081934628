import React from "react";
import CloseIcon from '@mui/icons-material/Close';
import { Dialog, Slide, AppBar, Toolbar, IconButton, Typography, styled, Grid, Box, ListItem, ListItemText, List as LContainer, Collapse, useTheme, Drawer, DialogTitle, DialogContent } from "@mui/material";
import { BreadScrumHeader, CustomDataGrid, HighlightSelectBox, InternatlServerError, LoadingSection, NotFoundSection } from "../../components";
import { useGetApprovedVendorQuery, useLazyGetVendorByIdQuery } from "../../redux/services";
import { TransitionProps } from '@mui/material/transitions';
import { useAppDispatch } from "../../redux";
import { useSnackbar } from "notistack";
import { closeBackdrop, openBackdrop } from "../../redux/slices/backdrop";
import { resetVendorSlice, updateVendorSlice } from "../../redux/slices/vendor";
import { closeConfirmationDialog, openConfirmationDialog } from "../../redux/slices/confirmationDialog";
import { ApprovedOptions, StatusOptions } from "../viewVendor/utils";
import { BasicDetails } from "../viewVendor/basicDetails";
import { SupplierTableColumn } from "./utils";
import { createOption } from "../../components/keywordSearch";
import { useStyles } from "../documentTemplates/sequenceNumbering";
import { useSearchParams } from "react-router-dom";

const Transition = React.forwardRef(function Transition(props: TransitionProps & { children: React.ReactElement; }, ref: React.Ref<unknown>,) {
    return <Slide direction="up" ref={ref} {...props} />;
});

// Without this style changes are not reflected to table like removing padding
const StyledDataGrid = styled(CustomDataGrid)(() => ({}));

export const SupplierList: React.FC = () => {
    const classes = useStyles(useTheme());

    const dispatch = useAppDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const [params, setSearchParams] = useSearchParams();

    const [open, setOpen] = React.useState(false);
    const [openWebNav, setOpenWebNavBar] = React.useState(true);
    const [openMobileNav, setOpenMobileNavBar] = React.useState(false);

    const [getVendorDetailById, vendorData] = useLazyGetVendorByIdQuery({ refetchOnFocus: true });
    const { data = [], isLoading, isError, refetch, isSuccess, status } = useGetApprovedVendorQuery(null, { refetchOnFocus: true });

    const updateTab = (currentTab: string) => {
        setSearchParams({ tab: currentTab });
    }

    const handleClose = () => {
        dispatch(resetVendorSlice());
        setOpen(false)
    };

    const openSupplier = (row: any) => {
        setOpen(row._id);
    }

    const toogleWebNav = () => {
        setOpenWebNavBar(!openWebNav);
    };

    const toogleMobileNav = () => {
        setOpenMobileNavBar(!openMobileNav);
    };

    React.useEffect(() => {
        if (open) {
            getVendorDetailById({ id: open });
        }
        // eslint-disable-next-line    
    }, [open]);

    React.useEffect(() => {
        if (isLoading || vendorData.isLoading || vendorData.isFetching) {
            dispatch(openBackdrop("Fetching Supplier Details..."));
        } else {
            dispatch(closeBackdrop());
        }
        // eslint-disable-next-line
    }, [status, vendorData.status])

    React.useEffect(() => {
        const updateValue = async () => {
            const data = vendorData.currentData;
            if (vendorData.isSuccess && data) {
                dispatch(updateVendorSlice({
                    basic_details: { vendor_name: data?.vendor_name ?? '', vendor_website: data.website, approved_vendor: data.approved ? ApprovedOptions[0] : ApprovedOptions[1], status: data.status === "inactive" ? StatusOptions[1] : StatusOptions[0] },
                    primary_contact: {
                        name: data.primary_contact?.person_name, email: data.primary_contact?.email, mobile: data.primary_contact?.phone, address: data.primary_contact?.address, country: {
                            value: data.primary_contact?.country, label: data.primary_contact?.country
                        }
                    },
                    secondary_contact: {
                        name: data.secondary_contact?.person_name, email: data.secondary_contact?.email, mobile: data.secondary_contact?.phone, address: data.secondary_contact?.address, country: {
                            value: data.secondary_contact?.country, label: data.secondary_contact?.country
                        }
                    },
                    applicable_materials: data?.applicable_materials ?? [],
                    error: {
                        basic_details: { vendor_name: false, vendor_website: false, approved_vendor: false, status: false },
                        primary_contact: { name: false, email: false, mobile: false, address: false, country: false },
                        secondary_contact: { name: false, email: false, mobile: false, address: false, country: false },
                    }
                }))
            }
        }
        updateValue();
        // eslint-disable-next-line
    }, [vendorData.status]);

    React.useEffect(() => {
        if (vendorData.isError) {
            enqueueSnackbar('404 - Unable to find the requested Supplier details', { variant: "error" });
            dispatch(openConfirmationDialog({
                title: "404 - Not Found",
                body: "Unable to find the requested Supplier details",
                positiveBtn: "Ok",
                onOk: () => {
                    dispatch(closeConfirmationDialog())
                    handleClose();
                },
                hideNegativeBtn: true
            }))
        }
        // eslint-disable-next-line
    }, [vendorData.status])

    React.useEffect(() => {
        let tab = params.get('tab');
        if (!tab && data?.length > 0) {
            updateTab(data[0]?.noun_name);
        }
        // eslint-disable-next-line
    }, [data])

    const tab = params.get('tab');

    const rows = React.useMemo(() => {
        return data?.find((d: any) => d?.noun_name === tab)?.vendor_info ?? []
    }, [tab, data]);

    const nounOptions = React.useMemo(() => {
        return data.map((d: { noun_name: string }) => createOption(d.noun_name));
    }, [data])

    const LeftPanel = (<Box sx={{
        border: (theme) => `1px solid ${theme.palette.divider}`,
        marginRight: { xs: 0, sm: 1 },
        flexGrow: 1,
        background: (theme) => theme.palette.background.paper,
        height: '100%',
        borderRadius: (theme) => theme.spacing(1),
        boxShadow: `rgba(99, 99, 99, 0.2) 0px 2px 8px 0px`
    }}>
        <Box px={1} paddingBottom={1}>
            <HighlightSelectBox
                label={"Nouns"}
                fullWdith
                loading={vendorData.isLoading || isLoading}
                value={createOption(tab ?? "")}
                options={nounOptions}
                onChange={(data: any) => {
                    if (data) {
                        updateTab(data?.value);
                    }
                }}
            />
        </Box>

        <LContainer component="nav" dense sx={{ height: "calc(100vh - 230px)", overflow: "scroll" }}>
            {nounOptions.map((noun: any, key: number) => {
                return <ListItem
                    sx={{ py: 1 }}
                    key={key}
                    dense
                    button
                    selected={tab === noun?.value}
                    onClick={(event) => {
                        updateTab(noun?.value);
                    }}
                >
                    <ListItemText
                        primary={noun?.value}
                    />
                </ListItem>
            })}
        </LContainer>
    </Box>);

    return <Box sx={{
        height: "100%",
        flexGrow: 1,
        padding: (theme) => theme.spacing(1.5),
        paddingBottom: 0
    }}>
        {/* Header */}
        <BreadScrumHeader
            openWebNav={openWebNav}
            toogleWebNav={toogleWebNav}
            toogleMobileNav={toogleMobileNav}
            currentPath={"Approved Supplier List"}
            history={[]}
        />

        {/* Loading */}
        {isLoading && <LoadingSection message="Fetching Supplier List..." />}

        {vendorData.isFetching && <LoadingSection message="Fetching Supplier Details..." />}

        {/* Error */}
        {!isLoading && isError && <InternatlServerError message="Fetching Supplier List..." onRetry={refetch} />}

        {/* Not Found */}
        {!isLoading && isSuccess && data.length === 0 && <NotFoundSection message="You are yet to add the Supplier List." />}

        {!vendorData.isFetching && !isLoading && isSuccess && data.length > 0 && (
            <Grid container direction={"row"} sx={classes.container}>
                <Grid
                    item
                    xs={openWebNav ? 12 : 0}
                    sm={openWebNav ? 4 : 0}
                    md={openWebNav ? 3 : 0}
                    lg={openWebNav ? 2 : 0}
                    sx={{
                        height: { xs: "auto", sm: "100%" },
                        display: { xs: "none", sm: "block" },
                    }}
                >
                    <Collapse
                        in={openWebNav}
                        timeout={1}
                        orientation={"horizontal"}
                        sx={{
                            height: { xs: "auto", sm: "100%" },
                            ".MuiCollapse-wrapperInner": { width: "100%" },
                        }}
                    >
                        {LeftPanel}
                    </Collapse>
                </Grid>

                <Grid 
                    item
                    xs={12}
                    sx={classes.rightPanel(openWebNav)}
                    sm={!openWebNav ? 0 : 8}
                    md={!openWebNav ? 0 : 9}
                    lg={!openWebNav ? 0 : 10}
                >
                    <Box sx={{
                        flexGrow: 1,
                        background: (theme) => theme.palette.background.paper,
                        boxShadow: `rgba(99, 99, 99, 0.2) 0px 2px 8px 0px`,
                        height: "100%",
                        borderRadius: (theme) => theme.spacing(1),
                        padding: (theme) => theme.spacing(2),
                    }}>
                        <StyledDataGrid
                            id="supplier-list"
                            columns={SupplierTableColumn(openSupplier)}
                            rows={rows}
                            loading={isLoading}
                            onCellDoubleClick={(e) => (e.isEditable = false)}
                            showToolbar
                            getRowId={(row: any) => row?._id}
                        />
                    </Box>
                </Grid>
            </Grid>
            )
        }

        <Dialog
            maxWidth={"xl"}
            fullWidth={true}
            open={open}
            onClose={handleClose}
            TransitionComponent={Transition}
        >
            {/* Ṭoolbar */}
            {!vendorData.isFetching && vendorData.isSuccess && <DialogTitle sx={{ padding: 0 }}>
                <AppBar sx={{ position: 'relative' }}>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={handleClose}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                        <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                            Approved Supplier
                        </Typography>
                    </Toolbar>
                </AppBar></DialogTitle>}
            {!vendorData.isFetching && vendorData.isSuccess && <DialogContent sx={{ mt: 2 }}><BasicDetails isEdit={false} readOnly={true} vendorId={open.toString()} /></DialogContent>}
        </Dialog>

        {/* Mobile Drawer */}
        <Drawer anchor="left" open={openMobileNav} onClose={toogleMobileNav}>
            {LeftPanel}
        </Drawer>
    </Box>
}