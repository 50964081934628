import { createSlice } from '@reduxjs/toolkit'

interface initialStateType {
    isError: boolean,
    message: string,
    userDetails: {
        perm?: any,
        role?: string,
        plan?: string,
        token?: string,
        username?: string,
        is_admin?: boolean
    }
}

const initialState: initialStateType = {
    isError: false,
    message: "",
    userDetails: {}
}

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setAuth(state, { payload }) {
            state.isError = false;
            state.message = "";
            state.userDetails = payload;
        },
        setError(state, { payload }: { payload: string }) {
            state.isError = true;
            state.message = payload;
        }
    },
})

export const { setAuth, setError } = authSlice.actions;
export default authSlice.reducer;