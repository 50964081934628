import { DeleteOutline, Link } from "@mui/icons-material";
import {
    Chip,
    IconButton,
    Stack,
    Tooltip,
    Typography,
} from "@mui/material";
import { GridRenderCellParams } from "@mui/x-data-grid-premium";
import moment from "moment";

export const UserFriendlyView: any = {
    bom: "BOM",
    material: "Material",
    document: "Document",
    "in-progress": "In Progress",
    completed: "Completed"
}

export const chipColor: any = {
    "in-progress": "info",
    completed: "success"
}

export const DashboardTableColumn = (arg: {
    onDelete?: (row: any) => void,
    view?: (row: any) => void,
    openAssociation?: (row: any) => void,
    hideDeleteBtn?: boolean
}) => {

    const {
        onDelete = (row) => false,
        view = (row) => false,
        openAssociation = (row) => false,
        hideDeleteBtn = true
    } = arg;

    const columns = [
        {
            field: "sequence_id",
            headerName: "Workbook ID",
            width: 150,
            renderCell: (params: GridRenderCellParams) => {
                if (params.rowNode.type === "group") {
                    return params.value;
                }
                return (
                    <Typography
                        id="view-workbook"
                        sx={{
                            color: "blue",
                            textDecoration: "underline",
                            cursor: "pointer",
                        }}
                        variant="body2"
                        onClick={() => view(params.row)}
                    >
                        {params.row?.sequence_id}
                    </Typography>
                );
            },
        },
        {
            field: "name",
            headerName: "Workbook Name",
            width: 300,
            renderCell: (params: GridRenderCellParams) => {
                if (params.rowNode.type === "group") {
                    return params.value;
                }
                return (
                    <Tooltip title={params.row?.name ?? ""}>
                        <Typography
                            variant="body2"
                            sx={{
                                width: "100%",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                            }}
                        >
                            {params.row?.name ?? ""}
                        </Typography>
                    </Tooltip>
                );
            },
        },
        {
            field: "description",
            headerName: "Description",
            width: 300,
            renderCell: (params: GridRenderCellParams) => {
                if (params.rowNode.type === "group") {
                    return params.value;
                }
                return (
                    <Tooltip title={params.row?.description ?? ""}>
                        <Typography
                            variant="body2"
                            sx={{
                                width: "100%",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                            }}
                        >
                            {params.row?.description ?? ""}
                        </Typography>
                    </Tooltip>
                );
            },
        },
        {
            field: "type",
            headerName: "Type",
            width: 250,
            renderCell: (params: GridRenderCellParams) => {
                if (params.rowNode.type === "group") {
                    return params.value;
                }
                return (
                    <Stack
                        spacing={1}
                        direction={"row"}
                        width="100%"
                        overflow={"scroll"}
                    >
                        <Chip size={"small"} label={UserFriendlyView?.[params.row?.type] ?? ""} color={chipColor?.[params.row?.type] ?? "primary"} />
                    </Stack>
                );
            },
        },
        {
            field: "status",
            headerName: "Status",
            width: 250,
            renderCell: (params: GridRenderCellParams) => {
                if (params.rowNode.type === "group") {
                    return params.value;
                }
                return (
                    <Stack
                        spacing={1}
                        direction={"row"}
                        width="100%"
                        overflow={"scroll"}
                    >
                        <Chip size={"small"} label={UserFriendlyView?.[params.row?.status] ?? ""} color={chipColor?.[params.row?.status] ?? "primary"} />
                    </Stack>
                );
            },
        },
        {
            field: "link",
            headerName: "Link",
            width: 100,
            renderCell: (params: GridRenderCellParams) => {
                if (params.rowNode.type === "group") {
                    return params.value;
                }
                return (
                    <>
                        <IconButton
                            disabled={!params.row.associations?.length}
                            onClick={() => openAssociation(params.row)}
                        >
                            <Link
                                color={params.row.associations?.length ? "info" : "disabled"}
                            />
                        </IconButton>
                    </>
                );
            },
        },
        { field: "created_user", headerName: "Created By", width: 150 },
        {
            field: "created_time",
            headerName: "Created Date",
            width: 250,
            renderCell: (params: GridRenderCellParams) => {
                if (params.rowNode.type === "group") {
                    return params.value;
                }
                return (
                    <Typography variant="body2">
                        {moment(params.row.created_time).format("lll")}
                    </Typography>
                );
            },
        },
        { field: "modified_user", headerName: "Last modified by", width: 150 },
        {
            field: "modified_time",
            headerName: "Last modified date",
            width: 250,
            renderCell: (params: GridRenderCellParams) => {
                if (params.rowNode.type === "group") {
                    return params.value;
                }
                return (
                    <Typography variant="body2">
                        {params.row.modified_time
                            ? moment(params.row.modified_time).format("lll")
                            : ""}
                    </Typography>
                );
            },
        },
        {
            field: "delete",
            headerName: "Delete",
            width: 100,
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <IconButton onClick={() => onDelete(params.row)}>
                        <DeleteOutline color="error" />
                    </IconButton>
                );
            },
        },
    ];

    if(hideDeleteBtn){
        columns.pop();
    }

    return columns;
};
