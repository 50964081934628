import { AlignHorizontalLeft, Analytics, Architecture, Compress, DocumentScanner, FilePresent, Folder, GroupAdd, List, MenuBook, PrecisionManufacturingOutlined, Troubleshoot, ViewQuilt } from "@mui/icons-material";
import { SelectBoxOption } from "../../interfaces";
import { AppRoutes } from "../../router/routes";

export const BOMTypeOptions: SelectBoxOption[] = [{ value: "individual", label: "Individual Part ID" }, { value: "assembled", label: "Assembly ID" }, { value: "block", label: "Area ID" }];

export const NavBars = (
    id: string,
    isEdit: boolean = false,
    loading: boolean = false,
    tags: { tag_id: string, tag_name: string }[] = [],
    tag_group_id: string,
    technicalParameterPath: string
) => ([
    {
        id: "1",
        title: "Basic Details",
        icon: <AlignHorizontalLeft fontSize="small" />,
        path: AppRoutes.viewProjectBasicDetail(id)
    },
    {
        id: "2",
        title: "Technical Parameters",
        disabled: !isEdit,
        icon: <Analytics fontSize="small" />,
        path: AppRoutes.viewProjectTechParamForm(id, technicalParameterPath)
    },
    {
        id: "3",
        title: "Documents",
        disabled: !isEdit,
        icon: <DocumentScanner fontSize="small" />,
        path: AppRoutes.viewProjectDocuments(id),
        loading,
        subNav: [
            {
                id: "3.1",
                title: "All",
                icon: <Folder htmlColor="#fde100" fontSize="small" />,
                path: `${AppRoutes.viewProjectDocuments(id)}?tags=all`
            },
            ...tags.map(_ => ({
                id: _.tag_id,
                title: _.tag_name,
                icon: <Folder htmlColor="#fde100" fontSize="small" />,
                path: `${AppRoutes.viewProjectDocuments(id)}?tag_id=${_.tag_id}&group_id=${tag_group_id}`
            }))
        ]
    },
    {
        id: "4",
        title: "IEDRAW",
        disabled: !isEdit,
        isBeta: true,
        icon: <Architecture fontSize="small" />,
        path: AppRoutes.viewProjectIEDraw(id),
    },
    {
        id: "5",
        title: "Material",
        disabled: !isEdit,
        icon: <FilePresent fontSize="small" />,
        path: AppRoutes.viewProjectMaterial(id) + "?tab=material",
    },
    {
        id: "6",
        title: "BOMs",
        disabled: !isEdit,
        icon: <ViewQuilt fontSize='small' />,
        path: AppRoutes.viewProjectBOM(id) + "?tab=bom",
    },
    {
        id: "7",
        title: "BOM Report",
        disabled: !isEdit,
        icon: <List fontSize='small' />,
        path: AppRoutes.viewBOMReportOnProjectModule(id) + "?tab=bomReport",
    },
    {
        id: "8",
        title: "List View",
        disabled: !isEdit,
        icon: <Compress fontSize='small' />,
        path: AppRoutes.viewListViewOnProjectModule(id) + "?tab=listView",
    },
    {
        id: "9",
        title: "Workbook",
        disabled: !isEdit,
        icon: <MenuBook fontSize='small' />,
        path: AppRoutes.workbookOnProjectModule(id) + "?tab=workbook",
    },
    {
        id:"10",
        title:"Issue Tracker",
        disabled: !isEdit,
        icon: <Troubleshoot fontSize="small" />,
        path: AppRoutes.viewIssueTrackerOnProject(id) + "?tab=issueTracker"
    },
     {
        id:"11",
        title:"AI Search",
        disabled: !isEdit,
        icon: <PrecisionManufacturingOutlined fontSize="small" />,
        path: AppRoutes.viewProjectAISearch(id)
    },
    {
        id:"12",
        title:"Manage Users",
        disabled: !isEdit,
        icon: <GroupAdd fontSize="small" />,
        path: AppRoutes.manageUsersOnProject(id)
    },
    // {
    //     id:"4",
    //     title: "Create BOM",
    //     disabled: !isEdit,
    //     icon: <AccountTree fontSize="small" />,
    //     path: AppRoutes.viewProjectBOM(id)
    // },
    // {
    //     id:"5",
    //     title: "View BOM",
    //     disabled: true,
    //     icon: <Lock fontSize="small" />,
    //     path: ""
    // },
    // {
    //     id:"6",
    //     title:"Equipment List",
    //     disabled: true,
    //     icon: <Lock fontSize="small" />,
    //     path: ""
    // },
    // {
    //     id:"7",
    //     title:"Valve List",
    //     disabled: true,
    //     icon: <Lock fontSize="small" />,
    //     path: ""
    // },
    // {
    //     id:"8",
    //     title:"Instrument List",
    //     disabled: true,
    //     icon: <Lock fontSize="small" />,
    //     path: ""
    // },
    // {
    //     id:"9",
    //     title:"Line List",
    //     disabled: true,
    //     icon: <Lock fontSize="small" />,
    //     path: ""
    // },
    
    // {
    //     id:"11",
    //     title:"Project Closure",
    //     disabled: true,
    //     icon: <Lock fontSize="small" />,
    //     path: ""
    // },
])

export const MDGTypeOptions = [{ value: "Generate using MDG", label: "Generate using MDG" }, { value: "Manually Enter the Description", label: "Manually Enter the Description" }];