
import { GridRenderCellParams, GridColumnHeaderParams, GridGroupingValueGetterParams } from "@mui/x-data-grid-premium";
import { FolderOutlined } from "@mui/icons-material";
import { Box, Chip, Stack, Tooltip, Typography } from "@mui/material";
import moment from "moment";
import { DownloadDoc } from "../../components";
import FileSize from "../../components/fileSize";
import { convertSize } from "../../utils";
import { WIPIcon } from "../../images/svg/WIPIcon";
import { PublishedIcon } from "../../images/svg/publishedIcon";

export const giveMeLocationAndDocumentTags = (tags_info: any) => {
    let location_tags: any = null;
    let document_tags: any = null;

    tags_info?.forEach((_: any) => {
        if (_.tag_group_name === "Document Type") {
            document_tags = { value: _.tag_id, label: _.tag_name, ..._ }
        }
        if (_.tag_group_name === "Folders") {
            location_tags = { value: _.tag_id, label: _.tag_name, ..._ }
        }
    })
    return { location_tags, document_tags };
}

export const DashboardTableColumn = () => ([
    {
        field: 'sequence_id', headerName: 'Doc Id', width: 150, renderCell: (params: GridRenderCellParams) => {
            return <Typography variant="body2" sx={{ width: "100%", overflow: "hidden", textOverflow: "ellipsis" }}>{params.row.sequence_id}</Typography>
        }
    },
    {
        field: 'version', headerName: 'Revision', width: 100, renderCell: (params: GridRenderCellParams) => {
            return <Typography variant="body2" sx={{ width: "100%", overflow: "hidden", textOverflow: "ellipsis" }}>{params.row.version}</Typography>
        }
    },
    {
        field: 'released',
        headerName: 'Status',
        width: 150,
        renderCell: (params: GridRenderCellParams) => {
            return (
                <Stack px={3} py={2}>
                    {params?.row?.released && <Tooltip title="Published" children={<div><PublishedIcon /></div>} />}
                    {!params?.row?.released && <Tooltip title="Yet to publish" children={<div><WIPIcon /></div>} />}
                </Stack>
            )
        }
    },
    {
        field: 'file_name', headerName: 'File Name', width: 200, renderCell: (params: GridRenderCellParams) => {
            return <Typography variant="body2" sx={{ width: "100%", overflow: "hidden", textOverflow: "ellipsis" }}>{params.row.file_name}</Typography>
        }
    },
    {
        field: 'size', headerName: 'Size', width: 100, renderCell: (params: GridRenderCellParams) => {
            return <FileSize id={params.row._id} status={params.row.status} size={params.row?.size ?? 0} />
        },
        groupingValueGetter: (params: GridGroupingValueGetterParams) => {
            return convertSize(params.row?.size ?? 0)
        },
    },
    {
        field: 'preview', headerName: 'Preview', width: 100, renderCell: (params: GridRenderCellParams) => {
            return <DownloadDoc fileType={params.row?.file_name?.split('.')?.pop() ?? "Unknown"} preview={true} id={params.row._id} url={params.row.url} status={params.row.status} />
        },
        sortable: false,
        disableColumnMenu: true
    },
    {
        field: 'location_tags', headerName: 'Folder', width: 250,
        valueGetter: (params: GridRenderCellParams) => giveMeLocationAndDocumentTags(params.row.tags_info).location_tags?.label,
        renderCell: (params: GridRenderCellParams) => (
            <Stack spacing={1} direction={"row"} width="100%" overflow={"scroll"} id={"tagsChip"}>
                <Chip variant="outlined" size={"small"} label={`${giveMeLocationAndDocumentTags(params.row.tags_info).location_tags?.label}`} />
            </Stack>),
        renderHeader: (params: GridColumnHeaderParams) => (
            <Box display={"flex"} alignItems={"center"} gap={1}>
                <Typography variant="body2">{"Folder"}</Typography>
                <FolderOutlined fontSize="small" />
            </Box>
        ),
        sortable: false,
        disableColumnMenu: true
    },
    {
        field: 'document_type_tags', headerName: 'Document Type', width: 250,
        valueGetter: (params: GridRenderCellParams) => giveMeLocationAndDocumentTags(params.row.tags_info).document_tags?.label,
        renderCell: (params: GridRenderCellParams) => (
            <Stack spacing={1} direction={"row"} width="100%" overflow={"scroll"} id={"tagsChip"}>
                <Chip variant="outlined" size={"small"} label={`${giveMeLocationAndDocumentTags(params.row.tags_info).document_tags?.label}`} />
            </Stack>),
        sortable: false,
        disableColumnMenu: true
    },
    { field: 'created_user', headerName: 'Uploaded By', width: 150 },
    {
        field: 'created_time', headerName: 'Uploaded Date', width: 200, renderCell: (params: GridRenderCellParams) => {
            return <Typography variant="body2" sx={{ width: "100%", overflow: "hidden", textOverflow: "ellipsis" }}>{moment(params.row.created_time).format('lll')}</Typography>
        }
    },
])