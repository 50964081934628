import { createSlice } from '@reduxjs/toolkit';

interface sideNavBar {
    open: boolean,
    openWebSideNavBar: boolean,
    openMobileSideNavBar: boolean
}

export type SelectedDependency = { 
    'material': string[], 
    'document': string[], 
    'tag': string[], 
    'project': string[], 
    'proposal': string[], 
    'product': string[], 
    'vendor': string[], 
    'bom': string[], 
    'dataTableForm': string[] 
};

export type DepKeys = keyof SelectedDependency;

interface initialStateType {
    sideNavBar: sideNavBar,
    docUploadStatus: any,
    selectedChip: Partial<SelectedDependency>,
    socket: {
        socket: any,
        isConnected: boolean,
        docArray: string[],
    }
}

const initialState: initialStateType = {
    sideNavBar: {
        open: false,
        openWebSideNavBar: true,
        openMobileSideNavBar: false
    },
    docUploadStatus: null,
    selectedChip: { material: [], document: [], tag: [], project: [], product: [], proposal: [], vendor: [], bom: [], dataTableForm: [] },
    socket: {
        socket: null,
        isConnected: false,
        docArray: []
    }
}

const utilsSlice = createSlice({
    name: 'utils',
    initialState,
    reducers: {
        toogleNavBar(state) {
            state.sideNavBar.open = !state.sideNavBar.open;
        },
        updateDocUploadStatus(state, { payload }) {
            state.docUploadStatus = payload;
        },
        updateSelectedChip(state, { payload }: { payload: { _id: string, key: DepKeys, index: number, checked: boolean } }) {
            const { _id, index, checked, key } = payload;
            let selectedChip = state.selectedChip;
            if (checked) {
                selectedChip[key]?.push(_id)
            } else {
                selectedChip[key]?.splice(index, 1);
            }
            state.selectedChip = selectedChip;
        },
        clearSelectedChip(state) {
            localStorage.removeItem('selectedChip')
            state.selectedChip = { material: [], document: [], tag: [], project: [], product: [], proposal: [], vendor: [], bom: [], dataTableForm: [] };
        },
        selectAllChip(state, { payload }: { payload: Partial<SelectedDependency> }) {
            console.log({payload})
            let selectedChip = {...state.selectedChip, ...payload};
            state.selectedChip = selectedChip;
        },
        connectSocket(state, { payload }: { payload: { socket: any, isConnected: boolean } }) {
            state.socket.socket = payload.socket;
            state.socket.isConnected = payload.socket;
        },
        closeSocket(state, { payload }) {
            if (state.socket.isConnected && state.socket.socket) {
                state.socket.socket.close();
            }
        },
        addSocketArray(state, { payload }: { payload: string }) {
            if (!state.socket.docArray.includes(payload)) {
                state.socket.docArray.push(payload);
            }
        },
        removeSocketArray(state, { payload }: { payload: string }) {
            state.socket.docArray.splice(state.socket.docArray.indexOf(payload), 1);
            if (state.socket.docArray.length === 0) {
                if (state.socket.isConnected && state.socket.socket) {
                    state.socket.socket.close();
                }
            }
        },
        setOpenWebSideNavBar(state, {payload}){
            state.sideNavBar.openWebSideNavBar = payload;
        },
        setOpenMobileSideNavBar(state, {payload}){
            state.sideNavBar.openMobileSideNavBar = payload;
        },
    }
})

export const { toogleNavBar, updateDocUploadStatus, updateSelectedChip, selectAllChip, clearSelectedChip, connectSocket, closeSocket, addSocketArray, removeSocketArray, setOpenMobileSideNavBar, setOpenWebSideNavBar } = utilsSlice.actions;
export default utilsSlice.reducer;