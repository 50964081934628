import { ArrowDropDown, Cancel, ContentCopy, DeleteOutline, DragIndicator, EditOutlined, ExpandMore, Lock, LockOpenOutlined, LockOutlined, Save } from "@mui/icons-material";
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Divider, Grid, IconButton, Input, ListItemIcon, Menu, MenuItem, Stack, styled, TextField, Typography } from "@mui/material";
import { GridActionsCellItem, GridColDef, GridColumnHeaderParams, GridColumnResizeParams, GridColumnOrderChangeParams, GridRowModel, GridRowSelectionModel, GRID_CHECKBOX_SELECTION_COL_DEF } from "@mui/x-data-grid-premium";
import copy from "fast-copy";
import React from "react";
import { Draggable } from "react-beautiful-dnd";
import { MuiColorInput, matchIsValidColor } from 'mui-color-input'
import { CustomDataGrid } from "../../../components";
import { useAppDispatch } from "../../../redux";
import { closeConfirmationDialog, openConfirmationDialog } from "../../../redux/slices/confirmationDialog";
import { CheckListCategories, CheckListContext } from "../viewCheckListDetail";
import { useSnackbar } from "notistack";
import { PageSizes } from "pdf-lib";
import { PageSize } from "pdf-generator";
import { SelectBoxOption } from "../../../interfaces";

interface IProps {
    children?: JSX.Element,
    category: CheckListCategories,
    index: number,
    size: SelectBoxOption | null,
    orientation: SelectBoxOption | null,
    showRuler: boolean;
}

// Without this style changes are not reflected to table lik removing padding
const StyledDataGrid = styled(CustomDataGrid)(({ theme }) => ({}));

const CustomColumn = ({ params, colIndex, updateColumnData, index, header_color }: { params: GridColumnHeaderParams; colIndex: number, updateColumnData: any, index: number, header_color?: string }) => {

    const [name, setName] = React.useState<string>(params.colDef?.headerName ?? "-");
    const [isEdit, setIsEdit] = React.useState<boolean>(false);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const { state, updateState } = React.useContext(CheckListContext);

    const dispatch = useAppDispatch();

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        event.preventDefault();
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };


    const onSaveBtnClicked = () => {
        updateColumnData(
            colIndex,
            name,
            "headerName",
            state
        );
        setIsEdit(false);
    };

    const onCancelBtnClicked = () => {
        setName(params.colDef?.headerName ?? "-");
        setIsEdit(false);
    };

    const deleteColumn = () => {
        dispatch(openConfirmationDialog({
            title: "Are you sure?",
            body: `Do you want to delete the column "${name}"?`,
            positiveBtn: "Delete",
            negativeBtn: "Cancel",
            onOk: () => {
                // let colIndex = parseInt(params.colDef.headerClassName?.toString()?.split("-")?.[1] ?? "0");
                let newCategories = copy(state.categories);
                let column: any = newCategories[index].definition.columns.splice(colIndex, 1);
                if (newCategories[index].definition.rows?.length > 0) {
                    for (const obj of newCategories[index].definition.rows) {
                        if (column.length > 0) {
                            delete obj[column[0].field]
                        }
                    }
                }
                updateState('categories', [...newCategories]);
                dispatch(closeConfirmationDialog());
            },
            onNegativeBtn: () => dispatch(closeConfirmationDialog())
        }))
    }

    return <Stack direction={"row"} alignItems={"center"} spacing={1} width={"100%"} bgcolor={isEdit ? "white !important" : header_color || 'rgb(230, 229, 229)'}>
        {!isEdit && <Stack direction={"row"} width={"100%"} alignItems={"center"} justifyContent={"space-between"}>
            <Stack direction="row" alignItems={"center"} spacing={1}>
                <IconButton size={"small"}>
                    <DragIndicator />
                </IconButton>
                <strong>{name}</strong>
                {!params.colDef?.editable && <Lock color="disabled" fontSize="small" />}
            </Stack>

            <Stack sx={{ cursor: "pointer" }} alignItems={'center'} onClick={handleClick}>
                <ArrowDropDown fontSize={"medium"} color="primary" />
            </Stack>


            <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
                <MenuItem onClick={() => {
                    setIsEdit(true);
                    handleClose();
                }}>
                    <ListItemIcon>
                        <EditOutlined color="info" fontSize="small" />
                    </ListItemIcon>
                    Edit
                </MenuItem>
                <MenuItem onClick={() => {
                    deleteColumn();
                    handleClose();
                }}>
                    <ListItemIcon>
                        <DeleteOutline color="error" fontSize="small" />
                    </ListItemIcon>
                    Delete
                </MenuItem>
                <MenuItem onClick={() => {
                    updateColumnData(
                        colIndex,
                        !params.colDef?.editable,
                        "editable",
                        state
                    );
                    handleClose();
                }}>
                    {params.colDef?.editable
                        ?
                        <>
                            <ListItemIcon>
                                <LockOutlined color="error" />
                            </ListItemIcon>
                            Lock
                        </>
                        :
                        <>
                            <ListItemIcon>
                                <LockOpenOutlined color="success" />
                            </ListItemIcon>
                            Unlock
                        </>
                    }
                </MenuItem>
            </Menu>
        </Stack>}


        {isEdit && <>
            <Input
                value={name}
                onChange={(e) => setName(e.target.value)}
                sx={{
                    ml: 1
                }}
            />
            <IconButton size="small" onClick={onSaveBtnClicked}><Save fontSize="small" /></IconButton>
            <IconButton size="small" onClick={onCancelBtnClicked} ><Cancel fontSize="small" /></IconButton>
        </>}
    </Stack>
};

const RenderTable: React.FC<IProps & { selected: GridRowSelectionModel; updateSelected: React.Dispatch<React.SetStateAction<GridRowSelectionModel>> }> = ({ index, category, size, orientation, showRuler, selected, updateSelected }) => {
    let paperDimensions: [number, number] = size?.value ?
        orientation?.value === "landscape"
            ? [PageSizes[size?.value as PageSize][1], PageSizes[size?.value as PageSize][0]]
            : PageSizes[size?.value as PageSize] : [0, 0];

    const dispatch = useAppDispatch();
    const { enqueueSnackbar } = useSnackbar();

    const { state, updateState } = React.useContext(CheckListContext);

    console.log("selected", selected)

    const handleDeleteRow = (rowId: string) => {
        dispatch(openConfirmationDialog({
            title: "Are you sure?",
            body: `Do you want to delete the row?`,
            positiveBtn: "Delete",
            negativeBtn: "Cancel",
            onOk: () => {
                let newCategories = copy(state.categories);
                newCategories[index].definition.rows = newCategories[index].definition.rows.filter((elem) => elem.id !== rowId).map((elem, id) => ({ ...elem, id: (id + 1).toString() }));
                updateState('categories', [...newCategories]);
                dispatch(closeConfirmationDialog());
            },
            onNegativeBtn: () => dispatch(closeConfirmationDialog())
        }))
    }

    const updateColumnData = (colIndex: number, value: string | number | boolean, key: keyof GridColDef, state: any) => {
        let newCategories = copy(state.categories);
        newCategories[index].definition.columns[colIndex][key] = value as never;
        updateState("categories", [...newCategories]);
    };

    const processRowUpdate = (newRow: GridRowModel) => {
        const updatedRow: any = { ...newRow, isNew: false };
        let newCategories = copy(state.categories);
        newCategories[index].definition.rows[updatedRow.id - 1] = updatedRow;
        updateState('categories', [...newCategories]);
        return updatedRow;
    };

    const onColumnReOrder = (params: GridColumnOrderChangeParams) => {
        let { oldIndex, targetIndex, column } = params;
        if (!oldIndex || !targetIndex) return;
        oldIndex--;
        targetIndex--;
        let newCategories = copy(state.categories);
        let targetColumn = newCategories[index].definition.columns[targetIndex];
        newCategories[index].definition.columns[targetIndex] = column;
        newCategories[index].definition.columns[oldIndex] = targetColumn;
        updateState("categories", newCategories);
    }

    const handleProcessRowUpdateError = React.useCallback((error: Error) => {
        console.error("error:", error);
        enqueueSnackbar(error.message, { variant: "error" });
        // eslint-disable-next-line
    }, []);

    // (70 for Actions + 45 for checkbox - 50 for s.no in pdf) = 115 - 50 = 65
    const left = paperDimensions[0] ? `${(paperDimensions[0] - 55) / 0.75}px` : 0;

    const PrintGuide = () => {
        return (
            <Box
                sx={{
                    position: 'absolute',
                    left: left,
                    top: 0,
                    height: '100%',
                    width: '1px',
                    backgroundColor: 'red',
                    zIndex: 1000,
                    pointerEvents: 'none',
                }}
            />
        );
    };

    const Columns = React.useMemo((): GridColDef[] => {
        let cols: GridColDef[] = [
            {
                field: 'actions',
                type: 'actions',
                headerName: 'Actions',
                width: 70,
                cellClassName: 'actions',
                sortable: false,
                resizable: false,
                hideSortIcons: true,
                disableColumnMenu: true,
                getActions: ({ id }) => {
                    return [
                        <GridActionsCellItem
                            icon={<DeleteOutline sx={{ height: "36px" }} color="error" />}
                            label="Delete"
                            onClick={(e: any) => {
                                if (e) {
                                    e?.preventDefault();
                                    e.defaultMuiPrevented = true;
                                }
                                handleDeleteRow(id.toString())
                            }}
                            color="inherit"
                        />,
                    ];
                },
                renderHeader: (params: GridColumnHeaderParams) => {
                    return (
                        <Box sx={{ bgcolor: `${category?.header_color ? `${category?.header_color} !important` : 'rgb(230, 229, 229) !important'}`, width: "100% !important", px: 1 }}>
                            <strong>Actions</strong>
                        </Box>
                    )
                },
            },
            ...category.definition?.columns?.map((_: GridColDef, idx: number) => {
                return {
                    ..._,
                    sortable: false,
                    hideSortIcons: true,
                    disableColumnMenu: true,
                    renderHeader: (params: GridColumnHeaderParams) => <CustomColumn params={params} colIndex={idx} updateColumnData={updateColumnData} index={index} header_color={category?.header_color} />,
                }
            }),
        ];

        if (showRuler && paperDimensions[0]) {
            cols = [...cols,  {
                field: "",
                minWidth: 0,
                width: 2,
                resizable: false,
                sortable: false,
                hideSortIcons: true,
                disableColumnMenu: true,
                renderCell: (params) => <PrintGuide />,
            }]
        }

        return cols;
        // eslint-disable-next-line
    }, [category.definition?.columns, paperDimensions, showRuler]);

    return <Box height={"100%"}>
        <StyledDataGrid
            saveLocal={false}
            initialState={{ pinnedColumns: { left: [GRID_CHECKBOX_SELECTION_COL_DEF.field, 'actions'] } }}
            onColumnWidthChange={(params: GridColumnResizeParams, event: any, details: any) => {
                updateColumnData(
                    // parseInt(params.colDef.headerClassName?.toString()?.split("-")?.[1] ?? "0"),
                    category.definition.columns.findIndex(col => col.headerClassName === params.colDef.headerClassName),
                    parseInt(params.width?.toString()),
                    "width",
                    state
                );
            }}
            classes={{
                'columnHeaderTitleContainerContent': 'checkListColumnHeaderTitleContent',
            }}
            sx={{
                '.MuiDataGrid-columnHeaderTitleContainer': {
                    width: "100% !important",
                },
                '.MuiDataGrid-columnHeaderTitleContainerContent': {
                    width: "100% !important",
                },
                '.MuiDataGrid-columnHeader': {
                    padding: "0 !important",
                },
                '.MuiDataGrid-columnHeaderCheckbox': {
                    bgcolor: `${category?.header_color ? `${category?.header_color} !important` : 'rgb(230, 229, 229) !important'}`,
                    paddingLeft: "5px !important",
                },
                position: "relative"
            }}
            editMode="row"
            columns={Columns}
            checkboxSelection
            onRowSelectionModelChange={(selected) => updateSelected(selected)}
            rows={category.definition?.rows ?? []}
            processRowUpdate={processRowUpdate}
            getRowHeight={() => 'auto'}
            onColumnOrderChange={onColumnReOrder}
            onProcessRowUpdateError={handleProcessRowUpdateError}
            hideFooter
            pagination={false}
        />
        <Typography variant="caption">* Double-click a cell to edit the row, then use the Tab key to move around the table. Press Enter to finish editing.</Typography>
    </Box>
};


export const CategoryListItem: React.FC<IProps> = ({ category, index, size, orientation, showRuler }) => {

    const dispatch = useAppDispatch();
    const { state, updateState } = React.useContext(CheckListContext);

    const [selected, updateSelected] = React.useState<GridRowSelectionModel>([]);

    const onDelSelectionClicked = (categoryName: string) => {
        dispatch(openConfirmationDialog({
            title: "Are you sure?",
            body: `Do you want to delete the category - "${categoryName}"?`,
            positiveBtn: "Delete",
            negativeBtn: "Cancel",
            onOk: () => {
                state.categories.splice(index, 1);
                updateState('categories', [...state.categories]);
                dispatch(closeConfirmationDialog());
            },
            onNegativeBtn: () => dispatch(closeConfirmationDialog())
        }))
    };

    const onDuplicateSelection = () => {
        let content = copy(category);
        updateState('categories', [...state.categories, { ...content, name: `Copy of ${content.name}` }]);
    };

    const updateCategory = (key: keyof CheckListCategories, value: any) => {
        state.categories[index][key] = value as never;
        updateState('categories', [...state.categories]);
    };

    const addColumn = () => {
        const count = (state.categories[index].definition.columns?.length ?? 0) + 1;
        let newCategories = copy(state.categories);
        newCategories[index].definition.columns.push({
            field: `column${count}`,
            headerName: `Column ${count}`,
            headerClassName: `column-${count - 1}`,
            width: 230,
            editable: true
        });
        updateState('categories', [...newCategories]);
    };

    const addRow = () => {
        const count = (state.categories[index].definition.rows?.length ?? 0) + 1;
        let obj: any = {};
        state.categories[index].definition.columns.forEach((col: any) => {
            obj[col.field] = ""
        })
        state.categories[index].definition.rows = [
            ...state.categories[index].definition.rows ?? [],
            { id: count.toString(), ...obj }
        ];
        updateState('categories', [...state.categories]);
    };

    const applyWidthHandler = () => {
        const category = state.categories[index].definition?.columns;
        const headers = category.map(c => c.headerName).join("");
        state.categories.forEach((cat, idx) => {
            if (idx !== index) {
                const currentHeaders = cat.definition.columns.map(c => c.headerName).join("");
                if (currentHeaders === headers) {
                    let mappedColumn = cat.definition.columns.map((c) => {
                        let index = category.findIndex(col => col.headerName === c.headerName);
                        if (index > -1) {
                            return {
                                ...c,
                                width: category[index].width
                            }
                        } else {
                            return c
                        }
                    })
                    cat.definition.columns = mappedColumn;
                }
            }
        });
        updateState('categories', [...state.categories]);
    };

    const deleteRows = () => {
        dispatch(openConfirmationDialog({
            title: "Delete",
            body: "Are you sure you want to deleted the selected rows",
            positiveBtn: "Delete",
            onOk: () => {
                state.categories[index].definition.rows = state.categories[index].definition.rows.filter(r => !selected.includes(r.id));
                updateState('categories', [...state.categories]);
                dispatch(closeConfirmationDialog());
            },
            onNegativeBtn: () => dispatch(closeConfirmationDialog())
        }))
    };

    return <Draggable draggableId={`category-${index}`} index={index}>
        {(provided: any) => (
            <Accordion variant="outlined" id="category-accordion">
                {/* Header  */}
                <AccordionSummary
                    ref={provided.innerRef}
                    {...provided.dragHandleProps}
                    {...provided.draggableProps}
                    expandIcon={<ExpandMore htmlColor="black" />}
                    sx={{
                        background: (theme) => theme.palette.background.default,
                        minHeight: "48px !important",
                        maxHeight: "48px !important",
                    }}
                >
                    <Stack direction={"row"} width={"100%"} alignItems={"center"} justifyContent={"space-between"}>
                        {/* Title */}
                        <Stack direction={"row"} spacing={1}>
                            <DragIndicator />
                            <Typography variant="body1">{category.name}</Typography>
                        </Stack>

                        <Stack direction={"row"}>
                            {/* Duplicate Selection Button */}
                            <IconButton onClick={onDuplicateSelection} size="small" id="copy-item"><ContentCopy fontSize="small" /></IconButton>

                            {/* Delete Selection Button */}
                            <IconButton onClick={(e) => onDelSelectionClicked(category.name)} size="small" ><DeleteOutline color="error" fontSize="small" /></IconButton>
                        </Stack>
                    </Stack>
                </AccordionSummary>

                {/* Content */}
                <AccordionDetails>
                    <Stack direction={"column"} spacing={1}>

                        {/* Category Details */}
                        <Grid container mt={0.5} spacing={2}>
                            <Grid item xs={12} sm={6} md={4}>
                                <TextField
                                    variant="outlined"
                                    size="small"
                                    fullWidth
                                    value={category.name}
                                    label={"Table Name"}
                                    onChange={(e) => updateCategory("name", e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                                <TextField
                                    id="table-description"
                                    variant="outlined"
                                    size="small"
                                    fullWidth
                                    value={category.description}
                                    label={"Description"}
                                    onChange={(e) => updateCategory("description", e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={3}>
                                <MuiColorInput
                                    label={"Table Header Color"}
                                    size={"small"}
                                    fallbackValue={"rgb(230, 229, 229)"}
                                    value={category.header_color || 'rgb(230, 229, 229)'}
                                    onChange={(color: string) => {
                                        if (matchIsValidColor(color)) {
                                            updateCategory("header_color", color)
                                        }
                                    }}
                                />
                            </Grid>
                        </Grid>

                        <Divider />

                        {/* Custom Table */}
                        <Stack direction={'row'} gap={2}>
                            {selected.length > 0 && <Button sx={{ width: 200 }} variant="outlined" color="error" onClick={deleteRows}>Delete ({`${selected.length}`})</Button>}
                            <Button sx={{ width: 200 }} variant="contained" onClick={addColumn}>Add Column</Button>
                            <Button sx={{ width: 200 }} variant="contained" onClick={addRow} id="add-item">Add Row</Button>
                            <Button sx={{ width: 200 }} variant="outlined" onClick={applyWidthHandler}>Resize Similar Tables</Button>
                        </Stack>

                        <RenderTable showRuler={showRuler} index={index} category={category} size={size} orientation={orientation} selected={selected} updateSelected={updateSelected} />
                    </Stack>
                </AccordionDetails>
            </Accordion>
        )}
    </Draggable >
}