import { createSlice } from '@reduxjs/toolkit'

interface initialStateType {
    open: boolean,
    children?: JSX.Element,
    component?: JSX.Element,
    direction: 'left' | 'top' | 'right' | 'bottom',
    title: string,
    btnName: string,
    variant: 'permanent' | 'persistent' | 'temporary',
    isLarge: boolean,
    onSave: () => void,
    onClose: () => void
}

const initialState: initialStateType = {
    open: false,
    direction:"right",
    title: "",
    variant: "temporary",
    btnName: "Close",
    isLarge: false,
    onSave: () => false,
    onClose: () => false
}

const drawerSlice = createSlice({
    name: 'drawer',
    initialState,
    reducers: {
        openDrawer(state, { payload }) {
            state.open = true;
            state.title = payload?.title ?? initialState.title;
            state.btnName = payload?.btnName ?? initialState.btnName;
            state.component = payload?.component ?? initialState.component;    
            state.onSave = payload?.onSave ?? initialState.onSave;    
            state.onClose = payload?.onClose ?? initialState.onClose;  
            state.isLarge = payload?.isLarge;
        },
        closeDrawer(state) {
            state.open = false;
            state = initialState;
        }
    },
})

export const { openDrawer, closeDrawer } = drawerSlice.actions;
export default drawerSlice.reducer;