import { Auth0Provider } from "@auth0/auth0-react";
import { LicenseInfo } from "@mui/x-data-grid-premium";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { registerLicense } from '@syncfusion/ej2-base';
import React from "react";
import { Outlet } from "react-router-dom";
import { AppAlert } from "./App.alert";
import { AppBackDrop } from "./App.backdrop";
import { AppConfirmationDialog } from "./App.confirmationDialog";
import { AppDialog } from "./App.dialog";
import { AppDrawer } from "./App.drawer";
import AppErrorBoundary from "./App.errorBoundry";
import { AppSocket } from "./App.socket";
import { AppTheme } from "./App.theme";
import { config } from "./config";
import { AppRedux } from "./redux";

registerLicense(config.sync_license_key);

LicenseInfo.setLicenseKey(config.mui_license_key);

export const AppLayout: React.FC = () => {

  // React.useEffect(() => {
  //   if (config.enable_amplitude === "true") {
  //     let win:any = window;
  
  //     win.amplitude.add(win.sessionReplay.plugin({ sampleRate: 1 }))
  //       .promise.then(function () {
  //         win.amplitude.add(win.amplitudeAutocapturePlugin.plugin());
  //         win.amplitude.init(config.amplitude_api_key);
  //       });
  //   }
  // }, []);

  return (
    <Auth0Provider
      domain={config.auth0_domain}
      clientId={config.auth0_client_id}
      authorizationParams={{
        redirect_uri: `${window.location.origin}/auth0`,
        audience: config.auth0_audience,
      }}
    >
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <AppRedux>
          <AppAlert>
            <AppSocket>
              <AppTheme>
                <AppErrorBoundary>
                  <AppConfirmationDialog>
                    <AppDialog>
                      <AppDrawer>
                        <AppBackDrop>
                          <Outlet />
                        </AppBackDrop>
                      </AppDrawer>
                    </AppDialog>
                  </AppConfirmationDialog>
                </AppErrorBoundary>
              </AppTheme>
            </AppSocket>
          </AppAlert>
        </AppRedux>
      </LocalizationProvider>
    </Auth0Provider>
  );
};