import { Box, SxProps, Theme } from "@mui/material";
import React from "react";

export const MyPaper: React.FC<{
  children?: JSX.Element;
  height?: any;
  shadow?: any;
  background?: any;
  padding?: any;
  borderRadius?: any;
  sx?: SxProps<Theme>
}> = ({
  children = <></>,
  height = "",
  shadow = "",
  background = "",
  padding = "",
  borderRadius = "",
  sx = {}
}) => {
  return (
    <Box
      id="paper"
      sx={{
        boxShadow: shadow ? shadow : "rgba(0, 0, 0, 0.16) 0px 1px 4px",
        background: (theme) =>
          background ? background : theme.palette.background.paper,
        borderRadius: borderRadius ? borderRadius : 2,
        padding: padding !== "" ? padding : 1.5,
        height: height ? height : "auto",
        overflow: "auto",
        ...sx,
      }}
    >
      {children}
    </Box>
  );
};
