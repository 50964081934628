import { ExpandLess, ExpandMore, Replay } from "@mui/icons-material";
import { Box, Button, Collapse, Divider, IconButton, LinearProgress, Stack, Typography } from "@mui/material";
import React from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { MyPaper } from "../../../components";
import { useAppDispatch, useAppSelector } from "../../../redux";
import { useLazyGetBOMCostByIdQuery } from "../../../redux/services";
import { openDialog } from "../../../redux/slices/dialog";
import { AppRoutes } from "../../../router/routes";
import { CalculateCost } from "./calculateCost";
import { convertNumberToCurrencyString, LocalStorageKeys } from "../../../utils";

const costingDetail = [
    { label: "BOM Assembly Total Cost", key: "total_assm_procs_cost" },
    { label: "Child BOM Total Cost", key: "total_child_bom_cost" },
    { label: "Material Total Cost", key: "total_child_material_cost" },
    { label: "Total Cost", key: "bom_cost" }
]

export const CostingDetails: React.FC<{ children?: JSX.Element, isViewOnly?: boolean, basicDetailsPath: string }> = ({ isViewOnly, basicDetailsPath }) => {

    const { bom } = useAppSelector(store => store);
    const [getBOMCosting, getBOMCostingData] = useLazyGetBOMCostByIdQuery();
    const dispatch = useAppDispatch();

    const [open, updateOpen] = React.useState(true);

    const navigate = useNavigate();

    const { bom_id = "" } = useParams();

    const location: any = useLocation();

    let paths = JSON.parse(localStorage.getItem(LocalStorageKeys.locationStatePaths) || "[]") || location.state?.paths || [];

    if (paths.length <= 0) {
        paths.push({ title: "BOMs", path: AppRoutes.bom });
    }

    const openBOMCostingTree = () => {
        if (bom_id) {
            navigate(AppRoutes.viewBomCostingTree(bom_id), {
                state: {
                    paths: [...paths, { path: basicDetailsPath, title: '' }]
                }
            })
        }
    };

    const openCostBreakup = () => {
        if (bom_id) {
            navigate(AppRoutes.viewBomCostBreakup(bom_id), {
                state: {
                    paths: [...paths, { path: basicDetailsPath, title: '' }]
                }
            })
        }
    };

    const openCalculateCost = () => {
        dispatch(openDialog({
            title: "Cost Calculate",
            body: <CalculateCost />,
            hideNegativeBtn: true,
            hidePositiveBtn: true,
            maxWidth: 'lg',
            enablePadding: false
        }))
    };

    const fetchBOMCosting = () => {
        if (bom.bomId) {
            getBOMCosting({ id: bom.bomId })
        }
    };

    React.useEffect(() => {
        fetchBOMCosting();
        // eslint-disable-next-line
    }, [bom.bomId])

    return <MyPaper padding={0}>
        <Box>
            {/* Heading */}
            <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"} sx={{ px: 2, py: 1, borderBottom: (theme) => `1px solid ${theme.palette.divider}` }}>

                <Typography variant="body1" fontFamily={"htrts_medium"}>
                    Costing Details
                </Typography>

                <Stack direction="row" spacing={1} alignItems={"center"}>
                    <Button disabled={!bom.bomId} title={"View BOM Tree (Beta)"} variant="outlined" onClick={openBOMCostingTree}>View BOM Tree <img style={{ marginLeft: "8px" }} src={'/beta.gif'} alt='In Beta' /></Button>
                    <Button disabled={!bom.bomId} variant="outlined" onClick={openCostBreakup}>View Cost Breakup</Button>
                    <Button disabled={!bom.bomId || bom?.released} variant="contained" onClick={openCalculateCost}>Calculate Cost</Button>
                    <IconButton size={"small"} onClick={(e) => updateOpen(!open)}>
                        {!open && <ExpandMore />}
                        {open && <ExpandLess />}
                    </IconButton>
                </Stack>
            </Stack>

            {/* Main Section */}
            <Collapse in={open} timeout="auto" unmountOnExit>
                {(getBOMCostingData.isLoading || getBOMCostingData.isFetching) && <LinearProgress />}

                <table style={{ padding: "8px" }}>
                    {costingDetail.map((costingDetail, index) => {
                        const currency = getBOMCostingData?.data?.[costingDetail.key]?.currency ?? "USD";
                        return <React.Fragment key={index}>
                            <tr style={{ marginTop: "16px" }}>
                                <td>
                                    <Typography variant="body2">{costingDetail.label}</Typography>
                                </td>
                                <td>
                                    <Box display={"flex"}>
                                        <span>:&nbsp;</span>
                                        <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                                            {convertNumberToCurrencyString(getBOMCostingData?.data?.[costingDetail.key]?.cost ?? 0, currency)}
                                        </Typography>
                                    </Box>
                                </td>
                                <td>
                                    <Typography variant="caption" >
                                        {currency}
                                    </Typography>
                                </td>
                                {index === 3 && <td>
                                    <IconButton size="small" onClick={() => fetchBOMCosting()}>
                                        <Replay color="info" fontSize="small" />
                                    </IconButton>
                                </td>}
                            </tr>

                            {index === 2 && <Divider />}
                        </React.Fragment>
                    })}
                </table>
            </Collapse>
        </Box>
    </MyPaper>
}