import CloseIcon from '@mui/icons-material/Close';
import { LoadingButton } from "@mui/lab";
import { Alert, AppBar, Box, Button, Dialog, DialogContent, DialogTitle, Divider, Grid, IconButton, Slide, Stack, Toolbar, Tooltip, Typography } from "@mui/material";
import { TransitionProps } from '@mui/material/transitions';
import copy from "fast-copy";
import { useSnackbar } from "notistack";
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { DeletePanel, HighlightSelectBox, ImageUploadList, MyPaper, RowRadioButtonsGroup, TextField } from "../../../components";
import { LinkUnlikeOnUpdate, SelectBoxOption } from "../../../interfaces";
import { useAppDispatch, useAppSelector } from "../../../redux";
import { useCreateBOMMutation, useDeleteAttachmentMutation, useGetUoMDropDownQuery, useUpdateBOMMutation, useUploadAttachmentMutation, useUploadBOMImageMutation } from "../../../redux/services";
import { BOMSliceInterface, reset, updateBOMSliceByKeyValue, updateErrors } from "../../../redux/slices/bom";
import { closeConfirmationDialog, openConfirmationDialog } from "../../../redux/slices/confirmationDialog";
import { clearSelectedChip } from '../../../redux/slices/utils';
import { AppRoutes } from "../../../router/routes";
import { MDCreate } from "../../mdCreate";
import { AssemblyProcess } from "../assemblyProcess";
import { CostingDetails } from "../costingDetails";
import { Notes } from "../notes";
import { DGTypeOptions, StatusOptions } from "../utils";
import { Sourcing } from '../sourcing';
import { closeBackdrop, openBackdrop } from '../../../redux/slices/backdrop';
import { closeDialog, openDialog } from '../../../redux/slices/dialog';
import { Attachment } from '../../../redux/slices/material';
import { DependencyChildren, PublishComponent } from '../../materials/dashboard';
import { PERMISSIONS } from '../../../router/permission';
import { HasAccess } from '../../../router/authorization';
import { WIPIcon } from '../../../images/svg/WIPIcon';
import { PublishedIcon } from '../../../images/svg/publishedIcon';

export const Transition = React.forwardRef(function Transition(props: TransitionProps & { children: React.ReactElement; }, ref: React.Ref<unknown>,) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const mandateKeys = ["mdgType", "bomShortDesc", "bomGroup", "bomTag", "status", "clientRefNo"];

export const BasicDetails: React.FC<{
    children?: JSX.Element,
    bomId: string,
    nounData: any,
    avoidNavigate?: boolean,
    updateSelected?: any,
    handleParentDialogClose?: any,
    groupData: any,
    supplierData: any,
    refetch: any,
    customAssociation?: {
        object_type: string,
        object_id: string
    },
    _id: string,
    version: string,
    associations: {
        object_type: 'bom' | 'document',
        object_details: {
            _id: string,
            sequence_id: string,
            version: string
        }[]
    }[],
    docNavigationPath: string,
    onSuccessfullBOMCreation?: (BOM: any) => any,
    avoidDocNavigation?: boolean,
    buttonPrefix?: string,
    note?: string,
    publishable?: boolean,
    basicDetailsPath: string
}> = ({
    bomId = "New",
    updateSelected = () => false,
    avoidNavigate = false,
    handleParentDialogClose,
    docNavigationPath,
    onSuccessfullBOMCreation = (BOM) => false,
    avoidDocNavigation = false,
    buttonPrefix = "",
    note = "",
    publishable = false,
    basicDetailsPath,
    ...props
}) => {

        const isEdit = bomId !== "New";

        const dispatch = useAppDispatch();
        const { bom, utils: { selectedChip }, notifications: { notifications } } = useAppSelector(store => store);
        const { perm } = useAppSelector(store => store.auth.userDetails);
        const navigate = useNavigate();
        const { enqueueSnackbar } = useSnackbar();

        const [createBOM, createBOMData] = useCreateBOMMutation();
        const [updateBOM, updateBOMData] = useUpdateBOMMutation();
        const regionData = useGetUoMDropDownQuery(null, { refetchOnMountOrArgChange: true, refetchOnFocus: true });
        const [postImage, postImageData] = useUploadAttachmentMutation();
        const [uploadBOMImage, uploadBOMImageData] = useUploadBOMImageMutation();
        const [deleteAttachment, deleteResponse] = useDeleteAttachmentMutation();

        const [open, setOpen] = React.useState(false);
        const [upload, setUpload] = React.useState<File | Attachment | null>(null);

        const creatable = React.useMemo(() => HasAccess(perm, PERMISSIONS.BOM_CREATE), [perm]);
        const updatable = React.useMemo(() => HasAccess(perm, PERMISSIONS.BOM_UPDATE), [perm]);

        const isViewOnly = isEdit ? !updatable : !creatable;

        const updateState = (key: string, value: any) => {
            if (key === "bomGroup") {
                dispatch(updateBOMSliceByKeyValue({ key: "bomTag", value: null }))
            }
            dispatch(updateBOMSliceByKeyValue({ key, value }));
        };

        const handleApply = () => setOpen(false);

        const handleClose = (generated?: string) => {
            if (generated && bom.bomDesc.length === 0) {
                enqueueSnackbar('Description is not yet generated', { variant: "warning" })
            } else if (generated && bom.bomDesc !== generated) {
                enqueueSnackbar('Generated Description is not yet applied', { variant: "warning" })
            } else {
                setOpen(false)
            }
        };

        const updateBOMImageUploadStatus = async (data: any) => {
            if (data?.length > 0) {
                if (data?.[0]?.url) {
                    await uploadBOMImage({ data: data?.[0], url: data?.[0]?.url, file: upload }).unwrap().finally(() => {
                        dispatch(closeBackdrop());
                    });
                }
            }
        };

        const uploadBOMImageHandler = (id: string) => {
            postImage({
                object_id: id,
                object_type: "bom",
                payload: [
                    {
                        _id: id,
                        file_name: (upload as File)?.name,
                    }
                ]
            }).unwrap().then((res: any) => {
                updateBOMImageUploadStatus(res);
            }).catch((err: any) => {
                console.error('err:', err)
            });
        };

        const isValid = () => {
            return new Promise((resolve) => {
                if (bom.uom?.value === 'length') {
                    mandateKeys.push('length');
                    mandateKeys.push('length_unit')
                } else if (mandateKeys.includes("length")) {
                    mandateKeys.splice(mandateKeys.indexOf('length'), 1);
                    mandateKeys.splice(mandateKeys.indexOf('length_unit'), 1);
                }

                if (bom.uom?.value === 'weight') {
                    mandateKeys.push('weight');
                    mandateKeys.push('weight_unit');
                } else if (mandateKeys.includes('weight')) {
                    mandateKeys.splice(mandateKeys.indexOf('weight'), 1);
                    mandateKeys.splice(mandateKeys.indexOf('weight_unit'), 1);
                }
                dispatch(updateErrors({
                    mandateKeys, callback: (updatedMaterial: BOMSliceInterface) => {
                        let isValid = true;
                        for (let index = 0; index < mandateKeys.length; index++) {
                            const key: any = mandateKeys[index];
                            const error: any = updatedMaterial.error;
                            if (error[key]) {
                                isValid = false;
                                enqueueSnackbar('Please fill mandatory fields (*)', { variant: "warning" });
                                break;
                            }
                        }
                        resolve(isValid)
                    }
                }));
            })
        };

        const onAddUpdateBtnClicked = async () => {
            const isValidData = await isValid();
            if (isValidData) {
                if (isEdit && bom?.released) {
                    dispatch(clearSelectedChip())
                    dispatch(openConfirmationDialog({
                        title: "Update Operation",
                        body: `Current revision for ${bomId} is already published. Do you want to create a new revision?`,
                        positiveBtn: "Check for dependencies",
                        negativeBtn: "Cancel",
                        onOk: () => openDependencyTable(),
                        onNegativeBtn: () => dispatch(closeConfirmationDialog())
                    }));
                } else if (isEdit) {
                    dispatch(clearSelectedChip())
                    dispatch(openConfirmationDialog({
                        title: "Update Operation",
                        body: <DeletePanel
                            message={`Are you sure want to overwrite?`}
                            warningMessage={`The dependent items of ${bomId}-r${props.version} will be updated with new changes`}
                        />,
                        positiveBtn: "Overwrite",
                        negativeBtn: "Cancel",
                        onOk: () => addEditBOM(false),
                        onNegativeBtn: () => dispatch(closeConfirmationDialog())
                    }));
                } else {
                    if (createBOMData.isSuccess && postImageData.isError && (upload as File)?.name) {
                        uploadBOMImageHandler(createBOMData?.data?._id ?? "");
                    } else {
                        addEditBOM(false);
                    }
                }
            }
        };

        const openDependencyTable = () => {
            dispatch(openConfirmationDialog({
                title: "Update Operation",
                body: <DeletePanel
                    message={props.associations ? props.associations.length === 0 ? 'No dependency found' : `Dependency Table of ${bomId}-r${props.version}` : "No dependency found"}
                    errorMessage={"Do you want to create new version?"}
                    errorMessageStacks={props.associations}
                />,
                positiveBtn: "New Version",
                negativeBtn: "Cancel",
                onOk: () => props.associations?.length > 0 ? openNewVersionDialog() : addEditBOM(true),
                onNegativeBtn: () => dispatch(closeConfirmationDialog())
            }));
        };

        const openNewVersionDialog = () => {
            dispatch(openConfirmationDialog({
                title: "Update Operation",
                body: <DeletePanel
                    message={`Select the dependencies of the newer version of ${bomId}-r${props.version}`}
                    errorMessage={""}
                    errorMessageStacks={props.associations}
                    warningMessage={"New version will be attached with selected dependencies"}
                    chipSelectable={true}
                />,
                positiveBtn: "Create",
                negativeBtn: "Cancel",
                onOk: () => addEditBOM(true),
                onNegativeBtn: () => dispatch(closeConfirmationDialog())
            }));
        };

        const updatePublishStatus = (row: BOMSliceInterface) => {
            dispatch(
                openConfirmationDialog({
                    title: "Publish Operation",
                    body: <DeletePanel
                        message={`Are you sure you want to publish the bom ${row?.sequenceId}?`}
                        warningMessage={`You will not be able to edit this revision after publishing. You can however create new revisions.`}
                    />,
                    positiveBtn: "Check for dependencies",
                    negativeBtn: "Cancel",
                    onOk: () => openDependencyTableForPublish(row),
                    onNegativeBtn: () => dispatch(closeConfirmationDialog())
                })
            );
        };

        const openDependencyTableForPublish = async (row: BOMSliceInterface) => {
            dispatch(closeConfirmationDialog())
            try {
                dispatch(openDialog({
                    title: "",
                    body: <DependencyChildren object_id={props?._id} object_type="bom" onPublish={() => proceedToPublish(row)} />,
                    hideNegativeBtn: true, hidePositiveBtn: true, enablePadding: false
                }));
            } catch (error) {
                console.error(`Unable to get the hierarchy: ${error}`);
                enqueueSnackbar("Error getting dependencies", { variant: "error" })
                dispatch(closeDialog())
            }
        };

        const proceedToPublish = (row: BOMSliceInterface) => {
            dispatch(openDialog({
                title: "",
                body: <PublishComponent {...({ ...row, sequence_id: row?.sequenceId as string, _id: props?._id })} refetch={props.refetch} object_type="bom" object_id={props?._id} />,
                hidePositiveBtn: true,
                hideNegativeBtn: true,
                enablePadding: false
            }));
        }

        const giveMeLinksAndUnlinks = (): LinkUnlikeOnUpdate => {
            let obj: LinkUnlikeOnUpdate = { link: [], unlink: [] };
            let lsChip: { [key: string]: string[] } = JSON.parse(localStorage.getItem('selectedChip') ?? "{}");
            props.associations.forEach(asso => {
                let linkedIds: string[] = [];
                let unlinkedIds: string[] = [];
                asso.object_details.forEach(od => {
                    if (lsChip[asso.object_type]?.includes(od._id)) {
                        linkedIds.push(od._id);
                    } else {
                        unlinkedIds.push(od._id);
                    }
                });
                obj.link.push({ object_ids: linkedIds, object_type: asso.object_type })
                obj.unlink.push({ object_ids: unlinkedIds, object_type: asso.object_type })
            })

            return obj;
        }

        const addEditBOM = (update_version: boolean = false) => {
            dispatch(closeConfirmationDialog());
            dispatch(openBackdrop(isEdit ? "Updating bom..." : "Creating bom..."))
            if (isEdit) {
                updateBOM({ id: props._id, payload: { ...bom, manual_desc_gen: bom?.mdgType === DGTypeOptions[1].value, ...giveMeLinksAndUnlinks(), }, update_version });
            } else {
                let payload = copy(bom);
                payload.link = [];
                if (props?.customAssociation?.object_id && props?.customAssociation?.object_type) {
                    payload.link = [{ object_ids: [props.customAssociation.object_id], object_type: props.customAssociation.object_type }]
                }
                payload.unlink = [];
                payload.manual_desc_gen = bom?.mdgType === DGTypeOptions[1].value;
                createBOM(payload);
            }
        };

        const giveMeErrorMessage = () => {
            let messageData: any = isEdit ? updateBOMData.error : createBOMData.error;
            return messageData?.data?.title ?? `Oops! Something went wrong, Unable to ${isEdit ? "Update" : "Create"} bom. Try Again Later!`
        };

        const giveMeButtonName = () => {
            if (createBOMData.isError) {
                return `${isEdit ? "Retry Update" : "Retry Create"}`
            }

            if (postImageData?.isError) {
                return `Re-upload BOM Image`
            }
            return `${isEdit ? "Update" : "Create"} ${buttonPrefix.length > 0 ? buttonPrefix : ""}`;
        };

        const onDelete = (file: Attachment | File) => {
            if ((file as Attachment)?._id) {
                deleteAttachment({ object_id: props?._id, object_type: "bom", attachment_id: (file as Attachment)?._id });
            } else {
                setUpload(null);
            }
        };

        const onUpload = (file: any) => {
            if (file) {
                setUpload(file)
            }
        };

        const onCancelBtnClicked = () => {
            let docNavArr = docNavigationPath.split('/');

            if (docNavArr[1] === 'product') {
                navigate(AppRoutes.viewProductBOM(docNavArr[2]))
            } else if (docNavArr[1] === 'project') {
                navigate(AppRoutes.viewProjectBOM(docNavArr[2]))
            } else if (docNavArr[1] === 'proposal') {
                navigate(AppRoutes.viewProposalBOM(docNavArr[2]))
            } else {
                navigate(AppRoutes.bom)
            }
        }

        React.useEffect(() => {
            if (createBOMData.isSuccess && (!(upload as File)?.name || uploadBOMImageData.isSuccess)) {
                enqueueSnackbar('BOM Created Successfully!', { variant: "success" });
                dispatch(closeBackdrop());
                let newBOMId = createBOMData.data?._id ?? "New";
                updateState("bomId", newBOMId);
                updateState("sequenceId", createBOMData.data?.sequence_id);

                if (avoidNavigate) {
                    updateSelected(["2"])
                } else {
                    navigate(docNavigationPath.replace("New", newBOMId));
                }

                dispatch(openConfirmationDialog({
                    title: "BOM Created",
                    body: <>
                        {`${createBOMData.data?.sequence_id}-r0 -  is the BOM ID, attach relevant documents `}
                        {avoidDocNavigation && <>later <Link to={AppRoutes.viewBomDocuments(newBOMId)} target='_blank'>here</Link> </>}
                        <span>.</span>
                    </>,
                    positiveBtn: "Ok",
                    onOk: () => {
                        dispatch(closeConfirmationDialog())
                        if (avoidNavigate) {
                            updateSelected(["2"])
                        } else {
                            navigate(docNavigationPath.replace("New", newBOMId));
                        }
                        onSuccessfullBOMCreation(createBOMData?.data?._id ?? {});
                    },
                    hideNegativeBtn: true
                }))
                dispatch(closeBackdrop());
            }

            if (createBOMData.isError) {
                enqueueSnackbar(giveMeErrorMessage(), { variant: "error" })
                dispatch(closeBackdrop());
                dispatch(closeDialog());
            }

            if (postImageData.isError || postImageData?.error) {
                enqueueSnackbar("Oops! Something went wrong, Unable to upload BOM Image", { variant: "error" })
                dispatch(closeBackdrop());
                dispatch(closeDialog());
            }

            if (createBOMData.isSuccess && !postImageData.isError && !postImageData.isLoading && !postImageData.isSuccess && (upload as File)?.name) {
                uploadBOMImageHandler(createBOMData.data?._id ?? "");
            }
            // eslint-disable-next-line
        }, [createBOMData.status, postImageData.status, uploadBOMImageData.status]);

        React.useEffect(() => {
            if (updateBOMData.isSuccess && (!(upload as File)?.name || uploadBOMImageData.data)) {
                enqueueSnackbar('BOM Updated Successfully!', { variant: "success" });
                dispatch(closeBackdrop());
                let newBOMId = updateBOMData.data?._id ?? bom.bomId;
                updateState("bomId", newBOMId);
                updateState("sequenceId", updateBOMData.data?.sequence_id);

                if (avoidNavigate) {
                    updateSelected(["1"])
                } else {
                    navigate(window.location.pathname);
                }
            }
            if (updateBOMData.isError) {
                let errorData: any = updateBOMData.error;
                enqueueSnackbar(errorData?.data?.title ?? 'Oops! Something went wrong unable to update BOM.', { variant: "error" })
                dispatch(closeDialog());
                dispatch(closeBackdrop());
            }

            if (postImageData.isError || postImageData?.error) {
                enqueueSnackbar("Oops! Something went wrong, Unable to upload BOM Image", { variant: "error" })
                dispatch(closeBackdrop());
                dispatch(closeDialog());
            }

            if (updateBOMData.isSuccess && !postImageData.isError && !postImageData.isLoading && !postImageData.isSuccess && (upload as File)?.name) {
                uploadBOMImageHandler(updateBOMData.data?._id ?? "");
            }
            // eslint-disable-next-line
        }, [updateBOMData.status, postImageData.status]);

        React.useEffect(() => {
            if (postImageData.isError) {
                enqueueSnackbar("Oops! Something went wrong, Unable to upload BOM Image", { variant: "error" })
            }
            // eslint-disable-next-line
        }, [postImageData.status]);

        React.useEffect(() => {
            localStorage.setItem('selectedChip', JSON.stringify(selectedChip));
            // eslint-disable-next-line
        }, [selectedChip]);

        React.useEffect(() => {
            if (bom.attachments) {
                setUpload(bom.attachments.at(-1) ?? null);
            }
        }, [bom?.attachments]);

        React.useEffect(() => {
            if (deleteResponse.isLoading) {
                dispatch(openBackdrop("Deleting bom image..."))
                return;
            }

            if (deleteResponse.isError) {
                enqueueSnackbar((deleteResponse?.error as any)?.data ?? "Unable to delete bom image.", { variant: "error" })
            }

            if (deleteResponse.isSuccess) {
                enqueueSnackbar("BOM image deleted successfully.", { variant: "success" })
                setUpload(null);
                props.refetch();
            }

            dispatch(closeBackdrop());
            // eslint-disable-next-line
        }, [deleteResponse.status]);

        const isLoading = createBOMData.isLoading || updateBOMData.isLoading;

        return <Stack spacing={2} mb={2}>
            {/* Basic Details Section */}
            <MyPaper padding={0}>
                <Stack justifyContent={"space-between"} height="inherit">
                    <Box>
                        {/* Heading */}
                        <Stack direction={"row"} justifyContent={"space-between"}>
                            <Typography sx={{ px: 2, py: 1 }}
                                variant="body1"
                                fontFamily={"htrts_medium"}>
                                Basic Details
                            </Typography>
                            {props?._id &&
                                bom?.released ? (
                                <Stack direction={"row"} alignItems={"center"}>
                                    <Typography variant="body2" color="textSecondary">Status: </Typography>
                                    <Button disableRipple disableFocusRipple sx={{ cursor: "default", '&:hover': { backgroundColor: 'transparent' } }}>
                                        <Tooltip
                                            title="Published"
                                            children={<Box width={30} height={30} alignItems={"center"} justifyContent={"center"}><PublishedIcon sx={{ width: "100%", height: "100%" }} /></Box>}
                                        />
                                    </Button>
                                </Stack>
                            ) : props?._id && (
                                <Stack direction={"row"} alignItems={"center"}>
                                    <Typography variant="body2" color="textSecondary">Status: </Typography>
                                    <Button disableRipple disableFocusRipple>
                                        <Tooltip
                                            title={!publishable ? "Not Published" : "Work in progress, click to publish the item"}
                                            children={
                                                <Box width={30} height={30} alignItems={"center"} justifyContent={"center"}><WIPIcon sx={{ width: "100%", height: "100%" }} onClick={() => !publishable ? false : updatePublishStatus(bom)} /></Box>
                                            }
                                        />
                                    </Button>
                                </Stack>
                            )
                            }
                        </Stack>

                        <Divider />

                        <Grid container p={2} spacing={2}>

                            {/* DG Type */}
                            {!isViewOnly && <Grid item xs={12}>
                                <RowRadioButtonsGroup
                                    label=""
                                    error={bom.error.mdgType}
                                    helperText={bom.error.mdgType ? "Please select BOM Description type" : ""}
                                    value={bom.mdgType}
                                    onChange={(e: any) => updateState("mdgType", e?.target?.value)}
                                    options={DGTypeOptions}
                                />
                            </Grid>}

                            {/* BOM Region */}
                            <Grid item xs={12} sm={4}>
                                <HighlightSelectBox
                                    id="bom-region"
                                    label={"Region"}
                                    margin={"none"}
                                    error={bom.error.region}
                                    helperText={bom.error.region ? "Please fill Region" : ""}
                                    value={bom.region}
                                    options={regionData?.currentData ?? []}
                                    loading={regionData?.isFetching}
                                    disabled={bom.mdgType === "Generate using DG"}
                                    onChange={(data: SelectBoxOption) => {
                                        updateState("region", data)
                                    }}
                                    viewOnly={isViewOnly}
                                />
                            </Grid>

                            {/* BOM Group */}
                            <Grid item xs={12} sm={4}>
                                <HighlightSelectBox
                                    id="bom-group"
                                    label={"BOM Group"}
                                    margin={"none"}
                                    required={true}
                                    error={bom.error.bomGroup}
                                    helperText={bom.error.bomGroup ? "Please fill BOM Group" : ""}
                                    value={bom.bomGroup}
                                    options={props.groupData?.currentData ?? []}
                                    loading={props.groupData?.isFetching}
                                    disabled={bom.mdgType === "Generate using DG"}
                                    onChange={(data: SelectBoxOption) => {
                                        updateState("bomGroup", data)
                                    }}
                                    viewOnly={isViewOnly}
                                />
                            </Grid>

                            {/* BOM Tag */}
                            <Grid item xs={12} sm={4}>
                                <HighlightSelectBox
                                    id="bom-tag"
                                    label={"BOM Tag"}
                                    margin={"none"}
                                    required={true}
                                    error={bom.error.bomTag}
                                    helperText={bom.error.bomTag ? "Please fill BOM Tag" : ""}
                                    value={bom.bomTag}
                                    loading={props.nounData?.isFetching}
                                    options={bom.bomGroup ? props.nounData?.currentData?.filter((_: any) => _.group_id === bom.bomGroup?.value) ?? [] : []}
                                    disabled={bom.mdgType === "Generate using DG"}
                                    onChange={(data: SelectBoxOption) => updateState("bomTag", data)}
                                    viewOnly={isViewOnly}
                                />
                            </Grid>

                            {/* BOM Short Description*/}
                            <Grid item xs={12} sm={4}>
                                <TextField
                                    id="bom-short-desc"
                                    variant="outlined"
                                    size="small"
                                    fullWidth
                                    required={true}
                                    error={bom.error.bomShortDesc}
                                    helperText={bom.error.bomShortDesc ? "Please fill BOM Short Description" : ""}
                                    value={bom.bomShortDesc}
                                    label={"BOM Short Description"}
                                    disabled={bom.mdgType === "Generate using DG"}
                                    onChange={(e) => updateState("bomShortDesc", e.target.value)}
                                    viewOnly={isViewOnly}
                                />
                            </Grid>

                            {/* Button */}
                            {bom.mdgType === "Generate using DG" && !isViewOnly && <Grid item xs={12}>
                                <Button id="generateUsingDGBtn" variant="outlined" size="medium" color="primary" sx={{ mt: 1 }} onClick={() => setOpen(true)}>Generate using DG</Button>
                            </Grid>}

                            {/* Divider */}
                            <Grid item xs={12}>
                                <Divider variant="fullWidth" />
                            </Grid>

                            {/* Client Reference Number */}
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    id="bom-reference_id"
                                    variant="outlined"
                                    size="small"
                                    fullWidth
                                    required={true}
                                    error={bom.error.clientRefNo}
                                    helperText={bom.error.clientRefNo ? "Please fill Reference Number" : ""}
                                    value={bom.clientRefNo}
                                    label={"Reference Number"}
                                    onChange={(e) => updateState("clientRefNo", e.target.value)}
                                    viewOnly={isViewOnly}
                                />
                            </Grid>

                            {/* Status */}
                            <Grid item xs={12} sm={6}>
                                <HighlightSelectBox
                                    id="bom-status"
                                    label={"Status"}
                                    margin={"none"}
                                    required={true}
                                    error={bom.error.status}
                                    helperText={bom.error.status ? "Please fill Status" : ""}
                                    value={bom.status}
                                    options={StatusOptions}
                                    onChange={(data: SelectBoxOption) => updateState("status", data)}
                                    viewOnly={isViewOnly}
                                />
                            </Grid>
                        </Grid>

                    </Box>
                </Stack>
            </MyPaper>

            {/* Upload Image */}
            <ImageUploadList
                title='BOM Image'
                disabled={createBOMData.isLoading || updateBOMData.isLoading || bom?.attachments?.[0]?.status === "notUploaded"}
                loading={(notifications?.["attachment-status"]?.[postImageData?.data?.[0]?._id]?.status !== "saved" && notifications?.["attachment-status"]?.[postImageData?.data?.[0]?._id]?.status !== "error") && Object.keys(notifications?.["attachment-status"]?.[postImageData?.data?.[0]?._id] || {}).length > 0}
                files={upload ? [upload] : []}
                onDelete={onDelete}
                onUpload={onUpload}
                stack={false}
                readOnly={isViewOnly}
            />

            {/* Costing Type */}
            <CostingDetails isViewOnly={isViewOnly} basicDetailsPath={basicDetailsPath} />

            {/* Assembly Process Section */}
            <AssemblyProcess isViewOnly={isViewOnly} />

            {/* Sourcing Details */}
            <Sourcing supplierData={props.supplierData} isViewOnly={isViewOnly} />

            {/* Notes Section */}
            <Notes isViewOnly={isViewOnly} />

            {/* Footer */}
            {!isViewOnly && <MyPaper>
                <Box>
                    {/* Alert Box */}
                    {(createBOMData.isError || updateBOMData.isError) && <Alert sx={{ mt: 2 }} severity="error">{giveMeErrorMessage()}</Alert>}

                    {/* Footer Buttons */}
                    <Stack direction={"row"} alignItems={"center"} justifyContent={"center"} spacing={1} p={1}>
                        {/* Clear All Button */}
                        <Button disabled={isLoading} variant="outlined" sx={{ width: 200 }} color="primary" onClick={() => dispatch(reset())} >
                            Clear All
                        </Button>

                        {/* Create Button */}
                        <LoadingButton id="u-c-btn" loading={isLoading} variant="contained" sx={{ width: 200 }} color="primary" onClick={() => onAddUpdateBtnClicked()}>
                            {giveMeButtonName()}
                        </LoadingButton>

                        {/* Cancel Button */}
                        <Button disabled={isLoading} variant="outlined" sx={{ width: 200 }} color="primary" onClick={() => avoidNavigate ? handleParentDialogClose() : onCancelBtnClicked()}>
                            Cancel
                        </Button>
                    </Stack>

                    {/* Alert Box */}
                    {note.length > 0 && <Alert sx={{ mt: 2 }} severity="info">{
                        note
                    }</Alert>}
                </Box>
            </MyPaper>}

            {/* MDG Dialog */}
            <Dialog
                maxWidth={"xl"}
                fullWidth={true}
                open={open}
                onClose={() => handleClose()}
                TransitionComponent={Transition}
            >
                {/* Ṭoolbar */}
                <DialogTitle sx={{ p: 0 }}>
                    <AppBar sx={{ position: 'relative' }}>
                        <Toolbar>
                            <IconButton
                                edge="start"
                                color="inherit"
                                onClick={() => handleClose()}
                                aria-label="close"
                            >
                                <CloseIcon />
                            </IconButton>
                            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                                Description Generator
                            </Typography>
                        </Toolbar>
                    </AppBar>
                </DialogTitle>

                {/* Description Generator */}
                <DialogContent sx={{ p: 0 }}>
                    <MDCreate for={"new_bom"} onClose={handleClose} onApply={handleApply} />
                </DialogContent>
            </Dialog>
        </Stack>
    }