import { Box, Drawer, Stack } from "@mui/material";
import React, { useCallback } from "react";
import { BreadScrumHeader, HighlightSelectBox, SelectBox, SimpleSideNavBar } from "../";
import { LayoutProps } from "../../interfaces";
import { useLocation, useNavigate } from "react-router-dom";
import { LocalStorageKeys } from "../../utils";

export const Layout: React.FC<LayoutProps> = ({
    history = [], currentPath = '', navBars = [], navBarTitle, mainPanel = <>Main Panel</>, otherBreadscrumProps = {}, hideNavBarByDefault = false, hideBreadScrum = false,
    showDropInsteadOfNavInMobile = false, navBarHasChild = false, showNavbarSearch = false, onClick = (nav) => false, selected = [], avoidNavigate = false, resizable = false,
    shouldAddStyle = true, hideSideNavBar = false,
    locationState, onBackBtnClick
}) => {

    const location = useLocation();
    const navigate = useNavigate();

    const [openWebNav, setOpenWebNavBar] = React.useState(!hideNavBarByDefault);
    const [openMobileNav, setOpenMobileNavBar] = React.useState(false);
    const [width, setWidth] = React.useState(0);

    const resizeRef = React.useRef<HTMLDivElement>(null);

    const toogleWebNav = () => { setOpenWebNavBar(!openWebNav) };
    const toogleMobileNav = () => { setOpenMobileNavBar(!openMobileNav) };

    const giveMeSelectDropDownValue = useCallback(() => {
        let selected = null;
        for (let index = 0; index < navBars.length; index++) {
            const nav = navBars[index];
            selected = nav?.subNav?.filter(_ => _.path === location?.pathname || _.path === location?.pathname + location?.search?.replaceAll('%20', ' '))?.map((_) => ({ value: `${_.id}:${nav.id}`, label: `${nav.title}: ${_.title}`, ..._ }))?.[0];
            if (selected) {
                break;
            }
        }
        return selected;
        // eslint-disable-next-line
    }, [location]);

    const giveMeDropDownValue = useCallback(() => {
        return navBars.filter(_ => _.path === location?.pathname || _.path === location?.pathname + location?.search?.replaceAll('%20', ' ')).map((_) => ({ value: _.id, label: _.title, ..._ }))[0]
        // eslint-disable-next-line
    }, [location])

    React.useEffect(() => {
        const element = resizeRef.current;
        const width = JSON.parse(localStorage.getItem(LocalStorageKeys.resizeSideNav) || "0");
        if (element) {
            element.style.width = `${width}px !important`;
            const handleResize = (event: any) => {
                console.log(event.detail);
            };

            element.addEventListener('resize', handleResize);

            const checkResize = (mutations: any[]) => {
                const el = mutations[0].target;
                const w = el.clientWidth;
                const h = el.clientHeight;

                const isChange = mutations
                    .map((m) => `${m.oldValue}`)
                    .some((prev) => prev.indexOf(`width: ${w}px`) === -1 || prev.indexOf(`height: ${h}px`) === -1);

                if (!isChange) { return; }

                localStorage.setItem(LocalStorageKeys.resizeSideNav, JSON.stringify(w));

                setWidth(w);

                const event = new CustomEvent('resize', { detail: { width: w, height: h } });
                el.dispatchEvent(event);

            };

            const observer = new MutationObserver(checkResize);
            observer.observe(element, { attributes: true, attributeOldValue: true, attributeFilter: ['style'] });

            return () => {
                observer.disconnect();
                element.removeEventListener('resize', handleResize);
            };
        }
    }, []);

    React.useEffect(() => {
        const width = JSON.parse(localStorage.getItem(LocalStorageKeys.resizeSideNav) || "0");
        setWidth(width);
    }, [])

    return <Box sx={{ height: "100%" }}>

        {/* Header */}
        {!hideBreadScrum && <BreadScrumHeader
            {...otherBreadscrumProps}
            openWebNav={openWebNav}
            toogleWebNav={toogleWebNav}
            toogleMobileNav={toogleMobileNav}
            history={history}
            currentPath={currentPath}
            onBackBtnClick={onBackBtnClick}
        />}

        {showDropInsteadOfNavInMobile && <Box sx={{ display: { xs: "block", sm: "none" }, paddingTop: 1, paddingRight: 1, paddingBottom: 2 }}>
            {!navBarHasChild && <HighlightSelectBox
                label={navBarTitle ? navBarTitle : ""}
                margin={"none"}
                required={true}
                value={giveMeDropDownValue()}
                options={navBars?.map(_ => ({ value: _.id, label: _.title, ..._ }))}
                onChange={(data: any) => {
                    if (data) {
                        navigate(data?.path);
                    }
                }}
            />}
            {navBarHasChild && <SelectBox
                label={navBarTitle ? navBarTitle : ""}
                value={giveMeSelectDropDownValue()}
                options={navBars?.map(_ => ({
                    value: _.id, label: _.title,
                    options: _?.subNav?.map(nav => ({ value: `${nav.id}:${_.id}`, label: nav.title, ...nav }))
                }))}
                onChange={(data: any) => {
                    if (data) {
                        navigate(data?.path);
                    }
                }}
            />}
        </Box>}

        {/* Panel */}
        <Stack direction={"row"} sx={{ height: "calc(100% - 30px)", transition: 'all 1s', paddingTop: hideBreadScrum ? 0 : 1 }}>

            {/* Side Nav */}
            {!hideSideNavBar && <>
                {navBars.length > 0 && openWebNav &&
                    <Stack
                        height={"100%"}
                        ref={resizeRef}
                        sx={{ resize: resizable ? "horizontal" : "none", overflow: "hidden", transform: "rotateX(180deg)", width: resizable && width ? width : resizable ? { xs: 300, md: 350, lg: 400 } : { xs: 300, md: 350, lg: 400 }, display: { xs: "none", sm: "block" } }}
                    >
                        <Stack height={"100%"} sx={{ transform: "rotateX(180deg)" }} width="100%" position={"relative"}>
                            <SimpleSideNavBar
                                navBars={navBars}
                                variant={"whitecard"}
                                title={navBarTitle}
                                showSearch={showNavbarSearch}
                                avoidNavigate={avoidNavigate}
                                onClick={onClick}
                                selected={selected}
                                shouldAddStyle={shouldAddStyle}
                                locationState={locationState}
                            />
                        </Stack>
                    </Stack>
                }
            </>}

            {/* Main Panel */}
            <Stack 
                sx={{ pl: { xs: 0, sm: openWebNav && navBars.length > 0 ? 1 : 0 }, width: { xs: '100%', sx: resizable ? `calc(100vw - ${openWebNav ? width : 0}px) !important` : "100%" } }} height={"100%"} overflow={"auto"}>
                {mainPanel}
            </Stack>
        </Stack>

        {/* Mobile Drawer */}
        <Drawer anchor="left" open={openMobileNav} onClose={toogleMobileNav}>
            <SimpleSideNavBar
                navBars={navBars}
                avoidNavigate={avoidNavigate}
                onClick={onClick}
                selected={selected}
                locationState={locationState}
            />
        </Drawer>
    </Box>
}