import { Alert, Box } from "@mui/material";
import { useSnackbar } from "notistack";
import React from "react";
import {
  matchPath,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { withNavBars } from "../../HOCs";
import { DataSheetPDFPreview, Layout } from "../../components";
import { Documents } from "../../components/documents";
import { SecondaryNavBars } from "../../interfaces";
import { useAppDispatch, useAppSelector } from "../../redux";
import {
  useGetGroupsDDQuery,
  useGetNounsDDQuery,
  useGetVendorsDDQuery,
  useLazyGetBOMByIdQuery,
  useLinkModuleMutation,
  useUnlinkModuleMutation,
} from "../../redux/services";
import { closeBackdrop, openBackdrop } from "../../redux/slices/backdrop";
import {
  linksData,
  reset,
  updateBOMSlice,
  initialState,
} from "../../redux/slices/bom";
import {
  closeConfirmationDialog,
  openConfirmationDialog,
} from "../../redux/slices/confirmationDialog";
import { AppRoutes } from "../../router/routes";
import { BasicDetails } from "./basicDetails";
import { DGTypeOptions, NavBars } from "./utils";
import { CapitalizeString, LocalStorageKeys } from "../../utils";
import { DocumentTree } from "./documentTree";
import { DataTableForm } from "../viewMaterial/dataSheet";
import { BOMTreePage } from "../viewBOMTree";
import { HasAccess } from "../../router/authorization";
import { PERMISSIONS } from "../../router/permission";

export const ViewBOM: React.FC<{
  children?: JSX.Element;
  currentPath: string;
  customAssociation?: { object_type: string; object_id: string };
  avoidNavigate?: boolean;
  handleParentDialogClose?: any;
}> = ({
  currentPath,
  avoidNavigate = false,
  handleParentDialogClose,
  ...props
}) => {

    const params = useParams();
    const navigate = useNavigate();
    const location: any = useLocation();
    const dispatch = useAppDispatch();
    const bomSliceData = useAppSelector((store) => store.bom);
    const { enqueueSnackbar } = useSnackbar();
    const { bom } = useAppSelector((store) => store);
    const { perm } = useAppSelector(store => store.auth.userDetails);

    const [getBOMById, bomData] = useLazyGetBOMByIdQuery({ refetchOnReconnect: true, refetchOnFocus: true });
    const nounData = useGetNounsDDQuery(null, { refetchOnMountOrArgChange: true, refetchOnFocus: true });
    const groupData = useGetGroupsDDQuery(null, { refetchOnMountOrArgChange: true, refetchOnFocus: true });
    const supplierData = useGetVendorsDDQuery(null, { refetchOnMountOrArgChange: true, refetchOnFocus: true });
    const [linkDocument] = useLinkModuleMutation();
    const [unlinkDocument] = useUnlinkModuleMutation();

    const [selected, updateSelected] = React.useState<string[]>(["1"]);

    let p_id: string = params?.id ?? "";
    let bomId: string = params?.bom_id ?? "New";

    const creatable = React.useMemo(() => HasAccess(perm, PERMISSIONS.BOM_CREATE), [perm]);
    const readable = React.useMemo(() => HasAccess(perm, PERMISSIONS.BOM_READ), [perm]);
    const updatable = React.useMemo(() => HasAccess(perm, PERMISSIONS.BOM_UPDATE), [perm]);
    const deletable = React.useMemo(() => HasAccess(perm, PERMISSIONS.BOM_DELETE), [perm]);
    const publishable = React.useMemo(() => {
      const primaryAccess = HasAccess(perm, PERMISSIONS.BOM_PUBLISH);
      if (props.customAssociation?.object_type === 'product') {
          return HasAccess(perm, PERMISSIONS.PRODUCT_PUBLISH) && primaryAccess
      }
      if (props.customAssociation?.object_type === 'proposal') {
          return HasAccess(perm, PERMISSIONS.PROPOSAL_PUBLISH) && primaryAccess
      }
      if (props.customAssociation?.object_type === 'project') {
          return HasAccess(perm, PERMISSIONS.PROJECT_PUBLISH) && primaryAccess
      }
      return primaryAccess
    }, [perm, props.customAssociation?.object_type]);

    const paths = JSON.parse(localStorage.getItem(LocalStorageKeys.locationStatePaths) || "[]") || location.state?.paths || [];

    if (paths.length <= 0) {
      paths.push({ title: "BOMs", path: AppRoutes.bom });
    }

    if (bomId === "New" && bomSliceData.bomId.toString().length > 0) {
      bomId = bomSliceData.bomId.toString();
    }

    const isEdit = bomId !== ":bom_id" && bomId !== "New";

    const updateOnTabClick = (nav: SecondaryNavBars) => {
      updateSelected([nav.id]);
    };

    const giveMeLinksAndUnlinks = (links: any[], unlinks: any[], object_type: string): linksData => {
      let obj: linksData = {
        links: [{ object_ids: links, object_type: object_type }],
        unlinks: [{ object_ids: unlinks, object_type: object_type }],
      };
      return obj;
    };

    const callLinkDocument = async (object_type: string, links?: any[], unlinks?: any[], refetch?: any) => {
      let action = (unlinks || [])?.length > 0 ? "Unlinking" : "Linking";
      let actionSuccess = (unlinks || [])?.length > 0 ? "Unlinked" : "Linked";
      let actionError = (unlinks || [])?.length > 0 ? "Unlink" : "Link";
      dispatch(openBackdrop(`${action} the ${object_type}...`));
      const linkedData = giveMeLinksAndUnlinks(links || [], unlinks || [], object_type);
      let res: any = {};
      try {
        if (action === "Linking") {
          res = await linkDocument({ object_id: bomId, object_type: "bom", payload: { links: linkedData.links } })
        } else {
          res = await unlinkDocument({ object_id: bomId, object_type: "bom", payload: { unlinks: linkedData.unlinks } });
        }

        if (Object.keys(res).includes("data")) {
          enqueueSnackbar(`${actionSuccess} ${object_type} Successfully!`, { variant: "success" })
          dispatch(closeConfirmationDialog());
          if (isEdit) {
            getBOMById({ id: bomId });
          }
          refetch && refetch();
        } else if (Object.keys(res).includes("error")) {
          // eslint-disable-next-line
          throw res.error.data;
        }
      } catch (error: any) {
        let errorMessage: string =
          error?.title ?? error ?? `Oops! Something went wrong, Unable to ${actionError} ${object_type}`;
        enqueueSnackbar(errorMessage, { variant: "error" });
      } finally {
        dispatch(closeBackdrop());
      }
    };

    const getSheetPath = (sheet_id: string) => {
      let sheetPath = AppRoutes.viewBomDataSheet(bomId, sheet_id);
      switch (props?.customAssociation?.object_type) {
        case "project":
          sheetPath = AppRoutes.viewBOMProjectDataSheet(p_id, bomId, sheet_id);
          break;
        case "proposal":
          sheetPath = AppRoutes.viewBOMProposalDataSheet(p_id, bomId, sheet_id);
          break;
        case "product":
          sheetPath = AppRoutes.viewBOMProductDataSheet(p_id, bomId, sheet_id);
          break;
        default:
          break;
      }
      return sheetPath;
    };

    const getPaths = () => {
      const sheet_id = bomData?.data?.associations?.find((_: any) => _.object_type === "dataTableForm")?.object_details?.at(-1)?._id || "New";

      let basicPath = AppRoutes.viewBomBasicDetail(bomId);
      let docPath = AppRoutes.viewBomDocuments(bomId);
      let sheetPath = AppRoutes.viewBomDataSheet(bomId, sheet_id);
      let treePath = AppRoutes.viewBomTree(bomId);
      let docTreePath = AppRoutes.viewDocTree(bomId);
      let detailsPreviewPath = AppRoutes.viewBOMDetailsPreview(bomId);
      switch (props?.customAssociation?.object_type) {
        case "project":
          basicPath = AppRoutes.createOrUpdateProjectBOMBasicDetail(p_id, bomId);
          docPath = AppRoutes.createOrUpdateProjectBOMDocuments(p_id, bomId);
          sheetPath = AppRoutes.viewBOMProjectDataSheet(p_id, bomId, sheet_id);
          treePath = AppRoutes.createOrUpdateProjectBOMTree(p_id, bomId);
          docTreePath = AppRoutes.createOrUpdateProjectDocTree(p_id, bomId);
          detailsPreviewPath = AppRoutes.viewProjectBOMDetailsPreview(p_id, bomId);
          break;
        case "proposal":
          basicPath = AppRoutes.createOrUpdateProposalBOMBasicDetail(p_id, bomId);
          docPath = AppRoutes.createOrUpdateProposalBOMDocuments(p_id, bomId);
          sheetPath = AppRoutes.viewBOMProposalDataSheet(p_id, bomId, sheet_id);
          treePath = AppRoutes.createOrUpdateProposalBOMTree(p_id, bomId);
          docTreePath = AppRoutes.createOrUpdateProposalDocTree(p_id, bomId);
          detailsPreviewPath = AppRoutes.viewProposalBOMDetailsPreview(p_id, bomId);
          break;
        case "product":
          basicPath = AppRoutes.createOrUpdateProductBOMBasicDetail(p_id, bomId);
          docPath = AppRoutes.createOrUpdateProductBOMDocuments(p_id, bomId);
          sheetPath = AppRoutes.viewBOMProductDataSheet(p_id, bomId, sheet_id);
          treePath = AppRoutes.createOrUpdateProductBOMTree(p_id, bomId);
          docTreePath = AppRoutes.createOrUpdateProductDocTree(p_id, bomId);
          detailsPreviewPath = AppRoutes.viewProductBOMDetailsPreview(p_id, bomId);
          break;
        default:
          break;
      }
      return [basicPath, docPath, sheetPath, treePath, docTreePath, detailsPreviewPath];
    };

    let [basicPath, docPath, sheetPath, treePath, docTreePath, detailsPreviewPath] = getPaths();

    const onAnnotate = (id: string, path: string) => {
      let routeState: any = {
        state: {
          paths:
            [...paths,
            { title: `${bomData?.data?.sequence_id ?? "..."}-r${bomData?.data?.version ?? 0}`, path: basicPath },
            { title: "Documents", path: path },
            ]
        },
      };
      if (props?.customAssociation?.object_type) {
        navigate(
          AppRoutes.nestedBOMDocumentAnnotateViewer(
            props?.customAssociation?.object_type,
            p_id,
            bomId,
            id
          ),
          { ...routeState }
        );
      } else {
        navigate(
          AppRoutes.bomDocumentAnnotateViewer(
            bomId,
            id
          ),
          { ...routeState }
        );
      }
    }

    const giveMeComponent = () => {
      // Basic Details
      if (
        matchPath(location.pathname, basicPath) ||
        (avoidNavigate && selected.includes("1"))
      ) {
        return (
          <BasicDetails
            refetch={() => isEdit && getBOMById({ id: bomId })}
            bomId={bomData?.currentData?.sequence_id}
            nounData={nounData}
            groupData={groupData}
            supplierData={supplierData}
            _id={bomData?.currentData?._id}
            version={bomData?.data?.version ?? 0}
            associations={bomData?.data?.associations ?? []}
            customAssociation={{
              ...props?.customAssociation,
              object_id: params?.id ?? "",
              object_type: props?.customAssociation?.object_type ?? "",
            }}
            avoidNavigate={avoidNavigate}
            updateSelected={updateSelected}
            handleParentDialogClose={handleParentDialogClose}
            docNavigationPath={docPath}
            publishable={publishable}
            basicDetailsPath={basicPath}
          />
        );
      }

      // Documents
      if (
        matchPath(location.pathname, docPath) ||
        (avoidNavigate && selected.includes("2"))
      ) {
        return (
          <Documents
            refetch={() => isEdit && getBOMById({ id: bomId })}
            Id={bomData?.data?.sequence_id}
            _id={bomData?.data?._id}
            objectType="bom"
            linkDocument={(links: any, refetch: any) =>
              callLinkDocument('document', links, [], refetch)
            }
            associations={bomData?.data?.associations ?? []}
            onAnnotate={(id: string) => onAnnotate(id, docPath)}
            showCreateBtn={!bom.released}
            showHelperBtn={false}
            hideDeleteBtn={bom.released}
            creatable={creatable}
            updatable={updatable}
            publishable={publishable}
            deletetable={deletable}
            readable={readable}
          />
        );
      }

      // Data Sheet
      if (matchPath(location.pathname, sheetPath) || (avoidNavigate && selected.includes("3"))) {
        return <DataTableForm type="datasheet" associations={bomData?.data?.associations ?? []} preview={bom.released || !updatable} refetch={() => isEdit && getBOMById({ id: bomId })} getPath={getSheetPath} linkDataTableTemplate={(links: any[], unlinks: any[]) => callLinkDocument('dataTableForm', links, unlinks)} />
      }

      // BOM Details Preview
      if (matchPath(location.pathname, detailsPreviewPath) || (avoidNavigate && selected.includes("4"))) {
        let uomValue = `${bom?.uom?.label} ${bom?.uom?.label === "length" ? `(${bom?.length} ${bom?.length_unit?.label})` : bom?.uom?.label === "weight" ? `(${bom?.weight} ${bom?.weight_unit?.label})` : ""}`;
        const basicDetails = {
          optionDetails: [
            { label: "BOM ID", value: bom?.sequenceId ?? "" },
            { label: "BOM Description", value: bom?.bomShortDesc ?? "" },
            { label: "Uom Type", value: uomValue ?? "" },
            { label: "Item Name", value: bom?.bomTag?.label ?? "" },
            { label: "Item Group", value: bom?.bomGroup?.label ?? "" },
          ],
          attribute_details: bom?.attribute_details ?? [],
          notes: bom?.notes ?? "",
          sequenceId: bom?.sequenceId ?? "",
          version: bom?.version ?? 0,
          associations: bom?.associations ?? [],
          manufactureNo: bom?.manufactureNo,
          supplierName: bom?.supplierName,
          manufactureName: bom?.manufactureName

        };
        return <DataSheetPDFPreview basicDetails={basicDetails} headerTitle={"BOM Preview Details"} title={`BOM Details Preview for ${basicDetails?.sequenceId || '...'}-r${basicDetails?.version ?? 0}`} />
      }

      // BOM Tree
      if (
        matchPath(location.pathname, treePath) ||
        (avoidNavigate && selected.includes("5.1"))
      ) {
        return (
          <BOMTreePage path={basicPath} />
        );
      }

      // Document Tree
      if (
        matchPath(location.pathname, docTreePath) ||
        (avoidNavigate && selected.includes("5.2"))
      ) {
        return (
          <DocumentTree
            bomId={bomId}
            bom={bomData?.data ?? {}}
            nounData={nounData}
            groupData={groupData}
            onAnnotate={(id: string) => onAnnotate(id, docTreePath)}
            path={basicPath}
            showCreateBtn={!bom.released}
          />
        );
      }
      return <></>;
    };

    React.useEffect(() => {
      if (bomId === ":bom_id") {
        dispatch(closeBackdrop());
        navigate(AppRoutes.bom);
      }
    });

    React.useEffect(() => {
      if (isEdit) {
        dispatch(openBackdrop("Fetching BOM Details..."));
        getBOMById({ id: bomId });
      } else {
        dispatch(reset());
        dispatch(closeBackdrop());
      }
      // eslint-disable-next-line
    }, [params]);

    React.useEffect(() => {
      const updateValue = async () => {
        if (
          isEdit &&
          bomData.isSuccess &&
          supplierData.isSuccess
        ) {
          const data = bomData.currentData ?? bomData.data;
          if (!data) {
            return;
          }
          const tag = { value: data.noun_id, label: data.noun_name };
          const group = { value: data.group_id, label: data.group_name };
          const region = { value: data?.uom_id, label: data?.uom_name };
          const supplierName = supplierData?.currentData?.filter((_: any) => _._id === data?.vendor?.vendor_id)?.[0] ?? null;

          dispatch(
            updateBOMSlice({
              ...initialState(),
              bomId,
              sequenceId: data.sequence_id,
              version: data?.version,
              mdgType: data.manual_desc_gen === false
                ? DGTypeOptions[0].value
                : DGTypeOptions[1].value,
              bomShortDesc: data.short_description,
              bomGroup: group,
              bomTag: tag,
              clientRefNo: data.reference_id,
              status: {
                value: data.status,
                label: CapitalizeString(data.status),
              },
              varientId: data.noun_variant_id,
              notes: data?.notes ?? "",
              assemblyProcess:
                data?.assm_procs?.map((_: any, index: number) => {
                  return {
                    _id: _?.child_id,
                    sl_no: index + 1,
                    process_name: _?.proc_name,
                    cost: _?.unit_cost?.cost,
                    currency: _?.unit_cost?.currency ? {
                      value: _?.unit_cost?.currency,
                      label: _?.unit_cost?.currency,
                    } : null,
                    qty: _?.quantity,
                    total_cost: _?.total_cost?.cost,
                  };
                }) ?? [],
              totalCost: data?.total_cost?.cost ?? 0,
              currency: data.currency_code
                ? { label: data.currency_code, value: data.currency_code }
                : undefined,
              sourceType: data.sourcing_type,
              supplierName: data.sourcing_type === "external_purchase" ? supplierName : null,
              manufactureNo:
                data.sourcing_type === "external_purchase"
                  ? data.vendor?.part_number
                  : "",
              manufactureName:
                data.sourcing_type === "external_purchase"
                  ? data.vendor?.manufacturer_name
                  : "",
              unitName: ["internal_purchase", "internal_manufactured"].includes(
                data.sourcing_type
              )
                ? data?.organization_unit?.unit_code
                : "",
              unitLocation: [
                "internal_purchase",
                "internal_manufactured",
              ].includes(data.sourcing_type)
                ? data?.organization_unit?.unit_location
                : "",
              otherDetails: [
                "internal_purchase",
                "internal_manufactured",
              ].includes(data.sourcing_type)
                ? data?.organization_unit?.other_details
                : "",
              attachments: data?.attachments ?? [],
              region,
              released: data?.released ?? false,
              associations: data?.associations ?? []
            })
          );
          dispatch(closeBackdrop());
        }
      };
      updateValue();
      // eslint-disable-next-line
    }, [bomData, supplierData]);

    React.useEffect(() => {
      if (!bomData.isFetching && bomData.isError) {
        enqueueSnackbar("404 - Unable to find the requested bom details", {
          variant: "error",
        });
        dispatch(
          openConfirmationDialog({
            title: "404 - Not Found",
            body: "Unable to find the requested material",
            positiveBtn: "Ok",
            onOk: () => {
              dispatch(closeConfirmationDialog());
              navigate(AppRoutes.allMaterials);
            },
            hideNegativeBtn: true,
          })
        );
        dispatch(closeBackdrop());
      }
      // eslint-disable-next-line
    }, [bomData.isError]);

    React.useEffect(() => {
      if (basicPath === AppRoutes.viewBomBasicDetail(bomId)) {
        localStorage.removeItem(LocalStorageKeys.locationStatePaths)
      }
      // eslint-disable-next-line
    }, [location.pathname]);

    React.useEffect(() => {
      if (location.state?.paths?.length > 0) {
        localStorage.setItem(
          LocalStorageKeys.locationStatePaths,
          JSON.stringify(location.state?.paths)
        );
      }
      // eslint-disable-next-line
    }, [p_id]);

    return (
      <Box p={0.5} height={"100%"}>
        <Layout
          history={[
            ...paths.map((_: any) => {
              return { label: _.title, onClick: () => navigate(_.path) };
            }),
            {
              label: isEdit
                ? `${bomData?.data?.sequence_id ?? "..."}-r${bomData?.data?.version ?? 0
                }`
                : "New",
              onClick: () => navigate(basicPath),
            },
          ]}
          currentPath={currentPath}
          navBars={NavBars(bomId, isEdit, [basicPath, docPath, sheetPath, treePath, docTreePath, detailsPreviewPath])}
          mainPanel={<>
            {/* Alert Box */}
            {bom.released && <Alert sx={{ mb: 1 }} severity="info">{
              "This item is published. Changes, overwrite or deleting is not allowed. Only revision is allowed."
            }</Alert>}
            {giveMeComponent()}
          </>}
          sideNavVariant={"whiteboard"}
          otherBreadscrumProps={{
            hideMenuBtn: paths.length > 1,
            showBackBtn: paths.length > 1,
          }}
          avoidNavigate={avoidNavigate}
          onClick={updateOnTabClick}
          selected={selected}
          locationState={paths}
          onBackBtnClick={paths[0]?.path ? () => navigate(paths[paths.length - 1]?.path) : undefined}
          resizable
        />
      </Box>
    );
  };

export default withNavBars(ViewBOM);
