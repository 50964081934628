import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { Box, Button, ButtonProps, Collapse, Grid, IconButton, Stack, Typography } from "@mui/material";
import React from "react";
import { MyPaper } from "../";

interface Action extends ButtonProps {
    title: string;
}

export const SectionPaper: React.FC<{
    children?: JSX.Element,
    title: string,
    subTitle?: string,
    isExpand?: boolean,
    primaryBtn?: Action,
    renderButtons?: JSX.Element[],
    hideExpandCollapse?: boolean,
    contentPadding?: number,
    px?: number,
    py?: number,
    height?: any
}> = ({
    title,
    subTitle = '',
    children,
    isExpand = true,
    primaryBtn,
    hideExpandCollapse = false,
    renderButtons = [],
    contentPadding = 2,
    px = 2,
    py = 1,
    height
}) => {

        const [open, updateOpen] = React.useState(isExpand);

        return <MyPaper padding={0} height={open ? height ? height + 48 : '' : ''}>
            <Box>
                {/* Heading */}
                <Grid container justifyContent={"space-between"} alignItems={"center"} sx={{ px, py, borderBottom: (theme) => `1px solid ${theme.palette.divider}` }}>
                    <Grid item xs={6} md={(renderButtons.length > 0 || primaryBtn) ? 4 : 10}>
                        <Stack direction={{ xs: "column", md: "row" }} spacing={{ xs: 0, md: 1 }} alignItems={{ xs: "flex-start", md: "center" }}>
                            <Typography variant="body1" fontFamily={"htrts_medium"}>{title}</Typography>
                            {subTitle && <Typography variant="body2" fontFamily={"htrts_regular"}>{subTitle}</Typography>}
                        </Stack>
                    </Grid>

                    <Grid item xs={6} md={(renderButtons.length > 0 || primaryBtn) ? 8 : 2}>
                        <Stack direction="row" spacing={1} justifyContent={"flex-end"} alignItems={"center"}>
                            {renderButtons}
                            {primaryBtn && <Button
                                id="primary-btn"
                                sx={{ width: 200 }}
                                variant={"outlined"}
                                {...primaryBtn}
                            >
                                {primaryBtn.title}
                            </Button>}
                            {!hideExpandCollapse && <Stack>
                                <IconButton size={"small"} onClick={(e) => updateOpen(!open)}>
                                    {!open && <ExpandMore />}
                                    {open && <ExpandLess />}
                                </IconButton>
                            </Stack>}
                        </Stack>
                    </Grid>


                </Grid>

                {/* Main Section */}
                {children &&
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box p={contentPadding} height={height} overflow={"scroll"}>
                            {children}
                        </Box>
                    </Collapse>
                }
            </Box>
        </MyPaper>
    }