import { createSlice } from "@reduxjs/toolkit";

interface INotification {
  [key: string]: {
    [key: string]: {
      content: string;
      subs: string[];
      status: string;
      message?: string;
    };
  };
}

interface initialStateType {
  notifications: INotification;
  showBadge: boolean;
}

const initialState: initialStateType = {
  notifications: {},
  showBadge: false,
};

const notificationsSlice = createSlice({
  name: "notifications",
  initialState,
  reducers: {
    notify(state, { payload }: { payload: any }) {
      if (!state.notifications[payload.name]) {
        state.notifications[payload.name] = {};
      }
      let oldState = state.notifications?.[payload.name]?.[payload.id] ?? {};
      state.notifications[payload.name][payload.id] = {
        ...oldState,
        ...payload,
      };
    },
    toggleBadge(state, { payload }: { payload: boolean }) {
      state.showBadge = payload;
    },
  },
});

export const { notify, toggleBadge } = notificationsSlice.actions;
export default notificationsSlice.reducer;
