import { AccountTree, ChevronRight, ExpandMore, StopSharp } from '@mui/icons-material';
import TreeItem, { TreeItemProps, treeItemClasses } from '@mui/lab/TreeItem';
import { Chip, CircularProgress, ListItemIcon, ListItemText, Menu, MenuItem, Stack, Tooltip, Typography } from "@mui/material";
import Collapse from '@mui/material/Collapse';
import { styled } from '@mui/material/styles';
import { TransitionProps } from '@mui/material/transitions';
import { animated, useSpring } from '@react-spring/web';
import React from 'react';

function TransitionComponent(props: TransitionProps) {
    const style = useSpring({
        from: {
            opacity: 0,
            transform: 'translate3d(20px,0,0)',
        },
        to: {
            opacity: props.in ? 1 : 0,
            transform: `translate3d(${props.in ? 0 : 20}px,0,0)`,
        },
    });

    return (
        <animated.div style={style}>
            <Collapse {...props} />
        </animated.div>
    );
}

export interface CustomTreeRightClickOpt {
    name: string,
    showIcon?: boolean,
    icon?: JSX.Element,
    onClick: (data: any) => void
}

export interface CustomTreeChips {
    title: string,
    color: "default" | "primary" | "secondary" | "error" | "info" | "success" | "warning",
    isOutlined?: boolean
}

interface CustomTreeItemInterfaceV2 extends TreeItemProps {
    isExpand: boolean,
    customTitle: string,
    customSubTitle?: string,
    tooltip?: string,
    selected: string,
    rightClickOptions?: CustomTreeRightClickOpt[],
    isLoading?: boolean
    hideExpandCollapseIcon?: boolean,
    disableRightClick?: boolean,
    icons?: JSX.Element,
    showIcons?: boolean,
    showChips?: boolean,
    chips?: CustomTreeChips[]
}

export const CustomTreeItemV2 = styled((props: CustomTreeItemInterfaceV2) => {

    const { hideExpandCollapseIcon, ...rest } = props;

    const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | HTMLLIElement | null>(null);

    const handleClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent> | React.MouseEvent<HTMLLIElement, MouseEvent>) => {
        event.preventDefault();

        if (props.disableRightClick) {
            return false;
        }

        if (props.selected === props.nodeId) {
            setAnchorEl(event.currentTarget);
        } else {
            setAnchorEl(null);
        }
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'treeItem-' + props.nodeId : undefined;

    return <>
        <TreeItem
            {...rest}
            TransitionComponent={TransitionComponent}
            sx={{
                '& .MuiTreeItem-content': { padding: 0, display: "inline-table", mr: 1 }
            }}
            label={
                <Stack direction={"row"} spacing={1} alignItems={"center"}>
                    <Tooltip title={props.tooltip ?? props.customTitle} placement='bottom' disableInteractive>
                        <Stack onContextMenu={handleClick} direction={"row"} justifyContent={"flex-start"} alignItems={"center"} gap={0.5} my={0.5}>
                            <Stack direction={"row"}>
                                {!props.isLoading && !hideExpandCollapseIcon && <>
                                    {!props.isExpand && <><ChevronRight fontSize='small' /><AccountTree color="primary" fontSize='small' /></>}
                                    {props.isExpand && <><ExpandMore fontSize='small' /><AccountTree color="primary" fontSize='small' /></>}
                                </>}
                                {!props.isLoading && hideExpandCollapseIcon && <StopSharp sx={{ fontSize: 16, marginLeft: "-14px" }} color="primary" fontSize='small' />}
                                {props.isLoading && <CircularProgress size={15} />}

                            </Stack>

                            <Stack direction={"row"} sx={{ whiteSpace: "nowrap" }} alignItems={"center"}>
                                <Typography variant='body2'>{props.customTitle}&nbsp;
                                    {props.customSubTitle && <Typography variant='caption'>{props.customSubTitle}</Typography>}
                                </Typography>&nbsp;
                                {props.showChips && props.chips?.length && <Stack direction={"row"} spacing={1}>
                                    {props.chips.map((chip, index) => <Chip sx={{ height: "16px", fontSize: "12px" }} key={index} color={chip.color} size='small' label={chip.title} variant={chip.isOutlined ? 'outlined' : 'filled'} />)}
                                </Stack>}
                            </Stack>
                        </Stack>
                    </Tooltip>

                    {props.showIcons && <Stack direction={"row"} spacing={0.5} justifyContent={"center"} alignItems="center" height={"100%"} sx={{ mb: "-7px !important" }}>
                        {props.icons}
                    </Stack>}
                </Stack>
            }
        />
        <Menu
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
        >
            {props.rightClickOptions?.map(option => (<MenuItem
                onClick={() => {
                    option.onClick(option);
                    setAnchorEl(null);
                }}>
                {option.showIcon && <ListItemIcon>{option.icon}</ListItemIcon>}
                <ListItemText>{option.name}</ListItemText>
            </MenuItem>))}
        </Menu>
    </>
})(({ theme }) => ({
    [`& .${treeItemClasses.iconContainer}`]: {
        display: "none !important",
        '& .close': {
            opacity: 0.3,
        },
    },
    [`& .${treeItemClasses.group}`]: {
        marginLeft: 34,
        paddingLeft: 2,
        borderLeft: `1px dashed ${theme.palette.primary.main}`,
    },
}));