import { DeleteOutline, HexagonOutlined } from "@mui/icons-material";
import { Alert, Box, Button, Chip, Grid, Stack, Tooltip, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useRef } from "react";
import { Assign, SectionPaper, SelectBox, UnAssign } from "../../components";
import { BOMTree } from "../../components/bomTree";
import { ReactSelectOption } from "../../interfaces";
import { useAppDispatch } from "../../redux";
import { AssignmentStatus, useLazyGetBOMByIdQuery, useLazyGetBOMsQuery, useUpdateAssignmentMutation, useUpdateBOMItemsMutation } from "../../redux/services";
import { closeBackdrop, openBackdrop } from "../../redux/slices/backdrop";
import { closeConfirmationDialog, openConfirmationDialog } from "../../redux/slices/confirmationDialog";
import { closeDialog, openDialog } from "../../redux/slices/dialog";
import { ViewMetaData } from "./viewMetaData";

export const BOMTreeForDrawIO: React.FC<{ children?: JSX.Element }> = (props) => {

    const dispatch = useAppDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const [getBOMById] = useLazyGetBOMByIdQuery();
    const [getBOMs, getBOMsData] = useLazyGetBOMsQuery();
    const [assignSequenceNumberQuery] = useUpdateAssignmentMutation({});
    const [updateBOMItems] = useUpdateBOMItemsMutation();
    const detailPanelRef = useRef<HTMLDivElement>(null);

    const [bom, setBom] = React.useState<ReactSelectOption>();
    const [state, setState] = React.useState<{ parentBOM?: any, bom?: any, childType?: "material" | "bom", childBOM?: any, childMaterial?: any, isEmpty: boolean, shape?: any }>({ isEmpty: true });
    const [selected, setSelected] = React.useState<any>({});
    const [reload, setReload] = React.useState<any>(false);
    const [height, setHeight] = React.useState<number>(300);

    const loadOption = async (search: string, loadedOptions: any) => {
        try {
            let page = Math.round(loadedOptions.length / 50);
            let options = await getBOMs({ page, pageSize: 50 }).unwrap();
            return { options: options.data.map((_: any) => ({ ..._, value: _._id, label: `${_.sequence_id}-r${_.version} (${_.short_description})` })), hasMore: options.data.length === 50 }
        } catch (error) {
            console.log('error:', error)
            return { options: [], hasMore: false }
        }
    };

    const onSerialNumberChanged = async (type: AssignmentStatus) => {
        if (state.bom) {
            if (type === 'assign') {
                dispatch(openBackdrop('Fetching updated BOM...'));
                let bom = await getBOMById({ id: state?.bom?.value }).unwrap();
                if (bom && state?.bom) {
                    let newState = {
                        ...state,
                        bom: { ...state.bom, ...bom },
                        childMaterial: state.childType === 'material' ? { ...state.childMaterial, ...bom?.bom_materials?.filter((_: any) => _.child_id === state.childMaterial?.value)?.[0] } : undefined,
                        childBOM: state.childType === 'bom' ? { ...state.childBOM, ...bom?.bom_children?.filter((_: any) => _.child_id === state.childBOM?.value)?.[0] } : undefined,
                    };

                    setState({ ...newState });
                    sendMessage({ ...newState, tagNumber: newState.childType === 'material' ? newState.childMaterial.serial_num : newState.childBOM.serial_num }, 'assignTagNo');
                }
                dispatch(closeBackdrop());
            } else if (state?.childType) {
                setState({
                    ...state,
                    childMaterial: state.childType === 'material' ? { ...state.childMaterial, serial_num: "-" } : undefined,
                    childBOM: state.childType === 'bom' ? { ...state.childBOM, serial_num: "-" } : undefined,
                })
                sendMessage({ ...state, tagNumber: "" }, 'assignTagNo');
            }
            setReload(true);
        }
    };

    const generateSerialNumbersForTemplate = async (
        id?: string | null,
        serial_num?: string,
        type: AssignmentStatus = "assign",
        isManual?: boolean
    ) => {
        dispatch(openBackdrop('Assigning Serial Number....'));
        const currentItem: { type: string, _id: string } = { type: "bom", _id: state?.bom?.value ?? "" };

        let object_type: string = "";
        let object_id: string = "";
        let child_id: string = "";

        if (state?.childType === 'bom') {
            let childBOM: any = state?.childBOM;
            object_type = 'bom';
            object_id = childBOM?._id ?? "";
            child_id = childBOM?.child_id ?? "";
        } else if (state?.childType === 'material') {
            let childMaterial: any = state?.childMaterial;
            object_type = 'material';
            object_id = childMaterial?._id ?? "";
            child_id = childMaterial?.child_id ?? "";
        }

        const selectedChild: {
            object_type: string,
            object_id: string,
            child_id: string,
            serial_num?: string
        } = { object_type, object_id, child_id };

        if (serial_num) {
            selectedChild.serial_num = serial_num;
        }

        await assignSequenceNumberQuery({
            id: id,
            status: type,
            manual_serial_num: isManual,
            payload: [
                {
                    object_type: currentItem?.type,
                    object_id: currentItem?._id,
                    children: [selectedChild],
                },
            ],
        }).then(async (res: any) => {
            if (res.error) {
                enqueueSnackbar(`Something went wrong unable to ${type === 'assign' ? "assign" : "un-assign"} serial number. Info: ${res?.error?.data?.title ?? "-"}`, { variant: "error" })
            } else {
                enqueueSnackbar(`${type === 'assign' ? "Assigned" : "Un Assigned"} Serial Number`, { variant: "success" });
                dispatch(closeDialog());
                onSerialNumberChanged(type);
            }
        }).catch((err: any) => {
            console.log('err:', err)
            enqueueSnackbar('Something went wrong unable to assign serial number', { variant: "error" });
        });
        dispatch(closeBackdrop());
    };

    const handleAssignClick = () => {
        dispatch(
            openDialog({
                title: "",
                hideNegativeBtn: true,
                hidePositiveBtn: true,
                maxWidth: "md",
                enablePadding: false,
                body: <Assign onAssign={(id?: string | null, serial_num?: string, isManual?: boolean, type: AssignmentStatus = "assign") => generateSerialNumbersForTemplate(id ?? "", serial_num ?? "", type, isManual)} />,
            })
        );
    };

    const onUnAssignClick = () => {
        dispatch(
            openDialog({
                title: "",
                hideNegativeBtn: true,
                hidePositiveBtn: true,
                body: (
                    <UnAssign
                        onCancel={() => dispatch(closeDialog())}
                        onUnassign={() => {
                            generateSerialNumbersForTemplate(
                                null,
                                tagNumber,
                                "unassign"
                            );
                        }}
                    />
                ),
            })
        );
    };

    const sendMessage = (data: any, subEvent: string) => {
        const drawioContent: any = window.parent.window.parent.document.getElementById('drawio-editor-iframe');
        if (drawioContent) {
            drawioContent.contentWindow?.postMessage({ event: "iehub-plugin", subEvent, ...data }, "*");
        } else {
            console.log('drawio editor is not mapped');
        }
    };

    const constructPayload = (_: any) => {
        return {
            _id: _._id,
            child_id: _?.child_id,
            symbol_assigned: _?.symbol_assigned ?? false,
            symbol_id: _?.symbol_id ?? undefined
        }
    };

    const onAssignTagNoBtnClicked = () => {
        if (!state.bom && state.shape.value) {
            if (!window.confirm(`Are you sure, the existing value in the shape '${state.shape.value}' will be replaced by the tag number of selected BOM/Material?`)) {
                return false;
            }
        }
        
        sendMessage({
            parentBOM: state.parentBOM,
            bom: selected?.parentBOM?.sequence_id,
            bomId: selected?.parentBOM?._id,
            childMaterial: selected?.curItem?.material !== undefined ? selected?.curItem?.sequence_id : "",
            childMaterialId: selected?.curItem?.material !== undefined ? selected.curItem.child_id : "",
            childBOM: selected?.curItem?.bom !== undefined ? selected.curItem.sequence_id : "",
            childBOMId: selected?.curItem?.bom !== undefined ?  selected.curItem.child_id : "",
            tagNo: selected?.curItem?.serial_num,
            shape: state.shape
        }, "assign");

        updateBOMChild();
    };

    const updateBOMChild = async () => {
        let payload: { bom_children: any[], bom_materials: any[] } = { bom_children: [], bom_materials: [], };
        if (selected?.curItem?.bom) {
            payload.bom_children = selected?.parentBOM?.bom?.bom_children
                ?.map((_: any, position: number) => {
                    if (selected?.curItem?.child_id === _.child_id) {
                        _ = {
                            ..._,
                            symbol_assigned: true,
                            symbol_id: state.shape?.id ?? "Unknown"
                        };
                    }

                    return constructPayload(_);
                });
        } else if (selected?.curItem?.material) {
            payload.bom_materials = selected?.parentBOM?.bom?.bom_materials
                ?.map((_: any, position: number) => {
                    if (selected?.curItem?.child_id === _.child_id) {
                        _ = {
                            ..._,
                            symbol_assigned: true,
                            symbol_id: state.shape?.id ?? "Unknown"
                        };
                    }

                    return constructPayload(_);
                });
        }

        await updateBOMItems({
            id: selected?.parentBOM?._id ?? "",
            payload,
            update_version: false,
        })
        setReload(true);
    };

    const updateExistingDetails = async (data: any) => {

        const { shape, bom, bomId, childMaterial, childMaterialId, childBOM, childBOMId, parentBOM, unAssign } = data;

        if (shape?.id) {
            dispatch(openBackdrop('Fetching BOM Detail...'));

            if (bomId && bomId !== "null") {
                try {
                    const bomDetails = await getBOMById({ id: bomId }).unwrap();
                    let newState = { ...state };

                    if (bomDetails) {
                        let child: { childType?: 'bom' | 'material' } = {};

                        if (childMaterialId) {
                            child.childType = 'material'
                        } else if (childBOMId) {
                            child.childType = 'bom'
                        };

                        newState = {
                            ...state,
                            shape,
                            parentBOM,
                            bom: { value: bomId, label: bom, ...bomDetails },
                            childType: child.childType,
                            childMaterial: child.childType === "material" ? { value: childMaterialId, label: childMaterial, ...bomDetails.bom_materials?.filter((_: any) => _.child_id === childMaterialId)?.[0] } : undefined,
                            childBOM: child.childType === "bom" ? { value: childBOMId, label: childBOM, ...bomDetails.bom_children?.filter((_: any) => _.child_id === childBOMId)?.[0] } : undefined,
                            isEmpty: false
                        }
                        setState({ ...newState });
                    }

                    if (unAssign) {
                        unAssignSymbolFromChild(newState);
                        setState({ shape, parentBOM, isEmpty: false })
                    }
                } catch (err) {
                    console.log('err:', err)
                }
            } else {
                setState({
                    ...state,
                    bom: null,
                    childType: undefined,
                    childBOM: null,
                    childMaterial: null,
                    shape, parentBOM, isEmpty: false
                })
            }


            dispatch(closeBackdrop());
        } else {
            setState({
                ...state,
                bom: null,
                childType: undefined,
                childBOM: null,
                childMaterial: null,
                shape, parentBOM, isEmpty: false
            })
        }
        dispatch(closeDialog())
    };

    const tagNumber = React.useMemo(() => {
        if (state?.childBOM) {
            let childBOM: any = state.childBOM;
            return childBOM?.serial_num ?? "-";
        } else if (state?.childMaterial) {
            let childMaterial: any = state.childMaterial;
            return childMaterial?.serial_num ?? "-";
        } else {
            return "-"
        }
    }, [state?.childBOM, state?.childMaterial]);

    const messageHandler = (evt: MessageEvent) => {
        if (evt.data?.type === 'viewDetail') {
            updateExistingDetails({ ...evt.data });
        }
    };

    const technicalCard = () => {
        dispatch(openDialog({
            title: `Technical Card :  ${state.childType === 'material' ? `${state?.childMaterial?.label ?? "-"}` : `${state?.childBOM?.label ?? "-"}`}`,
            hideNegativeBtn: true,
            maxWidth: "md",
            hideCloseIcon: true,
            onOk: () => dispatch(closeDialog()),
            contentStyle: { overflowY: "hidden !important" },
            body: <ViewMetaData bomId={state.bom?.value} childBOMId={state.childBOM?.value} childMaterialId={state.childMaterial?.value} />,
        }))
    };

    const unAssignSymbolFromChild = async (state: any) => {
        await updateBOMItems({
            id: state.bom?.value ?? "",
            payload: state.childType === 'bom' ? {
                bom_children: state.bom.bom_children.map((_: any) => {
                    if (_.child_id === state.childBOM?.child_id) {
                        return constructPayload({ ..._, symbol_assigned: false, symbol_id: "" });
                    }
                    return constructPayload({ ..._ });
                })
            } : {
                bom_materials: state.bom.bom_materials.map((_: any) => {
                    if (_.child_id === state.childMaterial?.child_id) {
                        return constructPayload({ ..._, symbol_assigned: false, symbol_id: "" });
                    }
                    return constructPayload({ ..._ });
                })
            },
            update_version: false,
        });
        setReload(true);
    };

    const clearBOM = () => {
        dispatch(openConfirmationDialog({
            title: "Are you sure?",
            body: "Do you want to clear the assigned BOM from the shape?",
            onOk: () => {
                unAssignSymbolFromChild(state);
                dispatch(closeConfirmationDialog());
                sendMessage({ ...state }, 'unAssign');
            }
        }))
    };

    const clearChild = () => {
        dispatch(openConfirmationDialog({
            title: "Are you sure?",
            body: `Do you want to clear the assigned child ${state.childType === 'bom' ? 'BOM' : 'Material'} from the shape?`,
            onOk: () => {
                unAssignSymbolFromChild(state);
                dispatch(closeConfirmationDialog());
                sendMessage({ ...state }, 'unAssignChild');
            }
        }))
    };

    // Initial load
    React.useEffect(() => {
        window.addEventListener('message', messageHandler);
        sendMessage({}, 'iAmReady');
        return () => {
            window.removeEventListener('message', messageHandler);
        };
        // eslint-disable-next-line
    }, []);

    React.useEffect(() => {
        setHeight(detailPanelRef?.current?.clientHeight ?? 300)
    }, [detailPanelRef])

    React.useEffect(() => {
        if (state.shape?.id) {
            sendMessage({ id: state.shape.id }, 'isIamInSync')
        }
    }, [state.shape])

    return <Stack spacing={1} height={"100%"} p={1} onClick={() => sendMessage({}, 'onIframClick')}>
        {/* Shape Details */}
        <Box ref={detailPanelRef}>
            <SectionPaper
                title="Shape details"
                px={1} py={0} contentPadding={1}
                renderButtons={
                    [
                        <>
                            {!state.isEmpty && (state.childBOM || state.childMaterial) && !state?.shape?.isMulti && <Chip color="primary" size="small" sx={{ mt: '5px' }} label="View Detail" variant="outlined" onClick={technicalCard} />}
                        </>
                    ]
                }
            >
                <>
                    {state.isEmpty && <Alert severity="info">
                        No Shapes Selected
                    </Alert>}

                    {state?.shape?.isMulti && state?.shape?.shapes <= 30 && <Alert severity="warning">
                        Caution: Multiple Shapes selected {`(${state?.shape?.shapes ?? 2})`}, assigning a BOM/Material will apply to all the selected shapes.
                    </Alert>}

                    {state?.shape?.isMulti && state?.shape?.shapes > 30 && <Alert severity="error">
                        Warning: Multiple Shapes selected {`(${state?.shape?.shapes ?? 2})`}, you have selected more than 30 shapes. Please select less than 30 shapes to assign a BOM/Material.
                    </Alert>}

                    {!state.isEmpty && !state?.shape?.isMulti && <Grid container spacing={1}>
                        <Grid item xs={12} display={"flex"} gap={1}>
                            <Typography variant="body2" color={"primary"}>Shape ID:</Typography>
                            <Typography variant="subtitle2" sx={{ fontFamily: "htrts_semibold" }}>{state?.shape?.id ?? "-"}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="body2" color={"primary"}>BOM:</Typography>
                            {state?.bom && <Stack direction={"row"} alignItems={"center"} gap={1}>
                                <Typography variant="subtitle2" sx={{ fontFamily: "htrts_semibold" }}>
                                    {`${state?.bom?.label ?? "-"}-r${state?.bom?.version}`}
                                </Typography>
                                <Chip sx={{ height: "16px", fontSize: "12px" }} color={'default'} size='small' label={state?.bom?.short_description ?? ""} variant={'filled'} />
                                {state?.bom && <Tooltip title="Clear Assigned BOM" placement="top">
                                    <DeleteOutline onClick={clearBOM} color="error" fontSize="small" sx={{ cursor: "pointer" }} />
                                </Tooltip>}
                            </Stack>}

                            {!state.bom && <>-</>}
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="body2" color={"primary"}>Child {state.childType ? state.childType === 'bom' ? 'BOM' : 'Material' : "Material/BOM"}:</Typography>
                            {state.childType && < Stack direction={"row"} alignItems={"center"} gap={1}>
                                <Typography variant="subtitle2" sx={{ fontFamily: "htrts_semibold" }}>
                                    {state?.childType === 'material' && <>{state?.childMaterial?.label ?? "-"}</>}
                                    {state?.childType === 'bom' && <>{state?.childBOM?.label ?? "-"}</>}
                                    {!state?.childType && "-"}-r{state?.bom?.version}
                                </Typography>
                                <Chip sx={{ height: "16px", fontSize: "12px" }} color={'default'} size='small' label={state?.childType === 'material' ? state?.childMaterial?.material?.short_description ?? "" : state?.childBOM?.bom?.short_description ?? ""} variant={'filled'} />
                                {state.childType && <Tooltip title={`Clear Assigned Child ${state.childType === 'bom' ? 'BOM' : 'Material'}`} placement="top">
                                    <DeleteOutline onClick={clearChild} color="error" fontSize="small" sx={{ cursor: "pointer" }} />
                                </Tooltip>}
                            </Stack>}

                            {!state.childType && <>-</>}
                        </Grid>

                        {state?.childType && <Grid item xs={12} display={"flex"} gap={1}>
                            <Typography variant="body2" gutterBottom color={"primary"}>{"Tag Number: "}</Typography>
                            <Box>
                                <Typography variant="subtitle2" color={"darkgreen"} sx={{ fontFamily: "htrts_semibold" }}>{tagNumber}</Typography>
                                {(state.childBOM || state.childMaterial) && tagNumber === "-" && <Button sx={{ height: "24px" }} size="small" variant="outlined" color="info" onClick={handleAssignClick}>Assign Tag No.</Button>}
                                {(state.childBOM || state.childMaterial) && tagNumber !== "-" && <Button sx={{ height: "24px" }} size="small" variant="outlined" color="error" onClick={onUnAssignClick} >Un Assign Tag No.</Button>}
                            </Box>
                        </Grid>}
                    </Grid>}
                </>
            </SectionPaper>
        </Box>

        {/* BOM Tree */}
        {!state?.isEmpty && state?.shape?.shapes <= 30 && <SectionPaper title="BOM Tree" subTitle="" px={1} py={0} contentPadding={1} height={window.innerHeight - height}>
            <Stack spacing={1}>
                {!state.parentBOM && <SelectBox
                    isPaginate
                    loadOptions={loadOption}
                    label={"BOM"}
                    value={bom}
                    loading={getBOMsData.isLoading || getBOMsData.isFetching}
                    options={[]}
                    onChange={(data: ReactSelectOption) => setBom({ ...bom, ...data })}
                    isMenuFixed
                />}
                <BOMTree
                    bomId={state.parentBOM ? state.parentBOM : bom?.value}
                    otherRightClickOptions={(bom) => {
                        return [
                            {
                                name: bom.symbol_assigned ? "Re-assign to Shape" : "Assign to Shape",
                                showIcon: true,
                                icon: <HexagonOutlined fontSize="small" />,
                                onClick: () => onAssignTagNoBtnClicked()
                            }
                        ]
                    }}
                    reload={reload}
                    toggleReload={() => setReload(!reload)}
                    itemSelected={selected}
                    onItemSelected={(_selected: any) => setSelected(_selected)}
                    onSerialNumberChanged={(type: AssignmentStatus) => onSerialNumberChanged(type)}
                    defaultSelected={state.childType ? state.childType === 'material' ? state.childMaterial.child_id : state.childBOM.child_id : ''}
                    defaultSelectType={state?.childType ?? 'bom'}
                />
            </Stack>
        </SectionPaper>}
    </Stack>
}