import { Chip, Tooltip, Typography } from "@mui/material";
import { GridRenderCellParams } from "@mui/x-data-grid-pro";
import moment from "moment";

export const DashboardTableColumn = () => ([
    { field: 'ticketNo', headerName: 'Ticket No.' },
    {
        field: 'status', headerName: 'Status', width: 200, renderCell: (params: GridRenderCellParams) => {
            if (params.rowNode.type === 'group') {
                return params.value;
            }
            return <Tooltip title={"Status"}>
                <Chip variant="outlined" size={"small"} label={"Status"} sx={{ mr: 0.8 }} />
            </Tooltip>
        }
    },
    { field: 'subject', headerName: 'Subject', width: 250 },
    { field: 'description', headerName: 'Description', width: 500 },
    {
        field: 'created_at', headerName: 'Creation Date', width: 250, renderCell: (params: GridRenderCellParams) => {
            if (params.rowNode.type === 'group') {
                return params.value;
            }
            return <Typography variant="body2">{moment(params.row.created_at).format('lll')}</Typography>
        }
    },
    {
        field: 'updated_at', headerName: 'Modified Date', width: 250, renderCell: (params: GridRenderCellParams) => {
            if (params.rowNode.type === 'group') {
                return params.value;
            }
            return <Typography variant="body2">{moment(params.row.updated_at).format('lll')}</Typography>
        }
    }
]);

