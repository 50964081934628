import { ExpandLess, ExpandMore, KeyboardBackspace } from "@mui/icons-material";
import { Alert, Box, Button, Divider, IconButton, LinearProgress, Stack, Typography } from "@mui/material";
import { GRID_CHECKBOX_SELECTION_COL_DEF, GRID_REORDER_COL_DEF, GridEventListener, GridRowEditStopReasons, GridRowId, GridRowModes, GridRowModesModel, GridRowOrderChangeParams, GridRowSelectionModel } from "@mui/x-data-grid-premium";
import { useSnackbar } from "notistack";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Assign, MyPaper, UnAssign } from "../../components";
import { BOMTree } from "../../components/bomTree";
import { SelectBoxOption } from "../../interfaces";
import { BOMChild } from "../../interfaces/bom";
import { useAppDispatch, useAppSelector } from "../../redux";
import { useGetBOMSparePartsTagsQuery, useGetChildStatusTagsQuery, useLazyGetBOMByIdQuery, useLazyGetChildrenByIdQuery, useLinkChildsMutation, useUpdateBOMItemsMutation } from "../../redux/services";
import { AssignmentStatus, BOMChildren, useUpdateAssignmentMutation } from "../../redux/services/assignment";
import { closeBackdrop, openBackdrop } from "../../redux/slices/backdrop";
import { closeConfirmationDialog, openConfirmationDialog } from "../../redux/slices/confirmationDialog";
import { closeDialog, openDialog } from "../../redux/slices/dialog";
import { Attachment } from "../../redux/slices/material";
import { setOpenWebSideNavBar } from "../../redux/slices/utils";
import { HasAccess } from "../../router/authorization";
import { PERMISSIONS } from "../../router/permission";
import { CapitalizeString, LocalStorageKeys } from "../../utils";
import { LengthUnits, UOMOptions, WeightUnits } from "../viewMaterial/utils";
import { StyledDataGrid } from "../workbook/actions/actionTable";
import { LinkChildBOM } from "./linkBOM";
import { LinkChildMaterial } from "./linkMaterial";
import { Columns } from "./utils";
import './index.css'
import { LoadingButton } from "@mui/lab";

export type LinkDetail = { _id: string, qty: number, sequence_id: string, description: string };
export interface BOMStateInterface {
  type: "bom" | "material";
  _id: string;
  bomId?: string;
  materialId?: string;
  short_description?: string;
  group_name?: string;
  noun_name?: string;
  reference_id?: string;
  uom?: string;
  spare_part?: boolean;
  normal_quantity?: number;
  total_quantity?: number;
  cost?: number | null;
  currency?: string;
  childs: BOMStateInterface[];
  level?: number;
  index?: number[];
  serial_num?: string;
  spare_part_category?: string;
  isSpareEditable?: string;
  spare_part_quantity?: number;
  cost_override?: boolean;
  override_cost?: number;
  override_currency?: string;
  cost_per_qty?: number;
  sourcing_type?: string;
  child_id?: string;
  version: number;
  attachments: Attachment[];
  child_status?: string;
  released?: boolean,
  symbol_assigned?: boolean,
  symbol_id?: boolean,
  uom_type?: string
  weight?: string
  length?: string
  lead_time_days?: string
  position?: number;
}

export const BOMTreePage: React.FC<{ children?: JSX.Element, path: string }> = (props) => {

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const params = useParams();
  const { perm } = useAppSelector(store => store.auth.userDetails);

  const sparePartsData = useGetBOMSparePartsTagsQuery(null, { refetchOnMountOrArgChange: true });
  const bomStatusData = useGetChildStatusTagsQuery("bom", { refetchOnMountOrArgChange: true });
  const materialStatusData = useGetChildStatusTagsQuery("material", { refetchOnMountOrArgChange: true });

  const [selected, setSelected] = React.useState<{ curItem: BOMChild | null, parentBOM: BOMChild | null }>({ curItem: null, parentBOM: null });
  const [reload, setReload] = React.useState<any>(false);

  const resizeRef = React.useRef<HTMLDivElement>(null);

  const bomId = params.bom_id;

  const updatable = React.useMemo(() => HasAccess(perm, PERMISSIONS.MATERIAL_UPDATE), [perm]);

  const onSerialNumberChanged = async (type: AssignmentStatus) => {
    setReload(true);
  };

  React.useEffect(() => {
    dispatch(setOpenWebSideNavBar(false))
    const element = resizeRef.current;
    const width = JSON.parse(localStorage.getItem(LocalStorageKeys.resizeWidthBOM) || "350");
    if (element) {
      element.style.width = `${width}px !important`;
      const handleResize = (event: any) => {
        console.log(event.detail);
      };

      element.addEventListener('resize', handleResize);

      const checkResize = (mutations: any[]) => {
        const el = mutations[0].target;
        const w = el.clientWidth;
        const h = el.clientHeight;

        const isChange = mutations
          .map((m) => `${m.oldValue}`)
          .some((prev) => prev.indexOf(`width: ${w}px`) === -1 || prev.indexOf(`height: ${h}px`) === -1);

        if (!isChange) { return; }

        // Apply constraints
        // const constrainedWidth = Math.max(200, Math.min(500, w));
        // el.style.width = `${constrainedWidth}px`; // Ensure the element respects the constraints

        localStorage.setItem(LocalStorageKeys.resizeWidthBOM, JSON.stringify(w));

        const event = new CustomEvent('resize', { detail: { width: w, height: h } });
        el.dispatchEvent(event);
      };

      const observer = new MutationObserver(checkResize);
      observer.observe(element, { attributes: true, attributeOldValue: true, attributeFilter: ['style'] });

      return () => {
        observer.disconnect();
        element.removeEventListener('resize', handleResize);
      };
    }
    // eslint-disable-next-line
  }, []);

  return (
    <Box
      sx={{
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        background: (theme) => theme.palette.grey[100],
        padding: 1,
      }}
    >
      <Stack height={"100%"} spacing={1} direction="row">
        {/* Side Navbar */}
        <Stack
          height={"100%"}
          ref={resizeRef}
          sx={{ resize: "horizontal", overflow: "hidden", transform: "rotateX(180deg)", width: JSON.parse(localStorage.getItem(LocalStorageKeys.resizeWidthBOM) || "350") }}
        >
          <Stack height={"100%"} sx={{ transform: "rotateX(180deg)" }} width="100%" position={"relative"}>
            {/* Tree */}
            <MyPaper height={"100%"} sx={{ overflow: "hidden !important", display: 'flex', flexDirection: "column" }}>
              <>
                {/* Heading */}
                <Stack position={"relative"}>
                  <Box
                    width={"100%"}
                    display={"flex"}
                    justifyContent={"center"}
                    alignItems={"center"}
                    position={"relative"}
                    pb={2}
                  >
                    <IconButton
                      onClick={() => navigate(props.path)}
                      sx={{ position: "absolute", left: 0 }}
                    >
                      <KeyboardBackspace />
                    </IconButton>
                    <Typography
                      sx={{ fontWeight: "bold" }}
                      variant="body2"
                      color="textPrimary"
                    >
                      BOM Tree
                    </Typography>
                  </Box>

                  <Box
                    sx={{
                      position: "absolute",
                      left: 0,
                      right: 0,
                      bottom: 0,
                    }}
                  >
                    <Divider variant="fullWidth" />
                    {reload && <LinearProgress />}
                  </Box>
                </Stack>

                <Stack flexGrow={1} mt={1} overflow={"auto"}>
                  <BOMTree
                    bomId={bomId ? bomId : ""}
                    reload={reload}
                    toggleReload={() => setReload(!reload)}
                    itemSelected={selected}
                    onItemSelected={(_selected: any) => setSelected(_selected)}
                    onSerialNumberChanged={(type: AssignmentStatus) => onSerialNumberChanged(type)}
                  />
                </Stack>
              </>
            </MyPaper>
          </Stack>
        </Stack>

        {/* Right Panel */}
        <Stack pl={1} sx={{ width: `calc(100vw - ${JSON.parse(localStorage.getItem(LocalStorageKeys.resizeWidthBOM) || "350")}px)` }} height={"100%"} overflow={"auto"}>
          {selected.curItem !== null && selected.parentBOM !== null && (
            <Stack gap={1}>
              <MyPaper>
                <Table
                  id="parent"
                  type="bom"
                  title="Parent Part"
                  isShowbtn={false}
                  isEditable={false}
                  parentId={selected.parentBOM._id}
                  selectedItem={selected.curItem.child_id}
                  currentItem={selected.curItem}
                  sparePartsData={sparePartsData}
                  bomStatusData={bomStatusData}
                  materialStatusData={materialStatusData}
                  reloadTree={() => setReload(true)}
                />
              </MyPaper>
              <MyPaper>
                <Table
                  id="child_bom"
                  type="bom"
                  title="Child BOM"
                  btnName="Create/Link BOM"
                  isEditable={updatable}
                  isShowAssign={updatable}
                  parentId={selected.curItem.type === 'material' ? selected.parentBOM._id : selected.curItem._id}
                  selectedItem={selected.curItem.child_id}
                  currentItem={selected.curItem.type === 'material' ? selected.parentBOM : selected.curItem}
                  isShowbtn={selected.curItem.level !== 9 ? updatable : false}
                  sparePartsData={sparePartsData}
                  bomStatusData={bomStatusData}
                  materialStatusData={materialStatusData}
                  reloadTree={() => setReload(true)}
                />
              </MyPaper>
              <MyPaper>
                <Table
                  id="child_material"
                  type="material"
                  isShowbtn={updatable}
                  title="Child Material"
                  btnName="Create/Link Material"
                  isEditable={updatable}
                  isShowAssign={updatable}
                  parentId={selected.curItem.type === 'material' ? selected.parentBOM._id : selected.curItem._id}
                  selectedItem={selected.curItem.child_id}
                  currentItem={selected.curItem.type === 'material' ? selected.parentBOM : selected.curItem}
                  sparePartsData={sparePartsData}
                  bomStatusData={bomStatusData}
                  materialStatusData={materialStatusData}
                  reloadTree={() => setReload(true)}
                />
              </MyPaper>
            </Stack>
          )}
        </Stack>
      </Stack>
    </Box>
  );
};

const Table = ({
  id, title, type, isShowbtn, btnName, isEditable = true, isShowAssign, parentId,
  currentItem, sparePartsData, bomStatusData, materialStatusData, reloadTree, selectedItem
}: {
  id: string, currentItem: BOMChild, title: string, type: "bom" | "material", isShowbtn?: boolean, isShowAssign?: boolean,
  btnName?: string, isEditable?: boolean, sparePartsData: any, bomStatusData: any, materialStatusData: any, reloadTree: any,
  parentId: string, selectedItem: string
}) => {

  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const [assignSequenceNumberQuery, assignSequenceNumberData] = useUpdateAssignmentMutation({});
  const [updateBOMItems, updateBOMItemsData] = useUpdateBOMItemsMutation();
  const [updateBOM, updateBOMData] = useLinkChildsMutation();
  const [getBOM, getBOMData] = useLazyGetBOMByIdQuery();
  const [getCurrentBOM, getCurrentBOMData] = useLazyGetBOMByIdQuery();
  const [getChildren, { data: allData = { data: [], totalCount: 0 }, isLoading, isError, isFetching }] = useLazyGetChildrenByIdQuery();
  const { totalCount } = allData;

  const [open, updateOpen] = React.useState(true);
  const [calculatedHeight, setHeight] = React.useState(0);
  const [childs, setChilds] = React.useState<BOMChild[]>([]);
  const [actionType, setActionType] = React.useState<AssignmentStatus>("assign");
  const [selectedChild, setSelectedChild] = React.useState<BOMChild | null>(null);
  const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>({});
  const [paginationModel, setPaginationModel] = React.useState({ page: 0, pageSize: 10 });
  const [rowSelectionModel, setRowSelectionModel] = React.useState<GridRowSelectionModel>([]);

  const handleRowEditStop: GridEventListener<"rowEditStop"> = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleEditClick = (id: GridRowId) => () => {
    const field = Object.keys(rowModesModel || {})?.[0];
    if (!field) {
      setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
    }
  };

  const handleSaveClick = (id: GridRowId) => () => {
    const field = Object.keys(rowModesModel?.[id] || {})?.[0];
    if (field) {
      setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
    }
  };

  const handleDeleteClick = (id: string) => () => {
    dispatch(
      openConfirmationDialog({
        title: "Are you sure?",
        body: `Do you want to de-link this ${type}?`,
        positiveBtn: "Yes",
        negativeBtn: "No",
        onOk: () => unLinkChildsConfirm(id, type),
        onNegativeBtn: () => dispatch(closeConfirmationDialog()),
      })
    );
  };

  const unLinkChildsConfirm = async (id: string, type: "bom" | "material") => {
    dispatch(openBackdrop(`De-linking ${type}...`));
    let payload: {
      bom_children_unlink: string[];
      materials_unlink: string[];
      currency_code: string;
      reference_id?: string;
      short_description?: string;
    } = {
      bom_children_unlink: [],
      materials_unlink: [],
      currency_code: getCurrentBOMData?.data?.currency ?? "USD",
      reference_id: getCurrentBOMData?.data?.reference_id ?? "",
      short_description: getCurrentBOMData?.data?.short_description ?? "",
    };

    if (type === "bom") {
      payload.bom_children_unlink.push(id);
    } else if (type === "material") {
      payload.materials_unlink.push(id);
    }

    try {
      const res = await updateBOM({ id: currentItem?._id ?? "", payload, update_version: false });

      if (res.hasOwnProperty("error")) {
        // eslint-disable-next-line 
        throw (res as any).error;
      } else {
        getRows();
        reloadTree();
        enqueueSnackbar(`Successfully Removed the child`, { variant: "success" });
      }
    } catch (error: any) {
      enqueueSnackbar(`Unlinking Failed: ${error?.data?.title ?? "Something went wrong, Unable to"}`, { variant: "error" })
      console.error(`Error while unlinking children ${JSON.stringify(error, null, 2)}`)
    };
    dispatch(closeBackdrop());
    dispatch(closeConfirmationDialog());
  };

  const handleCancelClick = (id: GridRowId) => () => {
    const field = Object.keys(rowModesModel?.[id] || {})?.[0];
    if (field) {
      setRowModesModel({
        ...rowModesModel,
        [id]: { mode: GridRowModes.View, ignoreModifications: true },
      });
    }
  };

  const processRowUpdate = (newRow: BOMChild) => {
    setChilds(childs.map((child) => (child.child_id === newRow.child_id ? newRow : child)));
    updateBOMItemsHandler(childs, newRow);
    return newRow;
  };

  const constructBOMChildPayload = (_: BOMChild, position: number) => {
    return {
      _id: _._id,
      override_cost: _.override_cost,
      position: position + 1,
      normal_quantity: _.normal_quantity ?? 0,
      child_id: _?.child_id,
      serial_num: _?.serial_num,
      spare_part: typeof _.spare_part === "string" ? _.spare_part === "Yes" : _.spare_part,
      spare_part_quantity: _.spare_part_quantity || 0,
      serial_id: _?.[type]?.sequence_id,
      optional_info: _.optional_info,
      tags_info: [
        {
          tag_id: _.spare_part_category,
          tag_group_id: sparePartsData?.data?.sparePartTagGroup,
        },
        {
          tag_id: _.child_status,
          tag_group_id: _?.type === "bom" ? bomStatusData?.data?.statusTagGroup : materialStatusData?.data?.statusTagGroup,
        },
      ].filter(tags => tags?.tag_id),
    };
  };

  const updateBOMItemsHandler = async (childs: BOMChild[], newRow?: BOMChild) => {
    let payload: { bom_children: any[], bom_materials: any[] } = { bom_children: [], bom_materials: [], };

    let key: 'bom_children' | 'bom_materials' = 'bom_materials';
    if (type === "bom") {
      key = 'bom_children';
    }

    payload[key] = childs.map((_, position) => {
      if (newRow && newRow.child_id === _.child_id) _ = newRow;
      return constructBOMChildPayload(_, position);
    });

    try {
      const res = await updateBOMItems({ id: currentItem?._id ?? "", payload, update_version: false });
      if (res.hasOwnProperty("error")) {
        // eslint-disable-next-line 
        throw (res as any).error;
      } else {
        enqueueSnackbar('Updated Successfully!!!', { variant: "success" });
        reloadTree()
      }
    } catch (error: any) {
      enqueueSnackbar(`Updating children Failed: ${error?.data?.title ?? "Something went wrong, Unable to"}`, { variant: "error" })
      console.error(`Error while updating children ${JSON.stringify(error, null, 2)}`)
    };
  };

  const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
    if (Object.keys(rowModesModel || {}).length === 0) {
      setRowModesModel(newRowModesModel);
    }
  };

  const handleProcessRowUpdateError = React.useCallback((error: Error) => {
    console.error("error:", error);
    enqueueSnackbar(error.message, { variant: "error" });
    // eslint-disable-next-line
  }, []);

  const handleRowOrderChange = async (params: GridRowOrderChangeParams) => {
    dispatch(
      openConfirmationDialog({
        title: "Are you sure?",
        body: `Do you want to move this ${CapitalizeString(type)}?`,
        positiveBtn: "Move",
        negativeBtn: "Cancel",
        onOk: () => {
          const newRows: BOMChild[] = updateRowPosition(params.oldIndex, params.targetIndex, childs);
          setChilds([...newRows]);
          updateBOMItemsHandler(newRows);
          dispatch(closeConfirmationDialog());
        },
        onNegativeBtn: () => dispatch(closeConfirmationDialog()),
      })
    );
  };

  const constructSerialNumPayload = (child: BOMChild, serial_num?: string) => {
    const payload: BOMChildren = { object_type: type, object_id: child?._id, child_id: child?.child_id || "" };

    if (serial_num) {
      payload.serial_num = serial_num;
    }

    return payload;
  };

  const generateSerialNumbersForTemplate = async (id?: string | null, serial_num?: string, type: AssignmentStatus = "assign", isManual?: boolean) => {
    if (selectedChild) {
      setActionType(type);
      try {

        let message = actionType === "assign" ? "Assigning Serial Number..." : "Un assigning Serial Number...";
        dispatch(openBackdrop(message));

        const res: any = await assignSequenceNumberQuery({
          id: id, status: type, manual_serial_num: isManual, payload: [
            {
              object_type: currentItem?.type,
              object_id: currentItem?._id,
              children: [constructSerialNumPayload(selectedChild, serial_num)],
            },
          ],
        });

        if (res.hasOwnProperty("error")) {
          let message = actionType === "assign"
            ? (res?.error as any)?.status === 409 ? "Assigning serial number on published item is not allowed" : (res?.error as any)?.data?.title ?? "Assigning Serial Number Failed..."
            : (res?.error as any)?.status === 409 ? "Unassigning serial number on published item is not allowed" : (res?.error as any)?.data?.title ?? "Un assigning Serial Number Failed...";
          enqueueSnackbar(message, { variant: "error" });
        } else {
          getRows();
          reloadTree();
          setRowSelectionModel([]);
          enqueueSnackbar(`Serial Number ${CapitalizeString(type)}ed successfully!`, { variant: "success" });
        }
      } catch (error: any) {
        enqueueSnackbar(`${CapitalizeString(type)}ing Failed: ${error?.data?.title ?? "Something went wrong, Unable to"}`, { variant: "error" })
        console.error(`Error while ${type}inf children ${JSON.stringify(error, null, 2)}`)
      } finally {
        dispatch(closeBackdrop());
        dispatch(closeDialog());
      };
    }
  };

  const handleAssignClick = () => {
    dispatch(
      openDialog({
        title: "",
        hideNegativeBtn: true,
        hidePositiveBtn: true,
        enablePadding: false,
        body: <Assign onAssign={(id?: string | null, serial_num?: string, isManual?: boolean, type: AssignmentStatus = "assign") => generateSerialNumbersForTemplate(id ?? "", serial_num ?? "", type, isManual)} />,
        onClose: () => setRowSelectionModel([]),
      })
    );
  };

  const onUnAssignClick = () => {
    dispatch(
      openDialog({
        title: "",
        hideNegativeBtn: true,
        hidePositiveBtn: true,
        body: (
          <UnAssign onCancel={() => dispatch(closeDialog())} onUnassign={() => generateSerialNumbersForTemplate(null, selectedChild?.serial_num || "", "unassign")} />
        ),
      })
    );
  };

  const getRows = async () => {
    if (id === 'parent') {
      const { data } = await getBOM({ id: currentItem.type === 'material' ? parentId : currentItem._id });
      setChilds([{ ...data, bom: data }] as unknown as BOMChild[] ?? []);
    } else {
      const { data } = await getChildren({ id: currentItem?._id, item_type: type, ...paginationModel });
      setChilds(data?.data?.map(_ => {
        return {
          ..._,
          spare_part_category: sparePartsData.data?.spareParts.find((bom: SelectBoxOption) => _?.tags_info?.map((tag: any) => tag?.tag_id).includes(bom?._id))?._id,
          child_status: type === 'bom' ?
            bomStatusData?.data?.childStatus?.find((bom: SelectBoxOption) => _?.tags_info?.map((tag: any) => tag?.tag_id).includes(bom?._id))?._id
            : materialStatusData?.data?.childStatus?.find((material: SelectBoxOption) => _?.tags_info?.map((tag: any) => tag?.tag_id).includes(material?._id))?._id,
          uom_type: UOMOptions.find(u => u.value === _?.material?.uom_type)?.label || "",
          weight: _?.material?.weight ? `${_?.material?.weight} ${WeightUnits.find(u => u.value === _?.material?.units_weight)?.label || ""}` : "",
          length: _?.material?.length ? `${_?.material?.length} ${LengthUnits.find(u => u.value === _?.material?.units_length)?.label || ""}` : "",
        }
      }) as unknown as BOMChild[] ?? []);
    }
  };

  const openLinkBtn = () => {
    if (type === "bom") {
      dispatch(
        openDialog({
          title: "", hideNegativeBtn: true, hidePositiveBtn: true, maxWidth: "md", hideCloseIcon: true, enablePadding: false,
          body: (<LinkChildBOM id={parentId} associations={[]} callLinkDocument={(linkIds) => linkChilds(linkIds as LinkDetail[])} />),
        })
      );
    } else if (type === "material") {
      dispatch(
        openDialog({
          title: "", hideNegativeBtn: true, hidePositiveBtn: true, maxWidth: "md", hideCloseIcon: true, enablePadding: false,
          body: (<LinkChildMaterial id={parentId} associations={[]} callLinkDocument={(linkIds) => linkChilds(linkIds as LinkDetail[])} />),
        })
      );
    }
  };

  const linkChilds = async (linkIds: (LinkDetail & Partial<BOMStateInterface>)[], isCloning?: boolean) => {

    let payload: {
      bom_children_link?: any[];
      materials_link?: any[];
      currency_code: string;
      reference_id?: string;
      short_description?: string;
    } = {
      bom_children_link: [],
      materials_link: [],
      currency_code: getCurrentBOMData?.data?.currency ?? "USD",
      reference_id: getCurrentBOMData?.data?.reference_id ?? "",
      short_description: getCurrentBOMData?.data?.short_description ?? "",
    };

    if (type === "bom") {
      let count = childs.length ?? 0;
      payload.bom_children_link = linkIds.map((_) => {
        count = count + 1;
        let obj = typeof _ === "string" ? { _id: _ } : _;
        return {
          position: count,
          normal_quantity: _?.qty ?? _?.normal_quantity ?? 1,
          cost: _?.cost ?? 0,
          currency: _?.currency ?? "USD",
          spare_part: _?.spare_part ?? false,
          spare_part_quantity: _?.spare_part_quantity ?? 0,
          ...obj,
        };
      });
    } else if (type === "material") {

      let count = childs.length ?? 0;
      payload.materials_link = linkIds.map((_) => {
        count = count + 1;
        let obj = typeof _ === "string" ? { _id: _ } : _;
        return {
          ...obj,
          position: count,
          normal_quantity: _?.qty ?? _?.normal_quantity ?? 1,
          cost: _?.cost ?? 0,
          currency: _?.currency ?? "USD",
          spare_part: _?.spare_part ?? false,
          spare_part_quantity: _?.spare_part_quantity ?? 0,
        };
      });
    }

    await updateBOM({ id: parentId, payload, update_version: false }).unwrap().then(res => {
      enqueueSnackbar(`${isCloning ? "Cloned" : "Linked"} the child ${type} successfully!`, { variant: "success" });
      getRows()
      reloadTree()
    })
      .catch((error) => {
        enqueueSnackbar(`${isCloning ? "Cloning" : "Linking"} Failed: ${error?.data?.title ?? "Something went wrong, Unable to"}`, { variant: "error" })
        console.error(`Error while ${isCloning ? "cloning" : "linking"} children ${JSON.stringify(error, null, 2)}`)
      });
    dispatch(closeBackdrop());
  };

  const cloneChild = async (row: BOMChild) => {
    dispatch(openBackdrop(`Cloning the child ${row?.[type]?.sequence_id ?? type}....`))
    linkChilds([{ _id: row._id, qty: 1, sequence_id: row?.[type]?.sequence_id ?? "", description: row?.[type]?.short_description ?? "" }], true)
  }

  React.useEffect(() => {
    let key = Object.keys(rowModesModel || {})?.[0];
    if (key) {
      if (rowModesModel[key]?.mode === "view") {
        setRowModesModel({});
      }
    }
    // eslint-disable-next-line
  }, [rowModesModel]);

  React.useEffect(() => {
    if (rowSelectionModel.length === 1) {
      setSelectedChild(
        childs.find((c) => c?.child_id === rowSelectionModel[0]) || null
      );
    } else if (selectedChild) {
      setSelectedChild(null);
    }
    // eslint-disable-next-line
  }, [rowSelectionModel]);

  React.useEffect(() => {
    const topNavBar = document.getElementById("parent");
    const parentTable = document.getElementById("breadcrumbs");
    const handleResize = () => {
      setHeight((parentTable?.clientHeight ?? 0) + (topNavBar?.clientHeight ?? 0));
    };

    window.addEventListener('resize', handleResize);

    if (!calculatedHeight) {
      setHeight((parentTable?.clientHeight ?? 0) + (topNavBar?.clientHeight ?? 0));
    }

    return () => {
      window.removeEventListener('resize', handleResize);
    };
    // eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    getRows();
    // eslint-disable-next-line
  }, [paginationModel, currentItem]);

  React.useEffect(() => {
    getRows();
    // eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    if (id !== 'parent') {
      getCurrentBOM({ id: currentItem._id })
    }
    // eslint-disable-next-line
  }, [currentItem])

  return (
    <Stack flexGrow={1} sx={{ overflow: open ? "auto" : "hidden", maxHeight: `calc(100vh - ${calculatedHeight}px)`, height: open ? 'auto' : '30px' }} id={id ?? type}>
      <Box display={"flex"} alignItems={"center"} justifyContent={"space-between"} sx={{ mb: 1 }}>
        <Box display={"flex"} alignItems={"center"} gap={1}>
          <Typography sx={{ fontWeight: "bold" }} variant="body2" color="textPrimary">
            {title}
          </Typography>
          <Box display={"flex"} gap={1}>
            {isShowbtn && (
              <LoadingButton
                id="link-button"
                onClick={() => openLinkBtn()}
                sx={{ minWidth: "unset", width: "fit-content" }}
                variant="contained"
                size="small"
                loading={getCurrentBOMData.isFetching || getCurrentBOMData.isLoading}
                disabled={rowSelectionModel.length > 0}
              >
                {btnName}
              </LoadingButton>
            )}
            {isShowAssign && (
              <Button
                onClick={handleAssignClick}
                sx={{ minWidth: "unset", width: "fit-content" }}
                variant="contained"
                size="small"
                disabled={
                  rowSelectionModel.length !== 1 || !!selectedChild?.serial_num
                }
              >
                Assign
              </Button>
            )}
            {isShowAssign && (
              <Button
                onClick={onUnAssignClick}
                sx={{ minWidth: "unset", width: "fit-content" }}
                variant="contained"
                size="small"
                disabled={!selectedChild?.serial_num || !selectedChild}
              >
                Un Assign
              </Button>
            )}
          </Box>
        </Box>

        <IconButton size={"small"} onClick={(e) => updateOpen(!open)}>
          {!open && <ExpandMore />}
          {open && <ExpandLess />}
        </IconButton>
      </Box>

      {/* Error */}
      {(isError || getBOMData.isError) && <Alert sx={{ mt: 2 }} severity="error">Oops! Something went wrong, Unable to fetch. Try Again Later!</Alert>}

      <StyledDataGrid
        saveLocal={false}
        initialState={{
          pinnedColumns: {
            left: [GRID_REORDER_COL_DEF.field, GRID_CHECKBOX_SELECTION_COL_DEF.field, "actions", "sequence_id", "version", "short_description", "serial_num"]
          },
        }}
        sx={{
          '.MuiDataGrid-virtualScroller': {
            overflow: 'auto !important',
            height: "auto !important"
          }
        }}
        showToolbar
        rows={childs}
        columns={Columns(type, handleSaveClick, handleEditClick, handleCancelClick, handleDeleteClick, rowModesModel, isEditable, sparePartsData, bomStatusData, materialStatusData, cloneChild)}
        hideFooter={id === "parent"}
        getRowId={(row) => row?.child_id || row?._id}
        disableColumnMenu
        checkboxSelection={isEditable}
        onRowSelectionModelChange={(newRowSelectionModel) => setRowSelectionModel(newRowSelectionModel)}
        editMode="row"
        loading={
          assignSequenceNumberData.isLoading
          || isFetching || isLoading
          || getBOMData.isFetching || getBOMData.isLoading
          || updateBOMItemsData.isLoading
          || updateBOMData.isLoading
        }
        rowSelectionModel={rowSelectionModel}
        rowModesModel={rowModesModel}
        onCellDoubleClick={(e) => (e.isEditable = false)}
        onRowModesModelChange={handleRowModesModelChange}
        onRowEditStop={handleRowEditStop}
        processRowUpdate={processRowUpdate}
        onProcessRowUpdateError={handleProcessRowUpdateError}
        rowReordering={isEditable}
        onRowOrderChange={handleRowOrderChange}
        getRowClassName={(params) => {
          let id = params?.row?.child_id
          if (selectedItem) {
            return id === selectedItem ? `${id} highlight` : `${id}`
          }
          return `${id}`
        }}
        rowCount={totalCount}
        pagination={true}
        paginationModel={paginationModel}
        paginationMode="server"
        onPaginationModelChange={setPaginationModel}
      />
    </Stack>
  );
};

function updateRowPosition(
  initialIndex: number,
  newIndex: number,
  rows: BOMChild[]
) {
  const rowsClone = [...rows];
  const row = rowsClone.splice(initialIndex, 1)[0];
  rowsClone.splice(newIndex, 0, row);
  return rowsClone;
}
