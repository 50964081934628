import { Cancel, ContentCopy, DeleteOutline, Edit, OpenInNewOutlined, Save } from "@mui/icons-material";
import { Box, Stack, Tooltip } from "@mui/material";
import {
  GridEditSingleSelectCell,
  GridEditInputCell,
  GridColDef,
  GridRowModes,
  GridActionsCellItem,
  type GridRowModesModel,
  type GridEditInputCellProps,
  type GridEditSingleSelectCellProps,
} from "@mui/x-data-grid";
import { GridPreProcessEditCellProps, GridRenderCellParams } from "@mui/x-data-grid-premium";
import React from "react";
import { WIPIcon } from "../../images/svg/WIPIcon";
import { SelectBoxOption } from "../../interfaces";
import { Link } from "react-router-dom";
import { AppRoutes } from "../../router/routes";
import { PublishedIcon } from "../../images/svg/publishedIcon";

export const calculateMaterialTotalCost = (row: any) => {
  let normal_quantity = row?.normal_quantity ?? 0;
  let cost = row?.cost ?? 0;
  if ([true, "Yes"].includes(row.spare_part)) {
    let spare_part_quantity = row?.spare_part_quantity ?? 0;
    normal_quantity = row.spare_part_quantity
      ? spare_part_quantity + normal_quantity
      : normal_quantity;
  }
  if ([true, "Yes"].includes(row.cost_override)) {
    let cost_per_qty = row?.cost_per_qty ?? 0;
    return row.cost_per_qty ? cost_per_qty * normal_quantity : 0;
  } else {
    return normal_quantity * cost;
  }
};

export const enableSpareQtyBasedOnSparePart: React.FC<GridEditInputCellProps> = (
  params
) => {
  return (
    <GridEditInputCell
      {...params}
      disabled={![true, "Yes"].includes(params?.row?.spare_part)}
    />
  );
};

const enableCategoryBasedOnSparePart: React.FC<
  GridEditSingleSelectCellProps | GridEditInputCellProps
> = (params, sparePartsData) => {
  if (![true, "Yes"].includes(params?.row?.spare_part)) {
    return (
      <GridEditInputCell
        {...(params as GridEditInputCellProps)}
        disabled={![true, "Yes"].includes(params?.row?.spare_part)}
        value={
          sparePartsData.data?.spareParts?.find(
            (s: SelectBoxOption) => s?._id === params?.row?.spare_part_category
          )?.label ?? ""
        }
      />
    );
  }
  return (
    <GridEditSingleSelectCell
      {...(params as GridEditSingleSelectCellProps)}
      disabled={![true, "Yes"].includes(params?.row?.spare_part)}
    />
  );
};

export const Columns = (
  type: "bom" | "material",
  handleSaveClick: any,
  handleEditClick: any,
  handleCancelClick: any,
  handleDeleteClick: any,
  rowModesModel: GridRowModesModel,
  isEditable: boolean = false,
  sparePartsData: any,
  bomStatusData: any,
  materialStatusData: any,
  cloneElement?: (row: any) => void,
): GridColDef[] => {
  let rows: GridColDef[] = [
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 100,
      cellClassName: "actions",
      getActions: ({ id, row }) => {
        const isInEditMode =
          rowModesModel?.[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<Save />}
              label="Save"
              sx={{
                color: "primary.main",
              }}
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<Cancel />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }

        return [
          <GridActionsCellItem
            icon={<Edit />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
          cloneElement ? <GridActionsCellItem
            icon={<ContentCopy />}
            label="Edit"
            className="textPrimary"
            onClick={() => cloneElement(row)}
            color="inherit"
          /> : <></>,
          <GridActionsCellItem
            icon={<DeleteOutline />}
            label="Delete"
            onClick={handleDeleteClick(row.child_id)}
            color="inherit"
          />,
        ];
      },
    },
    {
      field: "sequence_id",
      headerName: type === "bom" ? "BOM ID" : "Material ID",
      width: 125,
      renderCell: (params: GridRenderCellParams) => {
        return <Link title="Click to open in new tab" target="_blank" to={type === "bom" ? AppRoutes.viewBomBasicDetail(params.row._id) : AppRoutes.viewMatBasicDetail(params.row._id)}>{type === "bom" ? params.row.bom.sequence_id : params.row.material.sequence_id} <OpenInNewOutlined sx={{ fontSize: "12px", marginBottom: "-2px" }} fontSize="small" /></Link>
      }
    },
    {
      field: "version",
      headerClassName: "bomHeader",
      headerName: "Revision",
    },
    {
      field: "short_description",
      width: 200,
      headerClassName: "bomHeader",
      headerName: "Short Description",
      valueGetter: (params) => params.row?.[type]?.short_description ?? ""
    },
    {
      field: "attachments",
      headerName: "Image",
      width: 150,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <Box>
            <img
              height={"50px"}
              width={"50px"}
              src={params.row?.[type]?.attachments?.at(-1)?.url ?? `/imageNotFound.jpg`}
              alt={params.row?.[type]?.attachments?.at(-1)?.file_name ?? ""}
            />
          </Box>
        )
      },
      valueGetter: (params) => params.row?.attachments?.at(-1)?.file_name || "",
    },
    {
      field: 'released',
      headerName: 'Status',
      width: 150,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <Stack px={3} py={2} id={`${type === "bom" ? "bom" : "material"}-publish-success`}>
            {params?.row?.[type]?.released
              ? <Tooltip title="Published" children={<div><PublishedIcon /></div>} />
              : <Tooltip title="Un Published" children={<div><WIPIcon /></div>} />}
          </Stack>
        )
      }
    },
    {
      field: "group_name",
      headerClassName: "bomHeader",
      headerName: "Group",
      valueGetter: (params) => params.row?.[type]?.group_name ?? ""
    },
    {
      field: "noun_name",
      headerClassName: "bomHeader",
      headerName: "Noun",
      valueGetter: (params) => params.row?.[type]?.noun_name ?? ""
    },
    {
      field: "reference_id",
      width: 150,
      headerClassName: "bomHeader",
      headerName: "Ref Number",
      valueGetter: (params) => type === 'bom' ? params.row?.[type]?.reference_id : params.row?.[type]?.external_ref_id ?? ""
    },
    {
      field: "symbol_assigned",
      width: 150,
      headerClassName: "bomHeader",
      headerName: "Symbol assigned",
      renderCell: (params: GridRenderCellParams) => {
        return (
          <Box>
            {params.row.symbol_assigned ? params.row.symbol_id : 'No'}
          </Box>
        )
      },
    },
    {
      field: "original_cost",
      headerClassName: "bomHeader",
      headerName: "Cost",
      valueGetter: (params) => `${params.row?.material?.money?.cost ?? "0"} ${params.row?.material?.money?.currency ?? "USD"}`,
    },
    {
      field: "serial_num",
      headerName: "Tag No.",
      valueGetter: (params) => params.row.serial_num,
      editable: false,
    },
    {
      field: "spare_part",
      type: "singleSelect",
      valueOptions: (params) => ["Yes", "No"],
      valueGetter: (params) =>
        [true, "Yes"].includes(params.row.spare_part) ? "Yes" : "No",
      headerName: "Spare Part",
      editable: isEditable,
    },
    {
      field: "spare_part_category",
      width: 180,
      type: "singleSelect",
      headerName: "Spare Part Category",
      editable: isEditable,
      description: sparePartsData.isLoading ? "Fetching Spare Parts" : "Spare Parts",
      valueOptions: (params) => sparePartsData.data?.spareParts ?? ["None"],
      valueGetter: (params) => {
        return params.row?.spare_part_category || null;
      },
      renderEditCell: (params) =>
        enableCategoryBasedOnSparePart(params, sparePartsData),
      preProcessEditCellProps: (params: GridPreProcessEditCellProps) => {
        const hasError = ![true, "Yes"].includes(params.otherFieldsProps?.spare_part?.value);
        return { ...params.props, value: hasError ? null : params?.props?.value };
      },
    },
    {
      field: "spare_part_quantity",
      type: "number",
      width: 125,
      editable: isEditable,
      headerName: "Spare Part Qty",
      renderEditCell: enableSpareQtyBasedOnSparePart,
      preProcessEditCellProps: (params: GridPreProcessEditCellProps) => {
        const hasError = ![true, "Yes"].includes(params.otherFieldsProps?.spare_part?.value);
        return { ...params.props, value: hasError ? 0 : params?.props?.value };
      },
    },
    {
      field: "normal_quantity",
      type: "number",
      editable: isEditable,
      headerName: "Qty",
      valueGetter: (params) => params?.row?.normal_quantity,
    },
    {
      field: "override_cost",
      type: "number",
      width: 180,
      editable: isEditable,
      headerName: "Override cost per qty",
    },
    {
      field: "currency",
      type: "number",
      width: 125,
      headerName: "Currency",
      valueGetter: (params) => params?.row?.[type]?.money?.currency ?? "USD",
    },
    {
      field: "child_status",
      width: 180,
      type: "singleSelect",
      headerName: type === "bom" ? "Child BOM Status" : "Child Material Status",
      editable: isEditable,
      description: bomStatusData.isLoading || materialStatusData.isLoading
        ? "Fetching Status"
        : "Status",
      valueOptions: (params) => type === "bom" ? bomStatusData.data?.childStatus ?? ["None"] : materialStatusData.data?.childStatus ?? ["None"],
      valueGetter: (params) => {
        return params.row?.child_status || null;
      },
    }
  ];

  if (type === "bom") {
    let index = rows.findIndex((r: GridColDef) => r.field === "original_cost");
    rows = [...rows.slice(0, index), ...rows.slice(index + 1)];
  }

  if (type === "material") {
    let index = rows.findIndex((r: GridColDef) => r.field === "original_cost");
    rows = [...rows.slice(0, index), rows[index], {
      field: "uom_type",
      headerClassName: "bomHeader",
      headerName: "UOM Type",
      valueGetter: (params) => params.row.uom_type,
    },
    {
      field: "length",
      headerClassName: "bomHeader",
      headerName: "Length",
      valueGetter: (params) => params.row.length,
    },
    {
      field: "weight",
      headerClassName: "bomHeader",
      headerName: "Weight",
      valueGetter: (params) => params.row.weight,
    },
    {
      field: "lead_time_days",
      headerClassName: "bomHeader",
      headerName: "Lead Time (Days)",
      width: 180,
      valueGetter: (params) => params.row.lead_time_days,
    }, ...rows.slice(index + 1)];
  }

  if (!isEditable) {
    rows.shift();
    rows = rows.slice(0, 9);
  }

  rows.push({
    field: "optional_info",
    headerClassName: "bomHeader",
    headerName: "Remarks",
    editable: isEditable,
  })

  return rows;
};