import React, { useEffect } from "react";
import { Alert, AppBar, Box, Dialog, IconButton, Toolbar, Typography } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { CustomDataGrid, LoadingSection } from "../../../components";
import { SelectBoxOption } from "../../../interfaces";
import { Transition } from "../../viewBOM/basicDetails";
import { ListViewTree } from "./utils";
import { DataTableForm } from "../../viewMaterial/dataSheet";
import { UUIDv4 } from "../../../utils";
import './preview.css';
import { ListViewChildProps } from "./index";
import { material_group, material_noun } from "../addEditListView";

export type ListType = "material" | "document";

export const RenderPreview: React.FC<ListViewChildProps> = ({ listViewResponse, sparePartsOptions, childStatusOptions, sparePartStatus, childStatus, openAssociation, openReferences }) => {

    const { data, isError, isFetching, isLoading, isSuccess, status } = listViewResponse;

    const [dataSheet, setDataSheet] = React.useState(null);

    const [calculatedRows, setCalculatedRows] = React.useState<any[]>([]);

    const handleClose = () => setDataSheet(null);

    const appendChild = (index: number, data: any, row: any[], level: number | string, type: ListType): any[] => {

        let number = 1;

        if (type === "document") {
            data?.documents?.forEach((doc: any, doc_index: number) => {
                let pathId = doc.sequence_id + "-r" + doc?.version;

                number += 1;

                // Appending Level
                pathId = `${typeof level === "string" ? "" : "L"}${level}.${doc_index + 1} [${pathId}]`;

                row.push({
                    id: `${doc?._id + doc_index + number}-${UUIDv4()}`,
                    bom: [...row[index].bom, pathId],
                    bomId: doc.sequence_id,
                    version: doc.version ?? 0,
                    document_name: doc.file_name ?? "",
                    serial_num: doc?.serial_num,
                    document_tag_type: doc?.tags_info?.find((tag: any) => tag?.tag_group_name === "Document Type")?.tag_name ?? "",
                    published: doc?.released ? true : false,
                    _id: doc._id,
                    associations: doc.associations,
                    sequence_id: doc.sequence_id,
                    references: doc.references,
                    status: doc?.status,
                    released_time: doc?.released_time,
                    released_user: doc?.released_user,
                    created_time: doc?.created_time,
                    created_user: doc?.created_user,
                    modified_time: doc?.modified_time,
                    modified_user: doc?.modified_user,
                    child_id: `${data.sequence_id}-v${data.version}`,
                    child_description: data.short_description
                });
            });
        }

        if (data?.bom_children?.length === 0 && data?.bom_materials?.length === 0) {
            return row;
        }

        data?.bom_children?.forEach((bom_children: any, bom_children_index: number) => {

            let pathId = bom_children.sequence_id + "-r" + bom_children?.version;

            number += 1;

            // Appending Level
            pathId = `${typeof level === "string" ? "" : "L"}${level}.${(data?.documents?.length || 0) + bom_children_index + 1} [${pathId}]`;

            let childLevel = `${typeof level === "string" ? "" : "L"}${level}.${(data?.documents?.length || 0) + bom_children_index + 1}`

            row.push({
                id: `${bom_children?._id + bom_children_index + number}-${UUIDv4()}`,
                bom: [...row[index].bom, pathId],
                className: "highlight-row",
                // bomId: bom_children.sequence_id,
            });

            row.concat(appendChild(row.length - 1, bom_children?.bom, row, childLevel, type));
        });

        data?.bom_materials?.forEach((bom_material: any, bom_material_index: number) => {

            let pathId = bom_material.sequence_id + "-r" + bom_material?.version;

            number += 1;

            pathId = `${typeof level === "string" ? "" : "L"}${level}.${(data?.documents?.length || 0) + (data?.bom_children?.length || 0) + bom_material_index + 1} [${pathId}]`;

            let childLevel = `${typeof level === "string" ? "" : "L"}${level}.${(data?.documents?.length || 0) + (data?.bom_children?.length || 0) + bom_material_index + 1}`;

            const materialObj = type === "material" ? {
                bomId: bom_material.sequence_id,
                attachments: bom_material?.attachments || bom_material?.material?.attachments || [],
                serial_num: bom_material?.serial_num,
                short_description: bom_material?.material?.short_description || "",
                version: bom_material?.version ?? bom_material?.material?.version,
                reference_id: bom_material?.external_ref_id ?? bom_material?.material?.external_ref_id ?? '',
                unit_region_name: bom_material?.unit_region_name ?? bom_material?.material?.unit_region_name ?? '',
                group_name: bom_material?.group_name ?? bom_material?.material?.group_name ?? '',
                noun_name: bom_material?.noun_name ?? bom_material?.material?.noun_name ?? '',
                sourcing_type: bom_material?.sourcing_type,
                material_cost: `${bom_material?.material?.money?.cost ?? "-"}`,
                material_currency: `${bom_material?.material?.money?.material_currency ?? "-"}`,
                spare_part: bom_material?.spare_part ?? "-",
                spare_part_category: sparePartsOptions?.find((mat: SelectBoxOption) => bom_material?.tags_info?.map((tag: any) => tag?.tag_id).includes(mat?._id))?.tag_name || "",
                spare_part_quantity: bom_material?.spare_part_quantity ?? "-",
                normal_quantity: `${bom_material.normal_quantity ?? "-"}`,
                override_cost: `${bom_material?.override_cost ?? "-"}  ${bom_material?.material?.money?.currency ?? ""}`,
                child_status: childStatusOptions?.find((mat: SelectBoxOption) => bom_material?.tags_info?.map((tag: any) => tag?.tag_id).includes(mat?._id))?.tag_name || "",
                notes: bom_material?.material?.notes || "",
                published: bom_material?.material?.released ? true : false,
                lead_time_days: `${bom_material?.material?.lead_time_days ?? 0}`,
            } : {}

            row.push({
                id: `${bom_material?._id + bom_material_index + number}-${UUIDv4()}`,
                bom: [...row[index].bom, pathId],
                _id: bom_material?._id,
                dataSheet: bom_material?.material?.datasheets?.[0],
                className: type === "document" && bom_material?.material?.documents?.length > 0 ? "highlight-row" : "",
                ...materialObj
            });

            row.concat(appendChild(row.length - 1, bom_material?.material, row, childLevel, type));

        });

        return row;
    };

    const massageRows = React.useCallback((res: any, type: ListType) => {
        if (!res.bom_id) {
            setCalculatedRows([]);
        }

        let bom = res?.bom;

        let pathId = `L1 [${bom?.sequence_id}-r${bom?.version}]`;

        let row = [
            {
                id: res?.bom_id,
                bom: [pathId],
                className: "highlight-row"
            }
        ];

        setCalculatedRows(appendChild(0, bom, row, 1, type));
        // eslint-disable-next-line
    }, [data]);

    useEffect(() => {
        if (data) {
            massageRows(data, (data.filter_type === material_noun || data.filter_type === material_group) ? "material" : "document");
        }
        // eslint-disable-next-line
    }, [status, sparePartStatus, childStatus]);

    const viewDataSheet = (row: any) => {
        setDataSheet(row?.dataSheet);
    }

    return <Box>
        {isLoading && <LoadingSection message="Fetching List View details" />}

        {isError && <Alert severity="error">Something went wrong, Unable to fetch List View</Alert>}

        {isSuccess && Object.values(data ? data : {}).length > 0 &&
            <Box pl={1} pt={1} sx={{ width: "100%" }}>
                <CustomDataGrid
                    id={`tree-view-${data.filter_type}-list`}
                    treeData
                    showToolbar={true}
                    loading={isFetching || isLoading}
                    rows={calculatedRows}
                    columns={ListViewTree((data.filter_type === material_noun || data.filter_type === material_group) ? "material" : "document", viewDataSheet, "tree", openAssociation, openReferences)}
                    getTreeDataPath={(row) => row.bom}
                    disableChildrenFiltering
                    getRowId={(row) => row?.id}
                    getRowClassName={(params) => (params?.row as any)?.className || ""}
                    groupingColDef={{ headerName: "BOM Tree" }}
                    hideFooter={true}
                    pagination={false}
                    defaultGroupingExpansionDepth={1}
                />
            </Box>

        }

        <Dialog
            maxWidth={"xl"}
            fullWidth={true}
            open={dataSheet ? true : false}
            onClose={handleClose}
            TransitionComponent={Transition}
        >
            {/* Ṭoolbar */}
            <AppBar sx={{ position: 'relative' }}>
                <Toolbar>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={handleClose}
                        aria-label="close"
                    >
                        <CloseIcon />
                    </IconButton>
                    <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                        Datasheet Preview
                    </Typography>
                </Toolbar>
            </AppBar>

            <DataTableForm type="datasheet" dataTableData={dataSheet} linkDataTableTemplate={async () => { }} getPath={() => ''} preview />
        </Dialog>
    </Box>
}