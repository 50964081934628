import { createSlice } from "@reduxjs/toolkit";
import copy from "fast-copy";
import { TemplateType } from "../../../screens/documentTemplates/dashboard/addEditTemplate";
import { HorizontalPosition, paginationFormatOptions, VerticalPosition } from "../../../utils";
import { cutSheetHeaderInterface, DynamicContent, engineeringTableInterface, logoInterface, marginInterface, notesInterface, Orientation, paginationInterface, pdfEditorInterface, SideTextInterface, Template, TemplateResponse, templatesStateType, textEditorInterface } from './interface';
export * from './interface';

export const giveMeHeaderFooterTemplateInitialState = (payload?: Partial<TemplateResponse>): Template => {

  let logoContent = payload?.headers?.find((h) => h.content.content_type === "ImageContent");
  let staticHeaderContents = payload?.headers?.filter((h) => h.content.content_type === "StaticContent") ?? [];
  let dynamicHeaderContent = payload?.headers?.find((h) => h.content.content_type === "DynamicContent");

  const dContents = (dynamicHeaderContent?.content as DynamicContent)?.fields;

  let hasMaterialId = dContents?.find((f) => f.field_name === "sequence_id");
  let hasMaterialDescription = dContents?.find((f) => f.field_name === "short_description");
  let hasMaterialManufactureNo = dContents?.find((f) => f.field_name === "vendor.part_number");
  let hasManufacture = dContents?.find((f) => f.field_name === "vendor.manufacturer_name");
  let hasSerialNum = dContents?.find((f) => f.field_name === "serial_num");

  let footers = payload?.footers ?? [];
  let margin = payload?.margin;
  let pageNumber = payload?.page_number;
  let hasOf = pageNumber?.format.includes("of");
  let hasSlash = pageNumber?.format.includes("/");

  let updatedData = {
    id: payload?._id || "isNew",
    sequence_id: payload?.sequence_id ?? "",
    version: payload?.version ?? 0,
    orientation: payload?.orientation ?? "portrait",
    name: payload?.name || "Header Footer Template",
    paper_size: payload?.paper_size || "A4",
    logo: {
      hide: false,
      isActive: logoContent?.is_active ?? false,
      src: payload?.attachments?.at(-1),
      vertical: VerticalPosition.find((v) => v.value === logoContent?.alignment?.vertical) || null,
      horizontal: HorizontalPosition.find((h) => h.value === logoContent?.alignment?.horizontal) || null,
      width: logoContent?.position?.x ?? 0,
      height: logoContent?.position?.y ?? 0,
    },
    notes: {
      hide: false,
      header: {
        hide: false,
        isActive: payload?.is_header_active ?? false,
        notes: staticHeaderContents?.map((s: any) => ({
          hide: false,
          show: s?.is_active ?? false,
          content: s?.content?.text ?? "",
          xPosition: HorizontalPosition.find((h) => h.value === s?.alignment?.horizontal) || null,
          yPosition: VerticalPosition.find((v) => v.value === s?.alignment?.vertical) || null,
        })),
      },
      left: {
        hide: false,
        isActive: payload?.left_annotation?.is_active ?? false,
        notes: payload?.left_annotation?.content?.text ?? "",
        alignment: {
          vertical: VerticalPosition.find((h) => h.value === payload?.left_annotation?.alignment?.vertical) || null,
          horizontal: HorizontalPosition.find((v) => v.value === payload?.left_annotation?.alignment?.horizontal) || null,
        },
      },
      right: {
        hide: false,
        isActive: payload?.right_annotation?.is_active ?? false,
        notes: payload?.right_annotation?.content?.text ?? "",
        alignment: {
          vertical: VerticalPosition.find((h) => h.value === payload?.right_annotation?.alignment?.vertical) || null,
          horizontal: HorizontalPosition.find((v) => v.value === payload?.right_annotation?.alignment?.horizontal) || null,
        },
      },
    },
    margin: {
      hide: false,
      isActive: margin?.is_active ?? false,
      top_offset: margin?.top_offset ?? 5,
      bottom_offset: margin?.bottom_offset ?? 5,
      width_offset: margin?.width_offset ?? 5,
      showTopLine: margin?.border?.top ?? false,
      showLeftLine: margin?.border?.left ?? false,
      showRightLine: margin?.border?.right ?? false,
      showBottomLine: margin?.border?.bottom ?? false,
    },
    cutSheetHeader: {
      hide: false,
      isActive: dynamicHeaderContent?.is_active ?? false,
      materialId: hasMaterialId ? true : false,
      materialDescription: hasMaterialDescription ? true : false,
      materialManufactureNo: hasMaterialManufactureNo ? true : false,
      manufacture: hasManufacture ? true : false,
      serialNum: hasSerialNum ? true : false,
      alignment: {
        vertical: VerticalPosition.find((h) => h.value === dynamicHeaderContent?.alignment?.vertical) || null,
        horizontal: HorizontalPosition.find((v) => v.value === dynamicHeaderContent?.alignment?.horizontal) || null,
      },
    },
    textEditor: {
      hide: true,
      isActive: false,
      disabled: false,
      position: null,
    },
    pdfEditor: { hide: true, isActive: false, disabled: false, position: null },
    engineeringTable: {
      hide: true,
      isActive: false,
      disabled: true,
      position: null,
    },
    footers: {
      hide: false,
      isActive: payload?.is_footer_active ?? false,
      notes: footers?.map((f: any) => ({
        hide: false,
        show: f?.is_active ?? false,
        content: f?.content?.text ?? "",
        xPosition: HorizontalPosition.find((h) => h.value === (f?.xPosition?.value || f?.alignment?.horizontal)) || null,
        yPosition: VerticalPosition.find((v) => v.value === (f?.yPosition?.value || f?.alignment?.vertical)) || null,
      })),
    },
    paginationFormat: {
      hide: false,
      isActive: pageNumber?.print ? true : false,
      showPageNumberOf: hasOf ? true : false,
      showPageTextOf: hasSlash ? true : false,
      showPageNumber: (!hasOf && !hasSlash),
      xPosition: HorizontalPosition.find((h) => h.value === pageNumber?.alignment?.horizontal) || null,
      yPosition: VerticalPosition.find((v) => v.value === pageNumber?.alignment?.vertical) || null,
      format: pageNumber?.format || hasOf ? paginationFormatOptions[1] : hasSlash ? paginationFormatOptions[0] : paginationFormatOptions[2],
    },
    attachments: payload?.attachments ?? []
  };

  // If backend send only one note, we are pushing the empty not for other notes
  if (updatedData.notes.header.notes.length < 2 && updatedData.notes.header.notes.length === 1) {
    updatedData.notes.header.notes.push({
      hide: false,
      show: false,
      content: "",
      xPosition: null,
      yPosition: null,
    });

    // If backend send empty notes, we are pushing both the notes
  } else if (updatedData.notes.header.notes.length === 0) {
    updatedData.notes.header.notes.push(
      {
        hide: false,
        show: false,
        content: "",
        xPosition: null,
        yPosition: null,
      },
      {
        hide: false,
        show: false,
        content: "",
        xPosition: null,
        yPosition: null,
      }
    );
  }

  // If backend send only one note, we are pushing the empty not for other notes
  if (updatedData.footers.notes.length < 2 && updatedData.footers.notes.length === 1) {
    updatedData.footers.notes.push({
      hide: false,
      show: false,
      content: "",
      xPosition: null,
      yPosition: null,
    });

    // If backend send empty notes, we are pushing both the notes
  } else if (updatedData.footers.notes.length === 0) {
    updatedData.footers.notes.push(
      {
        hide: false,
        show: false,
        content: "",
        xPosition: null,
        yPosition: null,
      },
      {
        hide: false,
        show: false,
        content: "",
        xPosition: null,
        yPosition: null,
      }
    );
  }

  return updatedData;
};

const giveMeGeneralPageTemplateInitialState = (
  payload?: Partial<TemplateResponse>
): Template => {
  return {
    id: payload?._id || "isNew",
    sequence_id: payload?.sequence_id ?? "",
    version: payload?.version ?? 0,
    orientation: payload?.orientation || "portrait",
    name: payload?.name || "Header Footer Template",
    paper_size: payload?.paper_size || "A4",
    logo: {
      hide: true,
      isActive: false,
      src: null,
      vertical: null,
      horizontal: null,
      width: 60,
      height: 60,
    },
    notes: {
      hide: true,
      header: {
        hide: true,
        isActive: false,
        notes: [
          {
            show: true,
            content: "",
            xPosition: null,
            yPosition: null,
          },
          {
            show: true,
            content: "",
            xPosition: null,
            yPosition: null,
          },
        ],
      },
      left: {
        hide: false,
        isActive: false,
        notes: "",
        alignment: {
          horizontal: null,
          vertical: null,
        },
      },
      right: {
        hide: false,
        isActive: false,
        notes: "",
        alignment: {
          horizontal: null,
          vertical: null,
        },
      },
    },
    margin: {
      hide: true,
      isActive: false,
      top_offset: 0,
      bottom_offset: 0,
      width_offset: 0,
      showTopLine: false,
      showLeftLine: false,
      showRightLine: false,
      showBottomLine: false,
    },
    cutSheetHeader: {
      hide: true,
      isActive: false,
      materialId: false,
      materialDescription: false,
      materialManufactureNo: false,
      manufacture: false,
      serialNum: false,
      alignment: {
        horizontal: null,
        vertical: null,
      },
    },
    textEditor: {
      hide: false,
      isActive: false,
      disabled: false,
      position: null,
    },
    pdfEditor: {
      hide: false,
      isActive: false,
      disabled: false,
      position: null,
    },
    engineeringTable: {
      hide: false,
      isActive: false,
      disabled: true,
      position: null,
    },
    footers: {
      hide: true,
      isActive: false,
      notes: [
        {
          show: true,
          content: "",
          xPosition: null,
          yPosition: null,
        },
        {
          show: true,
          content: "",
          xPosition: null,
          yPosition: null,
        },
      ],
    },
    paginationFormat: {
      hide: true,
      isActive: false,
      showPageNumberOf: false,
      showPageTextOf: true,
      showPageNumber: false,
      xPosition: null,
      yPosition: null,
      format: paginationFormatOptions[1],
    },
    attachments: []
  };
};

const initialTemplateConfig = {
  name: "",
  paper_size: "A4",
  orientation: "portrait" as Orientation,
};

const initialState: templatesStateType = {
  headerFooterTemplate: giveMeHeaderFooterTemplateInitialState({}),
  generalPageTemplate: giveMeGeneralPageTemplateInitialState({}),
  coverPageTemplate: giveMeGeneralPageTemplateInitialState({}),
  templateInitialConfig: initialTemplateConfig,
};

const documentTemplateSlice = createSlice({
  name: "documentTemplate",
  initialState,
  reducers: {
    // To Update Template Initial Configuration
    updateTemplateConfig(state, { payload }: { payload: Partial<TemplateResponse> }) {
      state.templateInitialConfig = payload;
    },

    // Add Header Footer Template
    addHeaderFooterTemplate(state, { payload }: { payload: any }) {
      state.headerFooterTemplate = giveMeHeaderFooterTemplateInitialState(payload);
    },

    // Update Header Footer Basic Details
    updateHeaderFooterBasicDetails(state, { payload }: { payload: { key: keyof Template; value: never } }) {
      state.headerFooterTemplate[payload.key] = payload.value;
    },

    // Update Logo State
    updateOverallLogoState(state, { payload }: { payload: logoInterface }) {
      if (state?.headerFooterTemplate) {
        state.headerFooterTemplate.logo = payload;
        if(payload.src === null){
          state.headerFooterTemplate.attachments = [];
        }
      }
    },

    // Update Header Notes
    updateHeaderNotes(state, { payload }: { payload: { key: keyof notesInterface; value: any } }) {
      if (state?.headerFooterTemplate) {
        let header: any = state.headerFooterTemplate.notes.header;
        header[payload.key] = payload.value;
        state.headerFooterTemplate.notes.header = header;
      }
    },

    // Update Footer Notes
    updateFooterNotes(state, { payload }: { payload: { key: keyof notesInterface; value: any } }) {
      if (state?.headerFooterTemplate) {
        let footer: any = state.headerFooterTemplate.footers;
        footer[payload.key] = payload.value;
        state.headerFooterTemplate.footers = footer;
      }
    },

    // Update Left Notes
    updateLeftNotes(state, { payload }: { payload: { key: keyof SideTextInterface; value: any } }) {
      if (state?.headerFooterTemplate) {
        let left: any = state.headerFooterTemplate.notes.left;
        left[payload.key] = payload.value;
        state.headerFooterTemplate.notes.left = left;
      }
    },

    // Update Right Notes
    updateRightNotes(state, { payload }: { payload: { key: keyof SideTextInterface; value: any } }) {
      if (state?.headerFooterTemplate) {
        let right: any = state.headerFooterTemplate.notes.right;
        right[payload.key] = payload.value;
        state.headerFooterTemplate.notes.right = right;
      }
    },

    // Update Margin
    updateMargin(state, { payload }: { payload: { key: keyof marginInterface; value: any } }) {
      if (state?.headerFooterTemplate) {
        let margin: any = copy(state.headerFooterTemplate.margin);
        margin[payload.key] = payload.value;
        state.headerFooterTemplate.margin = margin;
      }
    },

    // Update Pagination Config
    updatePaginationConfig(state, { payload }: { payload: { key: keyof paginationInterface; value: any } }) {
      if (state?.headerFooterTemplate) {
        let paginationFormat: any = state.headerFooterTemplate.paginationFormat;
        paginationFormat[payload.key] = payload.value;
        if (payload.key === "showPageNumberOf") {
          paginationFormat.format = paginationFormatOptions[0];
        } else if (payload.key === "showPageTextOf") {
          paginationFormat.format = paginationFormatOptions[1];
        }
        state.headerFooterTemplate.paginationFormat = paginationFormat;
      }
    },

    // Update Pagination Over all
    updatePaginationOverall(state, { payload }: { payload: paginationInterface }) {
      if (state?.headerFooterTemplate) {
        let paginationFormat: any = state.headerFooterTemplate.paginationFormat;
        paginationFormat = payload;
        state.headerFooterTemplate.paginationFormat = paginationFormat;
      }
    },

    // Update Cut Sheet Header
    updateCutSheetHeader(state, { payload }: { payload: { key: keyof cutSheetHeaderInterface; value: any; } }) {
      if (state?.headerFooterTemplate) {
        let cutSheetHeader: any = state.headerFooterTemplate.cutSheetHeader;
        cutSheetHeader[payload.key] = payload.value;
        state.headerFooterTemplate.cutSheetHeader = cutSheetHeader;
      }
    },

    // TODO: replicating general page template for cover page
    addGeneralPageTemplate(state, { payload }: { payload: Partial<TemplateResponse> & { template_type: TemplateType; } }) {
      if (payload?.template_type === "coverPage") {
        state.coverPageTemplate =
          giveMeGeneralPageTemplateInitialState(payload);
      } else if (payload?.template_type === "generalPage") {
        state.generalPageTemplate =
          giveMeGeneralPageTemplateInitialState(payload);
      }
    },
    updateGeneralPageBasicDetails(state, { payload }: { payload: { key: keyof Template; value: never; template_type: TemplateType; } }) {
      if (payload.template_type === "coverPage") {
        if (state?.coverPageTemplate) {
          state.coverPageTemplate[payload.key] = payload.value;
        }
      } else if (payload.template_type === "generalPage") {
        if (state?.generalPageTemplate) {
          state.generalPageTemplate[payload.key] = payload.value;
        }
      }
    },
    updateTextEditor(state, { payload }: { payload: { key: keyof textEditorInterface; value: any; template_type: TemplateType; } }) {
      if (payload.template_type === "coverPage") {
        if (state?.coverPageTemplate) {
          let textEditor: any = state.coverPageTemplate.textEditor;
          textEditor[payload.key] = payload.value;
          state.coverPageTemplate.textEditor = textEditor;
        }
      } else if (payload.template_type === "generalPage") {
        if (state?.generalPageTemplate) {
          let textEditor: any = state.generalPageTemplate.textEditor;
          textEditor[payload.key] = payload.value;
          state.generalPageTemplate.textEditor = textEditor;
        }
      }
    },
    updatePDFEditor(state, { payload }: { payload: { key: keyof pdfEditorInterface; value: any; template_type: TemplateType; } }) {
      if (payload.template_type === "coverPage") {
        if (state?.coverPageTemplate) {
          let pdfEditor: any = state.coverPageTemplate.pdfEditor;
          pdfEditor[payload.key] = payload.value;
          state.coverPageTemplate.pdfEditor = pdfEditor;
        }
      } else if (payload.template_type === "generalPage") {
        if (state?.generalPageTemplate) {
          let pdfEditor: any = state.generalPageTemplate.pdfEditor;
          pdfEditor[payload.key] = payload.value;
          state.generalPageTemplate.pdfEditor = pdfEditor;
        }
      }
    },
    updateEngineeringTable(state, { payload }: { payload: { key: keyof engineeringTableInterface; value: any; template_type: TemplateType; } }) {
      if (payload.template_type === "coverPage") {
        if (state?.coverPageTemplate) {
          let engineeringTable: any = state.coverPageTemplate.engineeringTable;
          engineeringTable[payload.key] = payload.value;
          state.coverPageTemplate.engineeringTable = engineeringTable;
        }
      } else if (payload.template_type === "generalPage") {
        if (state?.generalPageTemplate) {
          let engineeringTable: any =
            state.generalPageTemplate.engineeringTable;
          engineeringTable[payload.key] = payload.value;
          state.generalPageTemplate.engineeringTable = engineeringTable;
        }
      }
    },
    resetHFTemplate(state) {
      state.headerFooterTemplate = giveMeHeaderFooterTemplateInitialState({});
      state.templateInitialConfig = initialTemplateConfig;
    },
  },
});

export const {
  addHeaderFooterTemplate,
  updateHeaderFooterBasicDetails,
  updateHeaderNotes,
  updateFooterNotes,
  updateLeftNotes,
  updateRightNotes,
  updateMargin,
  updateCutSheetHeader,
  updateGeneralPageBasicDetails,
  updatePDFEditor,
  updateTextEditor,
  addGeneralPageTemplate,
  updateEngineeringTable,
  updatePaginationConfig,
  updateTemplateConfig,
  updatePaginationOverall,
  resetHFTemplate,
  updateOverallLogoState
} = documentTemplateSlice.actions;

export default documentTemplateSlice.reducer;
