import { useAuth0 } from "@auth0/auth0-react";
import { LoadingButton } from "@mui/lab";
import { Box, Button, Stack } from "@mui/material";
import {
  Annotation,
  BookmarkView,
  Inject,
  LinkAnnotation,
  Magnification,
  Navigation,
  PdfViewerComponent,
  TextSearch,
  TextSelection,
  ThumbnailView,
  Toolbar,
} from "@syncfusion/ej2-react-pdfviewer";
import { useSnackbar } from "notistack";
import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { SocketContext } from "../../contexts";
import { useAppDispatch } from "../../redux";
import { useGetDocumentByIdQuery, useUpdateMaterialDocumentMutation, useUploadDocumentMutation } from "../../redux/services";
import { closeConfirmationDialog, openConfirmationDialog } from "../../redux/slices/confirmationDialog";
import { notify, toggleBadge } from "../../redux/slices/notifications";
import { clearSelectedChip } from "../../redux/slices/utils";
import { DeletePanel } from "../deletePanel";
import { Layout } from "../layout";
import { MyPaper } from "../myPaper";
import "./index.css";

export const PDFAnnotator = () => {
  const { id = "" } = useParams();

  const documentResponse = useGetDocumentByIdQuery({ document_id: id });

  const { data } = documentResponse;

  const [file, setFile] = useState<File | null>(null);

  const { user } = useAuth0();

  const [connectingSocket, updateSocketConnection] = useState<boolean>(false);

  const [updateDocument, updateDocumentData] = useUpdateMaterialDocumentMutation();
  const [uploadDocument] = useUploadDocumentMutation();

  const { enqueueSnackbar } = useSnackbar();

  const dispatch = useAppDispatch();

  const { open } = useContext(SocketContext);

  const navigate = useNavigate();

  const location: any = useLocation();

  const paths = location.state?.paths ?? [];

  const openSocket = (id: string, sequence_id: string, version: number) => {
    updateSocketConnection(true);
    return new Promise(async (resolve, reject) => {
      try {
        const socketPayload = {
          "name": "document-status",
          "payload": {
            "document_id": id
          }
        };
        await open(socketPayload);
        dispatch(notify({
          name: "document-status",
          id: id,
          status: "In Progress",
          content: "Updating document for seqID is (0)",
          sequenceID: `${sequence_id ?? "New"}-r${version ?? "0"}`,
          subs: [
            "in progress"
          ],
          message: ""
        }));
        dispatch(toggleBadge(true));
        resolve(true);
      } catch (error) {
        console.error('error:', error)
        resolve(false);
      } finally {
        updateSocketConnection(false);
      }

    })
  }

  const onUpdate = () => {
    if (data?.released) {
      dispatch(openConfirmationDialog({
        title: "Update Operation",
        body: `Before updating the ${data?.sequence_id ?? ""}-r${data?.version ?? ""}, check for the dependencies`,
        positiveBtn: "Check for dependencies",
        negativeBtn: "Cancel",
        onOk: () => openDependencyTable(),
        onNegativeBtn: () => dispatch(closeConfirmationDialog())
      }));
    } else {
      openOverWriteDialog();
    }
  };

  const onUpdateDocument = (update_version: boolean) => {
    updateDocument({
      document_id: id,
      file_name: file?.name,
      update_version: update_version,
      update_document: true,
    });
  };

  const openDependencyTable = () => {
    dispatch(clearSelectedChip());
    dispatch(openConfirmationDialog({
      title: "Update Operation",
      body: <DeletePanel
        message={`Dependency Table of ${data?.sequence_id ?? ""}-r${data?.version ?? ""}`}
        errorMessage={"Do you want to create new version?"}
        errorMessageStacks={data?.associations ?? []}
      />,
      positiveBtn: "New Version",
      negativeBtn: "Cancel",
      onOk: () => data?.associations?.length > 0 ? openNewVersionDialog() : onUpdateDocument(true),
      onNegativeBtn: () => dispatch(closeConfirmationDialog())
    }));
  };

  const openOverWriteDialog = () => {
    dispatch(openConfirmationDialog({
      title: "Update Operation",
      body: <DeletePanel
        message={`Are you sure want to overwrite?`}
        warningMessage={`The dependent items of ${data?.sequence_id ?? ""}-r${data?.version ?? ""} will be updated with new changes`}
      />,
      positiveBtn: "Overwrite",
      negativeBtn: "Cancel",
      onOk: () => onUpdateDocument(false),
      onNegativeBtn: () => {
        dispatch(closeConfirmationDialog());
      }
    }));
  }

  const openNewVersionDialog = () => {
    dispatch(openConfirmationDialog({
      title: "Update Operation",
      body: <DeletePanel
        message={`Select the dependencies of the newer version of ${data?.sequence_id ?? ""}-r${data?.version ?? ""}`}
        errorMessage={""}
        errorMessageStacks={data?.associations ?? []}
        warningMessage={"New version will be attached with selected dependencies"}
        chipSelectable={true}
      />,
      positiveBtn: "Create",
      negativeBtn: "Cancel",
      onOk: () => onUpdateDocument(true),
      onNegativeBtn: () => {
        dispatch(closeConfirmationDialog());
      }
    }));
  };

  useEffect(() => {
    const updateUpdateDocumentStatus = async () => {
      if (updateDocumentData.isSuccess) {
        if (updateDocumentData.data?.url) {
          enqueueSnackbar("Uploading your document...", { variant: "info" });
          await openSocket(updateDocumentData.data?._id, updateDocumentData.data?.sequence_id, updateDocumentData.data?.version);
          var pdfViewer = (document.getElementById('container') as any)?.ej2_instances?.[0];
          pdfViewer?.saveAsBlob().then(async function (value: any) {
            await uploadDocument({ data: updateDocumentData.data, url: updateDocumentData.data?.url, file: value }).unwrap().then(() => {
              navigate(-1);
            }).catch((err: any) => {
              console.error("upload err: ", err);
            });
          })
        }
        dispatch(closeConfirmationDialog());
        dispatch(clearSelectedChip());
      }
      if (updateDocumentData.isError) {
        let error: any = updateDocumentData.error;
        enqueueSnackbar(`${error.title}`, { variant: "error" })
      }
    }

    updateUpdateDocumentStatus();

    // eslint-disable-next-line
  }, [updateDocumentData.status]);

  useEffect(() => {
    if (data?.url) {
      setFile({
        size: data?.size,
        name: data?.file_name ?? "",
      } as File);
    }
    // eslint-disable-next-line
  }, [documentResponse?.status]);

  const loading = documentResponse.isFetching ||
    documentResponse.isLoading ||
    updateDocumentData.isLoading ||
    connectingSocket;

  const giveMeComponent = () => {
    return (
      <Stack spacing={2} justifyContent={"space-between"} height={"100%"}>
        <Box className="control-section">
          <PdfViewerComponent
            id="container"
            documentPath={documentResponse?.data?.url || ""}
            resourceUrl="https://cdn.syncfusion.com/ej2/23.1.40/dist/ej2-pdfviewer-lib"
            style={{ height: "85vh" }}
            annotationSettings={{
              author: user?.name
            }}
          >
            <Inject
              services={[
                Toolbar,
                Magnification,
                Navigation,
                Annotation,
                LinkAnnotation,
                BookmarkView,
                ThumbnailView,
                TextSelection,
                TextSearch,
              ]}
            />
          </PdfViewerComponent>
        </Box>
        <MyPaper>
          <Stack direction={"row"} alignItems={"center"} justifyContent={"center"} spacing={2}>
            <Button disabled={loading} variant="outlined" onClick={() => navigate(-1)}>Cancel</Button>
            <LoadingButton
              variant="contained"
              onClick={onUpdate}
              loading={
                loading
              }
            >
              Update
            </LoadingButton>
          </Stack>
        </MyPaper>
      </Stack>
    );
  }

  return (
    <Box padding={1}>
      <Layout
        history={[
          ...paths.map((_: any) => {
            return { label: _.title, onClick: () => navigate(_.path) };
          }),
        ]}
        currentPath={`${data?.sequence_id ?? "..."}-r${data?.version ?? 0
          }`}
        navBars={[]}
        mainPanel={giveMeComponent()}
        sideNavVariant={"whiteboard"}
        locationState={paths}
        onBackBtnClick={paths[0]?.path ? () => navigate(paths[0]?.path) : undefined}
      />
    </Box>
  );
};
