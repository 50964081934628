import { useSnackbar } from "notistack";
import React from "react";
import { useSearchParams } from "react-router-dom";
import { Assign, MyPaper } from "../../components";
import { useAppDispatch } from "../../redux";
import { AssignmentStatus, useUpdateAssignmentMutation } from "../../redux/services";
import { closeBackdrop, openBackdrop } from "../../redux/slices/backdrop";

export const AssignUnAssignSerialNo: React.FC<{ children?: JSX.Element }> = (props) => {

    const dispatch = useAppDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const [searchParams] = useSearchParams();

    const [assignSequenceNumberQuery] = useUpdateAssignmentMutation({});

    const generateSerialNumbersForTemplate = async (
        id?: string | null,
        serial_num?: string,
        type: AssignmentStatus = "assign",
        isManual?: boolean
    ) => {
        dispatch(openBackdrop('Assigning Serial Number....'));
        const currentItem: { type: string, _id: string } = {
            type: searchParams.get('type') ?? "",
            _id: searchParams.get('id') ?? "",
        };

        const selectedChild: {
            object_type: string,
            object_id: string,
            child_id: string,
            serial_num?: string
        } = {
            object_type: searchParams.get('object_type') ?? "",
            object_id: searchParams.get('object_id') ?? "",
            child_id: searchParams.get('child_id') ?? ""
        };

        if (serial_num) {
            selectedChild.serial_num = serial_num;
        }

        await assignSequenceNumberQuery({
            id: id,
            status: type,
            manual_serial_num: isManual,
            payload: [
                {
                    object_type: currentItem?.type,
                    object_id: currentItem?._id,
                    children: [selectedChild],
                },
            ],
        }).then((res: any) => {
            const drawioContent: any = window.parent.window.parent.document.getElementById('drawio-editor-iframe');
            if (res.error) {
                enqueueSnackbar('Something went wrong unable to assign serial number', { variant: "error" })
                if (drawioContent) {
                    drawioContent.contentWindow?.postMessage(JSON.stringify({ message: "Something went wrong unable to assign serial number", isSuccess: false }), "*");
                } else {
                    console.log('drawio editor is not mapped');
                }
            } else {
                enqueueSnackbar('Assigned Serial Number', { variant: "success" });
                if (drawioContent) {
                    drawioContent.contentWindow?.postMessage(JSON.stringify({ message: "Assigned Serial Number", isSuccess: true }), "*");
                } else {
                    console.log('drawio editor is not mapped');
                }
            }
        }).catch(err => {
            console.log('err:', err)
            enqueueSnackbar('Something went wrong unable to assign serial number', { variant: "error" });
        });
        dispatch(closeBackdrop());
    };

    //TODO: Need to write a useEffect with the params as dependenci to call the generateSerialNumbersForTemplateFunction for un-assign

    return <MyPaper>
        <Assign onAssign={(id?: string | null, serial_num?: string, isManual?: boolean, type: AssignmentStatus = "assign") => generateSerialNumbersForTemplate(id ?? "", serial_num ?? "", type, isManual)} />
    </MyPaper>;
}