import React from 'react';
import { Grid, Button, Box, Alert, AlertTitle } from '@mui/material';

export const InternatlServerError: React.FC<{
    title?:string,
    message?: string,
    onRetry?: any,
    retryBtnName?: string,
    top?: string
}> = (props) => {

    const {
        message = "Opps! Something Went Wrong.",
        onRetry = () => false,
        retryBtnName = "Try Again",
        top = "30vh",
        title
    } = props;

    return <Box width={"100%"} >
        <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            sx={{ marginTop: top }}
        >
            <Grid item>
                <Alert severity="error">
                    {title && <AlertTitle>{title}</AlertTitle>}
                    {message}
                </Alert>
            </Grid>
            <Grid item>
                <Button
                    style={{ marginTop: 16, textAlign: "center" }}
                    variant={"outlined"}
                    onClick={onRetry}
                >
                    {retryBtnName}
                </Button>
            </Grid>
        </Grid>
    </Box>
}

InternatlServerError.defaultProps = {
    message: 'Something went wrong!',
    retryBtnName: 'Try Again',
    top: '30vh'
}

