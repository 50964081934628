import { KeyboardBackspace } from "@mui/icons-material";
import { LoadingButton, TreeView } from "@mui/lab";
import {
    Alert,
    Box,
    CircularProgress,
    Divider,
    IconButton,
    LinearProgress,
    Stack,
    Tab,
    Tabs,
} from "@mui/material";
import { useSnackbar } from "notistack";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
    CustomTreeItemV2,
    LoadingSection,
    MyPaper,
    PDFPreview,
} from "../../components";
import { useAppDispatch } from "../../redux";
import {
    useGenerateBOMReportMutation,
    useGetProcessingStatusQuery,
    useLazyGetBOMReportByIdQuery,
} from "../../redux/services";
import { closeBackdrop, openBackdrop } from "../../redux/slices/backdrop";
import { setOpenWebSideNavBar } from "../../redux/slices/utils";
import { Attachment } from "../../redux/slices/material";
import { linksData } from "../../redux/slices/bom";
import { SelectBoxOption } from "../../interfaces";
import { AppRoutes } from "../../router/routes";
import { LocalStorageKeys } from "../../utils";

export interface BOMReportStateInterface {
    type: "bom" | "material";
    _id: string;
    sequence_id: string;
    short_description?: string;
    group_name?: string;
    noun_name?: string;
    reference_id?: string;
    uom?: string;
    spare_part?: string;
    uom_type?: string;
    noun_id?: string;
    normal_quantity?: number;
    total_quantity?: number;
    cost?: number | null;
    currency?: string;
    material?: BOMReportStateInterface;
    bom?: BOMReportStateInterface;
    bom_materials?: BOMReportStateInterface[];
    bom_children?: BOMReportStateInterface[];
    datasheets: Array<any>;
    level?: number;
    index?: number[];
    serial_num?: string;
    spare_part_category?: string;
    spare_part_quantity?: number;
    cost_override?: boolean;
    override_cost?: number;
    override_currency?: string;
    cost_per_qty?: number;
    sourcing_type?: string;
    child_id?: string;
    version: number;
    attachments: Attachment[];
    associations?: linksData;
    documents: any[];
    sourcingType: string;
    supplierName: string;
    manufactureNumber: string;
    manufactureName: string;
    unitName: string;
    unitLocation: string;
    otherDetails: string;
    notes: string;
    imageUrl?: string;
}

const basicTab = {
    label: "Basic Details",
    value: "basic-details"
};

export const BOMPreview: React.FC<{}> = () => {

    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { enqueueSnackbar } = useSnackbar();

    const { module = "", p_id = "", id = "New" } = useParams();

    const [getBOMReportById, bomReportResponse] = useLazyGetBOMReportByIdQuery();

    const [generateReport, reportResponse] = useGenerateBOMReportMutation();

    const { data: processingStatus } =
        useGetProcessingStatusQuery({ id, type: "bom_report" }, {
            pollingInterval: 3000,
            skip: id === "New" || bomReportResponse?.data?.processing === false
        })

    const [tabs, setTabs] = React.useState<SelectBoxOption[]>([basicTab]);
    const [treeData, setTreeData] = React.useState<any>({});
    const [currentTab, setCurrentTab] = React.useState(basicTab.value);
    const [expanded, setExpanded] = React.useState<string[]>([]);
    const [selected, setSelected] = React.useState<string>("");
    const [currentItem, setCurrentItem] = React.useState<BOMReportStateInterface | null>(null);
    const [currentBOM, setCurrentBOM] = React.useState<string>("");

    const resizeRef = React.useRef<HTMLDivElement>(null);

    const fetchBOMreport = async (id: string) => {
        dispatch(openBackdrop("Fetching BOM Report data..."));
        await getBOMReportById({ id }).unwrap().then((res) => {
            setTreeData(res);
        }).catch(() => {
            enqueueSnackbar(
                (bomReportResponse?.error as any)?.title ?? "Fetching BOM Report Failed",
                { variant: "error" }
            );
        }).finally(() => {
            dispatch(closeBackdrop());
        })
    };

    const handleToggle = (event: React.SyntheticEvent, nodeIds: string[]) => setExpanded(nodeIds);

    const handleSelect = (event: React.SyntheticEvent, nodeIds: string) => setSelected(nodeIds);

    const onItemClick = (item: BOMReportStateInterface, index: number[]) => {
        if (Object.keys(item).length <= 0) {
            return;
        }
        setCurrentBOM(item?.child_id ? item?.child_id : item?._id);
        let curItem = { ...item, ...(item.material || {}), ...(item.bom || {}), index };
        let dynamicTabs = curItem?.documents?.map((doc: any) => ({ label: `${(doc?.sequence_id || doc?._id) ?? '...'}-r${doc?.version || 0}`, value: `${(doc?.sequence_id || doc?._id) ?? '...'}-r${doc?.version || 0}`, ...doc })) ?? []
        if (curItem?.datasheets?.length > 0) {
            const datasheet = curItem?.datasheets?.[0];
            dynamicTabs.push({ label: `${(datasheet?.sequence_id || datasheet?._id) ?? '...'}`, value: `${(datasheet?.sequence_id || datasheet?._id) ?? '...'}-r${datasheet?.version || 0}`, ...datasheet, template_id: null })
            // dynamicTabs.push({ label: `${(datasheet?.sequence_id || datasheet?._id) ?? '...'}`, value: `${(datasheet?.sequence_id || datasheet?._id) ?? '...'}-r${datasheet?.version || 0}`, ...datasheet })
        }
        let sequence_id = item?.bom?.sequence_id || item?.sequence_id || item?.material?.sequence_id || item?.sequence_id || "";
        let split_id = sequence_id?.split("-")?.[0] || "";
        let bom_len = bomReportResponse?.data?.bom_details?.length > 0;
        let material_len = bomReportResponse?.data?.material_details?.length > 0;
        if ((split_id === "BOM" && bom_len) || (split_id === "M" && material_len)) {
            setTabs([basicTab, ...dynamicTabs])
            setCurrentTab(basicTab.value);
        } else {
            setTabs([...(dynamicTabs ?? [])])
            setCurrentTab(dynamicTabs?.[0]?.value ?? "");
        }
        setTimeout(() => {
            setCurrentItem(curItem);
            setCurrentBOM("");
        }, 0)
    };

    const giveMeTreeItems = (
        item: BOMReportStateInterface,
        level: number,
        index: number[]
    ) => {

        let isMaterial = Object.keys(item?.material || {}).length > 0;

        let currentValue = isMaterial ? item?.material : item?.bom;

        return (
            <CustomTreeItemV2
                key={item?.child_id ? item?.child_id : item?._id}
                nodeId={index.join("-")}
                isExpand={expanded.includes(index.join("-"))}
                customTitle={`${currentValue?.sequence_id ?? "..."
                    }-r${currentValue?.version}`}
                customSubTitle={`(${currentValue?.short_description})`}
                tooltip={`${currentValue?.short_description}`}
                selected={selected}
                onClick={() => {
                    setCurrentItem(null);
                    onItemClick(item, index);
                }}
                isLoading={currentBOM === (item?.child_id ? item?.child_id : item?._id)}
                hideExpandCollapseIcon={
                    isMaterial
                }
                disableRightClick
            >
                {currentValue?.bom_children?.map((child: any, _index: number) => {
                    return giveMeTreeItems(child, level + 1, [...index, _index]);
                })}
                {currentValue?.bom_materials?.map((child: any, _index: number) => {
                    return giveMeTreeItems(child, level + (currentValue?.bom_children?.length ? currentValue?.bom_children?.length : 1), [...index, _index + (currentValue?.bom_children?.length ? currentValue?.bom_children?.length : 1)]);
                })}
            </CustomTreeItemV2>
        );
    };

    const getPDFType = (currentItem: BOMReportStateInterface, tab: any) => {
        if (!currentItem) {
            return "none";
        }

        let isMaterial = Object.keys(currentItem?.material || {}).length > 0;
        let isBOM = Object.keys(currentItem?.bom || {}).length > 0;

        if ((isMaterial || isBOM) && tab.value === "basic-details") {
            return isMaterial ? "material" : "bom";
        } else if (tab?.file_name?.includes("pdf") || tab?.s3key?.includes("pdf")) {
            return "pdf";
        } else if (tab?.file_name?.includes("jpg") || tab?.s3key?.includes("jpg") || tab?.file_name?.includes("png") || tab?.s3key?.includes("png") || tab?.file_name?.includes("jpeg") || tab?.s3key?.includes("jpeg")) {
            return "none";
        } else if (tab?.value?.includes("DS-")) {
            return "datasheet";
        } else {
            return "none";
        }
    };

    const goBack = () => {
        if (module && p_id) {
            if (module === "product") {
                navigate(AppRoutes.viewBOMReportOnProductModule(p_id));
            } else if (module === "project") {
                navigate(AppRoutes.viewBOMReportOnProjectModule(p_id));
            } else if (module === "proposal") {
                navigate(AppRoutes.viewBOMReportOnProposalModule(p_id));
            }
        } else {
            navigate(AppRoutes.bomReport)
        }
    };

    const onGenerate = async () => {
        generateReport(bomReportResponse.data?._id).unwrap().then(async (res) => {
            if (res?._id) {
                console.log("")
                enqueueSnackbar(
                    res?.message ?? "BOM Report generation has started...",
                    { variant: "success" }
                );
            }
        }).catch(err => {
            enqueueSnackbar(`Failed to request full report generation, More Info: -${err.error?.data?.title ?? ""} ${err.error?.title ?? ""}`, { variant: "error" })
        });
    }

    React.useEffect(() => {
        dispatch(setOpenWebSideNavBar(false));
        // eslint-disable-next-line
    }, [treeData]);

    React.useEffect(() => {
        if (id !== "New") {
            fetchBOMreport(id)
        }
        // eslint-disable-next-line
    }, [id]);

    React.useEffect(() => {
        const element = resizeRef.current;
        const width = JSON.parse(localStorage.getItem(LocalStorageKeys.resizeWidthBOMReport) || "350");
        if (element) {
            element.style.width = `${width}px !important`;
            const handleResize = (event: any) => {
                console.log(event.detail);
            };

            element.addEventListener('resize', handleResize);

            const checkResize = (mutations: any[]) => {
                const el = mutations[0].target;
                const w = el.clientWidth;
                const h = el.clientHeight;

                const isChange = mutations
                    .map((m) => `${m.oldValue}`)
                    .some((prev) => prev.indexOf(`width: ${w}px`) === -1 || prev.indexOf(`height: ${h}px`) === -1);

                if (!isChange) { return; }

                localStorage.setItem(LocalStorageKeys.resizeWidthBOMReport, JSON.stringify(w));

                const event = new CustomEvent('resize', { detail: { width: w, height: h } });
                el.dispatchEvent(event);
            };

            const observer = new MutationObserver(checkResize);
            observer.observe(element, { attributes: true, attributeOldValue: true, attributeFilter: ['style'] });

            return () => {
                observer.disconnect();
                element.removeEventListener('resize', handleResize);
            };
        }
    }, []);

    return (
        <Box sx={{ position: "absolute", top: 0, left: 0, right: 0, bottom: 0, background: (theme) => theme.palette.grey[100], padding: 1 }}>
            <Stack height={"100%"} spacing={1} direction="row">
                {/* Side Navbar */}
                <Stack
                    height={"100%"}
                    ref={resizeRef}
                    sx={{ resize: "horizontal", overflow: "hidden", transform: "rotateX(180deg)", width: JSON.parse(localStorage.getItem(LocalStorageKeys.resizeWidthBOMReport) || "350") }}
                >
                    <Stack height={"100%"} sx={{ transform: "rotateX(180deg)" }} width="100%" position={"relative"}>
                        <MyPaper height={"100%"} sx={{ overflow: "hidden !important", display: 'flex', flexDirection: "column" }}>
                            <>
                                {/* Heading */}
                                <Stack position={"relative"}>
                                    <Box
                                        width={"100%"}
                                        display={"flex"}
                                        justifyContent={"flex-end"}
                                        alignItems={"center"}
                                        position={"relative"}
                                        pb={2}
                                    >
                                        <IconButton
                                            onClick={goBack}
                                            sx={{ position: "absolute", left: 0 }}
                                        >
                                            <KeyboardBackspace />
                                        </IconButton>
                                        <LoadingButton
                                            color="primary"
                                            size="small"
                                            variant="contained"
                                            onClick={onGenerate}
                                            loading={reportResponse?.isLoading || bomReportResponse?.isFetching || bomReportResponse?.isLoading || processingStatus?.processing === true}
                                        >
                                            Generate Full Report
                                        </LoadingButton>
                                    </Box>
                                    <Box
                                        sx={{
                                            position: "absolute",
                                            left: 0,
                                            right: 0,
                                            bottom: 0,
                                        }}
                                    >
                                        <Divider variant="fullWidth" />
                                        {currentBOM && <LinearProgress />}
                                    </Box>
                                </Stack>

                                <Stack flexGrow={1} overflow={"auto"}>
                                    <TreeView
                                        sx={{ mt: 1 }}
                                        defaultCollapseIcon={null}
                                        defaultExpandIcon={null}
                                        defaultEndIcon={null}
                                        expanded={expanded}
                                        selected={selected}
                                        onNodeToggle={handleToggle}
                                        onNodeSelect={handleSelect}
                                    >
                                        <CustomTreeItemV2
                                            key={treeData?.child_id ? treeData?.child_id : treeData?._id}
                                            nodeId={`${0}`}
                                            isExpand={expanded.includes(`${0}`)}
                                            customTitle={`${treeData?.bom?.sequence_id ?? "-"}-r${treeData?.bom?.version
                                                }`}
                                            customSubTitle={`(${treeData?.bom?.short_description})`}
                                            tooltip={`${treeData?.bom?.short_description}`}
                                            selected={selected}
                                            onClick={() => {
                                                onItemClick(treeData, [0]);
                                            }}
                                            isLoading={currentBOM === (treeData?.child_id ? treeData?.child_id : treeData?._id)}
                                            disableRightClick
                                        >
                                            {treeData?.bom?.bom_children?.map((child: any, _index: number) => {
                                                return giveMeTreeItems(child, 2, [
                                                    0,
                                                    _index,
                                                ]);
                                            })}
                                            {treeData?.bom?.bom_materials?.map((child: any, _index: number) => {
                                                return giveMeTreeItems(child, 2, [
                                                    1,
                                                    _index,
                                                ]);
                                            })}
                                        </CustomTreeItemV2>
                                    </TreeView>
                                </Stack>
                            </>
                        </MyPaper>
                    </Stack>
                </Stack>

                {/* Right Panel */}
                <Stack pl={1} sx={{ width: `calc(100vw - ${JSON.parse(localStorage.getItem(LocalStorageKeys.resizeWidthBOMReport) || "350")}px)` }} height={"100%"} overflow={"auto"}>
                    {processingStatus?.processing === true ? <MyPaper height={"100%"}>
                        <Alert
                            severity="info"
                            iconMapping={{
                                info: <CircularProgress size={"1em"} color="info" />,
                            }}>
                            Processing bom report...
                        </Alert>
                    </MyPaper> : (
                        <>
                            {currentBOM && (
                                <LoadingSection message="Fetching Bom Details...." />
                            )}
                            {!currentBOM && (

                                <MyPaper height={"100%"}>
                                    <>
                                        {currentItem !== null
                                            ?
                                            <>
                                                <Tabs
                                                    value={currentTab}
                                                    onChange={(e, tab) => setCurrentTab(tab)}
                                                    variant="scrollable"
                                                    scrollButtons="auto"
                                                    indicatorColor="primary"
                                                    textColor="primary"
                                                >
                                                    {tabs.map(tab => <Tab key={tab.value} sx={{ fontSize: 14 }} value={tab.value} label={tab.label} wrapped />)}
                                                </Tabs>
                                                {tabs.map(tab => {
                                                    const ty = getPDFType(currentItem, tab);
                                                    return currentTab === tab.value && <PDFPreview key={tab.value} data={tab.value === "basic-details" ? (currentItem || {}) : { ...currentItem || {}, mainContent: tab }} type={ty} id={tab?.template_id ? tab?.template_id : Object.keys(currentItem?.bom || {}).length > 0 ? bomReportResponse?.data?.bom_template_id : bomReportResponse?.data?.material_template_id} />
                                                })}
                                            </>
                                            :
                                            bomReportResponse.isError ? <Stack height={"100%"} alignItems={'center'} justifyContent={"center"}><Alert severity="error">Unable to show BOM Preview. Please retry after some time</Alert></Stack> : null
                                        }
                                    </>
                                </MyPaper>

                            )}
                        </>
                    )}
                </Stack>
            </Stack>
        </Box>
    );
};
