import React from "react";
import { TextField, Typography, Button, Grid, Alert, DialogTitle, DialogContent, DialogActions } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useDispatch } from "react-redux";
import { closeDialog } from "../../../../redux/slices/dialog";
import { openBackdrop, closeBackdrop } from "../../../../redux/slices/backdrop";
import { useCreateProductNounMutation, useGetProductGroupsQuery, useGetProductFamilyQuery, useUpdateProductNounMutation, useUploadProdS3AttachementMutation } from "../../../../redux/services";
import { useSnackbar } from "notistack";
import { HighlightSelectBox, UploadImage } from "../../../../components";
import { SelectBoxOption } from "../../../../interfaces";

export const AddUpdateProductNoun: React.FC<{ children?: JSX.Element, isEdit: boolean, data: any, refetch: any }> = (props) => {

    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const [createProductNoun, createProductNounData] = useCreateProductNounMutation();
    const [updateProductNoun, updateProductNounData] = useUpdateProductNounMutation();
    const [uploadImage, uploadImageData] = useUploadProdS3AttachementMutation();
    const { currentData: allFamilyData = { data: [] }, isFetching: familyLoading, isError: familyError, isSuccess: familySuccess } = useGetProductFamilyQuery({ page: null, pageSize: null }, { refetchOnMountOrArgChange: true });
    const { data: family } = allFamilyData;

    const { currentData: allGroupData = { data: [] }, isFetching: groupLoading, isError: groupError, isSuccess: groupSuccess } = useGetProductGroupsQuery({ page: null, pageSize: null }, { refetchOnMountOrArgChange: true });
    const { data: group } = allGroupData;

    const [state, setState] = React.useState<{ family: SelectBoxOption, name: string, abbrevation: string, description: string, group: SelectBoxOption }>({
        family: { value: "", label: "" },
        name: "",
        abbrevation: "",
        description: "",
        group: { value: "", label: "" }
    });

    const [upload, setUpload] = React.useState<any>(null);

    const [error, setError] = React.useState({
        family: false,
        name: false,
        abbrevation: false,
        description: false,
        group: false
    })

    const updateState = (key: string, value: string) => {
        setState({ ...state, [key]: value });
        setError({ ...error, [key]: !value?.length })
    }

    const updateDropDown = (key: string, data: SelectBoxOption) => {
        setState({ ...state, [key]: data });
        setError({ ...error, [key]: !data?.value })
    }

    const onCancelBtnClicked = () => {
        dispatch(closeDialog());
    }

    const onCreateUpdateBtnClicked = () => {
        if (state.family.value && state.name.length && state.description.length && state.abbrevation.length && state.group.value) {
            if (props.isEdit) {
                if (updateProductNounData.isSuccess && uploadImageData.isError) {
                    uploadTheProductNounImage(updateProductNounData?.data?._id ?? "");
                } else {
                    let attachments = upload ? { attachments: [{ file_name: upload.name, fileName: upload.name, document_type: "display_pic" }] } : {}
                    updateProductNoun({
                        product_noun_abbr: state.abbrevation,
                        product_noun_desc: state.description,
                        group_id: state.group.value,
                        product_noun_name: state.name,
                        family_id: state.family.value,
                        _id: props.data.id,
                        ...attachments
                    })
                }
            } else {
                if (createProductNounData.isSuccess && uploadImageData.isError) {
                    uploadTheProductNounImage(createProductNounData?.data?._id ?? "");
                } else {
                    let attachments = upload ? { attachments: [{ file_name: upload.name, fileName: upload.name, document_type: "display_pic" }] } : {}
                    createProductNoun({
                        product_noun_abbr: state.abbrevation,
                        product_noun_desc: state.description,
                        group_id: state.group.value,
                        product_noun_name: state.name,
                        family_id: state.family.value,
                        ...attachments
                    });
                }
            }
        } else {
            setError({
                ...error,
                name: !state.name.length, description: !state.description.length,
                family: !state.family.value, group: !state.group.value, abbrevation: !state.abbrevation.length
            })
        }
    }

    const uploadTheProductNounImage = (id: string) => {
        uploadImage({ file: upload, object_id: id, object_type: "productNoun" });
    }

    const giveMeButtonName = () => {
        if (createProductNounData.isError) {
            return `${props.isEdit ? "Retry Update Product" : "Retry Create Product"}`
        }

        if (uploadImageData.isError) {
            return `Re-upload Product Image`
        }
        return `${props.isEdit ? "Update Product Noun" : "Create Product Noun"}`;
    }

    const giveMeInfoText = () => {
        if (props.isEdit && updateProductNounData.isError) {
            return <Alert severity="error">Opps! Something went wrong, Unable to update Product Noun. Try Again Later!</Alert>
        }
        if (createProductNounData.isError) {
            return <Alert severity="error">Opps! Something went wrong, Unable to create Product Noun. Try Again Later!</Alert>
        }
        if (uploadImageData.isError) {
            return <Alert severity="warning">
                {`The Product Noun is ${props.isEdit ? "Updated" : "Created"} Successfully, only image upload is failed.  You can re-upload the image or upload the image later by click on the below button`}
            </Alert>
        }
    };

    const giveMeURLToShow = () => {
        if (props.isEdit) {
            return props?.data?.attachments?.[props.data.attachments.length - 1]?.attachment_url;
        }
        return "";
    }

    React.useEffect(() => {
        if (createProductNounData.isSuccess && (upload === null || uploadImageData.isSuccess)) {
            enqueueSnackbar("Product Noun Created Successfully", { variant: "success" });
            props.refetch();
            dispatch(closeDialog());
        }
        if (createProductNounData.isError) {
            enqueueSnackbar("Opps! Something weng wrong, Unable to create Product Noun", { variant: "error" })
        }

        if (uploadImageData.isError) {
            enqueueSnackbar("Opps! Something weng wrong, Unable to upload Product Noun Image", { variant: "error" })
        }

        if (createProductNounData.isSuccess && !uploadImageData.isError && !uploadImageData.isLoading && !uploadImageData.isSuccess) {
            uploadTheProductNounImage(createProductNounData.data?._id ?? "");
        }
        // eslint-disable-next-line
    }, [createProductNounData, uploadImageData]);

    React.useEffect(() => {
        if (props.isEdit) {
            if (updateProductNounData.isSuccess && (upload === null || uploadImageData.isSuccess)) {
                enqueueSnackbar("Product Noun Updated Successfully", { variant: "success" });
                props.refetch();
                dispatch(closeDialog());
            }
            if (updateProductNounData.isError) {
                enqueueSnackbar("Opps! Something weng wrong, Unable to update Product Noun", { variant: "error" })
            }
            if (uploadImageData.isError) {
                enqueueSnackbar("Opps! Something weng wrong, Unable to upload Product Noun Image", { variant: "error" })
            }

            if (updateProductNounData.isSuccess && !uploadImageData.isError && !uploadImageData.isLoading && !uploadImageData.isSuccess) {
                uploadTheProductNounImage(props.data?._id ?? "");
            }
        }
        // eslint-disable-next-line
    }, [updateProductNounData, uploadImageData]);

    React.useEffect(() => {
        if (props.isEdit && props.data?.id && familySuccess && groupSuccess) {
            let selectedUoM = family?.filter((_: any) => props.data.family_id === _._id)?.[0];
            let selectedGroup = group?.filter((_: any) => props.data.group_id === _._id)?.[0];
            setState({
                ...state,
                name: props.data.product_noun_name, description: props.data.product_noun_desc, abbrevation: props.data.product_noun_abbr,
                family: { value: selectedUoM?._id, label: selectedUoM?.family_name, ...selectedUoM },
                group: { value: selectedGroup?._id, label: selectedGroup?.group_name, ...selectedGroup }
            });

            dispatch(closeBackdrop());
        } else if (familyLoading || groupLoading) {
            dispatch(openBackdrop("Fetching Family & Groups..."));
        } else if ((familySuccess && groupSuccess) || familyError || groupError) {
            dispatch(closeBackdrop());
        }
        // eslint-disable-next-line
    }, [props.isEdit, familySuccess, groupSuccess, familyLoading, groupLoading]);

    return <>
        {/* Title */}
        <DialogTitle>
            <Typography
                variant="h6"
                color="textPrimary"
                sx={{
                    borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
                    width: "100%", fontFamily: 'htrts_medium'
                }}
            >
                {`${props.isEdit ? "Update" : "Create"} Product Noun`}
            </Typography>
        </DialogTitle>

        {/* Fields */}
        <DialogContent>
            <Grid container spacing={2}>
                {/* family */}
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                    <HighlightSelectBox
                        id="select_family"
                        size="medium"
                        label='Family'
                        disabled={createProductNounData.isSuccess || createProductNounData.isError}
                        value={state.family}
                        options={family?.map((_: any) => ({ value: _._id, label: _.family_name }))}
                        onChange={(data: SelectBoxOption) => updateDropDown("family", data)}
                        required={true}
                        loading={familyLoading}
                        error={error.family || familyError}
                        helperText={error.family ? "Please select family" : familyError ? "Opps! Unable to load family" : ""}
                    />
                </Grid>

                {/* Group */}
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                    <HighlightSelectBox
                        id="select_group"
                        size="medium"
                        label='Group'
                        value={state.group}
                        disabled={createProductNounData.isSuccess || createProductNounData.isError}
                        options={group?.map((_: any) => ({ value: _._id, label: _.group_name }))}
                        onChange={(data: SelectBoxOption) => updateDropDown("group", data)}
                        required={true}
                        loading={groupLoading}
                        error={error.group || groupError}
                        helperText={error.group ? "Please select group" : groupError ? "Opps! Unable to load group" : ""}
                    />
                </Grid>

                {/* Name */}
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                    <TextField
                        id="product_noun_name"
                        fullWidth
                        label={"Name"}
                        value={state.name}
                        required={true}
                        disabled={createProductNounData.isSuccess || createProductNounData.isError}
                        error={error.name}
                        helperText={error.name ? "Please enter Product Noun name" : ""}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => updateState("name", e.target.value)}
                    />
                </Grid>

                {/* Abbrevation */}
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                    <TextField
                        id="product_abbr"
                        fullWidth
                        label={"Abbrevation"}
                        value={state.abbrevation}
                        required={true}
                        disabled={createProductNounData.isSuccess || createProductNounData.isError}
                        error={error.abbrevation}
                        helperText={error.abbrevation ? "Please enter Product Noun abbrevation." : ""}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => updateState("abbrevation", e.target.value)}
                    />
                </Grid>

                {/* Description */}
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <TextField
                        id="product_noun_description"
                        fullWidth
                        label={"Description"}
                        value={state.description}
                        required={true}
                        disabled={createProductNounData.isSuccess || createProductNounData.isError}
                        error={error.description}
                        helperText={error.description ? "Please enter Product Noun description." : ""}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => updateState("description", e.target.value)}
                    />
                </Grid>

                {/* Upload Image */}
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <UploadImage
                        useImage={true}
                        url={giveMeURLToShow()}
                        onUploaded={(file: any) => setUpload(file)}
                        disabled={createProductNounData.isSuccess || createProductNounData.isError}
                        title={"Upload Product Noun Image"}
                    />
                </Grid>
            </Grid>
        </DialogContent>

        <DialogActions>
            <Grid container spacing={2} px={2} pb={1}>
                {/* Info Text */}
                <Grid item xs={12}>
                    {giveMeInfoText()}
                </Grid>

                {/* Add Product Btn */}
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                    <LoadingButton
                        id="u-c-btn"
                        fullWidth
                        variant="contained"
                        onClick={onCreateUpdateBtnClicked}
                        loading={createProductNounData.isLoading || updateProductNounData.isLoading || uploadImageData.isLoading}
                    >
                        {giveMeButtonName()}
                    </LoadingButton>
                </Grid>

                {/* Cancel Btn */}
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                    <Button
                        fullWidth
                        variant="outlined"
                        onClick={onCancelBtnClicked}
                        disabled={createProductNounData.isLoading || updateProductNounData.isLoading || uploadImageData.isLoading}
                    >
                        {`${uploadImageData.isError ? "Upload Later" : "Cancel"}`}
                    </Button>
                </Grid>
            </Grid>
        </DialogActions>
    </>
}