import { SecondaryNavBars } from "../../interfaces";
import { AppRoutes } from "../../router/routes";

const HEADER_FOOTER_TEMPLATE = "Header Footer Templates";
const CHECK_LIST = "Check List Templates";
const DATA_SHEET_LIST = "Data Sheet Templates";
const INPUT_PARAMETERS_LIST = "Technical Parameters Templates";

export const NavBars: SecondaryNavBars[] = [
    {
        id: "1",
        title: HEADER_FOOTER_TEMPLATE,
        path: AppRoutes.viewTemplateList
    },
    {
        id: "2",
        title: CHECK_LIST,
        path:  AppRoutes.checkList
    },
    {
        id: "3",
        title: DATA_SHEET_LIST,
        path:  AppRoutes.dataSheetList
    },
    {
        id: "4",
        title: INPUT_PARAMETERS_LIST,
        path:  AppRoutes.technicalParametersList
    }
];