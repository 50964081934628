import { Box } from "@mui/material";
import { useSnackbar } from "notistack";
import React from "react";
import { matchPath, useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { withNavBars } from "../../HOCs";
import { Layout, Documents } from "../../components";
import { useAppDispatch, useAppSelector } from "../../redux";
import { useLazyGetProposalByIdQuery, useLazyGetDocumentsByTagGroupIdQuery, useGetLocationAndDocumentTagsQuery, useLinkModuleMutation, useUnlinkModuleMutation } from "../../redux/services";
import { closeBackdrop, openBackdrop } from "../../redux/slices/backdrop";
import { closeConfirmationDialog, openConfirmationDialog } from "../../redux/slices/confirmationDialog";
import { resetProposalSlice, updateProposalSlice, linksData } from "../../redux/slices/proposal";
import { AppRoutes } from "../../router/routes";
import { BasicDetails } from "./basicDetails";
import { NavBars } from "./utils";
import { SelectBoxOption } from "../../interfaces";
import { ProposalMaterials } from "./material";
import { ProposalBOMs } from "./bom";
import BOMReport from "../bomReport";
import ListView from "../listview";
import { Workbook } from "../workbook";
import { IssueTracker } from "../viewProject/issueTracker";
import { Dashboard } from "../drawioDiagrams/dashboard";
import { AllSearches } from "../aiInstantSearch/dashboard";
import { DataTableForm } from "../viewMaterial/dataSheet";
import { HasAccess } from "../../router/authorization";
import { PERMISSIONS } from "../../router/permission";
import { UserAccessManagement } from "../userAccessManagement";

const ViewProposal: React.FC<{ children?: JSX.Element, currentPath: string }> = ({ currentPath }) => {

    const params = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useAppDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const [getProposalById, proposalData] = useLazyGetProposalByIdQuery({ refetchOnReconnect: true, refetchOnFocus: true });
    const [linkDocument] = useLinkModuleMutation();
    const [unlinkDocument] = useUnlinkModuleMutation();
    const [getDocumentFolders, { currentData: data, isLoading }] = useLazyGetDocumentsByTagGroupIdQuery();
    const folderData = useGetLocationAndDocumentTagsQuery(null);
    const [searchParams] = useSearchParams();

    const proposalId = params?.id ?? "Proposal ID";

    const isEdit = proposalId !== ':id' && proposalId !== "New";

    const technicalParameterPath = proposalData?.data?.associations?.find((_: any) => _.object_type === "dataTableForm")?.object_details?.at(-1)?._id || "New";

    const { perm } = useAppSelector(store => store.auth.userDetails);

    const creatable = React.useMemo(() => HasAccess(perm, PERMISSIONS.PROPOSAL_CREATE), [perm]);
    const readable = React.useMemo(() => HasAccess(perm, PERMISSIONS.PROPOSAL_READ), [perm]);
    const updatable = React.useMemo(() => HasAccess(perm, PERMISSIONS.PROPOSAL_UPDATE), [perm]);
    const deletable = React.useMemo(() => HasAccess(perm, PERMISSIONS.PROPOSAL_DELETE), [perm]);
    const publishable = React.useMemo(() => HasAccess(perm, PERMISSIONS.PROPOSAL_PUBLISH), [perm]);

    const giveMeComponent = () => {
        // Basic Details
        if (matchPath(location.pathname, AppRoutes.viewProposalBasicDetail(proposalId))) {
            return <BasicDetails refetch={() => {
                if (isEdit) {
                    dispatch(openBackdrop("Fetching proposal details"));
                    getProposalById({ id: proposalId });
                }
            }} proposalId={proposalId} />
        }

        // Technical Parameter
        if (matchPath(location.pathname, AppRoutes.viewProposalTechParamForm(proposalId, technicalParameterPath))) {
            return <DataTableForm type="inputParameter" preview={!updatable} refetch={refetch} getPath={(path: string) => AppRoutes.viewProposalTechParamForm(proposalId, path)} linkDataTableTemplate={(links: any[], unlinks: any[]) => callLinkDocument('dataTableForm', links, unlinks)} />
        }

        // Documents
        if (matchPath(location.pathname, AppRoutes.viewProposalDocuments(proposalId))) {
            return <Documents
                refetch={refetch}
                Id={proposalData?.data?.sequence_id ?? ""}
                _id={proposalId}
                objectType="proposal"
                tag_id={searchParams.get('tag_id')}
                tag_group_id={searchParams.get('group_id')}
                linkDocument={(links: any, refetch: any) => callLinkDocument('document', links, [], refetch)}
                associations={proposalData?.data?.associations ?? []}
                onAnnotate={(id: string) => {
                    navigate(AppRoutes.proposalAnnotateViewer(proposalId, id), {
                        state: {
                            paths: [
                                { title: "Proposals", path: AppRoutes.proposals },
                                {
                                    title: `${proposalData?.data?.sequence_id}`,
                                    path: AppRoutes.viewProposalBasicDetail(proposalId),
                                },
                                { title: "Documents", path: AppRoutes.viewProposalDocuments(proposalId) },
                            ]
                        }
                    })
                }}
                showHelperBtn={false}
                creatable={creatable}
                readable={readable}
                updatable={updatable}
                deletetable={deletable}
                publishable={publishable}
                hasCheckbox={updatable}
                unlinkDoc={(id: string, refetch: any) => callLinkDocument('document', [], [id], refetch)}
            />
        }

        // Material
        if (matchPath(location.pathname, AppRoutes.viewProposalMaterial(proposalId))) {
            return <ProposalMaterials refetch={refetch} proposalId={proposalId} sequence_id={proposalData?.data?.sequence_id ?? "Add Proposal"} />
        }

        // BOMs
        if (matchPath(location.pathname, AppRoutes.viewProposalBOM(proposalId))) {
            return <ProposalBOMs refetch={refetch} proposalId={proposalId} sequence_id={proposalData?.data?.sequence_id ?? "Add Proposal"} />
        }

        // BOM Report
        if (matchPath(location.pathname, AppRoutes.viewBOMReportOnProposalModule(proposalId))) {
            return <BOMReport paths={[
                { title: "Proposals", path: AppRoutes.proposals },
                {
                    title: `${proposalData?.data?.sequence_id ?? "Add Proposal"}`,
                    path: AppRoutes.viewProposalBasicDetail(proposalId),
                },
            ]} object_type="proposal" />
        }

        // List View
        if (matchPath(location.pathname, AppRoutes.viewListViewOnProposalModule(proposalId))) {
            return <ListView paths={[
                { title: "Proposals", path: AppRoutes.proposals },
                {
                    title: `${proposalData?.data?.sequence_id ?? "Add Proposal"}`,
                    path: AppRoutes.viewProposalBasicDetail(proposalId),
                },
            ]} object_type="proposal" />
        }

        // Workbook
        if (matchPath(location.pathname, AppRoutes.workbookOnProposalModule(proposalId))) {
            return <Workbook paths={[
                { title: "Proposals", path: AppRoutes.proposals },
                {
                    title: `${proposalData?.data?.sequence_id ?? "Add Proposal"}`,
                    path: AppRoutes.viewProposalBasicDetail(proposalId),
                },
            ]} object_type="proposal" />
        }

        // IssueTracker
        if (matchPath(location.pathname, AppRoutes.viewIssueTrackerOnProposal(proposalId))) {
            return <IssueTracker 
                        paths={[
                            { title: "Proposals", path: AppRoutes.proposals },
                            {
                                title: `${proposalData?.data?.sequence_id ?? "Add Proposal"}`,
                                path: AppRoutes.viewProposalBasicDetail(proposalId),
                            },
                        ]} 
                        object_type="proposal"
                        isNested={true}
                        readable={readable}
                        creatable={creatable}
                        updatable={updatable}
                        deletetable={deletable}
                    />
        }

        // IEDraw
        if (matchPath(location.pathname, AppRoutes.viewProposalIEDraw(proposalId))) {
            return <Dashboard object_id={proposalId} object_type="proposal" sequence_id={proposalData?.data?.sequence_id ?? ""} />
        }

        // AI Search
        if (matchPath(location.pathname, AppRoutes.viewProposalAISearch(proposalId))) {
            return <AllSearches
                object_id={proposalId}
                object_type="proposal"
                paths={[...history, { label: "AI Search", path: AppRoutes.viewProposalAISearch(proposalId) }]}
                documentsFilter={proposalData?.data?.associations?.find((a: { object_type: string }) => a?.object_type === "document")?.object_details?.map((d: { _id: string }) => d?._id)}
                unlinkSearch={(unlinks: any[]) => callLinkDocument('instantSearch', [], unlinks)}
            />
        }

        // User Management
        if (matchPath(location.pathname, AppRoutes.manageUsersOnProposal(proposalId))) {
            return <UserAccessManagement updatable={updatable} isLoading={proposalData.isFetching || proposalData.isLoading} object_id={proposalId} object_type="proposal" refetch={refetch} user_access={proposalData?.data?.user_access} sequence_id={proposalData?.data?.sequence_id ?? "..."} created_user={proposalData?.data?.created_user} />
        }

        return <></>
    };

    const giveMeLinksAndUnlinks = (links: any[], unlinks: any[], object_type: string): linksData => {
        let obj: linksData = {
            links: [{ object_ids: links, object_type: object_type }],
            unlinks: [{ object_ids: unlinks, object_type: object_type }],
        }
        return obj;
    }

    const callLinkDocument = async (object_type: string, links?: any[], unlinks?: any[], refetch?: any) => {
        let action = (unlinks || [])?.length > 0 ? "Unlinking" : "Linking";
        let actionSuccess = (unlinks || [])?.length > 0 ? "Unlinked" : "Linked";
        let actionError = (unlinks || [])?.length > 0 ? "Unlink" : "Link";
        dispatch(openBackdrop(`${action} the ${object_type}...`));
        const linkedData = giveMeLinksAndUnlinks(links || [], unlinks || [], object_type);
        let res: any = {};
        try {
            if (action === "Linking") {
                res = await linkDocument({ object_id: proposalId, object_type: "proposal", payload: { links: linkedData.links } })
            } else {
                res = await unlinkDocument({ object_id: proposalId, object_type: "proposal", payload: { unlinks: linkedData.unlinks } });
            }
            if (Object.keys(res).includes("data")) {
                enqueueSnackbar(`${actionSuccess} ${object_type} Successfully!`, { variant: "success" })
                dispatch(closeConfirmationDialog());
                getProposalById({ id: proposalId });
                refetch && refetch();
            } else if (Object.keys(res).includes("error")) {
                // eslint-disable-next-line 
                throw res.error.data;
            }
        } catch (error: any) {
            let errorMessage: string = error?.title ?? error ?? `Oops! Something went wrong, Unable to ${actionError} ${object_type}`
            enqueueSnackbar(errorMessage, { variant: "error" });
        } finally {
            dispatch(closeBackdrop());
        }
    }

    const refetch = () => {
        if (isEdit) {
            refetchDocumentFolders();
            getProposalById({ id: proposalId });
        }
    }

    const refetchDocumentFolders = () => {
        getDocumentFolders({ tag_group_id: folderData.data?.locationId, object_type: "proposal", object_ids: [proposalId] })
    };

    React.useEffect(() => {
        if (proposalId === ':id') {
            dispatch(closeBackdrop());
            navigate(AppRoutes.proposalsDashboard);
        }
    });

    React.useEffect(() => {
        if (isEdit) {
            dispatch(openBackdrop("Fetching Proposal Details..."));
            getProposalById({ id: proposalId });
            refetchDocumentFolders();
        } else {
            dispatch(resetProposalSlice());
        }
        // eslint-disable-next-line    
    }, [params])

    React.useEffect(() => {
        const updateValue = async () => {
            if (isEdit && !proposalData.isFetching && proposalData.isSuccess) {
                let tags: { type: SelectBoxOption | null, businessUnit: SelectBoxOption | null, tags: SelectBoxOption[], status: SelectBoxOption | null } = { type: null, businessUnit: null, tags: [], status: null };
                proposalData.data?.tags_info?.forEach((_: any) => {
                    if (_.tag_group_name === 'Business Units') {
                        tags.businessUnit = {
                            value: _?.tag_id ?? "",
                            label: _?.tag_name ?? "",
                            ..._
                        }
                    }

                    if (_.tag_group_name === 'Proposal Category') {
                        tags.type = {
                            value: _?.tag_id ?? "",
                            label: _?.tag_name ?? "",
                            ..._
                        }
                    }

                    if (_.tag_group_name === 'Project General Tag') {
                        tags.tags.push({
                            value: _?.tag_id ?? "",
                            label: _?.tag_name ?? "",
                            ..._
                        })
                    }

                    if (_.tag_group_name === 'Proposal Status') {
                        tags.status = {
                            value: _?.tag_id ?? "",
                            label: _?.tag_name ?? "",
                            ..._
                        }
                    }
                })
                dispatch(updateProposalSlice({
                    basic_details: {
                        proposal_name: proposalData.data?.proposal_name ?? "",
                        type: tags.type,
                        description: proposalData.data?.description ?? "",
                        cost_center: proposalData.data?.cost_center,
                        businessUnit: tags.businessUnit,
                        customer: proposalData.data?.customer_name ?? "",
                        proposalManager: proposalData.data?.proposal_manager ?? "",
                        status: tags.status,
                        reference_number: proposalData.data?.reference_number,
                        start_date: proposalData.data?.start_date ?? "",
                        finish_date: proposalData.data?.finish_date ?? "",
                    },
                    notes: atob(proposalData?.data?.notes ?? ""),
                    proposal_tags: {
                        tags: tags.tags
                    },
                    links_data: {
                        links: [],
                        unlinks: []
                    },
                    attachments: proposalData?.data?.attachments ?? [],
                    associations: proposalData?.data?.associations,
                    sequenceId: proposalData?.data?.sequence_id ?? "",
                    version: proposalData?.data?.version ?? ""
                }))
                dispatch(closeBackdrop());
            }
        }
        updateValue();
        // eslint-disable-next-line
    }, [proposalData]);

    React.useEffect(() => {
        if (!proposalData.isFetching && proposalData.isError) {
            enqueueSnackbar('404 - Unable to find the requested proposal details', { variant: "error" });
            dispatch(openConfirmationDialog({
                title: "404 - Not Found",
                body: "Unable to find the requested proposal",
                positiveBtn: "Ok",
                onOk: () => {
                    dispatch(closeConfirmationDialog())
                    navigate(AppRoutes.proposalsDashboard)
                },
                hideNegativeBtn: true
            }))
            dispatch(closeBackdrop());
        }
        // eslint-disable-next-line
    }, [proposalData.isError])

    React.useEffect(() => {
        if (folderData.isSuccess) {
            refetchDocumentFolders();
        }
        // eslint-disable-next-line
    }, [folderData.isSuccess]);

    const documentFolders = data?.filter((_: any) => _.object_id === proposalId)?.[0] ?? { tags: [], tag_group_id: "" };

    const history = [
        { label: "Proposals", path: AppRoutes.proposals },
        { label: `${proposalData?.data?.sequence_id ?? "Add Proposal"}`, path: AppRoutes.viewProposalBasicDetail(proposalId) }
    ];

    return <Box p={0.5} height={"100%"}>
        <Layout
            history={history}
            currentPath={currentPath}
            navBars={NavBars(proposalId, isEdit, isLoading || folderData.isLoading, documentFolders.tags ?? [], documentFolders?.tag_group_id ?? "", technicalParameterPath)}
            mainPanel={giveMeComponent()}
            sideNavVariant={"whiteboard"}
            onBackBtnClick={() => navigate(AppRoutes.proposals)}
            resizable
        />
    </Box>
}

export default withNavBars(ViewProposal);