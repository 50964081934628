import { Search } from "@mui/icons-material";
import { Box, IconButton, List, ListItem, ListItemText, Skeleton, TextField, Typography } from "@mui/material";
import copy from "fast-copy";
import React from "react";
import { HighlightSelectBox, InternatlServerError } from "../../components";
import { SelectBoxOption } from "../../interfaces";

export interface ProductNounDataInterface { data: any[], isLoading: boolean, isError: boolean, isSuccess: boolean, isFetching: boolean }

export const ProductNounListing: React.FC<{ value: any, onChange: any, showAll: boolean, familyOptions: SelectBoxOption[], productNounData: ProductNounDataInterface, family: SelectBoxOption, setFamily: any }> = ({ value, onChange, showAll = false, familyOptions, productNounData, family, setFamily }) => {

    const { data, isLoading, isError, isSuccess, isFetching } = productNounData;

    const [search, setSearch] = React.useState("");
    const [showSearch, setShowSearch] = React.useState(false);

    const giveMeProductNouns = () => {
        let newData = copy(data);
        let rows = showAll ? [{ id: "all", product_noun_name: "All Product Nouns", group_name: "To view all attributes" }] : [];

        if (family && family.value && family?.value?.toString() !== 'all') {
            newData = newData?.filter((_: any) => _?.family_id === family?.value);
        }

        if (search.length) {
            rows = [...rows, ...newData?.filter((_: any) => _?.product_noun_name?.toLowerCase()?.includes(search.toLowerCase())) ?? []];
        } else {
            rows = [...rows, ...newData];
        }
        return rows;
    }

    const toogleShowSearch = () => { setShowSearch(!showSearch) };

    React.useEffect(() => {
        if (isSuccess && !isLoading && !isError && !value) {
            if (family?.value) {
                onChange(data?.filter((_: any) => _.family_id === family.value)?.[0] ?? {});
            } else {
                onChange(data?.[0] ?? {});
            }
        }
        // eslint-disable-next-line
    }, [isSuccess, isFetching, value])

    React.useEffect(() => {
        onChange(null);
        // eslint-disable-next-line
    }, [family])

    if (isLoading) {
        return <>
            {Array(10).map((_: number, key: number) => {
                return <Skeleton variant="rectangular" />
            })}
        </>
    }

    if (isError && !isLoading) {
        return <InternatlServerError message="Opps!, Unable to fetch products" />
    }

    const nouns = giveMeProductNouns();

    return <Box sx={{ border: (theme) => `1px solid ${theme.palette.divider}`, marginRight: { xs: 0, sm: 1 }, borderRadius: 1 }}>
        {/* Heading */}
        <Box padding={1} display={"flex"} alignItems={"center"} justifyContent={"space-between"}>
            <Typography variant="subtitle2" sx={{ fontFamily: "htrts_bold" }}>{`Product Nouns (${nouns?.length ?? 0})`}</Typography>
            <IconButton size="small" onClick={toogleShowSearch}>
                <Search fontSize="small" htmlColor="black" />
            </IconButton>
        </Box>

        <Box px={1} paddingBottom={1}>
            <HighlightSelectBox
                label={"Family"}
                margin={"none"}
                value={family}
                options={[{ value: 'all', label: 'All' }, ...familyOptions]}
                onChange={(options: SelectBoxOption) => setFamily(options)}
            />
        </Box>

        {/* Search Bar */}
        {showSearch && <TextField
            sx={{ paddingX: 1, paddingBottom: 1 }}
            variant="standard"
            placeholder="Search product noun"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            size={"small"}
            fullWidth
        />}

        <List component="nav" dense sx={{ height: "calc(100vh - 230px)", overflow: "scroll" }}>
            {nouns.map((noun: any, key: number) => {
                return <ListItem
                    id={`pr-${key}`}
                    key={key}
                    dense
                    divider
                    button
                    selected={value?._id === noun?._id}
                    onClick={(event) => onChange(noun)}
                >
                    <ListItemText
                        primary={noun.family_name ? `${noun.product_noun_name}(${noun.family_name})`:noun.product_noun_name}
                        secondary={noun.group_name}
                    />
                </ListItem>
            })}
        </List>
    </Box>;
}