import React from 'react';
import { Box, Grid, Button, Typography, Checkbox, Tooltip, FormControlLabel } from "@mui/material";
import { SelectBoxOption } from "../../interfaces";
import { HighlightSelectBox } from "../../components";

export interface LinkDocHeaderProps {
    title?: string, SearchBtnLabel?: string, onSearch?: any, disableSearchBtn?: boolean,
    setRevision?: any, revision?: boolean, documentTypeDropdown?: SelectBoxOption[], documentType?: SelectBoxOption | null, setDocType?: any,
    tags?: SelectBoxOption | null, setTags?: any, linkedWith?: SelectBoxOption | null, setLinkedWith?: any, tagsDropdown?: SelectBoxOption[],
    linkedWithdropdown?: SelectBoxOption[], tagsLoading?: boolean
}

export const LinkDocHeader: React.FC<LinkDocHeaderProps> = ({
    title = "Title", SearchBtnLabel = "Search", onSearch, disableSearchBtn = false, linkedWithdropdown = [],
    revision = true, setRevision, documentTypeDropdown = [], documentType = null, setDocType, tags = null, setTags, linkedWith = null, setLinkedWith, tagsDropdown = [],
    tagsLoading = false
}) => {

    return <Box>
        <Grid
            container
            direction={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
            spacing={1}
        >
            <Grid item xs={12} sm={12} md={9}>
                <Typography gutterBottom variant="body1" color={"textPrimary"} sx={{ display: "flex", fontWeight: 600 }}>
                    {title}
                </Typography>
            </Grid>
        </Grid>
        <Grid container item xs={12} spacing={1} sm={12} md={12} sx={{ display: "flex", alignItems: "center", justifyContent: "flex-end", marginTop: "10px" }}>
            <Grid item sx={{ width: '20%' }}>
                <Tooltip title={"All Revision"}>
                    <FormControlLabel control={<Checkbox
                        title="All Revision"
                        checked={revision}
                        onChange={() => { setRevision(!revision) }}
                    />} label="All Revision" />
                </Tooltip>
            </Grid>
            <Grid item sx={{ width: '20%' }}>
                <HighlightSelectBox
                    label={"Tag Group"}
                    margin={"none"}
                    value={documentType}
                    options={documentTypeDropdown}
                    loading={tagsLoading}
                    onChange={(options: SelectBoxOption) => { setDocType(options) }}
                />
            </Grid>

            <Grid item sx={{ width: '20%' }}>
                <HighlightSelectBox
                    label={"Tags"}
                    margin={"none"}
                    value={tags}
                    disabled={!documentType}
                    options={tagsDropdown}
                    loading={tagsLoading}
                    onChange={(options: SelectBoxOption) => { setTags(options) }}
                />
            </Grid>

            <Grid item sx={{ width: '20%' }}>
                <HighlightSelectBox
                    label={"Linked With"}
                    margin={"none"}
                    value={linkedWith}
                    options={linkedWithdropdown}
                    onChange={(options: SelectBoxOption) => { setLinkedWith(options) }}
                />
            </Grid>
            <Grid item sx={{ width: '20%' }}>
                <Button
                    variant={"contained"}
                    color={"primary"}
                    fullWidth
                    onClick={onSearch}
                    disabled={disableSearchBtn}
                    sx={{ fontSize: (theme) => theme.spacing(1.75), paddingX: "4px" }}
                >
                    {SearchBtnLabel}
                </Button>
            </Grid>

        </Grid>

    </Box>
}