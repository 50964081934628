import React from "react";
import { OptionProps } from "react-select";
import { Theme, Stack, ListItemText, ListItemButton, ListItemAvatar } from "@mui/material";
import { History } from "@mui/icons-material";
import { SelectBoxOption } from "../../interfaces";

export interface NounSelectBoxInterface {
    value: any, onChange: any,
    label?: string, isRequired?: boolean, placeholder?: string, isMulti?: boolean, disabled?: boolean, height?: number | null, uom_id?: any,
    withSearchAPI?: boolean,
    viewOnly?: boolean
}

export const Option = (props: OptionProps<{ label: string, options: SelectBoxOption[], value: string, group_name: string, attachments: any, isRecent?: boolean }>) => {
    return (
        <Stack id="search-select-option">
            <ListItemButton alignItems="center" sx={{ padding: 0, paddingX: 2 }} selected={props.isSelected} onClick={(e) => props.selectOption(props.data)}>
                {props.data.isRecent && <History sx={{ marginRight: 2 }} color="action" />}
                <ListItemText
                    primary={props.data.label}
                    secondary={props.data.group_name}
                />

                {!props.data.isRecent && <ListItemAvatar sx={{ paddingTop: 1 }}>
                    <img height={"50px"} width={"50px"} src={props.data.attachments ? props.data.attachments?.[props.data.attachments.length - 1]?.attachment_url : `/imageNotFound.png`} alt={props.data.label} />
                </ListItemAvatar>}
            </ListItemButton>
        </Stack>
    );
};

export const customStyles = (theme: Theme, height: number | null) => ({
    control: (base: any) => ({
        ...base,
        borderRadius: 8,
        height,
        fontSize: "16px",
        // minHeight: 48,
        border: "1.5px solid " + theme.palette.divider,
        boxShadow: "none",
        "&:hover": {
            border: "1.5px solid " + theme.palette.primary.main,
        },
    }),
    menu: (base: any) => ({
        ...base,
        zIndex: 999
    })
});