import { ReportGmailerrorred } from "@mui/icons-material";
import { Box, CircularProgress, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { SocketContext } from "../../contexts";
import { convertSize } from "../../utils";

interface SizeProps {
  id: string;
  status: string;
  size: number;
}

const FileSize: React.FC<SizeProps> = ({ id, status, size }) => {
  const { receivedEvents } = useContext(SocketContext);

  const [currentStatus, updateStatus] = useState<string>(status);
  const [updatedSize, setSize] = useState<number>(size);
  
  useEffect(() => {
    if (currentStatus === "pending") {
      let document = receivedEvents?.["document-status"]?.[id] || {};
      if (currentStatus === "pending" && Object.keys(document).length > 0) {
        if (document?.status === "saved") {
          updateStatus("saved");
          setSize(document?.size ?? 0);
        } else {
          updateStatus("failed");
          setSize(0);
        }
      }
    }
    // eslint-disable-next-line
  }, [receivedEvents?.["document-status"], receivedEvents?.["document-status"]?.[id]]);

  return (
    <Box sx={{ width: "100%", textAlign: "center" }}>
      {currentStatus === "pending" && <CircularProgress size={20} />}
      {currentStatus === "saved" && (
        <Typography variant="body2">{convertSize(updatedSize ?? 0)}</Typography>
      )}
      {currentStatus === "failed" && (
        <ReportGmailerrorred color="error" fontSize="small" />
      )}
    </Box>
  );
};

export default FileSize;
