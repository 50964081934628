export const config = {
  api_url: process.env?.REACT_APP_API_BASE_URL ?? "https://dev-api.iehub.ai/",
  // api_url: process.env?.REACT_APP_API_BASE_URL ?? "http://localhost/",
  auth_api_url: process.env?.REACT_APP_AUTH_API_BASE_URL ?? "https://dev-api.iehub.ai/",
  // auth_api_url: process.env?.REACT_APP_AUTH_API_BASE_URL ?? "http://localhost/",

  // auth0_domain: process.env?.REACT_APP_AUTH0_DOMAIN ?? "auth-hontrel-dev.us.auth0.com",
  auth0_domain:
    process.env?.REACT_APP_AUTH0_DOMAIN ?? "auth-hontrel-dev.iehub.ai",
  auth0_client_id:
    process.env?.REACT_APP_AUTH0_CLIENT_ID ??
    "CPKYLsV3eaTtyCnAjNdJYK4OS4wLb8Tp",
  auth0_audience: process.env?.REACT_APP_AUTH0_AUDIENCE ?? "https://dev-api.iehub.ai",
  web_socket_url: process.env?.REACT_APP_WS ?? "wss://dev-api.iehub.ai/ws",
  mui_license_key: process.env?.REACT_APP_LICENSE_KEY ?? "",
  auto_logout_time:
    isNaN(Number(process.env?.REACT_APP_TIMEOUT)) === false
      ? process.env?.REACT_APP_TIMEOUT
      : 60,
  sync_license_key: process.env?.REACT_APP_SYNC_FUSION_LICENSE_KEY ?? "Ngo9BigBOggjHTQxAR8/V1NAaF1cXmhIfEx1RHxQdld5ZFRHallYTnNWUj0eQnxTdEFjW35bcHBRQGFcWUZ0WQ==",
  drawio_embed_url: process.env?.REACT_APP_DRAWIO_EMBED_URL ?? "https://hontrel-dev-drawio.iehub.ai/",
  amplitude_api_key: process.env?.REACT_APP_AMPLITUDE_API_KEY ?? "3f8fd96819eef0242d44d24b4fedc9fb",
  zoho_desk_refresh_token: process.env?.REACT_APP_ZOHO_DESK_REFRESH_TOKEN ?? "1000.af4b4e4a1141b8166beb80a34e20d167.cbe9eb4e211e8624f1246ea6ec519513",
  enable_amplitude: process.env?.REACT_APP_ENABLE_AMPLITUDE ?? "true"
};
