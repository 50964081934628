import React from "react";
import {
  HighlightSelectBox,
  InternatlServerError,
  LoadingSection,
  MyPaper,
} from "../../../../components";
import { Grid, Box, Typography } from "@mui/material";
import { Chart } from "react-google-charts";
import { useLazyGetDocumentDashboardQuery } from "../../../../redux/services";
import { SelectBoxOption } from "../../../../interfaces";
import { CapitalizeString, generateYearOptions } from "../../../../utils";

const options = generateYearOptions();

export const Dashboard = () => {
  const [getDashboardAPI, { data, isLoading, isFetching, isError, isSuccess }] =
    useLazyGetDocumentDashboardQuery();

  const [year, updateYear] = React.useState<SelectBoxOption | null>(
    options[0] || null
  );

  const getDashboard = () => {
    if (year) {
      getDashboardAPI({ year: year.value });
    }
  };

  const getData = (chartData: any[]) => {
    let _data: any[] = chartData.map((_: any) => [_[0], parseInt(_[1])]) ?? [];
    _data.shift();
    _data.unshift(chartData[0]);
    return _data;
  };

  React.useEffect(() => {
    getDashboard();
    // eslint-disable-next-line
  }, [year]);

  return (
    <Box>
      <MyPaper>
        <Box>
          <Grid container>
            <Grid item xs={12} sm={3}>
              <HighlightSelectBox
                options={options}
                label="Year"
                onChange={(value: SelectBoxOption) => updateYear(value)}
                value={year}
              />
            </Grid>
            {!isLoading &&
              !isFetching &&
              data?.map((d: any) => d.data?.length).filter(Boolean).length <=
                0 &&
              isSuccess && (
                <Grid item xs={12} mt={2}>
                  <Typography color={"error"} sx={{ fontWeight: "bold" }}>
                    No data to display
                  </Typography>
                </Grid>
              )}
          </Grid>
        </Box>
      </MyPaper>

      {(isLoading || isFetching) && (
        <LoadingSection message="Building Dashboard..." />
      )}

      {isError && (
        <InternatlServerError
          message="Something went wrong"
          onRetry={getDashboard}
        />
      )}

      {!isLoading &&
        !isFetching &&
        data?.map((d: any) => d.data?.length).filter(Boolean).length > 0 &&
        isSuccess && (
          <Grid container sx={{ marginTop: "1px" }} spacing={2}>
            {data?.[0]?.data.map((proj: any) => {
              return (
                <Grid item xs={12} sm={6}>
                  <MyPaper>
                    <Chart
                      chartType="PieChart"
                      data={getData(proj ?? [])}
                      options={{
                        title:
                          CapitalizeString(
                            `${proj?.[0]?.[0]?.toString()} vs Count`
                          ) ?? "",
                        pieHole: 0.35,
                        is3D: false,
                      }}
                      width={"100%"}
                      height={"400px"}
                    />
                  </MyPaper>
                </Grid>
              );
            })}

            <Grid item xs={12} sm={6}>
              <MyPaper>
                <Chart
                  chartType="LineChart"
                  width="100%"
                  height="400px"
                  data={getData(data?.[2]?.data ?? [])}
                  options={{
                    title: CapitalizeString(
                      data?.[2]?.name ? "Project Created Vs Month" : ""
                    ),
                    curveType: "function",
                    legend: { position: "bottom" },
                  }}
                />
              </MyPaper>
            </Grid>

            <Grid item xs={12} sm={6}>
              <MyPaper>
                <Chart
                  chartType="PieChart"
                  data={getData(data?.[1]?.data ?? [])}
                  options={{
                    title: CapitalizeString(
                      data?.[1]?.name ? "Created by Vs Count" : ""
                    ),
                    pieHole: 0.35,
                    is3D: false,
                  }}
                  width={"100%"}
                  height={"400px"}
                />
              </MyPaper>
            </Grid>
          </Grid>
        )}
    </Box>
  );
};
