import { Alert, Box, Theme, useTheme } from "@mui/material";
import moment from "moment";
import React from "react";
import { CustomDataGrid, TitleHeader } from "../../../components";
import { useAppDispatch } from "../../../redux";
import { useLazyGetUsersQuery } from "../../../redux/services";
import { closeBackdrop, openBackdrop } from "../../../redux/slices/backdrop";
import { openZohoDeskFeedbackModal } from "../../../utils";
import { DashboardTableColumn } from "../utils";

const useStyles = (theme: Theme) => ({
    root: {
        flexGrow: 1,
        background: theme.palette.background.paper,
        boxShadow: `rgba(99, 99, 99, 0.2) 0px 2px 8px 0px`,
        height: "100%",
        overflow: "scroll",
        borderRadius: theme.spacing(1),
        padding: theme.spacing(2),
    },
    dataGridParent: {
        height: "calc(100% - 40px)",
        overflow: "scroll",
        marginTop: theme.spacing(2),
    }
});

export const Dashboard: React.FC<{ children?: JSX.Element }> = (props) => {
    const dispatch = useAppDispatch();
    const classes = useStyles(useTheme());

    const [getUsers, { ...rest }] = useLazyGetUsersQuery();
    const { data, totalCount } = {data:[], totalCount: 0};

    const [search, setSearch] = React.useState('');
    const [paginationModel, setPaginationModel] = React.useState({ page: 0, pageSize: 10});

    const giveMeRows = () => {
        if (search.trim().length) {
            return data?.filter((_: any) => (_?.name?.toLowerCase()?.includes(search.toLowerCase())
                || _?.ticketNo?.toLowerCase()?.includes(search.toLowerCase())
                || _?.status?.toLowerCase()?.includes(search.toLowerCase())
                || _?.subject?.toLowerCase()?.includes(search.toLowerCase())
                || _?.description?.toLowerCase()?.includes(search.toLowerCase())
                || moment(_.created_at).format('lll').toString().includes(search.toLowerCase())
                || moment(_.updated_at).format('lll').toString().includes(search.toLowerCase())
            ))
        } else { return data }


    };

    let ROWS = giveMeRows();

    React.useEffect(() => {
        getUsers({ ...paginationModel });
        // eslint-disable-next-line
    }, [paginationModel]);

    React.useEffect(() => {
        if (rest.isLoading || rest.isFetching) {
            dispatch(openBackdrop("Fetching tickets..."));
        } else {
            dispatch(closeBackdrop());
        }
        // eslint-disable-next-line
    }, [rest.status]);

    return <Box sx={classes.root}>
        {/* Header */}
        <TitleHeader
            title="Tickets"
            count={ROWS?.length ?? 0}
            showSearch={true}
            search={search}
            onSearchChange={setSearch}
            searchPlaceholder={"Search for Tickets (only within this page)"}
            showCreateBtn={true}
            createBtnLabel={"New Ticket"}
            onCreateBtn={openZohoDeskFeedbackModal}
        />

        {/* Error */}
        {rest.isError && <Alert sx={{ mt: 2 }} severity="error">Oops! Something went wrong, Unable to fetch "Users". Try Again Later!</Alert>}

        {/* Data Grid */}
        <Box mt={2}>
            <CustomDataGrid
                id="tickets-list"
                initialState={{
                    sorting: {
                        sortModel: [{ field: 'sequence_id', sort: 'desc' }],
                    },
                }}
                rows={ROWS}
                columns={DashboardTableColumn()}
                getRowId={(row) => row.user_id}
                rowCount={totalCount}
                pagination
                paginationModel={paginationModel}
                paginationMode="server"
                onPaginationModelChange={setPaginationModel}
            />
        </Box>
    </Box>
}