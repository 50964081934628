import { Alert, Box, Theme, useTheme } from "@mui/material";
import moment from "moment";
import { useSnackbar } from "notistack";
import React from "react";
import { CustomDataGrid, DeletePanel, TitleHeader } from "../../../components";
import { useAppDispatch, useAppSelector } from "../../../redux";
import { useLazyGetAllBOMReportsQuery, useDeleteBOMReportMutation, useLazyGetProductsAsDDQuery, useLazyGetProjectsAsDDQuery, useLazyGetProposalsAsDDQuery } from "../../../redux/services";
import { closeBackdrop, openBackdrop } from "../../../redux/slices/backdrop";
import { closeConfirmationDialog, openConfirmationDialog } from "../../../redux/slices/confirmationDialog";
import { DashboardTableColumn } from "../utils";
import { matchPath, useLocation, useNavigate, useParams } from "react-router-dom";
import { AppRoutes } from "../../../router/routes";
import { getObjectType } from "../addEditBOMReport";
import { openDrawer } from "../../../redux/slices/drawer";
import { toggleBadge } from "../../../redux/slices/notifications";
import { ReportsView } from "./reports";
import { PublishComponent } from "../../materials/dashboard";
import { openDialog } from "../../../redux/slices/dialog";
import { HasAccess } from "../../../router/authorization";
import { PERMISSIONS } from "../../../router/permission";
import { GridSortDirection, GridSortModel } from "@mui/x-data-grid-premium";

const useStyles = (theme: Theme) => ({
    root: {
        flexGrow: 1,
        background: theme.palette.background.paper,
        boxShadow: `rgba(99, 99, 99, 0.2) 0px 2px 8px 0px`,
        height: "100%",
        overflow: "scroll",
        borderRadius: theme.spacing(1),
        padding: theme.spacing(2),
    },
    dataGridParent: {
        height: "calc(100% - 40px)",
        overflow: "scroll",
        marginTop: theme.spacing(2),
    }
});

export const Dashboard: React.FC<{ paths?: Array<any>, object_type?: string }> = ({ paths = [], object_type = "" }) => {

    const location = useLocation();
    const navigate = useNavigate();
    const { id = "" } = useParams();
    const dispatch = useAppDispatch();
    const classes = useStyles(useTheme());
    const { enqueueSnackbar } = useSnackbar();
    const { perm } = useAppSelector(store => store.auth.userDetails);

    const [getBOMReports, { data: allData = { data: [], totalCount: 0 }, isFetching, isError }] = useLazyGetAllBOMReportsQuery();
    const [getProducts, productsResponse] = useLazyGetProductsAsDDQuery();
    const [getProjects, projectsResponse] = useLazyGetProjectsAsDDQuery();
    const [getProposals, proposalsResponse] = useLazyGetProposalsAsDDQuery();
    const [deleteBOMReportMutation] = useDeleteBOMReportMutation();

    const [paginationModel, setPaginationModel] = React.useState({ page: 0, pageSize: 10 });
    const [search, setSearch] = React.useState("");
    const [queryOptions, setQueryOptions] = React.useState({
        sortModel: [{ field: 'sequence_id', sort: 'desc' as GridSortDirection }],
    });

    const { data, totalCount } = allData;

    const creatable = React.useMemo(() => HasAccess(perm, PERMISSIONS.BOM_REPORT_CREATE), [perm]);
    const readable = React.useMemo(() => HasAccess(perm, PERMISSIONS.BOM_REPORT_READ), [perm]);
    const deletable = React.useMemo(() => HasAccess(perm, PERMISSIONS.BOM_REPORT_DELETE), [perm]);
    const publishable = React.useMemo(() => HasAccess(perm, PERMISSIONS.BOM_REPORT_PUBLISH), [perm]);

    const viewBOMReport = (data: any) => {
        let stateObj = {
            state: {
                paths: [
                    ...paths,
                    {
                        title: "BOM Report",
                        path: -1,
                    }
                ]
            }
        }
        if (id && object_type) {
            navigate(AppRoutes.viewIndividualBOMReportOnModule(object_type, id, data?._id ?? "New"), stateObj)
        } else {
            navigate(AppRoutes.viewBOMReport(data?._id ?? "New"), stateObj)
        }
    };

    const deleteBOMReport = (bomRep: any) => {
        dispatch(
            openConfirmationDialog({
                title: "Are you sure?",
                body: `Do you want to delete the BOM Report ${bomRep.sequence_id}?`,
                positiveBtn: "Delete",
                negativeBtn: "Cancel",
                onOk: () => performRowDel(bomRep),
                onNegativeBtn: () => dispatch(closeConfirmationDialog()),
            })
        );
    };

    const performRowDel = async (bomRep: any) => {
        dispatch(openBackdrop("Deleting BOM Report..."));
        let res: any = {};
        try {
            res = await deleteBOMReportMutation({ id: bomRep._id })

            if (Object.keys(res).includes("data")) {
                enqueueSnackbar(`Deleted BOM Report Successfully!`, { variant: "success" })
                dispatch(closeConfirmationDialog());
                refetch();
            } else if (Object.keys(res).includes("error")) {
                // eslint-disable-next-line 
                throw res.error.data;
            } else {
                // eslint-disable-next-line 
                throw "Data not found"
            }
        } catch (error: any) {
            let errorMessage: string = error?.title ?? "Oops! Something went wrong, Unable to delete"
            enqueueSnackbar(errorMessage, { variant: "error" });
        } finally {
            dispatch(closeBackdrop());
        }
    };

    const giveMeRows = (): any[] => {
        if (!isFetching && data) {
            if (search.trim().length) {
                return data?.filter((_: any) => (_?.sequence_id?.toLowerCase()?.includes(search.toLowerCase())
                    || _?.long_description?.toLowerCase()?.includes(search.toLowerCase())
                    || _?.group_name?.toLowerCase()?.includes(search.toLowerCase())
                    || _?.noun_name?.toLowerCase()?.includes(search.toLowerCase())
                    || _?.external_ref_id?.toLowerCase().includes(search.toLowerCase())
                    || _?.created_user?.toLowerCase().includes(search.toLowerCase())
                    || moment(_.created_time).format('lll').toString().includes(search.toLowerCase())
                )) ?? []
            }
            return data;
        } else {
            return [];
        }
    };

    const view = (data: any) => viewBOMReport(data);

    const viewPreview = (row: any) => {
        let module = getObjectType(location);
        if (module) {
            navigate(AppRoutes.previewBOMReportOnModule(getObjectType(location), id, row?._id))
        } else {
            navigate(AppRoutes.previewBOMReport(row?._id));
        }
    };

    const openReportsViewer = (row: any) => {
        dispatch(openDrawer({
            title: "Full Reports",
            component: <ReportsView documents={row?.attachments ?? []} id={row?._id} />,
            onClose: () => dispatch(toggleBadge(false))
        }))
    };

    const updatePublishStatus = (row: any) => {
        dispatch(
            openConfirmationDialog({
                title: "Publish Operation",
                body: <DeletePanel
                    message={`Are you sure you want to publish the bom report ${row?.sequence_id}?`}
                    warningMessage={`You will not be able to edit this revision after publishing.`}
                />,
                positiveBtn: "Proceed",
                negativeBtn: "Cancel",
                onOk: () => proceedToPublish(row),
                onNegativeBtn: () => dispatch(closeConfirmationDialog())
            })
        );
    };

    const proceedToPublish = (row: any) => {
        dispatch(closeConfirmationDialog());
        dispatch(openDialog({
            title: "",
            body: <PublishComponent {...row} refetch={refetch} object_type="bomReport" object_id={row?._id} />,
            hidePositiveBtn: true,
            hideNegativeBtn: true,
            enablePadding: false
        }));
    };

    const ROWS = giveMeRows();

    const handleSortModelChange = React.useCallback((sortModel: GridSortModel) => {
        // Here you save the data you need from the sort model
        setQueryOptions({ sortModel: [...sortModel] });
    }, [])

    const refetch = () => {
        if (readable) {
            getBOMReports({ 
                ...paginationModel, 
                object_type: getObjectType(location), 
                object_id: id,
                sort_by: queryOptions?.sortModel?.[0]?.field, 
                sort_order: queryOptions?.sortModel?.[0]?.sort,
            })
        }
    };

    React.useEffect(() => {
        refetch();
        // eslint-disable-next-line
    }, [paginationModel, JSON.stringify(queryOptions?.sortModel)]);

    React.useEffect(() => {
        if (matchPath(location.pathname, AppRoutes.bomReport) && readable) {
            getProducts({});
            getProjects({});
            getProposals({});
        }
        // eslint-disable-next-line
    }, []);


    return <Box sx={classes.root}>
        {!readable && <Alert sx={{ mt: 2 }} severity="warning">You don't have permission to view this section, contact admin!</Alert>}
        {readable && <>
            {/* Header */}
            <TitleHeader
                title="BOM Report"
                count={ROWS?.length ?? 0}
                showSearch={true}
                search={search}
                onSearchChange={setSearch}
                searchPlaceholder={"Search for BOM Report (only within this page)"}
                showCreateBtn={creatable}
                createBtnLabel={"Create BOM Report"}
                onCreateBtn={() => viewBOMReport(null)}
            />

            {/* Error */}
            {isError && <Alert sx={{ mt: 2 }} severity="error">Oops! Something went wrong, Unable to fetch BOM report. Try Again Later!</Alert>}

            {/* Data Drid */}
            <Box mt={2}>
                <CustomDataGrid
                    id={`${object_type ? `${object_type}-` : ""}bom-report-list`}
                    // initialState={{
                    //     sorting: {
                    //         sortModel: [{ field: 'sequence_id', sort: 'desc' }],
                    //     },
                    // }}
                    rows={ROWS}
                    columns={DashboardTableColumn(deleteBOMReport, view, viewPreview, productsResponse?.data ?? [], projectsResponse?.data ?? [], proposalsResponse?.data ?? [], openReportsViewer, updatePublishStatus, !deletable, publishable)}
                    loading={isFetching}
                    getRowId={(row) => row._id}
                    showToolbar={true}
                    rowCount={totalCount}
                    paginationModel={paginationModel}
                    paginationMode="server"
                    onPaginationModelChange={setPaginationModel}
                    sortingMode="server"
                    onSortModelChange={handleSortModelChange}
                />
            </Box>
        </>}
    </Box>
}