import { createSlice } from '@reduxjs/toolkit';

interface initialStateType {
    open: boolean,
    title: string,
    body: string,
    positiveBtn: string,
    negativeBtn: string,
    hideNegativeBtn: boolean,
    hidePositiveBtn: boolean,
    hideForceBtn: boolean,
    forceBtn: string,
    onForce: () => void,
    onOk: () => void,
    onNegativeBtn: () => void,
    hideCloseIcon: boolean,
}

const initialState: initialStateType = {
    open: false,
    title: "Dialog",
    body: "",
    positiveBtn: "Ok",
    negativeBtn: "Cancel",
    forceBtn: "Force",
    onForce: () => null,
    onOk: () => null,
    onNegativeBtn: () => null,
    hideNegativeBtn: false,
    hidePositiveBtn: false,
    hideForceBtn: true,
    hideCloseIcon: false,
}

const confirmationDialogSlice = createSlice({
    name: 'dialog',
    initialState,
    reducers: {
        openConfirmationDialog(state, { payload }) {
            let {
                title = "Dialog", body = "", positiveBtn = "Ok", negativeBtn = "Cancel",
                onOk = () => null, hideNegativeBtn = false, hidePositiveBtn = false, onNegativeBtn = () => null,
                hideForceBtn = true, forceBtn = "Force", onForce = () => null, hideCloseIcon = false,
            } = payload;
            state.open = true;
            state.title = title;
            state.body = body;
            state.positiveBtn = positiveBtn;
            state.negativeBtn = negativeBtn;
            state.forceBtn = forceBtn;
            state.onForce = onForce;
            state.onOk = onOk;
            state.onNegativeBtn = onNegativeBtn;
            state.hideNegativeBtn = hideNegativeBtn;
            state.hidePositiveBtn = hidePositiveBtn;
            state.hideForceBtn = hideForceBtn;
            state.hideCloseIcon = hideCloseIcon;
        },
        closeConfirmationDialog(state) {
            state.open = false;
            state.body = "";
        },
        updateCongirmationDialogBody(state, { payload }) {
            state.body = payload;
        },
        updateConfirmationDialogForceBtn(state, { payload }) {
            const { forceBtn = "Force", onForce = () => null, hideForceBtn = true } = payload;
            state.forceBtn = forceBtn;
            state.onForce = onForce;
            state.hideForceBtn = hideForceBtn;
        },
        updateConfirmationDialogPositiveBtn(state, { payload }) {
            const { positiveBtn = state.positiveBtn, hidePositiveBtn = state.hidePositiveBtn, onOk = state.onOk } = payload;
            state.hidePositiveBtn = hidePositiveBtn;
            state.onOk = onOk;
            state.positiveBtn = positiveBtn;

        }
    },
})

export const { openConfirmationDialog, closeConfirmationDialog, updateCongirmationDialogBody, updateConfirmationDialogForceBtn, updateConfirmationDialogPositiveBtn } = confirmationDialogSlice.actions;
export default confirmationDialogSlice.reducer;