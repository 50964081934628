import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { Box, Collapse, IconButton, Stack, Typography } from "@mui/material";
import React from "react";
import { MyPaper, TextField } from "../../../components";
import { useAppDispatch, useAppSelector } from "../../../redux";
import { updateBOMSliceByKeyValue } from "../../../redux/slices/bom";

export const Notes: React.FC<{ children?: JSX.Element, isViewOnly?:boolean }> = (props) => {

    const { bom } = useAppSelector(store => store);
    const dispatch = useAppDispatch();

    const [open, updateOpen] = React.useState(true);

    const updateState = (key: string, value: any) => dispatch(updateBOMSliceByKeyValue({ key, value }));

    return <MyPaper padding={0}>
        <Box>
            {/* Heading */}
            <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"} sx={{ px: 2, py: 1, borderBottom: (theme) => `1px solid ${theme.palette.divider}` }}>

                <Typography variant="body1" fontFamily={"htrts_medium"}>
                    Notes
                </Typography>

                <Stack direction="row" spacing={1}>
                    <IconButton size={"small"} onClick={(e) => updateOpen(!open)}>
                        {!open && <ExpandMore />}
                        {open && <ExpandLess />}
                    </IconButton>
                </Stack>
            </Stack>

            {/* Main Section */}
            <Collapse in={open} timeout="auto" unmountOnExit>
                <Box sx={{ padding: 2 }}>
                    <TextField
                        id="notes"
                        variant="outlined"
                        size="small"
                        fullWidth
                        type={"number"}
                        value={bom.notes}
                        label={"Notes"}
                        multiline
                        maxRows={8}
                        rows={5}
                        onChange={(e) => updateState("notes", e.target.value)}
                        viewOnly={props.isViewOnly}
                    />
                </Box>
            </Collapse>
        </Box>

    </MyPaper>
}