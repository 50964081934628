import { createApi, fetchBaseQuery, retry } from '@reduxjs/toolkit/query/react';
import Axios from "axios";
import { config } from '../../../config';
import { LocalStorageKeys, customBtoa, getTransformedDataForPagination, ConvertToReactSelect, objectToQueryParams } from '../../../utils';
import { ProposalSliceInterface, linksData } from '../../slices/proposal';

const prepareProposalBody = (payload: ProposalSliceInterface, linkedData?: linksData) => {
    let body: any = {
        "customer_name": payload.basic_details.customer,
        "description": payload.basic_details.description,
        "proposal_manager": payload.basic_details.proposalManager,
        "proposal_name": payload.basic_details.proposal_name,
        "tags_info": payload.proposal_tags.tags.map((tag) => {
            return {
                "tag_group_id": tag.tag_group_id,
                "tag_id": tag.value
            }
        }),
        "notes": customBtoa(payload?.notes ?? ""),
        "links": linkedData?.links ?? payload?.links_data?.links,
        "unlinks": linkedData?.unlinks ?? payload?.links_data?.unlinks,
        "cost_center": payload.basic_details.cost_center,
        "reference_number": payload.basic_details.reference_number
    }
    if (payload.basic_details.start_date) body.start_date = payload.basic_details.start_date;
    if (payload.basic_details.finish_date) body.finish_date = payload.basic_details.finish_date;
    if (payload.basic_details?.type) body.tags_info.push({
        "tag_group_id": payload.basic_details.type?.tag_group_id ?? "",
        "tag_id": payload.basic_details.type?.value ?? ""
    });
    if (payload.basic_details?.businessUnit) body.tags_info.push({
        "tag_group_id": payload.basic_details.businessUnit?.tag_group_id ?? "",
        "tag_id": payload.basic_details.businessUnit?.value ?? ""
    });
    if (payload.basic_details?.status) body.tags_info.push({
        "tag_group_id": payload.basic_details.status?.tag_group_id ?? "",
        "tag_id": payload.basic_details.status?.value ?? ""
    });
    return body;
}

export const proposalAPI = createApi({
    reducerPath: "proposalAPI",
    baseQuery: retry(fetchBaseQuery({
        baseUrl: config.api_url + "propm/proposal",
        prepareHeaders: (headers, { getState }) => {
            const token = localStorage.getItem(LocalStorageKeys.authToken);

            // If we have a token set in state, let's assume that we should be passing it.
            if (token) {
                headers.set('authorization', `Bearer ${token}`)
            }

            return headers
        },
    }), { maxRetries: 1 }),
    tagTypes: ['Proposals'],
    endpoints: (builder) => ({
        // Get Proposals API
        getProposals: builder.query({
            query: ({ page = 0, pageSize = 10, sort_by = null, sort_order = null }) => ({
                url: `${page !== null ? `?page_no=${page + 1}` : ''}${pageSize ? `&page_size=${pageSize}` : ''}${sort_by ? `&sort_by=${sort_by}` : ''}${sort_order ? `&sort_order=${sort_order}` : ''}`,
                method: "GET"
            }),
            transformResponse: (data: any, meta: { request: Request; response?: Response }) => {
                return getTransformedDataForPagination(data, meta?.response);
            },
            providesTags: ["Proposals"]
        }),

        // Get Proposals as Options API
        getProposalsAsDD: builder.query({
            query:  (payload: { page_no?: number, page_size?: number }) => ({
                url: `${objectToQueryParams(payload)}`,
                method: "GET"
            }),
            transformResponse: (response: any[], meta, arg) => ConvertToReactSelect(response, "_id", "sequence_id", ["proposal_name"]),
        }),

        // Get Proposal by project id API
        getProposalById: builder.query({
            query: ({ id }) => ({ url: `/${id}`, method: "GET" }),
        }),

        // Create Proposal API
        createProposal: builder.mutation({
            query: (payload: ProposalSliceInterface) => ({ method: "POST", body: { ...prepareProposalBody(payload) } }),
            invalidatesTags: ["Proposals"]
        }),

        // Update Proposal API
        updateProposal: builder.mutation({
            query: ({ id, payload, update_version }: { id: string, update_version: boolean, payload: ProposalSliceInterface }) => {
                return { url: `/${id}?update_version=${false}`, method: "PUT", body: { ...prepareProposalBody(payload) } }
            },
            invalidatesTags: ["Proposals"]
        }),

        // Delete Proposal API
        deleteProposal: builder.mutation({
            query: ({ id, force }) => ({ url: `/${id}?force=${force}`, method: "DELETE" }),
            invalidatesTags: ["Proposals"]
        }),

        // Get Dashboard Data
        getProposalDashboard: builder.query({
            query: ({ year = '' }) => ({ url: `/dashboard${year.length ? `?year=${year}` : ''}`, method: "GET" })
        }),

        // proposal Image Upload
        uploadProposalImage: builder.mutation({
            async queryFn(_arg, _queryApi, _extraOptions) {
                const { data, url, file } = _arg;
                let result: any = {};

                try {
                    let resData = null;
                    if (file) {
                        resData = await Axios({
                            url,
                            method: "put",
                            headers: { "Content-Type": file.type },
                            data: file,
                        });
                    }
                    result = { data: { resData, document: data } };
                } catch (err) {
                    result = { error: err };
                } finally {
                    return result;
                }
            },
            invalidatesTags: ["Proposals"]
        }),
    })
})

// Export hooks for usage in functional components
export const { useGetProposalsQuery, useLazyGetProposalsQuery, useGetProposalByIdQuery, useLazyGetProposalByIdQuery, useCreateProposalMutation, useUpdateProposalMutation, useLazyGetProposalDashboardQuery, useDeleteProposalMutation, useUploadProposalImageMutation, useLazyGetProposalsAsDDQuery } = proposalAPI
