import { DeleteOutline, Settings, ZoomOutMapTwoTone } from "@mui/icons-material";
import { Button, IconButton, Tooltip, Typography } from "@mui/material";
import { GridRenderCellParams } from "@mui/x-data-grid-premium";
import moment from "moment";
import { PublishedIcon } from "../../images/svg/publishedIcon";
import { WIPIcon } from "../../images/svg/WIPIcon";
import { SelectBoxOption } from "../../interfaces";

export const DashboardTableColumn = (
  onDelete: any, view: any, viewPreview: any,
  products: SelectBoxOption[], projects: SelectBoxOption[], proposals: SelectBoxOption[],
  openReportsViewer: (row: any) => void, updatePublishStatus: (row: any) => void, hideDelete: boolean,
  publishable?: boolean
) => {
  let columns = [
    {
      field: 'sequence_id', headerName: 'BOM Report ID', width: 150, renderCell: (params: GridRenderCellParams) => {
        if (params.rowNode.type === 'group') {
          return params.value;
        }
        return <Typography sx={{ color: 'blue', textDecoration: "underline", cursor: "pointer" }} variant="body2" onClick={() => view(params.row)}>{params.row?.sequence_id}</Typography >
      }
    },
    {
      field: 'name', headerName: 'BOM Report Name', width: 250, renderCell: (params: GridRenderCellParams) => {
        if (params.rowNode.type === 'group') {
          return params.value;
        }
        return <Typography variant="body2">{params.row?.name}</Typography>
      }
    },
    {
      field: 'preview', headerName: 'Preview', width: 150, renderCell: (params: GridRenderCellParams) => {
        if (params.rowNode.type === 'group') {
          return params.value;
        }
        return (
          params.row?.processing ? <Settings
            color="disabled"
            sx={{
              animation: "spin 2s linear infinite",
              "@keyframes spin": {
                "0%": {
                  transform: "rotate(360deg)",
                },
                "100%": {
                  transform: "rotate(0deg)",
                },
              },
            }}
          /> :
            <IconButton size={"small"} onClick={() => viewPreview(params.row)}>
              <ZoomOutMapTwoTone color={"info"} fontSize="small" />
            </IconButton>

        )
      },
      disableColumnMenu: true,
      sortable: false,
    },
    {
      field: "released",
      headerName: "Status",
      width: 150,
      renderCell: (params: GridRenderCellParams) => {
        return (
          params?.row?.released ? (
            <Button disableRipple disableFocusRipple sx={{ cursor: "default", '&:hover': { backgroundColor: 'transparent' } }} id="bomReport-published">
              <Tooltip
                title="Published"
                children={<div><PublishedIcon /></div>}
              />
            </Button>
          ) : (
            <Button disableRipple disableFocusRipple id="bomReport-publish">
              <Tooltip
                title={publishable ? "Work in progress, click to publish the item" : "Not Published"}
                children={
                  <div><WIPIcon onClick={() => publishable ? updatePublishStatus(params.row) : false} /></div>
                }
              />
            </Button>
          )
        );
      },
    },
    {
      field: 'full-reports', headerName: 'Full Reports', width: 150, renderCell: (params: GridRenderCellParams) => {
        if (params.rowNode.type === 'group') {
          return params.value;
        }
        return (
          <Button size={"small"} onClick={() => openReportsViewer(params.row)} disabled={(params?.row?.attachments?.length === 0) || params.row?.processing} id="view-report">
            {"View Reports"}
          </Button>
        )
      },
      disableColumnMenu: true,
      sortable: false,
    },
    {
      field: 'linked_with', headerName: 'Linked with', width: 250, renderCell: (params: GridRenderCellParams) => {
        if (params.rowNode.type === 'group') {
          return params.value;
        }
        return <Typography variant="body2">{params.row?.object_type === "product" ? products?.find(p => p?._id === params.row?.object_id)?.sequence_id : params.row?.object_type === "project" ? projects?.find(p => p?._id === params.row?.object_id)?.sequence_id : proposals?.find(p => p?._id === params.row?.object_id)?.sequence_id}</Typography>
      },
      disableColumnMenu: true,
      sortable: false,
    },
    { field: "created_user", headerName: "Created By", width: 150 },
    {
      field: "created_time",
      headerName: "Created Date",
      width: 250,
      renderCell: (params: GridRenderCellParams) => {
        if (params.rowNode.type === "group") {
          return params.value;
        }
        return (
          <Typography variant="body2">
            {moment(params.row?.created_time).format("lll")}
          </Typography>
        );
      },
    },
    { field: "modified_user", headerName: "Last modified by", width: 150 },
    {
      field: "modified_time",
      headerName: "Last modified date",
      width: 250,
      renderCell: (params: GridRenderCellParams) => {
        if (params.rowNode.type === "group") {
          return params.value;
        }
        return (
          <Typography variant="body2">
            {params.row.modified_time
              ? moment(params.row.modified_time).format("lll")
              : ""}
          </Typography>
        );
      },
    },
    { field: 'released_user', headerName: 'Published by', width: 150 },
    {
      field: 'released_time', headerName: 'Published date', width: 250, renderCell: (params: GridRenderCellParams) => {
        if (params.rowNode.type === 'group') {
          return params.value;
        }
        return <Typography variant="body2">{params.row.released_time ? moment(params.row.released_time).format('lll') : ""}</Typography>
      }
    },
    {
      field: 'delete', headerName: 'Delete', width: 100, renderCell: (params: GridRenderCellParams) => {
        return (
          <Tooltip title={params?.row?.released ? `${params?.row?.sequence_id} is published` : `Delete the BOM Report "${params?.row?.sequence_id}"`}>
            <IconButton disableRipple={params?.row?.released} onClick={() => params?.row?.released ? () => { } : onDelete(params.row)}>
              <DeleteOutline color={params?.row?.released ? "disabled" : "error"} />
            </IconButton>
          </Tooltip>
        )
      },
      disableColumnMenu: true,
      sortable: false,
    },
  ];

  if (projects.length === 0 && products.length === 0 && proposals.length === 0) {
    columns = columns.filter(c => c.field !== "linked_with");
  }

  if (hideDelete) {
    columns.pop();
  }

  return columns;
};