import React, { useEffect } from "react";
import {
  Box,
  Button,
  Stack,
  Typography,
  IconButton,
  Collapse,
  Grid,
  Tooltip,
  Alert,
} from "@mui/material";
import { Documents, HighlightSelectBox, MyPaper, TextField } from "../../../../components";
import { SelectBoxOption } from "../../../../interfaces/selectBox";
import {
  Delete,
  Edit,
  ExpandLess,
  ExpandMore,
  FlipToFront,
  Save,
} from "@mui/icons-material";
import { IssueMaterials } from "../material";
import { LoadingButton } from "@mui/lab";
import {
  useLazyGetIssueByIdQuery,
  useGetIssueTrackerTagsQuery,
  useCreateIssueMutation,
  useUpdateIssueMutation,
  useLazyGetAllIssuesQuery,
  useLinkModuleMutation,
  useUnlinkModuleMutation,
  useLazyGetProductsAsDDQuery,
  useLazyGetProjectsAsDDQuery,
  useLazyGetProposalsAsDDQuery,
  useLazyGetProductByIdQuery,
  useLazyGetProjectByIdQuery,
  useLazyGetProposalByIdQuery,
} from "../../../../redux/services";
import { useAppDispatch, useAppSelector } from "../../../../redux";
import { closeBackdrop, openBackdrop } from "../../../../redux/slices/backdrop";
import {
  updateIssueSlice,
  linksData,
  updateIssueSliceByKeyValue,
  IssueTrackerBasicDetails,
  IssueTrackerDetails,
  updateErrors,
  resetIssueSlice,
  type IssueReason,
} from "../../../../redux/slices/issueTracker";
import moment from "moment";
import {
  closeConfirmationDialog,
  openConfirmationDialog,
} from "../../../../redux/slices/confirmationDialog";
import { useSnackbar } from "notistack";
import { TextOnlyEditor } from "../../../../HOCs";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { IssueBOMs } from "../boms";
import { getObjectType } from "../../../listview/addEditListView";
import { HasAccess } from "../../../../router/authorization";
import { PERMISSIONS } from "../../../../router/permission";

export const ChangeOrderOptions: SelectBoxOption[] = [
  { value: "Yes", label: "Yes" },
  { value: "No", label: "No" },
];

const editorButtonList = [
  ["undo", "redo"],
  ["font", "fontSize", "formatBlock"],
  ["bold", "underline", "italic", "strike", "subscript", "superscript"],
  ["removeFormat"],
  ["fontColor", "hiliteColor"],
  ["outdent", "indent"],
  ["align", "horizontalRule", "list", "table"],
  ["link"],
];

const mandateKeys: {
  parentKey: keyof IssueTrackerDetails;
  childKey: keyof IssueTrackerBasicDetails;
}[] = [
    { parentKey: "basic_details", childKey: "issue_status" },
    { parentKey: "basic_details", childKey: "issue_category" },
    { parentKey: "basic_details", childKey: "issue_tag" },
    { parentKey: "basic_details", childKey: "why_issue" },
    { parentKey: "basic_details", childKey: "change_req" },
  ];

export const ViewIssueDetails: React.FC<{
  children?: JSX.Element;
  isEdit: boolean;
  setSequenceId: React.Dispatch<React.SetStateAction<string>>;
}> = ({ isEdit, setSequenceId, ...props }) => {
  const { issueTracker, auth } = useAppSelector((store) => store);

  const { issue_id = "", id: p_id = "" } = useParams();

  const location = useLocation();

  const { perm } = auth.userDetails;

  const creatable = React.useMemo(() => {
    const primaryAccess = HasAccess(perm, PERMISSIONS.QUALITY_CREATE);
    const module = getObjectType(location);
    if (module === 'product') {
      return HasAccess(perm, PERMISSIONS.PRODUCT_UPDATE) && primaryAccess
    }
    if (module === 'proposal') {
      return HasAccess(perm, PERMISSIONS.PROPOSAL_UPDATE) && primaryAccess
    }
    if (module === 'project') {
      return HasAccess(perm, PERMISSIONS.PROJECT_UPDATE) && primaryAccess
    }
    return primaryAccess
  }, [perm, location]);

  const editable = React.useMemo(() => {
    const primaryAccess = HasAccess(perm, PERMISSIONS.QUALITY_UPDATE);
    const module = getObjectType(location);
    if (module === 'product') {
      return HasAccess(perm, PERMISSIONS.PRODUCT_UPDATE) && primaryAccess
    }
    if (module === 'proposal') {
      return HasAccess(perm, PERMISSIONS.PROPOSAL_UPDATE) && primaryAccess
    }
    if (module === 'project') {
      return HasAccess(perm, PERMISSIONS.PROJECT_UPDATE) && primaryAccess
    }
    return primaryAccess
  }, [perm, location]);

  const isViewOnly = isEdit ? !editable : !creatable;

  const paths = location?.state?.paths ?? [];

  const [getIssueById, issueData] = useLazyGetIssueByIdQuery({
    refetchOnReconnect: true,
    refetchOnFocus: true,
  });
  const [open, updateOpen] = React.useState(false);
  const [edit, updateEdit] = React.useState(false);
  const dispatch = useAppDispatch();
  const tagsData = useGetIssueTrackerTagsQuery(null, {
    refetchOnMountOrArgChange: true,
    refetchOnFocus: true,
  });
  const [createIssue, createIssueData] = useCreateIssueMutation();
  const [updateIssue, updateIssueData] = useUpdateIssueMutation();
  const [linkDocument] = useLinkModuleMutation();
  const [unlinkDocument] = useUnlinkModuleMutation();

  const [getAllIssues] = useLazyGetAllIssuesQuery({ refetchOnFocus: true });

  const [getAllProducts, productsRes] = useLazyGetProductsAsDDQuery({ refetchOnFocus: true });
  const [getAllProjects, projectsRes] = useLazyGetProjectsAsDDQuery({ refetchOnFocus: true });
  const [getAllProposals, proposalsRes] = useLazyGetProposalsAsDDQuery({ refetchOnFocus: true });

  const moduleLoading = productsRes.isFetching || productsRes.isLoading || projectsRes.isLoading || projectsRes.isLoading || proposalsRes.isLoading || proposalsRes.isLoading;

  const [getProductById] = useLazyGetProductByIdQuery({ refetchOnReconnect: true, refetchOnFocus: true });
  const [getProjectById] = useLazyGetProjectByIdQuery({ refetchOnReconnect: true, refetchOnFocus: true });
  const [getProposalById] = useLazyGetProposalByIdQuery({ refetchOnReconnect: true, refetchOnFocus: true });

  const { enqueueSnackbar } = useSnackbar();

  const navigate = useNavigate();

  const [issueLink, setIssueLink] = React.useState<SelectBoxOption | null>(null);

  const [hasModuleError, setModuleError] = React.useState<boolean>(false);

  const [moduleOptions, setModuleOptions] = React.useState<SelectBoxOption[]>([]);

  const updatedModuleOptions = React.useMemo(() => {
    return moduleOptions.map((option) => {
      const prefix = option.label.split('-')?.[0];
      return {
        prefix: prefix,
        ...option,
      };
    })
  }, [moduleOptions]);

  useEffect(() => {
    if (issue_id === "New" || issue_id === "") dispatch(resetIssueSlice());
  }, [dispatch, issue_id]);

  useEffect(() => {
    if (createIssueData.isSuccess || updateIssueData.isSuccess) {
      dispatch(resetIssueSlice());
      navigate(-1);
    }
  }, [createIssueData, updateIssueData, dispatch, navigate]);

  const updateState = (childKey: string, value: any) => {
    dispatch(
      updateIssueSliceByKeyValue({
        parentKey: "basic_details",
        childKey,
        value,
      })
    );
  };

  const filterModuleIds = (o: { object_type: string }) => {
    return o?.object_type !== "product" && o?.object_type !== "project" && o?.object_type !== "proposal"
  };

  const addEditIssue = (update_version: boolean = false) => {
    dispatch(closeConfirmationDialog());
    let copied = {
      ...issueTracker,
      links_data: {
        ...issueTracker?.links_data,
        links: [
          ...(issueTracker?.links_data?.links || [])?.filter(filterModuleIds),
          { object_ids: [(issueLink?.value as string) ?? ""], object_type: getObjectType(location, issueLink?.label?.split("-")?.[0]) as string },
        ].filter((o) => o?.object_ids?.[0] !== ""),
        unlinks: [
          ...(issueData?.data?.associations || [])?.filter((o: { object_type: string }) => o?.object_type === "product" || o?.object_type === "project" || o?.object_type === "proposal")?.map((o: { object_type: string, object_details: { _id: string }[] }) => ({ object_type: o?.object_type, object_ids: o?.object_details?.map(o => o?._id) })),
        ]
      },
    };
    if (isEdit) {
      updateIssue({ id: issue_id, update_version, payload: copied });
    } else {
      createIssue(copied);
    }
  };

  const checkIsValid = () => {
    let isValid = true;

    for (let index = 0; index < mandateKeys.length; index++) {
      const key = mandateKeys[index];

      if (key.parentKey === "basic_details") {
        if (
          !issueTracker.basic_details[
          key.childKey as keyof IssueTrackerBasicDetails
          ]
        ) {
          isValid = false;
          break; // Break out of the loop if the condition fails
        }

        if (
          Array.isArray(
            issueTracker.basic_details[
            key.childKey as keyof IssueTrackerBasicDetails
            ]
          )
        ) {
          const reasons = issueTracker.basic_details[
            key.childKey as keyof IssueTrackerBasicDetails
          ] as Array<IssueReason>;

          if (reasons.some((w) => w.why_description === "")) {
            isValid = false;
            break; // Break out of the loop if the condition fails
          }
        }
      }
    }

    if (!issueLink) {
      setModuleError(true)
      isValid = false;
    }

    if (!isValid) {
      enqueueSnackbar("Please fill mandatory fields (*)", {
        variant: "warning",
      });
      dispatch(updateErrors(mandateKeys));
    }

    return isValid;
  };

  const onCreateUpdateBtnClicked = () => {
    if (checkIsValid()) {
      if (isEdit) {
        dispatch(
          openConfirmationDialog({
            title: "Update Operation:",
            body: "Are you sure want to update?",
            positiveBtn: "Yes",
            negativeBtn: "No",
            onOk: () => addEditIssue(true),
            onNegativeBtn: () => dispatch(closeConfirmationDialog()),
          })
        );
      } else {
        addEditIssue(false);
      }
    }
  };

  const giveMeLinksAndUnlinks = (
    links: any,
    dlinks: any,
    objectType: any
  ): linksData => {
    let obj: linksData = {
      links: [{ object_ids: links, object_type: objectType }],
      unlinks: [{ object_ids: dlinks ? dlinks : [], object_type: objectType }],
    };
    return obj;
  };

  const callLinkItems = async (
    links?: any,
    refetch?: any,
    dlinks?: any,
    objectType?: string
  ) => {
    const linkedData = giveMeLinksAndUnlinks(links, dlinks, objectType);
    dispatch(
      openBackdrop(
        `${links?.length > 0 ? "Linking" : "UnLinking"} ${objectType}...`
      )
    );
    let res: any = {};
    try {
      if (links?.length > 0) {
        res = await linkDocument({ object_id: issue_id, object_type: "issue", payload: { links: linkedData.links } })
      } else {
        res = await unlinkDocument({ object_id: issue_id, object_type: "issue", payload: { unlinks: linkedData.unlinks } });
      }

      if (Object.keys(res).includes("data")) {
        enqueueSnackbar(
          links?.length > 0
            ? `Linked ${objectType} to Issue`
            : `UnLinked ${objectType}`,
          { variant: "success" }
        );
        dispatch(closeConfirmationDialog());
        getIssueById({ id: issue_id });
        refetch();
      } else if (Object.keys(res).includes("error")) {
        // eslint-disable-next-line
        throw res.error.data;
      }
    } catch (error: any) {
      let errorMessage: string =
        error?.title ?? error ??
        `Oops! Something went wrong, Unable to ${links?.length > 0 ? "link" : "remove"
        } document`;
      enqueueSnackbar(errorMessage, { variant: "error" });
      refetch();
    } finally {
      dispatch(closeBackdrop());
    }
  };

  React.useEffect(() => {
    if (isEdit) {
      dispatch(openBackdrop("Fetching Issue Details..."));
      getIssueById({ id: issue_id });
    }
    // eslint-disable-next-line
  }, [issue_id]);

  React.useEffect(() => {
    const updateValue = async () => {
      if (isEdit && !issueData.isFetching && issueData.isSuccess) {
        let tags: {
          issue_status: SelectBoxOption | null;
          issue_category: SelectBoxOption | null;
          issue_tag: SelectBoxOption[];
        } = { issue_category: null, issue_status: null, issue_tag: [] };
        issueData.data?.tags_info?.forEach((_: any) => {
          if (_.tag_group_name === "Issue Status") {
            tags.issue_status = {
              value: _?.tag_id ?? "",
              label: _?.tag_name ?? "",
              ..._,
            };
          }

          if (_.tag_group_name === "Issue Category") {
            tags.issue_category = {
              value: _?.tag_id ?? "",
              label: _?.tag_name ?? "",
              ..._,
            };
          }

          if (_.tag_group_name === "Issue Tag") {
            tags.issue_tag.push({
              value: _?.tag_id ?? "",
              label: _?.tag_name ?? "",
              ..._,
            });
          }
        });

        setSequenceId(issueData?.data?.sequence_id ?? "---");

        dispatch(
          updateIssueSlice({
            basic_details: {
              issue_id: issueData?.data?.sequence_id ?? "",
              revision: issueData?.data?.revision ?? 0,
              issue_created_On: issueData?.data?.created_time
                ? moment(issueData?.data?.created_time).format("lll")
                : "",
              issue_status: tags.issue_status,
              issue_category: tags.issue_category,
              issue_tag: tags.issue_tag,
              description: issueData?.data?.description ?? "",
              why_issue: issueData.data?.why_issue ?? [],
              impact: issueData?.data?.impact ?? "",
              action: issueData?.data?.action ?? "",
              name: issueData?.data?.name ?? "",
              change_req: issueData?.data?.change_req
                ? ChangeOrderOptions[0]
                : ChangeOrderOptions[1]
            },

            links_data: {
              links: [],
              unlinks: [],
            },
          })
        );
        const issue = issueData?.data?.associations?.find((o: { object_type: string }) => o?.object_type === "product" || o?.object_type === "project" || o?.object_type === "proposal")?.object_details?.[0] ?? null;
        if (issue) {
          setIssueLink({ ...issue, label: issue?.sequence_id, value: issue?._id });
        }
        dispatch(closeBackdrop());
      } else if (paths?.length > 0) {
        setIssueLink({ label: paths?.[1]?.title, value: p_id });
      }
    };
    updateValue();
    // eslint-disable-next-line
  }, [issueData]);

  const isLoading = createIssueData.isLoading || updateIssueData.isLoading;

  React.useEffect(() => {
    if (!createIssueData.isLoading && createIssueData.isSuccess) {
      enqueueSnackbar("Issue Created Successfully!", { variant: "success" });
      let newIssueId = createIssueData.data?._id ?? "New";
      updateState("issue_id", newIssueId);
    }
    // eslint-disable-next-line
  }, [createIssueData.isSuccess]);

  React.useEffect(() => {
    if (!updateIssueData.isLoading && updateIssueData.isSuccess) {
      enqueueSnackbar("Issue Updated Successfully!", { variant: "success" });
    }
    // eslint-disable-next-line
  }, [updateIssueData.isSuccess]);

  React.useEffect(() => {
    if (!createIssueData.isLoading && createIssueData.isError) {
      const error: any = createIssueData.error;
      enqueueSnackbar(error?.data?.title ?? "Opps! Something went wrong unable to create Issue.", {
        variant: "error",
      });
    }
    // eslint-disable-next-line
  }, [createIssueData.isError]);

  React.useEffect(() => {
    if (!updateIssueData.isLoading && updateIssueData.isError) {
      enqueueSnackbar("Opps! Something went wrong unable to update Issue.", {
        variant: "error",
      });
    }
    // eslint-disable-next-line
  }, [updateIssueData.isError]);

  const addAttribute = () => {
    // Why Issue Count Check
    if (issueTracker.basic_details.why_issue.length === 10) {
      enqueueSnackbar("Max 10 Why Issue can be added.", { variant: "warning" });
      return false;
    }

    // Adding a Why Issue
    let newWhy = {
      why_description: "",
      why_seq: issueTracker.basic_details.why_issue.length,
    };
    updateState("why_issue", [...issueTracker.basic_details.why_issue, newWhy]);
  };

  const reorder = (
    list: Array<IssueReason>,
    startIndex: number,
    endIndex: number
  ) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragEnd = (result: any) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const whyAnalysis = reorder(
      issueTracker.basic_details.why_issue,
      result.source.index,
      result.destination.index
    );

    updateState("why_issue", whyAnalysis);
  };

  const deleteRow = (index: number) => {
    // Deleting a why analysis
    if (issueTracker.basic_details && issueTracker?.basic_details?.why_issue) {
      updateState("why_issue", [
        ...issueTracker.basic_details.why_issue.slice(0, index),
        ...issueTracker.basic_details.why_issue.slice(index + 1),
      ]);
    }
  };

  const goBack = () => {
    dispatch(resetIssueSlice());
    getAllIssues({});
    navigate(-1);
  };

  const fetchModuleOptions = async () => {
    try {
      const productOptions = (await getAllProducts({})).data || [];
      const projectOptions = (await getAllProjects({})).data || [];
      const proposalOptions = (await getAllProposals({})).data || [];
      setModuleOptions([...productOptions, ...projectOptions, ...proposalOptions])
    } catch (error: any) {
      console.error(`Error from bom report page: ${error}`);
      setModuleOptions([]);
    }
  };

  const fetchParentData = async () => {
    if (p_id) {
      dispatch(openBackdrop("Fetch the module data..."))
      if (location.pathname.includes("product")) {
        await getProductById({ id: p_id }).unwrap().then((res) => {
          if (Object.keys(res ?? {}).length > 0) {
            setIssueLink({ ...res, label: res?.sequence_id, value: res?._id });
          }
        }).catch((err: any) => {
          enqueueSnackbar(
            "Oops! Something went wrong, Unable to fetch the module data",
            {
              variant: "error",
            }
          );
          console.error("error: ", err)
        }).finally(() => {
          dispatch(closeBackdrop());
        })
      } else if (location.pathname.includes("project")) {
        await getProjectById({ id: p_id }).unwrap().then((res) => {
          if (Object.keys(res ?? {}).length > 0) {
            setIssueLink({ ...res, label: res?.sequence_id, value: res?._id });
          }
        }).catch((err: any) => {
          enqueueSnackbar(
            "Oops! Something went wrong, Unable to fetch the module data",
            {
              variant: "error",
            }
          );
          console.error("error: ", err)
        }).finally(() => {
          dispatch(closeBackdrop());
        })
      } else {
        await getProposalById({ id: p_id }).unwrap().then((res) => {
          if (Object.keys(res ?? {}).length > 0) {
            setIssueLink({ ...res, label: res?.sequence_id, value: res?._id });
          }
        }).catch((err: any) => {
          enqueueSnackbar(
            "Oops! Something went wrong, Unable to fetch the module data",
            {
              variant: "error",
            }
          );
          console.error("error: ", err)
        }).finally(() => {
          dispatch(closeBackdrop());
        })
      }
    }
  };

  const giveMeErrorMessage = () => {
    let message = `Oops! Something went wrong, Unable to ${isEdit ? "Update" : "Create"} Issue. Try Again Later!`;
    let errorData: any = isEdit ? updateIssueData.error : createIssueData.error;
    message = errorData?.data?.title ?? message;
    return message;
  };

  React.useEffect(() => {
    if (p_id) {
      fetchParentData();
    } else {
      fetchModuleOptions();
    }
    // eslint-disable-next-line
  }, [])

  return (
    <MyPaper padding={0}>
      <Stack
        justifyContent={"space-between"}
        height="inherit"
        width={"inherit"}
      >
        <Box>
          <Typography
            sx={{
              px: 2,
              py: 1,
              borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
            }}
            variant="body1"
            fontFamily={"htrts_medium"}
          >
            Issue Details
          </Typography>
          <Stack
            sx={{
              px: 2,
              py: 1,
              borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
            }}
          >
            <Stack direction="row" justifyContent={"space-between"}>
              <Typography
                variant="body1"
                fontFamily={"htrts_medium"}
                sx={{ flex: 1, textAlign: "start" }}
              >
                Issue Id : {issueTracker?.basic_details?.issue_id}
              </Typography>
              <Typography
                variant="body1"
                fontFamily={"htrts_medium"}
                sx={{ flex: 1, textAlign: "end", marginRight: 5 }}
              >
                Issue Rev : {issueTracker?.basic_details?.revision}
              </Typography>
            </Stack>
            <Stack
              direction="row"
              justifyContent={"space-between"}
              sx={{ marginTop: "15px" }}
            >
              <Typography
                variant="body1"
                fontFamily={"htrts_medium"}
                sx={{ flex: 1, textAlign: "start" }}
              >
                Issue CreatedOn :{" "}
                {issueTracker?.basic_details?.issue_created_On}
              </Typography>
            </Stack>
          </Stack>
          <Stack sx={{ px: 2, py: 1 }}>
            <Grid
              container
              sx={{ marginTop: "15px" }}
              spacing={2}
            >
              <Grid item xs={12} md={6}>
                <Typography
                  variant="body1"
                  fontFamily={"htrts_medium"}
                  sx={{
                    color: (theme) => theme.palette.grey[600],
                    flex: 1,
                    textAlign: "start",
                  }}
                >
                  <HighlightSelectBox
                    label={"Issue Status"}
                    margin={"none"}
                    required={true}
                    value={issueTracker?.basic_details?.issue_status}
                    loading={tagsData?.isLoading || tagsData?.isFetching}
                    error={issueTracker.error.basic_details.issue_status}
                    helperText={
                      issueTracker.error.basic_details.issue_status
                        ? "Please fill Issue Status"
                        : ""
                    }
                    options={tagsData?.data?.issueStatusTags ?? []}
                    onChange={(data: SelectBoxOption) =>
                      updateState("issue_status", data)
                    }
                    viewOnly={isViewOnly}
                  />
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography
                  variant="body1"
                  fontFamily={"htrts_medium"}
                  sx={{
                    color: (theme) => theme.palette.grey[600],
                    flex: 1,
                    textAlign: "start",
                  }}
                >
                  <HighlightSelectBox
                    label={"Issue Category"}
                    margin={"none"}
                    required={true}
                    value={issueTracker?.basic_details?.issue_category}
                    loading={tagsData?.isLoading || tagsData?.isFetching}
                    error={issueTracker.error.basic_details.issue_category}
                    helperText={
                      issueTracker.error.basic_details.issue_category
                        ? "Please fill Issue Category"
                        : ""
                    }
                    options={tagsData?.data?.issueCategoryTags ?? []}
                    onChange={(data: SelectBoxOption) =>
                      updateState("issue_category", data)
                    }
                    viewOnly={isViewOnly}
                  />
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              sx={{ marginTop: "15px" }}
              spacing={2}
            >
              <Grid item xs={12} md={6}>
                <Typography
                  variant="body1"
                  fontFamily={"htrts_medium"}
                  sx={{
                    color: (theme) => theme.palette.grey[600],
                    flex: 1,
                    textAlign: "start",
                  }}
                >
                  <HighlightSelectBox
                    label={"Issue Tags"}
                    margin={"none"}
                    required={true}
                    value={issueTracker?.basic_details?.issue_tag}
                    multiple={true}
                    loading={tagsData?.isLoading || tagsData?.isFetching}
                    error={issueTracker.error.basic_details.issue_tag}
                    helperText={
                      issueTracker.error.basic_details.issue_tag
                        ? "Please fill Issue Tags"
                        : ""
                    }
                    options={tagsData?.data?.issueTags ?? []}
                    onChange={(data: SelectBoxOption) =>
                      updateState("issue_tag", data)
                    }
                    viewOnly={isViewOnly}
                  />
                </Typography>
              </Grid>

              {/* ID */}
              <Grid item xs={12} md={6}>
                {p_id
                  ? <TextField
                    label={"ID"}
                    value={issueLink?.sequence_id}
                    InputLabelProps={{ shrink: true }}
                    disabled={true}
                    fullWidth
                    size={"small"}
                    viewOnly={isViewOnly}
                  />
                  :
                  <HighlightSelectBox
                    label={"Select ID *"}
                    onChange={(data: SelectBoxOption) => {
                      setIssueLink(data)
                    }}
                    disabled={p_id !== ""}
                    margin={"none"}
                    loading={moduleLoading}
                    options={updatedModuleOptions}
                    value={updatedModuleOptions.find((d: SelectBoxOption) => d?.value === issueLink?.value) || null}
                    error={hasModuleError}
                    helperText={
                      hasModuleError ? "Please select the ID" : ""
                    }
                    viewOnly={isViewOnly}
                  />}
              </Grid>
            </Grid>
            <Stack
              direction="row"
              justifyContent={"space-between"}
              sx={{ marginTop: "15px" }}
            >
              <TextField
                variant="outlined"
                size="small"
                fullWidth
                required={false}
                value={issueTracker?.basic_details?.description}
                multiline
                rows={8}
                label={"Issue Description"}
                onChange={(e) => updateState("description", e.target.value)}
                viewOnly={isViewOnly}
              />
            </Stack>
          </Stack>
          <Stack sx={{ px: 2, py: 1 }}>
            <Grid xs={12} sm={12} md={12} lg={12} xl={12} p={2}>
              {/* Title */}
              <Typography gutterBottom>
                Why Analysis
                <Button
                  variant={"outlined"}
                  size={"small"}
                  sx={{ marginLeft: 1 }}
                  onClick={(e) => addAttribute()}
                  disabled={
                    issueTracker?.basic_details?.why_issue.filter(
                      (w: IssueReason) => w?.why_description === ""
                    ).length > 0 || isViewOnly
                  }
                >
                  Add Why
                </Button>
              </Typography>

              {/* Draggable Area */}
              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="droppable-why">
                  {(provided: any, _: any) => (
                    <div ref={provided.innerRef} {...provided.droppableProps}>
                      {issueTracker?.basic_details?.why_issue.map(
                        (why: IssueReason, index: number) => {
                          return (
                            <Draggable
                              key={index}
                              draggableId={`draggable-${index + 1}`}
                              index={index}
                            >
                              {(provided: any, _: any) => (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  key={`key${index + 1}`}
                                  id={`div${index + 1}`}
                                >
                                  <Grid
                                    container
                                    alignItems={"flex-start"}
                                    key={index}
                                    spacing={1}
                                    py={2}
                                    sx={{
                                      background: (theme) =>
                                        _.isDragging
                                          ? theme.palette.background.default
                                          : "unset",
                                    }}
                                  >
                                    {!isViewOnly &&
                                      <Grid
                                        {...provided.dragHandleProps}
                                        sx={{ textAlign: "center" }}
                                        item
                                        xs={1}
                                      >
                                        <Tooltip title="Drag Up/Down">
                                          <FlipToFront
                                            fontSize="small"
                                            sx={{ marginTop: 1 }}
                                          />
                                        </Tooltip>
                                      </Grid>
                                    }
                                    <Grid item xs={isViewOnly ? 12 : 9}>
                                      <TextField
                                        variant="outlined"
                                        size="small"
                                        fullWidth
                                        required={true}
                                        error={
                                          issueTracker.error.basic_details
                                            .why_issue[index]
                                        }
                                        helperText={
                                          issueTracker.error.basic_details
                                            .why_issue[index]
                                            ? `Please fill Why Reason ${index + 1
                                            }`
                                            : ""
                                        }
                                        value={why?.why_description ?? ""}
                                        label={`Why Reason ${index + 1}`}
                                        onChange={(e) => {
                                          if (
                                            issueTracker.basic_details &&
                                            issueTracker.basic_details.why_issue
                                          ) {
                                            updateState("why_issue", [
                                              ...issueTracker?.basic_details?.why_issue.slice(
                                                0,
                                                index
                                              ),
                                              {
                                                why_description: e.target.value,
                                                why_seq: index,
                                              },
                                              ...issueTracker?.basic_details?.why_issue.slice(
                                                index + 1
                                              ),
                                            ]);
                                          }
                                        }}
                                        viewOnly={isViewOnly}
                                      />
                                    </Grid>
                                    {!isViewOnly &&
                                      <Grid item xs={1}>
                                        {index ===
                                          issueTracker?.basic_details?.why_issue
                                            ?.length -
                                          1 &&
                                          index !== 0 && (
                                            <Grid
                                              item
                                              xs={12}
                                              sm={1}
                                              md={1}
                                              lg={1}
                                              xl={1}
                                            >
                                              <IconButton
                                                color="error"
                                                size="small"
                                                onClick={(e) => deleteRow(index)}
                                              // disabled={issueTracker?.basic_details?.why_issue?.[index + 1]?.why_description !== ""}
                                              >
                                                <Delete />
                                              </IconButton>
                                            </Grid>
                                          )}
                                      </Grid>
                                    }
                                  </Grid>
                                </div>
                              )}
                            </Draggable>
                          );
                        }
                      )}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </Grid>

            {!isViewOnly &&
              <Stack
                direction="row"
                justifyContent={"space-between"}
                sx={{ marginTop: "15px" }}
              >
                <Typography
                  variant="body1"
                  fontFamily={"htrts_medium"}
                  sx={{ flex: 1, textAlign: "start" }}
                >
                  Impact of the issue
                </Typography>
              </Stack>
            }
            <Stack
              direction="row"
              justifyContent={"space-between"}
              sx={{ marginTop: "15px" }}
            >
              <TextField
                variant="outlined"
                size="small"
                fullWidth
                required={false}
                value={issueTracker?.basic_details?.impact ?? ""}
                label={"Impact of the issue"}
                onChange={(e) => updateState("impact", e.target.value)}
                viewOnly={isViewOnly}
              />
            </Stack>
            {!isViewOnly &&
              <Stack
                direction="row"
                justifyContent={"space-between"}
                sx={{ marginTop: "15px" }}
              >
                <Typography
                  variant="body1"
                  fontFamily={"htrts_medium"}
                  sx={{ flex: 1, textAlign: "start" }}
                >
                  Action Plan
                </Typography>
              </Stack>
            }
            <Stack
              direction="row"
              justifyContent={"space-between"}
              sx={{ marginTop: "15px" }}
            >
              <TextField
                variant="outlined"
                size="small"
                fullWidth
                required={false}
                value={issueTracker?.basic_details?.action ?? ""}
                label={"Action Plan"}
                onChange={(e) => updateState("action", e.target.value)}
                viewOnly={isViewOnly}
              />
            </Stack>
            <Stack
              direction="row"
              justifyContent={"space-between"}
              sx={{ marginTop: "15px" }}
            >
              <Typography variant="body1" fontFamily={"htrts_medium"}>
                Comments
              </Typography>

              {!isViewOnly &&
                <Stack direction="row" spacing={1}>
                  <Button
                    variant="outlined"
                    color={edit ? "success" : "primary"}
                    size={"small"}
                    onClick={() => updateEdit(!edit)}
                    endIcon={
                      edit ? <Save fontSize="small" /> : <Edit fontSize="small" />
                    }
                  >
                    {edit ? "Save" : "Edit"}
                  </Button>
                  <IconButton size={"small"} onClick={(e) => updateOpen(!open)}>
                    {!open && <ExpandMore />}
                    {open && <ExpandLess />}
                  </IconButton>
                </Stack>
              }
            </Stack>
            <Stack
              direction="row"
              justifyContent={"space-between"}
              sx={{ marginTop: "15px" }}
            >
              <Collapse
                in={!open}
                timeout="auto"
                unmountOnExit
                sx={{ width: "100%" }}
              >
                <Box
                  sx={{
                    "& .sun-editor": {
                      border: edit ? "" : "unset",
                    },
                  }}
                >
                  {isEdit && !edit && (
                    <TextOnlyEditor
                      placeholder="Click Edit button to add Notes"
                      setDefaultStyle="border: none !important"
                      defaultValue={issueTracker?.basic_details?.name}
                      readOnly={true}
                      disable={true}
                      height="250px"
                      setOptions={{ buttonList: [] }}
                    />
                  )}
                  {isEdit && edit && (
                    <TextOnlyEditor
                      placeholder="Click Edit button to add Notes"
                      setDefaultStyle="border: none !important"
                      defaultValue={issueTracker?.basic_details?.name}
                      onChange={(note: string) => updateState("name", note)}
                      readOnly={edit ? false : true}
                      disable={edit ? false : true}
                      height="250px"
                      setOptions={{ buttonList: editorButtonList }}
                    />
                  )}
                </Box>
              </Collapse>
            </Stack>
            {isEdit && (
              <>
                <Stack
                  direction="row"
                  justifyContent={"space-between"}
                  sx={{ marginTop: "15px" }}
                >
                  <Typography
                    variant="body1"
                    fontFamily={"htrts_medium"}
                    sx={{ flex: 1, textAlign: "start" }}
                  >
                    Attach Documents
                  </Typography>
                </Stack>
                <Stack
                  direction="row"
                  justifyContent={"space-between"}
                  sx={{ marginTop: "15px", maxHeight: "500px" }}
                >
                  <Documents
                    refetch={() => {
                      getIssueById({ id: issue_id });
                    }}
                    Id={issueTracker?.basic_details?.issue_id}
                    _id={issue_id}
                    objectType="issue"
                    associations={issueData?.data?.associations ?? []}
                    deletetable={true}
                    showHelperBtn={false}
                    showCreateBtn={!isViewOnly}
                    updatable={!isViewOnly}
                    showSearch={false}
                    showFilter={false}
                    showRevision={false}
                    linkDocument={(
                      links: any,
                      refetch: any,
                      dlinks: any,
                      objectType: any
                    ) => {
                      callLinkItems(links, refetch, dlinks, objectType);
                    }}
                  />
                </Stack>

                <Stack
                  direction="row"
                  justifyContent={"space-between"}
                  sx={{ marginTop: "15px" }}
                >
                  <Typography
                    variant="body1"
                    fontFamily={"htrts_medium"}
                    sx={{ flex: 1, textAlign: "start" }}
                  >
                    Material Link
                  </Typography>
                </Stack>
                <Stack
                  direction="row"
                  justifyContent={"space-between"}
                  sx={{ marginTop: "15px", maxHeight: "500px" }}
                >
                  <IssueMaterials
                    refetch={() => {
                      getIssueById({ id: issue_id });
                    }}
                    Id={issueTracker?.basic_details?.issue_id}
                    _id={issue_id}
                    objectType="issue"
                    linkMaterial={(
                      links: any,
                      refetch: any,
                      dlinks: any,
                      objectType: any
                    ) => {
                      callLinkItems(links, refetch, dlinks, objectType);
                    }}
                    associations={issueData?.data?.associations ?? []}
                    isViewOnly={isViewOnly}
                  />
                </Stack>

                <Stack
                  direction="row"
                  justifyContent={"space-between"}
                  sx={{ marginTop: "15px" }}
                >
                  <Typography
                    variant="body1"
                    fontFamily={"htrts_medium"}
                    sx={{ flex: 1, textAlign: "start" }}
                  >
                    BOM Link
                  </Typography>
                </Stack>
                <Stack
                  direction="row"
                  justifyContent={"space-between"}
                  sx={{ marginTop: "15px", maxHeight: "500px" }}
                >
                  <IssueBOMs
                    refetch={() => {
                      getIssueById({ id: issue_id });
                    }}
                    _id={issue_id}
                    objectType="issue"
                    linkBOMs={(
                      links: any,
                      refetch: any,
                      dlinks: any,
                      objectType: any
                    ) => {
                      callLinkItems(links, refetch, dlinks, objectType);
                    }}
                    associations={issueData?.data?.associations ?? []}
                    isViewOnly={isViewOnly}
                  />
                </Stack>
              </>
            )}
            <Stack
              direction="row"
              justifyContent={"space-between"}
              sx={{ marginTop: "15px" }}
            >
              <Typography
                variant="body1"
                fontFamily={"htrts_medium"}
                sx={{
                  color: (theme) => theme.palette.grey[600],
                  flex: 1,
                  textAlign: "start",
                }}
              >
                <HighlightSelectBox
                  label={"Change Order"}
                  margin={"none"}
                  required={true}
                  value={issueTracker?.basic_details?.change_req ?? null}
                  error={issueTracker.error.basic_details.change_req}
                  helperText={
                    issueTracker.error.basic_details.change_req
                      ? "Please fill Change Order"
                      : ""
                  }
                  options={ChangeOrderOptions}
                  onChange={(data: SelectBoxOption) =>
                    updateState("change_req", data)
                  }
                  viewOnly={isViewOnly}
                />
              </Typography>
            </Stack>
          </Stack>

          {/* Alert Box */}
          {(createIssueData.isError || updateIssueData.isError) && <Alert sx={{ mt: 2 }} severity="error">{
            giveMeErrorMessage()
          }</Alert>}

          {!isViewOnly &&
            <Stack
              sx={{
                px: 2,
                py: 1,
                borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
              }}
            >
              <Stack
                direction={"row"}
                alignItems={"center"}
                justifyContent={"center"}
                spacing={1}
                p={1}
              >
                <LoadingButton
                  loading={isLoading}
                  variant="contained"
                  sx={{ width: 250 }}
                  color="primary"
                  onClick={() => onCreateUpdateBtnClicked()}
                >
                  {isEdit ? "Update" : "Create"}
                </LoadingButton>

                {/* Cancel Button */}
                <Button
                  disabled={false}
                  variant="outlined"
                  sx={{ width: 250 }}
                  color="primary"
                  onClick={() => {
                    goBack();
                  }}
                >
                  Cancel
                </Button>
              </Stack>
            </Stack>
          }
        </Box>
      </Stack>
    </MyPaper>
  );
};
