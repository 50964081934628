import { useAuth0 } from "@auth0/auth0-react";
import { Box, Typography } from "@mui/material";
import React from "react";
import { withNavBars } from "../../HOCs";


const Home: React.FC<{ children?: JSX.Element }> = (props) => {

    const { user } = useAuth0();

    return <Box
        height={"inherit"}
        width={"100%"}
        display={"flex"}
        flexDirection={"column"}
        alignItems={"center"}
        justifyContent={"center"}>
        {/* <img src="/logo.png" height={"250px"} alt="logo" /> */}
        <Typography variant={"h2"} gutterBottom sx={{fontFamily:"htrts_bold"}}>Welcome to {<Typography variant={"h2"} sx={{fontFamily:"htrts_bold"}} component={"span"} color="primary">{"IEHUB.AI"}</Typography>}</Typography>
        <Typography variant={"h5"} sx={{fontFamily:"htrts_medium"}}>{user?.name}!</Typography>
    </Box>
}

export default withNavBars(Home);