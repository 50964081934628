import { AlignHorizontalLeft, FilePresent, Folder } from "@mui/icons-material";
import { SelectBoxOption } from "../../interfaces";
import { AppRoutes } from "../../router/routes";

export const ApprovedOptions: SelectBoxOption[] = [{ value: "Yes", label: "Yes" }, { value: "No", label: "No" }];

export const StatusOptions: SelectBoxOption[] = [{ value: "active", label: "Active" }, { value: "inactive", label: "Inactive" }];

export const mandateKeys = [
    { parentKey: "basic_details", childKey: "vendor_name" },
    { parentKey: "basic_details", childKey: "vendor_website" },
    { parentKey: "basic_details", childKey: "approved_vendor" },
    { parentKey: "basic_details", childKey: "status" },
    { parentKey: "primary_contact", childKey: "name" },
    { parentKey: "primary_contact", childKey: "email" },
    { parentKey: "primary_contact", childKey: "mobile" },
    { parentKey: "primary_contact", childKey: "address" },
    { parentKey: "primary_contact", childKey: "country" },
];

export const NavBars = (
    id: string,
    isEdit: boolean = false,
    loading: boolean = false,
    tags: { tag_id: string, tag_name: string }[] = [],
    tag_group_id: string
) => ([
    {
        id: "1",
        title: "Basic Details",
        icon: <AlignHorizontalLeft fontSize="small" />,
        path: AppRoutes.viewVendorBasicDetails(id)
    },
    {
        id: "2",
        title: "Documents",
        disabled: !isEdit,
        icon: <FilePresent fontSize="small" />,
        path: AppRoutes.viewVendorDocuments(id),
        loading,
        subNav: [
            {
                id: "2.1",
                title: "All",
                icon: <Folder htmlColor="#fde100" fontSize="small" />,
                path: `${AppRoutes.viewVendorDocuments(id)}?tags=all`
            },
            ...tags.map(_ => ({
                id: _.tag_id,
                title: _.tag_name,
                icon: <Folder htmlColor="#fde100" fontSize="small" />,
                path: `${AppRoutes.viewVendorDocuments(id)}?tag_id=${_.tag_id}&group_id=${tag_group_id}`
            }))
        ]
    }
])