import { ArticleOutlined, ContentCopy, DeleteOutline, Link } from "@mui/icons-material";
import {
  Box,
  Button,
  Chip,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { GridRenderCellParams } from "@mui/x-data-grid-premium";
import moment from "moment";
import { PublishedIcon } from "../../images/svg/publishedIcon";
import { WIPIcon } from "../../images/svg/WIPIcon";
import { CapitalizeString, GiveMeStatusColor } from "../../utils";

export const DuplicateOptions = [{ value: "children", label: "Duplicate BOM with children" }, { value: "top", label: "Duplicate BOM at the Top Level" }];

export const DashboardTableColumn = (arg: {
  onDelete?: any,
  openRevision?: any,
  view?: any,
  openAssociation?: any,
  showTechnicalCard?: any,
  updatePublishStatus?: (row: any) => void,
  disableNavigation?: boolean,
  removeDelete?: boolean,
  openReferences?: any,
  handleBOMDuplicate?: (row: any) => void,
  showDuplicate?: boolean,
  publishable?: boolean,
}) => {

  const {
    onDelete = () => false,
    view = () => false,
    openAssociation = () => false,
    showTechnicalCard = () => false,
    updatePublishStatus = () => false,
    disableNavigation = false,
    removeDelete = false,
    openReferences = () => false,
    handleBOMDuplicate = () => false,
    showDuplicate = false,
    publishable = false
  } = arg;

  const columns = [
    {
      field: "sequence_id",
      headerName: "BOM ID",
      width: 150,
      renderCell: (params: GridRenderCellParams) => {
        if (params.rowNode.type === "group") {
          return params.value;
        }
        if (disableNavigation) {
          return (
            <Typography variant="body2">{params.row?.sequence_id}</Typography>
          );
        }
        return (
          <Typography
            sx={{
              color: "blue",
              textDecoration: "underline",
              cursor: "pointer",
            }}
            variant="body2"
            onClick={() => view(params.row)}
            id="view-bom-icon"
          >
            {params.row?.sequence_id}
          </Typography>
        );
      },
    },
    {
      field: "version",
      headerName: "Revision",
      width: 100,
      renderCell: (params: GridRenderCellParams) => {
        if (params.rowNode.type === "group") {
          return params.value;
        }
        return <Typography variant="body2" id="bom-version">{params.row?.version}</Typography>;
      },
    },
    {
      field: "duplicate",
      headerName: "Duplicate",
      resizable: true,
      width: 90,
      disableColumnMenu: true,
      sortable: false,
      renderCell: (params: GridRenderCellParams) => {
        if (params.rowNode.type === "group") {
          return params.value;
        }
        return (
          <Tooltip title="Make a Duplicate">
            <IconButton
              sx={{ margin: "auto" }}
              onClick={() =>
                handleBOMDuplicate && handleBOMDuplicate(params?.row)
              }
            >
              <ContentCopy fontSize="small" />
            </IconButton>
          </Tooltip>
        );
      },
    },
    {
      field: "released",
      headerName: "Status",
      width: 150,
      renderCell: (params: GridRenderCellParams) => {
        return (
          params?.row?.released ? (
            <Button disableRipple disableFocusRipple sx={{ cursor: "default", '&:hover': { backgroundColor: 'transparent' } }}>
              <Tooltip
                title="Published"
                children={<div><PublishedIcon /></div>}
              />
            </Button>
          ) : (
            <Button disableRipple disableFocusRipple id="publish-bom">
              <Tooltip
                title={publishable ? "Work in progress, click to publish the item" : "Not Published"}
                children={
                  <div><WIPIcon onClick={() => publishable ? updatePublishStatus(params.row) : false} /></div>
                }
              />
            </Button>
          )
        );
      },
    },
    {
      field: "attachments",
      headerName: "Image",
      width: 150,
      renderCell: (params: GridRenderCellParams) => (
        <Box>
          <img
            height={"50px"}
            width={"50px"}
            src={
              params?.row?.attachments
                ? params?.row?.attachments?.at(-1)?.url
                : `/imageNotFound.png`
            }
            alt={params?.row?.attachments?.at(-1)?.file_name ?? ""}
          />
        </Box>
      ),
      disableColumnMenu: true,
      sortable: false,
    },
    {
      field: "short_description",
      headerName: "Description",
      width: 300,
      renderCell: (params: GridRenderCellParams) => {
        if (params.rowNode.type === "group") {
          return params.value;
        }
        return (
          <Tooltip title={params.row?.short_description}>
            <Typography
              variant="body2"
              sx={{
                width: "100%",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {params.row?.short_description}
            </Typography>
          </Tooltip>
        );
      },
    },
    { field: "reference_id", headerName: "Ref. No.", width: 150 },
    {
      field: "group_name",
      headerName: "Groups",
      width: 250,
      renderCell: (params: GridRenderCellParams) => {
        if (params.rowNode.type === "group") {
          return params.value;
        }
        return (
          <Stack
            spacing={1}
            direction={"row"}
            width="100%"
            overflow={"scroll"}
            id={"tagsChip"}
          >
            <Chip size={"small"} label={params.row?.group_name ?? ""} />
          </Stack>
        );
      },
    },
    {
      field: "noun_name",
      headerName: "Noun",
      width: 250,
      renderCell: (params: GridRenderCellParams) => {
        if (params.rowNode.type === "group") {
          return params.value;
        }
        <Stack
          spacing={1}
          direction={"row"}
          width="100%"
          overflow={"scroll"}
          id={"tagsChip"}
        >
          <Chip size={"small"} label={params.row?.noun_name ?? ""} />
        </Stack>;
      },
    },
    {
      field: "link",
      headerName: "Associations",
      width: 100,
      renderCell: (params: GridRenderCellParams) => {
        if (params.rowNode.type === "group") {
          return params.value;
        }
        return (
          <>
            <IconButton
              disabled={!params.row.associations?.length}
              onClick={() => openAssociation(params.row)}
            >
              <Link
                color={params.row.associations?.length ? "info" : "disabled"}
              />
            </IconButton>
          </>
        );
      },
      disableColumnMenu: true,
      sortable: false,
    },
    {
      field: "references",
      headerName: "References",
      width: 100,
      renderCell: (params: GridRenderCellParams) => {
        if (params.rowNode.type === "group") {
          return params.value;
        }
        return (
          <>
            <IconButton
              disabled={!params.row.references?.length}
              onClick={() => openReferences(params.row)}
            >
              <Link
                color={params.row.references?.length ? "info" : "disabled"}
              />
            </IconButton>
          </>
        );
      },
      disableColumnMenu: true,
      sortable: false,
    },
    {
      field: "technical_card",
      headerName: "Technical Card",
      width: 100,
      renderCell: (params: GridRenderCellParams) => {
        if (params.rowNode.type === "group") {
          return params.value;
        }
        return (
          <>
            <IconButton onClick={() => showTechnicalCard(params.row)}>
              <ArticleOutlined color={"info"} />
            </IconButton>
          </>
        );
      },
      disableColumnMenu: true,
      sortable: false,
    },
    // {
    //     field: 'attachement_tags', headerName: 'Attachement Tags', width: 250, renderCell: (params: GridRenderCellParams) => (
    //         <Stack spacing={1} direction={"row"} width="100%" overflow={"scroll"} id={"tagsChip"}>
    //             {params.row?.attachement_tags?.map((tag: any, key: number) => (<Chip key={key} variant="outlined" size={"small"} label={`${tag.tag_name}`} />))}
    //         </Stack>)
    // },
    { field: "created_user", headerName: "Created By", width: 150 },
    {
      field: "created_time",
      headerName: "Created Date",
      width: 250,
      renderCell: (params: GridRenderCellParams) => {
        if (params.rowNode.type === "group") {
          return params.value;
        }
        return (
          <Typography variant="body2">
            {moment(params.row.created_time).format("lll")}
          </Typography>
        );
      },
    },
    { field: "modified_user", headerName: "Last modified by", width: 150 },
    {
      field: "modified_time",
      headerName: "Last modified date",
      width: 250,
      renderCell: (params: GridRenderCellParams) => {
        if (params.rowNode.type === "group") {
          return params.value;
        }
        return (
          <Typography variant="body2">
            {params.row.modified_time
              ? moment(params.row.modified_time).format("lll")
              : ""}
          </Typography>
        );
      },
    },
    { field: 'released_user', headerName: 'Published by', width: 150 },
    {
      field: 'released_time', headerName: 'Published date', width: 250, renderCell: (params: GridRenderCellParams) => {
        if (params.rowNode.type === 'group') {
          return params.value;
        }
        return <Typography variant="body2">{params.row.released_time ? moment(params.row.released_time).format('lll') : ""}</Typography>
      }
    },
    {
      field: "status",
      headerName: "Work Status",
      width: 150,
      renderCell: (params: GridRenderCellParams) => {
        if (params.rowNode.type === "group") {
          return params.value;
        }
        return (
          <Typography
            variant="body2"
            color={GiveMeStatusColor(params?.row?.status)}
          >
            {CapitalizeString(params.row.status)}
          </Typography>
        );
      },
      disableColumnMenu: true,
      sortable: false,
    },
    {
      field: "delete",
      headerName: "Delete",
      width: 100,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <Tooltip title={params?.row?.released ? `${params?.row?.sequence_id} Revision is published` : `Delete the BOM ${params?.row?.sequence_id}`}>
            <IconButton disableRipple={params?.row?.released} onClick={() => params?.row?.released ? () => { } : onDelete(params.row)}>
              <DeleteOutline color={params?.row?.released ? "disabled" : "error"} id="delete-bom-icon" />
            </IconButton>
          </Tooltip>
        );
      },
      disableColumnMenu: true,
      sortable: false,
    },
  ];

  if (!handleBOMDuplicate) {
    columns.splice(2, 1);
  }

  if (removeDelete) {
    columns.pop();
  }

  if (!showDuplicate) {
    columns.splice(2, 1);
  }

  return columns;
};
