import { Alert, Box, useTheme } from "@mui/material";
import moment from "moment";
import { useSnackbar } from "notistack";
import React from "react";
import { CustomDataGrid, DeletePanel, TitleHeader } from "../../components";
import { useAppDispatch, useAppSelector } from "../../redux";
import { useDeleteListViewMutation, useLazyGetAllListViewQuery } from "../../redux/services";
import { closeBackdrop, openBackdrop } from "../../redux/slices/backdrop";
import { closeConfirmationDialog, openConfirmationDialog } from "../../redux/slices/confirmationDialog";
import { DashboardTableColumn } from "./utils";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { AppRoutes } from "../../router/routes";
import { getObjectType } from "./addEditListView";
import { useStyles } from "../documentTemplates/styles";
import { openDialog } from "../../redux/slices/dialog";
import { PublishComponent } from "../materials/dashboard";
import { HasAccess } from "../../router/authorization";
import { PERMISSIONS } from "../../router/permission";
import { GridSortDirection, GridSortModel } from "@mui/x-data-grid-premium";

export const Dashboard: React.FC<{ paths?: Array<any>, object_type?: string }> = ({ paths = [], object_type = "" }) => {

    const navigate = useNavigate();
    const location = useLocation();
    const { id = "" } = useParams();
    const dispatch = useAppDispatch();
    const classes = useStyles(useTheme());
    const { enqueueSnackbar } = useSnackbar();
    const { perm } = useAppSelector(store => store.auth.userDetails);

    const [getAllListView, { data: allData = { data: [], totalCount: 0 }, isFetching, isError }] = useLazyGetAllListViewQuery();
    const [deleteListViewMutation] = useDeleteListViewMutation();

    const [paginationModel, setPaginationModel] = React.useState({ page: 0, pageSize: 10 });
    const [search, setSearch] = React.useState("");
    const [queryOptions, setQueryOptions] = React.useState({
        sortModel: [{ field: 'sequence_id', sort: 'desc' as GridSortDirection }],
    });

    const { data, totalCount } = allData;

    const creatable = React.useMemo(() => HasAccess(perm, PERMISSIONS.BOM_LIST_VIEW_CREATE), [perm]);
    const readable = React.useMemo(() => HasAccess(perm, PERMISSIONS.BOM_LIST_VIEW_READ), [perm]);
    const deletable = React.useMemo(() => HasAccess(perm, PERMISSIONS.BOM_LIST_VIEW_DELETE), [perm]);
    const publishable = React.useMemo(() => HasAccess(perm, PERMISSIONS.BOM_LIST_VIEW_PUBLISH), [perm]);

    const viewListView = (data: any) => {
        let stateObj = {
            state: {
                paths: [
                    ...paths,
                    {
                        title: "List View",
                        path: -1,
                    }
                ]
            }
        }
        if (id && object_type) {
            navigate(AppRoutes.viewIndividualListViewOnModule(object_type, id, data?._id ?? "New"), stateObj)
        }
    };

    const deleteListView = (lvRes: any) => {
        dispatch(
            openConfirmationDialog({
                title: "Are you sure?",
                body: `Do you want to delete the List View ${lvRes.sequence_id}?`,
                positiveBtn: "Delete",
                negativeBtn: "Cancel",
                onOk: () => performRowDel(lvRes),
                onNegativeBtn: () => dispatch(closeConfirmationDialog()),
            })
        );
    };

    const performRowDel = async (lvRes: any) => {
        dispatch(openBackdrop("Deleting List View..."));
        let res: any = {};
        try {
            res = await deleteListViewMutation({ id: lvRes._id })

            if (Object.keys(res).includes("data")) {
                enqueueSnackbar(`Deleted List View Successfully!`, { variant: "success" })
                dispatch(closeConfirmationDialog());
                refetch();
            } else if (Object.keys(res).includes("error")) {
                // eslint-disable-next-line 
                throw res.error.data;
            } else {
                // eslint-disable-next-line 
                throw "Data not found"
            }
        } catch (error: any) {
            let errorMessage: string = error?.title ?? "Oops! Something went wrong, Unable to delete"
            enqueueSnackbar(errorMessage, { variant: "error" });
        } finally {
            dispatch(closeBackdrop());
        }
    };

    const giveMeRows = (): any[] => {
        if (!isFetching && data) {
            if (search.trim().length) {
                return data?.filter((_: any) => (_?.sequence_id?.toLowerCase()?.includes(search.toLowerCase())
                    || _?.long_description?.toLowerCase()?.includes(search.toLowerCase())
                    || _?.group_name?.toLowerCase()?.includes(search.toLowerCase())
                    || _?.noun_name?.toLowerCase()?.includes(search.toLowerCase())
                    || _?.external_ref_id?.toLowerCase().includes(search.toLowerCase())
                    || _?.created_user?.toLowerCase().includes(search.toLowerCase())
                    || moment(_.created_time).format('lll').toString().includes(search.toLowerCase())
                )) ?? []
            }
            return data;
        } else {
            return [];
        }
    };

    const view = (data: any) => viewListView(data);

    const viewPreview = (row: any) => {
        let module = getObjectType(location);
        if (module) {
            navigate(AppRoutes.previewListViewOnModule(getObjectType(location), id, row?._id), {
                state: {
                    paths: [
                        ...paths,
                        { title: "List View", path: paths[1]?.path?.replace("basicDetail", "listView?tab=listView") || -1 }
                    ],
                    noun_id: row?.noun_id,
                    tag_id: row?.tag_id,
                }
            })
        }
    };

    const updatePublishStatus = (row: any) => {
        dispatch(
            openConfirmationDialog({
                title: "Publish Operation",
                body: <DeletePanel
                    message={`Are you sure you want to publish the list view ${row?.sequence_id}?`}
                    warningMessage={`You will not be able to edit this revision after publishing.`}
                />,
                positiveBtn: "Proceed",
                negativeBtn: "Cancel",
                onOk: () => proceedToPublish(row),
                onNegativeBtn: () => dispatch(closeConfirmationDialog())
            })
        );
    };

    const proceedToPublish = (row: any) => {
        dispatch(closeConfirmationDialog());
        dispatch(openDialog({
            title: "",
            body: <PublishComponent {...row} refetch={refetch} object_type="bomListView" object_id={row?._id} />,
            hidePositiveBtn: true,
            hideNegativeBtn: true,
            enablePadding: false
        }));
    };

    const ROWS = giveMeRows();

    const handleSortModelChange = React.useCallback((sortModel: GridSortModel) => {
        // Here you save the data you need from the sort model
        setQueryOptions({ sortModel: [...sortModel] });
    }, [])

    const refetch = () => {
        getAllListView({ 
            ...paginationModel, 
            object_type: getObjectType(location), 
            object_id: id,
            sort_by: queryOptions?.sortModel?.[0]?.field, 
            sort_order: queryOptions?.sortModel?.[0]?.sort,
        })
    }

    React.useEffect(() => {
        refetch();
        // eslint-disable-next-line
    }, [paginationModel, JSON.stringify(queryOptions?.sortModel)])

    return <Box sx={classes.root}>
        {!readable && <Alert sx={{ mt: 2 }} severity="warning">You don't have permission to view this section, contact admin!</Alert>}
        {readable && <>
            {/* Header */}
            <TitleHeader
                title="List View"
                count={ROWS?.length ?? 0}
                showSearch={true}
                search={search}
                onSearchChange={setSearch}
                searchPlaceholder={"Search for List View (only within this page)"}
                showCreateBtn={creatable}
                createBtnLabel={"Create New List"}
                onCreateBtn={() => viewListView(null)}
            />

            {/* Error */}
            {isError && <Alert sx={{ mt: 2 }} severity="error">Oops! Something went wrong, Unable to fetch List Views. Try Again Later!</Alert>}

            {/* Data Drid */}
            <Box mt={2}>
                <CustomDataGrid
                    id={`${object_type}-entity-list-view-list`}
                    // initialState={{
                    //     sorting: {
                    //         sortModel: [{ field: 'sequence_id', sort: 'desc' }],
                    //     },
                    // }}
                    rows={ROWS}
                    columns={DashboardTableColumn(deleteListView, view, viewPreview, updatePublishStatus, publishable, !deletable)}
                    loading={isFetching}
                    getRowId={(row) => row._id}
                    showToolbar={true}
                    rowCount={totalCount}
                    paginationModel={paginationModel}
                    paginationMode="server"
                    onPaginationModelChange={setPaginationModel}
                    sortingMode="server"
                    onSortModelChange={handleSortModelChange}
                />
            </Box>
        </>}
    </Box>
}