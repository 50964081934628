import { Chip, Stack, styled, TextField, Tooltip, Typography } from "@mui/material";
import { GridColDef, GridPaginationModel, GridRenderCellParams, GridRenderEditCellParams, GridRowSelectionModel } from "@mui/x-data-grid-premium";
import React from "react";
import { CustomDataGrid, HighlightSelectBox, MyPaper } from "../../../components";
import { useAppDispatch } from "../../../redux";
import { LoadingButton } from "@mui/lab";
import { useBatchCreateWorkbookMutation, useBulkDeleteWorkbookItemsMutation, useCreateWorkbookItemMutation, useDeleteWorkbookItemMutation, useGetCurrencyQuery, useGetGroupsDDQuery, useGetNounsMappingDDQuery, useGetUoMDropDownQuery, useGetVendorsDDQuery, useImportWorkbookMutation, useUpdateWorkbookItemMutation } from "../../../redux/services";
import { SelectBoxOption } from "../../../interfaces";
import { useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import { closeBackdrop, openBackdrop } from "../../../redux/slices/backdrop";
import { QueryStatus } from "@reduxjs/toolkit/dist/query";
import { CheckCircleRounded, ContentCopy, DeleteOutline, Edit, InfoRounded } from "@mui/icons-material";
import {
    GridActionsCellItem
} from "@mui/x-data-grid";
import { closeDialog, openDialog } from "../../../redux/slices/dialog";
import { AddEditRow } from "./addEditRow";
import { SourcingOption } from "../../viewMaterial/sourcing";
import { typeOptions } from "./utils";
import { chipColor, UserFriendlyView } from "../dashboard/utils";
import { closeConfirmationDialog, openConfirmationDialog } from "../../../redux/slices/confirmationDialog";

export type QueryType = { data?: any; isFetching?: boolean; isLoading: boolean; status: QueryStatus; isError: boolean; error?: any };

interface IProps {
    loading: boolean;
    removeChildren: boolean;
    workbookData: any;
    refetch: (id: string) => Promise<void>;
    sparePartCategoryData: QueryType;
    materialStatusData: QueryType;
    bomStatusData: QueryType;
    itemsRes: QueryType;
    refetchItems: () => void;
    paginationData: {
        paginationModel: GridPaginationModel;
        setPaginationModel: (model: GridPaginationModel) => void;
    },
    isViewOnly?: boolean;
}

export interface IRowError {
    _id: string;
    type: boolean;
    group_id: boolean;
    noun_id: boolean;
    uom_id: boolean;
    short_description: boolean;
    reference_id: boolean;
    uom_type: boolean;
    length?: boolean;
    units_length?: boolean;
    weight?: boolean;
    units_weight?: boolean;
    status: boolean;
    vendor_id?: boolean;
    part_number?: boolean;
    manufacturer_name?: boolean;
    unit_code?: boolean;
    unit_location?: boolean;
    other_details?: boolean;
    cost?: boolean;
    currency?: boolean;
    lead_time_days?: boolean;
    // For Child
    spare_part?: boolean;
    spare_part_category?: boolean;
    spare_part_quantity?: boolean;
    normal_quantity?: boolean;
}


export interface IRow {
    _id: string;
    s_no: number;
    type: SelectBoxOption | null;
    group_id: SelectBoxOption | null;
    noun_id: SelectBoxOption | null;
    uom_id: SelectBoxOption | null;
    short_description: string;
    long_description?: string;
    external_ref_id?: string;
    reference_id?: string;
    uom_type?: SelectBoxOption | null;
    length?: number;
    units_length?: SelectBoxOption | null;
    weight?: number;
    units_weight?: SelectBoxOption | null;
    status: SelectBoxOption | null;
    sourcing_type: SelectBoxOption | null;
    vendor_id?: SelectBoxOption | null;
    part_number?: string;
    manufacturer_name?: string;
    unit_code?: string;
    unit_location?: string;
    other_details?: string;
    cost_category?: SelectBoxOption | null;
    cost?: number;
    currency?: SelectBoxOption | null;
    lead_time_days?: number;
    // For Child
    serial_num?: string;
    spare_part?: SelectBoxOption | null;
    spare_part_category?: SelectBoxOption | null;
    spare_part_quantity?: number;
    normal_quantity?: number;
    override_cost?: number;
    child_status?: SelectBoxOption | null;
    validation_errors?: Array<string>;
}

type Field = string | SelectBoxOption | boolean | number | null;

export const SelectBoxRenderer = ({ params, onChange, options, isDisabled = false, label, isLoading = false, hide = false, defaultValue, error = false }: { params: GridRenderEditCellParams; onChange: (value: SelectBoxOption | null) => void, field?: Field; label: string; isDisabled?: boolean; options: SelectBoxOption[]; isLoading?: boolean; skipReset?: boolean; hide?: boolean; defaultValue?: SelectBoxOption | null; error?: boolean; }) => {
    const { value } = params;

    return (
        <Stack width={"100%"} display={hide ? "none" : 'flex'}>
            <HighlightSelectBox
                label={label}
                margin={"none"}
                disabled={isDisabled}
                loading={isLoading}
                value={options.find(op => (op.value === value?.value || op.label === value?.label) || (op.value === defaultValue?.value || op.label === defaultValue?.label)) ?? null}
                options={options}
                error={error}
                onChange={(option: SelectBoxOption) => {
                    onChange(option)
                }}
            />
        </Stack>
    );
}

export const InputRenderer = ({ params, onChange, isDisabled = false, placeholder = "Enter value", type = 'text', hide = false, error = false }: { params: GridRenderEditCellParams; onChange: (value: string | number) => void, field?: Field; isDisabled?: boolean; placeholder?: string; type?: React.InputHTMLAttributes<unknown>['type']; defaultValue?: string | number; skipReset?: boolean; hide?: boolean; error?: boolean; }) => {
    const { value } = params;

    return (
        <TextField
            sx={{
                '.MuiOutlinedInput-input': {
                    p: 1
                },
                '.Mui-disabled': { border: error ? "1px solid red !important" : 'inherit' },
                display: hide ? "none" : "block"
            }}
            disabled={isDisabled}
            placeholder={placeholder}
            type={type}
            value={value}
            error={error}
            onChange={(e) => {
                onChange(e.target.value)
            }}
            onKeyDown={(event) => {
                event.stopPropagation();
            }}
        />
    );
}

export const NounOptionsRenderer = ({ params, onChange, isDisabled = false, error = false }: { params: GridRenderEditCellParams; onChange: (value: SelectBoxOption | null) => void; skipReset?: boolean; isDisabled?: boolean; error?: boolean; }) => {
    const { value, row } = params;

    const uom_id = row?.uom_id?.value ?? "";
    const group_id = row?.group_id?.value ?? "";

    const nounResponse = useGetNounsMappingDDQuery({ uom_id, group_id }, { skip: !uom_id || !group_id });

    let nounOptions = uom_id && group_id ? nounResponse?.data ?? [] : [];

    return (
        <Stack width={"100%"}>
            <HighlightSelectBox
                label={"Select Noun"}
                margin={"none"}
                value={nounOptions.find(op => (op.value === value?.value || op.label === value?.label)) ?? null}
                options={nounOptions}
                loading={nounResponse.isFetching || nounResponse.isLoading}
                disabled={isDisabled}
                error={error}
                onChange={(option: SelectBoxOption) => {
                    onChange(option)
                }}
            />
        </Stack>
    );
}

export const sparePartOptions = [
    { label: "Yes", value: "yes" },
    { label: "No", value: "no" },
];

// Without this style changes are not reflected to table lik removing padding
export const StyledDataGrid = styled(CustomDataGrid)(() => ({}));

export const ActionTable: React.FC<IProps> = ({ loading, itemsRes, paginationData, removeChildren = false, workbookData, refetch, refetchItems, sparePartCategoryData, materialStatusData, bomStatusData, isViewOnly }) => {

    const dispatch = useAppDispatch();
    const { id = "New" } = useParams();
    const { enqueueSnackbar } = useSnackbar();

    const uomData = useGetUoMDropDownQuery({ refetchOnFocus: true });
    const groupData = useGetGroupsDDQuery({ refetchOnFocus: true });
    const supplierData = useGetVendorsDDQuery(null, { refetchOnMountOrArgChange: true, refetchOnFocus: true });
    const currencyData = useGetCurrencyQuery(null);
    const [importCSV] = useImportWorkbookMutation();
    const [batchCreateWorkbook] = useBatchCreateWorkbookMutation();
    const [createWorkbookItem, createRes] = useCreateWorkbookItemMutation();
    const [updateWorkbookItem, updateRes] = useUpdateWorkbookItemMutation();
    const [deleteWorkbookItem, deleteRes] = useDeleteWorkbookItemMutation();
    const [bulkDeleteWorkbookItems, bulkRes] = useBulkDeleteWorkbookItemsMutation();

    const uomOptions = uomData?.data ?? [];
    const groupOptions = groupData?.data ?? [];
    const supplierOptions = supplierData?.data ?? [];
    const currencyOptions = currencyData?.data ?? [];
    const sparePartCategoryOptions = sparePartCategoryData.data?.spareParts ?? [];
    const childMaterialStatusOptions = materialStatusData?.data?.childStatus ?? [];
    const childBOMStatusOptions = bomStatusData?.data?.childStatus ?? [];

    const [rows, setRows] = React.useState<Partial<IRow>[]>([]);
    const [rowsError, setRowsError] = React.useState<IRowError[]>([]);
    const inputRef = React.useRef<HTMLInputElement>(null);
    const [rowSelectionModel, setRowSelectionModel] = React.useState<GridRowSelectionModel>([]);

    const { data: allData = { data: [], totalCount: 0 }, ...rest } = itemsRes;
    const { data, totalCount } = allData;
    const { paginationModel, setPaginationModel } = paginationData;

    const isCompleted = isViewOnly || workbookData?.status === "completed";

    const constructPayload = (currentRow: Partial<IRow>) => {
        return {
            ...currentRow,
            _id: isNaN(Number(currentRow?._id)) ? currentRow?._id : undefined,
            type: currentRow?.type?.value,
            uom_id: currentRow?.uom_id?.value,
            uom_name: currentRow?.uom_id?.label,
            group_id: currentRow?.group_id?.value,
            group_name: currentRow?.group_id?.label,
            noun_id: currentRow?.noun_id?.value,
            noun_name: currentRow?.noun_id?.label,
            uom_type: currentRow?.uom_type?.value,
            units_length: currentRow?.units_length?.value || undefined,
            units_weight: currentRow?.units_weight?.value || undefined,
            sourcing_type: currentRow?.sourcing_type?.value,
            vendor_id: currentRow?.vendor_id?.value,
            vendor_name: currentRow?.vendor_id?.label,
            manufacturer_name: currentRow?.manufacturer_name || undefined,
            part_number: currentRow?.part_number || undefined,
            unit_code: currentRow?.unit_code || undefined,
            unit_location: currentRow?.unit_location || undefined,
            other_details: currentRow?.other_details || undefined,
            cost_category: currentRow?.cost_category?.value,
            currency: currentRow?.currency?.value,
            spare_part: [true, "yes"].includes((currentRow?.spare_part?.value || "") as string),
            length: isNaN(Number(currentRow.length)) ? undefined : Number(currentRow.length) || undefined,
            weight: isNaN(Number(currentRow.weight)) ? undefined : Number(currentRow.weight) || undefined,
            lead_time_days: isNaN(Number(currentRow.lead_time_days)) ? undefined : Number(currentRow.lead_time_days) || undefined,
            cost: isNaN(Number(currentRow.cost)) ? 0 : Number(currentRow.cost),
            normal_quantity: isNaN(Number(currentRow.normal_quantity)) ? 0 : Number(currentRow.normal_quantity),
            spare_part_quantity: isNaN(Number(currentRow.spare_part_quantity)) ? 0 : Number(currentRow.spare_part_quantity),
            long_description: currentRow?.long_description || undefined,
            tags_info: [
                {
                    tag_id: currentRow.spare_part_category?.value,
                    tag_group_id: sparePartCategoryData?.data?.sparePartTagGroup,
                },
                {
                    tag_id: currentRow.child_status?.value,
                    tag_group_id: currentRow?.type?.value === "bom" ? bomStatusData?.data?.statusTagGroup : materialStatusData?.data?.statusTagGroup,
                },
            ].filter(tags => tags?.tag_id),
        };
    };

    const onAddOrUpdateRow = (currentRow: Partial<IRow>) => {
        const payload = constructPayload(currentRow);
        if (isNaN(Number(currentRow?._id))) {
            dispatch(openBackdrop("Updating workbook item..."));
            updateWorkbookItem({ id: workbookData?._id, item_id: currentRow?._id, payload: { ...payload, validation_errors: undefined } })
                .unwrap()
                .then((res) => {
                    if (res?.message) {
                        enqueueSnackbar(res?.message, { variant: 'success' });
                        refetchItems();
                    }
                })
                .catch((error) => {
                    enqueueSnackbar(error?.data?.title ?? "Unable to update workbook item", { variant: 'error' })
                })
                .finally(() => {
                    dispatch(closeDialog());
                    dispatch(closeBackdrop());
                });
        } else {
            dispatch(openBackdrop("Creating workbook item..."));
            createWorkbookItem({ id: workbookData?._id, payload: payload })
                .unwrap()
                .then((res) => {
                    if (res?.message) {
                        enqueueSnackbar(res?.message, { variant: 'success' });
                        refetchItems();
                    }
                })
                .catch((error) => {
                    enqueueSnackbar(error?.data?.title ?? "Unable to create workbook item", { variant: 'error' })
                })
                .finally(() => {
                    dispatch(closeDialog());
                    dispatch(closeBackdrop());
                });
        }
    };

    const handleEditClick = (_id: string) => {
        dispatch(openDialog({
            title: "Edit Row",
            maxWidth: "lg",
            enablePadding: false,
            body: <AddEditRow _id={_id} rows={rows} rowsError={rowsError} isCompleted={isCompleted} workbookData={workbookData} uomData={uomData} uomOptions={uomOptions} groupData={groupData} groupOptions={groupOptions} supplierData={supplierData} supplierOptions={supplierOptions} currencyData={currencyData} currencyOptions={currencyOptions} sparePartCategoryData={sparePartCategoryData} sparePartCategoryOptions={sparePartCategoryOptions} materialStatusData={materialStatusData} childMaterialStatusOptions={childMaterialStatusOptions} bomStatusData={bomStatusData} childBOMStatusOptions={childBOMStatusOptions} onUpdate={onAddOrUpdateRow} />,
            hidePositiveBtn: true,
            hideNegativeBtn: true
        }));
    };

    const deleteRows = async () => {
        dispatch(openConfirmationDialog({
            title: 'Are you sure?',
            body: `Do you want to delete the workbook items?`,
            positiveBtn: 'Yes',
            negativeBtn: 'No',
            onOk: () => deleteRowsHandler(),
            onNegativeBtn: () => dispatch(closeConfirmationDialog())
        }));
    }

    const deleteRowsHandler = () => {
        dispatch(openBackdrop("Deleting Workbook items..."))
        bulkDeleteWorkbookItems({ id: workbookData?._id, item_ids: rowSelectionModel }).then(() => {
            enqueueSnackbar("Workbook items deleted successfully!", { variant: 'success' })
            refetchItems();
        }).catch((error) => {
            console.error(`Error Deleting workbook items: ${error}`);
            enqueueSnackbar(error?.data?.title ?? "Unable to delete workbook items", { variant: 'error' })
        }).finally(() => {
            dispatch(closeBackdrop());
            dispatch(closeConfirmationDialog());
        })
    }

    const handleDelete = async (_id: string) => {
        dispatch(openConfirmationDialog({
            title: 'Are you sure?',
            body: `Do you want to delete the workbook item?`,
            positiveBtn: 'Yes',
            negativeBtn: 'No',
            onOk: () => deleteHandler(_id),
            onNegativeBtn: () => dispatch(closeConfirmationDialog())
        }));
    };

    const deleteHandler = (_id: string) => {
        if (isNaN(Number(_id))) {
            dispatch(openBackdrop("Deleting Workbook item..."));
            deleteWorkbookItem({ id: workbookData?._id, item_id: _id }).then(() => {
                refetchItems();
            }).catch((error) => {
                console.error(`Error Deleting workbook item: ${error}`);
                enqueueSnackbar(error?.data?.title ?? "Unable to delete workbook item", { variant: 'error' })
            }).finally(() => {
                dispatch(closeBackdrop());
                dispatch(closeConfirmationDialog());
            })
        }
    };

    const handleDuplicate = (_id: string) => {
        const row = rows?.find((row: Partial<IRow>) => row?._id === _id);
        if (!row) {
            return;
        }
        const payload = constructPayload(row);
        dispatch(openBackdrop("Duplicating workbook item..."));
        createWorkbookItem({ id: workbookData?._id, payload: { ...payload, short_description: payload?.short_description ? `Copy of ${payload?.short_description}` : `Copied item` } })
            .unwrap()
            .then((res) => {
                if (!res?.title) {
                    enqueueSnackbar("Duplicated item successfully!", { variant: 'success' });
                    refetchItems();
                }
            })
            .catch((error) => {
                enqueueSnackbar(error?.data?.title ?? "Unable to duplicate workbook item", { variant: 'success' })
            })
            .finally(() => {
                dispatch(closeDialog());
                dispatch(closeBackdrop());
            });
    };

    let Columns = React.useMemo((): GridColDef[] => {
        let col: GridColDef[] = [
            {
                field: "actions",
                type: "actions",
                headerName: "Actions",
                width: 140,
                cellClassName: "actions",
                getActions: ({ row }: any) => {
                    return [
                        <GridActionsCellItem
                            id="edit-item"
                            icon={<Tooltip title={`${isCompleted ? 'View' : 'Edit'} ${row?.s_no}`}><Edit /></Tooltip>}
                            label="Edit"
                            className="textPrimary"
                            onClick={() => handleEditClick(row?._id)}
                            color="primary"
                        />,
                        <GridActionsCellItem
                            id="duplicate-item"
                            icon={<Tooltip title={`Make a Duplicate of ${row?.s_no}`}><ContentCopy /></Tooltip>}
                            label="Duplicate"
                            className="textPrimary"
                            onClick={() => handleDuplicate(row?._id)}
                            color="inherit"
                            disabled={isCompleted}
                        />,
                        <GridActionsCellItem
                            icon={<Tooltip title={`Delete ${row?.s_no}`}><DeleteOutline /></Tooltip>}
                            label="Delete"
                            className="textPrimary"
                            onClick={() => handleDelete(row?._id)}
                            color="error"
                            disabled={isCompleted}
                        />
                    ];
                },
            },
            {
                field: "error",
                headerName: "Item Status",
                renderCell: (params: GridRenderCellParams) => {
                    return params?.row?.validation_errors?.length > 0 ? <Tooltip title={params?.row?.validation_errors?.join(", ")}><InfoRounded color="error" /></Tooltip> : <Tooltip title={isCompleted ? `${params?.row?.type?.label ?? "Created"} successfully!` : "No Errors"}><CheckCircleRounded color="success" /></Tooltip>
                },
            },
            // {
            //     field: "s_no",
            //     headerName: "S.No",
            //     width: 100,
            // },
            {
                field: "type",
                width: 250,
                headerName: "Type",
                valueGetter: (params) => params?.row?.type?.label
            },
            {
                field: "uom_id",
                width: 250,
                headerName: "Region",
                valueGetter: (params) => params?.row?.uom_id?.label
            },
            {
                field: "group_id",
                width: 250,
                headerName: "Group",
                valueGetter: (params) => params?.row?.group_id?.label
            },
            {
                field: "noun_id",
                width: 250,
                headerName: "Noun",
                valueGetter: (params) => params?.row?.noun_id?.label
            },
            {
                field: "short_description",
                width: 250,
                headerName: "Description",
                valueGetter: (params) => params?.row?.short_description
            },
            {
                field: "long_description",
                width: 250,
                headerName: "Long Description",
                valueGetter: (params) => params?.row?.long_description,
            },
            {
                field: "reference_id",
                width: 240,
                headerName: "Reference Number",
                valueGetter: (params) => params?.row?.reference_id,
            },
            {
                field: "uom_type",
                width: 220,
                headerName: "UOM",
                valueGetter: (params) => params?.row?.uom_type?.label,
            },
            {
                field: "length",
                width: 180,
                headerName: "Length",
                valueGetter: (params) => params?.row?.length,
            },
            {
                field: "units_length",
                width: 220,
                headerName: "Units Length",
                valueGetter: (params) => params?.row?.units_length?.label,
            },
            {
                field: "weight",
                width: 180,
                headerName: "Weight",
                valueGetter: (params) => params?.row?.weight,
            },
            {
                field: "units_weight",
                width: 220,
                headerName: "Units Weight",
                valueGetter: (params) => params?.row?.units_weight?.label,
            },
            // For Costing Details
            {
                field: "cost_category",
                width: 230,
                headerName: "Costing Type",
                valueGetter: (params) => params?.row?.cost_category?.label,
            },
            {
                field: "cost",
                width: 180,
                headerName: "Cost",
                valueGetter: (params) => params?.row?.cost,
            },
            {
                field: "currency",
                width: 200,
                headerName: "Currency",
                valueGetter: (params) => params?.row?.currency?.label,
            },
            {
                field: "lead_time_days",
                width: 150,
                headerName: "Lead Time (Days)",
                valueGetter: (params) => params?.row?.lead_time_days,
            },
            // For Sourcing Details
            {
                field: "sourcing_type",
                width: 250,
                headerName: "Sourcing Type",
                valueGetter: (params) => params?.row?.sourcing_type?.label,
            },
            // For Vendor
            {
                field: "vendor_id",
                width: 250,
                headerName: "Supplier Name",
                valueGetter: (params) => params?.row?.vendor_id?.label,
            },
            {
                field: "manufacturer_name",
                width: 250,
                headerName: "Manufacturer Name",
                valueGetter: (params) => params?.row?.manufacturer_name,
            },
            {
                field: "part_number",
                width: 180,
                headerName: "Manufacturer Number",
                valueGetter: (params) => params?.row?.part_number,
            },
            // For Organization
            {
                field: "unit_code",
                width: 180,
                headerName: "Unit Name",
                valueGetter: (params) => params?.row?.unit_code,
            },
            {
                field: "unit_location",
                width: 180,
                headerName: "Unit Location",
                valueGetter: (params) => params?.row?.unit_location,
            },
            {
                field: "other_details",
                width: 180,
                headerName: "Other Details",
                valueGetter: (params) => params?.row?.other_details,
            },
            // For Child Fields
            {
                field: "serial_num",
                width: 250,
                headerName: "Tag Number",
                valueGetter: (params) => params?.row?.serial_num,
            },
            {
                field: "spare_part",
                width: 180,
                headerName: "Spare Part",
                valueGetter: (params) => params?.row?.spare_part?.label,
            },
            {
                field: "spare_part_category",
                width: 250,
                headerName: "Spare Part Category",
                valueGetter: (params) => params?.row?.spare_part_category?.label,
            },
            {
                field: "spare_part_quantity",
                width: 125,
                headerName: "Spare Part Qty",
                valueGetter: (params) => params?.row?.spare_part_quantity,
            },
            {
                field: "normal_quantity",
                type: "number",
                headerName: "Qty",
                valueGetter: (params) => params?.row?.normal_quantity,
            },
            {
                field: "override_cost",
                type: "number",
                width: 180,
                headerName: "Override cost per qty",
                valueGetter: (params) => params?.row?.override_cost,
            },
            {
                field: "child_status",
                width: 220,
                headerName: "Child Status",
                valueGetter: (params) => params?.row?.child_status?.label,
            }
        ];

        if (removeChildren === true) {
            col = col.slice(0, -7)
        }

        return col;
        // eslint-disable-next-line
    }, [removeChildren, rowsError, rows, isCompleted]);

    const addRow = () => {
        let obj: any = {};
        Columns.forEach((col: any) => {
            obj[col.field] = undefined
        })
        onAddOrUpdateRow({ ...obj, _id: rows?.length });
    };

    const handleCsvUpload = async (event: any) => {
        event?.preventDefault();
        const file =
            event?.dataTransfer?.files?.[0] || inputRef?.current?.files?.[0];
        if (file && id !== "New") {
            dispatch(openBackdrop("Upload workbook..."))
            const formData = new FormData();
            formData.append("file", file);

            await importCSV({ id: id, payload: formData }).unwrap().then((res) => {
                if (res.message) {
                    enqueueSnackbar(
                        res.message ?? "Imported workbook successfully!",
                        { variant: "success" }
                    );
                    refetchItems();
                }
            }).catch((error: any) => {
                enqueueSnackbar(
                    error?.data?.title ?? error?.title ?? "unable to upload the data from the file",
                    { variant: "error" }
                );
                console.error(`Error unable to upload the data from the file: ${error}`);
            }).finally(() => {
                dispatch(closeBackdrop());
            })
        }
        event.target.value = null;
    };

    const openFileInput = () => {
        inputRef?.current?.click();
    };

    const handleBatchCreate = async (event: any) => {
        event?.preventDefault();
        if (id !== "New") {
            dispatch(openBackdrop("Batch creating items from workbook..."))
            await batchCreateWorkbook(id).unwrap().then((res) => {
                if (res.message) {
                    enqueueSnackbar(
                        res.message ?? "Batch created items successfully!",
                        { variant: "success" }
                    );
                    refetch(id);
                }
            }).catch((error: any) => {
                enqueueSnackbar(
                    error.data?.title ?? error?.title ?? "Unable to create items from workbook",
                    { variant: "error" }
                );
                console.error(`Error unable to to create items from workbook: ${error}`);
            }).finally(() => {
                dispatch(closeBackdrop());
            })
        }
    };

    const isLoading = loading || deleteRes.isLoading || rest.isLoading || rest.isFetching || createRes.isLoading || updateRes.isLoading || bulkRes.isLoading;

    const getRowClassName = (params: any) => {
        // Apply the 'errBorderedRow' class if there is an error
        return (Object.values(rowsError.find(r => r?._id === params.row?._id) || {}).some((value) => value === true) || params.row?.validation_errors?.length > 0) ? 'errBorderedRow' : '';
    };

    // const handleRowOrderChange = async (params: GridRowOrderChangeParams) => {
    //     if (params.oldIndex > -1 && params.targetIndex > -1) {
    //         setRows((prevRows) => {
    //             const newRows = [...prevRows]; // Copy the array to avoid mutating the original state
    //             const oldItem = newRows[params.oldIndex];
    //             const newItem = newRows[params.targetIndex];

    //             // Swap the entire rows
    //             newRows[params.oldIndex] = newItem;
    //             newRows[params.targetIndex] = oldItem;

    //             // Update serial numbers
    //             newRows.forEach((row, index) => {
    //                 row.s_no = index + 1;
    //             });

    //             return newRows; // Return the updated array
    //         });
    //     }
    // };

    React.useEffect(() => {
        if (data?.length > 0) {
            const errs = data?.map((d: any) => {
                let obj: Partial<IRowError> = { _id: d?._id };
                if (d.validation_errors) {
                    d.validation_errors?.forEach((e: string) => {
                        if (e.includes("No region found") || e.includes("Error at 'uom_name'")) {
                            obj.uom_id = true;
                        }

                        if (e.includes("No group found") || e.includes("Error at 'group_name'")) {
                            obj.group_id = true;
                        }

                        if (e.includes("No noun found") || e.includes("Error at 'noun_name'")) {
                            obj.noun_id = true;
                        }

                        if (e.includes("Error at 'short_description'") || e.includes("Found duplicate values short/long description")) {
                            obj.short_description = true;
                        }

                        if (e.includes("Error at 'reference_id'")) {
                            obj.reference_id = true;
                        }

                        if (e.includes("Error at 'uom_type'")) {
                            obj.uom_type = true;
                        }

                        if (e.includes("Error at 'currency_code'")) {
                            obj.currency = true;
                        }

                        if (e.includes("vendor cannot be nil")) {
                            obj.vendor_id = true;
                        }

                        if (e.includes("length and length unit") || e.includes("length, weight, length unit and weight unit")) {
                            obj.length = true;
                            obj.units_length = true;
                        }

                        if (e.includes("weight and weight unit") || e.includes("length, weight, length unit and weight unit")) {
                            obj.weight = true;
                            obj.units_weight = true;
                        }
                    })
                }
                return obj;
            })
            setRowsError(errs);
        }
        // eslint-disable-next-line
    }, [data])

    // console.log({rowsError})

    React.useEffect(() => {
        if (workbookData?._id) {
            const uD = data?.length > 0 ? data : []
            const updatedRows = uD.map((item: any, idx: number) => {
                let updatedItem = {
                    ...item,
                    bom_type: undefined,
                    s_no: idx + 1,
                    type: (item?.type === typeOptions[0]?.value || item?.type === typeOptions[0]?.label) ? typeOptions[0] : (item?.type === typeOptions[1]?.value || item?.type === typeOptions[1]?.label) ? typeOptions[1] : null,
                    group_id: (item?.group_id || item?.group_name) ? { value: item?.group_id ?? undefined, label: item?.group_name } : null,
                    uom_id: (item?.uom_id || item?.uom_name) ? { value: item?.uom_id ?? undefined, label: item?.uom_name } : null,
                    noun_id: (item?.noun_id || item?.noun_name) ? { value: item?.noun_id ?? undefined, label: item?.noun_name } : null,
                    uom_type: item?.uom_type ? { value: item?.uom_type, label: item?.uom_type } : null,
                    units_length: item?.units_length ? { value: item?.units_length, label: item?.units_length } : null,
                    units_weight: item?.units_weight ? { value: item?.units_weight, label: item?.units_weight } : null,
                    cost_category: item?.cost_category ? { value: item?.cost_category, label: item?.cost_category } : null,
                    currency: item?.currency ? { value: item?.currency, label: item?.currency } : null,
                    sourcing_type: SourcingOption.find(s => (s.value === item?.sourcing_type || s.label === item?.sourcing_type)),
                    vendor_id: (item?.vendor_id || item?.vendor_id) ? { value: item?.vendor_id ?? undefined, label: item?.vendor_name } : null,
                    spare_part: item?.spare_part ? sparePartOptions[0] : sparePartOptions[1],
                    spare_part_category: sparePartCategoryData?.data?.spareParts?.find((spare: SelectBoxOption) => item?.tags_info?.map((tag: any) => tag?.tag_id).includes(spare?._id)),
                    child_status: (item?.uom_type ? materialStatusData?.data?.childStatus : bomStatusData?.data?.childStatus)?.find((spare: SelectBoxOption) => item?.tags_info?.map((tag: any) => tag?.tag_id).includes(spare?._id)),
                };
                return updatedItem;
            }) ?? [];

            setRows(updatedRows);
        }
        // eslint-disable-next-line
    }, [rest?.status, sparePartCategoryData?.status, materialStatusData?.status, bomStatusData?.status]);

    React.useEffect(() => {
        refetchItems();
        // eslint-disable-next-line
    }, [workbookData?._id, paginationModel]);

    const hasValidationErrors = rows?.some(r => (r?.validation_errors || [])?.length > 0);

    return (
        <MyPaper padding={0} height={"100%"}>
            <Stack height={"100%"} overflow={"hidden"}>
                <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"} sx={{ px: 2, py: 1, borderBottom: (theme) => `1px solid ${theme.palette.divider}` }}>
                    <Stack direction={"row"} spacing={1} alignItems={"center"}>
                        <Typography variant="body1" fontFamily={"htrts_medium"}>
                            Workbook
                        </Typography>
                        {workbookData?.status &&
                            <Chip size={"small"} label={UserFriendlyView?.[workbookData?.status] ?? ""} color={chipColor?.[workbookData?.status] ?? "primary"} />
                        }
                    </Stack>

                    <Stack direction="row" spacing={1}>
                        {!isCompleted &&
                            <LoadingButton
                                variant="contained"
                                onClick={deleteRows}
                                disabled={rowSelectionModel.length <= 0}
                                loading={
                                    loading
                                }
                            >
                                Delete Rows
                            </LoadingButton>
                        }

                        <LoadingButton
                            id="add-row"
                            variant="contained"
                            onClick={addRow}
                            disabled={isCompleted || rowSelectionModel.length > 0}
                            loading={
                                loading
                            }
                        >
                            Add Row
                        </LoadingButton>

                        <>
                            <LoadingButton
                                variant="contained"
                                onClick={openFileInput}
                                disabled={isCompleted || rowSelectionModel.length > 0}
                                loading={
                                    loading
                                }
                            >
                                Load File
                            </LoadingButton>
                            <form>
                                <input
                                    id="load-file"
                                    hidden
                                    type="file"
                                    ref={inputRef}
                                    accept={".csv"}
                                    onChange={handleCsvUpload}
                                />
                            </form>
                        </>

                        <LoadingButton
                            id="batch-create"
                            variant="contained"
                            onClick={handleBatchCreate}
                            disabled={(data ?? [])?.length <= 0 || isCompleted || rowSelectionModel.length > 0 || hasValidationErrors}
                            loading={
                                loading
                            }
                        >
                            Batch Create
                        </LoadingButton>
                    </Stack>


                </Stack>

                <Stack flexGrow={1} sx={{ overflow: "auto", p: 2 }}>
                    <StyledDataGrid
                        saveLocal={false}
                        columns={Columns}
                        rows={rows}
                        loading={isLoading}
                        getRowId={(row: any) => row?._id ?? row?.s_no}
                        onCellDoubleClick={(e) => (e.isEditable = false)}
                        getRowClassName={getRowClassName}
                        checkboxSelection={!isCompleted}
                        onRowSelectionModelChange={(newRowSelectionModel) =>
                            setRowSelectionModel(newRowSelectionModel)
                        }
                        rowSelectionModel={rowSelectionModel}
                        // rowReordering
                        // onRowOrderChange={handleRowOrderChange}
                        sx={{
                            '.MuiDataGrid-virtualScroller': {
                                overflow: 'auto !important',
                                height: "auto !important"
                            },
                        }}
                        showToolbar
                        paginationMode="server"
                        paginationModel={paginationModel}
                        onPaginationModelChange={setPaginationModel}
                        rowCount={totalCount}
                    />
                </Stack>
            </Stack>
        </MyPaper>
    );
}