import { LoadingButton } from "@mui/lab";
import { Alert, Box, Button, Stack } from "@mui/material";
import { useSnackbar } from "notistack";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { MyPaper } from "../../components";
import {
  prepareDataTableBody,
  useCreateDataTableMutation,
  useGetCheckListTagsQuery,
  useUpdateDataTableMutation,
} from "../../redux/services";
import { AppRoutes } from "../../router/routes";
import { BasicDetails } from "./basicDetails";
import {
  type CheckList,
  type CheckListData,
  type CheckListError,
  giveMeInitialErrorState,
  giveMeInitialState,
  CheckListContext,
} from "../viewCheckList/viewCheckListDetail";
import { Categories } from "../viewCheckList/categories";
import { closeBackdrop } from "../../redux/slices/backdrop";
import { useAppDispatch } from "../../redux";

export const ViewDataSheetDetail: React.FC<{
  isEdit: boolean;
  dataSheetData: CheckListData;
}> = ({ isEdit, dataSheetData }) => {
  const navigate = useNavigate();
  const { id = "" } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const tagsData = useGetCheckListTagsQuery(null, {
    refetchOnMountOrArgChange: true,
    refetchOnFocus: true,
  });
  const [createDataSheet, createdDataSheetData] = useCreateDataTableMutation();
  const [updateDataSheet, updateDataSheetData] = useUpdateDataTableMutation();

  const [state, setState] = React.useState<CheckList>(giveMeInitialState());
  const [error, setError] = React.useState<CheckListError>(
    giveMeInitialErrorState()
  );

  const dispatch = useAppDispatch();

  const updateState = (key: string, value: any) => {
    setState({ ...state, [key]: value });
    setError({ ...error, [key]: false });
  };

  const giveMeErrorMessage = () => {
    let message = `Oops! Something went wrong, Unable to ${isEdit ? "Update" : "Create"
      } Data Sheet. Try Again Later!`;
    let errorData: any = isEdit
      ? updateDataSheetData.error
      : createdDataSheetData.error;
    message = errorData?.data?.title ?? message;
    return message;
  };

  const onAddUpdateBtnClicked = async () => {
    if (isValid()) {
      if (isEdit) {
        await updateDataSheet({
          payload: state,
          dtTemplateType: "datasheet",
          dtInstanceType: "template",
          dtTemplateId: id,
          publish: state.status?.label === "Published"
        })
          .unwrap()
          .then((res) => {
            if (res) {
              enqueueSnackbar("Data Sheet Updated Successfully", {
                variant: "success",
              });
              navigate(AppRoutes.dataSheetList);
            }
          }).catch((error: any) => {
            enqueueSnackbar(
              error?.data?.title || "Something went wrong unable to update data sheet",
              { variant: "error" }
            );
          });
      } else {
        await createDataSheet({
          payload: state,
          dtTemplateType: "datasheet",
          dtInstanceType: "template",
          dtTemplateId: null
        })
          .unwrap()
          .then((res: any) => {
            if (!res.title) {
              enqueueSnackbar("Data Sheet Created Successfully", {
                variant: "success",
              });
              navigate(AppRoutes.dataSheetList);
            }
          }).catch((error: any) => {
            enqueueSnackbar(
              error?.data?.title || "Something went wrong unable to create data sheet",
              { variant: "error" }
            );
          });
      }
    }
  };

  const isValid = () => {
    if (state.name.trim().length === 0) {
      error.name = true;
    }

    if (!state.status) {
      error.status = true;
    }

    if (!state.tags_info || state.tags_info.length === 0) {
      error.tags_info = true;
    }

    setError({ ...error });
    return !Object.values(error).includes(true);
  };

  React.useEffect(() => {
    if (tagsData.isSuccess && dataSheetData.data) {
      const data = dataSheetData?.data ?? {};
      const statusOptions = tagsData?.data?.checkListStatusTags ?? [];
      let status = null;

      if (data.status) {
        status =
          statusOptions.filter((_: any) => _.tag_name === data.status)?.[0] ??
          null;
      }

      setState({
        ...state,
        ...data,
        status,
        tags_info: data?.tags_info?.map((_: any) => ({
          ..._,
          value: _.tag_id,
          label: _.tag_name,
        })),
        categories: data?.tables?.map((table: any, index: number) => {
          return {
            ...table,
            name: table.name,
            position: index,
            description: table.description,
            definition: {
              columns: table?.columns?.filter((col: any) => col.field)?.map((col: any) => {
                return {
                  ...col,
                  headerName: col?.header_name || "",
                  headerClassName: col?.header_class_name || "",
                }
              }),
              rows: table?.rows?.map((row: any) => {
                const obj: any = {};
                row?.column_content?.forEach((col: any) => {
                  obj[col.field] = col.content
                })
                return {
                  id: row?.id,
                  ...obj
                }
              })
            }
          }
        })
      });
      dispatch(closeBackdrop());
    }
    // eslint-disable-next-line
  }, [dataSheetData.data, tagsData.isSuccess]);

  const isLoading =
    createdDataSheetData.isLoading || updateDataSheetData.isLoading;
  const isError = createdDataSheetData.isError || updateDataSheetData.isError;

  return (
    <CheckListContext.Provider value={{ state, updateState, error }}>
      <Stack spacing={1}>
        {/* Basic Details */}
        <BasicDetails />

        {/* Categories */}
        <Categories dataSheet={{...dataSheetData, data: prepareDataTableBody(state, "template")}} />

        {/* Footer */}
        <MyPaper>
          <Box>
            {/* Alert Box */}
            {isError && (
              <Alert sx={{ mt: 2 }} severity="error">
                {giveMeErrorMessage()}
              </Alert>
            )}

            {/* Footer Buttons */}
            <Stack
              direction={"row"}
              alignItems={"center"}
              justifyContent={"center"}
              spacing={1}
              p={1}
            >
              {/* Create Button */}
              <LoadingButton
                id="u-c-btn"
                loading={isLoading}
                variant="contained"
                sx={{ width: 200 }}
                color="primary"
                onClick={onAddUpdateBtnClicked}
              >
                {isEdit ? "Update" : "Create"}
              </LoadingButton>

              {/* Cancel Button */}
              <Button
                disabled={isLoading}
                variant="outlined"
                sx={{ width: 200 }}
                color="primary"
                onClick={() => navigate(AppRoutes.dataSheetList)}
              >
                Cancel
              </Button>
            </Stack>
          </Box>
        </MyPaper>
      </Stack>
    </CheckListContext.Provider>
  );
};
