import { AccessTime, CloudDownload, DeleteOutline, InfoOutlined, PictureAsPdf } from "@mui/icons-material";
import {
    IconButton,
    Stack,
    Tooltip,
    Typography,
    useTheme
} from "@mui/material";
import { useSnackbar } from "notistack";
import React from "react";
import { IDocumentProps } from ".";
import { DeletePanel } from "../../../../components";
import { useAppDispatch } from "../../../../redux";
import { useDeleteAttachmentMutation, useLazyGetAttachmentUrlQuery } from "../../../../redux/services";
import { closeBackdrop, openBackdrop } from "../../../../redux/slices/backdrop";
import { closeConfirmationDialog, openConfirmationDialog } from "../../../../redux/slices/confirmationDialog";

const ReportView: React.FC<IDocumentProps> = (doc) => {

    const theme = useTheme();
    const dispatch = useAppDispatch();
    const { enqueueSnackbar } = useSnackbar();

    const [getAttachmentURL] = useLazyGetAttachmentUrlQuery();
    const [deleteReportAttachment] = useDeleteAttachmentMutation();

    const onDownloadBtnClicked = async () => {
        dispatch(openBackdrop('Downloading BOM Report...'));
        await getAttachmentURL({ object_type: "bomReport", object_id: doc.bomReportId, id: doc._id }).unwrap().then(res => {
            if (res) {
                let downloadLink = document.getElementById('downloadLink');
                downloadLink?.setAttribute('href', res);
                downloadLink?.click();
            } else {
                enqueueSnackbar('Something went wrong, Unable to downlad report', { variant: "error" })
            }
        }).catch(err => {
            enqueueSnackbar('Something went wrong, Unable to download report', { variant: "error" })
        })
        dispatch(closeBackdrop());
    };

    const onDelete = async () => {
        dispatch(openBackdrop('Deleting BOM Report...'));
        await deleteReportAttachment({ object_id: doc.bomReportId, object_type: "bomReport", attachment_id: doc._id }).unwrap().then(res => {
            if (doc?.refetch) {
                doc.refetch();
            }
            enqueueSnackbar('Deleted BOM Report Successfully!', { variant: "success" })
        }).catch(err => {
            enqueueSnackbar('Something went wrong, Unable to delete report', { variant: "error" })
        })
        dispatch(closeBackdrop());
        dispatch(closeConfirmationDialog())
    };

    const onDeleteBtnClicked = async () => {
        dispatch(openConfirmationDialog({
            title: "Update Operation",
            body: <DeletePanel
                message={doc.status === 'notUploaded' ? `Are you sure you want to delete the report "${doc.file_name}"? This report is currently being generated.` : `Are you sure you want to delete the report "${doc.file_name}"?`}
            />,
            positiveBtn: "Delete",
            negativeBtn: "Cancel",
            onOk: () => onDelete(),
            onNegativeBtn: () => dispatch(closeConfirmationDialog())
        }));
    };

    return (<>
        {/* eslint-disable-next-line */}
        <a id="downloadLink" href="#" target="_blank" style={{ display: "none" }} download >Download</a>
        <Stack direction={"row"} sx={{ bgcolor: `${theme.palette.grey[200]}`, borderRadius: 3, p: 1 }} justifyContent={"space-between"}>
            <Stack direction={"row"} alignItems={"center"} gap={1}>
                <PictureAsPdf color="info" />
                <Typography variant="body2" sx={{ whiteSpace: 'wrap' }} >
                    {doc.file_name}
                </Typography>
            </Stack>
            <Stack>
                {doc.status === 'notUploaded' &&
                    <Stack direction={"row"} spacing={0.5}>
                        <Tooltip title={"Report is yet to generate and upload."}>
                            <IconButton size="small">
                                <AccessTime fontSize="small" color="warning" />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title={"Delete Report"}>
                            <IconButton size="small" onClick={onDeleteBtnClicked}>
                                <DeleteOutline fontSize="small" />
                            </IconButton>
                        </Tooltip>
                    </Stack>
                }
                {doc.status === 'saved' && <Tooltip title={"Click to download"}>
                    <IconButton size="small" onClick={onDownloadBtnClicked} id="download-report">
                        <CloudDownload fontSize="small" color="success" />
                    </IconButton>
                </Tooltip>}
                {doc.status === 'failed' && 
                    <Stack direction={"row"} spacing={0.5}>
                        <Tooltip title={"Report generation failed"}>
                            <IconButton size="small">
                                <InfoOutlined fontSize="small" color="error" />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title={"Delete Report"}>
                            <IconButton size="small" onClick={onDeleteBtnClicked}>
                                <DeleteOutline fontSize="small" />
                            </IconButton>
                        </Tooltip>
                    </Stack>
                }
            </Stack>
        </Stack>
    </>);
};

export { ReportView };

