import {
  Alert,
  Box,
  Button,
  Checkbox,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  Grid,
  Tab,
  Tabs,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import {
  CustomDataGrid,
  HighlightSelectBox,
  SearchFilter,
} from "../../components";
import { Association, SelectBoxOption } from "../../interfaces";
import { useAppDispatch } from "../../redux";
import {
  useGetGroupsDDQuery,
  useGetNounsDDQuery,
  useGetVendorsDDQuery,
  useLazyGetMaterialsBySearchQuery,
  useLazyGetMaterialsQuery,
} from "../../redux/services";
import {
  closeConfirmationDialog,
  openConfirmationDialog,
} from "../../redux/slices/confirmationDialog";
import { closeDialog } from "../../redux/slices/dialog";
import { reset } from "../../redux/slices/material";
import { searchLocalList } from "../../utils";
import { BasicDetails } from "../viewMaterial/basicDetails";
import { DashboardTableColumn } from "./../materials/utils";
import { GridRowParams, GridSortDirection, GridSortModel } from "@mui/x-data-grid-premium";
import { LinkDetail } from "..";
import { ConfigureQuantity } from "./linkBOM";

interface ILinkProps {
  children?: JSX.Element;
  id?: any;
  callLinkDocument: (ids: LinkDetail[] | string[], id: string) => any;
  skipQty?: boolean;
  groupData?: any;
  nounData?: any;
  associations: any;
  showAllTabs?: boolean;
  showOnlyTabs?: ('list' | 'search' | 'create')[]
}

const isRowSelectable = (row: any, associations: Association[]) => {
  const assocArray: any = associations.filter(
    (obj: any) => obj.object_type === "material"
  );
  let isSelectable = true;

  try {
    for (let index = 0; index < assocArray?.length; index++) {
      const asso = assocArray?.[index];
      isSelectable = !asso.object_details
        .map((_: any) => _._id)
        .includes(row._id);
      if (!isSelectable) {
        break;
      }
    }
  } catch (error) {
    console.error("error", error);
  }

  return isSelectable;
};

const SearchMaterials: React.FC<ILinkProps> = (props) => {

  const dispatch = useDispatch();
  const isMatch = useMediaQuery("(max-width: 600px)");

  const [getMaterialsBySearch, { data: searchData = { data: [], totalCount: 0 }, ...searchedData }] = useLazyGetMaterialsBySearchQuery();
  const { data, totalCount } = searchData;

  const [links, setLinks] = React.useState<string[]>([]);
  const [paginationModel, setPaginationModel] = React.useState({ page: 0, pageSize: 10, });
  const [search, setSearch] = useState("");
  const [isOpen, setOpen] = React.useState<boolean>(false);
  const [queryOptions, setQueryOptions] = React.useState({
    sortModel: [{ field: 'sequence_id', sort: 'desc' as GridSortDirection }],
  });

  const onCancelBtnClicked = () => dispatch(closeDialog());

  const linkDocumentCall = (selectedLinks: LinkDetail[] | string[]) => {
    dispatch(closeConfirmationDialog());
    dispatch(closeDialog());
    setOpen(false);
    props.callLinkDocument(selectedLinks, props.id);
  };

  const configureQtys = () => {
    setOpen(true);
    dispatch(closeConfirmationDialog());
  }

  const onLinkBtnClicked = () => {
    dispatch(
      openConfirmationDialog({
        title: "Link Operation",
        body: `Are you sure you want to link the selected items`,
        positiveBtn: "Yes",
        negativeBtn: "Cancel",
        onOk: () => props?.skipQty ? linkDocumentCall(links) : configureQtys(),
        onNegativeBtn: () => dispatch(closeConfirmationDialog()),
      })
    );
  };

  const handleSortModelChange = React.useCallback((sortModel: GridSortModel) => {
    // Here you save the data you need from the sort model
    setQueryOptions({ sortModel: [...sortModel] });
  }, [])

  const onSearch = () => {
    if (search.trim()) {
      getMaterialsBySearch({ search, ...paginationModel, sort_by: queryOptions?.sortModel?.[0]?.field, sort_order: queryOptions?.sortModel?.[0]?.sort });
    }
  };

  const onRowSelection = (selected: any) => setLinks(selected);

  const giveMeRows = () => {
    if (!isLoading && data?.length > 0 && searchedData.isSuccess) {
      return data ?? [];
    } else {
      return [];
    }
  };

  React.useEffect(() => {
    onSearch();
    // eslint-disable-next-line
  }, [paginationModel, JSON.stringify(queryOptions?.sortModel)]);

  const isLoading = searchedData.isFetching || searchedData.isLoading;

  let ROWS = giveMeRows();

  return (
    <>
      <DialogContent>
        <Grid
          container item xs={12} spacing={1} sm={12} md={12}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            marginTop: "10px",
          }}
        >
          <Grid item xs={12} sm={6}>
            <SearchFilter
              searchPlaceholder={"Search for Materials"}
              handleChange={setSearch}
              value={search}
            />
          </Grid>

          <Grid item xs={12} md={2}>
            <Button
              variant={"contained"}
              color={"primary"}
              fullWidth
              onClick={onSearch}
              sx={{ fontSize: (theme) => theme.spacing(1.75), paddingX: "4px" }}
            >
              Search
            </Button>
          </Grid>
        </Grid>

        {searchedData.isError && (
          <Alert sx={{ mt: 2 }} severity="error">
            Oops! Something went wrong, Unable to fetch. Try Again Later!
          </Alert>
        )}

        <Box mt={2}>
          <CustomDataGrid
            saveLocal={false}
            rows={ROWS}
            loading={isLoading}
            columns={DashboardTableColumn({ disableNavigation: true, removeDelete: true })}
            getRowId={(row) => row._id}
            showToolbar={false}
            checkboxSelection={true}
            isRowSelectable={(params: GridRowParams) => isRowSelectable(params.row, props.associations)}
            onRowSelectionModelChange={(selected) => onRowSelection(selected)}
            rowCount={totalCount}
            paginationModel={paginationModel}
            paginationMode="server"
            onPaginationModelChange={setPaginationModel}
            sortingMode="server"
            onSortModelChange={handleSortModelChange}
          />
        </Box>

        <ConfigureQuantity links={links} data={data} onLinkDocs={linkDocumentCall} isMatch={isMatch} isOpen={isOpen} onClose={() => setOpen(false)} />
      </DialogContent>

      <DialogActions>
        <Grid
          container
          item
          xs={12}
          spacing={1}
          sm={12}
          md={12}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
          }}
          pb={1}
        >
          <Grid item xs={12} sm={6}></Grid>
          <Grid item xs={12} sm={3}>
            <Button
              variant={"contained"}
              color={"primary"}
              fullWidth
              onClick={onLinkBtnClicked}
              disabled={links.length === 0}
              sx={{
                fontSize: (theme) => theme.spacing(1.75),
                paddingX: "4px",
                right: isMatch ? 0 : 20,
                width: isMatch ? "80%" : "100%",
                transform: isMatch ? "translateX(10%)" : "translateX(0)",
              }}
            >
              Link
            </Button>
          </Grid>
          <Grid item xs={12} sm={3}>
            <Button
              variant={"outlined"}
              color={"primary"}
              fullWidth
              onClick={onCancelBtnClicked}
              disabled={false}
              sx={{
                fontSize: (theme) => theme.spacing(1.75),
                paddingX: "4px",
                right: isMatch ? 0 : 20,
                width: isMatch ? "80%" : "100%",
                transform: isMatch ? "translateX(10%)" : "translateX(0)",
              }}
            >
              Cancel
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </>
  );
};

const FilterMaterial: React.FC<ILinkProps> = (props) => {

  const dispatch = useDispatch();
  const isMatch = useMediaQuery("(max-width: 600px)");

  const [getMaterials, { data: allData = { data: [], totalCount: 0 }, ...materialsData }] = useLazyGetMaterialsQuery();
  const { data, totalCount } = allData;

  const [group, setGroup] = React.useState<SelectBoxOption | null>(null);
  const [tag, setTag] = React.useState<SelectBoxOption | null>(null);
  const [links, setLinks] = React.useState<string[]>([]);
  const [revision, setRevision] = React.useState<boolean>(false);
  const [paginationModel, setPaginationModel] = React.useState({ page: 0, pageSize: 10 });
  const [search, setSearch] = useState("");
  const [isOpen, setOpen] = React.useState<boolean>(false);
  const [queryOptions, setQueryOptions] = React.useState({
    sortModel: [{ field: 'sequence_id', sort: 'desc' as GridSortDirection }],
  });

  const onCancelBtnClicked = () => dispatch(closeDialog());

  const onGroupChange = (val: SelectBoxOption | null) => {
    setGroup(val);
    setTag(null);
  };

  const linkDocumentCall = (selectedLinks: LinkDetail[] | string[]) => {
    dispatch(closeConfirmationDialog());
    dispatch(closeDialog());
    setOpen(false);
    props.callLinkDocument(selectedLinks, props.id);
  };

  const configureQtys = () => {
    setOpen(true);
    dispatch(closeConfirmationDialog());
  }

  const onLinkBtnClicked = () => {
    dispatch(
      openConfirmationDialog({
        title: "Link Operation",
        body: `Are you sure you want to link the selected items`,
        positiveBtn: "Yes",
        negativeBtn: "Cancel",
        onOk: () => props?.skipQty ? linkDocumentCall(links) : configureQtys(),
        onNegativeBtn: () => dispatch(closeConfirmationDialog()),
      })
    );
  };

  const handleSortModelChange = React.useCallback((sortModel: GridSortModel) => {
    // Here you save the data you need from the sort model
    setQueryOptions({ sortModel: [...sortModel] });
  }, [])

  const onSearch = () => getMaterials({ include_histories: revision, noun_id: tag?._id, ...paginationModel, sort_by: queryOptions?.sortModel?.[0]?.field, sort_order: queryOptions?.sortModel?.[0]?.sort });

  const onRowSelection = (selected: any) => setLinks(selected);

  const giveMeRows = () => {
    if (search.trim().length && data && !isLoading && materialsData.isSuccess) {
      return data.filter((d: any) => searchLocalList(d, search));
    } else if (!isLoading && data) {
      return data ?? [];
    } else {
      return [];
    }
  };

  React.useEffect(() => {
    onSearch();
    // eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    onSearch();
    // eslint-disable-next-line
  }, [paginationModel, revision, JSON.stringify(queryOptions?.sortModel)]);

  const isLoading = materialsData.isFetching || materialsData.isLoading;

  let ROWS = giveMeRows();

  return (
    <>
      <DialogContent>
        <Grid
          container item xs={12} spacing={1} sm={12} md={12}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            marginTop: "10px",
          }}
        >
          <Grid item xs={12} md={3}>
            <Tooltip title={"All Revision"}>
              <FormControlLabel
                control={
                  <Checkbox
                    title="All Revision"
                    checked={revision}
                    onChange={() => {
                      setRevision(!revision);
                    }}
                  />
                }
                label="All Revision"
              />
            </Tooltip>
          </Grid>

          <Grid item xs={12} md={2}>
            <HighlightSelectBox
              label={"Group"}
              margin={"none"}
              value={group}
              options={props.groupData?.data ?? []}
              loading={props.groupData?.isLoading ?? false}
              onChange={(options: SelectBoxOption) => {
                onGroupChange(options);
              }}
            />
          </Grid>

          <Grid item xs={12} md={2}>
            <HighlightSelectBox
              label={"Tag"}
              margin={"none"}
              value={tag}
              disabled={!group}
              options={
                group
                  ? props.nounData?.currentData?.filter(
                    (_: any) => _.group_id === group?.value
                  ) ?? []
                  : []
              }
              loading={props.nounData?.isLoading ?? false}
              onChange={(options: SelectBoxOption) => {
                setTag(options);
              }}
            />
          </Grid>

          <Grid item xs={12} md={3}>
            <SearchFilter
              searchPlaceholder={"Search for Material"}
              handleChange={setSearch}
              value={search}
            />
          </Grid>

          <Grid item xs={12} md={2}>
            <Button
              variant={"contained"}
              color={"primary"}
              fullWidth
              onClick={onSearch}
              sx={{ fontSize: (theme) => theme.spacing(1.75), paddingX: "4px" }}
            >
              Search
            </Button>
          </Grid>
        </Grid>

        {materialsData.isError && (
          <Alert sx={{ mt: 2 }} severity="error">
            Oops! Something went wrong, Unable to fetch. Try Again Later!
          </Alert>
        )}

        <Box mt={2}>
          <CustomDataGrid
            saveLocal={false}
            rows={ROWS}
            loading={isLoading}
            columns={DashboardTableColumn({ disableNavigation: true, removeDelete: true })}
            getRowId={(row) => row._id}
            showToolbar={false}
            checkboxSelection={true}
            isRowSelectable={(params: GridRowParams) => isRowSelectable(params.row, props.associations)}
            onRowSelectionModelChange={(selected) => onRowSelection(selected)}
            rowCount={totalCount}
            paginationModel={paginationModel}
            paginationMode="server"
            onPaginationModelChange={setPaginationModel}
            sortingMode="server"
            onSortModelChange={handleSortModelChange}
          />
        </Box>

        <ConfigureQuantity links={links} data={data} onLinkDocs={linkDocumentCall} isMatch={isMatch} isOpen={isOpen} onClose={() => setOpen(false)} />
      </DialogContent>

      <DialogActions>
        <Grid
          container
          item
          xs={12}
          spacing={1}
          sm={12}
          md={12}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
          }}
          pb={1}
        >
          <Grid item xs={12} sm={6}></Grid>
          <Grid item xs={12} sm={3}>
            <Button
              id="link-material"
              variant={"contained"}
              color={"primary"}
              fullWidth
              onClick={onLinkBtnClicked}
              disabled={links.length === 0}
              sx={{
                fontSize: (theme) => theme.spacing(1.75),
                paddingX: "4px",
                right: isMatch ? 0 : 20,
                width: isMatch ? "80%" : "100%",
                transform: isMatch ? "translateX(10%)" : "translateX(0)",
              }}
            >
              Link
            </Button>
          </Grid>
          <Grid item xs={12} sm={3}>
            <Button
              variant={"outlined"}
              color={"primary"}
              fullWidth
              onClick={onCancelBtnClicked}
              disabled={false}
              sx={{
                fontSize: (theme) => theme.spacing(1.75),
                paddingX: "4px",
                right: isMatch ? 0 : 20,
                width: isMatch ? "80%" : "100%",
                transform: isMatch ? "translateX(10%)" : "translateX(0)",
              }}
            >
              Cancel
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </>
  );
};

const CreateAssignMaterial: React.FC<ILinkProps> = (props) => {

  const dispatch = useAppDispatch();
  const nounData = props.nounData;
  const groupData = props.groupData;
  const supplierData = useGetVendorsDDQuery(null, { refetchOnMountOrArgChange: true, refetchOnFocus: true });

  const onCancelBtnClicked = () => {
    dispatch(closeDialog());
  };

  React.useEffect(() => {
    dispatch(reset());
    // eslint-disable-next-line
  }, [])

  return <DialogContent>
    <BasicDetails
      refetch={() => false}
      matId={"New"}
      nounData={nounData}
      groupData={groupData}
      supplierData={supplierData}
      _id={""}
      version={"0"}
      associations={[]}
      avoidNavigate={true}
      customAssociation={{ object_id: "", object_type: "" }}
      handleParentDialogClose={onCancelBtnClicked}
      onSuccessfullMaterialCreation={(material: any) => {
        props.callLinkDocument([material], props.id);
        onCancelBtnClicked();
      }}
      docNavigationPath={""}
      avoidDocNavigation={true}
      buttonPrefix="& Link"
      note="Note: It will create the new material with the above basic details and link it to the BOM. To add other material related details please visit the material list page."
    />
  </DialogContent>
};

const allTabs: string[] = ["list", "search", "create"];

export const LinkChildMaterial: React.FC<ILinkProps> = ({ showAllTabs = true, showOnlyTabs = [], ...props }) => {

  const nounData = useGetNounsDDQuery(null, { refetchOnMountOrArgChange: true, refetchOnFocus: true });
  const groupData = useGetGroupsDDQuery(null, { refetchOnMountOrArgChange: true, refetchOnFocus: true });

  const [selectedTab, updateTab] = useState(showAllTabs ? allTabs[0] : showOnlyTabs[0]);

  return (
    <>
      <DialogTitle>
        <Typography
          gutterBottom
          variant="body1"
          color={"textPrimary"}
          sx={{ display: "flex", fontWeight: 600 }}
        >
          Create/Link Material
        </Typography>
        <Divider variant="fullWidth" sx={{ mb: 2 }} />
        <Tabs
          value={selectedTab}
          onChange={(e, tab) => updateTab(tab)}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons
          allowScrollButtonsMobile
        >
          {(showAllTabs || showOnlyTabs.includes('list')) && <Tab value={allTabs[0]} label="Browse Materials" />}
          {(showAllTabs || showOnlyTabs.includes('search')) && <Tab value={allTabs[1]} label="Search Material" />}
          {(showAllTabs || showOnlyTabs.includes('create')) && <Tab value={allTabs[2]} label="Create & Link New Material" />}
        </Tabs>
      </DialogTitle>

      {selectedTab === allTabs[0] && <FilterMaterial {...props} groupData={groupData} nounData={nounData} />}
      {selectedTab === allTabs[1] && <SearchMaterials {...props} groupData={groupData} nounData={nounData} />}
      {selectedTab === allTabs[2] && <CreateAssignMaterial {...props} groupData={groupData} nounData={nounData} />}
    </>
  );
};
