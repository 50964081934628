import React, { useEffect } from "react";
import { Alert, Box, useTheme } from "@mui/material";
import { useSnackbar } from "notistack";
import { TitleHeader, CustomDataGrid, DeletePanel } from "../../../components";
import { useAppDispatch, useAppSelector } from "../../../redux";
import { useDeleteTagGroupMutation, useLazyGetTagGroupsQuery } from "../../../redux/services";
import { closeBackdrop, openBackdrop } from "../../../redux/slices/backdrop";
import { closeConfirmationDialog, openConfirmationDialog, updateConfirmationDialogPositiveBtn, updateCongirmationDialogBody } from "../../../redux/slices/confirmationDialog";
import { openDialog } from "../../../redux/slices/dialog";
import { TagGroupsTableColumn } from "../utils";
import { AddEditTagGroup } from "./addEditTagGroups";
import { HasAccess } from "../../../router/authorization";
import { PERMISSIONS } from "../../../router/permission";
import { GridSortDirection, GridSortModel } from "@mui/x-data-grid-premium";
import { useStyles } from "../../documentTemplates/styles";

export const TagGroupsListing: React.FC<{ children?: JSX.Element }> = (props) => {

    const classes = useStyles(useTheme());
    const dispatch = useAppDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const [getTagGroups, { data: allData = { data: [], totalCount: 0 }, isLoading, isFetching, isError }] = useLazyGetTagGroupsQuery();
    const { data, totalCount } = allData;

    const [deleteTagGroup] = useDeleteTagGroupMutation();

    const [search, setSearch] = React.useState("");

    const [paginationModel, setPaginationModel] = React.useState({
        page: 0,
        pageSize: 10,
    });
    const [queryOptions, setQueryOptions] = React.useState({
        sortModel: [{ field: '', sort: '' as GridSortDirection }],
    });

    const { perm } = useAppSelector(store => store.auth.userDetails);

    const master = React.useMemo(() => HasAccess(perm, PERMISSIONS.TAG_CATEGORY_MASTER), [perm]);

    const handleSortModelChange = React.useCallback((sortModel: GridSortModel) => {
        // Here you save the data you need from the sort model
        setQueryOptions({ sortModel: [...sortModel] });
    }, [])

    const refetch = () => {
        getTagGroups({ ...paginationModel, sort_by: queryOptions?.sortModel?.[0]?.field, sort_order: queryOptions?.sortModel?.[0]?.sort });
    }

    useEffect(() => {
        refetch();
        // eslint-disable-next-line
    }, [paginationModel, JSON.stringify(queryOptions?.sortModel)]);
    
    const openAddEditTagGroupsDialog = (isEdit: boolean, data: any) => {
        dispatch(openDialog({
            title: "",
            hideNegativeBtn: true,
            hidePositiveBtn: true,
            body: <AddEditTagGroup isEdit={isEdit} data={data} refetch={refetch} />
        }));
    }

    const deleteRow = (data: any = null) => {
        let message = `Do you want to delete this "${data.tag_group_name}"?`;
        dispatch(openConfirmationDialog({
            title: "Delete Operation",
            body: <DeletePanel
                message={message}
                warningMessage={""}
            />,
            positiveBtn: "Check for dependencies",
            negativeBtn: "Cancel",
            onOk: () => performRowDel(data, message),
            onNegativeBtn: () => dispatch(closeConfirmationDialog())
        }))
    };

    const performRowDel = async (data: any, message: string) => {
        dispatch(openBackdrop("Checking dependencies and deleting tag category...."));
        let res: any = {};
        try {

            res = await deleteTagGroup({ id: data._id });

            if (Object.keys(res).includes("data")) {
                enqueueSnackbar(`Deleted ${data.tag_group_name} Successfully!`, { variant: "success" })
                dispatch(closeConfirmationDialog());
                refetch();
            } else if (Object.keys(res).includes("error")) {
                // eslint-disable-next-line 
                throw "Unable to delete Tag Category"
            } else {
                // eslint-disable-next-line 
                throw "Data not found"
            }
        } catch (error) {
            let errorMessage: string = `Unable to delete ${data.tag_group_name}!`;
            enqueueSnackbar(errorMessage, { variant: "error" });
            dispatch(updateCongirmationDialogBody(<DeletePanel message={"Dependency table of " + data.tag_group_name} errorMessage={errorMessage} errorMessageStacks={[{ object_type: "tag", object_details: res?.error?.data?.details?.tags?.map((_: any) => ({ sequence_id: _?.tag_name })) }]} />));
            dispatch(updateConfirmationDialogPositiveBtn({
                positiveBtn: "Force Delete",
                onOk: () => performRowDel(data,"")
            }))
        } finally {
            dispatch(closeBackdrop());
        }
    };

    const giveMeRows = () => {
        if (!isLoading && !isFetching) {
            return search.trim().length ? data?.filter((_: any) => _?.tag_group_name?.toLowerCase()?.includes(search?.toLowerCase())) ?? [] : data;
        } else {
            return [];
        }
    };

    const ROWS = giveMeRows();

    return <Box sx={classes.root}>
        {/* Header */}
        <TitleHeader
            title="Tag Categories"
            count={ROWS?.length ?? 0}
            showSearch={true}
            search={search}
            onSearchChange={setSearch}
            searchPlaceholder={"Search for Tag Categories (only within this page)"}
            showCreateBtn={master}
            createBtnLabel={"Create"}
            onCreateBtn={() => openAddEditTagGroupsDialog(false, null)}
        />

        {/* Error */}
        {isError && <Alert sx={{ mt: 2 }} severity="error">Oops! Something went wrong, Unable to fetch Tag Categories. Try Again Later!</Alert>}

        {/* Data Drid */}
        <Box mt={2}>
            <CustomDataGrid
                id="tag-group-list"
                rows={ROWS ? ROWS : []}
                columns={TagGroupsTableColumn((data: any) => openAddEditTagGroupsDialog(true, data), deleteRow, master)}
                loading={isLoading || isFetching}
                getRowId={(row) => row._id}
                showToolbar={true}
                rowCount={totalCount}
                paginationModel={paginationModel}
                paginationMode="server"
                onPaginationModelChange={setPaginationModel}
                sortingMode="server"
                onSortModelChange={handleSortModelChange}
            />
        </Box>
    </Box>
}