import { List } from "@mui/icons-material";
import { Box } from "@mui/material";
import React from "react";
import { Layout, MyPaper } from "../../components";
import { Dashboard } from "./dashboard";
import { useAppDispatch } from "../../redux";
import { closeBackdrop } from "../../redux/slices/backdrop";
import { reset } from "../../redux/slices/bom";
import { useNavigate, useParams } from "react-router-dom";

const ListView: React.FC<{ paths?: Array<any>, object_type?: string }> = ({ paths = [], object_type = "" }) => {
  const dispatch = useAppDispatch();

  const { id = "" } = useParams();

  const navigate = useNavigate();

  React.useEffect(() => {
    dispatch(closeBackdrop());
    dispatch(reset());
    // eslint-disable-next-line
  }, []);

  if (id) {
    return (
      <MyPaper height={"100%"} padding={0}>
        <Dashboard paths={paths} object_type={object_type} />
      </MyPaper>
    )
  }

  return (
    <Box sx={{ padding: 0.5, height: "100%" }}>
      <Layout
        history={[
          ...paths.map((_: any) => {
            return { label: _.title, onClick: () => navigate(_.path) };
          }),
        ]}
        currentPath={"List View"}
        hideNavBarByDefault={true}
        navBars={[
          {
            id: "1",
            title: "List View",
            icon: <List fontSize="small" />,
            path: paths[0]?.path,
          },
        ]}
        mainPanel={<Dashboard />}
        sideNavVariant={"whiteboard"}
        locationState={paths}
      />
    </Box>
  );
};

export default ListView;
