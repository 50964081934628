import { Box, DialogContent, DialogTitle, Stack, Tooltip, Typography } from "@mui/material"
import { GridRenderCellParams } from "@mui/x-data-grid-premium";
import { useEffect, useMemo, useState } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import { buildRows } from ".";
import { CustomDataGrid, Layout, NotFoundSection, SectionPaper } from "../../../components"
import { useAppDispatch, useAppSelector } from "../../../redux";
import { useLazyGetDataTableByIdQuery } from "../../../redux/services";
import { closeBackdrop, openBackdrop } from "../../../redux/slices/backdrop";
import { openDialog } from "../../../redux/slices/dialog";
import CustomTable from "../dataSheet/table";

type MatchProps = { title: string; value: string; score: number; matches: any[] };

const ViewMatches = (props: MatchProps) => {

    let columns = useMemo(() => {
        return [
            {
                field: "target",
                headerName: "Target",
                width: 250,
                renderCell: (params: GridRenderCellParams) => {
                    if (params.rowNode.type === "group") {
                        return params.value;
                    }
                    return (
                        <Typography
                            color="primary"
                            variant="body2"
                        >
                            {params.row?.target}
                        </Typography>
                    );
                },
            },
            {
                field: "value",
                headerName: "Value",
                width: 150,
                renderCell: (params: GridRenderCellParams) => {
                    if (params.rowNode.type === "group") {
                        return params.value;
                    }
                    return (
                        <Tooltip title={params.row?.value}>
                            <Typography
                                variant="body2"
                            >
                                {params.row?.value}
                            </Typography>
                        </Tooltip>
                    );
                },
            },
            {
                field: "algorithm",
                headerName: "Algorithm",
                width: 150,
                renderCell: (params: GridRenderCellParams) => {
                    if (params.rowNode.type === "group") {
                        return params.value;
                    }
                    return (
                        <Tooltip title={params.row?.value}>
                            <Typography
                                variant="body2"
                            >
                                {params.row?.algorithm}
                            </Typography>
                        </Tooltip>
                    );
                },
            },
            {
                field: "score",
                headerName: "Score",
                width: 150,
                renderCell: (params: GridRenderCellParams) => {
                    if (params.rowNode.type === "group") {
                        return params.value;
                    }
                    return (
                        <Typography
                            variant="body2"
                        >
                            {params?.row?.score ? (params?.row?.score * 100).toFixed(2) : "No Match Found"}
                        </Typography>
                    );
                },
            },
        ]
        // eslint-disable-next-line
    }, [props])

    return <>
        <DialogTitle>
            <Typography
                variant="h6"
                color="textPrimary"
                sx={{
                    borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
                    width: "100%", fontFamily: 'htrts_medium'
                }}
            >
                {`Matches for column: `}<Typography component={"span"} variant="h6">{`(${props.title} - ${props.value})`}</Typography>
            </Typography>
        </DialogTitle>
        <DialogContent>
            <CustomDataGrid
                saveLocal={false}
                initialState={{
                    sorting: {
                        sortModel: [{ field: 'description', sort: 'desc' }],
                    },
                }}
                rows={props.matches ?? []}
                columns={columns}
                getRowId={(row) => `${row.target} ${row.value}`}
                hideFooter
            />
        </DialogContent>
    </>
}

const RenderFoundResults = ({ viewData, dataSheet, setDataSheet }: any) => {
    const dispatch = useAppDispatch();
    const { materials } = useAppSelector(store => store);

    const rows = useMemo(() => {
        let r = buildRows(materials);
        let keys = Object.keys(viewData?.matching_fields || {});
        return r.filter(r => keys.includes(r.id));
        // eslint-disable-next-line
    }, [materials]);

    let columns = useMemo(() => [
        {
            field: "description",
            headerName: "Description",
            width: 280,
            renderCell: (params: GridRenderCellParams) => {
                if (params.rowNode.type === "group") {
                    return params.value;
                }
                return (
                    <Typography
                        color="primary"
                        variant="body2"
                    >
                        {params.row?.description}
                    </Typography>
                );
            },
        },
        {
            field: "value",
            headerName: "Value",
            width: 350,
            renderCell: (params: GridRenderCellParams) => {
                if (params.rowNode.type === "group") {
                    return params.value;
                }
                return (
                    <Typography
                        variant="body2"
                    >
                        {params.row?.value}
                    </Typography>
                );
            },
        },
        {
            field: "score",
            headerName: "Score",
            width: 350,
            renderCell: (params: GridRenderCellParams) => {
                const d = viewData?.matching_fields?.[params.row?.id];
                if (params.rowNode.type === "group") {
                    return params.value;
                }
                return (
                    <Typography
                        variant="body2"
                        sx={{
                            color: d?.score === 1 ? "green" : "primary.main",
                            textDecoration: d?.score ? "underline" : "none",
                            cursor: d?.score ? "pointer" : 'default'
                        }}
                        onClick={() => onOpenMatches({ ...d, title: params.row?.description })}
                    >
                        {d?.score ? (d?.score * 100).toFixed(2) : "No Match Found"}
                    </Typography>
                );
            },
        },
        // eslint-disable-next-line
    ], [materials, viewData])

    let productSpecColumns = useMemo(() => [
        {
            field: "name",
            headerName: "Name",
            width: 280,
            renderCell: (params: GridRenderCellParams) => {
                if (params.rowNode.type === "group") {
                    return params.value;
                }
                return (
                    <Typography
                        color="primary"
                        variant="body2"
                    >
                        {params.row?.name}
                    </Typography>
                );
            },
        },
        {
            field: "value",
            headerName: "Value",
            width: 350,
            renderCell: (params: GridRenderCellParams) => {
                if (params.rowNode.type === "group") {
                    return params.value;
                }
                return (
                    <Typography
                        variant="body2"
                    >
                        {Array.isArray(params.row?.value) ? `${params.row?.value?.join(", ")} ${params.row?.uom ? `(${params.row?.uom})` : ""}` : `${params.row?.value} ${params.row?.uom ? `(${params.row?.uom})` : ""}`}
                    </Typography>
                );
            },
        },
    ], [])

    const onOpenMatches = (d: MatchProps) => {
        if (d?.score) {
            dispatch(openDialog({
                title: "",
                hideNegativeBtn: true,
                hidePositiveBtn: true,
                maxWidth: "md",
                enablePadding: false,
                body: (
                    <ViewMatches {...d} />
                ),
            }))
        }
    }

    return <Stack spacing={2}>
        <SectionPaper title="Product Specs">
            <>
                <CustomDataGrid
                    saveLocal={false}
                    initialState={{
                        sorting: {
                            sortModel: [{ field: 'name', sort: 'desc' }],
                        },
                    }}
                    rows={viewData?.product?.specs ?? []}
                    columns={productSpecColumns}
                    getRowId={(row) => row.name}
                    hideFooter
                />
            </>
        </SectionPaper>

        <SectionPaper title="Basic Details">
            <>
                <CustomDataGrid
                    saveLocal={false}
                    initialState={{
                        sorting: {
                            sortModel: [{ field: 'description', sort: 'desc' }],
                        },
                    }}
                    rows={rows}
                    columns={columns}
                    getRowId={(row) => row.id}
                    hideFooter
                />
            </>
        </SectionPaper>

        {dataSheet?.map((data: any, index: number) => <CustomTable searchResponse={{ hasScore: true, response: viewData, onOpenMatches: onOpenMatches }} onUpdate={() => { }} data={data} key={index} state={dataSheet} updateState={setDataSheet} index={index} preview />)}
    </Stack>
}

export const FoundResults = ({ response }: { response: any }) => {

    const [getDataTableById] = useLazyGetDataTableByIdQuery({ refetchOnFocus: true, refetchOnReconnect: true })

    const [viewData, setViewData] = useState(response?.data?.results[0]);
    const [dataSheet, setDataSheet] = useState([]);

    const { materials } = useAppSelector(store => store);

    const dispatch = useAppDispatch();
    const location = useLocation();
    const [searchParams, setSearchParams] = useSearchParams();

    const updateTablesState = async (res: any) => {
        const newState = res?.tables?.map((table: any, idx: number) => ({
            rowSelectionModel: [],
            name: table.name,
            position: idx,
            description: table.description,
            header_color: table?.header_color,
            definition: {
                columns: table?.columns?.filter((col: any) => col.field)?.map((col: any) => {
                    return {
                        ...col,
                        headerName: col.header_name,
                        headerClassName: `${col.header_class_name}`,
                        resizable: false,
                        sortable: false,
                        disableColumnMenu: true,
                    }
                }),
                rows: table?.rows?.map((row: any) => {
                    const obj: any = {};
                    row?.column_content?.forEach((col: any) => {
                        obj[col.field] = col.content
                    })
                    return {
                        id: row?.id,
                        ...obj
                    }
                }) || [],
            },
        }));
        setDataSheet(newState)
    };

    const fetchDataSheet = async (id: string) => {
        dispatch(openBackdrop(`Fetching Data Sheet Detail...`));
        await getDataTableById({
            dtTemplateType: "datasheet",
            dtInstanceType: "form",
            dtTemplateId: id
        }).unwrap().then(async (res) => {
            if (res) {
                await updateTablesState(res);
            }
        }).catch((error: any) => {
            console.error(`Error fetching datasheet : ${error}`)
        }).finally(() => {
            dispatch(closeBackdrop());
        })
    };

    useEffect(() => {
        const sheet_id = materials?.associations?.find((_: any) => _.object_type === "dataTableForm")?.object_details?.at(-1)?._id;
        if (sheet_id) {
            fetchDataSheet(sheet_id);
        }
        // eslint-disable-next-line
    }, [materials])

    useEffect(() => {
        if (response?.data?.results?.length > 0 && !searchParams.get("id")) {
            setSearchParams({ ...searchParams, tab: "tab2", id: response?.data?.results[0]?.product?.id as any })
        }
        // eslint-disable-next-line
    }, [response])

    useEffect(() => {
        let value = response?.data?.results?.find((res: any) => res.product.id === Number(searchParams.get("id")));
        if (value) {
            setViewData(value)
        }
        // eslint-disable-next-line
    }, [response, searchParams])

    if (!response?.data?.results || response?.data?.results?.length <= 0) {
        return <NotFoundSection message="No Supplier Results Found" />
    }

    return (
        <Box py={1} height={"100%"}>
            <Layout
                hideBreadScrum={true}
                navBars={response?.data?.results?.map((res: any, index: number) => {
                    return {
                        id: res.product.id,
                        title: `${res.product?.name} ${res.score.toFixed(2)}`,
                        path: `${location.pathname}?tab=tab2&id=${res.product.id}`,

                    }
                })}
                mainPanel={<RenderFoundResults viewData={viewData} dataSheet={dataSheet} setDataSheet={setDataSheet} />}
                showDropInsteadOfNavInMobile={true}
                navBarTitle="Materials"
                navBarHasChild={true}
                sideNavVariant={"whiteboard"}
            />
        </Box>
    );
}