import { createApi, fetchBaseQuery, retry } from '@reduxjs/toolkit/query/react';
import Axios from "axios";
import { config } from '../../../config';
import { LocalStorageKeys, customBtoa, getTransformedDataForPagination, ConvertToReactSelect, objectToQueryParams } from '../../../utils';
import { ProductSliceInterface } from '../../slices/product';

const prepareProductBody = (payload: ProductSliceInterface) => {
    let body: any = {
        family_id: payload.basic_details.family?.value,
        group_id: payload.basic_details.group?.value,
        product_description: payload.basic_details.description,
        product_manager: payload.basic_details.productManager,
        product_name: payload.basic_details.product?.label,
        product_noun_id: payload.basic_details.product?.value,
        status: "active",
        tags_info: payload.product_tags.tags.map((tag) => {
            return {
                tag_group_id: tag.tag_group_id,
                tag_id: tag.value
            }
        }),
        links: payload.links,
        unlinks: payload.unlinks,
        notes: customBtoa(payload?.notes ?? ""),
        external_ref_id: payload.basic_details.external_ref_id,
    }
    if (payload.basic_details.businessUnit?.tag_group_id && payload.basic_details.businessUnit?.value) body.tags_info.push({
        tag_group_id: payload.basic_details.businessUnit?.tag_group_id ?? "",
        tag_id: payload.basic_details.businessUnit?.value ?? ""
    })
    if (payload.basic_details.status?.tag_group_id && payload.basic_details.status?.value) body.tags_info.push({
        tag_group_id: payload.basic_details.status?.tag_group_id ?? "",
        tag_id: payload.basic_details.status?.value ?? ""
    })
    return body;
}

export const productAPI = createApi({
    reducerPath: "productAPI",
    baseQuery: retry(fetchBaseQuery({
        baseUrl: config.api_url + "prodm/product",
        prepareHeaders: (headers, { getState }) => {
            const token = localStorage.getItem(LocalStorageKeys.authToken);

            // If we have a token set in state, let's assume that we should be passing it.
            if (token) {
                headers.set('authorization', `Bearer ${token}`)
            }

            return headers
        },
    }), { maxRetries: 1 }),
    tagTypes: ['Products'],
    endpoints: (builder) => ({

        // Get Products API
        getProducts: builder.query({
            query: ({ page = 0, pageSize = 10, sort_by = null, sort_order = null }) => ({
                url: `${page !== null ? `?page_no=${page + 1}` : ''}${pageSize ? `&page_size=${pageSize}` : ''}${sort_by ? `&sort_by=${sort_by}` : ''}${sort_order ? `&sort_order=${sort_order}` : ''}`,
                method: "GET"
            }),
            transformResponse: (data: any, meta: { request: Request; response?: Response }) => {
                return getTransformedDataForPagination(data, meta?.response);
            },
            providesTags: ["Products"]
        }),

        // Get Products as Options API
        getProductsAsDD: builder.query({
            query: (payload: { page_no?: number, page_size?: number }) => ({
                url: `${objectToQueryParams(payload)}`,
                method: "GET"
            }),
            transformResponse: (response: any[], meta, arg) => ConvertToReactSelect(response, "_id", "sequence_id", ["product_name"]),
        }),

        // Get Product by Product id API
        getProductById: builder.query({
            query: ({ id }) => ({ url: `/${id}`, method: "GET" }),
        }),

        // Create Product API
        createProduct: builder.mutation({
            query: (payload: ProductSliceInterface) => ({ method: "POST", body: { ...prepareProductBody(payload) } }),
            invalidatesTags: ["Products"]
        }),

        // Update Product API
        updateProduct: builder.mutation({
            query: ({ id, payload, update_version }: { id: string, update_version: boolean, payload: ProductSliceInterface }) => {
                return { url: `/${id}?update_version=${false}`, method: "PUT", body: { ...prepareProductBody(payload) } }
            },
            invalidatesTags: ["Products"]
        }),

        // Delete Product API
        deleteProduct: builder.mutation({
            query: ({ id, force }) => ({ url: `/${id}?force=${force}`, method: "DELETE" }),
            invalidatesTags: ["Products"]
        }),

        // Get Dashboard Data
        getProductDashboard: builder.query({
            query: ({ year = '' }) => ({ url: `/dashboard${year.length ? `?year=${year}` : ''}`, method: "GET" })
        }),

        // product Image Upload
        uploadProductImage: builder.mutation({
            async queryFn(_arg, _queryApi, _extraOptions) {
                const { data, url, file } = _arg;
                let result: any = {};

                try {
                    let resData = null;
                    if (file) {
                        resData = await Axios({
                            url,
                            method: "put",
                            headers: { "Content-Type": file.type },
                            data: file,
                        });
                    }
                    result = { data: { resData, document: data } };
                } catch (err) {
                    result = { error: err };
                } finally {
                    return result;
                }
            },
            invalidatesTags: ["Products"]
        }),
    })
})

// Export hooks for usage in functional components
export const { useGetProductsQuery, useLazyGetProductsQuery, useGetProductByIdQuery, useLazyGetProductByIdQuery, useCreateProductMutation, useUpdateProductMutation, useDeleteProductMutation, useLazyGetProductDashboardQuery, useUploadProductImageMutation, useLazyGetProductsAsDDQuery } = productAPI
