import React from "react";
import { Alert, colors, Divider, FormControlLabel, Grid, Radio, Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import PdfView, { paperSizes } from "./pdfView";
import { SelectBoxOption } from "../../interfaces";
import { createOption } from "../keywordSearch";
import { CheckListData } from "../../screens/viewCheckList/viewCheckListDetail";
import { useGetDocumentTemplatesDDOptionsQuery } from "../../redux/services";
import { TemplateResponse } from "../../redux/slices/documentTemplate";
import { orientationOptions } from "../../screens/documentTemplates/dashboard/addEditTemplate";
import { HighlightSelectBox } from "../highLightSelectBox";
import { SectionPaper } from "../sectionPaper";
import { IBasicDetails, Orientation } from "pdf-generator";

const renderingOptions = [createOption("No Template"), createOption("With Template")];

const DataSheetPDFPreview = ({ basicDetails, dataSheet, title = '', headerTitle = '', selectedSize, selectedOrientation }: { basicDetails?: IBasicDetails; dataSheet?: CheckListData; title?: string; headerTitle?: string, selectedSize?: SelectBoxOption, selectedOrientation?: SelectBoxOption }) => {
    const [orientation, setOrientation] = React.useState<SelectBoxOption | null>(selectedOrientation ?? orientationOptions[0])

    const [paperSize, setPaperSize] = React.useState<SelectBoxOption | null>(selectedSize ?? paperSizes[6]);

    const theme = useTheme();

    const matches = useMediaQuery(theme.breakpoints.down('md'));

    const [renderingType, setRenderingType] = React.useState(renderingOptions[0]?.value);

    const [selectedTemplate, setTemplate] = React.useState<Partial<TemplateResponse & SelectBoxOption> | null>(null);

    const templateOptions = useGetDocumentTemplatesDDOptionsQuery({}, {
        refetchOnFocus: true,
        refetchOnMountOrArgChange: true,
    });

    const options = templateOptions?.data ?? [];

    const current_paper_size = (selectedTemplate?.paper_size ?? paperSize?.value ?? "A4") as string;
    const current_orientation = (selectedTemplate?.orientation ?? orientation?.value ?? "portrait") as Orientation;

    return <Stack sx={{ display: "flex", height: "100%" }}>
        <Stack width={"100%"} height={"100%"}>
            <Stack mb={2}>
                <SectionPaper title="Configure Preview">
                    <Grid container spacing={1} width={"100%"} columns={13}>
                        <Grid item xs={13} md={6}>
                            <Stack spacing={2}>
                                <FormControlLabel
                                    value={renderingOptions[0].value}
                                    control={
                                        <Radio
                                            size="small"
                                            checked={renderingOptions[0]?.value === renderingType}
                                            onChange={() => {
                                                setRenderingType(renderingOptions[0].value);
                                                setTemplate(null);
                                                setPaperSize(paperSizes[6]);
                                                setOrientation(orientationOptions[0]);
                                            }}
                                            value={renderingOptions[0].value} />
                                    }
                                    label={renderingOptions[0].label}
                                />
                                <HighlightSelectBox
                                    disabled={renderingOptions[1]?.value === renderingType}
                                    label={"Paper Size"}
                                    margin={"none"}
                                    fullWdith
                                    value={paperSize}
                                    options={paperSizes}
                                    onChange={(option: SelectBoxOption) => { setPaperSize(option) }}
                                />
                                <HighlightSelectBox
                                    disabled={renderingOptions[1]?.value === renderingType}
                                    label={"Orientation"}
                                    margin={"none"}
                                    fullWdith
                                    value={orientation}
                                    options={orientationOptions}
                                    onChange={(option: SelectBoxOption) => { setOrientation(option) }}
                                />
                            </Stack>
                        </Grid>
                        <Grid item xs={13} md={1} mt={2}>
                            <Stack sx={{ width: "100%", height: "100%" }} justifyContent={matches ? "center" : "flex-start"} alignItems={matches ? "stretch" : 'center'}><Divider orientation={matches ? "horizontal" : "vertical"} /></Stack>
                        </Grid>
                        <Grid item xs={13} md={6}>
                            <Stack spacing={2}>
                                <FormControlLabel
                                    value={renderingOptions[1].value}
                                    control={
                                        <Radio
                                            size="small"
                                            checked={renderingOptions[1].value === renderingType}
                                            onChange={() => {
                                                setRenderingType(renderingOptions[1].value);
                                                setOrientation(null);
                                                setPaperSize(null);
                                            }}
                                            value={renderingOptions[1].value}
                                            name="radio-buttons"
                                        />
                                    }
                                    label={renderingOptions[1].label}
                                />

                                <HighlightSelectBox
                                    disabled={renderingOptions[0]?.value === renderingType}
                                    label={"Select template"}
                                    margin={"none"}
                                    fullWdith
                                    value={selectedTemplate as SelectBoxOption}
                                    options={options}
                                    onChange={(option: SelectBoxOption) => { setTemplate(option) }}
                                />
                                <Alert variant="outlined" severity="warning" sx={{ borderWidth: 6, borderRight: 0, borderTop: 0, borderBottom: 0, mt: 2 }}>
                                    <Typography variant="caption" color={colors.grey[500]}>
                                        Caution! Please ensure the table width is not exceeding the selected template page width!
                                        Please select appropriate template to accommodate table content.
                                    </Typography>
                                </Alert>
                            </Stack>
                        </Grid>
                    </Grid>
                </SectionPaper>
            </Stack>
            <PdfView headerTitle={headerTitle} title={title} basicDetails={basicDetails} dataSheet={dataSheet} templateData={selectedTemplate} orientation={current_orientation} paperSize={current_paper_size} />
        </Stack>
    </Stack>
};

export { DataSheetPDFPreview };