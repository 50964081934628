import { Box, useMediaQuery, useTheme } from "@mui/material";
import { SideNavBar, TopNavBar, navbarWidths } from './components';
import { useAppSelector } from "./redux";
import { ThemeContext } from "./contexts";
import { useContext } from "react";
import { Themes } from "./utils";

export const AppNavBars = (props: any) => {

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const { openWebSideNavBar } = useAppSelector(store => store.utils.sideNavBar);
    const themeContext = useContext(ThemeContext);

    const isLight = themeContext.name === Themes.default;

    return (
        <Box sx={{ height: "100%" }}>
            <TopNavBar />

            {!isMobile && <SideNavBar isMobile={false} />}

            <Box sx={{
                width: '100%',
                height: `calc(100% - ${navbarWidths.topNavHeight}px)`,
                background: (theme) => isLight ? theme.palette.background.paper : "#292E32",
                paddingLeft: `${!isMobile ? openWebSideNavBar ? navbarWidths.web : navbarWidths.webCollapse : '0'}px`,
                overflow: 'auto'
            }}>
                <Box height={"100%"} bgcolor={(theme) => theme.palette.background.default} sx={{ borderTopLeftRadius: 16, overflow: 'auto' }} position={"relative"} p={props.enablePadding ? 1 : 0}>
                    {props?.children}
                </Box>
            </Box>
        </Box>
    );
};