import React from "react";
import { Stack, Typography } from "@mui/material";
import { DatePicker as DP, DatePickerProps, } from '@mui/x-date-pickers/DatePicker';
import { Moment } from "moment";

type CustomDatePickerProps<T> = DatePickerProps<T> & {
    viewOnly?: boolean
}

export const DatePicker: React.FC<CustomDatePickerProps<Moment>> = ({ viewOnly = false, ...props }) => {

    const value: any = typeof props.value === "string" ? props.value || "N/A" : props.value;

    return (
        viewOnly ? (
            <Stack sx={{ overflowWrap: "break-word" }}>
                <Typography variant="body1" sx={{ fontFamily: "htrts_medium" }}>{props.label}</Typography>
                <Typography variant="body2" sx={{ fontFamily: "htrts_regular" }}>{value ? value : "-"}</Typography>
            </Stack>
        ) : <DP
            {...props}
            sx={{
                width: "100%", '& .MuiInputBase-root': {
                    height: "40px"
                },
                '& .MuiInputLabel-root': {
                    top: -7
                },
                ...props.sx
            }}
        />

    )
};
