import React from "react";
import 'suneditor/dist/css/suneditor.min.css';
import { Alert, Box, Theme, useTheme } from "@mui/material";
import { CustomDataGrid, DeletePanel, TitleHeader } from "../../../components";
import { useSnackbar } from "notistack";
import { DashboardTableColumn } from "./issueTrackerUtils";
import { useLazyGetAllIssuesQuery, useDeleteIssueMutation } from "../../../redux/services";
import { useAppDispatch, useAppSelector } from "../../../redux";
import { closeBackdrop, openBackdrop } from "../../../redux/slices/backdrop";
import { closeConfirmationDialog, openConfirmationDialog, updateConfirmationDialogPositiveBtn, updateCongirmationDialogBody } from "../../../redux/slices/confirmationDialog";
import { useNavigate, useParams } from "react-router-dom";
import { AppRoutes } from "../../../router/routes";
import { PERMISSIONS } from "../../../router/permission";
import { HasAccess } from "../../../router/authorization";
import { GridSortDirection, GridSortModel } from "@mui/x-data-grid-premium";

const useStyles = (theme: Theme) => ({
    root: {
        flexGrow: 1,
        background: theme.palette.background.paper,
        boxShadow: `rgba(99, 99, 99, 0.2) 0px 2px 8px 0px`,
        height: "100%",
        overflow: "scroll",
        borderRadius: theme.spacing(1),
        padding: theme.spacing(2),
    },
    dataGridParent: {
        height: "calc(100% - 40px)",
        overflow: "scroll",
        marginTop: theme.spacing(2),
    }
});

interface IssueTrackerProps {
    children?: JSX.Element,
    object_type?: string, 
    refetch?: any,
    paths?: Array<any>
    creatable?: boolean, 
    updatable?: boolean, 
    deletetable?: boolean, 
    readable?: boolean, 
    isNested?: boolean
}

export const IssueTracker: React.FC<IssueTrackerProps> = ({ object_type = "New", paths = [], ...props }) => {
    const classes = useStyles(useTheme());
    const dispatch = useAppDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const [getAllIssues, { data: allData = { data: [], totalCount: 0 }, isFetching, isLoading, isError }] = useLazyGetAllIssuesQuery({ refetchOnFocus: true });
    const { data, totalCount } = allData;

    const { perm } = useAppSelector(store => store.auth.userDetails);

    const creatable = React.useMemo(() => {
        let primaryAccess = HasAccess(perm, PERMISSIONS.QUALITY_CREATE);
        if (props.isNested) {
            return (props.updatable ?? false) && primaryAccess;
        }
        return primaryAccess;
    }, [perm, props.isNested, props.updatable]);

    const deletable = React.useMemo(() => {
        let primaryAccess = HasAccess(perm, PERMISSIONS.QUALITY_DELETE);
        if (props.isNested) {
            return (props.deletetable ?? false) && primaryAccess;
        }
        return primaryAccess;
    }, [perm, props.isNested, props.deletetable]);

    const [paginationModel, setPaginationModel] = React.useState({
        page: 0,
        pageSize: 10,
    });
    const [queryOptions, setQueryOptions] = React.useState({
        sortModel: [{ field: 'sequence_id', sort: 'desc' as GridSortDirection }],
    });

    const [deleteIssueMutation] = useDeleteIssueMutation();

    const navigate = useNavigate();

    const { id = '' } = useParams();

    const giveMeRows = (): any[] => {
        if (!isLoading && !isFetching && data) {
            return data ?? [];
        } else return [];
    }

    let ROWS = giveMeRows();

    const handleSortModelChange = React.useCallback((sortModel: GridSortModel) => {
        // Here you save the data you need from the sort model
        setQueryOptions({ sortModel: [...sortModel] });
    }, [])

    const fetchIssues = () => {
        if (id) {
            getAllIssues({ 
                include_histories: false, 
                object_type, 
                object_id: id,
                sort_by: queryOptions?.sortModel?.[0]?.field, 
                sort_order: queryOptions?.sortModel?.[0]?.sort,
                ...paginationModel
            })
        } else {
            getAllIssues({ ...paginationModel, sort_by: queryOptions?.sortModel?.[0]?.field, sort_order: queryOptions?.sortModel?.[0]?.sort })
        }
    }

    React.useEffect(() => {
        fetchIssues();
        // eslint-disable-next-line
    }, [paginationModel, JSON.stringify(queryOptions?.sortModel)]);

    const viewIssue = (data: any) => {
        const hasAccess = (!data?._id && creatable) || data?._id;
        if (hasAccess) {
            let stateObj = {
                state: {
                    paths
                }
            }
            if (id && object_type) {
                navigate(AppRoutes.viewIssueTrackerWithId(object_type, id, data?._id ?? "New"), stateObj)
            } else {
                navigate(AppRoutes.viewIssueWithId(data?._id ?? "New"), stateObj)
            }
        }
    }

    const deleteIssue = (issue: any) => {
        let message = `Check for dependencies before deleting the Issue ${issue.sequence_id}-r${issue.version}`;
        dispatch(openConfirmationDialog({
            title: "Delete Operation",
            body: <DeletePanel
                message={message}
                warningMessage={""}
            />,
            positiveBtn: "Check for dependencies",
            negativeBtn: "Cancel",
            onOk: () => openDependency(issue, message, false),
            onNegativeBtn: () => dispatch(closeConfirmationDialog())
        }))
    }

    const openAssociation = (issue: any) => {
        dispatch(openConfirmationDialog({
            title: "Associations",
            body: <DeletePanel
                message={issue?.associations ? issue.associations.length === 0 ? 'No dependency found' : `Dependency Table of ${issue?.sequence_id}-r${issue.version}` : "No dependency found"}
                errorMessageStacks={issue?.associations ?? []}
            />,
            hideNegativeBtn: true,
            hidePositiveBtn: true
        }))
    }

    const openDependency = (issue: any, message: string, force: boolean) => {
        dispatch(updateCongirmationDialogBody(<DeletePanel
            message={issue.associations ? issue.associations.length === 0 ? 'No dependency found' : `Dependency Table of ${issue?.sequence_id}-r${issue.version}` : "No dependency found"}
            errorMessage={""}
            errorMessageStacks={issue?.associations ?? []}
            warningMessage={""}
        />))
        dispatch(updateConfirmationDialogPositiveBtn({
            positiveBtn: "Force Delete",
            onOk: () => {
                dispatch(updateCongirmationDialogBody(<DeletePanel
                    message={"Are you sure want to force delete the Issue " + issue?.sequence_id + "-r" + issue?.version + "?"}
                    warningMessage={"Warning: All the issue contents and its associated entities will be de-linked"}
                />))
                dispatch(updateConfirmationDialogPositiveBtn({
                    positiveBtn: "Ok",
                    onOk: () => performRowDel(issue, "", true)
                }))
            },
        }))
    }

    const performRowDel = async (issue: any, message: string, force: boolean = false) => {
        dispatch(openBackdrop("Deleting issue..."));
        let res: any = {};
        try {
            res = await deleteIssueMutation({ id: issue._id, force })

            if (Object.keys(res).includes("data")) {
                enqueueSnackbar(`Deleted issue Successfully!`, { variant: "success" })
                dispatch(closeConfirmationDialog());
                fetchIssues();
            } else if (Object.keys(res).includes("error")) {
                // eslint-disable-next-line 
                throw res.error.data;
            } else {
                // eslint-disable-next-line 
                throw "Data not found"
            }
        } catch (error: any) {
            let errorMessage: string = error?.title ?? "Opps! Something went wrong, Unable to delete"
            enqueueSnackbar(errorMessage, { variant: "error" });
        } finally {
            dispatch(closeBackdrop());
        }
    }

    return <Box sx={classes.root}>
        <TitleHeader
            title="All Searches"
            showSearch={false}
            showCreateBtn={creatable}
            createBtnLabel={"Create Issue"}
            onCreateBtn={() => viewIssue(null)}
        />

        {/* Error */}
        {isError && <Alert sx={{ mt: 2 }} severity="error">Oops! Something went wrong, Unable to fetch Issues. Try Again Later!</Alert>}

        <Box mt={2}>
            <CustomDataGrid
                id={`${object_type ? `${object_type}-` : ""}issue-tracker-list`}
                // initialState={{
                //     sorting: {
                //         sortModel: [{ field: 'sequence_id', sort: 'desc' }],
                //     },
                // }}
                rows={ROWS}
                columns={DashboardTableColumn(deleteIssue, viewIssue, openAssociation, deletable)}
                loading={isLoading || isFetching}
                getRowId={(row) => row._id}
                showToolbar={true}
                rowCount={totalCount}
                paginationModel={paginationModel}
                paginationMode="server"
                onPaginationModelChange={setPaginationModel}
                sortingMode="server"
                onSortModelChange={handleSortModelChange}
            />
        </Box>
    </Box>
}


