import { ChevronRight, DeleteOutline, ExpandMore, List, Save } from "@mui/icons-material";
import { Stack, Typography, Divider, Button, Accordion, AccordionSummary, AccordionDetails, IconButton } from "@mui/material";
import React from "react";
import { MyPaper } from "../../../components";
import { useAppDispatch, useAppSelector } from "../../../redux";
import { addSelection, deleteSelection, navigateTab, PMPVariantInterface, PMPVariantValueInterface, updateVariants } from "../../../redux/slices/pmp";
import { randomNameGenerator } from "../../../utils";
import { SelectionInfo } from "./selectionInfo";

export const SelectionDetail: React.FC<{ children?: JSX.Element }> = (props) => {

    const { selections } = useAppSelector((state) => state.pmp);
    const dispatch = useAppDispatch();

    const onAddSelectionClicked = () => { dispatch(addSelection(null)) };

    const onDelSelectionClicked = (index: number) => { dispatch(deleteSelection({ index })) };

    const onNextBtnClicked = () => {
        //Building Initial Variants
        let variants: PMPVariantInterface = {};

        selections.forEach((sel, selIndex) => {
            let selVariants: PMPVariantValueInterface[] = [];

            if (selIndex === 0) {
                sel.options.forEach((opt, optIndex) => {
                    selVariants.push({
                        id: randomNameGenerator(12),
                        name: opt.abbr,
                        options: { [sel.id + "-" + opt.id]: { selection: sel, option: opt } }
                    })
                })
            } else {
                variants[selections[selIndex - 1].id].forEach((preVar, preVarIndex) => {
                    sel.options.forEach((opt, optIndex) => {
                        selVariants.push({
                            id: randomNameGenerator(12),
                            name: preVar.name + " - " + opt.abbr,
                            options: {
                                ...preVar.options,
                                [sel.id + "-" + opt.id]: { selection: sel, option: opt }
                            }
                        })
                    })
                })
            }

            variants[sel.id] = selVariants;
        });

        dispatch(updateVariants(variants));
        dispatch(navigateTab(2));
    }

    return <MyPaper padding={0} height={"100%"}>
        <Stack divider={<Divider />} height={"100%"}>
            {/* Heading */}
            <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"} p={1}>
                {/* Title */}
                <Typography variant="body1" sx={{ fontFamily: "htrts_medium" }} >Selection Details</Typography>

                {/* Add Selection Button */}
                <Button size="small" variant="contained" color="primary" onClick={onAddSelectionClicked}>Add Selection</Button>
            </Stack>

            {/* Details */}
            <Stack spacing={1} p={1} height={"calc(100% - 100px)"} sx={{ overflow: "scroll" }}>
                {selections.map((sel, key) => {
                    return <Accordion key={key} variant="outlined" >
                        {/* Header  */}
                        <AccordionSummary expandIcon={<ExpandMore htmlColor="black" />} sx={{ background: (theme) => theme.palette.background.default }}>
                            <Stack direction={"row"} width={"100%"} alignItems={"center"} justifyContent={"space-between"}>
                                {/* Title */}
                                <Typography variant="body1">{sel.name.length ? sel.name : `Selection ${key + 1}`}</Typography>

                                {/* Delete Selection Button */}
                                {key > 0 && <IconButton onClick={(e) => onDelSelectionClicked(key)} size="small" ><DeleteOutline color="error" fontSize="small" /></IconButton>}
                            </Stack>
                        </AccordionSummary>

                        {/* Content */}
                        <AccordionDetails>
                            <SelectionInfo index={key} data={sel} />
                        </AccordionDetails>
                    </Accordion>
                })}
            </Stack>

            {/* Footer */}
            <Stack spacing={1} p={1} direction={"row"} alignItems={"center"} justifyContent={"center"} sx={{ borderTop: (theme) => `1px solid ${theme.palette.divider}` }}>
                <Button onClick={(e) => dispatch(navigateTab(2))} size={"small"} variant="contained" color="primary" startIcon={<Save />}>Save</Button>
                <Button size={"small"} variant="contained" color="secondary" startIcon={<List />}>View</Button>
                <Button onClick={onNextBtnClicked} size={"small"} variant="outlined" color="primary" endIcon={<ChevronRight />}>Next</Button>
            </Stack>
        </Stack>
    </MyPaper>
}