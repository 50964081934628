import React from "react";
import { Box, Grid, TextField, Typography, Button, IconButton, Stack, Tooltip, DialogTitle, DialogContent, DialogActions, Dialog } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useDispatch } from "react-redux";
import { closeDialog } from "../../../../redux/slices/dialog";
import { openBackdrop, closeBackdrop } from "../../../../redux/slices/backdrop";
import { useCreateAttributeMutation, useLazyGetNounsQuery, useUpdateAttributeMutation } from "../../../../redux/services";
import { useSnackbar } from "notistack";
import { SelectBoxOption } from "../../../../interfaces";
import { NounSelectBox, ReferenceCard, ReferenceCardRow } from "../../../../components";
import { Delete, FlipToFront, SdCard } from "@mui/icons-material";
import { closeConfirmationDialog, openConfirmationDialog } from "../../../../redux/slices/confirmationDialog";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import copy from "fast-copy";
import GreyAccordian from "../../../../components/greyAccordian";

interface SelectionInterface { option_name: string, option_abbr: string, reference_metadata: ReferenceCardRow[] }

export const AddUpdateAttribute: React.FC<{ children?: JSX.Element, isEdit: boolean, data: any, refetch: any, noun: any, updateNoun: (noun: SelectBoxOption | null) => void }> = (props) => {

    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const [createAttribute, createAttributeData] = useCreateAttributeMutation();
    const [updateAttribute, updateAttributeData] = useUpdateAttributeMutation();
    const [getNouns, nounD] = useLazyGetNounsQuery();
    const nounData = { ...nounD, data: nounD?.data?.data ?? [], currentData: nounD?.data?.data ?? [] };

    const [state, setState] = React.useState<{ name: string, selectedNouns: SelectBoxOption[], nouns: SelectBoxOption[], selections: SelectionInterface[] }>({
        name: "",
        selectedNouns: [],
        nouns: [],
        selections: [{ option_name: "", option_abbr: "", reference_metadata: [{ id: "", name: "" }] }]
    });
    const [rows, setRows] = React.useState<ReferenceCardRow[]>([{ id: "", name: "" }]);
    const [error, setError] = React.useState({ name: false, nouns: false, selections: false });
    const [openReferenceDialog, setOpenReferenceDialog] = React.useState<{ index: number, opt: SelectionInterface }>();

    const updateState = (key: string, value: string) => {
        setState({ ...state, [key]: value });
        setError({ ...error, [key]: !value.length })
    }

    const reorder = (list: SelectionInterface[], startIndex: number, endIndex: number) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    };

    const onDragEnd = (result: any) => {
        // dropped outside the list
        if (!result.destination) {
            return;
        }

        const selections = reorder(
            state.selections,
            result.source.index,
            result.destination.index
        );

        setState({ ...state, selections })
    }

    const updateDropDown = (selectedNouns: SelectBoxOption[]) => {
        setState({ ...state, selectedNouns: selectedNouns.filter((_: any) => _.value), nouns: selectedNouns.map((_: any) => _.value).filter(Boolean) });
    }

    const onCancelBtnClicked = () => {
        dispatch(closeDialog());
    }

    const onCreateUpdateBtnClicked = () => {
        if (state.name.length && state.nouns.length && state.selections.length) {
            if (state.selections.every(e => e.option_name === "" && e.option_abbr === "")) {
                enqueueSnackbar(`Minimum one option is needed for ${props.isEdit ? "updating" : "creating"}.`, { variant: "warning" });
            } else {
                if (props.isEdit) {
                    updateAttribute({
                        attr_name: state.name,
                        nouns: state.nouns,
                        options: state.selections.filter(e => e.option_name !== "" && e.option_abbr !== ""),
                        _id: props.data._id,
                        reference_metadata: rows
                    })
                } else {
                    createAttribute({
                        attr_name: state.name,
                        nouns: state.nouns,
                        options: state.selections.filter(e => e.option_name !== "" && e.option_abbr !== ""),
                        reference_metadata: rows
                    });
                }
            }
        } else {
            if (state.selections.length === 0) {
                enqueueSnackbar(`Minimum one option is needed for ${props.isEdit ? "updating" : "creating"}.`, { variant: "warning" });
            }
            setError({ ...error, name: !state.name.length, nouns: !state.nouns.length, selections: !state.selections.length })
        }
    }

    const updateOption = (index: number, value: string) => {
        let selections: SelectionInterface[] = copy(state.selections);
        selections[index].option_name = value;
        setState((prevState) => ({ ...prevState, selections }));
    }

    const updateAbbrevation = (index: number, value: string) => {
        let selections: SelectionInterface[] = copy(state.selections);
        selections[index].option_abbr = value;
        setState((prevState) => ({ ...prevState, selections }));
    }

    const updateReferenceData = (index: number, value: ReferenceCardRow[]) => {
        let selections: SelectionInterface[] = copy(state.selections);
        selections[index].reference_metadata = value;
        setState((prevState) => ({ ...prevState, selections }));
    }

    const addOption = () => {
        let selections: SelectionInterface[] = copy(state.selections);
        selections.push({ option_name: "", option_abbr: "", reference_metadata: [{ id: "", name: "" }] });
        setState((prevState) => ({ ...prevState, selections }));
    }

    const deleteConfirmation = (index: number, attribute: string) => {
        if (attribute.length === 0) {
            deleteRow(index);
            return false;
        }
        dispatch(openConfirmationDialog({
            title: "Are you sure?",
            body: "Do you want to delete this attribute - " + attribute + "?",
            positiveBtn: "Delete",
            negativeBtn: "Cancel",
            onOk: () => deleteRow(index),
            onNegativeBtn: () => dispatch(closeConfirmationDialog())
        }));
    }

    const deleteRow = (index: number) => {
        let selections: SelectionInterface[] = state.selections;
        selections.splice(index, 1);
        setState({ ...state, selections });
        dispatch(closeConfirmationDialog());
    }

    const openReferenceCard = (index: number, opt: SelectionInterface) => {
        setOpenReferenceDialog({ index, opt });
    }

    React.useEffect(() => {
        if (createAttributeData.isSuccess) {
            enqueueSnackbar("Attribute Created Successfully", { variant: "success" });
            props.updateNoun(props.noun)
            props.refetch({ id: props.noun?._id });
            dispatch(closeDialog());
        }
        if (createAttributeData.isError) {
            enqueueSnackbar("Oops! Something went wrong, Unable to create Attribute", { variant: "error" })
        }
        // eslint-disable-next-line
    }, [createAttributeData.status]);

    React.useEffect(() => {
        if (updateAttributeData.isSuccess) {
            enqueueSnackbar("Attribute Updated Successfully", { variant: "success" });
            props.updateNoun(props.noun)
            props.refetch({ id: props.noun?._id });
            dispatch(closeDialog());
        }
        if (updateAttributeData.isError) {
            enqueueSnackbar("Oops! Something went wrong, Unable to update Attribute", { variant: "error" })
        }
        // eslint-disable-next-line
    }, [updateAttributeData.status]);

    React.useEffect(() => {
        if (props.isEdit && props.data?._id && nounData.isSuccess) {
            let selectedNouns = nounData.data?.filter((_: any) => props?.data?.nouns?.includes(_._id))?.map((_: any) => ({ value: _._id, label: _.noun_name, ..._ }));
            setState({ ...state, name: props.data.attr_name, nouns: props.data.nouns, selections: props.data.options?.map((_: any) => ({ ..._ })), selectedNouns });
            setRows(props.data?.reference_metadata ?? [{ id: "", name: "" }]);
            dispatch(closeBackdrop());
        } else if (nounData.isFetching) {
            dispatch(openBackdrop("Fetching Nouns..."))
        }
        // eslint-disable-next-line
    }, [props.isEdit, nounData.status]);

    React.useEffect(() => {
        if (props.isEdit && props.data?._id) {
            getNouns({ page: null, pageSize: null });
        } else {
            const name = `${props?.noun?.uom_name ? `${props?.noun?.noun_name} (${props?.noun?.uom_name})` : props?.noun?.noun_name}`;
            updateDropDown([{ value: props.noun?._id ?? "", label: name ?? "", ...props.noun }])
        }
        // eslint-disable-next-line 
    }, []);

    return <>
        {/* Title */}
        <DialogTitle>
            <Typography
                variant="h6"
                color="textPrimary"
                sx={{
                    borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
                    width: "100%", fontFamily: 'htrts_medium'
                }}
            >
                {`${props.isEdit ? "Update" : "Create"} Attribute`}
            </Typography>
        </DialogTitle>

        {/* Fields */}
        <DialogContent>
            <Stack sx={{ marginTop: 1 }} spacing={2}>
                {/* Noun */}
                <NounSelectBox
                    label='Select Noun'
                    isMulti={true}
                    value={state.selectedNouns}
                    onChange={(data: SelectBoxOption[]) => updateDropDown(data)}
                    isRequired={true}
                />

                {/* Name */}
                <TextField
                    id="attribute-name"
                    sx={{ marginTop: 2 }}
                    fullWidth
                    size="small"
                    label={"Name"}
                    value={state.name}
                    required={true}
                    error={error.name}
                    helperText={error.name ? "Please enter Attribute name" : ""}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => updateState("name", e.target.value)}
                />

                <Grid item xs={12}>
                    <GreyAccordian title="Reference Card" content={
                        <ReferenceCard rows={copy(rows)} setRows={(newRows) => setRows([...newRows])} hideTitle={true} />
                    } />
                </Grid>

                {/* Options */}
                {/* Title */}
                <Box display={"flex"} justifyContent={"space-between"}>
                    <Typography gutterBottom>
                        Options
                    </Typography>
                    <Button
                        id="add-attribute-option"
                        variant={"outlined"}
                        size={"small"}
                        sx={{ marginLeft: 1 }}
                        onClick={addOption}
                    >
                        Add Option
                    </Button>
                </Box>

                {/* Selections */}
                <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="droppable-option">
                        {(provided: any, _: any) => (
                            <div ref={provided.innerRef}   {...provided.droppableProps}>
                                {state.selections?.map((opt, index) => {
                                    return <Draggable key={index} draggableId={`draggable-${index + 1}-option`} index={index}>
                                        {(provided: any, _: any) => (
                                            <div
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                key={`key${index + 1}`}
                                                id={`div-option-${index + 1}`}
                                            >
                                                <Grid
                                                    container
                                                    alignItems={"center"}
                                                    key={index}
                                                >
                                                    <Grid {...provided.dragHandleProps} sx={{ textAlign: "center" }} item xs={12} sm={1} md={1} lg={1} xl={1}>
                                                        <Tooltip title="Drag Up/Down">
                                                            <FlipToFront fontSize="small" sx={{ marginTop: 1 }} />
                                                        </Tooltip>
                                                    </Grid>
                                                    <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                                        <TextField
                                                            id={`option-${index}`}
                                                            size="small"
                                                            variant={"filled"}
                                                            fullWidth
                                                            label={`Option ${index + 1}`}
                                                            value={opt.option_name}
                                                            onChange={(e) => updateOption(index, e.target.value)}
                                                        />
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        xs={12} sm={3} md={3} lg={3} xl={3}
                                                        sx={{
                                                            paddingLeft: { xs: 0, sm: 2, md: 2, lg: 2, xl: 2 },
                                                            marginTop: { xs: 1, sm: 0, md: 0, lg: 0, xl: 0 }
                                                        }}
                                                    >
                                                        <TextField
                                                            id={`abbr-${index}`}
                                                            size="small"
                                                            variant={"filled"}
                                                            fullWidth
                                                            label={`Abbrevation ${index + 1}`}
                                                            value={opt.option_abbr}
                                                            onChange={(e) => updateAbbrevation(index, e.target.value)}
                                                        />
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        xs={12} sm={2} md={2} lg={2} xl={2}
                                                        sx={{
                                                            paddingLeft: { xs: 0, sm: 1, md: 1, lg: 1, xl: 1 },
                                                            marginTop: { xs: 1, sm: 0, md: 0, lg: 0, xl: 0 }
                                                        }}
                                                    >
                                                        <Tooltip title={"Reference Card"}>
                                                            <IconButton
                                                                id={`add-reference-for-option-${index}`}
                                                                color="info"
                                                                size="small"
                                                                onClick={(e) => openReferenceCard(index, opt)}
                                                            >
                                                                <SdCard />
                                                            </IconButton>
                                                        </Tooltip>
                                                        <IconButton
                                                            color="error"
                                                            size="small"
                                                            onClick={(e) => deleteConfirmation(index, opt.option_name)}
                                                        >
                                                            <Delete />
                                                        </IconButton>
                                                    </Grid>
                                                </Grid>
                                            </div>
                                        )}
                                    </Draggable>
                                })}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
            </Stack>
        </DialogContent>

        <DialogActions>
            {/* Add Attribute Btn */}
            <Grid container px={2} pb={1}>
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                    <LoadingButton
                        id="u-c-attribute"
                        fullWidth
                        variant="contained"
                        onClick={onCreateUpdateBtnClicked}
                        loading={createAttributeData.isLoading || updateAttributeData.isLoading}
                    >
                        {`${props.isEdit ? "Update" : "Create"}`}
                    </LoadingButton>
                </Grid>

                {/* Cancel Btn */}
                <Grid
                    item
                    xs={12} sm={6} md={6} lg={6} xl={6}
                    sx={{
                        paddingLeft: { xs: 0, sm: 2, md: 2, lg: 2, xl: 2 },
                        marginTop: { xs: 1, sm: 0, md: 0, lg: 0, xl: 0 }
                    }}
                >
                    <Button
                        fullWidth
                        variant="outlined"
                        onClick={onCancelBtnClicked}
                        disabled={createAttributeData.isLoading || updateAttributeData.isLoading}
                    >
                        Cancel
                    </Button>
                </Grid>
            </Grid>
        </DialogActions>

        <Dialog onClose={() => setOpenReferenceDialog(undefined)} open={openReferenceDialog ? true : false}>
            <Box p={2}>
                <ReferenceCard testPrefix="inmodal" rows={copy(openReferenceDialog?.opt?.reference_metadata ?? [])} setRows={(reference_metadata) => {
                    if (openReferenceDialog) {
                        setOpenReferenceDialog({ ...openReferenceDialog, opt: { ...openReferenceDialog.opt, reference_metadata: [...reference_metadata] } })
                    }
                }} />
                <Button id="save-reference" size="small" sx={{ mt: 2, float: "right" }} variant="contained" onClick={() => {
                    if (openReferenceDialog) {
                        updateReferenceData(openReferenceDialog.index, openReferenceDialog.opt.reference_metadata);
                        setOpenReferenceDialog(undefined);
                    }
                }}>Save</Button>
                <Button size="small" sx={{ mt: 2, mr: 1, float: "right" }} variant="outlined" onClick={() => {
                    setOpenReferenceDialog(undefined);
                }}>Cancel</Button>
            </Box>
        </Dialog>
    </>
}