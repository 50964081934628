import { Alert, Box, useTheme } from "@mui/material";
import React from "react";
import moment from "moment";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import { CustomDataGrid, DeletePanel, TitleHeader } from "../../../components";
import { useAppDispatch, useAppSelector } from "../../../redux";
import { useDeleteProjectMutation, useLazyGetProjectsQuery } from "../../../redux/services";
import { resetProjectSlice } from "../../../redux/slices/project";
import { AppRoutes } from "../../../router/routes";
import { DashboardTableColumn } from "../utils";
import { closeBackdrop, openBackdrop } from "../../../redux/slices/backdrop";
import { closeConfirmationDialog, openConfirmationDialog, updateConfirmationDialogPositiveBtn, updateCongirmationDialogBody } from "../../../redux/slices/confirmationDialog";
import { HasAccess } from "../../../router/authorization";
import { PERMISSIONS } from "../../../router/permission";
import { GridSortDirection, GridSortModel } from "@mui/x-data-grid-premium";
import { useStyles } from "../../documentTemplates/styles";

export const Dashboard: React.FC<{ children?: JSX.Element }> = (props) => {

    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const classes = useStyles(useTheme());
    const { enqueueSnackbar } = useSnackbar();
    const { perm } = useAppSelector(store => store.auth.userDetails);
    
    const [getAllProjects, { data: allData = { data: [], totalCount: 0 }, isFetching, isLoading, isError }] = useLazyGetProjectsQuery({ refetchOnFocus: true });
    const [deleteProjectMutation] = useDeleteProjectMutation();
    
    const [search, setSearch] = React.useState("");    
    const [paginationModel, setPaginationModel] = React.useState({ page: 0, pageSize: 10});
    const [queryOptions, setQueryOptions] = React.useState({
        sortModel: [{ field: 'sequence_id', sort: 'desc' as GridSortDirection }],
    });
    
    const { data, totalCount } = allData;
    const creatable = React.useMemo(() => HasAccess(perm, PERMISSIONS.PROJECT_CREATE), [perm]);
    const deletable = React.useMemo(() => HasAccess(perm, PERMISSIONS.PROJECT_DELETE), [perm]);
    
    const addEditProject = (data: any, isEdit: boolean = true) => {
        dispatch(resetProjectSlice())
        navigate(AppRoutes.viewProjectBasicDetail(isEdit ? data._id : "New"))
    };

    const deleteProject = (project: any) => {
        let message = `Check for dependencies before deleting the Project ${project.sequence_id}-r${project.version}`;
        dispatch(openConfirmationDialog({
            title: "Delete Operation",
            body: <DeletePanel
                message={message}
                warningMessage={""}
            />,
            positiveBtn: "Check for dependencies",
            negativeBtn: "Cancel",
            onOk: () => openDependency(project, message, false),
            onNegativeBtn: () => dispatch(closeConfirmationDialog())
        }))
    };

    const openDependency = (project: any, message: string, force: boolean) => {
        dispatch(updateCongirmationDialogBody(<DeletePanel
            message={project.associations ? project.associations.length === 0 ? 'No dependency found' : `Dependency Table of ${project?.sequence_id}-r${project.version}` : "No dependency found"}
            errorMessage={""}
            errorMessageStacks={project?.associations ?? []}
            warningMessage={""}
        />))
        dispatch(updateConfirmationDialogPositiveBtn({
            positiveBtn: "Force Delete",
            onOk: () => {
                dispatch(updateCongirmationDialogBody(<DeletePanel
                    message={"Are you sure want to force delete the Project " + project?.sequence_id + "-r" + project?.version + "?"}
                    warningMessage={"Warning: All the project contents and its associated entities will be de-linked"}
                />))
                dispatch(updateConfirmationDialogPositiveBtn({
                    positiveBtn: "Ok",
                    onOk: () => performRowDel(project, "", true)
                }))
            },
        }))
    };

    const performRowDel = async (project: any, message: string, force: boolean = false) => {
        dispatch(openBackdrop("Deleting project..."));
        let res: any = {};
        try {
            res = await deleteProjectMutation({ id: project._id, force })

            if (Object.keys(res).includes("data")) {
                enqueueSnackbar(`Deleted project Successfully!`, { variant: "success" })
                dispatch(closeConfirmationDialog());
                getAllProjects({ ...paginationModel });
            } else if (Object.keys(res).includes("error")) {
                // eslint-disable-next-line 
                throw res.error.data;
            } else {
                // eslint-disable-next-line 
                throw "Data not found"
            }
        } catch (error: any) {
            let errorMessage: string = error?.title ?? "Opps! Something went wrong, Unable to delete"
            enqueueSnackbar(errorMessage, { variant: "error" });
        } finally {
            dispatch(closeBackdrop());
        }
    };

    const giveMeRows = () => {
        if (!isLoading && !isFetching && data) {
            if (search.trim().length) {
                return data?.filter((_: any) => _?.project_name?.toLowerCase()?.includes(search.toLowerCase()) ||
                _?.sequence_id?.toLowerCase()?.includes(search.toLowerCase()) ||
                _?.tag?.tag_name?.toLowerCase()?.includes(search.toLowerCase()) ||
                _?.project_manager?.toLowerCase()?.includes(search.toLowerCase()) ||
                _?.customer_name?.toLowerCase()?.includes(search.toLowerCase()) ||
                _?.money?.cost?.toString()?.includes(search.toLowerCase()) ||
                _?.money?.currency?.toLowerCase()?.includes(search.toLowerCase()) ||
                _?.created_user?.toLowerCase()?.includes(search.toLowerCase()) ||
                moment(_?.created_time).format('lll').toString().includes(search.toLowerCase()) ||
                _?.tags_info?.filter((_: any) => _?.tag_group_name === 'Business Units')?.[0]?.tag_name?.toLowerCase()?.includes(search.toLowerCase()) ||
                _?.tags_info?.filter((_: any) => _?.tag_group_name === 'Project Category')?.[0]?.tag_name?.toLowerCase()?.includes(search.toLowerCase()) ||
                _?.tags_info?.filter((_: any) => _?.tag_group_name === 'Project Status')?.[0]?.tag_name?.toLowerCase()?.includes(search.toLowerCase()) ||
                _?.tags_info?.filter((_: any) => _?.tag_group_name === 'Project General Tag')?.[0]?.tag_name?.toLowerCase()?.includes(search.toLowerCase())
                
                ) ?? []
            }
            return data;
        } else {
            return [];
        }
    };

    const ROWS = giveMeRows();

    const handleSortModelChange = React.useCallback((sortModel: GridSortModel) => {
        // Here you save the data you need from the sort model
        setQueryOptions({ sortModel: [...sortModel] });
    }, [])

    React.useEffect(() => {
        getAllProjects({ ...paginationModel, sort_by: queryOptions?.sortModel?.[0]?.field, sort_order: queryOptions?.sortModel?.[0]?.sort });
        // eslint-disable-next-line
    }, [paginationModel, JSON.stringify(queryOptions?.sortModel)])

    return <Box sx={classes.root}>
        {/* Header */}
        <TitleHeader
            title="Projects"
            count={ROWS?.length ?? 0}
            showSearch={true}
            search={search}
            onSearchChange={setSearch}
            searchPlaceholder={"Search for Projects (only within this page)"}
            showCreateBtn={creatable}
            createBtnLabel={"Create Project"}
            onCreateBtn={() => addEditProject(null, false)}
        />

        {/* Error */}
        {isError && <Alert sx={{ mt: 2 }} severity="error">Oops! Something went wrong, Unable to fetch Projects. Try Again Later!</Alert>}


        {/* Data Drid */}
        <Box mt={2}>
            <CustomDataGrid
                id="project-list"
                // initialState={{
                //     sorting: {
                //         sortModel: [{ field: 'sequence_id', sort: 'desc' }],
                //     },
                // }}
                rows={ROWS}
                columns={DashboardTableColumn(deleteProject, addEditProject, !deletable)}
                disableVirtualization
                loading={isLoading || isFetching}
                getRowId={(row) => row._id}
                showToolbar={true}
                rowCount={totalCount}
                paginationModel={paginationModel}
                paginationMode="server"
                onPaginationModelChange={setPaginationModel}
                sortingMode="server"
                onSortModelChange={handleSortModelChange}
            />
        </Box>
    </Box>
}