import * as React from "react";
import { Viewer, Worker } from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import { highlightPlugin, Trigger, RenderHighlightsProps } from "@react-pdf-viewer/highlight";

// Import styles
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import "@react-pdf-viewer/highlight/lib/styles/index.css";
import { PDFDocumentProps, useLazyGetDocumentUrlQuery } from "../../../redux/services";
import { Alert, Avatar, Button, Chip, CircularProgress, DialogContent, DialogTitle, Stack, Typography, Tooltip } from "@mui/material";
import { useSnackbar } from "notistack";

interface IBBox {
    pageIndex: number;
    left: number;
    top: number;
    width: number;
    height: number;
}
export interface ICitation {
    document: PDFDocumentProps;
    bbox: Array<IBBox>;
}

interface IHighlighterProps {
    citation: ICitation;
    storagePrefix: string;
}

const PDFHighlighter: React.FC<IHighlighterProps> = ({ citation, storagePrefix }) => {
    const [currentCitationView, setCitationView] = React.useState(citation.bbox[0]);

    const [getDocumentUrl, docResponse] = useLazyGetDocumentUrlQuery();

    const { enqueueSnackbar } = useSnackbar();

    const defaultLayoutPluginInstance = defaultLayoutPlugin();

    const renderHighlights = (props: RenderHighlightsProps) => {
        return (
            <div>
                {citation.bbox
                    .filter((area) => area.pageIndex === props.pageIndex)
                    .map((area, idx) => (
                        <div
                            key={idx}
                            className="highlight-area"
                            style={Object.assign(
                                {},
                                {
                                    border: "1px solid red",
                                },
                                props.getCssProperties(area, props.rotation)
                            )}
                        />
                    ))}
            </div>
        )
    };

    const highlightPluginInstance = highlightPlugin({
        renderHighlights,
        trigger: Trigger.None,
    });

    React.useEffect(() => {
        if (citation.document.document_id) {
            const key = `${storagePrefix}-${citation.document.document_id}`;
            const parsed = JSON.parse((localStorage.getItem(key) || "{}"));
            const url = parsed?.[citation.document.document_id];
            if (!url) {
                getDocumentUrl({ id: citation.document.document_id })
                    .unwrap()
                    .then((res) => {
                        localStorage.setItem(key, JSON.stringify({
                            [citation.document.document_id]: res
                        }))
                    })
                    .catch((error) => {
                        console.error(`Error fetching document: ${error}`);
                        let errorMessage: string = error?.data?.title ?? "Oops! Something went wrong, Unable to fetch the document";
                        enqueueSnackbar(errorMessage, { variant: "error" });
                    })
            }
        }
        // eslint-disable-next-line
    }, [citation.document.document_id])

    React.useEffect(() => {
        highlightPluginInstance.jumpToHighlightArea(currentCitationView)
        // eslint-disable-next-line
    }, [currentCitationView])

    const parsed = JSON.parse((localStorage.getItem(`${storagePrefix}-${citation.document.document_id}`) || "{}"));
    const url = parsed?.[citation.document.document_id];

    return (

        <>
            <DialogTitle>
                <Typography
                    variant="h6"
                    color="textPrimary"
                    sx={{
                        borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
                        width: "100%",
                        fontFamily: "htrts_medium",
                    }}
                >
                    {citation.document?.filename ?? "Citation Preview"}
                </Typography>
               <Stack direction={"row"} mb={1} flexWrap={'wrap'} gap={1}>{citation.bbox.map((box, idx) => {
                    const isDisabled = JSON.stringify(currentCitationView) === JSON.stringify(box);
                    const title = citation.document?.filename ?? "Citation Preview";
                    return (
                        <Tooltip title={title}>
                            <Button disabled={isDisabled} onClick={() => setCitationView(box)}>
                                <Chip size="small" sx={{ borderRadius: 2, cursor: "pointer" }} variant="outlined" color={isDisabled ? undefined : "primary"} avatar={<Avatar>{idx + 1}</Avatar>} label={title.length >= 50 && !isDisabled ? `${title.substring(0, 50)}...` : title} />
                            </Button>
                        </Tooltip>
                    );
                })}</Stack>
            </DialogTitle>
            <DialogContent>
                <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js">
                    <div style={{ width: "100%", height: "80vh" }}>
                        {url || docResponse.isSuccess ? <Viewer
                            fileUrl={url || docResponse?.data}
                            plugins={[
                                defaultLayoutPluginInstance,
                                highlightPluginInstance,
                            ]}
                            onDocumentLoad={(e) => {
                                highlightPluginInstance.jumpToHighlightArea(currentCitationView)
                                // To get the number of pages
                                // if (e.doc.numPages > 1) {
                                //     jumpToPage(1);
                                // }
                                // setNumPages(e.doc.numPages);
                            }}
                        /> : (docResponse.isFetching || docResponse.isLoading) ? <Stack height={"100%"} width={"100%"} alignItems={"center"} justifyContent={"center"}><CircularProgress /></Stack> : <Alert severity="error">Unable to show the citations. please try again later.</Alert>}
                    </div>
                </Worker>
            </DialogContent>
        </>
    );
};

export default PDFHighlighter;
