import { Box, Button, Divider, Grid, Tab, Tabs, TextField, Typography, useTheme } from "@mui/material";
import React from "react";
import { ReactSelectOption, SelectBoxOption } from "../../interfaces";
import { useAppDispatch } from "../../redux";
import { useGetSNTemplateDropDownOptionsQuery, useLazyGetUnAssignmentValuesByTemplateQuery } from "../../redux/services";
import { updateCloseIconDimensions } from "../../redux/slices/dialog";
import { MyPaper } from "../myPaper";
import { SelectBox } from "../reactSelect";

interface IAssign {
  onAssign: (template_id: string | null, sequence_num?: string, isManual?: boolean) => void;
}

type Selection = "new" | "existing" | "manual";

const allTabs: string[] = ['generate', 'existing', 'manual'];

export const Assign: React.FC<IAssign> = ({ onAssign }) => {

  const theme = useTheme();
  const dispatch = useAppDispatch();
  const { data, isLoading, isError } = useGetSNTemplateDropDownOptionsQuery({});
  const [fetchSerialNumbers, serialNumbersResponse] = useLazyGetUnAssignmentValuesByTemplateQuery({});

  const [selectedValue, setSelectedValue] = React.useState<Selection>("new");
  const [selectedOption, setSelectedOption] = React.useState<SelectBoxOption | null>(null);
  const [selectedSerialNum, setSelectedSerialNum] = React.useState<SelectBoxOption | null>(null);
  const [serialNum, setSerialNum] = React.useState<string>('');
  const [selectedTab, updateTab] = React.useState(allTabs[0]);

  const getTemplateOptions = isLoading || isError ? [] : data || [];

  const serialNumberOptions = serialNumbersResponse?.data?.unassigned?.map((d: string) => ({ label: d, value: d })) || [];

  const resetOption = () => {
    setSelectedOption(null);
    setSelectedSerialNum(null);
  };

  const handleGenerate = () => onAssign(selectedOption?.value as string);

  const handleAssign = () => onAssign(selectedOption?.value as string, selectedSerialNum?.label);

  const handleManualAssign = () => onAssign(null, serialNum, true);

  let getSelectedOption = getTemplateOptions.find((t) => t?._id === selectedOption?._id) || null;

  React.useEffect(() => {
    if (selectedOption && selectedValue === "existing") {
      fetchSerialNumbers({ id: selectedOption?.value });
    }
    // eslint-disable-next-line
  }, [selectedOption]);

  React.useEffect(() => {
    dispatch(updateCloseIconDimensions({ top: "1px" }))
    // eslint-disable-next-line
  }, []);

  return (
    <Box>
      <Box px={2} py={1}>
        <Typography variant="body1" color="inherit">
          Assign Tag or Serial Number
        </Typography>
      </Box>

      <Divider variant="fullWidth" />

      {/* Tabs */}
      <MyPaper padding={0} borderRadius={"0px"}>
        <Tabs
          value={selectedTab}
          onChange={(e, tab) => {
            updateTab(tab)
            if (tab === allTabs[0]) {
              setSelectedValue("new");
            } else if (tab === allTabs[1]) {
              setSelectedValue("existing");
            } else if (tab === allTabs[2]) {
              setSelectedValue("manual");
            }
            resetOption();
          }}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons
          allowScrollButtonsMobile
        >
          <Tab value={allTabs[0]} label="Generate New Number" />
          <Tab value={allTabs[1]} label="Assign Existing Number" />
          <Tab value={allTabs[2]} label="Assign Manual Number" />
        </Tabs>
      </MyPaper>

      {/* Tabs Container */}
      <Box p={2} flexGrow={1}>

        {/* Generate a new serial number */}
        {selectedTab === allTabs[0] && (
          <Grid container gap={2}>
            <Grid item xs={12}>
              <SelectBox
                isMulti={false}
                value={
                  selectedValue === "new"
                    ? getSelectedOption
                    : null
                }
                placeholder={"Please select the template"}
                options={getTemplateOptions as unknown as ReactSelectOption[]}
                loading={isLoading}
                onChange={(data: SelectBoxOption | null) =>
                  setSelectedOption(data)
                }
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                onClick={handleGenerate}
                variant="contained"
                fullWidth
                disabled={
                  (!selectedOption && selectedValue === "new") ||
                  selectedValue === "existing" ||
                  selectedValue === "manual"
                }
              >
                Generate & Assign
              </Button>
            </Grid>
          </Grid>
        )}

        {/* Select from existing serial number */}
        {selectedTab === allTabs[1] && (
          <Grid container gap={2}>
            <Grid item xs={12}>
              <SelectBox
                isMulti={false}
                value={
                  selectedValue === "existing"
                    ? getSelectedOption
                    : null
                }
                placeholder={"Please select the template"}
                options={getTemplateOptions as unknown as ReactSelectOption[]}
                loading={isLoading}
                onChange={(data: SelectBoxOption | null) => {
                  setSelectedOption(data)
                  setSelectedSerialNum(null);
                }}
              />
            </Grid>
            {selectedValue === "existing" &&
              getSelectedOption && (
                <Grid item xs={12}>
                  <SelectBox
                    isMulti={false}
                    value={selectedSerialNum}
                    placeholder={"Please select the serial number"}
                    options={serialNumberOptions as unknown as ReactSelectOption[]}
                    loading={serialNumbersResponse.isFetching || serialNumbersResponse.isLoading}
                    onChange={(data: SelectBoxOption | null) =>
                      setSelectedSerialNum(data)
                    }
                  />
                </Grid>
              )}

            <Grid item xs={12}>
              <Button
                onClick={handleAssign}
                variant="contained"
                fullWidth
                disabled={
                  ((!selectedOption || !selectedSerialNum) &&
                    selectedValue === "existing") ||
                  selectedValue === "new" ||
                  selectedValue === "manual"
                }
              >
                Assign
              </Button>
            </Grid>
          </Grid>
        )}

        {/* Manual Entry of serial number */}
        {selectedTab === allTabs[2] && (
          <Grid container gap={2}>
            <Grid item xs={12}>
              <TextField
                sx={{
                  '.MuiFormLabel-colorPrimary': {
                    color: theme.palette.primary.main
                  },
                  mt: 1
                }}
                fullWidth
                size="small"
                label={"Serial Number"}
                value={serialNum}
                required={true}
                error={!serialNum.trim()}
                helperText={!serialNum.trim() ? "Please enter the serial number." : ""}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSerialNum(e.target.value)}
              />
            </Grid>

            <Grid item xs={12}>
              <Button
                onClick={handleManualAssign}
                fullWidth
                variant="contained"
                disabled={!serialNum.trim()}
              >
                Assign
              </Button>
            </Grid>
          </Grid>
        )}
      </Box>
    </Box>
  );
};
