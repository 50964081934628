import React, { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import { useAppDispatch } from "../../redux";
import { useLazyGetUsersAsOptionsQuery, useSetVisibilityMutation, Visibility } from "../../redux/services";
import { closeBackdrop, openBackdrop } from "../../redux/slices/backdrop";
import { LoadingButton } from "@mui/lab";
import { Stack, Grid, Tooltip, DialogActions, Button, DialogContent, DialogTitle, Typography, Alert } from "@mui/material";
import { SectionPaper, HighlightSelectBox, CustomDataGrid, MyPaper } from "../../components";
import { SelectBoxOption } from "../../interfaces";
import { GridActionsCellItem, GridColDef, GridRowSelectionModel } from "@mui/x-data-grid-premium";
import { DeleteOutline, Edit } from "@mui/icons-material";
import { closeDialog, openDialog } from "../../redux/slices/dialog";
import { LocalStorageKeys, parseJwt } from "../../utils";
import { closeConfirmationDialog, openConfirmationDialog } from "../../redux/slices/confirmationDialog";

const roleOptions = [{ label: "Owner", value: "owner" }, { label: "Member", value: "member" }];

const AddEditUser = ({ refetch, options, object_id, object_type, currentUserDetails }: { refetch?: () => void, options: SelectBoxOption[], object_id: string, object_type: string, currentUserDetails?: { role: SelectBoxOption | null, user: SelectBoxOption } }) => {
    const dispatch = useAppDispatch();

    const { enqueueSnackbar } = useSnackbar();

    const isEdit = !!currentUserDetails?.user;

    const [selectedUsers, setSelectedUsers] = useState<SelectBoxOption[]>([]);
    const [selectedRole, setSelectedRole] = useState<SelectBoxOption | null>(null);
    const [errors, setErrors] = useState({
        users: false,
        role: false
    });

    const [setAccess, accessResponse] = useSetVisibilityMutation();

    const onCreateUpdateBtnClicked = async () => {
        if (selectedUsers.length <= 0) {
            setErrors((prevE) => ({ ...prevE, users: true }));
            return;
        } else {
            setErrors((prevE) => ({ ...prevE, users: false }));
        }

        if (!selectedRole) {
            setErrors((prevE) => ({ ...prevE, role: true }));
            return;
        } else {
            setErrors((prevE) => ({ ...prevE, role: false }));
        }

        const roles = selectedRole?.value === roleOptions[0]?.value ? { owners: selectedUsers?.map(user => user?.value as string) } : { members: selectedUsers?.map(user => user?.value as string) };
        const revoke = isEdit ? {
            revoke: {
                user_ids: selectedUsers?.map(user => user?.value as string)
            }
        } : {};
        dispatch(openBackdrop("Assigning permissions..."));
        await setAccess({
            object_id,
            object_type,
            payload: {
                access: {
                    grant: roles,
                    ...revoke
                },
                visibility: 'private'
            }
        }).then((res: any) => {
            if (Object.keys(res).includes("data")) {
                enqueueSnackbar(`Assigned Permissions Successfully!`, { variant: "success" })
                refetch && refetch();
            } else if (Object.keys(res).includes("error")) {
                // eslint-disable-next-line 
                throw res.error.data;
            } else {
                // eslint-disable-next-line 
                throw "Data not found"
            }
        }).catch((error: any) => {
            let errorMessage: string = error?.title ?? "Oops! Something went wrong, Unable to Assign Permissions"
            enqueueSnackbar(errorMessage, { variant: "error" });
        }).finally(() => {
            dispatch(closeBackdrop());
            dispatch(closeDialog());
        })
    };

    useEffect(() => {
        if (currentUserDetails) {
            setSelectedUsers([currentUserDetails.user]);
            setSelectedRole(currentUserDetails.role)
        }
    }, [currentUserDetails])

    return (
        <>
            {/* Title */}
            <DialogTitle>
                <Typography
                    variant="h6"
                    color="textPrimary"
                    sx={{
                        borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
                        width: "100%", fontFamily: 'htrts_medium'
                    }}
                >
                    {isEdit ? "Update" : "Add"} Users
                </Typography>
            </DialogTitle>

            <DialogContent>
                <Grid container gap={2}>
                    <Grid item xs={12}>
                        <HighlightSelectBox
                            label={"Users"}
                            required={true}
                            value={selectedUsers}
                            multiple={true}
                            disabled={isEdit}
                            error={errors.users}
                            helperText={errors.users ? "Please select the users" : ""}
                            options={options}
                            onChange={(data: SelectBoxOption[]) => {
                                setSelectedUsers(data)
                            }}
                            viewOnly={isEdit}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <HighlightSelectBox
                            label={"Role"}
                            margin={"none"}
                            required={true}
                            value={selectedRole}
                            error={errors.role}
                            helperText={errors.role ? "Please select the role" : ""}
                            options={roleOptions}
                            onChange={(data: SelectBoxOption) => setSelectedRole(data)}
                        />
                    </Grid>
                </Grid>

            </DialogContent>

            <DialogActions>
                <Grid container spacing={2} px={2} pb={1}>
                    <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                        {/* Permission Access Btn */}
                        <LoadingButton
                            fullWidth
                            variant="contained"
                            onClick={onCreateUpdateBtnClicked}
                            loading={accessResponse.isLoading}
                        >
                            {`${isEdit ? "Update" : "Add"}`}
                        </LoadingButton>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                        {/* Cancel Btn */}
                        <Button
                            fullWidth
                            variant="outlined"
                            onClick={() => dispatch(closeDialog())}
                            disabled={accessResponse.isLoading}
                        >
                            Cancel
                        </Button>
                    </Grid>
                </Grid>
            </DialogActions>
        </>
    );
};

export const UserAccessManagement: React.FC<{ updatable?: boolean, refetch?: () => void, user_access: { members?: Array<string>, owners?: Array<string> }, object_id: string, object_type: string, isLoading: boolean, sequence_id: string, created_user: string }> = ({ user_access, isLoading, object_id, object_type, sequence_id, refetch, created_user, updatable = false }) => {

    const dispatch = useAppDispatch();
    const { enqueueSnackbar } = useSnackbar();
    
    const [setAccess, accessResponse] = useSetVisibilityMutation();
    let [getAllUsers, { data: userOptions = [], ...restUsersData }] = useLazyGetUsersAsOptionsQuery();    

    const [rowSelectionModel, setRowSelectionModel] = React.useState<GridRowSelectionModel>([]);
    
    const token = localStorage.getItem(LocalStorageKeys.authToken) || "";
    const data = parseJwt(token);
    const loading = restUsersData.isFetching || restUsersData.isLoading || isLoading || accessResponse.isLoading;

    const currentOwners = React.useMemo(() => [...(user_access?.owners ?? [])?.map(a => a)], [user_access]);
    const currentMembers = React.useMemo(() => [...(user_access?.members ?? [])?.map(a => a)], [user_access]);
    
    const currentUsers = React.useMemo(() => [...currentOwners, ...currentMembers], [currentOwners, currentMembers]);
    const currentOwnerData: any = React.useMemo(() => userOptions.find(user => currentOwners.includes(user?.value as string) && user?.value === data?.sub), [userOptions, currentOwners, data]);
    
    const ref = React.createRef();    

    const handleDelete = (row: any) => {
        dispatch(openConfirmationDialog({
            title: 'Are you sure?',
            body: `Do you want to revoke permission for this user '${row.name}'?`,
            positiveBtn: 'Yes',
            negativeBtn: 'No',
            onOk: () => {
                revokeUserPermission(row);
                dispatch(closeConfirmationDialog())
            },
            onNegativeBtn: () => dispatch(closeConfirmationDialog())
        }));
    };

    const revokeUserPermission = async (row: any) => {
        dispatch(openBackdrop("Revoking access..."))
        await setAccess({
            object_id, object_type, payload: {
                access: {
                    revoke: {
                        user_ids: [row?.user_id]
                    }
                }, visibility: 'private'
            }
        }).then((res: any) => {
            if (Object.keys(res).includes("data")) {
                enqueueSnackbar(`Revoked access for this user "${row.name}"!`, { variant: "success" })
                refetch && refetch();
            } else if (Object.keys(res).includes("error")) {
                // eslint-disable-next-line 
                throw res.error.data;
            } else {
                // eslint-disable-next-line 
                throw "Data not found"
            }
        }).catch((error: any) => {
            let errorMessage: string = error?.title ?? `Oops! Something went wrong, Unable to revoke access for this user "${row.name}"!`
            enqueueSnackbar(errorMessage, { variant: "error" });
        }).finally(() => {
            dispatch(closeBackdrop());
            dispatch(closeDialog());
        })
    };

    const handleBulkRevoke = () => {
        dispatch(openConfirmationDialog({
            title: 'Are you sure?',
            body: `Do you want to revoke permission for the selected users?`,
            positiveBtn: 'Yes',
            negativeBtn: 'No',
            onOk: () => {
                revokeBulkAccess();
                dispatch(closeConfirmationDialog())
            },
            onNegativeBtn: () => dispatch(closeConfirmationDialog())
        }));
    };

    const revokeBulkAccess = async () => {
        dispatch(openBackdrop("Revoking access..."))
        await setAccess({
            object_id, object_type, payload: {
                access: {
                    revoke: {
                        user_ids: rowSelectionModel as Array<string>
                    }
                }, visibility: 'private'
            }
        }).then((res: any) => {
            if (Object.keys(res).includes("data")) {
                enqueueSnackbar(`Revoked access successfully!`, { variant: "success" })
                refetch && refetch();
            } else if (Object.keys(res).includes("error")) {
                // eslint-disable-next-line 
                throw res.error.data;
            } else {
                // eslint-disable-next-line 
                throw "Data not found"
            }
        }).catch((error: any) => {
            let errorMessage: string = error?.title ?? `Oops! Something went wrong, Unable to revoke access!`
            enqueueSnackbar(errorMessage, { variant: "error" });
        }).finally(() => {
            dispatch(closeBackdrop());
            dispatch(closeDialog());
        })
    };

    const addUser = (_id?: string) => {
        const user = userOptions?.find(p => p?.value === _id);
        const role = currentOwners.includes(user?.value as string) ? roleOptions[0] : currentMembers.includes(user?.value as string) ? roleOptions[1] : null;
        dispatch(openDialog({
            title: "",
            hideNegativeBtn: true,
            hidePositiveBtn: true,
            enablePadding: false,
            body: (
                <AddEditUser
                    options={userOptions.filter(user => !currentUsers.includes(user.value as string) || _id === user?.value)}
                    object_id={object_id}
                    object_type={object_type}
                    currentUserDetails={user ? {
                        user,
                        role
                    } : undefined}
                    refetch={refetch}
                />
            ),
        }))
    };

    const handleConfirmation = (access: Visibility) => {
        dispatch(openConfirmationDialog({
            title: 'Are you sure?',
            body: <Typography>{`Do you want to make this "${sequence_id}" public? This ${object_type} will be accessed by all the users in your organization.`} <br /> <Typography variant="caption" color="error">Caution: This change cannot be undone.</Typography></Typography>,
            positiveBtn: 'Confirm, Make this public',
            negativeBtn: 'Cancel',
            onOk: () => {
                giveAccess(access);
                dispatch(closeConfirmationDialog());
            },
            onNegativeBtn: () => dispatch(closeConfirmationDialog())
        }));
    };

    const giveAccess = async (visibility: Visibility) => {
        dispatch(openBackdrop(`Making this ${sequence_id}! ${visibility}...`))
        await setAccess({ object_id, object_type, payload: { visibility } }).then((res: any) => {
            if (Object.keys(res).includes("data")) {
                enqueueSnackbar(`Made this ${sequence_id}! ${visibility}`, { variant: "success" })
                refetch && refetch();
            } else if (Object.keys(res).includes("error")) {
                // eslint-disable-next-line 
                throw res.error.data;
            } else {
                // eslint-disable-next-line 
                throw "Data not found"
            }
        }).catch((error: any) => {
            let errorMessage: string = error?.title ?? `Oops! Something went wrong, Unable to make this ${sequence_id}! ${visibility}`
            enqueueSnackbar(errorMessage, { variant: "error" });
        }).finally(() => {
            dispatch(closeBackdrop());
            dispatch(closeDialog());
        })
    };

    const rows = React.useMemo(() => userOptions.filter((user) => currentUsers.includes(user.value as string)).map((user: SelectBoxOption & { name?: string }) => ({ ...user, role: currentOwners.includes(user.value as string) && created_user === user?.name ? { label: "Created Owner" } : currentOwners.includes(user.value as string) ? roleOptions[0] : roleOptions[1] })), [userOptions, currentUsers, currentOwners, created_user]);

    const Columns = React.useMemo((): GridColDef[] => {
        let col: GridColDef[] = [
            {
                field: "name",
                width: 250,
                headerName: "User Name",
                valueGetter: (params) => params?.row?.name
            },
            {
                field: "role",
                width: 250,
                headerName: "Role",
                valueGetter: (params) => params?.row?.role?.label
            }
        ];

        if (currentOwners.includes(data?.sub) && updatable) {
            col = [{
                field: "actions",
                type: "actions",
                headerName: "Actions",
                width: 140,
                cellClassName: "actions",
                getActions: ({ row }: any) => {
                    return [
                        <GridActionsCellItem
                            icon={<Tooltip title={`Edit ${row?.name}`}><Edit /></Tooltip>}
                            label="Edit"
                            className="textPrimary"
                            onClick={() => addUser(row?.user_id)}
                            color="primary"
                            disabled={currentOwnerData?.name === row?.name || created_user === row?.name}
                        />,
                        <GridActionsCellItem
                            icon={<Tooltip title={`Revoke ${row?.name}`}><DeleteOutline /></Tooltip>}
                            label="Revoke"
                            className="textPrimary"
                            onClick={() => handleDelete(row)}
                            color="error"
                            disabled={currentOwnerData?.name === row?.name || created_user === row?.name}
                        />
                    ];
                },
            }, ...col]
        }

        return col;
        // eslint-disable-next-line
    }, [rows]);

    React.useEffect(() => {
        // fetchTemplates();
        getAllUsers(null);
        // eslint-disable-next-line
    }, []);


    return (
        <Stack spacing={2} ref={ref}>
            {/* Main Content */}
            {user_access ?
                <SectionPaper title={"Users"} primaryBtn={currentOwners.includes(data?.sub) && updatable ? {
                    title: "Add User",
                    onClick: () => addUser(),
                    variant: "contained",
                    color: "primary",
                    disabled: loading || rowSelectionModel.length > 0
                } : undefined} renderButtons={currentOwners.includes(data?.sub) && updatable? [
                    <LoadingButton
                        variant="outlined"
                        onClick={() => handleConfirmation("public")}
                        disabled={rowSelectionModel.length > 0}
                        loading={
                            loading
                        }
                    >
                        Make Public
                    </LoadingButton>,
                    <LoadingButton
                        variant="contained"
                        onClick={handleBulkRevoke}
                        disabled={rowSelectionModel.length <= 0}
                        loading={
                            loading
                        }
                    >
                        Revoke Access
                    </LoadingButton>
                ] : []}>
                    <CustomDataGrid
                        id={`${object_type ? `${object_type}-` : ""}user-access-list`}
                        rows={rows}
                        checkboxSelection={currentOwners.includes(data?.sub) && updatable}
                        onRowSelectionModelChange={(newRowSelectionModel) =>
                            setRowSelectionModel(newRowSelectionModel)
                        }
                        isRowSelectable={(params) => params?.row?.name !== created_user}
                        columns={Columns}
                        loading={loading}
                        getRowId={(row) => row.user_id}
                        showToolbar={true}
                    />
                </SectionPaper>
                : <MyPaper><Alert severity="info">{
                    `This is a public ${object_type} so everyone can access it.`
                }</Alert></MyPaper>}
        </Stack>
    );
};
