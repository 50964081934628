import * as React from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { SelectBoxOption } from '../../interfaces';
import { Typography } from '@mui/material';

export const RowRadioButtonsGroup: React.FC<{ label?: string, options: SelectBoxOption[], value: string | number, onChange: any, required?: boolean, error?: boolean, helperText?: string }> = ({
    label = "", options = [], value = "", onChange = () => false, required = false, error = false, helperText = ""
}) => {
    return (
        <FormControl>
            {label && <FormLabel id="row-radio-buttons-group-label">{`${label} ${required ? "*" : ""}`}</FormLabel>}
            <RadioGroup
                row
                value={value}
                onChange={onChange}
                aria-labelledby="row-radio-buttons-group-label"
                name="row-radio-buttons-group"
            >
                {options.map((opt, key) => {
                    return <FormControlLabel key={key} value={opt.value} control={<Radio />} label={opt.label} />
                })}
            </RadioGroup>
            {helperText && <Typography color={error ? "error" : "primary"} variant='caption' >{helperText}</Typography>}
        </FormControl>
    );
}