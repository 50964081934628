import { Cancel, Search } from "@mui/icons-material";
import { Box, Button, CircularProgress, Collapse, IconButton, List, ListItemButton, ListItemIcon, ListItemText, Stack, TextField, Theme, Typography, useTheme } from "@mui/material";
import React, { useState } from "react";
import { Location, matchPath, useLocation, useNavigate } from "react-router-dom";
import { RightVector } from "../../images";
import { SecondaryNavBars } from "../../interfaces";

const useStyles = (theme: Theme) => ({
    headerText: {
        borderBottom: `1px solid ${theme.palette.divider}`,
    },
    headerTextTitle: {
        paddingLeft: theme.spacing(2),
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
    }
})

export const SimpleSideNavBar: React.FC<{
    children?: JSX.Element, navBars: SecondaryNavBars[], variant?: "whitecard" | "grey",
    title?: string, showAddBtn?: boolean, onAddBtnClick?: any,
    showSearch?: boolean, onClick?: (navBar: SecondaryNavBars) => void, selected?: string[], avoidNavigate?: boolean,
    shouldAddStyle?: boolean,
    locationState?: Location["state"]
}> = ({ variant = "grey", onClick = (nav) => false, avoidNavigate = false, selected = [], shouldAddStyle = true, ...props }) => {

    const navigate = useNavigate();
    const location = useLocation();
    const classes = useStyles(useTheme());

    const [expand, setExpand] = useState<null | string>(null);
    const [subExpand, setSubExpand] = useState<null | string>(null);
    const [search, setSearch] = useState<string>("");
    const [toggleSearch, updateSearchToggle] = useState<boolean>(false);

    const onSearchIconClicked = () => {
        updateSearchToggle(!toggleSearch)
    }

    React.useEffect(() => {
        if (!expand) {
            for (let i = 0; i < props.navBars.length; i++) {
                const nav = props.navBars[i];
                let canIBreak = false;
                let subNav = nav?.subNav ?? [];
                for (let j = 0; j < subNav.length ?? 0; j++) {
                    const sub = subNav[j];
                    canIBreak = matchPath(location.pathname, sub.path) ? true
                        : matchPath(location.pathname + location.search.replaceAll('%20', ' '), sub.path) ? true : false;
                    if (canIBreak) {
                        break;
                    }
                }

                if (canIBreak) {
                    setExpand(expand === nav.id ? null : nav.id);
                    break;
                }
            }
        }
        // eslint-disable-next-line
    }, [location])

    return <Box sx={{
        height: "calc(100% - 6px)",
        overflow: "auto",
        background: (theme) => theme.palette.background[variant === "whitecard" ? "paper" : "default"],
        boxShadow: variant === "whitecard" ? `rgba(99, 99, 99, 0.2) 0px 2px 8px 0px` : `unset`,
        borderRadius: 2,
    }} id="nav">
        {/* Header */}
        {props.title && <Stack sx={classes.headerText}>
            {/* Header */}
            <Stack sx={classes.headerTextTitle} direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
                <Typography sx={{ fontFamily: 'htrts_bold' }} variant="body2" >{props.title}</Typography>
                {props.showAddBtn && <Button onClick={props.onAddBtnClick} variant="outlined" sx={{ width: "fit-content", height: "20px", minWidth: "unset", marginRight: 1 }} >+</Button>}
                {props.showSearch && <IconButton size="small" onClick={onSearchIconClicked}>
                    {!toggleSearch && <Search fontSize="small" />}
                    {toggleSearch && <Cancel fontSize="small" />}
                </IconButton>}
            </Stack>

            {/* Search Text Field */}
            {toggleSearch && <TextField
                sx={{ paddingX: 1, paddingBottom: 1 }}
                variant="standard"
                placeholder="Search"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                size={"small"}
                fullWidth
            />}
        </Stack>}


        {/* List */}
        <List component="nav" dense={shouldAddStyle}>
            {props.navBars?.filter(_ => props.showSearch ? search.length > 0 ? _.title.toLowerCase().includes(search.toLowerCase().trim()) : true : true).map((nav, key) => {

                const isSelected = avoidNavigate ? nav?.subNav?.find((s) => s.id === selected[0]) !== undefined ? true
                    : selected.includes(nav.id)
                    : matchPath(location.pathname, nav.path) ? true
                        : matchPath(location.pathname + location.search.replaceAll("%20", " "), nav.path) ? true
                            : location.pathname + location.search.replaceAll('%20', ' ') === nav.path;

                return <React.Fragment key={key}>
                    <ListItemButton key={key} sx={shouldAddStyle ? {
                        // background: (theme) => matchPath(location.pathname, nav.path) ? theme.palette.primary.light : "",
                        marginX: 1,
                        padding: "0px",
                        paddingX: "4px",
                        borderRadius: 1,
                        overflowWrap: 'anywhere',
                        backgroundColor: (theme) => isSelected ? theme.palette.mode === 'light' ? '#D9C4FE80' : '#F2F3F81A' : '',
                        '&:hover': {
                            backgroundColor: (theme) => theme.palette.mode === 'light' ? '#D9C4FE80' : '#F2F3F81A',
                            '.MuiListItemIcon-root, .MuiTypography-root, .MuiSvgIcon-root': { color: (theme) => theme.palette.mode === 'light' ? theme.palette.primary.main : theme.palette.common.white }
                        },
                        marginBottom: 1
                    } : {}}
                        disabled={nav.disabled}
                        onClick={(e) => {
                            if (nav?.subNav?.length) {
                                setExpand(expand === nav.id ? null : nav.id);
                            } else {
                                if (avoidNavigate) {
                                    onClick(nav);
                                } else {
                                    navigate(nav.path, {
                                        state: props?.locationState
                                    });
                                }
                            }
                        }}
                    >
                        {/* Nav Icon */}
                        {nav.icon && <ListItemIcon sx={{
                            minWidth: "36px",
                            ".MuiSvgIcon-root": {
                                color: (theme) => theme.palette.mode === 'light' ?
                                    isSelected ? theme.palette.primary.main : '#637381'
                                    : isSelected ? theme.palette.common.white : '#7C7F90'
                            }

                        }}>
                            {nav.icon}
                        </ListItemIcon>}

                        {/* Nav Title */}
                        <ListItemText
                            primaryTypographyProps={{
                                variant: "body2",
                                color: (theme) => theme.palette.mode === 'light' ?
                                    isSelected ? theme.palette.primary.main : '#637381'
                                    : isSelected ? theme.palette.common.white : '#7C7F90'
                            }}
                            primary={<Stack direction={"row"} alignItems={"center"}>
                                {nav?.title}
                                {nav?.isBeta && <img style={{ marginLeft: "8px" }} src={'/beta.gif'} alt='In Beta' />}
                            </Stack>}
                        />

                        {/* Expand/ Collapse */}
                        {nav?.subNav?.length && !nav.loading && <>
                            <RightVector sx={{
                                rotate: expand === nav.id ? "90deg" : "",
                                transition: 'all 0.5s',
                                fontSize: 12,
                                color: (theme) => theme.palette.mode === 'light' ?
                                    isSelected ? theme.palette.primary.main : '#637381'
                                    : isSelected ? theme.palette.common.white : '#7C7F90'
                            }} />
                        </>}

                        {/* Loading */}
                        {nav?.loading && <CircularProgress size={15} />}
                    </ListItemButton>
                    <Collapse in={expand === nav.id} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            {nav?.subNav?.map((subNav, subNavKey) => {

                                const isSelectedSub = avoidNavigate ?
                                    selected.includes(subNav.id) : matchPath(location.pathname, subNav.path) ? true
                                        : matchPath(location.pathname + location.search.replaceAll('%20', ' '), subNav.path) ? true :
                                            location.pathname + location.search.replaceAll('%20', ' ') === subNav.path;

                                return <React.Fragment key={subNavKey}>
                                    <ListItemButton key={subNavKey} sx={{
                                        ml: "34px",
                                        mr: 1,
                                        padding: "0px",
                                        paddingX: "4px",
                                        borderRadius: 1,
                                        overflowWrap: 'anywhere',
                                        backgroundColor: (theme) => isSelectedSub ? theme.palette.mode === 'light' ? '#D9C4FE80' : '#F2F3F81A' : '',
                                        '&:hover': {
                                            backgroundColor: (theme) => theme.palette.mode === 'light' ? '#D9C4FE80' : '#F2F3F81A',
                                            '.MuiListItemIcon-root, .MuiTypography-root, .MuiSvgIcon-root': { color: (theme) => theme.palette.mode === 'light' ? theme.palette.primary.main : theme.palette.common.white }
                                        },
                                        marginBottom: 1
                                    }}
                                        disabled={subNav.disabled}
                                        onClick={(e) => {
                                            if (subNav?.subNav?.length) {
                                                setSubExpand(expand === subNav.id ? null : subNav.id);
                                            } else {
                                                if (avoidNavigate) {
                                                    onClick(subNav);
                                                } else {
                                                    navigate(subNav.path, {
                                                        state: props?.locationState
                                                    });
                                                }
                                            }
                                        }}
                                    >
                                        {/* Nav Icon */}
                                        {subNav.icon && <ListItemIcon sx={{
                                            minWidth: "36px",
                                            ".MuiSvgIcon-root": {
                                                color: (theme) => theme.palette.mode === 'light' ?
                                                    isSelectedSub ? theme.palette.primary.main : '#637381'
                                                    : isSelectedSub ? theme.palette.common.white : '#7C7F90'
                                            }
                                        }}>
                                            {subNav.icon}
                                        </ListItemIcon>}

                                        {/* Nav Title */}
                                        <ListItemText
                                            primaryTypographyProps={{
                                                variant: "body2",
                                                color: (theme) => theme.palette.mode === 'light' ?
                                                    isSelectedSub ? theme.palette.primary.main : '#637381'
                                                    : isSelectedSub ? theme.palette.common.white : '#7C7F90'
                                            }}
                                            primary={subNav?.title}
                                        />

                                        {/* Expand/ Collapse */}
                                        {subNav?.subNav?.length && !subNav.loading && <>
                                            <RightVector sx={{
                                                rotate: expand === subNav.id ? "90deg" : "",
                                                transition: 'all 0.5s',
                                                fontSize: 12,
                                                color: (theme) => theme.palette.mode === 'light' ?
                                                    isSelectedSub ? theme.palette.primary.main : '#637381'
                                                    : isSelectedSub ? theme.palette.common.white : '#7C7F90'
                                            }} />
                                        </>}

                                        {/* Loading */}
                                        {subNav?.loading && <CircularProgress size={15} />}
                                    </ListItemButton>
                                    <Collapse in={subExpand === subNav.id} timeout="auto" unmountOnExit>
                                        <List component="div" disablePadding>
                                            {subNav?.subNav?.map((subSubNav, subSubNavKey) => <ListItemButton key={subSubNavKey} sx={{
                                                ml: "34px",
                                                mr: 1,
                                                padding: "0px",
                                                paddingX: "4px",
                                                borderRadius: 1,
                                                overflowWrap: 'anywhere',
                                                '&:hover': {
                                                    background: (theme) => theme.palette.primary.light,
                                                    border: (theme) => `1px solid ${theme.palette.primary.dark}`
                                                },
                                                marginBottom: 1
                                            }}
                                                disabled={subSubNav.disabled}
                                                onClick={(e) => navigate(subSubNav.path, {
                                                    state: props?.locationState
                                                })}
                                                selected={matchPath(location.pathname, subSubNav.path) ? true
                                                    : matchPath(location.pathname + location.search.replaceAll('%20', ' '), subSubNav.path) ? true : false}
                                            >
                                                {/* Nav Icon */}
                                                {subSubNav.icon && <ListItemIcon sx={{ minWidth: "36px", color: "black" }}>
                                                    {subSubNav.icon}
                                                </ListItemIcon>}

                                                {/* Nav Title */}
                                                <ListItemText
                                                    primaryTypographyProps={{ variant: "body2" }}
                                                    primary={subSubNav?.title}
                                                />
                                            </ListItemButton>
                                            )}
                                        </List>
                                    </Collapse>
                                </React.Fragment>
                            })}
                        </List>
                    </Collapse>
                </React.Fragment>

            })}
        </List>
    </Box>
}