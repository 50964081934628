import React from "react";
import { useNavigate } from "react-router-dom";
import { AppNavBars } from "../App.appNavbar";
import { AppAuth } from "../App.auth";
import { useAppSelector } from "../redux";
import { Root } from "../screens";
import { LocalStorageKeys } from "../utils";
import { setup } from "../utils/timeout";
import { HasAccess } from "./authorization";

const PrivateRoute: React.FC<{ path: string; children: JSX.Element, permission: any[], hideNavbar?: boolean, enablePadding?: boolean }> = ({
  path,
  children,
  permission = [],
  hideNavbar = false,
  enablePadding = true
}) => {

  const navigate = useNavigate();
  const { perm } = useAppSelector((store) => store.auth.userDetails);

  const hasPrevilage = React.useMemo(() => HasAccess(perm ?? {}, permission), [perm, permission])

  const isAuthenticated = localStorage.getItem(LocalStorageKeys.authToken) ? true : false;

  React.useEffect(() => {
    if (isAuthenticated) {
      setup(navigate);
    }
    //  eslint-disable-next-line
  }, []);

  const component = isAuthenticated && hasPrevilage ? (
    children
  ) : (
    <Root state={{ path, from: window.location.pathname + window.location.search, hasPrevilage }}/>
  );

  return (
    <>
      <AppAuth>
        {!hideNavbar
          ? <AppNavBars enablePadding={enablePadding}>
            {component}
          </AppNavBars>
          : component
        }
      </AppAuth>
    </>
  );
};

export default PrivateRoute;