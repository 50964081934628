import { LoadingButton } from "@mui/lab";
import { Box, Button, Grid, Stack, TextField } from "@mui/material";
import copy from "fast-copy";
import { useSnackbar } from "notistack";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { BreadScrumHeader, HighlightSelectBox, ImageUpload, Margin, MyPaper, Notes, SectionPaper, SideNote } from "../../../components";
import { CutSheet } from "../../../components/templateConfigComponents/cutSheet";
import { SelectBoxOption } from "../../../interfaces";
import { useAppDispatch, useAppSelector } from "../../../redux";
import { useDeleteAttachmentMutation, useUploadAttachmentMutation } from "../../../redux/services";
import { useUpdateDocumentTemplateMutation, useUploadTemplateLogoMutation } from "../../../redux/services/documentTemplate";
import { closeBackdrop, openBackdrop } from "../../../redux/slices/backdrop";
import {
    Alignment, Template, TemplateErrorType, cutSheetHeaderInterface, marginInterface, notesTemplate,
    updateCutSheetHeader, updateFooterNotes, updateHeaderFooterBasicDetails, updateHeaderNotes, updateLeftNotes, updateMargin,
    updateOverallLogoState, updateRightNotes
} from "../../../redux/slices/documentTemplate";
import { Attachment } from "../../../redux/slices/material";
import { AppRoutes } from "../../../router/routes";
import { getImageDimension } from "../../../utils";
import { orientationOptions, paperSizeOptions } from "../../documentTemplates/dashboard/addEditTemplate";
import { initialErrorState } from "./utils";

export const ConfigurePanel: React.FC<{ children?: JSX.Element }> = () => {

    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { id = "" } = useParams();
    const { enqueueSnackbar } = useSnackbar();
    const { documentTemplate } = useAppSelector((store) => store);
    const template = documentTemplate.headerFooterTemplate;

    const [updateDocumentTemplate, updateResponse] = useUpdateDocumentTemplateMutation();
    const [postImage, postImageData] = useUploadAttachmentMutation();
    const [uploadLogo] = useUploadTemplateLogoMutation();
    const [deleteAttachment, deleteResponse] = useDeleteAttachmentMutation();

    const [triggerValidation, setTriggerValidation] = React.useState(false);

    const [errors, setErrors] = React.useState<TemplateErrorType>(initialErrorState());

    const [marginOffsets, setMarginOffsets] = React.useState({
        top_offset: template.margin?.top_offset,
        bottom_offset: template.margin?.bottom_offset,
        width_offset: template.margin?.width_offset,
    });

    const [marginOffErrors, setMarginErrors] = React.useState({
        top_offset: errors.margin.top_offset,
        bottom_offset: errors.margin.bottom_offset,
        width_offset: errors.margin.width_offset,
    });

    const validateData = (temp: Template) => {
        let result = copy(errors);
        let isValid = true;

        if (temp.logo.src) {
            if (!temp.logo.vertical?.value) {
                result.logo.yPositionError = true;
                isValid = false;
            } else {
                result.logo.yPositionError = false;
            }
            if (!temp.logo.horizontal?.value) {
                result.logo.xPositionError = true;
                isValid = false;
            } else {
                result.logo.xPositionError = false;
            }
            if (!temp.logo.width) {
                result.logo.widthError = true;
                isValid = false;
            } else {
                result.logo.widthError = false;
            }
            if (!temp.logo.height) {
                result.logo.heightError = true;
                isValid = false;
            } else {
                result.logo.heightError = false;
            }
        }

        temp.notes.header.notes?.forEach((n, i: number) => {
            if (!result.header[i]) {
                return;
            }
            if (n.show && !n.content) {
                result.header[i].contentError = true;
                isValid = false;
            } else {
                result.header[i].contentError = false;
            }
            if (n.content && !n.xPosition?.value) {
                result.header[i].xPositionError = true;
                isValid = false;
            } else {
                result.header[i].xPositionError = false;
            }
            if (n.content && !n.yPosition?.value) {
                result.header[i].yPositionError = true;
                isValid = false;
            } else {
                result.header[i].yPositionError = false;
            }
        });

        temp.footers.notes?.forEach((n, i: number) => {
            if (!result.footer[i]) {
                return;
            }
            if (n.show && !n.content) {
                result.footer[i].contentError = true;
                isValid = false;
            } else {
                result.footer[i].contentError = false;
            }
            if (n.content && !n.xPosition?.value) {
                result.footer[i].xPositionError = true;
                isValid = false;
            } else {
                result.footer[i].xPositionError = false;
            }
            if (n.content && !n.yPosition?.value) {
                result.footer[i].yPositionError = true;
                isValid = false;
            } else {
                result.footer[i].yPositionError = false;
            }
        });

        let availableHeaders = [
            temp.cutSheetHeader.materialId,
            temp.cutSheetHeader.materialDescription,
            temp.cutSheetHeader.materialManufactureNo,
            temp.cutSheetHeader.manufacture,
            temp.cutSheetHeader.serialNum,
        ];

        if (
            availableHeaders.some((h) => h === true) &&
            !temp.cutSheetHeader.alignment?.horizontal?.value
        ) {
            result.cutsheet.xPositionError = true;
            isValid = false;
        } else {
            result.cutsheet.xPositionError = false;
        }

        if (
            availableHeaders.some((h) => h === true) &&
            !temp.cutSheetHeader.alignment?.vertical?.value
        ) {
            result.cutsheet.yPositionError = true;
            isValid = false;
        } else {
            result.cutsheet.yPositionError = false;
        }

        if (
            temp.cutSheetHeader?.isActive &&
            availableHeaders.every((h) => h === false)
        ) {
            result.cutsheet.selectionError = true;
            isValid = false;
        } else {
            result.cutsheet.selectionError = false;
        }

        if (temp.margin?.isActive) {
            if (
                temp.margin.showTopLine &&
                (Number(marginOffsets.top_offset) < 5 ||
                    Number(marginOffsets.top_offset) > 15 ||
                    typeof Number(marginOffsets.top_offset) !== "number")
            ) {
                result.margin.top_offset = true;
                isValid = false;
            } else {
                result.margin.top_offset = false;
            }
            if (
                temp.margin.showBottomLine &&
                (Number(marginOffsets.bottom_offset) < 5 ||
                    Number(marginOffsets.bottom_offset) > 15 ||
                    typeof Number(marginOffsets.bottom_offset) !== "number")
            ) {
                result.margin.bottom_offset = true;
                isValid = false;
            } else {
                result.margin.bottom_offset = false;
            }
            if (
                (temp.margin.showLeftLine || temp.margin.showRightLine) &&
                (Number(marginOffsets.width_offset) < 5 ||
                    Number(marginOffsets.width_offset) > 15 ||
                    typeof Number(marginOffsets.width_offset) !== "number")
            ) {
                result.margin.width_offset = true;
                isValid = false;
            } else {
                result.margin.width_offset = false;
            }
        }

        if (temp.paginationFormat.format && temp.paginationFormat.isActive) {
            if (!temp.paginationFormat.xPosition?.value) {
                result.pagination.xPositionError = true;
                isValid = false;
            } else {
                result.pagination.xPositionError = false;
            }

            if (!temp.paginationFormat.yPosition?.value) {
                result.pagination.yPositionError = true;
                isValid = false;
            } else {
                result.pagination.yPositionError = false;
            }
        }

        if (temp.notes.left.isActive && !temp.notes.left.notes) {
            result.left.contentError = true;
            isValid = false;
        } else {
            result.left.contentError = false;
        }

        if (
            (temp.notes.left.isActive ||
                temp.notes.left.notes) &&
            !temp.notes.left.alignment.horizontal?.value
        ) {
            result.left.xPositionError = true;
            isValid = false;
        } else {
            result.left.xPositionError = false;
        }

        if (
            (temp.notes.left.isActive ||
                temp.notes.left.notes) &&
            !temp.notes.left.alignment.vertical?.value
        ) {
            result.left.yPositionError = true;
            isValid = false;
        } else {
            result.left.yPositionError = false;
        }

        if (temp.notes.right.isActive && !temp.notes.right.notes) {
            result.right.contentError = true;
            isValid = false;
        } else {
            result.right.contentError = false;
        }

        if (
            (temp.notes.right.isActive ||
                temp.notes.right.notes) &&
            !temp.notes.right.alignment.horizontal?.value
        ) {
            result.right.xPositionError = true;
            isValid = false;
        } else {
            result.right.xPositionError = false;
        }

        if (
            (temp.notes.right.isActive ||
                temp.notes.right.notes) &&
            !temp.notes.right.alignment.vertical?.value
        ) {
            result.right.yPositionError = true;
            isValid = false;
        } else {
            result.right.yPositionError = false;
        }

        setErrors(result);
        return isValid;
    };

    const onLogoUploaded = (file: File) => {
        if (file) {
            dispatch(updateOverallLogoState({ ...template.logo, src: file, width: 0, height: 0 }));
        } else {
            dispatch(updateOverallLogoState({ ...template.logo, isActive: false }))
        }
    };

    const onDelete = (file: File | Attachment) => {
        if ((file as Attachment)?._id) {
            deleteAttachment({ object_id: id, object_type: "hfTemplate", attachment_id: (file as Attachment)?._id });
        } else {
            dispatch(updateOverallLogoState({ ...template.logo, src: null, width: 0, height: 0, isActive: false, horizontal: null, vertical: null }));
        }
    };

    const onHeaderNoteChange = (note: string, index: number) => {
        let newNotes = copy(template.notes.header.notes);
        newNotes[index].content = note;
        dispatch(updateHeaderNotes({ key: "notes", value: newNotes }));
    };

    const onHeaderNotesStatusChange = (status: boolean, index: number) => {
        let newNotes = copy(template.notes.header.notes);
        newNotes[index].show = status;
        dispatch(updateHeaderNotes({ key: "notes", value: newNotes }));
    };

    const onHeaderPositionChange = (position: SelectBoxOption, index: number, key: keyof notesTemplate) => {
        let newNotes = copy(template.notes.header.notes);
        newNotes[index][key] = position as never;
        dispatch(updateHeaderNotes({ key: "notes", value: newNotes }));
    };

    const onFooterNoteChange = (note: string, index: number) => {
        let newNotes = copy(template.footers.notes);
        newNotes[index].content = note;
        dispatch(updateFooterNotes({ key: "notes", value: newNotes }));
    };

    const onFooterNotesStatusChange = (status: boolean, index: number) => {
        let newNotes = copy(template.footers.notes);
        newNotes[index].show = status;
        dispatch(updateFooterNotes({ key: "notes", value: newNotes }));
    };

    const onFooterPositionChange = (position: SelectBoxOption, index: number, key: keyof notesTemplate) => {
        let newNotes = copy(template.footers.notes);
        newNotes[index][key] = position as never;
        dispatch(updateFooterNotes({ key: "notes", value: newNotes }));
    };

    const onLeftNoteChange = (note: string) => dispatch(updateLeftNotes({ key: "notes", value: note }));

    const onLeftPositionChange = (position: SelectBoxOption, key: keyof Alignment) => dispatch(updateLeftNotes({ key: "alignment", value: { ...template.notes.left.alignment, [key]: position } }));

    const onRightNoteChange = (note: string) => dispatch(updateRightNotes({ key: "notes", value: note }));

    const onRightPositionChange = (position: SelectBoxOption, key: keyof Alignment) => dispatch(updateRightNotes({ key: "alignment", value: { ...template.notes.right.alignment, [key]: position } }));

    const updateMarginData = (key: keyof marginInterface, value: any) => dispatch(updateMargin({ key, value }));

    const updateOffset = (key: keyof marginInterface, value: any) => setMarginOffsets((prev) => ({ ...prev, [key]: value }));

    const updateCutSheetData = (key: keyof cutSheetHeaderInterface, value: any) => dispatch(updateCutSheetHeader({ key, value }));

    const onCutSheetPositionChange = (position: SelectBoxOption, key: keyof Alignment) => dispatch(updateCutSheetHeader({ key: "alignment", value: { ...template.cutSheetHeader.alignment, [key]: position } }));

    const updateTemplateLogoStatus = async (data: any) => {
        if (data?.length > 0) {
            if (data?.[0]?.url) {
                await uploadLogo({ data: data?.[0], url: data?.[0]?.url, file: template.logo.src })
                    .unwrap()
                    .catch((err: any) => {
                        console.error("upload err on template: ", err);
                    }).finally(async () => {
                        await updateDocumentTemplate({ id, payload: { ...template, attachments: [{ _id: data?.[0]?._id }] } })
                            .unwrap()
                            .then((res) => {
                                if (res?._id && res?.message) {
                                    enqueueSnackbar(res?.message ?? "Template Updated Successfully...", { variant: "success" });
                                }
                            }).catch((error) => {
                                enqueueSnackbar(error?.data?.title ?? "Error updating template...", { variant: "error" });
                            }).finally(() => {
                                dispatch(closeBackdrop());
                            });
                    });
            }
        }
    };

    const uploadLogoToS3 = (id: string, sequence_id: string, version: number) => {
        dispatch(openBackdrop("Uploading Logo..."));
        postImage({ object_id: id, object_type: "hfTemplate", payload: [{ _id: id, file_name: (template?.logo?.src as File)?.name }] }).unwrap().then((res: any) => {
            updateTemplateLogoStatus(res);
        }).catch((err: any) => {
            console.error('err:', err)
            dispatch(closeBackdrop());
        });
    };

    React.useEffect(() => {
        if (postImageData.isError) {
            enqueueSnackbar("Oops! Something went wrong, Unable to upload Template Logo", { variant: "error" });
        }
        // eslint-disable-next-line
    }, [postImageData.status]);

    React.useEffect(() => {
        if (template?.logo?.src && !template.logo.height && !template.logo.width) {
            let src = (template?.logo?.src as Attachment)?.url || URL.createObjectURL(template?.logo?.src as File);
            if (src) {
                getImageDimension(src, (dimension) => {
                    dispatch(updateOverallLogoState({ ...template.logo, width: dimension.width, height: dimension.height }));
                });
            }
        }
        // eslint-disable-next-line
    }, [template?.logo?.src]);

    React.useEffect(() => {
        if (deleteResponse.isLoading) {
            dispatch(openBackdrop("Deleting template logo..."))
            return;
        }

        if (deleteResponse.isError) {
            enqueueSnackbar((deleteResponse?.error as any)?.data ?? "Unable to delete template logo.", { variant: "error" })
        }

        if (deleteResponse.isSuccess) {
            enqueueSnackbar("Template logo deleted successfully.", { variant: "success" });
            dispatch(updateOverallLogoState({ ...template.logo, src: null, width: 0, height: 0, isActive: false, horizontal: null, vertical: null }));
        }

        dispatch(closeBackdrop());
        // eslint-disable-next-line
    }, [deleteResponse.status]);

    React.useEffect(() => {
        if (marginOffsets.top_offset >= 5 && marginOffsets.top_offset <= 15) {
            updateMarginData("top_offset", marginOffsets.top_offset);
            setMarginErrors({ ...marginOffErrors, top_offset: false });
        } else {
            setMarginErrors((errs) => ({ ...errs, top_offset: true }));
        }
        // eslint-disable-next-line
    }, [marginOffsets.top_offset]);

    React.useEffect(() => {
        if (marginOffsets.bottom_offset >= 5 && marginOffsets.bottom_offset <= 15) {
            updateMarginData("bottom_offset", marginOffsets.bottom_offset);
            setMarginErrors({ ...marginOffErrors, bottom_offset: false });
        } else {
            setMarginErrors((errs) => ({ ...errs, bottom_offset: true }));
        }
        // eslint-disable-next-line
    }, [marginOffsets.bottom_offset]);

    React.useEffect(() => {
        if (marginOffsets.width_offset >= 5 && marginOffsets.width_offset <= 15) {
            updateMarginData("width_offset", marginOffsets.width_offset);
            setMarginErrors({ ...marginOffErrors, width_offset: false });
        } else {
            setMarginErrors((errs) => ({ ...errs, width_offset: true }));
        }
        // eslint-disable-next-line
    }, [marginOffsets.width_offset]);

    React.useEffect(() => {
        setMarginOffsets({
            top_offset: template.margin.top_offset,
            bottom_offset: template.margin.bottom_offset,
            width_offset: template.margin.width_offset,
        });
    }, [template.margin]);

    React.useEffect(() => {
        setMarginErrors({
            top_offset: errors.margin.top_offset,
            bottom_offset: errors.margin.bottom_offset,
            width_offset: errors.margin.width_offset,
        });
    }, [errors.margin]);

    React.useEffect(() => {
        if (triggerValidation) {
            validateData(template);
        }
        // eslint-disable-next-line
    }, [template]);

    const isLoading = updateResponse.isLoading || postImageData.isLoading;

    return (<Stack spacing={1} position={"relative"} height={"100%"}>

        {/* Back Button */}
        <Box bgcolor={"white"} position={"sticky"} top={1} left={0} right={0} zIndex={99}>
            <BreadScrumHeader
                openWebNav={false}
                toogleWebNav={() => false}
                toogleMobileNav={() => false}
                history={[{ label: "Header Footer Template", onClick: () => navigate(AppRoutes.viewTemplateList) }]}
                showBackBtn={true}
                onBackBtnClick={() => navigate(AppRoutes.viewTemplateList)}
                hideMenuBtn={true}
                currentPath={template?.sequence_id ?? "New"}
            />
        </Box>

        <Stack spacing={1}>
            {/* Basic Details */}
            <SectionPaper title="Template Details" px={1} py={0} contentPadding={1}>
                <Grid container spacing={2} py={1}>
                    {/* Template Name */}
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <TextField
                            fullWidth
                            label={"Template Name"}
                            value={template?.name}
                            size="small"
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => dispatch(updateHeaderFooterBasicDetails({ key: "name", value: e.target.value as never }))}
                        />
                    </Grid>

                    {/* Paper Size */}
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <HighlightSelectBox
                            label={"Paper Size"}
                            value={paperSizeOptions.find((p) => (template?.paper_size || "") === p?.value) as SelectBoxOption}
                            options={paperSizeOptions}
                            loading={false}
                            margin="none"
                            onChange={(data: any) => dispatch(updateHeaderFooterBasicDetails({ key: "paper_size", value: data.value as never }))}
                            error={false}
                        />
                    </Grid>

                    {/* Tag */}
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <HighlightSelectBox
                            label={"Orientation"}
                            value={orientationOptions.find((or) => (template?.orientation || "") === or?.value) as SelectBoxOption}
                            options={orientationOptions}
                            loading={false}
                            margin="none"
                            onChange={(data: any) => dispatch(updateHeaderFooterBasicDetails({ key: "orientation", value: data.value as never }))}
                            error={false}
                        />
                    </Grid>
                </Grid>
            </SectionPaper>

            {/* Ṃargin */}
            {!template.margin?.hide && (
                <MyPaper padding={0}>
                    <Margin
                        title="Margin"
                        showSwitch={true}
                        switchStatus={template.margin?.isActive}
                        onSwitchChanged={(isChecked: boolean) => dispatch(updateMargin({ key: "isActive", value: isChecked }))}
                        margin={{ ...template.margin, ...marginOffsets }}
                        updateMargin={updateMarginData}
                        updateOffset={updateOffset}
                        error={marginOffErrors}
                    />
                </MyPaper>
            )}

            {/* Image Upload */}
            {!template.logo?.hide && (
                <MyPaper padding={0}>
                    <ImageUpload
                        title="Logo"
                        showSwitch={true}
                        switchStatus={template.logo?.isActive}
                        onSwitchChanged={(isChecked) => dispatch(updateOverallLogoState({ ...template.logo, isActive: isChecked }))}
                        image={template?.logo?.src ?? null}
                        onImageUpload={onLogoUploaded}
                        onDelete={onDelete}
                        showBtn={true}
                        btnName={"Upload Logo"}
                        showHorizontalPosition={true}
                        horizontalPosition={template.logo?.horizontal}
                        onHorizontalPositionChange={(position) => dispatch(updateOverallLogoState({ ...template.logo, horizontal: position }))}
                        showVerticalPosition={true}
                        verticalPosition={template.logo?.vertical}
                        onVerticalPositionChange={(position) => dispatch(updateOverallLogoState({ ...template.logo, vertical: position }))}
                        height={template.logo?.height}
                        width={template.logo?.width}
                        onHeightChange={(height) => {
                            // change width according to aspect ratio
                            if (template?.logo?.src) {
                                let src = (template?.logo?.src as Attachment)?.url || URL.createObjectURL(template?.logo?.src as File);
                                getImageDimension(src, (_, aspectRatio) => {
                                    dispatch(updateOverallLogoState({ ...template.logo, width: Math.ceil(height * aspectRatio), height: height }))
                                });
                            }
                        }}
                        onWidthChange={(width) => {
                            // change height according to aspect ratio
                            if (template?.logo?.src) {
                                let src = (template?.logo?.src as Attachment)?.url || URL.createObjectURL(template?.logo?.src as File);
                                getImageDimension(src, (_, aspectRatio) => {
                                    dispatch(updateOverallLogoState({ ...template.logo, width: width, height: Math.ceil(width / aspectRatio) }))
                                });
                            }
                        }}
                        error={errors.logo}
                    />
                </MyPaper>
            )}

            {/* Header Style */}
            {!template.notes?.hide && !template.notes?.header?.hide && (
                <MyPaper padding={0}>
                    <Notes
                        title="Header Style"
                        showSwitch={true}
                        switchStatus={template.notes?.header?.isActive}
                        onSwitchChanged={(isChecked: boolean) =>
                            dispatch(
                                updateHeaderNotes({
                                    key: "isActive",
                                    value: isChecked,
                                })
                            )
                        }
                        notes={template.notes?.header?.notes?.map((_, index) => ({
                            note: _.content,
                            status: _.show,
                            onStatusChange: (isChecked: boolean) =>
                                onHeaderNotesStatusChange(isChecked, index),
                            onNoteChange: (note: string) => onHeaderNoteChange(note, index),
                            placeholder: "Note " + (index + 1),
                            xPosition: _.xPosition,
                            yPosition: _.yPosition,
                            onPositionChange: (position, key) =>
                                onHeaderPositionChange(position, index, key),
                        }))}
                        error={errors.header}
                    />
                </MyPaper>
            )}

            {/* Footer Style */}
            {!template.notes?.hide && !template.footers?.hide && (
                <MyPaper padding={0}>
                    <Notes
                        title="Footer Style"
                        showSwitch={true}
                        switchStatus={template.footers?.isActive}
                        onSwitchChanged={(isChecked: boolean) =>
                            dispatch(
                                updateFooterNotes({
                                    key: "isActive",
                                    value: isChecked,
                                })
                            )
                        }
                        notes={template.footers?.notes?.map((_, index) => ({
                            note: _.content,
                            status: _.show,
                            onStatusChange: (isChecked: boolean) =>
                                onFooterNotesStatusChange(isChecked, index),
                            onNoteChange: (note: string) => onFooterNoteChange(note, index),
                            placeholder: "Note " + (index + 1),
                            xPosition: _.xPosition,
                            yPosition: _.yPosition,
                            onPositionChange: (position, key) =>
                                onFooterPositionChange(position, index, key),
                        }))}
                        error={errors.footer}
                    />
                </MyPaper>
            )}

            {/* Left Annotation Style */}
            {!template.notes?.hide && !template.notes?.left?.hide && (
                <MyPaper padding={0}>
                    <SideNote
                        title="Left Annotation Style"
                        showSwitch={true}
                        switchStatus={template.notes?.left?.isActive}
                        onSwitchChanged={(isChecked: boolean) =>
                            dispatch(
                                updateLeftNotes({
                                    key: "isActive",
                                    value: isChecked,
                                })
                            )
                        }
                        sideNote={{
                            note: template.notes?.left?.notes,
                            onNoteChange: (note: string) => onLeftNoteChange(note),
                            placeholder: "Enter Side Note",
                            alignment: template.notes?.left?.alignment,
                            onPositionChange: (position, key) =>
                                onLeftPositionChange(position, key),
                        }}
                        error={errors.left}
                    />
                </MyPaper>
            )}

            {/* Right Annotation Style */}
            {!template.notes?.hide && !template.notes?.right?.hide && (
                <MyPaper padding={0}>
                    <SideNote
                        title="Right Annotation Style"
                        showSwitch={true}
                        switchStatus={template.notes?.right?.isActive}
                        onSwitchChanged={(isChecked: boolean) =>
                            dispatch(
                                updateRightNotes({
                                    key: "isActive",
                                    value: isChecked,
                                })
                            )
                        }
                        sideNote={{
                            note: template.notes?.right?.notes,
                            onNoteChange: (note: string) => onRightNoteChange(note),
                            placeholder: "Enter Side Note",
                            alignment: template.notes?.right?.alignment,
                            onPositionChange: (position, key) =>
                                onRightPositionChange(position, key),
                        }}
                        error={errors.right}
                    />
                </MyPaper>
            )}

            {/* Cut Sheet */}
            {!template.cutSheetHeader?.hide && (
                <MyPaper padding={0}>
                    <CutSheet
                        title="Cutsheet Header"
                        showSwitch={true}
                        switchStatus={template.cutSheetHeader?.isActive}
                        onSwitchChanged={(isChecked: boolean) =>
                            dispatch(
                                updateCutSheetHeader({
                                    key: "isActive",
                                    value: isChecked,
                                })
                            )
                        }
                        cutSheet={template?.cutSheetHeader ?? {}}
                        updateCut={updateCutSheetData}
                        onPositionChange={(position, key) =>
                            onCutSheetPositionChange(position, key)
                        }
                        error={errors.cutsheet}
                    />
                </MyPaper>
            )}

        </Stack>

        {/* Action Section */}
        <Box bgcolor={"white"} position={"sticky"} bottom={1} left={0} right={0} zIndex={99}>
            <MyPaper padding={0}>
                <Stack
                    direction={"row"}
                    justifyContent={"end"}
                    spacing={2}
                    sx={{
                        background: (theme) => theme.palette.background.paper,
                        padding: 1,
                        mb: 2
                    }}
                >
                    <LoadingButton
                        variant="contained"
                        onClick={async () => {
                            let isValid = validateData(template);
                            if (!isValid) {
                                enqueueSnackbar(`Please enter the required fields`, {
                                    variant: "error",
                                });
                                setTriggerValidation(true);
                            } else if ((template.logo.src as File)?.name && isValid) {
                                uploadLogoToS3(id, template?.sequence_id ?? "", template?.version ?? 0);
                            } else if (isValid) {
                                await updateDocumentTemplate({ id, payload: template }).unwrap().then((res) => {
                                    if (res?._id && res?.message) {
                                        enqueueSnackbar(res?.message ?? "Template Updated Successfully...", { variant: "success" });
                                    }
                                }).catch((error) => {
                                    enqueueSnackbar(error?.data?.title ?? "Error updating template...", {
                                        variant: "error",
                                    });
                                });
                            }
                        }}
                        loading={isLoading}
                    >
                        {id === "" || id === "isNew" ? "Save" : "Update"}
                    </LoadingButton>
                    <Button
                        variant="outlined"
                        onClick={() => navigate(AppRoutes.viewTemplateList)}
                        disabled={isLoading}
                    >
                        Cancel
                    </Button>
                </Stack>
            </MyPaper>
        </Box>
    </Stack>);
};
