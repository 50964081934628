import React from "react";
import {
    Box,
    Stack,
    Button,
    Grid,
    Alert,
    CircularProgress,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useDispatch } from "react-redux";
import { useSnackbar } from "notistack";
import {
    useLazyGetBOMsQuery,
    useCreateWorkbookMutation,
    useLazyGetWorkbookByIdQuery,
    useUpdateWorkbookMutation,
    useLazyGetAllBOMsQuery,
    useLazyGetBOMSparePartsTagsQuery,
    useLazyGetChildStatusTagsQuery,
    useLazyGetAllWorkbookItemsQuery,
    useGetWorkbookProcessingStatusQuery
} from "../../../redux/services";
import { ReactSelectOption, SelectBoxOption } from "../../../interfaces";
import { HighlightSelectBox, Layout, MyPaper, SectionPaper, SelectBox, TextField } from "../../../components";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { closeBackdrop, openBackdrop } from "../../../redux/slices/backdrop";
import { ModuleMapper } from "../../../components/moduleMapper";
import { getObjectType } from "../../bomReport/addEditBOMReport";
import { typeOptions } from "./utils";
import { ActionTable } from "./actionTable";
import { useAppSelector } from "../../../redux";
import { HasAccess } from "../../../router/authorization";
import { PERMISSIONS } from "../../../router/permission";
interface IState {
    name: string;
    description: string;
    id: SelectBoxOption | null;
    bom_parent: SelectBoxOption | null;
    type: SelectBoxOption | null;
    objectType: string | null;
    objectId: string | null;
}

interface Error {
    name: boolean;
    id: boolean;
    type: boolean;
    bom_parent: boolean;
    objectType: boolean;
    objectId: boolean;
}

export const RenderWorkBookForm: React.FC<{
    setSequenceId: (value: string) => void;
}> = ({ setSequenceId }) => {

    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const { p_id = "", id = "New" } = useParams();
    const location = useLocation();
    const navigate = useNavigate();
    const { perm } = useAppSelector(store => store.auth.userDetails);

    const { data: workbookItemsStatus } =
        useGetWorkbookProcessingStatusQuery(id, {
            pollingInterval: 3000,
            skip: id === "New"
        })
    const [getBOMs, getBOMsData] = useLazyGetBOMsQuery();
    const [getAllBOMs, getBOMsResponse] = useLazyGetAllBOMsQuery();
    const [getSparePartsCategories, sparePartCategoryData] = useLazyGetBOMSparePartsTagsQuery();
    const [getMaterialChildStatus, materialStatusData] = useLazyGetChildStatusTagsQuery();
    const [getBomChildStatus, bomStatusData] = useLazyGetChildStatusTagsQuery();
    const [createWorkbook, createWorkbookResponse] = useCreateWorkbookMutation();
    const [updateWorkbook, updateWorkbookResponse] = useUpdateWorkbookMutation();
    const [getWorkbookById, workbookResponse] = useLazyGetWorkbookByIdQuery();
    const [getAllItems, itemsRes] = useLazyGetAllWorkbookItemsQuery();
    
    const [state, setState] = React.useState<IState>({ name: "", description: "", id: null, bom_parent: null, type: null, objectType: null, objectId: null, });
    const [error, setError] = React.useState<Error>({ name: false, id: false, type: false, bom_parent: false, objectType: false, objectId: false, });
    const [paginationModel, setPaginationModel] = React.useState({ page: 0, pageSize: 10 });
    const [calculatedHeight, setHeight] = React.useState(0);

    const isEdit = id !== "New";
    const isMaterialModule = location.pathname?.includes("material");

    const creatable = React.useMemo(() => HasAccess(perm, PERMISSIONS.MATERIAL_CREATE), [perm]);
    const updatable = React.useMemo(() => HasAccess(perm, PERMISSIONS.MATERIAL_UPDATE), [perm]);

    const isViewOnly = isEdit ? !updatable : !creatable;

    const loadOptions = async (search: string, loadedOptions: any) => {
        try {
            let page = Math.round(loadedOptions.length / 50);
            let options = await getBOMs({ page, pageSize: 50 }).unwrap();
            return { options: options.data?.filter((op) => !op.released).map((_: any) => ({ ..._, value: _._id, label: `${_.sequence_id}-r${_.version} (${_.short_description})` })), hasMore: options.data.length === 50 }
        } catch (error) {
            console.error('error:', error)
            return { options: [], hasMore: false }
        }
    };

    const updateState = (key: keyof IState, value: IState[keyof IState], ignoreError?: boolean) => {
        setState((prevState) => ({ ...prevState, [key]: value }));
        if (!ignoreError) {
            setError((prevError) => ({ ...prevError, [key]: !value }));
        }
    };

    const fetchWorkbook = async (id: string) => {
        dispatch(openBackdrop("Fetching Workbook data..."));

        try {
            getMaterialChildStatus("material");
            getBomChildStatus("bom");
            getSparePartsCategories(null)
            await getWorkbookById({ id }).unwrap().then(async (res) => {
                setSequenceId(res?.sequence_id ?? "...");
                setError((prevError) => ({ ...prevError, objectType: false, objectId: false }))
            }).catch((error: any) => {
                console.error(`Error loading workbook: ${error}`);
                enqueueSnackbar(
                    (error?.data as any)?.title ?? error?.title ?? "Fetching Workbook Failed",
                    { variant: "error" }
                );
            }).finally(() => {
                dispatch(closeBackdrop());
            })
        } catch (error: any) {
            console.error(`Error loading workbook: ${error}`);
            enqueueSnackbar(
                (error?.data as any)?.title ?? error?.title ?? "Fetching Workbook Failed",
                { variant: "error" }
            );
        }
    };

    const refetchItems = () => {
        if (workbookResponse?.data?._id) {
            getAllItems({ id: workbookResponse?.data?._id, ...paginationModel });
        }
    };

    const validate = () => {
        let isValid = true;
        if (!state.name.trim()) {
            setError((prevError) => ({
                ...prevError,
                name: true
            }))
            isValid = false;
        }

        if (!state.type) {
            setError((prevError) => ({
                ...prevError,
                type: true
            }))
            isValid = false;
        }

        if (state.type?.value === "bom" && !state.bom_parent) {
            setError((prevError) => ({
                ...prevError,
                bom_parent: true
            }))
            isValid = false;
        }

        if (!state.objectId && !isMaterialModule) {
            setError((prevError) => ({
                ...prevError,
                objectId: true
            }))
            isValid = false;
        }

        if (!state.objectType && !isMaterialModule) {
            setError((prevError) => ({
                ...prevError,
                objectType: true
            }))
            isValid = false;
        }

        return isValid;
    };

    const onCreateUpdateBtnClicked = async () => {
        let valid = validate();
        if (valid) {
            let payload: Record<string, any> = {
                bom_parent_id: state?.bom_parent?._id,
                name: state?.name,
                description: state?.description,
                type: state?.type?.value,
                links: p_id ? [
                    { object_ids: [state.objectId ?? p_id], object_type: state.objectType ?? getObjectType(location) },
                ] : undefined,
            };

            if (isEdit) {
                dispatch(openBackdrop("Updating workbook..."))
                await updateWorkbook({ id, payload }).unwrap().then((res) => {
                    enqueueSnackbar("Workbook Updated Successfully", {
                        variant: "success",
                    });
                    navigate(-1);
                }).catch((error: any) => {
                    enqueueSnackbar(
                        error?.data?.title ?? "Oops! Something went wrong, Unable to update Workbook",
                        {
                            variant: "error",
                        }
                    );
                    console.error(`unable to update the workbook ${error}`)
                }).finally(() => {
                    dispatch(closeBackdrop());
                });
            } else {
                dispatch(openBackdrop("Creating workbook..."))
                await createWorkbook(payload).unwrap().then((res) => {
                    if (res?._id) {
                        enqueueSnackbar("Workbook Created Successfully", {
                            variant: "success",
                        });
                        navigate(-1);
                    }
                }).catch((error: any) => {
                    enqueueSnackbar(
                        error?.data?.title ?? "Oops! Something went wrong, Unable to create Workbook",
                        {
                            variant: "error",
                        }
                    );
                    console.error(`unable to create the workbook ${error}`)
                }).finally(() => {
                    dispatch(closeBackdrop());
                });
            }
        }
    };

    const loadBOMForPrePopulation = async (data: any) => {
        try {
            const bomOptions = await getAllBOMs({});
            let selectedBOM = bomOptions.data?.find((bom: { _id: string }) => bom?._id === data?.bom_parent_id) ?? null;
            if (selectedBOM) {
                selectedBOM = { ...selectedBOM, value: selectedBOM._id, label: `${selectedBOM.sequence_id ?? "..."}-r${selectedBOM?.version ?? "0"} (${selectedBOM?.short_description ?? ""})` }
            }
            setState((prevState) => ({ ...prevState, bom_parent: selectedBOM }))
            setError((prevError) => ({ ...prevError, bom_parent: !selectedBOM }))
        } catch (error) {
            console.error(`Unable to load the bom: ${error}`)
        }
    };

    React.useEffect(() => {
        if (id !== "New") {
            fetchWorkbook(id);
        } else if (p_id) {
            setState((prevState) => ({
                ...prevState,
                objectId: p_id ?? null,
                objectType: getObjectType(location) ?? null
            }));
        }
        // eslint-disable-next-line
    }, []);

    React.useEffect(() => {
        if (isEdit) {
            let lType = typeOptions.find(t => t.value === workbookResponse?.data?.type) ?? null;

            let currentAssociation = workbookResponse?.data?.associations?.at(-1);

            setState((prevState) => ({
                ...prevState,
                name: workbookResponse?.data?.name,
                description: workbookResponse?.data?.description ?? "",
                type: lType,
                objectId: currentAssociation?.object_details?.at(-1)?._id ?? null,
                objectType: currentAssociation?.object_type ?? null
            }));
            if (!state.bom_parent && lType?.value === typeOptions[1].value) {
                loadBOMForPrePopulation(workbookResponse?.data);
            }
        } else if (p_id) {
            setState((prevState) => ({
                ...prevState,
                objectId: p_id ?? null,
                objectType: getObjectType(location) ?? null
            }));
        }
        // eslint-disable-next-line
    }, [workbookResponse?.status]);

    React.useEffect(() => {
        const breadcrumbs = document.getElementById("breadcrumbs");
        const topNavBar = document.getElementById("topNavBar");
        const handleResize = () => {
            setHeight((breadcrumbs?.clientHeight ?? 0) + (topNavBar?.clientHeight ?? 0) + 35);
        };

        window.addEventListener('resize', handleResize);

        if (!calculatedHeight) {
            setHeight((breadcrumbs?.clientHeight ?? 0) + (topNavBar?.clientHeight ?? 0) + 35);
        }

        return () => {
            window.removeEventListener('resize', handleResize);
        };
        // eslint-disable-next-line
    }, []);

    const isBOMFetching = getBOMsData.isLoading || getBOMsData.isFetching || getBOMsResponse.isFetching || getBOMsResponse.isLoading;

    const isCompleted = workbookResponse?.data?.status === "completed";

    return (
        <Stack sx={{ height: `calc(100vh - ${calculatedHeight}px)`, overflow: "auto" }}>
            <Stack height={"100%"} overflow={"hidden"} spacing={2}>
                <Stack>
                    <SectionPaper title={`Basic Details`} renderButtons={!isViewOnly ? [
                        <Button
                            variant="outlined"
                            onClick={() => navigate(-1)}
                            disabled={
                                createWorkbookResponse.isLoading || updateWorkbookResponse.isLoading
                            }
                        >
                            Cancel
                        </Button>,
                        <LoadingButton
                            id="u-c-btn"
                            variant="contained"
                            onClick={onCreateUpdateBtnClicked}
                            disabled={isCompleted}
                            loading={
                                createWorkbookResponse.isLoading || updateWorkbookResponse.isLoading || workbookItemsStatus?.processing === true
                            }
                        >
                            {`${isEdit ? "Update" : "Create"}`}
                        </LoadingButton>
                    ] : []}>
                        <>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={6} lg={3}>
                                    {/* Name */}
                                    <TextField
                                        id="workbook-name"
                                        label={"Name"}
                                        value={state.name}
                                        required={true}
                                        fullWidth
                                        size={"small"}
                                        variant="outlined"
                                        placeholder={"Enter Workbook Name *"}
                                        error={error.name}
                                        helperText={error.name ? "Please enter the name" : ""}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                            updateState("name", e.target.value)
                                        }
                                        viewOnly={isViewOnly}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} lg={3}>
                                    {/* Description */}
                                    <TextField
                                        id="workbook-description"
                                        label={"Description"}
                                        value={state.description}
                                        required={false}
                                        fullWidth
                                        size={"small"}
                                        variant="outlined"
                                        placeholder={"Enter Workbook Description"}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                            updateState("description", e.target.value)
                                        }
                                        viewOnly={isViewOnly}
                                    />
                                </Grid>

                                {/* Type */}
                                <Grid item xs={12} sm={6} lg={3}>
                                    <HighlightSelectBox
                                        id="workbook-type"
                                        margin={"none"}
                                        label={"Select Type *"}
                                        onChange={(data: SelectBoxOption) => {
                                            updateState("type", typeOptions.find(type => type?.value === data?.value) || null)
                                            updateState("bom_parent", null, true)
                                        }}
                                        options={typeOptions}
                                        value={
                                            typeOptions.find((type: SelectBoxOption) => type?.value === state.type?.value) ||
                                            null
                                        }
                                        disabled={(itemsRes?.data?.data ?? []).length > 0}
                                        error={error.type}
                                        helperText={
                                            error.type ? "Please select the type" : ""
                                        }
                                        viewOnly={isViewOnly}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={6} lg={3}>
                                    {/* BOM */}
                                    <SelectBox
                                        id={state.bom_parent?._id ?? "bom-options"}
                                        isPaginate
                                        loadOptions={loadOptions}
                                        label={isViewOnly ? "BOM" : ""}
                                        placeholder={"Select Parent BOM"}
                                        value={state.bom_parent}
                                        loading={isBOMFetching}
                                        options={[]}
                                        onChange={(data: ReactSelectOption) => updateState("bom_parent", data)}
                                        isDisabled={state?.type?.value !== typeOptions[1].value || !state?.type?.value || (itemsRes?.data?.data ?? []).length > 0}
                                        error={error.bom_parent}
                                        helperText={"Please select the parent bom"}
                                        isMenuFixed
                                        viewOnly={isViewOnly}
                                    />
                                </Grid>
                            </Grid>
                        </>
                    </SectionPaper>
                </Stack>

                {false &&
                    <Stack mt={3}>
                        <SectionPaper title={"Module Mapping"}>
                            <ModuleMapper
                                associations={[]}
                                onOptionChange={(object_type, object_id) => {
                                    setState((prevState) => ({ ...prevState, objectType: object_type as string, objectId: object_id as string }))
                                    setError((prevError) => ({ ...prevError, objectType: !object_type, objectId: !object_id }))
                                }}
                                errors={{ moduleError: error.objectType, moduleOptionError: error.objectId }}
                                optionsValue={{ moduleValue: state.objectType as string, moduleOptionValue: state.objectId as string }}
                                shouldSkip={!isEdit}
                            />
                        </SectionPaper>
                    </Stack>
                }

                {isEdit && (workbookItemsStatus?.processing === false || !workbookItemsStatus) &&
                    <Stack flexGrow={1} overflow={"auto"}>
                        <ActionTable
                            isViewOnly={isViewOnly}
                            refetch={fetchWorkbook}
                            workbookData={workbookResponse?.data}
                            loading={createWorkbookResponse.isLoading || updateWorkbookResponse.isLoading}
                            removeChildren={!state.bom_parent?._id}
                            sparePartCategoryData={sparePartCategoryData}
                            bomStatusData={bomStatusData}
                            materialStatusData={materialStatusData}
                            itemsRes={itemsRes}
                            refetchItems={refetchItems}
                            paginationData={{
                                paginationModel,
                                setPaginationModel
                            }}
                        />
                    </Stack>
                }

                {workbookItemsStatus?.processing === true && <MyPaper>
                        <Alert 
                            severity="info" 
                            iconMapping={{
                                info: <CircularProgress size={"1em"} color="info" />,
                            }}>
                                Processing workbook...
                        </Alert>
                    </MyPaper>
                }
            </Stack>
        </Stack>
    );
};

export const AddEditWorkBook = () => {

    const navigate = useNavigate();

    const location: any = useLocation();

    const paths = location?.state?.paths ?? [];

    const [sequenceId, setSequenceId] = React.useState("...");

    return (
        <Box padding={0.5}>
            <Layout
                history={[
                    ...paths.map((_: any) => {
                        return { label: _.title, onClick: () => navigate(_.path) };
                    }),
                ]}
                currentPath={`${sequenceId}`}
                navBars={[]}
                mainPanel={<RenderWorkBookForm setSequenceId={setSequenceId} />}
                sideNavVariant={"whiteboard"}
                otherBreadscrumProps={{
                    hideMenuBtn: paths.length > 0,
                    showBackBtn: paths.length > 0,
                }}
                locationState={paths}
                onBackBtnClick={paths[0]?.path ? () => navigate(paths[0]?.path) : undefined}
            />
        </Box>
    );
};