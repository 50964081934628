import { createSlice } from '@reduxjs/toolkit';
import copy from 'fast-copy';
import { Association, SelectBoxOption } from '../../../interfaces';
import { AccessPayload } from '../../services';
import { Attachment } from '../material';

export interface ProductBasicDetails { product: SelectBoxOption | null, description: string, family: SelectBoxOption | null, group: SelectBoxOption | null, businessUnit: SelectBoxOption | null, productManager: string, status: SelectBoxOption | null, external_ref_id: string }

export interface ProductTags { tags: SelectBoxOption[] }

interface ProductBasicDetailsError { product: boolean, description: boolean, family: boolean, group: boolean, businessUnit: boolean, productManager: boolean, status: boolean, external_ref_id: boolean }

interface ProductTagsError { tags: boolean }

export interface ProductSliceInterface {
    // Basic Details
    basic_details: ProductBasicDetails,

    // product Tags
    product_tags: ProductTags,

    notes: string,

    product_images: any[],

    // Links
    links?: { object_type: string, object_ids: string[] }[],

    unlinks?: { object_type: string, object_ids: string[] }[],

    attachments: Attachment[],

    associations: Association[],

    sequenceId?: string,
    version?: number

    error: {
        // Basic Details
        basic_details: ProductBasicDetailsError,

        // product Tags
        product_tags: ProductTagsError
    }
}

const initialState: ProductSliceInterface = {
    basic_details: { product: null, description: "", family: null, group: null, businessUnit: null, productManager: "", status: null, external_ref_id: "" },
    product_tags: { tags: [] },
    notes: "",
    product_images: [],
    attachments: [],
    associations: [],
    error: {
        basic_details: { product: false, description: false, family: false, group: false, businessUnit: false, productManager: false, status: false, external_ref_id: false },
        product_tags: { tags: false }
    }
}

export const productSlice = createSlice({
    name: 'product',
    initialState,
    reducers: {
        // To Slice by key value pair
        updateProductSliceByKeyValue(state, { payload }: { payload: { parentKey: string, childKey: string, value: any } }) {
            let newState: any = copy(state);
            newState.error[payload.parentKey][payload.childKey] = payload.value ? false : true;
            newState[payload.parentKey][payload.childKey] = payload.value;
            return { ...state, ...newState }
        },

        // To Update notes
        updateNotes(state, { payload }: { payload: { notes: string } }) {
            let newState: any = copy(state);
            newState.notes = payload;
            return { ...state, ...newState };
        },

        // To Update photos
        updatePhotos(state, { payload }: { payload: { product_images: any[] } }) {
            let { product_images } = payload;
            return { ...state, product_images };
        },

        // To update errors
        updateErrors(state, { payload }: { payload: { parentKey: keyof ProductSliceInterface, childKey: keyof ProductBasicDetails | keyof ProductTags }[] }) {
            let product: ProductSliceInterface = copy(state);
            let error = product.error;
            payload.forEach((key) => {
                if (key.parentKey === 'basic_details') {
                    if (!product.basic_details[key.childKey as keyof ProductBasicDetails]) {
                        error.basic_details[key.childKey as keyof ProductBasicDetails] = true;
                    }
                }

                if (key.parentKey === 'product_tags') {
                    if (key.childKey === 'tags') {
                        if (product.product_tags[key.childKey as keyof ProductTags].length === 0) {
                            error.product_tags[key.childKey as keyof ProductTags] = true;
                        }
                    } else {
                        if (!product.product_tags[key.childKey as keyof ProductTags]) {
                            error.product_tags[key.childKey as keyof ProductTags] = true;
                        }
                    }
                }

            })

            return { ...state, error }
        },

        // To reset all values to initialValue
        resetProductSlice(state) {
            return { ...state, ...initialState }
        },

        // To Bulk Update the slice
        updateProductSlice(state, { payload }: {
            payload: {
                // Basic Details
                basic_details: ProductBasicDetails,

                // product Tags
                product_tags: ProductTags,

                notes: string,

                product_images: any[],
                attachments: Attachment[],
                associations: Association[],
                sequenceId: string,
                version: number,
                user_access: AccessPayload
            }
        }) {
            return { ...state, ...payload }
        }
    }
});

export const { updateProductSliceByKeyValue, updateProductSlice, resetProductSlice, updateErrors, updateNotes, updatePhotos } = productSlice.actions;
export default productSlice.reducer;