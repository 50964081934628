import React from "react";
import { useAppSelector } from "../../../redux";
import { AddSelectionPage } from "./addSelectionPage";
import { SelOptMapping } from "./selOptMapping";
import { Summary } from "./summary";

export const RightPanel: React.FC<{ children?: JSX.Element }> = (props) => {

    const { tab } = useAppSelector((store) => store.pmp);

    switch (tab) {
        case 1:
            return <AddSelectionPage />

        case 2:
            return <SelOptMapping />

        case 3:
            return <Summary />

        default:
            return <AddSelectionPage />

    }
}