import { useTheme } from '@mui/material';
import React from 'react';

export const LeftVector: React.FC<{ color?: string }> = ({ color }) => {

    const theme = useTheme();

    return <svg width="5" height="10" viewBox="0 0 5 10" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M4.21978 9.6662C4.12019 9.66654 4.02178 9.64456 3.93179 9.60187C3.84181 9.55919 3.76253 9.49688 3.69978 9.41953L0.479784 5.41953C0.381729 5.30024 0.328125 5.15062 0.328125 4.9962C0.328125 4.84178 0.381729 4.69215 0.479784 4.57287L3.81312 0.572866C3.92628 0.436722 4.08888 0.351105 4.26517 0.334852C4.44145 0.318599 4.61697 0.37304 4.75312 0.486199C4.88926 0.599358 4.97488 0.761966 4.99113 0.93825C5.00738 1.11453 4.95294 1.29005 4.83978 1.4262L1.85978 4.99953L4.73978 8.57287C4.82131 8.67072 4.87309 8.78988 4.88901 8.91625C4.90493 9.04261 4.88432 9.1709 4.82961 9.28592C4.77491 9.40094 4.6884 9.49788 4.58033 9.56527C4.47226 9.63266 4.34714 9.66769 4.21978 9.6662Z"
            fill={color ? color : theme.palette.text.primary}
        />
    </svg>
}