import React from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { MyPaper } from "../../../components";
import { reset, updateBOMSliceByKeyValue } from "../../../redux/slices/bom";
import { useAppDispatch, useAppSelector } from "../../../redux";
import { useLinkModuleMutation, useUnlinkModuleMutation } from "../../../redux/services";
import { closeBackdrop, openBackdrop } from "../../../redux/slices/backdrop";
import { useSnackbar } from "notistack";
import { Dashboard } from "../../boms/dashboard";
import { AppRoutes } from "../../../router/routes";
import { openDialog } from "../../../redux/slices/dialog";
import { HasAccess } from "../../../router/authorization";
import { PERMISSIONS } from "../../../router/permission";
import { LinkChildBOM } from "../../viewBOMTree/linkBOM";

export const ProductBOMs: React.FC<{
  children?: JSX.Element;
  productId: string;
  sequence_id: string;
  refetch: any;
}> = (props) => {

  const [params] = useSearchParams();
  const dispatch = useAppDispatch();
  const [linkDocument, linkResponse] = useLinkModuleMutation();
  const [unlinkDocument, unlinkResponse] = useUnlinkModuleMutation();
  const { product } = useAppSelector((store) => store);
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { perm } = useAppSelector(store => store.auth.userDetails);

  const [selected, updateSelected] = React.useState<string[]>([]);
  const [reload, toggleReload] = React.useState<boolean>(false);

  const creatable = React.useMemo(() => HasAccess(perm, PERMISSIONS.PRODUCT_CREATE), [perm]);
  const readable = React.useMemo(() => HasAccess(perm, PERMISSIONS.PRODUCT_READ), [perm]);
  const updatable = React.useMemo(() => HasAccess(perm, PERMISSIONS.PRODUCT_UPDATE), [perm]);
  const deletable = React.useMemo(() => HasAccess(perm, PERMISSIONS.PRODUCT_DELETE), [perm]);
  const publishable = React.useMemo(() => HasAccess(perm, PERMISSIONS.PRODUCT_PUBLISH), [perm]);

  const locationPaths = {
    paths: [
      { title: "Products", path: AppRoutes.products },
      {
        title: `${props?.sequence_id}`,
        path: AppRoutes.viewProductBasicDetail(props.productId),
      },
      {
        title: "BOMs",
        path: `${AppRoutes.viewProductBOM(props.productId)}?tab=bom`,
      },
    ],
  };


  const unlinkBOM = async () => {
    dispatch(openBackdrop(`Removing BOMs...`));
    try {
      await unlinkDocument({
        object_id: props.productId,
        object_type: "product",
        payload: {
          unlinks: [{ object_type: "bom", object_ids: selected }],
        },
      }).then((res) => {
        enqueueSnackbar("Removed BOMs Linking", { variant: "success" });
        props.refetch();
        toggleReload(!reload);
      });
    } catch (err) {
      enqueueSnackbar(`Oops! Something went wrong, Unable to remove BOMs.`, { variant: "error" });
      dispatch(closeBackdrop());
    }
  };

  const linkBOM = async (linkIds: string[]) => {
    dispatch(openBackdrop(`Linking BOMs...`));
    try {
      await linkDocument({
        object_id: props.productId,
        object_type: "product",
        payload: {
          links: [{ object_type: "bom", object_ids: linkIds }]
        },
      }).then((res) => {
        enqueueSnackbar("Linked BOMs to product", { variant: "success" });
        props.refetch();
        toggleReload(!reload);
      });
    } catch (err) {
      enqueueSnackbar(`Oops! Something went wrong, Unable to link BOMs.`, { variant: "error" });
      dispatch(closeBackdrop());
    }
  };

  const openLinkingWindow = () => {
    dispatch(
      openDialog({
        title: "",
        hideNegativeBtn: true,
        hidePositiveBtn: true,
        maxWidth: "md",
        hideCloseIcon: true,
        enablePadding: false,
        body: (
          <LinkChildBOM
            id={props.productId}
            associations={product.associations}
            callLinkDocument={(linkIds, id: string) =>
              linkBOM(linkIds as string[])
            }
            isGeneral
            skipQty
          />
        ),
      })
    );
  };

  React.useEffect(() => {
    updateSelected([]);
    // eslint-disable-next-line
  }, [params]);

  React.useEffect(() => {
    if (!reload) {
      toggleReload(!reload);
    }
  }, [reload])

  return (
    <MyPaper height={"100%"} padding={0}>
      <>
        {reload && <Dashboard
          checkboxSelection={updatable}
          onCheckBoxSelected={(selected: string[]) => updateSelected(selected)}
          titleHeaderProps={{
            showHelperBtn: updatable,
            helperBtnLabel: "Remove BOM",
            onHelperBtn: () => unlinkBOM(),
            disableHelperBtn: selected.length === 0 || unlinkResponse.isLoading || linkResponse.isLoading,
            showDropDown: false,
            linkingOption: updatable ? { linkingBtnLabel: "Link BOM", openLinkingWindow: openLinkingWindow } : undefined,
            onCreateBtn: () => {
              dispatch(reset());
              navigate(AppRoutes.createOrUpdateProductBOMBasicDetail(props.productId, "New"), { state: locationPaths });
            },
          }}
          objectType={"product"}
          objectId={props.productId}
          onEdit={(id: string, sequence_id: string) => {
            dispatch(updateBOMSliceByKeyValue({ key: "bomId", value: id }));
            navigate(AppRoutes.createOrUpdateProductBOMBasicDetail(props.productId, id), { state: locationPaths });
          }}
          isNested={true}
          readable={readable}
          creatable={creatable}
          updatable={updatable}
          deletetable={deletable}
          publishable={publishable}
        />}
      </>
    </MyPaper>
  );
};
