import { createApi, fetchBaseQuery, retry } from '@reduxjs/toolkit/query/react';
import { config } from '../../../config';
import { ConvertToReactSelect, getTransformedDataForPagination, LocalStorageKeys } from '../../../utils';
import { VendorSliceInterface } from '../../slices/vendor';

const prepareVendorBody = (payload: any, linkedData:any) => {
    let body: any = {
        approved: payload.basic_details.approved_vendor?.value === "Yes",
            primary_contact: { address: payload.primary_contact.address, country: payload.primary_contact.country?.label, email: payload.primary_contact.email, person_name: payload.primary_contact?.name, phone: payload.primary_contact.mobile },
            status: payload.basic_details.status?.value,
            vendor_name: payload.basic_details.vendor_name,
            website: payload.basic_details.vendor_website,
            applicable_materials: payload.applicable_materials
    }
    if(payload.secondary_contact) body.secondary_contact = { address: payload.secondary_contact.address, country: payload.secondary_contact.country?.label, email: payload.secondary_contact.email, person_name: payload.secondary_contact.name, phone: payload.secondary_contact.mobile }
    body.links = linkedData.link;
    return body;
};

export const vendorsAPI = createApi({
    reducerPath: "vendorsAPI",
    baseQuery: retry(fetchBaseQuery({
        baseUrl: config.api_url + "vm/",
        prepareHeaders: (headers, { getState }) => {
            const token = localStorage.getItem(LocalStorageKeys.authToken);

            // If we have a token set in state, let's assume that we should be passing it.
            if (token) {
                headers.set('authorization', `Bearer ${token}`)
            }

            return headers
        },
    }), { maxRetries: 2 }),
    tagTypes: ['Vendors'],
    endpoints: (builder) => ({
        // Get Vendors API
        getVendors: builder.query({
            query: ({ page = 0, pageSize = 10, sort_by = null, sort_order = null }) => ({ url: `vendor${page !== null ? `?page_no=${page + 1}` : ''}${pageSize ? `&page_size=${pageSize}` : ''}${sort_by ? `&sort_by=${sort_by}` : ''}${sort_order ? `&sort_order=${sort_order}` : ''}`, method: "GET" }),
            transformResponse: (data: any, meta: { request: Request; response?: Response }) => {
                return getTransformedDataForPagination(data, meta?.response);
            }
        }),

        // Get Vendors API for Drop Down
        getVendorsDD: builder.query({
            query: (payload) => ({ url: 'vendor', method: "GET" }),
            transformResponse: (response: any[], meta, arg) => ConvertToReactSelect(response, "_id", "vendor_name"),
        }),

        // Get Approved vendor
        getApprovedVendor: builder.query({
            query: (payload) => ({url: 'approvedVendors', method:"GET"})
        }),

        // Get Vendor by vendor_id API
        getVendorById: builder.query({
            query: ({ id }) => ({ url: `vendor/${id}`, method: "GET" })
        }),

        // Create Vendor API
        createVendor: builder.mutation({
            query: (payload) => ({ url: 'vendor', method: "POST", body: payload })
        }),

        // Update Vendor API
        updateVendor: builder.mutation({
            query: ({ id, payload }) => ({ url: `vendor/${id}`, method: "PUT", body: payload })
        }),

        // Delete Vendor API
        deleteVendor: builder.mutation({
            query: ({ id }) => ({ url: `vendor/${id}`, method: "DELETE" })
        }),

        //Get Dashboard Data
        getVendorDashboardData: builder.query({
            query: ({ year = '' }) => ({ url: `vendor/dashboard${year.length ? `?year=${year}` : ''}`, method: "GET" })
        }),

         //UpdateLink Document API
         linkDocumentVendor: builder.mutation({
            query: ({ id, payload, linkedData }: { id: string, payload: VendorSliceInterface, linkedData: any}) => {
                return { url: `vendor/${id}?update_version=${false}`, method: "PUT", body: { ...prepareVendorBody(payload, linkedData) } }
            }
        }),
    })
})

// Export hooks for usage in functional components
export const { useGetVendorsQuery, useGetVendorsDDQuery,useLazyGetVendorsQuery,useGetVendorByIdQuery, useLazyGetVendorByIdQuery, useCreateVendorMutation, useUpdateVendorMutation, useDeleteVendorMutation, useGetApprovedVendorQuery, useLinkDocumentVendorMutation, useLazyGetVendorDashboardDataQuery, useLazyGetVendorsDDQuery } = vendorsAPI
