import { Checkbox, Divider, Grid, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { HighlightSelectBox, MyPaper, TextField } from "../../../components";
import { SelectBoxOption } from "../../../interfaces";
import { useGetCountryQuery } from "../../../redux/services";


export const PersonDetails: React.FC<{
    title: string, required: string[], person: any, error: any, updatePerson: any, readOnly?: boolean,
    showCheckBox?: boolean, defaultCheckBoxValue?: boolean, id?: string
}> = ({ title = "", required, person = {}, error = {}, updatePerson = () => false, readOnly = false, showCheckBox = false, defaultCheckBoxValue = false, id = "primary" }) => {

    const countryData = useGetCountryQuery(null);
    const [checkBox, updateCheckBox] = useState<boolean>(defaultCheckBoxValue);

    const updateTheCheckBox = () => {
        updatePerson('name', "");
        updatePerson('email', "");
        updatePerson('mobile', "");
        updatePerson('address', "");
        updatePerson('country', null);
        updateCheckBox(!checkBox);
    }

    useEffect(() => {
        updateCheckBox(defaultCheckBoxValue);
        // eslint-dsiable-next-line
    }, [defaultCheckBoxValue])

    return <MyPaper padding={0}>
        <Stack divider={<Divider />} height={"100%"}>
            {/* Heading */}
            <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"} p={1}>
                <Typography
                    variant="body1"
                    fontFamily={"htrts_medium"}>
                    {title}
                </Typography>

                {/* Add Selection Button */}
                {showCheckBox && <Checkbox id="vendor-checkbox" checked={checkBox} size="small" onClick={updateTheCheckBox} />}
            </Stack>


            {(!showCheckBox || checkBox) && <Grid container p={2} spacing={2}>
                {/* Name */}
                <Grid item xs={12} sm={6}>
                    <TextField
                        id={`${id}-name`}
                        variant="outlined"
                        size="small"
                        fullWidth
                        value={person.name}
                        label={"Name"}
                        onChange={(e) => updatePerson("name", e.target.value)}
                        required={required.includes("name")}
                        error={error.name}
                        helperText={error.name ? 'Please enter name' : ""}
                        viewOnly={readOnly}
                    />
                </Grid>

                {/* Email */}
                <Grid item xs={12} sm={6}>
                    <TextField
                        id={`${id}-email`}
                        variant="outlined"
                        size="small"
                        fullWidth
                        type={"email"}
                        value={person.email}
                        label={"Email"}
                        onChange={(e) => updatePerson("email", e.target.value)}
                        required={required.includes("email")}
                        error={error.email}
                        helperText={error.email ? 'Please enter valid email' : ""}
                        viewOnly={readOnly}
                    />
                </Grid>

                {/* Mobile */}
                <Grid item xs={12} sm={6}>
                    <TextField
                        id={`${id}-mobile`}
                        variant="outlined"
                        size="small"
                        fullWidth
                        type={"number"}
                        value={person.mobile}
                        label={"Mobile"}
                        onChange={(e) => updatePerson("mobile", e.target.value)}
                        required={required.includes("mobile")}
                        error={error.mobile}
                        helperText={error.mobile ? 'Please enter valid mobile' : ""}
                        viewOnly={readOnly}
                    />
                </Grid>

                {/* Address */}
                <Grid item xs={12} sm={6}>
                    <TextField
                        id={`${id}-address`}
                        variant="outlined"
                        size="small"
                        fullWidth
                        value={person.address}
                        label={"Address"}
                        onChange={(e) => updatePerson("address", e.target.value)}
                        required={required.includes("address")}
                        error={error.address}
                        helperText={error.address ? 'Please enter address' : ""}
                        viewOnly={readOnly}
                    />
                </Grid>

                {/* Country */}
                <Grid item xs={12} sm={6}>
                    <HighlightSelectBox
                        id={`${id}-country`}
                        label={"Country"}
                        margin={"none"}
                        value={person.country}
                        loading={countryData.isLoading}
                        options={countryData?.data ?? []}
                        onChange={(data: SelectBoxOption) => updatePerson("country", data)}
                        required={required.includes("country")}
                        error={error.country}
                        helperText={error.country ? 'Please select country' : ""}
                        viewOnly={readOnly}
                    />
                </Grid>
            </Grid>}
        </Stack>
    </MyPaper>
};
