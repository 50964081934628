import { Delete } from "@mui/icons-material"
import { Button, Grid, IconButton, Stack, Typography } from "@mui/material"
import { TextField } from "../textField"

export interface Props {
    rows: ReferenceCardRow[]
    setRows: (rows: ReferenceCardRow[]) => void
    hideTitle?: boolean
    testPrefix?: string
}

export interface ReferenceCardRow {
    id: string
    name: string
}

export const ReferenceCard = (props: Props) => {

    const { rows, setRows, hideTitle } = props;

    const updateRow = (index: number, key: 'id' | 'name', value: string) => {
        rows[index][key] = value;
        setRows(rows);
    };

    const deleteRow = (index: number) => {
        rows.splice(index, 1);
        setRows(rows);
    };

    const addNewRow = () => {
        rows.push({ id: "", name: "" });
        setRows(rows);
    };

    return <Stack spacing={1}>
        <Stack direction={"row"} justifyContent={ hideTitle ? "flex-end" : "space-between"} alignItems={"center"}>
            {/* Title */}
            {!hideTitle && <Typography variant="body1" gutterBottom>Reference Card</Typography>}

            {/* Add Row */}
            <Button variant="outlined" size="small" onClick={addNewRow} id={`reference-row-button${props.testPrefix ? `-${props.testPrefix}` : ''}`}>Add Row</Button>
        </Stack>

        <Stack spacing={2}>
            {rows.map((row, index) => {
                return <Grid key={index} container alignItems={"center"}>
                    <Grid item xs={11} sm={6} sx={{ pl: 1 }}>
                        <TextField
                            id={`reference-name-${index}${props.testPrefix ? `-${props.testPrefix}` : ''}`}
                            size="small"
                            variant={"filled"}
                            fullWidth
                            label={`Reference Library`}
                            value={row.name}
                            onChange={(e) => updateRow(index, 'name', e.target.value)}
                        />
                    </Grid>

                    <Grid item xs={12} sm={5}>
                        <TextField
                            id={`reference-id-${index}${props.testPrefix ? `-${props.testPrefix}` : ''}`}
                            size="small"
                            variant={"filled"}
                            fullWidth
                            label={`Reference ID`}
                            value={row.id}
                            onChange={(e) => updateRow(index, 'id', e.target.value)}
                        />
                    </Grid>

                    <Grid item xs={1} sm={1} sx={{ pl: 1 }}>
                        <IconButton
                            disabled={index === 0}
                            color="error"
                            size="small"
                            onClick={(e) => deleteRow(index)}
                        >
                            <Delete />
                        </IconButton>
                    </Grid>
                </Grid>
            })}
        </Stack>
    </Stack>
}