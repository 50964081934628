import React from "react";
import {
    Box,
    Stack,
    Button,
    Grid,
    FormControlLabel,
    RadioGroup,
    Radio,
    Tooltip,
    CircularProgress,
    Alert,
    Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useDispatch } from "react-redux";
import { useSnackbar } from "notistack";
import {
    useCreateListViewMutation,
    useGetGroupsDDQuery,
    useGetNounsDDQuery,
    useGetProcessingStatusQuery,
    useLazyGetListViewByIdQuery,
    useLazyGetLocationAndDocumentTagsQuery,
    useLazyGetProductByIdQuery,
    useLazyGetProductsAsDDQuery,
    useLazyGetProjectByIdQuery,
    useLazyGetProjectsAsDDQuery,
    useLazyGetProposalByIdQuery,
    useLazyGetProposalsAsDDQuery,
    useUpdateListViewMutation,
} from "../../redux/services";
import { SelectBoxOption } from "../../interfaces";
import { DeletePanel, HighlightSelectBox, Layout, MyPaper, SectionPaper, TextField } from "../../components";
import { type Location, useLocation, useNavigate, useParams } from "react-router-dom";
import { closeBackdrop, openBackdrop } from "../../redux/slices/backdrop";
import { ConvertToReactSelect } from "../../utils";
import { useAppDispatch, useAppSelector } from "../../redux";
import { createOption } from "../../components/keywordSearch";
import { QueryStatus } from "@reduxjs/toolkit/dist/query";
import { closeConfirmationDialog, openConfirmationDialog } from "../../redux/slices/confirmationDialog";
import { openDialog } from "../../redux/slices/dialog";
import { PublishComponent } from "../materials/dashboard";
import { HasAccess } from "../../router/authorization";
import { PERMISSIONS } from "../../router/permission";
import { WIPIcon } from "../../images/svg/WIPIcon";
import { PublishedIcon } from "../../images/svg/publishedIcon";

interface IState {
    name: string;
    id: SelectBoxOption | null;
    bom: SelectBoxOption | null;
    listType: SelectBoxOption | null;
    materialType: SelectBoxOption | null;
    tagOrNounOrGroup: SelectBoxOption[];
}

interface Error {
    name: boolean;
    id: boolean;
    bom: boolean;
    listType: boolean;
    materialType: boolean;
    tagOrNounOrGroup: boolean;
}

export const getObjectType = (location: Location, prefix?: string) => {
    if (location.pathname.includes("product") || prefix === "PRD") {
        return "product";
    } else if (location.pathname.includes("project") || prefix === "PJ") {
        return "project";
    } else if (location.pathname.includes("proposal") || prefix === "PRP") {
        return "proposal"
    }
}

const listTypeOptions = [
    createOption("Material List"),
    createOption("Document List")
]

const materialOptions = [
    createOption("Group"),
    createOption("Noun")
]

export const material_noun = "material_noun";
export const material_group = "material_group";
export const document_tag = "document_tag";

export const RenderListForm: React.FC<{
    data: any;
    listViewStatus: QueryStatus;
    refetch: (id: string) => void;
}> = ({ data, listViewStatus, refetch }) => {

    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const { p_id = "", id = "" } = useParams();
    const { perm } = useAppSelector(store => store.auth.userDetails);

    const [getAllProducts] = useLazyGetProductsAsDDQuery({ refetchOnFocus: true });
    const [getAllProjects] = useLazyGetProjectsAsDDQuery({ refetchOnFocus: true });
    const [getAllProposals] = useLazyGetProposalsAsDDQuery({ refetchOnFocus: true });
    const nounsResponse = useGetNounsDDQuery({ refetchOnFocus: true });
    const groupResponse = useGetGroupsDDQuery(null, { refetchOnMountOrArgChange: true, refetchOnFocus: true });
    const [fetchDocTags, docOptions] = useLazyGetLocationAndDocumentTagsQuery();
    const [getProductById] = useLazyGetProductByIdQuery({ refetchOnReconnect: true, refetchOnFocus: true });
    const [getProjectById] = useLazyGetProjectByIdQuery({ refetchOnReconnect: true, refetchOnFocus: true });
    const [getProposalById] = useLazyGetProposalByIdQuery({ refetchOnReconnect: true, refetchOnFocus: true });
    const [createListView, createListViewData] = useCreateListViewMutation();
    const [updateListView, updateListViewData] = useUpdateListViewMutation();

    const { data: processingStatus } =
        useGetProcessingStatusQuery({ id, type: "bom_list_view" }, {
            pollingInterval: 3000,
            skip: id === "New" || data?.processing === false
        })

    const [parentData, setParentData] = React.useState<any>({});
    const [moduleOptions, setModuleOptions] = React.useState<SelectBoxOption[]>([]);
    const [state, setState] = React.useState<IState>({
        name: "",
        id: null,
        bom: null,
        listType: listTypeOptions[0],
        materialType: null,
        tagOrNounOrGroup: [],
    });
    const [error, setError] = React.useState<Error>({
        name: false,
        id: false,
        bom: false,
        listType: false,
        materialType: false,
        tagOrNounOrGroup: false,
    });

    const isEdit = id !== "" && id !== "New";
    const docTypeTags = docOptions.data?.documentTags ?? [];
    const subOptionsLoading = docOptions.isFetching || docOptions.isFetching || nounsResponse.isLoading || nounsResponse.isFetching;
    const bomOptions = ConvertToReactSelect(parentData?.associations?.find((a: any) => a.object_type === "bom")?.object_details ?? [], "_id", "sequence_id", ["version"], "r");

    const creatable = React.useMemo(() => HasAccess(perm, PERMISSIONS.BOM_LIST_VIEW_CREATE), [perm]);
    const updatable = React.useMemo(() => HasAccess(perm, PERMISSIONS.BOM_LIST_VIEW_UPDATE), [perm]);
    const publishable = React.useMemo(() => {
        const primaryAccess = HasAccess(perm, PERMISSIONS.BOM_LIST_VIEW_PUBLISH);
        const objectType = getObjectType(location);
        if (objectType === 'product') {
            return HasAccess(perm, PERMISSIONS.PRODUCT_PUBLISH) && primaryAccess
        }
        if (objectType === 'proposal') {
            return HasAccess(perm, PERMISSIONS.PROPOSAL_PUBLISH) && primaryAccess
        }
        if (objectType === 'project') {
            return HasAccess(perm, PERMISSIONS.PROJECT_PUBLISH) && primaryAccess
        }
        return primaryAccess
    }, [perm, location]);

    const isViewOnly = isEdit ? !updatable : !creatable;

    const updateState = (key: keyof IState, value: IState[keyof IState]) => {
        setState((prevState) => ({ ...prevState, [key]: value }));
        setError((prevError) => ({ ...prevError, [key]: !value }));
    };

    const onCancelBtnClicked = () => navigate(-1);

    const validate = () => {
        let isValid = true;
        if (!state.name.trim()) {
            setError((prevError) => ({
                ...prevError,
                name: true
            }))
            isValid = false;
        }

        if (!state.bom) {
            setError((prevError) => ({
                ...prevError,
                bom: true
            }))
            isValid = false;
        }

        if (!state.listType) {
            setError((prevError) => ({
                ...prevError,
                listType: true
            }))
            isValid = false;
        }

        if (state.listType?.value === listTypeOptions[0]?.value && !state.materialType) {
            setError((prevError) => ({
                ...prevError,
                materialType: true
            }))
            isValid = false;
        }

        if (state.tagOrNounOrGroup.length <= 0) {
            setError((prevError) => ({
                ...prevError,
                tagOrNounOrGroup: true
            }))
            isValid = false;
        }

        return isValid;
    };

    const onCreateUpdateBtnClicked = async () => {
        let valid = validate();
        if (valid) {
            let filter_type = state?.listType?.value === listTypeOptions[1]?.value ? document_tag : state?.materialType?.value === materialOptions[0]?.value ? material_group : material_noun;
            let payload: Record<string, any> = {
                object_type: getObjectType(location, parentData?.sequence_id?.split("-")?.[0]),
                object_id: p_id || parentData?._id,
                bom_id: state?.bom?._id,
                name: state?.name,
                filter_type: filter_type,
                filter_object_id: state?.tagOrNounOrGroup?.map(t => t?._id)
            };

            if (isEdit) {
                dispatch(openBackdrop("Updating list view..."))
                await updateListView({ id, payload }).unwrap().then((res) => {
                    enqueueSnackbar(res?.message ?? "List View Updated Successfully", {
                        variant: "success",
                    });
                    navigate(-1);
                }).catch((error: any) => {
                    enqueueSnackbar(
                        error?.data?.title ?? "Oops! Something went wrong, Unable to update List View",
                        {
                            variant: "error",
                        }
                    );
                    console.error(`unable to update the list view ${error}`)
                }).finally(() => {
                    dispatch(closeBackdrop());
                });
            } else {
                dispatch(openBackdrop("Creating list view..."))
                await createListView(payload).unwrap().then((res) => {
                    if (res?._id) {
                        enqueueSnackbar(res?.message ?? "List View Created Successfully", {
                            variant: "success",
                        });
                    }
                    navigate(-1);
                }).catch((error: any) => {
                    enqueueSnackbar(
                        error?.data?.title ?? "Oops! Something went wrong, Unable to create List View",
                        {
                            variant: "error",
                        }
                    );
                    console.error(`unable to create the list view ${error}`)
                }).finally(() => {
                    dispatch(closeBackdrop());
                });
            }
        }
    };

    const fetchParentData = async () => {
        if (p_id) {
            dispatch(openBackdrop("Fetch the module data..."))
            if (location.pathname.includes("product")) {
                await getProductById({ id: p_id }).unwrap().then((res) => {
                    if (Object.keys(res ?? {}).length > 0) {
                        setParentData(res);
                    }
                }).catch((err: any) => {
                    enqueueSnackbar(
                        "Oops! Something went wrong, Unable to fetch the module data",
                        {
                            variant: "error",
                        }
                    );
                    console.error("error: ", err)
                }).finally(() => {
                    dispatch(closeBackdrop());
                })
            } else if (location.pathname.includes("project")) {
                await getProjectById({ id: p_id }).unwrap().then((res) => {
                    if (Object.keys(res ?? {}).length > 0) {
                        setParentData(res);
                    }
                }).catch((err: any) => {
                    enqueueSnackbar(
                        "Oops! Something went wrong, Unable to fetch the module data",
                        {
                            variant: "error",
                        }
                    );
                    console.error("error: ", err)
                }).finally(() => {
                    dispatch(closeBackdrop());
                })
            } else {
                await getProposalById({ id: p_id }).unwrap().then((res) => {
                    if (Object.keys(res ?? {}).length > 0) {
                        setParentData(res);
                    }
                }).catch((err: any) => {
                    enqueueSnackbar(
                        "Oops! Something went wrong, Unable to fetch the module data",
                        {
                            variant: "error",
                        }
                    );
                    console.error("error: ", err)
                }).finally(() => {
                    dispatch(closeBackdrop());
                })
            }
        }
    };

    const fetchModuleOptions = async () => {
        try {
            const productOptions = (await getAllProducts({})).data || [];
            const projectOptions = (await getAllProjects({})).data || [];
            const proposalOptions = (await getAllProposals({})).data || [];
            setModuleOptions([...productOptions, ...projectOptions, ...proposalOptions])
        } catch (error: any) {
            console.error(`Error from List View page: ${error}`);
            setModuleOptions([]);
        }
    };

    const updatePublishStatus = (row: any) => {
        dispatch(
            openConfirmationDialog({
                title: "Publish Operation",
                body: <DeletePanel
                    message={`Are you sure you want to publish the list view ${row?.sequence_id}?`}
                    warningMessage={`You will not be able to edit this revision after publishing.`}
                />,
                positiveBtn: "Proceed",
                negativeBtn: "Cancel",
                onOk: () => proceedToPublish(row),
                onNegativeBtn: () => dispatch(closeConfirmationDialog())
            })
        );
    };

    const proceedToPublish = (row: any) => {
        dispatch(closeConfirmationDialog());
        dispatch(openDialog({
            title: "",
            body: <PublishComponent {...row} refetch={() => refetch(row?._id)} object_type="bomListView" object_id={row?._id} />,
            hidePositiveBtn: true,
            hideNegativeBtn: true,
            enablePadding: false
        }));
    };

    const giveMeErrorMessage = () => {
        let message = `Oops! Something went wrong, Unable to ${isEdit ? "Update" : "Create"} List View. Try Again Later!`;
        let errorData: any = isEdit ? updateListViewData.error : createListViewData.error;
        message = errorData?.data?.title ?? message;
        return message;
    };

    React.useEffect(() => {
        fetchDocTags(null);
        // eslint-disable-next-line
    }, []);

    React.useEffect(() => {
        if (p_id) {
            fetchParentData();
        } else {
            fetchModuleOptions();
        }
        // eslint-disable-next-line
    }, []);

    React.useEffect(() => {
        if (isEdit) {
            dispatch(openBackdrop("Updating state..."));
            let selectedId = moduleOptions.length > 0 ? { id: moduleOptions.find((d: SelectBoxOption) => d?._id === data?.object_id) || null } : {}

            let bomOp: SelectBoxOption[] = [];
            if (selectedId?.id) {
                bomOp = ConvertToReactSelect(((selectedId?.id || parentData) as any)?.associations?.find((a: any) => a.object_type === "bom")?.object_details ?? [], "_id", "sequence_id", ["version"], "r")
            }

            let lType = (data?.filter_type === material_noun || data?.filter_type === material_group) ? listTypeOptions[0] : listTypeOptions[1];

            let mType = data?.filter_type === material_noun ? materialOptions[1] : data?.filter_type === material_group ? materialOptions[0] : null;

            let options = lType?.value === listTypeOptions[0]?.value ? (mType?.value === materialOptions[0]?.value ? groupResponse?.data ?? [] : mType?.value === materialOptions[1]?.value ? nounsResponse?.data ?? [] : []) : lType?.value === listTypeOptions[1]?.value ? docTypeTags : []

            if (!options || options?.length <= 0) {
                options = subDocOrNounOrGroupOptions;
            }

            setState({
                ...state,
                name: data?.name,
                bom: [...bomOp, ...bomOptions].find((d: SelectBoxOption) => d?._id === data?.bom_id) || null,
                listType: lType,
                materialType: mType,
                tagOrNounOrGroup: options.filter((d: SelectBoxOption) =>  data?.filter_object_id.includes(d?._id)),
                ...selectedId
            });

            if (selectedId?.id) {
                setParentData(selectedId?.id);
            }
        }
        // eslint-disable-next-line
    }, [listViewStatus, docOptions.status, moduleOptions, nounsResponse?.status, groupResponse?.status]);

    React.useEffect(() => {
        if (isEdit) {
            dispatch(closeBackdrop());
        }
        // eslint-disable-next-line
    }, [state.id, state.bom]);

    const subDocOrNounOrGroupOptions = state.listType?.value === listTypeOptions[0]?.value ? (state.materialType?.value === materialOptions[0]?.value ? groupResponse?.data ?? [] : state.materialType?.value === materialOptions[1]?.value ? nounsResponse?.data ?? [] : []) : state.listType?.value === listTypeOptions[1]?.value ? docTypeTags : [];

    return (
        <Box>
            {processingStatus?.processing === true ? <MyPaper>
                <Alert
                    severity="info"
                    iconMapping={{
                        info: <CircularProgress size={"1em"} color="info" />,
                    }}>
                    Processing list view...
                </Alert>
            </MyPaper>
                :
                <SectionPaper py={0} title={`${isEdit ? isViewOnly ? "View" : "Update" : "Create"} List View`} renderButtons={[
                    <>
                        {
                            isEdit && data?.released ? (
                                <Stack direction={"row"} alignItems={"center"}>
                                    <Typography variant="body2" color="textSecondary">Status: </Typography>
                                    <Button disableRipple disableFocusRipple sx={{ cursor: "default", '&:hover': { backgroundColor: 'transparent' } }}>
                                        <Tooltip
                                            title="Published"
                                            children={<Box width={30} height={30} alignItems={"center"} justifyContent={"center"}><PublishedIcon sx={{ width: "100%", height: "100%" }} /></Box>}
                                        />
                                    </Button>
                                </Stack>
                            ) : isEdit && (
                                <Stack direction={"row"} alignItems={"center"}>
                                    <Typography variant="body2" color="textSecondary">Status: </Typography>
                                    <Button disableRipple disableFocusRipple>
                                        <Tooltip
                                            title={publishable ? "Click to Publish" : "Not Published"}
                                            children={
                                                <Box width={30} height={30} alignItems={"center"} justifyContent={"center"}><WIPIcon sx={{ width: "100%", height: "100%" }} onClick={() => publishable ? updatePublishStatus(data) : false} /></Box>
                                            }
                                        />
                                    </Button>
                                </Stack>
                            )
                        }
                    </>
                ]}>
                    <>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6} md={4}>
                                {/* Name */}
                                <TextField
                                    id="list-view-name"
                                    label={"Name"}
                                    value={state.name}
                                    required={true}
                                    fullWidth
                                    size={"small"}
                                    variant="outlined"
                                    placeholder={"Enter List View Name *"}
                                    error={error.name}
                                    helperText={error.name ? "Please enter the name" : ""}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                        updateState("name", e.target.value)
                                    }
                                    viewOnly={isViewOnly}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                                {/* BOM */}
                                <HighlightSelectBox
                                    id="select-bom"
                                    margin={"none"}
                                    label={"Select BOM *"}
                                    onChange={(data: SelectBoxOption | null) => updateState("bom", data)}
                                    options={bomOptions}
                                    value={
                                        bomOptions.find((d: SelectBoxOption) => d?.value === state.bom?.value) ||
                                        null
                                    }
                                    error={error.bom}
                                    helperText={
                                        error.bom ? "Please select BOM" : ""
                                    }
                                    viewOnly={isViewOnly}
                                />
                            </Grid>

                            {/* List Type */}
                            <Grid item xs={12} sm={6} md={4}>
                                <RadioGroup
                                    row
                                    aria-labelledby="demo-radio-buttons-group-label"
                                    name="radio-buttons-group"
                                    value={state.listType?.value}
                                    onChange={(event) => {
                                        updateState("listType", listTypeOptions.find(lType => lType?.value === event.target?.value) || null)
                                        updateState("materialType", null)
                                        updateState("tagOrNounOrGroup", [])
                                    }}
                                >
                                    <FormControlLabel disabled={isViewOnly} value={listTypeOptions[0]?.value} control={<Radio disabled={isViewOnly} size={'small'} />} label={listTypeOptions[0]?.label} />
                                    <FormControlLabel disabled={isViewOnly} value={listTypeOptions[1]?.value} control={<Radio disabled={isViewOnly} size={'small'} />} label={listTypeOptions[1]?.label} />
                                </RadioGroup>
                            </Grid>

                            <Grid container item md={12} spacing={2}>
                                {state.listType?.value === listTypeOptions[0]?.value ? (
                                    <>
                                        <Grid item xs={12} sm={6} md={4}>
                                            <HighlightSelectBox
                                                id="select-primary"
                                                margin={"none"}
                                                label={"Select Group or Noun *"}
                                                onChange={(data: SelectBoxOption | null) => {
                                                    updateState("materialType", data)
                                                    updateState("tagOrNounOrGroup", [])
                                                }}
                                                options={materialOptions}
                                                value={
                                                    materialOptions?.find((d: SelectBoxOption) => d?.value === state.materialType?.value) ||
                                                    null
                                                }
                                                disabled={state.listType?.value !== listTypeOptions[0]?.value}
                                                error={error.materialType && state.listType?.value === listTypeOptions[0]?.value}
                                                helperText={
                                                    error.materialType && state.listType?.value === listTypeOptions[0]?.value ? "Please select Group or Noun" : ""
                                                }
                                                viewOnly={isViewOnly}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={4}>
                                            <HighlightSelectBox
                                                multiple
                                                id="select-item"
                                                margin={"none"}
                                                label={"Select Item *"}
                                                onChange={(data: SelectBoxOption[]) => updateState("tagOrNounOrGroup", data)}
                                                loading={subOptionsLoading}
                                                options={subDocOrNounOrGroupOptions}
                                                value={state?.tagOrNounOrGroup}
                                                disabled={state.listType?.value !== listTypeOptions[0]?.value}
                                                error={error.tagOrNounOrGroup && state.listType?.value === listTypeOptions[0]?.value}
                                                helperText={
                                                    error.tagOrNounOrGroup && state.listType?.value === listTypeOptions[0]?.value ? "Please select Item" : ""
                                                }
                                                viewOnly={isViewOnly}
                                            />
                                        </Grid>
                                    </>
                                ) : <Grid item xs={12} sm={6} md={4}>
                                    <HighlightSelectBox
                                        multiple
                                        margin={"none"}
                                        label={"Select Document Type Tag *"}
                                        onChange={(data: SelectBoxOption[]) => updateState("tagOrNounOrGroup", data)}
                                        loading={subOptionsLoading}
                                        options={subDocOrNounOrGroupOptions}
                                        value={state?.tagOrNounOrGroup}
                                        disabled={state.listType?.value !== listTypeOptions[1]?.value}
                                        error={error.tagOrNounOrGroup && state.listType?.value === listTypeOptions[1]?.value}
                                        helperText={
                                            error.tagOrNounOrGroup && state.listType?.value === listTypeOptions[1]?.value ? "Please select Document Type Tag" : ""
                                        }
                                        viewOnly={isViewOnly}
                                    />
                                </Grid>
                                }
                            </Grid>
                        </Grid>
                    </>
                </SectionPaper>
            }

            {/* Alert Box */}
            {(createListViewData.isError || updateListViewData.isError) && <Alert sx={{ mt: 2 }} severity="error">{
                giveMeErrorMessage()
            }</Alert>}

            {!isViewOnly && <Box mt={3}>
                <MyPaper>
                    <>
                        {/* Actions */}
                        <Stack
                            gap={2}
                            flexDirection={"row"}
                            alignItems={"center"}
                            justifyContent={"center"}
                        >
                            <Button
                                variant="outlined"
                                onClick={onCancelBtnClicked}
                                disabled={
                                    createListViewData.isLoading || updateListViewData.isLoading
                                }
                            >
                                Cancel
                            </Button>

                            <LoadingButton
                                id="u-c-btn"
                                variant="contained"
                                onClick={onCreateUpdateBtnClicked}
                                disabled={data?.released}
                                loading={
                                    createListViewData.isLoading || updateListViewData.isLoading || processingStatus?.processing === true
                                }
                            >
                                {`${isEdit ? "Update" : "Create"}`}
                            </LoadingButton>
                        </Stack>
                    </>
                </MyPaper>
            </Box>}
        </Box>
    );
};

export const AddEditListView = () => {

    const navigate = useNavigate();

    const dispatch = useAppDispatch();

    const { enqueueSnackbar } = useSnackbar();

    const location: any = useLocation();

    const paths = location?.state?.paths ?? [];

    const { id = "New" } = useParams();

    const [getListViewById, listViewResponse] = useLazyGetListViewByIdQuery();

    const fetchListView = async (id: string) => {
        dispatch(openBackdrop("Fetching List View data..."));
        await getListViewById({ id, include_bom: false }).unwrap().catch(() => {
            enqueueSnackbar(
                (listViewResponse?.error as any)?.title ?? "Fetching List View Failed",
                { variant: "error" }
            );
        }).finally(() => {
            dispatch(closeBackdrop());
        })
    }

    React.useEffect(() => {
        if (id !== "New") {
            fetchListView(id);
        }
        // eslint-disable-next-line
    }, []);

    return (
        <Box padding={1}>
            <Layout
                history={[
                    ...paths.map((_: any) => {
                        return { label: _.title, onClick: () => navigate(_.path) };
                    }),
                ]}
                currentPath={`${listViewResponse?.data?.sequence_id ?? "..."}-r${listViewResponse?.data?.version ?? 0}`}
                navBars={[]}
                mainPanel={<RenderListForm data={listViewResponse?.data} listViewStatus={listViewResponse?.status} refetch={fetchListView} />}
                sideNavVariant={"whiteboard"}
                otherBreadscrumProps={{
                    hideMenuBtn: true,
                    showBackBtn: false,
                }}
                locationState={paths}
                onBackBtnClick={paths[0]?.path ? () => navigate(paths[0]?.path) : undefined}
            />
        </Box>
    );
};