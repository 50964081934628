import React from "react";
import Select from "react-select";
import { Typography, useTheme, Box, Tooltip, Chip, Stack } from "@mui/material";
import { useGetSNNounsQuery } from "../../../../redux/services";
import { NounSelectBoxInterface, Option } from "./util";
import { customStyles } from "../../../../components/nounSelectBox/util";

export const NounSelectBoxWithoutSearch: React.FC<NounSelectBoxInterface> = ({
  value = null,
  onChange = () => null,
  label = "",
  isRequired = false,
  placeholder = "",
  isMulti = false,
  disabled = false,
  height = null,
  category_id = null,
  viewOnly = false
}) => {
  const theme = useTheme();
  const snNounD = useGetSNNounsQuery({ page: null, pageSize: null }, {
    refetchOnMountOrArgChange: true,
  });
  const snNounData = {
    ...snNounD,
    data: snNounD?.data?.data ?? [],
    currentData: snNounD?.data?.data ?? [],
    totalCount: snNounD?.data?.totalCount ?? 0,
  };

  const giveMeOptions = () => {
    if (category_id) {
      return snNounData.currentData
        ?.filter((_: any) => _.category_id === category_id)
        ?.map((_: any) => ({ value: _._id, label: _.noun_name, ..._ }));
    }
    return snNounData.currentData?.map((_: any) => ({
      value: _._id,
      label: _?.category_name
        ? `${_.noun_name} (${_?.category_name})`
        : _.noun_name,
      ..._,
    }));
  };

  let selectedValue = value && !Array.isArray(value) ? value?.label || "N/A" : "N/A";

  return viewOnly ? (
    <Stack sx={{ overflowWrap: "break-word" }}>
      <Typography variant="body1" sx={{ fontFamily: "htrts_semibold" }}>{label}</Typography>
      {Array.isArray(value) && value.length > 0
        ?
        <Stack
          gap={1}
          direction={"row"}
          flexWrap={"wrap"}
        >
          {
            value.map(v =>
              <Tooltip title={v?.label || "N/A"}>
                <Chip size={"small"} label={v?.label || "N/A"} />
              </Tooltip>
            )
          }
        </Stack>
        :
        <Typography variant="body2" sx={{ fontFamily: "htrts_regular" }}>{selectedValue}</Typography>}
    </Stack>
  ) : (
    <Box>
      {label.length > 0 && (
        <Box sx={{ display: "flex" }}>
          {
            <Typography
              variant="body2"
              color="primary"
              align="left"
              gutterBottom
            >
              {label}
            </Typography>
          }
          {isRequired && (
            <Typography color="error" variant="caption">
              *
            </Typography>
          )}
        </Box>
      )}

      <Select
        isMulti={isMulti}
        isClearable={false}
        isSearchable
        isDisabled={disabled}
        components={{
          IndicatorSeparator: () => null,
          Option,
        }}
        value={value}
        placeholder={placeholder}
        options={giveMeOptions()}
        isLoading={snNounData.isFetching}
        onChange={onChange}
        styles={customStyles(theme, height)}
      />

      {snNounData.isError && !snNounData.isFetching && (
        <Typography variant="caption" color="error">
          {"Oops! Unable to fetch "}
        </Typography>
      )}
    </Box>
  );
};
