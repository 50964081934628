import { Alert, Box, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControlLabel, Grid, Input, Stack, Tab, Tabs, Tooltip, Typography, useMediaQuery } from "@mui/material";
import { GridRowParams, GridSortDirection, GridSortModel } from "@mui/x-data-grid-premium";
import React, { useState } from 'react';
import { useDispatch } from "react-redux";
import { CustomDataGrid, SearchFilter, TextField } from '../../components';
import { useGetGroupsDDQuery, useGetNounsDDQuery, useGetVendorsDDQuery, useLazyGetBOMChildrensByIdQuery, useLazyGetBOMsBySearchQuery, useLazyGetBOMsQuery } from "../../redux/services";
import { closeConfirmationDialog, openConfirmationDialog } from "../../redux/slices/confirmationDialog";
import { closeDialog } from "../../redux/slices/dialog";
import { isRowSelectable, searchLocalList } from "../../utils";
import { DashboardTableColumn } from "./../boms/utils";
import { BasicDetails, Transition } from "../viewBOM/basicDetails";
import { useAppDispatch } from "../../redux";
import { reset } from "../../redux/slices/bom";
import { type LinkDetail } from "..";

interface ILinkProps {
    children?: JSX.Element;
    id?: any;
    skipQty?: boolean;
    callLinkDocument: (ids: LinkDetail[] | string[], id: string) => any;
    associations: any;
    showAllTabs?: boolean;
    showOnlyTabs?: ('list' | 'search' | 'create')[];
    isGeneral?: boolean
}

const allTabs: string[] = ["list", "search", "create"];

export const ConfigureQuantity = ({ links, data, onLinkDocs, isMatch, isOpen, onClose }: { links: string[], data: any[], onLinkDocs: (links: LinkDetail[]) => void, isMatch: boolean, isOpen: boolean, onClose: () => void }) => {
    const [linkQtys, setLinkQtys] = React.useState<LinkDetail[]>([]);

    const updateQty = (qty: number, _id: string) => {
        setLinkQtys((prevLinks) => {
            let index = prevLinks.findIndex(p => p._id === _id);
            let elem = prevLinks[index];
            return [...prevLinks.slice(0, index), { ...elem, qty: qty }, ...prevLinks.slice(index + 1)]
        })
    }

    React.useEffect(() => {
        const qtys = data.filter(d => links.includes(d?._id)).map((d) => ({ _id: d?._id, qty: 1, sequence_id: d.sequence_id, description: d.short_description }));
        setLinkQtys(qtys);
    }, [data, links]);

    return <Dialog open={isOpen}
        onClose={onClose}
        TransitionComponent={Transition} maxWidth="xs"
        fullWidth={true}
    >
        <DialogTitle>
            <Typography gutterBottom variant="body1" color={"textPrimary"} sx={{ display: "flex", fontWeight: 600 }}>
                Enter Quantity
            </Typography>
            <Divider variant="fullWidth" />
        </DialogTitle>
        <DialogContent>
            <Grid container spacing={2} mt={0.1}>
                {linkQtys.map((l, idx) => (
                    <>
                        <Grid item xs={12} sm={8}>
                            <TextField
                                label={l.sequence_id}
                                value={l.description}
                                required={true}
                                fullWidth
                                size={"small"}
                                variant="outlined"
                                viewOnly={true}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Stack direction={"row"} spacing={1}>
                                <Button variant="outlined" disabled={l.qty === 1} size={"small"} sx={{ minWidth: 30 }} onClick={() => updateQty(l.qty - 1, l._id)}>-</Button>

                                <Input
                                    placeholder={"Enter the qty *"}
                                    value={l.qty}
                                    fullWidth
                                    sx={{
                                        width: 30
                                    }}
                                    inputProps={{
                                        style: {
                                            textAlign: "center"
                                        }
                                    }}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                        updateQty(Number(e.target.value), l._id)
                                    }}
                                />
                                <Button variant="outlined" size={"small"} sx={{ minWidth: 30 }} onClick={() => updateQty(l.qty + 1, l._id)} id={`increment-${idx}`}>+</Button>
                            </Stack>
                        </Grid>

                        {idx < linkQtys.length - 1 &&
                            <Grid xs={12} mt={1}>
                                <Divider variant="fullWidth" />
                            </Grid>
                        }
                    </>
                ))}
            </Grid>
        </DialogContent>

        <DialogActions>
            <Grid container item xs={12} spacing={1} sm={12} md={12} sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
            }}
                pb={1}
            >
                <Grid item xs={12} sm={6}></Grid>
                <Grid item xs={12} sm={3}>
                    <Button
                        id="link-elem"
                        variant={"contained"}
                        color={"primary"}
                        fullWidth
                        onClick={() => onLinkDocs(linkQtys)}
                        disabled={links.length === 0}
                        sx={{ fontSize: (theme) => theme.spacing(1.75), paddingX: "4px", right: isMatch ? 0 : 20, width: isMatch ? "80%" : "100%", transform: isMatch ? "translateX(10%)" : "translateX(0)" }}
                    >
                        Link
                    </Button>
                </Grid>
                <Grid item xs={12} sm={3}>
                    <Button
                        variant={"outlined"}
                        color={"primary"}
                        fullWidth
                        onClick={() => onClose()}
                        disabled={false}
                        sx={{ fontSize: (theme) => theme.spacing(1.75), paddingX: "4px", right: isMatch ? 0 : 20, width: isMatch ? "80%" : "100%", transform: isMatch ? "translateX(10%)" : "translateX(0)" }}
                    >
                        Cancel
                    </Button>
                </Grid>
            </Grid>
        </DialogActions>
    </Dialog>
}

export const BrowseBOM: React.FC<ILinkProps> = (props) => {
    const api = props.isGeneral ? useLazyGetBOMsQuery : useLazyGetBOMChildrensByIdQuery;

    const dispatch = useDispatch();
    const [getBOMs, { data: allData = { data: [], totalCount: 0 }, isLoading, isFetching, isError, isSuccess }] = api();
    const { data, totalCount } = allData;

    const [links, setLinks] = React.useState<string[]>([]);
    const [revision, setRevision] = React.useState<boolean>(false);
    const [isOpen, setOpen] = React.useState<boolean>(false);

    const [search, setSearch] = useState("");

    const [paginationModel, setPaginationModel] = React.useState({
        page: 0,
        pageSize: 10,
    });
    const [queryOptions, setQueryOptions] = React.useState({
        sortModel: [{ field: 'sequence_id', sort: 'desc' as GridSortDirection }],
    });

    const isMatch = useMediaQuery("(max-width: 600px)");

    const onCancelBtnClicked = () => {
        dispatch(closeDialog());
    }

    const linkDocumentcall = (selectedLinks: LinkDetail[] | string[]) => {
        dispatch(closeConfirmationDialog());
        dispatch(closeDialog());
        setOpen(false);
        props.callLinkDocument(selectedLinks, props.id)
    }

    const configureQtys = () => {
        setOpen(true);
        dispatch(closeConfirmationDialog());
    }

    const onLinkBtnClicked = () => {
        dispatch(openConfirmationDialog({
            title: "Link Operation",
            body: `Are you sure you want to link the selected items`,
            positiveBtn: "Yes",
            negativeBtn: "Cancel",
            onOk: () => props?.skipQty ? linkDocumentcall(links) : configureQtys(),
            onNegativeBtn: () => dispatch(closeConfirmationDialog())
        }));
    }

    const handleSortModelChange = React.useCallback((sortModel: GridSortModel) => {
        // Here you save the data you need from the sort model
        setQueryOptions({ sortModel: [...sortModel] });
    }, [])

    const onSearch = () => {
        getBOMs({ 
            include_histories: revision, 
            id: props.id,
            sort_by: queryOptions?.sortModel?.[0]?.field, 
            sort_order: queryOptions?.sortModel?.[0]?.sort,
            ...paginationModel 
        })
    }

    const giveMeRows = () => {
        if (search.trim().length && data && !isLoading && isSuccess) {
            return data.filter((d: any) => searchLocalList(d, search));
        } else if (!isLoading && data) {
            return data ?? [];
        } else {
            return [];
        }
    }

    let ROWS = giveMeRows();

    const onRowSelection = (selected: any) => {
        setLinks(selected);
    }

    React.useEffect(() => {
        onSearch();
        // eslint-disable-next-line
    }, [paginationModel, revision, JSON.stringify(queryOptions?.sortModel)])

    return <>
        <DialogContent>
            <Grid container item xs={12} spacing={1} sm={12} md={12} sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", marginTop: "10px" }}>
                <Grid item>
                    <Tooltip title={"All Revision"}>
                        <FormControlLabel control={<Checkbox
                            title="All Revision"
                            checked={revision}
                            onChange={() => { setRevision(!revision) }}
                        />} label="All Revision" />
                    </Tooltip>
                </Grid>

                <Grid item xs={12} sm={6}>
                    <SearchFilter
                        searchPlaceholder={"Search for BOM (only within this page)"}
                        handleChange={setSearch}
                        value={search}
                    />
                </Grid>
            </Grid>

            {isError && <Alert sx={{ mt: 2 }} severity="error">Oops! Something went wrong, Unable to fetch. Try Again Later!</Alert>}

            <Box mt={2}>
                <CustomDataGrid
                    saveLocal={false}
                    rows={ROWS}
                    loading={isLoading || isFetching}
                    columns={DashboardTableColumn({ disableNavigation: true, removeDelete: true })}
                    getRowId={(row) => row._id}
                    showToolbar={false}
                    checkboxSelection={true}
                    onRowSelectionModelChange={(selected) => { onRowSelection(selected) }}
                    isRowSelectable={(params: GridRowParams) => isRowSelectable(params.row, props.associations)}
                    rowCount={totalCount}
                    paginationModel={paginationModel}
                    paginationMode="server"
                    onPaginationModelChange={setPaginationModel}
                    sortingMode="server"
                    onSortModelChange={handleSortModelChange}
                />
            </Box>

            <ConfigureQuantity links={links} data={data} onLinkDocs={linkDocumentcall} isMatch={isMatch} isOpen={isOpen} onClose={() => setOpen(false)} />
        </DialogContent>

        <DialogActions>
            <Grid container item xs={12} spacing={1} sm={12} md={12} sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
            }}
                pb={1}
            >
                <Grid item xs={12} sm={6}></Grid>
                <Grid item xs={12} sm={3}>
                    <Button
                        id="link-bom"
                        variant={"contained"}
                        color={"primary"}
                        fullWidth
                        onClick={onLinkBtnClicked}
                        disabled={links.length === 0}
                        sx={{ fontSize: (theme) => theme.spacing(1.75), paddingX: "4px", right: isMatch ? 0 : 20, width: isMatch ? "80%" : "100%", transform: isMatch ? "translateX(10%)" : "translateX(0)" }}
                    >
                        Link
                    </Button>
                </Grid>
                <Grid item xs={12} sm={3}>
                    <Button
                        variant={"outlined"}
                        color={"primary"}
                        fullWidth
                        onClick={onCancelBtnClicked}
                        disabled={false}
                        sx={{ fontSize: (theme) => theme.spacing(1.75), paddingX: "4px", right: isMatch ? 0 : 20, width: isMatch ? "80%" : "100%", transform: isMatch ? "translateX(10%)" : "translateX(0)" }}
                    >
                        Cancel
                    </Button>
                </Grid>
            </Grid>
        </DialogActions>
    </>
};

export const SearchBOMs: React.FC<ILinkProps> = (props) => {

    const dispatch = useDispatch();
    const isMatch = useMediaQuery("(max-width: 600px)");
  
    const [getBOMsBySearch, { data: searchData = { data: [], totalCount: 0 }, ...searchedData }] = useLazyGetBOMsBySearchQuery();
    const { data, totalCount } = searchData;
  
    const [links, setLinks] = React.useState<string[]>([]);
    const [paginationModel, setPaginationModel] = React.useState({ page: 0, pageSize: 10 });
    const [search, setSearch] = useState("");
    const [isOpen, setOpen] = React.useState<boolean>(false);
    const [queryOptions, setQueryOptions] = React.useState({
        sortModel: [{ field: 'sequence_id', sort: 'desc' as GridSortDirection }],
    });
  
    const onCancelBtnClicked = () => dispatch(closeDialog());
  
    const linkDocumentCall = (selectedLinks: LinkDetail[] | string[]) => {
      dispatch(closeConfirmationDialog());
      dispatch(closeDialog());
      setOpen(false);
      props.callLinkDocument(selectedLinks, props.id);
    };
  
    const configureQtys = () => {
      setOpen(true);
      dispatch(closeConfirmationDialog());
    }
  
    const onLinkBtnClicked = () => {
      dispatch(
        openConfirmationDialog({
          title: "Link Operation",
          body: `Are you sure you want to link the selected items`,
          positiveBtn: "Yes",
          negativeBtn: "Cancel",
          onOk: () => props?.skipQty ? linkDocumentCall(links) : configureQtys(),
          onNegativeBtn: () => dispatch(closeConfirmationDialog()),
        })
      );
    };

    const handleSortModelChange = React.useCallback((sortModel: GridSortModel) => {
        // Here you save the data you need from the sort model
        setQueryOptions({ sortModel: [...sortModel] });
    }, [])
  
    const onSearch = () => {
      if (search.trim()) {
        getBOMsBySearch({ search, ...paginationModel, sort_by: queryOptions?.sortModel?.[0]?.field, sort_order: queryOptions?.sortModel?.[0]?.sort });
      }
    };
  
    const onRowSelection = (selected: any) => setLinks(selected);
  
    const giveMeRows = () => {
      if (!isLoading && data?.length > 0 && searchedData.isSuccess) {
        return data ?? [];
      } else {
        return [];
      }
    };
  
    React.useEffect(() => {
      onSearch();
      // eslint-disable-next-line
    }, [paginationModel, JSON.stringify(queryOptions?.sortModel)]);
  
    const isLoading = searchedData.isFetching || searchedData.isLoading;
  
    let ROWS = giveMeRows();
  
    return (
      <>
        <DialogContent>
          <Grid
            container item xs={12} spacing={1} sm={12} md={12}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              marginTop: "10px",
            }}
          >
            <Grid item xs={12} sm={6}>
              <SearchFilter
                searchPlaceholder={"Search for BOMs"}
                handleChange={setSearch}
                value={search}
              />
            </Grid>
  
            <Grid item xs={12} md={2}>
              <Button
                variant={"contained"}
                color={"primary"}
                fullWidth
                onClick={onSearch}
                sx={{ fontSize: (theme) => theme.spacing(1.75), paddingX: "4px" }}
              >
                Search
              </Button>
            </Grid>
          </Grid>
  
          {searchedData.isError && (
            <Alert sx={{ mt: 2 }} severity="error">
              Oops! Something went wrong, Unable to fetch. Try Again Later!
            </Alert>
          )}
  
          <Box mt={2}>
            <CustomDataGrid
              saveLocal={false}
              rows={ROWS}
              loading={isLoading}
              columns={DashboardTableColumn({ disableNavigation: true, removeDelete: true })}
              getRowId={(row) => row._id}
              showToolbar={false}
              checkboxSelection={true}
              isRowSelectable={(params: GridRowParams) => isRowSelectable(params.row, props.associations)}
              onRowSelectionModelChange={(selected) => onRowSelection(selected)}
              rowCount={totalCount}
              paginationModel={paginationModel}
              paginationMode="server"
              onPaginationModelChange={setPaginationModel}
              sortingMode="server"
              onSortModelChange={handleSortModelChange}
            />
          </Box>
  
          <ConfigureQuantity links={links} data={data} onLinkDocs={linkDocumentCall} isMatch={isMatch} isOpen={isOpen} onClose={() => setOpen(false)} />
        </DialogContent>
  
        <DialogActions>
          <Grid
            container
            item
            xs={12}
            spacing={1}
            sm={12}
            md={12}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
            pb={1}
          >
            <Grid item xs={12} sm={6}></Grid>
            <Grid item xs={12} sm={3}>
              <Button
                variant={"contained"}
                color={"primary"}
                fullWidth
                onClick={onLinkBtnClicked}
                disabled={links.length === 0}
                sx={{
                  fontSize: (theme) => theme.spacing(1.75),
                  paddingX: "4px",
                  right: isMatch ? 0 : 20,
                  width: isMatch ? "80%" : "100%",
                  transform: isMatch ? "translateX(10%)" : "translateX(0)",
                }}
              >
                Link
              </Button>
            </Grid>
            <Grid item xs={12} sm={3}>
              <Button
                variant={"outlined"}
                color={"primary"}
                fullWidth
                onClick={onCancelBtnClicked}
                disabled={false}
                sx={{
                  fontSize: (theme) => theme.spacing(1.75),
                  paddingX: "4px",
                  right: isMatch ? 0 : 20,
                  width: isMatch ? "80%" : "100%",
                  transform: isMatch ? "translateX(10%)" : "translateX(0)",
                }}
              >
                Cancel
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </>
    );
};

const CreateAssignBOM: React.FC<ILinkProps> = (props) => {

    const dispatch = useAppDispatch();
    const nounData = useGetNounsDDQuery(null);
    const groupData = useGetGroupsDDQuery(null);
    const supplierData = useGetVendorsDDQuery(null);

    const onCancelBtnClicked = () => {
        dispatch(closeDialog());
    };

    React.useEffect(() => {
        dispatch(reset());
        // eslint-disable-next-line
    }, [])

    return <DialogContent>
        <BasicDetails
            refetch={() => false}
            bomId={"New"}
            nounData={nounData}
            groupData={groupData}
            supplierData={supplierData}
            _id={""}
            version={"0"}
            associations={[]}
            avoidNavigate={true}
            customAssociation={{ object_id: "", object_type: "" }}
            handleParentDialogClose={() => onCancelBtnClicked()}
            onSuccessfullBOMCreation={(data: any) => {
                props.callLinkDocument([data], props.id);
                onCancelBtnClicked();
            }}
            docNavigationPath={""}
            avoidDocNavigation={true}
            buttonPrefix="& Link"
            note="Note: It will create the new BOM with the above basic details and link it to the selected BOM as child. To add other BOM related details please visit the BOM list page."
            basicDetailsPath=""
        />
    </DialogContent>
};

export const LinkChildBOM: React.FC<ILinkProps> = ({ showAllTabs = true, showOnlyTabs = [], ...props }) => {

    const [selectedTab, updateTab] = useState(showAllTabs ? allTabs[0] : showOnlyTabs[0]);

    return <>
        <DialogTitle>
            <Typography gutterBottom variant="body1" color={"textPrimary"} sx={{ display: "flex", fontWeight: 600 }}>
                Create/Link BOM
            </Typography>
            <Divider variant="fullWidth" sx={{ mb: 2 }} />
            <Tabs
                value={selectedTab}
                onChange={(e, tab) => updateTab(tab)}
                indicatorColor="primary"
                textColor="primary"
            >
                {(showAllTabs || showOnlyTabs.includes('list')) && <Tab value={allTabs[0]} label="Browse BOM" />}
                {(showAllTabs || showOnlyTabs.includes('search')) && <Tab value={allTabs[1]} label="Search BOM" />}
                {(showAllTabs || showOnlyTabs.includes('create')) && <Tab value={allTabs[2]} label="Create & Link New BOM" />}
            </Tabs>
        </DialogTitle>

        {selectedTab === allTabs[0] && <BrowseBOM {...props} />}
        {selectedTab === allTabs[1] && <SearchBOMs {...props} />}
        {selectedTab === allTabs[2] && <CreateAssignBOM {...props} />}
    </>

}