import { Alert, Box, Theme, useTheme } from "@mui/material";
import { useSnackbar } from "notistack";
import React from "react";
import { useNavigate } from "react-router-dom";
import { CustomDataGrid, DeletePanel, TitleHeader } from "../../../components";
import { useAppDispatch } from "../../../redux";
import { useDeleteMaterialMutation, useLazyGetMaterialsQuery } from "../../../redux/services";
import { closeBackdrop, openBackdrop } from "../../../redux/slices/backdrop";
import { closeConfirmationDialog, openConfirmationDialog, updateConfirmationDialogPositiveBtn, updateCongirmationDialogBody } from "../../../redux/slices/confirmationDialog";
import { AppRoutes } from "../../../router/routes";
import { DashboardTableColumn } from "../utils";

const useStyles = (theme: Theme) => ({
    root: {
        flexGrow: 1,
        background: theme.palette.background.paper,
        boxShadow: `rgba(99, 99, 99, 0.2) 0px 2px 8px 0px`,
        height: "100%",
        borderRadius: theme.spacing(1),
        padding: theme.spacing(2),
    },
    dataGridParent: {
        height: "calc(100% - 40px)",
        overflow: "scroll",
        marginTop: theme.spacing(2),
    }
});

export const Dashboard: React.FC<{ children?: JSX.Element }> = (props) => {

    const [paginationModel, setPaginationModel] = React.useState({
        page: 0,
        pageSize: 10,
    });

    const classes = useStyles(useTheme());
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const [getMaterials, { data: allData = { data: [], totalCount: 0 }, isFetching, isError }] = useLazyGetMaterialsQuery();
    const { data, totalCount } = allData;

    const [deleteCutSheetMutation] = useDeleteMaterialMutation();
    const { enqueueSnackbar } = useSnackbar();

    const [search, setSearch] = React.useState("");

    const viewCutsheet = (data: any, isEdit: boolean = true) => {
        navigate(AppRoutes.viewCutSheetBasicDetails(isEdit ? data.sequence_id : "New"))
    }

    const deleteCutSheet = (cutsheet: any) => {
        let message = `Do you want to delete this cutsheet ${cutsheet.sequence_id}?`;
        dispatch(openConfirmationDialog({
            title: "Are you sure?",
            body: <DeletePanel
                message={message}
                warningMessage={"Note: Clicking Delete will check the only dependencies and will list if any, else the current version will be deleted."}
            />,
            positiveBtn: "Delete",
            negativeBtn: "Cancel",
            onOk: () => performRowDel(cutsheet, message, false),
            onNegativeBtn: () => dispatch(closeConfirmationDialog())
        }))
    }

    const performRowDel = async (cutsheet: any, message: string, force: boolean = false) => {
        dispatch(openBackdrop("Deleting...."));
        let res: any = {};
        try {
            res = await deleteCutSheetMutation({ id: cutsheet._id, force })

            if (Object.keys(res).includes("data")) {
                enqueueSnackbar(`Deleted cutsheet Successfully!`, { variant: "success" })
                dispatch(closeConfirmationDialog());
                refetch();
            } else if (Object.keys(res).includes("error")) {
                // eslint-disable-next-line 
                throw res.error.data;
            } else {
                // eslint-disable-next-line 
                throw "Data not found"
            }
        } catch (error: any) {
            let errorMessage: string = error?.title ?? "Opps! Something went wrong, Unable to delete"
            enqueueSnackbar(errorMessage, { variant: "error" });
            dispatch(updateCongirmationDialogBody(<DeletePanel
                message={message}
                errorMessage={errorMessage}
                errorMessageStacks={[
                    {
                        object_type: "document",
                        object_details: error?.details?.document ?? []
                    }
                ]}
                warningMessage={""}
            />))
            dispatch(updateConfirmationDialogPositiveBtn({
                positiveBtn: "Force Delete",
                onOk: () => performRowDel(cutsheet, message, true)
            }))
        } finally {
            dispatch(closeBackdrop());
        }
    }

    const giveMeRows = (): any[] => {
        if (!isFetching) {
            if (search.trim().length) {
                return data?.filter((_: any) => (_._id.toLowerCase().includes(search.toLowerCase()))) ?? []
            }
            return data ? data : [];
        } else {
            return [];
        }
    };

    const refetch = () => {
        getMaterials({ ...paginationModel });
    }

    let ROWS = giveMeRows();

    React.useEffect(() => {
        refetch();
        // eslint-disable-next-line
    }, [paginationModel])

    React.useEffect(() => {
        refetch();
        // eslint-disable-next-line
    }, [])

    return <Box sx={classes.root}>
        {/* Header */}
        <TitleHeader
            title="Cutsheets"
            count={ROWS?.length ?? 0}
            showSearch={true}
            search={search}
            onSearchChange={setSearch}
            searchPlaceholder={"Search for Cutsheet Name (only within this page)"}
            showCreateBtn={true}
            createBtnLabel={"New CutSheet"}
            onCreateBtn={() => viewCutsheet(null, false)}
        />

        {/* Error */}
        {isError && <Alert sx={{ mt: 2 }} severity="error">Opps! Something went wrong, Unable to fetch cutsheets. Try Again Later!</Alert>}

        {/* Data Drid */}
        <Box mt={2}>
            <CustomDataGrid
                saveLocal={false}
                rows={ROWS}
                columns={DashboardTableColumn(deleteCutSheet)}
                loading={isFetching}
                getRowId={(row) => row._id}
                showToolbar={false}
                rowCount={totalCount}
                paginationModel={paginationModel}
                paginationMode="server"
                onPaginationModelChange={setPaginationModel}
            />
        </Box>
    </Box>
}