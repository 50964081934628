import { Alert, Box, useTheme } from "@mui/material";
import React from "react";
import moment from "moment";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import { CustomDataGrid, DeletePanel, TitleHeader } from "../../../components";
import { useAppDispatch, useAppSelector } from "../../../redux";
import { useLazyGetProductsQuery, useDeleteProductMutation } from "../../../redux/services";
import { resetProductSlice } from "../../../redux/slices/product";
import { AppRoutes } from "../../../router/routes";
import { DashboardTableColumn } from "../utils";
import { closeConfirmationDialog, openConfirmationDialog, updateConfirmationDialogPositiveBtn, updateCongirmationDialogBody } from "../../../redux/slices/confirmationDialog";
import { closeBackdrop, openBackdrop } from "../../../redux/slices/backdrop";
import { HasAccess } from "../../../router/authorization";
import { PERMISSIONS } from "../../../router/permission";
import { GridSortDirection, GridSortModel } from "@mui/x-data-grid-premium";
import { useStyles } from "../../documentTemplates/styles";

export const Dashboard: React.FC<{ children?: JSX.Element }> = (props) => {

    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const classes = useStyles(useTheme());
    const { enqueueSnackbar } = useSnackbar();
    const [deleteProductMutation] = useDeleteProductMutation();
    const { perm } = useAppSelector(store => store.auth.userDetails);
    const [getAllProducts, { data: allData = { data: [], totalCount: 0 }, isFetching, isLoading, isError }] = useLazyGetProductsQuery({ refetchOnFocus: true });

    const [search, setSearch] = React.useState("");
    const [paginationModel, setPaginationModel] = React.useState({ page: 0, pageSize: 10 });
    const [queryOptions, setQueryOptions] = React.useState({
        sortModel: [{ field: 'sequence_id', sort: 'desc' as GridSortDirection }],
    });

    const { data, totalCount } = allData;

    const creatable = React.useMemo(() => HasAccess(perm, PERMISSIONS.MATERIAL_CREATE), [perm]);
    const deletable = React.useMemo(() => HasAccess(perm, PERMISSIONS.MATERIAL_DELETE), [perm]);

    const addEditProduct = (data: any, isEdit: boolean = true) => {
        dispatch(resetProductSlice())
        navigate(AppRoutes.viewProductBasicDetail(isEdit ? data._id : "New"))
    };

    const deleteProduct = (product: any) => {
        let message = `Check for dependencies before deleting the Product ${product.sequence_id}-r${product.version}`;
        dispatch(openConfirmationDialog({
            title: "Delete Operation",
            body: <DeletePanel
                message={message}
                warningMessage={""}
            />,
            positiveBtn: "Check for dependencies",
            negativeBtn: "Cancel",
            onOk: () => openDependency(product, message, false),
            onNegativeBtn: () => dispatch(closeConfirmationDialog())
        }))
    };

    const openDependency = (product: any, message: string, force: boolean) => {
        dispatch(updateCongirmationDialogBody(<DeletePanel
            message={product.associations ? product.associations.length === 0 ? 'No dependency found' : `Dependency Table of ${product?.sequence_id}-r${product.version}` : "No dependency found"}
            errorMessage={""}
            errorMessageStacks={product?.associations ?? []}
            warningMessage={""}
        />))
        dispatch(updateConfirmationDialogPositiveBtn({
            positiveBtn: "Force Delete",
            onOk: () => {
                dispatch(updateCongirmationDialogBody(<DeletePanel
                    message={"Are you sure want to force delete the Product " + product?.sequence_id + "-r" + product?.version + "?"}
                    warningMessage={"Warning: All the products contents and its associated entities will be de-linked"}
                />))
                dispatch(updateConfirmationDialogPositiveBtn({
                    positiveBtn: "Ok",
                    onOk: () => performRowDel(product, "", true)
                }))
            },
        }))
    };

    const performRowDel = async (product: any, message: string, force: boolean = false) => {
        dispatch(openBackdrop("Deleting product..."));
        let res: any = {};
        try {
            res = await deleteProductMutation({ id: product._id, force })

            if (Object.keys(res).includes("data")) {
                enqueueSnackbar(`Deleted product Successfully!`, { variant: "success" })
                dispatch(closeConfirmationDialog());
                getAllProducts({ ...paginationModel });
            } else if (Object.keys(res).includes("error")) {
                // eslint-disable-next-line 
                throw res.error.data;
            } else {
                // eslint-disable-next-line 
                throw "Data not found"
            }
        } catch (error: any) {
            let errorMessage: string = error?.title ?? "Opps! Something went wrong, Unable to delete"
            enqueueSnackbar(errorMessage, { variant: "error" });
        } finally {
            dispatch(closeBackdrop());
        }
    };

    const giveMeRows = () => {
        if (!isLoading && !isFetching && data) {
            if (search.trim().length) {
                return data?.filter((_: any) => _?.product_name?.toLowerCase()?.includes(search.toLowerCase()) ||
                    _?.sequence_id?.toLowerCase()?.includes(search.toLowerCase()) ||
                    _?.product_manager?.toLowerCase()?.includes(search.toLowerCase()) ||
                    _?.created_user?.toLowerCase()?.includes(search.toLowerCase()) ||
                    moment(_?.created_time).format('lll').toString().includes(search.toLowerCase()) ||
                    _?.tags_info?.filter((_: any) => _?.tag_group_name === 'Business Units')?.[0]?.tag_name?.toLowerCase()?.includes(search.toLowerCase()) ||
                    _?.tags_info?.filter((_: any) => _?.tag_group_name === 'Product Category')?.[0]?.tag_name?.toLowerCase()?.includes(search.toLowerCase()) ||
                    _?.tags_info?.filter((_: any) => _?.tag_group_name === 'Product Status')?.[0]?.tag_name?.toLowerCase()?.includes(search.toLowerCase()) ||
                    _?.tags_info?.filter((_: any) => _?.tag_group_name === 'Product General Tags')?.[0]?.tag_name?.toLowerCase()?.includes(search.toLowerCase())

                ) ?? []
            }
            return data;
        } else {
            return [];
        }
    };

    const ROWS = giveMeRows();

    const handleSortModelChange = React.useCallback((sortModel: GridSortModel) => {
        // Here you save the data you need from the sort model
        setQueryOptions({ sortModel: [...sortModel] });
    }, [])

    React.useEffect(() => {
        getAllProducts({ ...paginationModel, sort_by: queryOptions?.sortModel?.[0]?.field, sort_order: queryOptions?.sortModel?.[0]?.sort });
        // eslint-disable-next-line
    }, [paginationModel, JSON.stringify(queryOptions?.sortModel)])

    return <Box sx={classes.root}>
        {/* Header */}
        <TitleHeader
            title="Products"
            count={ROWS?.length ?? 0}
            showSearch={true}
            search={search}
            onSearchChange={setSearch}
            searchPlaceholder={"Search for Products (only within this page)"}
            showCreateBtn={creatable}
            createBtnLabel={"Create Product"}
            onCreateBtn={() => addEditProduct(null, false)}
        />

        {/* Error */}
        {isError && <Alert sx={{ mt: 2 }} severity="error">Oops! Something went wrong, Unable to fetch Products. Try Again Later!</Alert>}


        {/* Data Drid */}
        <Box mt={2}>
            <CustomDataGrid
                id="product-list"
                // initialState={{
                //     sorting: {
                //         sortModel: [{ field: 'sequence_id', sort: 'desc' }],
                //     },
                // }}
                rows={ROWS}
                columns={DashboardTableColumn({ onDelete: deleteProduct, view: addEditProduct, hideDelete: !deletable })}
                disableVirtualization
                loading={isLoading || isFetching}
                getRowId={(row) => row._id}
                showToolbar={true}
                rowCount={totalCount}
                paginationModel={paginationModel}
                paginationMode="server"
                onPaginationModelChange={setPaginationModel}
                sortingMode="server"
                onSortModelChange={handleSortModelChange}
            />
        </Box>
    </Box>
}