import { List } from "@mui/icons-material";
import { Box } from "@mui/material";
import React from "react";
import { Layout } from "../../components";
import { withNavBars } from "../../HOCs";
import { AppRoutes } from "../../router/routes";
import { Dashboard } from "./dashboard";
import { useAppDispatch } from "../../redux";
import { closeBackdrop } from "../../redux/slices/backdrop";

const Projects: React.FC<{ children?: JSX.Element }> = (props) => {

    const dispatch = useAppDispatch();

    React.useEffect(() => {
        dispatch(closeBackdrop());
        // eslint-disable-next-line
    },[])

    return <Box sx={{ padding: 0.5, height: "100%" }}>
        {/* Breadscrum */}
        <Layout
            currentPath={"Projects"}
            hideNavBarByDefault={true}
            navBars={[{
                id: "1",
                title: "Projects",
                icon: <List fontSize="small" />,
                path: AppRoutes.projects
            },]}
            mainPanel={<Dashboard />}
            sideNavVariant={"whiteboard"}
        />
    </Box>
}

export default withNavBars(Projects);