import { createApi, fetchBaseQuery, retry } from '@reduxjs/toolkit/query/react';
import Axios from 'axios';
import { config } from '../../../config';
import { getTransformedDataForPagination, LocalStorageKeys } from '../../../utils';
import { updateDocUploadStatus } from '../../slices/utils';

type UrlType = "preview" | "download";

export const materialDocumentAPI = createApi({
    reducerPath: "materialDocumentAPI",
    baseQuery: retry(fetchBaseQuery({
        baseUrl: config.api_url + "dm/document",
        prepareHeaders: (headers, { getState }) => {
            const token = localStorage.getItem(LocalStorageKeys.authToken);

            // If we have a token set in state, let's assume that we should be passing it.
            if (token) {
                headers.set('authorization', `Bearer ${token}`)
            }

            return headers
        },
    }), { maxRetries: 0 }),
    tagTypes: ['Documents', 'SearchedDocuments'],
    endpoints: (builder) => ({
        getDocuments: builder.query({
            query: (
                { include_all = false, object_id, include_history = false, sequence_id, tag_group_id, tag_id, object_type, page = 0, pageSize = 10, document_type = '', include_assoc = true, sort_by = null, sort_order = null }
                    : { include_all?: boolean, object_id?: any, include_history?: any, sequence_id?: any, tag_group_id?: any, tag_id?: any, object_type?: any, page?: number, pageSize?: number, document_type?: '' | 'drawing' | 'drawing_template', include_assoc?: boolean, sort_by?: string | null, sort_order?: string | null }
            ) => ({ url: `?include_histories=${include_history}&include_all=${include_all}&include_assoc=${include_assoc}${object_type ? `&object_type=${object_type}` : ``}${object_id ? `&object_id=${object_id}` : ``}${sequence_id ? `&sequence_id=${sequence_id}` : ``}${tag_group_id ? `&tag_group_id=${tag_group_id}` : ``}${tag_id ? `&tag_id=${tag_id}` : ``}${page !== null ? `&page_no=${page + 1}` : ''}${pageSize ? `&page_size=${pageSize}` : ''}${document_type ? `&document_type=${document_type}` : ``}${sort_by ? `&sort_by=${sort_by}` : ''}${sort_order ? `&sort_order=${sort_order}` : ''}`, method: "GET" }),
            transformResponse: (data: any, meta: { request: Request; response?: Response }) => {
                return getTransformedDataForPagination(data, meta?.response);
            },
            providesTags: ['Documents'],
        }),
        getDocumentsBySearch: builder.query({
            query: ({
                search = "", include_histories = false, page: page_no = 0, pageSize: page_size = 10, object_id, object_type, sort_by = null, sort_order = null
            }) => ({
                url: `/search?search=${search}&include_histories=${include_histories}${page_no !== null ? `&page_no=${page_no + 1}` : ``}${page_size ? `&page_size=${page_size}` : ``}${object_type ? `&object_type=${object_type}` : ``}${object_id ? `&object_id=${object_id}` : ``}${sort_by ? `&sort_by=${sort_by}` : ''}${sort_order ? `&sort_order=${sort_order}` : ''}`,
                method: "GET"
            }),
            transformResponse: (data: any, meta: { request: Request; response?: Response }, args) => {
                let totalCountHeader = meta?.response?.headers.get('x-hontrel-total-records-count');
                let totalCount = totalCountHeader ? Number(totalCountHeader) : 0;
                return { data, totalCount };
            },
            providesTags: ['SearchedDocuments'],
        }),
        getDocumentsByTagGroupId: builder.query({
            query: ({ tag_group_id = null, object_type = null, include_history = false, object_ids = null }) => ({ url: `/tags?include_histories=${include_history}${object_ids ? `&object_ids=${object_ids?.toString()}` : ``}${tag_group_id ? `&tag_group_id=${tag_group_id}` : ``}${object_type ? `&object_type=${object_type}` : ``}` })
        }),
        getDocumentById: builder.query({
            query: ({ document_id, url_type = "inline" }) => ({ url: `/${document_id}?url_type=${url_type}`, method: "GET" })
        }),
        createMaterialDocument: builder.mutation({
            query: (payload) => {
                return ({ method: "POST", body: payload })
            },
            invalidatesTags: ['Documents'],
        }),
        uploadDocument: builder.mutation({
            async queryFn(_arg, _queryApi, _extraOptions) {
                const { data, url, file } = _arg;
                let result: any = {};

                try {
                    let resData = null;
                    if (file) {
                        resData = await Axios({
                            url,
                            method: "put",
                            headers: { 'Content-Type': file.type },
                            data: file
                        });
                    }
                    result = { data: { resData, document: data } }
                    _queryApi.dispatch(updateDocUploadStatus(data));
                } catch (err) {
                    result = { error: err }
                } finally {
                    return result;
                }
            },
            invalidatesTags: ['Documents'],
        }),
        updateMaterialDocument: builder.mutation({
            async queryFn(_arg, _queryApi, _extraOptions, fetchWithBQ) {
                const { comment, object_id, object_type = "", tags_info, document_id, update_version = false, update_document = false, links = [], unlinks = [], file_name, document_type = undefined } = _arg;

                let result: any = {};
                try {
                    const doc: any = await fetchWithBQ({ url: `/${document_id}?update_version=${update_version}&upload_document=${update_document}`, method: "put", body: { links, unlinks, comment, file_name: file_name, object_id, object_type, tags_info, document_type } });
                    if (doc.error) {
                        // eslint-disable-next-line
                        throw { title: doc?.error?.data?.title ?? "Opps! Something went wrong, Unable to update Document. Try Again Later!", error: doc.error }
                    }
                    result = { data: doc.data }
                } catch (err: any) {
                    result = { error: { title: err.title, moreInfo: err.error } }
                } finally {
                    return result;
                }
            },
            invalidatesTags: ['Documents'],
        }),
        // Delete Material Document API
        deleteMaterialDocument: builder.mutation({
            query: ({ id, force }) => ({ url: `/${id}?force=${force}`, method: "DELETE" }),
            invalidatesTags: ['Documents'],
        }),

        // Get Dashboard API
        getDocumentDashboard: builder.query({
            query: ({ year = '' }) => ({ url: `/dashboard${year.length ? `?year=${year}` : ''}`, method: "GET" })
        }),

        // Get Document Url
        getDocumentUrl: builder.query({
            query: ({ id, url_type = "preview" }: { id: string; url_type?: UrlType }) => ({ url: `/${id}/presignedurl?url_type=${url_type}`, method: "GET" }),
        }),

        // Update document edit status
        updateDocumentEditStatus: builder.mutation({
            query: ({ documentId, activityType }: { documentId: string, activityType: 'open' | 'close' }) => ({ url: `/${documentId}/activity/${activityType}`, method: 'PUT' })
        }),

         // Duplicate Document API
        duplicateDocument: builder.mutation({
            query: (id) => ({ url: `/${id}`, method: "POST" }),
            invalidatesTags: ['Documents'],
        }),

         // Bulk Download document
         bulkDownloadDocuments: builder.query({
            query: ({ document_ids = [], file_name = '' }: { document_ids: string[], file_name: string }) => ({
                url: `/download`,
                method: 'POST',
                body: { document_ids, file_name },
                responseHandler: async (response: any) => {
                    // Here we directly return the blob
                    const blob = await response.blob();
                    return blob;
                },
            }),
            async onQueryStarted(arg, { queryFulfilled }) {
                try {
                    const { data } = await queryFulfilled;

                    // Create a downloadable link and trigger the download
                    const url = window.URL.createObjectURL(new Blob([data], { type: 'application/zip' }));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', arg.file_name); // Set the filename dynamically if needed
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                } catch (err) {
                    console.error('Download failed:', err);
                }
            },
         }),
    })
})

// Export hooks for usage in functional components
export const { useCreateMaterialDocumentMutation, useUpdateMaterialDocumentMutation, useLazyGetDocumentByIdQuery, useGetDocumentByIdQuery, useGetDocumentsQuery, useLazyGetDocumentsQuery, useDeleteMaterialDocumentMutation, useUploadDocumentMutation, useGetDocumentsByTagGroupIdQuery, useLazyGetDocumentsByTagGroupIdQuery, useLazyGetDocumentDashboardQuery, useLazyGetDocumentsBySearchQuery, useLazyGetDocumentUrlQuery, useUpdateDocumentEditStatusMutation, useDuplicateDocumentMutation, useLazyBulkDownloadDocumentsQuery } = materialDocumentAPI
