import { createSlice } from "@reduxjs/toolkit";
import copy from "fast-copy";
import { SelectBoxOption } from "../../../interfaces";

export interface CategoryItem {
  description?: string;
  name: string;
  position: number;
  remarks?: string;
  status?: boolean;
}

export interface Category {
  description?: string;
  items: Array<CategoryItem>;
  name: string;
  position: number;
}

export interface CheckListDetail {
  sequence_id: string;
  revision: number;
  checklist_status: SelectBoxOption | null;
  name: string;
  checklist_tags: SelectBoxOption[];
  categories: Array<Category>;
}

export interface CategoryItemError {
  items: Array<boolean>;
}

export interface CheckListDetailError {
  sequence_id: boolean;
  revision: boolean;
  checklist_status: boolean;
  name: boolean;
  checklist_tags: boolean;
  categories: Array<CategoryItemError>;
}

export interface CheckListState {
  basic_details: CheckListDetail;
  error: CheckListDetailError;
}

export const categoryItem = {
  description: "",
  name: "",
  position: 0,
  remarks: "",
  status: true
};

export const categoryState = { description: "", position: 0, name: "", items: [] };

const initialState: CheckListState = {
  basic_details: {
    sequence_id: "",
    revision: 0,
    checklist_status: null,
    name: "",
    checklist_tags: [],
    categories: []
  },
  error: {
    sequence_id: false,
    revision: false,
    checklist_status: false,
    name: false,
    checklist_tags: false,
    categories: [{ items: [false] }]
  }
};

export const checkListSlice = createSlice({
  name: "checkList",
  initialState,
  reducers: {
    // To Slice by key value pair
    updateCheckListSliceByKeyValue(
      state,
      { payload }: { payload: { parentKey: string; childKey: string; value: any } }
    ) {
      let newState: any = copy(state);
      newState.error[payload.childKey] = payload.value ? false : true;
      newState[payload.parentKey][payload.childKey] = payload.value;
      return { ...state, ...newState };
    },

    // To reset all values to initialValue
    resetCheckListSlice(state) {
      return { ...state, ...initialState };
    },

    // To Bulk Update the checklist slice
    updateCheckListSlice(
      state,
      {
        payload
      }: {
        payload: {
          // Basic Details
          basic_details: CheckListDetail;
        };
      }
    ) {
      return { ...state, ...payload };
    },

    // To check for validation errors
    updateCheckListErrors(
      state,
      {
        payload
      }: {
        payload: {
          parentKey: keyof CheckListState;
          childKey: keyof CheckListDetail;
        }[];
      }
    ) {
      let project: CheckListState = copy(state);
      let error = project.error;
      payload.forEach((key) => {
        if (key.parentKey === "basic_details") {
          if (
            Array.isArray(project.basic_details[key.childKey as keyof CheckListDetail]) &&
            key.childKey === "categories"
          ) {
            let errors: Array<CategoryItemError> = [];
            (
              project.basic_details[key.childKey as keyof CheckListDetail] as Array<Category>
            ).forEach((w, index) => {
              errors[index] = { items: [] };
              w.items.forEach((item) => {
                if (item.name === "") {
                  errors[index].items.push(true);
                } else {
                  errors[index].items.push(false);
                }
              });
            });
            (error[key.childKey as keyof CheckListDetail] as Array<CategoryItemError>) = errors;
          }

          if (
            Array.isArray(project.basic_details[key.childKey as keyof CheckListDetail]) &&
            key.childKey === "checklist_tags"
          ) {
            if (
              (
                project.basic_details[
                  key.childKey as keyof CheckListDetail
                ] as Array<SelectBoxOption>
              ).length <= 0
            ) {
              (error[key.childKey as keyof CheckListDetail] as boolean) = true;
            }
          }

          if (!project.basic_details[key.childKey as keyof CheckListDetail]) {
            (error[key.childKey as keyof CheckListDetail] as boolean) = true;
          }
        }
      });

      return { ...state, error };
    }
  }
});

export const {
  resetCheckListSlice,
  updateCheckListSliceByKeyValue,
  updateCheckListSlice,
  updateCheckListErrors
} = checkListSlice.actions;

export default checkListSlice.reducer;