import React from "react";
import { ProductNounSelectBoxInterface } from "./util";
import { ProductNounSelectBoxWithoutSeach } from "./withoutSearch";
import { ProductNounSelectBoxWithSearch } from "./withSearch";

export const ProductNounSelectBox: React.FC<ProductNounSelectBoxInterface> = (props) => {
    if (props.withSearchAPI) {
        return <ProductNounSelectBoxWithSearch {...props} />
    } else {
        return <ProductNounSelectBoxWithoutSeach {...props} />
    }
};
