import { Box } from "@mui/material";
import { useSnackbar } from "notistack";
import React from "react";
import { matchPath, useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { withNavBars } from "../../HOCs";
import { Layout, Documents } from "../../components";
import { useAppDispatch, useAppSelector } from "../../redux";
import { useLazyGetProjectByIdQuery, useLazyGetDocumentsByTagGroupIdQuery, useGetLocationAndDocumentTagsQuery, useLinkModuleMutation, useUnlinkModuleMutation } from "../../redux/services";
import { closeBackdrop, openBackdrop } from "../../redux/slices/backdrop";
import { closeConfirmationDialog, openConfirmationDialog } from "../../redux/slices/confirmationDialog";
import { resetProjectSlice, updateProjectSlice, linksData } from "../../redux/slices/project";
import { AppRoutes } from "../../router/routes";
import { BasicDetails } from "./basicDetails";
import { NavBars } from "./utils";
import { SelectBoxOption } from "../../interfaces";
import { ProjectMaterials } from "./material";
import { IssueTracker } from "./issueTracker";
import { ProjectBOMs } from "./bom";
import BOMReport from "../bomReport";
import ListView from "../listview";
import { Workbook } from "../workbook";
import { Dashboard } from "../drawioDiagrams/dashboard";
import { AllSearches } from "../aiInstantSearch/dashboard";
import { DataTableForm } from "../viewMaterial/dataSheet";
import { HasAccess } from "../../router/authorization";
import { PERMISSIONS } from "../../router/permission";
import { UserAccessManagement } from "../userAccessManagement";

const ViewProject: React.FC<{ children?: JSX.Element, currentPath: string }> = ({ currentPath }) => {

    const params = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useAppDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const [getProjectById, projectData] = useLazyGetProjectByIdQuery({ refetchOnReconnect: true, refetchOnFocus: true });
    const [linkDocument] = useLinkModuleMutation();
    const [unlinkDocument] = useUnlinkModuleMutation();
    const [getDocumentFolders, { currentData: data, isLoading }] = useLazyGetDocumentsByTagGroupIdQuery();
    const folderData = useGetLocationAndDocumentTagsQuery(null);
    const [searchParams] = useSearchParams();

    const projectId = params?.id ?? "Project ID";

    const isEdit = projectId !== ':id' && projectId !== "New";

    const technicalParameterPath = projectData?.data?.associations?.find((_: any) => _.object_type === "dataTableForm")?.object_details?.at(-1)?._id || "New";

    const { perm } = useAppSelector(store => store.auth.userDetails);

    const creatable = React.useMemo(() => HasAccess(perm, PERMISSIONS.PROJECT_CREATE), [perm]);
    const readable = React.useMemo(() => HasAccess(perm, PERMISSIONS.PROJECT_READ), [perm]);
    const updatable = React.useMemo(() => HasAccess(perm, PERMISSIONS.PROJECT_UPDATE), [perm]);
    const deletable = React.useMemo(() => HasAccess(perm, PERMISSIONS.PROJECT_DELETE), [perm]);
    const publishable = React.useMemo(() => HasAccess(perm, PERMISSIONS.PROJECT_PUBLISH), [perm]);

    const giveMeComponent = () => {
        // Basic Details
        if (matchPath(location.pathname, AppRoutes.viewProjectBasicDetail(projectId))) {
            return <BasicDetails refetch={() => {
                if (isEdit) {
                    dispatch(openBackdrop("Fetching project details"));
                    getProjectById({ id: projectId });
                }
            }} projectId={projectId} />
        }

        
        // Technical Parameter
        if (matchPath(location.pathname, AppRoutes.viewProjectTechParamForm(projectId, technicalParameterPath))) {
            return <DataTableForm type="inputParameter" preview={!updatable} refetch={refetch} getPath={(path: string) => AppRoutes.viewProjectTechParamForm(projectId, path)} linkDataTableTemplate={(links: any[], unlinks: any[]) => callLinkDocument('dataTableForm', links, unlinks)} />
        }

        // Documents
        if (matchPath(location.pathname, AppRoutes.viewProjectDocuments(projectId))) {
            return <Documents
                refetch={refetch}
                Id={projectData?.data?.sequence_id ?? ""}
                _id={projectId}
                objectType="project"
                tag_id={searchParams.get('tag_id')}
                tag_group_id={searchParams.get('group_id')}
                linkDocument={(links: any, refetch: any) => callLinkDocument('document', links, [], refetch)}
                associations={projectData?.data?.associations ?? []}
                onAnnotate={(id: string) => {
                    navigate(AppRoutes.projectAnnotateViewer(projectId, id), {
                        state: {
                            paths: [
                                { title: "Projects", path: AppRoutes.projects },
                                {
                                    title: `${projectData?.data?.sequence_id}`,
                                    path: AppRoutes.viewProjectBasicDetail(projectId),
                                },
                                { title: "Documents", path: AppRoutes.viewProjectDocuments(projectId) },
                            ]
                        }
                    })
                }}
                showHelperBtn={false}
                creatable={creatable}
                readable={readable}
                updatable={updatable}
                deletetable={deletable}
                publishable={publishable}
                hasCheckbox={updatable}
                unlinkDoc={(id: string, refetch: any) => callLinkDocument('document', [], [id], refetch)}
            />
        }

        // BOMs
        if (matchPath(location.pathname, AppRoutes.viewProjectBOM(projectId))) {
            return <ProjectBOMs refetch={refetch} projectId={projectId} sequence_id={projectData?.data?.sequence_id ?? "Add Project"} />
        }

        // Material
        if (matchPath(location.pathname, AppRoutes.viewProjectMaterial(projectId))) {
            return <ProjectMaterials refetch={refetch} projectId={projectId} sequence_id={projectData?.data?.sequence_id ?? "Add Project"} />
        }

        // BOM Report
        if (matchPath(location.pathname, AppRoutes.viewBOMReportOnProjectModule(projectId))) {
            return <BOMReport paths={[
                { title: "Projects", path: AppRoutes.projects },
                {
                    title: `${projectData?.data?.sequence_id ?? "Add Project"}`,
                    path: AppRoutes.viewProjectBasicDetail(projectId),
                },
            ]} object_type="project" />
        }

        // List View
        if (matchPath(location.pathname, AppRoutes.viewListViewOnProjectModule(projectId))) {
            return <ListView paths={[
                { title: "Projects", path: AppRoutes.projects },
                {
                    title: `${projectData?.data?.sequence_id ?? "Add Project"}`,
                    path: AppRoutes.viewProjectBasicDetail(projectId),
                },
            ]} object_type="project" />
        }

        // Workbook
        if (matchPath(location.pathname, AppRoutes.workbookOnProjectModule(projectId))) {
            return <Workbook paths={[
                { title: "Projects", path: AppRoutes.projects },
                {
                    title: `${projectData?.data?.sequence_id ?? "Add Project"}`,
                    path: AppRoutes.viewProjectBasicDetail(projectId),
                },
            ]} object_type="project" />
        }

        // IssueTracker
        if (matchPath(location.pathname, AppRoutes.viewIssueTrackerOnProject(projectId))) {
            return <IssueTracker 
                        paths={[
                            { title: "Projects", path: AppRoutes.projects },
                            {
                                title: `${projectData?.data?.sequence_id ?? "Add Project"}`,
                                path: AppRoutes.viewProjectBasicDetail(projectId),
                            },
                        ]} 
                        object_type="project" 
                        isNested={true}
                        readable={readable}
                        creatable={creatable}
                        updatable={updatable}
                        deletetable={deletable}
                    />
        }

        // IEDraw
        if (matchPath(location.pathname, AppRoutes.viewProjectIEDraw(projectId))) {
            return <Dashboard object_id={projectId} object_type="project" sequence_id={projectData?.data?.sequence_id ?? ""} />
        }

        // AI Search
        if (matchPath(location.pathname, AppRoutes.viewProjectAISearch(projectId))) {
            return <AllSearches
                object_id={projectId}
                object_type="project"
                paths={[...history, { label: "AI Search", path: AppRoutes.viewProjectAISearch(projectId) }]}
                documentsFilter={projectData?.data?.associations?.find((a: { object_type: string }) => a?.object_type === "document")?.object_details?.map((d: { _id: string }) => d?._id)}
                unlinkSearch={(unlinks: any[]) => callLinkDocument('instantSearch', [], unlinks)}
            />
        }

        // User Management
        if (matchPath(location.pathname, AppRoutes.manageUsersOnProject(projectId))) {
            return <UserAccessManagement updatable={updatable} isLoading={projectData.isFetching || projectData.isLoading} object_id={projectId} object_type="project" refetch={refetch} user_access={projectData?.data?.user_access} sequence_id={projectData?.data?.sequence_id ?? "..."} created_user={projectData?.data?.created_user} />
        }

        return <></>
    };

    const giveMeLinksAndUnlinks = (links: any[], unlinks: any[], object_type: string): linksData => {
        let obj: linksData = {
            links: [{ object_ids: links, object_type: object_type }],
            unlinks: [{ object_ids: unlinks, object_type: object_type }],
        }
        return obj;
    }

    const callLinkDocument = async (object_type: string, links?: any[], unlinks?: any[], refetch?: any) => {
        let action = (unlinks || [])?.length > 0 ? "Unlinking" : "Linking";
        let actionSuccess = (unlinks || [])?.length > 0 ? "Unlinked" : "Linked";
        let actionError = (unlinks || [])?.length > 0 ? "Unlink" : "Link";
        dispatch(openBackdrop(`${action} the ${object_type}...`));
        const linkedData = giveMeLinksAndUnlinks(links || [], unlinks || [], object_type);
        let res: any = {};
        try {
            if (action === "Linking") {
                res = await linkDocument({ object_id: projectId, object_type: "project", payload: { links: linkedData.links } })
            } else {
                res = await unlinkDocument({ object_id: projectId, object_type: "project", payload: { unlinks: linkedData.unlinks } });
            }
            if (Object.keys(res).includes("data")) {
                enqueueSnackbar(`${actionSuccess} ${object_type} Successfully!`, { variant: "success" })
                dispatch(closeConfirmationDialog());
                getProjectById({ id: projectId });
                refetch && refetch();
            } else if (Object.keys(res).includes("error")) {
                // eslint-disable-next-line 
                throw res.error.data;
            }
        } catch (error: any) {
            let errorMessage: string = error?.title ?? error ?? `Oops! Something went wrong, Unable to ${actionError} ${object_type}`
            enqueueSnackbar(errorMessage, { variant: "error" });
        } finally {
            dispatch(closeBackdrop());
        }
    }

    const refetch = () => {
        if (isEdit) {
            refetchDocumentFolders();
            getProjectById({ id: projectId })
        }
    }

    const refetchDocumentFolders = () => {
        getDocumentFolders({ tag_group_id: folderData.data?.locationId, object_type: "project", object_ids: [projectId] })
    };

    React.useEffect(() => {
        if (projectId === ':id') {
            dispatch(closeBackdrop());
            navigate(AppRoutes.projectDashboard);
        }
    });

    React.useEffect(() => {
        if (isEdit) {
            dispatch(openBackdrop("Fetching Project Details..."));
            getProjectById({ id: projectId });
            refetchDocumentFolders();
        } else {
            dispatch(resetProjectSlice());
        }
        // eslint-disable-next-line    
    }, [params])

    React.useEffect(() => {
        const updateValue = async () => {
            if (isEdit && !projectData.isFetching && projectData.isSuccess) {
                let tags: { type: SelectBoxOption | null, businessUnit: SelectBoxOption | null, tags: SelectBoxOption[], status: SelectBoxOption | null } = { type: null, businessUnit: null, tags: [], status: null };
                projectData.data?.tags_info?.forEach((_: any) => {
                    if (_.tag_group_name === 'Business Units') {
                        tags.businessUnit = {
                            value: _?.tag_id ?? "",
                            label: _?.tag_name ?? "",
                            ..._
                        }
                    }

                    if (_.tag_group_name === 'Project Category') {
                        tags.type = {
                            value: _?.tag_id ?? "",
                            label: _?.tag_name ?? "",
                            ..._
                        }
                    }

                    if (_.tag_group_name === 'Project General Tag') {
                        tags.tags.push({
                            value: _?.tag_id ?? "",
                            label: _?.tag_name ?? "",
                            ..._
                        })
                    }

                    if (_.tag_group_name === 'Project Status') {
                        tags.status = {
                            value: _?.tag_id ?? "",
                            label: _?.tag_name ?? "",
                            ..._
                        }
                    }
                })
                dispatch(updateProjectSlice({
                    basic_details: {
                        project_name: projectData.data?.project_name ?? "",
                        type: tags.type,
                        description: projectData.data?.description ?? "",
                        cost_center: projectData.data?.cost_center,
                        businessUnit: tags.businessUnit,
                        customer: projectData.data?.customer_name ?? "",
                        projectManager: projectData.data?.project_manager ?? "",
                        status: tags.status,
                        reference_number: projectData.data?.reference_number,
                        start_date: projectData.data?.start_date ?? "",
                        finish_date: projectData.data?.finish_date ?? ""
                    },
                    notes: atob(projectData?.data?.notes ?? ""),
                    project_tags: {
                        tags: tags.tags
                    },
                    links_data: {
                        links: [],
                        unlinks: []
                    },
                    attachments: projectData?.data?.attachments ?? [],
                    associations: projectData?.data?.associations ?? [],
                    sequenceId: projectData?.data?.sequence_id,
                    version: projectData?.data?.version ?? ""
                }))
                dispatch(closeBackdrop());
            }
        }
        updateValue();
        // eslint-disable-next-line
    }, [projectData]);

    React.useEffect(() => {
        if (!projectData.isFetching && projectData.isError) {
            enqueueSnackbar('404 - Unable to find the requested project details', { variant: "error" });
            dispatch(openConfirmationDialog({
                title: "404 - Not Found",
                body: "Unable to find the requested project",
                positiveBtn: "Ok",
                onOk: () => {
                    dispatch(closeConfirmationDialog())
                    navigate(AppRoutes.projectDashboard)
                },
                hideNegativeBtn: true
            }))
            dispatch(closeBackdrop());
        }
        // eslint-disable-next-line
    }, [projectData.isError])

    React.useEffect(() => {
        if (folderData.isSuccess) {
            refetchDocumentFolders();
        }
        // eslint-disable-next-line
    }, [folderData.isSuccess]);

    const documentFolders = data?.filter((_: any) => _.object_id === projectId)?.[0] ?? { tags: [], tag_group_id: "" };

    const history = [
        { label: "Projects", path: AppRoutes.projects },
        { label: `${projectData?.data?.sequence_id ?? "Add Project"}`, path: AppRoutes.viewProjectBasicDetail(projectId) }
    ]

    return <Box p={0.5} height={"100%"}>
        <Layout
            history={history}
            currentPath={currentPath}
            navBars={NavBars(projectId, isEdit, isLoading || folderData.isLoading, documentFolders.tags ?? [], documentFolders?.tag_group_id ?? "", technicalParameterPath)}
            mainPanel={giveMeComponent()}
            sideNavVariant={"whiteboard"}
            onBackBtnClick={() => navigate(AppRoutes.projects)}
            resizable
        />
    </Box>
}

export default withNavBars(ViewProject);