import { Box, Chip, Collapse, List, ListItemButton, Typography } from "@mui/material";
import React from "react";
import { ExpandLess, ChevronRight as ExpandMore } from '@mui/icons-material';
import { SelectBoxOption } from "../../interfaces";

export const CollapsableOption: React.FC<{
    id: string,
    title: string,
    options: SelectBoxOption[],
    value: SelectBoxOption | null,
    isMandatory?: boolean,
    onChange: (data: SelectBoxOption | null) => any
}> = (props) => {

    const [isExpand, setIsExpand] = React.useState(false);

    const toogle = () => { setIsExpand(!isExpand) }

    React.useEffect(() => {
        if (props.options?.length === 1) {
            props.onChange(props.options[0])
        }
        // eslint-disable-next-line
    }, [props.title])

    return <Box>
        <Box
            id={props.id}
            onClick={toogle}
            sx={{
                cursor: "pointer", width: "100%", padding: 0.5,
                '&:hover': { background: (theme) => theme.palette.action.hover, borderRadius: 1 }
            }}
            display={"flex"} alignItems={"center"} justifyContent={"space-between"}>

            {/* Collapse Title */}
            <Typography
                variant={"body2"}
                sx={{ fontFamily: "htrts_regular", color: (theme) => theme.palette.text.secondary }}
            >
                {props.title}
                {props.isMandatory && <Typography color={"error"} variant="caption">&nbsp;*</Typography>}
                {/* Selected Option */}
                {props.value?.value && <Typography variant="caption">
                    &nbsp;&nbsp;&nbsp;<Chip size="small" label={props.value.label} onDelete={(e) => { toogle(); props.onChange(null) }} />
                </Typography>}
            </Typography>

            {/* Expand & Collapse Icon Button */}
            {isExpand ? <ExpandLess sx={{ color: (theme) => theme.palette.text.secondary }} /> : <ExpandMore sx={{ color: (theme) => theme.palette.text.secondary }} />}

        </Box>
        <Collapse in={isExpand}>
            <List sx={{ maxHeight: 150, overflow: 'scroll' }}>
                {props.options.map((option, key) => {
                    const isSelected = option.value === props.value?.value;
                    return <ListItemButton sx={{
                        background: (theme) => isSelected ? theme.palette.primary.dark : "unset",
                        borderRadius: '8px',
                        color: isSelected ? "white" : "unset",
                        padding: 0.25,
                        paddingLeft: 2,
                        paddingRight: 2,
                        marginBottom: 1,
                        marginLeft: 0.25,
                        marginRight: 0.25,
                        '&:hover': {
                            background: (theme) => isSelected ? theme.palette.primary.dark : theme.palette.background.paper,
                            border: (theme) => `1px solid ${theme.palette.primary.dark}`,
                            color: (theme) => isSelected ? "white" : theme.palette.primary.dark
                        }
                    }}
                        onClick={(e) => { toogle(); props.onChange(option) }}
                        key={key}
                        id={`${props.id}-${key}`}
                    >
                        {option.label}
                    </ListItemButton>
                })}
            </List>
        </Collapse>
    </Box>
}