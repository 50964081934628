// import { ArrowRightAlt } from '@mui/icons-material';
import { KeyboardBackspace } from '@mui/icons-material';
import { Box, Grid, IconButton, Tooltip, Typography } from '@mui/material';
import React from 'react';
import { NounSelectBox, SelectBox } from '../../components';
import { SelectBoxOption } from '../../interfaces';
import { useGetUoMQuery } from '../../redux/services';

export const SearchBarSection: React.FC<{
    UOM: SelectBoxOption | null,
    noun: SelectBoxOption | null,
    onUOMSelected: any,
    onNounSelected: any,
    goBack: any
}> = ({ UOM, noun, onUOMSelected, onNounSelected, goBack }) => {

    const { data: allData = { data: [], totalCount: 0 }, isFetching: isLoading, isError, isSuccess } = useGetUoMQuery({ page: null, pageSize: null }, { refetchOnFocus: true, refetchOnMountOrArgChange: true });
    const { data } = allData;

    React.useEffect(() => {
        if (!isLoading && data.length > 0) {
            onUOMSelected({ ...data[0], value: data[0]._id, label: data[0].uom_name })
        }
        // eslint-disable-next-line
    }, [isSuccess])

    return <Box sx={{
        boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px',
        background: (theme) => theme.palette.background.paper,
        borderRadius: 2,
        padding: 1.5
    }} position={"relative"}>
        {/* Search Bar.Container */}
        <Grid
            container
            justifyContent={"center"}
            alignItems={"center"}
            spacing={2}
        >

            {/* Select Box */}
            <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
                <SelectBox
                    // label='UoM'
                    placeholder='Regions'
                    value={UOM}
                    options={data?.map((_: any) => ({ value: _._id, label: _.uom_name }))}
                    onChange={onUOMSelected}
                    isRequired={true}
                    loading={isLoading}
                    error={isError}
                    helperText={isError ? "Opps! Unable to load UoM." : ""}
                />
            </Grid>

            {/* Search Bar */}
            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                <NounSelectBox
                    // label='Search Noun'
                    withSearchAPI={true}
                    placeholder='Search Noun'
                    value={noun}
                    onChange={onNounSelected}
                    isRequired={true}
                    uom_id={UOM?.value}
                />
            </Grid>

            {/* Note */}
            <Grid item xs={12} sm={6} md={6} lg={3} xl={3}>
                <Typography variant="caption" sx={{ fontStyle: "italic" }} color="grey">*Note: Some images are AI-generated</Typography>
            </Grid>

            {/* Select noun button */}
            {/* <Grid item xs={12} sm={6} md={2} lg={2} xl={2}>
                <Button
                    variant='contained'
                    onClick={onSelectBtnClicked}
                    sx={{ marginTop: 3.5 }}>
                    Select <ArrowRightAlt sx={{ marginLeft: 2 }} />
                </Button>
            </Grid> */}
        </Grid>

        {/* Back Button */}
        {noun && <Tooltip title={"Go back to Noun Listing"}>
            <IconButton onClick={goBack} sx={{ position: "absolute", left: 4, top: 12, background: (theme) => theme.palette.grey[100] }}>
                <KeyboardBackspace color="primary" />
            </IconButton>
        </Tooltip>
        }
    </Box>
}