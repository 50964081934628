import React from 'react';
import { Grid, Box, useTheme, Theme, Collapse, Drawer } from '@mui/material';
import { LeftPanel } from './leftPanel';
import { RightPanel } from './rightPanel';
import { allMasters as getMasters, MasterValue } from './util';
import { withNavBars } from '../../HOCs';
import { MastersInterface, SelectBoxOption } from '../../interfaces';
import { useLazyGetProductFamilyQuery, useLazyGetProductGroupsQuery, useLazyGetProductAttributesQuery, useLazyGetProductNounsQuery } from '../../redux/services';
import { BreadScrumHeader } from '../../components';
import { initialPaginationState, initialSortingState } from '../mdUpdate';
import { useAppSelector } from '../../redux';
import { HasAccess } from '../../router/authorization';
import { PERMISSIONS } from '../../router/permission';
import { GridSortModel } from '@mui/x-data-grid-premium';

const useStyles = (theme: Theme) => ({
    root: {
        height: '100%',
        flexGrow: 1,
        padding: theme.spacing(1),
    },
    container: {
        height: 'calc(100% - 60px)',
        overflow: "scroll",
        marginTop: { xs: 0, sm: theme.spacing(2) }
    },
    rightPanel: (openWebNav: boolean = true) => ({
        paddingTop: { xs: theme.spacing(1), sm: 0 },
        height: { xs: "auto", sm: "100%" },
        paddingLeft: { xs: 0, sm: openWebNav ? theme.spacing(2) : 0 }
    })
})

const ProductRepository: React.FC<{ children?: JSX.Element }> = (props) => {

    const classes = useStyles(useTheme());
    const { perm } = useAppSelector(store => store.auth.userDetails);

    // All Queries for masters
    const [getFamily, familyD] = useLazyGetProductFamilyQuery({ refetchOnFocus: true });    
    const [getGroup, groupD] = useLazyGetProductGroupsQuery({ refetchOnFocus: true });    
    const [getProductNoun, productNounD] = useLazyGetProductNounsQuery({ refetchOnFocus: true });    
    const [getAttribute, attributeD] = useLazyGetProductAttributesQuery({ refetchOnFocus: true });

    const creatable = React.useMemo(() => HasAccess(perm, PERMISSIONS.PRODUCT_REPO_CREATE), [perm]);
    const readable = React.useMemo(() => HasAccess(perm, PERMISSIONS.PRODUCT_REPO_READ), [perm]);
    const updatable = React.useMemo(() => HasAccess(perm, PERMISSIONS.PRODUCT_REPO_UPDATE), [perm]);
    const deletable = React.useMemo(() => HasAccess(perm, PERMISSIONS.PRODUCT_REPO_DELETE), [perm]);

    const previlages = React.useMemo(() => {
        let rbac = [];
        if (creatable) rbac.push("C");
        if (readable) rbac.push("R");
        if (updatable) rbac.push("U");
        if (deletable) rbac.push("D");
        return rbac;
    }, [creatable, readable, updatable, deletable])

    const allMasters = React.useMemo(() => getMasters(previlages), [previlages]);
    
    const [state, setState] = React.useState<{ selectedMaster: MastersInterface, search: string, productNoun: any, family?: SelectBoxOption }>({ selectedMaster: allMasters?.[0], search: "", productNoun: null, family: { value: '', label: 'All' } });
    const [openWebNav, setOpenWebNavBar] = React.useState(true);
    const [openMobileNav, setOpenMobileNavBar] = React.useState(false);
    const [paginationModel, setPaginationModel] = React.useState(initialPaginationState);
    const [queryOptions, setQueryOptions] = React.useState(initialSortingState);
    
    const familyData = { ...familyD, data: familyD?.data?.data ?? [], currentData: familyD?.data?.data ?? [], totalCount: familyD?.data?.totalCount ?? 0 };
    const groupData = { ...groupD, data: groupD?.data?.data ?? [], currentData: groupD?.data?.data ?? [], totalCount: groupD?.data?.totalCount ?? 0 };
    const productNounData = { ...productNounD, data: productNounD?.data?.data ?? [], currentData: productNounD?.data?.data ?? [], totalCount: productNounD?.data?.totalCount ?? 0 };
    const attributeData = { ...attributeD, data: attributeD?.data?.data ?? [], currentData: attributeD?.data?.data ?? [], totalCount: attributeD?.data?.totalCount ?? 0 };

    const changeState = (key: string, value: string) => { setState({ ...state, [key]: value }) };

    const handleSortModelChange = React.useCallback((sortModel: GridSortModel) => {
        // Here you save the data you need from the sort model
        setQueryOptions({ sortModel: [...sortModel] });
    }, [])

    const giveMeMasterReleatedFunctions = () => {
        switch (state.selectedMaster?.value) {
            case MasterValue.family:
                return { getData: () => getFamily({ ...paginationModel, sort_by: queryOptions?.sortModel?.[0]?.field, sort_order: queryOptions?.sortModel?.[0]?.sort }), getDataProps: {...familyData, paginationModel} }

            case MasterValue.group:
                return { getData: () => getGroup({ ...paginationModel, sort_by: queryOptions?.sortModel?.[0]?.field, sort_order: queryOptions?.sortModel?.[0]?.sort }), getDataProps: {...groupData, paginationModel} }

            case MasterValue.productNoun:
                return { getData: () => getProductNoun({ ...paginationModel, sort_by: queryOptions?.sortModel?.[0]?.field, sort_order: queryOptions?.sortModel?.[0]?.sort }), getDataProps: { ...productNounData, familyData, paginationModel } }

            case MasterValue.attribute:
                return { getData: (payload: any) => getAttribute({ ...payload, ...paginationModel, sort_by: queryOptions?.sortModel?.[0]?.field, sort_order: queryOptions?.sortModel?.[0]?.sort }), getDataProps: { ...attributeData, familyData, paginationModel } }

            default:
                return { getData: (data: any) => false, getDataProps: {paginationModel, totalCount: 0} };
        }
    }

    const toogleWebNav = () => { setOpenWebNavBar(!openWebNav) };
    const toogleMobileNav = () => { setOpenMobileNavBar(!openMobileNav) };

    React.useEffect(() => {
        giveMeMasterReleatedFunctions().getData({ ...paginationModel, sort_by: queryOptions?.sortModel?.[0]?.field, sort_order: queryOptions?.sortModel?.[0]?.sort });
        // eslint-disable-next-line
    }, [state.selectedMaster, paginationModel, JSON.stringify(queryOptions?.sortModel)]);

    React.useEffect(() => {
        let isSearchWithNoun = state.selectedMaster.value === MasterValue.attribute;
        if (isSearchWithNoun && state.productNoun) {
            giveMeMasterReleatedFunctions().getData({id: state.productNoun?._id, ...paginationModel, sort_by: queryOptions?.sortModel?.[0]?.field, sort_order: queryOptions?.sortModel?.[0]?.sort });
        } else if (isSearchWithNoun) {
            giveMeMasterReleatedFunctions().getData({id: null, ...paginationModel, sort_by: queryOptions?.sortModel?.[0]?.field, sort_order: queryOptions?.sortModel?.[0]?.sort });
        } else {
            giveMeMasterReleatedFunctions().getData({...paginationModel, sort_by: queryOptions?.sortModel?.[0]?.field, sort_order: queryOptions?.sortModel?.[0]?.sort });
        }
        // eslint-disable-next-line
    }, [state.selectedMaster, paginationModel, state.productNoun, JSON.stringify(queryOptions?.sortModel)]);

    React.useEffect(() => {
        setPaginationModel(initialPaginationState);
        setQueryOptions(initialSortingState);
        // eslint-disable-next-line
    }, [state.productNoun]);

    return <Box sx={classes.root}>

        {/* Header */}
        <BreadScrumHeader
            openWebNav={openWebNav}
            toogleWebNav={toogleWebNav}
            toogleMobileNav={toogleMobileNav}
            history={[
                { label: "Product Module", onClick: () => false },
                { label: "Repository", onClick: () => { toogleWebNav() } }
            ]}
            currentPath={state.selectedMaster.label}
        />

        {/* Body */}
        <Grid
            container
            direction={"row"}
            sx={classes.container}
        >
            <Grid item xs={openWebNav ? 12 : 0} sm={openWebNav ? 3 : 0} md={openWebNav ? 2 : 0} sx={{ height: { xs: "auto", sm: "100%" }, display: { xs: "none", sm: "block" } }}>
                <Collapse in={openWebNav} timeout={1} orientation={"horizontal"} sx={{ height: { xs: "auto", sm: "100%" }, '.MuiCollapse-wrapperInner': { width: "100%" } }}>
                    <LeftPanel
                        selectedMaster={state.selectedMaster}
                        onMasterClicked={(master: any) => {
                            changeState('selectedMaster', master);
                            setPaginationModel(initialPaginationState);
                            setQueryOptions(initialSortingState);
                        }}
                        allMasters={allMasters}
                    />
                </Collapse>
            </Grid>

            <Grid item xs={12} sx={classes.rightPanel(openWebNav)} sm={!openWebNav ? 0 : 9} md={!openWebNav ? 0 : 10}>
                <RightPanel
                    selectedMaster={state.selectedMaster}
                    getMaster={giveMeMasterReleatedFunctions().getData}
                    {...giveMeMasterReleatedFunctions().getDataProps}
                    state={state}
                    setState={setState}
                    setPaginationModel={setPaginationModel}
                    handleSortModelChange={handleSortModelChange}
                />
            </Grid>
        </Grid>

        {/* Ṃobile Drawer */}
        <Drawer anchor="left" open={openMobileNav} onClose={toogleMobileNav}>
            <LeftPanel
                selectedMaster={state.selectedMaster}
                onMasterClicked={(master: any) => {
                    toogleMobileNav();
                    changeState('selectedMaster', master);
                    setPaginationModel(initialPaginationState);
                    setQueryOptions(initialSortingState);
                }}
                allMasters={allMasters}
            />
        </Drawer>
    </Box>
}

export default withNavBars(ProductRepository);