import React from "react";
import { Box } from "@mui/material";
import { withNavBars } from "../../HOCs";
import { Layout } from "../../components";
import { TagsModuleNavBars } from "../../utils";
import { TagListing } from "./tagListing";
import { useGetTagGroupsDDQuery } from "../../redux/services";

const Tags: React.FC<{ children?: JSX.Element }> = (props) => {

    const { data, } = useGetTagGroupsDDQuery({}, { refetchOnFocus: true, refetchOnMountOrArgChange: true });

    return <Box sx={{ height: "100%" }}>
        {/* Layout */}
        <Layout
            history={[
                { label: "Tags", onClick: () => false },
            ]}
            currentPath={"Dashboard"}
            navBars={TagsModuleNavBars(data)}
            navBarTitle={"Tag Categories"}
            mainPanel={<TagListing />}
            sideNavVariant={"whiteboard"}
        />
    </Box>
}

export default withNavBars(Tags);