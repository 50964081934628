import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../redux";
import { Box, Grid } from "@mui/material";
import { HighlightSelectBox } from "../../../components";
import { SelectBoxOption } from "../../../interfaces";
import { useGetUoMDropDownQuery, useGetGroupsDDQuery, useLazyGetNounVariantByNounIdDDQuery, useGetNounsDDQuery } from '../../../redux/services';
import { updateSelectionDetail, supplierMappingInterface } from "../../../redux/slices/supplierMapping";

export const MappingInfo: React.FC<{ children?: JSX.Element, index: number, data: supplierMappingInterface, selections: any, isEdit: boolean, initialSelections: any, readOnly?: boolean }> = ({ isEdit, index, data, selections, initialSelections, readOnly }) => {
    const dispatch = useAppDispatch();
    const uomData = useGetUoMDropDownQuery(null);
    const groups = useGetGroupsDDQuery(null);
    const nouns = useGetNounsDDQuery(null);
    const [getVariantsByNounId, nounVariant] = useLazyGetNounVariantByNounIdDDQuery();
    const { selectedNouns } = useAppSelector(store => store.supplierMap);

    const giveMeNouns = React.useCallback(() => {
        return nouns.data?.filter(_ => !selectedNouns.includes(_.value.toString())) ?? []
        // eslint-disable-next-line
    }, [selectedNouns])

    useEffect(() => {
        if (data?.noun_id?.value) {
            getVariantsByNounId(data?.noun_id?.value)
        }
        // eslint-disable-next-line
    }, [data?.noun_id?.value])

    return <Box pt={2}>
        <Grid container spacing={2} alignItems={"center"}>
            <Grid item xs={12} sm={4}>
                <HighlightSelectBox
                    id="vendor_region"
                    label={"Region"}
                    margin={"none"}
                    value={data?.uom_id}
                    loading={uomData.isLoading}
                    options={uomData?.data ?? []}
                    onChange={(val: SelectBoxOption) => dispatch(updateSelectionDetail({ index, ...data, uom_id: val, noun_id: null, noun_variant_ids: [] }))}
                    viewOnly={readOnly}
                />
            </Grid>
            <Grid item xs={12} sm={4}>
                <HighlightSelectBox
                    id="vendor_group"
                    label={"Group"}
                    margin={"none"}
                    viewOnly={readOnly}
                    value={data?.group_id}
                    loading={groups.isLoading}
                    options={groups?.data ?? []}
                    onChange={(val: SelectBoxOption) => dispatch(updateSelectionDetail({ index, ...data, group_id: val, noun_id: null, noun_variant_ids: [] }))}
                />
            </Grid>
            <Grid item xs={12} sm={4}>
                <HighlightSelectBox
                    id="vendor_noun"
                    label={"Noun"}
                    margin={"none"}
                    value={data?.noun_id}
                    loading={nouns.isLoading}
                    options={giveMeNouns()}
                    disabled={(data?.uom_id && data?.group_id && nouns?.data) ? false : true}
                    onChange={(val: SelectBoxOption) => dispatch(updateSelectionDetail({ index, ...data, noun_id: val, noun_variant_ids: [] }))}
                    viewOnly={readOnly}
                />
            </Grid>
            <Grid item xs={12} sm={12}>
                <HighlightSelectBox
                    id="vendor_noun_variant"
                    label={"Noun Variants"}
                    margin={"none"}
                    value={data?.noun_variant_ids}
                    multiple={true}
                    loading={nounVariant.isLoading || nounVariant.isFetching}
                    options={nounVariant?.data ?? []}
                    disabled={(data?.noun_id?.value && nounVariant?.data) ? false : true}
                    onChange={(val: SelectBoxOption) => dispatch(updateSelectionDetail({ index, ...data, noun_variant_ids: val }))}
                    viewOnly={readOnly}
                />
            </Grid>
        </Grid>
    </Box>
}