import { Edit, ExpandLess, ExpandMore, Save } from "@mui/icons-material";
import { Box, Button, Collapse, IconButton, Modal, Stack, Typography } from "@mui/material";
import React from "react";
import CloseIcon from '@mui/icons-material/Close';
import { MyPaper, TextEditor } from "../../../components";
import { useAppDispatch, useAppSelector } from "../../../redux";
import { updateMatSliceByKeyValue } from "../../../redux/slices/material";

export const Notes: React.FC<{ children?: JSX.Element, viewOnly?: boolean }> = ({viewOnly}) => {

    const { materials } = useAppSelector(store => store);
    const dispatch = useAppDispatch();

    const [open, updateOpen] = React.useState(false);
    const [edit, updateEdit] = React.useState(false);

    const updateState = (key: string, value: any) => dispatch(updateMatSliceByKeyValue({ key, value }));

    return <MyPaper padding={0}>
        <Box>
            {/* Heading */}
            <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"} sx={{ px: 2, py: 1, borderBottom: (theme) => `1px solid ${theme.palette.divider}` }}>

                <Typography variant="body1" fontFamily={"htrts_medium"}>
                    Notes
                </Typography>

                <Stack direction="row" spacing={1}>
                    {!viewOnly && <Button
                        id="open-notes"
                        variant="outlined"
                        color={edit ? 'success' : 'primary'}
                        size={"small"}
                        onClick={() => updateEdit(!edit)}
                        endIcon={edit ? <Save fontSize="small" /> : <Edit fontSize="small" />}
                    >
                        {edit ? 'Save' : 'Edit'}
                    </Button>}
                    <IconButton size={"small"} onClick={(e) => updateOpen(!open)}>
                        {!open && <ExpandMore />}
                        {open && <ExpandLess />}
                    </IconButton>
                </Stack>
            </Stack>

            {/* Main Section */}
            <Collapse in={open} timeout="auto" unmountOnExit>
                <Box sx={{
                    '& .sun-editor': {
                        border: edit ? '' : 'unset'
                    }
                }}>

                    {!edit && <TextEditor content={materials.notes} onChange={(note) => updateState('notes', note)} readOnly={true} />}
                </Box>
            </Collapse>
            <Modal open={edit} onClose={() => updateEdit(!edit)}>
                <Box flexDirection={"column"} sx={{ width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <Box sx={{ width: "80%" }}>
                        <MyPaper>
                            <>
                                <Box display={"flex"} width={"100%"} justifyContent={"flex-end"}>
                                    <IconButton
                                        aria-label="close"
                                        onClick={() => updateEdit(!edit)}
                                        sx={{
                                            justifyContent: "end",
                                            backgroundColor: '#fff',
                                            border: 'none',
                                            borderRadius: '5px',
                                            cursor: 'pointer',
                                            outline: 'none',
                                            transition: 'box-shadow 0.3s ease-in-out',
                                            ':hover, :focus': {
                                                boxShadow: '0px 0px 5px 2px rgba(103, 58, 183, 0.5)',
                                            },
                                            color: (theme) => theme.palette.grey[500],
                                            width: "fitContent",
                                            marginRight: '11px',
                                            marginBottom: '10px'
                                        }}
                                    >
                                        <CloseIcon />
                                    </IconButton>
                                </Box>
                                {/* <TextOnlyEditor defaultValue={materials.notes} height="250px" onChange={(note) => updateState('notes', note)} setOptions={{ buttonList: editorButtonList }} /> */}
                                <TextEditor content={materials.notes} onChange={(note) => updateState('notes', note)} placeholder="Enter the notes" />
                                <Stack spacing={1} direction="row" sx={{ marginTop: "12px" }} justifyContent={"center"}>
                                    <Button id="n-save" sx={{ width: "200px" }} onClick={() => updateEdit(!edit)} variant="contained">Save</Button>
                                    <Button id="n-cancel" sx={{ width: "200px" }} onClick={() => updateEdit(false)} variant="outlined">Cancel</Button>
                                </Stack>
                            </>
                        </MyPaper>
                    </Box>
                </Box>
            </Modal>

        </Box>

    </MyPaper>
}