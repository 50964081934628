import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';
import { SelectBoxOption } from '../../interfaces';
import { Chip, Stack, Tooltip, Typography } from '@mui/material';

export const HighlightSelectBox: React.FC<{
    id?: string,
    options: SelectBoxOption[],
    label: string,
    onChange: any,
    value: SelectBoxOption | SelectBoxOption[] | null,
    disabled?: boolean,
    size?: "small" | "medium",
    required?: boolean,
    error?: boolean,
    helperText?: string,
    loading?: boolean,
    margin?: "dense" | "normal" | "none",
    variant?: "standard" | "filled" | "outlined" | undefined,
    fullWdith?: boolean,
    multiple?: boolean,
    disableClearable?: boolean,
    viewOnly?: boolean,
    readOnly?: boolean
    onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void
}> = ({
    id = 'highlight-selectbox', options = [], label = "", onChange = () => null,
    value = { value: "", label: "" }, size = "small", margin = "normal", variant = "outlined", viewOnly = false, readOnly, onKeyDown = () => {}, ...props }) => {
        const enableGroupBy = options[0]?.prefix ? { groupBy: (option: SelectBoxOption) => option.prefix || '' } : {};

        let selectedValue = value && !Array.isArray(value) ? value?.label || "N/A" : "N/A";

        return viewOnly ? (
            <Stack sx={{ overflowWrap: "break-word" }}>
                <Typography variant="body1" sx={{ fontFamily: "htrts_medium" }}>{label}</Typography>
                {Array.isArray(value) && value.length > 0 ? <Stack
                    gap={1}
                    direction={"row"}
                    flexWrap={"wrap"}
                >
                    {
                        value.map(v =>
                            <Tooltip title={v?.label || "N/A"}>
                                <Chip size={"small"} label={v?.label || "N/A"} />
                            </Tooltip>
                        )
                    }
                </Stack>
                    : <Typography variant="body2" sx={{ fontFamily: "htrts_regular" }}>{selectedValue}</Typography>}
            </Stack>
        ) : (
            <Autocomplete
                // open
                id={id}
                disableClearable={props?.disableClearable || false}
                fullWidth={props.fullWdith}
                disabled={props?.disabled}
                loading={props?.loading}
                options={options}
                {...enableGroupBy}
                getOptionLabel={(option) => option.label}
                multiple={props?.multiple}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        variant={variant}
                        required={props.required}
                        error={props.error}
                        helperText={props.helperText}
                        label={label}
                        margin={margin}
                    />
                )}
                size={size}
                readOnly={readOnly}
                value={value}
                onChange={(e: any, newValue: any) => onChange(newValue)}
                renderOption={(props, option, { inputValue }) => {
                    const matches = match(option.label, inputValue);
                    const parts = parse(option.label, matches);

                    return (
                        <li {...props}>
                            <div>
                                {parts.map((part: any, index: any) => (
                                    <span
                                        id={`autocomplete-${index}`}
                                        key={index}
                                        style={{
                                            fontWeight: part.highlight ? 700 : 400,
                                        }}
                                    >
                                        {part.text}
                                    </span>
                                ))}
                            </div>
                        </li>
                    );
                }}
                isOptionEqualToValue={(option, value) => option.value === value.value}
                onKeyDown={onKeyDown}
            />
        );
    }
