import { ZoomOutMapTwoTone } from "@mui/icons-material";
import { Box, Chip, IconButton, Stack, Tooltip, Typography } from "@mui/material";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid-premium";
import { WIPIcon } from "../../../images/svg/WIPIcon";
import { getSourceDetail } from "../../materials/utils";
import { ListType } from "./preview";
import { DownloadDoc } from "../../../components";
import moment from "moment";
import { PublishedIcon } from "../../../images/svg/publishedIcon";

export const ListViewTree = (type: ListType, viewDataSheet: (row: any) => void, viewType: "flat" | "tree", openAssociation: (document: any) => any, openReferences: (document: any) => any): GridColDef[] => {
    let list: GridColDef[] = [];
    if (type === "material") {
        list = [
            {
                field: "attachments",
                headerName: "Image",
                width: 150,
                renderCell: (params: GridRenderCellParams) => {
                    return params?.row?.bomId ?
                        (
                            <Box>
                                <img
                                    height={"50px"}
                                    width={"50px"}
                                    src={
                                        params?.row?.attachments?.length > 0
                                            ? params?.row?.attachments?.at(-1)?.url
                                            : `/imageNotFound.png`
                                    }
                                    alt={params?.row?.attachments?.at(-1)?.file_name ?? ""}
                                />
                            </Box>
                        ) : null
                },
            },
            {
                field: 'version',
                width: 200,
                headerName: 'Revision',
            },
            {
                field: 'published',
                headerName: 'Status',
                width: 150,
                renderCell: (params: GridRenderCellParams) => {
                    return params?.row?.bomId ? (
                        <Stack px={3} py={2}>
                            <Tooltip
                                title={params?.row?.published ? "Published" : "Not Published"}
                                children={params?.row?.published
                                    ?
                                    <div><PublishedIcon /></div>
                                    :
                                    <div><WIPIcon /></div>
                                }
                            />
                        </Stack>
                    ) : null;
                },
            },
            {
                field: 'serial_num',
                width: 200,
                headerName: 'Tag Number',
            },
            {
                field: 'short_description',
                width: 200,
                headerName: 'Description',
            },
            {
                field: 'notes',
                width: 200,
                headerName: 'Notes',
            },
            {
                field: 'preview', headerName: 'Datasheet Preview', width: 150, renderCell: (params: GridRenderCellParams) => {
                    if (params.rowNode.type === 'group') {
                        return params.value;
                    }
                    return params?.row?.bomId ?
                        (
                            <IconButton size={"small"} onClick={() => params.row?.dataSheet ? viewDataSheet(params?.row) : {}} disabled={!params.row?.dataSheet}>
                                <ZoomOutMapTwoTone color={params.row?.dataSheet ? "info" : "disabled"} fontSize="small" />
                            </IconButton>
                        ) : null
                },
                valueGetter: (params: GridRenderCellParams) => params?.row?.bomId ?
                    (
                        params.row?.dataSheet?.tables?.map((table: any) => {
                            const tableName = `Table Name: ${table?.name}${table?.description ? ` (${table?.description})` : ''}`;

                            const columns = table?.columns?.map((col: any) => {
                                const columnContent = table?.rows?.map((row: any) => {
                                    return row?.column_content?.find((r: any) => r.field === col?.field)?.content || "-";
                                }).join("; ");

                                return `${col?.field}: ${columnContent}`;
                            }).join(" ");

                            return `${tableName} ${columns}`;
                        }).join(" ")
                    ) : ""
            },
            {
                field: 'reference_id',
                headerName: 'Ref. No.',
                width: 200
            },
            { field: "unit_region_name", headerName: "UoM", width: 150 },
            {
                field: "group_name",
                headerName: "Material Group",
                width: 250,
                renderCell: (params: GridRenderCellParams) => {
                    if (params.rowNode.type === "group") {
                        return params.value;
                    }
                    return params.row?.group_name ?
                        (
                            <Stack
                                spacing={1}
                                direction={"row"}
                                width="100%"
                                overflow={"scroll"}
                                id={"tagsChip"}
                            >
                                <Chip size={"small"} label={params.row?.group_name ?? ""} />
                            </Stack>
                        ) : null
                },
            },
            {
                field: "noun_name",
                headerName: "Noun",
                width: 200,
                renderCell: (params: GridRenderCellParams) => {
                    if (params.rowNode.type === "group") {
                        return params.value;
                    }

                    return params.row?.noun_name ?
                        (
                            <Stack
                                spacing={1}
                                direction={"row"}
                                width="100%"
                                overflow={"scroll"}
                                id={"tagsChip"}
                            >
                                <Chip size={"small"} label={params.row?.noun_name ?? ""} />
                            </Stack>
                        ) : null
                },
            },
            {
                field: "sourcing_type",
                headerName: "Sourcing Details",
                width: 200,
                renderCell: (params: GridRenderCellParams) => {
                    return (
                        <Typography variant="body2">{getSourceDetail(params?.row)}</Typography>
                    );
                },
                valueGetter: (params: GridRenderCellParams) => getSourceDetail(params?.row),
            },
            {
                field: 'material_cost',
                headerName: 'Material Cost',
                width: 150
            },
            {
                field: 'material_currency',
                headerName: 'Material Currency',
                width: 150
            },
            {
                field: 'lead_time_days',
                headerName: 'Lead Time (Days)',
                width: 150
            },
            {
                field: "spare_part",
                headerName: "Spare Part",
                renderCell: (params: GridRenderCellParams) => {
                    if (params.rowNode.type === "group") {
                        return params.value;
                    }
                    return params.row?.spare_part ?
                        (
                            <Typography variant="body2">{[true, "Yes"].includes(params.row?.spare_part) ? "Yes" : "No"}</Typography>
                        ) : null
                }
            },
            {
                field: 'spare_part_category',
                headerName: 'Spare Part Category',
                width: 150
            },
            {
                field: 'spare_part_quantity',
                headerName: 'Spare Part Qty',
                width: 150
            },
            {
                field: 'normal_quantity',
                headerName: 'Normal Qty',
                width: 150
            },
            {
                field: 'override_cost',
                headerName: 'Override Cost',
                width: 150
            },
            {
                field: "child_status",
                width: 180,
                headerName: "Material Status",
            },
        ]
    } else {
        list = [
            {
                field: 'bomId',
                width: 200,
                headerName: 'Document ID',
            },
            {
                field: 'version',
                width: 200,
                headerName: 'Revision',
            },
            {
                field: 'serial_num',
                width: 200,
                headerName: 'Tag Number',
            },
            {
                field: 'document_name',
                width: 200,
                headerName: 'Document Name',
            },
            {
                field: "document_tag_type",
                headerName: "Document Tag Type",
                width: 200,
                renderCell: (params: GridRenderCellParams) => {
                    if (params.rowNode.type === "group") {
                        return params.value;
                    }

                    return params.row?.document_tag_type
                        ? <Tooltip title={params.row?.document_tag_type ?? ""}>
                            <Stack
                                spacing={1}
                                direction={"row"}
                                width="100%"
                                overflow={"scroll"}
                                id={"tagsChip"}
                            >
                                <Chip size={"small"} label={params.row?.document_tag_type ?? ""} />
                            </Stack>
                        </Tooltip>
                        : null
                },
            },
            {
                field: 'preview', headerName: 'Preview', width: 100, renderCell: (params: GridRenderCellParams) => {
                    return <DownloadDoc preview={true} id={params.row._id} url={params.row.url} status={params.row.status} fileType={params.row?.document_name?.split('.')?.pop() ?? "Unknown"} />
                }
            },
            {
                field: 'published',
                headerName: 'Status',
                width: 150,
                renderCell: (params: GridRenderCellParams) => {
                    return params?.row?.bomId ? (
                        <Stack px={3} py={2}>
                            <Tooltip
                                title={params?.row?.published ? "Published" : "Not Published"}
                                children={params?.row?.published
                                    ?
                                    <div><PublishedIcon /></div>
                                    :
                                    <div><WIPIcon /></div>
                                }
                            />
                        </Stack>
                    ) : null;
                },
            },
            { field: 'child_id', headerName: 'Associated Material/BOM', width: 200 },
            { field: 'child_description', headerName: 'Material/BOM Description', width: 200 },
            { field: 'created_user', headerName: 'Uploaded By', width: 150 },
            {
                field: 'created_time', headerName: 'Uploaded Date', width: 250, renderCell: (params: GridRenderCellParams) => {
                    return <Typography variant="body2">{moment(params.row.created_time).format('lll')}</Typography>
                }
            },
            { field: 'modified_user', headerName: 'Last modified by', width: 150 },
            {
                field: 'modified_time', headerName: 'Last modified date', width: 250, renderCell: (params: GridRenderCellParams) => {
                    if (params.rowNode.type === 'group') {
                        return params.value;
                    }
                    return <Typography variant="body2">{params.row.modified_time ? moment(params.row.modified_time).format('lll') : ""}</Typography>
                }
            },
            { field: 'released_user', headerName: 'Published by', width: 150 },
            {
                field: 'released_time', headerName: 'Published date', width: 250, renderCell: (params: GridRenderCellParams) => {
                    if (params.rowNode.type === 'group') {
                        return params.value;
                    }
                    return <Typography variant="body2">{params.row.released_time ? moment(params.row.released_time).format('lll') : ""}</Typography>
                }
            },
        ]
    }

    if (viewType === "flat" && type === "material") {
        let conditionalField = {
            field: "bomId",
            headerName: "Material ID",
            width: 150
        };

        list.unshift(conditionalField)
    }
    return list;
};