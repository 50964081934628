import { Alert, Box, useTheme } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { CustomDataGrid, TitleHeader } from "../../../components";
import { AppRoutes } from "../../../router/routes";
import { DashboardTableColumn } from "./utils";
import { resetAISearchSlice } from "../../../redux/slices/ai";
import { useAppDispatch, useAppSelector } from "../../../redux";
import { useDeleteInstantSearchMutation, useLazyGetInstantSearchesQuery, useUpdateInstantSearchMutation } from "../../../redux/services";
import { BreadScrumHeaderHistoryProp } from "../../../interfaces";
import { PERMISSIONS } from "../../../router/permission";
import { HasAccess } from "../../../router/authorization";
import { closeConfirmationDialog, openConfirmationDialog } from "../../../redux/slices/confirmationDialog";
import { closeBackdrop, openBackdrop } from "../../../redux/slices/backdrop";
import { useSnackbar } from "notistack";
import { GridSortDirection, GridSortModel } from "@mui/x-data-grid-premium";
import { useStyles } from "../../documentTemplates/styles";

export const AllSearches: React.FC<{ children?: JSX.Element, object_id?: string, object_type?: string, paths?: BreadScrumHeaderHistoryProp[], documentsFilter?: string[], unlinkSearch?: (unlinks: Array<string>) => Promise<void> }> = (props) => {

    const classes = useStyles(useTheme());
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { enqueueSnackbar } = useSnackbar();

    const [getInstantSearch, { data: allData = { data: [], totalCount: 0 }, isLoading, isFetching, isError }] = useLazyGetInstantSearchesQuery();
    const { data, totalCount } = allData;

    const [updateSearch] = useUpdateInstantSearchMutation();

    const [deleteSearch] = useDeleteInstantSearchMutation();

    const [paginationModel, setPaginationModel] = React.useState({ page: 0, pageSize: 10 });

    const [search, setSearch] = React.useState("");

    const [queryOptions, setQueryOptions] = React.useState({
        sortModel: [{ field: 'sequence_id', sort: 'desc' as GridSortDirection }],
    });

    const { perm } = useAppSelector(store => store.auth.userDetails);

    const creatable = HasAccess(perm, PERMISSIONS.AI_SEARCH_CREATE);

    const addEditSearch = (data: any, isEdit: boolean = true) => {
        dispatch(resetAISearchSlice())
        navigate(AppRoutes.viewAIAllSearcheBasicDetails(isEdit ? data._id : "New"), { state: { paths: props.paths ?? [], object_id: props?.object_id, object_type: props?.object_type, parentData: { object_id: props.object_id, object_type: props.object_type } } })
    }

    const deleteInstantSearch = (row: any) => {
        dispatch(
            openConfirmationDialog({
                title: "Are you sure?",
                body: `Do you want to delete the ai search ${row.sequence_id}?`,
                positiveBtn: "Delete",
                negativeBtn: "Cancel",
                onOk: () => performRowDel(row),
                onNegativeBtn: () => dispatch(closeConfirmationDialog()),
            })
        );
    }

    const deleteAI = async (row: any) => {
        dispatch(openBackdrop("Deleting AI Search..."));
        let res: any = {};
        try {
            res = await deleteSearch(row._id)

            if (Object.keys(res).includes("data")) {
                enqueueSnackbar(`Deleted AI Search Successfully!`, { variant: "success" })
                dispatch(closeConfirmationDialog());
                getInstantSearch({ ...paginationModel, object_id: props.object_id, object_type: props.object_type });
            } else if (Object.keys(res).includes("error")) {
                // eslint-disable-next-line 
                throw res.error.data;
            } else {
                // eslint-disable-next-line 
                throw "Data not found"
            }
        } catch (error: any) {
            let errorMessage: string = error?.title ?? "Oops! Something went wrong, Unable to delete"
            enqueueSnackbar(errorMessage, { variant: "error" });
        } finally {
            dispatch(closeBackdrop());
        }
    }

    const callUnlinkDocument = async (id: string, unlinks: string[]) => {
        if (unlinks?.length > 0) {
            dispatch(openBackdrop(`unlinking the document...`));

            let code;

            let payload = unlinks ? { unlinks: [{ object_ids: unlinks, object_type: "document" }] } : {};
    
            await updateSearch({ id, payload }).then((res: any) => {
                if (res.error) {
                    code = 0;
                    enqueueSnackbar(res?.error?.data?.title ?? `Something went wrong, Unable to unlink document.`, { variant: "error" });
                } else {
                    getInstantSearch({ ...paginationModel, object_id: props.object_id, object_type: props.object_type });
                    code = 1;
                }
            }).catch(err => {
                enqueueSnackbar(`Something went wrong, Unable to unlink document.`, { variant: "error" });
                code = 0;
            });
            dispatch(closeBackdrop());

            if (code) {
                return  code;
            }

            if (!code) {
                throw  code;
            }
        }
    };

    const performRowDel = async (row: any) => {
        const unlinks = row?.associations?.find((o: any) => o.object_type === "document")?.object_details?.map((e: any) => e?._id);
        if (props.unlinkSearch) {
            await callUnlinkDocument(row?._id, unlinks).then(async (res) => {
                if (props.unlinkSearch) {
                    await props.unlinkSearch([row?._id]).then(() => {
                        deleteAI(row);
                    }).catch((error) => {
                        console.log(`Error Unlinking ${error}`);
                        enqueueSnackbar("Error deleting ai search", { variant: "error" });
                    });
                }
            })
        } else {
            await callUnlinkDocument(row?._id, unlinks).then(async (res) => {
                deleteAI(row);
            })
        }
    }

    const giveMeRows = () => {
        return data;
    }

    let ROWS = giveMeRows();

    const handleSortModelChange = React.useCallback((sortModel: GridSortModel) => {
        // Here you save the data you need from the sort model
        setQueryOptions({ sortModel: [...sortModel] });
    }, [])

    React.useEffect(() => {
        getInstantSearch({ 
            ...paginationModel, 
            object_id: props.object_id, 
            object_type: props.object_type,
            sort_by: queryOptions?.sortModel?.[0]?.field, 
            sort_order: queryOptions?.sortModel?.[0]?.sort,
        });
        // eslint-disable-next-line
    }, [paginationModel, JSON.stringify(queryOptions?.sortModel)])

    return <Box sx={classes.root}>
        {/* Header */}
        <Box>
            <TitleHeader
                title="All Searches"
                count={totalCount}
                showSearch={true}
                search={search}
                onSearchChange={setSearch}
                searchPlaceholder={"Search"}
                showCreateBtn={creatable}
                createBtnLabel={"New Search"}
                onCreateBtn={() => addEditSearch(null, false)}
            />
        </Box>

        {/* Error */}
        {isError && <Alert sx={{ mt: 2 }} severity="error">Oops! Something went wrong, Unable to fetch Searches. Try Again Later!</Alert>}


        {/* Data Drid */}
        <Box mt={2}>
            <CustomDataGrid
                id={`${props?.object_type ? `${props?.object_type}-` : ""}aisearch-list`}
                // initialState={{
                //     sorting: {
                //         sortModel: [{ field: 'sequence_id', sort: 'desc' }],
                //     },
                // }}
                rows={ROWS}
                columns={DashboardTableColumn(addEditSearch, deleteInstantSearch, props.object_id ? true : false)}
                loading={isLoading || isFetching}
                getRowId={(row) => row._id}
                showToolbar={true}
                rowCount={totalCount}
                paginationModel={paginationModel}
                paginationMode="server"
                onPaginationModelChange={setPaginationModel}
                sortingMode="server"
                onSortModelChange={handleSortModelChange}
            />
        </Box>
    </Box>
}