import { AccountTree, AlignHorizontalLeft, Biotech, DataArray, DocumentScanner, TextSnippetOutlined } from "@mui/icons-material";
import { SelectBoxOption } from "../../interfaces";

export const BOMTypeOptions: SelectBoxOption[] = [{ value: "individual", label: "Individual Part ID" }, { value: "assembled", label: "Assembly ID" }, { value: "block", label: "Area ID" }];

export const NavBars = (id: string, isEdit: boolean = false, paths: Array<string>) => ([
    {
        id: "1",
        title: "Basic Details",
        icon: <AlignHorizontalLeft fontSize="small" />,
        path: paths[0]
    },
    {
        id: "2",
        title: "Documents",
        disabled: !isEdit,
        icon: <DocumentScanner fontSize="small" />,
        path: paths[1]
    },
    {
        id: "3",
        title: "Datasheet",
        disabled: !isEdit,
        icon: <DataArray fontSize="small" />,
        path: paths[2]
    },
    {
        id: "4",
        title: "BOM Details Preview",
        disabled: !isEdit,
        icon: <Biotech fontSize="small" />,
        path: paths[5]
    },
    {
        id: "5",
        title: "BOM",
        disabled: !isEdit,
        icon: <AccountTree fontSize="small" id="bom-sidebar" />,
        path: paths[3],
        subNav: [
            {
                id: "5.1",
                title: "BOM Tree",
                icon: <AccountTree fontSize="small" id="bom-tree-sidebar" />,
                path: paths[3],
            },
            {
                id: "5.2",
                title: "Document Tree",
                icon: <TextSnippetOutlined fontSize="small" />,
                path: paths[4],
            }
        ]
    },
])

export const DGTypeOptions = [{ value: "Generate using DG", label: "Generate using DG" }, { value: "Manually Enter the Description", label: "Manually Enter the Description" }];

export const MDGTypeOptions = [{ value: "Generate using MDG", label: "Generate using MDG" }, { value: "Manually Enter the Description", label: "Manually Enter the Description" }];

export const UOMOptions = [{ label: "Piece", value: "piece" }, { label: "Length", value: "length" }, { label: "Weight", value: 'weight' }];

export const LengthUnits = [{ value: "In", label: "In" }, { value: "Ft", label: "Ft" }, { value: "mm", label: "mm" }, { value: "cm", label: "cm" }, { value: "m", label: "m" }]

export const WeightUnits = [{ value: "gram", label: "gram" }, { value: "kg", label: "kg" }, { value: "mt", label: "mt" }, { value: "lb", label: "lb" }, { value: "m", label: "m" }]

export const StatusOptions = [{ value: "active", label: "Active" }, { value: "inactive", label: "Inactive" }]