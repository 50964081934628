import { Chip, Stack, Tooltip, Typography } from "@mui/material";
import { GridRenderCellParams } from "@mui/x-data-grid-premium";
import moment from "moment";
import { IconButton } from "@mui/material";
import { DeleteOutline, Link } from "@mui/icons-material";
import { TagInfo } from "../../../interfaces";
import { hexToGrayscale } from "../../../utils";

export const DashboardTableColumn = (
  onDelete: (row: any) => void,
  view: (row: any) => void,
  openAssociation: (row: any) => void,
  hasDeleteAccess: boolean
) => {
  let columns = [
    {
      field: "sequence_id",
      headerName: "Issue ID",
      width: 100,
      renderCell: (params: GridRenderCellParams) => {
        if (params.rowNode.type === "group") {
          return params.value;
        }
        return (
          <Typography
            onClick={() => view(params.row)}
            variant="body2"
            sx={{ color: "blue", cursor: "pointer", textDecoration: "underline" }}
          >
            {params.row.sequence_id}
          </Typography>
        );
      },
    },
    {
      field: "version",
      headerName: "Revision",
      width: 100,
      renderCell: (params: GridRenderCellParams) => {
        if (params.rowNode.type === "group") {
          return params.value;
        }
        return <Typography variant="body2">{params.row?.version}</Typography>;
      },
    },
    {
      field: "description",
      headerName: "Issue Description",
      width: 250,
      renderCell: (params: GridRenderCellParams) => {
        if (params.rowNode.type === "group") {
          return params.value;
        }
        return <Typography variant="body2">{params.row?.description}</Typography>;
      },
    },
    {
      field: "why_issue.why_description",
      headerName: "WhyOccurred(1st why)",
      width: 250,
      renderCell: (params: GridRenderCellParams) => {
        if (params.rowNode.type === "group") {
          return params.value;
        }
        return (
          <Typography variant="body2">
            {params.row?.why_issue?.[0]?.why_description}
          </Typography>
        );
      },
      valueGetter: (params: GridRenderCellParams) => {
        return params.row?.why_issue?.[0]?.why_description ?? "";
      },
    },
    {
      field: "tags_info.tag_name",
      headerName: "Issue Category",
      width: 150,
      renderCell: (params: GridRenderCellParams) => {
        if (params.rowNode.type === "group") {
          return params.value;
        }
        return (
          <Stack
            spacing={1}
            direction={"row"}
            width="100%"
            overflow={"scroll"}
            id={"tagsChip"}
          >
            {params.row.tags_info
              ?.filter((_: TagInfo) => _.tag_group_name === "Issue Category")
              .map((tag: TagInfo) => (
                <Tooltip
                  title={tag?.tag_name}
                  key={tag?.tag_name}
                  placement={"top"}
                >
                  <Chip
                    variant="outlined"
                    sx={{
                      borderColor: tag.tag_group_color,
                      backgroundColor: tag.tag_group_color,
                      color: `${
                        hexToGrayscale(tag.tag_group_color) > 150
                          ? "rgba(0, 0, 0, 0.87)"
                          : "#FFFFFF"
                      }`,
                    }}
                    size={"small"}
                    label={tag?.tag_name ?? ""}
                  />
                </Tooltip>
              ))}
          </Stack>
        );
      },
      valueGetter: (params: GridRenderCellParams) => {
        return (
          params.row?.tags_info
            ?.filter((t: TagInfo) => t.tag_group_name === "Issue Category")
            .map((t: TagInfo) => t?.tag_name)
            .join(",") ?? ""
        );
      },
    },
    {
      field: "action",
      headerName: "Issue Action",
      width: 150,
      renderCell: (params: GridRenderCellParams) => {
        if (params.rowNode.type === "group") {
          return params.value;
        }
        return <Typography variant="body2">{params.row?.action}</Typography>;
      },
    },
    {
      field: "link",
      headerName: "Link",
      width: 100,
      renderCell: (params: GridRenderCellParams) => {
        if (params.rowNode.type === "group") {
          return params.value;
        }
        return (
          <>
            <IconButton
              disabled={!params.row.associations?.length}
              onClick={() => openAssociation(params.row)}
            >
              <Link
                color={params.row.associations?.length ? "info" : "disabled"}
              />
            </IconButton>
          </>
        );
      },
      sortable: false,
      disableColumnMenu: true
    },
    {
      field: "change_req",
      headerName: "Change Request",
      width: 150,
      renderCell: (params: GridRenderCellParams) => {
        if (params.rowNode.type === "group") {
          return params.value;
        }
        return (
          <Typography variant="body2">
            {params.row?.change_req ? "Yes" : "No"}
          </Typography>
        );
      }
    },
    {
      field: "created_user",
      headerName: "Created By",
      width: 150,
      renderCell: (params: GridRenderCellParams) => {
        if (params.rowNode.type === "group") {
          return params.value;
        }
        return (
          <Typography variant="body2">{params.row?.created_user}</Typography>
        );
      },
    },
    {
      field: "created_time",
      headerName: "Date & Time",
      width: 180,
      renderCell: (params: GridRenderCellParams) => {
        if (params.rowNode.type === "group") {
          return params.value;
        }
        return (
          <Typography variant="body2">
            {moment(params.row?.created_time).format("lll")}
          </Typography>
        );
      },
    },
    {
      field: "modified_user",
      headerName: "Updated By",
      width: 150,
      renderCell: (params: GridRenderCellParams) => {
        if (params.rowNode.type === "group") {
          return params.value;
        }
        return (
          <Typography variant="body2">{params.row?.modified_user}</Typography>
        );
      },
    },
    {
      field: "modified_time",
      headerName: "Date & Time",
      width: 250,
      renderCell: (params: GridRenderCellParams) => {
        if (params.rowNode.type === "group") {
          return params.value;
        }
        return (
          <Typography variant="body2">
            {params.row.modified_time
              ? moment(params.row.modified_time).format("lll")
              : ""}
          </Typography>
        );
      },
    }
  ];

  if (hasDeleteAccess) {
    columns = [...columns, {
      field: "delete",
      headerName: "Delete",
      width: 100,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <IconButton onClick={() => onDelete(params.row)}>
            <DeleteOutline color="error" />
          </IconButton>
        );
      },
    }]
  }

  return columns;
};
