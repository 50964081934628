import { createSlice } from '@reduxjs/toolkit';
import copy from 'fast-copy';

interface AISearchSliceInterface {
    // Basic Details
    basic_details: { name: string, reason: string },
    documents: { document_id: string }[],

    error: {
        // Basic Details
        basic_details: { name: boolean, reason: boolean },
    }
}

const initialState: AISearchSliceInterface = {
    basic_details: { name: "", reason: "" },
    documents: [],
    error: {
        basic_details: { name: false, reason: false },
    }
}

export const aiSearchSlice = createSlice({
    name: 'aiSearch',
    initialState,
    reducers: {
        // To Slice by key value pair
        updateAISearchSliceByKeyValue(state, { payload }: { payload: { parentKey: string, childKey: string, value: any } }) {
            let newState: any = copy(state);
            newState.error[payload.parentKey][payload.childKey] = payload.value ? false : true;
            newState[payload.parentKey][payload.childKey] = payload.value;

            return { ...state, ...newState }
        },

        // To update errors
        updateErrors(state, { payload }: { payload: any[] }) {
            let aiSearch: any = copy(state);
            let error: any = aiSearch.error;
            payload.forEach((key: any) => {
                if (!aiSearch[key.parentKey][key.childKey]) {
                    error[key.parentKey][key.childKey] = true;
                }
            })

            return { ...state, error }
        },

        // To reset all values to initialValue
        resetAISearchSlice(state) {
            return { ...state, ...initialState }
        },

        // To Bulk Update the slice
        updateAISearchSlice(state, { payload }) {
            return { ...state, ...payload }
        }
    }
});

export const { updateAISearchSliceByKeyValue, updateAISearchSlice, resetAISearchSlice, updateErrors } = aiSearchSlice.actions;
export default aiSearchSlice.reducer;