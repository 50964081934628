import React, { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  Button,
  IconButton,
  Tooltip,
  Divider,
  Alert,
  Stack,
  TextField,
  Box,
  useMediaQuery,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useDispatch } from "react-redux";
import { closeDialog, openDialog } from "../../../../../redux/slices/dialog";
import {
  useGetSNAttributesQuery,
  useLazyGetSNTemplateByIdQuery,
  useGetSNGroupsDDQuery,
  useGetCategoryDropDownQuery,
  FormatType,
  FormatConfig,
  useGetSNNounsDDQuery,
  useUpdateSNTemplateMutation,
  useCreateSNTemplateMutation,
} from "../../../../../redux/services";
import { useSnackbar } from "notistack";
import { SelectBoxOption } from "../../../../../interfaces";
import { HighlightSelectBox, MyPaper } from "../../../../../components";
import { Delete, Edit, FlipToFront } from "@mui/icons-material";
import {
  closeBackdrop,
  openBackdrop,
} from "../../../../../redux/slices/backdrop";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { useNavigate, useParams } from "react-router-dom";
import AddEditField from "./AddEditField";

export interface IFormatConfig {
  attribute?: SelectBoxOption | null;
  option?: SelectBoxOption | null;
  type: FormatType;
  sequenceNumber?: string;
  label?: string;
  separator?: SelectBoxOption | null;
  position?: number;
}
export interface IFormatConfigError {
  attribute: boolean;
  option: boolean;
  sequenceNumber: boolean;
  label: boolean;
  separator: boolean;
}

export const separatorOptions: SelectBoxOption[] = [
  { value: "-", label: "-" },
  { value: ",", label: "," },
  { value: "/", label: "/" },
  { value: ".", label: "." },
  { value: "#", label: "#" },
];

const initialError = {
  sequenceName: false,
  noun: false,
  group: false,
  category: false,
  formatConfig: [],
};

const textFieldStyle = {
  ".MuiFilledInput-root": {
    color: "rgba(0, 0, 0, 0.87) !important",
    bgcolor: "rgba(0, 0, 0, 0.06) !important",
  },
  ".Mui-disabled": {
    color: "rgba(0, 0, 0, 0.87) !important",
    "-webkit-text-fill-color": "rgba(0, 0, 0, 0.87) !important",
  },
  ".MuiFilledInput-input": {
    color: "rgba(0, 0, 0, 0.87) !important",
    "-webkit-text-fill-color": "rgba(0, 0, 0, 0.87) !important",
  },
};

export const AddUpdateSNTemplate: React.FC<{
  children?: JSX.Element;
}> = (props) => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const navigate = useNavigate();

  const { id = "" } = useParams();

  const isEdit = id !== "" && id !== "isNew";

  const isMatch = useMediaQuery("(max-width: 1200px)");

  const [getSNTemplate, snTemplateData] = useLazyGetSNTemplateByIdQuery();

  const [createSNTemplate, createSNTemplateData] =
    useCreateSNTemplateMutation();
  const [updateSNTemplate, updateSNTemplateData] =
    useUpdateSNTemplateMutation();
  const snAttributeD = useGetSNAttributesQuery(
    { page: null, pageSize: null },
    {
      refetchOnMountOrArgChange: true,
    }
  );
  const snAttributeData = {
    ...snAttributeD,
    data: snAttributeD?.data?.data ?? [],
    currentData: snAttributeD?.data?.data ?? [],
    totalCount: snAttributeD?.data?.totalCount ?? 0,
  };

  const snNounData = useGetSNNounsDDQuery({});
  const snGroupsData = useGetSNGroupsDDQuery({});
  const snCategoryData = useGetCategoryDropDownQuery({});

  const [state, setState] = React.useState<{
    sequenceName: string;
    noun: SelectBoxOption | null;
    group: SelectBoxOption | null;
    category: SelectBoxOption | null;
    formatConfig: IFormatConfig[];
  }>({
    sequenceName: "",
    noun: null,
    group: null,
    category: null,
    formatConfig: [],
  });

  const [error, setError] = React.useState<{
    sequenceName: boolean;
    noun: boolean;
    group: boolean;
    category: boolean;
    formatConfig: IFormatConfigError[];
  }>(initialError);

  const [nounOptions, setNounOptions] = useState<SelectBoxOption[]>([]);

  useEffect(() => {
    if (id !== "" && id !== "isNew") {
      getSNTemplate({ id });
    }
    // eslint-disable-next-line
  }, [id]);

  const updateState = (
    key: string,
    value: string | SelectBoxOption | IFormatConfig[] | null
  ) => {
    setState({ ...state, [key]: value });
    if (Array.isArray(value)) {
      let errors: boolean[] = [];
      value.forEach((v, index) => {
        if (v.type === "Attribute" && !v.option && !v.attribute) {
          errors[index] = false;
        } else if (v.type === "Counter" && !v.sequenceNumber?.trim()) {
          errors[index] = false;
        } else if (v.type === "Separator" && !v.separator) {
          errors[index] = false;
        } else if (v.type === "Label" && !v.label?.trim()) {
          errors[index] = false;
        }
      });
      setError({ ...error, [key]: errors });
    } else {
      setError({ ...error, [key]: !value });
    }
  };

  const reorder = (
    list: IFormatConfig[],
    startIndex: number,
    endIndex: number
  ) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragEnd = (result: any) => {
    // dropped outside the list
    if (!result.destination || !result.source) {
      return;
    }

    const formatConfig = reorder(
      state.formatConfig,
      result.source.index,
      result.destination.index
    ).filter((f) => f);

    setState({ ...state, formatConfig });
  };

  const onCancelBtnClicked = () => {
    navigate(-1);
  };

  const isValidate = () => {
    let isValidate = true;

    if (state.sequenceName.trim() === "") {
      isValidate = false;
      enqueueSnackbar("Please enter the sequence name", {
        variant: "warning",
      });
      return isValidate;
    }

    if (!state.category) {
      isValidate = false;
      enqueueSnackbar("Please select the category", {
        variant: "warning",
      });
      return isValidate;
    }

    if (!state.noun) {
      isValidate = false;
      enqueueSnackbar("Please select the noun", {
        variant: "warning",
      });
      return isValidate;
    }

    if (!state.group) {
      isValidate = false;
      enqueueSnackbar("Please select the group", {
        variant: "warning",
      });
      return isValidate;
    }

    if (
      state.formatConfig.length === 0 ||
      state.formatConfig.find((f) => f.type === "Counter") === undefined
    ) {
      isValidate = false;
      enqueueSnackbar("Please add at least sequence number", {
        variant: "warning",
      });
      return isValidate;
    }

    for (let key = 0; key < state.formatConfig.length; key++) {
      const _ = state.formatConfig[key];
      if (_.type === "Attribute" && !(_?.attribute?.value ?? false)) {
        isValidate = false;
        enqueueSnackbar(`Please select the (Attribute ${key + 1}) value`, {
          variant: "warning",
        });
        break;
      }

      if (_.type === "Attribute" && !(_?.option?.value ?? false)) {
        isValidate = false;
        enqueueSnackbar(
          `Please select the Attribute (${
            _?.attribute?.label ?? `Attribute ${key + 1}`
          }) Option`,
          { variant: "warning" }
        );
        break;
      }

      if (_.type === "Separator" && !(_?.separator?.value ?? false)) {
        isValidate = false;
        enqueueSnackbar(`Please select the Separator ${key + 1} value`, {
          variant: "warning",
        });
        break;
      }

      if (_.type === "Counter" && !(_?.sequenceNumber?.trim() ?? false)) {
        isValidate = false;
        enqueueSnackbar(`Please enter the Sequence Number`, {
          variant: "warning",
        });
        break;
      }

      if (_.type === "Label" && !(_?.label?.trim() ?? false)) {
        isValidate = false;
        enqueueSnackbar(`Please enter the Label`, {
          variant: "warning",
        });
        break;
      }
    }

    return isValidate;
  };

  const onCreateUpdateBtnClicked = () => {
    if (isValidate()) {
      let formattedConfig = state.formatConfig.map((f, index) => {
        if (f.type === "Attribute") {
          return {
            attribute_id: f.attribute?.value,
            option_id: f.option?.value,
            position: index + 1,
            type: "Attribute",
            value: f.option?.option_abbr,
          };
        } else if (f.type === "Separator") {
          return {
            position: index + 1,
            type: "Separator",
            value: f.separator?.value,
          };
        } else if (f.type === "Counter") {
          return {
            position: index + 1,
            type: "Counter",
            value: f.sequenceNumber,
          };
        } else {
          return {
            position: index + 1,
            type: "Label",
            value: f.label,
          };
        }
      });
      let payload = {
        category_id: state.category?.value,
        description: "",
        group_id: state.group?.value,
        noun_id: state.noun?.value,
        status: "active",
        name: state.sequenceName,
        format_config: formattedConfig,
      };
      if (isEdit) {
        updateSNTemplate({
          id: snTemplateData.data._id,
          payload,
        });
      } else {
        createSNTemplate(payload);
      }
    } else {
      let errors: IFormatConfigError[] = [];
      state.formatConfig.forEach((v, index) => {
        if (errors[index]) {
          if (v.type === "Attribute") {
            if (!v.attribute) {
              errors[index].attribute = false;
            }
            if (!v.option) {
              errors[index].option = false;
            }
          } else if (v.type === "Counter" && !v.sequenceNumber?.trim()) {
            errors[index].sequenceNumber = false;
          } else if (v.type === "Separator" && !v.separator) {
            errors[index].separator = false;
          } else if (v.type === "Label" && !v.label?.trim()) {
            errors[index].label = false;
          }
        }
      });
      setError({
        ...error,
        noun: !state.noun,
        category: !state.category,
        group: !state.group,
        sequenceName: !state.sequenceName.trim(),
        formatConfig: errors,
      });
    }
  };

  const onSaveFormat = (index: number, data: IFormatConfig) => {
    const before = state.formatConfig.slice(0, index);
    const after = state.formatConfig.slice(index + 1);
    updateState("formatConfig", [...before, data, ...after]);
    dispatch(closeDialog());
  };

  const addUpdateField = (index: number, data?: IFormatConfig) => {
    // Format Config Count Check
    if (state.formatConfig.length === 10) {
      enqueueSnackbar("Max 10 Format can be mapped.", { variant: "warning" });
      return false;
    }

    dispatch(
      openDialog({
        title: "",
        hideNegativeBtn: true,
        hidePositiveBtn: true,
        body: (
          <AddEditField
            index={index}
            noun={state.noun}
            formatConfig={state.formatConfig}
            onSave={onSaveFormat}
            error={error.formatConfig[index]}
          />
        ),
      })
    );
  };

  const deleteRow = (index: number) => {
    // Checking the Format Config min count
    if (state.formatConfig.length === 1) {
      enqueueSnackbar("Min 1 Format must be mapped.", { variant: "warning" });
      return false;
    }

    // Deleting a Format
    let attrArray: IFormatConfig[] = state.formatConfig;
    attrArray.splice(index, 1);
    setState({
      ...state,
      formatConfig: attrArray,
    });
  };

  const giveMeButtonName = () => {
    if (createSNTemplateData.isError) {
      return `${isEdit ? "Retry Update" : "Retry Create"}`;
    }
    return `${isEdit ? "Update" : "Create"}`;
  };

  const giveMeInfoText = () => {
    if (isEdit && updateSNTemplateData.isError) {
      return (
        <Alert severity="error">
         {(updateSNTemplateData?.error as any)?.data?.title ?? "Oops! Something went wrong, Unable to update Template. Try Again Later!"}
        </Alert>
      );
    }
    if (createSNTemplateData.isError) {
      return (
        <Alert severity="error">
          {(createSNTemplateData?.error as any)?.data?.title ?? "Oops! Something went wrong, Unable to create Template. Try Again Later!"}
        </Alert>
      );
    }
  };

  useEffect(() => {
    let options = [];
    if (!state.category || !state.group) {
      options = [];
    } else {
      options =
        snNounData.currentData
          ?.filter((_: any) => _.category_id === state.category?.value)
          ?.filter((_: any) => _.group_id === state.group?.value)
          ?.map((_: any) => ({ value: _._id, label: _.noun_name, ..._ })) || [];
    }

    setNounOptions(options);
    updateState(
      "noun",
      options.find((n) => n.value === state?.noun?.value) || null
    );
    setError((errors) => ({
      ...errors,
      noun: false,
    }));
    // eslint-disable-next-line
  }, [state.category, state.group]);

  const giveMeGroupOptions = () => {
    return snGroupsData.data || [];
  };

  const giveMeCategoryOptions = () => {
    return snCategoryData.data || [];
  };

  React.useEffect(() => {
    if (createSNTemplateData.isSuccess) {
      enqueueSnackbar("SN Template Created Successfully", {
        variant: "success",
      });
      navigate(-1);
      dispatch(closeDialog());
    }
    if (createSNTemplateData.isError) {
      enqueueSnackbar(
        "Oops! Something went wrong, Unable to create SN Template",
        { variant: "error" }
      );
    }
    // eslint-disable-next-line
  }, [createSNTemplateData]);

  React.useEffect(() => {
    if (isEdit) {
      if (updateSNTemplateData.isSuccess) {
        enqueueSnackbar("SN Template Updated Successfully", {
          variant: "success",
        });
        navigate(-1);
        dispatch(closeDialog());
      }
      if (updateSNTemplateData.isError) {
        enqueueSnackbar(
          (updateSNTemplateData?.error as any)?.data?.title ?? "Oops! Something went wrong, Unable to update SN Template",
          { variant: "error" }
        );
      }
    }
    // eslint-disable-next-line
  }, [updateSNTemplateData]);

  React.useEffect(() => {
    if (
      isEdit &&
      snTemplateData.data?._id &&
      snNounData.isSuccess &&
      snAttributeData.isSuccess &&
      snGroupsData.isSuccess &&
      snCategoryData.isSuccess
    ) {
      let selectedGroup: any = snGroupsData.data?.find(
        (_: any) => _._id === snTemplateData.data.group_id
      );
      let selectedCategory: any = snCategoryData.data?.find(
        (_: any) => _._id === snTemplateData.data.category_id
      );
      let selectedNoun: any = snNounData.currentData
        ?.filter(
          (_: SelectBoxOption) =>
            _.category_id === snTemplateData.data.category_id
        )
        ?.filter(
          (_: SelectBoxOption) => _.group_id === snTemplateData.data.group_id
        )
        ?.find((n: SelectBoxOption) => n?._id === snTemplateData.data?.noun_id);
      let attributeKey: any = {};
      let optionKey: any = {};
      let formatConfig: IFormatConfig[] = [];

      snAttributeData.currentData?.forEach((_: any) => {
        attributeKey[_._id] = _;
        _?.options?.forEach((__: any) => {
          optionKey[__._id] = __;
        });
      });

      snTemplateData?.data?.format_config?.forEach((_: FormatConfig) => {
        if (_?.type === "Attribute" && _?.attribute_id && _?.option_id) {
          let selAttr = attributeKey[_.attribute_id];
          let selOpt = optionKey[_.option_id];
          formatConfig.push({
            attribute: {
              label: selAttr?.attr_name,
              value: selAttr?._id,
              ...selAttr,
            },
            option: selOpt
              ? {
                  label: selOpt?.option_name,
                  value: selOpt?._id,
                  ...selOpt,
                }
              : null,
            type: "Attribute",
            position: _?.position,
          });
        } else if (_?.type === "Separator") {
          formatConfig.push({
            separator: separatorOptions.find((s) => s.value === _?.value),
            type: "Separator",
            position: _?.position,
          });
        } else if (_?.type === "Counter") {
          formatConfig.push({
            sequenceNumber: _?.value,
            type: "Counter",
            position: _?.position,
          });
        } else if (_?.type === "Label") {
          formatConfig.push({
            label: _?.value,
            type: "Label",
            position: _?.position,
          });
        }
      });

      setState((st) => ({
        ...st,
        sequenceName: snTemplateData?.data?.name,
        noun: selectedNoun,
        group: selectedGroup,
        category: selectedCategory,
        formatConfig,
      }));
      dispatch(closeBackdrop());
    } else if (
      snNounData.isFetching ||
      snAttributeData.isFetching ||
      snGroupsData.isFetching ||
      snCategoryData.isFetching
    ) {
      dispatch(openBackdrop("Fetching Data...."));
    } else if (
      (snNounData.isSuccess &&
        snAttributeData.isSuccess &&
        snGroupsData.isSuccess &&
        snCategoryData.isSuccess) ||
      snNounData.isError ||
      snAttributeData.isError ||
      snGroupsData.isError ||
      snCategoryData.isError
    ) {
      dispatch(closeBackdrop());
    }
    // eslint-disable-next-line
  }, [
    isEdit,
    snNounData.status,
    snAttributeData.status,
    snCategoryData.status,
    snGroupsData.status,
    snTemplateData.status,
  ]);

  
  useEffect(() => {
    let updatedFormatConfig: IFormatConfig[] = state.formatConfig.map((f) => {
      if (f.type === "Attribute") {
        if (f.attribute?.nouns?.[0] !== state.noun?.value) {
          return {
            ...f,
            attribute: null,
            option: null,
            type: "Attribute",
          };
        }
        return f;
      }
      return f;
    });
    updateState("formatConfig", updatedFormatConfig);
    // eslint-disable-next-line
  }, [state.noun]);

  const giveMeComponent = (
    payload: IFormatConfig,
    noun: SelectBoxOption | null
  ) => {
    let component = null;
    if (payload?.type) {
      if (payload?.type === "Attribute") {
        component = (
          <>
            <Grid item xs={12} sm={7} md={7} lg={7} xl={7}>
              <TextField
                size={"small"}
                variant={"filled"}
                fullWidth
                label={"Attribute Name"}
                value={payload.attribute?.label || ""}
                disabled
                sx={textFieldStyle}
                error={
                  !state.noun ||
                  !payload.attribute?.nouns?.includes(noun?._id as string)
                }
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={3}
              md={3}
              lg={3}
              xl={3}
              sx={{
                paddingLeft: {
                  xs: 0,
                  sm: 2,
                  md: 2,
                  lg: 2,
                  xl: 2,
                },
                marginTop: {
                  xs: 1,
                  sm: 0,
                  md: 0,
                  lg: 0,
                  xl: 0,
                },
              }}
            >
              <TextField
                size={"small"}
                variant={"filled"}
                fullWidth
                label={"Abbreviation"}
                value={payload.option?.option_abbr || ""}
                disabled
                sx={textFieldStyle}
                error={
                  !state.noun ||
                  !payload.attribute?.nouns?.includes(noun?._id as string)
                }
              />
            </Grid>
          </>
        );
      } else if (payload?.type === "Counter") {
        component = (
          <>
            <Grid item xs={12} sm={7} md={7} lg={7} xl={7}>
              <TextField
                size={"small"}
                variant={"filled"}
                fullWidth
                label={"Counter"}
                value={payload.sequenceNumber}
                disabled
                sx={textFieldStyle}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={3}
              md={3}
              lg={3}
              xl={3}
              sx={{
                paddingLeft: {
                  xs: 0,
                  sm: 2,
                  md: 2,
                  lg: 2,
                  xl: 2,
                },
                marginTop: {
                  xs: 1,
                  sm: 0,
                  md: 0,
                  lg: 0,
                  xl: 0,
                },
              }}
            ></Grid>
          </>
        );
      } else if (payload?.type === "Label") {
        component = (
          <>
            <Grid item xs={12} sm={7} md={7} lg={7} xl={7}>
              <TextField
                size={"small"}
                variant={"filled"}
                fullWidth
                label={"Label"}
                value={payload.label}
                disabled
                sx={textFieldStyle}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={3}
              md={3}
              lg={3}
              xl={3}
              sx={{
                paddingLeft: {
                  xs: 0,
                  sm: 2,
                  md: 2,
                  lg: 2,
                  xl: 2,
                },
                marginTop: {
                  xs: 1,
                  sm: 0,
                  md: 0,
                  lg: 0,
                  xl: 0,
                },
              }}
            ></Grid>
          </>
        );
      } else {
        component = (
          <>
            <Grid item xs={12} sm={7} md={7} lg={7} xl={7}>
              <TextField
                size={"small"}
                variant={"filled"}
                fullWidth
                label={"Separator"}
                value={payload.separator?.value}
                disabled
                sx={textFieldStyle}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={3}
              md={3}
              lg={3}
              xl={3}
              sx={{
                paddingLeft: {
                  xs: 0,
                  sm: 2,
                  md: 2,
                  lg: 2,
                  xl: 2,
                },
                marginTop: {
                  xs: 1,
                  sm: 0,
                  md: 0,
                  lg: 0,
                  xl: 0,
                },
              }}
            ></Grid>
          </>
        );
      }
    }
    return component;
  };

  const getSequenceFormat = () => {
    return state.formatConfig
      ?.map((f) => {
        if (f?.type === "Attribute") {
          return f.option?.option_abbr;
        } else if (f?.type === "Separator") {
          return f.separator?.value;
        } else if (f?.type === "Label") {
          return f.label;
        } else {
          return f.sequenceNumber;
        }
      })
      .join("");
  };

  return (
    <MyPaper height={"100%"}>
      <>
        {/* Title */}
        <Typography
          variant="h6"
          color="textPrimary"
          sx={{
            width: "100%",
            fontFamily: "htrts_medium",
            pb: 2,
          }}
        >
          {`${isEdit ? "Update" : "Create"} SN Template`}
        </Typography>

        {/* Divider */}
        <Grid item xs={12} md={12}>
          <Divider variant="fullWidth" />
        </Grid>

        {/* Fields */}
        <Grid item xs={12} md={12} mt={2}>
          <Box width={isMatch ? "100%" : "80%"} sx={{ margin: "auto" }}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={4}>
                <HighlightSelectBox
                  label={"Select SN Category"}
                  value={state.category || null}
                  options={giveMeCategoryOptions()}
                  onChange={(data: SelectBoxOption) =>
                    updateState("category", data)
                  }
                  disabled={
                    createSNTemplateData.isSuccess ||
                    updateSNTemplateData.isSuccess
                  }
                  error={error.category}
                  helperText={
                    error.category ? "Please select the category" : ""
                  }
                />
              </Grid>

              <Grid item xs={12} md={4}>
                <HighlightSelectBox
                  label={"Select SN Group"}
                  value={state.group || null}
                  options={giveMeGroupOptions()}
                  onChange={(data: SelectBoxOption) =>
                    updateState("group", data)
                  }
                  disabled={
                    createSNTemplateData.isSuccess ||
                    updateSNTemplateData.isSuccess
                  }
                  error={error.group}
                  helperText={error.group ? "Please select the group" : ""}
                />
              </Grid>

              {/* Noun */}
              <Grid item xs={12} md={4}>
                <HighlightSelectBox
                  label={"Select SN Noun"}
                  value={
                    nounOptions.find((n) => n.value === state?.noun?.value) ||
                    null
                  }
                  options={nounOptions}
                  onChange={(data: SelectBoxOption) =>
                    updateState("noun", data)
                  }
                  disabled={
                    createSNTemplateData.isSuccess ||
                    updateSNTemplateData.isSuccess
                  }
                  error={error.noun}
                  helperText={error.noun ? "Please select the noun" : ""}
                />
              </Grid>

              {/* Divider */}
              <Grid item xs={12} md={12}>
                <Divider variant="fullWidth" />
              </Grid>

              <Grid item xs={12} md={12} mt={1}>
                <TextField
                  size={"small"}
                  fullWidth
                  label={"Enter the name for sequence"}
                  disabled={
                    createSNTemplateData.isSuccess ||
                    updateSNTemplateData.isSuccess
                  }
                  value={state.sequenceName}
                  onChange={(e) => updateState("sequenceName", e.target.value)}
                  error={error.sequenceName}
                  helperText={
                    error.sequenceName ? "Please select the sequence name" : ""
                  }
                />
              </Grid>

              <Grid item xs={12} md={12}>
                <TextField
                  size={"small"}
                  fullWidth
                  label={"Sequence Format"}
                  disabled
                  value={getSequenceFormat()}
                />
              </Grid>

              {/* Sequence */}
              <Grid item xs={12} md={12}>
                {/* Title */}
                <Stack
                  flexDirection="row"
                  justifyContent={"space-between"}
                  sx={{ mb: 2 }}
                >
                  <Typography sx={{ fontWeight: "bold" }} gutterBottom>
                    Sequence
                  </Typography>
                  <Button
                    variant={"outlined"}
                    size={"small"}
                    sx={{ marginLeft: 1 }}
                    onClick={(e) =>
                      addUpdateField(state.formatConfig.length + 1)
                    }
                    disabled={
                      createSNTemplateData.isSuccess ||
                      updateSNTemplateData.isSuccess ||
                      !state.noun
                    }
                  >
                    Add Field
                  </Button>
                </Stack>

                {/* Sequence */}
                <DragDropContext onDragEnd={onDragEnd}>
                  <Droppable droppableId="droppable-attr">
                    {(provided: any, _: any) => (
                      <div ref={provided.innerRef} {...provided.droppableProps}>
                        {state.formatConfig?.map((format, index) => {
                          if (!format?.type) {
                            return null;
                          }
                          return (
                            <Draggable
                              key={index}
                              draggableId={`draggable-${index + 1}`}
                              index={index}
                            >
                              {(provided: any, _: any) => (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  key={`key${index + 1}`}
                                  id={`div${index + 1}`}
                                >
                                  <Grid
                                    container
                                    alignItems={"center"}
                                    key={index}
                                    sx={{
                                      background: (theme) =>
                                        _.isDragging
                                          ? theme.palette.background.default
                                          : "unset",
                                      my: 2,
                                    }}
                                  >
                                    <Grid
                                      {...provided.dragHandleProps}
                                      sx={{ textAlign: "center" }}
                                      item
                                      xs={12}
                                      sm={1}
                                      md={1}
                                      lg={1}
                                      xl={1}
                                    >
                                      <Tooltip title="Drag Up/Down">
                                        <FlipToFront
                                          fontSize="small"
                                          sx={{ marginTop: 1 }}
                                        />
                                      </Tooltip>
                                    </Grid>
                                    {giveMeComponent(format, state.noun)}
                                    <Grid
                                      xs={12}
                                      sm={1}
                                      md={1}
                                      lg={1}
                                      xl={1}
                                      sx={{
                                        paddingLeft: {
                                          xs: 0,
                                          sm: 1,
                                          md: 1,
                                          lg: 1,
                                          xl: 1,
                                        },
                                        marginTop: {
                                          xs: 1,
                                          sm: 0,
                                          md: 0,
                                          lg: 0,
                                          xl: 0,
                                        },
                                      }}
                                    >
                                      <Stack
                                        flexDirection={"row"}
                                        alignItems={"center"}
                                        justifyContent={"center"}
                                      >
                                        <IconButton
                                          color="primary"
                                          size="small"
                                          onClick={(e) =>
                                            addUpdateField(index, format)
                                          }
                                          disabled={
                                            createSNTemplateData.isSuccess ||
                                            updateSNTemplateData.isSuccess
                                          }
                                        >
                                          <Edit />
                                        </IconButton>

                                        <IconButton
                                          color="error"
                                          size="small"
                                          onClick={(e) => deleteRow(index)}
                                          disabled={
                                            createSNTemplateData.isSuccess ||
                                            updateSNTemplateData.isSuccess
                                          }
                                        >
                                          <Delete />
                                        </IconButton>
                                      </Stack>
                                    </Grid>
                                  </Grid>
                                </div>
                              )}
                            </Draggable>
                          );
                        })}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              </Grid>
              {/* Info Text */}
              <Grid item xs={12}>
                {giveMeInfoText()}
              </Grid>
            </Grid>
          </Box>
        </Grid>

        <Stack
          direction={"row"}
          alignItems={"center"}
          justifyContent={"center"}
          spacing={1}
          p={2}
        >
          {/* Cancel Button */}
          <Button
            sx={{ width: 200 }}
            variant="outlined"
            onClick={onCancelBtnClicked}
            disabled={
              createSNTemplateData.isLoading || updateSNTemplateData.isLoading
            }
          >
            Cancel
          </Button>

          {/* Reset Btn */}

          <Button
            sx={{ width: 200 }}
            variant="outlined"
            onClick={() => updateState("formatConfig", [])}
            disabled={
              createSNTemplateData.isLoading || updateSNTemplateData.isLoading
            }
          >
            Re-set
          </Button>

          {/* Create/Update Btn */}
          <LoadingButton
            sx={{ width: 200 }}
            variant="contained"
            onClick={onCreateUpdateBtnClicked}
            loading={
              createSNTemplateData.isLoading || updateSNTemplateData.isLoading
            }
          >
            {giveMeButtonName()}
          </LoadingButton>
        </Stack>
      </>
    </MyPaper>
  );
};
