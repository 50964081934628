import { Grid, Stack, TextField, Typography } from "@mui/material";
import React from "react";
import { useDispatch } from "react-redux";
import { MyPaper } from "../../../components";
import { useAppSelector } from "../../../redux";
import { updateAbbrevation } from "../../../redux/slices/pmp";

export const Header: React.FC<{ children?: JSX.Element }> = (props) => {

    const dispatch = useDispatch();
    const { name, abbr } = useAppSelector((state) => state.pmp);

    return <MyPaper>
        <Grid container spacing={1} alignItems={"center"}>
            {/* Selection Name */}
            <Grid item xs={12} sm={6}>
                <Stack spacing={1} direction="row" alignItems={"center"}>
                    <Typography variant="subtitle2" color={"textSecondary"} sx={{ fontFamily: "htrts_medium" }}>Selection Name: </Typography>
                    <Typography variant="body2" sx={{ fontFamily: "htrts_semibold" }} >{name}</Typography>
                </ Stack>
            </Grid>

            {/*  Abbrevation */}
            <Grid item xs={12} sm={6}>
                <Stack spacing={1} direction="row" alignItems={"center"}>
                    <Typography variant="subtitle2" color={"textSecondary"} sx={{ fontFamily: "htrts_medium" }}>Abbrevation:</Typography>
                    <TextField
                        variant="standard"
                        size="small"
                        placeholder="Enter Abbrevation"
                        value={abbr}
                        onChange={(e) => dispatch(updateAbbrevation(e.target.value))}
                    />
                </Stack>
            </Grid>
        </Grid>
    </MyPaper>
}