import CloseIcon from '@mui/icons-material/Close';
import { LoadingButton } from "@mui/lab";
import { Alert, AppBar, Box, Button, Dialog, DialogContent, DialogTitle, Divider, Grid, IconButton, Slide, Stack, Toolbar, Tooltip, Typography } from "@mui/material";
import { TransitionProps } from '@mui/material/transitions';
import copy from "fast-copy";
import { useSnackbar } from "notistack";
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { DeletePanel, HighlightSelectBox, ImageUploadList, MyPaper, RowRadioButtonsGroup, TextField } from "../../../components";
import { PublishedIcon } from '../../../images/svg/publishedIcon';
import { WIPIcon } from '../../../images/svg/WIPIcon';
import { LinkUnlikeOnUpdate, SelectBoxOption } from "../../../interfaces";
import { useAppDispatch, useAppSelector } from "../../../redux";
import { useCreateMaterialMutation, useDeleteAttachmentMutation, useGetCurrencyQuery, useGetUoMDropDownQuery, useUpdateMaterialMutation, useUploadAttachmentMutation, useUploadMaterialImageMutation } from "../../../redux/services";
import { closeBackdrop, openBackdrop } from '../../../redux/slices/backdrop';
import { closeConfirmationDialog, openConfirmationDialog } from "../../../redux/slices/confirmationDialog";
import { closeDialog, openDialog } from '../../../redux/slices/dialog';
import { Attachment, MatSliceInterface, reset, updateErrors, updateMatSliceByKeyValue } from "../../../redux/slices/material";
import { clearSelectedChip } from '../../../redux/slices/utils';
import { HasAccess } from '../../../router/authorization';
import { PERMISSIONS } from '../../../router/permission';
import { AppRoutes } from "../../../router/routes";
import { DependencyChildren, PublishComponent } from '../../materials/dashboard';
import { MDCreate } from "../../mdCreate";
import { Notes } from "../notes";
import { CostingTypes, LengthUnits, MDGTypeOptions, StatusOptions, UOMOptions, WeightUnits } from "../utils";
import { Sourcing } from "./../sourcing";

const Transition = React.forwardRef(function Transition(props: TransitionProps & { children: React.ReactElement; }, ref: React.Ref<unknown>,) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const mandateKeys = ["matShortDesc", "materialTag", "uom"];

export const BasicDetails: React.FC<{
    children?: JSX.Element, matId: string, nounData: any, avoidNavigate?: boolean, updateSelected?: any, handleParentDialogClose?: any,
    groupData: any, supplierData: any, refetch: any, customAssociation?: { object_type: string, object_id: string }, publishable?: boolean,
    _id: string, version: string, associations: { object_type: 'material' | 'document' | 'bom', object_details: { _id: string, sequence_id: string, version: string }[] }[],
    docNavigationPath: string; onSuccessfullMaterialCreation?: (material: any) => any, avoidDocNavigation?: boolean, buttonPrefix?: string, note?: string
}> = ({
    matId = "New", updateSelected = () => false, avoidNavigate = false,
    handleParentDialogClose, docNavigationPath, onSuccessfullMaterialCreation = () => false,
    avoidDocNavigation = false, buttonPrefix = "", note = "", publishable = false, ...props
}) => {

        const isEdit = matId !== "New";

        const { materials, utils: { selectedChip }, notifications: { notifications } } = useAppSelector(store => store);
        const dispatch = useAppDispatch();
        const navigate = useNavigate();
        const { enqueueSnackbar } = useSnackbar();
        const { perm } = useAppSelector(store => store.auth.userDetails);

        const currencyData = useGetCurrencyQuery(null);
        const [createMaterial, createMaterialData] = useCreateMaterialMutation();
        const [updateMaterial, updateMaterialData] = useUpdateMaterialMutation();
        const [postImage, postImageData] = useUploadAttachmentMutation();
        const [uploadMaterialImage, uploadMaterialImageData] = useUploadMaterialImageMutation();
        const [deleteAttachment, deleteResponse] = useDeleteAttachmentMutation();
        const regionData = useGetUoMDropDownQuery(null, { refetchOnMountOrArgChange: true, refetchOnFocus: true });

        const [open, setOpen] = React.useState(false);
        const [upload, setUpload] = React.useState<File | Attachment | null>(null);

        const editable = React.useMemo(() => HasAccess(perm, PERMISSIONS.MATERIAL_UPDATE), [perm]);
        const creatable = React.useMemo(() => HasAccess(perm, PERMISSIONS.MATERIAL_CREATE), [perm]);

        const isViewOnly = isEdit ? !editable : !creatable;

        const updateState = (key: string, value: any) => {
            if (key === "materialGroup") {
                dispatch(updateMatSliceByKeyValue({ key: "materialTag", value: null }))
            }
            dispatch(updateMatSliceByKeyValue({ key, value }));
        }

        const handleClose = (generated?: string) => {
            if (generated && materials.matDesc?.length === 0) {
                enqueueSnackbar('Description is not yet generated', { variant: "warning" })
            } else if (generated && materials.matDesc !== generated) {
                enqueueSnackbar('Generated Description is not yet applied', { variant: "warning" })
            } else {
                setOpen(false)
            }
        };

        const handleApply = () => {
            setOpen(false);
        };

        const updateMaterialImageUploadStatus = async (data: any) => {
            if (data?.length > 0) {
                if (data?.[0]?.url) {
                    await uploadMaterialImage({ data: data?.[0], url: data?.[0]?.url, file: upload }).unwrap().finally(() => {
                        dispatch(closeBackdrop());
                    });
                }
            }
        };

        const uploadMaterialImageHandler = (id: string) => {
            postImage({
                object_id: id,
                object_type: "material",
                payload: [
                    {
                        _id: id,
                        file_name: (upload as File)?.name,
                    }
                ]
            }).unwrap().then((res: any) => {
                updateMaterialImageUploadStatus(res);
            }).catch((err: any) => {
                console.error('err:', err)
            });
        }

        const isValid = () => {
            return new Promise((resolve) => {
                if (materials.uom?.value === 'length') {
                    mandateKeys.push('length');
                    mandateKeys.push('length_unit')
                } else if (mandateKeys.includes("length")) {
                    mandateKeys.splice(mandateKeys.indexOf('length'), 1);
                    mandateKeys.splice(mandateKeys.indexOf('length_unit'), 1);
                }

                if (materials.uom?.value === 'weight') {
                    mandateKeys.push('weight');
                    mandateKeys.push('weight_unit');
                } else if (mandateKeys.includes('weight')) {
                    mandateKeys.splice(mandateKeys.indexOf('weight'), 1);
                    mandateKeys.splice(mandateKeys.indexOf('weight_unit'), 1);
                }
                dispatch(updateErrors({
                    mandateKeys, callback: (updatedMaterial: MatSliceInterface) => {
                        let isValid = true;
                        for (let index = 0; index < mandateKeys.length; index++) {
                            const key: any = mandateKeys[index];
                            const error: any = updatedMaterial.error;
                            if (error[key]) {
                                isValid = false;
                                enqueueSnackbar('Please fill mandatory fields (*)', { variant: "warning" });
                                break;
                            }
                        }
                        resolve(isValid)
                    }
                }));
            })
        };

        const onAddUpdateBtnClicked = async () => {
            const isValidData = await isValid();
            if (isValidData) {
                if (isEdit && materials.released) {
                    dispatch(clearSelectedChip())
                    dispatch(openConfirmationDialog({
                        title: "Update Operation",
                        body: `Current revision for ${matId} is already published. Do you want to create a new revision?`,
                        positiveBtn: "Check for dependencies",
                        negativeBtn: "Cancel",
                        onOk: () => openDependencyTable(),
                        onNegativeBtn: () => dispatch(closeConfirmationDialog())
                    }));
                } else if (isEdit) {
                    dispatch(clearSelectedChip())
                    dispatch(openConfirmationDialog({
                        title: "Update Operation",
                        body: <DeletePanel
                            isMaterial={true}
                            message={`Are you sure want to overwrite?`}
                            warningMessage={`The dependent items of ${matId}-r${props.version} will be updated with new changes`}
                            errorLinkingMessage={"The new version of a material cannot be linked to BOM here, for this, go to the BOM and link the revised material."}
                        />,
                        positiveBtn: "Overwrite",
                        negativeBtn: "Cancel",
                        onOk: () => addEditMaterial(false),
                        onNegativeBtn: () => dispatch(closeConfirmationDialog())
                    }));
                } else {
                    if (createMaterialData.isSuccess && postImageData.isError && (upload as File)?.name) {
                        uploadMaterialImageHandler(createMaterialData?.data?._id ?? "");
                    } else {
                        addEditMaterial(false);
                    }
                }
            }
        };

        const openDependencyTable = () => {
            dispatch(openConfirmationDialog({
                title: "Update Operation",
                body: <DeletePanel
                    isMaterial={true}
                    message={props.associations ? props.associations.length === 0 ? 'No dependency found' : `Dependency Table of ${matId}-r${props.version}` : "No dependency found"}
                    errorMessageStacks={props.associations}
                    errorLinkingMessage={"The new version of a material cannot be linked to BOM here, for this, go to the BOM and link the revised material."}
                />,
                positiveBtn: "New Version",
                negativeBtn: "Cancel",
                onOk: () => props.associations?.length > 0 ? openNewVersionDialog() : addEditMaterial(true),
                onNegativeBtn: () => dispatch(closeConfirmationDialog())
            }));
        };

        const openNewVersionDialog = () => {
            dispatch(openConfirmationDialog({
                title: "Update Operation",
                body: <DeletePanel
                    isMaterial={true}
                    message={`Select the dependencies of the newer version of ${matId}-r${props.version}`}
                    errorMessage={""}
                    errorMessageStacks={props.associations}
                    warningMessage={"New version will be attached with selected dependencies"}
                    chipSelectable={true}
                    errorLinkingMessage={"The new version of a material cannot be linked to BOM here, for this, go to the BOM and link the revised material."}
                />,
                positiveBtn: "Create",
                negativeBtn: "Cancel",
                onOk: () => addEditMaterial(true),
                onNegativeBtn: () => dispatch(closeConfirmationDialog())
            }));
        };

        const updatePublishStatus = (row: MatSliceInterface) => {
            dispatch(
                openConfirmationDialog({
                    title: "Publish Operation",
                    body: <DeletePanel
                        isMaterial={true}
                        message={`Are you sure you want to publish the material ${row?.sequenceId}?`}
                        warningMessage={`You will not be able to edit this revision after publishing. You can however create new revisions.`}
                    />,
                    positiveBtn: "Check for dependencies",
                    negativeBtn: "Cancel",
                    onOk: () => openDependencyTableForPublish(row),
                    onNegativeBtn: () => dispatch(closeConfirmationDialog())
                })
            );
        };

        const openDependencyTableForPublish = async (row: MatSliceInterface) => {
            dispatch(closeConfirmationDialog())
            try {
                dispatch(openDialog({
                    title: "",
                    body: <DependencyChildren object_id={props?._id} object_type="material" onPublish={() => proceedToPublish(row)} />,
                    hideNegativeBtn: true, hidePositiveBtn: true, enablePadding: false
                }));
            } catch (error) {
                console.error(`Unable to get the hierarchy: ${error}`);
                enqueueSnackbar("Error getting dependencies", { variant: "error" })
                dispatch(closeDialog())
            }
        };

        const proceedToPublish = (row: MatSliceInterface) => {
            dispatch(openDialog({
                title: "",
                body: <PublishComponent {...({ ...row, sequence_id: row?.sequenceId as string, _id: props?._id })} refetch={props.refetch} object_type="material" object_id={props?._id} />,
                hidePositiveBtn: true,
                hideNegativeBtn: true,
                enablePadding: false
            }));
        }

        const giveMeLinksAndUnlinks = (): LinkUnlikeOnUpdate => {
            let obj: LinkUnlikeOnUpdate = {
                link: [],
                unlink: [],
            }
            let lsChip: { [key: string]: string[] } = JSON.parse(localStorage.getItem('selectedChip') ?? "{}");
            props.associations.forEach(asso => {
                let linkedIds: string[] = [];
                let unlinkedIds: string[] = [];
                asso.object_details.forEach(od => {
                    if (lsChip[asso.object_type]?.includes(od._id)) {
                        linkedIds.push(od._id);
                    } else {
                        unlinkedIds.push(od._id);
                    }
                });
                obj.link.push({ object_ids: linkedIds.filter(Boolean), object_type: asso.object_type })
                obj.unlink.push({ object_ids: unlinkedIds.filter(Boolean), object_type: asso.object_type })
            })

            return obj;
        }

        const addEditMaterial = (update_version: boolean = false) => {
            dispatch(closeConfirmationDialog());
            dispatch(openBackdrop(isEdit ? "Updating material..." : "Creating material..."))
            if (isEdit) {
                updateMaterial({ id: props._id, payload: { ...materials, ...giveMeLinksAndUnlinks() }, update_version });
            } else {
                let payload = copy(materials);
                payload.link = [];
                if (props?.customAssociation?.object_id && props?.customAssociation?.object_type) {
                    payload.link = [{ object_ids: [props.customAssociation.object_id], object_type: props.customAssociation.object_type }]
                }
                payload.unlink = [];
                createMaterial(payload);
            }
        };

        const giveMeErrorMessage = () => {
            let message = `Oops! Something went wrong, Unable to ${isEdit ? "Update" : "Create"} Materials. Try Again Later!`;
            let errorData: any = isEdit ? updateMaterialData.error : createMaterialData.error;
            message = errorData?.data?.title ?? message;
            return message;
        };

        const onCancelBtnClicked = () => {
            let docNavArr = docNavigationPath.split('/');

            if (docNavArr[1] === 'product') {
                navigate(AppRoutes.viewProductMaterial(docNavArr[2]))
            } else if (docNavArr[1] === 'project') {
                navigate(AppRoutes.viewProjectMaterial(docNavArr[2]))
            } else if (docNavArr[1] === 'proposal') {
                navigate(AppRoutes.viewProposalMaterial(docNavArr[2]))
            } else {
                navigate(AppRoutes.allMaterials)
            }
        }

        const isLoading = createMaterialData.isLoading || updateMaterialData.isLoading;

        React.useEffect(() => {
            if (createMaterialData.isSuccess && (!(upload as File)?.name || uploadMaterialImageData.isSuccess)) {
                enqueueSnackbar("Material Created Successfully", { variant: "success" });
                dispatch(closeBackdrop());
                let newMatId = createMaterialData.data?._id ?? "New";
                updateState("matId", newMatId);
                updateState("sequenceId", createMaterialData.data?.sequence_id);

                if (avoidNavigate) {
                    updateSelected(["2"])
                } else {
                    navigate(docNavigationPath.replace("New", newMatId));
                }

                dispatch(openConfirmationDialog({
                    title: "Material Created",
                    body: <>
                        {`${createMaterialData.data?.data?.sequence_id}-r0 -  is the Material ID, attach relevant documents `}
                        {avoidDocNavigation && <>later <Link to={AppRoutes.viewMatDocuments(newMatId)} target='_blank'>here</Link> </>}
                        <span>.</span>
                    </>,
                    positiveBtn: "Ok",
                    onOk: () => {
                        dispatch(closeConfirmationDialog())
                        if (avoidNavigate) {
                            updateSelected(["2"])
                        } else {
                            navigate(docNavigationPath.replace("New", newMatId));
                        }
                        onSuccessfullMaterialCreation(createMaterialData?.data?.data ?? {})
                    },
                    hideNegativeBtn: true
                }))
                dispatch(closeBackdrop());
            }
            if (createMaterialData.isError) {
                enqueueSnackbar(giveMeErrorMessage(), { variant: "error" });
                dispatch(closeBackdrop());
                dispatch(closeDialog());
            }

            if (postImageData.isError || postImageData?.error) {
                enqueueSnackbar("Oops! Something went wrong, Unable to upload Material Image", { variant: "error" });
                dispatch(closeBackdrop());
                dispatch(closeDialog());
            }

            if (createMaterialData.isSuccess && !postImageData.isError && !postImageData.isLoading && !postImageData.isSuccess && (upload as File)?.name) {
                uploadMaterialImageHandler(createMaterialData.data?._id ?? "");
            }
            // eslint-disable-next-line
        }, [createMaterialData.status, postImageData.status, uploadMaterialImageData.status]);

        React.useEffect(() => {
            if (updateMaterialData.isSuccess && (!(upload as File)?.name || uploadMaterialImageData.data)) {
                enqueueSnackbar('Material Updated Successfully!', { variant: "success" });
                dispatch(closeBackdrop());
                let newMatId = updateMaterialData.data?._id ?? "New";
                updateState("matId", newMatId);

                if (avoidNavigate) {
                    updateSelected(["1"])
                } else {
                    navigate(window.location.pathname);
                }
            }
            if (updateMaterialData.isError) {
                let errorData: any = updateMaterialData.error;
                enqueueSnackbar(errorData?.data?.title ?? 'Oops! Something went wrong unable to update material.', { variant: "error" });
                dispatch(closeDialog());
                dispatch(closeBackdrop());
            }

            if (postImageData.isError || postImageData?.error) {
                enqueueSnackbar("Oops! Something went wrong, Unable to upload Material Image", { variant: "error" });
                dispatch(closeBackdrop());
                dispatch(closeDialog());
            }

            if (updateMaterialData.isSuccess && !postImageData.isError && !postImageData.isLoading && !postImageData.isSuccess && (upload as File)?.name) {
                uploadMaterialImageHandler(updateMaterialData.data?._id ?? "");
            }
            // eslint-disable-next-line
        }, [updateMaterialData.status, postImageData.status]);

        React.useEffect(() => {
            if (postImageData.isError) {
                enqueueSnackbar("Oops! Something went wrong, Unable to upload Material Image", { variant: "error" })
            }
            // eslint-disable-next-line
        }, [postImageData.status]);

        React.useEffect(() => {
            localStorage.setItem('selectedChip', JSON.stringify(selectedChip));
            // eslint-disable-next-line
        }, [selectedChip])

        const giveMeButtonName = () => {
            if (createMaterialData.isError) {
                return `${isEdit ? "Retry Update" : "Retry Create"}`
            }

            if (postImageData?.isError) {
                return `Re-upload Material Image`
            }
            return `${isEdit ? "Update" : "Create"} ${buttonPrefix.length > 0 ? buttonPrefix : ""}`;
        }

        React.useEffect(() => {
            if (materials.attachments) {
                setUpload(materials.attachments.at(-1) ?? null);
            }
        }, [materials?.attachments])

        React.useEffect(() => {
            if (deleteResponse.isLoading) {
                dispatch(openBackdrop("Deleting material image..."))
                return;
            }

            if (deleteResponse.isError) {
                enqueueSnackbar((deleteResponse?.error as any)?.data ?? "Unable to delete material image.", { variant: "error" })
            }

            if (deleteResponse.isSuccess) {
                enqueueSnackbar("Material image deleted successfully.", { variant: "success" })
                setUpload(null);
                props.refetch();
            }

            dispatch(closeBackdrop());
            // eslint-disable-next-line
        }, [deleteResponse.status]);

        const onDelete = (file: Attachment | File) => {
            if ((file as Attachment)?._id) {
                deleteAttachment({ object_id: props?._id, object_type: "material", attachment_id: (file as Attachment)?._id });
            } else {
                setUpload(null);
            }
        }

        const onUpload = (file: any) => {
            if (file) {
                setUpload(file)
            }
        }

        return <Stack spacing={2} mb={2}>
            {/* Basic Details Section */}
            <MyPaper padding={0}>
                <Stack justifyContent={"space-between"} height="inherit">
                    <Box>
                        {/* Heading */}
                        <Stack direction={"row"} justifyContent={"space-between"}>
                            <Typography sx={{ px: 2, py: 1 }}
                                variant="body1"
                                fontFamily={"htrts_medium"}>
                                Basic Details
                            </Typography>
                            {props?._id &&
                                materials?.released ? (
                                <Stack direction={"row"} alignItems={"center"}>
                                    <Typography variant="body2" color="textSecondary">Status: </Typography>
                                    <Button disableRipple disableFocusRipple sx={{ cursor: "default", '&:hover': { backgroundColor: 'transparent' } }}>
                                        <Tooltip
                                            title="Published"
                                            children={<Box width={30} height={30} alignItems={"center"} justifyContent={"center"}><PublishedIcon sx={{ width: "100%", height: "100%" }} /></Box>}
                                        />
                                    </Button>
                                </Stack>
                            ) : props?._id && (
                                <Stack direction={"row"} alignItems={"center"}>
                                    <Typography variant="body2" color="textSecondary">Status: </Typography>
                                    <Button disableRipple disableFocusRipple>
                                        <Tooltip
                                            title={publishable ? "Work in progress, click to publish the item" : "Not Published"}
                                            children={
                                                <Box width={30} height={30} alignItems={"center"} justifyContent={"center"}><WIPIcon sx={{ width: "100%", height: "100%" }} onClick={() => publishable ? updatePublishStatus(materials) : false} /></Box>
                                            }
                                        />
                                    </Button>
                                </Stack>
                            )}
                        </Stack>

                        <Divider />

                        <Grid container p={2} spacing={2}>
                            {/* MDG Type */}
                            {!isViewOnly && <Grid item xs={12}>
                                <RowRadioButtonsGroup
                                    label=""
                                    error={materials.error.mdgType}
                                    helperText={materials.error.mdgType ? "Please select Material Description type" : ""}
                                    value={materials.mdgType}
                                    onChange={(e: any) => updateState("mdgType", e?.target?.value)}
                                    options={MDGTypeOptions}
                                />
                            </Grid>}

                            {/* Material Region */}
                            <Grid item xs={12} sm={4}>
                                <HighlightSelectBox
                                    id="material-region"
                                    label={"Region"}
                                    margin={"none"}
                                    error={materials.error.region}
                                    helperText={materials.error.region ? "Please fill Region" : ""}
                                    value={materials.region}
                                    options={regionData?.currentData ?? []}
                                    loading={regionData?.isFetching}
                                    disabled={materials.mdgType === "Generate using MDG"}
                                    onChange={(data: SelectBoxOption) => {
                                        updateState("region", data)
                                    }}
                                    viewOnly={isViewOnly}
                                />
                            </Grid>

                            {/* Material Group */}
                            <Grid item xs={12} sm={4}>
                                <HighlightSelectBox
                                    id="material-group"
                                    label={"Material Group"}
                                    margin={"none"}
                                    error={materials.error.materialGroup}
                                    helperText={materials.error.materialGroup ? "Please fill Material Group" : ""}
                                    value={materials.materialGroup}
                                    options={props.groupData?.currentData ?? []}
                                    loading={props.groupData?.isFetching}
                                    disabled={materials.mdgType === "Generate using MDG"}
                                    onChange={(data: SelectBoxOption) => {
                                        updateState("materialGroup", data)
                                    }}
                                    viewOnly={isViewOnly}
                                />
                            </Grid>

                            {/* Material Tag */}
                            <Grid item xs={12} sm={4}>
                                <HighlightSelectBox
                                    id="material-tag"
                                    label={"Material Tag"}
                                    margin={"none"}
                                    required={true}
                                    error={materials.error.materialTag}
                                    helperText={materials.error.materialTag ? "Please fill Material Tag" : ""}
                                    value={materials.materialTag}
                                    loading={props.nounData?.isFetching}
                                    options={materials.materialGroup ? props.nounData?.currentData?.filter((_: any) => _.group_id === materials.materialGroup?.value && _.uom_id === materials.region?.value) ?? [] : []}
                                    disabled={materials.mdgType === "Generate using MDG"}
                                    onChange={(data: SelectBoxOption) => updateState("materialTag", data)}
                                    viewOnly={isViewOnly}
                                />
                            </Grid>

                            {/* Material Short Description*/}
                            <Grid item xs={12} sm={4}>
                                <TextField
                                    id="short-desc"
                                    variant="outlined"
                                    size="small"
                                    fullWidth
                                    required={true}
                                    error={materials.error.matShortDesc}
                                    helperText={materials.error.matShortDesc ? "Please fill Material Short Description" : ""}
                                    value={materials.matShortDesc}
                                    label={"Material Short Description"}
                                    disabled={materials.mdgType === "Generate using MDG"}
                                    onChange={(e) => updateState("matShortDesc", e.target.value)}
                                    viewOnly={isViewOnly}
                                />
                            </Grid>


                            {/* Material Description*/}
                            <Grid item xs={12} sm={8}>
                                <TextField
                                    id="long-desc"
                                    variant="outlined"
                                    size="small"
                                    fullWidth
                                    required={false}
                                    error={materials.error.matDesc}
                                    helperText={materials.error.matDesc ? "Please fill Material Description" : ""}
                                    value={materials.matDesc}
                                    label={"Material Long Description"}
                                    disabled={materials.mdgType === "Generate using MDG"}
                                    onChange={(e) => updateState("matDesc", e.target.value)}
                                    viewOnly={isViewOnly}
                                />
                            </Grid>

                            {/* Button */}
                            {materials.mdgType === "Generate using MDG" && !isViewOnly && <Grid item xs={12}>
                                <Button id="generateUsingMDGBtn" variant="outlined" size="medium" color="primary" sx={{ mt: 1 }} onClick={() => setOpen(true)}>Generate using MDG</Button>
                            </Grid>}

                            {/* Divider */}
                            <Grid item xs={12}>
                                <Divider variant="fullWidth" />
                            </Grid>

                            {/* Client Reference Number */}
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    id="reference_id"
                                    variant="outlined"
                                    size="small"
                                    fullWidth
                                    required={false}
                                    error={materials.error.clientRefNo}
                                    helperText={materials.error.clientRefNo ? "Please fill Reference Number" : ""}
                                    value={materials.clientRefNo}
                                    label={"Reference Number"}
                                    onChange={(e) => updateState("clientRefNo", e.target.value)}
                                    viewOnly={isViewOnly}
                                />
                            </Grid>

                            {/* UOM */}
                            <Grid item xs={12} sm={6}>
                                <HighlightSelectBox
                                    id="uom"
                                    label={"UOM"}
                                    margin={"none"}
                                    required={true}
                                    error={materials.error.uom}
                                    helperText={materials.error.uom ? "Please fill UOM" : ""}
                                    value={materials.uom}
                                    options={UOMOptions}
                                    onChange={(data: SelectBoxOption) => updateState("uom", data)}
                                    viewOnly={isViewOnly}
                                />
                            </Grid>

                            {/* Length */}
                            {materials.uom?.value === "weight" && <Grid item xs={12} sm={3}>
                                <TextField
                                    id="uom"
                                    variant="outlined"
                                    size="small"
                                    fullWidth
                                    required={true}
                                    type={"number"}
                                    error={materials.error.weight}
                                    helperText={materials.error.weight ? "Please fill weight" : ""}
                                    value={materials.weight}
                                    label={"Weight"}
                                    onChange={(e) => updateState("weight", e.target.value)}
                                    viewOnly={isViewOnly}
                                />
                            </Grid>}

                            {materials.uom?.value === "weight" && <Grid item xs={12} sm={3}>
                                <HighlightSelectBox
                                    label={"Unit"}
                                    margin={"none"}
                                    required={true}
                                    error={materials.error.weight_unit}
                                    helperText={materials.error.weight_unit ? "Please fill length unit" : ""}
                                    value={materials.weight_unit}
                                    options={WeightUnits}
                                    onChange={(data: SelectBoxOption) => updateState("weight_unit", data)}
                                    viewOnly={isViewOnly}
                                />
                            </Grid>}

                            {/* Length */}
                            {materials.uom?.value === "length" && <Grid item xs={12} sm={3}>
                                <TextField
                                    id="material-length"
                                    variant="outlined"
                                    size="small"
                                    fullWidth
                                    required={true}
                                    type={"number"}
                                    error={materials.error.length}
                                    helperText={materials.error.length ? "Please fill Length" : ""}
                                    value={materials.length}
                                    label={"Length"}
                                    onChange={(e) => updateState("length", e.target.value)}
                                    viewOnly={isViewOnly}
                                />
                            </Grid>}

                            {/* Length Unit */}
                            {materials.uom?.value === "length" && <Grid item xs={12} sm={3}>
                                <HighlightSelectBox
                                    id="material-unit"
                                    label={"Unit"}
                                    margin={"none"}
                                    required={true}
                                    error={materials.error.length_unit}
                                    helperText={materials.error.length_unit ? "Please fill length unit" : ""}
                                    value={materials.length_unit}
                                    options={LengthUnits}
                                    onChange={(data: SelectBoxOption) => updateState("length_unit", data)}
                                    viewOnly={isViewOnly}
                                />
                            </Grid>}

                            {/* Status */}
                            <Grid item xs={12} sm={6}>
                                <HighlightSelectBox
                                    id="status"
                                    label={"Status"}
                                    margin={"none"}
                                    error={materials.error.status}
                                    helperText={materials.error.status ? "Please fill Status" : ""}
                                    value={materials.status}
                                    options={StatusOptions}
                                    onChange={(data: SelectBoxOption) => updateState("status", data)}
                                    viewOnly={isViewOnly}
                                />
                            </Grid>
                        </Grid>

                    </Box>
                </Stack>
            </MyPaper>

            {/* Upload Image */}
            <ImageUploadList
                title='Upload Material Image'
                disabled={createMaterialData.isLoading || updateMaterialData.isLoading || materials?.attachments?.[0]?.status === "notUploaded"}
                loading={(notifications?.["attachment-status"]?.[postImageData?.data?.[0]?._id]?.status !== "saved" && notifications?.["attachment-status"]?.[postImageData?.data?.[0]?._id]?.status !== "error") && Object.keys(notifications?.["attachment-status"]?.[postImageData?.data?.[0]?._id] || {}).length > 0}
                files={upload ? [upload] : []}
                onDelete={onDelete}
                onUpload={onUpload}
                stack={false}
                readOnly={isViewOnly}
            />

            {/* Costing Type */}
            <MyPaper padding={0}>
                <Stack justifyContent={"space-between"} height="inherit">
                    <Box>
                        {/* Heading */}
                        <Typography sx={{ px: 2, py: 1, borderBottom: (theme) => `1px solid ${theme.palette.divider}` }}
                            variant="body1"
                            fontFamily={"htrts_medium"}>
                            Costing & Lead time Details
                        </Typography>

                        <Grid container p={2} spacing={2}>
                            {/* Costing Type */}
                            <Grid item xs={12} sm={6}>
                                <HighlightSelectBox
                                    id="costing-type"
                                    label={"Costing Type"}
                                    margin={"none"}
                                    error={materials.error.costingType}
                                    helperText={materials.error.costingType ? "Please fill Costing Type" : ""}
                                    value={materials.costingType ?? null}
                                    options={CostingTypes}
                                    onChange={(data: SelectBoxOption) => updateState("costingType", data)}
                                    viewOnly={isViewOnly}
                                />
                            </Grid>

                            {/* Cost */}
                            <Grid item xs={12} sm={3}>
                                <TextField
                                    id="m-cost"
                                    variant="outlined"
                                    size="small"
                                    fullWidth
                                    type="number"
                                    InputLabelProps={{ shrink: materials.cost ? true : false }}
                                    inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                                    error={materials.error.cost}
                                    helperText={materials.error.cost ? "Please fill Cost" : ""}
                                    value={materials.cost}
                                    label={"Cost"}
                                    onChange={(e) => updateState("cost", e.target.value)}
                                    viewOnly={isViewOnly}
                                />
                            </Grid>

                            {/* Currency */}
                            <Grid item xs={12} sm={3}>
                                <HighlightSelectBox
                                    id="m-currency"
                                    label={"Currency"}
                                    margin={"none"}
                                    loading={currencyData.isLoading}
                                    error={materials.error.currency}
                                    helperText={materials.error.currency ? "Please fill Currency" : ""}
                                    value={materials.currency ?? null}
                                    options={currencyData?.data ?? []}
                                    onChange={(data: SelectBoxOption) => updateState("currency", data)}
                                    viewOnly={isViewOnly}
                                />
                            </Grid>

                            {/* Lead time */}
                            <Grid item xs={12} sm={3}>
                                <TextField
                                    id="lead-time"
                                    variant="outlined"
                                    size="small"
                                    fullWidth
                                    type="number"
                                    inputProps={{ inputMode: 'numeric', pattern: '[0-9]*', defaultValue: 0 }}
                                    value={materials.lead_time}
                                    InputLabelProps={{ shrink: true }}
                                    label={"Lead Time (Days)"}
                                    error={materials.error.lead_time}
                                    helperText={materials.error.lead_time ? "Please fill Lead Time" : ""}
                                    onChange={(e) => updateState("lead_time", e.target.value)}
                                    viewOnly={isViewOnly}
                                />
                            </Grid>
                        </Grid>

                    </Box>
                </Stack>
            </MyPaper>

            {/* Sourcing Section */}
            <Sourcing supplierData={props.supplierData} viewOnly={isViewOnly} />

            {/* Notes Section */}
            <Notes viewOnly={isViewOnly} />

            {/* Footer */}
            {!isViewOnly && <MyPaper>
                <Box>
                    {/* Alert Box */}
                    {(createMaterialData.isError || updateMaterialData.isError) && <Alert sx={{ mt: 2 }} severity="error">{
                        giveMeErrorMessage()
                    }</Alert>}

                    {/* Footer Buttons */}
                    <Stack direction={"row"} alignItems={"center"} justifyContent={"center"} spacing={1} p={1}>
                        {/* Clear All Button */}
                        <Button disabled={isLoading} variant="outlined" sx={{ width: 200 }} color="primary" onClick={() => dispatch(reset())} >
                            Clear All
                        </Button>

                        {/* Create Button */}
                        <LoadingButton id="u-c-btn" loading={isLoading} variant="contained" sx={{ width: 200 }} color="primary" onClick={() => onAddUpdateBtnClicked()}>
                            {giveMeButtonName()}
                        </LoadingButton>

                        {/* Cancel Button */}
                        <Button disabled={isLoading} variant="outlined" sx={{ width: 200 }} color="primary" onClick={() => avoidNavigate ? handleParentDialogClose() : onCancelBtnClicked()} >
                            Cancel
                        </Button>
                    </Stack>

                    {/* Alert Box */}
                    {note.length > 0 && <Alert sx={{ mt: 2 }} severity="info">{
                        note
                    }</Alert>}
                </Box>
            </MyPaper>}

            {/* MDG Dialog */}
            <Dialog
                maxWidth={"xl"}
                fullWidth={true}
                open={open}
                onClose={() => handleClose()}
                TransitionComponent={Transition}
            >
                {/* Ṭoolbar */}
                <DialogTitle sx={{ p: 0 }}>
                    <AppBar sx={{ position: 'relative' }}>
                        <Toolbar>
                            <IconButton
                                edge="start"
                                color="inherit"
                                onClick={() => handleClose()}
                                aria-label="close"
                            >
                                <CloseIcon />
                            </IconButton>
                            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                                Description Generator
                            </Typography>
                        </Toolbar>
                    </AppBar>
                </DialogTitle>

                {/* Description Generator */}
                <DialogContent sx={{ p: 0 }}>
                    <MDCreate for={"new_material"} onClose={handleClose} onApply={handleApply} />
                </DialogContent>
            </Dialog>
        </Stack>
    }