import React from "react";
import { Box, Stack, TextField, Typography, Button, Chip } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useDispatch } from "react-redux";
import { useSnackbar } from "notistack";
import { closeDialog } from "../../../redux/slices/dialog";
import { useCreateTagGroupMutation, useUpdateTagGroupMutation } from "../../../redux/services";
import { ColorPalette } from "../../../components";
import { hexToGrayscale } from "../../../utils";

const defaultColor = "#FFFFFF";

export const AddEditTagGroup: React.FC<{ children?: JSX.Element, isEdit: boolean, data: any, refetch: any }> = (props) => {

    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const [createTagGroup, createTagGroupData] = useCreateTagGroupMutation();
    const [updateTagGroup, updateTagGroupData] = useUpdateTagGroupMutation();

    const [state, setState] = React.useState({ name: "", description: "", color: defaultColor });
    const [error, setError] = React.useState({ name: false, description: false, color: false });

    const updateState = (key: string, value: string) => {
        setState({ ...state, [key]: value });
        setError({ ...error, [key]: !value.length })
    }

    const onCancelBtnClicked = () => {
        dispatch(closeDialog());
    }

    const onCreateUpdateBtnClicked = () => {
        if (state.name.length && state.description.length) {
            if (props.isEdit) {
                updateTagGroup({ id: props.data._id, payload: { tag_group_name: state.name, tag_group_desc: state.description, tag_group_color: state.color ?? defaultColor } })
            } else {
                createTagGroup({ tag_group_name: state.name, tag_group_desc: state.description, tag_group_color: state.color ?? defaultColor });
            }
        } else {
            setError({ ...error, name: !state.name.length, description: !state.description.length })
        }
    }

    React.useEffect(() => {
        if (createTagGroupData.isSuccess) {
            enqueueSnackbar("Tag Category Created Successfully", { variant: "success" });
            props.refetch();
            dispatch(closeDialog());
        }
        if (createTagGroupData.isError) {
            enqueueSnackbar("Opps! Something weng wrong, Unable to create Tag Category", { variant: "error" })
        }
        // eslint-disable-next-line
    }, [createTagGroupData]);

    React.useEffect(() => {
        if (updateTagGroupData.isSuccess) {
            enqueueSnackbar("Tag Category Updated Successfully", { variant: "success" });
            props.refetch();
            dispatch(closeDialog());
        }
        if (updateTagGroupData.isError) {
            enqueueSnackbar("Opps! Something weng wrong, Unable to update Tag Category", { variant: "error" })
        }
        // eslint-disable-next-line
    }, [updateTagGroupData]);

    React.useEffect(() => {
        if (props.isEdit && props.data?._id) {
            setState({ ...state, name: props.data.tag_group_name, description: props.data.tag_group_desc, color: props.data.tag_group_color })
        }
        // eslint-disable-next-line
    }, [props.isEdit]);

    return <Box>
        {/* Title */}
        <Typography
            variant="h6"
            color="textPrimary"
            sx={{
                borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
                width: "100%", fontFamily: 'htrts_medium'
            }}
        >
            {`${props.isEdit ? "Update" : "Create"} Tag Categories`}
        </Typography>

        {/* Fields */}
        <Stack sx={{ marginTop: 3 }} spacing={2}>
            {/* Name */}
            <TextField
                label={"Tag Category Name"}
                value={state.name}
                required={true}
                error={error.name}
                helperText={error.name ? "Please enter Category name" : ""}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => updateState("name", e.target.value)}
            />

            {/* Description */}
            <TextField
                label={"Tag Category Description"}
                value={state.description}
                required={true}
                error={error.description}
                helperText={error.description ? "Please enter Tag Category description." : ""}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => updateState("description", e.target.value)}
            />

            {/* Color Palette */}
            <ColorPalette
                label={"Tag Category Color"}
                value={state.color}
                required={true}
                error={error.color}
                helperText={error.color ? "Please enter Tag Category Color." : ""}
                onChange={(color: string) => updateState("color", color)}
            />

            <Box>
                <Typography gutterBottom variant="body2">Preview</Typography>
                <Chip sx={{ borderColor: state.color,backgroundColor:state.color, color:`${hexToGrayscale(state.color) > 150 ? "rgba(0, 0, 0, 0.87)" : "#FFFFFF"}`,  width: "fit-content" }} variant="outlined" label={state.name ?? "Tag"} />
            </Box>

            {/* Add UoM Btn */}
            <LoadingButton
                variant="contained"
                onClick={onCreateUpdateBtnClicked}
                loading={createTagGroupData.isLoading || updateTagGroupData.isLoading}
            >
                {`${props.isEdit ? "Update" : "Create"}`}
            </LoadingButton>

            {/* Cancel Btn */}
            <Button
                variant="outlined"
                onClick={onCancelBtnClicked}
                disabled={false}
            >
                Cancel
            </Button>
        </Stack>
    </Box>
}