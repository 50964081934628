import { Box, Chip, Stack, Theme, Tooltip, Typography, useTheme } from "@mui/material";
import React from "react";
import Select, { components } from "react-select";
import { AsyncPaginate } from "react-select-async-paginate";
import CreatableSelect from "react-select/creatable";
import { ReactSelectOption } from "../../interfaces";

const useStyles = (theme: Theme) => ({
    createBtn: {
        display: "inline-flex",
        cursor: "pointer",
    },
});

const customStyles = (theme: Theme, error?: boolean) => ({
    control: (base: any) => ({
        ...base,
        borderRadius: 5,
        minHeight: 38,
        fontSize: "16px",
        fontFamily: "htrts_regular !important",
        // minHeight: 48,
        border: `1.5px solid ${error ? theme.palette.error.main : theme.palette.divider}`,
        boxShadow: "none",
        "&:hover": {
            border: `1.5px solid ${error ? theme.palette.error.dark : theme.palette.primary.main}`,
        },
    }),
    menu: (base: any) => ({
        ...base,
        zIndex: 999,
        fontFamily: "htrts_regular !important",
    }),
    menuPortal: (base: any) => ({ ...base, zIndex: 9999 })
});

export const SelectBox: React.FC<{
    value: any, options: ReactSelectOption[], onChange: any, label?: string,
    onSearch?: any, isRequired?: boolean, isPaginate?: boolean, createSelect?: boolean, error?: boolean,
    helperText?: string, loadOptions?: any, placeholder?: string, loading?: boolean, isMulti?: boolean,
    labelStartIcon?: JSX.Element, isDisabled?: boolean, id?: string, isMenuFixed?: boolean, isClearable?: boolean, viewOnly?: boolean
}> = ({
    label = "", value = null, options = [], onChange = () => null,
    isRequired = false, isPaginate = false, createSelect = false, error = false, helperText = "",
    placeholder = "", loading = false, loadOptions = () => null, onSearch = (str: string) => str, isMulti = false,
    labelStartIcon = <></>, isDisabled = false, id = 'select-box-id-for-portal', isMenuFixed = false, isClearable = true, viewOnly = false
}) => {

        const theme = useTheme();
        const classes = useStyles(theme);

        const formatCreateLabel = (inputValue: string) => (
            <Box component={"span"} sx={classes.createBtn}>
                <img
                    src="/images/icons8-add.svg"
                    alt="add"
                    style={{ marginRight: "8px" }}
                />
                <Typography variant="body1" color="primary">
                    {" "}
                    Add {inputValue}
                </Typography>
            </Box>
        );

        let selectedValue = value && !Array.isArray(value) ? value?.label || "N/A" : "N/A";

        return viewOnly ? (
            <Stack sx={{ overflowWrap: "break-word" }}>
                <Typography variant="body1" sx={{ fontFamily: "htrts_semibold" }}>{label}</Typography>
                {Array.isArray(value) && value.length > 0 ? <Stack
                    gap={1}
                    direction={"row"}
                    flexWrap={"wrap"}
                >
                    {
                        value.map(v =>
                            <Tooltip title={v?.label || "N/A"}>
                                <Chip size={"small"} label={v?.label || "N/A"} />
                            </Tooltip>
                        )
                    }
                </Stack>
                    : <Typography variant="body2" sx={{ fontFamily: "htrts_regular" }}>{selectedValue}</Typography>}
            </Stack>
        ) : (
            <Box>
                {label.length > 0 && <Box sx={{ display: "flex" }}>
                    {
                        <Typography variant="body2" color="primary" align="left" gutterBottom>
                            {label}
                        </Typography>
                    }
                    {isRequired && (
                        <Typography color="error" variant="caption">
                            *
                        </Typography>
                    )}
                </Box>}

                {isPaginate ? (
                    <AsyncPaginate
                        key={id}
                        id={id}
                        isMulti={isMulti}
                        isClearable={isClearable}
                        isSearchable
                        components={{
                            IndicatorSeparator: () => null,
                        }}
                        value={value}
                        placeholder={placeholder}
                        loadOptions={loadOptions}
                        onChange={onChange}
                        options={options}
                        isLoading={loading}
                        defaultOptions={options}
                        isDisabled={isDisabled}
                        styles={customStyles(theme, error)}
                        menuPosition={isMenuFixed ? 'fixed' : undefined}
                        menuPortalTarget={document.body}
                    />
                ) : createSelect ? (
                    <CreatableSelect
                        isMulti={isMulti}
                        isClearable={isClearable}
                        options={options}
                        isLoading={loading}
                        placeholder={placeholder}
                        components={{
                            IndicatorSeparator: () => null,
                        }}
                        value={value}
                        onChange={onChange}
                        styles={customStyles(theme, error)}
                        formatCreateLabel={formatCreateLabel}
                        isDisabled={isDisabled}
                    />
                ) : (
                    <Select
                        id={id || "select-box-id-for-portal"}
                        isMulti={isMulti}
                        isClearable={isClearable}
                        isSearchable
                        components={{
                            IndicatorSeparator: () => null,
                            Option: (props) => <components.Option {...props}>
                                <Stack direction={"row"} spacing={1} id={"options"}>
                                    {labelStartIcon}
                                    <Box>{props.label}</Box>
                                </Stack>
                            </components.Option>
                        }}
                        value={value}
                        placeholder={placeholder}
                        options={options}
                        isLoading={loading}
                        onChange={onChange}
                        styles={customStyles(theme, error)}
                        onInputChange={onSearch}
                        isDisabled={isDisabled}
                        menuPosition={isMenuFixed ? 'fixed' : undefined}
                        menuPortalTarget={document.body}
                    />
                )}
                {error && (
                    <Typography variant="caption" color="error">
                        {helperText}
                    </Typography>
                )}
            </Box>
        );
    };
