import React from "react";
import { Box, Tooltip, Stack, Typography } from "@mui/material";
import { CheckCircle } from "@mui/icons-material";

export const CardWithImage: React.FC<{
    id: string;
    data: any,
    onImageClick: any,
    height?: number,
    image?: string,
    title: string,
    isSelected?: boolean,
}> = ({ data, onImageClick, height = 200, image, title, id, isSelected = false }) => {

    return <Box
        id={id}
        height={height + "px"}
        mb={1}
        mr={1}
        sx={{
            width: { xs: "100%", sm: height + "px" },
            cursor: "pointer", transition: 'transform .2s', '&:hover': { transform: 'scale(1.05)', zIndex: 1 },
            boxShadow: "rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px"
        }}
        onClick={(e: any) => onImageClick(data)}
    >
        <Box
            sx={{
                backgroundImage: `url(${image ? image : `/imageNotFound.jpg`})`,
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                backgroundSize: "contain",
                borderBottom: (theme) => `1px solid ${theme.palette.divider}`
            }}
            height={height - (height * 0.25) + "px"}
            width={"100%"}
        ></Box>
        <Tooltip title={title}>
            <Stack
                direction="row"
                alignItems="center"
                justifyContent={"space-between"}
                height={(height * 0.25) + "px"}
                pl={1}
                sx={{ backgroundColor: (theme) => theme.palette.background.paper }}
            >
                <Typography variant={"body2"} sx={{ fontFamily: "htrts_medium", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>{title}</Typography>
                {isSelected && <Box sx={{ background: "white", borderRadius: 4, marginRight: 2 }}
                    display={"flex"} justifyContent="center" alignItems={"center"}
                >
                    <CheckCircle fontSize="small" color="success" />
                </Box>
                }
            </Stack>
        </Tooltip>
    </Box>

}