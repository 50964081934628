import React from "react";
import { Stack, TextField, Typography, Button, DialogTitle, DialogContent, DialogActions, Grid } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useDispatch } from "react-redux";
import { closeDialog } from "../../../../redux/slices/dialog";
import { useCreateProductFamilyMutation, useUpdateProductFamilyMutation } from "../../../../redux/services";
import { useSnackbar } from "notistack";

export const AddUpdateFamily: React.FC<{ children?: JSX.Element, isEdit: boolean, data: any, refetch: any }> = (props) => {

    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const [createFamily, createFamilyData] = useCreateProductFamilyMutation();
    const [updateFamily, updateFamilyData] = useUpdateProductFamilyMutation();

    const [state, setState] = React.useState({
        name: "",
        description: ""
    });

    const [error, setError] = React.useState({
        name: false,
        description: false
    })

    const updateState = (key: string, value: string) => {
        setState({ ...state, [key]: value });
        setError({ ...error, [key]: !value.length })
    }

    const onCancelBtnClicked = () => {
        dispatch(closeDialog());
    }

    const onCreateUpdateBtnClicked = () => {
        if (state.name.length && state.description.length) {
            if (props.isEdit) {
                updateFamily({ family_name: state.name, family_desc: state.description, _id: props.data.id })
            } else {
                createFamily({ family_name: state.name, family_desc: state.description });
            }
        } else {
            setError({ ...error, name: !state.name.length, description: !state.description.length })
        }
    }

    React.useEffect(() => {
        if (createFamilyData.isSuccess) {
            enqueueSnackbar("Family Created Successfully", { variant: "success" });
            props.refetch();
            dispatch(closeDialog());
        }
        if (createFamilyData.isError) {
            enqueueSnackbar("Opps! Something weng wrong, Unable to create Family", { variant: "error" })
        }
        // eslint-disable-next-line
    }, [createFamilyData]);

    React.useEffect(() => {
        if (updateFamilyData.isSuccess) {
            enqueueSnackbar("Family Updated Successfully", { variant: "success" });
            props.refetch();
            dispatch(closeDialog());
        }
        if (updateFamilyData.isError) {
            enqueueSnackbar("Opps! Something weng wrong, Unable to update Family", { variant: "error" })
        }
        // eslint-disable-next-line
    }, [updateFamilyData]);

    React.useEffect(() => {
        if (props.isEdit && props.data?.id) {
            setState({ ...state, name: props.data.family_name, description: props.data.family_desc })
        }
        // eslint-disable-next-line
    }, [props.isEdit]);

    return <>
        {/* Title */}
        <DialogTitle>
            <Typography
                variant="h6"
                color="textPrimary"
                sx={{
                    borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
                    width: "100%", fontFamily: 'htrts_medium'
                }}
            >
                {`${props.isEdit ? "Update" : "Create"} Family`}
            </Typography>
        </DialogTitle>

        {/* Fields */}
        <DialogContent>
            <Stack sx={{ marginTop: 1 }} spacing={2}>
                {/* Name */}
                <TextField
                    id="family_name"
                    label={"Name"}
                    value={state.name}
                    required={true}
                    error={error.name}
                    helperText={error.name ? "Please enter family name" : ""}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => updateState("name", e.target.value)}
                />

                {/* Description */}
                <TextField
                    id="family_description"
                    label={"Description"}
                    value={state.description}
                    required={true}
                    error={error.description}
                    helperText={error.description ? "Please enter family description." : ""}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => updateState("description", e.target.value)}
                />


            </Stack>
        </DialogContent>

        <DialogActions>
            <Grid container spacing={2} px={2} pb={1}>
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                    {/* Add Family Btn */}
                    <LoadingButton
                        id="u-c-btn"
                        fullWidth
                        variant="contained"
                        onClick={onCreateUpdateBtnClicked}
                        loading={createFamilyData.isLoading || updateFamilyData.isLoading}
                    >
                        {`${props.isEdit ? "Update" : "Create"}`}
                    </LoadingButton>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                    {/* Cancel Btn */}
                    <Button
                        fullWidth
                        variant="outlined"
                        onClick={onCancelBtnClicked}
                        disabled={createFamilyData.isLoading || updateFamilyData.isLoading}
                    >
                        Cancel
                    </Button>
                </Grid>
            </Grid>
        </DialogActions>
    </>
}