import { createApi, fetchBaseQuery, retry } from '@reduxjs/toolkit/query/react';
import Axios from 'axios';
import { config } from '../../../config';
import { LocalStorageKeys } from '../../../utils';

export const attachementAPI = createApi({
    reducerPath: "attachementAPI",
    baseQuery: retry(fetchBaseQuery({
        baseUrl: config.api_url + "mm/mdg/",
        prepareHeaders: (headers, { getState }) => {
            const token = localStorage.getItem(LocalStorageKeys.authToken);

            // If we have a token set in state, let's assume that we should be passing it.
            if (token) {
                headers.set('authorization', `Bearer ${token}`)
            }

            return headers
        },
    }), { maxRetries: 2 }),
    tagTypes: ['Attachement'],
    endpoints: (builder) => ({
        // Upload Attachement API
        uploadAttachement: builder.mutation({
            query: ({ body, object_type, object_id }) => ({ url: `attachment/${object_type}/${object_id}`, method: "POST", body })
        }),

        uploadS3Attachement: builder.mutation({
            async queryFn(_arg, _queryApi, _extraOptions, fetchWithBQ) {
                const { file, object_type, object_id } = _arg;
                let result: any = {};
                try {
                    const getSignedURL:any = await fetchWithBQ(`attachment/${object_type}/${object_id}/upload_url?file_name=${file.name}`);
                    const resData = await Axios({
                        url: getSignedURL?.data?.url,
                        method: "put",
                        headers: { 'Content-Type': file.type },
                        data: file
                    });
                    result = { data: { getSignedURL, resData } }
                } catch (err) {
                    result = { error: true }
                } finally {
                    return result;
                }
            }
        })
    })
})

// Export hooks for usage in functional components
export const { useUploadAttachementMutation,useUploadS3AttachementMutation } = attachementAPI
