import React from "react";
import { Alert, Box, Stack, Typography } from "@mui/material";

export const DeletePanel: React.FC<{ children?: JSX.Element, message: string, errorMessage?: string, errorData?: any }> = (props) => {
    return <Box>
        <Typography variant={"body1"} gutterBottom color={"GrayText"}>{props.message}</Typography>

        {props.errorMessage && <Alert severity="error" >
            {props.errorMessage}
            {props.errorData && <Box>
                <Typography variant="caption" mt={1}>
                    {"Becuase of the below dependencies"}
                </Typography>
                {props.errorData.nouns && props.errorData.nouns.length > 0 && <Stack mt={1} direction={"row"} spacing={1}>
                    <Typography variant="body2" sx={{ fontWeight: "bold" }}>Nouns: </Typography>
                    <Typography variant="subtitle2">{props.errorData.nouns.map((_: any) => _.noun_name).join(", ")}</Typography>
                </Stack>}
                {props.errorData.attributes && props.errorData.attributes.length > 0 && <Stack mt={1} direction={"row"} spacing={1}>
                    <Typography variant="body2" sx={{ fontWeight: "bold" }}>Attributes: </Typography>
                    <Typography variant="subtitle2">{props.errorData.attributes.map((_: any) => _.attr_name).join(", ")}</Typography>
                </Stack>}
                {props.errorData.noun_variants && props.errorData.noun_variants.length > 0 && <Stack mt={1} direction={"row"} spacing={1}>
                    <Typography variant="body2" sx={{ fontWeight: "bold" }}>Noun Variants: </Typography>
                    <Typography variant="subtitle2">{props.errorData.noun_variants.map((_: any) => _.nounvariant_name).join(", ")}</Typography>
                </Stack>}
                {props.errorData.materials && props.errorData.materials.length > 0 && <Stack mt={1} direction={"row"} spacing={1}>
                    <Typography variant="body2" sx={{ fontWeight: "bold" }}>Materials: </Typography>
                    <Typography variant="subtitle2">{props.errorData.materials.map((_: any) => `${_?.sequence_id ?? ""}-r${_?.version ?? ""}`).join(", ")}</Typography>
                </Stack>}
            </Box>}
        </Alert>}
    </Box>
}