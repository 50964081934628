import React from "react";
import {
    DataGridPremium,
    GridToolbarContainer,
    GridToolbarExportContainer,
    GridCsvExportMenuItem,
    GridToolbarContainerProps,
    GridCsvExportOptions,
    GridToolbarColumnsButton,
    GridToolbarFilterButton,
    GridToolbarDensitySelector,
    DataGridPremiumProps,
    GridExcelExportMenuItem,
    useGridApiRef,
} from '@mui/x-data-grid-premium';
import { ButtonProps } from '@mui/material/Button';
import { Theme, useTheme } from "@mui/material";
import { useAppSelector } from "../../redux";
import { HasAccess } from "../../router/authorization";
import { PERMISSIONS } from "../../router/permission";
import { PlanUpgrade } from "../planUpgrade";

const csvOptions: GridCsvExportOptions = { delimiter: ';' };

const CustomExportButton = (props: ButtonProps) => {

    const { perm } = useAppSelector(store => store.auth.userDetails);

    const showExportBtn = React.useMemo(() => HasAccess(perm, PERMISSIONS.EXPORT_TO_EXCEL), [perm]);

    return <GridToolbarExportContainer {...props}>
        {showExportBtn && <>
            <GridCsvExportMenuItem options={csvOptions} />
            <GridExcelExportMenuItem />
        </>}
        {!showExportBtn && <PlanUpgrade />}
    </GridToolbarExportContainer>
};

const CustomToolbar = (props: GridToolbarContainerProps) => {

    return <GridToolbarContainer >
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <CustomExportButton />
    </GridToolbarContainer>
};


const useStyles = (theme: Theme) => ({
    dataGrid: {
        background: theme.palette.background.paper,
        '.MuiDataGrid-pinnedColumnHeaders': {
            backgroundColor: theme.palette.background.paper
        },
        '.MuiDataGrid-pinnedColumns': {
            backgroundColor: theme.palette.background.paper
        },
        '@media print': {
            '.MuiDataGrid-main': { color: 'rgba(0, 0, 0, 0.87)' },
        }
    },
});

interface CustomDataGridProps extends DataGridPremiumProps {
    showToolbar?: boolean
}

const WithLocalGrid: React.FC<CustomDataGridProps & { id?: string }> = ({ showToolbar = false, pagination = true, id = "grid", ...props }) => {

    const classes = useStyles(useTheme());

    const apiRef = useGridApiRef();
    const [columnState, setColumnState] = React.useState(null);

    // Load saved column state on mount
    React.useEffect(() => {
        try {
            const savedState = localStorage.getItem(`dataGridColumnState-${id}`);
            if (savedState) {
                setTimeout(() => {
                    setColumnState(JSON.parse(savedState));
                }, 1000)
            }
        } catch (e) {
            console.error("Error loading saved state:", e);
        }
    }, [id, props.loading]);

    // Apply column state after the grid is initialized and data is loaded
    React.useEffect(() => {
        if (columnState && apiRef.current.getRowModels().size > 0) {
            apiRef.current.restoreState(columnState);
        }
    }, [columnState, apiRef, props.loading]);

    const handleColumnChange = () => {
        const currentState = apiRef.current.exportState();
        const columnOnlyState = {
            columns: currentState.columns,
        };
        localStorage.setItem(`dataGridColumnState-${id}`, JSON.stringify(columnOnlyState));
    };

    return (
        <DataGridPremium
            key={id}
            apiRef={apiRef}
            {...props}
            initialState={props.initialState}
            disableRowSelectionOnClick={true}
            pagination={pagination}
            autoHeight
            sx={classes.dataGrid}
            slots={{ toolbar: showToolbar ? CustomToolbar : null }}
            pageSizeOptions={[10, 25, 50, 100]}
            onColumnOrderChange={handleColumnChange}
            onColumnWidthChange={handleColumnChange}
            onColumnVisibilityModelChange={handleColumnChange}
        />
    )
};

export const CustomDataGrid: React.FC<CustomDataGridProps & { id?: string, saveLocal?: boolean }> = ({ showToolbar = false, pagination = true, id = "grid", saveLocal = true, ...props }) => {

    const classes = useStyles(useTheme());

    if (saveLocal) {
        return <WithLocalGrid
            {...props}
            showToolbar={showToolbar}
            pagination={pagination}
            id={id}
        />;
    }

    return (
        <DataGridPremium
            {...props}
            disableRowSelectionOnClick={true}
            pagination={pagination}
            autoHeight
            sx={classes.dataGrid}
            slots={{ toolbar: showToolbar ? CustomToolbar : null }}
            pageSizeOptions={[10, 25, 50, 100]}
        />
    )
};
