import { Breakpoint } from "@mui/material";
import { createSlice } from "@reduxjs/toolkit";

interface initialStateType {
  open: boolean;
  title: string;
  body: string | React.ReactNode;
  positiveBtn: string;
  negativeBtn: string;
  hideNegativeBtn: boolean;
  hidePositiveBtn: boolean;
  maxWidth: false | Breakpoint | undefined;
  onOk: () => void;
  onClose?: () => void;
  hideCloseIcon?: boolean;
  enablePadding?: boolean;
  contentStyle?: object;
  closeIconMarginTop?: string;
  closeIconMarginRight?: string;
}

const initialState: initialStateType = {
  open: false,
  title: "",
  body: "",
  positiveBtn: "Ok",
  negativeBtn: "Cancel",
  onOk: () => null,
  hideNegativeBtn: false,
  hidePositiveBtn: false,
  maxWidth: "sm",
  onClose: () => { },
  hideCloseIcon: false,
  enablePadding: true,
  contentStyle: {},
  closeIconMarginTop: "10px",
  closeIconMarginRight: "11px"
};

const dialogSlice = createSlice({
  name: "dialog",
  initialState,
  reducers: {
    openDialog(
      state,
      {
        payload,
      }: {
        payload: {
          title?: string;
          body?: string | React.ReactNode;
          positiveBtn?: string;
          negativeBtn?: string;
          hideNegativeBtn?: boolean;
          hidePositiveBtn?: boolean;
          maxWidth?: false | Breakpoint;
          onOk?: () => void;
          onClose?: () => void;
          hideCloseIcon?: boolean;
          enablePadding?: boolean;
          contentStyle?: object;
        };
      }
    ) {
      let {
        title = "",
        body = "",
        positiveBtn = "Ok",
        negativeBtn = "Cancel",
        onOk = () => null,
        hideNegativeBtn = false,
        hidePositiveBtn = false,
        maxWidth = "sm",
        onClose,
        hideCloseIcon = false,
        enablePadding = true,
        contentStyle = {}
      } = payload;
      state.open = true;
      state.title = title;
      state.body = body;
      state.positiveBtn = positiveBtn;
      state.negativeBtn = negativeBtn;
      state.onOk = onOk;
      state.hideNegativeBtn = hideNegativeBtn;
      state.hidePositiveBtn = hidePositiveBtn;
      state.maxWidth = maxWidth;
      state.onClose = onClose;
      state.hideCloseIcon = hideCloseIcon;
      state.enablePadding = enablePadding;
      state.contentStyle = contentStyle;
      state.closeIconMarginRight = initialState.closeIconMarginRight;
      state.closeIconMarginTop = initialState.closeIconMarginTop
    },
    updateDialog(
      state,
      {
        payload,
      }: {
        payload: {
          maxWidth?: false | Breakpoint;
        };
      }
    ) {
      state.maxWidth = payload?.maxWidth;
    },
    closeDialog(state) {
      state.open = false;
      state.body = "";
    },
    updateCloseIconDimensions(state, { payload }: { payload: { top?: string, right?: string } }) {
      state.closeIconMarginRight = payload.right ? payload.right : state.closeIconMarginRight;
      state.closeIconMarginTop = payload.top ? payload.top : state.closeIconMarginTop;
    }
  },
});

export const { openDialog, closeDialog, updateDialog, updateCloseIconDimensions } = dialogSlice.actions;
export default dialogSlice.reducer;
