import { Box } from '@mui/material';
import { useSnackbar } from 'notistack';
import * as OV from 'online-3d-viewer';
import React, { useEffect, useRef } from 'react';
import { useAppDispatch } from '../../redux';
import { closeBackdrop } from '../../redux/slices/backdrop';

export const ThreeDViewer: React.FC<{ children?: JSX.Element, url: string, file_name: string }> = ({ url, file_name }) => {

    const parentDiv = useRef<HTMLDivElement>(null);
    const viewerRef = useRef<any>(null);

    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useAppDispatch();

    useEffect(() => {
        const parentElement = parentDiv.current;
        if (url) {
            try {
                OV.SetExternalLibLocation("libs");
                OV.Init3DViewerElements();
                if (viewerRef.current === null) {
                    let viewer = new OV.EmbeddedViewer(parentElement, {
                        backgroundColor: new OV.RGBAColor(255, 255, 255, 255),
                        defaultColor: new OV.RGBColor(0, 100, 100),
                        onModelLoaded: () => {
                            if (viewerRef.current) {
                                console.log(viewerRef.current.GetViewer());
                            }
                        },
                    });
                    viewer.Resize = () => {
                        if (viewerRef.current) {
                            viewerRef.current.canvas.style.width = `calc(${document.documentElement.clientWidth}px - 10vw)`;
                            viewerRef.current.canvas.style.height =`calc(${document.documentElement.clientHeight}px - 20vh)`;
                        }
                    };

                    // To load a file into the viewer using the url, we first pass a file name, OV.FileSource.Url and then the url of the model to the OV.InputFile constructor, put the newly created object in an array and save it as inputFiles
                    let inputFiles = [
                        new OV.InputFile(file_name, OV.FileSource.Url, url),
                    ];

                    viewerRef.current = viewer;

                    // Then we just pass inputFiles into the below method and viola
                    viewer.LoadModelFromInputFiles(inputFiles);
                }
            } catch (error) {
                dispatch(closeBackdrop());
                enqueueSnackbar('Something went wrong, unable to load the document', { variant: "error" })
                console.error(`Error from ThreeDViewer: ${error}`)
            }
        }

        return () => {
            if (viewerRef.current !== null && parentElement !== null) {
                delete viewerRef.current.model;
                viewerRef.current.viewer.renderer.resetState();
                viewerRef.current.viewer.Clear();
                delete viewerRef.current.viewer;
                const gl = viewerRef.current.canvas.getContext("webgl2");
                gl.getExtension("WEBGL_lose_context").loseContext();
                const tempClone = viewerRef.current.canvas.cloneNode(true);
                viewerRef.current.canvas.parentNode.replaceChild(
                    tempClone,
                    viewerRef.current.canvas
                );
                parentElement.removeChild(parentElement.children[0]);
                viewerRef.current = null;
            }
        };
         // eslint-disable-next-line
    }, [url]);

    return (
        <Box sx={{ height: "80vh", width: "90vw" }}>
            <div
                style={{ height: "100%", width: "100%" }}
                ref={parentDiv}
                role={"img"}
                aria-label="Canvas showing the model in the 3D Viewer"
            ></div>
        </Box>
    );
}

