import { Edit, ExpandLess, ExpandMore, Save } from "@mui/icons-material";
import { Box, Button, Collapse, IconButton, Modal, Stack, Typography } from "@mui/material";
import React from "react";
import { MyPaper } from "../../../components";
import { TextOnlyEditor } from "../../../HOCs";
import { useAppDispatch, useAppSelector } from "../../../redux";
import { updateNotes } from "../../../redux/slices/proposal";

const editorButtonList = [
    ["undo", "redo"],
    ["font", "fontSize", "formatBlock"],
    ["bold", "underline", "italic", "strike", "subscript", "superscript"],
    ["removeFormat"],
    ["fontColor", "hiliteColor"],
    ["outdent", "indent"],
    ["align", "horizontalRule", "list", "table"],
    ["link"],
];

export const Notes: React.FC<{ children?: JSX.Element, viewOnly?: boolean }> = (props) => {

    const { proposal } = useAppSelector(store => store);
    const dispatch = useAppDispatch();

    const [open, updateOpen] = React.useState(false);
    const [edit, updateEdit] = React.useState(false);

    const updateState = (value: any) => dispatch(updateNotes(value));

    return <MyPaper padding={0}>
        <Box>
            {/* Heading */}
            <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"} sx={{ px: 2, py: 1, borderBottom: (theme) => `1px solid ${theme.palette.divider}` }}>

                <Typography variant="body1" fontFamily={"htrts_medium"}>
                    Notes
                </Typography>

                <Stack direction="row" spacing={1}>
                    {!props.viewOnly && <Button
                        id="open-notes"
                        variant="outlined"
                        color={edit ? 'success' : 'primary'}
                        size={"small"}
                        onClick={() => updateEdit(!edit)}
                        endIcon={edit ? <Save fontSize="small" /> : <Edit fontSize="small" />}
                    >
                        {edit ? 'Save' : 'Edit'}
                    </Button>}
                    <IconButton size={"small"} onClick={(e) => updateOpen(!open)}>
                        {!open && <ExpandMore />}
                        {open && <ExpandLess />}
                    </IconButton>
                </Stack>
            </Stack>

            {/* Main Section */}
            <Collapse in={open} timeout="auto" unmountOnExit>
                <Box sx={{
                    '& .sun-editor': {
                        border: edit ? '' : 'unset'
                    }
                }}>
                    {!edit && <TextOnlyEditor placeholder="Click Edit button to add Notes" setDefaultStyle="border: none !important" defaultValue={proposal.notes} readOnly={true} disable={true} height="250px" setOptions={{ buttonList: [] }} />}
                </Box>
            </Collapse>
            <Modal open={edit} onClose={() => updateEdit(!edit)}>
                <Box flexDirection={"column"} sx={{ width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <Box sx={{ width: "80%" }}>
                        <MyPaper>
                            <>
                                <TextOnlyEditor defaultValue={proposal.notes} height="250px" onChange={(note) => updateState(note)} setOptions={{ buttonList: editorButtonList }} />
                                <Stack spacing={1} direction="row" sx={{ marginTop: "12px" }} justifyContent={"center"}>
                                    <Button id="n-save" sx={{ width: "200px" }} onClick={() => updateEdit(!edit)} variant="contained">Save</Button>
                                    <Button id="n-cancel" sx={{ width: "200px" }} onClick={() => updateEdit(false)} variant="outlined">Cancel</Button>
                                </Stack>
                            </>
                        </MyPaper>
                    </Box>
                </Box>
            </Modal>

        </Box>

    </MyPaper>
}