import { createApi, fetchBaseQuery, retry } from '@reduxjs/toolkit/query/react';
import { config } from '../../../config';
import { LocalStorageKeys } from '../../../utils';

export const stateManagementAPI = createApi({
    reducerPath: "stateManagementAPI",
    baseQuery: retry(fetchBaseQuery({
        baseUrl: config.api_url + "state_management",
        prepareHeaders: (headers) => {
            const token = localStorage.getItem(LocalStorageKeys.authToken);

            // If we have a token set in state, let's assume that we should be passing it.
            if (token) {
                headers.set('authorization', `Bearer ${token}`)
            }

            return headers
        },
    }), { maxRetries: 1 }),
    tagTypes: ['State Management'],
    endpoints: (builder) => ({
        // Get Access state for a module
        getAccessState: builder.query({
            query: ({ access_state = 'release', object_id = '', object_type = '' }) => ({ url: `?access_state=${access_state}&object_id=${object_id}&object_type=${object_type}` }),
        }),

        // Get Access state for a individual Material or BOM or Document
        getAccessStateById: builder.query({
            query: ({ object_id = '' }) => ({ url: `?object_id=${object_id}` }),
        }),

        // Set Access for a individual Material or BOM or Document
        setAccessState: builder.mutation({
            query: ({ payload }) => ({ method: "POST", body: payload }),
        })
    })
})

// Export hooks for usage in functional components
export const { useLazyGetAccessStateQuery, useLazyGetAccessStateByIdQuery, useSetAccessStateMutation } = stateManagementAPI;
