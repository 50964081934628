import { Alert, Box, Button } from "@mui/material"

export const PlanUpgrade = () => {
    return <Box>
        <Alert severity="info" action={
            <Button color="inherit" size="small">
                UPGRADE
            </Button>
        }>You don't have a proper plan to use this feature. Click Upgrade button to upgrade your plan.</Alert>
    </Box>
}