import React from "react";
import { Grid, Box, useTheme, Theme } from "@mui/material";
import { CustomDataGrid, TitleHeader } from "../../components";
import { MastersInterface, SelectBoxOption } from "../../interfaces";
import { openDialog } from "../../redux/slices/dialog";
import { useDispatch } from "react-redux";
import { AddUpdateAttribute, AddUpdateGroup, AddUpdateProductNoun, AddUpdateFamily } from "./addUpdate";
import { MasterValue } from "./util";
import { ProductNounDataInterface, ProductNounListing } from "./productNounListing";
import { openConfirmationDialog, closeConfirmationDialog, updateCongirmationDialogBody } from "../../redux/slices/confirmationDialog";
import { closeBackdrop, openBackdrop } from "../../redux/slices/backdrop";
import { useSnackbar } from "notistack";
import { useCreateProductAttributeMutation, useDeleteProductAttributeMutation, useDeleteProductGroupMutation, useDeleteProductNounMutation, useDeleteProductFamilyMutation, useGetProductNounsQuery } from "../../redux/services";
import { DeletePanel } from "./deletePanel";
import { ConvertToReactSelect } from "../../utils";
import { GridPaginationModel, GridSortModel } from "@mui/x-data-grid-premium";

const useStyles = (theme: Theme) => ({
  root: {
    flexGrow: 1,
    background: theme.palette.background.paper,
    boxShadow: `rgba(99, 99, 99, 0.2) 0px 2px 8px 0px`,
    height: "100%",
    borderRadius: theme.spacing(1),
    padding: theme.spacing(2),
  },
  dataGridParent: {
    height: "calc(100% - 40px)",
    overflow: "scroll",
    marginTop: theme.spacing(2),
  },
  dataGrid: {
    background: theme.palette.background.paper,
    '@media print': {
      '.MuiDataGrid-main': { color: 'rgba(0, 0, 0, 0.87)' },
    }
  },
});

export const RightPanel: React.FC<{
  selectedMaster: MastersInterface,
  data?: any,
  currentData?: any,
  isLoading?: boolean,
  isFetching?: boolean,
  isError?: boolean,
  getMaster?: any,
  familyData?: any,
  state: any,
  setState: any,
  paginationModel: GridPaginationModel,
  totalCount: number,
  setPaginationModel: React.Dispatch<React.SetStateAction<GridPaginationModel>>,
  handleSortModelChange: (sortModel: GridSortModel) => void
}> = ({
  selectedMaster,
  data = [],
  familyData = {},
  currentData = [],
  isLoading = true,
  isFetching = true,
  isError = false,
  getMaster = () => false,
  state = {},
  setState = () => false,
  totalCount = 0,
  paginationModel,
  setPaginationModel,
  handleSortModelChange
}) => {

    const classes = useStyles(useTheme());
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const [createAttribute] = useCreateProductAttributeMutation();

    const [deleteFamily, dF] = useDeleteProductFamilyMutation();
    const [deleteGroup, dG] = useDeleteProductGroupMutation();
    const [deleteProductNoun, dP] = useDeleteProductNounMutation();
    const [deleteAttribute, dA] = useDeleteProductAttributeMutation();

    const productNounD = useGetProductNounsQuery({ page: null, pageSize: null }, { refetchOnMountOrArgChange: true });
    const productNounData = { ...productNounD, data: productNounD?.data?.data ?? [], currentData: productNounD?.data?.data ?? [], totalCount: productNounD?.data?.totalCount };

    const onSearchChange = (newData: string) => {
      setState({ ...state, search: newData });
    };

    const giveMeAddEditComponent = (isEdit: boolean, data: any) => {
      switch (selectedMaster.value) {
        case MasterValue.family:
          return <AddUpdateFamily isEdit={isEdit} data={data} refetch={getMaster} />

        case MasterValue.group:
          return <AddUpdateGroup isEdit={isEdit} data={data} refetch={getMaster} />

        case MasterValue.productNoun:
          return <AddUpdateProductNoun isEdit={isEdit} data={data} refetch={getMaster} />

        case MasterValue.attribute:
          return <AddUpdateAttribute isEdit={isEdit} data={data} productNoun={state.productNoun} refetch={getMaster} updateNoun={(productNoun) => setState((prev: any) => ({...prev, productNoun}))} />

        default:
          return <></>
      }
    };

    const openAddEditDrawer = (isEdit: boolean = false, data: any = null) => {
      dispatch(openDialog({
        title: "",
        hideNegativeBtn: true,
        hidePositiveBtn: true,
        enablePadding: false,
        body: giveMeAddEditComponent(isEdit, data)
      }));
    };

    const giveMeFilerKey = () => {
      switch (selectedMaster.value) {
        case MasterValue.family:
          return "family_name";

        case MasterValue.group:
          return "group_name";

        case MasterValue.productNoun:
          return "product_noun_name";

        case MasterValue.attribute:
          return "attr_name";

        default:
          return "name";
      }
    };

    const giveMeRows = () => {
      let rows = currentData ?? [];
      if (selectedMaster.value === MasterValue.productNoun && state.family && state.family._id) {
        rows = currentData?.filter((_: any) => _.family_id === state.family?._id);
      }

      if (state.search.length === 0) {
        return rows?.map((_: any) => ({ ..._, id: _._id }))
      } else {
        let filterKey = giveMeFilerKey();
        return rows?.map((_: any) => ({ ..._, id: _._id }))?.filter((_: any) => _[filterKey].toLowerCase().includes(state.search.toLowerCase()))
      }
    };

    const giveMeGridBreakPoints = () => {
      if ([MasterValue.attribute].includes(selectedMaster.value)) {
        return { sm: 8, md: 8, lg: 8, xl: 8 }
      } else {
        return { sm: 12, md: 12, lg: 12, xl: 12 }
      }
    };

    const deleteRow = (data: any = null) => {
      let message = `Do you want to delete this "${data.label}" (${selectedMaster.label})?`;
      dispatch(openConfirmationDialog({
        title: "Are you sure?",
        body: <DeletePanel message={message} />,
        positiveBtn: "Delete",
        negativeBtn: "Cancel",
        onOk: () => performRowDel(data, message),
        onNegativeBtn: () => dispatch(closeConfirmationDialog())
      }))
    };

    const performRowDel = async (data: any, message: string) => {
      dispatch(openBackdrop("Deleting " + selectedMaster.label + "...."));
      let res: any = {};
      try {
        switch (selectedMaster.value) {
          case MasterValue.family:
            res = await deleteFamily({ id: data._id });
            break;

          case MasterValue.group:
            res = await deleteGroup({ id: data._id });
            break;

          case MasterValue.productNoun:
            res = await deleteProductNoun({ id: data._id });
            break;

          case MasterValue.attribute:
            res = await deleteAttribute({ id: data._id });
            break;

          default:
            break;
        }

        if (Object.keys(res).includes("data")) {
          enqueueSnackbar(`Deleted ${data.label} (${selectedMaster.label}) Successfully!`, { variant: "success" })
          dispatch(closeConfirmationDialog());
          getMaster({id: state.productNoun?._id});
        } else if (Object.keys(res).includes("error")) {
          // eslint-disable-next-line 
          throw "Unable to delete"
        } else {
          // eslint-disable-next-line 
          throw "Data not found"
        }
      } catch (error) {
        let errorMessage: string = `Unable to delete ${data.label} (${selectedMaster.label})!`;
        enqueueSnackbar(errorMessage, { variant: "error" });
        dispatch(updateCongirmationDialogBody(<DeletePanel message={message} errorMessage={errorMessage} errorData={res?.error?.data?.details} />))
      } finally {
        dispatch(closeBackdrop());
      }
    };

    const duplicate = (data: any) => {
      let message = `Do you want to create a copy of this "${data.label}" (${selectedMaster.label})?`;
      dispatch(openConfirmationDialog({
        title: "Are you sure?",
        body: message,
        positiveBtn: "Create",
        negativeBtn: "Cancel",
        onOk: () => performDuplicate(data, message),
        onNegativeBtn: () => dispatch(closeConfirmationDialog())
      }))
    };

    const performDuplicate = async (data: any, message: string) => {
      dispatch(openBackdrop("Creating copy of " + selectedMaster.label + "...."));
      let res: any = {};
      try {
        res = await createAttribute({
          attr_name: "Copy of - " + data.attr_name,
          product_nouns: data.product_nouns,
          options: data.options.map((_: any) => ({ option_name: _.option_name, option_abbr: _.option_abbr }))
        });

        if (Object.keys(res).includes("data")) {
          enqueueSnackbar(`Created copy of ${data.label} (${selectedMaster.label}) Successfully!`, { variant: "success" })
          dispatch(closeConfirmationDialog());
          getMaster({id: state.productNoun?._id});
        } else if (Object.keys(res).includes("error")) {
          // eslint-disable-next-line 
          throw "Unable to create copy"
        } else {
          // eslint-disable-next-line 
          throw "Data not found"
        }
      } catch (error) {
        let errorMessage: string = `Unable to create copy of ${data.label} (${selectedMaster.label})!`;
        enqueueSnackbar(errorMessage, { variant: "error" });
      } finally {
        dispatch(closeBackdrop());
      }
    };

    const giveMeDropDownValues = () => {
      if (selectedMaster.value === MasterValue.productNoun) {
        return {
          dropDownLabel: "Family",
          showDropDown: true,
          dropDownOptions: [{ value: 'all', label: 'All' }, ...ConvertToReactSelect(familyData?.currentData ?? [], "_id", "family_name")],
          onDropDownChange: (family: SelectBoxOption) => setState({ ...state, family }),
          dropDownValue: state.family
        }
      }
      return {};
    }

    React.useEffect(() => {
      setState({ ...state, search: "", productNoun: null });
      // eslint-disable-next-line
    }, [selectedMaster])

    const ROWS = giveMeRows();

    const loading = isLoading || isFetching || dF.isLoading || dG.isLoading || dP.isLoading || dA.isLoading;

    return (
      <Box sx={classes.root}>
        {/* Header */}
        <TitleHeader
          title={`${state?.productNoun?.product_noun_name ?? ""} ${selectedMaster?.label ?? ""}`}
          count={ROWS?.length ?? 0}
          showSearch={true}
          searchPlaceholder={"Search " + selectedMaster.label}
          onSearchChange={onSearchChange}
          search={state.search}
          showCreateBtn={selectedMaster?.previlages?.includes("C")}
          onCreateBtn={() => openAddEditDrawer(false, null)}
          createBtnLabel={"Create"}
          {...giveMeDropDownValues()}
        />

        {/* Data Table*/}
        <Box sx={classes.dataGridParent}>
          <Grid container>
            
            {[MasterValue.attribute].includes(selectedMaster.value) && <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
              <ProductNounListing
                family={state.family}
                setFamily={(family: SelectBoxOption) => setState({ ...state, family })}
                productNounData={productNounData as ProductNounDataInterface}
                familyOptions={ConvertToReactSelect(familyData?.currentData ?? [], "_id", "family_name")}
                showAll={MasterValue.attribute === selectedMaster.value}
                value={state.productNoun}
                onChange={(productNoun: any) => setState({ ...state, productNoun })}
              />
            </Grid>}

            <Grid item xs={12} {...giveMeGridBreakPoints()} sx={{ paddingTop: { xs: 1, sm: 0 } }}>
              <CustomDataGrid
                saveLocal={false}
                sx={classes.dataGrid}
                rows={ROWS}
                columns={selectedMaster?.column((data: any) => openAddEditDrawer(true, data), deleteRow, duplicate)}
                loading={loading}
                disableVirtualization
                getRowId={row => row._id}
                showToolbar={true}
                rowCount={totalCount}
                paginationModel={paginationModel}
                paginationMode="server"
                onPaginationModelChange={setPaginationModel}
                sortingMode="server"
                onSortModelChange={handleSortModelChange}
              />
            </Grid>
          </Grid>
        </Box>
      </Box>
    );
  };
