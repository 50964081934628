import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { Box, Collapse, Grid, IconButton, Stack, Typography } from "@mui/material";
import React from "react";
import { HighlightSelectBox, MyPaper, TextField } from "../../../components";
import { SelectBoxOption } from "../../../interfaces";
import { useAppDispatch, useAppSelector } from "../../../redux";
import { updateMatSliceByKeyValue } from "../../../redux/slices/material";

export const SourcingOption = [
    { value: "external_purchase", label: "External Purchase" },
    { value: "internal_purchase", label: "Internal Purchase" },
    { value: "internal_manufactured", label: "Internal Manufactured" },
]

export const SourcingValueSelection = {
    [SourcingOption[0].value]: SourcingOption[0],
    [SourcingOption[1].value]: SourcingOption[1],
    [SourcingOption[2].value]: SourcingOption[2],
}

export const Sourcing: React.FC<{ children?: JSX.Element, supplierData: any, viewOnly?: boolean }> = ({ viewOnly = true,...props}) => {

    const { materials } = useAppSelector(store => store);
    const dispatch = useAppDispatch();

    const [open, updateOpen] = React.useState(true);

    const updateState = (key: string, value: any) => dispatch(updateMatSliceByKeyValue({ key, value }));

    return <MyPaper padding={0}>
        <Box>
            {/* Heading */}
            <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"} sx={{ px: 2, borderBottom: (theme) => `1px solid ${theme.palette.divider}` }}>

                <Typography variant="body1" fontFamily={"htrts_medium"}>
                    Sourcing Details
                </Typography>

                <Stack direction="row" spacing={2}>
                    <IconButton onClick={(e) => updateOpen(!open)}>
                        {!open && <ExpandMore />}
                        {open && <ExpandLess />}
                    </IconButton>
                </Stack>
            </Stack>

            {/* Main Section */}
            <Collapse in={open} timeout="auto" unmountOnExit>
                <Grid container spacing={2} p={2}>
                    {/* Sourcing Type */}
                    <Grid item xs={12}>
                        <HighlightSelectBox
                            id="sourcing-type"
                            label="Sourcing Type"
                            value={SourcingValueSelection[materials.sourceType]}
                            options={SourcingOption}
                            onChange={(data: any) => {
                                if (data) {
                                    updateState("sourceType", data.value)
                                }
                            }}
                            viewOnly={viewOnly}
                        />
                    </Grid>

                    {/* Supplier Name */}
                    {materials.sourceType === "external_purchase" && <Grid item xs={12} sm={4}>
                        <HighlightSelectBox
                            id="supplier-name"
                            label={"Supplier Name"}
                            margin={"none"}
                            error={materials.error.supplierName}
                            helperText={materials.error.supplierName ? "Please select Supplier Name" : ""}
                            value={materials.supplierName}
                            options={props.supplierData?.currentData ?? []}
                            loading={props.supplierData?.isLoading || props.supplierData?.isFetching}
                            onChange={(data: SelectBoxOption) => {
                                updateState("supplierName", data)
                            }}
                            viewOnly={viewOnly}
                        />
                    </Grid>}

                    {/* Manufacture Name */}
                    {materials.sourceType === "external_purchase" && <Grid item xs={12} sm={4}>
                        <TextField
                            id="manufacturer-name"
                            variant="outlined"
                            size="small"
                            fullWidth
                            value={materials.manufactureName}
                            label={"Manufacturer Name"}
                            onChange={(e) => updateState("manufactureName", e.target.value)}
                            viewOnly={viewOnly}
                        />
                    </Grid>}

                    {/* Manufacture Number */}
                    {materials.sourceType === "external_purchase" && <Grid item xs={12} sm={4}>
                        <TextField
                            id="manufacturer-no"
                            variant="outlined"
                            size="small"
                            fullWidth
                            value={materials.manufactureNo}
                            label={"Manufacturer Number"}
                            onChange={(e) => updateState("manufactureNo", e.target.value)}
                            viewOnly={viewOnly}
                        />
                    </Grid>}

                    {/* Unit Name */}
                    {materials.sourceType !== "external_purchase" && <Grid item xs={12} sm={4}>
                        <TextField
                            variant="outlined"
                            size="small"
                            fullWidth
                            value={materials.unitName}
                            label={"Unit Name"}
                            onChange={(e) => updateState("unitName", e.target.value)}
                            viewOnly={viewOnly}
                        />
                    </Grid>}

                    {/* Unit Location */}
                    {materials.sourceType !== "external_purchase" && <Grid item xs={12} sm={4}>
                        <TextField
                            variant="outlined"
                            size="small"
                            fullWidth
                            value={materials.unitLocation}
                            label={"Unit Location"}
                            onChange={(e) => updateState("unitLocation", e.target.value)}
                            viewOnly={viewOnly}
                        />
                    </Grid>}

                    {/* Other Details */}
                    {materials.sourceType !== "external_purchase" && <Grid item xs={12} sm={4}>
                        <TextField
                            variant="outlined"
                            size="small"
                            fullWidth
                            value={materials.otherDetails}
                            label={"Other Details"}
                            onChange={(e) => updateState("otherDetails", e.target.value)}
                            viewOnly={viewOnly}
                        />
                    </Grid>}
                </Grid>
            </Collapse>
        </Box>
    </MyPaper>
}