import React from "react";
import { useParams } from "react-router-dom";
import { AssignBOMToDrawioShape } from "./assignBOMToDrawioShape";
import { AssignChildBOMorMaterialToDrawioShape } from "./assignChildBOMOrMaterialToDrawioShape";
import { AssignTagNoToDrawioShape } from "./assignTagNoToDrawioShape";
import { AssignUnAssignSerialNo } from "./assignUnassignSerialNo";
import { BOMTreeForDrawIO } from "./bomTreeForDrawio";
import { CreateAssignChildBOMorMaterialToDrawioShape } from "./createAssignChildBOMOrMaterial";
import { SearchAssignChildBOMorMaterialToDrawioShape } from "./searchAssignChildBOMOrMaterial";
import { SyncBOMToDrawio } from "./syncBOMToDrawio";

export const EmbedComponent: React.FC<{ children?: JSX.Element }> = (props) => {

    const { component_name } = useParams();

    switch (component_name) {
        case 'assign_tag_no':
            return <AssignUnAssignSerialNo />;

        case 'assign_tag_no_to_drawio_shape':
            return <AssignTagNoToDrawioShape />;

        case 'assign_bom_to_drawio_shape':
            return <AssignBOMToDrawioShape />;

        case 'assign_child_bom_or_material_to_drawio_shape':
            return <AssignChildBOMorMaterialToDrawioShape />;

        case 'search_assign_child_bom_or_material_to_drawio_shape':
            return <SearchAssignChildBOMorMaterialToDrawioShape />;

        case 'create_assign_child_bom_or_material_to_drawio_shape':
            return <CreateAssignChildBOMorMaterialToDrawioShape />;

        case 'view_meta_data':
            return <></>;

        case 'bomTreeForDrawio':
            return <BOMTreeForDrawIO />;

        case 'sync_bom':
            return <SyncBOMToDrawio />;

        default:
            return <></>;
    };
}