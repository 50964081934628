import { createApi, fetchBaseQuery, retry } from '@reduxjs/toolkit/query/react';
import Axios from "axios";
import { config } from '../../../config';
import { LocalStorageKeys } from '../../../utils';
import { MatSliceInterface, linksData, ILinking } from '../../slices/material';
import { MDGTypeOptions } from '../../../screens/viewMaterial/utils';

const linksFilter = (links: ILinking[] = []) => {
    return links.filter(l => l.object_type !== "bom");
}

const prepareMaterialBody = (payload: MatSliceInterface, updateVersion: boolean = false, linkedData?: linksData) => {
    let body: any = {
        manual_desc_gen: payload.mdgType === MDGTypeOptions[1].value,
        cost: payload.cost,
        cost_category: payload.costingType?.value,
        currency: payload?.currency?.value,
        drawing_number: payload.drawingNumber,
        external_ref_id: payload.clientRefNo,
        long_description: payload.matDesc,
        noun_id: payload.materialTag?.value,
        noun_variant_id: payload.varientId,
        organization_unit: { other_details: payload.otherDetails, unit_code: payload.unitName, unit_location: payload.unitLocation },
        physical_material: (payload.physicalMaterial?.value === "true"),
        raw_material: (payload.rawMaterial?.value === "true"),
        short_description: payload.matShortDesc,
        sourcing_type: payload.sourceType,
        status: payload.status?.value,
        uom_type: payload?.uom?.value,
        notes: payload?.notes ?? "",
        attribute_details: payload?.attribute_details,
        links: linkedData ? linksFilter(linkedData?.links) : updateVersion ? linksFilter(payload?.link) : linksFilter(payload?.unlink),
        unlinks: linkedData ? linksFilter(linkedData?.unlinks) : updateVersion ? payload?.unlink : [],
        vendor: { manufacturer_name: payload.manufactureName, vendor_name: payload.supplierName?.label, vendor_id: payload.supplierName?.value, part_number: payload.manufactureNo },
        lead_time_days: parseInt(payload?.lead_time?.toString() ?? "0")
    }

    if (body.cost && body.currency) {
        body.money = {
            cost: parseFloat(Number(body.cost).toFixed(2)),
            currency: body.currency
        }
    }

    if (payload.uom?.value === 'length') {
        body.length = parseFloat(payload?.length?.toString() ?? "0");
        body.units_length = payload?.length_unit?.value;
    }

    if (payload.uom?.value === 'weight') {
        body.weight = parseFloat(payload?.weight?.toString() ?? "0");
        body.units_weight = payload?.weight_unit?.value;
    }

    if (!payload.varientId) {
        delete body.noun_variant_id
    }

    if (payload.sourceType === 'external_purchase') {
        delete body.organization_unit
    } else {
        delete body.vendor
    }

    return body;
}

export const materialAPI = createApi({
    reducerPath: "materialAPI",
    baseQuery: retry(fetchBaseQuery({
        baseUrl: config.api_url + "mm/material",
        prepareHeaders: (headers, { getState }) => {
            const token = localStorage.getItem(LocalStorageKeys.authToken);

            // If we have a token set in state, let's assume that we should be passing it.
            if (token) {
                headers.set('authorization', `Bearer ${token}`)
            }

            return headers
        },
    }), { maxRetries: 1 }),
    tagTypes: ['Materials', 'SearchedMaterials'],
    refetchOnMountOrArgChange: true,
    endpoints: (builder) => ({
        // Get Materials API
        getMaterials: builder.query({
            query: ({
                include_histories = false, object_id = null, object_type = null,
                unit_region_id = null, noun_id = null, page = 0, pageSize = 10, sort_by = null, sort_order = null
            }) => ({
                url: `?include_histories=${include_histories}${object_type ? `&object_type=${object_type}` : ``}${object_id ? `&object_id=${object_id}` : ``}${unit_region_id ? `&unit_region_id=${unit_region_id}` : ``}${noun_id ? `&noun_id=${noun_id}` : ``}${page !== null ? `&page_no=${page + 1}` : ''}${pageSize ? `&page_size=${pageSize}` : ''}${sort_by ? `&sort_by=${sort_by}` : ''}${sort_order ? `&sort_order=${sort_order}` : ''}`,
                method: "GET"
            }),
            transformResponse: (data: any, meta: { request: Request; response?: Response }, args) => {
                let totalCountHeader = meta?.response?.headers.get('x-hontrel-total-records-count');
                let totalCount = totalCountHeader ? Number(totalCountHeader) : 0;
                return { data, totalCount };
            },
            providesTags: ['Materials'],
        }),

        getMaterialsBySearch: builder.query({
            query: ({
                search = "", include_histories = false, page: page_no = 0, pageSize: page_size = 10, sort_by = null, sort_order = null
            }) => ({
                url: `/search?search=${search}&include_histories=${include_histories}${page_no !== null ? `&page_no=${page_no + 1}` : ``}${page_size ? `&page_size=${page_size}` : ``}${sort_by ? `&sort_by=${sort_by}` : ''}${sort_order ? `&sort_order=${sort_order}` : ''}`,
                method: "GET"
            }),
            transformResponse: (data: any, meta: { request: Request; response?: Response }, args) => {
                let totalCountHeader = meta?.response?.headers.get('x-hontrel-total-records-count');
                let totalCount = totalCountHeader ? Number(totalCountHeader) : 0;
                return { data, totalCount };
            },
            providesTags: ['SearchedMaterials'],
        }),

        // Get Material by material id API
        getMaterialById: builder.query({
            query: ({ id }) => ({ url: `${id}`, method: "GET" }),
            // transformResponse: (response: any[], meta, arg) => response?.[0] ?? {},
        }),

        // Create Material API
        createMaterial: builder.mutation({
            async queryFn(payload: MatSliceInterface, _queryApi, _extraOptions, fetchWithBQ) {

                let result: any = {};
                try {
                    const createMaterial: any = await fetchWithBQ({ method: "POST", body: { ...prepareMaterialBody(payload, true) } });

                    if (createMaterial.data) {
                        let createdMaterial: any = await fetchWithBQ({ method: "GET", url: "/" + createMaterial.data._id });

                        result = { data: { ...createMaterial, _id: createdMaterial?.data?._id } }
                    } else {
                        result = createMaterial
                    }
                } catch (err) {

                    result = { error: true }
                } finally {
                    return result;
                }
            }
        }),

        // Update Material API
        updateMaterial: builder.mutation({
            query: ({ id, payload, update_version = false }: { id: string, payload: MatSliceInterface, update_version: boolean }) => {
                return { url: `/${id}?update_version=${update_version}`, method: "PUT", body: { ...prepareMaterialBody(payload, update_version) } }
            },
            invalidatesTags: ['Materials']
        }),

        // Delete Material API
        deleteMaterial: builder.mutation({
            query: ({ id, force }) => ({ url: `/${id}?force=${force}`, method: "DELETE" }),
            invalidatesTags: ['Materials']
        }),

        // Get Dashboard API
        getDashboard: builder.query({
            query: ({ year = '' }) => ({ url: `/dashboard${year.length ? `?year=${year}` : ''}`, method: "GET" })
        }),

        // Search Material
        searchMaterial: builder.query({
            query: ({ search = '' }) => ({ url: `/search?search=${search}`, method: "GET" })
        }),

        // Material Image Upload
        uploadMaterialImage: builder.mutation({
            async queryFn(_arg, _queryApi, _extraOptions) {
                const { data, url, file } = _arg;
                let result: any = {};
    
                try {
                    let resData = null;
                    if (file) {
                        resData = await Axios({
                            url,
                            method: "put",
                            headers: { 'Content-Type': file.type },
                            data: file
                        });
                    }
                    result = { data: { resData, document: data } }
                } catch (err) {
                    result = { error: err }
                } finally {
                    return result;
                }
            },
            invalidatesTags: ['Materials']
        }),
    })

})

// Export hooks for usage in functional components
export const {
    useGetMaterialsQuery,
    useLazyGetMaterialsQuery,
    useGetMaterialByIdQuery,
    useLazyGetMaterialByIdQuery,
    useCreateMaterialMutation,
    useUpdateMaterialMutation,
    useDeleteMaterialMutation,
    useLazyGetDashboardQuery,
    useLazySearchMaterialQuery,
    useGetMaterialsBySearchQuery,
    useLazyGetMaterialsBySearchQuery,
    useUploadMaterialImageMutation
} = materialAPI
