import { Alert, Box } from "@mui/material";
import { useSnackbar } from "notistack";
import React from "react";
import { matchPath, useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { DataSheetPDFPreview, Layout } from "../../components";
import { withNavBars } from "../../HOCs";
import { useAppDispatch, useAppSelector } from "../../redux";
import { useGetGroupsDDQuery, useGetNounsDDQuery, useGetVendorsDDQuery, useLazyGetMaterialByIdQuery, useLazyGetDocumentsByTagGroupIdQuery, useGetLocationAndDocumentTagsQuery, useLinkModuleMutation, useUnlinkModuleMutation } from "../../redux/services";
import { closeBackdrop, openBackdrop } from "../../redux/slices/backdrop";
import { closeConfirmationDialog, openConfirmationDialog } from "../../redux/slices/confirmationDialog";
import { linksData, reset, updateMatSlice } from "../../redux/slices/material";
import { AppRoutes } from "../../router/routes";
import { BasicDetails } from "./basicDetails";
import { Documents } from "../../components/documents";
import { BOMTypeOptions, MDGTypeOptions, NavBars } from "./utils";
import { SecondaryNavBars } from "../../interfaces";
import { LocalStorageKeys } from "../../utils";
import { HasAccess } from '../../router/authorization';
import { PERMISSIONS } from '../../router/permission';
import { DataTableForm } from "./dataSheet";
import { MaterialProcure } from "./procure";

export const ViewMaterials: React.FC<{ children?: JSX.Element, currentPath: string, customAssociation?: { object_type: string, object_id: string }, avoidNavigate?: boolean, handleParentDialogClose?: any }> = ({ currentPath, avoidNavigate = false, handleParentDialogClose, ...props }) => {

    const params = useParams();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const location: any = useLocation();
    const { enqueueSnackbar } = useSnackbar();
    const [searchParams, setSearchParams] = useSearchParams();
    const materialSliceData = useAppSelector(store => store.materials);
    const { perm } = useAppSelector(store => store.auth.userDetails);

    const [getMaterialById, materialData] = useLazyGetMaterialByIdQuery({ refetchOnReconnect: true, refetchOnFocus: true });
    const nounData = useGetNounsDDQuery(null, { refetchOnMountOrArgChange: true, refetchOnFocus: true });
    const groupData = useGetGroupsDDQuery(null, { refetchOnMountOrArgChange: true, refetchOnFocus: true });
    const supplierData = useGetVendorsDDQuery(null, { refetchOnMountOrArgChange: true, refetchOnFocus: true });
    const { materials } = useAppSelector(store => store);
    const [linkDocument] = useLinkModuleMutation();
    const [unlinkDocument] = useUnlinkModuleMutation();
    const [getMaterials, { currentData: data, isLoading }] = useLazyGetDocumentsByTagGroupIdQuery();
    const folderData = useGetLocationAndDocumentTagsQuery(null);

    const [selected, updateSelected] = React.useState<string[]>(["1"]);

    const creatable = React.useMemo(() => HasAccess(perm, PERMISSIONS.MATERIAL_CREATE), [perm]);
    const readable = React.useMemo(() => HasAccess(perm, PERMISSIONS.MATERIAL_READ), [perm]);
    const updatable = React.useMemo(() => HasAccess(perm, PERMISSIONS.MATERIAL_UPDATE), [perm]);
    const deletable = React.useMemo(() => HasAccess(perm, PERMISSIONS.MATERIAL_DELETE), [perm]);
    const publishable = React.useMemo(() => {
        const primaryAccess = HasAccess(perm, PERMISSIONS.MATERIAL_PUBLISH);
        if (props.customAssociation?.object_type === 'product') {
            return HasAccess(perm, PERMISSIONS.PRODUCT_PUBLISH) && primaryAccess
        }
        if (props.customAssociation?.object_type === 'proposal') {
            return HasAccess(perm, PERMISSIONS.PROPOSAL_PUBLISH) && primaryAccess
        }
        if (props.customAssociation?.object_type === 'project') {
            return HasAccess(perm, PERMISSIONS.PROJECT_PUBLISH) && primaryAccess
        }
        return primaryAccess
    }, [perm, props.customAssociation?.object_type]);

    const p_id: string = params?.id ?? "";
    let matId: string = params?.mat_id ?? "New";
    const isEdit = matId !== ':mat_id' && matId !== "New";
    const isViewOnly = isEdit ? !updatable : !creatable;
    const paths = JSON.parse(localStorage.getItem(LocalStorageKeys.locationStatePaths) || "[]") || location.state?.paths || [];

    if (paths.length <= 0) {
        paths.push({ title: "Materials", path: AppRoutes.allMaterials });
    };

    if (matId === 'New' && materialSliceData.matId.toString().length > 0) {
        matId = materialSliceData.matId.toString();
    };

    const updateOnTabClick = (nav: SecondaryNavBars) => {
        if (nav?.id?.includes("2")) {
            const tag_id = nav?.tag_id;
            setSearchParams(
                `tab=${searchParams.get("tab")}&${nav?.id === "2.1" ? "tags=all" : `&tag_id=${tag_id}`
                }`
            );
        }
        updateSelected([nav.id])
    };

    const giveMeLinksAndUnlinks = (links: any[], unlinks: any[], object_type: string): linksData => {
        let obj: linksData = {
            links: [{ object_ids: links, object_type: object_type }],
            unlinks: [{ object_ids: unlinks, object_type: object_type }],
        }
        return obj;
    };

    const callLinkDocument = async (object_type: string, links?: any[], unlinks?: any[], refetch?: any) => {
        let action = (unlinks || [])?.length > 0 ? "Unlinking" : "Linking";
        let actionSuccess = (unlinks || [])?.length > 0 ? "Unlinked" : "Linked";
        let actionError = (unlinks || [])?.length > 0 ? "Unlink" : "Link";
        dispatch(openBackdrop(`${action} the ${object_type}...`));
        const linkedData = giveMeLinksAndUnlinks(links || [], unlinks || [], object_type);
        let res: any = {};
        try {
            if (action === "Linking") {
                res = await linkDocument({ object_id: matId, object_type: "material", payload: { links: linkedData.links } })
            } else {
                res = await unlinkDocument({ object_id: matId, object_type: "material", payload: { unlinks: linkedData.unlinks } });
            }
            if (Object.keys(res).includes("data")) {
                enqueueSnackbar(`${actionSuccess} ${object_type} Successfully!`, { variant: "success" })
                dispatch(closeConfirmationDialog());
                getMaterialById({ id: matId });
                refetch && refetch();
            } else if (Object.keys(res).includes("error")) {
                // eslint-disable-next-line 
                throw res.error.data;
            }
        } catch (error: any) {
            let errorMessage: string = error?.title ?? error ?? `Oops! Something went wrong, Unable to ${actionError} ${object_type}`
            enqueueSnackbar(errorMessage, { variant: "error" });
        } finally {
            dispatch(closeBackdrop());
        }
    };

    const getSheetPath = (sheet_id: string) => {
        let sheetPath = AppRoutes.viewMatDataSheet(matId, sheet_id);
        switch (props?.customAssociation?.object_type) {
            case "project":
                sheetPath = AppRoutes.createOrUpdateProjectMaterialDataSheet(p_id, matId, sheet_id);
                break;
            case "proposal":
                sheetPath = AppRoutes.createOrUpdateProposalMaterialDataSheet(p_id, matId, sheet_id);
                break;
            case "product":
                sheetPath = AppRoutes.createOrUpdateProductMaterialDataSheet(p_id, matId, sheet_id);
                break;
            default:
                break;
        }
        return sheetPath;
    };

    const getPaths = () => {
        const sheet_id = materialData?.data?.associations?.find((_: any) => _.object_type === "dataTableForm")?.object_details?.at(-1)?._id || "New";

        let basicPath = AppRoutes.viewMatBasicDetail(matId);
        let docPath = AppRoutes.viewMatDocuments(matId);
        let sheetPath = AppRoutes.viewMatDataSheet(matId, sheet_id);
        let techPath = AppRoutes.viewMatDetailsPreview(matId);
        let procureMaterialPath = `${AppRoutes.viewMatProcure(matId)}?tab=tab1`;

        switch (props?.customAssociation?.object_type) {
            case "project":
                basicPath = AppRoutes.createOrUpdateProjectMaterialBasicDetail(p_id, matId);
                docPath = AppRoutes.createOrUpdateProjectMaterialDocuments(p_id, matId);
                sheetPath = AppRoutes.createOrUpdateProjectMaterialDataSheet(p_id, matId, sheet_id);
                techPath = AppRoutes.createOrUpdateProjectMaterialDetailsPreview(p_id, matId);
                procureMaterialPath = `${AppRoutes.createOrUpdateProjectMaterialProcure(p_id, matId)}?tab=tab1`;
                break;
            case "proposal":
                basicPath = AppRoutes.createOrUpdateProposalMaterialBasicDetail(p_id, matId);
                docPath = AppRoutes.createOrUpdateProposalMaterialDocuments(p_id, matId);
                sheetPath = AppRoutes.createOrUpdateProposalMaterialDataSheet(p_id, matId, sheet_id);
                techPath = AppRoutes.createOrUpdateProposalMaterialDetailsPreview(p_id, matId);
                procureMaterialPath = `${AppRoutes.createOrUpdateProposalMaterialProcure(p_id, matId)}?tab=tab1`;
                break;
            case "product":
                basicPath = AppRoutes.createOrUpdateProductMaterialBasicDetail(p_id, matId);
                docPath = AppRoutes.createOrUpdateProductMaterialDocuments(p_id, matId);
                sheetPath = AppRoutes.createOrUpdateProductMaterialDataSheet(p_id, matId, sheet_id);
                techPath = AppRoutes.createOrUpdateProductMaterialDetailsPreview(p_id, matId);
                procureMaterialPath = `${AppRoutes.createOrUpdateProductMaterialProcure(p_id, matId)}?tab=tab1`;
                break;
            default:
                break;

        }
        return [basicPath, docPath, sheetPath, techPath, procureMaterialPath]
    };

    let [basicPath, docPath, sheetPath, techPath, procureMaterialPath] = getPaths();

    const giveMeComponent = () => {
        // Basic Details
        if (matchPath(location.pathname, basicPath) || (avoidNavigate && selected.includes("1"))) {
            return <BasicDetails
                refetch={() => {
                    if (matId !== "New") {
                        getMaterialById({ id: matId })
                    }
                }}
                matId={materialData?.currentData?.sequence_id}
                nounData={nounData}
                groupData={groupData}
                supplierData={supplierData}
                _id={materialData?.currentData?._id}
                version={materialData?.data?.version ?? 0}
                associations={materialData?.data?.associations ?? []}
                customAssociation={{ ...props?.customAssociation, object_id: p_id, object_type: props?.customAssociation?.object_type ?? "" }}
                avoidNavigate={avoidNavigate}
                updateSelected={updateSelected}
                handleParentDialogClose={handleParentDialogClose}
                docNavigationPath={docPath}
                publishable={publishable}
            />
        }

        // Documents  
        if (matchPath(location.pathname, docPath) || (avoidNavigate && selected.join("-").includes("2"))) {
            return <Documents
                refetch={refetch}
                Id={materialData?.data?.sequence_id}
                _id={materialData?.data?._id}
                objectType="material"
                tag_id={searchParams.get('tag_id')}
                tag_group_id={searchParams.get('group_id')}
                linkDocument={(links: any, refetch: any) => callLinkDocument('document', links, [], refetch)}
                associations={materialData?.data?.associations ?? []}
                onAnnotate={(id: string) => {
                    let routeState: any = {
                        state: {
                            paths:
                                [...paths,
                                { title: `${materialData?.data?.sequence_id ?? "..."}-r${materialData?.data?.version ?? 0}`, path: basicPath },
                                { title: "Documents", path: docPath },
                                ]
                        },
                    };
                    if (props?.customAssociation?.object_type) {
                        navigate(
                            AppRoutes.nestedMaterialDocumentAnnotateViewer(
                                props?.customAssociation?.object_type,
                                p_id,
                                matId,
                                id
                            ),
                            { ...routeState }
                        );
                    } else {
                        navigate(
                            AppRoutes.materialDocumentAnnotateViewer(
                                matId,
                                id
                            ),
                            { ...routeState }
                        );
                    }
                }}
                showCreateBtn={!materials.released}
                showHelperBtn={false}
                hideDeleteBtn={materials.released}
                creatable={creatable}
                updatable={updatable}
                publishable={publishable}
                deletetable={deletable}
                readable={readable}
            />
        }

        // Data Sheet
        if (matchPath(location.pathname, sheetPath) || (avoidNavigate && selected.includes("3"))) {
            return <DataTableForm type="datasheet" associations={materialData?.data?.associations ?? []} preview={materials.released ? true : isViewOnly} refetch={refetch} getPath={getSheetPath} linkDataTableTemplate={(links: any[], unlinks: any[]) => callLinkDocument('dataTableForm', links, unlinks)} />
        }

        // Material Details Preview
        if (matchPath(location.pathname, techPath) || (avoidNavigate && selected.includes("4"))) {
            let uomValue = `${materials?.uom?.label} ${materials?.uom?.label === "length" ? `(${materials?.length} ${materials?.length_unit?.label})` : materials?.uom?.label === "weight" ? `(${materials?.weight} ${materials?.weight_unit?.label})` : ""}`;
            const basicDetails = {
                optionDetails: [
                    { label: "Material ID", value: materials?.sequenceId ?? "" },
                    { label: "Material Description", value: materials?.matShortDesc ?? "" },
                    { label: "Uom Type", value: uomValue ?? "" },
                    { label: "Item Name", value: materials?.materialTag?.label ?? "" },
                    { label: "Item Group", value: materials?.materialGroup?.label ?? "" },
                ],
                attribute_details: materials?.attribute_details ?? [],
                notes: materials?.notes ?? "",
                sequenceId: materials?.sequenceId ?? "",
                version: materials?.version ?? 0,
                associations: materials?.associations ?? [],
                manufactureNo: materials?.manufactureNo,
                supplierName: materials?.supplierName,
                manufactureName: materials?.manufactureName

            };
            return <DataSheetPDFPreview basicDetails={basicDetails} headerTitle={"MATERIAL DETAILS"} />
        }

        // Material Procurement
        if (`${location.pathname}?tab=tab1` === procureMaterialPath || `${location.pathname}?tab=tab2` === procureMaterialPath || (avoidNavigate && selected.includes("5"))) {
            return <MaterialProcure  />
        }

        return <></>
    };

    const refetch = () => {
        if (matId !== "New") {
            getMaterialById({ id: matId })
            refetchDocumentFolders();
        }
    };

    const refetchDocumentFolders = () => {
        getMaterials({ tag_group_id: folderData.data?.locationId, object_type: "material", object_ids: matId !== "New" && matId ? [matId] : undefined })
    };

    React.useEffect(() => {
        if (basicPath === AppRoutes.viewMatBasicDetail(matId)) {
            localStorage.removeItem(LocalStorageKeys.locationStatePaths)
        }
        // eslint-disable-next-line
    }, [location.pathname]);

    React.useEffect(() => {
        if (location.state?.paths?.length > 0) {
            localStorage.setItem(LocalStorageKeys.locationStatePaths, JSON.stringify(location.state?.paths))
        }
        // eslint-disable-next-line
    }, [p_id]);

    React.useEffect(() => {
        if (matId === ':mat_id') {
            dispatch(closeBackdrop());
            navigate(AppRoutes.allMaterials);
        }
    });

    React.useEffect(() => {
        if (isEdit) {
            dispatch(openBackdrop("Fetching Material Details..."));
            getMaterialById({ id: matId });
            refetchDocumentFolders();
        } else {
            dispatch(reset());
            dispatch(closeBackdrop());
        }
        // eslint-disable-next-line    
    }, [JSON.stringify(params)])

    React.useEffect(() => {
        const updateValue = async () => {
            if (isEdit
                && materialData.isSuccess
                && supplierData.isSuccess
            ) {
                const data = materialData.currentData;
                if (!data) {
                    return;
                }
                const tag = { value: data?.noun_id, label: data?.noun_name };
                const group = { value: data?.group_id, label: data?.group_name };
                const region = { value: data?.unit_region_id, label: data?.unit_region_name };
                const supplierName = supplierData?.currentData?.filter((_: any) => _._id === data?.vendor?.vendor_id)?.[0];

                let mdgType = MDGTypeOptions[0].value;
                if (data?.manual_desc_gen && !data?.noun_variant_id) {
                    mdgType = MDGTypeOptions[1].value;
                }

                dispatch(updateMatSlice({
                    matId,
                    sequenceId: data.sequence_id,
                    version: data.version,
                    mdgType: mdgType,
                    matDesc: data.long_description,
                    matShortDesc: data.short_description,
                    materialGroup: group,
                    materialTag: tag,
                    clientRefNo: data.external_ref_id,
                    costingType: data.cost_category ? { value: data.cost_category, label: data.cost_category } : null,
                    bomType: BOMTypeOptions.filter(_ => _.value === data.bom_type)?.[0],
                    drawingNumber: data.drawing_number,
                    status: { value: data.status, label: data.status },
                    uom: { value: data.uom_type, label: data.uom_type },
                    length: data.length,
                    length_unit: { value: data.units_length, label: data.units_length },
                    weight: data.weight,
                    weight_unit: { value: data.units_weight, label: data.units_weight },
                    physicalMaterial: { value: data.physical_material ? "true" : "false", label: data.physical_material ? "Yes" : "No" },
                    rawMaterial: { value: data.raw_material ? "true" : "false", label: data.raw_material ? "Yes" : "No" },
                    inventoryType: { value: data.inventory_type, label: data.inventory_type },
                    sourceType: data.sourcing_type,
                    supplierName: data.sourcing_type === "external_purchase" ? supplierName : null,
                    manufactureNo: data.sourcing_type === "external_purchase" ? data.vendor?.part_number : "",
                    manufactureName: data.sourcing_type === "external_purchase" ? data.vendor?.manufacturer_name : "",
                    unitName: ["internal_purchase", "internal_manufactured"].includes(data.sourcing_type) ? data?.organization_unit?.unit_code : "",
                    unitLocation: ["internal_purchase", "internal_manufactured"].includes(data.sourcing_type) ? data?.organization_unit?.unit_location : "",
                    otherDetails: ["internal_purchase", "internal_manufactured"].includes(data.sourcing_type) ? data?.organization_unit?.other_details : "",
                    varientId: data.noun_variant_id,
                    cost: data.money?.cost,
                    notes: data?.notes ?? "",
                    currency: data.money?.currency ? { label: data.money?.currency, value: data.money?.currency } : null,
                    attribute_details: data.attribute_details?.map((_: any, index: number) => ({ ..._, order: index })),
                    attachments: data?.attachments ?? [],
                    associations: data?.associations ?? [],
                    lead_time: data.lead_time_days,
                    region: region,
                    error: {
                        mdgType: false, matDesc: false, materialGroup: false, materialTag: false, clientRefNo: false, costingType: false, bomType: false,
                        drawingNumber: false, status: false, physicalMaterial: false, rawMaterial: false, inventoryType: false, sourceType: false, supplierName: false,
                        manufactureName: false, manufactureNo: false, unitName: false, unitLocation: false, otherDetails: false, lead_time: false, region: false
                    },
                    released: data?.released ?? false,
                }))
                dispatch(closeBackdrop());
            }
        }
        updateValue();
        // eslint-disable-next-line
    }, [materialData, supplierData]);

    React.useEffect(() => {
        if (!materialData.isFetching && materialData.isError) {
            enqueueSnackbar('404 - Unable to find the requested material details', { variant: "error" });
            dispatch(openConfirmationDialog({
                title: "404 - Not Found",
                body: "Unable to find the requested material",
                positiveBtn: "Ok",
                onOk: () => {
                    dispatch(closeConfirmationDialog())
                    navigate(AppRoutes.allMaterials)
                },
                hideNegativeBtn: true
            }))
            dispatch(closeBackdrop());
        }
        // eslint-disable-next-line
    }, [materialData.isError])

    React.useEffect(() => {
        if (folderData.isSuccess) {
            refetchDocumentFolders();
        }
        // eslint-disable-next-line
    }, [folderData.isSuccess]);

    const documentFolders = data?.filter((_: any) => _.object_id === matId)?.[0] ?? { tags: [], tag_group_id: "" };

    return <Box p={0.5} height={"100%"}>
        <Layout
            history={[
                ...paths.map((_: any) => {
                    return { label: _.title, onClick: () => navigate(_.path) }
                }),
                { label: isEdit ? `${materialData?.data?.sequence_id ?? '...'}-r${materialData?.data?.version ?? 0}` : 'New', onClick: () => navigate(basicPath) }
            ]}
            currentPath={currentPath}
            navBars={NavBars(matId, isEdit, isLoading || folderData.isLoading, documentFolders.tags ?? [], documentFolders?.tag_group_id ?? "", [basicPath, docPath, sheetPath, techPath, procureMaterialPath])}
            mainPanel={<>
                {/* Alert Box */}
                {materials.released && <Alert sx={{ mb: 1 }} severity="info">{
                    "This item is published. Changes, overwrite or deleting is not allowed. Only revision is allowed."
                }</Alert>}
                {giveMeComponent()}
            </>}
            sideNavVariant={"whiteboard"}
            otherBreadscrumProps={{
                hideMenuBtn: paths.length > 1,
                showBackBtn: paths.length > 1,
            }}
            avoidNavigate={avoidNavigate}
            onClick={updateOnTabClick}
            selected={selected}
            locationState={paths}
            onBackBtnClick={paths[0]?.path ? () => navigate(paths[paths.length - 1]?.path) : undefined}
            resizable
        />
    </Box>
}

export default withNavBars(ViewMaterials);