import { Stack, Typography } from '@mui/material';
import { ChangeEvent } from 'react';
import './index.css';

interface TextEditorProps {
    content: string;
    onChange?: (content: string) => void;
    readOnly?: boolean
    placeholder?: string;
    charLimit?: number;
}

export const TextEditor: React.FC<TextEditorProps> = ({ content, onChange = () => { }, readOnly = false, placeholder = '', charLimit = 1000 }) => {

    const handleChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
        if (e.target.value?.length > charLimit) {
            return;
        } else {
            onChange(e.target.value)
        }
    }

    return (
        <Stack p={1} id="textarea-container">
            <textarea
                id="textarea"
                value={content} 
                disabled={readOnly} 
                onChange={readOnly ? () => { } : handleChange} 
                placeholder={placeholder} 
            />
            <Stack flexDirection={"row"} justifyContent={"flex-end"} mt={1}>
                <Typography variant='caption'>{content.length} / {charLimit}</Typography>
            </Stack>
        </Stack>
    );
}