import * as React from 'react';
import { Box, Button, Typography, Stack, Avatar } from '@mui/material';
import { useSnackbar } from "notistack";
import { Upload } from '@mui/icons-material';
import DocViewer, { DocViewerRenderers, IDocument } from "@cyntler/react-doc-viewer";

export const pattern = /^[a-zA-Z0-9](?:[a-zA-Z0-9 ._,-]*[a-zA-Z0-9])?\.[a-zA-Z0-9_-]+$/;

export const MyNoRenderer: React.FC<{
    document: IDocument | undefined,
    fileName: string
}> = ({ document, fileName }) => {
    return <div>Unable to render the file! Please download and check.</div>;
};

export const UploadImage: React.FC<{ setDimensionAuto?: boolean, children?: JSX.Element, url: string, onUploaded: any, disabled?: boolean, title?: string, sizeLimit?: number, useImage?: boolean, uploadAllFiles?: boolean, uploadError?: boolean, id?: string, resetAttachments?: Function }> = ({ title = "Upload", sizeLimit = 1, ...props }) => {

    const { uploadAllFiles = false } = props;

    const { enqueueSnackbar } = useSnackbar();
    const inputRef = React.createRef<HTMLInputElement>();
    const [file, setFile] = React.useState<{ file: any, url: string }>({ file: null, url: props.url });

    const onUploadBtnClicked = () => {
        inputRef?.current?.click();
    }

    const handleUploadFile = (event: React.ChangeEvent<HTMLInputElement>) => {
        const files = inputRef.current?.files;
        const sizeInBytes = files?.[0]?.size ?? 0;
        const fileName = files?.[0]?.name ?? "";
        const sizeInKB = sizeInBytes / 1024;
        const sizeInMB = sizeInKB / 1024;
        if (sizeInMB > sizeLimit) {
            enqueueSnackbar(`Image Size should be less than or equal to ${sizeLimit}MB`, { variant: "warning" });
            return false;
        }
        if (!pattern.test(fileName ?? "")) {
            enqueueSnackbar(`Special characters like "/", "", "?", "#", "&", "=","+", "%" are not allowed, pls rename and upload`, { variant: "warning" });
            return false;
        }
        setFile({
            ...file, file: files?.[0], url: URL.createObjectURL(event?.target?.files?.[0] ?? new Blob([JSON.stringify("{}", null, 2)], {
                type: "application/json",
            }))
        });
        props.onUploaded(event.target.files?.[0]);
    }

    const clearImage = () => {
        setFile({ file: null, url: '' });
        props.onUploaded(null);
        if (props.resetAttachments) {
            props.resetAttachments();
        }
    }

    const giveMeHeightWidth = (file: string) => {
        return props.setDimensionAuto ? { height: "auto", width: "auto" } : { height: 300, width: 300 }
    }

    React.useEffect(() => {
        setFile({ ...file, url: props.url })
        // eslint-disable-next-line
    }, [props.url])

    return (<Stack spacing={1} alignItems={"center"}>
        <Button
            id="upload-image-btn"
            variant="outlined"
            sx={{ textTransform: "capitalize" }}
            disabled={props.disabled}
            onClick={onUploadBtnClicked}
            size='medium'
            endIcon={<Upload />}
        >
            {title}
        </Button>
        {file.file && <Typography variant='caption'>{file.file?.name ?? ""}</Typography>}
        {props.useImage && (
                    <Avatar variant="square" sx={{ '.MuiAvatar-img': {objectFit: "contain !important" }, width: 200, height: 200}} alt={"alt"} src={file.url ? file.url : "https://thumbs.dreamstime.com/b/random-word-concept-cubes-171151178.jpg"}>
                        <img height={"50px"} width={"50px"} src={`/imageNotFound.png`} alt={'alt'} />
                    </Avatar>
                )
        }
        {!props.useImage && file.url && false && <DocViewer
            documents={[{ uri: file.url }]}
            style={giveMeHeightWidth(file.url)}
            pluginRenderers={DocViewerRenderers}
            config={{
                header: {
                    disableHeader: true,
                    disableFileName: true,
                    retainURLParams: true,
                },
                noRenderer: {
                    overrideComponent: MyNoRenderer
                }
            }}
        />}
        <input id={props?.id ?? "upload"} hidden ref={inputRef} accept={uploadAllFiles ? "" : "image/jpeg, image/png, image/webp"} type="file" onChange={handleUploadFile} />

        {(file.file || file?.url) && <Button disabled={props.disabled} onClick={clearImage} variant={"outlined"} size={"small"} sx={{ marginTop: 1, marginRight: 1 }}>Clear</Button>}

        <Box display={"flex"} flexDirection={"column"} alignItems="center">
            <Typography variant={"caption"} gutterBottom>{`Upload ${uploadAllFiles ? "file" : "image"} less than ${sizeLimit}MB`}</Typography>
            {!uploadAllFiles && <Typography variant={"caption"} gutterBottom>Please upload only these formats: <strong>JPEG, WebP, PNG</strong>.</Typography>}
        </Box>
        {props.uploadError && <Typography variant="caption" color="error">
            {"Please upload the document."}
        </Typography>}
    </Stack>
    );
}
